import { extractPlatformIsSupported } from '../platform/extractPlatformIsSupported';
import { extractUrlFromLinkJsonLd } from '../url';
export var extractPreview = function extractPreview(jsonLd, platform, iframeUrlType) {
  var preview = jsonLd === null || jsonLd === void 0 ? void 0 : jsonLd.preview;
  var isSupported = extractPlatformIsSupported(preview, platform);
  if (preview && isSupported) {
    if (typeof preview === 'string') {
      return {
        src: preview
      };
    } else if (preview['@type'] === 'Link' && iframeUrlType === 'interactiveHref' && preview.interactiveHref) {
      return {
        src: preview.interactiveHref,
        aspectRatio: preview['atlassian:aspectRatio']
      };
    } else if (preview['@type'] === 'Link') {
      return {
        src: extractUrlFromLinkJsonLd(preview),
        aspectRatio: preview['atlassian:aspectRatio']
      };
    } else {
      // TODO, EDM-565: Remove this once the typings for Dropbox and Jira have been patched up.
      if (preview.url || preview.href) {
        if (iframeUrlType === 'interactiveHref' && preview.interactiveHref) {
          return {
            src: preview.interactiveHref,
            aspectRatio: preview['atlassian:aspectRatio']
          };
        }
        return {
          src: extractUrlFromLinkJsonLd(preview.url || preview.href),
          aspectRatio: preview['atlassian:aspectRatio']
        };
      } else if (preview.content) {
        return {
          content: preview.content
        };
      }
    }
  }
};