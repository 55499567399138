import {AnalyticsAPI, MarkAsSafeFunction} from './AnalyticsAPI';
import {AnalyticsRegistry} from './AnalyticsRegistry';
import DefaultAnalyticsAPI from './DefaultAnalyticsAPI';
import {logger} from '../logger/LoggerAdaptor';

class AnalyticsController {

  loggingEnabled = false;

  isLoggingEnabled() {
    return this.loggingEnabled;
  }

  /**
   * In the browser console, enable analytics logging by entering window._analyticsController.enableAnalyticsLogging().
   */
  enableAnalyticsLogging(): string {
    console.info('Enabling analytics logging...');
    this.loggingEnabled = true;
    return "Analytics logging is now enabled. (>'-')> <('_'<) ^('_')\- \m/(-_-)\m/ <( '-')> \_( .\")> <( ._.)-`";
  }

  /**
   * In the browser console, disable analytics logging by entering window._analyticsController.disableAnalyticsLogging().
   */
  disableAnalyticsLogging(): string {
    console.info('Disabling analytics logging...');
    this.loggingEnabled = false;
    return 'Analytics logging is now disabled. ¯\\_(ツ)_/¯';
  }

}

/**
 * This class serves two purposes:
 *   - It provides the ability for products to register their implementations
 *     of AnalyticsAPI for use by the connect front end code.
 *   - It provides the AnalyticsAPI implementation that connect front end code
 *     should use. This implementation delegates to the registered AnalyticsAPI
 *     implementation. This class should be used within the connect front end
 *     code as follows:
 *
 *     import analytics from '../adaptors/analytics/AnalyticsAdaptor';
 *
 *     analytics.trigger('Flags', 'close', {'type': 'warning'});
 */
class AnalyticsAdaptor implements AnalyticsRegistry {

  analyticsController: AnalyticsController;
  analyticsAPI = DefaultAnalyticsAPI;

  constructor() {
    this.analyticsController = new AnalyticsController();
    (<any> window)._analyticsController = this.analyticsController;
  }

  /**
   * Implementation of AnalyticsRegistry#registerAnalyticsAPI().
   */
  registerAnalyticsAPI(analyticsAPI: AnalyticsAPI): void {
    this.analyticsAPI = analyticsAPI;
  }

  /**
   * Implementation of AnalyticsAPI#trigger() for use by connect-module-core code.
   */
  trigger(category: any, action: any, appKey: any, payload: any): void {
    try {
      this.analyticsAPI.trigger(category, action, appKey, payload);
      if (this.analyticsController.isLoggingEnabled()) {
        logger.info('Analytics:', category, action, appKey, payload);
      }
    } catch (exception) {
      logger.warn('Exception trapped triggering analytics event', exception);
    }
  }

  markAsSafe(...allowedStrings:string[]): MarkAsSafeFunction {
    if (this.analyticsAPI.markAsSafe) {
      return this.analyticsAPI.markAsSafe(...allowedStrings);
    } else {
      return DefaultAnalyticsAPI.markAsSafe(...allowedStrings);
    }
  }

  dangerouslyCreateSafeString(value: string): any {
    if (this.analyticsAPI.dangerouslyCreateSafeString) {
      return this.analyticsAPI.dangerouslyCreateSafeString(value);
    } else {
      return DefaultAnalyticsAPI.dangerouslyCreateSafeString(value);
    }
  }

}

export const analytics = new AnalyticsAdaptor() as AnalyticsRegistry;
