import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useCallback } from 'react';
import { getATLContextUrl } from '@atlaskit/atlassian-context';
import { encodeParamsToUrl } from '../../../util/url';
export var firstCharUpper = function firstCharUpper(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
var ROVO_PARAM_PREFIX = 'rovoChat';
var createRovoParams = function createRovoParams(params) {
  var rovoParams = {};
  Object.entries(params).forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      key = _ref2[0],
      value = _ref2[1];
    rovoParams["".concat(ROVO_PARAM_PREFIX).concat(firstCharUpper(key))] = encodeURIComponent(value);
  });
  return rovoParams;
};
export var useAgentUrlActions = function useAgentUrlActions(_ref3) {
  var cloudId = _ref3.cloudId;
  var onEditAgent = useCallback(function (agentId) {
    var url = "".concat(getATLContextUrl('home'), "/chat/agents/").concat(agentId, "/edit");
    var urlWithParams = encodeParamsToUrl(url, _objectSpread({
      cloudId: cloudId
    }, createRovoParams({
      cloudId: cloudId
    })));
    window.open(urlWithParams, '_blank', 'noopener, noreferrer');
  }, [cloudId]);
  var onCopyAgent = function onCopyAgent(agentId) {
    navigator.clipboard.writeText("".concat(window.location.origin, "/people/agent/").concat(agentId));
  };
  var onDuplicateAgent = useCallback(function (agentId) {
    var baseUrl = "".concat(getATLContextUrl('home'), "/chat/agents/new");
    var urlWithParams = encodeParamsToUrl(baseUrl, _objectSpread({
      cloudId: cloudId
    }, createRovoParams({
      cloudId: cloudId,
      agentId: agentId,
      pathway: 'agents-create'
    })));
    window.open(urlWithParams, '_blank', 'noopener, noreferrer');
  }, [cloudId]);
  var onConversationStarter = function onConversationStarter(_ref4) {
    var agentId = _ref4.agentId,
      prompt = _ref4.prompt;
    var baseUrl = "".concat(getATLContextUrl('home'), "/chat");
    var urlWithParams = encodeParamsToUrl(baseUrl, _objectSpread({
      cloudId: cloudId
    }, createRovoParams({
      cloudId: cloudId,
      agentId: agentId,
      prompt: prompt,
      pathway: 'chat'
    })));
    window.open(urlWithParams, '_blank', 'noopener, noreferrer');
  };
  var onOpenChat = function onOpenChat(agentId) {
    var baseUrl = "".concat(getATLContextUrl('home'), "/chat");
    var urlWithParams = encodeParamsToUrl(baseUrl, _objectSpread({
      cloudId: cloudId
    }, createRovoParams({
      cloudId: cloudId,
      agentId: agentId
    })));
    window.open(urlWithParams, '_blank', 'noopener, noreferrer');
  };
  return {
    onEditAgent: onEditAgent,
    onCopyAgent: onCopyAgent,
    onDuplicateAgent: onDuplicateAgent,
    onOpenChat: onOpenChat,
    onConversationStarter: onConversationStarter
  };
};