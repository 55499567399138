import { createResource } from 'react-resource-router';

import { fetchData } from './utils';

export type Params = {
  repositoryFullSlug: string;
  branch: string;
  page: string;
};

export const getBranchUrl = (params: Params): string => {
  return `/!api/2.0/repositories/${params.repositoryFullSlug}/refs/branches/${params.branch}`;
};

export const loadPipelineBranchResource = createResource({
  type: 'pipeline-branch',
  getKey: ({ match: { params } }) => getBranchUrl(params as Params),
  maxAge: 60000,
  getData: async ({ match: { params } }) => {
    if (!params.branch) return null;

    const branchData = await fetchData(
      getBranchUrl(params as Params),
      'pipeline_branch_resource_exception'
    );

    return branchData;
  },
});
