import React from 'react';

type Context = {
  repositories: BB.Repository[];
  loadRepositories: (includeStatus: boolean) => Promise<BB.Repository[]>;
};

const ReposContext = React.createContext<Context>({
  repositories: [],
  loadRepositories: (_includeStatus: boolean) => Promise.resolve([]),
});

ReposContext.displayName = 'ReposContext';

export default ReposContext;
