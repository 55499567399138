import React from 'react';

import Icon from '@atlaskit/icon';

import { BitkitIconTypes } from '../types';

// @ts-ignore TODO: fix noImplicitAny error here
const glyph = props => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" {...props}>
    <g
      id="Source-Submodule-Icon"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Double-Folder-Icon">
        <path
          d="M5.19999981,16.4959421 L5.19999981,19 L3.99079514,19 C2.89130934,19 2,18.1661625 2,17.1409081 L2,10.4266166 L2,4.00585866 C2,2.89805351 2.89585781,2 3.9973917,2 L9.0026083,2 C10.1057373,2 11.3302593,2.82564831 11.7447262,3.86181545 L12.2,5 L20.0092049,5 C21.1086907,5 22,5.83383751 22,6.85909191 L22,12 L15.3999998,12 L14.944726,10.8618155 C14.5302591,9.82564831 13.3057371,9 12.2026081,9 L7.19739151,9 C6.09585762,9 5.19999981,9.89805351 5.19999981,11.0058587 L5.19999981,16.4959421 Z"
          id="Combined-Shape"
          fill="#42526E"
        />
        <path
          d="M14.2599142,13.0442477 L20.4571338,13.0442477 C21.3092353,13.0442477 22,13.5776508 22,14.2335025 L22,20.8107452 C22,21.4675525 21.3038854,22 20.4571338,22 L8.04286623,22 C7.19076474,22 6.5,21.466597 6.5,20.8107452 L6.5,17.0069981 L6.5,12.0520376 C6.5,11.1948709 7.17433563,10.5 8.00348904,10.5 L11.7710466,10.5 C12.6014007,10.5 13.5231305,11.1388472 13.8351106,11.9405839 L14.2194132,12.9281773 C14.2342264,12.966245 14.2477197,13.004965 14.2599142,13.0442477 Z"
          id="Combined-Shape"
          fill="#42526E"
        />
      </g>
    </g>
  </svg>
);

export const SubmoduleIcon = (props: BitkitIconTypes) => (
  <Icon {...props} glyph={glyph} />
);
