import { useLayoutEffect } from 'react';
import { useResource as _useResource } from 'react-resource-router';
import { useInteractionContext } from '@atlassian/ufo-interaction-context';
export function useResource() {
  var ufoContext = useInteractionContext();
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  var returnValues = _useResource.apply(void 0, args);
  var operationName = args[0].type;
  var isLoading = returnValues === null || returnValues === void 0 ? void 0 : returnValues.loading;
  useLayoutEffect(function () {
    if (isLoading) {
      return ufoContext === null || ufoContext === void 0 ? void 0 : ufoContext.hold(operationName);
    }
  }, [isLoading, operationName, ufoContext]);
  return returnValues;
}