import { combineReducers } from 'redux';

import changes, { ChangesState } from './changes';
import dashboard, { DashboardState } from './dashboard';
import environmentHistory, {
  EnvironmentHistoryState,
} from './environment-history';
import preview, { PreviewState } from './preview';
import redeployment, { RedeploymentState } from './redeployment';
import summary, { SummaryState } from './summary';
import websocket, { WebsocketType } from './websocket';

export type DeploymentsState = {
  changes: ChangesState;
  dashboard: DashboardState;
  environmentHistory: EnvironmentHistoryState;
  preview: PreviewState;
  redeployment: RedeploymentState;
  summary: SummaryState;
  websocket: WebsocketType[];
};

export default combineReducers({
  changes,
  dashboard,
  environmentHistory,
  preview,
  redeployment,
  summary,
  websocket,
});
