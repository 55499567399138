import _defineProperty from "@babel/runtime/helpers/defineProperty";
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { Fragment } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { CREATE_BREAKPOINT } from '../../common/constants';
import { useTheme } from '../../theme';
import { IconButtonSkeleton } from '../IconButton/skeleton';
var searchInputContainerStyles = css(_defineProperty({
  // eslint-disable-next-line @atlaskit/design-system/no-physical-properties
  marginRight: "var(--ds-space-100, 8px)",
  // eslint-disable-next-line @atlaskit/design-system/no-physical-properties
  marginLeft: "var(--ds-space-250, 20px)",
  position: 'relative'
}, "@media (max-width: ".concat(CREATE_BREAKPOINT - 1, "px)"), {
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
  display: 'none !important'
}));
var searchInputSkeletonStyles = css({
  boxSizing: 'border-box',
  width: '220px',
  height: "var(--ds-space-400, 32px)",
  padding: "0 ".concat("var(--ds-space-100, 8px)", " 0 ", "var(--ds-space-500, 40px)"),
  borderRadius: "var(--ds-border-radius-200, 6px)",
  opacity: 0.15
});
var searchIconStyles = css(_defineProperty({}, "@media (min-width: ".concat(CREATE_BREAKPOINT, "px)"), {
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
  display: 'none !important'
}));

// Not exported to consumers, only used in NavigationSkeleton
// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var SearchSkeleton = function SearchSkeleton() {
  var theme = useTheme();
  return jsx(Fragment, null, jsx("div", {
    css: searchInputContainerStyles
  }, jsx("div", {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
    style: theme.mode.skeleton,
    css: searchInputSkeletonStyles
  })), jsx(IconButtonSkeleton, {
    css: searchIconStyles,
    marginRight: 5,
    size: 26
  }));
};