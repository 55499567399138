/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { gridSize } from '@atlaskit/theme';

export const DropMenuContainer = styled.div<{
  showValueDescription: boolean;
  allowEmptyChoice: boolean;
  fitContainer: boolean;
  shiftLeft?: boolean;
  shiftRight?: boolean;
}>`
  margin-left: ${({ shiftLeft }) => (shiftLeft ? gridSize() * 2 : 0)}px;
  margin-right: ${({ shiftRight }) => (shiftRight ? gridSize() * 2 : 0)}px;

  & .triggerButton {
    text-align: left;
    align-items: center;
    min-width: ${({ showValueDescription, allowEmptyChoice }) => {
      let multiplier = 13;
      if (allowEmptyChoice) {
        multiplier = 18;
      } else if (showValueDescription) {
        multiplier = 45;
      }
      return gridSize() * multiplier;
    }}px;
    max-width: ${({ fitContainer }) =>
      fitContainer ? '100%' : `${gridSize() * 45}px`};
    height: auto;
    min-height: ${({ showValueDescription }) =>
      showValueDescription ? gridSize() * 8 : 0}px;

    &[disabled],
    :hover,
    :focus {
      align-items: center;
      text-align: left;
      height: auto;
    }
  }
`;

export const PermissionDescription = styled.p`
  white-space: break-spaces;
`;

export const PermissionsTriggerDescriptionContainer = styled.div`
  & [role='menuitem'] {
    background-color: transparent;
    text-align: left;
    color: inherit;

    :hover,
    :focus {
      background-color: transparent;
      color: inherit;
    }
  }
`;
