/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { typography } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { mediaObjectExpression } from '@atlassian/bitkit-responsive';

// Ak Page widths copied from @atlaskit/page because they can't be imported
const fixedGridPageWidth = '960px';

// because PageHeader is no longer inside Grid we need to
// apply the same margin/padding as it does to be consistent
export const PageHeaderWrapper = styled.header(
  {
    margin: '0 auto',
    padding: `0 ${token('space.250', '20px')}`,
  },
  ({ layout }: { layout?: string; 'data-qa'?: string }) => ({
    maxWidth: layout === 'fluid' ? '100%' : fixedGridPageWidth,
  })
);

export const PageHeaderColumn = styled.div(
  {
    ...mediaObjectExpression.upToSmall({ margin: 0 }),
    margin: `0 ${token('space.250', '20px')}`,
  },
  ({ tabbedLayout }: { tabbedLayout?: boolean }) => ({
    width: tabbedLayout ? '100%' : 'auto',
  })
);

export const PageSection = styled.section({
  margin: '0 auto',
  maxWidth: fixedGridPageWidth,
});

// This is the default styling applied to @atlaskit/panel headings
export const PanelHeader = styled.h2({
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  ...(typography.h400() as any),
});
