import { createResource, RouterContext } from 'react-resource-router';

import { WorkspaceNavPermission } from 'src/components/types';
import { NavigationData } from 'src/types/navigation';
import urls from 'src/urls/workspaces';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

export type WorkspaceNavigation = NavigationData & {
  workspace: BB.Workspace;
  workspace_permission?: WorkspaceNavPermission;
};

export const workspaceNavigationResource = createResource({
  type: 'WORKSPACE_NAVIGATION',
  getKey: ({ match }: RouterContext) =>
    `workspace:${match.params.workspaceSlug}`,
  maxAge: 1000 * 60 * 10, // 10 minute
  getData: async ({ match }): Promise<WorkspaceNavigation | null> => {
    const { workspaceSlug } = match.params;

    if (!workspaceSlug) {
      throw new Error(
        'This resource can only be used on routes that contain a workspaceSlug in the path'
      );
    }

    const url = urls.api.internal.navigation(workspaceSlug);
    const response = await fetch(authRequest(url, { headers: jsonHeaders }));

    if (!response.ok) {
      throw new Error(
        `Error fetching workspace navigation data (${response.status}): ${response.statusText}`
      );
    }

    return response.json();
  },
});
