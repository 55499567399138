import { ModelBase } from './ModelBase';

export class Metric extends ModelBase {
  readonly uuid: string = '';
  readonly sample_time: string = '';
  readonly metric_type: string = '';
  readonly value: any = {};
  readonly step: any = {};
  readonly 'step.uuid': string;

  constructor(props: Partial<Metric> = {}) {
    super(props);
    Object.assign(this, {
      'step.uuid': props.step?.uuid,
      ...props,
    });
    Object.freeze(this);
  }
}
