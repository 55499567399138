import { oneDay } from 'src/components/date';
import { User } from 'src/components/types';
import storage from 'src/utils/store';

const storageNameDismissedCount = (user: User, workspaceSlug: string) =>
  `default-group-intro-flag:dismisscount:${user.uuid}:${workspaceSlug}`;

const storageNameShownTime = (user: User, workspaceSlug: string) =>
  `default-group-intro-flag:lastshown:${user.uuid}:${workspaceSlug}`;

export const setDismissed = (user: User, workspaceSlug: string) => {
  const key = storageNameDismissedCount(user, workspaceSlug);
  const dismissCount = storage.get(key, 0);
  storage.set(key, Number(dismissCount) + 1);
};

export const getDismissedCount = (user: User, workspaceSlug: string) => {
  const key = storageNameDismissedCount(user, workspaceSlug);
  return storage.get(key, 0);
};

export const setShownNow = (user: User, workspaceSlug: string) => {
  const key = storageNameShownTime(user, workspaceSlug);
  storage.set(key, Date.now());
};

export const getShown = (user: User, workspaceSlug: string) => {
  const key = storageNameShownTime(user, workspaceSlug);
  // if no date stored, return a day ago
  return storage.get(key, Date.now() - oneDay);
};
