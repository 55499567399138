import React, { ReactElement, memo } from 'react';

import { FormattedMessage } from 'react-intl';

import { gridSize } from '@atlaskit/theme';

import {
  NameCellContainer,
  NameCellAvatar,
  NameCellText,
} from './name-cell.style';

export type NameCellProps = {
  avatar: React.ReactNode;
  name: string | ReactElement<FormattedMessage>;
  additionalInfo?: string | ReactElement<FormattedMessage>;
  padding?: number;
};

const NameCell = memo(
  ({
    avatar,
    name,
    additionalInfo,
    padding = gridSize() / 2,
  }: NameCellProps) => (
    <NameCellContainer padding={padding}>
      <NameCellAvatar>{avatar}</NameCellAvatar>
      <NameCellText>
        <div>{name}</div>
        {additionalInfo && (
          <div>
            <small>{additionalInfo}</small>
          </div>
        )}
      </NameCellText>
    </NameCellContainer>
  )
);

export default NameCell;
