import { getSupportedPaths as getAccountPaths } from './account/paths';
import { getSupportedPaths as getGlobalPaths } from './global/paths';
import { getSupportedPaths as getProjectPaths } from './project/paths';
import { getSupportedPaths as getRepositoryPaths } from './repository/paths';
import { SupportedPath, RouteFeatures } from './types';
import { getSupportedPaths as getWorkspacePaths } from './workspace/paths';

export const getSupportedPaths = (features: RouteFeatures): SupportedPath[] => {
  return [
    ...getGlobalPaths(),
    ...getAccountPaths(features),
    ...getWorkspacePaths(features),
    ...getProjectPaths(),
    ...getRepositoryPaths(features),
  ];
};
