import * as processors from './process-url';
import { RouteProcessor } from './types';

const JS_EVAL_START = ':load-start';
const JS_EVAL_STOP = ':load-end';

/**
 * Processors used by 'mapResources'
 */
const resourceProcessors: RouteProcessor[] = [
  processors.cloudfrontAssets,
  processors.localhostAssets,
  processors.newRelicBamCell,
  processors.newRelicCom,
];

/**
 * Processors used by 'sanitiseEndpoints'
 */
const sanitiseProcessors: RouteProcessor[] = [
  processors.gatewayApi,
  processors.bitbucketApi,
  processors.repositoryPaths,
  processors.sentryApi,
  processors.atlassianApi,
];

/**
 * Replaces the URL with simplified <domain> and optional trailing path
 * Removes hashing, versioning, and other unique identifiers
 * Ex: "https://bbc-object-storage--frontbucket.us-east-1.prod.public.atl-paas.net/frontbucket/assets/present/edit.9faa16bd.js"
 *  returns "<cloudfront>/edit.js"
 * @param url
 * @returns
 */
export const mapResources = (url: string): string => {
  return processors.processUrl(url, resourceProcessors);
};

/**
 * Replaces the URL with simplified <domain> and optional trailing path
 * Removes PII and UGC
 * Unprocessed routes will return <unknown> to prevent sensitive data from reporting
 * Ex 1: "https://bitbucket.org/bitbucket/frontbucket?state=section"
 *  returns "<repository>"
 * Ex 2: "https://bitbucket.org/gateway/api/session/heartbeat"
 *  returns "<gateway-api>/heartbeat"
 * As new endpoints are tracked, we need to be careful to remove PII and UGC
 * @param url
 * @returns
 */
export const sanitiseEndpoints = (url: string): string => {
  return processors.processUrl(url, sanitiseProcessors);
};

export const bundleEvalTimings = {
  mapPerformanceMark: (mark: string): { name: string; type: string } | null => {
    if (mark.endsWith(JS_EVAL_START)) {
      return {
        name: mark.substring(0, mark.indexOf(JS_EVAL_START)),
        type: 'start',
      };
    }
    if (mark.endsWith(JS_EVAL_STOP)) {
      return {
        name: mark.substring(0, mark.indexOf(JS_EVAL_STOP)),
        type: 'end',
      };
    }
    return null;
  },
};
