import { defineMessages } from 'react-intl';

export default defineMessages({
  emailAddress: {
    id: 'frontbucket.workspace.settings.invitations.table.column.emailAddress',
    defaultMessage: 'Email address',
    description: 'Email address table column',
  },
  actions: {
    id: 'frontbucket.workspace.settings.invitations.table.column.actions',
    defaultMessage: 'Actions',
    description: 'Actions table column',
  },
  invitationsTableHeader: {
    id: 'frontbucket.workspace.settings.invitations.table.actions.invitationsTableHeader',
    defaultMessage:
      '{count, plural, one {{count} invitation} other {{count} invitations}} pending',
    description: 'Header for invitations table',
  },
  resendInvitation: {
    id: 'frontbucket.workspace.settings.invitations.table.actions.resendInvitation',
    defaultMessage: 'Resend invitation',
    description: 'Link text for link to resend invitation',
  },
  invitationSent: {
    id: 'frontbucket.workspace.settings.invitations.table.actions.invitationSent',
    defaultMessage: 'invitation sent',
    description: 'Text for success lozenge after a user resends an invitation',
  },
  cancelInvitation: {
    id: 'frontbucket.workspace.settings.invitations.table.actions.cancelInvitation',
    defaultMessage: 'Cancel invitation',
    description: 'Link text for link to cancel invitation',
  },
});
