import { encodeBranchName } from 'src/urls/utils';

const urls = {
  api: {
    v10: {
      branchesTags: (owner: string, slug: string) =>
        `/!api/1.0/repositories/${owner}/${slug}/branches-tags`,
    },
    v20: {
      commitStatuses: (owner: string, slug: string, node: string) =>
        `/!api/2.0/repositories/${owner}/${slug}/commit/${node}/statuses`,
    },
  },
  external: {
    branchesEmptyStateLearnHow: 'https://confluence.atlassian.com/x/TwlODQ',
  },
  ui: {
    compareBranch: (
      repositoryFullSlug: string,
      branchName: string,
      params?: { compareWith?: string }
    ) => {
      return `/${repositoryFullSlug}/branches/compare/${encodeBranchName(
        branchName
      )}..${encodeBranchName(params?.compareWith || '')}`;
    },
  },
};

export default urls;
