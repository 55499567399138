import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';

export const ProjectRepositoriesLoadable = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      /* webpackChunkName: "project-repositories" */
      './project-repositories-layout'
    ),
});
