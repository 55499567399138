import React, { ReactNode } from 'react';

import WarningIcon from '@atlaskit/icon/glyph/warning';

import { WarningCardHeader, WarningCardWrapper } from './styled';

export interface Props {
  title: string;
  color: string;
  children?: ReactNode;
}

/* eslint @typescript-eslint/ban-types: "warn" */
const SettingsButton: React.FC<Props> = ({ title, color, children }) => {
  return (
    <WarningCardWrapper>
      <WarningCardHeader>
        <WarningIcon label="Warning" primaryColor={color} />
        <h5>{title}</h5>
      </WarningCardHeader>
      {children}
    </WarningCardWrapper>
  );
};

export default React.memo(SettingsButton);
