/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { call } from 'redux-saga/effects';

import FeatureGates from '@atlaskit/feature-gate-js-client';
import { StatsigFeatureKeys } from '@atlassian/bitbucket-features';

import { Commit } from 'src/components/types';
import { BuildStatusCountsMap, VerificationStatus } from 'src/types';
import urls from 'src/urls/commit';
import authRequest, { jsonHeaders } from 'src/utils/fetch';
import { short12Hash } from 'src/utils/short-hash';

function* makeApiRequest(
  url: string,
  body: string,
  headers?: HeadersInit
): Generator<any, Response, any> {
  const request = authRequest(url, {
    headers,
    method: 'POST',
    body,
  });
  return yield call(fetch, request);
}

export function* fetchBuildStatuses(
  repositoryFullSlug: string,
  commits: Commit[]
): Generator<any, BuildStatusCountsMap, any> {
  const url = urls.api.internal.commitsBuildStatuses(repositoryFullSlug);
  const body = commits.map(commit => `commits=${commit.hash}`).join('&');
  const response: Response = yield makeApiRequest(url, body);
  if (!response.ok) {
    return {};
  }

  return yield response.json();
}

export function* fetchVerificationStatuses(
  repositoryFullSlug: string,
  commits: Commit[]
): Generator<any, VerificationStatus[], any> {
  if (!FeatureGates.checkGate(StatsigFeatureKeys.gpgKeysUi)) {
    return [];
  }
  const url = urls.api.internal.verificationStatuses(repositoryFullSlug);
  const hashes = commits.map(commit => commit.hash);
  const body = JSON.stringify({ hashes });
  const response: Response = yield makeApiRequest(url, body, jsonHeaders);
  if (!response.ok) {
    return [];
  }

  return yield response.json();
}

export function createCommitStatusMapper(
  statuses: BuildStatusCountsMap,
  verificationStatuses: VerificationStatus[]
) {
  return (commit: Commit) => {
    const status = statuses[short12Hash(commit.hash)];

    // TODO: Modify endpoint to return { hash: VerificationStatus }
    const verificationStatus = verificationStatuses?.find(
      _status => _status.hash === commit.hash
    );

    return {
      ...commit,
      extra: {
        ...commit.extra,
        builds: status ? [status.commit_status] : [],
        status_counts: status ? status.status_counts : undefined,
        verification: verificationStatus,
      },
    };
  };
}
