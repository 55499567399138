import {
  ExecutionPhaseType,
  LogRangeTaskData,
  StepTaskData,
} from 'src/components/pipelines/models';

import durationToSeconds from '../utils/durationToSeconds';

export enum ExecutionPhase {
  'RUNNER_DETAILS',
  'SETUP',
  'MAIN',
  'AFTER_MAIN',
  'TEARDOWN',
}

export type CommandId =
  | 'RUNNER_DETAILS'
  | 'SETUP'
  | 'MAIN'
  | 'AFTER_MAIN'
  | 'TEARDOWN';

export type CommandsMetadata = {
  command_id: `${CommandId}_${string}`;
  byte_count: number;
  started_on: string;
  completed_on: string;
  execution_duration: string;
};

export type CommandsMetadataResponse = CommandsMetadata[];

export type LogRangesResponse = {
  execution_phases: {
    [key in ExecutionPhaseType]: StepTaskData[] | LogRangeTaskData[];
  };
  log_byte_count: number;
};

const getCommandsDuration = (
  commands: { execution_duration: number }[]
): number => {
  return (commands || [])
    .filter(command => command.execution_duration)
    .reduce((prev, current) => prev + current.execution_duration, 0);
};

export class LogRange {
  readonly byte_count: number = 0;
  readonly first_byte_position: number = 0;
  readonly last_byte_position: number = 0;

  constructor(props: Partial<LogRange> = {}) {
    Object.assign(this, props);
    Object.freeze(this);
  }
}

export class Command {
  readonly command_string: string = '';
  readonly log_range: LogRange = new LogRange();
  readonly execution_duration: any = null;
  readonly command: any = null;
  readonly index: number = -1;

  constructor(props: Partial<Command> = {}) {
    Object.assign(this, {
      ...props,
      log_range: new LogRange(props.log_range),
      index: props.index,
      execution_duration: props.execution_duration
        ? durationToSeconds(props.execution_duration)
        : props.command?.execution_duration
        ? durationToSeconds(props.command.execution_duration)
        : null,
    });
    Object.freeze(this);
  }

  toLegacyModel(): object {
    return {
      command: this.command_string,
      name: this.command_string,
      execution_duration: this.execution_duration,
      log_range: this.log_range,
      index: this.index,
    };
  }
}

export class ScriptCommand {
  readonly command: string = '';
  readonly log_range: LogRange = new LogRange();
  readonly execution_duration: any = null;
  readonly name: string = '';
  readonly execution_phase: ExecutionPhase = ExecutionPhase.MAIN;
  readonly index: number = -1;

  constructor(props: Partial<ScriptCommand> = {}) {
    Object.assign(this, {
      ...props,
      log_range: new LogRange(props.log_range),
    });
    Object.freeze(this);
  }
}

export class SetupCommand extends ScriptCommand {
  constructor(commands: any[] = [], logRange: LogRange, index?: number) {
    super({
      command: 'Build setup',
      name: 'Build setup',
      log_range: new LogRange(logRange),
      execution_duration: getCommandsDuration(commands),
      execution_phase: ExecutionPhase.SETUP,
      index,
    });
  }
}

export class AfterScriptCommand {
  readonly command: string = '';
  readonly log_range: LogRange = new LogRange();
  readonly execution_duration: any = null;
  readonly name: string = '';
  readonly execution_phase: ExecutionPhase = ExecutionPhase.AFTER_MAIN;
  readonly index: number = -1;

  constructor(props: Partial<AfterScriptCommand> = {}) {
    Object.assign(this, {
      ...props,
      log_range: new LogRange(props.log_range),
    });
    Object.freeze(this);
  }
}

export class TeardownCommand extends ScriptCommand {
  constructor(commands: any[] = [], logRange: LogRange, index?: number) {
    super({
      command: 'Build teardown',
      name: 'Build teardown',
      log_range: new LogRange(logRange),
      execution_duration: getCommandsDuration(commands),
      execution_phase: ExecutionPhase.TEARDOWN,
      index,
    });
  }
}

export class RunnerDetailsCommand extends ScriptCommand {
  constructor(commands: any[] = [], logRange: LogRange, index?: number) {
    super({
      command: 'Runner details',
      name: 'Runner',
      log_range: new LogRange(logRange),
      execution_duration: getCommandsDuration(commands),
      execution_phase: ExecutionPhase.RUNNER_DETAILS,
      index,
    });
  }
}

export type StepCommand =
  | SetupCommand
  | ScriptCommand
  | TeardownCommand
  | AfterScriptCommand
  | RunnerDetailsCommand;

export enum CommandToggleOption {
  DOWNLOADED = 'downloadedCommands',
  EXPANDED = 'expandedCommands',
}

export type CommandToggleState =
  | CommandToggleOption.DOWNLOADED
  | CommandToggleOption.EXPANDED;
