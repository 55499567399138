import { defineMessages } from 'react-intl';

export default defineMessages({
  workspaceBreadcrumbsAvatarAltText: {
    id: 'frontbucket.components.workspace.workspaceBreadcrumbsAvatarAltText',
    description:
      'Alt text for the breadcrumbs avatar that appears before workspace breadcrumbs text',
    defaultMessage: '{workspaceName} Avatar',
  },
});
