/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { gridSize, colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const PanelWrapper = styled.div`
  margin-top: -${gridSize() * 2}px;
  padding-left: ${gridSize() * 2.5}px;

  button {
    svg {
      color: ${token('color.text.information', colors.B400)};
    }
  }

  > div {
    margin: 0 auto ${gridSize()}px;
  }

  table {
    margin-top: ${gridSize()}px;
  }
`;

export const PanelHeader = styled.span`
  color: ${token('color.text.information', colors.B400)};
`;

export const LozengeContainer = styled.span`
  padding-left: ${gridSize()}px;
`;
