import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';

const PipelinesRepoVariablesPageLoadable = Loadable({
  loading: LoadingPage,
  loader: () => import('./variables'),
});

export default PipelinesRepoVariablesPageLoadable;
