import { defineMessages } from 'react-intl';

export default defineMessages({
  giveFeedbackSuccessTitle: {
    id: 'frontbucket.feedback.give.title',
    description: 'Button to dismiss successflag flag after submitting feedback',
    defaultMessage: 'Thanks!',
  },
  giveFeedbackSuccessBody: {
    id: 'frontbucket.repository.feedback.give.description',
    description: 'Description shown when feedback is successfully submitted',
    defaultMessage:
      'Your valuable feedback helps us continually improve our products.',
  },
});
