// Copied from src/sections/global/constants
const HORIZONTAL_NAVIGATION_MOBILE_HEADER_ACTIVE_WIDTH = 768;

// Note: do not use window.screen.width here because Chromedriver
// does not set that value reliably.
export default function isMobile(): boolean {
  return (
    Math.max(
      document.documentElement ? document.documentElement.clientWidth : 0,
      window.innerWidth || 0
    ) < HORIZONTAL_NAVIGATION_MOBILE_HEADER_ACTIVE_WIDTH
  );
}
