/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { put, select } from 'redux-saga/effects';

import { Repository } from 'src/components/types';
import { fetchSourceBranchDetails } from 'src/redux/pull-request/actions';
import {
  getPullRequestBranchName,
  getPullRequestSourceRepo,
} from 'src/redux/pull-request/selectors';

export default function* fetchSourceBranchDetailsSaga() {
  const sourceRepo: Repository = yield select(getPullRequestSourceRepo);
  const sourceBranchName: string = yield select(getPullRequestBranchName);
  const repoName = sourceRepo ? sourceRepo.full_name : '';
  const [owner, slug] = repoName.split('/');

  yield put(fetchSourceBranchDetails(owner, slug, sourceBranchName));
}
