import { combineReducers } from 'redux';

import createJiraIssue, { CreateJiraIssueState } from './create-jira-issue';
import dashboardJiraIssues, {
  DashboardJiraIssuesState,
} from './dashboard-jira-issues';
import jiraMigration, { JiraMigrationState } from './jira-migration';
import jiraRepoPage, { RepositoryJiraState } from './jira-repo-page';
import pullRequestJiraIssues, {
  PullRequestJiraIssuesState,
} from './pull-request-jira-issues';

export type JiraState = {
  jiraRepoPage: RepositoryJiraState;
  createJiraIssue: CreateJiraIssueState;
  pullRequestJiraIssues: PullRequestJiraIssuesState;
  dashboardJiraIssues: DashboardJiraIssuesState;
  jiraMigration: JiraMigrationState;
};

export default combineReducers({
  jiraRepoPage,
  createJiraIssue,
  pullRequestJiraIssues,
  dashboardJiraIssues,
  jiraMigration,
});
