import qs from 'qs';
import { createResource } from 'react-resource-router';

import { VARIABLES_PAGELEN } from 'src/components/pipelines/constants';
import { Variable } from 'src/components/pipelines/models';
import authRequest from 'src/utils/fetch';

import envBaseUrl from '../utils/env-base-url';

type Params = {
  workspaceSlug?: string;
};

type Query = {
  page: number;
  pagelen: number;
};

export type WorkspaceVariablesResponseBody = {
  page: number;
  pagelen: number;
  size: number;
  values: Partial<Variable>[];
};

export const getWorkspaceVariablesUrl = (params: Params, query?: Query) => {
  return `${envBaseUrl()}/workspaces/${
    params.workspaceSlug
  }/pipelines-config/variables?${qs.stringify({
    page: query?.page ?? 1,
    pagelen: query?.pagelen ?? VARIABLES_PAGELEN,
  })}`;
};

// TODO: Make this resource generic for all types of variables
export const loadPipelinesWorkspaceVariablesResource = createResource<{
  hasNextPage: boolean;
  page: number;
  total: number;
  variables: Variable[];
}>({
  type: 'pipelinesWorkspaceVariables',
  getKey: ({ match }) => getWorkspaceVariablesUrl(match.params),
  maxAge: 60000,
  getData: async ({ match }) => {
    const req = authRequest(getWorkspaceVariablesUrl(match.params));
    const resp = await fetch(req);
    if (!resp.ok) {
      throw new Error('Could not fetch workspace variables');
    } else {
      // Note: The `pagelen` here is the actual size of page returned from the backend.
      // Can be different from what we passed in the request query.
      const { page, pagelen, size, values } =
        (await resp.json()) as WorkspaceVariablesResponseBody;
      return {
        hasNextPage: size > pagelen * page,
        page,
        total: size,
        variables: values?.map(item => new Variable(item)) ?? [],
      };
    }
  },
});
