import { initialState } from 'src/redux/pull-request/reducer';
import { MergeCheck } from 'src/types';

export const mergeCheckPayloadTransformer = (payload: any) => {
  const {
    restrictions,
    can_merge: isMergeable,
    is_mergable_by_current_user:
      canCurrentUserMerge = initialState.canCurrentUserMerge,
    is_missing_commits: isMissingCommits = initialState.isMissingCommits,
    retriction_source: mergeChecksSource,
  } = payload;
  return {
    mergeChecks: Object.values(restrictions).filter(
      (mergeCheck: MergeCheck) => !!mergeCheck.label
    ),
    isMergeable,
    canCurrentUserMerge,
    isMissingCommits,
    mergeChecksSource,
  };
};
