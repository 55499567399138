import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _typeof from "@babel/runtime/helpers/typeof";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/** @jsx jsx */
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { uid } from 'react-uid';
import invariant from 'tiny-invariant';
import { FieldId } from './field-id-context';
import { FormContext, IsDisabledContext } from './form';
import { Label } from './label';
import RequiredAsterisk from './required-asterisk';
var fieldWrapperStyles = css({
  marginTop: "var(--ds-space-100, 8px)"
});
function isEvent(event) {
  return Boolean(event && event.target);
}
function isFunction(x) {
  return typeof x === 'function';
}
function usePreviousRef(current) {
  var ref = useRef(current);

  // will be updated on the next render
  useEffect(function () {
    ref.current = current;
  });

  // return the existing current (pre render)
  return ref;
}
function isShallowEqual(previousValue, currentValue) {
  if (previousValue === currentValue) {
    return true;
  }

  // not checking functions
  if (typeof previousValue === 'function' && typeof currentValue === 'function') {
    return true;
  }
  if (Array.isArray(previousValue) && Array.isArray(currentValue)) {
    return JSON.stringify(previousValue) === JSON.stringify(currentValue);
  }
  if (_typeof(previousValue) === 'object' && _typeof(currentValue) === 'object') {
    return JSON.stringify(previousValue) === JSON.stringify(currentValue);
  }
  return false;
}
export default function Field(props) {
  var _getCurrentValue;
  var _useContext = useContext(FormContext),
    registerField = _useContext.registerField,
    getCurrentValue = _useContext.getCurrentValue;
  var isDisabled = useContext(IsDisabledContext) || props.isDisabled || false;
  var defaultValue = isFunction(props.defaultValue) ? props.defaultValue() : props.defaultValue;
  var latestPropsRef = usePreviousRef(props);

  /**
   * HACK: defaultValue can potentially be an array or object which cannot be
   * passed directly into a `useEffect` dependency array, since it will trigger
   * the hook every time.
   */
  var isDefaultValueChanged = !isShallowEqual(latestPropsRef.current.defaultValue, props.defaultValue);
  var _useState = useState({
      fieldProps: {
        onChange: function onChange() {},
        onBlur: function onBlur() {},
        onFocus: function onFocus() {},
        /* Previously, defaultValue was being set as undefined in Field.defaultProps, which
         * effectively made it an optional prop to external consumers of Field. However the
         * prop types defined defaultValue as required, so inside the component it was not
         * valid for defaultValue to be undefined. We need to suppress the error
         * after changing defaultValue to explictly be an optional prop.
         * If default value has changed we are using new default value.
         * Otherwise we need to check if we already have value for this field
         * (because we are using changing key prop to re-run field level validation, and that
         * cause the component re-mounting) to not override the actual value with the default value.
         */
        // @ts-ignore
        value: isDefaultValueChanged ? defaultValue : (_getCurrentValue = getCurrentValue(props.name)) !== null && _getCurrentValue !== void 0 ? _getCurrentValue : defaultValue
      },
      error: undefined,
      valid: false,
      meta: {
        dirty: false,
        dirtySinceLastSubmit: false,
        touched: false,
        valid: false,
        validating: false,
        submitting: false,
        submitFailed: false,
        error: undefined,
        submitError: undefined
      }
    }),
    _useState2 = _slicedToArray(_useState, 2),
    state = _useState2[0],
    setState = _useState2[1];
  var latestStateRef = usePreviousRef(state);
  useEffect(function () {
    if (typeof process !== 'undefined' && process.env.NODE_ENV !== 'production' && !process.env.CI) {
      invariant(latestPropsRef.current.name, '@atlaskit/form: Field components have a required name prop');
    }
    function fieldStateToMeta() {
      var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return {
        dirty: value.dirty || false,
        dirtySinceLastSubmit: value.dirtySinceLastSubmit || false,
        touched: value.touched || false,
        valid: value.valid || false,
        submitting: value.submitting || false,
        submitFailed: value.submitFailed || false,
        error: value.error,
        submitError: value.submitError,
        validating: !!value.validating
      };
    }
    var unregister = registerField(latestPropsRef.current.name,
    /**
     * Similar as for setting initial state value.
     * Additionally we are checking if the default value is a function,
     * it is used in checkbox fields, where fields with same name and
     * defaultIsChecked should create array of values. In this situation we can't
     * override the default value on re-registering, but also we don't need to change
     * the key prop to re-run validation.
     */
    // @ts-ignore
    isDefaultValueChanged ||
    // @ts-ignore
    isFunction(latestPropsRef.current.defaultValue) ? latestPropsRef.current.defaultValue : latestStateRef.current.fieldProps.value, function (fieldState) {
      /**
       * Do not update dirtySinceLastSubmit until submission has finished.
       */
      var modifiedDirtySinceLastSubmit = fieldState.submitting ? latestStateRef.current.meta.dirtySinceLastSubmit : fieldState.dirtySinceLastSubmit;

      /**
       * Do not update submitFailed until submission has finished.
       */
      var modifiedSubmitFailed = fieldState.submitting ? latestStateRef.current.meta.submitFailed : fieldState.submitFailed;

      /**
       * Do not use submitError if the value has changed.
       */
      var modifiedSubmitError = modifiedDirtySinceLastSubmit && latestPropsRef.current.validate ? undefined : fieldState.submitError;
      var modifiedError = modifiedSubmitError || (fieldState.touched || fieldState.dirty) && fieldState.error;

      /**
       * If there has been a submit error, then use logic in modifiedError to determine validity,
       * so we can determine when there is a submit error which we do not want to display
       * because the value has been changed.
       */
      var modifiedValid = modifiedSubmitFailed ? modifiedError === undefined : fieldState.valid;
      function getTransform(eventOrValue, currentValue) {
        if (latestPropsRef.current.transform) {
          return latestPropsRef.current.transform(eventOrValue, currentValue);
        }
        if (isEvent(eventOrValue)) {
          var currentTarget = eventOrValue.currentTarget;
          if (currentTarget.type === 'checkbox') {
            if (currentTarget.checked) {
              return currentTarget.value || true;
            }
            return currentTarget.value ? undefined : false;
          } else if (currentTarget) {
            return currentTarget.value;
          }
        } else {
          return eventOrValue;
        }
      }
      setState({
        fieldProps: {
          onChange: function onChange(e) {
            fieldState.change(getTransform(e, fieldState.value));
          },
          onBlur: fieldState.blur,
          onFocus: fieldState.focus,
          value: fieldState.value
        },
        error: modifiedError || undefined,
        /**
         * The following parameters are optionally typed in final-form to indicate that not all parameters need
         * to be subscribed to. We cast them as booleans (using || false), since this is what they are semantically.
         */
        valid: modifiedValid || false,
        meta: fieldStateToMeta(fieldState)
      });
    }, {
      dirty: true,
      dirtySinceLastSubmit: true,
      touched: true,
      valid: true,
      submitting: true,
      submitFailed: true,
      value: true,
      error: true,
      submitError: true,
      validating: true
    }, {
      getValidator: function getValidator() {
        return function validate(value, formState, fieldState) {
          var supplied = latestPropsRef.current.validate;
          if (supplied && fieldState) {
            return supplied(value, formState, fieldStateToMeta(fieldState));
          }
        };
      }
    });
    return unregister;
  }, [latestPropsRef, latestStateRef, registerField, props.name, isDefaultValueChanged]);
  var fieldId = useMemo(
  // eslint-disable-next-line @repo/internal/react/disallow-unstable-values
  function () {
    return props.id ? props.id : "".concat(props.name, "-").concat(uid({
      id: props.name
    }));
  }, [props.id, props.name]);
  var getDescribedBy = function getDescribedBy() {
    var value = '';
    if (state.error) {
      value += "".concat(fieldId, "-error ");
    }
    if (state.valid) {
      value += "".concat(fieldId, "-valid ");
    }
    return "".concat(value).concat(fieldId, "-helper");
  };
  var extendedFieldProps = _objectSpread(_objectSpread({}, state.fieldProps), {}, {
    name: props.name,
    isDisabled: isDisabled,
    isInvalid: Boolean(state.error),
    isRequired: Boolean(props.isRequired),
    'aria-invalid': state.error ? 'true' : 'false',
    'aria-describedby': getDescribedBy(),
    'aria-labelledby': "".concat(fieldId, "-label"),
    id: fieldId
  });
  return jsx("div", {
    css: fieldWrapperStyles,
    "data-testid": props.testId
  }, props.label && jsx(Label, {
    htmlFor: fieldId,
    id: "".concat(fieldId, "-label"),
    testId: props.testId && "".concat(props.testId, "--label")
  }, props.label, props.isRequired && jsx(RequiredAsterisk, null), props.elementAfterLabel), jsx(FieldId.Provider, {
    value: fieldId
  }, props.children({
    fieldProps: extendedFieldProps,
    error: state.error,
    valid: state.valid,
    meta: state.meta
  })));
}