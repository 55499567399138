/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { Either } from 'funfix-core';
import { call, put, select } from 'redux-saga/effects';

import { Watch } from 'src/components/types';
import { HttpError } from 'src/components/types/src/http-methods';
import { watchLoading, watchLoadingEnd } from 'src/redux/pull-request/actions';
import { getCurrentPullRequestUrlPieces } from 'src/redux/pull-request/selectors';

import { WatchApiType } from '../api';

export function* watchSaga(f: WatchApiType, passedArgs?: { [k: string]: any }) {
  try {
    // In some instances, we cannot count on `getCurrentPullRequestUrlPieces`
    // being defined in the store yet. For example, when the PR is first
    // mounted and `ENTERED_CODE_REVIEW` + `codeReviewDataSaga` is called, the
    // current PR may not be available yet. To make sure we have those values,
    // we can check if the action explicitly passed them along as an argument.
    //  If they are not defined, we can default to looking them up in the store like before.
    const hasUrlArgs =
      passedArgs && passedArgs.owner && passedArgs.slug && passedArgs.id;

    const { owner, slug, id } = hasUrlArgs
      ? passedArgs
      : yield select(getCurrentPullRequestUrlPieces);

    if (owner && slug && id) {
      yield put(watchLoading());

      // Watch api returns either an error or a watch result.
      const result: Either<HttpError, Watch> = yield call(f, owner, slug, id);
      // Parse the result or in case of errors ignore them and default to not watching.
      const watch: boolean = result.map(r => r.watching).getOrElse(false);

      yield put(watchLoadingEnd(watch));
    }
  } catch (e) {
    yield put(watchLoadingEnd(false));
  }
}
