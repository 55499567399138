import type { Route } from 'react-resource-router';

import getUFORouteName from '@atlassian/react-ufo/route-name';

export function getUFOName(route: Route): string {
  const ufoName = getUFORouteName(route).toLowerCase();

  if (route.name === 'REPOSITORY_CODE_REVIEW') {
    const pathnameParts = window.location.pathname
      .split('/')
      .filter(part => part.length > 0);
    const selectedTab = pathnameParts[pathnameParts.length - 1];

    if (selectedTab === 'diff' || selectedTab === 'commits') {
      return `${ufoName}-${selectedTab}`;
    }

    return `${ufoName}-overview`;
  }

  return ufoName;
}
