import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import {
  LoadStatusPageIncidentsError,
  LoadStatusPageIncidentsSuccess,
} from 'src/redux/global/actions/statuspage';
import { getIncidents } from 'src/redux/global/api/statuspage-api';

/* eslint @typescript-eslint/ban-types: "warn" */
const StatuspagePoller: React.FC = () => {
  const dispatch = useDispatch();
  const [polling, setPolling] = useState(true);

  useEffect(() => {
    const pollStatuspageOnInterval = async () => {
      if (!document.hidden && polling) {
        try {
          const resp = await getIncidents();
          dispatch(LoadStatusPageIncidentsSuccess(resp));
        } catch (error) {
          dispatch(LoadStatusPageIncidentsError);
          setPolling(false);
        }
      }
    };
    pollStatuspageOnInterval();
    const interval = setInterval(pollStatuspageOnInterval, 60000);
    return () => clearInterval(interval);
  }, [dispatch, polling]);

  return null;
};

export default StatuspagePoller;
