/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { select, all, takeLatest, call, put } from 'redux-saga/effects';

import fetchWithAccessToken from 'src/redux/pull-request/sagas/utils/fetch-with-access-token';
import { getTargetUserKey } from 'src/selectors/user-selectors';
import { settings } from 'src/settings';
import authRequest from 'src/utils/fetch';
import fetchAccessToken from 'src/utils/fetch-access-token';

import { capturePipelinesExceptionWithTags } from '../../utils/sentry';
import {
  requestAllowance,
  VERIFY_PIPELINES_INSTALLATION,
} from '../actions/pipelines';

export const getPipelinesConfigUrl = (accountUuid: string) => {
  return `/!api/internal/accounts/${accountUuid}/pipelines_config`;
};

export const getInstallUrl = (accountUuid: string) => {
  return `${
    settings().API_CANON_URL
  }/internal/site/addons/account/${accountUuid}/install/`;
};

export function* pipelinesInstallerSaga(): any {
  const targetUserKey = yield select(getTargetUserKey);
  const url = getPipelinesConfigUrl(targetUserKey);
  const installUrl = getInstallUrl(targetUserKey);
  try {
    const res: Response = yield call(
      fetch,
      authRequest(url, { method: 'HEAD' })
    );
    if (!res.ok && res.status === 404) {
      const accessToken = yield call(
        fetchAccessToken,
        `site:pipelines-ui-installer`
      );
      yield call(fetchWithAccessToken, installUrl, accessToken, false, 'POST');
      yield put(requestAllowance(targetUserKey));
    }
  } catch (e) {
    if (e instanceof Error) {
      capturePipelinesExceptionWithTags(e, {
        segment: VERIFY_PIPELINES_INSTALLATION,
      });
    }
  }
}

export default function* () {
  yield all([
    takeLatest(VERIFY_PIPELINES_INSTALLATION, pipelinesInstallerSaga),
  ]);
}
