import React, { useContext, useEffect, useState } from 'react';

import { ConfigClient, ConfigCollection } from '@atlaskit/web-config-client';

import { StatsigKeys } from 'src/config/feature-flag-keys';
import { BbEnv, getBbEnv } from 'src/utils/bb-env';

import { statsigEnvMap, useFeatureIdsAndAttributes } from './feature-provider';

export const baseUrlMap: { [bbEnv in BbEnv]: string } = {
  [BbEnv.Development]: 'https://api.dev.atlassian.com/flags',
  [BbEnv.Test]: 'https://api.atlassian.com/flags',
  [BbEnv.Staging]: 'https://api.stg.atlassian.com/flags',
  [BbEnv.Integration]: 'https://api.stg.atlassian.com/flags',
  [BbEnv.Production]: 'https://api.atlassian.com/flags',
};

export const statsigOptions = (function () {
  const bbEnv = getBbEnv() || BbEnv.Development;
  const statsigEnv = statsigEnvMap[bbEnv];
  const baseUrl = baseUrlMap[bbEnv];
  const apiKey = StatsigKeys[statsigEnv];

  return { apiKey, statsigEnv, targetApp: 'bitbucket-cloud_web', baseUrl };
})();

export const StatsigConfigContext = React.createContext<{
  isLoading: boolean;
  configs: ConfigCollection | null;
}>({
  isLoading: true,
  configs: null,
});

export const StatsigConfigProvider = (props: any) => {
  const [statsigConfigs, setStatsigConfigs] = useState<ConfigCollection | null>(
    null
  );
  const { identifiers, customAttributes } = useFeatureIdsAndAttributes();
  const { apiKey, statsigEnv, baseUrl, targetApp } = statsigOptions;

  useEffect(() => {
    ConfigClient.fetch({
      ffsBaseUrl: baseUrl,
      ffsApiKey: apiKey,
      context: {
        namespace: targetApp,
        identifiers,
        metadata: {
          // @ts-ignore
          environment: statsigEnv,
          ...customAttributes,
        },
      },
    }).then(config => {
      setStatsigConfigs(config);
    });
  }, [identifiers, customAttributes, apiKey, statsigEnv, baseUrl, targetApp]);

  return (
    <StatsigConfigContext.Provider
      value={{ isLoading: !statsigConfigs, configs: statsigConfigs }}
    >
      {props.children}
    </StatsigConfigContext.Provider>
  );
};

// We have created custom hooks to access boolean, string, and number config values
// Similar hooks for StringList and NumberList config values can be created if needed
// We opted against creating one generic hook since it is less trivial to check the
// type for string and number arrays

// Since config values are initially fetched asynchronously, these hooks may return the fallback value
// on initial render
export const useConfigBoolean = (flag: string, fallback: boolean): boolean => {
  const { isLoading, configs } = useContext(StatsigConfigContext);
  const [value, setValue] = useState(fallback);

  useEffect(() => {
    if (!isLoading) {
      const result = configs!.getBoolean(flag);
      if ('error' in result) {
        // eslint-disable-next-line no-console
        console.error('Failed to read config flag', result.error);
      } else {
        setValue(result.value);
      }
    }
  }, [isLoading, configs, flag]);

  return value;
};

export const useConfigString = (flag: string, fallback: string): string => {
  const { isLoading, configs } = useContext(StatsigConfigContext);
  const [value, setValue] = useState(fallback);

  useEffect(() => {
    if (!isLoading) {
      const result = configs!.getString(flag);
      if ('error' in result) {
        // eslint-disable-next-line no-console
        console.error('Failed to read config flag', result.error);
      } else {
        setValue(result.value);
      }
    }
  }, [isLoading, configs, flag]);

  return value;
};

export const useConfigNumber = (flag: string, fallback: number): number => {
  const { isLoading, configs } = useContext(StatsigConfigContext);
  const [value, setValue] = useState(fallback);

  useEffect(() => {
    if (!isLoading) {
      const result = configs!.getNumber(flag);
      if ('error' in result) {
        // eslint-disable-next-line no-console
        console.error('Failed to read config flag', result.error);
      } else {
        setValue(result.value);
      }
    }
  }, [isLoading, configs, flag]);

  return value;
};
