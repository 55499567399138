import { createContext, useContext } from 'react';

import { Pipeline } from '../models';

export type PipelineRunHistoryContextType = {
  isLoading: boolean;
  pipelineRuns: Pipeline[];
  selectedRun: Pipeline | undefined;
  isHistoryRunSelected: boolean;
  onSelectRun: (run: Pipeline) => void;
  resetPipelineRuns: () => void;
};

export const PipelineRunHistoryContext =
  createContext<PipelineRunHistoryContextType>({
    isLoading: true,
    pipelineRuns: [],
    selectedRun: undefined,
    isHistoryRunSelected: false,
    resetPipelineRuns: () => {
      /**/
    },
    onSelectRun: () => {
      /**/
    },
  });

export const usePipelineRunHistoryContext =
  (): PipelineRunHistoryContextType => {
    return useContext(PipelineRunHistoryContext);
  };
