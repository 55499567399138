export type FlagType = 'info' | 'success' | 'warning' | 'error';

export type CloseFlagOption = 'manual' | 'auto' | 'never';

export class FlagAction {

    /**
     * The key/identifier of this action.
     */
    actionKey: string;

    /**
     * This is the short textual description of the action.
     */
    actionText: string;

    /**
     * This is the function that is called to execute the action.
     */
    executeAction: Function;

}

/**
 * This interface describes the options controlling the creation of flags.
 */
export class FlagCreationOptions {

    /**
     * This is an identifier for the flag.
     */
    id: string;

    /**
     * The title text of the flag.
     */
    title: string;

    /**
     * The body text of the flag.
     */
    body: string;

    /**
     * Sets the type of the message. Valid options are "info", "success", "warning" and "error".
     */
    type: FlagType;

    /**
     * The closing behaviour that this flag has. Valid options are "manual", "auto" and "never".
     */
    close: CloseFlagOption;

    /**
     * The onClose function must be called whe a user closes the flag.
     */
    onClose: Function;

    /**
     * The actions to add to the flag.
     */
    actions: FlagAction[];

}
