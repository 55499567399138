/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { put, select, call } from 'redux-saga/effects';

import { PullRequest } from 'src/components/types';
import { UPDATE_PARTICIPANTS } from 'src/redux/pull-request/actions';
import {
  getCurrentPullRequest,
  getCurrentPullRequestUrlPieces,
} from 'src/redux/pull-request/selectors';
import urls from 'src/redux/pull-request/urls';
import authRequest from 'src/utils/fetch';

import { updatePullRequest } from '../actions/update-pull-request';

function* updateParticipantsSaga() {
  const { owner, slug, id } = yield select(getCurrentPullRequestUrlPieces);
  const url = urls.api.v20.participants(owner, slug, id);
  const authUrl = authRequest(url);

  try {
    const response: Response = yield call(fetch, authUrl);
    // @ts-ignore
    const json = yield response.json();

    if (!response.ok) {
      throw Error(json.error.message);
    }

    const currentPullRequest: PullRequest = yield select(getCurrentPullRequest);
    const updatedPullRequest = {
      ...currentPullRequest,
      participants: json.participants,
    };

    yield put(
      updatePullRequest({
        type: UPDATE_PARTICIPANTS.SUCCESS,
        pullRequest: updatedPullRequest,
      })
    );
  } catch (e) {
    yield put({
      type: UPDATE_PARTICIPANTS.ERROR,
      payload: e.message,
    });
  }
}

export default updateParticipantsSaga;
