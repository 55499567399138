import qs from 'qs';

export default {
  api: {
    internal: {
      billingCountries: (countryIsoCode?: string) => {
        const baseUrl = `/!api/internal/billing/country/`;
        if (countryIsoCode) {
          const params = qs.stringify({ country: countryIsoCode });
          return `${baseUrl}?${params}`;
        }
        return baseUrl;
      },
      billingCountriesTax: (countryIsoCode: string) =>
        `/!api/internal/billing/country/${countryIsoCode}/tax`,
      checkHamsTask: (taskId: string) => `/!api/internal/hams/task/${taskId}`,
      navigation: (): string => {
        return `/!api/internal/menu/account`;
      },
      stripeKey: (countryCode: string, currency: string) =>
        `/!api/internal/stripe/stripe-key/${countryCode}/${currency}`,
      // TODO: currently gateway (STRIPE_US) needs to be hard-coded because the bb endpoint doesn't support
      // gateway as a query parameter and the HAMSCreditCardFormState doesn't support gateway as a part of
      // the route
      paymentMethodToken: () =>
        `/!api/internal/stripe/stripe-payment-method-token/STRIPE_US`,
      workspacesWithPlans: (pagelen?: number) =>
        `/!api/internal/workspace-admin${pagelen ? `?pagelen=${pagelen}` : ''}`,
    },
    plan: (uuid: string, planSlug: string) =>
      `/account/user/${uuid}/upgrade-new/${planSlug}`,
  },
  ui: {
    createWorkspace: '/workspace/create/',
    updatePaymentMethod: (uuid: string) =>
      `/account/user/${uuid}/payment-response-new`,
  },
};
