import { Pipeline, User } from 'src/components/pipelines/models';
import { LoadingStatus } from 'src/constants/loading-status';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import { PIPELINE_PAGE_SIZE } from '../../constants';
import { parseFilterPathParam } from '../../utils/pipeline-filter-query';
import {
  REMOVE_PIPELINES_DATA,
  REQUEST_PIPELINES,
  REQUEST_UPDATE_PIPELINE,
  SET_PIPELINES,
  SET_PIPELINES_LOADING,
  SET_PIPELINE_CREATORS,
} from '../actions/pipelines';

export type PipelinesState = {
  list: Pipeline[];
  creators: User[];
  fetchedStatus: LoadingStatus;
  size: number;
  page: number;
  fetchCount: number;
};

export const initialState: PipelinesState = {
  list: [],
  creators: [],
  fetchedStatus: LoadingStatus.Before,
  size: -1,
  page: 1,
  fetchCount: 0,
};

type CreatorPipeline = {
  creator: {
    uuid?: string;
    display_name?: string;
    links?: { avatar: { href: string } };
  };
  trigger: { type?: string };
};

const createUser = (pipeline: CreatorPipeline) => {
  let user = pipeline.creator;
  if (
    pipeline.creator?.uuid &&
    pipeline.trigger?.type === 'pipeline_trigger_schedule'
  ) {
    user = {
      uuid: pipeline.creator.uuid,
      display_name: 'Scheduled',
      links: {
        avatar: {
          href: require('../../assets/calendar-icon.png'),
        },
      },
    };
  } else if (
    pipeline.creator?.uuid &&
    !pipeline.creator?.display_name &&
    pipeline.trigger?.type === 'pipeline_trigger_push'
  ) {
    user = {
      uuid: pipeline.creator.uuid,
      display_name: 'Push',
    };
  } else if (
    pipeline.creator?.uuid &&
    !pipeline.creator?.display_name &&
    pipeline.trigger?.type === 'pipeline_trigger_manual'
  ) {
    user = {
      uuid: pipeline.creator.uuid,
      display_name: 'Manual',
    };
  }
  return new User(user);
};

const reducePipelines = (
  state: PipelinesState,
  action: Action<{
    values: any[];
    size: number;
    page: number;
    fetchCount?: number;
  }>
) => {
  // Prevents race conditions with requests overwriting requests that were made later
  if (
    action.payload?.fetchCount &&
    action.payload.fetchCount !== state.fetchCount
  ) {
    return state;
  }
  if (!action.payload?.values) {
    return state;
  }
  const { values, page } = action.payload;

  const list = values?.reduce(
    (reducer, pipelineData, index) => {
      reducer[index + PIPELINE_PAGE_SIZE * (page - 1)] = new Pipeline(
        pipelineData
      );
      return reducer;
    },
    [...state.list]
  );

  return {
    ...state,
    size: action.payload?.size,
    fetchedStatus: LoadingStatus.Success,
    list,
    page,
  };
};

const reducePipelineCreators = (
  state: PipelinesState,
  action: Action<{ values: any[] }>
) => {
  if (!action.payload?.values) {
    return state;
  }
  const creators: User[] = action.payload.values?.map(createUser);
  return {
    ...state,
    creators: creators.filter(
      (user, index) =>
        creators?.findIndex((u: any) => u.uuid === user?.uuid) === index
    ),
  };
};

export default createReducer(initialState, {
  [REMOVE_PIPELINES_DATA](state: PipelinesState) {
    return {
      ...initialState,
      creators: state.creators,
      fetchCount: state.fetchCount,
    };
  },
  [REQUEST_PIPELINES.REQUEST](state: PipelinesState, action: any) {
    return {
      ...state,
      page: action?.meta?.queryParams?.page || 1,
      fetchedStatus: LoadingStatus.Fetching,
    };
  },
  [SET_PIPELINES_LOADING](state: PipelinesState) {
    return {
      ...state,
      fetchedStatus: LoadingStatus.Fetching,
      fetchCount: state.fetchCount + 1,
    };
  },
  [SET_PIPELINES]: reducePipelines,
  [REQUEST_PIPELINES.SUCCESS]: reducePipelines,
  [REQUEST_UPDATE_PIPELINE.SUCCESS](
    state: PipelinesState,
    action: Action<{
      selectedBranch: string;
      data: any;
    }>
  ) {
    if (!action?.payload?.data.uuid) {
      return state;
    }
    const { data, selectedBranch } = action.payload;
    const pipeline = new Pipeline(data);
    if (selectedBranch && pipeline.sourceBranch !== selectedBranch) {
      return state;
    }

    const index = state.list.findIndex(i => i?.uuid === pipeline?.uuid);
    const hasFilters = Object.values(
      parseFilterPathParam(location.pathname)
    ).some(f => !!f);
    let newList = state.list;
    if (index > -1) {
      newList = [...state.list];
      newList[index] = pipeline;
    } else if (!hasFilters && state.page === 1) {
      newList = [pipeline, ...state.list];
    }

    return newList === state.list ? state : { ...state, list: newList };
  },
  [SET_PIPELINE_CREATORS]: reducePipelineCreators,
});
