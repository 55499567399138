import { PluginTypes } from './types';
export var createGetContainersPlugin = function createGetContainersPlugin(fetch) {
  return [PluginTypes.GetContainers, fetch];
};
export var createGetUsersPlugin = function createGetUsersPlugin(fetch) {
  return [PluginTypes.GetUsers, fetch];
};
export var createGetSuggestedSiteNamesPlugin = function createGetSuggestedSiteNamesPlugin(fetch) {
  return [PluginTypes.GetSuggestedSiteNames, fetch];
};
export var createGetAvailableSitesPlugin = function createGetAvailableSitesPlugin(fetch) {
  return [PluginTypes.GetAvailableSites, fetch];
};
export { PluginTypes, ContainerTypes, UserIdTypes } from './types';