import { Account } from 'src/components/pipelines/models';
import { LoadGlobal } from 'src/redux/global/actions';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import { getAddonKey } from '../../utils/env-base-url';
import { SET_ACCOUNT_META } from '../actions/common';

export default createReducer(new Account({ addonKey: getAddonKey() }), {
  [LoadGlobal.SUCCESS](state: Account, action: Action<any>) {
    const currentUser =
      action.payload?.entities?.users?.[action.payload?.result?.currentUser];
    return new Account({
      ...state?.toJS?.(),
      userHas2FaEnabled: currentUser?.has_2fa_enabled,
    });
  },
  [SET_ACCOUNT_META](state: Account, action: Action<Partial<Account>>) {
    return new Account({
      ...state?.toJS?.(),
      ...action.payload,
    });
  },
});
