import { ModelBase } from './ModelBase';

export class Allowance extends ModelBase {
  readonly startDate: string = '';
  readonly endDate: string = '';
  readonly totalUsage: number = -1;
  readonly quota: number = -1;
  readonly percentageUsed: number = -1;
  readonly hasFetched: boolean = false;
  readonly hasPermissionToView: boolean = false;
  readonly isTrusted: boolean = true;
  readonly isErrored: boolean = false;

  constructor(props: Partial<Allowance> = {}) {
    super(props);
    Object.assign(this, props);
    Object.freeze(this);
  }
}
