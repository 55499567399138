import React from 'react';

import { withRouter as withResourceRouter } from 'react-resource-router';

import { RouteComponentProps } from '../routes/types';

export default <P extends Record<string, any>>(
  Component: React.ComponentType<P & RouteComponentProps>
) => {
  const WithResourceRouter = withResourceRouter(Component);

  function withRouter(props: Omit<P, keyof RouteComponentProps>) {
    // @ts-ignore The types from `withRouter` from `react-resource-router` aren't as specific as they could be
    return <WithResourceRouter {...props} />;
  }

  return withRouter;
};
