import { Selector, createSelector } from 'reselect';

import { LoadingStatus } from 'src/constants/loading-status';
import { JiraState } from 'src/redux/jira/reducers';
import { getJiraSlice } from 'src/selectors/state-slicing-selectors';
import { BucketState } from 'src/types/state';

import { AppInstalledStatus } from '../constants';
import {
  AvailableSite,
  IssueMigrationToJiraProgress,
  IssueMigrationToJiraStarted,
  IssueMigrationToJiraLogs,
} from '../types';

export const getActiveStepIndex: Selector<BucketState, number> = createSelector(
  getJiraSlice,
  (state: JiraState) => state.jiraMigration.activeStepIndex
);

export const getAvailableSites: Selector<BucketState, AvailableSite[]> =
  createSelector(
    getJiraSlice,
    (state: JiraState) => state.jiraMigration.availableSites
  );

export const getAvailableSitesFetchedStatus: Selector<
  BucketState,
  LoadingStatus
> = createSelector(
  getJiraSlice,
  (state: JiraState) => state.jiraMigration.availableSitesFetchedStatus
);

export const getSelectedSiteCloudId: Selector<BucketState, string | undefined> =
  createSelector(
    getJiraSlice,
    (state: JiraState) => state.jiraMigration.selectedSiteCloudId
  );

export const getJiraProjectKey: Selector<BucketState, string | undefined> =
  createSelector(
    getJiraSlice,
    (state: JiraState) => state.jiraMigration.projectKey
  );

export const getJiraProjectName: Selector<BucketState, string | undefined> =
  createSelector(
    getJiraSlice,
    (state: JiraState) => state.jiraMigration.projectName
  );

export const getJiraMigrationProgress: Selector<
  BucketState,
  IssueMigrationToJiraProgress | null | undefined
> = createSelector(
  getJiraSlice,
  (state: JiraState) => state.jiraMigration.migrationProgress
);

export const getJiraMigrationStarted: Selector<
  BucketState,
  IssueMigrationToJiraStarted | null | undefined
> = createSelector(
  getJiraSlice,
  (state: JiraState) => state.jiraMigration.migrationStarted
);

export const getJiraMigrationLogs: Selector<
  BucketState,
  IssueMigrationToJiraLogs | null | undefined
> = createSelector(
  getJiraSlice,
  (state: JiraState) => state.jiraMigration.migrationLogs
);

export const getAppInstalledStatus: Selector<BucketState, AppInstalledStatus> =
  createSelector(
    getJiraSlice,
    (state: JiraState) => state.jiraMigration.appInstalledStatus
  );
