/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { gridSize } from '@atlaskit/theme';

import { PageSection } from 'src/sections/global/components/page.style';

export const SettingsPageLoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60%;
`;

export const SettingsPageIframe = styled.iframe<{
  isLoading: boolean;
  topOffset?: number;
}>`
  display: ${({ isLoading }) => (isLoading ? 'none' : 'block')};
  border: none;
  width: 100%;
  height: 100%;
`;

export const SettingsPageContainer = styled.div<{
  topOffset?: number;
}>`
  display: flex;
  justify-content: center;
  width: 100%;
  height: ${({ topOffset }) =>
    topOffset ? `calc(100vh - ${topOffset}px)` : '100%'};
`;

export const SettingsPageSection = styled(PageSection)`
  display: flex;
  flex: auto;
  height: 100%;
`;

// Using a custom GridColumn because atlaskit adds extra child div
export const SettingsPageWrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  margin: 0 ${gridSize() * 2.5}px;
  padding: 0 ${gridSize() * 2.5}px;
  word-wrap: break-word;
  margin-bottom: ${gridSize() * 8}px;
`;
