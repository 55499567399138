import { WorkspacePermission } from 'src/redux/workspaces/constants';

export async function hasWorkspaceAdminStatus(
  workspaceSlug: string
): Promise<boolean | undefined> {
  try {
    const resp = await fetch(
      `/!api/2.0/user/permissions/workspaces?fields=values.permission&q=workspace.slug=${encodeURIComponent(
        `"${workspaceSlug}"`
      )}`
    );
    if (resp.ok) {
      const { values } = (await resp.json()) as {
        values: { permission: WorkspacePermission }[];
      };
      return values[0]?.permission === WorkspacePermission.Owner;
    }
  } catch (e) {
    // no need to log error, just return undefined as workspace admin status unknown
    return undefined;
  }
  // by default return undefined as workspace admin status unknown
  return undefined;
}
