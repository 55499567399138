/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { gridSize, colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const GlobalErrorPageWrapper = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: ${token('color.text.subtle', colors.N500)};
`;

export const Center = styled.div`
  text-align: center;
  width: ${gridSize() * 40}px;
`;

export const ErrorImage = styled.div`
  width: ${gridSize() * 10}px;
  margin: auto;
`;
