import { Account } from 'src/components/pipelines/models';

export const prefixed = (action: string) => `pipelines/${action}`;

export const SET_ACCOUNT_META = 'SET_ACCOUNT_META';

export function setAccountMeta(payload: Partial<Account>) {
  return {
    type: SET_ACCOUNT_META,
    payload,
  };
}
