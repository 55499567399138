import { createAsyncAction } from 'src/redux/actions';

import { prefixed } from './common';

export const DELETE_BRANCH_DIALOG = {
  OPEN: prefixed('OPEN_DELETE_BRANCH_DIALOG'),
  CLOSE: prefixed('CLOSE_DELETE_BRANCH_DIALOG'),
};

export const openDeleteBranchDialog = (branchName: string) => ({
  type: DELETE_BRANCH_DIALOG.OPEN,
  payload: {
    branchName,
  },
});

export const closeDeleteBranchDialog = () => ({
  type: DELETE_BRANCH_DIALOG.CLOSE,
});

export const DELETE_BRANCH = createAsyncAction(prefixed('DELETE_BRANCH'));
export const deleteBranch = () => ({
  type: DELETE_BRANCH.REQUEST,
});

export const DELETE_BRANCH_PAGE = createAsyncAction(
  prefixed('DELETE_BRANCH_PAGE')
);
export const deleteBranchPage = () => ({
  type: DELETE_BRANCH_PAGE.REQUEST,
});
