import {
  isValid,
  isBefore,
  differenceInSeconds,
  formatDistanceStrict,
  subYears,
  subMonths,
  subDays,
  subHours,
  subMinutes,
  subSeconds,
} from 'date-fns';

import { DateLike, toDate } from './date';

/*
  Ref: the "short" format style
  moment.updateLocale('en', {
    relativeTime: {
      future: '%s',
      past: '%s',
      s: 'just now',
      ss: '%d seconds ago',
      m: 'a minute ago',
      mm: '%d minutes ago',
      h: 'an hour ago',
      hh: '%d hours ago',
      d: 'a day ago',
      dd: '%d days ago',
      M: 'a month ago',
      MM: '%d months ago',
      y: 'a year ago',
      yy: '%d years ago',
    },
  });
*/

export type DistanceFormat = 'default' | 'short';

/**
 * Format distance between two Date objects with customised `moment` style.
 *
 * Supported distance formats:
 * - `default`: the default `moment` style.
 * - `short`: customised format that replaced "a few seconds" with "just now".
 *
 * @see https://momentjs.com/docs/#/customization/relative-time/
 * @see https://momentjs.com/docs/#/displaying/fromnow/
 */
export function formatDistance(
  date: DateLike,
  baseDate: DateLike,
  format: DistanceFormat = 'default'
): string {
  const dateObj = toDate(date);
  const baseObj = toDate(baseDate);

  if (!isValid(dateObj) || !isValid(baseObj)) {
    return 'Invalid Date';
  }

  /** Positive, in seconds */
  const diff = Math.abs(differenceInSeconds(dateObj, baseObj));

  let prefix = isBefore(baseObj, dateObj) ? 'in ' : '';
  const suffix = !isBefore(baseObj, dateObj) ? ' ago' : '';

  if (format === 'short') {
    prefix = '';
  }

  // The moment formats outputs by a series of "ranges", i.e. "s", "ss", "m", ...
  if (diff <= 44) {
    // s
    switch (format) {
      case 'short':
        return 'just now';
      default:
        return `${prefix}a few seconds${suffix}`;
    }
  } else if (diff <= 89) {
    // m
    return `${prefix}a minute${suffix}`;
  } else if (diff <= 44.5 * 60) {
    // mm, use default
  } else if (diff <= 89.5 * 60) {
    // h
    return `${prefix}an hour${suffix}`;
  } else if (diff <= 21.5 * 3600) {
    // hh, use default
  } else if (diff <= 35.5 * 3600) {
    // d
    return `${prefix}a day${suffix}`;
  } else if (diff <= 25.5 * 24 * 3600) {
    // dd, use default
  } else if (diff <= 45.5 * 24 * 3600) {
    // M
    return `${prefix}a month${suffix}`;
  } else if (diff <= 319.5 * 24 * 3600) {
    // MM, use default
  } else if (diff <= 547.5 * 24 * 3600) {
    // y
    return `${prefix}a year${suffix}`;
  }
  // else: yy, use default

  // Default date-fns format
  return `${prefix}${formatDistanceStrict(dateObj, baseObj)}${suffix}`;
}

/**
 * This function is mainly used for test purpose to return a random date given a relative input
 * e.g. 1 year ago, 2 years ago
 */
export function formatDistanceToNow(
  date: DateLike,
  format: DistanceFormat = 'default'
): string {
  return formatDistance(date, Date.now(), format);
}

export function parseTimeAgoToUTCDate(timeAgo: string): Date | Error {
  const parts = timeAgo.split(' ');
  const quantity = parseInt(parts[0], 10);
  const unit = parts[1];
  const now = new Date();
  let date;
  switch (unit) {
    case 'year':
    case 'years':
      date = subYears(now, quantity);
      break;
    case 'month':
    case 'months':
      date = subMonths(now, quantity);
      break;
    case 'day':
    case 'days':
      date = subDays(now, quantity);
      break;
    case 'hour':
    case 'hours':
      date = subHours(now, quantity);
      break;
    case 'minute':
    case 'minutes':
      date = subMinutes(now, quantity);
      break;
    case 'second':
    case 'seconds':
      date = subSeconds(now, quantity);
      break;
    default:
      throw new Error(`Unsupported time unit: ${unit}`);
  }
  return date;
}
