import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';

export { loadProjectSettingsAccessKeysResource } from './resources';

export const ProjectSettingsAccessKeysResourceLoadable = Loadable({
  loading: LoadingPage,
  loader: () => import('./access-keys'),
});

export const ProjectSettingsAccessKeysLoadable = Loadable({
  loading: LoadingPage,
  loader: () => import('./access-keys-legacy'),
});
