/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { put, select, call, takeLatest } from 'redux-saga/effects';

import { FETCH_IS_PIPELINES_ENABLED } from 'src/sections/repository/actions';
import { getCurrentRepositoryFullSlug } from 'src/selectors/repository-selectors';

import envBaseUrl from '../components/pipelines/utils/env-base-url';

export function* fetchPipelinesConfig() {
  try {
    const baseUrl = envBaseUrl('/!api/internal');
    const fullSlug: string = yield select(getCurrentRepositoryFullSlug);
    if (!fullSlug) {
      throw new Error(`Repository slug not available`);
    }
    const url = `${baseUrl}/repositories/${fullSlug}/pipelines_config/capabilities/?fields=repository_config,allowance`;
    const response: Response = yield call(fetch, url);
    // @ts-ignore
    const json = yield call([response, 'json']);
    const { repository_config: repoConfig, allowance } = json;
    if (allowance) {
      yield put({
        type: FETCH_IS_PIPELINES_ENABLED.SUCCESS,
        payload: {
          isPipelinesEnabled: repoConfig.pipelines_enabled,
          isPipelinesPremium: allowance.premium || allowance.paid,
          ...json,
        },
      });
    } else {
      yield put({
        type: FETCH_IS_PIPELINES_ENABLED.SUCCESS,
        payload: {
          isPipelinesEnabled: repoConfig.pipelines_enabled,
          ...json,
        },
      });
    }
  } catch (e) {
    yield put({ type: FETCH_IS_PIPELINES_ENABLED.ERROR, payload: e.message });
  }
}

export function* fetchIsPipelinesEnabled() {
  try {
    yield call(fetchPipelinesConfig);
  } catch (e) {
    yield put({ type: FETCH_IS_PIPELINES_ENABLED.ERROR, payload: e.message });
  }
}

export default function* () {
  yield takeLatest(FETCH_IS_PIPELINES_ENABLED.REQUEST, fetchIsPipelinesEnabled);
}
