import { useState, useCallback, Dispatch, useRef, useEffect } from 'react';

export type SetStateAction<T> = Partial<T> | ((prevState: T) => Partial<T>);

export const useObjectState = <T>(
  initialState: T
): [T, Dispatch<SetStateAction<T>>] => {
  const isMounted = useRef(false);
  const [state, setState] = useState(initialState);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  const setNextState = useCallback((nextState: SetStateAction<T>) => {
    if (isMounted.current) {
      setState((prevState: T) => {
        const futureState =
          typeof nextState === 'function' ? nextState(prevState) : nextState;
        if (prevState === futureState) {
          return prevState;
        }
        return Object.assign({}, prevState, futureState);
      });
    }
  }, []);
  return [state, setNextState];
};
