import { BucketDispatch, Thunk } from 'src/types/state';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

import { FetchAction } from './fetch';

export const fetchData = (
  action: FetchAction,
  payloadTransformer?: (payload: any) => any
): Thunk => {
  return async (dispatch: BucketDispatch) => {
    const { asyncAction, csrftoken, fetchOptions = {}, url } = action.meta;

    const {
      responseType = 'json',
      errorType = 'json',
      ...otherFetchOptions
    } = fetchOptions;

    const requestOptions = {
      headers: responseType === 'json' ? jsonHeaders : {},
      ...otherFetchOptions,
    };

    try {
      const response = await fetch(authRequest(url, requestOptions, csrftoken));

      if (!response.ok) {
        let error;
        try {
          error = await response[errorType]();
        } catch (e) {
          error = response.status;
        }

        const getErrorText =
          errorType === 'json' && error.error
            ? (json: { error: { message: string } }) => json.error.message
            : (text: string) => text;

        const e = new Error(getErrorText(error));
        // @ts-ignore
        e.status = response.status;

        throw e;
      }

      const payload = await response[responseType]();
      return dispatch({
        ...action,
        type: asyncAction.SUCCESS,
        payload: payloadTransformer ? payloadTransformer(payload) : payload,
      });
    } catch (error) {
      return dispatch({
        type: asyncAction.ERROR,
        payload: error.message,
        error: true,
        meta: {
          ...action.meta,
          status: error.status,
        },
      });
    }
  };
};
