import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

const containerStyles = xcss({
  display: 'flex',
  gap: 'space.100',
  marginBottom: 'space.250',
});

/* eslint @typescript-eslint/ban-types: "warn" */
export const FilterContainer: React.FC = ({ children }) => (
  <Box xcss={containerStyles}>{children}</Box>
);
