import * as Sentry from '@sentry/browser';
/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { put, select, call, take, race } from 'redux-saga/effects';

import { User } from 'src/components/types';
import { getGlobalIsAnnotationsEnabled } from 'src/redux/diff-settings';
import {
  getCurrentPullRequestUrlPieces,
  getPullRequestSourceHash,
} from 'src/redux/pull-request/selectors';
import { CODE_INSIGHTS_ANNOTATIONS_PAGE_SIZE } from 'src/sections/repository/constants';
import { getTargetUser } from 'src/selectors/user-selectors';
import { settings } from 'src/settings';
import fetchAccessToken, { AccessToken } from 'src/utils/fetch-access-token';

import {
  FETCH_PULL_REQUEST_ANNOTATIONS,
  FETCH_CODE_INSIGHTS_PREINSTALL,
} from '../actions';

import { annotationsBaseUrl } from './utils/code-insights-session-auth-feature';
import fetchWithAccessToken, {
  fetchAllPages,
} from './utils/fetch-with-access-token';

// code-insights addon needs to be preinstalled for the account before requests can be made
// this would eventually be executed if not installed: https://softwareteams.atlassian.net/browse/BBCFR-1198
export function* preInstallAddon() {
  const account: User = yield select(getTargetUser);
  const url = `${settings().API_CANON_URL}/internal/site/addons/account/${
    account.uuid
  }/install/`;
  try {
    const accessToken: AccessToken = yield call(
      fetchAccessToken,
      `site:code-insights-installer`
    );
    yield call(fetchWithAccessToken, url, accessToken, false, 'POST');
    yield put({ type: FETCH_CODE_INSIGHTS_PREINSTALL.SUCCESS });
  } catch (e) {
    yield put({ type: FETCH_CODE_INSIGHTS_PREINSTALL.ERROR });
    if (e instanceof Error) {
      Sentry.captureException(e);
    }
  }
}

export function* fetchAnnotations() {
  const { owner, slug, id } = yield select(getCurrentPullRequestUrlPieces);
  const commitHash: string = yield select(getPullRequestSourceHash);
  const url = `${annotationsBaseUrl(
    'internal'
  )}/repositories/${owner}/${slug}/pullrequests/${id}/annotations?sort=-severity&commitHash=${commitHash}&page=1`;

  try {
    const annotations: Response = yield call(
      fetchAllPages,
      url,
      CODE_INSIGHTS_ANNOTATIONS_PAGE_SIZE
    );
    yield put({
      type: FETCH_PULL_REQUEST_ANNOTATIONS.SUCCESS,
      payload: annotations || [],
    });
  } catch (e) {
    yield put({
      type: FETCH_PULL_REQUEST_ANNOTATIONS.ERROR,
      payload: e.message,
    });
  }
}

export function* fetchPullRequestAnnotations() {
  const isAnnotationsUserPrefsEnabled: boolean = yield select(
    getGlobalIsAnnotationsEnabled
  );
  if (!isAnnotationsUserPrefsEnabled) {
    return;
  }
  try {
    const { error } = yield race({
      success: call(fetchAnnotations),
      error: take(FETCH_PULL_REQUEST_ANNOTATIONS.ERROR),
    });
    if (error?.payload && error.payload.indexOf('status: 404') !== -1) {
      yield call(preInstallAddon);
      yield call(fetchAnnotations);
    }
  } catch (e) {
    yield put({
      type: FETCH_PULL_REQUEST_ANNOTATIONS.ERROR,
      payload: e.message,
    });
  }
}
