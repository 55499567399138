export function isString(str: any): str is string {
  return typeof str === 'string' || str instanceof String;
}

export function isObject<T extends {}>(obj: any): obj is T {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    !Array.isArray(obj) &&
    !(obj instanceof RegExp) &&
    !(obj instanceof Date)
  );
}

export function isArray<T>(array: any): array is T[] {
  return (
    (Array.isArray && Array.isArray(array)) ||
    (array && typeof array.length === 'number' && array.constructor === Array)
  );
}

export function isFunction<T = (...args: any) => any>(fn: any): fn is T {
  return fn && {}.toString.call(fn) === '[object Function]';
}

export function getObjectValue(
  obj: any,
  path: string,
  defaultValue?: any
): any {
  return (
    (isString(path) &&
      isObject(obj) &&
      path
        .split('.')
        // @ts-ignore TODO: fix noImplicitAny error here
        .reduce((o: any, k: string) => (isObject(o) ? o[k] : ''), obj)) ||
    defaultValue
  );
}

export function hasObject(obj: any, path: string) {
  return isObject(getObjectValue(obj, path));
}

export function groupBy(
  list: any[],
  keyGetter: (item: any) => any
): Map<string, any> {
  const map = new Map();
  list.forEach((item: any) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function flatMerge(
  a: { [x: string]: any } = {},
  b: { [x: string]: any } = {}
) {
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  const len = Math.max(aKeys.length, bKeys.length);
  const out = {};
  for (let i = 0; i < len; i++) {
    if (aKeys[i] && a[aKeys[i]]) {
      // @ts-ignore TODO: fix noImplicitAny error here
      out[aKeys[i]] = a[aKeys[i]];
    }
    if (bKeys[i] && b[bKeys[i]]) {
      // @ts-ignore TODO: fix noImplicitAny error here
      out[bKeys[i]] = b[bKeys[i]];
    }
  }
  return out;
}

export function getUrlOrigin(url: string) {
  // everything except IE11
  if (typeof URL === 'function') {
    try {
      return new URL(url).origin;
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  // ie11 + safari 10
  const doc = document.implementation.createHTMLDocument('');
  const anchorElement = doc.createElement('a');
  anchorElement.href = url;
  doc.body.appendChild(anchorElement);
  let origin = `${anchorElement.protocol}//${anchorElement.hostname}`; // ie11, only include port if referenced in initial URL

  if (url.match(/\/\/[^/]+:[0-9]+\//)) {
    origin += anchorElement.port ? `:${anchorElement.port}` : '';
  }
  return origin;
}

/* TODO: Remove once the ACJS upgrade is complete */

export const SSR_STATE_KEY = '__ssr_state__';
export const getSsrState = () => window[SSR_STATE_KEY] || {};
const APP_DID_SSR = Object.keys(getSsrState()).length > 0;
export const appWasServerSideRendered = () => APP_DID_SSR;

export const getInitialOrBucketState = (): any =>
  appWasServerSideRendered()
    ? getSsrState().reduxStoreState
    : // eslint-disable-next-line no-restricted-properties
      window.__initial_state__;
