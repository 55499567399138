import { captureExceptionWithTags } from 'src/utils/sentry';

export type ContextLineRanges = {
  startingFrom: number;
  startingTo: number;
} & (
  | { endingFrom: number; endingTo?: number }
  | { endingFrom?: number; endingTo: number }
); // make this only require endingTo once we fully switch to topic diffs

const isNegative = (n?: number) => n !== undefined && n < 0;

const hasNoRangeParams = ({
  endingFrom,
  endingTo,
  startingFrom,
  startingTo,
}: ContextLineRanges) =>
  !endingFrom && !endingTo && !startingFrom && !startingTo;

const hasNegativeValues = ({
  endingFrom,
  endingTo,
  startingFrom,
  startingTo,
}: ContextLineRanges) =>
  isNegative(endingFrom) ||
  isNegative(endingTo) ||
  isNegative(startingFrom) ||
  isNegative(startingTo);

const hasInvertedRange = ({
  endingFrom,
  endingTo,
  startingFrom,
  startingTo,
}: ContextLineRanges) =>
  (startingFrom !== undefined &&
    endingFrom !== undefined &&
    startingFrom > endingFrom) ||
  (startingTo !== undefined && endingTo !== undefined && startingTo > endingTo);

// A value of 1 will return a 204 (there's no content above the 1st line of a file)
// A value of 0 should get caught by hasNoRangeParams or hasInvertedRange, but leaving it in just in case
const hasOutOfBoundRange = ({ endingFrom, endingTo }: ContextLineRanges) =>
  (endingFrom !== undefined && endingFrom <= 1) ||
  (endingTo !== undefined && endingTo <= 1);

// eslint-disable-next-line no-underscore-dangle
const _validateContextLineRanges = (ranges: ContextLineRanges) => {
  if (
    hasNoRangeParams(ranges) ||
    hasNegativeValues(ranges) ||
    hasInvertedRange(ranges) ||
    hasOutOfBoundRange(ranges)
  ) {
    throw new Error('The context line ranges are invalid');
  }
};

export const validateContextLineRanges = (contextLines: ContextLineRanges) => {
  try {
    _validateContextLineRanges(contextLines);
  } catch (e) {
    captureExceptionWithTags(
      e,
      {},
      {
        endingFrom: `${contextLines.endingFrom}`,
        endingTo: `${contextLines.endingTo}`,
        startingFrom: `${contextLines.startingFrom}`,
        startingTo: `${contextLines.startingTo}`,
      }
    );
    throw e;
  }
};
