import { Repository } from 'src/components/types';
import { Branch } from 'src/components/types/src/refs';
import createReducer from 'src/utils/create-reducer';

import {
  FetchRepositoryDetails,
  FetchRepositoryMainBranch,
  TOGGLE_REPOSITORY_WATCH,
  UPDATE_REPO_DETAILS,
  UPDATE_TRANSFER_STATUS,
  FETCH_IS_PIPELINES_ENABLED,
} from '../actions';
import { RepositoryPrivilege, XferStatus } from '../types';

export type SourceRepositoryDetailsState = {
  accessLevel?: RepositoryPrivilege;
};

export const initialStateSourceRepository: SourceRepositoryDetailsState = {
  accessLevel: undefined,
};

export enum PipelinesEnabledLoadingState {
  NotLoaded = 'NOT_LOADED',
  Loading = 'LOADING',
  Error = 'ERROR',
  Loaded = 'LOADED',
}

export type RepositoryDetailsState = {
  accessLevel?: RepositoryPrivilege;
  branchCount?: number;
  branches?: number;
  commitsBehindParent?: number;
  forkCount?: number;
  forks?: number;
  grantedAccess?: RepositoryPrivilege;
  hardLimit?: number;
  hasError: boolean;
  isLoading?: boolean;
  language?: string;
  lastUpdated?: string;
  mustStayNoPublicForks?: boolean;
  mustStayPrivate?: boolean;
  openPullRequestCount?: number;
  parent?: Repository;
  isAccountAdmin?: boolean;
  isReadOnly?: boolean;
  readOnly?: boolean;
  isWatching?: boolean;
  size?: number;
  softLimit?: number;
  watcherCount?: number;
  mainBranch?: Branch;
  developmentBranch?: any;
  productionBranch?: any;
  landingPage?: string;
  lfsUsage?: number;
  lfsHasLimits?: boolean;
  lfsRemaining?: number;
  isSignedCommitsEnforced?: boolean;
  analyticsKey?: string | null;
  xferStatus?: XferStatus;
  isPipelinesEnabledLoadingState: PipelinesEnabledLoadingState;
  isPipelinesEnabled: boolean;
  isPipelinesPremium: boolean;
  watchers?: number;
  pushFileSizeLimitDisabled?: boolean;
};

const initialState: RepositoryDetailsState = {
  hasError: false,
  isLoading: false,
  isWatching: false,
  isPipelinesEnabledLoadingState: PipelinesEnabledLoadingState.NotLoaded,
  isPipelinesEnabled: false,
  isPipelinesPremium: false,
};

const resultToDetails = (result: any) => ({
  accessLevel: result.access_level,
  branchCount: result.branches,
  commitsBehindParent: result.commits_behind_parent,
  forkCount: result.forks,
  grantedAccess: result.granted_access,
  lastUpdated: result.last_updated,
  language: result.language ? result.language.label : undefined,
  openPullRequestCount: result.pullrequests.open,
  parent: result.parent,
  isAccountAdmin: result.is_account_admin,
  isReadOnly: result.read_only,
  isWatching: result.is_watching,
  size: result.size,
  watcherCount: result.watchers,
  analyticsKey: result.analytics_key,
  landingPage: result.landing_page,
  lfsUsage: result.lfs_usage,
  lfsHasLimits: result.lfs_has_limits,
  lfsRemaining: result.lfs_remaining,
  xferStatus: result.xfer_status,
  pushFileSizeLimitDisabled: result.push_file_size_limit_disabled,
  isSignedCommitsEnforced: result.enforced_signed_commits,
});

export default createReducer(initialState, {
  [FetchRepositoryDetails.REQUEST](state) {
    return {
      ...state,
      hasError: false,
      isLoading: true,
    };
  },

  [FetchRepositoryDetails.SUCCESS](state, action) {
    const { result } = action.payload;
    const details = resultToDetails(result);

    return {
      ...state,
      ...details,
      hasError: false,
      isLoading: false,
    };
  },

  [FetchRepositoryDetails.ERROR](state) {
    return {
      ...state,
      hasError: true,
      isLoading: false,
    };
  },

  [FetchRepositoryMainBranch.SUCCESS](state, action) {
    const mainBranch = action.payload;

    return {
      ...state,
      mainBranch,
    };
  },
  [FETCH_IS_PIPELINES_ENABLED.REQUEST](state) {
    return {
      ...state,
      isPipelinesEnabled: false,
      isPipelinesPremium: false,
      isPipelinesEnabledLoadingState: PipelinesEnabledLoadingState.Loading,
    };
  },
  [FETCH_IS_PIPELINES_ENABLED.ERROR](state) {
    return {
      ...state,
      isPipelinesEnabled: false,
      isPipelinesPremium: false,
      isPipelinesEnabledLoadingState: PipelinesEnabledLoadingState.Error,
    };
  },
  [FETCH_IS_PIPELINES_ENABLED.SUCCESS](state, action) {
    return {
      ...state,
      isPipelinesEnabled: action.payload.isPipelinesEnabled,
      isPipelinesPremium: action.payload.isPipelinesPremium,
      isPipelinesEnabledLoadingState: PipelinesEnabledLoadingState.Loaded,
    };
  },
  [TOGGLE_REPOSITORY_WATCH.SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
  [UPDATE_REPO_DETAILS](state, action) {
    return {
      ...state,
      ...resultToDetails(action.payload),
    };
  },
  [UPDATE_TRANSFER_STATUS](state, action) {
    return {
      ...state,
      xferStatus: action.payload,
    };
  },
});
