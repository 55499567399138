import { unsupportedBlock as unsupportedBlockFactory } from '../../next-schema/generated/nodeTypes';
export var unsupportedBlock = unsupportedBlockFactory({
  parseDOM: [{
    tag: '[data-node-type="unsupportedBlock"]',
    getAttrs: function getAttrs(dom) {
      return {
        originalValue: JSON.parse(dom.getAttribute('data-original-value') || '{}')
      };
    }
  }],
  toDOM: function toDOM(node) {
    var attrs = {
      'data-node-type': 'unsupportedBlock',
      'data-original-value': JSON.stringify(node.attrs.originalValue)
    };
    return ['div', attrs, 'Unsupported content'];
  }
});