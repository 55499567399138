export class Reason {
  readonly type: string = '';

  constructor(props: Partial<Reason> = {}) {
    Object.assign(this, { ...props });
  }

  get isAllowanceExceededReason(): boolean {
    return this.type === 'pipeline_step_halted_reason_allowance_exceeded';
  }

  get isDeploymentConcurrencyReason(): boolean {
    return this.type === 'pipeline_step_halted_reason_concurrent_deployment';
  }

  get isUserHasNoDeploymentPermissionsReason(): boolean {
    return (
      this.type ===
      'pipeline_step_halted_reason_user_has_no_environment_permissions'
    );
  }

  get isBranchHasNoDeploymentPermissionsReason(): boolean {
    return (
      this.type === 'pipeline_step_halted_reason_branch_blocked_deployment'
    );
  }

  get isDeploymentGateChecksRequiredReason(): boolean {
    return (
      this.type ===
      'pipeline_step_halted_reason_deployment_gated_checks_required'
    );
  }

  get isDeploymentGateFailedReason(): boolean {
    return this.type === 'deployment_gate_failed_halted_reason';
  }

  get isFailFastStoppedReason(): boolean {
    return this.type === 'pipeline_step_stopped_reason_fail_fast';
  }
}
