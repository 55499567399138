import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  failedFetchErrorFlagMsg: {
    id: 'frontbucket.pipelines.hooks.pipelineRuns.fetch.errorFlagMsg',
    description:
      'Error flag message for failing to fetch the selected provider',
    defaultMessage: 'Failed to fetch previous pipeline runs.',
  },
});
