import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';

export const ProjectSettingsPermissionsLoadable = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      /* webpackChunkName: "project-settings-permissions" */
      './permissions'
    ),
});
