/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { call, select, take } from 'redux-saga/effects';

import { User } from 'src/components/types';
import { ADD_RECENTLY_VIEWED_REPOSITORY } from 'src/redux/recently-viewed-repositories';
import { getCurrentUser } from 'src/selectors/user-selectors';
import storeWithWorkspace from 'src/utils/recently-viewed-repositories-store';
import recentWorkspaceStore from 'src/utils/recently-viewed-workspaces-store';

function* cacheRecentlyViewedRepository() {
  while (true) {
    // @ts-ignore
    const action = yield take(ADD_RECENTLY_VIEWED_REPOSITORY);
    const currentUser: User = yield select(getCurrentUser);

    if (!currentUser) {
      continue;
    }

    // add workspace to recent workspaces
    recentWorkspaceStore.add(currentUser, action.meta.workspaceUuid);

    // writes to localStorage key: 'recently-viewed-repo-uuids-v2:<userId>'
    yield call(
      storeWithWorkspace.add,
      currentUser,
      action.payload.result,
      action.meta.workspaceUuid
    );
  }
}

export default cacheRecentlyViewedRepository;
