import React, { useEffect } from 'react';

import { injectIntl, InjectedIntl } from 'react-intl';

import Flag from '@atlaskit/flag';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import {
  ComponentFlagId,
  ComponentFlagProps,
  HandleDismissedType,
} from 'src/redux/flags/types';

import messages from './online.i18n';

type Props = ComponentFlagProps & {
  id: ComponentFlagId;
  intl: InjectedIntl;
} & HandleDismissedType;

const OnlineFlag = ({ id, intl, handleDismissed, ...otherProps }: Props) => {
  useEffect(() => {
    const dismissFlag = setTimeout(() => {
      handleDismissed(id);
    }, 2000);

    return () => {
      clearTimeout(dismissFlag);
    };
  }, [id, handleDismissed]);

  return (
    <Flag
      {...otherProps}
      id={id}
      appearance="success"
      icon={
        <SuccessIcon
          label="success"
          secondaryColor={token('color.icon.success', colors.G300)}
        />
      }
      title={intl.formatMessage(messages.onlineTitle)}
    />
  );
};

export default injectIntl(OnlineFlag);
