import {
  PageSegmentLoadMetric,
  Metric,
} from '@atlassian/browser-metrics/types';

export const startMetric = (metric: PageSegmentLoadMetric | Metric): void => {
  if (metric.getData().state !== 'started') {
    metric.start();
  }
};
