import { omit } from 'lodash-es';
import { schema } from 'normalizr';

export const branch = new schema.Entity(
  'branches',
  {},
  {
    idAttribute: entity => entity.links.self.href,
    processStrategy: entity => {
      return omit(
        {
          ...entity,
          isMainBranch: entity.ismainbranch,
          pullRequests: entity.pullrequests,
          commitStatuses: entity.statuses,
          pullRequestsTargetedCount: entity.pullrequests_targeted_count,
        },
        [
          'statuses',
          'pullrequests',
          'ismainbranch',
          'pullrequests_targeted_count',
        ]
      );
    },
  }
);

branch.define({});
