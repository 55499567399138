/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';

export const FocusedNavigationWrapper = styled.div({
  paddingTop: token('space.600', '48px'),
  paddingLeft: token('space.200', '16px'),
  display: 'flex',
  height: '100vh',
});
