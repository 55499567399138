/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { call, delay, fork, put, select } from 'redux-saga/effects';

import bufferEvery from 'src/sagas/buffer-every';
import { getIsSourceTimeoutFixEnabled } from 'src/selectors/feature-selectors';

import { FilterFiles } from '../actions';
import { getFileTree, getSourceDirectoryContents } from '../reducers/section';
import {
  FileTree,
  FilteredFiles,
  TreeDirectory,
  TreeEntry,
  TreeFile,
  TreeSubmodule,
  TreeSymlink,
} from '../types';
import { findFiles, limitedFilter } from '../utils/filter';

function createFilteredFiles(values: {
  isCleared?: boolean;
  isLoading?: boolean;
  errorCode?: number;
  files?: TreeEntry[] | null | undefined;
}): FilteredFiles | null {
  const { isCleared, isLoading, errorCode, files } = values;

  if (isCleared) {
    return null;
  }

  return {
    isLoading: !!isLoading,
    errorCode,
    files,
  };
}

/* eslint-disable @typescript-eslint/no-use-before-define */
// @ts-ignore TODO: fix noImplicitAny error here
function* filterFilesAgain(action) {
  yield delay(250);
  yield call(filterFiles, action);
}

// @ts-ignore TODO: fix noImplicitAny error here
function* filterFiles(action) {
  const query = action.payload;
  if (!query) {
    yield put({
      type: FilterFiles.SUCCESS,
      payload: createFilteredFiles({ isCleared: true }),
    });
    return;
  }

  const fileTree: FileTree = yield select(getFileTree);

  if (!fileTree) {
    yield put({
      type: FilterFiles.SUCCESS,
      payload: createFilteredFiles({
        isLoading: true,
        files: [],
      }),
    });
    // The tree might be available in a moment, so try filtering again
    yield fork(filterFilesAgain, action);
    return;
  }

  if (!fileTree.tree) {
    const sourceTimeoutFixEnabled: boolean = yield select(
      getIsSourceTimeoutFixEnabled
    );
    const sourceFiles: Array<
      TreeDirectory | TreeFile | TreeSubmodule | TreeSymlink
    > = yield select(getSourceDirectoryContents);
    if (sourceTimeoutFixEnabled && sourceFiles) {
      yield put({
        type: FilterFiles.ERROR,
        payload: createFilteredFiles({
          isLoading: false,
          errorCode: fileTree.errorCode || undefined,
          files: limitedFilter(sourceFiles, query),
        }),
      });
    } else {
      yield put({
        type: FilterFiles.ERROR,
        payload: createFilteredFiles({
          isLoading: false,
          errorCode: 404,
        }),
      });
    }
    return;
  }

  yield put({
    type: FilterFiles.SUCCESS,
    payload: createFilteredFiles({
      isLoading: false,
      files: findFiles(fileTree.tree, query),
    }),
  });
}
/* eslint-enable @typescript-eslint/no-use-before-define */

export default function* filterFileSagas() {
  yield bufferEvery(FilterFiles.REQUEST, filterFiles);
}
