import { typeAheadQuery as typeAheadQueryFactory } from '../../next-schema/generated/markTypes';
import { B400 } from '../../utils/colors';
export var typeAheadQuery = typeAheadQueryFactory({
  parseDOM: [{
    tag: 'span[data-type-ahead-query]'
  }],
  toDOM: function toDOM(node) {
    return ['span', {
      'data-type-ahead-query': 'true',
      'data-trigger': node.attrs.trigger,
      style: "color: ".concat(B400)
    }];
  }
});