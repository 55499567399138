import { Deployment, Environment } from 'src/components/pipelines/models';
import { createAsyncAction } from 'src/redux/actions';

import { prefixed } from './common';

export const REQUEST_DEPLOYMENT_CHANGES = createAsyncAction(
  prefixed('REQUEST_DEPLOYMENT_CHANGES')
);

export const REQUEST_DEPLOYMENT_COMMITS = createAsyncAction(
  prefixed('REQUEST_DEPLOYMENT_COMMITS')
);

export const REQUEST_DEPLOYMENT_DASHBOARD = createAsyncAction(
  prefixed('REQUEST_DEPLOYMENT_DASHBOARD')
);

export const REQUEST_DEPLOYMENT_PULL_REQUESTS = createAsyncAction(
  prefixed('REQUEST_DEPLOYMENT_PULL_REQUESTS')
);

export const REQUEST_DEPLOYMENT_SUMMARY = createAsyncAction(
  prefixed('REQUEST_DEPLOYMENT_SUMMARY')
);

export const REQUEST_ENVIRONMENT_HISTORY = createAsyncAction(
  prefixed('REQUEST_ENVIRONMENT_HISTORY')
);

export const REQUEST_BRANCH_RESTRICTIONS = createAsyncAction(
  prefixed('REQUEST_BRANCH_RESTRICTIONS')
);

export const REQUEST_CREATE_BRANCH_RESTRICTION = createAsyncAction(
  prefixed('REQUEST_CREATE_BRANCH_RESTRICTION')
);

export const REQUEST_DELETE_BRANCH_RESTRICTION = createAsyncAction(
  prefixed('REQUEST_DELETE_BRANCH_RESTRICTION')
);

export const REQUEST_REDEPLOYMENT = createAsyncAction(
  prefixed('REQUEST_REDEPLOYMENT')
);

export const REQUEST_CREATE_ENVIRONMENT = createAsyncAction(
  prefixed('REQUEST_CREATE_ENVIRONMENT')
);

export const REQUEST_UPDATE_ENVIRONMENT = createAsyncAction(
  prefixed('REQUEST_UPDATE_ENVIRONMENT')
);

export const REQUEST_DELETE_ENVIRONMENT = createAsyncAction(
  prefixed('REQUEST_DELETE_ENVIRONMENT')
);

export const REQUEST_REORDER_ENVIRONMENTS = createAsyncAction(
  prefixed('REQUEST_REORDER_ENVIRONMENT')
);

export const REMOVE_DEPLOYMENT_DATA = prefixed('REMOVE_DEPLOYMENT_DATA');
export const SET_DEPLOYMENT_PREVIEW = prefixed('SET_DEPLOYMENT_PREVIEW');
export const ENVIRONMENT_UPDATED = 'ENVIRONMENT_UPDATED';
export const ENVIRONMENT_LOCK_UPDATED = 'ENVIRONMENT_LOCK_UPDATED';
export const ENVIRONMENT_DASHBOARD_UPDATED = 'ENVIRONMENT_DASHBOARD_UPDATED';

export const SET_DEPLOYMENT_DASHBOARD = 'SET_DEPLOYMENT_DASHBOARD';
export const SET_BRANCH_RESTRICTIONS = 'SET_BRANCH_RESTRICTIONS';

export const removeDeploymentData = () => {
  return {
    type: REMOVE_DEPLOYMENT_DATA,
  };
};

export const requestDeploymentChanges = (
  revisionFrom: string,
  revisionTo: string,
  createdOn: string,
  isRollback: boolean
) => {
  return {
    type: REQUEST_DEPLOYMENT_CHANGES.REQUEST,
    meta: { revisionFrom, revisionTo, createdOn, isRollback },
  };
};

export const requestDeploymentCommits = (
  revisionFrom: string,
  revisionTo: string,
  isRollback: boolean,
  page: number
) => {
  return {
    type: REQUEST_DEPLOYMENT_COMMITS.REQUEST,
    meta: { revisionFrom, revisionTo, isRollback, page },
  };
};

export const setDeploymentDashboard = (payload: any) => {
  return { type: SET_DEPLOYMENT_DASHBOARD, payload };
};

export const setBranchRestrictions = (payload: any) => {
  return { type: SET_BRANCH_RESTRICTIONS, payload };
};

export const requestDeploymentDashboard = (queryParams?: {
  includeHidden?: boolean;
  fields?: string;
}) => {
  return {
    type: REQUEST_DEPLOYMENT_DASHBOARD.REQUEST,
    meta: { queryParams },
  };
};

export const requestDeploymentSummary = (deploymentUuid: string) => {
  return {
    type: REQUEST_DEPLOYMENT_SUMMARY.REQUEST,
    meta: { deploymentUuid },
  };
};

export const requestEnvironmentHistory = (
  environmentUuid: string,
  page?: number
) => {
  return {
    type: REQUEST_ENVIRONMENT_HISTORY.REQUEST,
    meta: { environmentUuid, page },
  };
};

export const requestRedeployment = (
  environmentUuid: string,
  deploymentUuid: string
) => {
  return {
    type: REQUEST_REDEPLOYMENT.REQUEST,
    meta: { environmentUuid, deploymentUuid },
  };
};

export const setDeploymentPreview = (
  deployment: Deployment,
  environment: Environment
) => {
  return {
    type: SET_DEPLOYMENT_PREVIEW,
    payload: { deployment, environment },
  };
};

export const requestCreateEnvironment = (
  environmentName: string,
  environmentType: string
) => {
  return {
    type: REQUEST_CREATE_ENVIRONMENT.REQUEST,
    payload: { environmentName, environmentType },
  };
};

export const requestUpdateEnvironment = (
  environmentUuid: string,
  change: Partial<Environment>
) => {
  return {
    type: REQUEST_UPDATE_ENVIRONMENT.REQUEST,
    payload: { environmentUuid, change },
  };
};

export const requestDeleteEnvironment = (environmentUuid: string) => {
  return {
    type: REQUEST_DELETE_ENVIRONMENT.REQUEST,
    payload: { environmentUuid },
  };
};

export const requestReorderEnvironments = (
  environmentType: string,
  environmentTypeOrder: { uuid: string }[]
) => {
  return {
    type: REQUEST_REORDER_ENVIRONMENTS.REQUEST,
    payload: { environmentType, environmentTypeOrder },
  };
};

export const requestCreateBranchRestriction = (
  environmentUuid: string,
  pattern: string
) => {
  return {
    type: REQUEST_CREATE_BRANCH_RESTRICTION.REQUEST,
    payload: { environmentUuid, pattern },
  };
};

export const requestDeleteBranchRestriction = (
  environmentUuid: string,
  restrictionUuid: string
) => {
  return {
    type: REQUEST_DELETE_BRANCH_RESTRICTION.REQUEST,
    payload: { environmentUuid, restrictionUuid },
  };
};
