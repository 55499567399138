import { throttle } from 'lodash-es';

import urls from 'src/urls/workspaces';
import { createHttpRequest } from 'src/utils/http-request';

export type CompassApi = {
  getCloudIdsForWorkspace: typeof getCloudIdsForWorkspace;
  getCloudIdsForRepository: typeof getCloudIdsForRepository;
};

export type GetCompassCloudIdsData = {
  connectedCloudIds: string[];
};

// The throttling is used to prevent multiple requests being sent
// from different React components trying to load the same data
const THROTTLE_TIMEOUT = 30000;

const getCloudIdsForWorkspace = throttle(
  (workspaceSlug: string) =>
    createHttpRequest<GetCompassCloudIdsData>('GET')(
      urls.api.internal.getCompassCloudIdsForWorkspace(workspaceSlug)
    ),
  THROTTLE_TIMEOUT
);

const getCloudIdsForRepository = throttle(
  (workspaceSlug: string, repositorySlug: string) => {
    return createHttpRequest<GetCompassCloudIdsData>('GET')(
      urls.api.internal.getCompassCloudIdsForRepository(
        workspaceSlug,
        repositorySlug
      )
    );
  },
  THROTTLE_TIMEOUT
);

export const compassApi = (): CompassApi => {
  return {
    getCloudIdsForWorkspace,
    getCloudIdsForRepository,
  };
};
