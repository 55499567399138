import { uuid } from '../../utils/uuid';
import { decisionList as decisionListFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name decisionList_node
 */

var name = 'decisionList';
export var decisionListSelector = "[data-node-type=\"".concat(name, "\"]");
export var decisionList = decisionListFactory({
  parseDOM: [{
    tag: "ol".concat(decisionListSelector),
    // Default priority is 50. We normally don't change this but since this node type is
    // also used by ordered-list we need to make sure that we run this parser first.
    priority: 100,
    getAttrs: function getAttrs() {
      return {
        localId: uuid.generate()
      };
    }
  }],
  toDOM: function toDOM(node) {
    var localId = node.attrs.localId;
    var attrs = {
      'data-node-type': name,
      'data-decision-list-local-id': localId || 'local-decision-list',
      style: 'list-style: none; padding-left: 0'
    };
    return ['ol', attrs, 0];
  }
});