import React from 'react';

import { FormattedMessage } from 'react-intl';

import { AutoDismissFlag } from '@atlaskit/flag';
import Error from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import { ComponentFlagId, HandleDismissedType } from 'src/redux/flags/types';
import { settings } from 'src/settings';

import messages from './create-workspace-error-flag.i18n';

type Props = {
  id: ComponentFlagId;
  description: string;
} & HandleDismissedType;

const CreateWorkspaceErrorFlag = ({
  id,
  description,
  ...otherProps
}: Props): JSX.Element => {
  const intl = useIntl();
  return (
    <AutoDismissFlag
      {...otherProps}
      id={id}
      title={intl.formatMessage(messages.workspaceCreateErrorTitle)}
      icon={
        <Error
          label="Error"
          primaryColor={token('color.icon.danger', colors.R300)}
        />
      }
      description={
        <FormattedMessage
          {...messages.workspaceCreateErrorDescription}
          values={{
            checkStatus: (
              <a href={settings().STATUSPAGE_URL}>
                <FormattedMessage
                  {...messages.workspaceCreateCheckStatusPage}
                />
              </a>
            ),
          }}
        />
      }
    />
  );
};

export default CreateWorkspaceErrorFlag;
