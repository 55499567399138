import React from 'react';

import CustomThemeButton, {
  CustomThemeButtonProps,
} from '@atlaskit/button/custom-theme-button';
import Modal, {
  ModalBody,
  ModalTitle as ModalTitleNext,
  ModalTitleProps as ModalTitleNextProps,
  ModalHeader as ModalHeaderNext,
  ModalFooter as ModalFooterNext,
  ModalHeaderProps as ModalHeaderNextProps,
  ModalFooterProps as ModalFooterNextProps,
  ModalDialogProps as ModalDialogNextProps,
} from '@atlaskit/modal-dialog';

export { ModalTransition } from '@atlaskit/modal-dialog';

/**
 * This component provides a a backwards compatible interface
 * for the v11 <ModalDialog/> API to be mostly compatible with v12.
 */

export type ActionProps = CustomThemeButtonProps & {
  // ReactNode provides support for i18n libraries
  text: React.ReactNode;
};

export type ModalHeaderProps = ModalHeaderNextProps &
  Pick<ModalTitleNextProps, 'appearance'>;

export type ModalFooterProps = ModalFooterNextProps &
  Pick<ModalTitleNextProps, 'appearance'> & {
    actions?: Array<ActionProps>;
  };

export type WidthNames = 'small' | 'medium' | 'large' | 'x-large';

export type ScrollBehavior = 'inside' | 'outside' | 'inside-wide';

export type KeyboardOrMouseEvent =
  | React.MouseEvent<any>
  | React.KeyboardEvent<any>
  | KeyboardEvent;

export type ModalDialogProps = ModalDialogNextProps & {
  /**
   * Buttons to render in the footer.
   * The first element in the array will implictly become the primary action.
   */
  actions?: Array<ActionProps>;

  /**
   * Appearance of the modal that changes the color of the primary action and adds an icon to the heading.
   */
  appearance?: ModalTitleNextProps['appearance'];

  /**
   * Component overrides to change components in the modal dialog.
   */
  components?: {
    Header?: React.ElementType<ModalHeaderProps>;
    Footer?: React.ElementType<ModalFooterProps>;
    Body?: React.ElementType;
    Container?: React.ElementType;
  };

  /**
   * Do not use. This prop has been deprecated.
   * Use the `components` prop instead.
   */
  body?: React.ElementType;

  /**
   * Do not use. This prop has been deprecated.
   * Use the `components` prop instead.
   */
  footer?: React.ElementType<ModalFooterProps>;

  /**
   * Do not use. This prop has been deprecated.
   * Use the `components` prop instead.
   */
  header?: React.ElementType<ModalHeaderProps>;

  /**
   * Heading for the modal dialog.
   */
  heading?: React.ReactNode;
  /**
   * When `true` will allow the heading to span multiple lines.
   */
  isHeadingMultiline?: boolean;

  /**
   * Controls the positioning and scroll behaviour of the modal dialog.
    - `inside` scrolls overflow contents within the modal dialog body.
    - `outside` turns off overflow and will scroll the entire modal dialog.
    - `inside-wide` has the same behaviour as `inside` but is made for wide horizontal scrolling applications, like Trello.
  */
  scrollBehavior?: ScrollBehavior;
};

const defaultContainer: React.ComponentType = ({ children }) => <>{children}</>;

export const ModalHeader = (props: ModalHeaderProps) => (
  <ModalHeaderNext {...props} />
);

export const ModalFooter = ({
  actions = [],
  appearance,
  ...props
}: ModalFooterProps) => {
  const end = actions.length - 1;
  return (
    <ModalFooterNext {...props}>
      {actions.map(({ text, ...btnProps }, index) => (
        <CustomThemeButton
          {...btnProps}
          autoFocus={index === end}
          appearance={index !== end ? 'subtle' : appearance || 'primary'}
          key={index}
        >
          {text}
        </CustomThemeButton>
      ))}
    </ModalFooterNext>
  );
};

export default function ModalDialogActions({
  actions,
  appearance,
  components = {},
  body,
  footer,
  header,
  heading,
  isHeadingMultiline,
  scrollBehavior,
  testId,
  children,
  ...props
}: ModalDialogProps) {
  const {
    Header = header ?? ModalHeader,
    Footer = footer ?? ModalFooter,
    Body = body ?? ModalBody,
    Container = defaultContainer,
  } = components;
  return (
    <Modal
      {...props}
      shouldScrollInViewport={scrollBehavior === 'outside'}
      testId={testId}
    >
      <Container>
        <Header testId={testId}>
          {heading && (
            <ModalTitleNext
              appearance={appearance}
              isMultiline={isHeadingMultiline}
              testId={testId}
            >
              {heading}
            </ModalTitleNext>
          )}
        </Header>
        <Body testId={testId}>{children}</Body>
        <Footer actions={actions} appearance={appearance} testId={testId} />
      </Container>
    </Modal>
  );
}
