import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject;
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { N20, N200 } from '@atlaskit/theme/colors';
import { borderRadius, gridSize } from '@atlaskit/theme/constants';
import { h600 } from '@atlaskit/theme/typography';
import { bgColor, teamHeaderBgColor } from './constants';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var CardTriggerWrapper = styled.div({
  display: 'inherit'
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var CardWrapper = styled.div({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  backgroundColor: bgColor,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  borderRadius: "".concat(borderRadius(), "px"),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  width: "".concat(gridSize() * 40, "px"),
  position: 'relative',
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale'
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var TeamForbiddenErrorStateWrapper = styled.div({
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  width: "".concat(gridSize() * 40, "px"),
  position: 'relative'
});

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var CardHeader = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n\tbackground-color: ", ";\n\tbackground-image: ", ";\n\tbackground-repeat: no-repeat;\n\tbackground-position: center;\n\tbackground-size: cover;\n\tbox-sizing: content-box;\n\theight: ", "px;\n"])), function (props) {
  return props.isLoading ? "var(--ds-background-neutral, ".concat(N20, ")") : props.image ? '' : teamHeaderBgColor;
}, function (props) {
  return props.image ? "url(".concat(props.image, ")") : '';
}, gridSize() * 16);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var CardContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: "var(--ds-space-300, 24px)",
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  minHeight: "".concat(gridSize() * 13, "px")
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export var TeamName = styled.h6(h600, {
  textTransform: 'none',
  overflow: 'hidden',
  maxHeight: '48px',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical'
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var MemberCount = styled.div({
  color: "var(--ds-text-subtlest, ".concat(N200, ")"),
  marginTop: "var(--ds-space-050, 4px)"
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var AvatarSection = styled.div({
  marginTop: "var(--ds-space-200, 16px)",
  marginLeft: "var(--ds-space-negative-025, -2px)"
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var DescriptionWrapper = styled.div({
  marginTop: "var(--ds-space-200, 16px)",
  alignItems: 'center',
  display: 'flex'
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var Description = styled.span({
  overflow: 'hidden',
  maxHeight: '60px',
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical'
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var ActionButtons = styled.div({
  width: 'calc(100% + 8px)',
  display: 'flex',
  justifyContent: 'space-between',
  margin: "var(--ds-space-300, 24px)".concat(" ", "var(--ds-space-negative-300, -24px)", " 0 ", "var(--ds-space-negative-100, -8px)"),
  backgroundColor: "var(--ds-surface-overlay, hsla(0, 100%, 100%, 0.2))"
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var WrappedButton = styled.div({
  flexBasis: 0,
  flexGrow: 1,
  marginLeft: "var(--ds-space-100, 8px)"
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var MoreButton = styled.div({
  marginLeft: "var(--ds-space-100, 8px)"
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var LoadingWrapper = styled.div({
  textAlign: 'center',
  marginTop: "var(--ds-space-500, 40px)"
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export var AccessLockSVGWrapper = styled.div({
  marginBottom: "var(--ds-space-300, 24px)"
});

// export const LoadingTeamName = styled.div`
//   width: 175px;
//   height: ${gridSize () *3)px;
//   border-radius: ${borderRadius}px;
//   background-color: #f4f5f7;
// `;

// export const LoadingMemberCount = styled.div`
//   width: 81px;
//   height: ${gridSize () *2)px;
//   border-radius: 3px;
//   background-color: #f4f5f7;
//   margin: 4px 0;
// `;