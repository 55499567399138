import {
  createResource,
  RouteContext,
  RouterContext,
} from 'react-resource-router';

import { Repository } from 'src/components/types';
import { buildProfileRepositoryBBQL } from 'src/redux/profile/repositories/utils';
import { RepositoryFilters, RepositoryQuery } from 'src/sections/profile/types';
import urls from 'src/urls/dashboard';
import { stringify } from 'src/urls/utils';
import authRequest from 'src/utils/fetch';

import { WorkspaceRepositoryResource } from './types';

type Query = RouteContext['query'];

function isPrivacyFilter(
  privacy?: string
): privacy is RepositoryFilters['privacy'] {
  return privacy === 'private' || privacy === 'public';
}

export const DEFAULT_PAGE_LENGTH = 25;
export const DEFAULT_REPOSITORIES_SORTING = '-updated_on';

export const parseQuery = (query: Query): RepositoryQuery => {
  const parsedPage = parseInt(query.page, 10);
  return {
    filters: {
      language: query.language || undefined,
      privacy: isPrivacyFilter(query.privacy) ? query.privacy : undefined,
      search: query.search || undefined,
      watching: query.watching,
      project: query.project || undefined,
    },
    page: !isNaN(parsedPage) ? Math.max(parsedPage, 1) : 1,
    sort: query.sort || DEFAULT_REPOSITORIES_SORTING,
  };
};

export const toQueryParams = (
  query: RepositoryQuery
): { [param: string]: any } => {
  const { filters, page, sort } = query;
  const { language, privacy, search, watching, project } = filters;

  return {
    language: language || undefined,
    page: page && page > 1 ? page : undefined,
    privacy: privacy || undefined,
    search: search || undefined,
    sort: sort || undefined,
    watching: watching || undefined,
    project: project || undefined,
  };
};

export const workspaceRepositoriesResource = createResource({
  type: 'WORKSPACE_REPOSITORIES',
  getKey: ({ match, query }: RouterContext) => {
    // Strip out query params unrelated to fetching the repository list
    const parsedQuery = parseQuery(query);
    const filteredQueryString = stringify(parsedQuery);
    return `workspace:${match.params.workspaceSlug}::repositories:${filteredQueryString}`;
  },
  maxAge: 0,
  getData: async ({ match, query }) => {
    const { workspaceSlug } = match.params;

    // If we can't match the URL to a workspace slug, this resource is being used on a route
    // it shouldn't be.
    if (!workspaceSlug) {
      return null;
    }

    const { filters, page, sort } = parseQuery(query);

    const response = await fetch(
      authRequest(
        urls.api.v20.profileRepositories(workspaceSlug, {
          page,
          pagelen: DEFAULT_PAGE_LENGTH,
          sort,
          q: buildProfileRepositoryBBQL(filters),
          fields: '-values.owner,-values.workspace',
        })
      )
    );

    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`);
    }

    const {
      values: repositories,
      pagelen,
      size,
    }: {
      values: Repository[];
      pagelen: number;
      size: number;
    } = await response.json();

    return { repositories, pagelen, size } as WorkspaceRepositoryResource;
  },
});
