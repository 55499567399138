import { FormattedMessage } from 'react-intl';

export enum ActionButton {
  cancel = 'cancel',
  confirm = 'confirm',
}

export enum Appearance {
  danger = 'danger',
  warning = 'warning',
}

export enum CancelButtonText {
  cancel = 'cancel',
  close = 'close',
}

export enum ConfirmButtonText {
  confirm = 'confirm',
  remove = 'remove',
  delete = 'delete',
}

export type PredifinedActionButton = CancelButtonText | ConfirmButtonText;

export type ModalText = FormattedMessage.MessageDescriptor | string;

export type SharedProps = {
  onShow?: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  header: ModalText;
  body: React.ReactNode;
};

export type ConfirgurableProps = {
  appearance?: keyof typeof Appearance;
  buttonText?: {
    [ActionButton.cancel]?: keyof typeof CancelButtonText;
    [ActionButton.confirm]?: keyof typeof ConfirmButtonText;
  };
  customButtonText?: {
    [ActionButton.cancel]?: ModalText;
    [ActionButton.confirm]?: ModalText;
  };
};
