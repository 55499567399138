
export class ValidationUtil {

  public static checkExists(objectName: string, object: any) {
    if (!object) {
      throw new Error(`${objectName} does not exist.`);
    }
  }

  public static checkPropertyExists(objectName: string, object: any, propertyName: string) {
    const objectProperty = object[propertyName];

    if (!objectProperty) {
      throw new Error(`Expected ${objectName} to have a property: ${propertyName}.`);
    }

    if (ValidationUtil.isFunction(objectProperty)) {
      throw new Error(`${objectName} has a property ${propertyName}, but it is a function.`);
    }
  }

  public static checkFunctionExists(objectName: string, object: any, functionName: string) {
    const objectProperty = object[functionName];

    if (!objectProperty) {
      throw new Error(`Expected ${objectName} to have a function: ${functionName}.`);
    }

    if (!ValidationUtil.isFunction(objectProperty)) {
      const objectPropertyTypeInfo = typeof objectProperty + ' (value: ' + objectProperty + ')';
      throw new Error(`${objectName} has a property ${functionName}, but it is a ${objectPropertyTypeInfo} rather than a function.`);
    }
  }

  public static isFunction(object: any) {
    return typeof(object) === 'function';
  }

}

export default ValidationUtil;
