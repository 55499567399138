import {
  Invitation,
  InvitationsResponse,
} from 'src/components/types/src/api/v1/invitation';
import projectUrls from 'src/urls/projects';
import repositoryUrls from 'src/urls/repositories';
import workspaceUrls from 'src/urls/workspaces';
import { httpRequest } from 'src/utils/http-request';

export const getWorkspaceInvitations = (
  workspaceSlug: string
): Promise<InvitationsResponse> =>
  httpRequest<InvitationsResponse>().get(
    workspaceUrls.api.v10.invitations(workspaceSlug)
  );

export const getProjectInvitations = (
  workspaceSlug: string,
  projectKey: string
): Promise<InvitationsResponse> =>
  httpRequest<InvitationsResponse>({
    successTransformer: data => data.values as InvitationsResponse,
  }).get(projectUrls.api.internal.invitations(workspaceSlug, projectKey));

export const getRepositoryInvitations = (
  workspaceSlug: string,
  repoSlug: string
): Promise<InvitationsResponse> =>
  httpRequest<InvitationsResponse>({
    successTransformer: data => data.values as InvitationsResponse,
  }).get(repositoryUrls.api.internal.invitations(workspaceSlug, repoSlug));

export const getRepositoryInvitationsLegacy = (
  workspaceSlug: string,
  repoSlug: string
): Promise<InvitationsResponse> =>
  httpRequest<InvitationsResponse>().get(
    repositoryUrls.api.v10.invitations(workspaceSlug, repoSlug)
  );

export const sendWorkspaceInvitation = (
  workspaceSlug: string,
  body: { email: string; group_slug: string }
): Promise<null> =>
  httpRequest<null>({
    successCustomHandler: () => null, // this method returns "OK" on success.
  }).put(workspaceUrls.api.v10.invitations(workspaceSlug), body);

export const sendProjectInvitation = (
  workspaceSlug: string,
  projectKey: string,
  body: { email: string; permission: BB.Privilege }
): Promise<null> =>
  httpRequest<null>({
    successCustomHandler: () => null,
  }).put(projectUrls.api.internal.invitations(workspaceSlug, projectKey), body);

export const sendRepoInvitation = (
  workspaceSlug: string,
  repoSlug: string,
  body: { email: string; permission: BB.Privilege }
): Promise<null> =>
  httpRequest<null>({
    successCustomHandler: () => null,
  }).put(
    repositoryUrls.api.internal.invitations(workspaceSlug, repoSlug),
    body
  );

export const sendRepoInvitationLegacy = (
  workspaceSlug: string,
  repoSlug: string,
  body: { email: string; permission: BB.Privilege }
): Promise<null> =>
  httpRequest<null>({
    successCustomHandler: () => null,
  }).post(repositoryUrls.api.v10.invitations(workspaceSlug, repoSlug), body);

export const deleteWorkspaceInvitation = (
  workspaceSlug: string,
  body: { email: string; group_slug: string }
): Promise<undefined> =>
  httpRequest<Invitation>().delete(
    workspaceUrls.api.v10.invitations(workspaceSlug),
    body
  );

export const deleteProjectInvitation = (
  workspaceSlug: string,
  projectKey: string,
  body: Invitation
): Promise<undefined> =>
  httpRequest<Invitation>().delete(
    projectUrls.api.internal.invitations(workspaceSlug, projectKey),
    body
  );

export const deleteRepoInvitation = (
  workspaceSlug: string,
  repoSlug: string,
  body: Invitation
): Promise<undefined> =>
  httpRequest<Invitation>().delete(
    repositoryUrls.api.internal.invitations(workspaceSlug, repoSlug),
    body
  );

export const deleteRepoInvitationLegacy = (
  workspaceSlug: string,
  repoSlug: string,
  body: Invitation
): Promise<undefined> =>
  httpRequest<Invitation>().delete(
    repositoryUrls.api.v10.invitations(workspaceSlug, repoSlug),
    body
  );
