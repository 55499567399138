import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';

const PipelinesRepoDeploymentsPageLoadable = Loadable({
  loading: LoadingPage,
  loader: () => import('./deployments'),
});

export default PipelinesRepoDeploymentsPageLoadable;
