import React, { useCallback, useState } from 'react';

import Button from '@atlaskit/button';
import InlineDialog from '@atlaskit/inline-dialog';
import Spinner from '@atlaskit/spinner';

import {
  Allowance,
  Capabilities,
  Repository,
} from 'src/components/pipelines/models';

import PremiumIcon from '../assets/PremiumIcon';
import { formatDate } from '../utils';
import { getAdminPlans, getPlansPageURL } from '../utils/links';

import {
  UsageContainer,
  UsageDescription,
  UsageDialogWrapper,
  UsageHeader,
  UsageHr,
  UsageLimitedInfo,
  UsageTitle,
  UsageWrapper,
} from './styled';

export type Props = {
  allowance: Allowance;
  capabilities: Capabilities;
  repository: Repository;
  _isDialogOpen?: boolean;
};

/* eslint @typescript-eslint/ban-types: "warn" */
const Usage: React.FC<Props> = ({
  allowance,
  capabilities,
  repository,
  _isDialogOpen,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(_isDialogOpen || false);

  const toggleShowingDialog = useCallback(() => {
    setIsDialogOpen(!isDialogOpen);
  }, [setIsDialogOpen, isDialogOpen]);

  const onCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, [setIsDialogOpen]);

  return (
    <InlineDialog
      content={
        <UsageDialogWrapper>
          <UsageWrapper>
            <UsageTitle>Pipelines usage</UsageTitle>
            {!allowance.hasFetched || !capabilities.hasFetchedCapabilities ? (
              <Spinner size="small" />
            ) : (
              <div>
                {capabilities.limited ? (
                  <p>
                    <strong>{`${allowance.totalUsage.toLocaleString()} / ${allowance.quota.toLocaleString()}`}</strong>{' '}
                    free minutes used this period.
                  </p>
                ) : (
                  <p>
                    <strong>{allowance.totalUsage.toLocaleString()}</strong>{' '}
                    minutes used this billing period.
                  </p>
                )}
                {!allowance.isErrored && (
                  <span>
                    Your usage will reset on{' '}
                    {formatDate(allowance.endDate, 'date-us-short')}.
                  </span>
                )}
                {capabilities.limited && (
                  <UsageLimitedInfo>
                    <span>
                      Pipelines will stop running once free minutes are used
                      unless you update your billing details.
                    </span>
                  </UsageLimitedInfo>
                )}
                {repository.userIsAdmin ? (
                  <p>
                    <a href={getPlansPageURL(repository.path)} target="_top">
                      View plan details
                    </a>
                  </p>
                ) : null}
              </div>
            )}
          </UsageWrapper>
          {!capabilities.isPremium ? (
            <>
              <UsageHr />
              <UsageContainer>
                <UsageHeader>
                  <PremiumIcon />
                  Need more Pipelines minutes?
                </UsageHeader>
                <UsageDescription>
                  Upgrade for more minutes – plus advanced admin controls and
                  greater security features.
                </UsageDescription>
                <Button
                  appearance="primary"
                  href={getAdminPlans(
                    repository.path?.split('/').length
                      ? repository.path.split('/')[0]
                      : undefined
                  )}
                  target="_top"
                  rel="nofollow"
                >
                  Upgrade
                </Button>
              </UsageContainer>
            </>
          ) : null}
        </UsageDialogWrapper>
      }
      onClose={onCloseDialog}
      isOpen={isDialogOpen}
      placement="bottom-end"
    >
      <Button onClick={toggleShowingDialog}>Usage</Button>
    </InlineDialog>
  );
};

export default React.memo(Usage);
