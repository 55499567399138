import { Link } from './link';
import { User } from './user';

type BBWorkspace = {
  created_on: string;
  is_private: boolean;
  display_name?: string;
  extra?: {
    has_premium?: boolean;
    admin_status?: string;
    is_paid?: boolean;
  };
  links: {
    avatar: Link;
    html: Link;
    members?: Link;
    owners?: Link;
    projects?: Link;
    repositories?: Link;
    self: Link;
  };
  name: string;
  slug: string;
  type: 'workspace';
  updated_on?: string;
  uuid: string;
  is_privacy_enforced?: boolean;
  forking_mode?: WorkspaceForkOptions;
};

export enum WorkspaceForkOptions {
  INTERNAL_ONLY = 'internal_only',
  ALL = 'allow_forks',
}

export type WorkspaceMember = {
  type: 'workspace_membership';
  links: {
    self: Link;
  };
  user: User;
  workspace: BBWorkspace;
};

export type WorkspaceNavPermission =
  | 'member'
  | 'create_project'
  | 'collaborator'
  | 'admin';

declare global {
  namespace BB {
    type Workspace = BBWorkspace;
  }
}

export type Workspace = BBWorkspace;
