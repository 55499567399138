import {InlineDialogProvider} from './InlineDialogProvider';
import {SimpleXdmDefinition} from '../../definitions/SimpleXdmDefinition';
import {SimpleXdmDefinitionBuilder} from '../../definitions/SimpleXdmDefinitionBuilder';

export class InlineDialogSimpleXdmDefinitionBuilder implements SimpleXdmDefinitionBuilder {

  inlineDialogProvider: InlineDialogProvider;

  constructor(inlineDialogProvider: InlineDialogProvider) {
      if (!inlineDialogProvider) {
          throw new Error('inlineDialogProvider is not defined');
      }
      this.inlineDialogProvider = inlineDialogProvider;
  }

  buildSimpleXdmDefinition(): SimpleXdmDefinition {
    return {
      // This is not implemented yet. The implication of this is that inline dialogs must use the
      // module definition defined in the atlassian-connect-js repo which switches between the AUI
      // implementation and module provider implementation.
    };
  }

}
