import { Artifact } from 'src/components/pipelines/models';
import { LoadingStatus } from 'src/constants/loading-status';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import { REQUEST_ARTIFACTS } from '../actions/pipelines';

export type ArtifactsState = {
  map: { [key: string]: Artifact[] };
  fetchedStatus: { [key: string]: LoadingStatus };
};

export const initialState: ArtifactsState = {
  map: {},
  fetchedStatus: {},
};

export default createReducer(initialState, {
  [REQUEST_ARTIFACTS.REQUEST](
    state: ArtifactsState,
    action: Action & { meta: { stepUuid: string } }
  ) {
    if (!action.meta?.stepUuid) {
      return state;
    }
    return {
      ...state,
      fetchedStatus: {
        ...state.fetchedStatus,
        [action.meta.stepUuid]: LoadingStatus.Fetching,
      },
    };
  },
  [REQUEST_ARTIFACTS.SUCCESS](
    state: ArtifactsState,
    action: Action<{ values: any[] }> & { meta: { stepUuid: string } }
  ) {
    if (!action.payload?.values || !action.meta?.stepUuid) {
      return state;
    }

    const map = { ...state.map };

    map[action.meta.stepUuid] = action.payload.values
      .filter(artifactData => artifactData.step_uuid === action.meta.stepUuid)
      .map(data => new Artifact(data));

    return {
      ...state,
      fetchedStatus: {
        ...state.fetchedStatus,
        [action.meta.stepUuid]: LoadingStatus.Success,
      },
      map,
    };
  },
});
