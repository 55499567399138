import { createNestedMenu } from 'src/components/navigation';
import createReducer from 'src/utils/create-reducer';

import { LoadProfilePage } from './actions';
import { ProfileSectionState } from './types';

const initialState: ProfileSectionState = {
  menuItems: [],
  currentUser: undefined,
  currentWorkspace: undefined,
  activeMenuItem: '',
};

export default createReducer(initialState, {
  [LoadProfilePage.REQUEST](state) {
    return {
      ...initialState,
      ...state,
    };
  },
  [LoadProfilePage.SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
      menuItems: createNestedMenu(action.payload.menuItems),
    };
  },
});
