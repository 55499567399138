/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { takeLatest, all } from 'redux-saga/effects';

import {
  FetchRepositories,
  CLEAR_PULL_REQUEST_FILTERS,
  FILTER_PULL_REQUESTS,
  FETCH_NONCRITICAL_REPOSITORIES_DATA,
} from 'src/redux/dashboard/actions';

import filterPullRequestSaga from './filter-pull-requests-saga';
import { getDashboardRepositoryListBuildStatuses } from './get-repository-build-statuses';

export default function* dashboardSagas() {
  yield all([
    takeLatest(
      [FetchRepositories.SUCCESS],
      getDashboardRepositoryListBuildStatuses
    ),
    takeLatest(
      [FETCH_NONCRITICAL_REPOSITORIES_DATA],
      getDashboardRepositoryListBuildStatuses
    ),
    takeLatest(
      [CLEAR_PULL_REQUEST_FILTERS, FILTER_PULL_REQUESTS],
      filterPullRequestSaga
    ),
  ]);
}
