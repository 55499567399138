/**
 * Note: The flag 'window.performance.okayToSendMetrics' is
 * set inside 'performance-timing-init.jinja' in Backbucket.
 */

export function shouldSendMetrics(): boolean {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { performance } = window;

  // Guard for old browsers, e.g. Safari <= v10, to avoid polluting Sentry with errors
  if (typeof performance.mark !== 'function') {
    return false;
  }

  // Block sending a metric if tab has been backgrounded
  if (document.hidden || !performance.okayToSendMetrics) {
    return false;
  }

  return true;
}
