export type FileTreeItem = {
  name: string;
  type: 'file' | 'directory';
  contents?: FileTreeItem[];
};

const updatePath = (
  fileTree: FileTreeItem[],
  files: string[],
  path: string
) => {
  fileTree.forEach(item => {
    if (item.type === 'file') {
      files.push(`${path}${item.name}`);
    } else if (item.type === 'directory') {
      // eslint-disable-next-line no-param-reassign
      files = updatePath(
        item.contents as any,
        files,
        `${path && !path.endsWith('/') ? `${path}/` : path}${item.name}/`
      );
    }
  });
  return files;
};

export default function flattenPaths(fileTree: FileTreeItem[]): string[] {
  return updatePath(fileTree, [], '');
}
