import {BaseFrameworkAdaptor} from '../../adaptors/framework/BaseFrameworkAdaptor';
import {BaseModule} from '../base/Module';
import {ModuleDefinitions} from '../base/Module';
import {DropdownProvider} from './DropdownProvider';
import {DropdownSimpleXdmDefinitionBuilder} from './DropdownSimpleXdmDefinitionBuilder';
import {Provider} from '../base/Provider';
import {SimpleXdmDefinitionBuilder} from '../../definitions/SimpleXdmDefinitionBuilder';

export module DropdownModuleDefinitions {

  export const DROPDOWN_MODULE_NAME = 'dropdown';

  export class Props implements ModuleDefinitions.Props {

    adaptor: BaseFrameworkAdaptor;
    dropdownProvider: DropdownProvider;

    constructor(
      adaptor: BaseFrameworkAdaptor,
      dropdownProvider: DropdownProvider) {
      this.adaptor = adaptor;
      this.dropdownProvider = dropdownProvider;
    }

  }

  export class State implements ModuleDefinitions.State {

    enabled: boolean;
    registered: boolean;
    dropdownProvider: DropdownProvider;
    simpleXdmDefinitionBuilder: SimpleXdmDefinitionBuilder;

    constructor(dropdownProvider: DropdownProvider) {
      this.enabled = true;
      this.registered = false;
      this.dropdownProvider = dropdownProvider;
      this.simpleXdmDefinitionBuilder = new DropdownSimpleXdmDefinitionBuilder(dropdownProvider);
    }

  }

}

/**
 * This module provides dropdown related functionality.
 */
export class DropdownModule extends BaseModule<DropdownModuleDefinitions.Props, DropdownModuleDefinitions.State> {

  constructor(props: DropdownModuleDefinitions.Props) {
      super(props);
  }

  componentWillMount(): void {
    if (!this.props.dropdownProvider) {
        throw new Error('this.props.dropdownProvider is not defined');
    }
    this.setState(new DropdownModuleDefinitions.State(this.props.dropdownProvider));
  }

  public getModuleRegistrationName(): string {
    return DropdownModuleDefinitions.DROPDOWN_MODULE_NAME;
  }

  public getSimpleXdmDefinitionBuilder() : SimpleXdmDefinitionBuilder {
    return this.state.simpleXdmDefinitionBuilder;
  }

  getProvider(): Provider {
    return this.props.dropdownProvider;
  }

  render(): JSX.Element | null {
    return null;
  }
}
