import React from 'react';

import { AutoDismissFlag } from '@atlaskit/flag';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import commonMessages from 'src/i18n/common';
import { ComponentFlagId, HandleDismissedType } from 'src/redux/flags/types';

import messages from './pull-request-retarget.i18n';

type Props = {
  id: ComponentFlagId;
} & HandleDismissedType;

export const PullRequestRetargetErrorFlag = ({
  id,
  ...otherProps
}: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <AutoDismissFlag
      {...otherProps}
      id={id}
      description={intl.formatMessage(messages.retargetPullRequestErrorBody)}
      icon={
        <ErrorIcon
          label="error"
          primaryColor={token('color.icon.danger', colors.R300)}
        />
      }
      title={intl.formatMessage(commonMessages.errorHeading)}
    />
  );
};
