/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { put, call, select } from 'redux-saga/effects';

import { showFlagComponent } from 'src/redux/flags';
import urls from 'src/sections/repository/urls';
import {
  getCurrentRepositoryOwnerName,
  getCurrentRepositorySlug,
} from 'src/selectors/repository-selectors';
import { encodeBranchName } from 'src/urls/utils';
import authRequest from 'src/utils/fetch';

import { DELETE_BRANCH, getDeleteBranchDialogBranch } from '..';
import { DeleteBranchErrorTypes } from '../reducers/delete-branch-reducer';

import reloadBranchesSaga from './reload-branches-saga';

export const getErrorType = (status: number) => {
  switch (status) {
    case 404:
      return DeleteBranchErrorTypes.BRANCH_NOT_FOUND;
    case 403:
      return DeleteBranchErrorTypes.ACCESS_DENIED;
    default:
      return DeleteBranchErrorTypes.GENERIC;
  }
};

export default function* deleteBranchSaga() {
  const slug: string = yield select(getCurrentRepositorySlug);
  const owner: string = yield select(getCurrentRepositoryOwnerName);
  // @ts-ignore
  const branch = yield select(getDeleteBranchDialogBranch);
  const branchName = branch.name;
  const url = `${urls.api.v20.branches(owner, slug)}/${encodeBranchName(
    branch.name
  )}`;
  const request = authRequest(url, { method: 'DELETE' });

  try {
    const response: Response = yield call(fetch, request);
    if (response.ok) {
      yield put({
        type: DELETE_BRANCH.SUCCESS,
        payload: {
          branch,
          branchName: branch.name,
        },
      });
    } else {
      const errorType = getErrorType(response.status);
      yield put({
        type: DELETE_BRANCH.ERROR,
        payload: {
          branchName,
          errorType,
        },
      });
    }
  } catch (error) {
    yield put({
      type: DELETE_BRANCH.ERROR,
      payload: {
        branchName,
        errorType: DeleteBranchErrorTypes.GENERIC,
      },
    });
  }
}

export function* handleDeleteBranchSuccessSaga() {
  yield put(showFlagComponent('delete-branch-success'));
  yield* reloadBranchesSaga();
}

export function* handleDeleteBranchErrorSaga() {
  yield put(showFlagComponent('delete-branch-error'));
}
