import qs from 'qs';

export const qsDefaultOptions: qs.IStringifyOptions = {
  addQueryPrefix: true,
  skipNulls: true,
  // When requesting the diff for a renamed file we pass the before and after
  // path; the server expects the request to look like `path=before&path=after`
  // so we specify that here
  arrayFormat: 'repeat',
};

type CommentUrlOptions = {
  baseUrl?: string;
  extraQueryStringParams?: object;
};

const BASE_URL = '!api/2.0/repositories';

const DEFAULT_OPTIONS: CommentUrlOptions = {
  baseUrl: BASE_URL,
  extraQueryStringParams: {},
};

// IMPORTANT: NUM_INITIAL_CONTEXT_LINES MUST BE AT LEAST 1
// OTHERWISE CONTEXT EXPANSION WILL BREAK.
const NUM_INITIAL_CONTEXT_LINES = 3;

export function buildCommentUrl(
  user: string,
  repoSlug: string,
  pullRequestId: string | number,
  commentId: number | string = '',
  options: CommentUrlOptions = DEFAULT_OPTIONS
) {
  const { baseUrl, extraQueryStringParams } = options;

  const query = qs.stringify(
    {
      ...extraQueryStringParams,
    },
    qsDefaultOptions
  );

  const base = baseUrl || BASE_URL;
  const url = `/${base}/${user}/${repoSlug}/pullrequests/${pullRequestId}/comments/${commentId}`;

  return `${url}${query}`;
}

export const normalizeDiffParams = (params: {
  topicDiff?: boolean | null | undefined;
  ignoreWhitespace?: boolean | null | undefined;
  includeFilesOverLimit?: boolean | null | undefined;
  useForkMergebase?: boolean | null | undefined;
  excludeFiles?: string | null | undefined;
}) => {
  return {
    binary: false,
    context: NUM_INITIAL_CONTEXT_LINES,
    ignore_whitespace: params.ignoreWhitespace || null,
    exclude_files_over_limit: !params.includeFilesOverLimit,
    use_fork_mergebase: params.useForkMergebase,
    // we explicitly pass topic=null so that the topic query param is not passed in the request
    // any value other than topic=true will yield a simple diff (or 2 dot diff)
    topic: params.topicDiff ? true : null,
    ...(params.excludeFiles && { exclude_files: params.excludeFiles }),
  };
};

export const normalizeDiffStatParams = (params: {
  topicDiff?: boolean | null | undefined;
  ignoreWhitespace?: boolean | null | undefined;
  useForkMergebase?: boolean | null | undefined;
  excludeFiles?: string | null | undefined;
}) => ({
  ignore_whitespace: params.ignoreWhitespace || null,
  use_fork_mergebase: params.useForkMergebase,
  // we explicitly pass topic=null so that the topic query param is not passed in the request
  // any value other than topic=true will yield a simple diff (or 2 dot diff)
  topic: params.topicDiff ? true : null,
  pagelen: 1000,
  ...(params.excludeFiles && { exclude_files: params.excludeFiles }),
});

export function getUrlPieces(url: string) {
  const queryStart = url.indexOf('?');
  if (queryStart === -1) {
    return { location: url, params: {} };
  }

  const query = url.substring(queryStart);
  const location = url.substring(0, queryStart);

  return {
    location,
    params: qs.parse(query, { ignoreQueryPrefix: true }),
  };
}

export function addQueryParams(url: string, params: object) {
  const { location, params: oldParams } = getUrlPieces(url);
  const newParams = {
    ...oldParams,
    ...params,
  };
  const query = qs.stringify(newParams, qsDefaultOptions);
  return `${location}${query}`;
}
