/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { put, select, call, all, takeLatest } from 'redux-saga/effects';

import {
  getCurrentPullRequestUrlPieces,
  getPullRequestSourceHash,
} from 'src/redux/pull-request/selectors';
import {
  CODE_INSIGHTS_ANNOTATIONS_PAGE_SIZE,
  CODE_INSIGHTS_REPORTS_PAGE_SIZE,
} from 'src/sections/repository/constants';
import authRequest from 'src/utils/fetch';

import {
  FETCH_CODE_INSIGHTS_REPORTS,
  FETCH_CODE_INSIGHTS_ANNOTATIONS,
} from '../actions';

import { annotationsBaseUrl } from './utils/code-insights-session-auth-feature';
import { fetchAllPages } from './utils/fetch-with-access-token';

export function* fetchCodeInsightsReportsForCommit(commitHash: string) {
  const { owner, slug } = yield select(getCurrentPullRequestUrlPieces);
  const url =
    `${annotationsBaseUrl(
      '2.0'
    )}/repositories/${owner}/${slug}/commit/${commitHash}` +
    `/reports?page=1&pagelen=${CODE_INSIGHTS_REPORTS_PAGE_SIZE}`;

  try {
    let reports: Response = yield call(fetch, authRequest(url));
    // @ts-ignore
    reports = (yield reports.json()).values;
    yield put({
      type: FETCH_CODE_INSIGHTS_REPORTS.SUCCESS,
      payload: reports || [],
    });
  } catch (e) {
    yield put({
      type: FETCH_CODE_INSIGHTS_REPORTS.ERROR,
      payload: e.message,
    });
  }
}

export function* fetchCodeInsightsAnnotationsForReport(
  commitHash: string,
  reportId: string
) {
  const { owner, slug } = yield select(getCurrentPullRequestUrlPieces);
  const url =
    `${annotationsBaseUrl(
      '2.0'
    )}/repositories/${owner}/${slug}/commit/${commitHash}` +
    `/reports/${reportId}/annotations?sort=-severity&page=1`;

  try {
    const annotations: Response = yield call(
      fetchAllPages,
      url,
      CODE_INSIGHTS_ANNOTATIONS_PAGE_SIZE
    );
    yield put({
      type: FETCH_CODE_INSIGHTS_ANNOTATIONS.SUCCESS,
      meta: { reportId },
      payload: annotations || [],
    });
  } catch (e) {
    yield put({
      type: FETCH_CODE_INSIGHTS_ANNOTATIONS.ERROR,
      payload: e.message,
    });
  }
}

export function* fetchCodeInsightsReports() {
  try {
    const commitHash: string = yield select(getPullRequestSourceHash);
    yield call(fetchCodeInsightsReportsForCommit, commitHash);
  } catch (e) {
    yield put({
      type: FETCH_CODE_INSIGHTS_REPORTS.ERROR,
      payload: e.message,
    });
  }
}

export function* fetchCodeInsightsAnnotations(action: { payload: string }) {
  const commitHash: string = yield select(getPullRequestSourceHash);
  yield call(fetchCodeInsightsAnnotationsForReport, commitHash, action.payload);
}

export default function* () {
  yield all([
    takeLatest(FETCH_CODE_INSIGHTS_REPORTS.REQUEST, fetchCodeInsightsReports),
    takeLatest<{ payload: string; type: string }>(
      FETCH_CODE_INSIGHTS_ANNOTATIONS.REQUEST,
      fetchCodeInsightsAnnotations
    ),
  ]);
}
