import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';

const PipelinesWorkspaceVariablesPageLoadable = Loadable({
  loading: LoadingPage,
  loader: () => import('./variables'),
});

export default PipelinesWorkspaceVariablesPageLoadable;
