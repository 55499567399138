var filterActions = function filterActions() {
  var actions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var data = arguments.length > 1 ? arguments[1] : undefined;
  return actions.filter(function (action) {
    if (!action.shouldRender) {
      return true;
    } else if (typeof action.shouldRender !== 'function') {
      return Boolean(action.shouldRender);
    }
    return action.shouldRender(data);
  });
};
export default filterActions;