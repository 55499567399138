import { type ValueType, type OptionType } from '@atlaskit/select';

export const encodeAutocompleteFilterValues = (
  values: ValueType<OptionType, true>
): string => {
  return values.map(v => v.value).toString();
};

export const decodeAutocompleteFilterValues = (
  queryParam?: string
): ValueType<OptionType, true> => {
  if (!queryParam) return [];

  return queryParam.split(',')?.map(param => ({ label: param, value: param }));
};

export const sortQueryParamIsAscending = (queryParam: string): boolean => {
  return queryParam.substring(0, 1) === '-';
};

export const addOrToggleSort = (key: string, queryParam?: string): string => {
  if (!queryParam?.includes(key)) return key;

  return sortQueryParamIsAscending(queryParam) ? key : `-${key}`;
};

const isInvalidInput = (seconds: number) => {
  return isNaN(seconds) || seconds === Infinity || seconds < 0;
};

export function formatTestDurations(seconds: number): string {
  if (isInvalidInput(seconds)) {
    return '--';
  }

  if (seconds < 0.01) {
    return 'less than 0.01s';
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = (seconds % 60).toFixed(2);

  const parts = [];
  if (hours > 0) parts.push(`${hours}h`);
  if (minutes > 0) parts.push(`${minutes}m`);
  if (parseFloat(secs) > 0) parts.push(`${secs}s`);

  return parts.join(' ');
}
