import React, { useContext, ComponentType, useMemo } from 'react';

import { SiteMessage } from 'src/types/site-message';

export type AppData = {
  features: { [feature: string]: any };
  frontbucket_environment: string;
  frontbucket_version: string;
  navigationIsOpen: boolean;
  site_message?: SiteMessage;
  tenantId?: string;
  user?: {
    needsMarketingConsent?: boolean;
    needsTerms?: boolean;
    isAtlassian: boolean;
    isBitbucket?: boolean;
    aaid: string | null;
    uuid: string;
  };
  links: {
    backButtonUrl: string | undefined;
    overviewUrl: string;
  };
  initialContext: {
    workspace?: BB.Workspace;
    project?: BB.Project;
    repository?: BB.Repository;
  };
};

export const AppDataContext = React.createContext<AppData>({
  features: {},
  navigationIsOpen: true,
  frontbucket_environment: 'local',
  frontbucket_version: 'local',
  links: {
    overviewUrl: '',
    backButtonUrl: undefined,
  },
  initialContext: {},
});

// Intended for use with functional components that need access to app data
export const useAppData = () => {
  return useContext(AppDataContext);
};

// Intended for use with functional components that need access to an app data feature
export const useAppDataFeature = (key: string, defaultValue?: any) => {
  const appData = useAppData();
  return useMemo(
    () => (appData.features[key] || defaultValue) ?? false,
    [appData, key, defaultValue]
  );
};

// Intended for use with class components that need access to app data
export const withAppData =
  <T,>(Component: ComponentType<T>) =>
  (props: T): React.ReactElement => {
    const appData = useAppData();
    return <Component {...props} appData={appData} />;
  };

// Intended for use with class components that need access to a specific feature in app data
export const withAppDataFeature =
  (key: string, propKey?: string, defaultValue?: any) =>
  <T,>(Component: ComponentType<T>) =>
  (props: T): React.ReactElement => {
    const appData = useAppData();
    const value = useMemo(
      () => (appData.features[key] || defaultValue) ?? false,
      [appData]
    );
    const propsWithFeature = { ...props, [propKey || key]: value };
    return <Component {...propsWithFeature} />;
  };

type Props = {
  appData: AppData;
  children: React.ReactNode;
};

export const AppDataProvider = ({
  appData = {
    navigationIsOpen: true,
    features: {},
    frontbucket_environment: 'local',
    frontbucket_version: 'local',
    links: {
      overviewUrl: '',
      backButtonUrl: undefined,
    },
    initialContext: {},
  },
  children,
}: Props): JSX.Element => {
  return (
    <AppDataContext.Provider value={appData}>
      {children}
    </AppDataContext.Provider>
  );
};
