import React from 'react';

import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import { StatelessProps } from '@atlaskit/dynamic-table/types';
import Pagination from '@atlaskit/pagination';

import {
  DetailsTableWrapper,
  DetailsTableFooter,
  DetailsTablePaginationWrapper,
} from './styled';

export type Props = Pick<
  StatelessProps,
  'head' | 'rows' | 'isLoading' | 'testId'
> & {
  total: number;
  limit: number;
  page: number;
  isLoading?: boolean;
  onRowsChange?: (rows: number) => void;
  onPageChange?: (page: number) => void;
  isDisabled?: boolean;
};

const PaginatedTable = ({
  total,
  limit,
  page,
  onRowsChange,
  onPageChange,
  isDisabled,
  ...props
}: Props) => {
  const pageCount = Math.ceil(total / limit);
  const selectedIndex = Math.max(0, Math.min(pageCount - 1, page - 1));
  return (
    <>
      <DetailsTableWrapper>
        <DynamicTableStateless {...props} loadingSpinnerSize="large" />
      </DetailsTableWrapper>
      {total > limit && (
        <DetailsTableFooter isDisabled={isDisabled}>
          <DetailsTablePaginationWrapper>
            <Pagination
              pages={Array.from({ length: pageCount }, (_, i) => i + 1)}
              selectedIndex={selectedIndex}
              onChange={(_, nextPage) => onPageChange && onPageChange(nextPage)}
            />
          </DetailsTablePaginationWrapper>
        </DetailsTableFooter>
      )}
    </>
  );
};

export default PaginatedTable;
