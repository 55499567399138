/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import RepositoryLink from 'src/components/repository-link';

export const BreadcrumbsItemRepositoryLink = styled(RepositoryLink)({
  color: token('color.text.subtle', colors.N200),
  padding: `${token('space.025', '2px')} 0`,
  '&:hover': {
    color: token('color.text.subtle', colors.N200),
  },
  '&:focus': {
    color: token('color.text.subtle', colors.N200),
  },
});
