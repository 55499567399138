import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { ACTION_PENDING, ACTION_RESOLVING, ACTION_RESOLVED, ACTION_ERROR, ACTION_ERROR_FALLBACK, ACTION_UPDATE_METADATA_STATUS, ACTION_RELOADING, getStatus } from '@atlaskit/linking-common';
var isCardAction = function isCardAction(action) {
  return [ACTION_PENDING, ACTION_RESOLVING, ACTION_RESOLVED, ACTION_RELOADING, ACTION_ERROR, ACTION_ERROR_FALLBACK, ACTION_UPDATE_METADATA_STATUS].includes(action.type);
};
function persistPayloadToState(payload, state, type) {
  var nextDetails = payload;
  var nextState = _objectSpread({}, state);
  if (nextDetails) {
    nextState.status = getStatus(nextDetails);
    nextState.details = nextDetails;
  } else {
    // Keep the pre-existing data in the store. If there
    // is no data, the UI should handle this gracefully.
    nextState.status = type;
  }
  return nextState;
}
function reduceToNextState(state, action) {
  switch (action.type) {
    case ACTION_PENDING:
      {
        return {
          status: action.type
        };
      }
    case ACTION_RESOLVING:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          status: action.type
        });
      }
    case ACTION_RESOLVED:
      {
        return persistPayloadToState(action.payload, state, action.type);
      }
    case ACTION_RELOADING:
      {
        return persistPayloadToState(action.payload, state, 'resolved');
      }
    case ACTION_ERROR:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          status: action.type,
          error: action.error
        });
      }
    case ACTION_ERROR_FALLBACK:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          status: action.type,
          error: action.error,
          details: action.payload
        });
      }
    case ACTION_UPDATE_METADATA_STATUS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          metadataStatus: action.metadataStatus
        });
      }
  }
}
export var cardReducer = function cardReducer(state, action) {
  if (!isCardAction(action)) {
    return state;
  }
  var cardState = state[action.url];

  // Card may have reached the same state on account of multiple of the same
  // URL being present in an editor session. E.g. page with N links to one resource.
  var hasSameStatus = !action.ignoreStatusCheck && cardState && cardState.status === action.type;
  if (hasSameStatus) {
    return state;
  } else {
    var nextState = reduceToNextState(cardState, action);
    return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, action.url, nextState));
  }
};