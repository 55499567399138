/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import {
  borderRadius,
  colors,
  gridSize,
  fontSize,
  typography,
} from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const Panel = styled.section`
  background-color: ${token('elevation.surface', colors.N0)};
  border-radius: ${borderRadius()}px;
  text-align: left;
`;

// We use <h2> headings in the sidebar to create semantic structure and make it accessible.
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements#accessibility_concerns
export const PanelHeadingWrapper = styled.h2`
  margin-top: 0;
  margin-bottom: 0;
`;

export const PanelHeading = styled.button<{
  isCollapsed: boolean;
  isExpandable: boolean;
}>`
  align-items: center;
  background-color: ${token('elevation.surface', colors.N0)};
  border: 0 none transparent;
  border-bottom: 1px solid ${token('color.border', colors.N30)};
  border-radius: ${({ isCollapsed }) =>
    isCollapsed
      ? `${borderRadius()}px`
      : `${borderRadius()}px ${borderRadius()}px 0 0`};
  box-sizing: border-box;
  color: inherit; /* needed to prevent Safari setting to "activebuttontext" (white) */
  cursor: ${({ isExpandable }) => (isExpandable ? 'pointer' : 'inherit')};
  display: flex;
  flex-wrap: wrap;
  min-height: ${gridSize() * 5}px;
  padding: ${gridSize()}px;
  position: sticky;
  top: 0;
  z-index: 1; /* Stop avatars from hovering over this sticky heading */
  text-align: left;
  font-size: ${fontSize()}px;
  width: 100%;
  outline: none;
  line-height: inherit;

  &:hover {
    box-shadow: ${({ isCollapsed, isExpandable }) =>
      isExpandable && isCollapsed
        ? `0 0 0 1px ${token('color.background.information', colors.B200)}`
        : 'none'};
  }

  &:focus,
  &:active {
    box-shadow: ${({ isExpandable }) =>
      isExpandable
        ? `0 0 0 1px ${token('color.background.information', colors.B200)}`
        : 'none'};
  }
`;

export const PanelHeadingLabel = styled.span`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

// This is the default styling applied to @atlaskit/panel headings
// THIS IS A COPY FROM src/sections/global/components/page.style
export const PanelHeader = styled.h2({
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  ...(typography.h400() as any),
});

type PanelBodyProps = {
  isOverflowVisible?: boolean;
  panelBodyPadding?: string;
};

export const ExpanderPanelBody = styled.div`
  border-radius: 0 0 ${borderRadius()}px ${borderRadius()}px;
  flex-flow: column;
  overflow: ${({ isOverflowVisible }: PanelBodyProps) =>
    isOverflowVisible ? 'visible' : 'hidden'};
  padding: ${(props: PanelBodyProps) =>
    props.panelBodyPadding ? `${props.panelBodyPadding}` : `${gridSize()}px`};
`;

export const AnimateHeightContainer = styled.div<{
  allowMaxHeight: boolean;
}>`
  max-height: ${({ allowMaxHeight }) =>
    allowMaxHeight ? `${gridSize() * 100}px` : 'none'};
  overflow-y: ${({ allowMaxHeight }) => (allowMaxHeight ? 'auto' : 'visible')};
`;

export const Icon = styled.span`
  display: flex;
  margin-right: 4px;
`;

// allows for caller to pass in an AK Icon or
// an AK Tooltip wrapped one with any tag name specified
export const IconSecondary = styled.div`
  display: flex;
  > * {
    display: flex;
  }
`;

export const Chevron = styled.span`
  display: flex;
  margin-left: auto;
`;

export const Spinner = styled.div`
  & > div {
    display: flex;
  }
`;

export const WarningWrapper = styled.span`
  color: ${token('color.text.warning', colors.Y300)};
  display: flex;

  button {
    display: flex;
  }
`;

export const GrayDivider = styled.hr({
  backgroundColor: token('color.background.accent.gray.subtler', '#DCDFE4'),
  height: token('space.025', '2px'),
  border: 'none',
});
