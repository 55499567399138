import store from 'src/utils/store';

export const storageKey = (userId: string): string => `sitemessage::${userId}`;
export const incidentStorageKey = (userId: string): string =>
  `statuspage-incident::${userId}`;

export function shouldDisplayFlag(userId: string, messageId: number): boolean {
  if (!userId) {
    return false;
  }

  const lastDismissedMessageId = store.get(storageKey(userId), 0);
  return lastDismissedMessageId < messageId;
}

export function messageDismissed(userId: string, messageId: number): void {
  store.set(storageKey(userId), messageId);
}

export function shouldDisplayIncidentBanner(
  userId: string,
  messageId: number
): boolean {
  if (!userId) {
    return false;
  }

  const dismissedIDs = store.get(incidentStorageKey(userId), {});
  return !dismissedIDs[messageId];
}

export function incidentBannerDismissed(
  userId: string,
  messageId: number
): void {
  const dismissedIDs = store.get(incidentStorageKey(userId), {});
  dismissedIDs[messageId] = true;
  store.set(incidentStorageKey(userId), dismissedIDs);
}

export const storeDisabled = store.disabled;
