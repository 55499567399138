import React from 'react';

import qs from 'qs';
import { FormattedMessage } from 'react-intl';

import { AutoDismissFlag } from '@atlaskit/flag';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import { ComponentFlagId, HandleDismissedType } from 'src/redux/flags/types';

import messages from './delete-repository.i18n';

type Props = {
  id: ComponentFlagId;
  description: string;
} & HandleDismissedType;

const DeleteRepositorySuccessFlag = ({
  id,
  description,
  ...otherProps
}: Props): JSX.Element => {
  const intl = useIntl();
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  return (
    <AutoDismissFlag
      {...otherProps}
      id={id}
      description={
        query.repo_name ? (
          <FormattedMessage
            {...messages.deleteMessageDescription}
            values={{ repoName: <strong>{query.repo_name}</strong> }}
          />
        ) : (
          <FormattedMessage {...messages.deleteUnknownRepoDescription} />
        )
      }
      icon={
        <CheckCircleIcon
          label="Success"
          primaryColor={token('color.icon.success', colors.G300)}
        />
      }
      title={intl.formatMessage(messages.deleteMessageSuccessTitle)}
    />
  );
};

export default DeleteRepositorySuccessFlag;
