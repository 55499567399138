/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { select, call, put } from 'redux-saga/effects';

import { UNDO_REQUEST_CHANGES } from 'src/redux/pull-request/actions';
import urls from 'src/redux/pull-request/urls';
import authRequest from 'src/utils/fetch';

import { getCurrentPullRequestUrlPieces } from '../selectors';

import updateParticipantsSaga from './update-participants-saga';
import { getErrorMessage } from './utils/get-error-message';

function* undoPullRequestChangesRequest() {
  const { owner, slug, id } = yield select(getCurrentPullRequestUrlPieces);
  const url = urls.api.v20.requestChanges(owner, slug, id);
  const authUrl = authRequest(url);

  try {
    const response: Response = yield call(fetch, authUrl, { method: 'DELETE' });

    if (!response.ok) {
      const message: string = yield call(getErrorMessage, response.clone());
      throw Error(message);
    }

    yield call(updateParticipantsSaga);
    yield put({ type: UNDO_REQUEST_CHANGES.SUCCESS });
  } catch (e) {
    yield put({
      type: UNDO_REQUEST_CHANGES.ERROR,
      payload: e.message,
      error: true,
    });
  }
}

export default undoPullRequestChangesRequest;
