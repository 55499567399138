import * as colors from '@atlaskit/theme/colors';
var values = {
  // Default appearance
  background: {
    default: {
      /**
       * Some colors need specific fallback colors specified
       * to match the original Button when no theme is active.
       *
       * This is because the `xcss` automatic token fallbacks use
       * the legacy themes, which do not match original colors.
       */
      // @ts-expect-error
      default: "var(--ds-background-neutral, ".concat(colors.N20A, ")"),
      hover: 'color.background.neutral.hovered',
      active: 'color.background.neutral.pressed',
      // @ts-expect-error
      disabled: "var(--ds-background-disabled, ".concat(colors.N20A, ")"),
      // @ts-expect-error
      selected: "var(--ds-background-selected, ".concat(colors.N700, ")")
    },
    primary: {
      default: 'color.background.brand.bold',
      hover: 'color.background.brand.bold.hovered',
      active: 'color.background.brand.bold.pressed',
      // @ts-expect-error
      disabled: "var(--ds-background-disabled, ".concat(colors.N20A, ")"),
      // @ts-expect-error
      selected: "var(--ds-background-selected, ".concat(colors.N700, ")")
    },
    warning: {
      default: 'color.background.warning.bold',
      hover: 'color.background.warning.bold.hovered',
      active: 'color.background.warning.bold.pressed',
      // @ts-expect-error
      disabled: "var(--ds-background-disabled, ".concat(colors.N20A, ")"),
      // @ts-expect-error
      selected: "var(--ds-background-selected, ".concat(colors.Y400, ")")
    },
    danger: {
      default: 'color.background.danger.bold',
      hover: 'color.background.danger.bold.hovered',
      active: 'color.background.danger.bold.pressed',
      // @ts-expect-error
      disabled: "var(--ds-background-disabled, ".concat(colors.N20A, ")"),
      // @ts-expect-error
      selected: "var(--ds-background-selected, ".concat(colors.R500, ")")
    },
    link: {
      default: 'color.background.neutral.subtle',
      // @ts-expect-error
      selected: "var(--ds-background-selected, ".concat(colors.N700, ")")
    },
    subtle: {
      default: 'color.background.neutral.subtle',
      hover: 'color.background.neutral.subtle.hovered',
      active: 'color.background.neutral.subtle.pressed',
      // @ts-expect-error
      disabled: "var(--ds-background-neutral-subtle, none)",
      // @ts-expect-error
      selected: "var(--ds-background-selected, ".concat(colors.N700, ")")
    },
    'subtle-link': {
      default: 'color.background.neutral.subtle',
      // @ts-expect-error
      selected: "var(--ds-background-selected, ".concat(colors.N700, ")")
    }
  },
  color: {
    default: {
      default: 'color.text',
      active: 'color.text',
      disabled: 'color.text.disabled',
      // @ts-expect-error
      selected: "var(--ds-text-selected, ".concat(colors.N20, ")")
    },
    primary: {
      default: 'color.text.inverse',
      disabled: 'color.text.disabled',
      // @ts-expect-error
      selected: "var(--ds-text-selected, ".concat(colors.N20, ")")
    },
    warning: {
      default: 'color.text.warning.inverse',
      disabled: 'color.text.disabled',
      // @ts-expect-error
      selected: "var(--ds-text-selected, ".concat(colors.N800, ")")
    },
    danger: {
      default: 'color.text.inverse',
      disabled: 'color.text.disabled',
      // @ts-expect-error
      selected: "var(--ds-text-selected, ".concat(colors.N0, ")")
    },
    link: {
      default: 'color.link',
      hover: 'color.link',
      active: 'color.link.pressed',
      disabled: 'color.text.disabled',
      // @ts-expect-error
      selected: "var(--ds-text-selected, ".concat(colors.N20, ")")
    },
    subtle: {
      default: 'color.text',
      active: 'color.text',
      disabled: 'color.text.disabled',
      // @ts-expect-error
      selected: "var(--ds-text-selected, ".concat(colors.N20, ")")
    },
    'subtle-link': {
      default: 'color.text.subtle',
      hover: 'color.text.subtle',
      active: 'color.text',
      disabled: 'color.text.disabled',
      // @ts-expect-error
      selected: "var(--ds-text-selected, ".concat(colors.N20, ")")
    }
  }
};
export default values;