export var createSimplePubSub = function createSimplePubSub() {
  var subscribers = new Map();
  var subscribe = function subscribe(subscriber) {
    subscribers.set(subscriber, subscriber);
    return function () {
      subscribers.delete(subscriber);
    };
  };
  var publish = function publish(payload) {
    subscribers.forEach(function (callback) {
      return callback(payload);
    });
  };
  return [subscribe, publish];
};