import { ModelBase } from './ModelBase';

export enum AnnotationType {
  Vulnerability = 'VULNERABILITY',
  CodeSmell = 'CODE_SMELL',
  Bug = 'BUG',
  Unknown = 'UNKNOWN',
}

export enum AnnotationResult {
  Passed = 'PASSED',
  Failed = 'FAILED',
  Skipped = 'SKIPPED',
  Ignored = 'IGNORED',
  Unknown = 'UNKNOWN',
}

export enum AnnotationSeverity {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
  Critical = 'CRITICAL',
  Unknown = 'UNKNOWN',
}

export class Annotation extends ModelBase {
  readonly uuid: string = '';
  readonly annotation_type: AnnotationType | null = null;
  readonly type: string = '';
  readonly external_id: string = '';
  readonly summary: string = '';
  readonly details: string = '';
  readonly path: string = '';
  readonly line: number = -1;
  readonly result: AnnotationResult | null = null;
  readonly severity: AnnotationSeverity | null = null;
  readonly link: string = '';
  readonly created_on: string = '';
  readonly updated_on: string = '';

  constructor(props: Partial<Annotation> = {}) {
    super(props);
    Object.assign(this, props);
    Object.freeze(this);
  }
}
