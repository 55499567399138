import { stringify } from './utils';

type QueryParams = {
  pagelen?: number;
  page?: number;
  q?: string | null;
  watching?: boolean | null;
  sort?: string | null;
  privacy?: 'private' | 'public' | null;
  fields?: string | null;
};

type ProjectQueryParams = {
  pagelen?: number;
  fields?: string;
  q?: string | null;
};

export default {
  api: {
    internal: {
      dashboardRepositories: (queryParams?: QueryParams): string => {
        const url = `/!api/internal/dashboard/repositories`;
        return `${url}${stringify(queryParams || {})}`;
      },

      navigation: (): string => {
        return `/!api/internal/menu/dashboard`;
      },

      repositoryOwners: (query: string): string => {
        const url = `/!api/internal/dashboard/repository-owners`;
        return `${url}${stringify({ query })}`;
      },

      repositoryBuildStatuses: (): string => {
        return `/!api/internal/repository-statuses`;
      },

      repositoryProjects: (queryParams?: ProjectQueryParams): string => {
        const url = `/!api/internal/dashboard/repository-projects`;
        return `${url}${stringify(queryParams || {})}`;
      },

      overviewViewState: (): string => {
        return `/!api/internal/dashboard/overview-view-state`;
      },
    },
    v20: {
      profileRepositories: (
        uuid: string,
        queryParams?: QueryParams
      ): string => {
        const url = `/!api/2.0/repositories/${uuid}`;
        return `${url}${stringify(queryParams || {})}`;
      },
      filterUserWorkspaces: (search?: string): string => {
        const url = '/!api/2.0/workspaces';
        const query = { q: `name~"${search}"` };
        return `${url}${search ? stringify(query) : null}`;
      },
    },
  },
  ui: {
    root: (): string => '/',
    overview: (): string => '/dashboard/overview',
    repositories: (search?: string): string => {
      const url = `/dashboard/repositories`;
      return `${url}${stringify({ search })}`;
    },
  },
};
