import { defineMessages } from 'react-intl';

export default defineMessages({
  description: {
    id: 'frontbucket.workspace.settings.flags.defaultGroupIntroFlag.description',
    description: 'Default group introduction flag description',
    defaultMessage:
      'Default groups are needed for members to invite new people into this workspace. Bitbucket will make a default group for you if you don’t create one the next time an invite is sent to new user.',
  },
  title: {
    id: 'frontbucket.workspace.settings.flags.defaultGroupIntroFlag.title',
    description: 'Default group introduction flag title',
    defaultMessage: 'Introducing default groups',
  },
  chooseExistingAction: {
    id: 'frontbucket.workspace.settings.flags.defaultGroupIntroFlag.chooseExistingAction',
    description: 'Text for choosing existing group as default action item',
    defaultMessage: 'Choose existing group',
  },
  createDefaultGroupAction: {
    id: 'frontbucket.workspace.settings.flags.defaultGroupIntroFlag.createDefaultGroupAction',
    description: 'Text for create default group action item',
    defaultMessage: 'Create default group',
  },
});
