// temporary while using mock data
export const failureCorrelationHasWarning = (failureCorrelation: any) =>
  failureCorrelation > 0.1;

// temporary while using mock data
export const averageDurationHasWarning = (averageDuration: any) =>
  averageDuration > 0.5;

// temporary while using mock data
export const executionTimeHasWarning = (
  executionTime: any,
  averageExecutionTime: any
) => executionTime > averageExecutionTime;

// temporary while using mock data
export const getVariance = (executionTime: any, averageExecutionTime: any) =>
  (executionTime - averageExecutionTime).toFixed(2);

// temporary while using mock data
export const varianceHasWarning = (variance: any) => variance > 0;
