import {DialogProvider} from './DialogProvider';
import {SimpleXdmDefinition} from '../../definitions/SimpleXdmDefinition';
import {SimpleXdmDefinitionBuilder} from '../../definitions/SimpleXdmDefinitionBuilder';

export class DialogSimpleXdmDefinitionBuilder implements SimpleXdmDefinitionBuilder {

  dialogProvider: DialogProvider;

  constructor(dialogProvider: DialogProvider) {
      if (!dialogProvider) {
          throw new Error('dialogProvider is not defined');
      }
      this.dialogProvider = dialogProvider;
  }

  buildSimpleXdmDefinition(): SimpleXdmDefinition {
    return {
      // This is not implemented yet. The implication of this is that dialogs must use the
      // module definition defined in the atlassian-connect-js repo which switches between the AUI
      // implementation and module provider implementation.
    };
  }

}
