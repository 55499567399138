import { ProductIds } from '@atlassian/ipm-choreographer';
import { envType } from '@atlassiansox/analytics-web-client';
import { CoordinationClient } from '@atlassiansox/engagekit-ts';

import { BbEnv, getBbEnv } from 'src/utils/bb-env';

let globalCoordinationClient: CoordinationClient | null = null;

export const getAnalyticsEnvironment = (): envType => {
  const env = getBbEnv();
  if (env === BbEnv.Production) {
    return envType.PROD;
  } else if (env === BbEnv.Staging) {
    return envType.STAGING;
  } else {
    return envType.DEV;
  }
};

export const getCoordinationClient = (): CoordinationClient => {
  if (!globalCoordinationClient) {
    // We don't have cloudIds, we use the environment instead. Default to development
    // if we can't find an environment since the CoordinationClient requires a cloudId
    const cloudId = getBbEnv() || BbEnv.Development;
    const stargateUrl = '/gateway/api';
    const coordinationOptions = {
      enableChoreographer: true,
      envType: getAnalyticsEnvironment(),
      additionalData: { sourceProductId: ProductIds.BITBUCKET },
    };

    globalCoordinationClient = new CoordinationClient({
      cloudId,
      stargateUrl,
      coordinationOptions,
    });
  }
  return globalCoordinationClient;
};
