export var extractPlatformIsSupported = function extractPlatformIsSupported(preview, platform) {
  if (preview) {
    // By default, we support previews everywhere.
    if (typeof preview === 'string') {
      return true;
    } else {
      var supportedPlatforms = preview['atlassian:supportedPlatforms'];
      if (supportedPlatforms) {
        var isWeb = platform === 'web';
        return isWeb && supportedPlatforms.includes('web') || !isWeb && supportedPlatforms.includes('mobile');
      }
      // No supported platforms - assume they are all supported.
      return true;
    }
  }

  // No preview, don't try and render it on any platforms.
  return false;
};