import { createResource } from 'react-resource-router';

import {
  GetPermissionsOptions,
  PermissionsState,
  mapInheritedUserAndGroupPrivilege,
  InheritedPrivilegesResponse,
  buildBBQL,
  getAdminLevel,
} from 'src/components/settings/permissions';
import urls from 'src/urls/projects';
import { request } from 'src/utils/http-request';
import { hasWorkspaceAdminStatus } from 'src/utils/workspace-admin-status';

export const fetchPermissions = (
  workspaceSlug: string,
  projectKey: string,
  {
    page = 1,
    accessLevel,
    permission,
    principalName,
  }: GetPermissionsOptions = {}
) => {
  return request<
    Pick<PermissionsState, 'permissions' | 'page' | 'limit' | 'total'>
  >(
    urls.api.internal.inheritedPermissions(workspaceSlug, projectKey, {
      page,
      q: buildBBQL({ accessLevel, permission, principalName }),
    }),
    {
      successTransformer: ({
        values = [],
        page: pagenum,
        pagelen,
        size,
      }: InheritedPrivilegesResponse) => {
        return {
          permissions: new Map([
            [pagenum, values.map(mapInheritedUserAndGroupPrivilege)],
          ]),
          page: pagenum,
          limit: pagelen,
          total: size,
        };
      },
    }
  );
};

export const loadProjectSettingsPermissionsResource = createResource({
  type: 'projectAdminPermissions',
  getKey: ({ match }) => {
    const { workspaceSlug, projectKey } = match.params;
    return urls.ui.permissions(workspaceSlug!, projectKey!);
  },
  maxAge: 60000, // cache for 1 minute so navigating between pages is fast
  getData: async ({ match }) => {
    const { workspaceSlug, projectKey } = match.params;
    const [permissions, isWorkspaceAdmin] = await Promise.all([
      fetchPermissions(workspaceSlug!, projectKey!),
      hasWorkspaceAdminStatus(workspaceSlug!),
    ]);
    return {
      ...permissions,
      adminLevel: getAdminLevel(isWorkspaceAdmin, true),
    } as Pick<
      PermissionsState,
      'permissions' | 'page' | 'limit' | 'total' | 'adminLevel'
    >;
  },
});
