import { flatten } from '../utils/flatten';
import { pick } from '../utils/pick';

import { ModelBase } from './ModelBase';

export class CheckRun extends ModelBase {
  readonly uuid: string = '';
  readonly commit_hash: string = '';
  readonly check_run_type: 'JSD_GATING' | string = '';
  readonly check_suite: any = {};
  readonly url: any = {};

  // flattened properties
  readonly 'check_suite.uuid': string = '';
  readonly 'url.text': string = '';
  readonly 'url.href': string = '';

  constructor(props: Partial<CheckRun> = {}) {
    super(props);
    Object.assign(this, {
      ...pick(flatten(props), Object.keys(this)),
    });
    Object.freeze(this);
  }

  get isJsdGatingCheckRun(): boolean {
    return this.check_run_type === 'JSD_CHANGE_GATING';
  }
}

export class CheckSuite extends ModelBase {
  readonly uuid: string = '';
  readonly check_runs: string[] = [];
  readonly triggerer: any = {};

  // flattened properties
  'triggerer.type': 'DEPLOYMENT' | string = '';
  'triggerer.uuid' = '';

  constructor(props: Partial<CheckSuite> = {}) {
    super(props);
    Object.assign(this, {
      ...pick(flatten(props), Object.keys(this)),
      check_runs: props.check_runs, // preserve array
    });
    Object.freeze(this);
  }

  get isDeploymentCheckSuite(): boolean {
    return this['triggerer.type'] === 'DEPLOYMENT';
  }
}

export class Checks extends ModelBase {
  readonly check_runs: { [key: string]: CheckRun } = {};
  readonly check_suites: { [key: string]: CheckSuite } = {};

  constructor(props: Partial<Checks> = {}) {
    super(props);
    if (Object.values(props).length === 0) {
      Object.assign(this, props);
    } else {
      const check_runs = Object.keys(props.check_runs || {}).reduce(
        (runs, key) => {
          runs[key] = new CheckRun(props.check_runs?.[key]);
          return runs;
        },
        {} as any
      );
      const check_suites = Object.keys(props.check_suites || {}).reduce(
        (runs, key) => {
          runs[key] = new CheckSuite(props.check_suites?.[key]);
          return runs;
        },
        {} as any
      );
      Object.assign(this, { check_runs, check_suites });
    }
    Object.freeze(this);
  }

  get deploymentCheckSuites(): CheckSuite[] {
    return Object.values(this.check_suites).filter(
      checkSuite => !!checkSuite?.isDeploymentCheckSuite
    );
  }

  get jsdGatingCheckRuns(): CheckRun[] {
    return Object.values(this.check_runs).filter(
      checkRun => !!checkRun?.isJsdGatingCheckRun
    );
  }
}
