import { createResource } from 'react-resource-router';

import {
  TestCase,
  TestCases,
} from 'src/components/pipelines/models/TestReport';
import envBaseUrl from 'src/components/pipelines/utils/env-base-url';
import authRequest from 'src/utils/fetch';

export type Params = {
  repositoryFullSlug?: string;
  repositoryOwner?: string; // legacyPath
  repositorySlug?: string; // legacyPath
};

export function getTestCasesUrl(params: Params, page: string) {
  const {
    repositoryFullSlug = `${params.repositoryOwner}/${params.repositorySlug}`,
  } = params;
  return `${envBaseUrl(
    '/!api/internal'
  )}/repositories/${repositoryFullSlug}/test-cases?page=${page}`;
}

export const loadTestCasesResource = createResource({
  type: 'pipelinesTestCases',
  getKey: ({ match, query }) =>
    `${getTestCasesUrl(match.params, query.page || '1')}`,
  maxAge: 60000,
  getData: async ({ match, query }) => {
    const testCasesData = await (
      await fetch(
        authRequest(`${getTestCasesUrl(match.params, query.page || '1')}`)
      )
    ).json();

    return new TestCases({
      page: testCasesData.page,
      pagelen: testCasesData.pagelen,
      size: testCasesData.size,
      values: testCasesData.values.map((testCase: TestCase) => ({
        uuid: testCase.uuid,
        name: testCase.name,
        suiteName: testCase.suite_name,
        fullyQualifiedName: testCase.fully_qualified_name,
        className: testCase.class_name,
        customProperties: testCase.custom_properties,
        duration: testCase.duration,
        metrics: {
          p90Duration: testCase.metrics.p90_duration,
          p10Duration: testCase.metrics.p10_duration,
          averageDuration: testCase.metrics.average_duration,
          failureRate: testCase.metrics.failure_rate,
        },
      })),
    });
  },
});
