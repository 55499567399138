import React from 'react';

import { AutoDismissFlag } from '@atlaskit/flag';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import { ComponentFlagId, HandleDismissedType } from 'src/redux/flags/types';

import messages from './load-context-flag.i18n';

type Props = {
  id: ComponentFlagId;
  description: string;
} & HandleDismissedType;

export const LoadContextErrorFlag = ({
  id,
  description,
  ...otherProps
}: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <AutoDismissFlag
      {...otherProps}
      id={id}
      description={intl.formatMessage(messages.loadContextErrorDescription)}
      icon={
        <ErrorIcon
          label="Error"
          primaryColor={token('color.icon.danger', colors.R300)}
        />
      }
      title={intl.formatMessage(messages.loadContextErrorTitle)}
    />
  );
};
