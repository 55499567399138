import { createResource } from 'react-resource-router';

import { setSteps } from 'src/components/pipelines/redux/actions/pipelines';
import { getStepsUrl } from 'src/components/pipelines/redux/sagas/steps';
import { ResourceContext } from 'src/router/types';

import { fetchData, hasFetchedSteps } from './utils';

export type Params = {
  pipelineUuid: string;
  stepUuid: string;
  repositoryFullSlug: string;
  trigger: string;
};

export type Query = {
  run: string;
};

export const getUrl = (params: Params, query: Query): string => {
  return getStepsUrl(params.repositoryFullSlug, params.pipelineUuid, query.run);
};

export const loadStepsResource = createResource({
  type: 'steps',
  getKey: ({ match: { params }, query }) =>
    getUrl(params as Params, query as Query),
  maxAge: 0,
  getData: async (
    { match: { params }, query },
    { reduxStore }: ResourceContext
  ) => {
    const { dispatch, getState } = reduxStore;

    if (hasFetchedSteps(getState())) {
      return { status: 'success' };
    }

    const data = await fetchData(
      getUrl(params as Params, query as Query),
      'steps_resource_exception'
    );
    dispatch(setSteps(data, params.stepUuid, query.run));
    return { status: 'success' };
  },
});
