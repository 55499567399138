import { Cache, CacheVersion } from 'src/components/pipelines/models';
import { LoadingStatus } from 'src/constants/loading-status';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import {
  REQUEST_CACHES,
  REQUEST_DELETE_CACHE,
  REQUEST_DELETE_CACHES,
} from '../actions/pipelines';

export type CachesState = {
  list: Cache[];
  fetchedStatus: LoadingStatus;
};

type DeleteCacheRequestActionMeta = {
  cache: Cache;
  cacheUuid?: string;
};

export const initialState: CachesState = {
  list: [],
  fetchedStatus: LoadingStatus.Before,
};

const convertToCacheList = (caches: any[]) => {
  const cacheMap: Record<string, Cache> = {};
  caches.forEach(cache => {
    if (!cacheMap[cache.name])
      cacheMap[cache.name] = new Cache({
        name: cache.name,
        path: cache.path,
        pipeline_uuid: cache.pipeline_uuid,
        versions: [],
      });
    cacheMap[cache.name].versions.push(
      new CacheVersion({
        uuid: cache.uuid,
        created_on: cache.created_on,
        file_size_bytes: cache.file_size_bytes,
        key_hash: cache.key_hash || '',
      })
    );
  });
  return Object.values(cacheMap);
};

const removeCacheVersion = (
  caches: Cache[],
  { cache, cacheUuid }: DeleteCacheRequestActionMeta
) =>
  caches
    .map(c => {
      if (c.name === cache.name) {
        return {
          ...c,
          versions: c.versions.filter(version => version.uuid !== cacheUuid),
        };
      }
      return c;
    })
    .filter(c => c.versions.length !== 0);

const restoreRemovedCache = (caches: Cache[], removedCache: Cache) => {
  const cacheGroupExists = caches.find(
    cache => cache.name === removedCache.name
  );
  if (!cacheGroupExists) {
    return [...caches, removedCache];
  } else {
    return caches.map(cache => {
      if (cache.name === removedCache.name) {
        return removedCache;
      }
      return cache;
    });
  }
};

export default createReducer(initialState, {
  [REQUEST_CACHES.REQUEST](state: CachesState) {
    return {
      ...state,
      fetchedStatus: LoadingStatus.Fetching,
    };
  },
  [REQUEST_CACHES.SUCCESS](
    state: CachesState,
    action: Action<{ values: any[] }>
  ) {
    if (!action.payload?.values) {
      return state;
    }
    return {
      ...state,
      list: convertToCacheList(action.payload.values),
      fetchedStatus: LoadingStatus.Success,
    };
  },
  [REQUEST_DELETE_CACHE.REQUEST](
    state: CachesState,
    action: Action & { meta: DeleteCacheRequestActionMeta }
  ) {
    return {
      ...state,
      list: removeCacheVersion(state.list, action.meta),
    };
  },
  [REQUEST_DELETE_CACHE.ERROR](
    state: CachesState,
    action: Action & { meta: DeleteCacheRequestActionMeta }
  ) {
    return {
      ...state,
      list: restoreRemovedCache(state.list, action.meta.cache),
    };
  },
  [REQUEST_DELETE_CACHES.REQUEST](
    state: CachesState,
    action: Action & { meta: { cache: Cache } }
  ) {
    return {
      ...state,
      list: state.list.filter(cache => cache.name !== action.meta.cache.name),
    };
  },
  [REQUEST_DELETE_CACHES.ERROR](
    state: CachesState,
    action: Action & { meta: { cache: Cache } }
  ) {
    return {
      ...state,
      list: [...state.list, action.meta.cache],
    };
  },
});
