import { Annotation, Report } from 'src/components/pipelines/models';
import { LoadingStatus } from 'src/constants/loading-status';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import {
  REQUEST_ANNOTATIONS,
  REQUEST_REPORT,
  REQUEST_REPORTS,
} from '../actions/pipelines';

export type ReportsState = {
  list: Report[];
  annotations: Annotation[];
  fetchedAnnotationStatus: LoadingStatus;
  fetchedStatus: LoadingStatus;
};

export const initialState: ReportsState = {
  list: [],
  annotations: [],
  fetchedAnnotationStatus: LoadingStatus.Before,
  fetchedStatus: LoadingStatus.Before,
};

export default createReducer(initialState, {
  [REQUEST_REPORTS.REQUEST](state: ReportsState) {
    return {
      ...state,
      list: [],
      annotations: [],
      fetchedStatus: LoadingStatus.Fetching,
    };
  },
  [REQUEST_REPORTS.SUCCESS](
    state: ReportsState,
    action: Action<{ values: any[] }>
  ) {
    if (!action.payload?.values) {
      return state;
    }

    return {
      ...state,
      fetchedStatus: LoadingStatus.Success,
      list: action.payload.values.map(data => new Report(data)),
    };
  },
  [REQUEST_REPORTS.ERROR](state: ReportsState) {
    return {
      ...state,
      fetchedStatus: LoadingStatus.Failed,
    };
  },
  [REQUEST_REPORT.SUCCESS](state: ReportsState, action: Action) {
    if (!action?.payload) {
      return state;
    }

    const list = [...state.list];
    const report = new Report(action.payload);
    const index = state.list.findIndex(r => r?.uuid === report.uuid);
    if (index !== -1) {
      list[index] = report;
    } else {
      list.push(report);
    }

    return {
      ...state,
      list,
    };
  },
  [REQUEST_ANNOTATIONS.REQUEST](state: ReportsState) {
    return {
      ...state,
      annotations: [],
      fetchedAnnotationStatus: LoadingStatus.Fetching,
    };
  },
  [REQUEST_ANNOTATIONS.SUCCESS](state: ReportsState, action: Action<any[]>) {
    if (!action.payload) {
      return state;
    }
    return {
      ...state,
      annotations: action.payload.map(data => new Annotation(data)),
      fetchedAnnotationStatus: LoadingStatus.Success,
    };
  },
  [REQUEST_ANNOTATIONS.ERROR](state: ReportsState) {
    return {
      ...state,
      fetchedAnnotationStatus: LoadingStatus.Failed,
    };
  },
});
