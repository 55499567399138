export class WebHookConfiguration {
  readonly project: string = '';
  readonly repository: string = '';
  readonly enabled: boolean = false;

  constructor(props: Partial<WebHookConfiguration> = {}) {
    Object.assign(this, props);
    Object.freeze(this);
  }
}

export class ShadowRepositoryConfiguration {
  readonly branch_filter: string = '';
  readonly release_branch_filter: string = '';
  readonly webhook_config: WebHookConfiguration | null;

  constructor(props: Partial<ShadowRepositoryConfiguration> = {}) {
    Object.assign(this, props);
    Object.freeze(this);
  }
}
