/* eslint-disable react/no-unescaped-entities */
import React, { useMemo } from 'react';

import Button from '@atlaskit/button';
import EditorWarningIcon from '@atlaskit/icon/glyph/editor/warning';
import JiraFailedBuildStatusIcon from '@atlaskit/icon/glyph/jira/failed-build-status';
import * as colors from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { MetricUsageStatusType, SERVICE_CONTAINERS } from '../../constants';
import { MetricsUsageCard, MetricsUsageSideIcon } from '../styled';

export interface Props {
  status: MetricUsageStatusType;
  stepSize: number;
  warningContainers: string[];
}

const warningMessageData = [
  {
    key: 1,
    text: ({ warningContainers }: any) => (
      <>Container '{warningContainers[0]}' is nearing the memory limit.</>
    ),
  },
  {
    key: 2,
    text: ({ warningContainers }: any) => (
      <>
        Container '{warningContainers[0]}' and '{warningContainers[1]}' are
        nearing the memory limit.
      </>
    ),
  },
  {
    key: 3,
    text: ({ warningContainers, numSecondaryContainers }: any) => (
      <>
        {warningContainers[0]} and {numSecondaryContainers} other are nearing
        the memory limit.
      </>
    ),
  },
];

const errorMessageData = [
  {
    key: 1,
    text: ({ warningContainers }: any) => (
      <>Container '{warningContainers[0]}' exceeded the memory limit.</>
    ),
  },
  {
    key: 2,
    text: ({ warningContainers }: any) => (
      <>
        Container '{warningContainers[0]}' and '{warningContainers[1]}' exceeded
        the memory limit.
      </>
    ),
  },
  {
    key: 3,
    text: ({ warningContainers, numSecondaryContainers }: any) => (
      <>
        Container '{warningContainers[0]}' and {numSecondaryContainers} others
        exceeded the memory limit.
      </>
    ),
  },
];

/* eslint @typescript-eslint/ban-types: "warn" */
export const MetricsUsageMessage: React.FC<Props> = React.memo(
  ({ status, stepSize, warningContainers }) => {
    const metricMessage = useMemo(() => {
      const numOomContainers =
        warningContainers.length > 3 ? 3 : warningContainers.length;

      return status === MetricUsageStatusType.Error
        ? errorMessageData.filter(l => l.key === numOomContainers)[0]
        : warningMessageData.filter(l => l.key === numOomContainers)[0];
    }, [status, warningContainers]);

    const numSecondaryContainers = warningContainers.length - 1;

    if (stepSize === undefined) {
      return null;
    }

    return (
      <MetricsUsageCard usageStatus={status}>
        <MetricsUsageSideIcon>
          {status === MetricUsageStatusType.Warning && (
            <EditorWarningIcon
              label={status}
              size="small"
              primaryColor={token('color.icon.warning', colors.Y500)}
            />
          )}
          {status === MetricUsageStatusType.Error && (
            <JiraFailedBuildStatusIcon
              label={status}
              size="small"
              primaryColor={token('color.icon.danger', colors.R400)}
            />
          )}
        </MetricsUsageSideIcon>
        <div>
          <strong>{status}</strong>
          <br />
          {metricMessage.text({
            warningContainers,
            numSecondaryContainers,
          })}
          <br />
          {stepSize < 2 && (
            <>
              <strong>Hint:</strong> You can define the step size using
              <br />
              <pre
                style={{
                  background: MetricUsageStatusType.Error
                    ? colors.Y50
                    : '#FFEBE6',
                }}
              >
                size: 2x
              </pre>{' '}
              to double the memory
              <br />
              <br />
            </>
          )}
          <Button
            spacing="none"
            appearance="link"
            href={SERVICE_CONTAINERS}
            target="_blank"
          >
            Learn more about service memory limits
          </Button>
        </div>
      </MetricsUsageCard>
    );
  }
);
