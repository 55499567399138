/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { eventChannel } from 'redux-saga';
import Sockette from 'sockette';

import { getWebSocketUrl } from '../utils/env-base-url';

import { WEBSOCKET_RECONNECT } from './actions/pipelines';

export default (function () {
  let sendResolver: any;
  let send: (event: any) => null | Promise<any> = () => null;
  const sendPromise = new Promise(function (resolve) {
    sendResolver = resolve;
  });
  const channel: any = eventChannel(emit => {
    const wsUrl = getWebSocketUrl();
    let socket: any;
    const onmessage = (e: any) => {
      let data: any = null;
      try {
        data = JSON.parse(e.data);
        if (data.event) {
          emit({ ...data, type: data.event, from_ws: true });
        }
      } catch (error) {
        /* empty */
      }
    };
    const onopen = () => {
      if (sendResolver) {
        sendResolver();
        sendResolver = null;
      } else {
        emit({ type: WEBSOCKET_RECONNECT });
      }
    };
    const getSocket = () => {
      if (!socket) {
        socket = new Sockette(wsUrl, { onmessage, onopen });
      }
      return new Promise(function (resolve) {
        sendPromise.then(() => resolve(socket));
      });
    };
    send = event => getSocket().then((s: WebSocket) => s?.send(event));
    return () => getSocket().then((s: WebSocket) => s?.close());
  });
  channel.send = send;
  return channel;
})();
