import React, { ReactNode } from 'react';

import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';

import { useAnalytics } from 'src/components/settings/analytics';
import { Repository } from 'src/components/types';
import {
  getRepoOwnerName,
  getRepoOwnerUrl,
} from 'src/sections/repository/utils/repository-owner';
import WorkspaceIcon from 'src/sections/workspace/components/workspace-icon';
import globalUrls from 'src/urls';

import * as styles from './breadcrumbs.style';

type RepositoryBreadcrumbsProps = {
  children?: ReactNode;
  isRoot?: boolean;
  repository: Repository | null | undefined;
};

/* eslint @typescript-eslint/ban-types: "warn" */
const RepositoryBreadcrumbs: React.FC<RepositoryBreadcrumbsProps> = ({
  repository,
  isRoot = false,
  children,
}) => {
  const { publishBreadcrumbClickEvent } = useAnalytics();

  const projectUrl = () => {
    if (!repository) {
      return undefined;
    }

    const { owner, project } = repository;

    if (!project) {
      return undefined;
    }

    if (project.links) {
      return project.links.html.href;
    }

    return owner ? globalUrls.ui.project(owner.uuid, project.key) : undefined;
  };

  if (!repository) {
    return null;
  }
  const { project, slug } = repository;

  const LinkComponent = React.forwardRef(function BreadcrumbsItemRepositoryLink(
    // explicitly don't pass through props
    _props,
    ref: React.Ref<HTMLAnchorElement>
  ) {
    return (
      <styles.BreadcrumbsItemRepositoryLink
        // @ts-ignore Emotion's types are a bit wonky
        ref={ref}
        onClick={() => publishBreadcrumbClickEvent('RepositoryLink')}
        repository={repository}
      />
    );
  });

  const workspaceIcon = <WorkspaceIcon workspace={repository.workspace} />;

  return (
    <Breadcrumbs>
      <BreadcrumbsItem
        href={getRepoOwnerUrl(repository)}
        text={getRepoOwnerName(repository) || ''}
        onClick={() => publishBreadcrumbClickEvent('WorkspaceLink')}
        iconBefore={workspaceIcon}
      />
      {project && (
        <BreadcrumbsItem
          href={projectUrl()}
          text={project.name}
          onClick={() => publishBreadcrumbClickEvent('ProjectLink')}
        />
      )}
      {slug && !isRoot && (
        // `text` is not actually used, but it's a required prop
        <BreadcrumbsItem component={LinkComponent} text={slug} />
      )}
      {children}
    </Breadcrumbs>
  );
};

export default RepositoryBreadcrumbs;
