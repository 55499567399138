import React from 'react';

/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { gridSize } from '@atlaskit/theme';

import { GlobalError as ErrorBoundary } from 'src/components/error-boundary';

const SettingsVerticalAlignCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: ${gridSize() * 8}px;
  box-sizing: border-box;
`;

export const GlobalError = () => (
  <SettingsVerticalAlignCenter>
    <ErrorBoundary />
  </SettingsVerticalAlignCenter>
);
