import {BaseFrameworkAdaptor} from './BaseFrameworkAdaptor';
// Removing the dependency on connect-client to ensure we don't bloat out the size of
// the code unnecessarily. The following line will be re-instated during the migration
// to CaaS.
//import { defineModule } from '@atlassian/connect-client';
import {SimpleXdmDefinition} from '../../definitions/SimpleXdmDefinition';

/**
 * This class provides the Connect client framework adaptor implementation for CaaS.
 */
class CaaSFrameworkAdaptor extends BaseFrameworkAdaptor {

  public registerModuleWithHost(moduleName: string, simpleXdmDefinition: SimpleXdmDefinition): void {
    // Removing the dependency on connect-client to ensure we don't bloat out the size of
    // the code unnecessarily. The following line will be re-instated during the migration
    // to CaaS.
    //defineModule(moduleName, simpleXdmDefinition);
  }

}

/**
 * Use only this instance of CaaSFrameworkAdaptor.
 */
export const caasFrameworkAdaptor = new CaaSFrameworkAdaptor() as BaseFrameworkAdaptor;
