export function delay(fn: () => Promise<any>, wait: number) {
  return new Promise((resolve, reject) => {
    window.setTimeout(() => {
      fn().then(resolve, reject);
    }, wait);
  });
}

export function debouncePromise(fn: any, wait: number) {
  let timer: number;
  return (...args: any[]) => {
    clearTimeout(timer);
    return new Promise(resolve => {
      timer = window.setTimeout(() => resolve(fn(...args)), wait);
    });
  };
}

export function syncPromise(fn: any) {
  let promise = Promise.resolve();
  return (...args: any[]) => {
    promise = promise.then(() => fn(...args));
    return promise;
  };
}

export const debounceSyncPromise = (fn: any, wait: number) =>
  debouncePromise(syncPromise(fn), wait);
