import Loadable from 'react-loadable';

import DeploymentsLoadable from 'src/components/pipelines/pages/deployments';
import PipelinesLoadable from 'src/components/pipelines/pages/pipelines';
import { TestManagementLoadable } from 'src/components/pipelines/pages/test-management';
import { TestExecutionLoadable } from 'src/components/pipelines/pages/test-management/test-execution';
import { loadAllowanceResource } from 'src/components/pipelines/resources/allowance';
import { loadPipelinesRepositoryAssociationSummaryResource } from 'src/components/pipelines/resources/association-summary';
import { loadCurrentPipelineResource } from 'src/components/pipelines/resources/current-pipeline';
import { loadDeploymentDashboardResource } from 'src/components/pipelines/resources/deployment-dashboard';
import { loadRepositoryDynamicPipelinesResource } from 'src/components/pipelines/resources/dynamic-pipelines';
import { loadPipelinesRepositoryKeyPairResource } from 'src/components/pipelines/resources/key-pair';
import { loadPipelinesRepositoryKnownHostsResource } from 'src/components/pipelines/resources/known-hosts';
import { loadPipelineBranchResource } from 'src/components/pipelines/resources/pipeline-branch';
import { loadPipelineCreatorsResource } from 'src/components/pipelines/resources/pipeline-creators';
import { loadPipelineRunsResource } from 'src/components/pipelines/resources/pipeline-runs';
import { loadPipelinesResource } from 'src/components/pipelines/resources/pipelines';
import {
  loadPipelinesRepositoryRunnersResource,
  loadPipelinesWorkspaceRunnersResource,
} from 'src/components/pipelines/resources/runners';
import { loadShadowRepositoryConfigurationResource } from 'src/components/pipelines/resources/shadow-repository-configuration';
import { loadStepsResource } from 'src/components/pipelines/resources/steps';
import { loadTestCasesResource } from 'src/components/pipelines/resources/test-cases';
import { loadTestExecutionsResource } from 'src/components/pipelines/resources/test-executions';
import { LoadingPage } from 'src/components/react-loadable';
import { customMergeChecksConfigRouteResource } from 'src/components/settings/merge-checks/resources';
import { loadGlobalResource } from 'src/redux/global/resources/load-global';
import { loadPullRequestResource } from 'src/redux/pull-request/resources/load-pull-request';
import { loadRepositoryDetailsResource } from 'src/redux/repository/resources/load-repository-details-resource';
import { loadRepositoryResource } from 'src/redux/repository/resources/load-repository-resource';
import { loadRepositoryResourcePipelines } from 'src/redux/repository/resources/load-repository-resource-pipelines';
import CreateBranchPageLoadable from 'src/sections/create-branch/containers/entry';
import { repositoryNavigationResource } from 'src/sections/repository/repository-navigation-resource';
import { RepoSettingsAccessKeysLoadable } from 'src/sections/repository/sections/admin/components/settings-pages/access-keys';
import { AccessTokensLoadable } from 'src/sections/repository/sections/admin/components/settings-pages/access-tokens';
import { BranchRestrictionsLoadable } from 'src/sections/repository/sections/admin/components/settings-pages/branch-restrictions';
import { BranchingModelLoadable } from 'src/sections/repository/sections/admin/components/settings-pages/branching-model';
import { DefaultReviewGroupsLoadable } from 'src/sections/repository/sections/admin/components/settings-pages/default-review-groups';
import { DefaultReviewersLoadable } from 'src/sections/repository/sections/admin/components/settings-pages/default-reviewers';
import { MergeChecksLoadable } from 'src/sections/repository/sections/admin/components/settings-pages/merge-checks';
import { RepoSettingsMergeStrategiesLoadable } from 'src/sections/repository/sections/admin/components/settings-pages/merge-strategies';
import { RepoSettingsPermissionsLoadable } from 'src/sections/repository/sections/admin/components/settings-pages/permissions';
import { loadRepositorySettingsPermissionsResource } from 'src/sections/repository/sections/admin/components/settings-pages/permissions/resources';
import PipelinesRepoDeploymentsPageLoadable from 'src/sections/repository/sections/admin/components/settings-pages/pipelines/deployments';
import PipelinesRepoDynamicPipelinesPageLoadable from 'src/sections/repository/sections/admin/components/settings-pages/pipelines/dynamic-pipelines';
import PipelinesRepoOpenIDPageLoadable from 'src/sections/repository/sections/admin/components/settings-pages/pipelines/openid';
import PipelinesRepoRunnersPageLoadable from 'src/sections/repository/sections/admin/components/settings-pages/pipelines/runners';
import PipelinesRepoSettingsPageLoadable from 'src/sections/repository/sections/admin/components/settings-pages/pipelines/settings';
import PipelinesRepoSshKeysPageLoadable from 'src/sections/repository/sections/admin/components/settings-pages/pipelines/ssh-keys';
import PipelinesRepoVariablesPageLoadable from 'src/sections/repository/sections/admin/components/settings-pages/pipelines/variables';
import { loadConnectWebhooksResource } from 'src/sections/repository/sections/admin/components/settings-pages/webhooks/resources/load-connect-webhooks-resource';
import { loadRepositoryWebhooksResource } from 'src/sections/repository/sections/admin/components/settings-pages/webhooks/resources/load-repository-webhooks-resource';
import { loadWorkspaceWebhooksResource } from 'src/sections/repository/sections/admin/components/settings-pages/webhooks/resources/load-workspace-webhooks-resource';
import SettingsConnectIframe from 'src/sections/repository/sections/admin/containers/settings-connect-iframe';
import SettingsIframe from 'src/sections/repository/sections/admin/containers/settings-iframe';
import JiraMigrationLoadable from 'src/sections/repository/sections/admin/sections/jira-migration';
import BranchesListLoadable from 'src/sections/repository/sections/branches';
import ColdStorageLoadable from 'src/sections/repository/sections/cold-storage';
import CommitListLoadable from 'src/sections/repository/sections/commit-list';
import { loadCommitResource } from 'src/sections/repository/sections/commit/resources/load-commit-resource';
import { loadBranchesResource } from 'src/sections/repository/sections/create-pull-request/resources/load-branches-resource';
import PullRequestListLoadable from 'src/sections/repository/sections/pull-request-list';
import { loadForgeMergeChecksResultsResource } from 'src/sections/repository/sections/pull-request/components/merge-checks-card/resources';
import {
  RepoSecurityLandingPageLoadable,
  RepoSecurityLearnMoreLoadable,
} from 'src/sections/repository/sections/security';

import { ROUTE_NAME, RouteConfig } from '../types';

const RepositoryLayout = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/repository/layout'),
});

const RepositoryRedirect = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/repository/repository-redirect'),
});

const SourceDiffLoadable = Loadable({
  loading: LoadingPage,
  loader: () =>
    import('src/sections/repository/sections/source/containers/source-diff'),
});

const FileHistoryLoadable = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/repository/sections/source/containers/file-history-wrapped'
    ),
});

const RepositoryHistoryRedirect = Loadable({
  loading: LoadingPage,
  loader: () =>
    import('src/sections/repository/sections/commit-list/history-redirect'),
});

const SourceBrowser = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/repository/sections/source/containers/source-browser-resource-router'
    ),
});

const CommitPage = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/repository/sections/commit/route'),
});

const CreatePullRequest = Loadable({
  loading: LoadingPage,
  loader: () =>
    import('src/sections/repository/sections/create-pull-request/route'),
});

const PullRequest = Loadable({
  loading: LoadingPage,
  loader: () =>
    import('src/sections/repository/sections/pull-request/containers/page'),
});

const ConnectPageLoadable = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/repository/containers/connect-page'),
});

const ForgePageLoadable = Loadable({
  loading: LoadingPage,
  loader: () => import('src/forge/repo-main-menu-page/repo-main-menu-page'),
});

const SettingsForgePageLoadable = Loadable({
  loading: LoadingPage,
  loader: () =>
    import('src/forge/repo-settings-menu-page/repo-settings-menu-page'),
});

const JiraViewLoadable = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/repository/sections/jira'),
});

const loadableWebhooksPage =
  (
    pageName:
      | 'WebhooksLandingPage'
      | 'AddWebhookPage'
      | 'EditWebhookPage'
      | 'ViewWebhookEventPage'
      | 'ViewWebhookDetailsPage'
  ) =>
  () =>
    import(
      'src/sections/repository/sections/admin/components/settings-pages/webhooks/pages'
    ).then(pages => pages[pageName]);

const WebhooksLandingPage = Loadable({
  loading: LoadingPage,
  loader: loadableWebhooksPage('WebhooksLandingPage'),
});

const AddWebhookPage = Loadable({
  loading: LoadingPage,
  loader: loadableWebhooksPage('AddWebhookPage'),
});

const EditWebhookPage = Loadable({
  loading: LoadingPage,
  loader: loadableWebhooksPage('EditWebhookPage'),
});

const ViewWebhookPage = Loadable({
  loading: LoadingPage,
  loader: loadableWebhooksPage('ViewWebhookDetailsPage'),
});

const ViewWebhookEventPage = Loadable({
  loading: LoadingPage,
  loader: loadableWebhooksPage('ViewWebhookEventPage'),
});

const RepoDetailsSettingsPage = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/repository/sections/admin/components/settings-pages/details'
    ),
});

const RepoComplianceSettingsPage = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/repository/sections/admin/components/settings-pages/compliance'
    ),
});

const RepoUsernameAliasesSettingsPage = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/repository/sections/admin/components/settings-pages/username-aliases'
    ),
});

const BranchDetailPage = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/repository/sections/branch/route'),
});

const PipelinesRepoAdminRedirectDeploymentsSettingsLoadable = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/repository/sections/admin/components/settings-pages/pipelines/redirect/to-repo-admin'
    ),
});

const PipelinesRepoAdminRedirectRunnersSettingsLoadable = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/repository/sections/admin/components/settings-pages/pipelines/redirect/to-repo-admin'
    ),
});

const PipelinesRepoAdminRedirectSettingsLoadable = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/repository/sections/admin/components/settings-pages/pipelines/redirect/to-repo-admin'
    ),
});

export const getRepositoryRoutesConfig = (): RouteConfig => {
  return {
    [ROUTE_NAME.REPOSITORY_CREATE_PR]: {
      // @ts-ignore: requires RouteContext type on component
      component: CreatePullRequest,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        loadBranchesResource,
        loadRepositoryDetailsResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_CODE_REVIEW]: {
      // @ts-ignore: requires RouteContext type on component
      component: PullRequest,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        loadPullRequestResource,
        loadRepositoryDetailsResource,
        loadForgeMergeChecksResultsResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_BRANCH_DETAIL]: {
      // @ts-ignore: requires RouteContext type on component
      component: BranchDetailPage,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        loadRepositoryDetailsResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_SOURCE]: {
      // @ts-ignore: requires RouteContext type on component
      component: SourceBrowser,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        loadRepositoryDetailsResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_BRANCH_CREATE]: {
      component: CreateBranchPageLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_COMMITS]: {
      // @ts-ignore: requires RouteContext type on component
      component: CommitListLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_COMMIT]: {
      // @ts-ignore: requires RouteContext type on component
      component: CommitPage,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        loadCommitResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_SOURCE_DIFF]: {
      // @ts-ignore: requires RouteContext type on component
      component: SourceDiffLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_FILE_HISTORY]: {
      // @ts-ignore: requires RouteContext type on component
      component: FileHistoryLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_COMMITS_HISTORY]: {
      // @ts-ignore: requires RouteContext type on component
      component: RepositoryHistoryRedirect,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_CONNECT_PAGE]: {
      component: ConnectPageLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_FORGE_PAGE]: {
      component: ForgePageLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_JIRA_VIEW]: {
      // @ts-ignore: requires RouteContext type on component
      component: JiraViewLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        loadRepositoryDetailsResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_BRANCHES_LIST]: {
      // @ts-ignore: requires RouteContext type on component
      component: BranchesListLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        loadRepositoryDetailsResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_PULL_REQUESTS]: {
      // @ts-ignore: requires RouteContext type on component
      component: PullRequestListLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        loadRepositoryDetailsResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_COLD_STORAGE]: {
      // @ts-ignore: requires RouteContext type on component
      component: ColdStorageLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_SECURITY]: {
      // @ts-ignore: requires RouteContext type on component
      component: RepoSecurityLandingPageLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_SECURITY_LEARN_MORE]: {
      // @ts-ignore: requires RouteContext type on component
      component: RepoSecurityLearnMoreLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_DEPLOYMENTS]: {
      // @ts-ignore: requires RouteContext type on component
      component: DeploymentsLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
        loadDeploymentDashboardResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_PIPELINES]: {
      // @ts-ignore: requires RouteContext type on component
      component: PipelinesLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResourcePipelines,
        loadPipelinesResource,
        loadPipelineBranchResource,
        loadPipelineCreatorsResource,
        repositoryNavigationResource,
        loadDeploymentDashboardResource,
        loadAllowanceResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_PIPELINES_RESULT]: {
      // @ts-ignore: requires RouteContext type on component
      component: PipelinesLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResourcePipelines,
        loadCurrentPipelineResource,
        loadStepsResource,
        loadPipelineRunsResource,
        repositoryNavigationResource,
        loadAllowanceResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_TEST_MANAGEMENT]: {
      // @ts-ignore: requires RouteContext type on component
      component: TestManagementLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
        loadTestCasesResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_TEST_EXECUTION]: {
      // @ts-ignore: requires RouteContext type on component
      component: TestExecutionLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
        loadTestExecutionsResource,
      ],
    },

    // Jira migration repo admin routes
    [ROUTE_NAME.REPOSITORY_ADMIN_ISSUES_MIGRATION]: {
      // @ts-ignore: requires RouteContext type on component
      component: JiraMigrationLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },

    // Webhooks repo admin routes
    [ROUTE_NAME.REPOSITORY_ADMIN_WEBHOOKS_ROOT]: {
      // @ts-ignore: requires RouteContext type on component
      component: WebhooksLandingPage,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        loadRepositoryWebhooksResource,
        loadWorkspaceWebhooksResource,
        loadConnectWebhooksResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_CREATE_WEBHOOK]: {
      // @ts-ignore: requires RouteContext type on component
      component: AddWebhookPage,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        loadRepositoryWebhooksResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_EDIT_WEBHOOK]: {
      // @ts-ignore: requires RouteContext type on component
      component: EditWebhookPage,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        loadRepositoryWebhooksResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_VIEW_WEBHOOK]: {
      // @ts-ignore: requires RouteContext type on component
      component: ViewWebhookPage,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        // Users can view Repo/Workspace/Connect hook details so we request all 3 types here, but
        // they should already be cached from the visit to the landing page.
        loadRepositoryWebhooksResource,
        loadWorkspaceWebhooksResource,
        loadConnectWebhooksResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_VIEW_WEBHOOK_EVENT]: {
      // @ts-ignore: requires RouteContext type on component
      component: ViewWebhookEventPage,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        // Users can view Repo/Workspace/Connect hook details so we request all 3 types here, but
        // they should already be cached from the visit to the landing page.
        loadRepositoryWebhooksResource,
        loadWorkspaceWebhooksResource,
        loadConnectWebhooksResource,
        repositoryNavigationResource,
      ],
    },

    // Repo admin routes
    [ROUTE_NAME.REPOSITORY_ADMIN_DETAIL]: {
      // @ts-ignore: requires RouteContext type on component
      component: RepoDetailsSettingsPage,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        loadRepositoryDetailsResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_PERMISSIONS]: {
      // @ts-ignore: requires RouteContext type on component
      component: RepoSettingsPermissionsLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        loadRepositorySettingsPermissionsResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_ACCESS_KEYS]: {
      // @ts-ignore: requires RouteContext type on component
      component: RepoSettingsAccessKeysLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_COMMITTERS]: {
      // @ts-ignore: requires RouteContext type on component
      component: RepoUsernameAliasesSettingsPage,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_COMPLIANCE]: {
      // @ts-ignore: requires RouteContext type on component
      component: RepoComplianceSettingsPage,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_BRANCH_RESTRICTIONS]: {
      // @ts-ignore: requires RouteContext type on component
      component: BranchRestrictionsLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_BRANCHING_MODEL]: {
      // @ts-ignore: requires RouteContext type on component
      component: BranchingModelLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_MERGE_CHECKS]: {
      // @ts-ignore: requires RouteContext type on component
      component: MergeChecksLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        customMergeChecksConfigRouteResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_MERGE_STRATEGIES]: {
      // @ts-ignore: requires RouteContext type on component
      component: RepoSettingsMergeStrategiesLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_DEFAULT_REVIEWERS]: {
      // @ts-ignore: requires RouteContext type on component
      component: DefaultReviewersLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_DEFAULT_REVIEW_GROUPS]: {
      // @ts-ignore: requires RouteContext type on component
      component: DefaultReviewGroupsLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_ACCESS_TOKENS]: {
      // @ts-ignore: requires RouteContext type on component
      component: AccessTokensLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_REDIRECT_SETTINGS]: {
      // @ts-ignore: requires RouteContext type on component
      component: PipelinesRepoAdminRedirectSettingsLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_REDIRECT_RUNNERS]: {
      // @ts-ignore: requires RouteContext type on component
      component: PipelinesRepoAdminRedirectRunnersSettingsLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_REDIRECT_DEPLOYMENTS]: {
      // @ts-ignore: requires RouteContext type on component
      component: PipelinesRepoAdminRedirectDeploymentsSettingsLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
        loadDeploymentDashboardResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_SETTINGS]: {
      // @ts-ignore: requires RouteContext type on component
      component: PipelinesRepoSettingsPageLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        loadShadowRepositoryConfigurationResource,
        repositoryNavigationResource,
        loadAllowanceResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_OPENID]: {
      // @ts-ignore: requires RouteContext type on component
      component: PipelinesRepoOpenIDPageLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
        loadDeploymentDashboardResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_SSH_KEYS]: {
      // @ts-ignore: requires RouteContext type on component
      component: PipelinesRepoSshKeysPageLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        loadPipelinesRepositoryKeyPairResource,
        loadPipelinesRepositoryKnownHostsResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_RUNNERS]: {
      // @ts-ignore: requires RouteContext type on component
      component: PipelinesRepoRunnersPageLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        loadPipelinesWorkspaceRunnersResource,
        loadPipelinesRepositoryRunnersResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_DEPLOYMENTS]: {
      // @ts-ignore: requires RouteContext type on component
      component: PipelinesRepoDeploymentsPageLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        loadPipelinesRepositoryAssociationSummaryResource,
        repositoryNavigationResource,
        loadDeploymentDashboardResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_VARIABLES]: {
      // @ts-ignore: requires RouteContext type on component
      component: PipelinesRepoVariablesPageLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_PIPELINES_DYNAMIC_PIPELINES]: {
      // @ts-ignore: requires RouteContext type on component
      component: PipelinesRepoDynamicPipelinesPageLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        loadRepositoryDynamicPipelinesResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_CONNECT_IFRAME]: {
      // @ts-ignore: requires RouteContext type on component
      component: SettingsConnectIframe,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_IFRAME]: {
      // @ts-ignore: requires RouteContext type on component
      component: SettingsIframe,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },
    [ROUTE_NAME.REPOSITORY_ADMIN_FORGE_PAGE]: {
      // @ts-ignore: requires RouteContext type on component
      component: SettingsForgePageLoadable,
      layout: RepositoryLayout,
      resources: [
        loadGlobalResource,
        loadRepositoryResource,
        repositoryNavigationResource,
      ],
    },

    // Redirect if no other match
    [ROUTE_NAME.REPOSITORY_REDIRECT]: {
      // @ts-ignore: requires RouteContext type on component
      component: RepositoryRedirect,
      layout: RepositoryLayout,
      resources: [loadGlobalResource],
    },
  };
};
