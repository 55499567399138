import { getCurrentRepositoryFullSlug } from 'src/selectors/repository-selectors';
import { BucketState } from 'src/types/state';

export const isSameRepository = (
  repositoryFullSlug: string,
  state: BucketState
): boolean => {
  const currentRepoFullSlug = getCurrentRepositoryFullSlug(state);
  return !!currentRepoFullSlug && currentRepoFullSlug === repositoryFullSlug;
};
