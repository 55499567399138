import URL from 'url';

import React from 'react';

import qs from 'qs';

import Spinner from '@atlaskit/spinner';

import {
  SettingsPageContainer,
  SettingsPageIframe,
  SettingsPageLoadingWrapper,
} from './settings.styled';

type SetIsLoading = (isLoading: boolean) => void;

export type IframeActionProps = {
  event: MessageEvent;
  isLoading: boolean;
  setIsLoading: SetIsLoading;
  iframeRef: React.RefObject<HTMLIFrameElement>;
};

export type Props = {
  url?: string;
  title?: string;
  showLoadingOnUrlChange?: boolean;
  onIframeLoad?: (setIsLoading: SetIsLoading) => void;
  onIframeSubmit?: (props: IframeActionProps) => void;
  onIframeReadyStateChangeComplete?: (props: IframeActionProps) => void;
  topOffset?: number;
};

type State = {
  isLoading: boolean;
};

export class SettingsIframe extends React.Component<Props, State> {
  static getIframeURL(str: string) {
    const url = URL.parse(str);
    const query = qs.parse(url.query || '');
    const pageQuery = qs.parse(URL.parse(window.location.href).query || '');
    return URL.format({
      protocol: url.protocol || window.location.protocol,
      host: url.host || window.location.host,
      pathname: url.pathname,
      query: { ...query, ...pageQuery, iframe: true, spa: 0 },
      hash: window.location.hash,
    });
  }

  state = {
    isLoading: true,
  };

  componentDidMount() {
    window.addEventListener('message', this.onMessage);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.showLoadingOnUrlChange && prevProps.url !== this.props.url) {
      this.setIsLoading(true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onMessage);
  }

  iframeRef = React.createRef<HTMLIFrameElement>();

  setIsLoading = (isLoading: boolean) => this.setState({ isLoading });

  getActionProps = (event: MessageEvent) => ({
    event,
    isLoading: this.state.isLoading,
    setIsLoading: this.setIsLoading,
    iframeRef: this.iframeRef,
  });

  onMessage = (evt: MessageEvent) => {
    const {
      onIframeSubmit: onSubmit,
      onIframeReadyStateChangeComplete: onChange,
    } = this.props;
    if (evt.origin !== window.location.origin) {
      return;
    }
    if (evt.data === 'iframe:submit') {
      if (typeof onSubmit === 'function') {
        onSubmit(this.getActionProps(evt));
      } else {
        this.setIsLoading(true);
      }
    } else if (evt.data === 'iframe:readystatechange:complete') {
      if (typeof onChange === 'function') {
        onChange(this.getActionProps(evt));
      } else {
        this.setIsLoading(false);
      }
    }
  };

  render() {
    const { isLoading } = this.state;
    const { url, title, onIframeLoad, topOffset } = this.props;
    return (
      <SettingsPageContainer topOffset={topOffset}>
        {isLoading && (
          <SettingsPageLoadingWrapper>
            <Spinner size="large" />
          </SettingsPageLoadingWrapper>
        )}
        {url && (
          <SettingsPageIframe
            id="settings-frame"
            title={title}
            src={SettingsIframe.getIframeURL(url)}
            isLoading={isLoading}
            onLoad={onIframeLoad && (() => onIframeLoad(this.setIsLoading))}
            aria-hidden={isLoading}
            ref={this.iframeRef}
          />
        )}
      </SettingsPageContainer>
    );
  }
}
