import { defineMessages } from 'react-intl';

export default defineMessages({
  removeAccessHeader: {
    id: 'frontbucket.settings.permissions.modals.removeAccessHeader',
    description: 'Remove access modal header message',
    defaultMessage: 'Remove access',
  },
  user: {
    id: 'frontbucket.settings.permissions.modals.user',
    description: 'User word that is used in remove access content for users',
    defaultMessage: 'user',
  },
  group: {
    id: 'frontbucket.settings.permissions.modals.group',
    description: 'Group word that is used in remove access content for groups',
    defaultMessage: 'group',
  },
  cancel: {
    id: 'frontbucket.settings.permissions.modals.cancel',
    description:
      'Cancel button message for add/remove user/group access modal footer',
    defaultMessage: 'Cancel',
  },
  remove: {
    id: 'frontbucket.settings.permissions.modals.remove',
    description:
      'Remove button message for remove user/group access modal footer',
    defaultMessage: 'Remove',
  },

  addAccessHeader: {
    id: 'frontbucket.settings.permissions.modals.addAccessHeader',
    description: 'Add access modal header message',
    defaultMessage: 'Add users or groups',
  },
  addWorkspaceAccessHeader: {
    id: 'frontbucket.settings.permissions.modals.addWorkspaceAccessHeader',
    description: 'Add workspace access modal header message',
    defaultMessage: 'Add workspace permissions',
  },
  confirm: {
    id: 'frontbucket.settings.permissions.modals.confirm',
    description:
      'Confirm button message for add user/group access modal footer',
    defaultMessage: 'Confirm',
  },
  addMembersPlaceholder: {
    id: 'frontbucket.settings.permissions.modals.addMembersPlaceholder',
    description: 'Placeholder for the search field for users',
    defaultMessage: 'Add a group or user by name or email address',
  },
  addMembersByNamePlaceholder: {
    id: 'frontbucket.settings.permissions.modals.addMembersByNamePlaceholder',
    description: 'Placeholder for the search field for users',
    defaultMessage: 'Add a group or user by name',
  },
  addMembersWorkspacePlaceholder: {
    id: 'frontbucket.settings.permissions.modals.addMembersWorkspacePlaceholder',
    description: 'Placeholder for the search field for workspace groups',
    defaultMessage: 'Add a group by name',
  },
  bulkEditPrivilegesButton: {
    id: 'frontbucket.settings.permissions.modals.bulkEditPrivilegesButton',
    description: 'Submit button for "bulk edit permissions" modal',
    defaultMessage: 'Confirm',
  },
  bulkEditPrivilegesHeader: {
    id: 'frontbucket.settings.permissions.modals.bulkEditPrivilegesHeader',
    description: 'Header text for "bulk edit permissions" modal',
    defaultMessage:
      'Edit permissions of {count, plural, one {{count} item} other {{count} items}}',
  },
  bulkEditPrivilegesBody: {
    id: 'frontbucket.settings.permissions.modals.bulkEditPrivilegesBody',
    description: 'Body text for "bulk edit permissions" modal',
    defaultMessage: 'You are about to change access for: {items}.',
  },
  selectPermission: {
    id: 'frontbucket.settings.permissions.modals.selectPermission',
    description: 'Trigger text for privilege selection drop down',
    defaultMessage: 'Select permission',
  },

  emailInputMaxedOutError: {
    id: 'frontbucket.settings.permissions.modals.emailInputMaxedOutError',
    description:
      'Error text, user has added more than the allowed number of email addresses',
    defaultMessage:
      'Reduce the number of email addresses to no more than {max}.',
  },

  entitiesMaxedOutError: {
    id: 'frontbucket.settings.permissions.modals.entitiesMaxedOutError',
    description:
      'Error text, user has added more than the allowed number of users and groups',
    defaultMessage:
      'Reduce the number of users and groups to no more than {max}.',
  },
  noResults: {
    id: 'frontbucket.settings.permissions.modals.noResults',
    description: 'Text for a search query that produces no results',
    defaultMessage: 'No results found. Type a user’s email address to invite.',
  },
  noWorkspaceMembersFound: {
    id: 'frontbucket.settings.permissions.modals.noWorkspaceMembersFound',
    description: 'Workspace member search query that produces no results',
    defaultMessage: 'No matching workspace members found.',
  },
  beginTyping: {
    id: 'frontbucket.settings.permissions.modals.beginTyping',
    description: 'Prompt for the user to begin typing and view search matches',
    defaultMessage: 'Begin typing to search for a user or group',
  },
  beginTypingGroup: {
    id: 'frontbucket.settings.permissions.modals.beginTypingGroup',
    description: 'Prompt for the user to begin typing and view search matches',
    defaultMessage: 'Begin typing to search for a group',
  },
  createProjectDescription: {
    id: 'frontbucket.settings.permissions.modals.createProjectDescription',
    description:
      'Description of create-project permission when adding a group to a workspace',
    defaultMessage:
      'Groups are assigned Create permission, which allows users to create projects and have Admin access to those projects. All activities permitted by the "Write" permission are allowed as well.',
  },
  partialInvitationsModalHeader: {
    id: 'frontbucket.settings.permissions.modals.partialInvitationsModalHeader',
    description: 'Partial invitations modal header message',
    defaultMessage:
      'Workspace disallowed {count, plural, one {an invitation} other {some invitations}}',
  },
  close: {
    id: 'frontbucket.settings.permissions.modals.close',
    description: 'Close button for the Partial Invitations Modal',
    defaultMessage: 'Close',
  },
  successfulAdditions: {
    id: 'frontbucket.settings.permissions.modals.successfulAdditions',
    description:
      'Message shown above users that were succesfully given permissions',
    defaultMessage:
      'The following {count, plural, one {user} other {users}} were successfully given permission to the repository:',
  },
  successfulInvitations: {
    id: 'frontbucket.settings.permissions.modals.successfulInvitations',
    description:
      'Message shown above emails that were succesfully sent an invitation',
    defaultMessage:
      'We sent {count, plural, one {an invitation} other {invitations}} to the following {count, plural, one {email} other {emails}}:',
  },
  nonAdminErrorMessage: {
    id: 'frontbucket.settings.permissions.modals.nonAdminErrorMessage',
    description:
      'Message shown above emails that could not be sent invitations because of the non-admin workspace invitation restriction',
    defaultMessage: `The following email {count, plural, one {address was} other {addresses were}} not sent {count, plural, one {an invitation} other {invitations}} because this workspace only allows workspace administrators to send invitations to users who are not workspace members:`,
  },
  domainErrorMessage: {
    id: 'frontbucket.settings.permissions.modals.domainErrorMessage',
    description:
      'Message shown above emails that could not be sent invitations because of the domain workspace invitation restriction',
    defaultMessage: `The following email {count, plural, one {address was} other {addresses were}} not sent {count, plural, one {an invitation} other {invitations}} because {count, plural, one {it has a domain} other {they include domains}} not allowed for the workspace:`,
  },
  hasRepoAccessLozenge: {
    id: 'frontbucket.settings.permissions.hasRepoAccessLozenge',
    description: 'Label indicating that a principal has access to a repository',
    defaultMessage: 'Already has repository access',
  },
  hasProjectAccessLozenge: {
    id: 'frontbucket.settings.permissions.hasProjectAccessLozenge',
    description: 'Label indicating that a principal has access to a project',
    defaultMessage: 'Already has project access',
  },
  hasWorkspaceAccessLozenge: {
    id: 'frontbucket.settings.permissions.hasWorkspaceAccessLozenge',
    description: 'Label indicating that a principal has access to a workspace',
    defaultMessage: 'Already has workspace access',
  },
});
