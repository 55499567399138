/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import {
  select,
  getContext,
  all,
  takeEvery,
  put,
  takeLatest,
  call,
} from 'redux-saga/effects';

import { SAGAS_CONTEXT_KEY_PIPELINES_WEBSOCKET } from 'src/sagas/helpers';
import { getCurrentRepository } from 'src/selectors/repository-selectors';
import { Action } from 'src/types/state';

import {
  WEBSOCKET_RECONNECT,
  WEBSOCKET_SUBSCRIBE,
  WEBSOCKET_UNSUBSCRIBE,
} from '../actions/pipelines';
import { getWebsocketSubscriptions } from '../selectors/pipelines';

export function* subscribeWebSocketSaga(
  action: Action<{ criteria?: {}; event: string }>
): any {
  const { event, criteria } = action.payload || {};
  if (!event) {
    return;
  }
  const { uuid: repositoryUuid } = yield select(getCurrentRepository);
  const socketChannel = yield getContext(SAGAS_CONTEXT_KEY_PIPELINES_WEBSOCKET);
  socketChannel.send(
    JSON.stringify({
      type: 'SUBSCRIBE',
      event,
      criteria: { repositoryUuid, ...criteria },
      requestId: 0,
    })
  );
}

export function* unsubscribeWebSocketSaga(
  action: Action<{ criteria?: {}; event: string }>
): any {
  const { event, criteria } = action.payload || {};
  if (!event) {
    return;
  }
  const socketChannel = yield getContext(SAGAS_CONTEXT_KEY_PIPELINES_WEBSOCKET);
  socketChannel.send(
    JSON.stringify({
      type: 'UNSUBSCRIBE',
      event,
      requestId: 0,
      ...(criteria ? { criteria } : {}),
    })
  );
}

export function* reconnectWebSocketSaga(): any {
  const { uuid: repositoryUuid } = yield select(getCurrentRepository);
  const socketChannel = yield getContext(SAGAS_CONTEXT_KEY_PIPELINES_WEBSOCKET);
  const subscriptions: { criteria?: {}; event: string }[] = yield select(
    getWebsocketSubscriptions
  );
  subscriptions.forEach((subscription: any) => {
    socketChannel.send(
      JSON.stringify({
        type: 'SUBSCRIBE',
        event: subscription.event,
        criteria: { repositoryUuid, ...subscription.criteria },
        requestId: 0,
      })
    );
  });
  yield all(
    subscriptions.map(subscription =>
      put({
        type: subscription.event.toUpperCase(),
        ...subscription.criteria,
      })
    )
  );
}

export function* processSocketChannelSaga({
  type,
  ...params
}: {
  type: string;
}): any {
  yield put({ ...params, type });
}

export function* watchWebSocketChannel(): any {
  const socketChannel = yield getContext(SAGAS_CONTEXT_KEY_PIPELINES_WEBSOCKET);
  yield takeEvery(socketChannel, processSocketChannelSaga);
}
export default function* () {
  yield all([
    call(watchWebSocketChannel),
    takeLatest(WEBSOCKET_SUBSCRIBE, subscribeWebSocketSaga),
    takeLatest(WEBSOCKET_UNSUBSCRIBE, unsubscribeWebSocketSaga),
    takeLatest(WEBSOCKET_RECONNECT, reconnectWebSocketSaga),
  ]);
}
