import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
import { withFocusWithin } from 'react-focus-within';
import styled, { css } from 'styled-components';

// AFP-2532 TODO: Fix automatic suppressions below
// eslint-disable-next-line @atlassian/tangerine/import/entry-points
import { colors } from '@atlaskit/theme';
var transition = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  transition: all 200ms ease-in-out;\n"])));
export var PanelWrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  margin: 0 auto ", ";\n"])), "var(--ds-space-200, 16px)");
export var ButtonWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  left: 0;\n  line-height: 0;\n  opacity: ", ";\n  position: absolute;\n  ", ";\n\n  /* IE 11 needs these vertical positioning rules - the flexbox\n  behavior for absolute-positioned children is not up to spec.\n  https://googlechrome.github.io/samples/css-flexbox-abspos/ */\n  top: 50%;\n  transform: translateY(-50%);\n\n  button {\n    pointer-events: none;\n  }\n"])), function (_ref) {
  var isHidden = _ref.isHidden;
  return isHidden ? 0 : 1;
}, transition);
export var PanelHeader = withFocusWithin(styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  align-items: center;\n  background-color: ", ";\n  border-radius: ", ";\n  display: flex;\n  left: ", ";\n  margin-bottom: ", ";\n  margin-top: ", ";\n  padding: ", " ", "\n    ", " ", ";\n  position: relative;\n  ", ";\n  width: 100%;\n\n  ", " {\n    opacity: ", ";\n  }\n\n  &:hover {\n    background-color: ", ";\n    cursor: pointer;\n\n    ", " {\n      opacity: 1;\n    }\n  }\n"])), function (props) {
  return props.isFocused && "var(--ds-surface-hovered, ".concat(colors.N20, ")");
}, "var(--ds-border-radius-100, 3px)", "var(--ds-space-negative-300, -24px)", "var(--ds-space-100, 8px)", "var(--ds-space-200, 16px)", "var(--ds-space-025, 2px)", "var(--ds-space-0, 0px)", "var(--ds-space-025, 2px)", "var(--ds-space-300, 24px)", transition, ButtonWrapper, function (props) {
  return props.isFocused && 1;
}, "var(--ds-surface-hovered, ".concat(colors.N20, ")"), ButtonWrapper));