export class PipelineError {
  readonly key: string = '';
  readonly message: string = '';
  readonly name: string = '';
  readonly type: string = '';
  readonly arguments: any = {};

  constructor(props: Partial<PipelineError> = {}) {
    Object.assign(this, props);
    Object.freeze(this);
  }
}
