import { defineMessages } from 'react-intl';

export const repository = defineMessages({
  read: {
    id: 'frontbucket.components.settings.privileges-dropdown.repository.permission.read-description',
    description: 'Read repository permission description',
    defaultMessage: 'Can view, clone, and fork repositories.',
  },
  write: {
    id: 'frontbucket.components.settings.privileges-dropdown.repository.permission.write-description',
    description: 'Write repository permission description',
    defaultMessage: 'Can push code, create and merge pull requests.',
  },
  admin: {
    id: 'frontbucket.components.settings.privileges-dropdown.repository.permission.admin-description',
    description: 'Admin repository permission description',
    defaultMessage:
      'Can manage repository settings and users, delete repositories, and also inherits all Write permissions.',
  },
  none: {
    id: 'frontbucket.components.settings.privileges-dropdown.repository.permission.none-description',
    description: '`None` repository permission description',
    defaultMessage: 'Denied access to repositories.',
  },
});

export const project = defineMessages({
  read: {
    id: 'frontbucket.components.settings.privileges-dropdown.project.permission.read-description',
    description: 'Read project permission description',
    defaultMessage:
      'Can clone, browse and fork any repository within the project. Can create and contribute to pull requests targeting any of these repositories.',
  },
  write: {
    id: 'frontbucket.components.settings.privileges-dropdown.project.permission.write-description',
    description: 'Write project permission description',
    defaultMessage: `Can push to any repository within the project and merge pull requests targeting these repositories which don't have other restrictions. All activities permitted by the Read permission are granted to the Write users as well.`,
  },
  'create-repo': {
    id: 'frontbucket.components.settings.privileges-dropdown.project.permission.create-repo-description',
    description: 'Create repo permission description',
    defaultMessage:
      'Can create repositories and are assigned Admin access to the created repositories. All activities permitted by the Write permission are granted to Create users as well.',
  },
  admin: {
    id: 'frontbucket.components.settings.privileges-dropdown.project.permission.admin-description',
    description: 'Admin project permission description',
    defaultMessage:
      'Can administer the project and create new repositories. Administrators have complete access to all repositories in the project.',
  },
  none: {
    id: 'frontbucket.components.settings.privileges-dropdown.project.permission.none-description',
    description: '`None` project permission description',
    defaultMessage: 'Denied access to projects.',
  },
});

export const workspace = defineMessages({
  'create-project': {
    id: 'frontbucket.components.settings.privileges-dropdown.workspace.permission.create-project-description',
    description: 'Create projects workspace permission description',
    defaultMessage: 'Can create projects in this workspace.',
  },
  admin: {
    id: 'frontbucket.components.settings.privileges-dropdown.workspace.permission.admin-description',
    description: 'Admin workspace permission description',
    defaultMessage:
      'Can administer the workspace and create new projects. Administrators have complete access to all projects and repositories in the workspace.',
  },
});

export default defineMessages({
  write: {
    id: 'frontbucket.components.settings.privileges-dropdown.permission.write',
    description: 'Write permission name',
    defaultMessage: 'Write',
  },
  read: {
    id: 'frontbucket.components.settings.privileges-dropdown.permission.read',
    description: 'Read permission name',
    defaultMessage: 'Read',
  },
  'create-repo': {
    id: 'frontbucket.components.settings.privileges-dropdown.permission.create-repo',
    description: 'Create repo permission name',
    defaultMessage: 'Create',
  },
  'create-project': {
    id: 'frontbucket.components.settings.privileges-dropdown.permission.create-project',
    description: 'Create project permission name',
    defaultMessage: 'Create',
  },
  admin: {
    id: 'frontbucket.components.settings.privileges-dropdown.permission.admin',
    description: 'Admin permission name',
    defaultMessage: 'Admin',
  },
  none: {
    id: 'frontbucket.components.settings.privileges-dropdown.permission.none',
    description: 'Default value name for the permissions drop-down',
    defaultMessage: 'None',
  },

  allPermissions: {
    id: 'frontbucket.components.settings.privileges-dropdown.permission.allPermissions',
    description: 'Default label for privileges drop-down',
    defaultMessage: 'Permissions',
  },
  allPrivilegesMenuItem: {
    id: 'frontbucket.components.settings.privileges-dropdown.permission.allPrivilegesMenuItem',
    description: 'Label for "All permissions" dropdrown menu item',
    defaultMessage: 'All',
  },
});
