/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { change } from 'redux-form';
import { put, select } from 'redux-saga/effects';

import { User } from 'src/components/types';
import { FORM_KEY } from 'src/constants/search';

import { RESET_SEARCH, syncUrlToState } from '../actions';
import fetchSearchResults, {
  FetchSearchResults,
} from '../actions/fetch-search-results';
import { getSearchAccount } from '../selectors';

export default function* syncUrlToStateSaga({
  payload: { params },
}: ReturnType<typeof syncUrlToState>) {
  const account: User = yield select(getSearchAccount, params);
  const query = params.q;

  yield put(change(FORM_KEY, 'account', account));
  yield put(change(FORM_KEY, 'query', query));
  if (
    !account ||
    !account.extra ||
    account.extra.search_state !== 'ENABLED' ||
    params.extra?.modifiersOnly
  ) {
    yield put({ type: RESET_SEARCH });
    return;
  }

  if (query) {
    yield put(fetchSearchResults(params));
  } else {
    // reset the results
    yield put({
      type: FetchSearchResults.SUCCESS,
      payload: null,
    });
  }
}
