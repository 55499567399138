import React, { useMemo } from 'react';

import EditorImageBorderIcon from '@atlaskit/icon/glyph/editor/image-border';
import SuitcaseIcon from '@atlaskit/icon/glyph/suitcase';
import Lozenge from '@atlaskit/lozenge';
import { Inline } from '@atlaskit/primitives';
import { SimpleTag as Tag } from '@atlaskit/tag';
import TagGroup from '@atlaskit/tag-group';
import Tooltip from '@atlaskit/tooltip';

import {
  TestLink,
  TestMetricValue,
  TestName,
  TestChildWrapper,
  TestTableHeader,
  TestTextWrapper,
  TestTitle,
  TestManagementTable,
  type TestCases,
} from 'src/components/pipelines/components/test-management';
import { formatTestDurations } from 'src/components/pipelines/components/test-management/utils';
import { messages } from 'src/components/pipelines/pages/test-management/test-management-page.i18n';
import { averageDurationHasWarning } from 'src/components/pipelines/pages/test-management/utils';
import { getTestExecutionUrl } from 'src/components/pipelines/utils/links';
import { getPages } from 'src/components/pipelines/utils/pagination';
import { useIntl } from 'src/hooks/intl';

type Props = {
  testCases?: TestCases;
  isLoading?: boolean;
};

/* eslint @typescript-eslint/ban-types: "warn" */
export const TestCaseTable: React.FC<Props> = ({ testCases, isLoading }) => {
  const { formatMessage } = useIntl();

  const tableHead = useMemo(
    () => ({
      cells: [
        {
          key: 'test',
          content: (
            <TestTableHeader>{formatMessage(messages.test)}</TestTableHeader>
          ),
          width: 40,
        },
        {
          key: 'Labels',
          content: (
            <TestTableHeader>{formatMessage(messages.labels)}</TestTableHeader>
          ),
          width: 25,
        },
        {
          key: 'failureRate',
          content: (
            <TestTableHeader>
              {formatMessage(messages.failureRate)}
            </TestTableHeader>
          ),
          width: 10,
        },
        {
          key: 'averageDuration',
          content: (
            <TestTableHeader>
              {formatMessage(messages.averageDuration)}
            </TestTableHeader>
          ),
          width: 15,
        },
        {
          key: 'variance',
          content: (
            <TestTableHeader>
              {formatMessage(messages.variance)}
            </TestTableHeader>
          ),
          width: 10,
        },
      ],
    }),
    [formatMessage]
  );

  const tableRows = useMemo(() => {
    return testCases?.values?.map(
      ({
        uuid,
        name,
        className,
        fullyQualifiedName,
        metrics: { averageDuration, failureRate, p10Duration, p90Duration },
        customProperties,
      }) => {
        const rowKey = `row-${uuid}`;
        return {
          key: rowKey,
          cells: [
            {
              key: `${rowKey}-test`,
              content: (
                <TestLink
                  to={getTestExecutionUrl(
                    uuid,
                    fullyQualifiedName,
                    averageDuration
                  )}
                >
                  <TestTitle>
                    <TestTextWrapper>{name}</TestTextWrapper>
                  </TestTitle>
                  <TestName>
                    <TestChildWrapper>
                      <SuitcaseIcon
                        label={formatMessage(messages.fullyQualifiedNameLabel)}
                        size="small"
                      />
                      <TestTextWrapper>{fullyQualifiedName}</TestTextWrapper>
                    </TestChildWrapper>
                    <TestChildWrapper>
                      <EditorImageBorderIcon
                        label={formatMessage(messages.classNameLabel)}
                        size="small"
                      />
                      <TestTextWrapper>{className}</TestTextWrapper>
                    </TestChildWrapper>
                  </TestName>
                </TestLink>
              ),
            },
            {
              key: `${rowKey}-labels`,
              content: (
                <TagGroup>
                  {customProperties?.map(({ key, value }) => (
                    <Tag key={key} text={`${key}:${value}`} color="blueLight" />
                  ))}
                </TagGroup>
              ),
            },
            {
              key: `${rowKey}-failureRate`,
              content: (
                <TestMetricValue hasWarning={failureRate > 0}>
                  {failureRate}%
                </TestMetricValue>
              ),
            },
            {
              key: `${rowKey}-averageDuration`,
              content: (
                <TestMetricValue
                  hasWarning={averageDurationHasWarning(averageDuration)}
                >
                  {formatTestDurations(averageDuration)}
                </TestMetricValue>
              ),
            },
            {
              key: `${rowKey}-variance`,
              content: (
                <Inline alignBlock="end" space="space.025">
                  <Tooltip content={formatMessage(messages.p10OfDuration)}>
                    <Lozenge>{p10Duration.toFixed(2)}</Lozenge>
                  </Tooltip>
                  <span>~</span>
                  <Tooltip content={formatMessage(messages.p90OfDuration)}>
                    <Lozenge appearance="removed">
                      {p90Duration.toFixed(2)}
                    </Lozenge>
                  </Tooltip>
                </Inline>
              ),
            },
          ],
        };
      }
    );
  }, [formatMessage, testCases]);

  return (
    <TestManagementTable
      head={tableHead}
      rows={tableRows}
      pages={getPages(360, 10)}
      isLoading={isLoading}
    />
  );
};
