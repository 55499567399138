import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import Cookies from 'js-cookie';
import { fg } from '@atlaskit/platform-feature-flags';
import { sendPackageOperationalEvent } from '../../../common/utils';
import { Logger } from '../../../common/utils/logger';

/**
 * Sets a cookie synchronously. Since this doesn't wait for user preferences,
 * it should only be used for strictly necessary cookies which are critical to
 * application functionality.
 */

export var setStrictlyNecessaryCookie = function setStrictlyNecessaryCookie(key, value, attributes) {
  if (fg('platform_moonjelly_bsc_master_killswitch')) {
    // Set the cookie without package operational events
    // when master killswitch is enabled
    Cookies.set(key, value, _objectSpread(_objectSpread({}, attributes), {}, {
      'atl-set-cookie': true
    }));
  } else {
    try {
      sendPackageOperationalEvent({
        action: 'usedSetStrictlyNecessaryCookie',
        attributes: {
          cookieKey: key
        }
      });
      Cookies.set(key, value, _objectSpread(_objectSpread({}, attributes), {}, {
        'atl-set-cookie': true
      }));
    } catch (e) {
      Logger.errorWithOperationalEvent({
        action: 'usedSetStrictlyNecessaryCookieError',
        attributes: {
          cookieKey: key
        },
        message: "Failed to use set strictly necessary cookie. ".concat(e.message || '')
      });
    }
  }
};