import * as Sentry from '@sentry/browser';
/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { call, take, select } from 'redux-saga/effects';

import { User } from 'src/components/types';
import { getCurrentUser } from 'src/selectors/user-selectors';

import { LoadGlobal } from '../actions';

export default function* bootstrapSaga() {
  yield take(LoadGlobal.SUCCESS);

  const currentUser: User = yield select(getCurrentUser);
  if (currentUser && currentUser.uuid) {
    yield call(Sentry.configureScope, scope => {
      scope.setUser({
        id: currentUser.uuid,
      });
    });
  }
}
