import React, { useEffect, useRef } from 'react';

import PageHeader from '@atlaskit/page-header';

import { StyledTitle } from './styled';

// Screen readers: Announce Level-1 heading (<h1>) once per component mount
//
// Keyboards: Move focus to <h1> when the content is loaded. The idea here is
//  that when the page is loaded using the main navigation, focus moves to
//  inside the newly loaded content area.
//
// https://reactjs.org/docs/accessibility.html#programmatically-managing-focus

export const FocusedPageHeader = (
  props: React.ComponentProps<typeof PageHeader>
) => {
  const headingRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (headingRef && headingRef.current) {
      headingRef.current.focus();
    }
  }, []);

  return (
    <PageHeader {...props} disableTitleStyles>
      <StyledTitle
        data-qa="page-header"
        ref={headingRef}
        tabIndex={-1}
        truncate={props.truncateTitle}
      >
        {props.children}
      </StyledTitle>
    </PageHeader>
  );
};
