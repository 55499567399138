import DefaultLoggerAPI from './DefaultLoggerAPI';
import {LoggerAPI} from './LoggerAPI';
import {LoggerRegistry} from './LoggerRegistry';

class LoggerController {

  debugEnabled = false;
  infoEnabled = true;

  isDebugEnabled() {
    return this.debugEnabled;
  }

  isInfoEnabled() {
    return this.infoEnabled;
  }

  /**
   * In the browser console, enable debug by entering window._loggerController.enableDebug().
   */
  enableDebug(): string {
    console.info('Enabling debug...');
    this.debugEnabled = true;
    return 'Debug level logging is now enabled.   ۜ\(סּںסּَ` )/ۜ';
  }

  /**
   * In the browser console, disable debug by entering window._loggerController.disableDebug().
   */
  disableDebug() {
    console.info('Disabling debug...');
    this.debugEnabled = false;
    return 'Debug level logging is now disabled. ε(´סּ︵סּ`)з';
  }

  /**
   * In the browser console, enable info by entering window._loggerController.enableInfo().
   */
  enableInfo(): string {
    console.info('Enabling info...');
    this.infoEnabled = true;
    return 'Info level logging is now enabled. 【ツ】';
  }

  /**
   * In the browser console, disable info by entering window._loggerController.disableInfo().
   * <br/>
   * <strong>Note</strong>: disabling info will also disable debug.
   */
  disableInfo() {
    console.info('Disabling info...');
    this.debugEnabled = false;
    this.infoEnabled = false;
    return 'Info level logging is now disabled. (-.-)Zzz...';
  }

}

/**
 * This class serves two purposes:
 *   - It provides the ability for products to register their implementations
 *     of LoggerAPI for use by the connect front end code.
 *   - It provides the LoggerAPI implementation that connect front end code
 *     should use. This implementation delegates to the registered LoggerAPI
 *     implementation. This class should be used within the connect front end
 *     code as follows:
 *
 *     import logger from '../adaptors/logger/LoggerAdaptor';
 *
 *     logger.info('My message', extraStuffOfInterest, etc, ...);
 */
class LoggerAdaptor implements LoggerRegistry {

  loggerController: LoggerController;
  loggerAPI = DefaultLoggerAPI;

  constructor() {
    this.loggerController = new LoggerController();
    (<any> window)._loggerController = this.loggerController;
  }

  /**
   * Implementation of AnalyticsRegistry#registerAnalyticsAPI().
   */
  registerLoggerAPI(loggerAPI: LoggerAPI): void {
    this.loggerAPI = loggerAPI;
  }

  /**
   * Implementation of LoggerAPI#debug() for use by connect-module-core code.
   */
  debug(message?: string, ...optionalParams: any[]): void {
    try {
      if (this.loggerController.isDebugEnabled()) {
        this.loggerAPI.debug.apply(this.loggerAPI, arguments);
      }
    } catch (exception) {
      // Ignore
    }
  }

  /**
   * Implementation of LoggerAPI#info() for use by connect-module-core code.
   */
  info(message?: string, ...optionalParams: any[]): void {
    try {
      if (this.loggerController.isInfoEnabled()) {
        this.loggerAPI.info.apply(this.loggerAPI, arguments);
      }
    } catch (exception) {
      // Ignore
    }
  }

  /**
   * Implementation of LoggerAPI#warn() for use by connect-module-core code.
   */
  warn(message?: string, ...optionalParams: any[]): void {
    try {
      this.loggerAPI.warn.apply(this.loggerAPI, arguments);
    } catch (exception) {
      const logger = console.warn || console.log;
      if (logger) {
        logger.apply(logger, arguments);
      }
    }
  }

  /**
   * Implementation of LoggerAPI#error() for use by connect-module-core code.
   */
  error(message?: string, ...optionalParams: any[]): void {
    try {
      this.loggerAPI.error.apply(this.loggerAPI, arguments);
    } catch (exception) {
      const logger = console.error || console.log;
      if (logger) {
        logger.apply(logger, arguments);
      }
    }
  }

}

export const logger = new LoggerAdaptor() as LoggerRegistry;
