import { createSelector } from 'reselect';

import { Environment } from 'src/components/pipelines/models';
import { LoadingStatus } from 'src/constants/loading-status';
import { getCurrentRepository } from 'src/selectors/repository-selectors';
import { getRepository } from 'src/selectors/state-slicing-selectors';
import store from 'src/utils/store';

import { DEPLOYMENTS_ONBOARDED_KEY } from '../../constants';

export const getDeploymentChanges = createSelector(
  getRepository,
  ({ deployments }) => {
    return deployments.changes;
  }
);

export const getDeploymentDashboard = createSelector(
  getRepository,
  ({ deployments }) => {
    return Object.values(deployments.dashboard.type) as Environment[][];
  }
);

export const getDeploymentDashboardFetchedStatus = createSelector(
  getRepository,
  ({ deployments }) => {
    return deployments.dashboard.fetchedStatus;
  }
);

export const getDeploymentDashboardWithTypeNames = createSelector(
  getRepository,
  ({ deployments }) => deployments.dashboard.type
);

export const getDeploymentDashboardVersion = createSelector(
  getRepository,
  ({ deployments }) => deployments.dashboard.version
);

export const getEnvironmentsByUuid = createSelector(
  getDeploymentDashboard,
  deploymentDashboard => {
    return deploymentDashboard.flat().reduce((reducer, environment) => {
      reducer[environment.uuid] = environment;
      return reducer;
    }, {} as Record<string, Environment>);
  }
);

export const getEnvironmentNames = createSelector(
  getDeploymentDashboard,
  deploymentDashboard => {
    return deploymentDashboard.reduce((reducer: any, group: any) => {
      if (group[0]) {
        reducer[group[0].uuid] = group[0].name;
      }
      return reducer;
    }, {});
  }
);

export const getEditorEnvironments = createSelector(
  getDeploymentDashboard,
  deploymentDashboard => {
    return deploymentDashboard.map(group =>
      group.map((e: Environment) => e.name.toLowerCase())
    );
  }
);

export const getDeploymentSummary = createSelector(
  getRepository,
  ({ deployments }) => {
    const previousCommit =
      deployments.summary?.lastSuccessfulDeployment?.deployable.commit;
    const currentCommit = deployments.summary?.deployment?.deployable.commit;
    const isRollback =
      new Date(previousCommit?.date || '').getTime() >
      new Date(currentCommit?.date || '').getTime();
    const createdOn = isRollback
      ? deployments.summary?.lastSuccessfulDeployment?.deployable.created_on ||
        ''
      : deployments.summary?.deployment?.deployable.created_on || '';
    return {
      ...deployments.summary,
      revisionFrom:
        !previousCommit?.hash && currentCommit?.parentHash
          ? currentCommit.parentHash
          : previousCommit?.hash || '',
      revisionTo: currentCommit?.hash || '',
      createdOn,
      isRollback,
    };
  }
);

export const getDeploymentPreview = createSelector(
  getRepository,
  ({ deployments }) => {
    const {
      environment,
      environment: { latest_successful_deployment: deployment },
      deployment: previousDeployment,
    } = deployments.preview;

    const previousCommit = previousDeployment.deployable.commit;
    const currentCommit = deployment?.deployable?.commit;

    const isRollback =
      new Date(previousCommit?.date || '').getTime() >
      new Date(currentCommit?.date || '').getTime();
    const createdOn = isRollback
      ? previousDeployment.deployable.created_on || ''
      : deployment?.deployable?.created_on || '';

    return {
      deployment,
      environment: new Environment({
        uuid: environment.nextPromotion?.['environment.uuid'],
        name: environment.nextPromotion?.['environment.name'],
        next_promotion: (environment?.toJS?.() as any)?.next_promotion,
      }),
      previousDeployment,
      revisionFrom:
        !previousCommit?.hash && currentCommit?.parentHash
          ? currentCommit.parentHash
          : previousCommit?.hash || '',
      revisionTo: currentCommit?.hash || '',
      createdOn,
      isRollback,
    };
  }
);

export const getRedeployment = createSelector(
  getRepository,
  ({ deployments }) => {
    const {
      deployment,
      environment,
      lastSuccessfulDeployment: previousDeployment,
    } = deployments.redeployment;

    const previousCommit = previousDeployment.deployable.commit;
    const currentCommit = deployment.deployable.commit;

    const isRollback =
      new Date(previousCommit?.date || '').getTime() >
      new Date(currentCommit?.date || '').getTime();
    const createdOn = isRollback
      ? previousDeployment.deployable.created_on || ''
      : deployment.deployable.created_on || '';

    return {
      deployment,
      environment,
      previousDeployment,
      revisionFrom:
        !previousCommit?.hash && currentCommit?.parentHash
          ? currentCommit.parentHash
          : previousCommit?.hash || '',
      revisionTo: currentCommit?.hash || '',
      createdOn,
      isRollback,
    };
  }
);

export const isFetchingDeploymentDashboard = createSelector(
  getRepository,
  ({ deployments }) =>
    deployments.dashboard.fetchedStatus === LoadingStatus.Before ||
    deployments.dashboard.fetchedStatus === LoadingStatus.Fetching
);

export const isUpdatingDeploymentDashboard = createSelector(
  getRepository,
  ({ deployments }) =>
    deployments.dashboard.reorderedStatus === LoadingStatus.Fetching
  // If there're any other update status, add them here
);

export const isUpdatingEnvironment = createSelector(
  getRepository,
  ({ deployments }) =>
    (environmentUuid: string | undefined) =>
      !!environmentUuid &&
      !!deployments.dashboard.updatingEnvironmentUuids?.[environmentUuid]
);

export const getEnvironmentHistoryMap = createSelector(
  getRepository,
  ({ deployments }) => {
    return deployments.environmentHistory.map;
  }
);

export const getFetchingEnvironmentHistoryMap = createSelector(
  getRepository,
  ({ deployments }) =>
    (environmentUuid: string) =>
      deployments.environmentHistory.fetchedStatus?.[environmentUuid] ===
      LoadingStatus.Fetching
);

export const getHasDeployments = createSelector(
  getDeploymentDashboard,
  dashboard => {
    return (
      dashboard
        .flat()
        .filter(
          (e: Environment) =>
            e?.latest_deployment || e?.latest_successful_deployment
        ).length > 0
    );
  }
);

export const getHasOnboardedDeployments = createSelector(
  getRepository,
  getCurrentRepository,
  getHasDeployments,
  ({ deployments }, repository, hasDeployments) => {
    const key = `${DEPLOYMENTS_ONBOARDED_KEY}-${repository?.uuid}`;
    if (store.get(key)) {
      return true;
    } else if (
      deployments.dashboard.fetchedStatus === LoadingStatus.Success &&
      hasDeployments
    ) {
      store.set(`${DEPLOYMENTS_ONBOARDED_KEY}-${repository?.uuid}`, true);
      return true;
    }
    return false;
  }
);

const reduceEnvironmentsToSelectOptions = (environments: Environment[]) => {
  return environments.reduce(
    (reducer: { label: string; value: string }[], env, index: number) => {
      if (env) {
        const val = {
          label: env.name,
          value: env.name,
          uuid: env.uuid,
          type: env.type,
        };
        reducer[index] = val;
      }
      return reducer;
    },
    []
  );
};

export const getDeploymentDashboardAsSelectOptions = createSelector(
  getDeploymentDashboard,
  deploymentDashboard => {
    const envs: { label: string; options: any }[] = [];
    deploymentDashboard.forEach((environments: Environment[]) => {
      if (environments.length > 0) {
        const group = `${environments[0].type} environments`;
        envs.push({
          label: group,
          options: reduceEnvironmentsToSelectOptions(environments),
        });
      }
    });

    if (envs.length !== 0) {
      envs.unshift({
        label: 'Other',
        options: [
          { label: 'No environment', value: 'no environment', uuid: '' },
        ],
      });
    }
    return envs;
  }
);
