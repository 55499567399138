import React, { ReactNode } from 'react';

import { Box, xcss } from '@atlaskit/primitives';

import { Pipeline } from 'src/components/pipelines/models';

import {
  PipelineStatusBuildNumber,
  PipelineStatusIcon,
  PipelineStatusWrapper,
} from '../styled';

export interface Props {
  pipeline: Pipeline;
  children?: ReactNode;
}

const ActionGroupStyles = xcss({
  display: 'flex',
  flexDirection: 'row',
  gap: 'space.100',
});

/* eslint @typescript-eslint/ban-types: "warn" */
const PipelineStatus: React.FC<Props> = ({ pipeline, children }) => {
  if (!pipeline.uuid) {
    return <PipelineStatusWrapper />;
  }
  return (
    <PipelineStatusWrapper pipeline={pipeline}>
      <PipelineStatusIcon status={pipeline.statusIcon} isLabelHidden />
      <PipelineStatusBuildNumber>
        #{pipeline.build_number}
      </PipelineStatusBuildNumber>
      <Box xcss={ActionGroupStyles}>{children}</Box>
    </PipelineStatusWrapper>
  );
};

export default React.memo(PipelineStatus);
