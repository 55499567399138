import { defineMessages } from 'react-intl';

export default defineMessages({
  no: {
    id: 'frontbucket.workspace.settings.group.none',
    defaultMessage: 'No',
    description:
      'Describes access to future repositories when the group`s default access is `none`',
  },

  workspacePermissions: {
    id: 'frontbucket.workspace.settings.group.workspacePermissions',
    defaultMessage: 'Workspace permissions',
    description: 'Workspace permissions section title',
  },
  defaultAccessGroupPrefix: {
    id: 'frontbucket.workspace.settings.group.defaultAccessGroupPrefix',
    defaultMessage: 'This group is set as your',
    description: 'Workspace permissions default group prefix',
  },
  defaultAccessGroup: {
    id: 'frontbucket.workspace.settings.group.defaultAccessGroup',
    defaultMessage: 'default group',
    description: 'Workspace permissions default group',
  },
  canAdminister: {
    id: 'frontbucket.workspace.settings.group.canAdminister',
    defaultMessage: 'Can administer all workspace and repository settings',
    description: 'Workspace permissions administer item',
  },
  canAdministerWithProject: {
    id: 'frontbucket.workspace.settings.group.canAdministerWithProject',
    defaultMessage:
      'Can administer all workspace, project and repository settings',
    description: 'Workspace permissions administer item',
  },
  canCreateProjects: {
    id: 'frontbucket.workspace.settings.group.canCreateProjects',
    defaultMessage: 'Can create projects for this workspace',
    description: 'Workspace permissions projects item',
  },
  canCreateRepositories: {
    id: 'frontbucket.workspace.settings.group.canCreateRepositories',
    defaultMessage: 'Can create repositories for this workspace',
    description: 'Workspace permissions repositories item',
  },
  assignAccess: {
    id: 'frontbucket.workspace.settings.group.assignAccess',
    defaultMessage:
      'User will be assigned {permissionLevel} access on all future repositories',
    description: 'Workspace permissions assign access',
  },

  enabled: {
    id: 'frontbucket.workspace.settings.group.access.enabled',
    defaultMessage: 'Enabled',
    description: 'Workspace permission access level',
  },
  disabled: {
    id: 'frontbucket.workspace.settings.group.access.disabled',
    defaultMessage: 'Disabled',
    description: 'Workspace permission access level',
  },

  usersTab: {
    id: 'frontbucket.workspace.settings.group.tab.users',
    defaultMessage: 'Users',
    description: 'Users tab label',
  },
  repositoriesTab: {
    id: 'frontbucket.workspace.settings.group.tab.repositories',
    defaultMessage: 'Repositories',
    description: 'Repositories tab label',
  },
  projectsAndRepositoriesTab: {
    id: 'frontbucket.workspace.settings.group.tab.projectsAndRepositories',
    defaultMessage: 'Projects & Repositories',
    description: 'Projects and repositories tab label',
  },
  write: {
    id: 'frontbucket.workspace.settings.group.write',
    defaultMessage: 'Write',
    description: 'Write permission',
  },
  read: {
    id: 'frontbucket.workspace.settings.group.read',
    defaultMessage: 'Read',
    description: 'Read permission',
  },
  admin: {
    id: 'frontbucket.workspace.settings.group.admin',
    defaultMessage: 'Admin',
    description: 'Admin permission',
  },

  private: {
    id: 'frontbucket.workspace.settings.group.private',
    defaultMessage: 'Private',
    description: 'Private publicity',
  },
  public: {
    id: 'frontbucket.workspace.settings.group.public',
    defaultMessage: 'Public',
    description: 'Public publicity',
  },
  privacy: {
    id: 'frontbucket.workspace.settings.group.privacy',
    defaultMessage: 'Privacy',
    description: 'Privacy publicity',
  },
  all: {
    id: 'frontbucket.workspace.settings.group.all',
    defaultMessage: 'All',
    description: 'All publicity',
  },

  removeRepoModalMessage: {
    id: 'frontbucket.workspace.settings.group.removeRepoModalMessage',
    defaultMessage:
      'You are about to remove {repoName} from the current group.',
    description: 'Modal message before removing the repository from the group',
  },
  removeRepoHeader: {
    id: 'frontbucket.workspace.settings.group.removeRepoHeader',
    defaultMessage: 'Remove repository',
    description: 'Modal header before removing the repository from the group',
  },
  addRepoButtonLabel: {
    id: 'frontbucket.workspace.settings.group.addRepoButtonLabel',
    defaultMessage: 'Add repository',
    description:
      'Text for button trigger to display modal for bulk add repository access to group',
  },
  addMembersButtonLabel: {
    id: 'frontbucket.workspace.settings.group.addMembersButtonLabel',
    defaultMessage: 'Add members',
    description:
      'Text for button trigger to display modal for bulk add user access to a group',
  },
  success: {
    id: 'frontbucket.workspace.settings.groups.success',
    defaultMessage: 'Success',
    description: 'Header text for various success notification flags',
  },
  error: {
    id: 'frontbucket.workspace.settings.groups.error',
    defaultMessage: 'Error',
    description: 'Header text for various error notification flags',
  },
  successAddReposToGroupBody: {
    id: 'frontbucket.workspace.settings.groups.successAddReposToGroupBody',
    defaultMessage:
      'Access to {repoNames}{overflowCount, plural, =0 {} one {, and 1 other repository} other {, and {overflowCount} other repositories}} granted to {groupName}',
    description:
      'Body text for notification flag after successful addition of repository access to a group',
  },
  successRemoveRepos: {
    id: 'frontbucket.workspace.settings.group.successRemoveRepos',
    defaultMessage:
      'Access to {repoNames}{overflowCount, plural, =0 {} one {, and 1 other repository} other {, and {overflowCount} other repositories}} removed from {groupName}',
    description:
      'Notification message after successfully removing repository access from a group',
  },
  successChangeRepoPrivileges: {
    id: 'frontbucket.workspace.settings.group.successChangeRepoPrivileges',
    defaultMessage:
      'Access to {repoNames}{overflowCount, plural, =0 {} one {, and 1 other repository} other {, and {overflowCount} other repositories}} updated for {groupName}',
    description:
      'Notification message after successfully updating repository access for a group',
  },
  groupsBreadcrumb: {
    id: 'frontbucket.workspace.settings.group.groupsBreadcrumb',
    defaultMessage: 'User groups',
    description: 'Breadcrumb link name to the workspace groups settings',
  },
  successEditGroup: {
    id: 'frontbucket.workspace.settings.group.successEditGroup',
    defaultMessage:
      'Successfully changed the workspace permissions for the "{groupName}" group',
    description: 'Notification message after successful group editing',
  },

  edit: {
    id: 'frontbucket.workspace.settings.group.edit',
    defaultMessage: 'Edit',
    description: 'Edit group button label',
  },
  removeAccessContent: {
    id: 'frontbucket.workspace.settings.group.bulkRemoveReposModal.removeAccessContent',
    description: 'Remove access modal content message',
    defaultMessage:
      'You are about to remove group access to {count, plural, one {{countMarkup} repository} other {{countMarkup} repositories}}.',
  },
  invitationsSent: {
    id: 'frontbucket.workspace.settings.group.notification.invitationsSent',
    description:
      'Success flag header that shows after user inputs email addresses and triggers invitations',
    defaultMessage:
      '{count, plural, one {Invitation} other {Invitations}} sent',
  },
  invitationsSentDescription: {
    id: 'frontbucket.workspace.settings.group.notification.invitationsSentDescription',
    description: 'Label for the "Edit permissions" button',
    defaultMessage:
      '{count} {count, plural, one {user was} other {users were}} invited to the current group.',
  },
  memberAdded: {
    id: 'frontbucket.workspace.settings.group.notification.memberAdded',
    description:
      'Success flag header that shows after group a member or members have been added',
    defaultMessage:
      'Workspace {count, plural, one {member} other {members}} added',
  },
  memberAddedDescription: {
    id: 'frontbucket.workspace.settings.group.notification.memberAddedDescription',
    description: 'Label for the "Edit permissions" button',
    defaultMessage:
      '{count} {count, plural, one {member was} other {members were}} added to the current group.',
  },
  inviteFailedErrorHeader: {
    id: 'frontbucket.workspace.settings.group.notification.inviteFailedErrorHeader',
    description:
      'Header for error notification body that appears if a user could not be added by email or invited.',
    defaultMessage: 'Invitation error',
  },
  inviteFailedErrorBody: {
    id: 'frontbucket.workspace.settings.group.notification.inviteFailedErrorBody',
    description:
      'Message for error notification body that appears if a user could not be added by email or invited.',
    defaultMessage:
      'The following {count, plural, one {user} other {users}} could not be invited: {entity}. Try again.',
  },
});
