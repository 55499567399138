import React, { useCallback, useEffect, useState } from 'react';

import Button from '@atlaskit/button';
import IconRemove from '@atlaskit/icon/glyph/editor/remove';
import IconFolder from '@atlaskit/icon/glyph/folder-filled';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import Popup from '@atlaskit/popup';
import Spinner from '@atlaskit/spinner';
import TableTree, { Cell, Row, Rows } from '@atlaskit/table-tree';
import Tooltip from '@atlaskit/tooltip';

import {
  Account,
  Cache,
  CacheVersion,
  Repository,
} from 'src/components/pipelines/models';

import CachesIcon from '../assets/CachesIcon';
import { DOCS_DEPENDENCY_CACHES_URL } from '../constants';
import { formatDate } from '../utils';
import formatBytes from '../utils/formatBytes';

import CacheVersionRow from './CacheVersionRow';
import {
  CacheRemoveGroupButton,
  CachesDialogWrapper,
  CachesEmpty,
  CachesIconCell,
  CachesList,
  CachesName,
  CachesTitle,
  CachesVersionsLabel,
  CachesWrapper,
} from './styled';

export type CachesProps = {
  account: Account;
  repository: Repository;
  fetchingCaches: boolean;
  caches: Cache[];
  getCaches: () => void;
  deleteCache: (cacheUuid: string, cacheGroupName: string) => void;
  deleteCaches: (cacheGroupName: string) => void;
  _isDialogOpen?: boolean;
  _isDialogExpanded?: boolean;
};

export type CacheItem = {
  id: string;
  label: string;
  versions: CacheItem[];
  name?: string;
  fileSize?: string;
  createdOn?: string;
  parentName?: string;
  pipelineUuid?: string;
};

const formatVersionString = (length: number) =>
  `${length} Version${length > 1 ? 's' : ''}`;

const formatKeyHash = (hash: string) => (hash ? hash.substring(0, 7) : '-');

const transformCacheVersionToItem = (
  version: CacheVersion,
  parentName: string,
  pipelineUuid: string
): CacheItem => ({
  id: version.uuid,
  label: formatKeyHash(version.key_hash),
  versions: [],
  fileSize: formatBytes(version.file_size_bytes),
  createdOn: version.created_on,
  parentName,
  pipelineUuid,
});

/* eslint @typescript-eslint/ban-types: "warn" */
const Caches: React.FC<CachesProps> = ({
  account,
  repository,
  fetchingCaches,
  caches,
  getCaches,
  deleteCache,
  deleteCaches,
  _isDialogOpen = false,
  _isDialogExpanded = false,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(_isDialogOpen);
  const [cacheItemToDelete, setCacheItemToDelete] = useState<CacheItem>();
  const [cacheGroupSelected, setCacheGroupSelected] = useState(false);
  const [items, setItems] = useState<CacheItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isDialogOpen) {
      if (!_isDialogOpen) {
        setIsLoading(true);
      }
      getCaches();
    }
  }, [getCaches, _isDialogOpen, isDialogOpen]);

  useEffect(() => {
    const cacheItems: CacheItem[] = caches?.map(cache => {
      const groupName = `${cache.name}: ${cache.path}`;
      return {
        id: groupName,
        label: groupName,
        name: cache.name,
        versions: cache.versions.map(version =>
          transformCacheVersionToItem(version, cache.name, cache.pipeline_uuid)
        ),
      };
    });
    setItems(cacheItems);
    setIsLoading(false);
  }, [caches]);

  const toggleShowingDialog = useCallback(() => {
    setIsDialogOpen(!isDialogOpen);
  }, [isDialogOpen]);

  const onCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const onCloseDeleteDialog = useCallback(() => {
    setCacheItemToDelete(undefined);
    setCacheGroupSelected(false);
  }, []);

  const onOpenDeleteDialog = useCallback((item: CacheItem) => {
    if (!item.parentName) {
      setCacheGroupSelected(true);
    }
    setCacheItemToDelete(item);
  }, []);

  const onConfirmDeleteDialog = useCallback(() => {
    if (cacheGroupSelected) {
      deleteCaches(cacheItemToDelete!.name!);
    } else {
      deleteCache(cacheItemToDelete!.id, cacheItemToDelete!.parentName!);
    }
    onCloseDeleteDialog();
  }, [
    deleteCache,
    deleteCaches,
    onCloseDeleteDialog,
    cacheItemToDelete,
    cacheGroupSelected,
  ]);

  return (
    <>
      <Popup
        content={() => (
          <CachesDialogWrapper>
            <CachesWrapper>
              <header>
                <CachesTitle>Dependency caches</CachesTitle>
              </header>
              {fetchingCaches || isLoading ? (
                <Spinner size="small" testId="loading-spinner" />
              ) : (
                <CachesList>
                  {items.length > 0 ? (
                    <TableTree>
                      <Rows
                        items={items}
                        render={(item: CacheItem) =>
                          item.versions?.length > 0 ? (
                            <Row
                              itemId={item.id}
                              hasChildren
                              items={item.versions}
                              isDefaultExpanded={_isDialogExpanded}
                            >
                              <Cell width={54}>
                                <CachesIconCell>
                                  <IconFolder label="Cache" size="medium" />
                                </CachesIconCell>
                              </Cell>
                              <Cell>
                                <CachesName>{item.label}</CachesName>
                              </Cell>
                              <Cell width={183}>
                                <CachesVersionsLabel>
                                  {formatVersionString(item.versions.length)}
                                </CachesVersionsLabel>
                              </Cell>
                              <Cell width={82}>
                                <CacheRemoveGroupButton>
                                  <Tooltip
                                    content="Delete caches"
                                    position="top"
                                  >
                                    <Button
                                      appearance="subtle"
                                      iconAfter={<IconRemove label="Remove" />}
                                      onClick={() => onOpenDeleteDialog(item)}
                                    />
                                  </Tooltip>
                                </CacheRemoveGroupButton>
                              </Cell>
                            </Row>
                          ) : (
                            <CacheVersionRow
                              account={account}
                              repository={repository}
                              item={item}
                              onClickRemove={onOpenDeleteDialog}
                            />
                          )
                        }
                      ></Rows>
                    </TableTree>
                  ) : (
                    <CachesEmpty>
                      <CachesIcon />
                      <h5>You haven&apos;t added any dependency caches yet</h5>
                      <p>
                        Speed up your build by caching your dependencies.{' '}
                        <Button
                          href={DOCS_DEPENDENCY_CACHES_URL}
                          spacing="none"
                          appearance="link"
                          target="_blank"
                        >
                          Learn more
                        </Button>
                      </p>
                    </CachesEmpty>
                  )}
                </CachesList>
              )}
            </CachesWrapper>
          </CachesDialogWrapper>
        )}
        onClose={onCloseDialog}
        isOpen={isDialogOpen}
        placement="bottom-end"
        trigger={triggerProps => (
          <Button {...triggerProps} onClick={toggleShowingDialog}>
            Caches
          </Button>
        )}
      ></Popup>
      <ModalTransition>
        {cacheItemToDelete && (
          <Modal onClose={onCloseDeleteDialog}>
            <ModalHeader>
              <ModalTitle appearance="danger">
                {`Delete cache${cacheGroupSelected ? 's' : ''}`}
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <p>
                {cacheGroupSelected ? (
                  <>
                    Are you sure you want to delete the{' '}
                    <strong>{cacheItemToDelete.name}</strong> cache and all
                    associated versions?
                  </>
                ) : (
                  <>
                    Are you sure you want to delete{' '}
                    {cacheItemToDelete.label !== '-' && (
                      <>
                        version <strong>{cacheItemToDelete.label}</strong> of
                      </>
                    )}{' '}
                    the <strong>{cacheItemToDelete.parentName}</strong> cache
                    created on{' '}
                    {formatDate(cacheItemToDelete.createdOn!, 'date-au')}?
                  </>
                )}
              </p>
            </ModalBody>
            <ModalFooter>
              <Button appearance="subtle" onClick={onCloseDeleteDialog}>
                Cancel
              </Button>
              <Button
                appearance="danger"
                onClick={onConfirmDeleteDialog}
                autoFocus
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default React.memo(Caches);
