import React, { ReactNode, useMemo } from 'react';

import Button from '@atlaskit/button';

import { ORIGIN } from '../constants';

export interface Props {
  children?: ReactNode;
  className?: string;
  href: string;
  buttonProps?: any;
}

/* eslint @typescript-eslint/ban-types: "warn" */
const ExternalLink: React.FC<Props> = ({
  children,
  className,
  href,
  buttonProps,
}) => {
  const target = useMemo(
    () => (href.indexOf(ORIGIN) === -1 ? '_blank' : '_top'),
    [href]
  );

  return buttonProps ? (
    <Button href={href} target={target} {...buttonProps}>
      {children}
    </Button>
  ) : (
    <a
      href={href}
      target={target}
      rel="noopener noreferrer"
      {...(className ? { className } : '')}
    >
      {children}
    </a>
  );
};

export default React.memo(ExternalLink);
