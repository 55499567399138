import { css } from '@emotion/core';
/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';
import { injectGlobal } from 'styled-components';

import { HORIZONTAL_GLOBAL_NAV_HEIGHT } from '@atlaskit/atlassian-navigation/dist/esm/common/constants';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { BANNER_HEIGHT } from 'src/constants/navigation';

/* In right-to-left languages, the dir(rtl) here keeps the sidebar on the right of the page content */
const pageWithSidebarStyles = css({
  display: 'flex',
  width: '100%',
  boxSizing: 'border-box',
  '&:dir(rtl)': {
    flexDirection: 'row-reverse',
  },
});

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
injectGlobal`
  body {
    background-color: ${token('elevation.surface', colors.N0)}
  }
`;
export const PageLayout = styled.div<{
  isMobileHeaderActive: boolean;
}>`
  ${({ isMobileHeaderActive }) => {
    // In a separate file the LayoutManager `experimental_horizontalGlobalNav` prop is applied
    // to force the nav-next component to hide itself (but remain mounted) when the mobile nav
    // is visible. Unfortunately setting the 'experimental_horizontalGlobalNav' prop causes
    // a `margin-top` to be applied, which would make sense if we were actually using the
    // horizontal nav, but since horizontal nav is not yet rolled out we negate the `margin-top`
    // here. (fd-horizontal-nav)
    return isMobileHeaderActive
      ? css({
          marginTop: `-${HORIZONTAL_GLOBAL_NAV_HEIGHT}px`,
        })
      : pageWithSidebarStyles;
  }}
`;

// Setting an explicit flex-basis here instead of 'auto' fixes IE 11 not
// correctly shrinking the child flex container for the page header.
// IE 11 requires a unit for the 3rd argument (flex basis).
// SEE: known issues on https://caniuse.com/#feat=flexbox
//
// NOTE: DO NOT set `overflow` on this or change `100%` to `100vh`.
// If you do you will cause a massive scroll performance regression.
// SEE: https://hello.atlassian.net/wiki/spaces/BB/blog/2019/02/14/399650562/Improving+Scroll+Performance+on+Bitbucket
export const Content = styled.div<{
  isBannerOpen?: boolean;
  consentBannerHeight?: number;
}>`
  flex: 1 1 0px; /* stylelint-disable-line length-zero-no-unit */
  min-width: 0;
  min-height: ${props =>
    props.isBannerOpen
      ? `calc(100% - ${BANNER_HEIGHT + (props.consentBannerHeight || 0)}px)`
      : `calc(100% - ${props.consentBannerHeight || 0}px)`};
`;

export const StyledPageLayout = styled.div`
  & > :first-of-type {
    width: 100vw;
  }
`;
