import React, { useCallback, useEffect, useRef, useState } from 'react';

import { defineMessages } from 'react-intl';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';

import { useAnalytics } from 'src/components/settings/analytics';
import { SCROLL_TO_DURATION } from 'src/constants/permalink-scroll';
import { useIntl } from 'src/hooks/intl';
import { useParams } from 'src/router/hooks';

import { InvitationsPanel } from '../invitations-panel';
import PrivilegesDropdown from '../privileges-dropdown';

import AccessLevelDropdown from './access-level-dropdown';
import ActiveModal from './active-modal';
import { LoadingState } from './constants';
import PaginatedTable from './paginated-table';
import {
  useCheckedRows,
  useFilters,
  useGetPermissions,
  useLoadingState,
  usePermissions,
  usePermissionsContext,
} from './provider';
import SearchField from './search-field';
import { PermissionsTableContainer, TextFieldSection } from './styled';
import { PermissionsAPIProps } from './types';
import { useTableHeader } from './use-table-header';
import { useTableRows } from './use-table-rows';
import { includesPermission } from './utils';

const messages = defineMessages({
  resultsEmptyMessage: {
    id: 'frontbucket.settings.permissions.resultsEmptyMessage',
    description: 'Results empty message',
    defaultMessage: 'No results were found matching your search',
  },
});

export type PermissionsProps = PermissionsAPIProps & {
  heading: JSX.Element;
  showAccessLevel?: boolean;
};

const Permissions = ({
  api,
  heading,
  showAccessLevel = true,
}: PermissionsProps) => {
  const { formatMessage } = useIntl();
  const { publishScreenEvent, publishButtonClickEvent } = useAnalytics();
  const [{ permissions }] = usePermissions();
  const [{ loadingState }] = useLoadingState();
  const [{ checkedRows }] = useCheckedRows();
  const [{ context }] = usePermissionsContext();
  const [
    { limit, page, total, privilegeFilter, accessLevelFilter, searchTerm },
  ] = useFilters();
  const defaultSearchTermRef = useRef(searchTerm);
  const getPermissions = useGetPermissions(api.getPermissions);
  const checkedCount = checkedRows.length;
  const permissionsCurrentPage = permissions.get(page) || [];
  const isLoading = loadingState === LoadingState.LOADING;

  const checkedCountCurrentPage = permissionsCurrentPage.reduce(
    (index, row) =>
      row && includesPermission(checkedRows, row) ? index + 1 : index,
    0
  );

  const {
    groupSlug,
    repositorySlug: repoSlug,
    projectKey,
    ...params
  } = useParams<{
    groupSlug?: string;
    projectKey?: string;
    workspaceSlug?: string;
    repositoryOwner?: string;
    repositorySlug?: string;
  }>();

  const workspaceSlug = params.workspaceSlug || params.repositoryOwner || '';

  const { header: head, actions } = useTableHeader({
    checkedCount,
    checkedCountCurrentPage,
    limit,
    isDisabled: isLoading,
    showAccessLevel,
  });

  const rows = useTableRows({
    api,
    permissions: permissionsCurrentPage,
    checkedRows,
    workspaceSlug,
    showAccessLevel,
  });
  useEffect(publishScreenEvent, [publishScreenEvent]);

  const [invitationsCount, setInvitationsCount] = useState(0);

  const refreshInvitations = useCallback(
    () => setInvitationsCount(n => n + 1),
    [setInvitationsCount]
  );

  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      smoothScrollIntoView(root, { duration: SCROLL_TO_DURATION });
    }
  }, [page]);

  return (
    <>
      {heading}
      <p>
        <InvitationsPanel
          groupSlug={groupSlug}
          projectKey={projectKey}
          repoSlug={repoSlug}
          workspaceSlug={workspaceSlug}
          refreshCount={invitationsCount}
        />
      </p>
      <PermissionsTableContainer>
        <TextFieldSection>
          <SearchField api={api} defaultValue={defaultSearchTermRef.current} />
          <PrivilegesDropdown
            value={privilegeFilter}
            onChange={privilege => {
              getPermissions(1, {
                privilegeFilter: privilege,
              });
              publishButtonClickEvent('PrivilegeFilter', {
                privilege,
              });
            }}
            isDisabled={isLoading}
            showOptionDescription={false}
            allowEmptyChoice
            testId="privilegesFilterDropdown"
            context={context}
            isFilter
          />
          {showAccessLevel && context !== 'workspace' && (
            <AccessLevelDropdown
              value={accessLevelFilter}
              onChange={accessLevel => {
                getPermissions(1, {
                  accessLevelFilter: accessLevel,
                });
                publishButtonClickEvent('AccessLevelFilter', {
                  accessLevel,
                });
              }}
              isProjectContext={context === 'project'}
              isDisabled={isLoading}
              allowEmptyChoice
            />
          )}
        </TextFieldSection>
        {actions}
        <PaginatedTable
          head={head}
          rows={rows}
          limit={limit}
          total={total}
          page={page}
          isLoading={isLoading}
          isDisabled={isLoading}
          onPageChange={nextPage => getPermissions(nextPage)}
        />
        {total <= 0 && loadingState !== LoadingState.LOADING && (
          <p>{formatMessage(messages.resultsEmptyMessage)}</p>
        )}
      </PermissionsTableContainer>
      <ActiveModal api={api} refreshInvitations={refreshInvitations} />
    </>
  );
};

export default Permissions;
