import { blockquote as blockquoteFactory } from '../../next-schema/generated/nodeTypes';
import { blockquoteLegacy as blockquoteLegacyFactory, blockquoteWithoutNestedCodeblockOrMedia as blockquoteWithoutNestedCodeblockOrMediaFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name blockquote_node
 */

var nodeSpecOptions = {
  parseDOM: [{
    tag: 'blockquote'
  }],
  toDOM: function toDOM() {
    return ['blockquote', 0];
  }
};
export var blockquote = blockquoteLegacyFactory(nodeSpecOptions);
export var blockquoteWithNestedCodeblockOrMedia = blockquoteFactory(nodeSpecOptions);

/**
 * @name blockquote_with_list_node
 */
export var blockquoteWithList = blockquoteWithoutNestedCodeblockOrMediaFactory({
  parseDOM: [{
    tag: 'blockquote'
  }],
  toDOM: function toDOM() {
    return ['blockquote', 0];
  }
});