import React, { useMemo } from 'react';

import { Link } from 'react-resource-router';

import { Account, Repository } from 'src/components/pipelines/models';

import isInIframe from '../utils/isInIframe';
import { getPipelinesPageURL } from '../utils/links';

export type Props = {
  account?: Account;
  repository?: Repository;
  to: string;
  children: React.ReactNode;
};

/* eslint @typescript-eslint/ban-types: "warn" */
const BitbucketLink: React.FC<Props> = ({
  account,
  repository,
  children,
  to,
  ...rest
}) => {
  const href = useMemo(
    () =>
      repository?.path && account?.addonKey
        ? `${getPipelinesPageURL(repository.path, encodeURI(to))}`
        : to,
    [account, repository, to]
  );

  return isInIframe() ? (
    <a
      {...{ ...rest, href }}
      {...(repository?.path && account?.addonKey ? { target: '_parent' } : {})}
    >
      {children}
    </a>
  ) : (
    <Link to={to}>{children}</Link>
  );
};

export default React.memo(BitbucketLink);
