/** @jsx jsx */
import { css, jsx } from '@emotion/react';
var labelTextStyles = css({
  alignSelf: 'center',
  gridArea: '1 / 2 / 2 / 3'
});
export default function LabelText(_ref) {
  var children = _ref.children;
  return jsx("span", {
    css: labelTextStyles
  }, children);
}