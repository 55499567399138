import { rule as ruleFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name rule_node
 */

var hrDOM = ['hr'];
export var rule = ruleFactory({
  parseDOM: [{
    tag: 'hr'
  }],
  toDOM: function toDOM() {
    return hrDOM;
  }
});