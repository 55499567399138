/* eslint-disable no-underscore-dangle */
export interface Action {
  key: string;
  name: string;
  isVisible: boolean;
  disabled?: DisabledState;
  onClick: () => void;
}

// Represent the state when action is disabled.
export class DisabledState {
  static readonly empty = new DisabledState(false, '');

  // value=true represent the disabled state.
  // value=false means that action can be triggered.
  private readonly _value: boolean;
  // the user-friendly explainaition of why state was disabeld.
  private readonly _reason: string;

  constructor(value: boolean, reason: string) {
    this._value = value;
    this._reason = reason;
  }

  get value(): boolean {
    return this._value;
  }

  get reason(): string {
    return this._value ? this._reason : '';
  }

  or(other: DisabledState): DisabledState {
    return new DisabledState(
      this.value || other.value,
      this.reason || other.reason
    );
  }
}
