import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import {
  REQUEST_CREATE_ENVIRONMENT_VARIABLE,
  REQUEST_DELETE_ENVIRONMENT_VARIABLE,
  REQUEST_ENVIRONMENT_VARIABLES,
  REQUEST_UPDATE_ENVIRONMENT_VARIABLE,
} from '../actions/variables';

import {
  VariablesState,
  requestCreateVariableBegin,
  requestCreateVariableError,
  requestCreateVariableSuccess,
  requestDeleteVariableBegin,
  requestUpdateVariableBegin,
  requestUpdateVariableError,
  requestUpdateVariableSuccess,
  requestVariablesBegin,
  requestVariablesSuccess,
  initialState as variablesInitialState,
} from './variables';

export type EnvironmentVariablesState = {
  map: Record<string, VariablesState>;
};

export const initialState: EnvironmentVariablesState = {
  map: {},
};

const getVariableStore = (state: EnvironmentVariablesState, action: any) =>
  state.map[action?.meta?.environmentUuid] || variablesInitialState;

export default createReducer(initialState, {
  [REQUEST_CREATE_ENVIRONMENT_VARIABLE.REQUEST](
    state: EnvironmentVariablesState,
    action: Action<void> & {
      meta: { variable: any; environmentUuid: string };
    }
  ) {
    if (!action.meta) {
      return state;
    }
    return {
      map: {
        ...state.map,
        [action.meta.environmentUuid]: requestCreateVariableBegin(
          getVariableStore(state, action),
          action
        ),
      },
    };
  },
  [REQUEST_CREATE_ENVIRONMENT_VARIABLE.SUCCESS]: (
    state: EnvironmentVariablesState,
    action: Action<any> & {
      meta: { variable: any; environmentUuid: string };
    }
  ) => {
    if (!action.meta) {
      return state;
    }
    return {
      map: {
        ...state.map,
        [action.meta.environmentUuid]: requestCreateVariableSuccess(
          getVariableStore(state, action),
          action
        ),
      },
    };
  },
  [REQUEST_CREATE_ENVIRONMENT_VARIABLE.ERROR]: (
    state: EnvironmentVariablesState,
    action: Action<{ body: { error: any } }> & {
      meta: { variable: any; environmentUuid: string };
    }
  ) => {
    if (!action.meta) {
      return state;
    }
    return {
      map: {
        ...state.map,
        [action.meta.environmentUuid]: requestCreateVariableError(
          getVariableStore(state, action),
          action
        ),
      },
    };
  },
  [REQUEST_ENVIRONMENT_VARIABLES.REQUEST]: (
    state: EnvironmentVariablesState,
    action: Action<any> & {
      meta: { environmentUuid: string };
    }
  ) => {
    if (!action.meta) {
      return state;
    }
    return {
      map: {
        ...state.map,
        [action.meta.environmentUuid]: requestVariablesBegin(
          getVariableStore(state, action)
        ),
      },
    };
  },
  [REQUEST_ENVIRONMENT_VARIABLES.SUCCESS]: (
    state: EnvironmentVariablesState,
    action: Action<any> & {
      meta: { environmentUuid: string };
    }
  ) => {
    if (!action.meta) {
      return state;
    }
    return {
      map: {
        ...state.map,
        [action.meta.environmentUuid]: requestVariablesSuccess(
          getVariableStore(state, action),
          action
        ),
      },
    };
  },
  [REQUEST_UPDATE_ENVIRONMENT_VARIABLE.REQUEST]: (
    state: EnvironmentVariablesState,
    action: Action<void> & {
      meta: {
        previousVariable: any;
        variable: any;
        environmentUuid: string;
      };
    }
  ) => {
    if (!action.meta) {
      return state;
    }
    return {
      map: {
        ...state.map,
        [action.meta.environmentUuid]: requestUpdateVariableBegin(
          getVariableStore(state, action),
          action
        ),
      },
    };
  },
  [REQUEST_UPDATE_ENVIRONMENT_VARIABLE.SUCCESS]: (
    state: EnvironmentVariablesState,
    action: Action<any> & {
      meta: {
        previousVariable: any;
        variable: any;
        environmentUuid: string;
      };
    }
  ) => {
    if (!action.meta) {
      return state;
    }
    return {
      map: {
        ...state.map,
        [action.meta.environmentUuid]: requestUpdateVariableSuccess(
          getVariableStore(state, action),
          action
        ),
      },
    };
  },
  [REQUEST_UPDATE_ENVIRONMENT_VARIABLE.ERROR]: (
    state: EnvironmentVariablesState,
    action: Action<{ body: { error: any } }> & {
      meta: {
        previousVariable: any;
        variable: any;
        environmentUuid: string;
      };
    }
  ) => {
    if (!action.meta) {
      return state;
    }
    return {
      map: {
        ...state.map,
        [action.meta.environmentUuid]: requestUpdateVariableError(
          getVariableStore(state, action),
          action
        ),
      },
    };
  },
  [REQUEST_DELETE_ENVIRONMENT_VARIABLE.REQUEST]: (
    state: EnvironmentVariablesState,
    action: Action<void> & {
      meta: {
        variable: any;
        variableIndex: number;
        environmentUuid: string;
      };
    }
  ) => {
    if (!action.meta) {
      return state;
    }
    return {
      map: {
        ...state.map,
        [action.meta.environmentUuid]: requestDeleteVariableBegin(
          getVariableStore(state, action),
          action
        ),
      },
    };
  },
});
