import React from 'react';

import Icon, { IconProps } from '@atlaskit/icon';

const defaultGlyph = (props: { [key: string]: string }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    version="1.1"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13 12.394v7.6C13 20.55 12.552 21 12 21s-1-.45-1-1.007v-7.648l-.313.317c-.386.388-1.012.388-1.398 0-.386-.39-.386-1.02 0-1.408l1.918-1.933c.216-.216.5-.323.78-.32h.013c.19 0 .364.052.514.142.083.048.16.107.232.18l1.965 1.98c.387.388.386 1.018 0 1.407-.386.387-1.01.387-1.397-.002L13 12.394zm3.84-6.976c2.804 0 5.16 2.202 5.16 5.08C22 13.41 19.615 16 16.9 16h-2.88v-1.993h2.88c1.657 0 3.115-1.663 3.115-3.508 0-1.78-1.47-3.088-3.104-3.088h-.01c-.39 0-.687.05-.97.15l-.17.063c-.606.248-.876-.246-.876-.246l-.15-.268c-.73-1.347-2.2-2.096-3.716-2.12-2.082.035-3.797 1.548-4.07 3.645l-.045.34s-.07.525-.665.525c-.013 0-.012.006-.023.006H5.96c-1.135 0-1.975.957-1.975 2.157 0 1.206.987 2.342 2.07 2.342h3.964V16H6.054C3.895 16 2 13.955 2 11.665 2 9.665 3.312 8 5.138 7.59 5.845 4.885 8.19 3.04 11.024 3c1.975.02 3.9.9 5.038 2.48.256-.04.516-.062.777-.062z"
    />
  </svg>
);

const DeploymentIcon = (props: IconProps): React.ReactElement => (
  <Icon glyph={defaultGlyph} {...props} />
);

export default DeploymentIcon;
