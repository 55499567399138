import { Deployment, Environment } from 'src/components/pipelines/models';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import { REMOVE_DEPLOYMENT_DATA } from '../actions/deployments';
import { REQUEST_STEP_PROMOTION } from '../actions/pipelines';

export type StepPreviewState = {
  deployment: Deployment;
  environment: Environment;
};

export const initialState: StepPreviewState = {
  deployment: new Deployment(),
  environment: new Environment(),
};

export default createReducer(initialState, {
  [REMOVE_DEPLOYMENT_DATA]() {
    return initialState;
  },
  [REQUEST_STEP_PROMOTION.SUCCESS]: (
    state: StepPreviewState,
    action: Action<any> & { meta: { environmentUuid: string } }
  ) => {
    if (!action.payload || !action.meta) {
      return state;
    }
    const {
      environments: { groupings },
      deployment,
    } = action.payload;
    const { environmentUuid } = action.meta;
    const environments = groupings.reduce(
      (dashboard: any[], group: any) =>
        dashboard.concat(group.environment_summaries),
      []
    );

    const environmentData = environments.find(
      (env: any) => env.uuid === environmentUuid
    );

    return {
      deployment: new Deployment(deployment),
      environment: new Environment(environmentData),
    };
  },
});
