import {
  Privilege,
  AccountPrivilege,
  PrivilegeOptions,
} from 'src/components/types';

export type SimpleUser = {
  id: string;
  name: string;
  avatar: string;
  nickname: string;
  uuid: string;
};

export type SimpleGroupPrivilege = {
  privilege: Privilege;
  group: {
    owner: string;
    slug: string;
  };
  repository: {
    slug: string;
    name: string;
    fullSlug?: string;
  };
};

export type SimpleGroup = {
  id: string;
  name: string;
  description?: string;
  privilege: Privilege | null;
  owner: string;
  members: SimpleUser[];
  repositories?: SimpleGroupPrivilege[];
  account_privilege?: null | AccountPrivilege;
  email_forwarding_disabled: boolean;
  set_as_default: boolean;
};

export type SimpleRepositoryOrProject = {
  uuid: string;
  slug: string;
  avatar: string;
  privilege: Privilege | null;
  description: string;
  name: string;
  project_name?: string;
  is_private: boolean;
  type: 'project' | 'repository';
};

export type InternalWorkspaceGroup = {
  default_permission: BB.Privilege | null;
  name: string;
  extra: {
    repository_count: number;
    members_count: number;
  };
  account_privilege: BB.Privilege | null;
  default_for_workspace_slug: string | null;
  full_slug: string;
  type: 'group';
  slug: string;
};

export type WorkspaceGroupsResponse = {
  size: number;
  pagelen: number;
  page: number;
  values: Array<InternalWorkspaceGroup>;
};

export enum ModalDialogName {
  group = 'group',
  member = 'member',
  remove = 'remove',
  chooseDefaultGroup = 'choose',
  makeDefault = 'makeDefault',
}

export enum ModalDialogUrlHash {
  CREATE_GROUP_MODAL_KEY = 'createGroup',
  CHOOSE_GROUP_MODAL_KEY = 'chooseGroup',
}

export type WorkspaceGroupsPageProps = {
  getWorkspaceGroups: (options: {
    pagelen: number;
    page: number;
    search: string;
    sort: string;
  }) => Promise<WorkspaceGroupsResponse>;
  getWorkspaceDefaultGroup: () => Promise<BB.InternalGroup>;
  chooseWorkspaceDefaultGroup: (data: {
    group_slug: string | number;
  }) => Promise<BB.InternalGroup>;
  createWorkspaceGroup: (
    data: AddEditGroupFormData
  ) => Promise<BB.InternalGroup>;
  removeWorkspaceGroup: (id: string) => Promise<undefined>;
  addMemberToWorkspaceGroup: (data: {
    user: string;
    groups: string[];
  }) => Promise<any>;
};

export type GroupsPageState = {
  refreshCount: number;
  groups: InternalWorkspaceGroup[];
  modalDialogName: ModalDialogName | null;
  isLoadingModal: boolean;
  currentDefaultGroup?: BB.InternalGroup;
  hasFetchedDefaultGroup: boolean;
  groupToRemove: { id: string; name: string } | null;
  groupToDefault: { id: string; name: string } | null;
};

export type GroupsLayoutProps = {
  refreshCount: number;
  getWorkspaceGroups: WorkspaceGroupsPageProps['getWorkspaceGroups'];
  removeAction: (groupToRemove: { id: string; name: string }) => void;
  makeDefaultAction: (groupToDefault: { id: string; name: string }) => void;
  handleFailedResponse: (errorDisplayParams: {
    error: any;
    isShowFlag: boolean;
  }) => void;
  GroupsModal: JSX.Element | null;
};

export type GroupMember = {
  account_id: string;
  display_name: string;
  email: string | null;
  is_active: boolean;
  links: { self: BB.Link; html: BB.Link; avatar: BB.Link };
  nickname: string;
  type: 'user';
  uuid: string;
};

export type GroupMembersEndpointResponse = {
  page: number;
  pagelen: number;
  size: number;
  values: GroupMember[];
};

export type AddEditGroupFormData = {
  name: string;
  privilege: PrivilegeOptions | Privilege;
  account_privilege?: AccountPrivilege | null;
  email_forwarding_disabled: boolean;
  set_as_default: boolean;
};
