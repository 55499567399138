import { ApolloLink } from 'apollo-link';
import { addSpanToAll } from '@atlassian/ufo-interaction-metrics';
export var UFOLoggerLink = new ApolloLink(function (operation, forward) {
  var networkStart = performance.now();
  return forward(operation).map(function (response) {
    var _operation$operationN;
    var networkComplete = performance.now();
    var operationName = (_operation$operationN = operation.operationName) !== null && _operation$operationN !== void 0 ? _operation$operationN : 'unnamed';
    addSpanToAll('graphql', operationName, [{
      name: 'network'
    }], networkStart, networkComplete);
    return response;
  });
});