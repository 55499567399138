import repoUrls from 'src/sections/repository/urls';

export const ui = {
  pullrequest: (owner: string, slug: string, pullRequestId: number) =>
    `${repoUrls.ui.repository(owner, slug)}/pull-requests/${pullRequestId}`,

  pullrequests: (owner: string, slug: string) =>
    `${repoUrls.ui.repository(owner, slug)}/pull-requests`,

  pullrequestdata: (owner: string, slug: string, pullRequestId: number) =>
    `${repoUrls.ui.repository(
      owner,
      slug
    )}/pull-requests/${pullRequestId}?state=view&fields=latest_excluded_files_version`,
};
