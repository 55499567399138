/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { all } from 'redux-saga/effects';

import filterFiles from './filter-files';
import loadFile from './load-file';
import loadFileTreeSagas from './load-file-tree-saga';
import loadSourceObjectSagas from './load-source-object-saga';

export default function* sourceSagas() {
  yield all([
    filterFiles(),
    loadFile(),
    loadFileTreeSagas(),
    loadSourceObjectSagas(),
  ]);
}
