export default () =>
  // @ts-ignore TODO: fix noImplicitAny error here
  ({ dispatch, getState }) =>
  // @ts-ignore TODO: fix noImplicitAny error here
  next =>
  // @ts-ignore TODO: fix noImplicitAny error here
  action => {
    if (typeof action === 'function') {
      return action(dispatch, getState);
    }
    return next(action);
  };
