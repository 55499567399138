import { underline as underlineFactory } from '../../next-schema/generated/markTypes';

/**
 * @name underline_mark
 */

export var underline = underlineFactory({
  parseDOM: [{
    tag: 'u'
  }, {
    style: 'text-decoration',
    getAttrs: function getAttrs(value) {
      return value === 'underline' && null;
    }
  }],
  toDOM: function toDOM() {
    return ['u'];
  }
});