/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { select, take } from 'redux-saga/effects';

import { Team, User } from 'src/components/types';
import { LoadRepositoryPage } from 'src/sections/repository/actions';
import { getCurrentRepositoryOwner } from 'src/selectors/repository-selectors';

export function* getWorkspace() {
  // Unfortunately `workspace` is not reliably present on LoadRepositoryPage.SUCCESS,
  // and since at the moment we only need workspace UUID, fetch the `owner` instead
  // which appears to be more reliable (always comes with `repository` object itself).
  // In the future, when workspace becomes first class citizen in Frontbucket,
  // this will need to be replaced with the proper `workspace` object.
  let owner: User | Team | null | undefined = yield select(
    getCurrentRepositoryOwner
  );
  if (!owner) {
    // Wait for the current repository page to be loaded
    // so that `repository` object becomes available
    yield take(LoadRepositoryPage.SUCCESS);
    owner = yield select(getCurrentRepositoryOwner);
  }

  return { uuid: owner?.uuid };
}
