import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _excluded = ["testId", "valueTextLabel", "isDisabled", "isLeftSidebarCollapsed", "label", "leftSidebarPercentageExpanded", "onKeyDown", "onMouseDown", "onBlur", "onFocus", "ref"];
/* eslint-disable react/react-in-jsx-scope */
/**
 * @jsxRuntime classic
 */
/** @jsx jsx */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { getBooleanFF } from '@atlaskit/platform-feature-flags';
import { B200 } from '@atlaskit/theme/colors';
import { GRAB_AREA_LINE_SELECTOR, GRAB_AREA_SELECTOR } from '../../common/constants';
/**
 * Determines the color of the grab line.
 *
 * Used on internal leaf node, so naming collisions won't matter.
 */
var varLineColor = '--ds-line';
var grabAreaStyles = css({
  width: "var(--ds-space-200, 16px)",
  height: '100%',
  padding: 0,
  backgroundColor: 'transparent',
  border: 0,
  cursor: 'ew-resize',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  '&::-moz-focus-inner': {
    border: 0
  },
  '&:focus': {
    outline: 0
  },
  '&:enabled:hover, &:enabled:focus, &:enabled:active': _defineProperty({}, varLineColor, "var(--ds-border-selected, ".concat(B200, ")"))
});
var grabAreaReducedHeightStyles = css({
  height: "calc(100% - ".concat("var(--ds-space-600, 3rem)", " * 2)"),
  position: 'absolute',
  insetBlockEnd: 0
});
var grabAreaCollapsedStyles = css({
  padding: 0,
  backgroundColor: 'transparent',
  border: 0,
  cursor: 'default'
});
var lineStyles = css({
  display: 'block',
  width: "var(--ds-border-width-outline, 2px)",
  height: '100%',
  backgroundColor: 'var(--ds-line)',
  transition: 'background-color 200ms'
});
var grabAreaLineSelector = _defineProperty({}, GRAB_AREA_LINE_SELECTOR, true);
var grabAreaSelector = _defineProperty({}, GRAB_AREA_SELECTOR, true);
var GrabArea = function GrabArea(_ref) {
  var testId = _ref.testId,
    _ref$valueTextLabel = _ref.valueTextLabel,
    valueTextLabel = _ref$valueTextLabel === void 0 ? 'Width' : _ref$valueTextLabel,
    isDisabled = _ref.isDisabled,
    isLeftSidebarCollapsed = _ref.isLeftSidebarCollapsed,
    label = _ref.label,
    leftSidebarPercentageExpanded = _ref.leftSidebarPercentageExpanded,
    onKeyDown = _ref.onKeyDown,
    onMouseDown = _ref.onMouseDown,
    onBlur = _ref.onBlur,
    onFocus = _ref.onFocus,
    ref = _ref.ref,
    rest = _objectWithoutProperties(_ref, _excluded);
  return jsx("button", _extends({}, grabAreaSelector, {
    "aria-label": label,
    "data-testid": testId,
    disabled: isDisabled,
    "aria-hidden": isLeftSidebarCollapsed,
    type: "button"
    // The slider role is applied to a button to utilize the native
    // interactive and disabled functionality on the resize slider. While a
    // range input would be more semantically accurate, it does not affect
    // usability.
    ,
    role: "slider",
    css: [grabAreaStyles, isLeftSidebarCollapsed && grabAreaCollapsedStyles,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
    getBooleanFF('platform.design-system-team.page-layout-resize-button-fix_u0qxv') && isLeftSidebarCollapsed && grabAreaReducedHeightStyles],
    "aria-orientation": "vertical",
    "aria-valuenow": leftSidebarPercentageExpanded,
    "aria-valuemin": 0,
    "aria-valuemax": 100,
    "aria-valuetext": "".concat(valueTextLabel, " ").concat(leftSidebarPercentageExpanded, "%"),
    onKeyDown: onKeyDown,
    onMouseDown: onMouseDown,
    onFocus: onFocus,
    onBlur: onBlur
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
  }, rest), jsx("span", _extends({
    css: lineStyles
  }, grabAreaLineSelector)));
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default GrabArea;