import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _actionMap;
import { KEY_DOWN, KEY_END, KEY_HOME, KEY_UP } from '@atlaskit/ds-lib/keycodes';
var actionMap = (_actionMap = {}, _defineProperty(_actionMap, KEY_DOWN, 'next'), _defineProperty(_actionMap, KEY_UP, 'prev'), _defineProperty(_actionMap, KEY_HOME, 'first'), _defineProperty(_actionMap, KEY_END, 'last'), _actionMap);

/**
 * currentFocusedIdx + 1 will not work if the next focusable element
 * is disabled. So, we need to iterate through the following menu items
 * to find one that isn't disabled. If all following elements are disabled,
 * return undefined.
 */
var getNextFocusableElement = function getNextFocusableElement(refs, currentFocusedIdx) {
  while (currentFocusedIdx + 1 < refs.length) {
    var isDisabled = refs[currentFocusedIdx + 1].getAttribute('disabled') !== null;
    if (!isDisabled) {
      return refs[currentFocusedIdx + 1];
    }
    currentFocusedIdx++;
  }
};

/**
 * currentFocusedIdx - 1 will not work if the prev focusable element
 * is disabled. So, we need to iterate through the previous menu items
 * to find one that isn't disabled. If all previous elements are disabled,
 * return undefined.
 */
var getPrevFocusableElement = function getPrevFocusableElement(refs, currentFocusedIdx) {
  while (currentFocusedIdx > 0) {
    var isDisabled = refs[currentFocusedIdx - 1].getAttribute('disabled') !== null;
    if (!isDisabled) {
      return refs[currentFocusedIdx - 1];
    }
    currentFocusedIdx--;
  }
};
export default function handleFocus(refs) {
  return function (e) {
    var currentFocusedIdx = refs.findIndex(function (el) {
      var _document$activeEleme;
      return (_document$activeEleme = document.activeElement) === null || _document$activeEleme === void 0 ? void 0 : _document$activeEleme.isSameNode(el);
    });
    var action = actionMap[e.key];
    switch (action) {
      case 'next':
        if (currentFocusedIdx < refs.length - 1) {
          e.preventDefault();
          var _nextFocusableElement = getNextFocusableElement(refs, currentFocusedIdx);
          _nextFocusableElement && _nextFocusableElement.focus();
        }
        break;
      case 'prev':
        if (currentFocusedIdx > 0) {
          e.preventDefault();
          var _prevFocusableElement = getPrevFocusableElement(refs, currentFocusedIdx);
          _prevFocusableElement && _prevFocusableElement.focus();
        }
        break;
      case 'first':
        e.preventDefault();
        // Search for first non-disabled element if first element is disabled
        var nextFocusableElement = getNextFocusableElement(refs, -1);
        nextFocusableElement && nextFocusableElement.focus();
        break;
      case 'last':
        e.preventDefault();
        // Search for last non-disabled element if last element is disabled
        var prevFocusableElement = getPrevFocusableElement(refs, refs.length);
        prevFocusableElement && prevFocusableElement.focus();
        break;
      default:
        return;
    }
  };
}