import * as Sentry from '@sentry/browser';

import { Privilege, PrivilegeOptions } from 'src/components/types';

import * as messages from './privileges-dropdown.i18n';

export type PrivilegeDropdownContext = 'repository' | 'project' | 'workspace';

export type AllPrivileges =
  | keyof typeof PrivilegeOptions
  | PrivilegeOptions
  | Privilege
  | null;

export type PrivilegeDropdownOptions = Array<
  PrivilegeOptions | keyof typeof PrivilegeOptions
>;

export type PrivilegeDropdownOptionsValue = {
  default: PrivilegeDropdownOptions;
  filter: PrivilegeDropdownOptions;
};

const defaultOptions: PrivilegeDropdownOptions = [
  PrivilegeOptions.read,
  PrivilegeOptions.write,
  PrivilegeOptions.admin,
];

const defaultOptionsWithCreateRepo: PrivilegeDropdownOptions = [
  PrivilegeOptions.read,
  PrivilegeOptions.write,
  PrivilegeOptions['create-repo'],
  PrivilegeOptions.admin,
];

const defaultWorkspaceOptions: PrivilegeDropdownOptions = [
  PrivilegeOptions['create-project'],
];

const filterWorkspaceOptions: PrivilegeDropdownOptions = [
  PrivilegeOptions['create-project'],
  PrivilegeOptions.admin,
];

export const privilegeOptions: Record<
  PrivilegeDropdownContext,
  PrivilegeDropdownOptionsValue
> = {
  repository: {
    default: defaultOptions,
    filter: defaultOptionsWithCreateRepo,
  },
  project: {
    default: defaultOptionsWithCreateRepo,
    filter: defaultOptionsWithCreateRepo,
  },
  workspace: {
    default: defaultWorkspaceOptions,
    filter: filterWorkspaceOptions,
  },
};

export const getDescription = (
  permission: PrivilegeOptions | keyof typeof PrivilegeOptions,
  context: PrivilegeDropdownContext
) => {
  if (
    context === 'workspace' &&
    (permission === PrivilegeOptions.admin ||
      permission === PrivilegeOptions['create-project'])
  ) {
    return messages.workspace[permission];
  }
  if (
    context === 'project' &&
    permission !== PrivilegeOptions['create-project']
  ) {
    return messages.project[permission];
  }
  if (
    context === 'repository' &&
    permission !== PrivilegeOptions['create-project'] &&
    permission !== PrivilegeOptions['create-repo']
  ) {
    return messages.repository[permission];
  }
  // should never reach here
  const err = new Error(
    '<PrivilegeDropdown/>: incorrect privilege/context passed to getDescription()'
  );
  Sentry.captureMessage(err.message);
  throw err;
};

export const getPermissionName = (
  permission: PrivilegeOptions | keyof typeof PrivilegeOptions
) => {
  return messages.default[permission];
};
