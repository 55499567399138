import React, { ReactNode, useMemo } from 'react';

import Button from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';

export interface Props {
  configurationPageURL: string;
  branch: string;
  isBranchSet: boolean;
  tooltipActive: boolean;
  children?: ReactNode;
}

/* eslint @typescript-eslint/ban-types: "warn" */
const SettingsButton: React.FC<Props> = ({
  configurationPageURL,
  branch,
  isBranchSet,
  tooltipActive,
  children,
}) => {
  const hasMainBranch = useMemo(() => {
    return isBranchSet && branch !== null;
  }, [isBranchSet, branch]);

  const tooltipContent = useMemo(() => {
    return tooltipActive && !hasMainBranch
      ? 'Commit some files in the repository before enabling Pipelines.'
      : '';
  }, [tooltipActive, hasMainBranch]);

  return (
    <Tooltip position="right" content={tooltipContent}>
      <Button
        appearance="link"
        href={configurationPageURL}
        target="_parent"
        isDisabled={!hasMainBranch}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

export default React.memo(SettingsButton);
