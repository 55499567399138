import { defineMessages } from 'react-intl';

export default defineMessages({
  remove: {
    id: 'frontbucket.components.settings.modals.remove-modal.remove',
    description: 'Remove button label',
    defaultMessage: 'Remove',
  },
  delete: {
    id: 'frontbucket.components.settings.modals.remove-modal.delete',
    description: 'Delete button label',
    defaultMessage: 'Delete',
  },
  confirm: {
    id: 'frontbucket.components.settings.modals.remove-modal.confirm',
    description: 'Confirm button label',
    defaultMessage: 'Confirm',
  },

  cancel: {
    id: 'frontbucket.components.settings.modals.remove-modal.cancel',
    description: 'Cancel button label',
    defaultMessage: 'Cancel',
  },
  close: {
    id: 'frontbucket.components.settings.modals.remove-modal.close',
    description: 'Close button label',
    defaultMessage: 'Close',
  },
});
