import createReducer from 'src/utils/create-reducer';

import { FetchRepositorySubscriptions } from '../actions';
import { WatchPrefs } from '../types';

export type RepositorySubscriptionsState = {
  hasError: boolean;
  isDialogOpen: boolean;
  isLoading: boolean;
  settings: WatchPrefs | null | undefined;
};

const initialState: RepositorySubscriptionsState = {
  hasError: false,
  isDialogOpen: false,
  isLoading: false,
  settings: null,
};

export default createReducer(initialState, {
  [FetchRepositorySubscriptions.REQUEST](state) {
    return {
      ...state,
      isLoading: true,
    };
  },
  [FetchRepositorySubscriptions.SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      settings: action.payload,
    };
  },
  [FetchRepositorySubscriptions.ERROR](state) {
    return {
      ...state,
      hasError: true,
      isLoading: false,
    };
  },
});
