import urls from 'src/redux/pull-request/urls';
import authRequest, { jsonHeaders } from 'src/utils/fetch';
import store from 'src/utils/store';

const LAST_REVIEWED_COMMIT_KEY = 'last-reviewed-commit';

export const updateLastReviewedCommit = async (
  commitHash: string | null,
  owner: string,
  slug: string,
  id: string | number | undefined
): Promise<void> => {
  if (!commitHash || !id) {
    return;
  }

  // store in local storage
  const key = `${LAST_REVIEWED_COMMIT_KEY}:${owner}/${slug}/${id}`;
  const sevenDayExpiry = new Date().getTime() + 7 * 24 * 60 * 60 * 1000;
  store.set(key, commitHash, sevenDayExpiry);

  // store in PR service
  const url = urls.api.internal.checkpoint(owner, slug, id);
  const response = await fetch(
    authRequest(url, {
      method: 'POST',
      headers: jsonHeaders,
      body: JSON.stringify({ commit: commitHash }),
    })
  );
  if (!response.ok) {
    // fail silently for now
  }
};

export const getLastReviewedCommit = async (
  owner: string,
  slug: string,
  id: string | number | undefined
): Promise<string> => {
  if (!id) {
    return '';
  }

  // fetch from local storage
  const key = `${LAST_REVIEWED_COMMIT_KEY}:${owner}/${slug}/${id}`;
  const localStorageValue = store.get(key);

  // fetch from PR service
  const url = urls.api.internal.checkpoint(owner, slug, id);
  const response = await fetch(authRequest(url));
  const json = await response.json();

  // if request is successful and last_reviewed_commit is present, return from PR service
  // else fallback to local storage
  // if FF was just turned on, commit could be null in PR service but present in local storage
  if (response.ok && json.last_reviewed_commit) {
    return json.last_reviewed_commit;
  }

  return localStorageValue;
};

export const getCommitMessage = (commit: BB.Commit): string => {
  const newlineOrTabRegex = /[\n\r\t]+/g;
  const renderedMessage =
    (commit.rendered && commit.rendered.message) || commit.summary;
  return (
    renderedMessage?.raw.replace(newlineOrTabRegex, ' ') ||
    commit.message.replace(newlineOrTabRegex, ' ')
  );
};
