/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import * as Sentry from '@sentry/browser';
import { call, put, select } from 'redux-saga/effects';

import { PullRequestParticipant } from 'src/components/types';
import { showFlagComponent } from 'src/redux/flags';
import urls from 'src/redux/pull-request/urls';
import { USER_NEEDS_ACCESS_FLAG_ID } from 'src/sections/global/components/flags/user-needs-access';
import { getCurrentRepository } from 'src/selectors/repository-selectors';
import authRequest from 'src/utils/fetch';

import { verifyRepoMember } from '../actions';
import { getCurrentPullRequestParticipants } from '../selectors';

import { getErrorMessage } from './utils/get-error-message';

export function* verifyRepoMemberSaga({
  payload: { userAaid, displayName },
}: ReturnType<typeof verifyRepoMember>) {
  const {
    owner,
    slug,
    is_private: isPrivate,
  } = yield select(getCurrentRepository);
  if (!isPrivate) {
    // don't verify member unless repo is private
    return;
  }
  const repoPermissionsUrl = urls.api.v20.repositoryPermissions(
    owner.username,
    slug,
    userAaid
  );
  const verifyMember = authRequest(repoPermissionsUrl);
  try {
    const verifyMemberResp: Response = yield call(fetch, verifyMember);
    if (!verifyMemberResp.ok) {
      if (verifyMemberResp.status === 403) {
        // We don't need to capture 403 errors. This is expected behavior.
        // This will happen when the requesting user isn't an admin of the repository.
        return;
      }
      const message: string = yield call(
        getErrorMessage,
        verifyMemberResp.clone()
      );
      throw new Error(message);
    }

    const { values = [] } = yield verifyMemberResp.json();
    if (values.length === 0) {
      const participants: PullRequestParticipant[] = yield select(
        getCurrentPullRequestParticipants
      );
      const participant = participants.find(
        ({ user }) => user?.account_id === userAaid
      );
      yield put(
        showFlagComponent(`${USER_NEEDS_ACCESS_FLAG_ID}_${userAaid}`, {
          componentId: USER_NEEDS_ACCESS_FLAG_ID,
          props: {
            displayName: participant?.user?.display_name || displayName,
          },
        })
      );
    }
  } catch (e) {
    // silently fail and capture exception
    // this is a warning to the user that PR reviewer/mention
    // doesn't have access to the repository
    Sentry.captureException(e);
  }
}
