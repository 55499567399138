import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { expandWithoutNestedExpand as expandWithoutNestedExpandFactory, expand as expandFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name expand_node
 */

/**
 * @name expand_with_no_mark_node
 */

/**
 * @name expand_with_breakout_mark_node
 */

function getExpandAttrs(domNode) {
  var dom = domNode;
  return {
    title: dom.getAttribute('data-title'),
    __expanded: true
  };
}
var nodeSpecOptions = {
  parseDOM: [{
    context: 'table//',
    tag: 'div[data-node-type="expand"]',
    getAttrs: getExpandAttrs
  }, {
    context: 'expand//',
    tag: '[data-node-type="expand"]',
    getAttrs: getExpandAttrs
  }, {
    context: 'nestedExpand//',
    tag: '[data-node-type="expand"]',
    getAttrs: getExpandAttrs
  }, {
    tag: '[data-node-type="nestedExpand"] button',
    ignore: true
  }, {
    tag: '[data-node-type="expand"] button',
    ignore: true
  }, {
    tag: 'div[data-node-type="expand"]',
    getAttrs: getExpandAttrs
  }],
  toDOM: function toDOM(node) {
    var attrs = {
      'data-node-type': 'expand',
      'data-title': node.attrs.title,
      'data-expanded': node.attrs.__expanded
    };
    return ['div', attrs, 0];
  }
};
export var expand = expandWithoutNestedExpandFactory(nodeSpecOptions);
export var expandWithNestedExpand = expandFactory(nodeSpecOptions);
export var toJSON = function toJSON(node) {
  return {
    attrs: Object.keys(node.attrs).filter(function (key) {
      return !key.startsWith('__');
    }).reduce(function (obj, key) {
      return _objectSpread(_objectSpread({}, obj), {}, _defineProperty({}, key, node.attrs[key]));
    }, {})
  };
};