import { Schedule } from 'src/components/pipelines/models';
import { LoadingStatus } from 'src/constants/loading-status';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import {
  REQUEST_CREATE_SCHEDULE,
  REQUEST_DELETE_SCHEDULE,
  REQUEST_SCHEDULES,
  REQUEST_TOGGLE_SCHEDULE,
} from '../actions/pipelines';

export type SchedulesState = {
  list: Schedule[];
  fetchedStatus: LoadingStatus;
  createdStatus: LoadingStatus;
};

export const initialState: SchedulesState = {
  list: [],
  fetchedStatus: LoadingStatus.Before,
  createdStatus: LoadingStatus.Before,
};

export default createReducer(initialState, {
  [REQUEST_SCHEDULES.REQUEST](state: SchedulesState) {
    return {
      ...state,
      fetchedStatus: LoadingStatus.Fetching,
    };
  },
  [REQUEST_SCHEDULES.SUCCESS](state: SchedulesState, action: Action<any[]>) {
    if (!action.payload) {
      return state;
    }
    return {
      ...state,
      list: action.payload.map(schedule => new Schedule(schedule)),
      fetchedStatus: LoadingStatus.Success,
    };
  },
  [REQUEST_CREATE_SCHEDULE.REQUEST](
    state: SchedulesState,
    action: Action & { meta: { schedule: Schedule } }
  ) {
    if (!action.meta?.schedule) {
      return state;
    }
    return {
      ...state,
      createdStatus: LoadingStatus.Fetching,
    };
  },

  [REQUEST_CREATE_SCHEDULE.ERROR](
    state: SchedulesState,
    action: Action & { meta: { schedule: Schedule } }
  ) {
    if (!action.meta?.schedule) {
      return state;
    }
    return {
      ...state,
      createdStatus: LoadingStatus.Failed,
    };
  },
  [REQUEST_TOGGLE_SCHEDULE.REQUEST](
    state: SchedulesState,
    action: Action & { meta: { schedule: Schedule } }
  ) {
    if (!action.meta?.schedule) {
      return state;
    }
    return {
      ...state,
      list: state.list.map(s =>
        s.uuid === action.meta.schedule.uuid
          ? new Schedule({ ...s.toJS(), enabled: !s.enabled })
          : s
      ),
    };
  },
  [REQUEST_TOGGLE_SCHEDULE.ERROR](
    state: SchedulesState,
    action: Action & { meta: { schedule: Schedule } }
  ) {
    if (!action.meta?.schedule) {
      return state;
    }
    return {
      ...state,
      list: state.list.map(s =>
        s.uuid === action.meta.schedule.uuid
          ? new Schedule({ ...s.toJS(), enabled: !s.enabled })
          : s
      ),
    };
  },
  [REQUEST_CREATE_SCHEDULE.SUCCESS](
    state: SchedulesState,
    action: Action<any>
  ) {
    if (!action?.payload) {
      return state;
    }
    return {
      ...state,
      list: [new Schedule(action.payload)].concat(state.list),
      createdStatus: LoadingStatus.Success,
    };
  },
  [REQUEST_DELETE_SCHEDULE.REQUEST](
    state: SchedulesState,
    action: Action & { meta: { schedule: Schedule } }
  ) {
    if (!action.meta?.schedule) {
      return state;
    }
    return {
      ...state,
      list: state.list.filter(c => c?.uuid !== action.meta.schedule.uuid),
    };
  },
  [REQUEST_DELETE_SCHEDULE.ERROR](
    state: SchedulesState,
    action: Action & { meta: { schedule: Schedule } }
  ) {
    if (!action.meta?.schedule) {
      return state;
    }
    return {
      ...state,
      list: state.list.concat(action.meta.schedule),
    };
  },
});
