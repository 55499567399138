import React from 'react';

import Flag from '@atlaskit/flag';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import { HandleDismissedType } from 'src/redux/flags/types';
import messages from 'src/sections/repository/sections/pull-request/pull-request.i18n';

import { AppearanceTypes } from './simple/simple-flag';

export const SYNTAX_HIGHLIGHTING_FLAG_ID = 'syntax-highlighting' as const;

type Props = {
  id: typeof SYNTAX_HIGHLIGHTING_FLAG_ID;
} & HandleDismissedType;

export const SyntaxHighlightingFlag = React.memo((props: Props) => {
  const intl = useIntl();
  return (
    <Flag
      {...props}
      title={intl.formatMessage(messages.syntaxHighlightingPopupTitle)}
      appearance={AppearanceTypes.normal}
      description={intl.formatMessage(messages.syntaxHighlightingPopupBody)}
      icon={
        <InfoIcon
          label=""
          primaryColor={token('color.icon.discovery', colors.P300)}
        />
      }
      actions={[
        {
          content: intl.formatMessage(
            messages.syntaxHighlightingGoToLabsAction
          ),
          href: '/account/settings/features/#feature_syntax-highlighting',
          target: '_blank',
        },
      ]}
    />
  );
});
