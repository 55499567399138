/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import * as Sentry from '@sentry/browser';
import { omit } from 'lodash-es';
import { all, call, debounce, put, select, take } from 'redux-saga/effects';

import { Team, User } from 'src/components/types';
import { LoadingStatus } from 'src/constants/loading-status';
import { DEBOUNCE_DELAY } from 'src/constants/settings';
import { showFlag } from 'src/redux/flags';
import { LoadRepositoryPage } from 'src/sections/repository/actions';
import { SCREEN_NAME } from 'src/sections/repository/sections/jira/components/jira-tab/constants';
import manageProjectsMessages from 'src/sections/repository/sections/jira/components/jira-tab/manage-projects/manage-projects-dialog.i18n';
import jiraTabMessages from 'src/sections/repository/sections/jira/components/jira-tab/site-issues-list/site-issues-list.i18n';
import { getRepositoryPageLoadingStatus } from 'src/selectors/global-selectors';
import {
  getCurrentRepositoryFullSlug,
  getCurrentRepositoryOwnerName,
  getCurrentRepositoryUuid,
} from 'src/selectors/repository-selectors';
import {
  getCurrentUser,
  getCurrentUserIsAnonymous,
  getTargetUser,
} from 'src/selectors/user-selectors';
import { Action } from 'src/types/state';
import urls from 'src/urls/jira';
import { publishTrackEvent } from 'src/utils/analytics/publish';
import authRequest, { jsonHeaders } from 'src/utils/fetch';
import prefs from 'src/utils/preferences';
import { captureMessageForResponse } from 'src/utils/sentry';

import {
  closeUnlinkProjectDialog,
  FETCH_DEV_ACTIVITY,
  FETCH_JIRA_ASSIGNEES,
  FETCH_JIRA_PROJECTS,
  FETCH_JIRA_RELEVANT_ISSUES,
  FETCH_JIRA_RELEVANT_PROJECTS,
  FETCH_JIRA_SITES,
  FETCH_JIRA_TAB_FILTERS_USER_PREFERENCE,
  FETCH_RELEVANT_JIRA_SITES,
  FETCH_WORKSPACE_PERMISSION,
  fetchDevActivity,
  fetchJiraAssignees,
  fetchJiraRelevantIssues,
  fetchJiraRelevantProjects,
  fetchJiraSites,
  fetchRelevantJiraSites,
  fetchWorkspacePermission,
  JIRA_TAB_FILTERS_USER_PREFERENCE_NOT_REQUIRED,
  LINK_JIRA_PROJECT,
  saveFilterState,
  setEmptyStateKind,
  UNLINK_JIRA_PROJECT,
  updateRelevantJiraProjects,
  SET_ISSUE_ASSIGNEE,
} from '../actions';
import { EmptyStateKind } from '../constants';
import {
  getAssignees,
  getAssigneesFetchedStatus,
  getFilterPreferencesFetchedStatus,
  getFilterState,
  getJiraSites,
  getProjectAssociations,
  getRelevantProjectFetchedStatus,
  getRelevantSites,
  getRelevantSitesFetchedStatus,
} from '../selectors/repo-page-selectors';
import {
  Assignee,
  ChangeFilterStateAction,
  FilterState,
  JiraIssue,
  ProjectAssociation,
  Site,
  SORT_ORDER,
} from '../types';
import { createJiraTabFiltersUserPreferencesKey } from '../utils';

import { getWorkspace } from './get-repository-workspace';

export function* initJiraRepoPageSaga({
  payload: repositoryFullSlug,
}: Action<string>) {
  // Fetch relevant projects from all sites - this feeds both relevant project navigation
  // and project filter of the issues table
  yield put(fetchJiraRelevantProjects());
  // TODO BBCFAM-480 fetch the user preference here in parallel with relevant sites
  // Fetch relevant sites to figure out where to fetch issues from or empty state to show
  yield put(fetchRelevantJiraSites(repositoryFullSlug!));
}

export function* updateJiraTabFiltersUserPreferencesSaga() {
  const currentUser: User = yield select(getCurrentUser);
  if (!currentUser) {
    return;
  }

  const repositoryUuid: string = yield select(getCurrentRepositoryUuid);
  const filterState: FilterState = yield select(getFilterState);
  try {
    yield call(
      prefs.set,
      currentUser.uuid,
      createJiraTabFiltersUserPreferencesKey(repositoryUuid),
      JSON.stringify(
        omit<FilterState, keyof FilterState>(filterState, [
          'textFilterQuery',
          'currentPage',
          'sort',
        ])
      )
    );
  } catch (e) {
    Sentry.captureException(e);
  }
}

export function* fetchJiraTabFiltersUserPreferencesSaga() {
  const { status } = yield select(getRepositoryPageLoadingStatus);
  if (status !== LoadingStatus.Success) {
    yield take(LoadRepositoryPage.SUCCESS);
  }
  const currentUser: User = yield select(getCurrentUser);
  const repositoryUuid: string = yield select(getCurrentRepositoryUuid);

  try {
    if (currentUser) {
      // @ts-ignore
      const preferencesRaw = yield call(
        prefs.get,
        currentUser.uuid,
        createJiraTabFiltersUserPreferencesKey(repositoryUuid)
      );
      const preferences = preferencesRaw
        ? JSON.parse(preferencesRaw)
        : undefined;

      if (preferences) {
        yield put({
          type: FETCH_JIRA_TAB_FILTERS_USER_PREFERENCE.SUCCESS,
        });
        yield put(saveFilterState(preferences));
      } else {
        yield put({
          type: FETCH_JIRA_TAB_FILTERS_USER_PREFERENCE.ERROR,
        });
      }
    } else {
      // Put ERROR action for an anonymous user.
      yield put({
        type: FETCH_JIRA_TAB_FILTERS_USER_PREFERENCE.ERROR,
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_JIRA_TAB_FILTERS_USER_PREFERENCE.ERROR,
    });
    Sentry.captureException(e);
  }
}

function* fetchJiraAssigneesRecursively(
  repositoryFullSlug: string,
  siteCloudId: string,
  currentPage: number,
  values: any[] = []
): Generator<any, void, any> {
  const url = `${urls.api.internal.assignees(
    repositoryFullSlug,
    siteCloudId
  )}&page=${currentPage}`;
  const request = authRequest(url);
  const response: Response = yield call(fetch, request);
  if (response.ok) {
    const data = yield response.json();
    if (Array.isArray(data.values)) {
      values.push(...data.values);
    }
    const hasFetchedAllAssignees =
      values.length === data.size || (data.values || []).length === 0;

    if (!hasFetchedAllAssignees) {
      yield fetchJiraAssigneesRecursively(
        repositoryFullSlug,
        siteCloudId,
        currentPage + 1,
        values
      );
    } else {
      yield put({
        type: FETCH_JIRA_ASSIGNEES.SUCCESS,
        payload: {
          values,
        },
      });
    }
  } else {
    yield captureMessageForResponse(
      response,
      `Fetching assignees for the Jira site ${siteCloudId}`
    );
    yield put({
      type: FETCH_JIRA_ASSIGNEES.ERROR,
    });
  }
}

export function* fetchJiraAssigneesSaga() {
  const repositoryFullSlug: string = yield select(getCurrentRepositoryFullSlug);
  const { siteCloudId } = yield select(getFilterState);

  try {
    yield fetchJiraAssigneesRecursively(repositoryFullSlug, siteCloudId, 1);
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_JIRA_ASSIGNEES.ERROR,
    });
  }
}

export function* updateEmptyStateForSelectedSite() {
  // To decide which empty state reflects the situation we might need
  // to check the outcome of concurrently executed API calls to fetch
  // relevant sites and relevant projects. Due to visibility of these
  // to the requesting user, we can't make such decision earlier.
  const { siteCloudId } = yield select(getFilterState);
  // If no site selected yet, we are not ready to show the page
  if (!siteCloudId) {
    return;
  }
  const relevantProjectFetchedStatus: LoadingStatus = yield select(
    getRelevantProjectFetchedStatus
  );
  if (relevantProjectFetchedStatus === LoadingStatus.Forbidden) {
    // No relevant projects are visible to the user, but we can only get
    // into this branch if at least one of the relevant sites is visible
    // and became selected
    yield put(
      setEmptyStateKind(EmptyStateKind.HasNoAccessibleRelevantProjects)
    );
  } else if (relevantProjectFetchedStatus === LoadingStatus.Success) {
    const projectAssociations: ProjectAssociation[] = yield select(
      getProjectAssociations
    );
    // No relevant projects means no relevant sites, for that case we have a special
    // flow to fetch available sites and decide which empty state to show, so here we
    // only need to care about the case when there're some relevant projects
    if (projectAssociations.length > 0) {
      // We might have a site selected which has no relevant projects visible to the user,
      // while there are relevant projects on other sites that user can access; we need to
      // detect such case to show appropriate empty state and hide an empty project filter
      const someVisibleProjectsOnSelectedSite = projectAssociations.some(
        (pa: ProjectAssociation) => pa.project.site.cloudId === siteCloudId
      );
      yield put(
        setEmptyStateKind(
          someVisibleProjectsOnSelectedSite
            ? EmptyStateKind.None
            : EmptyStateKind.HasNoAccessibleRelevantProjects
        )
      );
    }
  } else if (relevantProjectFetchedStatus === LoadingStatus.Failed) {
    // We need to let the user see the page so that they are able to retry fetching
    // relevant projects if they want. Once they do that, empty state might change
    // but that's ok since that would be a reaction on a button click.
    yield put(setEmptyStateKind(EmptyStateKind.None));
  }
}

export function* handleSelectedSiteChange() {
  yield all([
    put(fetchJiraAssignees()),
    put(fetchJiraRelevantIssues()),
    call(updateEmptyStateForSelectedSite),
  ]);
}

export function* fetchSitesSaga() {
  const targetAccount: User = yield select(getTargetUser);

  try {
    const url = urls.api.internal.sites(targetAccount.uuid);
    const request = authRequest(url);
    const response: Response = yield call(fetch, request);

    if (response.ok) {
      // @ts-ignore
      const data = yield response.json();
      yield put({
        type: FETCH_JIRA_SITES.SUCCESS,
        payload: data.values,
      });
    } else {
      yield captureMessageForResponse(
        response,
        'Fetching Jira sites in Jira tab failed'
      );
      yield put({
        type: FETCH_JIRA_SITES.ERROR,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_JIRA_SITES.ERROR,
    });
  }
}

export function* publishIssuesFetchedTrackEvent(size: number, pageLen: number) {
  const relevantSites: Site[] = yield select(getRelevantSites);
  const relevantProjects: ProjectAssociation[] = yield select(
    getProjectAssociations
  );
  const relevantProjectsFetchedStatus: LoadingStatus = yield select(
    getRelevantProjectFetchedStatus
  );
  const assignees: Assignee[] = yield select(getAssignees);
  const assigneesFetchedStatus: LoadingStatus = yield select(
    getAssigneesFetchedStatus
  );
  const filterState: FilterState = yield select(getFilterState);

  yield call(publishTrackEvent, {
    source: SCREEN_NAME,
    action: 'fetched',
    actionSubject: 'jiraTabRelevantIssues',
    actionSubjectId: 'jiraTabRelevantIssues',
    attributes: {
      relevantSitesCount: relevantSites.length,
      relevantProjectsCount:
        relevantProjectsFetchedStatus === LoadingStatus.Success
          ? relevantProjects.length
          : undefined,
      relevantProjectsForSelectedSiteCount:
        relevantProjectsFetchedStatus === LoadingStatus.Success
          ? relevantProjects.filter(
              p => p.project.site.cloudId === filterState.siteCloudId
            ).length
          : undefined,
      assigneesCount:
        assigneesFetchedStatus === LoadingStatus.Success
          ? assignees.length
          : undefined,
      selectedProjectsCount: filterState.projectIds.length,
      selectedAssigneesCount: filterState.assignees.length,
      selectedStatusCategories: filterState.issueCategories,
      isSearchingByText: Boolean(filterState.textFilterQuery),
      sort: filterState.sort,
      currentPage: filterState.currentPage,
      totalPages: Math.ceil(size / pageLen),
    },
  });
}

export function* fetchJiraRelevantIssuesSaga() {
  const RELEVANT_ISSUES_PAGE_LEN = 25;
  const repositoryFullSlug: string = yield select(getCurrentRepositoryFullSlug);
  const filterState: FilterState = yield select(getFilterState);
  const cloudId = filterState.siteCloudId;
  const filterParams = {
    cloud_id: cloudId,
    project_ids: filterState.projectIds,
    statuses: filterState.issueCategories,
    text: filterState.textFilterQuery,
    assignees: filterState.assignees,
    sort: filterState.sort
      ? filterState.sort.order === SORT_ORDER.ASC
        ? filterState.sort.field
        : `-${filterState.sort.field}`
      : undefined,
    page: filterState.currentPage,
    pagelen: RELEVANT_ISSUES_PAGE_LEN,
  };

  try {
    const url = urls.api.internal.relevantIssues(
      repositoryFullSlug,
      filterParams
    );
    const request = authRequest(url);
    const response: Response = yield call(fetch, request);

    if (response.ok) {
      // @ts-ignore
      const data = yield response.json();
      yield put({
        type: FETCH_JIRA_RELEVANT_ISSUES.SUCCESS,
        payload: data,
      });
      const issues: [JiraIssue] = data.values;
      const issueIds = issues.map(issue => issue.id);
      yield put(
        fetchDevActivity({
          cloudId,
          issueIds,
        })
      );

      yield call(
        publishIssuesFetchedTrackEvent,
        data.size,
        RELEVANT_ISSUES_PAGE_LEN
      );
    } else {
      yield captureMessageForResponse(
        response,
        'Fetching relevant Jira issues in Jira tab failed'
      );
      yield put({
        type: FETCH_JIRA_RELEVANT_ISSUES.ERROR,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_JIRA_RELEVANT_ISSUES.ERROR,
    });
  }
}

export function* fetchRelevantJiraSitesSaga({
  payload: repositoryFullSlug,
}: Action<string>) {
  try {
    const url = urls.api.internal.relevantSites(repositoryFullSlug!);
    const request = authRequest(url);
    const response: Response = yield call(fetch, request);

    if (response.ok) {
      // @ts-ignore
      const data = yield response.json();
      yield put({
        type: FETCH_RELEVANT_JIRA_SITES.SUCCESS,
        payload: {
          values: data.values,
          hasPermission: true,
        },
      });
    } else if (response.status === 403) {
      yield put({
        type: FETCH_RELEVANT_JIRA_SITES.SUCCESS,
        payload: {
          values: [],
          hasPermission: false,
        },
      });
    } else {
      yield captureMessageForResponse(
        response,
        'Fetching relevant Jira sites in Jira tab failed'
      );
      yield put({
        type: FETCH_RELEVANT_JIRA_SITES.ERROR,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_RELEVANT_JIRA_SITES.ERROR,
    });
  }
}

function* fetchRelevantProjectsRecursively(
  repositoryFullSlug: string,
  currentPage: number,
  values: any[] = []
): Generator<any, void, any> {
  const url = `${urls.api.internal.relevantProjects(
    repositoryFullSlug
  )}?page=${currentPage}`;
  const request = authRequest(url);
  const response: Response = yield call(fetch, request);

  if (response.ok) {
    const data = yield response.json();
    if (Array.isArray(data.values)) {
      values.push(...data.values);
    }

    // Due to doing multiple separate requests, we could end up with a situation
    // where values.length and data.size never match, so also stop if we get no
    // results in the new page.
    const hasFetchedAllRelevantProjects =
      values.length === data.size || (data.values || []).length === 0;

    if (hasFetchedAllRelevantProjects) {
      // This code handles the edge case where a project was saved in the user preference
      // api but is no longer a relevant project for this repo. We discard the saved project.
      const filterState: FilterState = yield select(getFilterState);
      const projectIds = filterState.projectIds.filter(id =>
        values.some(
          (association: ProjectAssociation) => association.project.id === id
        )
      );

      yield put({
        type: FETCH_JIRA_RELEVANT_PROJECTS.SUCCESS,
        payload: {
          projectAssociations: values,
          projectIds,
          hasPermission: true,
        },
      });
    } else {
      yield fetchRelevantProjectsRecursively(
        repositoryFullSlug,
        currentPage + 1,
        values
      );
    }
  } else if (response.status === 403) {
    yield put({
      type: FETCH_JIRA_RELEVANT_PROJECTS.SUCCESS,
      payload: {
        projectAssociations: [],
        projectIds: [],
        hasPermission: false,
      },
    });
  } else {
    yield captureMessageForResponse(
      response,
      'Fetching relevant Jira projects failed'
    );
    yield put({
      type: FETCH_JIRA_RELEVANT_PROJECTS.ERROR,
    });
  }
}

export function* fetchJiraRelevantProjectsSaga() {
  const repositoryFullSlug: string = yield select(getCurrentRepositoryFullSlug);

  try {
    yield fetchRelevantProjectsRecursively(repositoryFullSlug, 1);
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_JIRA_RELEVANT_PROJECTS.ERROR,
    });
  }
}

export function* handleFetchRelevantIssuesSuccessSaga({
  payload,
}: Action<{ values: JiraIssue[] }>) {
  // If we got some issues back, we can show them straight away
  // without waiting for all relevant projects to finish loading
  if ((payload?.values || []).length > 0) {
    yield put(setEmptyStateKind(EmptyStateKind.None));
  }
}

export function* handleFetchRelevantProjectsSuccessSaga() {
  yield call(updateEmptyStateForSelectedSite);
}

// If there re no relevant sites, we need to show an empty state
// based on the available and connected sites.
export function* setNoRelevantSitesEmptyState() {
  yield put(fetchJiraSites());
  yield take(FETCH_JIRA_SITES.SUCCESS);

  const sites: Site[] = yield select(getJiraSites);
  const connectedSites = (sites || []).filter(s => s.connected);
  const availableSites = (sites || []).filter(s => !s.connected);
  if (connectedSites.length > 0) {
    // We're only fetching all Jira sites if there're no relevant sites,
    // and therefore no relevant projects.
    yield put(setEmptyStateKind(EmptyStateKind.HasNoRelevantProjects));
  } else if (connectedSites.length === 0 && availableSites.length > 0) {
    yield put(setEmptyStateKind(EmptyStateKind.HasAvailableSites));
    yield put(fetchWorkspacePermission());
  } else if (connectedSites.length === 0 && availableSites.length === 0) {
    yield put(setEmptyStateKind(EmptyStateKind.HasNoAvailableSites));
    yield put(fetchWorkspacePermission());
  }
}

export function* handleFetchRelevantSitesSuccessSaga() {
  // Make sure the repository has been loaded.
  const { status } = yield select(getRepositoryPageLoadingStatus);
  if (status !== LoadingStatus.Success) {
    yield take(LoadRepositoryPage.SUCCESS);
  }

  const relevantSites: Site[] = yield select(getRelevantSites);
  if ((relevantSites || []).length === 0) {
    const relevantSitesFetchStatus: LoadingStatus = yield select(
      getRelevantSitesFetchedStatus
    );
    if (relevantSitesFetchStatus === LoadingStatus.Forbidden) {
      yield put(setEmptyStateKind(EmptyStateKind.HasNoAccessibleRelevantSites));
    } else {
      yield call(setNoRelevantSitesEmptyState);
    }
  } else {
    const filterPreferencesFetchedStatus: LoadingStatus | 'NOT_REQUIRED' =
      yield select(getFilterPreferencesFetchedStatus);

    // Wait for preferences to be fetched.
    if (
      filterPreferencesFetchedStatus !== LoadingStatus.Success &&
      filterPreferencesFetchedStatus !== LoadingStatus.Failed &&
      filterPreferencesFetchedStatus !== 'NOT_REQUIRED'
    ) {
      yield take([
        FETCH_JIRA_TAB_FILTERS_USER_PREFERENCE.SUCCESS,
        FETCH_JIRA_TAB_FILTERS_USER_PREFERENCE.ERROR,
        JIRA_TAB_FILTERS_USER_PREFERENCE_NOT_REQUIRED,
      ]);
    }

    const filterState: FilterState = yield select(getFilterState);
    if (
      !filterState.siteCloudId ||
      !relevantSites.some(s => s.cloudId === filterState.siteCloudId)
    ) {
      // Select the first site if we don't have a site selected yet, or if the selected site is not relevant anymore.
      // The latter can happen if users open an old URL that has a `site` query param of an old site.
      yield put(saveFilterState({ siteCloudId: relevantSites[0].cloudId }));
    }
    yield call(handleSelectedSiteChange);
  }
}

export function* fetchWorkspacePermissionSaga() {
  const isAnonymousUser: boolean = yield select(getCurrentUserIsAnonymous);
  // Request to get workspace permissions needs auth, don't try for anonymous users.
  if (isAnonymousUser) {
    yield put({
      type: FETCH_WORKSPACE_PERMISSION.SUCCESS,
      payload: [],
    });
    return;
  }

  const repoOwnerName: string = yield select(getCurrentRepositoryOwnerName);
  const url = urls.api.v20.workspacePermission(repoOwnerName);

  try {
    const request = authRequest(url);
    const response: Response = yield call(fetch, request);

    if (response.ok) {
      // @ts-ignore
      const data = yield response.json();
      yield put({
        type: FETCH_WORKSPACE_PERMISSION.SUCCESS,
        payload: data.values,
      });
    } else {
      yield captureMessageForResponse(
        response,
        'Fetching workspace permission in Jira tab failed'
      );
      yield put({
        type: FETCH_WORKSPACE_PERMISSION.ERROR,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_WORKSPACE_PERMISSION.ERROR,
    });
  }
}

export function* changeFilterStateSaga({
  payload: nextFilterState,
}: ChangeFilterStateAction) {
  const prevFilterState: FilterState = yield select(getFilterState);

  const filterState = { ...nextFilterState };

  const filtersOrSortChanged = !nextFilterState.currentPage;
  if (filtersOrSortChanged) {
    // When changing any of the filters or sort order, we want to reset to page 1, not stay on the page that they were
    // before because the context is no longer the same. See https://ux.stackexchange.com/a/129076/68221
    filterState.currentPage = 1;
  }

  const selectedSiteChanged =
    nextFilterState.siteCloudId &&
    nextFilterState.siteCloudId !== prevFilterState.siteCloudId;
  if (selectedSiteChanged) {
    // When switching to a different site, we don't want to keep the project or assignee filters. For projects it
    // doesn't make sense because they are completely different. The same assignee user might exist on a different site,
    // but might not have any issues on that site, so it would just be an empty result and confusing UX.
    filterState.projectIds = [];
    filterState.assignees = [];
  }
  yield put(saveFilterState(filterState));

  // Don't update preferences when text filter, column sort or page is changed
  // because these values are not included in the preferences anyway.
  const persistFiltersChanged =
    !nextFilterState.currentPage &&
    !nextFilterState.sort &&
    !nextFilterState.textFilterQuery;
  if (persistFiltersChanged) {
    yield call(updateJiraTabFiltersUserPreferencesSaga);
  }

  if (selectedSiteChanged) {
    yield call(handleSelectedSiteChange);
  } else {
    yield put(fetchJiraRelevantIssues());
  }
}

export function* fetchDevActivitySaga({
  payload,
}: Action<{ issueIds: string[]; cloudId: string }>) {
  if (!payload) {
    return;
  }

  const { cloudId, issueIds } = payload;

  try {
    const workspace: User | Team = yield getWorkspace();
    const url = urls.api.internal.devActivity(workspace.uuid, cloudId);
    const request = authRequest(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ issueIds }),
    });
    const response: Response = yield call(fetch, request);

    if (response.ok) {
      // @ts-ignore
      const data = yield response.json();
      yield put({
        type: FETCH_DEV_ACTIVITY.SUCCESS,
        payload: data ? { [cloudId]: data.activity } : {},
      });
    } else {
      yield captureMessageForResponse(
        response,
        'Fetching dev activity in Jira tab failed'
      );
      yield put({
        type: FETCH_DEV_ACTIVITY.ERROR,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_DEV_ACTIVITY.ERROR,
    });
  }
}

export function* fetchJiraProjectsSaga({
  payload,
}: Action<{ cloudId: string; projectQuery: string }>) {
  if (!payload) {
    return;
  }

  const { cloudId, projectQuery } = payload;

  try {
    const workspace: User | Team = yield getWorkspace();
    const url = urls.api.internal.projects(
      workspace.uuid,
      cloudId,
      projectQuery
    );
    const request = authRequest(url);
    const response: Response = yield call(fetch, request);

    if (response.ok) {
      // @ts-ignore
      const data = yield response.json();
      yield put({
        type: FETCH_JIRA_PROJECTS.SUCCESS,
        payload: data
          ? {
              [cloudId]: {
                projects: data.values,
                fetchedStatus: LoadingStatus.Success,
                size: data.size,
              },
            }
          : {},
      });
    } else {
      yield captureMessageForResponse(
        response,
        'Fetching Jira projects failed'
      );
      yield put({
        type: FETCH_JIRA_PROJECTS.ERROR,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_JIRA_PROJECTS.ERROR,
      payload: { cloudId },
    });
  }
}

export function* debouncedFetchJiraProjectsSaga() {
  yield debounce(
    DEBOUNCE_DELAY,
    FETCH_JIRA_PROJECTS.REQUEST,
    fetchJiraProjectsSaga
  );
}

export function* updateProjectAssociation(
  projectAssociation: ProjectAssociation
) {
  const {
    id: projectId,
    site: { cloudId },
  } = projectAssociation.project;

  const repositoryFullSlug: string = yield select(getCurrentRepositoryFullSlug);

  const url = urls.api.internal.projectAssociation(
    repositoryFullSlug,
    cloudId,
    projectId
  );
  const request = authRequest(url, {
    method: 'PUT',
    headers: jsonHeaders,
    body: JSON.stringify(projectAssociation),
  });

  const response: Response = yield call(fetch, request);
  return response;
}

export function* handleLinkJiraProjectSuccess(
  projectAssociation: ProjectAssociation
) {
  const projectAssociations: ProjectAssociation[] = yield select(
    getProjectAssociations
  );
  const newProjectAssociations = [...projectAssociations, projectAssociation];
  // Update the list of relevant projects to include the new one,
  yield put(updateRelevantJiraProjects(newProjectAssociations));
  // fetch updated list of relevant sites (this will update relevant issues list too),
  const repositoryFullSlug: string = yield select(getCurrentRepositoryFullSlug);
  yield put(fetchRelevantJiraSites(repositoryFullSlug));
  // and show the success flag.
  yield put(
    showFlag({
      id: 'link-project-success-message',
      title: { msg: manageProjectsMessages.linkProjectSuccessMessage },
      autoDismiss: true,
      iconType: 'success',
    })
  );

  yield call(publishTrackEvent, {
    source: SCREEN_NAME,
    action: 'linked',
    actionSubject: 'jiraProject',
    actionSubjectId: projectAssociation.project.id,
    attributes: {
      relevantProjectsCount: newProjectAssociations.length,
    },
  });
}

export function* setIssueAssigneeSaga({
  payload,
}: Action<{ issueKey: string; assigneeId: string }>) {
  if (!payload) {
    return;
  }
  const { issueKey, assigneeId } = payload;
  const currentUser: User = yield select(getCurrentUser);

  const { siteCloudId } = yield select(getFilterState);
  const url = urls.api.internal.setAssignee(siteCloudId, issueKey);
  try {
    const request = authRequest(url, {
      method: 'PUT',
      headers: jsonHeaders,
      body: JSON.stringify({ assigneeId }),
    });
    const response: Response = yield call(fetch, request);

    if (response.ok) {
      yield put({
        type: SET_ISSUE_ASSIGNEE.SUCCESS,
        payload: {
          currentJiraUser: {
            displayName: currentUser.display_name,
            accountId: currentUser.account_id,
            avatarUrls: {
              '16x16': currentUser.links.avatar.href,
              '24x24': currentUser.links.avatar.href,
              '32x32': currentUser.links.avatar.href,
              '48x48': currentUser.links.avatar.href,
              '128x128': currentUser.links.avatar.href,
            },
          },
          issueKey,
        },
      });
      yield put(
        showFlag({
          id: 'assign-issue-success-flag',
          title: { msg: jiraTabMessages.assignIssueSuccessTitle },
          autoDismiss: true,
          iconType: 'success',
        })
      );
    } else {
      yield put({
        type: SET_ISSUE_ASSIGNEE.ERROR,
      });
      yield put(
        showFlag({
          id: 'assign-issue-failure-flag',
          title: { msg: jiraTabMessages.assignIssueFailureTitle },
          autoDismiss: true,
          iconType: 'error',
        })
      );
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: SET_ISSUE_ASSIGNEE.ERROR,
    });
    yield put(
      showFlag({
        id: 'assign-issue-failure-flag',
        title: { msg: jiraTabMessages.assignIssueFailureTitle },
        autoDismiss: true,
        iconType: 'error',
      })
    );
  }
}

export function* linkJiraProjectSaga({
  payload,
}: Action<{ projectAssociation: ProjectAssociation }>) {
  if (!payload) {
    return;
  }

  try {
    const response: Response = yield call(
      updateProjectAssociation,
      payload.projectAssociation
    );

    if (response.ok) {
      // @ts-ignore
      const data = yield response.json();
      yield put({
        type: LINK_JIRA_PROJECT.SUCCESS,
        payload: {
          projectAssociation: data,
        },
      });
      yield call(handleLinkJiraProjectSuccess, data);
    } else {
      yield captureMessageForResponse(response, 'Linking Jira project failed');
      yield put({
        type: LINK_JIRA_PROJECT.ERROR,
      });
      yield put(
        showFlag({
          id: 'link-project-failed-message',
          title: { msg: manageProjectsMessages.linkProjectFailedMessage },
          autoDismiss: true,
          iconType: 'error',
        })
      );
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: LINK_JIRA_PROJECT.ERROR,
    });
  }
}

export function* handleUnlinkJiraProjectSuccess(
  projectAssociation: ProjectAssociation
) {
  const projectAssociations: ProjectAssociation[] = yield select(
    getProjectAssociations
  );
  // Filter out the unlinked project association from the list,
  const newProjectAssociations = (projectAssociations || []).filter(
    pa =>
      !(
        pa.project.site.cloudId === projectAssociation.project.site.cloudId &&
        pa.project.id === projectAssociation.project.id
      )
  );
  yield put(updateRelevantJiraProjects(newProjectAssociations));
  // fetch updated list of relevant sites (this will update relevant issues list too),
  const repositoryFullSlug: string = yield select(getCurrentRepositoryFullSlug);
  yield put(fetchRelevantJiraSites(repositoryFullSlug));
  // close the unlink project confirmation dialog,
  yield put(closeUnlinkProjectDialog());
  // and show the success flag.
  yield put(
    showFlag({
      id: 'unlink-project-success-message',
      title: { msg: manageProjectsMessages.unlinkProjectSuccessMessage },
      autoDismiss: true,
      iconType: 'success',
    })
  );

  yield call(publishTrackEvent, {
    source: SCREEN_NAME,
    action: 'unlinked',
    actionSubject: 'jiraProject',
    actionSubjectId: projectAssociation.project.id,
    attributes: {
      relevantProjectsCount: newProjectAssociations.length,
    },
  });
}

export function* unlinkJiraProjectSaga({
  payload,
}: Action<{ projectAssociation: ProjectAssociation }>) {
  if (!payload) {
    return;
  }

  try {
    const response: Response = yield call(
      updateProjectAssociation,
      payload.projectAssociation
    );

    if (response.ok) {
      // @ts-ignore
      const data = yield response.json();
      yield put({
        type: UNLINK_JIRA_PROJECT.SUCCESS,
        payload: {
          projectAssociation: data,
        },
      });
      yield call(handleUnlinkJiraProjectSuccess, data);
    } else {
      yield captureMessageForResponse(
        response,
        'Unlinking Jira project failed'
      );
      yield put({
        type: UNLINK_JIRA_PROJECT.ERROR,
      });
      yield put(
        showFlag({
          id: 'unlink-project-failed-message',
          title: { msg: manageProjectsMessages.unlinkProjectFailedMessage },
          autoDismiss: true,
          iconType: 'error',
        })
      );
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: UNLINK_JIRA_PROJECT.ERROR,
    });
  }
}
