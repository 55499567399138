import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';

export default Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      /* webpackChunkName: "repository-admin-jira-migration" */ './components/page'
    ),
});
