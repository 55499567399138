export const urls = {
  results: (
    repositoryOwner: string,
    repositorySlug: string,
    pullRequestId: string
  ) =>
    `/!api/internal/repositories/${repositoryOwner}/${repositorySlug}/pullrequests/${pullRequestId}/checks/results`,
  retry: (
    repositoryOwner: string,
    repositorySlug: string,
    pullRequestId: string,
    resultUuid: string
  ) =>
    `/!api/internal/repositories/${repositoryOwner}/${repositorySlug}/pullrequests/${pullRequestId}/checks/results/${resultUuid}/retry`,
};
