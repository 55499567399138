import {BaseFrameworkAdaptor} from '../../adaptors/framework/BaseFrameworkAdaptor';
import {BaseModule} from '../base/Module';
import {ModuleDefinitions} from '../base/Module';
import {InlineDialogProvider} from './InlineDialogProvider';
import {InlineDialogSimpleXdmDefinitionBuilder} from './InlineDialogSimpleXdmDefinitionBuilder';
import {Provider} from '../base/Provider';
import {SimpleXdmDefinitionBuilder} from '../../definitions/SimpleXdmDefinitionBuilder';

export module InlineDialogModuleDefinitions {

    export const INLINE_DIALOG_MODULE_NAME = 'inlineDialog';

    export class Props implements ModuleDefinitions.Props {

        adaptor: BaseFrameworkAdaptor;
        inlineDialogProvider: InlineDialogProvider;

        constructor(
            adaptor: BaseFrameworkAdaptor,
            inlineDialogProvider: InlineDialogProvider) {
            this.adaptor = adaptor;
            this.inlineDialogProvider = inlineDialogProvider;
        }

    }

    export class State implements ModuleDefinitions.State {

        enabled: boolean;
        registered: boolean;
        inlineDialogProvider: InlineDialogProvider;
        simpleXdmDefinitionBuilder: SimpleXdmDefinitionBuilder;

        constructor(inlineDialogProvider: InlineDialogProvider) {
            this.enabled = true;
            this.registered = false;
            this.inlineDialogProvider = inlineDialogProvider;
            this.simpleXdmDefinitionBuilder = new InlineDialogSimpleXdmDefinitionBuilder(inlineDialogProvider);
        }

    }

}

/**
 * This module provides inlineDialog related functionality.
 */
export class InlineDialogModule extends BaseModule<InlineDialogModuleDefinitions.Props, InlineDialogModuleDefinitions.State> {

    constructor(props: InlineDialogModuleDefinitions.Props) {
        super(props);
    }

    componentWillMount(): void {
        if (!this.props.inlineDialogProvider) {
            throw new Error('this.props.inlineDialogProvider is not defined');
        }
        this.setState(new InlineDialogModuleDefinitions.State(this.props.inlineDialogProvider));
    }

    public getModuleRegistrationName(): string {
        return InlineDialogModuleDefinitions.INLINE_DIALOG_MODULE_NAME;
    }

    public getSimpleXdmDefinitionBuilder() : SimpleXdmDefinitionBuilder {
      return this.state.simpleXdmDefinitionBuilder;
    }

    getProvider(): Provider {
        return this.props.inlineDialogProvider;
    }

    render(): JSX.Element | null {
        return null;
    }
}
