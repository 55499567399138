import { FC, memo, useEffect, useRef, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { PageLoadMetric } from '@atlassian/browser-metrics';
import {
  PageSegmentLoadMetric,
  Metric,
  CustomValues,
  BaseMetricStopArguments,
} from '@atlassian/browser-metrics/types';

import { uncurlyUuid } from 'src/components/analytics';
import { getCurrentRepository } from 'src/selectors/repository-selectors';

export type Props = {
  metric: PageLoadMetric | PageSegmentLoadMetric | Metric;
};

export const useMetricSender = (
  metric: PageLoadMetric | PageSegmentLoadMetric | Metric
): (() => void) => {
  const repository = useSelector(getCurrentRepository);
  const metricRef = useRef<PageLoadMetric | PageSegmentLoadMetric | Metric>();
  const sendMetric = useCallback(() => {
    // We only want this hook to fire if metric has changed, not any other
    // dependencies, because it marks the end of the metric. However, we want to
    // be passively aware of when dependencies change, so we can include them as
    // custom attributes, if they are available.
    if (metricRef.current !== metric) {
      let metricReady = true;
      // Unless it's a full page load, we want to ensure state === 'started'
      if (metric.type !== 'PAGE_LOAD') {
        metricReady = metric.getData().state === 'started';
      }

      if (metricReady) {
        const customValues: CustomValues = {};

        if (repository?.uuid) {
          customValues.repositoryId = uncurlyUuid(repository.uuid);
        }

        const stopArgs: BaseMetricStopArguments = { customData: customValues };
        metric.stop(stopArgs);
      }
    }

    metricRef.current = metric;
  }, [metric, repository]);

  return sendMetric;
};

/* eslint @typescript-eslint/ban-types: "warn" */
export const MetricSender: FC<Props> = memo(({ metric }) => {
  const sendMetric = useMetricSender(metric);

  useEffect(() => {
    sendMetric();
  }, [sendMetric]);

  return null;
});

export function useAbortMetricOnUnmount(
  metric: PageLoadMetric | PageSegmentLoadMetric | Metric
): void {
  useEffect(() => {
    return () => {
      if (metric.getData().state === 'started') {
        metric.stop();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
