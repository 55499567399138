import React from 'react';

import Lozenge, { ThemeAppearance } from '@atlaskit/lozenge';

import { TestOutcomeEnum } from '..';

interface TestOutcomeProps {
  type?: TestOutcomeEnum;
  children: React.ReactNode;
}

/* eslint @typescript-eslint/ban-types: "warn" */
export const TestOutcome: React.FC<TestOutcomeProps> = ({ type, children }) => {
  let appearance: ThemeAppearance;

  switch (type) {
    case 'PASSED':
      appearance = 'success';
      break;
    case 'FAILED':
      appearance = 'removed';
      break;
    case 'ERROR':
      appearance = 'moved';
      break;
    default:
      appearance = 'default';
  }

  return <Lozenge appearance={appearance}>{children}</Lozenge>;
};
