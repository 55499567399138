/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { change } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';

import { uncurlyUuid } from '@atlassian/bitkit-analytics';

import { FORM_KEY } from 'src/constants/search';
import { SearchFormRefineMenuItemAddedFact } from 'src/sections/search/facts';
import { publishFact, publishTrackEvent } from 'src/utils/analytics/publish';

type RefineQueryAction = {
  type: string;
  payload: {
    item: { modifier?: string; operator?: string };
  };
};

export default function* refineQuerySaga({
  payload: { item },
}: RefineQueryAction) {
  // @ts-ignore
  const state = yield select();
  const { values } = state.form[FORM_KEY];
  const { query, account } = values;
  let refinedQuery;

  if (query && item.modifier) {
    refinedQuery = `${values.query} ${item.modifier}:`;
  } else if (item.modifier) {
    refinedQuery = `${item.modifier}:`;
  }

  yield call(
    publishFact,
    new SearchFormRefineMenuItemAddedFact({
      modifier: item.modifier ? item.modifier : undefined,
    })
  );

  yield call(publishTrackEvent, {
    action: 'added',
    actionSubject: 'menu',
    actionSubjectId: 'refineMenuItem',
    source: 'search',
    containerType: 'account',
    containerId: uncurlyUuid(account.uuid),
    attributes: {
      modifier: item.modifier ? item.modifier : undefined,
    },
  });

  yield put(change(FORM_KEY, 'query', refinedQuery));
}
