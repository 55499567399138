/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { colors, borderRadius } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const ShortcutGroup = styled.div`
  margin-bottom: ${token('space.200', '16px')};
`;

export const GroupTitle = styled.h5`
  border-bottom: 2px solid ${token('color.border', colors.N40)};
  padding-bottom: ${token('space.050', '4px')};
`;

export const Shortcuts = styled.dl`
  padding-left: 0;
`;

export const Shortcut = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: ${token('space.050', '4px')};
`;

export const ShortcutKeys = styled.dd`
  margin-top: 0;
`;

export const ShortcutKey = styled.kbd`
  align-items: center;
  background-clip: padding-box;
  border: 1px solid ${token('color.border', colors.N50)};
  border-radius: ${borderRadius()}px;
  box-sizing: border-box;
  display: flex;
  background-color: ${token('color.background.input.hovered', colors.N10)};
  background-image: linear-gradient(
    to bottom,
    ${token('color.background.input.pressed', colors.N0)},
    ${token('color.background.input.hovered', colors.N20)}
  );
  background-repeat: repeat-x;
  font-size: 0.9em;
  justify-content: center;
  margin: 0 2px;
  min-height: 20px;
  min-width: 20px;
  padding: 0 5px;
  white-space: nowrap;
`;
