/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import * as Sentry from '@sentry/browser';
import { select, call, put, take } from 'redux-saga/effects';

import { Repository } from 'src/components/types';
import {
  createCommitStatusMapper,
  fetchBuildStatuses,
  fetchVerificationStatuses,
} from 'src/redux/commit-list/utils';
import { commit as commitSchema } from 'src/redux/pull-request/schemas';
import { PR_UPDATE_COMMITS_STATUS } from 'src/redux/repo-commits/actions';
import { getRepoCommitsState } from 'src/redux/repo-commits/selectors';
import { LoadRepositoryPage } from 'src/sections/repository/actions';
import { getCurrentRepository } from 'src/selectors/repository-selectors';
import { BuildStatusCountsMap, VerificationStatus } from 'src/types';

export function* fetchCommitsStatusesSaga() {
  const { commits } = yield select(getRepoCommitsState);

  if (!commits.length) {
    return;
  }

  try {
    let repository: Repository = yield select(getCurrentRepository);

    if (!repository) {
      yield take(LoadRepositoryPage.SUCCESS);
      repository = yield select(getCurrentRepository);
    }

    const { full_name: fullName } = repository;

    const statuses: BuildStatusCountsMap = yield call(
      fetchBuildStatuses,
      fullName,
      commits
    );

    const verificationStatuses: VerificationStatus[] = yield call(
      fetchVerificationStatuses,
      fullName,
      commits
    );

    const addStatus = createCommitStatusMapper(statuses, verificationStatuses);
    yield put({
      type: PR_UPDATE_COMMITS_STATUS.SUCCESS,
      payload: commits.map(addStatus),
      meta: {
        schema: [commitSchema],
      },
    });
  } catch (e) {
    Sentry.captureException(e);
  }
}
