import React from 'react';

import { CustomGlyphProps } from '@atlaskit/icon/types';

export const SuccessStatusIcon = (props: CustomGlyphProps): JSX.Element => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16">
      <path
        d="M11.922 6.375l-4.328 4.36c-.188.145-.37.218-.547.218h-.375a.791.791 0 01-.297-.062.708.708 0 01-.25-.156L4.078 8.672A.342.342 0 014 8.445c0-.088.026-.164.078-.226l.813-.798a.257.257 0 01.22-.11c.093 0 .17.037.234.11L6.64 8.72a.32.32 0 00.227.093.314.314 0 00.227-.094l3.562-3.595a.41.41 0 01.243-.078c.086 0 .157.026.21.078l.812.797a.408.408 0 01.078.242c0 .09-.026.16-.078.21M15.367 4.9a8.039 8.039 0 00-1.703-2.546A8.098 8.098 0 0011.11.644 7.709 7.709 0 008 0C6.916 0 5.883.21 4.9.633a8.025 8.025 0 00-2.548 1.703A8.087 8.087 0 00.642 4.89 7.693 7.693 0 000 8c0 1.083.21 2.117.633 3.102.422.984.99 1.833 1.703 2.546a8.08 8.08 0 002.555 1.71c.99.428 2.026.642 3.11.642 1.083 0 2.117-.21 3.102-.633a8.055 8.055 0 002.547-1.703 8.182 8.182 0 001.71-2.555 7.79 7.79 0 00.64-3.11c0-1.083-.21-2.117-.633-3.102"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};
