const urls = {
  ui: {
    learnMore: () =>
      `https://support.atlassian.com/organization-administration/docs/learn-about-atlassian-intelligence/`,
    trustCenter: () => `https://www.atlassian.com/trust/atlassian-intelligence`,
    acceptableUsePolicy: () =>
      `https://www.atlassian.com/legal/acceptable-use-policy`,
    specificToS: () =>
      `https://www.atlassian.com/legal/product-specific-terms#atlassian-intelligence-specific-terms`,
    otherProducts: () =>
      'https://support.atlassian.com/organization-administration/docs/activate-atlassian-intelligence-for-products/',
  },
};

export default urls;
