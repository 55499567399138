import { Pipeline, PipelineError } from 'src/components/pipelines/models';
import { LoadingStatus } from 'src/constants/loading-status';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import {
  SET_CURRENT_PIPELINE,
  CLEAR_CURRENT_PIPELINE,
  REQUEST_CREATE_PIPELINE,
  REQUEST_CURRENT_PIPELINE,
  REQUEST_REDEPLOY_STEP,
  REQUEST_RERUN_STEPS,
  REQUEST_STOP_PIPELINE,
  REQUEST_UPDATE_PIPELINE,
} from '../actions/pipelines';

export type CurrentPipelineState = {
  pipeline: Pipeline;
  fetchedStatus?: LoadingStatus;
};

export const initialState: CurrentPipelineState = {
  pipeline: new Pipeline(),
  fetchedStatus: LoadingStatus.Before,
};

const reduceCurrentPipeline = (
  state: CurrentPipelineState,
  action: Action<any>
) => {
  if (!action.payload?.uuid) {
    return state;
  }
  return {
    pipeline: new Pipeline({
      ...action.payload,
    }),
    fetchedStatus: LoadingStatus.Success,
  };
};

export default createReducer(initialState, {
  [CLEAR_CURRENT_PIPELINE]() {
    return initialState;
  },
  [SET_CURRENT_PIPELINE]: reduceCurrentPipeline,
  [REQUEST_CURRENT_PIPELINE.SUCCESS]: reduceCurrentPipeline,
  [REQUEST_CURRENT_PIPELINE.ERROR](state: CurrentPipelineState) {
    return {
      pipeline: new Pipeline({
        ...state?.pipeline.toJS?.(),
        error: new PipelineError({ key: 'pipeline-error' }),
      }),
      fetchedStatus: state.fetchedStatus,
    };
  },
  [REQUEST_UPDATE_PIPELINE.SUCCESS](
    state: CurrentPipelineState,
    action: Action<any>
  ) {
    if (
      !state.pipeline.uuid ||
      action.payload?.data.uuid !== state.pipeline.uuid
    ) {
      return state;
    }
    return {
      pipeline: new Pipeline({
        ...action.payload.data,
      }),
      fetchedStatus: state.fetchedStatus,
    };
  },
  [REQUEST_CREATE_PIPELINE.REQUEST](state: CurrentPipelineState) {
    return {
      pipeline: new Pipeline({
        ...state?.pipeline.toJS?.(),
        isCreating: true,
      }),
      fetchedStatus: LoadingStatus.Fetching,
    };
  },
  [REQUEST_CREATE_PIPELINE.SUCCESS](state, action) {
    return {
      pipeline: new Pipeline({
        ...state?.pipeline.toJS?.(),
        build_number: action.payload.build_number,
        uuid: action.payload.uuid,
        error: null,
      }),
      fetchedStatus: state.fetchedStatus,
    };
  },
  [REQUEST_CREATE_PIPELINE.ERROR](state, action) {
    if (!action.payload?.body) {
      return state;
    }
    return {
      pipeline: new Pipeline({
        ...state?.pipeline.toJS?.(),
        isCreating: false,
        error: new PipelineError(action.payload.body),
      }),
      fetchedStatus: state.fetchedStatus,
    };
  },
  [REQUEST_STOP_PIPELINE.REQUEST](state) {
    return {
      pipeline: new Pipeline({
        ...state?.pipeline.toJS?.(),
        isStopping: true,
      }),
      fetchedStatus: state.fetchedStatus,
    };
  },
  [REQUEST_STOP_PIPELINE.ERROR](state, action) {
    return {
      pipeline: new Pipeline({
        ...state?.pipeline.toJS?.(),
        isStopping: false,
        error: new PipelineError(action.payload.body),
      }),
      fetchedStatus: state.fetchedStatus,
    };
  },
  [REQUEST_REDEPLOY_STEP.SUCCESS]: (state, action) => {
    if (!action.payload) {
      return state;
    }
    return {
      pipeline: new Pipeline({
        ...state?.pipeline.toJS?.(),
        ...action.payload,
      }),
      fetchedStatus: state.fetchedStatus,
    };
  },
  [REQUEST_RERUN_STEPS.SUCCESS]: (state, action) => {
    if (!action.payload) {
      return state;
    }
    return {
      pipeline: new Pipeline({
        ...state?.pipeline.toJS?.(),
        ...action.payload,
      }),
      fetchedStatus: state.fetchedStatus,
    };
  },
});
