import { uniqBy } from 'lodash-es';
import qs from 'qs';

import { BranchingModelState } from 'src/redux/branches/reducers/branch-list-reducer';
import { BranchingModelBranch } from 'src/sections/repository/sections/branches/types';
import { Ref, RefType } from 'src/sections/repository/types';
import urls from 'src/sections/repository/urls';
import authRequest from 'src/utils/fetch';

const PAGE_LEN = 25;

export type QueryOptions =
  | { type: 'exact'; exact: boolean }
  | { type: 'custom'; customQuery: string };

/**
 * Sorts tags by last updated date
 * Sorts branches by name, with production/dev/main branch at top
 * @param {string} repositoryFullSlug - Repo full slug
 * @param {string} filter - Search filter
 * @param {RefType} type - Whether to search for "branch" or "tag" objects
 * @param {Ref | null | undefined} mainRef - Main ref object
 * @param {BranchingModelState | undefined} branchingModel - Branching model state used to derive priority refs
 * @param {string | undefined} sort - String to sort results by
 * @param {QueryOptions | undefined} queryOptions - Optional query options
 * There are options for setting an exact query (`exact` set to true results in a query like `name = "filter"`)
 * Or options for defining a custom query (e.g. customQuery = `name = "filter" AND name != "some text" `)
 *
 * @returns {string} - Ref URL
 */
export const generateRefUrl = (
  repositoryFullSlug: string,
  filter: string,
  type: RefType | 'all',
  mainRef: Ref | null | undefined,
  branchingModel?: BranchingModelState,
  sort?: string,
  queryOptions?: QueryOptions
): string => {
  const [owner, slug] = repositoryFullSlug.split('/');

  let url;
  switch (type) {
    case 'branch': {
      url = urls.api.v20.branches(owner, slug);
      break;
    }
    case 'tag': {
      url = urls.api.v20.tags(owner, slug);
      break;
    }
    default: {
      url = urls.api.v20.refs(owner, slug);
      break;
    }
  }

  // Prevent duplicate priority refs
  const priorityRefs: Set<string> = new Set(mainRef ? [mainRef.name] : []);

  if (branchingModel) {
    const { production, development } = branchingModel;
    const priorityModelRefs = [production, development].filter(
      n => !!n && !n.use_mainbranch
    );

    priorityModelRefs.forEach(ref => {
      if (ref) priorityRefs.add(ref.name);
    });
  }

  const specialRefsQuery = Array.from(priorityRefs)
    .map(ref => `name != "${ref}" AND `)
    .join('');

  const sortQuery = sort || (type === 'branch' ? 'name' : '-target.date');

  // Define the base query
  let query = type === 'branch' ? `${specialRefsQuery}` : '';

  if (queryOptions) {
    switch (queryOptions.type) {
      case 'exact':
        query = queryOptions.exact
          ? `name = "${filter}"` // Query for exact matches
          : query.concat(`name ~ "${filter}"`); // Base query + similar matches
        break;
      case 'custom':
        query = queryOptions.customQuery; // Custom query
        break;
    }
  } else {
    query = query.concat(`name ~ "${filter}"`); // Base query + similar matches
  }

  const queryString = qs.stringify({
    pagelen: PAGE_LEN,
    q: query,
    sort: sortQuery,
    // Filter down to just the target.hash, so we don't have to load
    // the commit object which could be unreasonably large, as the commit
    // message is user-controlled. If we only access the ref hash, we can
    // usually skip loading the commit object entirely.
    //
    // We need the hash because a ref name may not be safe to use as a
    // path element (e.g. a branch with a / in it). See `sourceWithRef`.
    fields: '-values.target.*,+values.target.hash',
  });

  return `${url}?${queryString}`;
};

export const shouldShowRef = (
  ref: BranchingModelBranch | Ref | null | undefined,
  filter: string
) => {
  if (!ref || !ref.name) {
    return false;
  }

  return ref.name.includes(filter);
};

type RefOptionsReturnType = {
  next: string;
  preparedRefs: Ref[];
};

export const fetchRefOptions = async (
  url: string,
  reset: boolean,
  filter: string,
  type: RefType,
  mainRef: Ref | null | undefined,
  branchingModel?: BranchingModelState,
  onError?: (e: Error & { status?: number }) => void
): Promise<RefOptionsReturnType> => {
  const response = await fetch(authRequest(url));
  if (!response.ok) {
    if (onError) {
      const e = new Error(response.statusText || 'error loading branches');
      onError({ ...e, status: response.status });
    } else {
      throw Error();
    }
  }
  const { next, values: newRefs } = await response.json();
  let preparedRefs = newRefs;

  // for branches, we apply custom ordering
  if (type === 'branch') {
    let priorityRefs: any[] = [];

    if (shouldShowRef(mainRef, filter) && reset) {
      priorityRefs = [mainRef];
    }

    if (branchingModel) {
      const { development, production } = branchingModel;
      const priorityModelRefs = [development, production].filter(
        b => !!b && !b.use_mainbranch && shouldShowRef(b, filter) && reset
      );

      priorityRefs = [...priorityRefs, ...priorityModelRefs];
    }

    preparedRefs = uniqBy([...priorityRefs, ...preparedRefs], 'name');
    return { preparedRefs, next };
  }

  // tags
  return { preparedRefs, next };
};
