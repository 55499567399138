/* eslint-disable no-bitwise */
export var generateUuid = function generateUuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0;
    return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
  });
};

/* eslint-enable no-bitwise */
var staticValue = false;
export var uuid = {
  setStatic: function setStatic(value) {
    staticValue = value;
  },
  generate: function generate() {
    return staticValue || generateUuid();
  }
};