import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  previousRunMessageWarning: {
    id: 'frontbucket.pipelines.components.previousRunMessage.warning',
    description: 'The warning title on the previous run message',
    defaultMessage: 'Warning',
  },
  previousRunMessageText: {
    id: 'frontbucket.pipelines.components.previousRunMessage.text',
    description:
      'The text of the previous run message telling the user they are on a previous run',
    defaultMessage: 'You are viewing a previous run',
  },
  previousRunMessageLink: {
    id: 'frontbucket.pipelines.components.previousRunMessage.link',
    description:
      'The text for the link to guide the user back to the current pipeline run',
    defaultMessage: 'Return to the current run to take pipeline actions',
  },
});
