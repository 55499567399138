import React, { SyntheticEvent } from 'react';

import { useQueryParam } from 'react-resource-router';

import DynamicTable, { DynamicTableProps } from '@atlaskit/dynamic-table';
import Pagination, { type PaginationPropTypes } from '@atlaskit/pagination';
import { Box, Flex, xcss } from '@atlaskit/primitives';

import { type SortData } from 'src/components/pipelines/components/test-management/types';
import { addOrToggleSort } from 'src/components/pipelines/components/test-management/utils';

type Props = Pick<DynamicTableProps, 'rows' | 'head'> &
  PaginationPropTypes & {
    isLoading?: boolean;
  };

const flexStyle = xcss({
  justifyContent: 'center',
  marginTop: 'space.400',
  marginBottom: 'space.800',
});

/* eslint @typescript-eslint/ban-types: "warn" */
export const TestManagementTable: React.FC<Props> = ({
  head,
  rows,
  pages,
  isLoading,
}) => {
  const [page, setPage] = useQueryParam('page');
  const [sort, setSort] = useQueryParam('sort');

  const onPageChange = (_: SyntheticEvent, selectedIndex: number) => {
    setPage(selectedIndex.toString());
  };

  const onSort = (sortData: SortData) => {
    setSort(addOrToggleSort(sortData.key, sort));
  };

  return (
    <Box>
      <DynamicTable
        head={head}
        rows={rows}
        isFixedSize
        isLoading={isLoading}
        onSort={onSort}
      />
      <Flex xcss={flexStyle}>
        <Pagination
          pages={pages}
          selectedIndex={Number(page ?? 1) - 1}
          onChange={onPageChange}
        />
      </Flex>
    </Box>
  );
};
