import React from 'react';

import { LoadingButton } from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip';

import { Action } from './Action';
import { ButtonOverlay, SingleActionButtonWrapper } from './styled';

export interface Props {
  isLoading?: boolean;
  action: Action;
}

/* eslint @typescript-eslint/ban-types: "warn" */
const SingleActionButton: React.FC<Props> = ({ isLoading, action }) => {
  if (!action.isVisible) {
    return null;
  }

  return (
    <SingleActionButtonWrapper>
      <Tooltip position="left" content={!isLoading && action.disabled?.reason}>
        {!isLoading && action.disabled?.value && (
          <ButtonOverlay data-testid="button-overlay" />
        )}
        <LoadingButton
          spacing="compact"
          isDisabled={action.disabled?.value}
          isLoading={isLoading}
          onClick={action.onClick}
          testId="action-button"
        >
          {action.name}
        </LoadingButton>
      </Tooltip>
    </SingleActionButtonWrapper>
  );
};

export default React.memo(SingleActionButton);
