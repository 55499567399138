import React, { useContext, useMemo } from 'react';

import { Link, useQueryParam } from 'react-resource-router';

import Button from '@atlaskit/button/standard-button';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu-12';
import BranchesIcon from '@atlaskit/icon/glyph/bitbucket/branches';
import CommitsIcon from '@atlaskit/icon/glyph/bitbucket/commits';
import PullRequestsIcon from '@atlaskit/icon/glyph/bitbucket/pullrequests';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { IntervalRenderer } from 'src/components/pipelines/common';
import {
  TestMetricValue,
  TestName,
  TestChildWrapper,
  TestTableHeader,
  TestTextWrapper,
  TestOutcome,
  TestTitle,
  TestManagementTable,
  type TestExecutions,
} from 'src/components/pipelines/components/test-management';
import { formatTestDurations } from 'src/components/pipelines/components/test-management/utils';
import { DropdownMenuMockContext } from 'src/components/pipelines/contexts/DropdownMenuMockContext';
import { messages } from 'src/components/pipelines/pages/test-management/test-execution/test-execution-page.i18n';
import {
  executionTimeHasWarning,
  getVariance,
  varianceHasWarning,
} from 'src/components/pipelines/pages/test-management/utils';
import { formatDistanceToNow } from 'src/components/pipelines/utils';
import durationToSeconds from 'src/components/pipelines/utils/durationToSeconds';
import { getPipelinesPageURL } from 'src/components/pipelines/utils/links';
import { getPages } from 'src/components/pipelines/utils/pagination';
import { useIntl } from 'src/hooks/intl';

type Props = {
  testExecutions?: TestExecutions;
  repositoryFullSlug: string;
  isLoading?: boolean;
};

/* eslint @typescript-eslint/ban-types: "warn" */
export const InlineSettings: React.FC<{
  pipelineURL: string;
}> = ({ pipelineURL }) => {
  const { defaultOpen } = useContext(DropdownMenuMockContext);
  const { formatMessage } = useIntl();

  return (
    <DropdownMenu<HTMLButtonElement>
      defaultOpen={defaultOpen}
      trigger={({ triggerRef, ...props }) => (
        <Button
          {...props}
          iconBefore={
            <MoreIcon label={formatMessage(messages.actions)} size="small" />
          }
          label={formatMessage(messages.actions)}
          appearance="subtle"
          ref={triggerRef}
          testId="pipelines-inline-action-button"
        />
      )}
      shouldRenderToParent
    >
      <DropdownItemGroup>
        <DropdownItem>
          <Link
            to={pipelineURL}
            style={{
              textDecoration: 'none',
              color: token('color.text', colors.N800),
            }}
          >
            {formatMessage(messages.viewLogs)}
          </Link>
        </DropdownItem>
      </DropdownItemGroup>
    </DropdownMenu>
  );
};

/* eslint @typescript-eslint/ban-types: "warn" */
export const TestExecutionTable: React.FC<Props> = ({
  testExecutions,
  repositoryFullSlug,
  isLoading,
}) => {
  const { formatMessage } = useIntl();

  // Hack to get the average duration from the URL
  // until test execution api is updated to return it
  const [averageDurationParam] = useQueryParam('averageDuration');
  const averageDuration = averageDurationParam || '0.113';

  const tableHead = useMemo(
    () => ({
      cells: [
        {
          key: 'build',
          content: (
            <TestTableHeader>{formatMessage(messages.build)}</TestTableHeader>
          ),
          width: 40,
        },
        {
          key: 'outcome',
          content: (
            <TestTableHeader>{formatMessage(messages.outcome)}</TestTableHeader>
          ),
          width: 15,
        },
        {
          key: 'executionTime',
          content: (
            <TestTableHeader>
              {formatMessage(messages.executionTime)}
            </TestTableHeader>
          ),
          width: 15,
        },
        {
          key: 'variance',
          content: (
            <TestTableHeader>
              {formatMessage(messages.variance)}
            </TestTableHeader>
          ),
          width: 15,
        },
        {
          key: 'actions',
          content: (
            <TestTableHeader>{formatMessage(messages.actions)}</TestTableHeader>
          ),
          width: 5,
        },
      ],
    }),
    [formatMessage]
  );

  const tableRows = useMemo(() => {
    return testExecutions?.values?.map(
      ({ uuid, status, duration, target, createdOn }) => {
        const key = `row-${uuid}`;

        const executionTime = durationToSeconds(duration);
        const executionTimeContent = (
          <TestMetricValue
            hasWarning={executionTimeHasWarning(duration, averageDuration)}
          >
            {formatTestDurations(executionTime)}
          </TestMetricValue>
        );

        const executionTimeDiff = getVariance(executionTime, averageDuration);
        const hasVarianceWarning = varianceHasWarning(executionTimeDiff);
        const varianceContent = (
          <TestMetricValue hasWarning={hasVarianceWarning}>
            {hasVarianceWarning ? '+' : ''}
            {executionTimeDiff}
          </TestMetricValue>
        );

        const outcomeContent = (
          <TestOutcome type={status}>{status}</TestOutcome>
        );

        return {
          key,
          cells: [
            {
              key: `${key}-build`,
              content: (
                <>
                  <TestTitle>
                    <TestChildWrapper>
                      <CommitsIcon
                        label={formatMessage(messages.revisionLabel)}
                        size="small"
                      />
                      <TestTextWrapper>
                        {target.revision.slice(0, 7)}
                      </TestTextWrapper>
                    </TestChildWrapper>
                    <TestChildWrapper>
                      {target.type === 'REFERENCE' ? (
                        <BranchesIcon
                          label={formatMessage(messages.referenceNameLabel)}
                          size="small"
                        />
                      ) : (
                        <PullRequestsIcon
                          label={formatMessage(messages.referenceNameLabel)}
                          size="small"
                        />
                      )}
                      <TestTextWrapper>{target.referenceName}</TestTextWrapper>
                    </TestChildWrapper>
                  </TestTitle>
                  <TestName>
                    <IntervalRenderer interval={15000}>
                      {() => formatDistanceToNow(createdOn)}
                    </IntervalRenderer>
                  </TestName>
                </>
              ),
            },
            {
              key: `${key}-outcome`,
              content: outcomeContent,
            },
            {
              key: `${key}-executionTime`,
              content: executionTimeContent,
            },
            {
              key: `${key}-variance`,
              content: varianceContent,
            },
            {
              key: `${key}-viewLogs`,
              content: (
                <InlineSettings
                  pipelineURL={getPipelinesPageURL(repositoryFullSlug)}
                />
              ),
            },
          ],
        };
      }
    );
  }, [averageDuration, formatMessage, testExecutions, repositoryFullSlug]);

  return (
    <TestManagementTable
      head={tableHead}
      rows={tableRows}
      pages={getPages(testExecutions?.size ?? 0, testExecutions?.pagelen ?? 0)}
      isLoading={isLoading}
    />
  );
};
