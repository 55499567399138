import React, { PureComponent } from 'react';

import { toDate } from 'date-fns';
import { injectIntl, InjectedIntl } from 'react-intl';

import { getISODate, localeFormat } from '../utils/time';

type ISODateProps = {
  date: Date | string;
  intl: InjectedIntl;
};

// @ts-ignore TODO: fix noImplicitAny error here
const isNaN = obj => obj !== obj; // eslint-disable-line no-self-compare

export default injectIntl(
  class ISODate extends PureComponent<ISODateProps> {
    render() {
      const { intl, date } = this.props;
      const dt = typeof date === 'string' ? toDate(new Date(date)) : date;

      // check if valid Date
      if (isNaN(dt.getTime())) {
        return null;
      }

      return (
        <time
          dateTime={String(getISODate(dt))}
          title={intl.formatDate(dt, localeFormat)}
        >
          {getISODate(dt)}
        </time>
      );
    }
  }
);

/**
 * Same as the ISODate component, but returns a string instead of
 * an element.
 */
export function ISODateString(date: Date | string) {
  const dt = typeof date === 'string' ? toDate(new Date(date)) : date;

  if (isNaN(dt.getTime())) {
    return '';
  }

  return getISODate(dt);
}
