import { denormalize } from 'normalizr';

import {
  ActivityApiResponse,
  isApprovalActivity,
  isTaskActivity,
} from 'src/components/activity/types';
import { PullRequest, User } from 'src/components/types';
import { Task } from 'src/components/types/src/task';
import { createAsyncAction } from 'src/redux/actions';
import { Action } from 'src/types/state';

import {
  prefixed,
  EXITED_CODE_REVIEW,
  TASK_CREATE,
  TASK_STATE_CHANGE,
  TASK_DELETE,
  DELETE_COMMENT,
  ADD_REVIEWER,
  APPROVAL_SUCCESS,
  REQUEST_CHANGES,
  REMOVE_REVIEWER,
  UPDATE_DESCRIPTION,
  UPDATE_TITLE,
} from './actions';
import { DECLINE } from './decline-reducer';
import { MERGE } from './merge-reducer';
import { pullRequest as pullRequestSchema } from './schemas';

export type ActivityState = {
  activityEvents: ActivityApiResponse[];
  isActivityLoading: boolean;
  nextUrl: string | null;
  hasError: boolean;
};

export const FETCH_ACTIVITY = createAsyncAction(prefixed('FETCH_ACTIVITY'));

export const activityInitialState: ActivityState = {
  activityEvents: [],
  nextUrl: null,
  isActivityLoading: false,
  hasError: false,
};

const transformToTaskEvent = (
  newTask: Task,
  currentUser: User | undefined = undefined
) => {
  // Transforms a BBTask into an object minimally similiar to the task
  // activity events we get from the activity/ API (ActivityApi['TaskActivity'])
  const { id, creator, created_on: createdOn, state: taskState } = newTask;
  return {
    task: {
      id,
      actor: currentUser || creator,
      action: taskState === 'UNRESOLVED' ? 'CREATED' : 'RESOLVED',
      action_on:
        taskState === 'UNRESOLVED' ? createdOn : new Date().toISOString(),
      updated_on:
        taskState === 'UNRESOLVED' ? createdOn : new Date().toISOString(),
      task: newTask,
    },
  };
};

const transformToReviewerAddedEvent = (userAdded: User, currentUser: User) => {
  return {
    update: {
      author: currentUser,
      date: new Date().toISOString(),
      changes: {
        reviewers: {
          added: [userAdded],
        },
      },
    },
  };
};

const transformToReviewerRemovedEvent = (
  userRemoved: User,
  currentUser: User
) => {
  return {
    update: {
      author: currentUser,
      date: new Date().toISOString(),
      changes: {
        reviewers: {
          removed: [userRemoved],
        },
      },
    },
  };
};

const transformToStatusChangeEvent = (pullRequest: PullRequest) => {
  return {
    update: {
      author: pullRequest.closed_by,
      date: pullRequest.closed_on || new Date().toISOString(),
      state: pullRequest.state,
      changes: {
        status: {
          new: pullRequest.state,
        },
      },
    },
  };
};

const transformToDescriptionUpdatedEvent = (
  pullRequest: PullRequest,
  currentUser: User
) => {
  return {
    update: {
      author: currentUser,
      date: new Date().toISOString(),
      changes: {
        description: {
          new: pullRequest.description,
        },
      },
    },
  };
};

const transformToTitleUpdatedEvent = (
  oldTitle: string,
  pullRequest: PullRequest,
  currentUser: User
) => {
  return {
    update: {
      author: currentUser,
      date: new Date().toISOString(),
      changes: {
        title: {
          new: pullRequest.title,
          old: oldTitle,
        },
      },
    },
  };
};

export const activityReducer = (
  state: ActivityState = activityInitialState,
  action: Action
) => {
  switch (action.type) {
    case FETCH_ACTIVITY.REQUEST:
      return {
        ...state,
        activityEvents: [],
        isActivityLoading: true,
        hasError: false,
      };
    case FETCH_ACTIVITY.SUCCESS: {
      const { nextUrl } = action.payload;
      return {
        ...state,
        activityEvents: [
          ...state.activityEvents,
          ...action.payload.activityEvents,
        ],
        isActivityLoading: false,
        hasError: false,
        nextUrl,
      };
    }
    case FETCH_ACTIVITY.ERROR:
      return {
        ...state,
        isActivityLoading: false,
        hasError: true,
      };
    case TASK_CREATE.SUCCESS: {
      const { newTask } = action.payload;
      if (newTask.pending) {
        return state;
      }
      return {
        ...state,
        activityEvents: [
          transformToTaskEvent(newTask),
          ...state.activityEvents,
        ],
      };
    }
    case TASK_DELETE.SUCCESS: {
      const task: Task = action.payload;
      return {
        ...state,
        activityEvents: state.activityEvents.filter(event => {
          return !(isTaskActivity(event) && event.task.task.id === task.id);
        }),
      };
    }
    case ADD_REVIEWER.SUCCESS: {
      const userAddedUuid =
        Object.keys(action.payload.entities.users).find(
          key =>
            action.payload.entities.users[key].account_id ===
            action.payload.result.userAaid
        ) || '';
      const userAdded: User = action.payload.entities.users[userAddedUuid];
      const { currentUser } = action.payload.result as { currentUser: User };
      return {
        ...state,
        activityEvents: [
          transformToReviewerAddedEvent(userAdded, currentUser),
          ...state.activityEvents,
        ],
      };
    }
    case APPROVAL_SUCCESS: {
      const { currentUser, approval } = action.payload;
      if (approval) {
        return {
          ...state,
          activityEvents: [
            {
              approval: {
                date: new Date().toISOString(),
                user: currentUser,
              },
            },
            ...state.activityEvents,
          ],
        };
      } else {
        // If it's an unapproval, remove the corresponding approval event of the currentUser
        return {
          ...state,
          activityEvents: state.activityEvents.filter(event => {
            return (
              // Keep non-approval events
              !isApprovalActivity(event) ||
              // Keep approval events not from the currentUser
              (isApprovalActivity(event) &&
                event.approval.user.uuid !== currentUser.uuid)
            );
          }),
        };
      }
    }
    case REQUEST_CHANGES.SUCCESS: {
      const { currentUser } = action.payload;
      return {
        ...state,
        activityEvents: [
          {
            changes_requested: {
              date: new Date().toISOString(),
              user: currentUser,
            },
          },
          ...state.activityEvents,
        ],
      };
    }
    case REMOVE_REVIEWER.SUCCESS: {
      const { currentUser } = action.payload.result as { currentUser: User };
      if (!action.payload.result.removedUser) {
        return state;
      }
      return {
        ...state,
        activityEvents: [
          transformToReviewerRemovedEvent(
            action.payload.result.removedUser,
            currentUser
          ),
          ...state.activityEvents,
        ],
      };
    }
    case DELETE_COMMENT.SUCCESS: {
      return {
        ...state,
        activityEvents: state.activityEvents.filter(event => {
          return !(
            isTaskActivity(event) &&
            event.task.task.comment?.id === action.payload.id
          );
        }),
      };
    }
    case TASK_STATE_CHANGE.SUCCESS: {
      const { task, user } = action.payload;
      if (task.state === 'RESOLVED') {
        return {
          ...state,
          activityEvents: [
            transformToTaskEvent(task, user),
            ...state.activityEvents,
          ],
        };
      } else {
        return {
          ...state,
          activityEvents: state.activityEvents.filter(event => {
            return !(
              isTaskActivity(event) &&
              event.task.task.id === task.id &&
              event.task.action === 'RESOLVED'
            );
          }),
        };
      }
    }
    case DECLINE.SUCCESS: {
      const declinedPullRequest = denormalize(
        action.payload.result,
        pullRequestSchema,
        action.payload.entities
      );
      return {
        ...state,
        activityEvents: [
          transformToStatusChangeEvent(declinedPullRequest),
          ...state.activityEvents,
        ],
      };
    }
    case MERGE.SUCCESS: {
      const mergedPullRequest = denormalize(
        action.payload.result.currentPullRequest,
        pullRequestSchema,
        action.payload.entities
      );
      return {
        ...state,
        activityEvents: [
          transformToStatusChangeEvent(mergedPullRequest),
          ...state.activityEvents,
        ],
      };
    }
    case UPDATE_DESCRIPTION.SUCCESS: {
      const { currentUser, currentPullRequest } = action.payload.result;
      const pullRequest = denormalize(
        currentPullRequest,
        pullRequestSchema,
        action.payload.entities
      );
      return {
        ...state,
        activityEvents: [
          transformToDescriptionUpdatedEvent(pullRequest, currentUser),
          ...state.activityEvents,
        ],
      };
    }
    case UPDATE_TITLE.SUCCESS: {
      const { oldTitle, currentUser, currentPullRequest } =
        action.payload.result;
      const pullRequest = denormalize(
        currentPullRequest,
        pullRequestSchema,
        action.payload.entities
      );
      return {
        ...state,
        activityEvents: [
          transformToTitleUpdatedEvent(
            oldTitle || '',
            pullRequest,
            currentUser
          ),
          ...state.activityEvents,
        ],
      };
    }
    case EXITED_CODE_REVIEW:
      return { ...activityInitialState };
    default:
      return state;
  }
};
