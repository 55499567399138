import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';

export const RepoSecurityLandingPageLoadable = Loadable({
  loading: LoadingPage,
  loader: () => import('./pages/security-landing-page'),
});

export const RepoSecurityLearnMoreLoadable = Loadable({
  loading: LoadingPage,
  loader: () => import('./pages/security-learn-more-page'),
});
