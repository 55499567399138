import { createResource, RouterContext } from 'react-resource-router';

import authRequest from 'src/utils/fetch';

import { RepoHookListApiModel } from '../types';

export const loadRepositoryWebhooksResource = createResource({
  type: 'repositoryWebhooks',
  getKey: ({ match }: RouterContext) =>
    `repositoryWebhooks:${match.params.repositoryOwner}:${match.params.repositorySlug}`,
  maxAge: 60000, // cache repo hooks for 1 minute so navigating between pages is fast
  getData: async ({ match }) => {
    const { repositoryOwner, repositorySlug } = match.params;
    if (!repositoryOwner || !repositorySlug) {
      throw new Error(
        'Could not fetch repository webhooks, URL params were not available'
      );
    }
    const resp = await fetch(
      authRequest(
        // pagelen=100 is the same as the old webhooks UI.
        // We should implement pagination but for now parity with the old UI will do.
        `/!api/2.0/repositories/${repositoryOwner}/${repositorySlug}/hooks/?pagelen=100`
      )
    );
    if (!resp.ok) {
      throw new Error('Could not fetch repository webhooks');
    }
    const body: RepoHookListApiModel = await resp.json();
    return body.values.filter(h => !h.read_only);
  },
});
