import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useCallback, useEffect, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { bind } from 'bind-event-listener';
import Avatar from '@atlaskit/avatar';
import { KEY_DOWN } from '@atlaskit/ds-lib/keycodes';
import noop from '@atlaskit/ds-lib/noop';
import useFocus from '@atlaskit/ds-lib/use-focus-event';
import { Section } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { layers } from '@atlaskit/theme/constants';
import Tooltip from '@atlaskit/tooltip';
import AvatarGroupItem from './avatar-group-item';
import Grid from './grid';
import FocusManager from './internal/components/focus-manager';
import PopupAvatarGroup from './internal/components/popup-avatar-group';
import MoreIndicator from './more-indicator';
import Stack from './stack';
import { composeUniqueKey } from './utils';
var MAX_COUNT = {
  grid: 11,
  stack: 5
};
function getOverrides(overrides) {
  return {
    AvatarGroupItem: _objectSpread({
      render: function render(Component, props, index) {
        return jsx(Component, _extends({}, props, {
          key: composeUniqueKey(props.avatar, index)
        }));
      }
    }, overrides && overrides.AvatarGroupItem),
    Avatar: _objectSpread({
      render: function render(Component, props, index) {
        return jsx(Component, _extends({}, props, {
          key: composeUniqueKey(props, index)
        }));
      }
    }, overrides && overrides.Avatar)
  };
}

/**
 * __Avatar group__
 *
 * An avatar group displays a number of avatars grouped together in a stack or grid.
 *
 * - [Examples](https://atlassian.design/components/avatar-group/examples)
 * - [Code](https://atlassian.design/components/avatar-group/code)
 * - [Usage](https://atlassian.design/components/avatar-group/usage)
 */
var AvatarGroup = function AvatarGroup(_ref) {
  var _ref$appearance = _ref.appearance,
    appearance = _ref$appearance === void 0 ? 'stack' : _ref$appearance,
    _ref$avatar = _ref.avatar,
    avatar = _ref$avatar === void 0 ? Avatar : _ref$avatar,
    borderColor = _ref.borderColor,
    boundariesElement = _ref.boundariesElement,
    data = _ref.data,
    isTooltipDisabled = _ref.isTooltipDisabled,
    maxCount = _ref.maxCount,
    onAvatarClick = _ref.onAvatarClick,
    onMoreClick = _ref.onMoreClick,
    overrides = _ref.overrides,
    _ref$showMoreButtonPr = _ref.showMoreButtonProps,
    showMoreButtonProps = _ref$showMoreButtonPr === void 0 ? {} : _ref$showMoreButtonPr,
    _ref$size = _ref.size,
    size = _ref$size === void 0 ? 'medium' : _ref$size,
    testId = _ref.testId,
    _ref$label = _ref.label,
    label = _ref$label === void 0 ? 'avatar group' : _ref$label,
    _ref$tooltipPosition = _ref.tooltipPosition,
    tooltipPosition = _ref$tooltipPosition === void 0 ? 'bottom' : _ref$tooltipPosition,
    _ref$shouldPopupRende = _ref.shouldPopupRenderToParent,
    shouldPopupRenderToParent = _ref$shouldPopupRende === void 0 ? false : _ref$shouldPopupRende;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isTriggeredUsingKeyboard = _useState2[0],
    setTriggeredUsingKeyboard = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    isOpen = _useState4[0],
    setIsOpen = _useState4[1];
  var onClose = useCallback(function () {
    return setIsOpen(false);
  }, []);
  var handleTriggerClicked = useCallback(function (event) {
    var _ref2 = event,
      clientX = _ref2.clientX,
      clientY = _ref2.clientY,
      type = _ref2.type;
    // Hitting enter/space is registered as a click with both clientX and clientY === 0
    if (type === 'keydown' || clientX === 0 || clientY === 0) {
      setTriggeredUsingKeyboard(true);
    }
    setIsOpen(function (isOpen) {
      return !isOpen;
    });
  }, []);
  var _useFocus = useFocus(),
    isFocused = _useFocus.isFocused,
    bindFocus = _useFocus.bindFocus;

  // When a trigger is focused, we want to open the popup
  // the user presses the DownArrow
  useEffect(function () {
    // Set initial value if popup is closed
    if (!isOpen) {
      setTriggeredUsingKeyboard(false);
    }

    // Only need to listen for keydown when focused
    if (!isFocused) {
      return noop;
    }

    // Being safe: we don't want to open the popup if it is already open
    // Note: This shouldn't happen as the trigger should not be able to get focus
    if (isOpen) {
      return noop;
    }
    bind(window, {
      type: 'keydown',
      listener: function openOnKeyDown(e) {
        if (e.key === KEY_DOWN) {
          // prevent page scroll
          e.preventDefault();
          handleTriggerClicked(e);
        }
      }
    });
    var unbind = function unbind() {
      bind(window, {
        type: 'keydown',
        listener: function openOnKeyDown(e) {
          if (e.key === KEY_DOWN) {
            // prevent page scroll
            e.preventDefault();
            handleTriggerClicked(e);
          }
        }
      });
    };
    return unbind;
  }, [isFocused, isOpen, handleTriggerClicked]);
  function renderMoreDropdown(max, total) {
    if (total <= max) {
      return null;
    }
    var renderMoreButton = function renderMoreButton(props) {
      return jsx(MoreIndicator, _extends({
        buttonProps: showMoreButtonProps,
        borderColor: borderColor,
        count: total - max,
        size: size,
        testId: testId && "".concat(testId, "--overflow-menu--trigger"),
        isActive: isOpen
      }, props));
    };

    // bail if the consumer wants to handle onClick
    if (typeof onMoreClick === 'function') {
      return renderMoreButton({
        onClick: onMoreClick
      });
    }

    // split boundariesElement into `boundary` and `rootBoundary` props for Popup
    var boundary = boundariesElement === 'scrollParent' ? 'clippingParents' : undefined;
    var rootBoundary = function () {
      if (boundariesElement === 'scrollParent') {
        return undefined;
      }
      return boundariesElement === 'window' ? 'document' : 'viewport';
    }();
    return jsx(Popup, {
      isOpen: isOpen,
      onClose: onClose,
      placement: "bottom-end",
      boundary: boundary,
      rootBoundary: rootBoundary,
      shouldFlip: true,
      zIndex: layers.modal(),
      shouldRenderToParent: shouldPopupRenderToParent,
      content: function content(_ref3) {
        var setInitialFocusRef = _ref3.setInitialFocusRef;
        return jsx(FocusManager, null, jsx(PopupAvatarGroup, {
          onClick: function onClick(e) {
            return e.stopPropagation();
          },
          minWidth: 250,
          maxHeight: 300,
          setInitialFocusRef: isTriggeredUsingKeyboard ? setInitialFocusRef : undefined
        }, jsx(Section, null, data.slice(max).map(function (avatar, index) {
          return getOverrides(overrides).AvatarGroupItem.render(AvatarGroupItem, {
            avatar: avatar,
            onAvatarClick: onAvatarClick,
            testId: testId && "".concat(testId, "--avatar-group-item-").concat(index + max),
            index: index + max
          },
          // This index holds the true index,
          // adding up the index of non-overflowed avatars and overflowed avatars.
          index + max);
        }))));
      },
      trigger: function trigger(triggerProps) {
        return renderMoreButton(_objectSpread(_objectSpread(_objectSpread({}, triggerProps), bindFocus), {}, {
          onClick: handleTriggerClicked
        }));
      },
      testId: testId && "".concat(testId, "--overflow-menu")
    });
  }
  var max = maxCount === undefined || maxCount === 0 ? MAX_COUNT[appearance] : maxCount;
  var total = data.length;
  var maxAvatar = total > max ? max - 1 : max;
  var Group = appearance === 'stack' ? Stack : Grid;
  return jsx(Group, {
    testId: testId && "".concat(testId, "--avatar-group"),
    "aria-label": label
  }, data.slice(0, maxAvatar).map(function (avatarData, idx) {
    var callback = avatarData.onClick || onAvatarClick;
    var finalAvatar = getOverrides(overrides).Avatar.render(avatar, _objectSpread(_objectSpread({}, avatarData), {}, {
      size: size,
      borderColor: borderColor || avatarData.borderColor,
      testId: testId && "".concat(testId, "--avatar-").concat(idx),
      onClick: callback ? function (event, analyticsEvent) {
        callback(event, analyticsEvent, idx);
      } : undefined,
      stackIndex: max - idx
    }), idx);
    return !isTooltipDisabled && !avatarData.isDisabled ? jsx(Tooltip, {
      key: composeUniqueKey(avatarData, idx),
      content: avatarData.name,
      testId: testId && "".concat(testId, "--tooltip-").concat(idx),
      position: tooltipPosition
    }, finalAvatar) : finalAvatar;
  }), renderMoreDropdown(+maxAvatar, total));
};
export default AvatarGroup;