import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';

const PipelinesRepoSettingsPageLoadable = Loadable({
  loading: LoadingPage,
  loader: () => import('./settings'),
});

export default PipelinesRepoSettingsPageLoadable;
