import { chunk, flatten } from 'lodash-es';
import { FormattedMessage, MessageValue } from 'react-intl';

import { Privilege } from 'src/components/types';

import {
  BAD_REQUEST_ERROR,
  DOMAIN_RESTRICTION_ERROR,
  INVITE_FAILED_ERROR,
  NON_ADMIN_RESTRICTION_ERROR,
} from './constants';
import messages from './shared.i18n';
import {
  AccessLevelType,
  AccessMode,
  CommonGroup,
  CommonUser,
  FlatEntity,
  FlatPrincipal,
  GroupPrivilege,
  InheritedPrivilege,
  NotUpdatedItems,
  Permission,
  PrivilegeOptionsType,
  UserPrivilege,
  WorkspaceGroup,
} from './types';

export const getPermissionId = ({ id, name, level, privilege }: Permission) =>
  `${id}-${name}-${level === 'workspace' ? `${level}-${privilege}` : level}`;

export const includesPermission = (
  permissions: Permission[],
  permission: Permission
) =>
  permissions.some(
    perm => getPermissionId(perm) === getPermissionId(permission)
  );

export const getSetPermissions = (
  permissions: Map<number, Permission[]>,
  page: number,
  setter: (perms: Permission[]) => Permission[],
  reset = false
) =>
  new Map(reset ? [] : permissions).set(
    page,
    setter(permissions.get(page) || [])
  );

export const getSetAllPermissions = (
  permissions: Map<number, Permission[]>,
  setter: (perms: Permission[]) => Permission[]
) => {
  const perms = new Map(permissions);
  perms.forEach((value, key) => {
    perms.set(key, setter(value));
  });
  return perms;
};

export const flattenPermissions = (permissions: Map<number, Permission[]>) =>
  flatten(
    [...permissions.entries()].sort((a, b) => a[0] - b[0]).map(p => p[1])
  );

export const resetPermissions = (
  permissions: Map<number, Permission[]>,
  limit: number,
  filter?: (permission: Permission) => boolean
) => {
  const perms = flattenPermissions(permissions);
  return new Map(
    chunk(filter ? perms.filter(filter) : perms, limit).map((p, i) => [
      i + 1,
      p,
    ])
  );
};

export const createNonce = (length = 10, radix = 32) =>
  Math.random().toString(radix).substring(2, length);

export const isPermissionEditable = (
  context: AccessLevelType,
  level: AccessLevelType,
  privilege: PrivilegeOptionsType
) =>
  (context === 'workspace' && privilege === 'create-project') ||
  (context === 'project' && level === 'project') ||
  level === 'repository';

export const getAdminLevel = (
  isWorkspaceAdmin?: boolean,
  isProjectAdmin?: boolean,
  isRepoAdmin?: boolean
): AccessLevelType | undefined => {
  if (isWorkspaceAdmin) {
    return 'workspace';
  }
  if (isProjectAdmin) {
    return 'project';
  }
  if (isRepoAdmin) {
    return 'repository';
  }
  return undefined;
};

export const selectAllPermissions = (
  permissions: Map<number, Permission[]>,
  checkedRows: Permission[],
  page: number,
  context: AccessLevelType,
  shouldSelectAll?: boolean
) => {
  const currentPage = permissions.get(page) || [];
  const rest = checkedRows.filter(p => !includesPermission(currentPage, p));
  const visible = currentPage.reduce((rows, row) => {
    if (
      !!shouldSelectAll &&
      isPermissionEditable(context, row.level, row.privilege)
    ) {
      return [...rows, row];
    }
    return rows;
  }, []);
  return [...rest, ...visible];
};

export const transformUser = (user: CommonUser): FlatPrincipal => ({
  id: user.mention_id,
  name: user.display_name,
  avatar: user.avatar_url,
  mode: AccessMode.users,
  hasAccess: user.has_access,
});

export const transformGroup = (group: CommonGroup): FlatPrincipal => ({
  id: `${group.owner.uuid}/${group.slug}`,
  name: group.name,
  mode: AccessMode.groups,
  avatar: '',
  hasAccess: group.has_access,
});

export const transformWorkspaceGroup = (
  group: WorkspaceGroup
): FlatPrincipal => ({
  id: `${group.owner.uuid}/${group.slug}`,
  name: group.name,
  mode: AccessMode.groups,
  avatar: '',
  hasAccess: group.has_access,
});

export const transformEntityToFlat = (mode: AccessMode) => (entity: any) =>
  mode === AccessMode.users ? transformUser(entity) : transformGroup(entity);

export const transformUserPrivilege = (
  userPrivilege: UserPrivilege,
  level: Permission['level']
): Permission => ({
  level,
  mode: AccessMode.users,
  avatar: userPrivilege.user.avatar,
  id: userPrivilege.user.account_id,
  name: userPrivilege.user.display_name,
  privilege: userPrivilege.privilege,
  nickname: userPrivilege.user.nickname,
  count: 0,
  slug: '',
});

export const transformGroupPrivilege = (
  groupPrivilege: GroupPrivilege,
  level: Permission['level']
): Permission => ({
  level,
  mode: AccessMode.groups,
  count: groupPrivilege.group.members.length,
  id: `${groupPrivilege.group.owner.uuid}/${groupPrivilege.group.slug}`,
  name: groupPrivilege.group.name,
  privilege: groupPrivilege.privilege,
  nickname: '',
  avatar: '',
  slug: groupPrivilege.group.slug,
});

export const transformPrivilegeToFlat =
  (mode: AccessMode) => (entity: any, level: Permission['level']) =>
    mode === AccessMode.users
      ? transformUserPrivilege(entity, level)
      : transformGroupPrivilege(entity, level);

export const extractNamesFromList = (list: (FlatEntity | Permission)[]) =>
  list.map(entity => entity.name).join(', ');

export const mapInheritedUserAndGroupPrivilege = ({
  principal,
  principal_name,
  permission,
  access_level,
  members_count = 0,
}: InheritedPrivilege): Permission =>
  principal.type === 'user'
    ? {
        mode: AccessMode.users,
        avatar: principal.links?.avatar.href,
        id: principal.account_id || principal.uuid,
        name: principal_name,
        privilege: permission,
        nickname: principal.nickname,
        count: members_count,
        slug: '',
        level: access_level,
        is_active: principal.is_active,
      }
    : {
        mode: AccessMode.groups,
        count: members_count,
        id: `${principal.owner.uuid}/${principal.slug}`,
        name: principal_name,
        privilege: permission,
        nickname: principal.name,
        avatar: '',
        slug: principal.slug,
        level: access_level,
        group_link: principal.links.html.href,
      };

/**
 * Extracts the names and IDs of entities that failed to update from a
 * NotUpdatedItems array.
 *
 * Joins the names into a comma-separated string and IDs into a
 * double-hyphen-separated string.
 *
 * @param errors - The array of entities that failed to update
 * @returns An object containing the joined entity names and IDs
 */
function getEntityNamesAndIds(errors: NotUpdatedItems): {
  entityNames: string;
  entityIds: string;
} {
  const entityNames: Array<string> = [];
  const entityIds: Array<string> = [];

  errors.forEach(({ name, id }) => {
    entityNames.push(`${name}`);
    if (id) {
      entityIds.push(id);
    }
  });

  return {
    entityNames: entityNames.join('", "'),
    entityIds: entityIds.join('--'),
  };
}

/**
 * Extracts error details from a list of entities that failed to update,
 * formats them, and returns an array of error objects.Filters the input array
 * by error message, extracts entity names/IDs, and generates formatted error
 * objects with titles, descriptions and IDs. Covers various error cases like
 * invite failures, bad requests, domain restrictions, etc.
 */
export const getAddUsersErrors = (
  items: NotUpdatedItems,
  formatMessage: (
    message: FormattedMessage.MessageDescriptor,
    values?: { [key: string]: MessageValue }
  ) => string
) => {
  const errors: Array<{
    id: string;
    description: string;
    title: string;
  }> = [];

  items
    .filter(({ errorMessage }) => errorMessage === NON_ADMIN_RESTRICTION_ERROR)
    .forEach(({ name: email }) => {
      errors.push({
        id: 'non-admin-failure',
        description: email,
        title: email,
      });
    });
  items
    .filter(({ errorMessage }) => errorMessage === DOMAIN_RESTRICTION_ERROR)
    .forEach(({ name: email }) => {
      errors.push({
        id: 'domain-restriction-failure',
        description: email,
        title: email,
      });
    });

  const inviteFailedErrors = items.filter(
    ({ errorMessage }) => errorMessage === INVITE_FAILED_ERROR
  );
  const inviteFailuresLength = inviteFailedErrors.length;

  if (inviteFailuresLength) {
    const { entityNames } = getEntityNamesAndIds(inviteFailedErrors);
    // bulkInviteTimestamp is used for unique flag IDs
    const bulkInviteTimestamp = Date.now();

    errors.push({
      id: `invite-failure-${bulkInviteTimestamp}`,
      description: formatMessage(messages.inviteFailedErrorBody, {
        entity: `"${entityNames}"`,
        count: inviteFailuresLength,
      }),
      title: formatMessage(messages.inviteFailedErrorHeader, {
        count: inviteFailuresLength,
      }),
    });
  }

  const badRequestErrors = items.filter(
    ({ errorMessage }) => errorMessage === BAD_REQUEST_ERROR
  );
  const badRequestsLength = badRequestErrors.length;

  if (badRequestsLength) {
    const { entityNames, entityIds } = getEntityNamesAndIds(badRequestErrors);

    errors.push({
      id: `bad-request-${entityIds}`,
      description: formatMessage(messages.badRequestErrorBody, {
        entity: `"${entityNames}"`,
        count: badRequestsLength,
      }),
      title: formatMessage(messages.badRequestErrorHeader, {
        count: badRequestsLength,
      }),
    });
  }

  const unknownErrors = items.filter(({ errorMessage }) => !errorMessage);

  if (unknownErrors.length) {
    const { entityNames, entityIds } = getEntityNamesAndIds(unknownErrors);

    errors.push({
      id: `${messages.unknownAddUserErrorBody.id}-${entityIds}`,
      description: formatMessage(messages.unknownAddUserErrorBody, {
        entity: `"${entityNames}"`,
        count: unknownErrors.length,
      }),
      title: formatMessage(messages.unknownErrorHeader),
    });
  }

  const otherErrors = items.filter(
    ({ errorMessage }) =>
      errorMessage &&
      errorMessage !== BAD_REQUEST_ERROR &&
      errorMessage !== NON_ADMIN_RESTRICTION_ERROR &&
      errorMessage !== DOMAIN_RESTRICTION_ERROR &&
      errorMessage !== INVITE_FAILED_ERROR
  );
  otherErrors.forEach(({ errorMessage, id, name }) => {
    errors.push({
      id: `${messages.userNotAddedHeader.id}-${id}`,
      description: errorMessage as string,
      title: formatMessage(messages.userNotAddedHeader, { entity: name }),
    });
  });

  return errors;
};

const getEntityById = (list: Permission[], id?: string) => {
  if (!id) return null;
  return list.find(p => p.id === id) || null;
};

export const showRemoveOwnAccessWarning = (
  privileges: Permission[],
  mode: AccessMode,
  entityId?: string,
  currentUser?: { account_id?: string } | null
) => {
  if (mode === AccessMode.users) {
    return (
      getEntityById(privileges, entityId)?.privilege === Privilege.admin &&
      entityId === currentUser?.account_id
    );
  } else if (mode === AccessMode.groups) {
    const entity = getEntityById(privileges, entityId);
    return entity && entity.count > 0 && entity.privilege === Privilege.admin;
  }
  return false;
};

export const bulkShowRemoveOwnAccessWarning = (
  checkedEntities: Permission[],
  currentUser?: { account_id?: string } | null
) =>
  checkedEntities.some(entity =>
    showRemoveOwnAccessWarning(
      checkedEntities,
      entity.mode,
      entity.id,
      currentUser
    )
  );
