import { createResource } from 'react-resource-router';

import authRequest from 'src/utils/fetch';

import {
  getRepositoryDynamicPipelinesProvidersUrl,
  getWorkspaceDynamicPipelinesProvidersUrl,
} from '../urls';
import { createErrorMessage } from '../utils/sentry';

export type DynamicPipelinesProvidersBody = {
  appAri: string;
  inheritedWorkspaceAPPAri: string;
};

type DynamicPipelinesResourceOptions = {
  getRequestUrl: (params: {}) => string;
  type: string;
};

export type DynamicPipelinesResource = {
  appAri: string;
  inheritedWorkspaceAPPAri: string;
};

async function fetchData(url: string) {
  try {
    const req = authRequest(url);
    const resp = await fetch(req);

    if (!resp.ok) {
      if (resp.status === 404) {
        return { appAri: '' };
      } else {
        throw new Error(await createErrorMessage(resp));
      }
    }

    const body = await resp.json();
    return { appAri: body.appAri || '' };
  } catch (e) {
    throw new Error(e);
  }
}

function createDynamicPipelinesResource({
  getRequestUrl,
  type,
}: DynamicPipelinesResourceOptions) {
  return createResource<DynamicPipelinesResource>({
    type,
    getKey: ({ match }) => getRequestUrl(match.params),
    maxAge: 60000,
    getData: async ({ match }) => {
      const initialData = await fetchData(getRequestUrl(match.params));

      // If the type is 'pipelinesRepositoryDynamicPipelines', fetch additional data
      if (type === 'pipelinesRepositoryDynamicPipelines') {
        const workspaceData = await fetchData(
          getWorkspaceDynamicPipelinesProvidersUrl({
            workspaceSlug: match.params.repositoryOwner || '',
          })
        );
        return {
          ...initialData,
          inheritedWorkspaceAPPAri: workspaceData.appAri,
        };
      }

      return { ...initialData, inheritedWorkspaceAPPAri: '' };
    },
  });
}

export const loadWorkspaceDynamicPipelinesResource =
  createDynamicPipelinesResource({
    getRequestUrl: getWorkspaceDynamicPipelinesProvidersUrl,
    type: 'pipelinesWorkspaceDynamicPipelines',
  });

export const loadRepositoryDynamicPipelinesResource =
  createDynamicPipelinesResource({
    getRequestUrl: getRepositoryDynamicPipelinesProvidersUrl,
    type: 'pipelinesRepositoryDynamicPipelines',
  });
