import { embedCard as embedCardFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name embedCard_node
 */

export var embedCard = embedCardFactory({
  parseDOM: [{
    tag: 'div[data-embed-card]',
    getAttrs: function getAttrs(dom) {
      return {
        url: dom.getAttribute('data-card-url'),
        layout: dom.getAttribute('data-layout') || 'center',
        width: Number(dom.getAttribute('data-width')) || null,
        originalWidth: Number(dom.getAttribute('data-card-original-width')) || null,
        originalHeight: Number(dom.getAttribute('data-card-original-height')) || null
      };
    }
  }],
  toDOM: function toDOM(node) {
    var _node$attrs = node.attrs,
      url = _node$attrs.url,
      layout = _node$attrs.layout,
      width = _node$attrs.width,
      originalWidth = _node$attrs.originalWidth,
      originalHeight = _node$attrs.originalHeight;
    var attrs = {
      'data-embed-card': '',
      'data-card-url': url,
      'data-layout': layout,
      'data-width': width,
      'data-original-width': originalWidth,
      'data-original-height': originalHeight
    };
    return ['div', attrs];
  }
});