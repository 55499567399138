import { createSelector } from 'reselect';

import {
  getConflicts,
  getFilteredDiffStat,
  getActiveDiff,
} from 'src/redux/pull-request/selectors';
import {
  getDiffStatPath,
  DiffStatPathType,
} from 'src/redux/pull-request/utils/get-diff-path';
import { getConversations } from 'src/selectors/conversation-selectors';
import { DiffStat, AnnotatedDiffStat } from 'src/types/diffstat';
import { diffStatToFileTree } from 'src/utils/diffstat-transformer';
import { extractFilepath } from 'src/utils/extract-file-path';

// @ts-ignore TODO: fix noImplicitAny error here
const addConflictMarkers = (diffStat, conflicts): AnnotatedDiffStat[] =>
  diffStat.map((stat: DiffStat): AnnotatedDiffStat => {
    const filepath = extractFilepath(stat);
    // @ts-ignore TODO: fix noImplicitAny error here
    const hasConflict = conflicts.find(c => c.path === filepath);

    return { ...stat, isConflicted: !!hasConflict };
  });

// @ts-ignore TODO: fix noImplicitAny error here
const addCommentCounts = (diffStat, conversations) =>
  // @ts-ignore TODO: fix noImplicitAny error here
  diffStat.map(stat => {
    const filepath = extractFilepath(stat);

    const conversationsForFile = conversations.filter(
      // @ts-ignore TODO: fix noImplicitAny error here
      conversation => filepath === conversation.meta.path
    );

    const totalComments = conversationsForFile.reduce(
      // @ts-ignore TODO: fix noImplicitAny error here
      (runningCount, currentConversation) =>
        runningCount + currentConversation.numOfComments,
      0
    );
    return { ...stat, comments: totalComments };
  });

const addHiddenFileMarker = (
  diffStat: AnnotatedDiffStat[],
  filteredDiffStat: DiffStat[]
) => {
  return diffStat.map((stat: AnnotatedDiffStat) => {
    stat.isHidden = !filteredDiffStat.find(
      (filteredStat: DiffStat) =>
        getDiffStatPath(stat, DiffStatPathType.Unescaped) ===
        getDiffStatPath(filteredStat, DiffStatPathType.Unescaped)
    );

    return stat;
  });
};

export const getFileTree = createSelector(
  getFilteredDiffStat,
  getConflicts,
  getConversations,
  (filteredDiffStat: DiffStat[], conflicts = [], conversations = []) => {
    if (!filteredDiffStat) {
      return null;
    }

    let annotatedDiffStat = addConflictMarkers(filteredDiffStat, conflicts);
    annotatedDiffStat = addHiddenFileMarker(
      annotatedDiffStat,
      filteredDiffStat
    );
    annotatedDiffStat = addCommentCounts(annotatedDiffStat, conversations);
    return diffStatToFileTree(annotatedDiffStat);
  }
);

export const makeGetIsActiveDiff = (href: string | undefined) =>
  createSelector(
    getActiveDiff,
    activeDiff => !!activeDiff && `#${activeDiff}` === href
  );

// Returns flattened array of file hrefs from file tree
// if sorting is enabled, will not files that do not match current filter
export const getFileTreeHrefs = createSelector(
  getFilteredDiffStat,
  (filteredAndSortedDiffStat: DiffStat[]): string[] =>
    (filteredAndSortedDiffStat || []).map(
      diffStat =>
        `#chg-${getDiffStatPath(diffStat, DiffStatPathType.Unescaped)}`
    )
);
