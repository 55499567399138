import React, { PureComponent, ReactNode } from 'react';

import Link, { RelativeLinkProps } from './relative-link';

export type ComponentLinkProps = Omit<RelativeLinkProps, 'to' | 'href'> & {
  href: string;
  target?: string;
};

export type ComponentLinkProxyProps = ComponentLinkProps & {
  'aria-label'?: string;
};
/**
 * Some Atlaskit components that accept a custom `component` prop (like @atlaskit/button) have
 * a component proxy that catches any unneeded/unwanted props and avoids passing them through
 * to the underlying custom component or DOM node
 *
 * see: https://bitbucket.org/atlassian/atlaskit-mk-2/src/master/packages/core/button/src/components/CustomComponentProxy.js
 *
 * However, some do not (like @atlaskit/avatar or the `linkComponent` used in @atlaskit/navigation).
 * For these cases, we need to filter the props being passed ourselves
 */
export class ComponentLinkProxy extends PureComponent<ComponentLinkProxyProps> {
  render() {
    const {
      href,
      children,
      className,
      onClick,
      onFocus,
      onKeyDown,
      onMouseEnter,
      onMouseLeave,
      onMouseDown,
      qaId,
      innerRef,
      'aria-label': ariaLabel,
      target,
      'data-testid': dataTestId,
    } = this.props;

    return (
      <Link
        to={href}
        className={className}
        data-qa={qaId}
        onClick={onClick}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onMouseDown={onMouseDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        innerRef={innerRef}
        aria-label={ariaLabel}
        target={target}
        data-testid={dataTestId}
      >
        {children}
      </Link>
    );
  }
}

export class ComponentLink extends PureComponent<ComponentLinkProps> {
  render(): ReactNode {
    const { href, children, ...otherProps } = this.props;
    return (
      <Link {...otherProps} to={href}>
        {children}
      </Link>
    );
  }
}
