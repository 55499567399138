import { createSelector, Selector } from 'reselect';

import { MenuItem } from 'src/components/navigation';
import { Workspace } from 'src/components/types';
import { BucketState } from 'src/types/state';

import { getCurrentProject } from './project-selectors';
import { getCurrentRepository } from './repository-selectors';
import {
  getProfile,
  getRecentlyViewedWorkspacesKeys as getRecentlyViewedWorkspaces,
  getWorkspacePlanSlice,
} from './state-slicing-selectors';

export const getRecentlyViewedWorkspacesKeys: Selector<
  BucketState,
  string[] | undefined
> = createSelector(
  getRecentlyViewedWorkspaces,
  recentlyWorkspacesKeys => recentlyWorkspacesKeys
);

export const getCurrentWorkspace: Selector<BucketState, Workspace | undefined> =
  createSelector(getProfile, profile => profile?.section?.currentWorkspace);

export const getCurrentWorkspaceSlug = createSelector(
  getCurrentWorkspace,
  currentWorkspace => currentWorkspace?.slug
);

export const getCurrentWorkspaceUUID = createSelector(
  getCurrentWorkspace,
  getCurrentProject,
  getCurrentRepository,
  (currentWorkspace, currentProject, currentRepository) =>
    currentWorkspace?.uuid ||
    currentProject?.workspace?.uuid ||
    currentRepository?.workspace?.uuid
);

export const getMenuItems: Selector<BucketState, MenuItem[]> = createSelector(
  getProfile,
  profile => profile.section.menuItems
);

export const getCurrentWorkspaceExtraAttributes = createSelector(
  getCurrentWorkspace,
  currentWorkspace => ({
    adminStatus: currentWorkspace?.extra?.admin_status,
    hasPremium: currentWorkspace?.extra?.has_premium,
    isPaid: currentWorkspace?.extra?.is_paid,
  })
);

export const getWorkspacePlanRequestStatus = createSelector(
  getWorkspacePlanSlice,
  workspacePlanSlice => {
    return {
      isFetchWorkspacePlanLoading: workspacePlanSlice
        ? workspacePlanSlice.isFetchWorkspacePlanLoading
        : true,
      isFetchWorkspacePlanError: workspacePlanSlice
        ? workspacePlanSlice.isFetchWorkspacePlanError
        : false,
    };
  }
);

export const getWorkspacePlan = createSelector(
  getWorkspacePlanSlice,
  workspacePlanSlice => {
    return {
      isWorkspacePaid: workspacePlanSlice
        ? workspacePlanSlice.isWorkspacePaid
        : false,
      isWorkspacePremium: workspacePlanSlice
        ? workspacePlanSlice.isWorkspacePremium
        : false,
    };
  }
);
