import {analytics} from '../../adaptors/analytics/AnalyticsAdaptor';
import {AnalyticsCategories, AnalyticsActions} from '../../adaptors/analytics/AnalyticsConstants';
import {AppContext} from '../../definitions/AppContext';
import {InlineDialogProvider} from './InlineDialogProvider';
import {logger} from '../../adaptors/logger/LoggerAdaptor';

/**
 * This is the default way that products should use to implement InlineDialogProvider operations.
 */
class DefaultInlineDialogProvider implements InlineDialogProvider {

    hide(appContext: AppContext): void {
        const appHasHideOperation =
            appContext.extension.options._contextualOperations &&
            appContext.extension.options._contextualOperations.hideInlineDialog &&
            typeof appContext.extension.options._contextualOperations.hideInlineDialog === 'function';
        if (appHasHideOperation) {
            appContext.extension.options._contextualOperations.hideInlineDialog();
            logger.debug('Hiding inline dialog...');
            analytics.trigger(
                AnalyticsCategories.inlineDialog,
                AnalyticsActions.close,
                appContext.extension.addon_key,
                {});
        } else {
            logger.warn('Attempted to hide the inline dialog for a malformed app: ', appContext);
            analytics.trigger(
                AnalyticsCategories.inlineDialog,
                AnalyticsActions.error,
                appContext.extension.addon_key,
                {message: 'No hideInlineDialog function'});
        }
    }

}

export {DefaultInlineDialogProvider};
