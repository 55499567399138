/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { put, select, take } from 'redux-saga/effects';

import { Repository, User } from 'src/components/types';
import { loadBranchingModel } from 'src/redux/branches';
import { addRecentlyViewedRepository } from 'src/redux/recently-viewed-repositories';
import {
  FetchRepositoryMainBranch,
  LoadRepositoryPage,
} from 'src/sections/repository/actions';
import fetchForgeRepoMenuItems from 'src/sections/repository/actions/fetch-forge-repo-menu-items';
import { getCurrentRepository } from 'src/selectors/repository-selectors';
import { getCurrentUser } from 'src/selectors/user-selectors';

import fetchRepositoryBranch from '../sections/repository/actions/fetch-repository-branch';

function* loadRepositorySuccess() {
  while (true) {
    yield take(LoadRepositoryPage.SUCCESS);
    const repository: Repository = yield select(getCurrentRepository);
    yield put(addRecentlyViewedRepository(repository));

    // At this point the currentUser should have been set by the GlobalState reducer in src/redux/global/reducer.ts
    const user: User = yield select(getCurrentUser);
    if (user) {
      yield put(fetchForgeRepoMenuItems(repository));
    }

    if (repository.mainbranch && repository.mainbranch.name) {
      yield put(
        fetchRepositoryBranch(
          FetchRepositoryMainBranch,
          repository.full_name,
          repository.mainbranch.name
        )
      );
    }

    const [repositoryOwner, repositorySlug] = repository.full_name.split('/');
    const options = {
      repositoryOwner,
      repositorySlug,
    };

    yield put(loadBranchingModel(options));
  }
}

export default loadRepositorySuccess;
