import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _actionMap;
import { KEY_DOWN, KEY_END, KEY_HOME, KEY_TAB, KEY_UP } from '@atlaskit/ds-lib/keycodes';
import { fg } from '@atlaskit/platform-feature-flags';
import { PREFIX } from './use-generated-id';
var actionMap = (_actionMap = {}, _defineProperty(_actionMap, KEY_DOWN, 'next'), _defineProperty(_actionMap, KEY_UP, 'prev'), _defineProperty(_actionMap, KEY_HOME, 'first'), _defineProperty(_actionMap, KEY_END, 'last'), _actionMap);

/**
 * `currentFocusedIdx + 1` will not work if the next focusable element
 * is disabled. So, we need to iterate through the following menu items
 * to find one that isn't disabled. If all following elements are disabled,
 * return undefined.
 */
var getNextFocusableElement = function getNextFocusableElement(refs, currentFocusedIdx) {
  while (currentFocusedIdx + 1 < refs.length) {
    var isDisabled = refs[currentFocusedIdx + 1].hasAttribute('disabled');
    if (!isDisabled) {
      return refs[currentFocusedIdx + 1];
    }
    currentFocusedIdx++;
  }
};

/**
 * `currentFocusedIdx - 1` will not work if the prev focusable element
 * is disabled. So, we need to iterate through the previous menu items
 * to find one that isn't disabled. If all previous elements are disabled,
 * return undefined.
 */
var getPrevFocusableElement = function getPrevFocusableElement(refs, currentFocusedIdx) {
  while (currentFocusedIdx > 0) {
    var isDisabled = refs[currentFocusedIdx - 1].hasAttribute('disabled');
    if (!isDisabled) {
      return refs[currentFocusedIdx - 1];
    }
    currentFocusedIdx--;
  }
};
export default function handleFocus(refs, isLayerDisabled, onClose) {
  return function (e) {
    var currentFocusedIdx = refs.findIndex(function (el) {
      var _document$activeEleme;
      return (_document$activeEleme = document.activeElement) === null || _document$activeEleme === void 0 ? void 0 : _document$activeEleme.isSameNode(el);
    });
    if (fg('platform_dst_popup-disable-focuslock')) {
      var _document$activeEleme2;
      // if we use a popup as a nested dropdown, we must prevent the dropdown from closing.
      var isNestedDropdown = !!((_document$activeEleme2 = document.activeElement) !== null && _document$activeEleme2 !== void 0 && _document$activeEleme2.closest("[id^=".concat(PREFIX, "]")));
      if (isLayerDisabled() && isNestedDropdown) {
        if (e.key === KEY_TAB && !e.shiftKey) {
          onClose(e);
        }

        // if it is a nested dropdown and the level of the given dropdown is not the current level,
        // we don't need to have focus on it
        return;
      }
    } else {
      if (isLayerDisabled()) {
        if (e.key === KEY_TAB && !e.shiftKey) {
          onClose(e);
        }

        // if it is a nested dropdown and the level of the given dropdown is not the current level,
        // we don't need to have focus on it
        return;
      }
    }
    var action = actionMap[e.key];
    switch (action) {
      case 'next':
        // Always cancelling the event to prevent scrolling
        e.preventDefault();
        if (currentFocusedIdx < refs.length - 1) {
          var _nextFocusableElement = getNextFocusableElement(refs, currentFocusedIdx);
          _nextFocusableElement === null || _nextFocusableElement === void 0 || _nextFocusableElement.focus();
        } else {
          var firstFocusableElement = getNextFocusableElement(refs, -1);
          firstFocusableElement === null || firstFocusableElement === void 0 || firstFocusableElement.focus();
        }
        break;
      case 'prev':
        // Always cancelling the event to prevent scrolling
        e.preventDefault();
        if (currentFocusedIdx > 0) {
          var _prevFocusableElement = getPrevFocusableElement(refs, currentFocusedIdx);
          _prevFocusableElement === null || _prevFocusableElement === void 0 || _prevFocusableElement.focus();
        } else {
          var lastFocusableElement = getPrevFocusableElement(refs, refs.length);
          lastFocusableElement === null || lastFocusableElement === void 0 || lastFocusableElement.focus();
        }
        break;
      case 'first':
        e.preventDefault();
        // Search for first non-disabled element if first element is disabled
        var nextFocusableElement = getNextFocusableElement(refs, -1);
        nextFocusableElement === null || nextFocusableElement === void 0 || nextFocusableElement.focus();
        break;
      case 'last':
        e.preventDefault();
        // Search for last non-disabled element if last element is disabled
        var prevFocusableElement = getPrevFocusableElement(refs, refs.length);
        prevFocusableElement === null || prevFocusableElement === void 0 || prevFocusableElement.focus();
        break;
      default:
        return;
    }
  };
}