import { Line } from '../types';

export const COMMENT_PERMALINK_MATCHER = /^comment-/;
export const SIDEBAR_EXPANDER_HASH_MATCHER = /^sidebar-/;
export const LINE_PERMALINK_MATCHER = /^L|^chg_/;
export const SIDE_BY_SIDE_LINE_PERMALINK_MATCHER = /^chg_/;
export const FILEPATH_PERMALINK_MATCHER = /^chg-/;

enum PermalinkLineTypes {
  OldLine = 'oldline',
  NewLine = 'newline',
}

export const createUnifiedLinePermalink = (
  line: Line,
  filePath: string
): string => {
  return `L${filePath}${line.oldLine ? `F${line.oldLine}` : ''}${
    line.newLine ? `T${line.newLine}` : ''
  }`;
};

export const createSideBySideLinePermalink = (
  line: Line,
  filePath: string,
  hideNewLines?: boolean
): string => {
  const lineType = hideNewLines
    ? PermalinkLineTypes.OldLine
    : PermalinkLineTypes.NewLine;
  const lineNumber = hideNewLines ? line.oldLine : line.newLine;
  return `chg_${filePath}_${lineType}${lineNumber}`;
};

export const createDiffPermalink = (filepath: string): string =>
  `chg-${filepath}`;

type FilepathGetter = (permalink: string) => undefined | string;

const parseHrefToFilepathWithRegEx = (regex: RegExp) => (permalink: string) =>
  regex.exec(permalink)?.groups?.filepath;

const patterns: FilepathGetter[] = [
  parseHrefToFilepathWithRegEx(
    /L(?<filepath>.*?)(F(?<fromNumber>\d+))?(T(?<toNumber>\d+))?$/
  ),
  parseHrefToFilepathWithRegEx(
    /chg_(?<filepath>.*)_(?<lineType>oldline|newline)(?<lineNumber>\d+)$/
  ),
  parseHrefToFilepathWithRegEx(/chg-(?<filepath>.*)$/),
];

export const getFilepathFromPermalink = (permalink = ''): undefined | string =>
  patterns
    .map(pattern => pattern(permalink))
    .find(matchResult => !!matchResult);

export const getPermalink = (): string | undefined => {
  try {
    const hash = decodeURIComponent(window.location.hash);
    return hash.slice(1);
  } catch (e) {
    // URIError: malformed URI sequence
    return undefined;
  }
};

export const getDiffRange = (): string | undefined => {
  try {
    const url = decodeURIComponent(window.location.href);
    const diffRangeRegex = /\/([0-9a-f]{7,40})\.\.([0-9a-f]{7,40})/;
    const diffRange = url.match(diffRangeRegex);
    if (diffRange) {
      return diffRange[0];
    }
    return undefined;
  } catch (e) {
    // URIError: malformed URI sequence
    return undefined;
  }
};

const urlEncodeWhitespace = (permalink: string) =>
  permalink
    .split('')
    .map(chr => (['\t', '\n'].includes(chr) ? encodeURIComponent(chr) : chr))
    .join('');

type PushHistory = {
  location: { hash: string };
  push: (location: { hash: string }) => void;
};

export const updateHashInURL = (
  activePermalink: string,
  history: PushHistory
): void => {
  // Prevent the browser from doing additional scrolling by
  // updating the URL in a way that doens't trigger a hashchange
  // event but still allows the back & forward button to work.
  const newPermalink = urlEncodeWhitespace(activePermalink);

  history.push({
    ...history.location,
    hash: newPermalink,
  });
};

export const isSameCommentPermalink = (commentId: string | number): boolean => {
  const newPermalink = `comment-${commentId}`;
  const currentPermalink = getPermalink();
  return newPermalink === currentPermalink;
};
