import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  haltedMessageText: {
    id: 'frontbucket.pipelines.components.haltedMessage.text',
    description: 'The text of the halted pipeline message',
    defaultMessage:
      'This pipeline was halted because {linkOne} was deploying to {linkTwo}.',
  },

  haltedMessageLink: {
    id: 'frontbucket.pipelines.components.haltedMessage.link',
    description: 'The link of the halted pipeline message',
    defaultMessage: 'another pipeline',
  },
  haltedMessageLearnMore: {
    id: 'frontbucket.pipelines.components.haltedMessage.learnMore',
    description: 'Learn more of the halted pipeline message',
    defaultMessage: 'Learn more',
  },
  manualHaltedStep: {
    id: 'frontbucket.pipelines.components.haltedMessage.manual',
    description: 'Manual halted step message',
    defaultMessage:
      ' A change request is pending for this deployment. The change request must be completed before deploying to this environment.',
  },
  automaticalHaltedStep: {
    id: 'frontbucket.pipelines.components.haltedMessage.automatical.halted',
    description: 'Automatical halted step message',
    defaultMessage:
      'A change request is pending for this deployment. When the change request is complete, the deployment will automatically resume.',
  },
  automaticalPausedMessage: {
    id: 'frontbucket.pipelines.components.haltedMessage.automatical.paused',
    description: 'Automatical paused message',
    defaultMessage:
      'This pipeline was automatically paused, and can be manually resumed. {reason} {isDeploymentConcurrencyReason}',
  },

  deploymentGateFailedReason: {
    id: 'frontbucket.pipelines.components.haltedMessage.deploymentGate.failed',
    description: 'Deployment gate failed reason message',
    defaultMessage:
      'This pipeline was halted because something went wrong when creating a change request in Jira. Resume the step to try again.',
  },
  branchHasNoDeploymentPermissionsReason: {
    id: 'frontbucket.pipelines.components.haltedMessage.branch.noDeployment.permissions',
    description: 'Branch has no deployment permissions reason',
    defaultMessage: 'This pipeline was halted because {link}  {text}',
  },
  noDeployedToFromSourceBranch: {
    id: 'frontbucket.pipelines.components.haltedMeage.deployedToFrom.sourceBranch',
    description: 'Can not deployed to from source branch message',
    defaultMessage: 'can not be deployed to from the {sourceBranch} branch.',
  },
  deployedToFromPermittedBranch: {
    id: 'frontbucket.pipelines.components.haltedMessage.deployedToFrom.permittedBranch',
    description: 'Deployed to from permitted branch message',
    defaultMessage: 'can only be deployed to from permitted branch pipelines.',
  },
  userHasNoDeploymentPermissionsReason: {
    id: 'frontbucket.pipelines.components.haltedMessage.user.noDeployment.permissions',
    description: 'User has no deployment permissions reason',
    defaultMessage:
      'This pipeline was halted because {linkOne} tried to deploy, but only an admin can deploy to {linkTwo}.',
  },
  allowanceExceededReasonPartOne: {
    id: 'frontbucket.pipelines.components.haltedMessage.allowanceExceeded.reasonOne',
    description: 'allowance exceeded reason message part one',
    defaultMessage:
      'This pipeline was halted because you’ve reached your monthly minutes quota.',
  },
  allowanceExceededReasonPartTwo: {
    id: 'frontbucket.pipelines.components.haltedMessage.allowanceExceeded.reasonTwo',
    description: 'allowance exceeded reason message part two',
    defaultMessage:
      'You won’t be able to run new steps until next month or unless you update your quota.',
  },
  viewPlanDetails: {
    id: 'frontbucket.pipelines.components.haltedMessage.plan.details',
    description: 'View plan details message',
    defaultMessage: 'View plan details',
  },
});
