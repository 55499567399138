import React, { ComponentType, useCallback, useMemo, useRef } from 'react';

import { captureException } from '@sentry/browser';
import {
  Action,
  createActionsHook,
  createContainer,
  createHook,
  createStore,
} from 'react-sweet-state';

import { useAnalytics } from 'src/components/settings/analytics';
import { Privilege } from 'src/components/types';
import { CreateInvitationStatus } from 'src/components/types/src/api/internal/invitation';
import { DEBOUNCE_DELAY } from 'src/constants/settings';
import { useFlag } from 'src/hooks/flag';
import { useIntl } from 'src/hooks/intl';
import { debounceSyncPromise } from 'src/utils/async';

import {
  BAD_REQUEST_ERROR,
  DOMAIN_RESTRICTION_ERROR,
  INVITE_FAILED_ERROR,
  LoadingState,
  NON_ADMIN_RESTRICTION_ERROR,
} from './constants';
import { ModalKeyType, ModalProps } from './modals';
import messages from './shared.i18n';
import {
  AccessCheck,
  AccessLevelFilter,
  AccessMode,
  CreateInvitation,
  FlatEntity,
  FlatPrincipal,
  GetPermissions,
  NotUpdatedItems,
  Permission,
  PermissionsAPI,
  PermissionsState,
  PrivilegeFilter,
  SearchUsersAndGroups,
  isEditableProps,
} from './types';
import {
  createNonce,
  extractNamesFromList,
  getAddUsersErrors,
  getPermissionId,
  getSetAllPermissions,
  getSetPermissions,
  includesPermission,
  isPermissionEditable,
  resetPermissions,
  selectAllPermissions,
} from './utils';

// actions

const setStoreState =
  (
    state:
      | Partial<PermissionsState>
      | ((s: PermissionsState) => Partial<PermissionsState>)
  ): Action<PermissionsState> =>
  ({ setState, getState }) => {
    setState(typeof state === 'function' ? state(getState()) : state);
  };

const setActiveModal =
  <T extends ModalKeyType>(
    type?: T,
    props?: ModalProps[T]
  ): Action<PermissionsState> =>
  ({ setState }) => {
    setState({ activeModal: type ? { type, props } : undefined });
  };

const setIsChecked =
  (permission: Permission, isChecked?: boolean): Action<PermissionsState> =>
  ({ setState, getState }) => {
    const s = getState();
    const checked =
      isChecked === undefined
        ? includesPermission(s.checkedRows, permission)
        : isChecked;
    setState({
      ...s,
      checkedRows: checked
        ? s.checkedRows.filter(
            p => getPermissionId(permission) !== getPermissionId(p)
          )
        : [...s.checkedRows, permission],
    });
  };

const selectAll =
  (shouldSelectAll?: boolean): Action<PermissionsState> =>
  ({ setState, getState }) => {
    const s = getState();
    setState({
      checkedRows: selectAllPermissions(
        s.permissions,
        s.checkedRows,
        s.page,
        s.context,
        shouldSelectAll
      ),
    });
  };

export const isEditable =
  ({
    level,
    privilege,
  }: isEditableProps): Action<PermissionsState, void, boolean> =>
  ({ getState }) =>
    isPermissionEditable(getState().context, level, privilege);

// store

const initialState: PermissionsState = {
  loadingState: LoadingState.IDLE,
  context: 'project',
  permissions: new Map(),
  privilegeFilter: null,
  accessLevelFilter: null,
  checkedRows: [],
  limit: 20,
  page: 1,
  total: 0,
};

const PermissionsStore = createStore({
  initialState,
  actions: {
    setState: setStoreState,
    setActiveModal,
    setIsChecked,
    selectAll,
    isEditable,
  },
});

export type PermissionsContainerValue = Pick<PermissionsState, 'context'> &
  Partial<Omit<PermissionsState, 'context'>>;

export type PermissionsContainerProps = {
  value: PermissionsContainerValue;
  onCleanup?: (state: Readonly<PermissionsState>) => void;
};

export const PermissionsContainer = createContainer(PermissionsStore, {
  onInit:
    () =>
    ({ setState }, { value }: PermissionsContainerProps) => {
      setState({ ...value });
    },
  onUpdate:
    () =>
    ({ setState }, { value }: PermissionsContainerProps) => {
      setState({ ...value });
    },
  onCleanup:
    () =>
    ({ getState }, { onCleanup }: PermissionsContainerProps) => {
      if (typeof onCleanup === 'function') {
        onCleanup(getState());
      }
    },
});

export type PermissionsContainerComponentProps = PermissionsContainerProps & {
  Component: ComponentType<{ api: PermissionsAPI }>;
};

export type PermissionsContainerComponent =
  ComponentType<PermissionsContainerComponentProps>;

export const makeWithPermissions =
  (Container: PermissionsContainerComponent) =>
  (
    Component: PermissionsContainerComponentProps['Component'],
    value: PermissionsContainerValue
  ) =>
  () =>
    <Container Component={Component} value={value} />;

// hooks
export const useActions = createActionsHook(PermissionsStore);

export const usePermissions = createHook(PermissionsStore, {
  selector: ({ permissions }) => ({ permissions }),
});

export const useIsEditable = createHook(PermissionsStore, {
  selector: ({ context }, { level, privilege }: isEditableProps) =>
    isPermissionEditable(context, level, privilege),
});

export const useIsSumEnabled = createHook(PermissionsStore, {
  selector: ({ sumEnabled }) => !!sumEnabled,
});

export const useIsOrgAdmin = createHook(PermissionsStore, {
  selector: ({ orgAdmin }) => !!orgAdmin,
});

export const useWorkspaceOrgId = createHook(PermissionsStore, {
  selector: ({ workspaceOrgId }) => workspaceOrgId,
});

export const useFilters = createHook(PermissionsStore, {
  selector: ({
    limit,
    page,
    total,
    privilegeFilter,
    accessLevelFilter,
    searchTerm,
  }) => ({
    limit,
    page,
    total,
    privilegeFilter,
    accessLevelFilter,
    searchTerm,
  }),
});

export const useLoadingState = createHook(PermissionsStore, {
  selector: ({ loadingState }) => ({ loadingState }),
});

export const useActivePermission = createHook(PermissionsStore, {
  selector: ({ activePermission }) => ({ activePermission }),
});

export const useCheckedRows = createHook(PermissionsStore, {
  selector: ({ checkedRows }) => ({ checkedRows }),
});

export const useActiveModal = createHook(PermissionsStore, {
  selector: ({ activeModal }) => ({ activeModal }),
});

export const usePermissionsContext = createHook(PermissionsStore, {
  selector: ({ context }) => ({ context }),
});

export const useAdminLevel = createHook(PermissionsStore, {
  selector: ({ adminLevel }) => ({
    adminLevel,
    isWorkspaceAdmin: adminLevel === 'workspace',
    isProjectAdmin: adminLevel === 'project',
    isRepoAdmin: adminLevel === 'repository',
  }),
});

export const useHandleError = () => {
  const { showFlag, showErrorFlag } = useFlag({ enableForPdvs: true });
  const { setState } = useActions();
  return useCallback(
    (e: any) => {
      setState({ loadingState: LoadingState.ERROR });
      if (Array.isArray(e)) {
        e.forEach(({ id, description, title }) => {
          showFlag({
            id,
            iconType: 'error',
            description,
            title,
          });
        });
        return;
      }
      captureException(e);
      showErrorFlag(e?.message || e?.error?.message);
    },
    [showErrorFlag, showFlag, setState]
  );
};

export const useAccessCheck = (accessCheck: AccessCheck) => {
  return async function userAccessCheck() {
    try {
      await accessCheck();
    } catch (errorResponse) {
      if (errorResponse.status === 403) {
        window.location.reload();
      }
    }
  };
};

export const useAddUsersByEmail = (
  createInvitation: CreateInvitation,
  refreshInvitations: () => void
) => {
  const { formatMessage } = useIntl();
  const { showFlag } = useFlag({ enableForPdvs: true });
  const { setState } = useActions();
  const handleError = useHandleError();

  return useCallback(
    async (
      usersByEmail: Array<FlatEntity & { isNew?: boolean }>,
      privilege: Privilege
    ) => {
      const notUpdated: NotUpdatedItems = [];
      const successfullyAdded: FlatEntity[] = [];
      const successfullyInvited: FlatEntity[] = [];

      if (!usersByEmail.length) {
        return { hasEmailInviteError: false, successfullyAdded };
      }

      try {
        const inviteStatuses = await createInvitation({
          emails: usersByEmail.map(({ name }) => name),
          permission: privilege,
        });
        if (!inviteStatuses) {
          throw new Error(INVITE_FAILED_ERROR);
        }
        Object.entries(inviteStatuses).forEach(([email, { status, error }]) => {
          const user = usersByEmail.find(({ name }) => name === email)!;
          if (status === CreateInvitationStatus.invited) {
            successfullyInvited.push(user);
          } else if (status === CreateInvitationStatus.added) {
            successfullyAdded.push(user);
          } else if (error?.includes('NON_ADMIN_RESTRICTION')) {
            notUpdated.push({
              id: user.id,
              errorMessage: NON_ADMIN_RESTRICTION_ERROR,
              name: user.name,
            });
          } else if (error?.includes('DOMAIN_RESTRICTION')) {
            notUpdated.push({
              id: user.id,
              errorMessage: DOMAIN_RESTRICTION_ERROR,
              name: user.name,
            });
          } else {
            notUpdated.push({
              id: user.id,
              errorMessage: INVITE_FAILED_ERROR,
              name: user.name,
            });
          }
        });
      } catch (e) {
        captureException(e);
        // if createInvitation() fails, add all users invites to notUpdated
        // This is mostly for backwards compatibility so not to refactor this
        // code too much. Ideally we would improve this flow.
        usersByEmail.forEach(({ id, name }) => {
          notUpdated.push({
            id,
            errorMessage: INVITE_FAILED_ERROR,
            name,
          });
        });
      }

      // only show the flag if all invitations were successful
      if (successfullyInvited.length && !notUpdated.length) {
        refreshInvitations();
        showFlag({
          id: 'invites-success-flag',
          iconType: 'success',
          description: formatMessage(messages.invitationsSentDescription, {
            entity: successfullyInvited.map(entity => entity.name).join(', '),
            count: successfullyInvited.length,
          }),
          title: formatMessage(messages.invitationsSent),
        });
      }

      if (notUpdated.length) {
        const errors = getAddUsersErrors(notUpdated, formatMessage);
        const nonAdminErrors = errors.reduce(
          (arr, { id, description }) =>
            id === 'non-admin-failure' ? arr.concat(description) : arr,
          [] as string[]
        );
        const domainErrors = errors.reduce(
          (arr, { id, description }) =>
            id === 'domain-restriction-failure' ? arr.concat(description) : arr,
          [] as string[]
        );
        if (nonAdminErrors.length || domainErrors.length) {
          setState({
            activeModal: {
              type: 'PartialInvitationMessageModal',
              props: {
                successfullyAdded: successfullyAdded.map(entity => entity.name),
                successfullyInvited: successfullyInvited.map(
                  entity => entity.name
                ),
                nonAdminErrors,
                domainErrors,
              },
            },
          });
        } else {
          handleError(errors);
        }
        return { hasEmailInviteError: true, successfullyAdded };
      }
      return { hasEmailInviteError: false, successfullyAdded };
    },
    [
      setState,
      formatMessage,
      handleError,
      showFlag,
      createInvitation,
      refreshInvitations,
    ]
  );
};

export const useRemoveAccess = (api: PermissionsAPI) => {
  const { formatMessage } = useIntl();
  const { publishTrackEvent } = useAnalytics();
  const { showSuccessFlag } = useFlag({ enableForPdvs: true });
  const { setState } = useActions();
  const handleError = useHandleError();
  const accessCheck = useAccessCheck(api.accessCheck);

  return async (...permissions: Permission[]) => {
    setState({ loadingState: LoadingState.MODAL_LOADING });
    try {
      await api.updateAccess({
        privilege: 'none',
        principals: (Array.isArray(permissions)
          ? permissions
          : [permissions]
        ).map(({ id, mode }) =>
          mode === AccessMode.users
            ? { account_id: id }
            : { slug: id.split('/')[1] }
        ),
      });
      setState(s => {
        const nextPerms = resetPermissions(
          s.permissions,
          s.limit,
          p => !!p && !includesPermission(permissions, p)
        );
        return {
          loadingState: LoadingState.IDLE,
          permissions: nextPerms,
          checkedRows: s.checkedRows.filter(
            p => !includesPermission(permissions, p)
          ),
          page: Math.max(Math.min(s.page, nextPerms.size), 1),
          total: Math.max(s.total - permissions.length, 0),
        };
      });
      showSuccessFlag(
        formatMessage(messages.accessRemovedDescription, {
          entity: permissions.map(item => item.name).join('", "'),
          count: permissions.length,
        }),
        messages.accessRemovedTitle
      );
      publishTrackEvent({
        action: 'removeUserOrGroupPrivileges',
        attributes: {
          users: permissions.filter(item => item.mode === AccessMode.users)
            .length,
          groups: permissions.filter(item => item.mode === AccessMode.groups)
            .length,
        },
      });
      accessCheck();
    } catch (e) {
      handleError(e);
    }
  };
};

export const useUpdateAccess = (api: PermissionsAPI) => {
  const { formatMessage } = useIntl();
  const { publishTrackEvent } = useAnalytics();
  const { showSuccessFlag } = useFlag({ enableForPdvs: true });
  const { setState } = useActions();
  const handleError = useHandleError();
  const accessCheck = useAccessCheck(api.accessCheck);

  return async (
    privilege: Privilege,
    permissions: Permission | Permission[]
  ) => {
    setState({
      loadingState: Array.isArray(permissions)
        ? LoadingState.MODAL_LOADING
        : LoadingState.PRIVILEGE_UPDATING,
      activePermission: Array.isArray(permissions) ? undefined : permissions,
    });
    try {
      await api.updateAccess({
        privilege,
        principals: ([] as Permission[])
          .concat(permissions)
          .map(({ id, mode }) =>
            mode === AccessMode.users
              ? { account_id: id }
              : { slug: id.split('/')[1] }
          ),
      });

      setState(s => ({
        loadingState: LoadingState.IDLE,
        permissions: getSetAllPermissions(s.permissions, perms =>
          perms.map(p =>
            !!p &&
            ((Array.isArray(permissions) &&
              includesPermission(permissions, p)) ||
              permissions === p)
              ? { ...p, privilege }
              : p
          )
        ),
        activePermission: undefined,
        activeModal: undefined,
      }));
      const updateCount = Array.isArray(permissions) ? permissions.length : 1;
      showSuccessFlag(
        formatMessage(messages.accessChangedDescription, {
          entity: Array.isArray(permissions)
            ? permissions.map(item => item.name).join('", "')
            : permissions.name,
          count: updateCount,
        }),
        messages.accessChangedTitle
      );
      const perms = ([] as Permission[]).concat(permissions);
      publishTrackEvent({
        action: 'updateUserOrGroupPrivileges',
        attributes: {
          users: perms.filter(item => item.mode === AccessMode.users).length,
          groups: perms.filter(item => item.mode === AccessMode.groups).length,
          privilege,
        },
      });
      accessCheck();
    } catch (e) {
      handleError(e);
    }
  };
};

export const useSearchUsersAndGroups = (
  searchUsersAndGroups: SearchUsersAndGroups
) => {
  const handleError = useHandleError();
  return useMemo(
    () =>
      debounceSyncPromise(async (searchTerm: string) => {
        try {
          return (
            (await searchUsersAndGroups(searchTerm)).filter(
              v => !!v
            ) as FlatPrincipal[]
          ).sort((a, b) => a.name.localeCompare(b.name));
        } catch (e) {
          handleError(e);
          return [] as FlatPrincipal[];
        }
      }, DEBOUNCE_DELAY) as (searchTerm: string) => Promise<FlatPrincipal[]>,
    [searchUsersAndGroups, handleError]
  );
};

export const useAddUsersAndGroups = (
  api: PermissionsAPI,
  refreshInvitations: () => void
) => {
  const { formatMessage } = useIntl();
  const { publishTrackEvent } = useAnalytics();
  const { showSuccessFlag } = useFlag({ enableForPdvs: true });
  const { setState } = useActions();
  const handleError = useHandleError();
  const [{ accessLevelFilter, privilegeFilter, searchTerm, page }] =
    useFilters();
  const { addAccess, getPermissions, createInvitation } = api;
  const addUsersByEmail = useAddUsersByEmail(
    createInvitation,
    refreshInvitations
  );

  return useCallback(
    async (
      list: Array<FlatEntity & { isNew?: boolean }>,
      privilege: Privilege
    ) => {
      setState({
        loadingState: LoadingState.MODAL_LOADING,
      });

      const principals = list.filter(entity => !entity.isNew);
      const usersByEmail = list.filter(entity => !!entity.isNew);

      let addedPrincipals: FlatEntity[] = [];
      let hasPrincipalAddError = false;
      const { hasEmailInviteError, successfullyAdded: addedByEmail } =
        await addUsersByEmail(usersByEmail, privilege);

      if (principals.length) {
        try {
          await addAccess({
            principals: principals.map(entity =>
              entity.mode === AccessMode.users
                ? { account_id: entity.id }
                : { slug: entity.id.split('/')[1] }
            ),
            privilege,
          });
          addedPrincipals = principals;
        } catch (e) {
          hasPrincipalAddError = true;
          if (e.status === 400 || e.status === 422) {
            let problemEntities: Array<{
              id: string;
              name: string;
              errorMessage: string;
            }>;
            // Surface special error in case of personal workspace owner
            if (e.status === 422) {
              problemEntities = list
                .filter(entity =>
                  e?.error?.fields?.account_id?.includes(entity.id)
                )
                .map(entity => ({
                  id: entity.id,
                  name: entity.name,
                  errorMessage: formatMessage(
                    messages.personalWorkspaceException,
                    { entity: entity.name }
                  ),
                }));
            } else {
              problemEntities = list
                .filter(entity =>
                  e?.error?.fields?.account_id?.includes(entity.id)
                )
                .map(entity => ({
                  id: entity.id,
                  name: entity.name,
                  errorMessage: BAD_REQUEST_ERROR,
                }));
            }

            const errors = getAddUsersErrors(problemEntities, formatMessage);
            handleError(errors.length ? errors : e);
          } else {
            handleError(e);
          }
        }
      }

      if (addedPrincipals.length || addedByEmail.length) {
        try {
          const state = await getPermissions({
            page,
            principalName: searchTerm,
            accessLevel: accessLevelFilter || undefined,
            permission:
              privilegeFilter === 'none' || !privilegeFilter
                ? undefined
                : privilegeFilter,
          });
          setState({
            ...state,
            activeModal: undefined,
            activePermission: undefined,
            loadingState: LoadingState.IDLE,
          });
        } catch (e) {
          handleError(e);
        }

        const addCount = principals.length + addedByEmail.length;

        showSuccessFlag(
          formatMessage(messages.accessGrantedDescription, {
            entity: extractNamesFromList([...addedByEmail, ...principals]),
            count: addCount,
          }),
          messages.accessGrantedTitle
        );

        const perms = [...principals, ...addedByEmail];
        publishTrackEvent({
          action: 'addUsersAndGroups',
          attributes: {
            users: perms.filter(item => item.mode === AccessMode.users).length,
            groups: perms.filter(item => item.mode === AccessMode.groups)
              .length,
            privilege,
          },
        });
      } else {
        setState(s => ({
          loadingState: LoadingState.IDLE,
          // keep the modal open if there are any errors.
          activeModal:
            hasEmailInviteError || hasPrincipalAddError
              ? s.activeModal
              : undefined,
        }));
      }
    },
    [
      addUsersByEmail,
      addAccess,
      getPermissions,
      formatMessage,
      showSuccessFlag,
      publishTrackEvent,
      handleError,
      setState,
      accessLevelFilter,
      privilegeFilter,
      searchTerm,
      page,
    ]
  );
};

export const useGetPermissions = (getPermissions: GetPermissions) => {
  const { setState } = useActions();
  const handleError = useHandleError();
  const nonceRef = useRef<string>();
  const [{ permissions }] = usePermissions();
  const [filters] = useFilters();
  const permissionsRef = useRef(permissions);
  const filtersRef = useRef(filters);
  const previousTotalRef = useRef(filters.total);
  const previousPrivilegeFilter = useRef<PrivilegeFilter | null>(
    filters.privilegeFilter
  );
  const previousAccessLevelFilter = useRef<AccessLevelFilter | null>(
    filters.accessLevelFilter
  );
  const previousSearchTerm = useRef<string | undefined>(filters.searchTerm);
  // using refs here so we can memoize the callback
  // the callback is used by a search debounce function
  // memoizing maintains the debounce timeline
  permissionsRef.current = permissions;
  filtersRef.current = filters;

  const totalUnchanged = useCallback(
    (total = filtersRef.current.total) =>
      previousTotalRef.current === 0 || previousTotalRef.current === total,
    [previousTotalRef, filtersRef]
  );

  return useCallback(
    async (
      page: number,
      {
        searchTerm = filtersRef.current.searchTerm,
        privilegeFilter = filtersRef.current.privilegeFilter,
        accessLevelFilter = filtersRef.current.accessLevelFilter,
      }: Partial<
        Pick<
          PermissionsState,
          'searchTerm' | 'privilegeFilter' | 'accessLevelFilter'
        >
      > = {}
    ) => {
      const filtersChanged = () =>
        previousPrivilegeFilter.current !== privilegeFilter ||
        previousAccessLevelFilter.current !== accessLevelFilter ||
        previousSearchTerm.current !== searchTerm;

      if (
        totalUnchanged() &&
        permissionsRef.current.has(page) &&
        !filtersChanged()
      ) {
        setState({ page });
        return;
      }

      previousPrivilegeFilter.current = privilegeFilter;
      previousAccessLevelFilter.current = accessLevelFilter;
      previousSearchTerm.current = searchTerm;
      previousTotalRef.current = filtersRef.current.total;

      setState({
        loadingState: LoadingState.LOADING,
        accessLevelFilter,
        privilegeFilter,
        searchTerm,
      });
      try {
        const nonce = createNonce();
        nonceRef.current = nonce;
        const state = await getPermissions({
          page,
          principalName: searchTerm,
          accessLevel: accessLevelFilter || undefined,
          permission:
            privilegeFilter === 'none' || !privilegeFilter
              ? undefined
              : privilegeFilter,
        });
        // ensure we update only last request
        if (nonce === nonceRef.current) {
          setState({
            ...state,
            permissions: getSetPermissions(
              permissionsRef.current,
              page,
              () => state?.permissions.get(page) || [],
              !totalUnchanged(state?.total) || filtersChanged()
            ),
            loadingState: LoadingState.IDLE,
          });
        }
      } catch (e) {
        handleError(e);
      }
    },
    [
      setState,
      handleError,
      nonceRef,
      permissionsRef,
      filtersRef,
      getPermissions,
      previousPrivilegeFilter,
      previousAccessLevelFilter,
      previousSearchTerm,
      previousTotalRef,
      totalUnchanged,
    ]
  );
};
