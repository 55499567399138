import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';

const PipelinesWorkspaceDynamicPipelinesPageLoadable = Loadable({
  loading: LoadingPage,
  loader: () => import('./dynamic-pipelines'),
});

export default PipelinesWorkspaceDynamicPipelinesPageLoadable;
