"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ClientVisibilityState;
(function (ClientVisibilityState) {
    ClientVisibilityState["VISIBLE"] = "visible";
    ClientVisibilityState["HIDDEN"] = "hidden";
    ClientVisibilityState["PRERENDER"] = "prerender";
})(ClientVisibilityState = exports.ClientVisibilityState || (exports.ClientVisibilityState = {}));
var ClientCauseReason;
(function (ClientCauseReason) {
    ClientCauseReason["INITIALIZATION"] = "initialization";
    ClientCauseReason["POLLING"] = "polling";
    ClientCauseReason["RETRY"] = "retry";
})(ClientCauseReason = exports.ClientCauseReason || (exports.ClientCauseReason = {}));
var ClientStorageState;
(function (ClientStorageState) {
    ClientStorageState["AVAILABLE"] = "available";
    ClientStorageState["NOT_AVAILABLE"] = "notAvailable";
    ClientStorageState["FULL"] = "full";
    ClientStorageState["ERRORED"] = "errored";
})(ClientStorageState = exports.ClientStorageState || (exports.ClientStorageState = {}));
var ClientUserState;
(function (ClientUserState) {
    ClientUserState["NEW"] = "new";
    ClientUserState["SAME"] = "same";
    ClientUserState["SWITCHED"] = "switched";
})(ClientUserState = exports.ClientUserState || (exports.ClientUserState = {}));
