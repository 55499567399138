import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';

const PipelinesWorkspaceRunnersPageLoadable = Loadable({
  loading: LoadingPage,
  loader: () => import('./runners'),
});

export default PipelinesWorkspaceRunnersPageLoadable;
