/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { call, select, take } from 'redux-saga/effects';

import { User } from 'src/components/types';
import {
  FetchRecentlyViewedRepository,
  RecentlyViewedRepositoriesWithWorkspace,
  RecentlyViewedRepositories,
} from 'src/redux/recently-viewed-repositories';
import { getRecentlyViewedRepositoriesKeys as getRecentlyViewedRepositoryUuids } from 'src/selectors/state-slicing-selectors';
import { getCurrentUser } from 'src/selectors/user-selectors';
import { getCurrentWorkspaceUUID } from 'src/selectors/workspace-selectors';
import storeWithWorkspace from 'src/utils/recently-viewed-repositories-store';

function* removeRecentlyViewedRepository() {
  while (true) {
    // @ts-ignore
    const action = yield take(FetchRecentlyViewedRepository.ERROR);
    const currentUser: User = yield select(getCurrentUser);

    if (!currentUser) {
      continue;
    }

    const { status } = action.meta;
    if (status === 404 || status === 403) {
      /**
       * By the time this saga runs, the reducer (in `src/redux/recently-viewed-repositories/index.ts`) has already
       * updated the data in redux to reflect the correct set of repositories based on the
       * `FetchRecentlyViewedRepository.ERROR` action. That way the removal logic and the source of truth are
       * consolidated in one spot (the reducer)
       *
       * We retrieve the entity keys directly instead of denormalizing because we might have some in-flight
       * requests where the repository entity doesn't exist quite yet, and we don't want to remove
       * those from localstorage. Also it's a bit faster to skip denormalization.
       */
      const recentlyViewedRepositoryUuids: RecentlyViewedRepositories =
        yield select(getRecentlyViewedRepositoryUuids);

      const workspaceUuid: string = yield select(getCurrentWorkspaceUUID);
      const recentlyViewedRepositoriesStore: RecentlyViewedRepositoriesWithWorkspace =
        yield call(storeWithWorkspace.get, currentUser);
      const { workspaceUuids } = recentlyViewedRepositoriesStore;
      const recentRepositoryUuidsStore = workspaceUuids[workspaceUuid];
      if (!recentRepositoryUuidsStore) {
        return;
      }

      /* 
        We return the intersection of the two arrays (redux repo uuids and local store repo uuids) since
        we only want to update recent repo uuids for a given workspace.

        `recentlyViewedRepositoryUuids` will be an array of uuids that may or may not include repo uuids
        across many workspaces but is also the source of truth with the correct set of repo uuids.

        `recentRepositoryUuidsStore` will be an array of repo uuids from local storage that is scoped to
        a workspace but may not be accurately updated for reasons such as the repository was deleted.

        We filter out repo uuids outside the scope of the given workspace and also filter out repo
        uuids that should be removed from local storage. See `recently-viewed-repositories-store.ts`
        for context on how recently-viewed-repositories local storage changed.
        */

      const filteredRepositoryUuids = recentlyViewedRepositoryUuids.filter(
        (uuid: string) => recentRepositoryUuidsStore.includes(uuid)
      );
      workspaceUuids[workspaceUuid] = filteredRepositoryUuids;
      yield call(storeWithWorkspace.set, currentUser, { workspaceUuids });
    }
  }
}

export default removeRecentlyViewedRepository;
