import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';

export const PipelinesRepoRunnersPageLoadable = Loadable({
  loading: LoadingPage,
  loader: () => import('./runners'),
});

export default PipelinesRepoRunnersPageLoadable;
