import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';

export const ProjectSettingsBranchingModelLoadable = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      /* webpackChunkName: "project-settings-branching-model" */ './branching-model'
    ),
});
