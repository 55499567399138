import React from 'react';

import { FormattedMessage } from 'react-intl-next';

import { AutoDismissFlag } from '@atlaskit/flag';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { ComponentFlagId, HandleDismissedType } from 'src/redux/flags/types';
import messages from 'src/sections/repository/sections/pull-request/components/header-actions/review-actions.i18n';
import globalUrls from 'src/urls/global';

type Props = {
  id: ComponentFlagId;
  props: {
    errorMessage: string;
  };
} & HandleDismissedType;

const generalErrorMessage = (
  <FormattedMessage
    {...messages.generalErrorDescription}
    values={{
      supportLink: (
        <a href={globalUrls.external.support}>
          <FormattedMessage {...messages.supportLink} />
        </a>
      ),
    }}
  />
);

export const PullRequestReviewDiscardErrorFlag = ({
  id,
  ...otherProps
}: Props): JSX.Element => {
  return (
    <AutoDismissFlag
      {...otherProps}
      title={<FormattedMessage {...messages.errorDiscardTitle} />}
      description={generalErrorMessage}
      icon={
        <ErrorIcon
          label="info"
          primaryColor={token('color.icon.danger', colors.R500)}
        />
      }
      id={id}
    />
  );
};

export const PullRequestReviewSubmitErrorFlag = ({
  id,
  props,
  ...otherProps
}: Props): JSX.Element => {
  return (
    <AutoDismissFlag
      {...otherProps}
      title={<FormattedMessage {...messages.errorSubmitTitle} />}
      description={props.errorMessage || generalErrorMessage}
      icon={
        <ErrorIcon
          label="info"
          primaryColor={token('color.icon.danger', colors.R500)}
        />
      }
      id={id}
    />
  );
};
