export var isValidLayout = function isValidLayout(name) {
  return !!name && ['default', 'wide', 'full-width'].includes(name);
};
export var getExtensionAttrs = function getExtensionAttrs(dom) {
  var isInline = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var extensionType = dom.getAttribute('data-extension-type');
  var extensionKey = dom.getAttribute('data-extension-key');
  if (!extensionType || !extensionKey) {
    return false;
  }
  var attrs = {
    extensionType: extensionType,
    extensionKey: extensionKey,
    text: dom.getAttribute('data-text') || undefined,
    parameters: JSON.parse(dom.getAttribute('data-parameters') || '{}'),
    localId: dom.getAttribute('data-local-id') || undefined
  };
  if (!isInline) {
    var rawLayout = dom.getAttribute('data-layout');
    attrs.layout = isValidLayout(rawLayout) ? rawLayout : 'default';
  }
  return attrs;
};