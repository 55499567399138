/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { Cache } from 'src/components/pipelines/models';
import { Repository } from 'src/components/types';
import { getCurrentRepository } from 'src/selectors/repository-selectors';
import authRequest from 'src/utils/fetch';

import envBaseUrl from '../../utils/env-base-url';
import {
  capturePipelinesExceptionWithTags,
  createErrorMessage,
} from '../../utils/sentry';
import {
  REQUEST_CACHES,
  REQUEST_DELETE_CACHE,
  REQUEST_DELETE_CACHES,
} from '../actions/pipelines';

export const getCachesUrl = (
  repositoryFullSlug: string,
  cacheUuid = ''
): string => {
  return `${envBaseUrl(
    '/!api/2.0'
  )}/repositories/${repositoryFullSlug}/pipelines-config/caches/${cacheUuid}`;
};

export const deleteCachesByNameUrl = (
  repositoryFullSlug: string,
  cacheName: string
): string => {
  return `${envBaseUrl(
    '/!api/2.0'
  )}/repositories/${repositoryFullSlug}/pipelines-config/caches?name=${cacheName}`;
};

export function* requestCachesSaga(): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getCachesUrl(full_name);
    const res: Response = yield call(fetch, authRequest(url));

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const payload = yield call([res, 'json']);
    yield put({
      type: REQUEST_CACHES.SUCCESS,
      payload,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_CACHES.ERROR,
    });
    yield put({
      type: REQUEST_CACHES.ERROR,
      payload: e,
    });
  }
}

export function* requestDeleteCacheSaga(action: {
  meta: {
    cache: Cache;
    cacheUuid: string;
  };
}): any {
  const repository: Repository = yield select(getCurrentRepository);
  try {
    const url = getCachesUrl(repository.full_name, action.meta.cacheUuid);
    const res: Response = yield call(
      fetch,
      authRequest(url, { method: 'DELETE' })
    );

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    yield put({
      type: REQUEST_DELETE_CACHE.SUCCESS,
      meta: action.meta,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_DELETE_CACHE.ERROR,
    });
    yield put({
      type: REQUEST_DELETE_CACHE.ERROR,
      meta: action.meta,
    });
  }
}

export function* requestDeleteCachesSaga(action: {
  meta: { cache: Cache };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = deleteCachesByNameUrl(full_name, action.meta.cache.name);
    const res: Response = yield call(
      fetch,
      authRequest(url, { method: 'DELETE' })
    );

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    yield put({
      type: REQUEST_DELETE_CACHES.SUCCESS,
      meta: action.meta,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_DELETE_CACHES.ERROR,
    });

    yield put({
      type: REQUEST_DELETE_CACHES.ERROR,
      meta: action.meta,
    });
  }
}

export default function* () {
  yield all([
    takeLatest(REQUEST_CACHES.REQUEST, requestCachesSaga),
    takeEvery(REQUEST_DELETE_CACHE.REQUEST, requestDeleteCacheSaga),
    takeEvery(REQUEST_DELETE_CACHES.REQUEST, requestDeleteCachesSaga),
  ]);
}
