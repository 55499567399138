import React, {
  ComponentType,
  Context,
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import isEqual from 'lodash-es/isEqual';
import { useSelector } from 'react-redux';

import {
  getCurrentProjectUuid,
  getCurrentProjectWorkspaceUuid,
} from 'src/selectors/project-selectors';
import {
  getCurrentRepositoryUuid,
  getCurrentRepositoryProjectUuid,
  getCurrentRepositoryWorkspaceUUID,
} from 'src/selectors/repository-selectors';
import { getCurrentWorkspaceUUID } from 'src/selectors/workspace-selectors';
import { BucketState } from 'src/types/state';
import {
  publishFullScreenEvent as publishFullScreenEventUsingClient,
  publishUiEvent as publishUiEventUsingClient,
  publishTrackEvent as publishTrackEventUsingClient,
} from 'src/utils/analytics/publish';
import { captureExceptionWithTags } from 'src/utils/sentry';

type Namespaces = Array<string>;
type Attributes = {
  attributes?: {
    namespaces?: Namespaces;
    [key: string]: any;
  };
};

export type AnalyticsInitProps = Attributes & {
  source?: string;
  namespaces?: Namespaces;
  actionSubject?: string;
};
export type AnalyticsProps = {
  analytics?: AnalyticsInitProps;
};

type UiEventArgs = { name: string } & Attributes;
type ClickEventArgs = UiEventArgs & {
  actionSubject: string;
};
type TrackEventArgs = {
  action: string;
  actionSubject?: string;
  actionSubjectId?: string;
  source?: string;
} & Attributes;

export type ClickEventWithSubjectArgs = (
  name: string,
  attributes?: Attributes['attributes']
) => void;

type AnalyticsHook = (options?: AnalyticsInitProps) => {
  publishScreenEvent: (args?: Attributes) => void;
  publishClickEvent: (args: ClickEventArgs) => void;
  publishTrackEvent: (args: TrackEventArgs) => void;
  publishLinkClickEvent: ClickEventWithSubjectArgs;
  publishButtonClickEvent: ClickEventWithSubjectArgs;
  publishBreadcrumbClickEvent: ClickEventWithSubjectArgs;
  publishOptionClickEvent: ClickEventWithSubjectArgs;
  publishToggleClickEvent: ClickEventWithSubjectArgs;
  getNamespacesWithParent: (modalNameSpace?: string) => Namespaces;
};

const AnalyticsContext = createContext(
  {}
) as unknown as Context<AnalyticsInitProps>;

/* eslint @typescript-eslint/ban-types: "warn" */
export const AnalyticsProvider: FC<{ data: AnalyticsInitProps }> = ({
  data,
  children,
}) => {
  const dataRef = useRef(data);
  useEffect(() => {
    if (!isEqual(dataRef.current, data)) {
      dataRef.current = data;
    }
  }, [data]);
  return (
    <AnalyticsContext.Provider value={dataRef.current}>
      {children}
    </AnalyticsContext.Provider>
  );
};
// @ts-ignore
export const withAnalytics =
  <T,>(Component: ComponentType<T>, analyticsProps: AnalyticsInitProps) =>
  (props: T) =>
    (
      <AnalyticsProvider data={analyticsProps}>
        {
          // @ts-ignore
          <Component {...props} />
        }
      </AnalyticsProvider>
    );

export const useAnalyticsContext = (initPropsOverride?: AnalyticsInitProps) => {
  const analytics = useContext(AnalyticsContext);
  return initPropsOverride || analytics || {};
};

const emptyAttributes = {};
// initPropsOverride can be used to initialize the hook in such a way that it ignores the AnalyticsInitProps from context
export const useAnalytics: AnalyticsHook = initPropsOverride => {
  const {
    source,
    actionSubject: defaultActionSubject = 'unknown',
    namespaces,
    attributes: unmemoizedInitialAttributes = emptyAttributes,
  } = useAnalyticsContext(initPropsOverride);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialAttributes = useMemo(() => unmemoizedInitialAttributes, []);

  const repositoryUuid = useSelector(getCurrentRepositoryUuid);
  const repoProjectUuid = useSelector(getCurrentRepositoryProjectUuid);
  const currentProjectUuid = useSelector(getCurrentProjectUuid);
  const projectUuid = repoProjectUuid ?? currentProjectUuid;
  const currentWorkspaceUuid = useSelector(getCurrentWorkspaceUUID);
  const repoWorkspaceUuid = useSelector(getCurrentRepositoryWorkspaceUUID);
  const projectWorkspaceUuid = useSelector(getCurrentProjectWorkspaceUuid);
  // TODO: Revist this and `isReady` and possible remove analytics regarding users without workspaces
  // We're implicitly determining that the user has access to a workspace via nav items
  const hasWorkspace = useSelector((state: BucketState) => {
    return state.global?.horizontalNavigationItems?.mainItems?.length > 0;
  });
  const workspaceUuid =
    currentWorkspaceUuid ?? repoWorkspaceUuid ?? projectWorkspaceUuid;
  const defaultActionSubjectId = repositoryUuid || projectUuid || workspaceUuid;
  const isReady = useMemo(
    () => !hasWorkspace || !!workspaceUuid,
    [workspaceUuid, hasWorkspace]
  );
  const uuidAttributes = useMemo(
    () => ({
      workspaceUuid,
      ...(projectUuid && { projectUuid }),
      ...(repositoryUuid && { repositoryUuid }),
    }),
    [workspaceUuid, projectUuid, repositoryUuid]
  );

  const wrapExceptions = useCallback((func: () => void) => {
    try {
      func();
    } catch (e) {
      captureExceptionWithTags(e, { component: 'analytics' });
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, []);

  const extractNamespaces = useCallback(
    (attributes: Attributes['attributes']) =>
      attributes?.namespaces?.join('.') || namespaces?.join('.'),
    [namespaces]
  );

  const eventContainers = useMemo(
    () => ({
      ...(workspaceUuid && { workspace: { id: workspaceUuid } }),
      ...(projectUuid && { project: { id: projectUuid } }),
      ...(repositoryUuid && { repository: { id: repositoryUuid } }),
    }),
    [workspaceUuid, projectUuid, repositoryUuid]
  );

  const publishTrackEvent = useCallback(
    ({
      action,
      actionSubject = defaultActionSubject,
      actionSubjectId = defaultActionSubjectId,
      source: sourceOverride,
      attributes,
    }: TrackEventArgs): void => {
      wrapExceptions(() => {
        if (isReady && source?.length && actionSubjectId?.length) {
          const finalSource = sourceOverride || source;
          const finalNamespaces = extractNamespaces(attributes);
          publishTrackEventUsingClient({
            action,
            actionSubject,
            actionSubjectId,
            containers: eventContainers,
            source: finalSource,
            attributes: {
              ...uuidAttributes,
              ...initialAttributes,
              ...attributes,
              namespaces: finalNamespaces || finalSource,
            },
          });
        }
      });
    },
    [
      eventContainers,
      defaultActionSubject,
      defaultActionSubjectId,
      initialAttributes,
      isReady,
      extractNamespaces,
      source,
      uuidAttributes,
      wrapExceptions,
    ]
  );

  const publishClickEvent = useCallback(
    ({ actionSubject, attributes, name }: ClickEventArgs): void => {
      wrapExceptions(() => {
        if (isReady && source?.length) {
          const finalNamespaces = extractNamespaces(attributes);
          publishUiEventUsingClient({
            actionSubject,
            action: 'clicked',
            actionSubjectId:
              name.charCodeAt(0) > 64 && name.charCodeAt(0) < 91 // only prepend source if name is capitalized
                ? `${source}${name}`
                : name,
            containers: eventContainers,
            source,
            attributes: {
              ...uuidAttributes,
              ...initialAttributes,
              ...attributes,
              namespaces: finalNamespaces || source,
            },
          });
        }
      });
    },
    [
      eventContainers,
      initialAttributes,
      isReady,
      extractNamespaces,
      source,
      uuidAttributes,
      wrapExceptions,
    ]
  );

  const publishLinkClickEvent = useCallback<ClickEventWithSubjectArgs>(
    (name, attributes) =>
      publishClickEvent({ actionSubject: 'link', attributes, name }),
    [publishClickEvent]
  );

  const publishToggleClickEvent = useCallback<ClickEventWithSubjectArgs>(
    (name, attributes) =>
      publishClickEvent({ actionSubject: 'toggle', attributes, name }),
    [publishClickEvent]
  );

  const publishButtonClickEvent = useCallback<ClickEventWithSubjectArgs>(
    (name, attributes) =>
      publishClickEvent({ actionSubject: 'button', attributes, name }),
    [publishClickEvent]
  );

  const publishOptionClickEvent = useCallback<ClickEventWithSubjectArgs>(
    (name, attributes) =>
      publishClickEvent({ actionSubject: 'option', attributes, name }),
    [publishClickEvent]
  );

  const publishBreadcrumbClickEvent = useCallback<ClickEventWithSubjectArgs>(
    (name, attributes = {}) =>
      publishClickEvent({ actionSubject: 'breadcrumbItem', attributes, name }),
    [publishClickEvent]
  );

  const publishScreenEvent = useCallback(
    ({ attributes }: Attributes = {}): void => {
      wrapExceptions(() => {
        if (isReady && source?.length) {
          const finalNamespaces = extractNamespaces(attributes);
          publishFullScreenEventUsingClient({
            name: source,
            containers: eventContainers,
            attributes: {
              ...uuidAttributes,
              ...initialAttributes,
              ...attributes,
              namespaces: finalNamespaces || source,
            },
          });
        }
      });
    },
    [
      eventContainers,
      extractNamespaces,
      initialAttributes,
      isReady,
      source,
      uuidAttributes,
      wrapExceptions,
    ]
  );

  const getNamespacesWithParent = useCallback<
    (childSource: string) => Namespaces
  >(
    childSource => {
      let parentNamespaces: string[] = [];
      if (namespaces?.length) {
        parentNamespaces = namespaces;
      } else {
        if (source) {
          parentNamespaces = [source];
        }
      }

      return [...parentNamespaces, childSource];
    },
    [namespaces, source]
  );

  return {
    publishClickEvent,
    publishLinkClickEvent,
    publishButtonClickEvent,
    publishBreadcrumbClickEvent,
    publishOptionClickEvent,
    publishToggleClickEvent,
    publishTrackEvent,
    publishScreenEvent,
    getNamespacesWithParent,
  };
};
