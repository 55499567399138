import { Diff, PullRequestRenderingLimits } from 'src/types/pull-request';
import { countAddedAndDeletedLines } from 'src/utils/count-diff-lines';
import { hasRenderableLines } from 'src/utils/diff-classifications';

export const truncateDiffsByOverallLineCount = (
  diffs: Diff[],
  isSingleFileModeActive: boolean,
  renderingLimits: PullRequestRenderingLimits | null
) => {
  if (!renderingLimits) {
    return diffs;
  }

  let lines = 0;
  let i = 0;

  for (; i < diffs.length; i++) {
    const file = diffs[i];
    const newLines = hasRenderableLines(
      file,
      isSingleFileModeActive,
      renderingLimits
    )
      ? countAddedAndDeletedLines(file)
      : 0;
    lines += newLines;
    if (
      lines >
      (isSingleFileModeActive
        ? renderingLimits.singleFileModeTruncateLines
        : renderingLimits.allFilesModeTruncateLines)
    ) {
      break;
    }
  }

  return diffs.slice(0, i);
};
