import {
  createResource,
  RouteContext,
  RouterContext,
} from 'react-resource-router';

import { Project } from 'src/components/types';
import { ResourceContext } from 'src/router/types';
import { stringify } from 'src/urls/utils';
import urls from 'src/urls/workspaces';
import escapeSearchString from 'src/utils/escape-bbql-string';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

type Query = RouteContext['query'];

type WorkspaceProjectsQuery = {
  page: number;
  isPrivate: string;
  search: string;
};

const parseQuery = (query: Query): WorkspaceProjectsQuery => {
  const parsedPage = parseInt(query.page, 10);
  return {
    page: !isNaN(parsedPage) ? Math.max(parsedPage, 1) : 1,
    isPrivate: query.is_private,
    search: query.query,
  };
};

const buildBbql = ({ isPrivate: is_private, search: inputSearch }: any) => {
  const bbql: string[] = [];
  if (is_private) {
    bbql.push(`is_private=${is_private === 'true'}`);
  }
  const search = escapeSearchString(inputSearch || '');
  if (search) {
    const conditions = [
      `description~"${search}"`,
      `name~"${search}"`,
      `key~"${search}"`,
    ];
    bbql.push(`(${conditions.join(' OR ')})`);
  }

  return bbql.join(' AND ');
};

export const workspaceProjectsResource = createResource({
  type: 'WORKSPACE_PROJECTS',
  getKey: ({ match, query }: RouterContext) => {
    // Strip out query params unrelated to fetching the project list
    const parsedQuery = parseQuery(query);
    const filteredQueryString = stringify(parsedQuery);
    return `workspace:${match.params.workspaceSlug}::projects:${filteredQueryString}`;
  },
  maxAge: 30 * 1000, // 30 seconds
  getData: async ({ match, query }, { csrftoken }: ResourceContext) => {
    const { workspaceSlug } = match.params;

    // If we can't match the URL to a workspace slug, this resource is being used on a route
    // it shouldn't be.
    if (!workspaceSlug) {
      return null;
    }

    const { page, isPrivate, search } = parseQuery(query);

    const filter = { isPrivate, search };
    const q = buildBbql(filter);

    const params = stringify({
      page,
      q,
      sort: 'name',
      fields: '-values.workspace,-values.owner',
    });

    const response = await fetch(
      authRequest(
        `${urls.api.v20.projects(workspaceSlug)}/${params}`,
        { headers: jsonHeaders },
        csrftoken
      )
    );

    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`);
    }
    const { values, size, pagelen } = await response.json();
    const projects: Project[] = values;

    return { projects, size, pagelen };
  },
});
