import { Variable } from 'src/components/pipelines/models';
import { LoadingStatus } from 'src/constants/loading-status';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import { VARIABLES_PAGELEN } from '../../constants';
import {
  CLEAR_VARIABLES,
  REQUEST_CREATE_VARIABLE,
  REQUEST_DELETE_VARIABLE,
  REQUEST_UPDATE_VARIABLE,
  REQUEST_VARIABLES,
} from '../actions/variables';

export type VariablesState = {
  map: Map<string, Variable> | [];
  fetchedStatus: LoadingStatus;
  page: number;
  loadMore: boolean;
};

export const initialState: VariablesState = {
  // SSR has a bug where it passes {} in initial state if set as new Map()
  map: [],
  fetchedStatus: LoadingStatus.Before,
  page: 1,
  loadMore: false,
};

export function clearVariables() {
  return { ...initialState };
}

export function requestCreateVariableBegin(
  state: VariablesState,
  action: Action<void> & { meta: { variable: Variable } }
) {
  if (!action.meta) {
    return state;
  }
  const newMap = new Map(state.map);
  newMap.delete(action.meta.variable.key);
  const map = new Map([
    ...newMap,
    [
      action.meta.variable.key,
      new Variable({
        ...action.meta.variable,
        isSyncing: true,
      }),
    ],
  ]);

  return { ...state, map };
}

export function requestCreateVariableSuccess(
  state: VariablesState,
  action: Action<any>
) {
  if (!action.payload) {
    return state;
  }
  const newMap = new Map(state.map);
  newMap.delete(action.payload?.key);
  const map = new Map([
    ...newMap,
    [action.payload?.uuid, new Variable(action.payload)],
  ]);
  return { ...state, map };
}

export function requestCreateVariableError(
  state: VariablesState,
  action: Action<any> & { meta: { variable: any } }
) {
  if (!action.meta) {
    return state;
  }
  const map = new Map(state.map);
  map.set(
    action.meta?.variable?.key,
    new Variable({
      ...action.meta.variable,
      error:
        action.payload?.body?.error || action.payload?.error || action.payload,
      isSyncing: false,
    })
  );
  return { ...state, map };
}

export function requestVariablesBegin(state: VariablesState) {
  return { ...state, fetchedStatus: LoadingStatus.Fetching };
}

export function requestVariablesSuccess(
  state: VariablesState,
  action: Action<{ values: any[]; pagelen: number }>
) {
  if (!action.payload) {
    return state;
  }
  const map = new Map(state.map);
  action.payload?.values?.forEach(variableData => {
    const variable = new Variable(variableData);
    map.set(variable.uuid, variable);
  });

  return {
    ...state,
    page: state.page + 1,
    loadMore: action.payload.pagelen >= VARIABLES_PAGELEN,
    fetchedStatus: LoadingStatus.Success,
    map,
  };
}

export function requestUpdateVariableBegin(
  state: VariablesState,
  action: Action<void> & {
    meta: { previousVariable: any; variable: any };
  }
) {
  if (!action.meta) {
    return state;
  }
  const map = new Map(state.map);
  map.set(
    action.meta.previousVariable.uuid,
    new Variable({
      ...map.get(action.meta.previousVariable.uuid)?.toJS(),
      ...action.meta.variable,
      isSyncing: true,
    })
  );

  return { ...state, map };
}

export function requestUpdateVariableSuccess(
  state: VariablesState,
  action: Action<Variable> & {
    meta: { previousVariable: any; variable: any };
  }
) {
  if (!action.payload || !action.meta) {
    return state;
  }

  const map = new Map(state.map);
  map.set(action.meta.previousVariable.uuid, new Variable(action.payload));
  return { ...state, map };
}

export function requestUpdateVariableError(
  state: VariablesState,
  action: Action<any> & {
    meta: { previousVariable: any; variable: any };
  }
) {
  if (!action.payload || !action.meta) {
    return state;
  }

  const map = new Map(state.map);
  map.set(
    action.meta.previousVariable.uuid,
    new Variable({
      ...map.get(action.meta.previousVariable.uuid)?.toJS(),
      error:
        action.payload?.body?.error || action.payload?.error || action.payload,
      isSyncing: false,
    })
  );

  return { ...state, map };
}

export function requestDeleteVariableBegin(
  state: VariablesState,
  action: Action<void> & { meta: { variable: any } }
) {
  if (!action.meta) {
    return state;
  }
  const map = new Map(state.map);
  map.delete(action.meta.variable.uuid);
  return { ...state, map };
}

export default createReducer(initialState, {
  // TODO: Replace the entire pipelines.variables with resource and hook in CICD-3682
  [CLEAR_VARIABLES]: clearVariables,
  [REQUEST_CREATE_VARIABLE.REQUEST]: requestCreateVariableBegin,
  [REQUEST_CREATE_VARIABLE.SUCCESS]: requestCreateVariableSuccess,
  [REQUEST_CREATE_VARIABLE.ERROR]: requestCreateVariableError,
  [REQUEST_VARIABLES.REQUEST]: requestVariablesBegin,
  [REQUEST_VARIABLES.SUCCESS]: requestVariablesSuccess,
  [REQUEST_UPDATE_VARIABLE.REQUEST]: requestUpdateVariableBegin,
  [REQUEST_UPDATE_VARIABLE.SUCCESS]: requestUpdateVariableSuccess,
  [REQUEST_UPDATE_VARIABLE.ERROR]: requestUpdateVariableError,
  [REQUEST_DELETE_VARIABLE.REQUEST]: requestDeleteVariableBegin,
});
