/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { SearchResultOption } from 'src/components/settings/styles/select';

export const SearchGroupOption = styled(SearchResultOption)`
  padding: 1px 0;
  & > div {
    background: ${token('color.background.accent.gray.subtler', colors.N50)};
    border-radius: 50%;
    border: ${token('space.025', '2px')} solid
      ${token('color.border.inverse', colors.N0)};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const DescriptionContainer = styled.div`
  margin-bottom: ${token('space.300', '24px')};
  color: #${token('color.text.accent.gray.bolder', colors.N500)};
`;
