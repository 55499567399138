import React, {
  createContext,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';

import { useResource } from 'react-resource-router';

import { MenuItem } from 'src/components/navigation/src/types';
import { workspaceNavigationResource } from 'src/sections/workspace/workspace-navigation-resource';
import urls from 'src/urls/workspaces';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

export const ForgeAppsContext = createContext({ workspaceForgeMenuItems: [] });

export type ForgeAppsContextType = {
  workspaceForgeMenuItems: MenuItem[];
};

/* eslint @typescript-eslint/ban-types: "warn" */
export const ForgeAppsProvider: React.FC<{}> = ({ children }) => {
  const [wsMenuItems, setWsMenuItems] = useState([]);
  const { data, loading } = useResource(workspaceNavigationResource);
  const workspaceSlug = data?.workspace.slug || null;
  const currentWorkspace = useRef<string | null>(workspaceSlug);
  useEffect(() => {
    let didCancel = false;

    const fetchWorkspaceMenuItems = async () => {
      if (!loading && workspaceSlug != null) {
        if (currentWorkspace.current !== workspaceSlug) {
          setWsMenuItems([]);
          currentWorkspace.current = workspaceSlug;
        }
        const response = await fetch(
          authRequest(`${urls.api.internal.forgeMenuItems(workspaceSlug)}`, {
            headers: jsonHeaders,
          })
        );

        if (response.ok) {
          const result = await response.json();
          const adminMenuItems = result.admin_menu_items || [];
          if (!didCancel) {
            setWsMenuItems(adminMenuItems);
          }
        }
      }
    };
    fetchWorkspaceMenuItems();
    return () => {
      didCancel = true;
    };
  }, [loading, workspaceSlug]);

  const providerValue = useMemo(
    () => ({ workspaceForgeMenuItems: wsMenuItems }),
    [wsMenuItems]
  );
  return (
    <ForgeAppsContext.Provider value={providerValue}>
      {children}
    </ForgeAppsContext.Provider>
  );
};
