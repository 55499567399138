import { PullRequest } from 'src/components/pipelines/models';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import {
  CLEAR_CURRENT_PIPELINE,
  REQUEST_COMMIT_PULL_REQUESTS,
} from '../actions/pipelines';

export type CommitPullRequestsState = PullRequest[];

export const initialState: CommitPullRequestsState = [];

export default createReducer(initialState, {
  [CLEAR_CURRENT_PIPELINE]() {
    return [];
  },
  [REQUEST_COMMIT_PULL_REQUESTS.REQUEST]() {
    return [];
  },
  [REQUEST_COMMIT_PULL_REQUESTS.SUCCESS](
    state: CommitPullRequestsState,
    action: Action<{ values: any[] }>
  ) {
    if (!action.payload?.values) {
      return state;
    }
    return action.payload.values.map(data => new PullRequest(data));
  },
});
