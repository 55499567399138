import { zipObject } from 'lodash-es';

import { createAsyncAction } from 'src/redux/actions';

const COMMITS_PREFIX = 'commits/';
const PR_PREFIX = 'pullrequest/';
const BRANCH_PREFIX = 'branch/';

// Any entry added here will get a string built be used as an Action type
// and autocomplete assistance from the default export of this module
const ACTIONS = {
  UPDATE_COMMITS: '',
  FETCH_COMMITS: '',
  FETCH_MORE_COMMITS: '',
  RETRY_COMMITS: '',
  COMMIT_FETCH_ERROR: '',
};

export const PR_UPDATE_COMMITS_STATUS = createAsyncAction(
  `${PR_PREFIX}UPDATE_COMMITS_STATUS`
);

export const BRANCH_UPDATE_COMMITS_STATUS = createAsyncAction(
  `${BRANCH_PREFIX}UPDATE_COMMITS_STATUS`
);

export const FETCH_BRANCH_SYNC_INFO = createAsyncAction(
  `${COMMITS_PREFIX}FETCH_BRANCH_SYNC_INFO`
);

export const UNLOAD_BRANCH_COMMITS = `${BRANCH_PREFIX}UNLOAD_BRANCH_COMMITS`;

type ActionMap = { [key in ActionTypes]: string };
type ActionTypes = keyof typeof ACTIONS;

type Options = {
  prefix?: string;
};

function makeActions(actions: ActionMap, opts: Options): ActionMap {
  const prefixed = Object.keys(actions).map(
    action => `${opts.prefix}${action}`
  );
  // Lodash tries to type as a string Dictionary, this casting keeps our autocomplete
  return zipObject(Object.keys(actions), prefixed) as ActionMap;
}

export const prCommitActions = makeActions(ACTIONS, { prefix: PR_PREFIX });
export const branchCommitActions = makeActions(ACTIONS, {
  prefix: BRANCH_PREFIX,
});
