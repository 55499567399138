import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'frontbucket.repository.pullRequest.revert.errorFlag.title',
    description: 'Revert pull request flag title',
    defaultMessage: 'Unable to revert pull request',
  },
  learnMore: {
    id: 'frontbucket.repository.pullRequest.revert.errorFlag.learnMore',
    description: 'Revert pull request flag learn more action',
    defaultMessage: 'Learn more',
  },
  description: {
    id: 'frontbucket.repository.pullRequest.revert.errorFlag.description',
    description: 'Revert pull request flag description',
    defaultMessage:
      'The resulting revert would cause conflicts on one or more files.' +
      ' You will need to resolve these conflicts manually or create a new pull request to revert these changes.',
  },
});
