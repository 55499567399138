import { defineMessages } from 'react-intl';

export default defineMessages({
  issueHeader: {
    id: 'frontbucket.jswTab.siteIssuesList.issueHeader',
    description: 'Table header text for issue column in Site issues table',
    defaultMessage: 'Issue',
  },
  statusHeader: {
    id: 'frontbucket.jswTab.siteIssuesList.statusHeader',
    description: 'Table header text for status column in Site issues table',
    defaultMessage: 'Status',
  },
  assigneeHeader: {
    id: 'frontbucket.jswTab.siteIssuesList.assigneeHeader',
    description: 'Table header text for assignee column in Site issues table',
    defaultMessage: 'Assignee',
  },
  updatedHeader: {
    id: 'frontbucket.jswTab.siteIssuesList.updatedHeader',
    description:
      'Table header text for updated column in Site issues table.  This is the last time the issue was updated',
    defaultMessage: 'Updated',
  },
  actionsHeader: {
    id: 'frontbucket.jswTab.siteIssuesList.actionsHeader',
    description: 'Table header text for actions column in Site issues table',
    defaultMessage: 'Actions',
  },
  moreLabel: {
    id: 'frontbucket.jswTab.siteIssuesList.moreLabel',
    description: 'Label for "more" actions',
    defaultMessage: `More`,
  },
  createBranchButton: {
    id: 'frontbucket.jswTab.siteIssuesList.createBranchButton',
    description: 'Label for the "Create branch" action',
    defaultMessage: `Create branch`,
  },
  failedToLoadIssuesTitle: {
    id: 'frontbucket.jswTab.siteIssuesList.failedToLoadIssuesTitle',
    description: 'Issues failed to load message title',
    defaultMessage: `We can't load the issues`,
  },
  bitbucket: {
    id: 'frontbucket.dashboard.overview.jiraIssues.bitbucket',
    description:
      'Text for category of actions related to the Bitbucket product',
    defaultMessage: 'Bitbucket',
  },
  jira: {
    id: 'frontbucket.dashboard.overview.jiraIssues.jira',
    description: 'Text for category of actions related to the Jira product',
    defaultMessage: 'Jira',
  },
  copyIssueLink: {
    id: 'frontbucket.dashboard.overview.jiraIssues.copyIssueLink',
    description: 'Text for a button which copies a link to the Jira issue',
    defaultMessage: 'Copy issue link',
  },
  issueLinkCopiedTitle: {
    id: 'frontbucket.dashboard.overview.jiraIssues.issueLinkCopiedTitle',
    description: 'Title text for flag when copying issue link succeeds',
    defaultMessage: 'Issue link copied',
  },
  issueLinkCopiedBody: {
    id: 'frontbucket.dashboard.overview.jiraIssues.issueLinkCopiedBody',
    description: 'Body text for a flag when copying issue link succeeds',
    defaultMessage: 'Issue link successfully copied to your clipboard',
  },
  assignIssueSuccessTitle: {
    id: 'frontbucket.dashboard.overview.jiraIssues.assignIssueSuccessTitle',
    description: 'Title text for flag when assigning issue succeeds',
    defaultMessage: 'Issue assigned successfully',
  },
  assignIssueFailureTitle: {
    id: 'frontbucket.dashboard.overview.jiraIssues.assignIssueFailureTitle',
    description: 'Title text for flag when assigning issue fails',
    defaultMessage: 'Assigning the issue failed',
  },
  openInJira: {
    id: 'frontbucket.dashboard.overview.jiraIssues.openInJira',
    description: 'Text for a button which opens the issue in Jira',
    defaultMessage: 'Open in Jira',
  },
  assignToMe: {
    id: 'frontbucket.dashboard.overview.jiraIssues.assignToMe',
    description:
      'Text for a button which assigns the jira issue to the user clicking the button',
    defaultMessage: 'Assign to me',
  },
});
