import { useContext, useCallback } from 'react';

import { denormalize } from 'normalizr';
import { useSelector, useDispatch } from 'react-redux';

import { AppDataContext } from 'src/app/data';
import { fetchData, FetchAction } from 'src/redux/actions';
import { UPDATE_REPOSITORY_BUILD_STATUSES } from 'src/redux/global/actions/repository-build-status';
import { addStatuses, RepositoryBuildStatus } from 'src/redux/global/utils';
import { fetchRecentlyViewedRepositories } from 'src/redux/recently-viewed-repositories';
import { GET_RECENTLY_VIEWED_WORKSPACES } from 'src/redux/workspaces/actions';
import { repository as repoSchema } from 'src/sections/repository/schemas';
import { getRecentlyViewedRepositories } from 'src/selectors/repository-selectors';
import urls from 'src/urls/dashboard';
import authRequest from 'src/utils/fetch';
import repositoryWithWorkspaceStore from 'src/utils/recently-viewed-repositories-store';
import workspaceStore from 'src/utils/recently-viewed-workspaces-store';

type Exports = {
  recentRepositories: BB.Repository[];

  loadRecentRepositories: (
    includeBuildStatus: boolean
  ) => Promise<BB.Repository[]>;
  loadRecentWorkspaces: () => void;
};

const useRepositoryLoader = () => {
  const appData = useContext(AppDataContext);
  const dispatch = useDispatch();

  return useCallback(
    async (includeBuildStatus: boolean) => {
      if (!appData.user) {
        return [];
      }
      if (!appData.initialContext.workspace) {
        return [];
      }

      // storage key expects UUID with braces
      const recentlyViewedRepositories = repositoryWithWorkspaceStore.get({
        uuid: `{${appData.user.uuid}}`,
      });
      const { workspaceUuids } = recentlyViewedRepositories;
      const repositoryUuids =
        workspaceUuids[appData.initialContext.workspace.uuid];
      if (
        Object.keys(workspaceUuids).length === 0 ||
        !repositoryUuids ||
        !repositoryUuids.length
      ) {
        return [];
      }

      const uniqueUuids = repositoryUuids.filter(
        (uuid, i, arr) => arr.indexOf(uuid) === i
      );

      const fetchAsyncData = (action: FetchAction): Promise<FetchAction> => {
        // Ignore the `fetch-action-saga` so we can await the fetchData action
        action.meta.isRouterResource = true;
        dispatch(action);
        // @ts-ignore
        return dispatch(fetchData(action));
      };

      const fetchAction = fetchRecentlyViewedRepositories(uniqueUuids);
      const recentReposResponse = await fetchAsyncData(fetchAction);
      const {
        payload: {
          result: { values: responseUuids },
          entities,
        },
      } = recentReposResponse;

      let repos = denormalize(responseUuids, [repoSchema], entities);
      if (!includeBuildStatus) {
        // We're using implicit knowledge that if we aren't requesting build
        // statuses, this is called from the horizontal nav search. In that
        // case, we're taking some shortcuts to return some (possibly
        // incomplete) data faster. We need to return the repository objects,
        // so denormalize the response.
        return repos;
      }

      try {
        const url = urls.api.internal.repositoryBuildStatuses();
        const response = await fetch(
          authRequest(url, {
            method: 'POST',
            body: uniqueUuids.map(uuid => `uuid=${uuid}`).join('&'),
            headers: {
              'content-type':
                'application/x-www-form-urlencoded; charset=UTF-8',
            },
          })
        );

        if (response.ok) {
          const statuses: RepositoryBuildStatus[] = await response.json();

          repos = addStatuses(repos, statuses);

          // normalizr will handle this action
          dispatch({
            type: UPDATE_REPOSITORY_BUILD_STATUSES.SUCCESS,
            payload: repos,
            meta: {
              schema: [repoSchema],
            },
          });
        }
      } catch (e) {
        dispatch({
          type: UPDATE_REPOSITORY_BUILD_STATUSES.ERROR,
          payload: e,
        });
      }

      return repos;
    },
    [dispatch, appData.user, appData.initialContext.workspace]
  );
};

export const useRecentData = (): Exports => {
  const appData = useContext(AppDataContext);
  const recentRepositories = useSelector(getRecentlyViewedRepositories);
  const dispatch = useDispatch();
  const loadRecentRepositories = useRepositoryLoader();

  const loadRecentWorkspaces = useCallback(() => {
    if (!appData.user) {
      return;
    }

    // storage key expects UUID with braces
    const workspaceUuids = workspaceStore.get({
      uuid: `{${appData.user.uuid}}`,
    });
    if (workspaceUuids) {
      dispatch({
        type: GET_RECENTLY_VIEWED_WORKSPACES,
        payload: workspaceUuids,
      });
    }
  }, [dispatch, appData.user]);

  return {
    recentRepositories,
    loadRecentRepositories,
    loadRecentWorkspaces,
  };
};
