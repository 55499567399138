import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

const testTitleStyles = xcss({
  font: 'font.body',
  fontWeight: '500',
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center',
  gap: 'space.075',
  paddingInlineEnd: 'space.500',
});

/* eslint @typescript-eslint/ban-types: "warn" */
export const TestTitle: React.FC = ({ children }) => (
  <Box xcss={testTitleStyles}>{children}</Box>
);
