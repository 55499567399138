/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { cssVar } from '../../../constants';
var beforeElementStyles = css({
  display: 'flex',
  position: 'absolute',
  top: "var(--ds-space-0, 0px)",
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: "var(".concat(cssVar.borderRadius, ")")
});
var Before = function Before(_ref) {
  var elemBefore = _ref.elemBefore;
  return elemBefore ? jsx("span", {
    css: beforeElementStyles
  }, elemBefore) : null;
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default Before;