/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { call, put, select } from 'redux-saga/effects';

import { getGlobalShouldIgnoreWhitespace } from 'src/redux/diff-settings';
import { LOAD_DIFFSTAT } from 'src/redux/pull-request/actions';
import urls from 'src/redux/pull-request/urls';
import { getPullRequestApis } from 'src/sagas/helpers';
import {
  getCurrentRepositoryOwnerName,
  getCurrentRepositorySlug,
} from 'src/selectors/repository-selectors';
import { DiffStat } from 'src/types/diffstat';

import {
  getPullRequestCompareSpecDiffStatUrl,
  getCurrentPullRequestId,
  getCurrentPullRequestInTopicDiffMode,
} from '../selectors';
import { normalizeDiffStatParams, addQueryParams } from '../urls/url-utils';

// @ts-ignore TODO: fix noImplicitAny error here
export function* fetchDiffStatSaga(
  url: string,
  // If maxPages is undefined, fetch all pages, otherwise return only the number of pages specified
  maxPages: number | undefined = undefined,
  values: DiffStat[] = [],
  isFirstFetch = true
) {
  try {
    if (isFirstFetch) {
      yield put({ type: LOAD_DIFFSTAT.REQUEST });
    }

    const api = yield* getPullRequestApis();
    // @ts-ignore
    const diffStat = yield call(api.getDiffStat, url);
    const newValues: DiffStat[] = diffStat.values;
    values.push(...newValues);
    const pagesLeft = maxPages === undefined ? undefined : maxPages - 1;
    const fetchMore = (pagesLeft ?? 1) > 0;

    // Diffstat is paginated.  Auto fetch the rest of diffstat data
    if (diffStat.next && fetchMore) {
      yield call(fetchDiffStatSaga, diffStat.next, pagesLeft, values, false);
    } else {
      // All diffstat pages loaded, no additional data
      yield put({
        type: LOAD_DIFFSTAT.SUCCESS,
        payload: {
          values,
        },
      });
    }
  } catch (e) {
    yield put({
      type: LOAD_DIFFSTAT.ERROR,
      payload: {
        diffStatErrorCode: e.status,
        diffStatErrorKey: e.key,
      },
    });
  }
}

export function* fetchDiffStatByPrIdSaga(
  owner: string,
  slug: string,
  id: string | number
) {
  const ignoreWhitespace: boolean = yield select(
    getGlobalShouldIgnoreWhitespace
  );
  const url = urls.api.v20.diffstat(owner, slug, id, {
    ignoreWhitespace,
  });

  const maxPages = 1;

  // We don't add the topic diff param here because PR data isn't avaible in the redux store when this function gets run.
  // The backend redirect that results from this call will override some parameters, including adding the topic diff param
  yield call(fetchDiffStatSaga, url, maxPages);
}

export function* fetchDiffStatForCurrentPullRequest() {
  const id: number = yield select(getCurrentPullRequestId);
  const owner: string = yield select(getCurrentRepositoryOwnerName);
  const slug: string = yield select(getCurrentRepositorySlug);

  yield call(fetchDiffStatByPrIdSaga, owner, slug, id);
}

export function* fetchDiffStatByCompareSpecSaga() {
  const serverGeneratedDiffStatUrl: string = yield select(
    getPullRequestCompareSpecDiffStatUrl
  );

  // Only run saga for PR page diffs
  if (serverGeneratedDiffStatUrl === undefined) {
    return;
  }

  const ignoreWhitespace: boolean = yield select(
    getGlobalShouldIgnoreWhitespace
  );
  const topicDiff: boolean = yield select(getCurrentPullRequestInTopicDiffMode);
  const url = addQueryParams(
    serverGeneratedDiffStatUrl,
    normalizeDiffStatParams({ topicDiff, ignoreWhitespace })
  );

  const maxPages = 1;

  yield call(fetchDiffStatSaga, url, maxPages);
}
