import { Repository } from 'src/components/types';
import { FetchAction, fetchAction } from 'src/redux/actions';
import { FETCH_FORGE_REPO_MENU_ITEMS } from 'src/sections/repository/actions';
import { Action } from 'src/types/state';

export default function fetchForgeRepoMenuItems(
  repository: Repository
): FetchAction | Action {
  const repoSlug = repository.slug;

  const { workspace } = repository;
  const { slug: workspaceSlug } = workspace || {};

  if (workspaceSlug && repoSlug) {
    return fetchAction(FETCH_FORGE_REPO_MENU_ITEMS, {
      url: `/!api/internal/repositories/${workspaceSlug}/${repoSlug}/forge/menu-items`,
    });
  } else {
    return {
      type: FETCH_FORGE_REPO_MENU_ITEMS.ERROR,
      payload: 'Missing workspace and/or repository slug.',
    };
  }
}
