import { createResource } from 'react-resource-router';

import { KeyPair } from 'src/components/pipelines/models';
import authRequest from 'src/utils/fetch';

import envBaseUrl from '../utils/env-base-url';

export type Params = {
  repositoryFullSlug?: string;
  repositoryOwner?: string; // legacyPath
  repositorySlug?: string; // legacyPath
};

export const getKeyPairUrl = (params: Params) => {
  const {
    repositoryFullSlug = `${params.repositoryOwner}/${params.repositorySlug}`,
  } = params;
  return `${envBaseUrl()}/repositories/${repositoryFullSlug}/pipelines_config/ssh/key_pair`;
};

export const getKeyPairGenerateUrl = (params: Params) => {
  const {
    repositoryFullSlug = `${params.repositoryOwner}/${params.repositorySlug}`,
  } = params;
  return `${envBaseUrl(
    '/!api/internal'
  )}/repositories/${repositoryFullSlug}/pipelines_config/ssh/key_pair/generate`;
};

export const loadPipelinesRepositoryKeyPairResource = createResource<KeyPair>({
  type: 'pipelinesRepositoryKeyPair',
  getKey: ({ match }) => getKeyPairUrl(match.params),
  maxAge: 60000, // cache for 1 minute so navigating between pages is fast
  getData: async ({ match }) =>
    new KeyPair(
      await (await fetch(authRequest(getKeyPairUrl(match.params)))).json()
    ),
});
