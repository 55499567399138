import { createSelector } from 'reselect';

import { User } from 'src/components/pipelines/models';
import { getCurrentUser } from 'src/selectors/user-selectors';

export const getCurrentUserAsPipelinesUser = createSelector(
  getCurrentUser,
  currentUser => {
    if (currentUser != null) {
      return new User({
        display_name: currentUser.display_name,
        username: currentUser.username,
        links: currentUser.links,
        uuid: currentUser.uuid,
        has_2fa_enabled: !!currentUser.has_2fa_enabled,
      });
    } else {
      return null;
    }
  }
);
