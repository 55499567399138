import * as Sentry from '@sentry/browser';

import { RouteProcessor } from './types';

/**
 * Helper to return the trailing path segment of a URL, even if it ends with a '/'
 * 'https://www.example.com/test.066720d6.js' should return 'test.066720d6.js'
 * 'https://www.example.com/sub/sub2/' should return 'sub2/'
 */
export const getTrailingPath = (url: string): string => {
  /**
   * [^/]
   *   start with the first non-"/" character
   * (?!.*\/
   *   that doesn't have a trailing slash
   * (?!$))
   *   _unless_ that trailing slash is the end of the line
   * .*
   *   finally, grab everything until the end
   */
  const matches: RegExpMatchArray = (url.match(/[^/](?!.*\/(?!$)).*/) ||
    []) as RegExpMatchArray;
  return matches[0] || url;
};

/**
 * Helper to remove the URL params
 */
export const removeUrlParams = (url: string): string => {
  // Pattern to match and replace a question mark and everything after it
  return url.replace(/\?.*/, '');
};

/**
 * Helper to remove the URL anchor
 */
export const removeUrlAnchor = (url: string): string => {
  // Pattern to match and replace a pound sign and everything after it
  return url.replace(/#.*/, '');
};

/**
 * Helper to remove 8 charater versions from resource URLs
 */
export const stripVersions = (url: string): string => {
  /**
   * (\.)(?:[a-zA-Z0-9]{8})
   *   matches an 8 character string that immediately follows a "."
   * (?=\.map\.js|\.js|\.css)
   *   that has one of the specified extensions immediately after it
   */
  return url.replace(/(\.)(?:[a-zA-Z0-9]{8})(?=\.map\.js|\.js|\.css)/, '');
};

/**
 * Processor for any URL within the cloudfront.net domain
 *   - gets the trailing path
 *   - removes URL params
 *   - removes versions
 */
export const cloudfrontAssets: RouteProcessor = {
  pattern:
    /(^.*)\/\/bbc-object-storage--frontbucket\.us-(.*)-1\.(.*)\.public\.atl-paas\.net(.*)/,
  onMatch: (url: string, _matches: RegExpMatchArray) => {
    let processedUrl: string = url;

    processedUrl = getTrailingPath(processedUrl);
    processedUrl = removeUrlParams(processedUrl);
    processedUrl = stripVersions(processedUrl);
    return `<cloudfront>/${processedUrl}`;
  },
};

/**
 * Processor for any resource served by the localhost in a dev environment
 *   - gets the trailing path
 *   - removes URL params
 *   - removes versions
 */
export const localhostAssets: RouteProcessor = {
  pattern: /(^.*)\/\/(.*)localhost:([0-9]+)\/static(.*)/,
  onMatch: (url: string, _matches: RegExpMatchArray) => {
    let processedUrl: string = url;

    processedUrl = getTrailingPath(processedUrl);
    processedUrl = removeUrlParams(processedUrl);
    processedUrl = stripVersions(processedUrl);
    return `<localhost>/${processedUrl}`;
  },
};

/**
 * Processor for gateway api URLs
 *   - gets the trailing path
 *   - removes URL params
 */
export const gatewayApi: RouteProcessor = {
  pattern: new RegExp(`(^.*)/${window.location.hostname}/gateway/api/`),
  onMatch: (url: string, _matches: RegExpMatchArray) => {
    let processedUrl: string = url;

    processedUrl = getTrailingPath(processedUrl);
    processedUrl = removeUrlParams(processedUrl);
    return `<gateway-api>/${processedUrl}`;
  },
};

/**
 * Processor for bitbucket api URLs
 *   - matches both "bitbucket.org/!api" and "api.bitbucket.org" routes
 *   - gets the trailing path
 *   - removes URL params
 */
export const bitbucketApi: RouteProcessor = {
  pattern: new RegExp(
    `(^.*)/(${window.location.hostname}/!api/)|(api.${window.location.hostname})`
  ),
  onMatch: (url: string, _matches: RegExpMatchArray) => {
    let processedUrl: string = url;

    processedUrl = getTrailingPath(processedUrl);
    processedUrl = removeUrlParams(processedUrl);
    return `<bitbucket-api>/${processedUrl}`;
  },
};

/**
 * Processor for repository URLs
 * Any window.location.hostname routes that don't get matched by previous processers AND
 * have at least 2 path segments will match
 *   - replaces the matched portion of the URL with <repository>
 *   - removes URL params
 *   - "https://bitbucket.org/username/repo/rest-of-path" becomes "<repository>/rest-of-path"
 */
export const repositoryPaths: RouteProcessor = {
  /**
   * (^.*)/${window.location.hostname}
   *   start within the app domain
   * /[^/]+/[^/]
   *   has at least two route paths after the TLD
   * .*?(?=\\?|\\/|$)
   *   lazy match everything until it hits a "?", "/", or end of string
   */
  pattern: new RegExp(
    `(^.*)/${window.location.hostname}/[^/]+/[^/].*?(?=\\?|\\/|$)`
  ),
  onMatch: (url: string, matches: RegExpMatchArray) => {
    let processedUrl: string = url.replace(matches[0], '<repository>');
    processedUrl = removeUrlParams(processedUrl);
    processedUrl = removeUrlAnchor(processedUrl);
    return processedUrl;
  },
};

/**
 * Processor for New Relic URLs at bam-cell.nr-data.net
 */
export const newRelicBamCell: RouteProcessor = {
  pattern: /(^.*)\/bam-cell\.nr-data\.net(.*)/,
  onMatch: (_url: string, _matches: RegExpMatchArray) => {
    return `<new-relic-bam-cell>`;
  },
};

/**
 * Processor for New Relic URLs at newrelic.com
 */
export const newRelicCom: RouteProcessor = {
  pattern: /(^.*)\.newrelic\.com(.*)/,
  onMatch: (_url: string, _matches: RegExpMatchArray) => {
    return `<new-relic>`;
  },
};

/**
 * Processor for atlassian api URLs
 *    - gets the trailing path
 *    - removes URL params
 */
export const atlassianApi: RouteProcessor = {
  pattern: /(^.*)\/api\.(.*)atlassian\.com\/(.*)/,
  onMatch: (url: string, _matches: RegExpMatchArray) => {
    let processedUrl: string = url;

    processedUrl = getTrailingPath(processedUrl);
    processedUrl = removeUrlParams(processedUrl);
    return `<atlassian-api>/${processedUrl}`;
  },
};

/**
 * Processor for Sentry
 */
export const sentryApi: RouteProcessor = {
  pattern: /(^.*)\/(.*)sentry\.io\/(.*)/,
  onMatch: (url: string, _matches: RegExpMatchArray) => {
    let processedUrl: string = url;

    processedUrl = removeUrlParams(processedUrl);
    processedUrl = getTrailingPath(processedUrl);
    return `<sentry-api>/${processedUrl}`;
  },
};

/**
 * Returns the processed url or <unknown> if unprocessed
 * @param url
 * @param processors
 * @returns
 */
export const processUrl = (
  url: string,
  processors: RouteProcessor[]
): string => {
  for (let a = 0; a < processors.length; a += 1) {
    const matches: RegExpMatchArray | null = url.match(processors[a].pattern);

    if (matches) {
      return processors[a].onMatch(url, matches);
    }
  }

  // Try to extract the TLD
  // Very rudimentary but will match everything from the beginning through the first '/' after a '//'
  const tldMatch = url.match(/(^.*)\/\/(.+?)\//);

  // Since these aren't urgent warnings, we only need to capture a sampling of them
  // I tried to get the built-in tracesSampler method working, but with no luck
  // We may want to figure that out later though as it could significantly cut down on our log rate:
  // https://docs.sentry.io/platforms/javascript/configuration/sampling/
  const SAMPLE_RATE = 0.02;
  if (Math.random() < SAMPLE_RATE) {
    Sentry.captureMessage(
      `Unprocessed browser-metrics URL at TLD: ${
        tldMatch ? tldMatch[0] : 'unknown'
      }`,
      Sentry.Severity.Warning
    );
  }

  return '<unknown>';
};
