/**
 * Get the `page` prop for `@atlaskit/pagination`.
 * @example getPages(30, 10) => [1, 2, 3]
 * @see https://atlassian.design/components/pagination/code
 */
export function getPages(total: number, pagelen: number) {
  const length = Math.max(Math.ceil(total / pagelen), 1);
  if (Number.isNaN(length) || !Number.isFinite(length)) {
    return [1];
  } else {
    return Array.from({ length }, (_, i) => i + 1);
  }
}
