import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["clearControlLabel"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/* eslint-disable @repo/internal/react/require-jsdoc */
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import DownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import CrossIcon from '@atlaskit/icon/utility/migration/cross-circle--select-clear';
import { Inline, Pressable, xcss } from '@atlaskit/primitives';
import { components } from '@atlaskit/react-select';
import Spinner from '@atlaskit/spinner';
var iconContainerStyles = xcss({
  all: 'unset',
  outline: 'revert',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 'space.025'
});
var dropdownWrapperStyles = xcss({
  padding: 'space.075'
});
export var ClearIndicator = function ClearIndicator(_ref) {
  var _ref$clearControlLabe = _ref.clearControlLabel,
    clearControlLabel = _ref$clearControlLabe === void 0 ? 'clear' : _ref$clearControlLabe,
    props = _objectWithoutProperties(_ref, _excluded);
  return jsx(components.ClearIndicator, _objectSpread(_objectSpread({}, props), {}, {
    innerProps: _objectSpread(_objectSpread({}, props.innerProps), {}, {
      'aria-hidden': 'false'
    })
  }), jsx(Pressable, {
    xcss: iconContainerStyles,
    tabIndex: -1,
    "aria-label": clearControlLabel
  }, jsx(CrossIcon, {
    label: "",
    color: "currentColor",
    LEGACY_size: "small",
    LEGACY_margin: "var(--ds-space-negative-025, -0.125rem)"
  })));
};
export var DropdownIndicator = function DropdownIndicator(props) {
  return (
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
    jsx(components.DropdownIndicator, props, jsx(Inline, {
      as: "span",
      xcss: dropdownWrapperStyles
    }, jsx(DownIcon, {
      color: "currentColor",
      label: "open",
      LEGACY_margin: "var(--ds-space-negative-075, -0.375rem)"
    })))
  );
};
export var LoadingIndicator = function LoadingIndicator(props) {
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  var loadingStyles = css(props.getStyles('loadingIndicator', props));
  return (
    // This *must* be constructed this way because this is being consumed by
    // `react-select` and we don't control what it wants.
    // eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
    jsx("div", _extends({
      css: loadingStyles
    }, props.innerProps), jsx(Spinner, {
      size: "small"
    }))
  );
};