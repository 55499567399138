export module AnalyticsCategories {

  export const dialog = 'dialog';
  export const iframe = 'iframe';
  export const inlineDialog = 'inlineDialog';

}

export module AnalyticsActions {

  export const create = 'create';
  export const close = 'close';
  export const error = 'error';
  export const timeout = 'timeout';
  export const established = 'established';

}

export const allowedStrings = [
  AnalyticsActions.create,
  AnalyticsActions.close,
  AnalyticsActions.error,
  AnalyticsActions.timeout,
  AnalyticsActions.established
];