export default function formatBytes(bytes: number, decimals?: number): string {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1000;
  const dm = decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
