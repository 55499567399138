import { defineMessages } from 'react-intl';

export default defineMessages({
  deleteBranchSuccessTitle: {
    id: 'frontbucket.branches.deleteBranchSuccessTitle',
    description:
      'Text for the title of the delete branch success message for a git repository',
    defaultMessage: 'Branch deleted',
  },
  deleteBranchSuccessDescription: {
    id: 'frontbucket.branches.deleteBranchSuccessDescription',
    description:
      'Text for the body of the delete branch success message for a git repository',
    defaultMessage: 'You successfully deleted the {branchLabel} branch.',
  },
});
