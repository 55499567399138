import { createResource, RouterContext } from 'react-resource-router';

import { MenuItem } from 'src/components/navigation/src/types';
import { ResourceContext } from 'src/router/types';
import urls from 'src/urls/dashboard';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

type DashboardNavigationMenuItems = {
  actions: MenuItem[];
  active: string | null;
  main: MenuItem[];
  secondary: MenuItem[];
  settings: MenuItem[];
  sidebar: MenuItem[];
};

export type DashboardNavigation = {
  features: { [featureName: string]: any };
  links: {
    attributions?: BB.Link;
    login?: BB.Link;
    logout?: BB.Link;
    signup?: BB.Link;
    whatsNew: BB.Link;
    manage_account?: BB.Link;
    switch_account?: BB.Link;
  };
  menuItems: DashboardNavigationMenuItems;
  user?: BB.User & {
    extra?: { workspace_slug: string };
  };
};

// NOTE: Currently we don't seem to render additional workspace specific nav (such
//       as sidebar links or breadcrumbs). If that changes, we can use match.params.workspaceSlug
//       to call the WorkspaceNavigationHandler in core instead.

export const dashboardNavigationResource = createResource({
  type: 'DASHBOARD_NAVIGATION',
  getKey: ({ match }: RouterContext) =>
    `dashboard:${match.params.workspaceSlug}`,
  maxAge: 20 * 1000,
  getData: async (
    _routerStoreContext,
    { csrftoken }: ResourceContext
  ): Promise<DashboardNavigation | null> => {
    const requestUrl = urls.api.internal.navigation();

    const response = await fetch(
      authRequest(requestUrl, { headers: jsonHeaders }, csrftoken)
    );

    if (!response.ok) {
      throw new Error(
        `Error fetching dashboard navigation data (${response.status}): ${response.statusText}`
      );
    }

    const navigationData = await response.json();

    return navigationData;
  },
});
