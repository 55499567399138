import { ChunkEntry } from '@atlassian/bitkit-diff';

import { ContextDatum } from '../expand-context-saga';

const NUM_CONTEXT_LINES = 10;
// line ranges are inclusive
export const CONTEXT_LINES_RANGE = 9;

export function buildLineNumbers(length: number, startingFrom: number) {
  return Array.from({ length }, (_, k) => k + startingFrom);
}

function chunksWillConnect(
  chunks: ChunkEntry[],
  chunkIndex: number,
  shouldExpandDown: boolean
) {
  const chunk = chunks[chunkIndex];
  const isFirstChunk = chunkIndex === 0;
  const isLastChunk = chunkIndex >= chunks.length - 1;

  if (shouldExpandDown) {
    if (isLastChunk) {
      return false;
    }
    const contextLinesBottom =
      chunk.oldStart + chunk.oldLines + NUM_CONTEXT_LINES;
    const nextChunk = chunks[chunkIndex + 1] || {};
    const nextChunkTop = nextChunk.oldStart;
    return contextLinesBottom >= nextChunkTop;
  } else {
    if (isFirstChunk) {
      return false;
    }
    const contextLinesTop = chunk.oldStart - NUM_CONTEXT_LINES;
    const nextChunk = chunks[chunkIndex - 1] || {};
    const nextChunkBottom = nextChunk.oldStart + nextChunk.oldLines;
    return contextLinesTop <= nextChunkBottom;
  }
}

function lineNumsBefore(chunk: ChunkEntry, startingFrom?: number) {
  const endingFrom = chunk.oldStart - 1;
  const endingTo = chunk.newStart - 1;

  return {
    startingFrom: startingFrom || Math.max(endingFrom - CONTEXT_LINES_RANGE, 1),
    endingFrom,
    startingTo: startingFrom
      ? endingTo - (endingFrom - startingFrom)
      : Math.max(endingTo - CONTEXT_LINES_RANGE, 1),
  };
}

function lineNumsAfter(chunk: ChunkEntry, endingFrom?: number) {
  const from = chunk.oldStart + chunk.oldLines;
  const to = chunk.newStart + chunk.newLines;

  return {
    startingFrom: from,
    endingFrom: endingFrom || from + CONTEXT_LINES_RANGE,
    startingTo: to,
  };
}

export function getContextData(
  chunks: ChunkEntry[],
  expanderIndex: number,
  shouldExpandDown: boolean
): ContextDatum {
  if (shouldExpandDown) {
    const chunkIndex = expanderIndex - 1;
    const chunk = chunks[chunkIndex];

    let lineNums;

    if (chunksWillConnect(chunks, chunkIndex, true)) {
      const nextChunk = chunks[chunkIndex + 1];

      lineNums = lineNumsAfter(chunk, nextChunk.oldStart - 1);
    } else {
      lineNums = lineNumsAfter(chunk);
    }

    return {
      beforeOrAfter: 'after',
      chunkId: chunk.id,
      lineNums,
    };
  } else {
    const chunkIndex = expanderIndex;
    const chunk = chunks[chunkIndex];

    let lineNums;

    if (chunksWillConnect(chunks, chunkIndex, false)) {
      const prevChunk = chunks[chunkIndex - 1];

      lineNums = lineNumsBefore(chunk, prevChunk.oldStart + prevChunk.oldLines);
    } else {
      lineNums = lineNumsBefore(chunk);
    }

    return {
      beforeOrAfter: 'before',
      chunkId: chunk.id,
      lineNums,
    };
  }
}

function lineNumsBeforeTopicDiff(chunk: ChunkEntry, startingTo?: number) {
  const endingFrom = chunk.oldStart - 1;
  const endingTo = chunk.newStart - 1;

  return {
    endingTo,
    startingTo: startingTo || Math.max(endingTo - CONTEXT_LINES_RANGE, 1),
    startingFrom: startingTo
      ? endingFrom - (endingTo - startingTo)
      : Math.max(endingFrom - CONTEXT_LINES_RANGE, 1),
  };
}

function lineNumsAfterTopicDiff(chunk: ChunkEntry, endingTo?: number) {
  const from = chunk.oldStart + chunk.oldLines;
  const to = chunk.newStart + chunk.newLines;

  return {
    startingFrom: from,
    startingTo: to,
    endingTo: endingTo || to + CONTEXT_LINES_RANGE,
  };
}

// TODO: after we fully move topic diffs we can remove the implementations of
// getContextData and lineNumsBefore/After above - which are still being used
// on the PR view to support preview-merge diffs
export function getContextDataTopicDiff(
  chunks: ChunkEntry[],
  expanderIndex: number,
  shouldExpandDown: boolean
): ContextDatum {
  if (shouldExpandDown) {
    const chunkIndex = expanderIndex - 1;
    const chunk = chunks[chunkIndex];

    let lineNums;

    if (chunksWillConnect(chunks, chunkIndex, true)) {
      const nextChunk = chunks[chunkIndex + 1];
      lineNums = lineNumsAfterTopicDiff(chunk, nextChunk.newStart - 1);
    } else {
      lineNums = lineNumsAfterTopicDiff(chunk);
    }

    return {
      beforeOrAfter: 'after',
      chunkId: chunk.id,
      lineNums,
    };
  } else {
    const chunkIndex = expanderIndex;
    const chunk = chunks[chunkIndex];

    let lineNums;

    if (chunksWillConnect(chunks, chunkIndex, false)) {
      const prevChunk = chunks[chunkIndex - 1];

      lineNums = lineNumsBeforeTopicDiff(
        chunk,
        prevChunk.newStart + prevChunk.newLines
      );
    } else {
      lineNums = lineNumsBeforeTopicDiff(chunk);
    }

    return {
      beforeOrAfter: 'before',
      chunkId: chunk.id,
      lineNums,
    };
  }
}

/**
 * helper to parse context lines into array
 * The internal src api will return context lines in the format "line1\nline2\nline3\n"
 */
export function parseSrcApiLines(srcLines: string): string[] {
  // some files are not newline terminated (BCLOUD-22333)
  if (srcLines.slice(-1) === '\n') {
    return srcLines.split('\n').slice(0, -1);
  } else {
    return srcLines.split('\n');
  }
}
