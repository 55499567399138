export class SearchLog {
  readonly matchedLines: number[] = [];
  readonly currentLineIndex: number = -1;
  readonly isEnabled: boolean = false;
  readonly isErrored: boolean = false;
  readonly search: string = '';

  constructor(props: Partial<SearchLog> = {}) {
    Object.assign(this, props);
    Object.freeze(this);
  }
}
