/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { gridSize } from '@atlaskit/theme';

export const NameCellContainer = styled.div<{
  padding: number;
}>`
  display: flex;
  padding: ${props => props.padding}px;
  align-items: center;

  &:hover,
  &:focus {
    cursor: default;
  }
`;

export const NameCellAvatar = styled.div`
  margin-right: ${gridSize()}px;
`;

export const NameCellText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
