import { FILTER_KIND } from 'src/sections/profile/constants';
import { RepositoryFilters } from 'src/sections/profile/types';

export const buildProfileRepositoryBBQL = (
  filter: RepositoryFilters | undefined
): string | null => {
  if (!filter) {
    return null;
  }

  const queries: string[] = [];

  if (filter.search) {
    queries.push(
      `(name ~ "${filter.search}" OR description ~ "${filter.search}")`
    );
  }

  if (filter.privacy) {
    queries.push(`is_private = ${filter.privacy === FILTER_KIND.PRIVATE}`);
  }

  if (filter.language) {
    queries.push(`language = "${filter.language}"`);
  }

  if (filter.watching) {
    queries.push(`followers.uuid = "${filter.watching}"`);
  }

  if (filter.project) {
    queries.push(`(project.uuid = "${filter.project}")`);
  }

  return queries.join(' AND ');
};
