import { renderLogGroup } from '../components/log-viewer/utils/renderLogGroup';

import { StepCommand } from './LogCommands';

export type LogRequestMeta = {
  pipelineUuid: string;
  stepUuid: string;
  range: {
    rangeStart: number;
    rangeEnd: number;
  };
  index?: number;
  requestCounter?: number;
  requestType?: LogRequestType;
};

export type StepLogMap = Map<number, string[][]>;

export enum LogRequestType {
  // this is to get the rest of logs for a command when it's not yet fetched but next streaming command already started
  PREV_COMMAND_LOG = 'command log',
  // current streaming request
  STREAMING = 'streaming',
  // clicks on command
  CLICK = 'command click',
  // on load to open commands automatically
  ON_LOAD = 'on load',
  // search or download
  SEARCH_DOWNLOAD = 'search & download',
}

export type LinkifyProps = {
  logLines: string[];
};

export type FlatNestedLogLinesProps = {
  stepLog: StepLogMap;
  logLines: string[];
  meta: LogRequestMeta;
};

export type LogLineWorkerProps = LinkifyProps & FlatNestedLogLinesProps;
export type LogLineWorkerReturnTypes =
  | string[]
  | {
      logLines: string[];
      stepLog: StepLogMap;
    };

export type LogLine = StepCommand | string;
export type RenderLogItem = (item: LogLine, index: number) => JSX.Element;
export type ChildRenderFuncType = typeof renderLogGroup | RenderLogItem;
export type LogLineOrJSXElement = LogLine | JSX.Element;
