/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { all, takeLatest } from 'redux-saga/effects';

import deploymentsSaga from 'src/components/pipelines/redux/sagas/deployments-saga';
import { pipelinesSaga } from 'src/components/pipelines/redux/sagas/pipelines-saga';
import branchesSagas from 'src/redux/branches/sagas';
import commitListSagas from 'src/redux/commit-list/sagas';
import createBranchSagas from 'src/redux/create-branch/sagas';
import dashboardRepositoryBuildStatusesSagas from 'src/redux/dashboard/sagas';
import diffSettingsSagas from 'src/redux/diff-settings/sagas';
import { SCROLL_TO, UPDATE_NAVIGATION_STATE } from 'src/redux/global/actions';
import bootstrapSaga from 'src/redux/global/sagas/bootstrap-saga';
import drawerSagas from 'src/redux/global/sagas/drawer-sagas';
import offlineSaga from 'src/redux/global/sagas/offline-saga';
import pageVisibilitySaga from 'src/redux/global/sagas/page-visibility-saga';
import scrollToSaga from 'src/redux/global/sagas/scroll-to-saga';
import toggleNavigationSaga from 'src/redux/global/sagas/toggle-navigation-saga';
import updateNavigationStateSaga from 'src/redux/global/sagas/update-navigation-state-saga';
import jiraSagas from 'src/redux/jira/sagas';
import pullRequestListSagas from 'src/redux/pull-request-list/sagas';
import pullRequestSagas from 'src/redux/pull-request/sagas';
import cacheRecentlyViewedRepositorySaga from 'src/redux/recently-viewed-repositories/sagas/cache-recently-viewed-repository-saga';
import branchSagas from 'src/redux/repository/branch/sagas';
import searchSagas from 'src/redux/search/sagas';
import {
  toggleSidebarSaga,
  updateSidebarStateSaga,
  UPDATE_SIDEBAR_STATE,
} from 'src/redux/sidebar';
import cacheRecentlyViewedWorkspaceSaga from 'src/redux/workspaces/sagas/cache-recently-viewed-workspace-saga';
import fetchWorkspacePlanSaga from 'src/redux/workspaces/sagas/fetch-workspace-plan-saga';
import loadWorkspaceSuccessSaga from 'src/redux/workspaces/sagas/load-workspace-success-saga';
import pipelinesEnabledSaga from 'src/sagas/fetch-pipelines-enabled-saga';
import toggleRepositoryWatch from 'src/sections/repository/sagas/toggle-repository-watch';
import sourceSagas from 'src/sections/repository/sections/source/sagas';

import fetchActionSaga, { fetchLatestActionSaga } from './fetch-action-saga';
import loadRepositoryErrorSaga from './load-repository-error-saga';
import loadRepositorySuccessSaga from './load-repository-success-saga';
import removeRecentlyViewedRepositorySaga from './remove-recently-viewed-repository-saga';
import unloadRepositorySaga from './unload-repository-saga';
import updateRepoNavMenuItemUrls from './update-repo-nav-menu-item-urls';

export { default as bufferEvery } from './buffer-every';

export default function* rootSaga() {
  yield all([
    updateRepoNavMenuItemUrls(),
    pipelinesEnabledSaga(),
    deploymentsSaga(),
    pipelinesSaga(),
    fetchWorkspacePlanSaga(),
    bootstrapSaga(),
    pageVisibilitySaga(),
    commitListSagas(),
    pullRequestSagas(),
    branchSagas(),
    pullRequestListSagas(),
    fetchActionSaga(),
    fetchLatestActionSaga(),
    loadRepositorySuccessSaga(),
    loadWorkspaceSuccessSaga(),
    loadRepositoryErrorSaga(),
    unloadRepositorySaga(),
    cacheRecentlyViewedRepositorySaga(),
    cacheRecentlyViewedWorkspaceSaga(),
    removeRecentlyViewedRepositorySaga(),
    sourceSagas(),
    toggleRepositoryWatch(),
    toggleSidebarSaga(),
    branchesSagas(),
    createBranchSagas(),
    dashboardRepositoryBuildStatusesSagas(),
    offlineSaga(),
    searchSagas(),
    toggleNavigationSaga(),
    drawerSagas(),
    jiraSagas(),
    diffSettingsSagas(),
    takeLatest(UPDATE_SIDEBAR_STATE, updateSidebarStateSaga),
    takeLatest(UPDATE_NAVIGATION_STATE, updateNavigationStateSaga),
    takeLatest(SCROLL_TO, scrollToSaga),
  ]);
}
