/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

// overrides any alignment that the parent component
// (like the PR sidebar) applies
export const SectionErrorContainer = styled.div`
  text-align: left;
`;

export const SentryErrorCode = styled.code`
  word-break: break-word;
`;
