/**
 * Flags that pipelines need to create in statsig.
 * Follow https://hello.atlassian.net/wiki/spaces/BB/pages/2990998399/Pipelines+UI+-+Using+Feature+Flags to create new flags in statsig
 * No New flags should be added in this file
 */
export const pipelinesLDFeatureKeys = {
  // to be migrated long-living flags or unreleased features
  pipelinesArtifactPreviewSizeLimit: 'pipelines-artifact-preview-size-limit',
  pipelinesMaximumAllowedStepSizeForCloudRunners:
    'pipelines-maximum-allowed-step-size-for-cloud-runners',

  // need to be checked - half released or released but with certain segments turned off
  isDeploymentsFilteringEnabled: 'deployments-filtering', // check with Liam(Peter is no longer with pipelines)
};
