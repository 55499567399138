/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { Action } from 'redux';
import { call, select } from 'redux-saga/effects';

import { uncurlyUuid } from 'src/components/analytics';
import { Repository } from 'src/components/types';
import {
  DiffViewMode,
  FileViewMode,
  getGlobalDiffViewMode,
  getGlobalFileViewMode,
  getGlobalTabSize,
  getGlobalIsWordDiffEnabled,
  getGlobalShouldIgnoreWhitespace,
} from 'src/redux/diff-settings';
import { PullRequestEventAttributesType } from 'src/redux/pull-request/actions';
import {
  publishScreenEvent,
  publishUiEvent,
  publishTrackEvent,
} from 'src/utils/analytics/publish';

import {
  getCurrentPullRequestId,
  getPullRequestSourceRepo,
  getPullRequestDestinationRepo,
  getCurrentPullRequestRoleForCurrentUser,
} from '../selectors';

export const PULL_REQUEST_SCREEN_NAME = 'pullRequestScreen';
export const PIPELINE_PULL_REQUEST_BANNER_SCREEN_NAME =
  'pipelinesPullRequestBanner';

export function* publishPipelinesScreenEventSaga(
  action: Action & {
    payload: PullRequestEventAttributesType;
  }
) {
  yield call(publishScreenEvent, PIPELINE_PULL_REQUEST_BANNER_SCREEN_NAME, {
    action: action.payload.action,
    actionSubject: action.payload.actionSubject,
    actionSubjectId: action.payload.actionSubjectId,
    source: PIPELINE_PULL_REQUEST_BANNER_SCREEN_NAME,
    pipelinesPremium: action.payload.pipelinesPremium,
    paidWorkspace: action.payload.paidWorkspace,
    premiumWorkspace: action.payload.premiumWorkspace,
  });
}

export function* publishScreenEventSaga() {
  const diffView: DiffViewMode = yield select(getGlobalDiffViewMode);
  const fileView: FileViewMode = yield select(getGlobalFileViewMode);
  const tabSize: number = yield select(getGlobalTabSize);
  const enableWordDiff: boolean = yield select(getGlobalIsWordDiffEnabled);
  const ignoreWhitespace: boolean = yield select(
    getGlobalShouldIgnoreWhitespace
  );
  const role: string | null = yield select(
    getCurrentPullRequestRoleForCurrentUser
  );
  const pullRequestId: number = yield select(getCurrentPullRequestId);
  const pullRequestSourceRepo: Repository = yield select(
    getPullRequestSourceRepo
  );

  yield call(publishScreenEvent, PULL_REQUEST_SCREEN_NAME, {
    diffView: diffView === DiffViewMode.SideBySide ? 'sideBySide' : 'unified',
    fileView:
      fileView === FileViewMode.List
        ? 'list'
        : fileView === FileViewMode.Tree
        ? 'tree'
        : null,
    tabSize,
    enableWordDiff,
    ignoreWhitespace,
    pullRequestLocalId: pullRequestId,
    repoUuid: pullRequestSourceRepo?.uuid,
    role,
  });
}

export function* publishTrackEventSaga(
  action: Action & {
    payload: PullRequestEventAttributesType;
  }
) {
  const pullRequestId: number = yield select(getCurrentPullRequestId);
  const pullRequestSourceRepo: Repository = yield select(
    getPullRequestSourceRepo
  );

  yield call(publishTrackEvent, {
    action: action.payload.action,
    actionSubject: action.payload.actionSubject,
    actionSubjectId: action.payload.actionSubjectId,
    source: PULL_REQUEST_SCREEN_NAME,
    objectType: 'pullRequest',
    objectId: `${uncurlyUuid(
      pullRequestSourceRepo?.uuid || ''
    )}/${pullRequestId}`,
    attributes: action.payload.attributes,
  });
}

export function* publishUiEventSaga(
  action: Action & {
    payload: PullRequestEventAttributesType;
  }
) {
  const pullRequestId: number = yield select(getCurrentPullRequestId);
  const pullRequestDestinationRepo: Repository = yield select(
    getPullRequestDestinationRepo
  );
  const pullRequestSourceRepo: Repository = yield select(
    getPullRequestSourceRepo
  );

  yield call(publishUiEvent, {
    action: action.payload.action,
    actionSubject: action.payload.actionSubject,
    actionSubjectId: action.payload.actionSubjectId,
    source: 'pullRequest',
    objectId: uncurlyUuid(pullRequestSourceRepo?.uuid),
    objectType: 'repository',
    containerId: uncurlyUuid(pullRequestSourceRepo?.workspace?.uuid || ''),
    containerType: 'workspace',
    attributes: {
      pullRequestId,
      toRepositoryUuid: uncurlyUuid(pullRequestDestinationRepo?.uuid),
      toRepositoryOwnerUuid: uncurlyUuid(
        pullRequestDestinationRepo?.owner?.uuid || ''
      ),
      ...action.payload.attributes,
    },
  });
}
