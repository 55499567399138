import { LoadingStatus } from 'src/constants/loading-status';
import createReducer from 'src/utils/create-reducer';

import {
  FETCH_PULL_REQUEST_JIRA_ISSUES,
  UPDATE_JIRA_ISSUES_LIST_AFTER_CREATION,
  FETCH_AVAILABLE_ISSUE_TRANSITIONS,
  TRANSITION_ISSUES,
  UPDATE_ISSUE_TRANSITION,
  INIT_ISSUE_TRANSITIONS,
  FETCH_TRANSITION_USER_PREFERENCE,
} from '../actions';
import { PrCommentJiraIssue, IssueTransitionFormRowData } from '../types';

export type PullRequestJiraIssuesState = {
  jiraIssues: PrCommentJiraIssue[];
  jiraIssuesFetchedStatus: LoadingStatus;
  issueTransitionFormData: IssueTransitionFormRowData[];
  transitionStatus: LoadingStatus;
  issueTransitionPreference?: string;
  issueTransitionPreferenceFetchedStatus: LoadingStatus;
};

const initialState: PullRequestJiraIssuesState = {
  jiraIssues: [],
  jiraIssuesFetchedStatus: LoadingStatus.Before,
  issueTransitionFormData: [],
  transitionStatus: LoadingStatus.Before,
  issueTransitionPreference: undefined,
  issueTransitionPreferenceFetchedStatus: LoadingStatus.Before,
};

export default createReducer(initialState, {
  [FETCH_TRANSITION_USER_PREFERENCE.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      issueTransitionPreferenceFetchedStatus: LoadingStatus.Success,
      issueTransitionPreference: payload,
    };
  },
  [FETCH_TRANSITION_USER_PREFERENCE.REQUEST]: state => {
    return {
      ...state,
      issueTransitionPreferenceFetchedStatus: LoadingStatus.Fetching,
      issueTransitionPreference: undefined,
    };
  },
  [FETCH_TRANSITION_USER_PREFERENCE.ERROR]: state => {
    return {
      ...state,
      issueTransitionPreferenceFetchedStatus: LoadingStatus.Failed,
      issueTransitionPreference: undefined,
    };
  },
  [FETCH_PULL_REQUEST_JIRA_ISSUES.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      jiraIssuesFetchedStatus: LoadingStatus.Success,
      jiraIssues: payload,
    };
  },
  [FETCH_PULL_REQUEST_JIRA_ISSUES.REQUEST]: state => {
    return {
      ...state,
      jiraIssuesFetchedStatus: LoadingStatus.Before,
      jiraIssues: [],
    };
  },
  [FETCH_PULL_REQUEST_JIRA_ISSUES.ERROR]: state => ({
    ...state,
    jiraIssuesFetchedStatus: LoadingStatus.Failed,
    jiraIssues: [],
  }),
  [FETCH_AVAILABLE_ISSUE_TRANSITIONS.SUCCESS]: (state, { payload }) => {
    const { updatedTransitionForm, index } = payload;
    const updatedIssueTransitionFormData = state.issueTransitionFormData;
    updatedIssueTransitionFormData[index] = updatedTransitionForm;
    return {
      ...state,
      issueTransitionFormData: updatedIssueTransitionFormData,
    };
  },
  [FETCH_AVAILABLE_ISSUE_TRANSITIONS.REQUEST]: (state, { payload }) => {
    return {
      ...state,
      issueTransitionFormData: state.issueTransitionFormData.map((row, i) =>
        i === payload
          ? {
              ...row,
              availableIssueTransitions: [],
              availableIssueTransitionsFetchedStatus: LoadingStatus.Before,
            }
          : row
      ),
    };
  },
  [FETCH_AVAILABLE_ISSUE_TRANSITIONS.ERROR]: (state, { payload }) => {
    return {
      ...state,
      issueTransitionFormData: state.issueTransitionFormData.map((row, i) =>
        i === payload
          ? {
              ...row,
              availableIssueTransitions: [],
              availableIssueTransitionsFetchedStatus: LoadingStatus.Failed,
            }
          : row
      ),
    };
  },
  [UPDATE_JIRA_ISSUES_LIST_AFTER_CREATION]: (state, { payload }) => ({
    ...state,
    jiraIssues: [...state.jiraIssues, payload],
  }),
  [UPDATE_ISSUE_TRANSITION]: (state, { payload }) => ({
    ...state,
    issueTransitionFormData: state.issueTransitionFormData.map(
      (currentTransition, i) =>
        i === payload.index ? payload.newTransition : currentTransition
    ),
  }),
  [INIT_ISSUE_TRANSITIONS]: (state, { payload }) => ({
    ...state,
    issueTransitionFormData: payload,
  }),
  [TRANSITION_ISSUES.REQUEST]: state => ({
    ...state,
    transitionStatus: LoadingStatus.Before,
  }),
  [TRANSITION_ISSUES.SUCCESS]: state => ({
    ...state,
    transitionStatus: LoadingStatus.Success,
  }),
  [TRANSITION_ISSUES.ERROR]: state => ({
    ...state,
    transitionStatus: LoadingStatus.Failed,
  }),
});
