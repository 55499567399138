import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["indent", "width"];
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { N800 } from '@atlaskit/theme/colors';
import { indentBase } from './styled';
var commonStyles = css({
  display: 'flex',
  boxSizing: 'border-box',
  minHeight: 40,
  padding: "var(--ds-space-100, 10px)".concat(" ", indentBase, " ", "var(--ds-space-100, 10px)", " var(--indent, ").concat(indentBase, ")"),
  position: 'relative',
  alignItems: 'center',
  color: "var(--ds-text, ".concat(N800, ")"),
  lineHeight: "var(--ds-font-lineHeight-200, 20px)"
});
/**
 * __Common cell__
 */
var CommonCell = function CommonCell(_ref) {
  var indent = _ref.indent,
    width = _ref.width,
    props = _objectWithoutProperties(_ref, _excluded);
  return jsx("div", _extends({}, props, {
    style: {
      '--indent': indent,
      width: width
    },
    css: commonStyles
  }));
};
export default CommonCell;