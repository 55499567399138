var isPerformanceAvailable = function isPerformanceAvailable() {
  return typeof window !== 'undefined' && 'performance' in window && !!performance.now;
};
var performanceAvailable = isPerformanceAvailable();
var initialPageTime = Date.now();
export var getPageTime = function getPageTime() {
  if (performanceAvailable) {
    return performance.now();
  }
  return Date.now() - initialPageTime;
};