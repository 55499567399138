import React from 'react';

import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Flag from '@atlaskit/flag';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import { getCompareBranchesDialogSlice } from 'src/redux/branches';
import { ComponentFlagId, HandleDismissedType } from 'src/redux/flags/types';

import messages from './compare-branches-timeout-flag.i18n';

type Props = {
  id: ComponentFlagId;
} & HandleDismissedType;

export const CompareBranchesTimeoutFlag = ({ id, ...otherProps }: Props) => {
  const intl = useIntl();
  const { isMerge } = useSelector(getCompareBranchesDialogSlice);
  const action = isMerge ? 'merge' : 'sync';
  return (
    <Flag
      {...otherProps}
      id={id}
      icon={
        <InfoIcon
          label="info"
          primaryColor={token('color.icon.discovery', colors.P300)}
        />
      }
      title={intl.formatMessage(messages[action].compareBranchesTimeoutTitle)}
      description={
        <FormattedMessage
          {...messages[action].compareBranchesTimeoutDescription}
        />
      }
    />
  );
};
