// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { gridSize } from '@atlaskit/theme/constants';
export var AVATAR_SIZES = {
  xsmall: gridSize() * 2,
  small: gridSize() * 3,
  medium: gridSize() * 4,
  large: gridSize() * 5,
  xlarge: gridSize() * 12,
  xxlarge: gridSize() * 16
};

// border radius only applies to "square" avatars
export var AVATAR_RADIUS = {
  xsmall: 2,
  small: 2,
  medium: 3,
  large: 3,
  xlarge: 6,
  xxlarge: 12
};
export var BORDER_WIDTH = 2;
export var ACTIVE_SCALE_FACTOR = 0.9;
export var CSS_VAR_AVATAR_BGCOLOR = '--avatar-background-color';
export var ICON_SIZES = {
  small: 12,
  medium: 14,
  large: 15,
  xlarge: 18
};
export var ICON_OFFSET = {
  small: 0,
  medium: 0,
  large: 1,
  xlarge: 7
};