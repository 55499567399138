import { stringify } from 'qs';

import { fetchAction } from 'src/redux/actions';
import { FetchSourceRepositoryDetails } from 'src/sections/repository/actions';
import urls from 'src/sections/repository/urls';

export const fetchSourceRepositoryDetailsQuery = stringify({
  fields: '+parent.mainbranch.*',
});

export const fetchSourceRepositoryDetails = (repositoryFullSlug: string) => {
  return fetchAction(FetchSourceRepositoryDetails, {
    url: `${urls.api.internal.details(
      repositoryFullSlug
    )}?${fetchSourceRepositoryDetailsQuery}`,
  });
};
