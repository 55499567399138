"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var __1 = require("..");
var errors_1 = require("./errors");
var PROD_BASE_URL = 'https://api.atlassian.com/flags';
var STAGING_BASE_URL = 'https://api.stg.atlassian.com/flags';
var DEV_BASE_URL = 'https://api.dev.atlassian.com/flags';
var Fetcher = /** @class */ (function () {
    function Fetcher(apiKey, serviceEnv, baseUrl) {
        if (serviceEnv === void 0) { serviceEnv = __1.EnvironmentType.PROD; }
        this.apiKey = apiKey;
        this.baseUrl = Fetcher.buildBaseUrl(serviceEnv, baseUrl);
    }
    Fetcher.prototype.fetchFeatureFlags = function (user, metadata, version) {
        var requestBody = {
            apiKey: this.apiKey,
            identifier: user.identifier,
            isAnonymous: user.isAnonymous,
            additionalIdentifiers: user.additionalIdentifiers,
            customAttributes: user.custom,
            versionData: version,
            metadata: metadata,
        };
        return fetch(this.baseUrl + "/api/v1/frontend/featureFlagValues", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Client-Name': metadata.client.name,
                'X-Client-Version': metadata.client.version,
                'X-Api-Key': this.apiKey,
            },
            body: JSON.stringify(requestBody),
        })
            .then(function (response) { return Fetcher.handleResponseError(response); })
            .then(function (response) { return Fetcher.extractBody(response); })
            .catch(function (error) { return Promise.reject(error); });
    };
    Fetcher.handleResponseError = function (response) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var body;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!response.ok) return [3 /*break*/, 2];
                        return [4 /*yield*/, response.text()];
                    case 1:
                        body = _a.sent();
                        throw new errors_1.ResponseError(response.status, body);
                    case 2: return [2 /*return*/, response];
                }
            });
        });
    };
    Fetcher.extractBody = function (response) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (response.status === 204) {
                    return [2 /*return*/, {}];
                }
                return [2 /*return*/, response.text().then(function (text) { return JSON.parse(text || '{}'); })];
            });
        });
    };
    Fetcher.buildBaseUrl = function (serviceEnv, baseUrl) {
        if (baseUrl) {
            return baseUrl;
        }
        if (serviceEnv === __1.EnvironmentType.DEV || serviceEnv === __1.EnvironmentType.LOCAL) {
            return DEV_BASE_URL;
        }
        if (serviceEnv === __1.EnvironmentType.STAGING) {
            return STAGING_BASE_URL;
        }
        return PROD_BASE_URL;
    };
    return Fetcher;
}());
exports.default = Fetcher;
