import React, { Component, Fragment } from 'react';

import { get } from 'lodash-es';
// @ts-ignore TODO: fix noImplicitAny error here
import Helmet from 'react-helmet';

import { HORIZONTAL_GLOBAL_NAV_HEIGHT } from '@atlaskit/atlassian-navigation';
import {
  AddonManager,
  ConnectHost,
  replaceContentHandler,
} from '@atlassian/bitbucket-connect-js';
import {
  ConnectModuleIframe,
  ConnectModules,
  ConnectModulesProps,
} from '@atlassian/bitbucket-connect-react';

import { GlobalError } from 'src/connect/shared/global-error';
import { repositoryTarget } from 'src/connect/targets';
import { Redirect } from 'src/router/components';
import { RouteComponentProps } from 'src/router/types';
import { withRouter } from 'src/router/utils';
import {
  publishUiEvent,
  publishScreenEvent,
} from 'src/utils/analytics/publish';
import { captureMessageWithTags } from 'src/utils/sentry';

type ConnectRepoPageProps = RouteComponentProps & {
  updateMenuItems: (oldRoute: string, newRoute: string) => void;
  repositoryOwner: string | null | undefined;
  repositorySlug: string | null | undefined;
  appKey: string | null | undefined;
  moduleKey: string | null | undefined;
  principalId: string | undefined;
};

export class ConnectRepoPage extends Component<ConnectRepoPageProps> {
  replaceContentHandler: {
    unlisten: () => void;
  };
  unlistenHistory: () => void;
  // @ts-ignore TODO: fix noImplicitAny error here
  constructor(props) {
    super(props);
    // define additional Connect modules for Pipelines usage
    if (props.appKey === 'pipelines') {
      const NotificationAPI = (window as any).Notification;
      if (NotificationAPI) {
        ConnectHost.defineModule('Notification', {
          // allow to pass permission state without broadcasting event
          [NotificationAPI.permission]: () => null,
          // @ts-ignore TODO: fix noImplicitAny error here
          show: (title, options) => new NotificationAPI(title, options),
        });
      }
    }
  }

  componentDidMount() {
    this.publishScreenEvent(this.props.appKey);
    if (!this.props.history) {
      return;
    }
    // Currently, "AP.history.popState" doesn't listen to external route changes.
    // We're using this custom event to notify the RepoPage of route changes.
    this.unlistenHistory = this.props.history.listen(
      ({ pathname, search, hash }) => {
        ConnectHost.broadcastEvent(
          'location-change',
          { addon_key: this.props.appKey },
          { pathname, search, hash }
        );
      }
    );
    // This is needed for legacy compatibility. Currently, Pipelines has an install flow
    // that swaps out the iframe after an install. As a result, we need to update the
    // the nav to reflect the new route (with new module key).
    // @ts-ignore TODO: fix noImplicitAny error here
    this.replaceContentHandler = replaceContentHandler(mod => {
      if (mod) {
        const { repositoryOwner, repositorySlug, appKey, moduleKey } =
          this.props;
        const {
          app_key: aKey,
          descriptor: { key: mKey },
        } = mod;
        if (repositoryOwner && repositorySlug && appKey && moduleKey) {
          const oldRoute = `/${repositoryOwner}/${repositorySlug}/addon/${appKey}/${moduleKey}`;
          const newRoute = `/${repositoryOwner}/${repositorySlug}/addon/${aKey}/${mKey}`;
          this.props.updateMenuItems(oldRoute, newRoute);
          this.props.history.replace(newRoute);
        }
      }
    });
  }

  shouldComponentUpdate(prevProps: ConnectRepoPageProps) {
    const { principalId, repositoryOwner, repositorySlug, appKey, moduleKey } =
      this.props;
    return (
      principalId !== prevProps.principalId ||
      repositoryOwner !== prevProps.repositoryOwner ||
      repositorySlug !== prevProps.repositorySlug ||
      appKey !== prevProps.appKey ||
      moduleKey !== prevProps.moduleKey
    );
  }

  componentDidUpdate(prevProps: ConnectRepoPageProps) {
    if (prevProps.appKey !== this.props.appKey) {
      this.publishScreenEvent(this.props.appKey);
    }
  }

  componentWillUnmount() {
    if (typeof this.unlistenHistory === 'function') {
      this.unlistenHistory();
    }
    if (
      this.replaceContentHandler &&
      typeof this.replaceContentHandler.unlisten === 'function'
    ) {
      this.replaceContentHandler.unlisten();
    }

    // This clears addon cache when component unmounts
    AddonManager.removeModules();
  }

  publishScreenEvent(addonKey?: string | null) {
    if (addonKey) {
      publishScreenEvent('addonRepositoryPage', {
        addonKey,
      });
    }
  }

  cacheResolver = (props: ConnectModulesProps) => {
    const { repositoryOwner, repositorySlug, appKey, moduleKey } = this.props;
    if (!repositoryOwner || !repositorySlug || !appKey || !moduleKey) {
      return [];
    }
    const target = repositoryTarget({ repositoryOwner, repositorySlug });
    return props.addonManager.filterModules({
      appKey,
      moduleKey,
      targetHref: target.links.self.href,
    });
  };

  render() {
    const { repositoryOwner, repositorySlug, appKey, moduleKey, principalId } =
      this.props;

    if (
      !principalId ||
      !repositoryOwner ||
      !repositorySlug ||
      !appKey ||
      !moduleKey
    ) {
      return null;
    }

    const mod = {
      appKey,
      // moduleKey, // This has been left out so the associated addon will be returned
      moduleType: 'repoPages',
      location: 'org.bitbucket.repository.navigation',
    };

    // This is incorrectly typed, as the ConnectModules component requires the
    // links property but we're deleting that property conditionally below.
    // This was typed as any when migrating to Typescript 4.1 as it was flagged
    // in order to avoid making too many changes during the migration
    // TODO: remove "any" type and update logic to handle deleted properties
    const target: any = repositoryTarget({
      repositoryOwner,
      repositorySlug,
    });

    // this allows repo pages to be accessed via UUID
    if (repositorySlug.charAt(0) === '{') {
      target.uuid = repositorySlug;
      delete target.full_name;
      delete target.name;
      // remove links object as the selfHref returned from server is
      // in the format {repositoryOwner}/{repositorySlug} and will not match
      delete target.links;
    }

    const query = [
      {
        target,
        modules: [
          mod,
          { ...mod, location: 'org.bitbucket.repository.actions' },
          { moduleType: 'associatedAddon' },
          { moduleType: 'generalPages' },
        ],
      },
    ];

    return (
      <ConnectModules
        principalId={principalId}
        query={query}
        cacheResolver={this.cacheResolver}
      >
        {({ modules, loading, error }) => {
          const repoPage = modules.find(
            m =>
              m.app_key === appKey &&
              m.descriptor.key === moduleKey &&
              m.module_type === 'repoPages'
          );
          if (repoPage) {
            return (
              <Fragment>
                <Helmet
                  defer={false}
                  title={`/ ${get(
                    repoPage,
                    'descriptor.name.value',
                    repoPage.app_name
                  )}`}
                />
                <ConnectModuleIframe
                  key={repoPage.id}
                  module={repoPage}
                  iframeContainer={({ children }) => (
                    <Fragment>{children}</Fragment>
                  )}
                  defaultStyles={{
                    display: 'block',
                    height: `calc(100vh - ${HORIZONTAL_GLOBAL_NAV_HEIGHT}px`,
                    width: '100%',
                  }}
                  options={{
                    isFullPage: true,
                    __featuresInternal: {},
                  }}
                  publishUiEvent={publishUiEvent}
                  captureMessageWithTags={captureMessageWithTags}
                />
              </Fragment>
            );
          }
          if (!loading && error) {
            return <GlobalError />;
          }
          // links to pipelines installer should redirect to pipelines if pipelines is installed
          // initially pipelines installer is an repoPages module
          const pipelinesInstaller = modules.find(
            m =>
              m.app_key === appKey &&
              appKey === 'pipelines-installer' &&
              m.module_type === 'associatedAddon'
          );
          if (pipelinesInstaller) {
            return (
              <Redirect
                to={`/${repositoryOwner}/${repositorySlug}/addon/pipelines/home`}
              />
            );
          }
          return null;
        }}
      </ConnectModules>
    );
  }
}

export default withRouter(ConnectRepoPage);
