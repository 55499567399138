import {MessageProvider} from '../MessageProvider';
import ValidationUtil from '../../../definitions/validation/ValidationUtil';
import {MessageCreationOptions} from "../MessageCreationOptions";

/**
 * This class validates an instance of MessageProvider. This is intended to be used by consumers of this library in
 * their tests to validate their implementations. Knowing products have incorporated this into their test suite will
 * allow breaking changes to be introduced in the library.
 */
export class MessageProviderValidator {

  public static validate(messageProvider: MessageProvider): void {
    ValidationUtil.checkFunctionExists('messageProvider', messageProvider, 'clear');
    ValidationUtil.checkFunctionExists('messageProvider', messageProvider, 'onClose');

    const creationRoutineNames = ['generic', 'hint', 'info', 'success', 'warning', 'error'];
    creationRoutineNames.forEach((creationRoutineName) => {
      ValidationUtil.checkFunctionExists('messageProvider', messageProvider, creationRoutineName);

      const messageId = 'message-' + (new Date()).getMilliseconds();
      const title = 'My Message';
      const body = 'My message content';
      const messageOptions : MessageCreationOptions = {
        id: messageId,
        closeable: true,
        fadeout: true,
        delay: 2000,
        duration: 4000
      };
      if (creationRoutineName === 'generic') {
        messageProvider.generic(title, body, messageOptions);
      } else if (creationRoutineName === 'hint') {
        messageProvider.hint(title, body, messageOptions);
      } else if (creationRoutineName === 'info') {
        messageProvider.info(title, body, messageOptions);
      } else if (creationRoutineName === 'success') {
        messageProvider.success(title, body, messageOptions);
      } else if (creationRoutineName === 'warning') {
        messageProvider.warning(title, body, messageOptions);
      } else if (creationRoutineName === 'error') {
        messageProvider.error(title, body, messageOptions);
      }
      ValidationUtil.checkExists('messageId', messageId);

      let dummyOnCloseCalled = false;
      let onCloseCalled = false;
      messageProvider.onClose(messageId, function() {
        onCloseCalled = true;
      });
      messageProvider.onClose('dummy-message-id', function() {
        dummyOnCloseCalled = true;
      });

      messageProvider.clear(messageId);
      if (!onCloseCalled) {
        throw new Error('Expected onClose callback to be called after calling clear.');
      }
      if (dummyOnCloseCalled) {
        throw new Error('Did not expect the dummy onClose callback to be called.');
      }
    });
  }

}
