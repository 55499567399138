import { RouterActionsType } from 'react-resource-router';

import isInIframe from './isInIframe';

export default function handleClick(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  e: any,
  routerActions: RouterActionsType
): void {
  if (!isInIframe() && e?.currentTarget?.href) {
    e?.preventDefault();
    routerActions.push(`${e.currentTarget.href}${location.search}`);
  }
}
