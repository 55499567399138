import qs from 'qs';

import { settings } from 'src/settings';
import escapeBbqlString from 'src/utils/escape-bbql-string';

import { RefType } from './types';

export type GroupPrivilegeToAdd = {
  name: string;
  slug: string;
  owner: { uuid: string };
};

export type NameFilter = 'ONLY' | 'SKIP' | undefined;

function projectsUrlNameQ(url: string, name: string) {
  // public and internal projects APIs both support the same
  // "name" search functionality
  let url_with_params = `${url}`;
  if (name) {
    const q = `name ~ "${escapeBbqlString(name)}"`;
    url_with_params = `${url_with_params}?${qs.stringify(
      { q, sort: 'name' },
      { skipNulls: true }
    )}`;
  }
  return url_with_params;
}

const urls = {
  api: {
    internal: {
      compare: (repositoryFullSlug: string) => `/${repositoryFullSlug}/compare`,
      commitAnnotated: (slug: string, hash: string) =>
        `/!api/internal/repositories/${slug}/changeset/${hash}/annotated_refs`,
      details: (repositoryFullSlug: string) =>
        `/!api/internal/repositories/${repositoryFullSlug}/details`,
      onlineEditorCommit: (owner: string, slug: string) =>
        `/!api/internal/repositories/${owner}/${slug}/oecommits/`,
      toggleWatch: (repositoryFullSlug: string) =>
        `/${repositoryFullSlug}/follow`,
      branchList: (owner: string, slug: string) =>
        `/!api/internal/repositories/${owner}/${slug}/branch-list/`,
      branchRestrictionsByBranch: (owner: string, slug: string) =>
        `/!api/internal/repositories/${owner}/${slug}/branch-restrictions/group-by-branch/`,
      pullRequestAuthors: (
        repositoryFullSlug: string,
        options: { status: string | null; query?: string }
      ) => {
        const url = `/!api/internal/repositories/${repositoryFullSlug}/pr-authors/`;
        if (options.query || options.status) {
          return `${url}?${qs.stringify(
            { pr_status: options.status, user_search: options.query },
            { skipNulls: true }
          )}`;
        }
        return url;
      },
      defaultCommitMsg: (
        repositoryFullSlug: string,
        mergeStrategy: string,
        sourceBranchName: string,
        destinationBranchName: string
      ) =>
        `/!api/internal/repositories/${repositoryFullSlug}/compare/default-commit-msg/${mergeStrategy}/${sourceBranchName}%0D${destinationBranchName}`,
      branchRestrictions: (owner: string, slug: string, branch: string) =>
        `/!api/internal/repositories/${owner}/${slug}/branch-restrictions/branch-summary/${branch}`,
      branchRestrictionsByPattern: (
        owner: string,
        slug: string,
        pattern: string
      ): string =>
        `/!api/internal/repositories/${owner}/${slug}/branch-restrictions/by-pattern/${pattern}`,
      branchRestrictionsByBranchType: (
        owner: string,
        slug: string,
        branchType: string
      ): string =>
        `/!api/internal/repositories/${owner}/${slug}/branch-restrictions/by-branch-type/${branchType}`,
      bulkDeleteBranches: (repositoryFullSlug: string) =>
        `/!api/internal/repositories/${repositoryFullSlug}/branches-delete`,
      repoSettingsDetails: (workspace: string, repoUuid: string) =>
        `/!api/internal/repositories/${workspace}/${repoUuid}/repo-settings-details`,
      repositoryAvatar: (workspace: string, repoUuid: string) =>
        `/${workspace}/${repoUuid}/admin/logo`,
      userPrivileges: (owner: string, slug: string) =>
        `/!api/internal/privileges/${owner}/${slug}/`,
      repoAiDesc: (fullSlug: string, sourceHash: string, destHash: string) =>
        `/!api/internal/repositories/${fullSlug}/ai-description/${fullSlug}:${sourceHash}%0D${destHash}`,
      users: (
        owner: string,
        slug: string,
        options: { term: string; has_access?: boolean }
      ) => {
        const url = `/!api/internal/repositories/${owner}/${slug}/users/`;
        if (options) {
          return `${url}${qs.stringify(options, {
            skipNulls: true,
            addQueryPrefix: true,
          })}`;
        }
        return url;
      },
      groupPrivilegesToAdd: (
        workspaceSlug: string,
        repoSlug: string,
        search?: string
      ): string => {
        const url = `/!api/internal/_group-privileges-to-add/${workspaceSlug}/${repoSlug}/`;
        if (search) {
          return `${url}${qs.stringify(
            { search },
            {
              skipNulls: true,
              addQueryPrefix: true,
            }
          )}`;
        }
        return url;
      },
      inheritedPermissions: (
        workspaceSlug: string,
        repositorySlug: string,
        options?: {
          page?: number;
          q?: string;
        }
      ) => {
        const url = `/!api/internal/inherited-repo-privileges/${workspaceSlug}/${repositorySlug}/`;
        if (options) {
          return `${url}${qs.stringify(options, {
            skipNulls: true,
            addQueryPrefix: true,
          })}`;
        }
        return url;
      },
      mergeStrategy: (repositoryOwner: string, repoSlug: string) =>
        `/!api/internal/repositories/${repositoryOwner}/${repoSlug}/merge-strategy`,
      repoWriters: (
        repositoryOwner: string,
        repoSlug: string,
        options?: { pagelen?: number; query?: string }
      ): string => {
        const url = `/!api/internal/repositories/${repositoryOwner}/${repoSlug}/repo-writers`;
        return `${url}${qs.stringify(options, {
          skipNulls: true,
          addQueryPrefix: true,
        })}`;
      },
      repoWriterGroups: (
        repositoryOwner: string,
        repoSlug: string,
        options?: { pagelen?: number; query?: string }
      ): string => {
        const url = `/!api/internal/repositories/${repositoryOwner}/${repoSlug}/repo-writer-groups`;
        return `${url}${qs.stringify(options, {
          skipNulls: true,
          addQueryPrefix: true,
        })}`;
      },
      addDefaultReviewers: (
        workspace: string,
        repoSlug: string,
        options?: { search?: string }
      ): string => {
        const url = `/!api/internal/repositories/${workspace}/${repoSlug}/add-default-reviewers`;
        return `${url}${qs.stringify(options, {
          skipNulls: true,
          addQueryPrefix: true,
        })}`;
      },
      defaultReviewGroups: (repoOwner: string, repoSlug: string): string =>
        `/!api/internal/repositories/${repoOwner}/${repoSlug}/default-review-groups`,
      accessTokens: (repoOwner: string, repoSlug: string): string =>
        `/!api/internal/repositories/${repoOwner}/${repoSlug}/access-tokens`,
      recommendedReviewers: (
        workspace_slug: string,
        repo_slug: string
      ): string =>
        `/!api/internal/repositories/${workspace_slug}/${repo_slug}/recommended-reviewers`,
      defaultMessages: (
        sourceRepoFullSlug: string,
        sourceBranchName: string,
        destRepoFullSlug: string,
        destBranchName: string
      ) =>
        `/!api/internal/repositories/${sourceRepoFullSlug}/pullrequests/default-messages/${sourceRepoFullSlug}:${encodeURIComponent(
          sourceBranchName
        )}%0D${destRepoFullSlug}:${encodeURIComponent(destBranchName)}`,
      createPullRequestData: (
        repoOwner: string,
        repoSlug: string,
        sourceBranch: string | undefined | null,
        destRepo: string | undefined | null,
        destBranch: string | undefined | null
      ) => {
        let destQuery;
        if (destRepo && destBranch) {
          destQuery = `${destRepo}:${destBranch}`;
        } else if (destBranch) {
          destQuery = `${destBranch}`;
        }
        const sourceQuery = sourceBranch ? `${sourceBranch}` : null;
        const query = qs.stringify(
          {
            source: sourceQuery,
            dest: destQuery,
          },
          {
            skipNulls: true,
            addQueryPrefix: true,
          }
        );
        return `/!api/internal/repositories/${repoOwner}/${repoSlug}/pullrequests/create-pr-data${query}`;
      },
      codeowners: (
        repoOwner: string | undefined | null,
        repoSlug: string | undefined | null,
        sourceHash: string | undefined | null,
        destHash: string | undefined | null
      ) => {
        const source = encodeURIComponent(sourceHash || '');
        const dest = encodeURIComponent(destHash || '');
        return `/!api/internal/repositories/${repoOwner}/${repoSlug}/codeowners/${source}..${dest}`;
      },
      projects: (workspace: string, name: string, permission?: string) => {
        const url = `/!api/internal/workspaces/${workspace}/projects/`;
        let url_with_params = projectsUrlNameQ(url, name);
        if (permission) {
          const sep = name ? '&' : '?';
          url_with_params = `${url_with_params}${sep}${qs.stringify(
            { permission: 'create-repo' },
            { skipNulls: true }
          )}`;
        }
        return url_with_params;
      },
      mergeChecks: (workspaceSlug: string, repoSlug: string): string =>
        `/!api/internal/repositories/${workspaceSlug}/${repoSlug}/checks-config`,
      customMergeChecks: (workspaceSlug: string, repoSlug: string): string =>
        `/!api/internal/repositories/${workspaceSlug}/${repoSlug}/checks-config/v2`,
      // TODO: BCAT-4915 rename to customMergeChecks
      customMergeChecksV3: (workspaceSlug: string, repoSlug: string): string =>
        `/!api/internal/repositories/${workspaceSlug}/${repoSlug}/checks`,
      prBatchCycleMetrics: (
        workspaceSlug: string,
        repoUuids: string[]
      ): string =>
        `/!api/internal/workspaces/${workspaceSlug}/stats/pr-cycle-time?${qs.stringify(
          { repository_uuids: repoUuids },
          { indices: false }
        )}`,
    },
    v10: {
      groups: (owner: string) => `/!api/1.0/groups/${owner}/`,
      branchTags: (workspaceSlug: string, repoSlug: string) =>
        `/!api/1.0/repositories/${workspaceSlug}/${repoSlug}/branches-tags`,
    },
    v20: {
      email: () => '/!api/2.0/user/emails',
      branches: (owner: string, slug: string) =>
        `${urls.api.v20.refs(owner, slug)}/branches`,
      findBranchNames: (
        owner: string,
        slug: string,
        params?: { name?: string; nameFilter?: NameFilter }
      ) => {
        /*
        NameFilter is used to exclude branches to work around pagination
        sometimes hiding an exact match (currently our api won't sort by the
        closest match). We can make separate calls (an exact match and the rest)
        and then merge the results.
        */
        const { name, nameFilter } = params || {};

        const url = `${urls.api.v20.refs(
          owner,
          slug
        )}/branches?fields=values.name`;
        let q;

        if (!name && nameFilter !== 'ONLY') {
          return url;
        }

        const branchName = !name ? '' : escapeBbqlString(name);

        if (nameFilter === 'ONLY') {
          q = `name = "${branchName}"`;
        } else if (nameFilter === 'SKIP') {
          q = `name != "${branchName}" AND name ~ "${branchName}"`;
        } else {
          q = `name ~ "${branchName}"`;
        }
        return `${url}&${qs.stringify({ q })}`;
      },
      branch: (owner: string, slug: string, branchName: string) =>
        `${urls.api.v20.refs(owner, slug)}/branches/${branchName}`,
      branchAbs: (owner: string, slug: string, branchName: string) =>
        `${settings().CANON_URL}${urls.api.v20.branch(
          owner,
          slug,
          branchName
        )}`,
      branchingModel: (owner: string, slug: string) =>
        `/!api/2.0/repositories/${owner}/${slug}/effective-branching-model`,
      branchingModelSettings: (owner: string, slug: string) =>
        `/!api/2.0/repositories/${owner}/${slug}/branching-model/settings`,
      commits: (owner: string, slug: string) =>
        `${urls.api.v20.repository(owner, slug)}/commits`,
      commit: (owner: string, slug: string, hash: string) =>
        `${urls.api.v20.repository(owner, slug)}/commit/${hash}`,
      defaultReviewer: (owner: string, slug: string, userUuid: string) =>
        `${urls.api.v20.repository(owner, slug)}/default-reviewers/${userUuid}`,
      defaultReviewers: (
        owner: string,
        slug: string,
        options?: { page?: number; pagelen?: number }
      ): string => {
        const url = `${urls.api.v20.repository(
          owner,
          slug
        )}/effective-default-reviewers`;
        return `${url}${qs.stringify(options, {
          skipNulls: true,
          addQueryPrefix: true,
        })}`;
      },
      forks: (owner: string, slug: string) =>
        `/!api/2.0/repositories/${owner}/${slug}/forks`,
      refs: (
        owner: string,
        slug: string,
        options?: { q?: string; sort?: string; pagelen?: number }
      ) => {
        const url = `${urls.api.v20.repository(owner, slug)}/refs`;
        if (options) {
          return `${url}?${qs.stringify(options, { skipNulls: true })}`;
        }
        return url;
      },
      repositories: () => `/!api/2.0/repositories`,
      repository: (owner: string, slug: string) =>
        `/!api/2.0/repositories/${owner}/${slug}`,
      repositoryByUuid: (uuid: string) => urls.api.v20.repository('{}', uuid),
      repositoryAbs: (owner: string, slug: string) =>
        `${settings().CANON_URL}${urls.api.v20.repository(owner, slug)}`,
      tags: (owner: string, slug: string) =>
        `${urls.api.v20.refs(owner, slug)}/tags`,
      pullRequests: (owner: string, slug: string) =>
        `/!api/2.0/repositories/${owner}/${slug}/pullrequests/`,
      pullRequestExistsUrl: (
        sourceRepoFullSlug: string,
        sourceBranch: string,
        destRepoFullSlug: string,
        destBranch: string
      ) => {
        // BBQL check for PR page to check if duplicate PR already exists
        const bbqlQuery = `source.branch.name="${escapeBbqlString(
          sourceBranch
        )}" AND source.repository.full_name="${escapeBbqlString(
          sourceRepoFullSlug
        )}" AND destination.repository.full_name="${escapeBbqlString(
          destRepoFullSlug
        )}" AND destination.branch.name="${escapeBbqlString(
          destBranch
        )}" AND state="OPEN"`;

        const bbql = encodeURIComponent(bbqlQuery);

        return `/!api/2.0/repositories/${sourceRepoFullSlug}/pullrequests/?fields=values.links.html.href&q=${bbql}`;
      },
      watchers: (owner: string, slug: string) =>
        `/!api/2.0/repositories/${owner}/${slug}/watchers`,
      deployKeys: (
        owner: string,
        slug: string,
        options?: { [key: string]: any }
      ) => {
        const url = `/!api/2.0/repositories/${owner}/${slug}/deploy-keys/`;
        if (options) {
          return `${url}${qs.stringify(options, {
            skipNulls: true,
            addQueryPrefix: true,
          })}`;
        }
        return url;
      },
      project: (workspace: string, projectKey: string) =>
        `/!api/2.0/workspaces/${workspace}/projects/${projectKey}`,
      projects: (workspace: string, name: string) => {
        const url = `/!api/2.0/workspaces/${workspace}/projects/`;
        return projectsUrlNameQ(url, name);
      },
    },
  },
  external: {
    emptyStateLearnMore: 'https://confluence.atlassian.com/x/Ep1IN',
    excludedFilesLearnMore: 'https://confluence.atlassian.com/x/j41LOQ',
    reduceRepositorySize: 'https://confluence.atlassian.com/x/xgMvEw',
    updateMirrorPushUrl: 'https://confluence.atlassian.com/x/jGP5MQ',
    referenceIssuesLearnMore: 'https://confluence.atlassian.com/x/JR9QLg',
    usernameAliasesDocumentation: 'https://confluence.atlassian.com/x/xYE-E',
    learnMoreAboutModifyingUrlsLink:
      'https://confluence.atlassian.com/x/hneeOQ#ChangeaworkspaceID-update_URLUpdatetheURLinyourconfigurationfile',
    bitbucketStatusPage: 'https://status.bitbucket.org',
    branchingModelDocumentation: 'https://confluence.atlassian.com/x/TwlODQ',
    branchRestrictionsDocumentation:
      'https://confluence.atlassian.com/x/z40AFw',
  },
  ui: {
    admin: (owner: string, slug: string) =>
      `${urls.ui.repository(owner, slug)}/admin`,
    adminPage: (owner: string, slug: string, page: string) =>
      `${urls.ui.repository(owner, slug)}/admin/${page}`,
    accessCheck: (owner: string, slug: string) =>
      `${urls.ui.repository(owner, slug)}/admin/access-check`,
    branches: (owner: string, slug: string) =>
      `${urls.ui.repository(owner, slug)}/branches`,
    branch: (owner: string, slug: string, branchName: string) =>
      `${urls.ui.repository(owner, slug)}/branch/${branchName}`,
    compare: (owner: string, slug: string) =>
      `${urls.ui.repository(owner, slug)}/branches/compare`,
    branchingModel: (owner: string, slug: string) =>
      `${urls.ui.repository(owner, slug)}/admin/branching-model`,
    branchPermissions: (owner: string, slug: string) =>
      `${urls.ui.repository(owner, slug)}/admin/branch-permissions`,
    defaultReviewers: (owner: string, slug: string) =>
      `${urls.ui.repository(owner, slug)}/admin/pullrequests/reviewers/`,
    commits: (
      repositoryName: string,
      opts?: { kind: RefType; refName: string } | null
    ) => {
      const url = `/${repositoryName}/commits`;
      if (!opts) {
        return url;
      }
      return `${url}/${opts.kind}/${opts.refName}`;
    },
    create: (params?: { workspace?: string; project?: string }) => {
      if (params) {
        return `/repo/create?${qs.stringify(params, { skipNulls: true })}`;
      }
      return '/repo/create';
    },
    excludedFiles: (repositoryName: string) =>
      `/${repositoryName}/admin/pullrequests/excluded-files/`,
    fork: (owner: string, slug: string) =>
      `${urls.ui.repository(owner, slug)}/fork`,
    import: (params?: { workspace?: string; project?: string }) => {
      if (params) {
        return `/repo/import?${qs.stringify(params, { skipNulls: true })}`;
      }
      return '/repo/import';
    },
    notifications: '/account/settings/notifications/',
    pipelines: (owner: string, slug: string) =>
      `${urls.ui.repository(owner, slug)}/pipelines/?from=bitbucketBuildCard`,
    pullRequests: (owner: string, slug: string) =>
      `${urls.ui.repository(owner, slug)}/pull-requests`,
    pullRequestsTargetingBranch: (
      owner: string,
      slug: string,
      targetBranch: string
    ) =>
      `${urls.ui.repository(
        owner,
        slug
      )}/pull-requests?state=OPEN&at=${encodeURIComponent(targetBranch)}`,
    createPullRequest: (
      owner: string,
      slug: string,
      sourceBranch?: string,
      destRepo?: string,
      destBranch?: string
    ) => {
      const url = `${urls.ui.repository(owner, slug)}/pull-requests/new`;
      const sourceQuery = qs.stringify(
        { source: sourceBranch },
        {
          skipNulls: true,
          addQueryPrefix: true,
        }
      );

      // PR create spec is slug:hash:spec
      if (destRepo && destBranch) {
        const destQuery = qs.stringify({
          dest: `${destRepo}::${destBranch}`,
        });
        return `${url}${sourceQuery}&${destQuery}`;
      } else {
        return `${url}${sourceQuery}`;
      }
    },
    repository: (owner: string, slug: string) => `/${owner}/${slug}`,
    smartMirroring: (uuid: string) => `/account/user/${uuid}/mirroring/`,
    getLFSpath: (fullSlug: string) => `/${fullSlug}/admin/lfs/file-management/`,
    getRepoDetailsUrl: (fullSlug: string) => `/${fullSlug}/admin`,
    transferRepo: (fullSlug: string) => `/${fullSlug}/admin/transfer`,
    deleteRepo: (fullSlug: string) => `/${fullSlug}/delete`,
    settingsGroups: (slug: string) => `/${slug}/workspace/settings/groups`,
    members: (slug: string) => `/${slug}/workspace/members/`,
    customMergeChecks: (
      workspaceSlug: string,
      repositorySlug: string
    ): string => `/${workspaceSlug}/${repositorySlug}/admin/merge-checks`,
  },
  xhr: {
    watchPrefs: (repositoryFullSlug: string) =>
      `/xhr/watch-prefs/${repositoryFullSlug}`,
    userMention: (query: string) => `/xhr/user-mention?term=${query}`,
    reviewerPermissions: (owner: string, slug: string) =>
      `/${owner}/${slug}/pull-requests/xhr/reviewer-permissions/`,
  },
};

export default urls;
