import { denormalize } from 'normalizr';
import { createSelector, Selector } from 'reselect';

import { commit as commitSchema } from 'src/redux/pull-request/schemas';
import { getEntities } from 'src/selectors/state-slicing-selectors';
import { BucketState } from 'src/types/state';

import { BranchSyncInfo } from './types';

const getCommitsKeys = (state: BucketState) => state.commits.commits;
export const getCommitsNextUrl = (state: BucketState) =>
  state.commits.nextCommitsUrl;
const getCommitsLoading = (state: BucketState) => state.commits.isLoading;
const getCommitsError = (state: BucketState) => state.commits.hasError;
const getCommitState = (state: BucketState) => state.commits;

export const getCurrentRepoCommits: Selector<
  BucketState,
  BB.Commit[] | undefined
> = createSelector(getCommitsKeys, getEntities, (keys, entities) =>
  denormalize(keys, [commitSchema], entities)
);

export const getBranchSyncInfo: Selector<BucketState, BranchSyncInfo | null> =
  createSelector(getCommitState, commits => commits.branchSyncInfo);

export const getRepoCommitsState = createSelector(
  getCurrentRepoCommits,
  getCommitsError,
  getCommitsLoading,
  getCommitsNextUrl,
  (commits, hasError, isLoading, nextUrl) => ({
    commits,
    hasError,
    isLoading,
    nextUrl,
  })
);
