import { combineReducers } from 'redux';

import deployments, {
  DeploymentsState,
} from 'src/components/pipelines/redux/reducers/deployments-reducer';
import branches, { BranchesState } from 'src/redux/branches/reducers';
import {
  reducer as commitListReducer,
  CommitListState,
} from 'src/redux/commit-list';
import {
  reducer as pendingCommit,
  PendingCommitState,
} from 'src/redux/pending-commit';
import pullRequestList, {
  PullRequestListState,
} from 'src/redux/pull-request-list';
import { activityReducer } from 'src/redux/pull-request/activity-reducer';
import declineReducer from 'src/redux/pull-request/decline-reducer';
import imageUploadReducer from 'src/redux/pull-request/image-upload-reducer';
import { iterativeReviewReducer } from 'src/redux/pull-request/iterative-review-reducer';
import mergeReducer from 'src/redux/pull-request/merge-reducer';
import pollingReducer from 'src/redux/pull-request/polling-reducer';
import {
  pullRequestReducer,
  PullRequestState,
} from 'src/redux/pull-request/reducer';
import sidebarCardCollapsedReducer from 'src/redux/pull-request/sidebar-card-collapsed-reducer';
import tasksReducer from 'src/redux/pull-request/tasks-reducer';
import viewEntireFileReducer from 'src/redux/pull-request/view-entire-file-reducer';
import {
  BranchCompareState,
  branchReducer,
} from 'src/redux/repository/branch/branch-redux';
import {
  repoSecurityReducer,
  RepositorySecurityState,
} from 'src/redux/repository/security/repo-security-redux';

import source, { SourceState } from '../sections/source/reducers';

import details, { RepositoryDetailsState } from './details';
import section, { RepositorySectionState } from './section';
import subscriptions, { RepositorySubscriptionsState } from './subscriptions';

export type RepositoryState = {
  branch: BranchCompareState;
  commitList: CommitListState;
  details: RepositoryDetailsState;
  deployments: DeploymentsState;
  pendingCommit: PendingCommitState;
  pullRequest: PullRequestState;
  pullRequestList: PullRequestListState;
  branches: BranchesState;
  section: RepositorySectionState;
  source: SourceState;
  subscriptions: RepositorySubscriptionsState;
  security: RepositorySecurityState;
};

const reducer = combineReducers<RepositoryState>({
  branch: branchReducer,
  commitList: commitListReducer,
  details,
  deployments,
  pendingCommit,
  // Interim duct tape to append reducer to parent slice
  pullRequest: (state: PullRequestState, action): PullRequestState => ({
    ...pullRequestReducer(state, action),
    decline: declineReducer(state ? state.decline : undefined, action),
    merge: mergeReducer(state ? state.merge : undefined, action),
    imageUpload: imageUploadReducer(
      state ? state.imageUpload : undefined,
      action
    ),
    activity: activityReducer(state ? state.activity : undefined, action),
    iterativeReview: iterativeReviewReducer(
      state ? state.iterativeReview : undefined,
      action
    ),
    polling: pollingReducer(state ? state.polling : undefined, action),
    sidebarCardCollapsed: sidebarCardCollapsedReducer(
      state ? state.sidebarCardCollapsed : undefined,
      action
    ),
    tasks: tasksReducer(state ? state.tasks : undefined, action),
    viewEntireFile: viewEntireFileReducer(
      state ? state.viewEntireFile : undefined,
      action
    ),
  }),
  pullRequestList,
  branches,
  section,
  source,
  subscriptions,
  security: repoSecurityReducer,
});

export default reducer;
