import { cloneDeep, update } from 'lodash-es';

import { Environment } from 'src/components/pipelines/models';
import { SET_REPOSITORY } from 'src/components/pipelines/redux/actions/pipelines';
import { LoadingStatus } from 'src/constants/loading-status';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import {
  REQUEST_BRANCH_RESTRICTIONS,
  REQUEST_CREATE_BRANCH_RESTRICTION,
  REQUEST_CREATE_ENVIRONMENT,
  REQUEST_DELETE_BRANCH_RESTRICTION,
  REQUEST_DELETE_ENVIRONMENT,
  REQUEST_DEPLOYMENT_DASHBOARD,
  REQUEST_REORDER_ENVIRONMENTS,
  REQUEST_UPDATE_ENVIRONMENT,
  SET_DEPLOYMENT_DASHBOARD,
  SET_BRANCH_RESTRICTIONS,
} from '../actions/deployments';

type EnvironmentType = 'Test' | 'Staging' | 'Production';
type BranchRestriction = {
  environmentUuid?: string;
  pattern?: string;
  uuid?: string;
};

export type DashboardState = {
  fetchedStatus: LoadingStatus;
  reorderedStatus: LoadingStatus;
  /** Environment objects, grouped by type */
  type: { [key in EnvironmentType]: Environment[] };
  updatingEnvironmentUuids: { [uuid: string]: boolean };
  version: number;
};

export const initialState: DashboardState = {
  fetchedStatus: LoadingStatus.Before,
  reorderedStatus: LoadingStatus.Before,
  type: { Test: [], Staging: [], Production: [] },
  updatingEnvironmentUuids: {},
  version: -1,
};

function findPath(state: DashboardState, environmentUuid: string) {
  for (const [typeName, environments] of Object.entries(state.type)) {
    const index = environments.findIndex(env => env.uuid === environmentUuid);
    if (index !== -1) {
      return {
        index,
        path: `${typeName}[${index}]`,
        type: typeName as EnvironmentType,
      };
    }
  }
  return { index: -1, path: '', type: '' };
}

function getUpdatingWith(state: DashboardState, environmentUuid: string) {
  return {
    ...state.updatingEnvironmentUuids,
    [environmentUuid]: true,
  };
}

function getUpdatingWithOut(state: DashboardState, environmentUuid: string) {
  const updatingEnvironmentUuids = { ...state.updatingEnvironmentUuids };
  delete updatingEnvironmentUuids[environmentUuid];
  return updatingEnvironmentUuids;
}

function updateEnvironmentFinishedReducer(
  state: DashboardState,
  action: Action<{ environmentUuid: string }>
) {
  const { environmentUuid } = action.payload!;
  return {
    ...state,
    updatingEnvironmentUuids: getUpdatingWithOut(state, environmentUuid),
  };
}

export function updateBranchRestrictionsInState(
  restrictions: { [uuid: string]: BranchRestriction[] },
  state: DashboardState
) {
  return Object.keys(restrictions).reduce((tempState, environmentUuid) => {
    if (!Object.values(state.type).every(val => Array.isArray(val))) {
      return state;
    }
    const { path } = findPath(tempState, environmentUuid);
    const branchRestrictions = restrictions[environmentUuid];

    return {
      ...tempState,
      type: update(
        tempState.type,
        path,
        environment =>
          new Environment({
            ...(environment ? environment.toJS() : {}),
            branchRestrictions,
          })
      ),
    };
  }, state);
}

const reduceDeploymentDashboard = (
  state: DashboardState,
  action: Action<{
    groupings: {
      environment_summaries: Partial<Environment>[];
      environment_type: { name: EnvironmentType };
    }[];
    version: number;
  }>
) => {
  if (!action.payload?.groupings?.length) {
    return state;
  }
  const type = { ...initialState.type };
  action.payload.groupings.forEach(group => {
    type[group.environment_type.name] = group.environment_summaries.map(
      e => new Environment(e)
    );
  });
  return {
    ...state,
    fetchedStatus: LoadingStatus.Success,
    type,
    version: action.payload.version,
  };
};

const reduceBranchRestrictions = (
  state: DashboardState,
  action: Action<{ values: BranchRestriction[] }>
) => {
  if (!action.payload?.values?.length) {
    return state;
  }
  const restrictions = action.payload.values.reduce(
    (tempRestrictions, restriction: BranchRestriction) => {
      if (tempRestrictions[restriction.environmentUuid!]) {
        tempRestrictions[restriction.environmentUuid!].push(restriction);
      } else {
        tempRestrictions[restriction.environmentUuid!] = [restriction];
      }
      return tempRestrictions;
    },
    {} as { [uuid: string]: BranchRestriction[] }
  );
  return updateBranchRestrictionsInState(restrictions, state);
};

export default createReducer(initialState, {
  [SET_REPOSITORY]() {
    return { ...initialState };
  },
  [REQUEST_DEPLOYMENT_DASHBOARD.REQUEST]() {
    return { ...initialState, fetchedStatus: LoadingStatus.Fetching };
  },
  [SET_DEPLOYMENT_DASHBOARD]: reduceDeploymentDashboard,
  [REQUEST_DEPLOYMENT_DASHBOARD.SUCCESS]: reduceDeploymentDashboard,
  [REQUEST_DEPLOYMENT_DASHBOARD.ERROR](state: DashboardState) {
    return { ...state, fetchedStatus: LoadingStatus.Failed };
  },
  [SET_BRANCH_RESTRICTIONS]: reduceBranchRestrictions,
  [REQUEST_BRANCH_RESTRICTIONS.SUCCESS]: reduceBranchRestrictions,
  [REQUEST_CREATE_BRANCH_RESTRICTION.REQUEST]: (
    state,
    action: Action<{ environmentUuid: string }>
  ) => {
    const { environmentUuid } = action.payload!;
    return {
      ...state,
      updatingEnvironmentUuids: getUpdatingWith(state, environmentUuid),
    };
  },
  [REQUEST_CREATE_BRANCH_RESTRICTION.SUCCESS]: (
    state,
    action: Action<{
      environmentUuid: string;
      data: BranchRestriction;
    }>
  ) => {
    const { environmentUuid, data } = action.payload!;
    const { path } = findPath(state, environmentUuid);
    const type = update(
      cloneDeep(state.type),
      path,
      (env: Environment) =>
        new Environment({
          ...env.toJS(),
          branchRestrictions: [...env.branchRestrictions, data] as any,
        })
    );
    return {
      ...state,
      type,
      updatingEnvironmentUuids: getUpdatingWithOut(state, environmentUuid),
    };
  },
  [REQUEST_CREATE_BRANCH_RESTRICTION.ERROR]: updateEnvironmentFinishedReducer,
  [REQUEST_DELETE_BRANCH_RESTRICTION.REQUEST]: (
    state,
    action: Action<{ environmentUuid: string }>
  ) => {
    const { environmentUuid } = action.payload!;
    return {
      ...state,
      updatingEnvironmentUuids: getUpdatingWith(state, environmentUuid),
    };
  },
  [REQUEST_DELETE_BRANCH_RESTRICTION.SUCCESS]: (
    state,
    action: Action<{ environmentUuid: string; restrictionUuid: string }>
  ) => {
    const { environmentUuid, restrictionUuid } = action.payload!;
    const { path } = findPath(state, environmentUuid);
    const type = update(
      cloneDeep(state.type),
      path,
      (env: Environment) =>
        new Environment({
          ...env.toJS(),
          branchRestrictions: env.branchRestrictions.filter(
            restriction => restriction.uuid !== restrictionUuid
          ),
        })
    );
    return {
      ...state,
      type,
      updatingEnvironmentUuids: getUpdatingWithOut(state, environmentUuid),
    };
  },
  [REQUEST_DELETE_BRANCH_RESTRICTION.ERROR]: updateEnvironmentFinishedReducer,
  [REQUEST_CREATE_ENVIRONMENT.REQUEST]: (
    state: DashboardState,
    action: Action<{
      environmentName: string;
      environmentType: EnvironmentType;
    }>
  ) => {
    const { environmentName, environmentType } = action.payload!;
    const type = {
      ...state.type,
      [environmentType]: [
        ...state.type[environmentType],
        // Append the temp environment object
        new Environment({
          name: environmentName,
          environment_type: { name: environmentType },
        }),
      ],
    };
    return { ...state, type };
  },
  [REQUEST_CREATE_ENVIRONMENT.SUCCESS]: (
    state: DashboardState,
    action: Action<{
      environment: Partial<Environment>;
      environmentName: string;
      environmentType: EnvironmentType;
    }>
  ) => {
    const { environment, environmentName, environmentType } = action.payload!;
    const type = { ...state.type };
    type[environmentType] = type[environmentType].map(env =>
      // Replace the temp environment object
      env.name !== environmentName ? env : new Environment(environment)
    );
    return { ...state, type };
  },
  [REQUEST_CREATE_ENVIRONMENT.ERROR]: (
    state: DashboardState,
    action: Action<{
      environmentName: string;
      environmentType: EnvironmentType;
    }>
  ) => {
    const { environmentName, environmentType } = action.payload!;
    const type = {
      ...state.type,
      [environmentType]: state.type[environmentType].filter(
        // Remove the temp environment object
        env => env.name !== environmentName
      ),
    };
    return { ...state, type };
  },
  [REQUEST_UPDATE_ENVIRONMENT.REQUEST]: (
    state: DashboardState,
    action: Action<{ environmentUuid: string }>
  ) => {
    const { environmentUuid } = action.payload!;
    return {
      ...state,
      updatingEnvironmentUuids: getUpdatingWith(state, environmentUuid),
    };
  },
  [REQUEST_UPDATE_ENVIRONMENT.SUCCESS]: (
    state: DashboardState,
    action: Action<{ environmentUuid: string; data: Partial<Environment> }>
  ) => {
    const { environmentUuid, data } = action.payload!;
    const { type: environmentType } = findPath(state, environmentUuid);
    if (
      environmentType &&
      Array.isArray(state.type[environmentType as EnvironmentType])
    ) {
      const type = {
        ...state.type,
        [environmentType]: state.type[environmentType as EnvironmentType].map(
          env =>
            // Replace the updated environment
            env.uuid !== environmentUuid ? env : new Environment(data)
        ),
      };
      return {
        ...state,
        type,
        updatingEnvironmentUuids: getUpdatingWithOut(state, environmentUuid),
      };
    }
    return state;
  },
  [REQUEST_UPDATE_ENVIRONMENT.ERROR]: updateEnvironmentFinishedReducer,
  [REQUEST_DELETE_ENVIRONMENT.REQUEST]: (
    state: DashboardState,
    action: Action<{ environmentUuid: string }>
  ) => {
    const { environmentUuid } = action.payload!;
    return {
      ...state,
      updatingEnvironmentUuids: getUpdatingWith(state, environmentUuid),
    };
  },
  [REQUEST_DELETE_ENVIRONMENT.SUCCESS]: (
    state: DashboardState,
    action: Action<{ environmentUuid: string }>
  ) => {
    const { environmentUuid } = action.payload!;
    const { type: environmentType } = findPath(state, environmentUuid);
    const type = {
      ...state.type,
      [environmentType]: state.type[environmentType as EnvironmentType].filter(
        // Remove the deleted environment
        env => env.uuid !== environmentUuid
      ),
    };
    return {
      ...state,
      type,
      updatingEnvironmentUuids: getUpdatingWithOut(state, environmentUuid),
    };
  },
  [REQUEST_DELETE_ENVIRONMENT.ERROR]: updateEnvironmentFinishedReducer,
  [REQUEST_REORDER_ENVIRONMENTS.REQUEST]: (
    state,
    action: Action<{
      environmentType: EnvironmentType;
      environmentTypeOrder: { uuid: string }[];
    }>
  ) => {
    const { environmentType, environmentTypeOrder } = action.payload!;

    const type = {
      ...state.type,
      [environmentType]: environmentTypeOrder.map(({ uuid }) =>
        state.type[environmentType].find(
          (env: Environment) => env.uuid === uuid
        )
      ),
    };

    return { ...state, reorderedStatus: LoadingStatus.Fetching, type };
  },
  [REQUEST_REORDER_ENVIRONMENTS.SUCCESS]: state => {
    return { ...state, reorderedStatus: LoadingStatus.Success };
  },
  [REQUEST_REORDER_ENVIRONMENTS.ERROR]: state => {
    return { ...state, reorderedStatus: LoadingStatus.Failed };
  },
});
