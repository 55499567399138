import { Deployment, Environment } from 'src/components/pipelines/models';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import { REMOVE_DEPLOYMENT_DATA } from '../actions/deployments';
import { REQUEST_STEP_REDEPLOYMENT } from '../actions/pipelines';

export type StepRedeploymentState = {
  deployment: Deployment;
  lastSuccessfulDeployment: Deployment;
  environment: Environment;
};

export const initialState: StepRedeploymentState = {
  deployment: new Deployment(),
  lastSuccessfulDeployment: new Deployment(),
  environment: new Environment(),
};

export default createReducer(initialState, {
  [REMOVE_DEPLOYMENT_DATA]() {
    return initialState;
  },
  [REQUEST_STEP_REDEPLOYMENT.REQUEST]() {
    return initialState;
  },
  [REQUEST_STEP_REDEPLOYMENT.SUCCESS](
    state: StepRedeploymentState,
    action: Action<StepRedeploymentState>
  ) {
    return { ...state, ...action.payload };
  },
});
