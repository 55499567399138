import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["maxWidth", "minWidth", "setInitialFocusRef"];
/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useContext, useEffect } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { MenuGroup } from '@atlaskit/menu';
import { FocusManagerContext } from './focus-manager';

/**
 * It sets focus to the first avatar when popup is open.
 */
var PopupAvatarGroup = function PopupAvatarGroup(_ref) {
  var _ref$maxWidth = _ref.maxWidth,
    maxWidth = _ref$maxWidth === void 0 ? 800 : _ref$maxWidth,
    _ref$minWidth = _ref.minWidth,
    minWidth = _ref$minWidth === void 0 ? 320 : _ref$minWidth,
    setInitialFocusRef = _ref.setInitialFocusRef,
    rest = _objectWithoutProperties(_ref, _excluded);
  var _useContext = useContext(FocusManagerContext),
    menuItemRefs = _useContext.menuItemRefs;
  useEffect(function () {
    setInitialFocusRef === null || setInitialFocusRef === void 0 || setInitialFocusRef(menuItemRefs[0]);
  }, [menuItemRefs, setInitialFocusRef]);
  return (
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
    jsx(MenuGroup, _extends({
      maxWidth: maxWidth,
      minWidth: minWidth
    }, rest))
  );
};
export default PopupAvatarGroup;