import { PullRequest, User } from 'src/components/types';

import { pullRequest as pullRequestSchema } from '../schemas';

export const updatePullRequest = ({
  type,
  pullRequest,
  oldTitle,
  userAaid,
  currentUser,
  removedUser,
}: {
  type: string;
  pullRequest: PullRequest;
  oldTitle?: string | null;
  userAaid?: string | null;
  currentUser?: User | null;
  removedUser?: User | null;
}) => ({
  type,
  payload: {
    currentPullRequest: pullRequest,
    oldTitle,
    userAaid,
    currentUser,
    removedUser,
  },
  meta: {
    schema: {
      currentPullRequest: pullRequestSchema,
    },
  },
});
