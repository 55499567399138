import {
  UNLOAD_PULL_REQUEST,
  LOAD_PULL_REQUEST,
} from '../pull-request/actions';

import {
  prCommitActions,
  branchCommitActions,
  UNLOAD_BRANCH_COMMITS,
  FETCH_BRANCH_SYNC_INFO,
} from './actions';
import { BranchSyncInfo } from './types';

export type CommitStateShape = {
  isLoading: boolean;
  hasError: boolean;
  // commits are normalized data
  commits: string[] | undefined;
  nextCommitsUrl: string | undefined;
  branchSyncInfo: BranchSyncInfo | null;
};

const initialState: CommitStateShape = {
  isLoading: true,
  hasError: false,
  commits: undefined,
  nextCommitsUrl: undefined,
  branchSyncInfo: null,
};

export default (
  state: CommitStateShape = initialState,
  // @ts-ignore TODO: fix noImplicitAny error here
  action
): CommitStateShape => {
  switch (action.type) {
    case LOAD_PULL_REQUEST.SUCCESS: {
      const { result } = action.payload;
      // If we're NOT using commits api then the LOAD_PULL_REQUEST will have commits for us
      if (result.commits) {
        return {
          ...state,
          commits: result.commits,
          isLoading: false,
          hasError: false,
          nextCommitsUrl: undefined,
        };
      }
      return state;
    }
    case UNLOAD_PULL_REQUEST:
      return initialState;
    case prCommitActions.UPDATE_COMMITS: {
      const { commits, page, next } = action.payload.result;

      return {
        ...state,
        commits: page === 1 ? commits : [...(state.commits || []), ...commits],
        isLoading: false,
        hasError: false,
        nextCommitsUrl: next,
      };
    }
    case prCommitActions.COMMIT_FETCH_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        nextCommitsUrl: undefined,
      };
    case prCommitActions.RETRY_COMMITS:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        nextCommitsUrl: undefined,
      };
    case prCommitActions.FETCH_MORE_COMMITS:
      return {
        ...state,
        hasError: false,
        isLoading: true,
      };
    case branchCommitActions.FETCH_COMMITS: {
      const { commits, page, next } = action.payload.result;
      return {
        ...state,
        commits: page === 1 ? commits : [...(state.commits || []), ...commits],
        isLoading: false,
        hasError: false,
        nextCommitsUrl: next,
      };
    }
    case branchCommitActions.FETCH_MORE_COMMITS:
      return {
        ...state,
        hasError: false,
        isLoading: true,
      };
    case branchCommitActions.RETRY_COMMITS:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        nextCommitsUrl: undefined,
      };
    case branchCommitActions.COMMIT_FETCH_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false,
        nextCommitsUrl: undefined,
      };
    case UNLOAD_BRANCH_COMMITS:
      return initialState;
    case FETCH_BRANCH_SYNC_INFO.SUCCESS:
      return {
        ...state,
        branchSyncInfo: {
          behind: action.payload.behind,
          behindTruncated: action.payload.behind_truncated,
        },
      };
    default:
      return state;
  }
};
