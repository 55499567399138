import { defineMessages } from 'react-intl';

export default defineMessages({
  successTitle: {
    id: 'frontbucket.jiraTab.createIssueFlag.successTitle',
    description:
      'The title of a flag which informs the user that a Jira issue was created successfully',
    defaultMessage: 'Issue successfully created!',
  },
  successDescription: {
    id: 'frontbucket.jiraTab.createIssueFlag.successDescription',
    description:
      'A message which informs the user that the issue was created and in which project',
    defaultMessage:
      'The issue {issueKey} was successfully created in the {projectName} ({projectKey}) project.',
  },
  viewIssueLink: {
    id: 'frontbucket.jiraTab.createIssueSuccessFlag.viewIssueLink',
    description:
      'Text for a link to the Jira issue which was successfully created.',
    defaultMessage: 'View issue',
  },
});
