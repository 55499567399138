import format from 'date-fns/format';
import isValid from 'date-fns/isValid';

import { DateLike, toDate } from './date';

export type DateFormat = 'datetime-us' | 'date-us-short' | 'date-au';

/**
 * Format a date like value into a user-facing string.
 *
 * Supported date/time formats:
 * - `datetime-us`: US style long datetime. Example: "December 2nd 2022, 11:59:59 pm"
 * - `date-us-short`: US style date without year (month-day). Example: "Dec 2nd"
 * - `date-au`: Australia/UK style date (day-month-year). Example: "02 Dec 2022"
 */
export function formatDate(
  date: DateLike,
  dateFormat: DateFormat = 'datetime-us'
): string {
  const dateObj = toDate(date);

  if (!isValid(dateObj)) {
    return 'Invalid Date';
  }

  switch (dateFormat) {
    case 'date-au':
      return format(dateObj, 'dd MMM yyyy');
    case 'date-us-short':
      return format(dateObj, 'MMM do');
    default:
      // datetime-us
      return format(dateObj, 'MMMM do yyyy, h:mm:ss aaa');
  }
}

/**
 * Format current date into a user-facing string.
 */
export function formatNow(dateFormat: DateFormat = 'datetime-us'): string {
  return formatDate(Date.now(), dateFormat);
}
