/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import qs from 'qs';
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { VARIABLES_PAGELEN } from 'src/components/pipelines/constants';
import {
  getEnvironmentVariablesPage,
  getVariablesPage,
} from 'src/components/pipelines/redux/selectors/pipelines';
import { getCurrentRepository } from 'src/selectors/repository-selectors';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

import envBaseUrl from '../../utils/env-base-url';
import {
  capturePipelinesExceptionWithTags,
  createErrorMessage,
} from '../../utils/sentry';
import {
  REQUEST_CREATE_ENVIRONMENT_VARIABLE,
  REQUEST_CREATE_VARIABLE,
  REQUEST_DELETE_ENVIRONMENT_VARIABLE,
  REQUEST_DELETE_VARIABLE,
  REQUEST_ENVIRONMENT_VARIABLES,
  REQUEST_UPDATE_ENVIRONMENT_VARIABLE,
  REQUEST_UPDATE_VARIABLE,
  REQUEST_VARIABLES,
} from '../actions/variables';

export const getVariablesUrl = (
  repositoryFullSlug: string,
  variableUuid = ''
) => {
  return `${envBaseUrl(
    '/!api/2.0'
  )}/repositories/${repositoryFullSlug}/pipelines_config/variables/${variableUuid}`;
};

export const getEnvironmentVariablesUrl = (
  repositoryFullSlug: string,
  environmentUuid: string,
  variableUuid = ''
) => {
  return `${envBaseUrl(
    '/!api/internal',
    'deployments'
  )}/repositories/${repositoryFullSlug}/deployments_config/environments/${environmentUuid}/variables/${variableUuid}`;
};

export function* requestVariablesSaga(action: any): any {
  const { full_name } = yield select(getCurrentRepository);
  const page = yield select(getVariablesPage);
  try {
    const url = `${getVariablesUrl(full_name)}?${qs.stringify({
      page,
      pagelen: VARIABLES_PAGELEN,
    })}`;
    const res: Response = yield call(fetch, authRequest(url));

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const payload = yield call([res, 'json']);
    yield put({
      type: REQUEST_VARIABLES.SUCCESS,
      meta: action?.meta,
      payload,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_VARIABLES.ERROR,
    });
    yield put({
      type: REQUEST_VARIABLES.ERROR,
      meta: action?.meta,
      payload: e,
    });
  }
}

export function* requestCreateVariableSaga(action: {
  meta: { variable: any };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getVariablesUrl(full_name);
    const res: Response = yield call(
      fetch,
      authRequest(url, {
        method: 'POST',
        headers: jsonHeaders,
        body: JSON.stringify(action.meta.variable),
      })
    );

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const payload = yield call([res, 'json']);
    yield put({
      type: REQUEST_CREATE_VARIABLE.SUCCESS,
      meta: action?.meta,
      payload,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_CREATE_VARIABLE.ERROR,
    });
    yield put({
      type: REQUEST_CREATE_VARIABLE.ERROR,
      meta: action?.meta,
      payload: e,
    });
  }
}

export function* requestUpdateVariableSaga(action: {
  meta: { previousVariable: any; variable: any };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getVariablesUrl(full_name, action.meta?.previousVariable?.uuid);
    const res: Response = yield call(
      fetch,
      authRequest(url, {
        method: 'PUT',
        headers: jsonHeaders,
        body: JSON.stringify(action.meta.variable),
      })
    );

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const payload = yield call([res, 'json']);
    yield put({
      type: REQUEST_UPDATE_VARIABLE.SUCCESS,
      meta: action?.meta,
      payload,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_UPDATE_VARIABLE.ERROR,
    });
    yield put({
      type: REQUEST_UPDATE_VARIABLE.ERROR,
      meta: action?.meta,
      payload: e,
    });
  }
}

export function* requestDeleteVariableSaga(action: {
  meta: { variable: any };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getVariablesUrl(full_name, action.meta?.variable?.uuid);
    const res: Response = yield call(
      fetch,
      authRequest(url, { method: 'DELETE' })
    );

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    yield put({
      type: REQUEST_DELETE_VARIABLE.SUCCESS,
      meta: action?.meta,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_DELETE_VARIABLE.ERROR,
    });
    yield put({
      type: REQUEST_DELETE_VARIABLE.ERROR,
      meta: action?.meta,
      payload: e,
    });
  }
}

export function* requestEnvironmentVariableSaga(action: {
  meta: { environmentUuid: string };
}): any {
  const { environmentUuid } = action.meta;
  const { full_name } = yield select(getCurrentRepository);
  const page =
    (yield select(getEnvironmentVariablesPage))?.[environmentUuid] ?? 1;
  try {
    const url = `${getEnvironmentVariablesUrl(
      full_name,
      environmentUuid
    )}?${qs.stringify({
      page,
      pagelen: VARIABLES_PAGELEN,
    })}`;
    const res: Response = yield call(fetch, authRequest(url));

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const payload = yield call([res, 'json']);
    yield put({
      type: REQUEST_ENVIRONMENT_VARIABLES.SUCCESS,
      meta: action?.meta,
      payload,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_ENVIRONMENT_VARIABLES.ERROR,
    });
    yield put({
      type: REQUEST_ENVIRONMENT_VARIABLES.ERROR,
      meta: action?.meta,
      payload: e,
    });
  }
}

export function* requestCreateEnvironmentVariableSaga(action: {
  meta: { variable: any; environmentUuid: string };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getEnvironmentVariablesUrl(
      full_name,
      action.meta.environmentUuid
    );
    const res: Response = yield call(
      fetch,
      authRequest(url, {
        method: 'POST',
        headers: jsonHeaders,
        body: JSON.stringify(action.meta.variable),
      })
    );

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const payload = yield call([res, 'json']);
    yield put({
      type: REQUEST_CREATE_ENVIRONMENT_VARIABLE.SUCCESS,
      meta: action?.meta,
      payload,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_CREATE_ENVIRONMENT_VARIABLE.ERROR,
    });
    yield put({
      type: REQUEST_CREATE_ENVIRONMENT_VARIABLE.ERROR,
      meta: action?.meta,
      payload: e,
    });
  }
}

export function* requestUpdateEnvironmentVariableSaga(action: {
  meta: { previousVariable: any; variable: any; environmentUuid: string };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getEnvironmentVariablesUrl(
      full_name,
      action.meta?.environmentUuid,
      action.meta?.previousVariable?.uuid
    );
    const res: Response = yield call(
      fetch,
      authRequest(url, {
        method: 'PUT',
        headers: jsonHeaders,
        body: JSON.stringify(action.meta.variable),
      })
    );

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const payload = yield call([res, 'json']);
    yield put({
      type: REQUEST_UPDATE_ENVIRONMENT_VARIABLE.SUCCESS,
      meta: action?.meta,
      payload,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_UPDATE_ENVIRONMENT_VARIABLE.ERROR,
    });
    yield put({
      type: REQUEST_UPDATE_ENVIRONMENT_VARIABLE.ERROR,
      meta: action?.meta,
      payload: e,
    });
  }
}

export function* requestDeleteEnvironmentVariableSaga(action: {
  meta: { variable: any; environmentUuid: string };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getEnvironmentVariablesUrl(
      full_name,
      action.meta?.environmentUuid,
      action.meta?.variable?.uuid
    );
    const res: Response = yield call(
      fetch,
      authRequest(url, { method: 'DELETE' })
    );

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    yield put({
      type: REQUEST_DELETE_ENVIRONMENT_VARIABLE.SUCCESS,
      meta: action?.meta,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_DELETE_ENVIRONMENT_VARIABLE.ERROR,
    });
    yield put({
      type: REQUEST_DELETE_ENVIRONMENT_VARIABLE.ERROR,
      meta: action?.meta,
      payload: e,
    });
  }
}

export default function* () {
  yield all([
    takeLatest(REQUEST_VARIABLES.REQUEST, requestVariablesSaga),
    takeEvery(REQUEST_CREATE_VARIABLE.REQUEST, requestCreateVariableSaga),
    takeEvery(REQUEST_UPDATE_VARIABLE.REQUEST, requestUpdateVariableSaga),
    takeEvery(REQUEST_DELETE_VARIABLE.REQUEST, requestDeleteVariableSaga),
    takeEvery(
      REQUEST_ENVIRONMENT_VARIABLES.REQUEST,
      requestEnvironmentVariableSaga
    ),
    takeEvery(
      REQUEST_CREATE_ENVIRONMENT_VARIABLE.REQUEST,
      requestCreateEnvironmentVariableSaga
    ),
    takeEvery(
      REQUEST_UPDATE_ENVIRONMENT_VARIABLE.REQUEST,
      requestUpdateEnvironmentVariableSaga
    ),
    takeEvery(
      REQUEST_DELETE_ENVIRONMENT_VARIABLE.REQUEST,
      requestDeleteEnvironmentVariableSaga
    ),
  ]);
}
