import { defineMessages } from 'react-intl';

const sync = defineMessages({
  compareBranchesTimeoutTitle: {
    id: 'frontbucket.branches.updatedSyncBranchTimeoutTitle',
    description: 'Text for the title of the branch sync timeout message',
    defaultMessage: 'Branch sync',
  },
  compareBranchesTimeoutDescription: {
    id: 'frontbucket.branches.updatedSyncBranchTimeoutDescription',
    description: 'Text for the description of the branch sync timeout message',
    defaultMessage: `The sync is still running in the background. Wait a few minutes and then refresh the page.`,
  },
  compareBranchesTimeoutAction: {
    id: 'frontbucket.branches.updatedSyncBranchTimeoutAction',
    description: 'Text for action to reload the page',
    defaultMessage: `Refresh`,
  },
});

const merge = defineMessages({
  compareBranchesTimeoutTitle: {
    id: 'frontbucket.branches.updatedMergeBranchTimeoutTitle',
    description: 'Text for the title of the branch merge timeout message',
    defaultMessage: 'Branch merge',
  },
  compareBranchesTimeoutDescription: {
    id: 'frontbucket.branches.updatedMergeBranchTimeoutDescription',
    description: 'Text for the description of the branch merge timeout message',
    defaultMessage: `The merge is still running in the background. Wait a few minutes and then refresh the page.`,
  },
  compareBranchesTimeoutAction: {
    id: 'frontbucket.branches.updatedMergeBranchTimeoutAction',
    description: 'Text for action to reload the page',
    defaultMessage: `Refresh`,
  },
});

export default {
  sync,
  merge,
};
