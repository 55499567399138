import { orderedList as orderedListFactory } from '../../next-schema/generated/nodeTypes';
export var orderedListSelector = '.ak-ol';
export var orderedList = orderedListFactory({
  parseDOM: [{
    tag: 'ol'
  }],
  toDOM: function toDOM() {
    var attrs = {
      class: orderedListSelector.substr(1)
    };
    return ['ol', attrs, 0];
  }
});

// resolve "start" to a safe, 0+ integer, otherwise return undefined
// Note: Any changes to this function should also be made to "resolveOrder"
// in packages/editor/editor-common/src/utils/list.ts
var resolveStart = function resolveStart(start) {
  var num = Number(start);
  if (Number.isNaN(num)) {
    return;
  }
  if (num < 0) {
    return;
  }
  return Math.floor(Math.max(num, 0));
};
export var orderedListWithOrder = orderedListFactory({
  parseDOM: [{
    tag: 'ol',
    getAttrs: function getAttrs(domNode) {
      var dom = domNode;
      var startDOMAttr = dom.getAttribute('start');
      if (startDOMAttr) {
        var start = resolveStart(startDOMAttr);
        if (typeof start === 'number') {
          return {
            order: start
          };
        }
      }
      return null;
    }
  }],
  toDOM: function toDOM(node) {
    var _node$attrs;
    var start = resolveStart(node === null || node === void 0 ? void 0 : (_node$attrs = node.attrs) === null || _node$attrs === void 0 ? void 0 : _node$attrs.order);
    var attrs = {
      start: typeof start === 'number' ? String(start) : undefined,
      class: orderedListSelector.substr(1)
    };
    return ['ol', attrs, 0];
  }
});