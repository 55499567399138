import { ModelBase } from './ModelBase';

export enum ResultType {
  Passed = 'PASSED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN',
}

export enum ReportType {
  Security = 'SECURITY',
  Coverage = 'COVERAGE',
  Test = 'TEST',
  Bug = 'BUG',
  Unknown = 'UNKNOWN',
}

export class Report extends ModelBase {
  readonly uuid: string = '';
  readonly report_type: ReportType = ReportType.Unknown;
  readonly type: string = '';
  readonly title: string = '';
  readonly details: string = '';
  readonly result: ResultType = ResultType.Unknown;
  readonly reporter: string = '';
  readonly link: string = '';
  readonly external_id: string = '';
  readonly logo_url: string = '';
  readonly data: {
    title: string;
    type:
      | 'BOOLEAN'
      | 'DATE'
      | 'DURATION'
      | 'LINK'
      | 'NUMBER'
      | 'PERCENTAGE'
      | 'TEXT';
    value: any;
  }[] = [];
  readonly created_on: string = '';
  readonly updated_on: string = '';

  constructor(props: Partial<Report> = {}) {
    super(props);
    Object.assign(this, props);
    Object.freeze(this);
  }
}
