/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { put, select, take } from 'redux-saga/effects';

import { Workspace } from 'src/components/types';
import { LoadProfilePage } from 'src/redux/profile/section/actions/constants';
import { getCurrentWorkspace } from 'src/redux/profile/selectors';
import createAddRecentlyViewedWorkspaceAction from 'src/redux/workspaces/actions/add-recently-viewed-workspace';

function* loadWorkspaceSuccess() {
  while (true) {
    yield take(LoadProfilePage.SUCCESS);
    const workspace: Workspace = yield select(getCurrentWorkspace);
    if (workspace) {
      yield put(createAddRecentlyViewedWorkspaceAction(workspace));
    }
  }
}

export default loadWorkspaceSuccess;
