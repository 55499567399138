import { LoadingStatus } from 'src/constants/loading-status';
import createReducer from 'src/utils/create-reducer';

import {
  GO_TO_STEP,
  FETCH_SITES_FOR_JIRA_MIGRATION,
  SET_SELECTED_SITE,
  SET_PROJECT,
  FETCH_ISSUE_MIGRATION_TO_JIRA_STATUS,
  START_ISSUE_MIGRATION_TO_JIRA,
  RESET_JIRA_MIGRATION_STATE,
  FETCH_ISSUE_MIGRATION_TO_JIRA_LOGS,
  INSTALL_ADDON_JIRA_MIGRATION,
} from '../actions';
import { AppInstalledStatus } from '../constants';
import {
  AvailableSite,
  IssueMigrationToJiraProgress,
  IssueMigrationToJiraStarted,
  IssueMigrationToJiraLogs,
} from '../types';

export type JiraMigrationState = {
  activeStepIndex: number;
  availableSites: AvailableSite[];
  availableSitesFetchedStatus: LoadingStatus;
  appInstalledStatus: AppInstalledStatus;
  selectedSiteCloudId?: string;
  projectName: string;
  projectKey: string;
  migrationProgress?: IssueMigrationToJiraProgress;
  migrationStarted?: IssueMigrationToJiraStarted;
  migrationLogs?: IssueMigrationToJiraLogs;
};

export const initialState: JiraMigrationState = {
  activeStepIndex: 0,
  availableSites: [],
  availableSitesFetchedStatus: LoadingStatus.Before,
  appInstalledStatus: AppInstalledStatus.Before,
  selectedSiteCloudId: undefined,
  projectName: '',
  projectKey: '',
};

export default createReducer(initialState, {
  [GO_TO_STEP]: (state, { payload: activeStepIndex }) => ({
    ...state,
    activeStepIndex,
  }),

  [RESET_JIRA_MIGRATION_STATE]: state => ({
    ...initialState,
    // We don't want to reset the app installed status.
    appInstalledStatus: state.appInstalledStatus,
  }),

  // Install addon
  [INSTALL_ADDON_JIRA_MIGRATION.REQUEST]: state => ({
    ...state,
    appInstalledStatus: AppInstalledStatus.Installing,
  }),
  [INSTALL_ADDON_JIRA_MIGRATION.SUCCESS]: state => ({
    ...state,
    appInstalledStatus: AppInstalledStatus.Installed,
  }),
  [INSTALL_ADDON_JIRA_MIGRATION.ERROR]: state => ({
    ...state,
    appInstalledStatus: AppInstalledStatus.FailedToInstall,
  }),

  // Fetch available sites
  [FETCH_SITES_FOR_JIRA_MIGRATION.REQUEST]: state => ({
    ...state,
    availableSites: [],
    availableSitesFetchedStatus: LoadingStatus.Fetching,
  }),
  [FETCH_SITES_FOR_JIRA_MIGRATION.SUCCESS]: (state, { payload }) => ({
    ...state,
    availableSites: payload,
    availableSitesFetchedStatus: LoadingStatus.Success,
  }),
  [FETCH_SITES_FOR_JIRA_MIGRATION.ERROR]: state => ({
    ...state,
    availableSites: [],
    availableSitesFetchedStatus: LoadingStatus.Failed,
  }),

  [SET_SELECTED_SITE]: (state, { payload: selectedSiteCloudId }) => ({
    ...state,
    selectedSiteCloudId,
  }),

  [SET_PROJECT]: (state, { payload: project }) => ({
    ...state,
    projectKey: project.projectKey,
    projectName: project.projectName,
  }),

  [FETCH_ISSUE_MIGRATION_TO_JIRA_STATUS.SUCCESS]: (
    state,
    { payload: migrationProgress }
  ) => ({
    ...state,
    migrationProgress,
  }),

  [START_ISSUE_MIGRATION_TO_JIRA.REQUEST]: state => ({
    ...state,
    migrationStarted: {
      status: LoadingStatus.Fetching,
    },
  }),
  [START_ISSUE_MIGRATION_TO_JIRA.SUCCESS]: state => ({
    ...state,
    migrationStarted: {
      status: LoadingStatus.Success,
    },
  }),
  [START_ISSUE_MIGRATION_TO_JIRA.ERROR]: (state, { payload: statusCode }) => ({
    ...state,
    migrationStarted: {
      status: LoadingStatus.Failed,
      statusCode,
    },
  }),

  // Fetch migration logs
  [FETCH_ISSUE_MIGRATION_TO_JIRA_LOGS.REQUEST]: state => ({
    ...state,
    migrationLogs: {
      status: LoadingStatus.Fetching,
    },
  }),
  [FETCH_ISSUE_MIGRATION_TO_JIRA_LOGS.SUCCESS]: (state, { payload: logs }) => ({
    ...state,
    migrationLogs: {
      status: LoadingStatus.Success,
      logs,
    },
  }),
  [FETCH_ISSUE_MIGRATION_TO_JIRA_LOGS.ERROR]: state => ({
    ...state,
    migrationLogs: {
      status: LoadingStatus.Failed,
    },
  }),
});
