import { createAnalyticsClient } from '@atlassian/bitkit-analytics';
import AnalyticsWebClient from '@atlassiansox/analytics-web-client';

let globalAnalyticsClient: AnalyticsWebClient;

export const initAnalyticsClient = (): AnalyticsWebClient => {
  return createAnalyticsClient();
};

/**
 * Get the global analytics client, initializing it if it's the first time this
 * function is called.
 */
export const analyticsClient = (): AnalyticsWebClient => {
  if (!globalAnalyticsClient) {
    globalAnalyticsClient = initAnalyticsClient();
  }

  return globalAnalyticsClient;
};
