import { createResource } from 'react-resource-router';

import { TestOutcomeEnum } from 'src/components/pipelines/components/test-management';
import {
  TestExecution,
  TestExecutions,
  TestReportStatus,
} from 'src/components/pipelines/models/TestReport';
import envBaseUrl from 'src/components/pipelines/utils/env-base-url';
import authRequest from 'src/utils/fetch';

const testStatusMap: {
  [key in TestReportStatus]: TestOutcomeEnum;
} = {
  SUCCESSFUL: TestOutcomeEnum.passed,
  FAILED: TestOutcomeEnum.failed,
  SKIPPED: TestOutcomeEnum.skipped,
  ERROR: TestOutcomeEnum.error,
};

export type Params = {
  testCase?: string;
  repositoryFullSlug?: string;
  repositoryOwner?: string; // legacyPath
  repositorySlug?: string; // legacyPath
};

export type Query = {
  page?: string;
  fullyQualifiedName?: string;
  AverageDuration?: string;
};

export function getTestExecutionsUrl(params: Params, query: Query) {
  const {
    repositoryFullSlug = `${params.repositoryOwner}/${params.repositorySlug}`,
  } = params;
  return `${envBaseUrl(
    '/!api/internal'
  )}/repositories/${repositoryFullSlug}/test-cases/${
    query.fullyQualifiedName
  }/executions?page=${query.page || 1}`;
}

export const loadTestExecutionsResource = createResource({
  type: 'pipelinesTestExecution',
  getKey: ({ match, query }) => `${getTestExecutionsUrl(match.params, query)}`,
  maxAge: 60000,
  getData: async ({ match, query }) => {
    const testExecutionData = await (
      await fetch(authRequest(`${getTestExecutionsUrl(match.params, query)}`))
    ).json();

    return new TestExecutions({
      page: testExecutionData.page,
      pagelen: testExecutionData.pagelen,
      size: testExecutionData.size,
      values: testExecutionData.values.map((testExecution: TestExecution) => ({
        ...testExecution,
        testReportUuid: testExecution.test_report_uuid,
        testSummaryUuid: testExecution.test_summary_uuid,
        fullyQualifiedName: testExecution.fully_qualified_name,
        className: testExecution.class_name,
        suiteName: testExecution.suite_name,
        packageName: testExecution.package_name,
        createdOn: testExecution.created_on,
        status: testStatusMap[testExecution.status as TestReportStatus],
        averageExecutionTime: query.average_duration,
        target: {
          referenceName: testExecution.target.reference_name,
          revision: testExecution.target.revision,
          type: testExecution.target.type,
        },
      })),
    });
  },
});
