import React, { useCallback, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import Button from '@atlaskit/button';

import {
  Account,
  CheckRun,
  Checks,
  CheckSuite,
  Deployment,
  HaltedReason,
  Pipeline,
  Repository,
  Step,
} from 'src/components/pipelines/models';
import { useLocation } from 'src/router/hooks';

import { DOCS_DEPLOYMENT_CONCURRENCY, DOCS_DEPLOYMENTS } from '../../constants';
import {
  getDeploymentsPageURL,
  getPlansPageURL,
  getStepPageURL,
} from '../../utils/links';
import ExternalLink from '../ExternalLink';
import {
  HaltedMessageLinks,
  HaltedMessageText,
  HaltedMessageWrapper,
} from '../styled';
import WarningCard from '../WarningCard';

import { messages } from './HaltedMessage.i18n';

export interface Props {
  account: Account;
  repository: Repository;
  pipeline: Pipeline;
  steps: Step[];
  checks: Checks;
  deployments: { [key: string]: Deployment };
}

/* eslint @typescript-eslint/ban-types: "warn" */
const HaltedMessage: React.FC<Props> = ({
  account,
  repository,
  pipeline,
  steps,
  checks,
  deployments,
}) => {
  const location = useLocation();

  const haltedStep = useMemo(() => {
    return steps.filter((step: Step) => step?.isHalted)?.[0];
  }, [steps]);

  const deploymentEnvironment = useMemo(() => {
    return haltedStep ? haltedStep.environmentName : 'the same environment';
  }, [haltedStep]);

  const getReasonUrl = useCallback(
    (repo: Repository) => {
      const reason = haltedStep
        ? (haltedStep.statusReason as HaltedReason)
        : null;
      return reason
        ? getStepPageURL(
            { uuid: reason.pipeline_uuid },
            reason.step_uuid,
            repo.path
          ) + location.search
        : '';
    },
    [haltedStep, location.search]
  );

  const reasonMessage = useMemo(() => {
    const reason: HaltedReason =
      (haltedStep?.statusReason as HaltedReason) || new HaltedReason();
    if (reason.isDeploymentConcurrencyReason) {
      return (
        <>
          <HaltedMessageText>
            <FormattedMessage
              {...messages.haltedMessageText}
              values={{
                linkOne: (
                  <ExternalLink href={getReasonUrl(repository)}>
                    <FormattedMessage {...messages.haltedMessageLink} />
                  </ExternalLink>
                ),
                linkTwo: (
                  <ExternalLink
                    href={getDeploymentsPageURL(
                      repository.path,
                      account.addonKey
                    )}
                  >
                    {deploymentEnvironment}
                  </ExternalLink>
                ),
              }}
            />
          </HaltedMessageText>

          <HaltedMessageText>
            <Button
              href={DOCS_DEPLOYMENT_CONCURRENCY}
              spacing="none"
              appearance="link"
              target="_blank"
            >
              <FormattedMessage {...messages.haltedMessageLearnMore} />
            </Button>
          </HaltedMessageText>
        </>
      );
    } else if (reason.isUserHasNoDeploymentPermissionsReason) {
      return (
        <>
          <HaltedMessageText>
            <FormattedMessage
              {...messages.userHasNoDeploymentPermissionsReason}
              values={{
                linkOne: (
                  <ExternalLink href={reason.actor.accountUrl}>
                    {reason.actor.displayName}
                  </ExternalLink>
                ),
                linkTwo: (
                  <ExternalLink
                    href={getDeploymentsPageURL(
                      repository.path,
                      account.addonKey
                    )}
                  >
                    {deploymentEnvironment}
                  </ExternalLink>
                ),
              }}
            />
          </HaltedMessageText>
          <HaltedMessageLinks>
            <Button
              href={DOCS_DEPLOYMENTS}
              spacing="none"
              appearance="link"
              target="_blank"
            >
              <FormattedMessage {...messages.haltedMessageLearnMore} />
            </Button>
          </HaltedMessageLinks>
        </>
      );
    } else if (reason.isBranchHasNoDeploymentPermissionsReason) {
      return (
        <>
          <HaltedMessageText>
            <FormattedMessage
              {...messages.branchHasNoDeploymentPermissionsReason}
              values={{
                link: (
                  <ExternalLink
                    href={getDeploymentsPageURL(
                      repository.path,
                      account.addonKey
                    )}
                  >
                    {deploymentEnvironment}
                  </ExternalLink>
                ),
                text: pipeline.isBranchPipeline ? (
                  <FormattedMessage
                    {...messages.noDeployedToFromSourceBranch}
                    values={{ sourceBranch: pipeline.sourceBranch }}
                  />
                ) : (
                  <FormattedMessage
                    {...messages.deployedToFromPermittedBranch}
                  />
                ),
              }}
            />
          </HaltedMessageText>
          <HaltedMessageLinks>
            <ExternalLink href={DOCS_DEPLOYMENTS}>
              <FormattedMessage {...messages.haltedMessageLearnMore} />
            </ExternalLink>
          </HaltedMessageLinks>
        </>
      );
    } else if (reason.isAllowanceExceededReason) {
      return (
        <>
          <HaltedMessageText>
            <FormattedMessage {...messages.allowanceExceededReasonPartOne} />
            <br />
            <FormattedMessage {...messages.allowanceExceededReasonPartTwo} />
          </HaltedMessageText>
          <HaltedMessageLinks>
            <ExternalLink href={getPlansPageURL(repository.path)}>
              <FormattedMessage {...messages.viewPlanDetails} />
            </ExternalLink>
          </HaltedMessageLinks>
        </>
      );
    } else if (reason.isDeploymentGateFailedReason) {
      return (
        <HaltedMessageText>
          <FormattedMessage {...messages.deploymentGateFailedReason} />
        </HaltedMessageText>
      );
    } else if (reason.isDeploymentGateChecksRequiredReason) {
      const haltedDeployment: Deployment | undefined = Object.values(
        deployments
      ).find(
        (deployment: any) =>
          !!deployment?.step?.uuid && deployment.step.uuid === haltedStep?.uuid
      );
      const haltedDeploymentCheckSuite: CheckSuite | undefined =
        haltedDeployment &&
        checks.deploymentCheckSuites.find(
          (checkSuite: any) =>
            !!checkSuite &&
            checkSuite['triggerer.uuid'] === haltedDeployment.uuid
        );

      let changeRequestLink: JSX.Element | null = null;
      if (haltedDeploymentCheckSuite) {
        const checkRun: CheckRun | undefined = checks.jsdGatingCheckRuns.find(
          cr => cr?.['check_suite.uuid'] === haltedDeploymentCheckSuite.uuid
        );
        if (checkRun && checkRun['url.href']) {
          changeRequestLink = (
            <a href={checkRun['url.href']} target="_blank" rel="noopener">
              View change request
            </a>
          );
        }
      }

      if (haltedStep?.isManual) {
        return (
          <>
            <HaltedMessageText>
              <FormattedMessage {...messages.manualHaltedStep} />
            </HaltedMessageText>
            {changeRequestLink}
          </>
        );
      }
      return (
        <>
          <HaltedMessageText>
            <FormattedMessage {...messages.automaticalHaltedStep} />
          </HaltedMessageText>
          {changeRequestLink}
        </>
      );
    } else {
      return (
        <HaltedMessageText>
          <FormattedMessage
            {...messages.automaticalPausedMessage}
            values={{
              reason: reason === undefined,
              isDeploymentConcurrencyReason:
                reason.isDeploymentConcurrencyReason,
            }}
          />
        </HaltedMessageText>
      );
    }
  }, [
    account,
    checks.deploymentCheckSuites,
    checks.jsdGatingCheckRuns,
    deploymentEnvironment,
    deployments,
    getReasonUrl,
    haltedStep?.isManual,
    haltedStep?.statusReason,
    haltedStep?.uuid,
    pipeline.isBranchPipeline,
    pipeline.sourceBranch,
    repository,
  ]);

  const warningCardTitle = useMemo(() => {
    return (
      (haltedStep?.statusReason as HaltedReason)?.warningText ||
      pipeline.statusText
    );
  }, [haltedStep, pipeline.statusText]);

  return (
    <WarningCard title={warningCardTitle} color={pipeline.statusColor}>
      <HaltedMessageWrapper>{reasonMessage}</HaltedMessageWrapper>
    </WarningCard>
  );
};

export default React.memo(HaltedMessage);
