import { PageUnload } from 'src/redux/profile/repositories/actions';
import { ProfileRepositoriesState } from 'src/redux/profile/repositories/types';
import createReducer from 'src/utils/create-reducer';

export const profileRepositoriesState: ProfileRepositoriesState = {
  listItems: [],
  nextUrl: null,
  isLoading: false,
  isError: false,
  isPreRequestState: true,
  size: 0,
  pagelen: 0,
};

export default createReducer(profileRepositoriesState, {
  [PageUnload]() {
    return profileRepositoriesState;
  },
});
