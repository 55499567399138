/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { takeEvery, takeLatest, put } from 'redux-saga/effects';

import { FetchAction, fetchData } from 'src/redux/actions';

export function* loadData(action: FetchAction) {
  if (action.meta.isRouterResource) {
    return;
  }
  // @ts-ignore
  yield put(fetchData(action));
}

function* loadAllDatas() {
  yield takeEvery(
    // @ts-ignore TODO: fix noImplicitAny error here
    a =>
      a.meta &&
      a.meta.asyncAction &&
      a.type === a.meta.asyncAction.REQUEST &&
      !a.meta.isCached &&
      !a.meta.takeLatest,
    loadData
  );
}

export function* fetchLatestActionSaga() {
  yield takeLatest(
    // @ts-ignore TODO: fix noImplicitAny error here
    a =>
      a.meta &&
      a.meta.asyncAction &&
      a.type === a.meta.asyncAction.REQUEST &&
      !a.meta.isCached &&
      a.meta.takeLatest,
    loadData
  );
}

export default loadAllDatas;
