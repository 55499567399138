import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { pipelinesReducer as pipelines } from 'src/components/pipelines/redux/reducers/pipelines-reducer';
import createBranch from 'src/redux/create-branch';
import dashboard from 'src/redux/dashboard/reducers';
import { reducer as diffSettings } from 'src/redux/diff-settings';
import favicon from 'src/redux/favicon';
import { reducer as flagsReducer } from 'src/redux/flags';
import global from 'src/redux/global/reducer';
import jira from 'src/redux/jira/reducers';
import { reducer as keyboardShortcuts } from 'src/redux/keyboard-shortcuts';
import profile from 'src/redux/profile/reducer';
import { reducer as projectReducer } from 'src/redux/project';
import { reducer as recentlyViewedRepositories } from 'src/redux/recently-viewed-repositories';
import commits from 'src/redux/repo-commits/reducer';
import search from 'src/redux/search/reducer';
import { reducer as sidebar } from 'src/redux/sidebar';
import { BucketState } from 'src/types/state';

import repository from '../sections/repository/reducers';

import entities from './entities/entities';
import recentlyViewedWorkspaces from './recently-viewed-workspaces/recently-viewed-workspaces';
import workspacePlan from './workspaces/reducers/workspace-plan-reducer';

export default combineReducers<BucketState>({
  dashboard,
  entities,
  favicon,
  flags: flagsReducer,
  // @ts-ignore Unsure what redux-form + redux wants here
  form: formReducer,
  global,
  keyboardShortcuts,
  recentlyViewedRepositories,
  recentlyViewedWorkspaces,
  project: projectReducer,
  repository,
  search,
  sidebar,
  createBranch,
  profile,
  commits,
  diffSettings,
  jira,
  workspacePlan,
  pipelines,
});
