import {
  Match as ResourceRouterMatch,
  useRouter as useResourceRouter,
} from 'react-resource-router';

export const useHistory__UNSAFE = () => {
  const [routerState] = useResourceRouter();
  // @ts-ignore history is intentionally untyped
  return routerState.history;
};

export const useLocation = () => {
  const [{ location }] = useResourceRouter();
  return location;
};

type MatchParams<P> = { params: P };

export type Match<P> = Omit<ResourceRouterMatch, 'params'> & MatchParams<P>;

export const useMatch = <P>() => {
  const [{ match }] = useResourceRouter();
  return match as unknown as Match<P>;
};

export const useParams = <P>() => {
  const match = useMatch<P>();
  return match.params;
};
