import { MAX_UNTRUNCATED_LENGTH, TRUNCATION_OFFSET } from '../constants';

export function truncateEllipsisMiddle(str = ''): string {
  const { length } = str;
  if (length <= MAX_UNTRUNCATED_LENGTH) {
    return str;
  }
  return `${str.substring(0, TRUNCATION_OFFSET)}…${str.substring(
    length - TRUNCATION_OFFSET
  )}`;
}

export default function truncate(str = '', length: number): string {
  return str.length > length ? `${str.substr(0, length)}...` : str;
}
