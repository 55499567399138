import React from 'react';

import { useSelector } from 'react-redux';

import BaseHeartbeat from '@atlassian/heartbeat';

import { getCurrentUserIsAnonymous } from 'src/selectors/user-selectors';

import urls from '../navigation/src/urls';

/**
 * We use patch-package to correct filepaths in @use-it/interval's package.json,
 * which would otherwise cause the build to break.
 * @use-it/interval is a dep of @atlassian/heartbeat (direct dep)
 * @atlassian/heartbeat's creator Max H has a public fix in-review at
 * https://github.com/donavon/use-interval/pull/58/files which will
 * allow us to no longer need patch-package and postinstall-postinstall
 */
export const Heartbeat = () => {
  const isCurrentUserAnon = useSelector(getCurrentUserIsAnonymous);
  return !isCurrentUserAnon ? (
    <BaseHeartbeat
      onAuthenticationFailed={() => {
        // redirect to the page they were on after logging in
        window.location.href = `${urls.ui.login()}?session_invalid=1&next=${
          window.location.pathname
        }${window.location.search}`;
      }}
    />
  ) : null;
};
