import React, { useMemo } from 'react';

import Button from '@atlaskit/button';

import { Account, Repository } from 'src/components/pipelines/models';

import {
  DOCS_CONFIGURE_CLONE_DEPTH,
  DOCS_DEPLOYMENTS,
  DOCS_SERVICE_CONTAINERS,
  DOCS_SETUP_DEPLOYMENT_GATING,
  DOCS_YML_DOCKER_URL,
  DOCS_YML_URL,
  MEMORY_LIMIT_EXCEEDED_STEP_URL,
  SUPPORT_PORTAL,
  VALIDATOR_URL,
  YML_FILE_NAME,
} from '../../constants';
import {
  getAddConfigurationURL,
  getDeploymentSettingsPageURL,
  getEditConfigurationURL,
} from '../../utils/links';

export interface Props {
  account: Account;
  repository: Repository;
  errorKey: string;
  revision: string;
}

/* eslint @typescript-eslint/ban-types: "warn" */
const Link: React.FC<any> = ({ href, text }: { href: string; text: any }) => (
  <Button href={href} spacing="none" appearance="link" target="_blank">
    {text}
  </Button>
);

/* eslint @typescript-eslint/ban-types: "warn" */
const WarningLinks: React.FC<Props> = ({
  account,
  repository,
  errorKey,
  revision,
}) => {
  const documentationLinkData = useMemo(() => {
    const docLinks = [
      {
        key: '',
        text: 'See our documentation',
        href: DOCS_YML_URL,
        actionSubjectId: 'documentationLink',
      },
      {
        key: 'plan-service.parse.invalid-image-name',
        text: 'See our documentation',
        href: DOCS_YML_DOCKER_URL,
        actionSubjectId: 'dockerDocumentationLink',
      },
      {
        key: 'plan-service.parse.too-many-services',
        text: 'Read more about service limits',
        href: DOCS_SERVICE_CONTAINERS,
        actionSubjectId: 'serviceContainersDocumentationLink',
      },
      {
        key: 'agent-service.account-concurrency.reached',
        text: 'Let us know if we were wrong',
        href: SUPPORT_PORTAL,
        actionSubjectId: 'supportAccountLink',
      },
      {
        key: 'agent.git.git-object-not-found',
        text: 'Read more about Git clone depth',
        href: DOCS_CONFIGURE_CLONE_DEPTH,
        actionSubjectId: 'gitCloneDepthDocumentationLink',
      },
      {
        keyStartsWith: 'plan-service.parse',
        excludeKeys: ['invalid-deployment'],
        text: `Validate your ${YML_FILE_NAME}`,
        href: VALIDATOR_URL,
        actionSubjectId: 'validatorLink',
      },
      {
        key: 'plan-service.parse.invalid-deployment',
        text: `Read more about deployments`,
        href: DOCS_DEPLOYMENTS,
        actionSubjectId: 'documentationLink',
      },
      {
        key: 'result-service.deployment-gate.deployment-gate-checks-suite-invalid-state',
        text: `Read more about deployment gating`,
        href: DOCS_SETUP_DEPLOYMENT_GATING,
        actionSubjectId: 'documentationLink',
      },
      {
        key: 'result-service.deployment-gate.deployment-gate-checks-failed-error',
        text: `Read more about deployment gating`,
        href: DOCS_SETUP_DEPLOYMENT_GATING,
        actionSubjectId: 'documentationLink',
      },
      repository.userIsAdmin
        ? {
            key: 'plan-service.parse.invalid-deployment',
            text: `View deployment settings`,
            href: getDeploymentSettingsPageURL(
              repository.path,
              account.addonKey
            ),
            actionSubjectId: 'deploymentSettings',
          } // tslint:disable-line
        : ({} as any),
    ];

    docLinks.push({
      key: 'agent.step.memory-limit-exceeded',
      text: `Troubleshooting Steps`,
      href: MEMORY_LIMIT_EXCEEDED_STEP_URL,
      actionSubjectId: 'serviceContainersMemoryDocumentationLink',
    });

    return docLinks;
  }, [repository, account]);

  const documentationLinks = useMemo(() => {
    const findLinks = (link: any) =>
      link.key === errorKey ||
      (link.keyStartsWith && errorKey.startsWith(link.keyStartsWith));
    // we don't want to show link to external validator in certain cases
    const excludeLinks = (link: any) =>
      Array.isArray(link.excludeKeys)
        ? !link.excludeKeys.some((key: any) => errorKey.indexOf(key) !== -1)
        : true;
    const links = documentationLinkData.filter(findLinks).filter(excludeLinks);
    return links.length ? links : [documentationLinkData[0]];
  }, [errorKey, documentationLinkData]);

  return (
    <>
      {documentationLinks.map((link, key) => (
        <div key={`documentation_link_${key}`}>
          <Link {...link} />
        </div>
      ))}
      {errorKey.indexOf('file-not-found') !== -1 ? (
        <Link
          text={`Add ${YML_FILE_NAME}`}
          href={getAddConfigurationURL(repository.path, revision)}
          actionSubjectId="addConfigurationLink"
        />
      ) : (
        <Link
          text={`View ${YML_FILE_NAME}`}
          href={getEditConfigurationURL(repository.path, revision)}
          actionSubjectId="viewConfigurationLink"
        />
      )}
    </>
  );
};

export default React.memo(WarningLinks);
