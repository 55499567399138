import { createAsyncAction } from 'src/redux/actions';

import { jiraMigration as prefix } from './prefix';

export const INSTALL_ADDON_JIRA_MIGRATION = createAsyncAction(
  prefix('INSTALL_ADDON_JIRA_MIGRATION')
);
export const installAddonJiraMigration = (workspace: string) => ({
  type: INSTALL_ADDON_JIRA_MIGRATION.REQUEST,
  payload: workspace,
});

export const GO_TO_STEP = prefix('GO_TO_STEP');
export const goToStep = (setpIndex: number) => ({
  type: GO_TO_STEP,
  payload: setpIndex,
});

export const RESET_JIRA_MIGRATION_STATE = prefix('RESET_JIRA_MIGRATION_STATE');
export const resetJiraMigrationState = () => ({
  type: RESET_JIRA_MIGRATION_STATE,
});

export const FETCH_SITES_FOR_JIRA_MIGRATION = createAsyncAction(
  prefix('FETCH_SITES_FOR_JIRA_MIGRATION')
);
export const fetchSitesForJiraMigration = () => ({
  type: FETCH_SITES_FOR_JIRA_MIGRATION.REQUEST,
});

export const SET_SELECTED_SITE = prefix('SET_SELECTED_SITE');
export const setSelectedSite = (selectedSiteCloudId: string) => ({
  type: SET_SELECTED_SITE,
  payload: selectedSiteCloudId,
});

export const SET_PROJECT = prefix('SET_PROJECT');
export const setProject = (project: {
  projectKey: string;
  projectName: string;
}) => ({
  type: SET_PROJECT,
  payload: project,
});

export const START_ISSUE_MIGRATION_TO_JIRA = createAsyncAction(
  prefix('START_ISSUE_MIGRATION_TO_JIRA')
);
export const startIssueMigrationToJira = (payload: {
  repositoryFullSlug: string;
  cloudId: string;
  projectKey: string;
  projectName: string;
}) => ({
  type: START_ISSUE_MIGRATION_TO_JIRA.REQUEST,
  payload,
});

export const FETCH_ISSUE_MIGRATION_TO_JIRA_STATUS = createAsyncAction(
  prefix('FETCH_ISSUE_MIGRATION_TO_JIRA_STATUS')
);

export const POLL_ISSUE_MIGRATION_TO_JIRA = {
  START: prefix('START_POLL_ISSUE_MIGRATION_TO_JIRA'),
  STOP: prefix('STOP_POLL_ISSUE_MIGRATION_TO_JIRA'),
};

export const pollIssueMigrationStatus = (payload: {
  repositoryFullSlug: string;
}) => ({
  type: POLL_ISSUE_MIGRATION_TO_JIRA.START,
  payload,
});

export const FETCH_ISSUE_MIGRATION_TO_JIRA_LOGS = createAsyncAction(
  prefix('FETCH_ISSUE_MIGRATION_TO_JIRA_LOGS')
);
export const fetchIssueMigrationToJiraLogs = (payload: {
  repositoryFullSlug: string;
}) => ({
  type: FETCH_ISSUE_MIGRATION_TO_JIRA_LOGS.REQUEST,
  payload,
});
