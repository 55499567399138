import { defineMessages } from 'react-intl';

export default defineMessages({
  description: {
    id: 'frontbucket.settings.permissions.description',
    description: 'Page description section',
    defaultMessage:
      'Add users or groups and provide them the permissions they need to collaborate on code across the entire project.',
  },
  addUsersOrGroupsBtn: {
    id: 'frontbucket.settings.permissions.add-users-or-groups-btn',
    description: 'Add users or groups button text',
    defaultMessage: 'Add users or groups',
  },
  accessGrantedTitle: {
    id: 'frontbucket.settings.permissions.notification.accessGrantedTitle',
    description:
      'Message for notification title that appears after successful access grant',
    defaultMessage: 'Access granted',
  },
  accessGrantedDescription: {
    id: 'frontbucket.settings.permissions.notification.accessGrantedDescription',
    description:
      'Message for notification title that appears after successful access grant',
    defaultMessage:
      '"{entity}" {count, plural, one {was} other {were}} successfully added.',
  },

  accessRemovedTitle: {
    id: 'frontbucket.settings.permissions.notification.accessRemovedTitle',
    description: 'Message that appears after successful privilege removing',
    defaultMessage: 'Access removed',
  },
  accessRemovedDescription: {
    id: 'frontbucket.settings.permissions.notification.accessRemovedDescription',
    description: 'Message that appears after successful privilege removing',
    defaultMessage:
      '"{entity}" {count, plural, one {was} other {were}} successfully removed.',
  },

  accessChangedTitle: {
    id: 'frontbucket.settings.permissions.notification.accessChangedTitle',
    description: 'Message that appears after successful privilege changing',
    defaultMessage: 'Access changed',
  },
  accessChangedDescription: {
    id: 'frontbucket.settings.permissions.notification.accessChangedDescription',
    description: 'Message that appears after successful privilege changing',
    defaultMessage:
      'Access for "{entity}" {count, plural, one {was} other {were}} successfully changed.',
  },

  // access level dropdown i18n
  project: {
    id: 'frontbucket.settings.permissions.access-level-dropdown.project',
    description: 'Project access level name',
    defaultMessage: 'Project',
  },
  repository: {
    id: 'frontbucket.settings.permissions.access-level-dropdown.repository',
    description: 'Repository access level name',
    defaultMessage: 'Repository',
  },
  workspace: {
    id: 'frontbucket.settings.permissions.access-level-dropdown.workspace',
    description: 'Workspace access level name',
    defaultMessage: 'Workspace',
  },
  allAccessLevels: {
    id: 'frontbucket.settings.permissions.access-level-dropdown.allAccessLevels',
    description: 'Default label for access level dropdown',
    defaultMessage: 'Access level',
  },
  allAccessLevelsMenuItem: {
    id: 'frontbucket.settings.permissions.access-level-dropdown.allAccessLevelsMenuItem',
    description: 'Label for "All access levels" dropdrown menu item',
    defaultMessage: 'All',
  },

  // errors
  inviteFailedErrorHeader: {
    id: 'frontbucket.settings.permissions.notification.inviteFailedErrorHeader',
    description:
      'Header for error notification body that appears if a user could not be added by email or invited.',
    defaultMessage:
      'Issue adding new {count, plural, one {user} other {users}}',
  },
  inviteFailedErrorBody: {
    id: 'frontbucket.settings.permissions.notification.inviteFailedErrorBody',
    description:
      'Message for error notification body that appears if a user could not be added by email or invited.',
    defaultMessage:
      'The following {count, plural, one {user} other {users}} could not be added by email: {entity}. Try again.',
  },
  badRequestErrorHeader: {
    id: 'frontbucket.settings.permissions.notification.badRequestErrorHeader',
    description:
      'Header for error notification body that appears if user was not found',
    defaultMessage:
      'Issue adding existing {count, plural, one {user or group} other {users or groups}}',
  },
  badRequestErrorBody: {
    id: 'frontbucket.settings.permissions.notification.badRequestErrorBody',
    description:
      'Message for error notification body that appears if user was not found',
    defaultMessage:
      'All the users and groups that you selected could not be added because of an issue with {entity}. Remove the {count, plural, one {user or group} other {users or groups}} and try again.',
  },
  unknownErrorHeader: {
    id: 'frontbucket.settings.permissions.notification.unknownAddUserErrorHeader',
    description: 'Message for unknown error notification header',
    defaultMessage: 'Something went wrong',
  },
  unknownAddUserErrorBody: {
    id: 'frontbucket.settings.permissions.notification.unknownAddUserErrorBody',
    description: 'Message for unknown error notification body',
    defaultMessage:
      '{entity} {count, plural, one {was} other {were}} not added. Wait a few moments, then try again.',
  },
  userNotAddedHeader: {
    id: 'frontbucket.settings.permissions.notification.userNotAddedHeader',
    description:
      'Message for known error notification header when user not added',
    defaultMessage: '"{entity}" was not added',
  },

  invitationsSentDescription: {
    id: 'frontbucket.settings.permissions.notification.invitationsSentDescription',
    description: 'Label for the "Edit permissions" button',
    defaultMessage: '{entity} {count, plural, one {was} other {were}} invited.',
  },
  invitationsSent: {
    id: 'frontbucket.settings.permissions.notification.invitationsSent',
    description:
      'Success flag header that shows after user inputs email addresses and triggers invitations',
    defaultMessage: 'Invitations sent',
  },

  personalWorkspaceException: {
    id: 'frontbucket.settings.permissions.notification.personalWorkspaceException',
    description:
      'Error message when trying to add access to personal workspace owner',
    defaultMessage:
      'As an admin of this workspace, {entity} already has implicit admin access to this repository.',
  },
});
