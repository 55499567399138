/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { Children } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
var gutter = "var(--ds-space-negative-050, -4px)";
var listStyles = css({
  display: 'flex',
  margin: "var(--ds-space-0, 0px)",
  padding: "var(--ds-space-0, 0px)",
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  lineHeight: 1,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
  listStyleType: 'none !important',
  marginInlineEnd: gutter,
  marginInlineStart: gutter
});
var listItemStyles = css({
  margin: "var(--ds-space-0, 0px)",
  marginBlockEnd: "var(--ds-space-100, 8px)",
  paddingInlineEnd: "var(--ds-space-050, 4px)",
  paddingInlineStart: "var(--ds-space-050, 4px)"
});
var Grid = function Grid(_ref) {
  var children = _ref.children,
    testId = _ref.testId,
    label = _ref['aria-label'];
  return jsx("ul", {
    "data-testid": testId,
    "aria-label": label,
    css: listStyles
  }, Children.map(children, function (child) {
    return child && jsx("li", {
      css: listItemStyles
    }, child);
  }));
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default Grid;