/**
 * Version 0 of public Cross Flow API
 */

export var VERSION = 0;
export var createAPIv0 = function createAPIv0(onOpen) {
  return {
    open: function open(options) {
      return onOpen(options);
    }
  };
};