import React, { PureComponent } from 'react';

import { injectIntl, InjectedIntl } from 'react-intl';

import Lozenge from '@atlaskit/lozenge';

import messages from './i18n';

function isUser(userType: BB.UserType | null | undefined): userType is BB.User {
  return !!userType && (userType as BB.User).type === 'user';
}

type Props = {
  intl: InjectedIntl;
  user: BB.UserType | null | undefined;
};

class UserStatusLozenge extends PureComponent<Props> {
  getStatus() {
    const { intl, user } = this.props;

    if (!user) {
      return intl.formatMessage(messages.statusLabelDeleted);
    }
    if (isUser(user) && user.account_status === 'inactive') {
      return intl.formatMessage(messages.statusLabelDeactivated);
    }
    if (isUser(user) && user.account_status === 'closed') {
      return intl.formatMessage(messages.statusLabelDeleted);
    }

    return undefined;
  }

  render() {
    const status = this.getStatus();
    return status ? <Lozenge>{status}</Lozenge> : null;
  }
}

export default injectIntl(UserStatusLozenge);
