/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { ModalTitle } from '@atlaskit/modal-dialog';
import { gridSize } from '@atlaskit/theme';

const WRAP_MARGIN = gridSize() / 4;

export const BuildsContainer = styled.div({
  display: 'flex',
  flexFlow: 'column',
  padding: `${gridSize()}px`,
});

// Set `min-width: 0` on the flex child in order to support truncating text
// nested within: https://css-tricks.com/flexbox-truncated-text/
export const BuildInfo = styled.div({
  minWidth: 0,
  padding: `${gridSize() / 4}px`,
});

export const BuildItem = styled.div({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: `${gridSize()}px`,
});

export const BuildName = styled.div({
  display: 'inline-block',
  verticalAlign: 'bottom',
  maxWidth: '100%',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  marginBottom: `${gridSize() / 2}px`,
  overflow: 'hidden',
});

export const BuildState = styled.div({
  flex: '0 0 auto',
  marginRight: `${gridSize()}px`,
});

export const BuildDescription = styled.small({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  display: 'block',
  marginTop: `${WRAP_MARGIN}px`,
  '&::after': {
    content: "'\xb7'",
    width: `${gridSize() * 2}px`,
    display: 'inline-block',
    textAlign: 'center',
  },
  '&:empty::after': {
    display: 'none',
  },
});

export const BuildTime = styled.small({
  marginTop: `${WRAP_MARGIN}px`,
});

export const BuildDetails = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: `-${WRAP_MARGIN}px`,
});

export const DialogTitle = styled(ModalTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});
export const DialogContainer = styled.div({
  paddingBottom: `${gridSize() * 1.75}px`,
});
