import { defineMessages } from 'react-intl';

export default defineMessages({
  finishReview: {
    id: 'frontbucket.repository.pullRequest.finishReview.button',
    description: 'Label for finish review button',
    defaultMessage: 'Finish Review',
  },
  popupTitle: {
    id: 'frontbucket.repository.pullRequest.finishReview.title',
    description: 'Title for finish review popup',
    defaultMessage: 'Comments in Review',
  },
  pendingLink: {
    id: 'frontbucket.repository.pullRequest.finishReview.pendingLink',
    description: 'Link to pending comments and tasks modal',
    defaultMessage: 'View all pending comments and tasks',
  },
  statusFormTitle: {
    id: 'frontbucket.repository.pullRequest.finishReview.status.FormTitle',
    description: 'Prompt for status form',
    defaultMessage: 'Mark pull request as',
  },
  submitButton: {
    id: 'frontbucket.repository.pullRequest.finishReview.status.submitButton',
    description: 'Label for submit button',
    defaultMessage: 'Submit',
  },
  cancelButton: {
    id: 'frontbucket.repository.pullRequest.review.status.cancelButton',
    description: 'Label for cancel button',
    defaultMessage: 'Cancel',
  },
  discardButton: {
    id: 'frontbucket.repository.pullRequest.discardReview.status.discardButton',
    description: 'Label for discard button',
    defaultMessage: 'Discard',
  },
  discardReview: {
    id: 'frontbucket.repository.pullRequest.discardReview.label',
    description: 'Label for discard review',
    defaultMessage: 'Discard Review',
  },
  discardReviewConfirmation: {
    id: 'frontbucket.repository.pullRequest.discardReview.confirmationText',
    description: 'Confirmation text for discarding a review',
    defaultMessage:
      'Are you sure you want to discard your review? This will delete any pending comments and tasks you have created.',
  },
  noStatusTitle: {
    id: 'frontbucket.repository.pullRequest.finishReview.noStatus.title',
    description: 'Title for no status option',
    defaultMessage: 'No status',
  },
  noStatusDescription: {
    id: 'frontbucket.repository.pullRequest.finishReview.noStatus.description',
    description: 'Description for no status option',
    defaultMessage: 'Submit review with no pull request status applied',
  },
  requestChangesTitle: {
    id: 'frontbucket.repository.pullRequest.finishReview.requestChanges.title',
    description: 'Title for request changes option',
    defaultMessage: 'Request changes',
  },
  requestChangesDescription: {
    id: 'frontbucket.repository.pullRequest.finishReview.requestChanges.description',
    description: 'Description for request changes option',
    defaultMessage: 'Submit feedback that must be addressed before merging',
  },
  approveTitle: {
    id: 'frontbucket.repository.pullRequest.finishReview.approve.title',
    description: 'Title for approve option',
    defaultMessage: 'Approve',
  },
  approveDescription: {
    id: 'frontbucket.repository.pullRequest.finishReview.approve.description',
    description: 'Description for approve option',
    defaultMessage: 'This pull request is ready to merge',
  },
  permissionsError: {
    id: 'frontbucket.repository.pullRequest.finishReview.error.permissions',
    description:
      'Error message for insufficient permissions for submitting a review',
    defaultMessage:
      'You have not been granted the permissions required to submit a pull request review.',
  },
  requestChangesError: {
    id: 'frontbucket.repository.pullRequest.finishReview.error.requestChanges',
    description:
      'Error message for requesting changes on a merged pull request',
    defaultMessage:
      'Since this pull request has already been merged, you cannot request changes.',
  },
  discardNoPullRequestIdError: {
    id: 'frontbucket.repository.pullRequest.discardReview.error.noPRId',
    description: 'Error message if PR id is undefined',
    defaultMessage: 'Pull request id is not defined',
  },
  discardChangesError: {
    id: 'frontbucket.repository.pullRequest.discardReview.error.failure',
    description: 'Error message for discarding a review',
    defaultMessage: 'Error deleting pull request review',
  },
  discardReviewSuccess: {
    id: 'frontbucket.repository.pullRequest.discardReview.success',
    description: 'Title for discard review success flag',
    defaultMessage: 'Your review has been deleted',
  },
  errorSubmitTitle: {
    id: 'frontbucket.repository.pullRequest.finishReview.submit.error.title',
    description: 'Title for submit error message flag',
    defaultMessage: 'Unable to submit review',
  },
  errorDiscardTitle: {
    id: 'frontbucket.repository.pullRequest.finishReview.discard.error.title',
    description: 'Title for discard error message flag',
    defaultMessage: 'Unable to delete review',
  },
  successTitle: {
    id: 'frontbucket.repository.pullRequest.finishReview.success',
    description: 'Title for success flag',
    defaultMessage: 'Your review has been submitted',
  },
  generalErrorDescription: {
    id: 'frontbucket.repository.pullRequest.finishReview.error.description',
    description: 'Default description for error message flag',
    defaultMessage:
      'Wait a few moments, then try again. If the problem persists, {supportLink}.',
  },
  supportLink: {
    id: 'frontbucket.repository.pullRequest.finishReview.error.support',
    description: 'Link for contacting support',
    defaultMessage: 'contact support',
  },
  outdatedCommentsTitle: {
    id: 'frontbucket.repository.pullRequest.finishReview.outdatedComments.title',
    description: 'Title for outdated comments modal',
    defaultMessage: 'Outdated Comments',
  },
  outdatedCommentsDescription: {
    id: 'frontbucket.repository.pullRequest.finishReview.outdatedComments.description',
    description: 'Description for outdated comments modal',
    defaultMessage: `You're about to submit a review that will have {outdatedCommentCount}.`,
  },
  outdatedCommentCount: {
    id: 'frontbucket.repository.pullRequest.finishReview.outdatedComments.count',
    description: 'Outdated comments count for modal description',
    defaultMessage: `{numOutdatedComments, plural, one {{numOutdatedComments} outdated comment} other {{numOutdatedComments} outdated comments}}`,
  },
});
