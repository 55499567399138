import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { COMMERCIAL, FEDRAMP_FEDEX, FEDRAMP_MODERATE, PRODUCTION, STAGING } from '../../common/constants';
import { domainMap, fullDomainOverride, subdomainOverride } from '../../common/constants/domains';
import { AllowedDomains } from '../../common/types';
export function configure(data) {
  if (!data || Object.keys(data).length < 1) {
    throw new Error('Data are not available');
  }

  // @ts-ignore - This is causing ts errors when this package is being enrolled into jira local consumption so temporarily ts ignoring this line for now
  globalThis.ATL_CONTEXT_DOMAIN = data;
}
function isValidDomainKey(domainKey) {
  if (!AllowedDomains.includes(domainKey)) {
    return false;
  }
  return true;
}
export function getATLContextDomain(domain, environment) {
  var _domainMap$perimeter$, _domainMap$perimeter, _subdomainOverride$do;
  // Validate this is a valid domain
  if (!isValidDomainKey(domain)) {
    throw new Error("Domain ".concat(domain, " is not supported.\n      Supported domains: ").concat(AllowedDomains.join(', ')));
  }

  // First priority, always return the global context if the product has onboarded
  // @ts-ignore - This is causing ts errors when this package is being enrolled into jira local consumption so temporarily ts ignoring this line for now
  var data = globalThis.ATL_CONTEXT_DOMAIN;
  if (data) {
    return data[domain];
  }
  var _ref = environment !== null && environment !== void 0 ? environment : _getEnvironmentFromDomain(),
    _ref2 = _slicedToArray(_ref, 2),
    env = _ref2[0],
    perimeter = _ref2[1];

  // Second priority, return if there is a full domain override
  var override = fullDomainOverride[domain];
  if (override) {
    var _override$perimeter$e, _override$perimeter;
    return (// Exact match preferred
      (_override$perimeter$e = (_override$perimeter = override[perimeter]) === null || _override$perimeter === void 0 ? void 0 : _override$perimeter[env]) !== null && _override$perimeter$e !== void 0 ? _override$perimeter$e :
      // Fallback to mandatory commercial production
      override[COMMERCIAL][PRODUCTION]
    );
  }

  // Third priority, join the subdomain
  var tld = (_domainMap$perimeter$ = (_domainMap$perimeter = domainMap[perimeter]) === null || _domainMap$perimeter === void 0 ? void 0 : _domainMap$perimeter[env]) !== null && _domainMap$perimeter$ !== void 0 ? _domainMap$perimeter$ : domainMap[COMMERCIAL][PRODUCTION];
  var subdomain = (_subdomainOverride$do = subdomainOverride[domain]) !== null && _subdomainOverride$do !== void 0 ? _subdomainOverride$do : domain;
  return "".concat(subdomain, ".").concat(tld);
}
export function getATLContextUrl(domain) {
  return "".concat(globalThis.location.protocol, "//").concat(getATLContextDomain(domain));
}
export function _getEnvironmentFromDomain() {
  var _globalThis$location;
  var hostname = (_globalThis$location = globalThis.location) === null || _globalThis$location === void 0 ? void 0 : _globalThis$location.hostname;
  if (!hostname) {
    // default if no location available
    return [PRODUCTION, COMMERCIAL];
  }

  // *.atlassian-fex.com
  if (hostname.match(/atlassian-fex\.com/)) {
    return [STAGING, FEDRAMP_FEDEX];
  }

  // *.stg.atlassian-us-gov-mod.com OR *.stg.atlassian-us-gov-mod.net
  if (hostname.match(/stg\.atlassian-us-gov-mod\.(com|net)/)) {
    return [STAGING, FEDRAMP_MODERATE];
  }

  // *.atlassian-stg-fedm.net
  if (hostname.match(/atlassian-stg-fedm\.net/)) {
    return [STAGING, FEDRAMP_MODERATE];
  }

  // *.atlassian-us-gov-mod.com OR *.atlassian-us-gov-mod.net
  if (hostname.match(/atlassian-us-gov-mod\.(com|net)/)) {
    return [PRODUCTION, FEDRAMP_MODERATE];
  }

  // *.stg.atlassian.com OR *.stg.internal.atlassian.com
  if (hostname.match(/stg(\.internal)?\.atlassian\.com/)) {
    return [STAGING, COMMERCIAL];
  }

  // *.jira-dev.com
  if (hostname.match(/jira-dev\.com/)) {
    return [STAGING, COMMERCIAL];
  }

  // default fallthrough
  return [PRODUCTION, COMMERCIAL];
}