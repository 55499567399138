/* stylelint-disable a11y/media-prefers-reduced-motion */

import styled, { keyframes } from 'styled-components';

// AFP-2532 TODO: Fix automatic suppressions below
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

const fadeIn = keyframes({
  // @ts-expect-error -- pending a typing patch for styled-components@3.4
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});
const spin = keyframes({
  // @ts-expect-error -- pending a typing patch for styled-components@3.4
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(1080deg)',
  },
});
export const Wrapper = styled.span`
  display: flex;
  line-height: 16px;
  transition: opacity 1s ease-in;
  font-weight: bold;
  svg {
    width: 16px;
    height: 16px;
    margin: 0 5px;
    transform-origin: 50%;
  }
  &[data-state='pending'] {
    color: ${token('color.icon.information', colors.B300)};
    animation: ${fadeIn} 0.25s ease-in;
  }
  &[data-state='failed'] {
    color: ${token('color.icon.danger', colors.R400)};
    animation: ${fadeIn} 0.25s ease-in;
  }
  &[data-state='success'],
  &[data-state='rerun'] {
    color: ${token('color.icon.success', colors.G400)};
    animation: ${fadeIn} 0.25s ease-in;
  }
  &[data-state='paused'] {
    color: ${token('color.icon.subtle', colors.N100)};
    animation: ${fadeIn} 0.25s ease-in;
  }
  &[data-state='halted'] {
    color: ${token('color.icon.warning', colors.Y500)};
    animation: ${fadeIn} 0.25s ease-in;
  }
  &[data-state='stopped'] {
    color: ${token('color.icon.subtle', colors.N100)};
    animation: ${fadeIn} 0.25s ease-in;
  }
  &[data-state='redeploy'] {
    color: ${token('color.icon.success', colors.G400)};
    svg {
      overflow: visible;
    }
  }
  &[data-state='failed_redeploy'] {
    color: ${token('color.icon.danger', colors.R400)};
    svg {
      overflow: visible;
    }
  }
  &[data-state='building'] {
    color: ${token('color.icon.information', colors.B300)};
    svg {
      animation: fadein 0.25s ease-in, ${spin} 2.5s infinite ease-in-out;
    }
  }
  &[data-state='complete'] {
    svg {
      width: 16px;
      height: 16px;
      margin: 0 5px;
    }
  }
  &[data-state='default'] {
    svg {
      width: 14px;
      height: 14px;
      padding: 1px;
      margin: 0 5px;
    }
  }
`;
