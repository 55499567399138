import { ModelBase } from './ModelBase';

export class Account extends ModelBase {
  readonly uuid: string = '';
  readonly addonKey: string = '';
  readonly userUuid: string = '';
  readonly accountId: string = '';
  readonly userEmail: string = '';
  readonly userHas2FaEnabled: boolean = false;
  readonly hasFetchedUser: boolean = false;
  readonly name: string = '';
  readonly avatarSrc: string = '';

  constructor(props: Partial<Account> = {}) {
    super(props);
    Object.assign(this, props);
    Object.freeze(this);
  }
}
