/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import * as Sentry from '@sentry/browser';
import { clone } from 'lodash-es';
import { call, put, select, all, take, race } from 'redux-saga/effects';

import { User } from 'src/components/types';
import { LoadingStatus } from 'src/constants/loading-status';
import { EnteredCodeReviewAction } from 'src/redux/pull-request/actions';
import { getCurrentPullRequestId } from 'src/redux/pull-request/selectors';
import { getCurrentRepositoryFullSlug } from 'src/selectors/repository-selectors';
import { getCurrentUser } from 'src/selectors/user-selectors';
import { Action } from 'src/types/state';
import urls from 'src/urls/jira';
import authRequest from 'src/utils/fetch';
import prefs from 'src/utils/preferences';
import { captureMessageForResponse } from 'src/utils/sentry';

import {
  FETCH_PULL_REQUEST_JIRA_ISSUES,
  FETCH_AVAILABLE_ISSUE_TRANSITIONS,
  TRANSITION_ISSUES,
  initIssueTransitions,
  FETCH_TRANSITION_USER_PREFERENCE,
} from '../actions';
import { IssueCategory } from '../constants';
import { PullRequestJiraIssuesState } from '../reducers/pull-request-jira-issues';
import {
  getIssueTransitionForm,
  getPullRequestJiraIssues,
  getPullRequestJiraIssuesState,
} from '../selectors/jira-issue-selectors';
import {
  IssueTransitionFormRowData,
  PrCommentJiraIssue,
  IssueTransition,
} from '../types';

export function* fetchAvailableIssueTransitionsSaga({
  payload: index,
}: Action<number>) {
  const { issueTransitionPreferenceFetchedStatus }: PullRequestJiraIssuesState =
    yield select(getPullRequestJiraIssuesState);
  if (
    issueTransitionPreferenceFetchedStatus !== LoadingStatus.Failed &&
    issueTransitionPreferenceFetchedStatus !== LoadingStatus.Success
  ) {
    yield race({
      success: take(FETCH_TRANSITION_USER_PREFERENCE.SUCCESS),
      failure: take(FETCH_TRANSITION_USER_PREFERENCE.ERROR),
    });
  }
  const {
    issueTransitionFormData,
    issueTransitionPreference,
  }: PullRequestJiraIssuesState = yield select(getPullRequestJiraIssuesState);
  try {
    const prIssue = issueTransitionFormData[index!].selectedIssue;

    const url = urls.api.internal.availableTransitions(
      prIssue.issue.site.cloudId,
      prIssue.issue.key
    );
    const request = authRequest(url);
    const response: Response = yield call(fetch, request);

    if (response.ok) {
      // @ts-ignore
      const data = yield response.json();
      const transitionData = clone(issueTransitionFormData[index!]);
      const automaticTransitions = data.filter(
        (transition: IssueTransition) =>
          transition.triggers &&
          transition.triggers.filter(
            (trigger: string) => trigger === 'pull_request_merged'
          ).length > 0
      );
      const availableTransitions = data
        // Exclude unsupported transitions
        .filter(
          (transition: IssueTransition) =>
            transition.isAvailable && !transition.hasScreen
        )
        // Order the remaining transitions according to their status category.
        .sort((a: IssueTransition, b: IssueTransition) => {
          if (a.to?.statusCategory.key === b.to?.statusCategory.key) {
            return 0;
          }
          if (
            a.to?.statusCategory.key === IssueCategory.Todo ||
            b.to?.statusCategory.key === IssueCategory.Done
          ) {
            return -1;
          }
          return 1;
        });

      // Use the transition saved in user preference API as the default option.
      // If this transition doesn't exist in the list of available transitions
      // then use the transition which is at the end of the list sorted by status
      // category.
      let selectedTransition;
      selectedTransition = availableTransitions.find(
        (transition: IssueTransition) =>
          transition.id === issueTransitionPreference?.toString()
      );

      if (!issueTransitionPreference || !selectedTransition) {
        selectedTransition = availableTransitions
          ? availableTransitions[availableTransitions.length - 1]
          : undefined;
      }

      const updatedTransitionForm = {
        ...transitionData,
        availableIssueTransitions: availableTransitions,
        availableIssueTransitionsFetchedStatus: LoadingStatus.Success,
        automaticTransition: automaticTransitions
          ? automaticTransitions[0]
          : undefined,
        selectedTransition,
        hasUnsupportedTransitions: availableTransitions.length !== data.length,
      };

      yield put({
        type: FETCH_AVAILABLE_ISSUE_TRANSITIONS.SUCCESS,
        payload: {
          updatedTransitionForm,
          index,
        },
      });
    } else {
      yield captureMessageForResponse(
        response,
        'Fetching available issue transitions failed'
      );
      yield put({
        type: FETCH_AVAILABLE_ISSUE_TRANSITIONS.ERROR,
        payload: index,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_AVAILABLE_ISSUE_TRANSITIONS.ERROR,
      payload: index,
    });
  }
}

function* fetchIssuesRecursively(
  repositoryFullSlug: string,
  pullRequestId: number | string,
  currentPage: number,
  values: PrCommentJiraIssue[] = []
): Generator<any, void, any> {
  const url = `${urls.api.internal.issues(
    repositoryFullSlug,
    pullRequestId
  )}?page=${currentPage}`;
  const request = authRequest(url);
  const response: Response = yield call(fetch, request);

  if (response.ok) {
    const data = yield response.json();
    if (Array.isArray(data.values)) {
      values.push(...data.values);
    }

    // Due to doing multiple separate requests, we could end up with a situation
    // where values.length and data.size never match, so also stop if we get no
    // new results in the page.
    if (values.length === data.size || (data.values || []).length === 0) {
      yield put({
        type: FETCH_PULL_REQUEST_JIRA_ISSUES.SUCCESS,
        payload: values,
      });
    } else {
      yield fetchIssuesRecursively(
        repositoryFullSlug,
        pullRequestId,
        currentPage + 1,
        values
      );
    }
  } else {
    // Receiving a 404 error is the expected behaviour when a user isn't
    // authenticated so don't send an error to sentry.
    if (response.status !== 404) {
      yield captureMessageForResponse(
        response,
        'Fetching PR Jira issues failed'
      );
    }
    yield put({
      type: FETCH_PULL_REQUEST_JIRA_ISSUES.ERROR,
    });
  }
}

export function* fetchPullRequestJiraIssuesSaga({
  owner,
  slug,
  id: pullRequestId,
}: EnteredCodeReviewAction) {
  const repositoryFullSlug = `${owner}/${slug}`;

  try {
    yield fetchIssuesRecursively(repositoryFullSlug, pullRequestId, 1);
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_PULL_REQUEST_JIRA_ISSUES.ERROR,
    });
  }
}
const transitionIssueUserPrefKey = 'transition-issue-on-merge-preference';

export function* updateTransitionUserPreference(transitionId: string) {
  const currentUser: User = yield select(getCurrentUser);
  if (!currentUser) {
    return;
  }

  try {
    yield call(
      prefs.set,
      currentUser.uuid,
      transitionIssueUserPrefKey,
      transitionId
    );
  } catch (e) {
    Sentry.captureException(e);
  }
}

export function* fetchTransitionUserPreference() {
  const currentUser: User = yield select(getCurrentUser);
  if (!currentUser) {
    return;
  }

  try {
    // @ts-ignore
    const preferencesRaw = yield call(
      prefs.get,
      currentUser.uuid,
      transitionIssueUserPrefKey
    );
    const preferences = preferencesRaw ? JSON.parse(preferencesRaw) : undefined;

    if (preferences) {
      yield put({
        type: FETCH_TRANSITION_USER_PREFERENCE.SUCCESS,
        payload: preferences,
      });
    } else {
      yield put({
        type: FETCH_TRANSITION_USER_PREFERENCE.ERROR,
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_TRANSITION_USER_PREFERENCE.ERROR,
    });
    Sentry.captureException(e);
  }
}

export function* initialiseIssueTransitions() {
  const allPrIssues: PrCommentJiraIssue[] = yield select(
    getPullRequestJiraIssues
  );
  const prIssues: PrCommentJiraIssue[] = allPrIssues.slice(0, 3);

  const transitions: IssueTransitionFormRowData[] = prIssues.map(prIssue => ({
    selectedIssue: prIssue,
    availableIssueTransitions: [],
    availableIssueTransitionsFetchedStatus: LoadingStatus.Before,
    automaticTransition: undefined,
    selectedTransition: undefined,
    shouldTransition: false,
    hasUnsupportedTransitions: false,
  }));
  yield put(initIssueTransitions(transitions));
  yield all(
    transitions.map((_, index) =>
      call(fetchAvailableIssueTransitionsSaga, {
        type: FETCH_AVAILABLE_ISSUE_TRANSITIONS.REQUEST,
        payload: index,
      })
    )
  );
}

export function* transitionIssuesSaga() {
  const repositoryFullSlug: string = yield select(getCurrentRepositoryFullSlug);
  const pullRequestId: number = yield select(getCurrentPullRequestId);
  const issueTransitionForm: IssueTransitionFormRowData[] = yield select(
    getIssueTransitionForm
  );
  const issueTransitions = issueTransitionForm.filter(
    issue => issue.shouldTransition
  );

  try {
    const url = urls.api.internal.transitions(
      repositoryFullSlug,
      pullRequestId!
    );
    const body = {
      transitions: issueTransitions.map(
        (transition: IssueTransitionFormRowData) => {
          return {
            cloudId: transition.selectedIssue?.issue.site.cloudId,
            issueKeyOrId: transition.selectedIssue?.issue.key,
            transitionId: transition.selectedTransition?.id,
          };
        }
      ),
    };
    const request = authRequest(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const response: Response = yield call(fetch, request);

    if (response.ok) {
      yield put({
        type: TRANSITION_ISSUES.SUCCESS,
      });

      // If there are transitions of multiple types, we will save the first one as their preference.
      if (issueTransitions[0].selectedTransition) {
        yield call(
          updateTransitionUserPreference,
          issueTransitions[0].selectedTransition.id
        );
      }
    } else {
      yield captureMessageForResponse(response, 'Transitioning issue failed');
      yield put({
        type: TRANSITION_ISSUES.ERROR,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: TRANSITION_ISSUES.ERROR,
    });
  }
}
