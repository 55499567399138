// Data Plugin definition and utility types

export var PluginTypes = {
  GetContainers: 'GetContainers',
  GetUsers: 'GetUsers',
  GetSuggestedSiteNames: 'GetSuggestedSiteNames',
  GetAvailableSites: 'GetAvailableSites'
};

// GetContainers plugin types
export var ContainerTypes = {
  BITBUCKET_WORKSPACE: 'bitbucket-workspace',
  ATLASSIAN_CLOUD_ID: 'ATLASSIAN_CLOUD_ID',
  JIRA_SOFTWARE_PROJECT: 'JIRA_SOFTWARE_PROJECT'
};
// GetUsers plugin types

export var UserIdTypes = {
  ATLASSIAN_ACCOUNT_ID: 'ATLASSIAN_ACCOUNT_ID',
  TRELLO_ID: 'TRELLO_ID'
};
// Coincides with the product types in the Available Products Service
export var SwitcherProductType = {
  JIRA_BUSINESS: 'JIRA_BUSINESS',
  JIRA_SERVICE_DESK: 'JIRA_SERVICE_DESK',
  JIRA_SOFTWARE: 'JIRA_SOFTWARE',
  CONFLUENCE: 'CONFLUENCE',
  OPSGENIE: 'OPSGENIE',
  BITBUCKET: 'BITBUCKET',
  STATUSPAGE: 'STATUSPAGE',
  TRELLO: 'TRELLO',
  COMPASS: 'COMPASS'
};