/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { select, call } from 'redux-saga/effects';

import { User } from 'src/components/types';
import { getPullRequestsFilters } from 'src/redux/dashboard/selectors/pull-requests';
import { getCurrentUser } from 'src/selectors/user-selectors';
import prefs from 'src/utils/preferences';

export default function* filterPullRequestSaga() {
  const user: User = yield select(getCurrentUser);
  const pullRequestFilters: string[] = yield select(getPullRequestsFilters);

  if (user) {
    yield call(
      prefs.set,
      user.uuid,
      'dashboard:pullRequests:filter',
      JSON.stringify(pullRequestFilters)
    );
  }
}
