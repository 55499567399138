// https://en.wikipedia.org/wiki/HCL_color_space

// https://en.wikipedia.org/wiki/CIE_1931_color_space#CIE_xy_chromaticity_diagram_and_the_CIE_xyY_color_space

// https://en.wikipedia.org/wiki/CIELAB_color_space

var clamp = function clamp(i, min, max) {
  return Math.round(Math.min(Math.max(i, min), max));
};
var expandShorthandHex = function expandShorthandHex(input) {
  return input.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });
};
var rgbFromHex = function rgbFromHex(input) {
  var fullHex = expandShorthandHex(input);
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(fullHex);
  return result === null ? null : {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  };
};
var rgbToHex = function rgbToHex(_ref) {
  var r = _ref.r,
    g = _ref.g,
    b = _ref.b;
  var convertComponent = function convertComponent(c) {
    var cBase16 = c.toString(16);
    return cBase16.length === 1 ? "0".concat(cBase16) : cBase16;
  };
  return "#".concat(convertComponent(r)).concat(convertComponent(g)).concat(convertComponent(b));
};
var rgbToXyz = function rgbToXyz(rgb) {
  var convertRgbComponent = function convertRgbComponent(c) {
    return c > 0.04045 ? Math.pow((c + 0.055) / 1.055, 2.4) : c / 12.92;
  };
  var convertXyzComponent = function convertXyzComponent(c) {
    return c > 0.008856452 ? Math.pow(c, 1 / 3) : c / 0.12841855 + 0.137931034;
  };
  var r = convertRgbComponent(rgb.r / 255);
  var g = convertRgbComponent(rgb.g / 255);
  var b = convertRgbComponent(rgb.b / 255);
  return {
    x: convertXyzComponent((0.4124564 * r + 0.3575761 * g + 0.1804375 * b) / 0.95047),
    y: convertXyzComponent(0.2126729 * r + 0.7151522 * g + 0.072175 * b),
    z: convertXyzComponent((0.0193339 * r + 0.119192 * g + 0.9503041 * b) / 1.08883)
  };
};
var xyzToLab = function xyzToLab(_ref2) {
  var x = _ref2.x,
    y = _ref2.y,
    z = _ref2.z;
  return {
    l: Math.max(116 * y - 16, 0),
    a: 500 * (x - y),
    b: 200 * (y - z)
  };
};
var labToLch = function labToLch(_ref3) {
  var l = _ref3.l,
    a = _ref3.a,
    b = _ref3.b;
  var h = (Math.atan2(b, a) * (180 / Math.PI) + 360) % 360;
  var c = Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
  if (Math.round(c * 10000) === 0) h = Number.NaN;
  return {
    l: l,
    c: c,
    h: h
  };
};
var lchToLab = function lchToLab(_ref4) {
  var l = _ref4.l,
    c = _ref4.c,
    h = _ref4.h;
  var convertH = Number.isNaN(h) ? 0 : h * (Math.PI / 180);
  return {
    l: l,
    a: Math.cos(convertH) * c,
    b: Math.sin(convertH) * c
  };
};
var labToXyz = function labToXyz(_ref5) {
  var l = _ref5.l,
    a = _ref5.a,
    b = _ref5.b;
  var convertComponent = function convertComponent(c) {
    return c > 0.206896552 ? Math.pow(c, 3) : 0.12841855 * (c - 0.137931034);
  };
  var y = (l + 16) / 116;
  var x = a / 500 + y;
  var z = y - b / 200;
  return {
    x: convertComponent(x) * 0.95047,
    y: convertComponent(y),
    z: convertComponent(z) * 1.08883
  };
};
var xyzToRgb = function xyzToRgb(_ref6) {
  var x = _ref6.x,
    y = _ref6.y,
    z = _ref6.z;
  var convertComponent = function convertComponent(c) {
    return 255 * (c <= 0.00304 ? 12.92 * c : 1.055 * Math.pow(c, 1 / 2.4) - 0.055);
  };
  return {
    r: clamp(convertComponent(3.2404542 * x - 1.5371385 * y - 0.4985314 * z), 0, 255),
    g: clamp(convertComponent(-0.969266 * x + 1.8760108 * y + 0.041556 * z), 0, 255),
    b: clamp(convertComponent(0.0556434 * x - 0.2040259 * y + 1.0572252 * z), 0, 255)
  };
};
var rgbToLch = function rgbToLch(rgb) {
  return labToLch(xyzToLab(rgbToXyz(rgb)));
};
var lchToRgb = function lchToRgb(lch) {
  return xyzToRgb(labToXyz(lchToLab(lch)));
};
export var clampLightness = function clampLightness(color, newPercent) {
  var rgb = rgbFromHex(color);
  if (rgb === null) {
    return color;
  }

  // LCH (rather than HSL) gives the best results here as the L component in LCH is based on human color perception
  var lch = rgbToLch(rgb);
  lch.l = clamp(newPercent, 0, 100);
  return rgbToHex(lchToRgb(lch));
};
var getLightness = function getLightness(color) {
  var rgb = rgbFromHex(color);
  if (rgb === null) {
    return 0;
  }
  var lch = rgbToLch(rgb);
  return lch.l;
};
export var getDarkModeLCHColor = function getDarkModeLCHColor(currentBackgroundColor) {
  var lightness = getLightness(currentBackgroundColor);
  var newLightness = Math.abs(100 - lightness);
  return clampLightness(currentBackgroundColor, newLightness).toUpperCase();
};