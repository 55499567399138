import React, { ErrorInfo, PureComponent } from 'react';

import { captureExceptionWithTags } from 'src/utils/sentry';

import { SectionError } from './section-error';

type Props = {
  sectionHeading?: JSX.Element;
  sentryComponentId: string;
};

type State = {
  hasError: boolean;
  sentryEventId: string | null;
};

export class SectionErrorBoundary extends PureComponent<Props, State> {
  // According to `@types/react` this *is* explicitly an `any` type
  static getDerivedStateFromError(error: any) {
    // eslint-disable-next-line no-console
    console.error(error);
    return {
      hasError: true,
    };
  }

  state = {
    hasError: false,
    sentryEventId: null,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error(errorInfo);
    const componentTag = `${this.props.sentryComponentId}-section-error-boundary`;
    captureExceptionWithTags(
      error,
      { component: componentTag },
      { componentStack: errorInfo.componentStack }
    ).then((sentryEventId: string) => {
      this.setState({ sentryEventId });
    });
  }

  render() {
    const { hasError, sentryEventId } = this.state;
    const { children, sectionHeading } = this.props;

    if (!hasError) {
      return children;
    }

    return (
      <SectionError
        sectionHeading={sectionHeading}
        sentryEventId={sentryEventId || undefined}
      />
    );
  }
}
