import React, { useMemo } from 'react';

import { useDispatch } from 'react-redux';

import Flag from '@atlaskit/flag';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import { toggleSettingsDialog } from 'src/redux/diff-settings';
import { ComponentFlagProps } from 'src/redux/flags/types';

import { AppearanceTypes } from '../simple/simple-flag';

import messages from './file-mode-onboarding.i18n';

export const FileModeOnboardingFlag = React.memo(
  (props: ComponentFlagProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const { id, handleDismissed } = props;
    const actions = useMemo(() => {
      const actionItems = [
        {
          content: intl.formatMessage(messages.gotItAction),
          onClick: () => {
            handleDismissed(id);
          },
        },
      ];
      actionItems.push({
        content: intl.formatMessage(messages.viewPrefsAction),
        onClick: () => {
          dispatch(toggleSettingsDialog(true));
          handleDismissed(id);
        },
      });
      return actionItems;
    }, [dispatch, intl, handleDismissed, id]);

    return (
      <Flag
        actions={actions}
        appearance={AppearanceTypes.normal}
        description={intl.formatMessage(
          messages.fileModeOnboardingFlagDescription
        )}
        icon={
          <InfoIcon
            label=""
            primaryColor={token('color.icon.discovery', colors.P300)}
          />
        }
        title={intl.formatMessage(messages.fileModeOnboardingFlagTitle)}
        {...props}
      />
    );
  }
);
