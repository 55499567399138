import { defineMessages } from 'react-intl-next';
export default defineMessages({
  removeFromFavouritesLabel: {
    id: 'rovo-chat.browse-agents.remove-from-favourites-label',
    defaultMessage: 'Remove from favourites',
    description: 'Remove from favourites label'
  },
  clickToFavouriteLabel: {
    id: 'rovo-chat.browse-agents.click-to-favourite-label',
    defaultMessage: 'Not favourited, click to favourite',
    description: 'Click to favourite agent label'
  }
});