import {
  TestCase,
  TestCaseReason,
  TestCaseReasonError,
} from 'src/components/pipelines/models';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import {
  CLEAR_CURRENT_PIPELINE,
  COLLAPSE_TEST_CASE,
  EXPAND_TEST_CASE,
  REQUEST_FAILED_TEST_CASES,
  REQUEST_TEST_CASE_REASON,
} from '../actions/pipelines';

export type TestReportsState = {
  expandedTestCaseMap: { [key: string]: boolean };
  fetchingFailedTestCases: boolean;
  fetchingTestReasonMap: { [key: string]: boolean };
  testCaseReasonMap: { [key: string]: TestCaseReason };
  testCaseErrorMap: { [key: string]: TestCaseReasonError };
  failedTestCases: { [key: string]: any[] };
};

export const initialState: TestReportsState = {
  expandedTestCaseMap: {},
  fetchingFailedTestCases: false,
  fetchingTestReasonMap: {},
  testCaseReasonMap: {},
  testCaseErrorMap: {},
  failedTestCases: {},
};

export default createReducer(initialState, {
  [CLEAR_CURRENT_PIPELINE]() {
    return { ...initialState };
  },
  [EXPAND_TEST_CASE](
    state: TestReportsState,
    action: Action & { meta: { testCaseUuid: string } }
  ) {
    if (!action.meta?.testCaseUuid) {
      return state;
    }
    return {
      ...state,
      expandedTestCaseMap: {
        ...state.expandedTestCaseMap,
        [action.meta.testCaseUuid]: true,
      },
    };
  },
  [COLLAPSE_TEST_CASE](
    state: TestReportsState,
    action: Action & { meta: { testCaseUuid: string } }
  ) {
    if (!action.meta?.testCaseUuid) {
      return state;
    }
    return {
      ...state,
      expandedTestCaseMap: {
        ...state.expandedTestCaseMap,
        [action.meta.testCaseUuid]: false,
      },
    };
  },
  [REQUEST_FAILED_TEST_CASES.REQUEST](state: TestReportsState) {
    return { ...state, fetchingFailedTestCases: true };
  },
  [REQUEST_FAILED_TEST_CASES.SUCCESS](
    state: TestReportsState,
    action: Action<{ values: any[] }> & { meta: { stepUuid: string } }
  ) {
    if (!action.meta?.stepUuid || !action?.payload?.values) {
      return state;
    }
    return {
      ...state,
      fetchingFailedTestCases: false,
      failedTestCases: {
        ...state.failedTestCases,
        [action.meta.stepUuid]: action.payload.values.map(
          (data: any) => new TestCase(data)
        ),
      },
    };
  },
  [REQUEST_TEST_CASE_REASON.REQUEST](
    state: TestReportsState,
    action: Action & { meta: { testCaseUuid: string } }
  ) {
    if (!action.meta?.testCaseUuid) {
      return state;
    }
    return {
      ...state,
      fetchingTestReasonMap: {
        ...state.fetchingTestReasonMap,
        [action.meta.testCaseUuid]: true,
      },
    };
  },
  [REQUEST_TEST_CASE_REASON.SUCCESS](
    state: TestReportsState,
    action: Action<any> & { meta: { testCaseUuid: string } }
  ) {
    if (!action.meta?.testCaseUuid || !action.payload) {
      return state;
    }
    return {
      ...state,
      fetchingTestReasonMap: {
        ...state.fetchingTestReasonMap,
        [action.meta.testCaseUuid]: false,
      },
      testCaseReasonMap: {
        ...state.testCaseReasonMap,
        [action.meta.testCaseUuid]: new TestCaseReason(action.payload),
      },
    };
  },
  [REQUEST_TEST_CASE_REASON.ERROR](
    state: TestReportsState,
    action: Action<any> & { meta: { testCaseUuid: string } }
  ) {
    if (!action.meta?.testCaseUuid || !action.payload) {
      return state;
    }
    return {
      ...state,
      fetchingTestReasonMap: {
        ...state.fetchingTestReasonMap,
        [action.meta.testCaseUuid]: false,
      },
      testCaseErrorMap: {
        ...state.testCaseErrorMap,
        [action.meta.testCaseUuid]: new TestCaseReasonError(action.payload),
      },
    };
  },
});
