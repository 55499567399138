import { createResource } from 'react-resource-router';

import authRequest from 'src/utils/fetch';

import { ShadowRepositoryConfiguration } from '../models/ShadowRepositoryConfiguration';
import { getShadowRepositoryConfigurationUrl } from '../utils/shaow-repository-configuration';

export const loadShadowRepositoryConfigurationResource =
  createResource<ShadowRepositoryConfiguration>({
    type: 'shadowRepositoryConfiguration',
    getKey: ({ match }) => getShadowRepositoryConfigurationUrl(match.params),
    maxAge: 60000, // cache for 1 minute so navigating between pages is fast
    getData: async ({ match }) => {
      const url = getShadowRepositoryConfigurationUrl(match.params);
      const data = await (await fetch(authRequest(url))).json();
      return new ShadowRepositoryConfiguration(data);
    },
  });
