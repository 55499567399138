import qs from 'qs';
/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { all, call, put, race, select, take } from 'redux-saga/effects';

// @ts-ignore TODO: fix noImplicitAny error here
import diffParser from '@atlassian/diffparser';
import { EnvironmentType } from '@atlassiansox/feature-flag-web-client';

import {
  Commit,
  Deployment,
  Environment,
  Issue,
  PullRequest,
} from 'src/components/pipelines/models';
import {
  getDeploymentDashboardVersion,
  getDeploymentDashboardWithTypeNames,
  getEnvironmentsByUuid,
} from 'src/components/pipelines/redux/selectors/deployments';
import { getCurrentRepository } from 'src/selectors/repository-selectors';
import { Action } from 'src/types/state';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

import { BRANCH_RESTRICTIONS_PAGE_SIZE } from '../../constants';
import envBaseUrl from '../../utils/env-base-url';
import {
  capturePipelinesExceptionWithTags,
  createErrorMessage,
} from '../../utils/sentry';
import {
  REMOVE_DEPLOYMENT_DATA,
  REQUEST_BRANCH_RESTRICTIONS,
  REQUEST_CREATE_BRANCH_RESTRICTION,
  REQUEST_CREATE_ENVIRONMENT,
  REQUEST_DELETE_BRANCH_RESTRICTION,
  REQUEST_DELETE_ENVIRONMENT,
  REQUEST_DEPLOYMENT_CHANGES,
  REQUEST_DEPLOYMENT_COMMITS,
  REQUEST_DEPLOYMENT_DASHBOARD,
  REQUEST_DEPLOYMENT_PULL_REQUESTS,
  REQUEST_DEPLOYMENT_SUMMARY,
  REQUEST_ENVIRONMENT_HISTORY,
  REQUEST_REDEPLOYMENT,
  REQUEST_REORDER_ENVIRONMENTS,
  REQUEST_UPDATE_ENVIRONMENT,
} from '../actions/deployments';

export const getDeploymentDashboardUrl = (
  repositoryFullSlug: string,
  queryParams: { includeHidden?: boolean; fields?: string } = {}
) => {
  const defaultExcludeHiddenFields =
    '+groupings.environment_summaries.latest_successful_deployment.deployable.commit.*.*,' +
    '-groupings.environment_summaries.latest_successful_deployment.deployable.commit.parents,' +
    '-groupings.environment_summaries.latest_successful_deployment.deployable.commit.links.patch,' +
    '-groupings.environment_summaries.latest_successful_deployment.deployable.commit.properties,' +
    '+groupings.environment_summaries.latest_successful_deployment.step.run_number,' +
    '+groupings.environment_summaries.latest_successful_deployment.step.state.*.*,' +
    '+groupings.environment_summaries.latest_successful_deployment.step.trigger.*.*,' +
    '+groupings.environment_summaries.latest_deployment.deployable.commit.*.*,' +
    '-groupings.environment_summaries.latest_deployment.deployable.commit.parents,' +
    '-groupings.environment_summaries.latest_deployment.deployable.commit.links.patch,' +
    '-groupings.environment_summaries.latest_deployment.deployable.commit.properties,' +
    '+groupings.environment_summaries.latest_deployment.step.run_number,' +
    '+groupings.environment_summaries.latest_deployment.step.state.*.*,' +
    '+groupings.environment_summaries.latest_deployment.step.trigger.*.*,' +
    '+groupings.environment_summaries.next_promotion.environment.name';
  const defaultIncludeHiddenFields =
    '-groupings.environment_summaries.latest_deployment,' +
    '-groupings.environment_summaries.latest_successful_deployment';
  const {
    fields = queryParams.includeHidden
      ? defaultIncludeHiddenFields
      : defaultExcludeHiddenFields,
  } = queryParams;
  return `${envBaseUrl(
    '/!api/internal',
    'deployments'
  )}/repositories/${repositoryFullSlug}/environment-dashboard?${qs.stringify(
    { ...queryParams, fields },
    { allowDots: true }
  )}`;
};

export const getDeploymentUrl = (
  repositoryFullSlug: string,
  deploymentUuid: string,
  queryParams: any = {}
) => {
  const allQueryParams = {
    fields:
      '+deployable.commit.*,' +
      '+deployable.commit.author.*,' +
      '-deployable.commit.properties,' +
      '+environment.*,' +
      '+step.*,' +
      '+state.deployer.*,' +
      '+state.last_successful_deployment.*,' +
      '+state.last_successful_deployment.step.*,' +
      '+state.last_successful_deployment.state.deployer.*,' +
      '+state.last_successful_deployment.deployable.commit.*,' +
      '+state.last_successful_deployment.deployable.commit.author.*,' +
      '-state.last_successful_deployment.deployable.commit.properties',
    ...queryParams,
  };
  return `${envBaseUrl(
    '/!api/2.0',
    'deployments'
  )}/repositories/${repositoryFullSlug}/deployments/${deploymentUuid}?${qs.stringify(
    allQueryParams,
    { allowDots: true }
  )}`;
};

export const getDeploymentByStepUrl = (
  repositoryFullSlug: string,
  pipelineUuid: string,
  stepUuid: string
) => {
  const allQueryParams = {
    fields:
      '+deployable.commit.*,' +
      '+deployable.commit.author.*,' +
      '-deployable.commit.properties,' +
      '+environment.*,' +
      '+step.*,' +
      '+state.deployer.*,' +
      '+state.last_successful_deployment.*,' +
      '+state.last_successful_deployment.step.*,' +
      '+state.last_successful_deployment.state.deployer.*,' +
      '+state.last_successful_deployment.deployable.commit.*,' +
      '+state.last_successful_deployment.deployable.commit.author.*,' +
      '-state.last_successful_deployment.deployable.commit.properties',
  };
  return `${envBaseUrl(
    '/!api/internal',
    'deployments',
    true
  )}/repositories/${repositoryFullSlug}/pipelines/${pipelineUuid}/steps/${stepUuid}/deployment?${qs.stringify(
    allQueryParams,
    { allowDots: true }
  )}`;
};

export const getDeploymentsUrl = (
  repositoryFullSlug: string,
  queryParams: any = {}
) => {
  const allQueryParams = {
    'state.name': ['COMPLETED', 'IN_PROGRESS', 'PENDING'],
    fields:
      '-values.release,' +
      '-values.state.last_successful_deployment,' +
      '+values.deployable.commit.message,' +
      '+values.deployable.commit.summary.*,' +
      '+values.deployable.commit.date,' +
      '+values.deployable.commit.author.*,' +
      '+values.step.run_number,' +
      '+values.step.state.*.*,' +
      '+values.step.trigger.*.*',
    sort: '-state.started_on',
    pagelen: 10,
    ...queryParams,
  };
  return `${envBaseUrl(
    '/!api/2.0',
    'deployments'
  )}/repositories/${repositoryFullSlug}/deployments/?${qs.stringify(
    allQueryParams,
    { allowDots: true, arrayFormat: 'repeat' }
  )}`;
};

export const getCommitDiffUrl = (
  repositoryFullSlug: string,
  revisionFrom: string,
  revisionTo: string,
  queryParams: any = {}
) => {
  const allQueryParams = {
    ...{ include: revisionTo },
    ...(revisionFrom && { exclude: revisionFrom }),
    pagelen: 30,
    ...queryParams,
  };

  return `/!api/2.0/repositories/${repositoryFullSlug}/commits/?${qs.stringify(
    allQueryParams
  )}`;
};

export const getJiraIssuesUrl = (
  repositoryFullSlug: string,
  revisionFrom: string,
  revisionTo: string,
  queryParams: any = {}
) => {
  const allQueryParams = {
    ...{ include: revisionTo },
    ...(revisionFrom && { exclude: revisionFrom }),
    pagelen: 30,
    ...queryParams,
  };

  return `${envBaseUrl(
    '/!api/internal'
  )}/repositories/${repositoryFullSlug}/jira-issues?${qs.stringify(
    allQueryParams
  )}`;
};

export const getFileDiffUrl = (
  repositoryFullSlug: string,
  revisionFrom: string,
  revisionTo: string
) => {
  return `/!api/2.0/repositories/${repositoryFullSlug}/diff/${revisionTo}..${revisionFrom}?topic=false`;
};

export const getPullRequestsUrl = (
  repositoryFullSlug: string,
  createdOn: string,
  queryParams: any = {}
) => {
  const allQueryParams = {
    q: `created_on <= ${createdOn}`,
    pagelen: 50,
    ...queryParams,
  };
  return `/!api/2.0/repositories/${repositoryFullSlug}/pullrequests?${qs.stringify(
    allQueryParams
  )}`;
};

export const getBranchRestrictionsUrl = (
  repositoryFullSlug: string,
  queryParams: any = {}
) => {
  const allQueryParams = {
    page: 1,
    pagelen: BRANCH_RESTRICTIONS_PAGE_SIZE,
    ...queryParams,
  };
  return `${envBaseUrl(
    '/!api/internal',
    'deployments'
  )}/repositories/${repositoryFullSlug}/branch-restrictions/?${qs.stringify(
    allQueryParams
  )}`;
};

type EnvironmentGroupings = {
  environment_summaries: Partial<Environment>[];
}[];

export const getEnvironmentUuids = (
  groupings?: EnvironmentGroupings
): (string | undefined)[] => {
  if (!groupings) return [];

  return groupings
    .map(e => e?.environment_summaries.map(s => s?.uuid))
    .flat()
    .filter((uuid: any) => typeof uuid === 'string');
};

export const getEnvironmentBranchRestrictionUrl = (
  repositoryFullSlug: string,
  environmentUuid: string,
  restrictionUuid = ''
) => {
  return `${envBaseUrl(
    '/!api/internal',
    'deployments'
  )}/repositories/${repositoryFullSlug}/environments/${environmentUuid}/branch-restrictions/${restrictionUuid}`;
};

export const getEnvironmentUrl = (
  repositorySlug: string,
  environmentUuid?: string
) => {
  return `${envBaseUrl(
    '/!api/2.0',
    'deployments'
  )}/repositories/${repositorySlug}/environments/${environmentUuid ?? ''}`;
};

export const getUpdateEnvironmentUrl = (
  repositorySlug: string,
  environmentUuid: string
) => {
  return `${envBaseUrl(
    '/!api/2.0',
    'deployments'
  )}/repositories/${repositorySlug}/environments/${environmentUuid}/changes/`;
};

export function* requestDeploymentChangesSaga(action: {
  meta: { revisionFrom: string; revisionTo: string; isRollback: boolean };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  const { revisionFrom, revisionTo, isRollback } = action.meta;
  try {
    const commitDiffUrl = getCommitDiffUrl(
      full_name,
      isRollback ? revisionTo : revisionFrom,
      isRollback ? revisionFrom : revisionTo
    );
    const jiraIssuesUrl = getJiraIssuesUrl(
      full_name,
      isRollback ? revisionTo : revisionFrom,
      isRollback ? revisionFrom : revisionTo
    );
    const fileDiffUrl = getFileDiffUrl(
      full_name,
      isRollback ? revisionTo : revisionFrom,
      isRollback ? revisionFrom : revisionTo
    );

    const [commitRes, jiraIssuesRes, fileDiffRes] = yield all([
      call(fetch, authRequest(commitDiffUrl)),
      call(fetch, authRequest(jiraIssuesUrl)),
      call(fetch, authRequest(fileDiffUrl, { responseType: 'text' } as any)),
    ]);
    if (!commitRes.ok) {
      throw new Error(yield createErrorMessage(commitRes));
    }
    if (!fileDiffRes.ok) {
      throw new Error(yield createErrorMessage(fileDiffRes));
    }
    const [commitDiff, jiraIssues, fileDiff] = yield all([
      call([commitRes, 'json']),
      jiraIssuesRes.ok ? call([jiraIssuesRes, 'json']) : { values: [] },
      call([fileDiffRes, 'text']),
    ]);
    yield put({
      type: REQUEST_DEPLOYMENT_CHANGES.SUCCESS,
      meta: action.meta,
      payload: {
        commitDiff: (commitDiff?.values || []).map(
          (data: any) => new Commit(data)
        ),
        jiraIssues: (jiraIssues?.values || []).map(
          (data: any) => new Issue(data)
        ),
        fileDiff: diffParser(fileDiff),
        isJiraConnected: jiraIssuesRes.ok,
      },
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_DEPLOYMENT_CHANGES.ERROR,
    });
    yield put({
      type: REQUEST_DEPLOYMENT_CHANGES.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestDeploymentCommitsSaga(action: {
  meta: {
    revisionFrom: string;
    revisionTo: string;
    isRollback: boolean;
    page: number;
  };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  const { revisionFrom, revisionTo, isRollback, page } = action.meta;
  try {
    const commitDiffUrl = getCommitDiffUrl(
      full_name,
      isRollback ? revisionTo : revisionFrom,
      isRollback ? revisionFrom : revisionTo,
      { page }
    );

    const res: Response = yield call(fetch, authRequest(commitDiffUrl));

    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);
    yield put({
      type: REQUEST_DEPLOYMENT_COMMITS.SUCCESS,
      meta: action.meta,
      payload: (data?.values || []).map((c: any) => new Commit(c)),
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_DEPLOYMENT_COMMITS.ERROR,
    });
    yield put({
      type: REQUEST_DEPLOYMENT_COMMITS.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestDeploymentDashboardSaga(action: {
  meta: { queryParams?: { includeHidden?: boolean; fields?: string } };
}): any {
  const { queryParams } = action?.meta ?? {};
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getDeploymentDashboardUrl(full_name, queryParams);
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);
    yield put({
      type: REQUEST_DEPLOYMENT_DASHBOARD.SUCCESS,
      payload: data,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_DEPLOYMENT_DASHBOARD.ERROR,
    });
    yield put({
      type: REQUEST_DEPLOYMENT_DASHBOARD.ERROR,
      payload: e,
    });
  }
}

export function transformPullRequestsData(
  data: any,
  commitDiff: Commit[] | undefined
): PullRequest[] {
  return (
    data?.values
      .map((pr: any) => new PullRequest(pr))
      .filter((pullRequest: PullRequest) => {
        return commitDiff?.some(
          commit =>
            (pullRequest.mergeCommit?.hash &&
              commit.hash.startsWith(pullRequest.mergeCommit.hash)) ||
            (pullRequest.sourceCommit?.hash &&
              commit.hash.startsWith(pullRequest.sourceCommit.hash))
        );
      }) || []
  );
}

export function* requestDeploymentPullRequestsSaga(
  action: Action<{ commitDiff: Commit[] }> & {
    meta: { isRollback: boolean; createdOn: string };
  }
): any {
  const { full_name } = yield select(getCurrentRepository);

  try {
    const url = getPullRequestsUrl(full_name, action.meta.createdOn);
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }

    const data = yield call([res, 'json']);

    const payload = transformPullRequestsData(data, action.payload?.commitDiff);

    yield put({
      type: REQUEST_DEPLOYMENT_PULL_REQUESTS.SUCCESS,
      payload,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_DEPLOYMENT_PULL_REQUESTS.ERROR,
    });
    yield put({
      type: REQUEST_DEPLOYMENT_PULL_REQUESTS.ERROR,
      payload: e,
    });
  }
}

export function* requestDeploymentSummarySaga(action: {
  meta: { deploymentUuid: string };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getDeploymentUrl(full_name, action.meta.deploymentUuid);
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);
    yield put({
      type: REQUEST_DEPLOYMENT_SUMMARY.SUCCESS,
      meta: action.meta,
      payload: data,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_DEPLOYMENT_SUMMARY.ERROR,
    });
    yield put({
      type: REQUEST_DEPLOYMENT_SUMMARY.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestRedeploymentSaga(action: {
  meta: {
    environmentUuid: string;
    deploymentUuid: string;
  };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const redeploymentUrl = getDeploymentUrl(
      full_name,
      action.meta.deploymentUuid
    );
    // combine with last deployment for given environment
    const latestDeploymentUrl = getDeploymentsUrl(full_name, {
      environment: action.meta.environmentUuid,
      fields: '+values.*.*.*.*.*.*',
      pagelen: 1,
      page: 1,
    });
    const [redeploymentRes, latestDeploymentRes] = yield all([
      call(fetch, authRequest(redeploymentUrl)),
      call(fetch, authRequest(latestDeploymentUrl)),
    ]);

    if (!redeploymentRes.ok) {
      throw new Error(yield createErrorMessage(redeploymentRes));
    }
    if (!latestDeploymentRes.ok) {
      throw new Error(yield createErrorMessage(latestDeploymentRes));
    }

    const [redeploymentData, latestDeploymentData] = yield all([
      call([redeploymentRes, 'json']),
      call([latestDeploymentRes, 'json']),
    ]);
    yield put({
      type: REQUEST_REDEPLOYMENT.SUCCESS,
      meta: action.meta,
      payload: {
        deployment: new Deployment(redeploymentData),
        lastSuccessfulDeployment: new Deployment(
          latestDeploymentData.values?.[0]
        ),
        environment: new Environment(redeploymentData.environment),
      },
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_REDEPLOYMENT.ERROR,
    });
    yield put({
      type: REQUEST_REDEPLOYMENT.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestEnvironmentHistorySaga(action: {
  meta: { environmentUuid: string; page: number };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  try {
    const url = getDeploymentsUrl(full_name, {
      environment: action.meta.environmentUuid,
      page: action.meta.page,
    });
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);
    yield put({
      type: REQUEST_ENVIRONMENT_HISTORY.SUCCESS,
      meta: action.meta,
      payload: data,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_ENVIRONMENT_HISTORY.ERROR,
    });
    yield put({
      type: REQUEST_ENVIRONMENT_HISTORY.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestBranchRestrictionsSaga(
  action: Action<{
    groupings: {
      environment_summaries: Partial<Environment>[];
    }[];
  }>
): any {
  const { full_name } = yield select(getCurrentRepository);
  const environmentUuids = getEnvironmentUuids(action.payload?.groupings);

  try {
    const url = getBranchRestrictionsUrl(full_name);
    const res: Response = yield call(
      fetch,
      authRequest(url, {
        method: 'POST',
        headers: jsonHeaders,
        body: JSON.stringify({ environmentUuids }),
      })
    );
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);
    yield put({
      type: REQUEST_BRANCH_RESTRICTIONS.SUCCESS,
      payload: data,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_BRANCH_RESTRICTIONS.ERROR,
    });
    yield put({
      type: REQUEST_BRANCH_RESTRICTIONS.ERROR,
      payload: e,
    });
  }
}

export function* requestCreateBranchRestrictionSaga(
  action: Action<{ environmentUuid: string; pattern: string }>
): any {
  const { full_name: repositorySlug } = yield select(getCurrentRepository);
  const { environmentUuid, pattern } = action.payload!;
  const url = getEnvironmentBranchRestrictionUrl(
    repositorySlug,
    environmentUuid
  );
  try {
    const res: Response = yield call(
      fetch,
      authRequest(url, {
        method: 'POST',
        headers: jsonHeaders,
        body: JSON.stringify({ pattern, type: 'branch_restriction_pattern' }),
      })
    );
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);
    yield put({
      type: REQUEST_CREATE_BRANCH_RESTRICTION.SUCCESS,
      payload: { environmentUuid, data },
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_CREATE_BRANCH_RESTRICTION.ERROR,
    });
    yield put({
      type: REQUEST_CREATE_BRANCH_RESTRICTION.ERROR,
      payload: {
        environmentUuid,
        error: e,
      },
    });
  }
}

export function* requestDeleteBranchRestrictionSaga(
  action: Action<{ environmentUuid: string; restrictionUuid: string }>
): any {
  const { full_name: repositorySlug } = yield select(getCurrentRepository);
  const { environmentUuid, restrictionUuid } = action.payload!;
  const url = getEnvironmentBranchRestrictionUrl(
    repositorySlug,
    environmentUuid,
    restrictionUuid
  );
  try {
    const res: Response = yield call(
      fetch,
      authRequest(url, { method: 'DELETE' })
    );
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    yield put({
      type: REQUEST_DELETE_BRANCH_RESTRICTION.SUCCESS,
      payload: { environmentUuid, restrictionUuid },
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_DELETE_BRANCH_RESTRICTION.ERROR,
    });
    yield put({
      type: REQUEST_DELETE_BRANCH_RESTRICTION.ERROR,
      payload: { environmentUuid, error: e },
    });
  }
}

export function* requestDeploymentChangesWithRaceSaga(action: any) {
  yield race([
    call(requestDeploymentChangesSaga, action),
    take(REMOVE_DEPLOYMENT_DATA),
  ]);
}

export function* requestDeploymentCommitsWithRaceSaga(action: any) {
  yield race([
    call(requestDeploymentCommitsSaga, action),
    take(REMOVE_DEPLOYMENT_DATA),
  ]);
}

export function* requestDeploymentPullRequestsWithRaceSaga(action: any) {
  yield race([
    call(requestDeploymentPullRequestsSaga, action),
    take(REMOVE_DEPLOYMENT_DATA),
  ]);
}

export function* requestRedeploymentWithRaceSaga(action: any) {
  yield race([
    call(requestRedeploymentSaga, action),
    take(REMOVE_DEPLOYMENT_DATA),
  ]);
}

export function* requestDeploymentSummaryWithRaceSaga(action: any) {
  yield race([
    call(requestDeploymentSummarySaga, action),
    take(REMOVE_DEPLOYMENT_DATA),
  ]);
}

export function* requestCreateEnvironmentSaga(
  action: Action<{ environmentName: string; environmentType: EnvironmentType }>
): any {
  if (!action.payload) {
    return;
  }

  const { full_name: repositorySlug } = yield select(getCurrentRepository);
  const { environmentName, environmentType } = action.payload;

  try {
    const url = getEnvironmentUrl(repositorySlug);
    const body = {
      environment_type: { name: environmentType },
      name: environmentName,
      rank: 0,
    };
    const res: Response = yield call(
      fetch,
      authRequest(url, {
        method: 'POST',
        headers: jsonHeaders,
        body: JSON.stringify(body),
      })
    );
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const environment: Partial<Environment> = yield call([res, 'json']);
    yield put({
      type: REQUEST_CREATE_ENVIRONMENT.SUCCESS,
      payload: { environment, environmentName, environmentType },
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_CREATE_ENVIRONMENT.ERROR,
    });
    yield put({
      type: REQUEST_CREATE_ENVIRONMENT.ERROR,
      payload: { error: e, environmentName, environmentType },
    });
  }
}

export function* requestUpdateEnvironmentSaga(
  action: Action<{ environmentUuid: string; change: Partial<Environment> }>
): any {
  if (!action.payload) {
    return;
  }

  const { full_name: repositorySlug } = yield select(getCurrentRepository);
  const { environmentUuid, change } = action.payload;

  try {
    // Note: branch restrictions are not included in the environment responses.
    const { branchRestrictions } = (yield select(getEnvironmentsByUuid))[
      environmentUuid
    ];

    const url = getUpdateEnvironmentUrl(repositorySlug, environmentUuid);
    const body = { change };
    const res: Response = yield call(
      fetch,
      authRequest(url, {
        method: 'POST',
        headers: jsonHeaders,
        body: JSON.stringify(body),
      })
    );
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }

    // Unfortunately the request above does not return the updated object.
    // So has to GET the updated environment object separately.
    const getUrl = getEnvironmentUrl(repositorySlug, environmentUuid);
    const getRes: Response = yield call(fetch, authRequest(getUrl));
    if (!getRes.ok) {
      throw new Error(yield createErrorMessage(getRes));
    }
    const data = yield call([getRes, 'json']);

    yield put({
      type: REQUEST_UPDATE_ENVIRONMENT.SUCCESS,
      payload: {
        environmentUuid,
        change,
        data: { ...data, branchRestrictions },
      },
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_UPDATE_ENVIRONMENT.ERROR,
    });
    yield put({
      type: REQUEST_UPDATE_ENVIRONMENT.ERROR,
      payload: { error: e, environmentUuid },
    });
  }
}

export function* requestDeleteEnvironmentSaga(
  action: Action<{ environmentUuid: string }>
): any {
  if (!action.payload) {
    return;
  }

  const { full_name: repositorySlug } = yield select(getCurrentRepository);
  const { environmentUuid } = action.payload;

  try {
    const url = getEnvironmentUrl(repositorySlug, environmentUuid);
    const res: Response = yield call(
      fetch,
      authRequest(url, { method: 'DELETE' })
    );
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    yield put({
      type: REQUEST_DELETE_ENVIRONMENT.SUCCESS,
      payload: { environmentUuid },
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_DELETE_ENVIRONMENT.ERROR,
    });
    yield put({
      type: REQUEST_DELETE_ENVIRONMENT.ERROR,
      payload: { error: e, environmentUuid },
    });
  }
}

export function* requestReorderEnvironmentsSaga(
  action: Action<{
    environmentType: string;
    environmentTypeOrder: { uuid: string }[];
  }>
): any {
  if (!action.payload) {
    return;
  }

  const { full_name: repositorySlug } = yield select(getCurrentRepository);
  const { environmentType, environmentTypeOrder } = action.payload;

  try {
    const url = getDeploymentDashboardUrl(repositorySlug, {});
    const dashboard = yield select(getDeploymentDashboardWithTypeNames);
    const version = yield select(getDeploymentDashboardVersion);
    const body = {
      groupings: ['Test', 'Staging', 'Production'].map(type => ({
        environment_summaries:
          type === environmentType
            ? environmentTypeOrder
            : dashboard[type].map((env: Environment) => ({ uuid: env.uuid })),
        environment_type: { name: type },
      })),
      version,
    };
    const res: Response = yield call(
      fetch,
      authRequest(url, {
        method: 'PUT',
        headers: jsonHeaders,
        body: JSON.stringify(body),
      })
    );
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    yield put({
      type: REQUEST_REORDER_ENVIRONMENTS.SUCCESS,
      payload: { environmentType },
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_REORDER_ENVIRONMENTS.ERROR,
    });
    yield put({
      type: REQUEST_REORDER_ENVIRONMENTS.ERROR,
      payload: { error: e },
    });
  }
}
