import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  pageTitle: {
    id: 'frontbucket.pipelines.components.testManagement.pageTitle',
    description: 'The title for the Test management page',
    defaultMessage: 'Tests management',
  },
  test: {
    id: 'frontbucket.pipelines.components.testManagement.test',
    description: 'The test management table head column name for test',
    defaultMessage: 'Test',
  },
  labels: {
    id: 'frontbucket.pipelines.components.testManagement.labels',
    description: 'The test management table head column name for Labels',
    defaultMessage: 'Labels',
  },
  failureRate: {
    id: 'frontbucket.pipelines.components.testManagement.failureRate',
    description: 'The test management table head column name for Failure Rate',
    defaultMessage: 'Failure Rate',
  },
  averageDuration: {
    id: 'frontbucket.pipelines.components.testManagement.averageDuration',
    description:
      'The test management table head column name for Average Duration',
    defaultMessage: 'Average Duration',
  },
  variance: {
    id: 'frontbucket.pipelines.components.testManagement.variance',
    description: 'The test management table head column name for Variance',
    defaultMessage: 'Variance',
  },
  labelFilterPlaceholder: {
    id: 'frontbucket.pipelines.components.testManagement.labelFilterPlaceholder',
    description: 'The test management table label filter placeholder',
    defaultMessage: 'Label',
  },
  testFilterPlaceholder: {
    id: 'frontbucket.pipelines.components.testManagement.testFilterPlaceholder',
    description: 'The test management table name filter placeholder',
    defaultMessage: 'Test name',
  },
  fullyQualifiedNameLabel: {
    id: 'frontbucket.pipelines.components.testManagement.fullyQualifiedNameLabel',
    description: 'The test management table fully qualified name label',
    defaultMessage: 'Fully qualified name',
  },
  classNameLabel: {
    id: 'frontbucket.pipelines.components.testManagement.classNameLabel',
    description: 'The test management table class name label',
    defaultMessage: 'Class name',
  },
  p90OfDuration: {
    id: 'frontbucket.pipelines.components.testManagement.p90OfDuration',
    description: 'The test management table p90 of duration label',
    defaultMessage: 'P90 of the duration',
  },
  p10OfDuration: {
    id: 'frontbucket.pipelines.components.testManagement.p10OfDuration',
    description: 'The test management table p10 of duration label',
    defaultMessage: 'P10 of the duration',
  },
});
