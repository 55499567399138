import {
  ProfileClient as AkProfileClient,
  TeamProfileClient,
} from '@atlaskit/profilecard';
import { ClientOverrides } from '@atlaskit/profilecard/types';

import UserClient from './user-client';

type BBProfileClientConfig = {
  cacheMaxAge?: number;
  cacheSize?: number;
  fetchUser: (userId: string) => Promise<BB.UserType>;
  onError?: (e: Error) => void;
};

export default class ProfileClient extends AkProfileClient {
  constructor(config: BBProfileClientConfig, clients?: ClientOverrides) {
    const extendedConfig = { ...config, url: '' };
    const { fetchUser, onError, ...profileConfig } = extendedConfig;
    super({ ...profileConfig });

    // Setting userClient to our UserClient, so the consumer would not have to use it
    this.userClient = new UserClient(extendedConfig);

    // Following AK's API for teamClient as we have not implemented this yet
    this.teamClient =
      clients?.teamClient || new TeamProfileClient(profileConfig);
  }
}
