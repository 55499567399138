import React from 'react';

import Icon, { IconProps } from '@atlaskit/icon';

import { BitkitIconTypes } from '../types';

type Glyph = Required<IconProps>['glyph'];

const glyph = (props: ArgumentsOf<Glyph>[0]) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M11 5.73607L6.14177 8.16518L6.64261 12.6727C6.7995 14.0848 7.54972 15.3634 8.70584 16.1892L11 17.8279V5.73607ZM13 5.73607V17.8279L15.2942 16.1892C16.4503 15.3634 17.2005 14.0848 17.3574 12.6727L17.8582 8.16518L13 5.73607ZM4 7L12 3L20 7L19.3452 12.8936C19.1255 14.8705 18.0752 16.6606 16.4566 17.8167L12.5812 20.5848C12.2335 20.8332 11.7665 20.8332 11.4188 20.5848L7.54336 17.8167C5.9248 16.6606 4.8745 14.8705 4.65484 12.8936L4 7Z"
      fill="currentColor"
    />
  </svg>
);

export const ShieldIcon = (props: BitkitIconTypes): JSX.Element => (
  <Icon {...props} glyph={glyph} />
);
