import { Deployment } from 'src/components/pipelines/models';
import { LoadingStatus } from 'src/constants/loading-status';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import { REQUEST_ENVIRONMENT_HISTORY } from '../actions/deployments';

export type EnvironmentHistoryState = {
  map: { [key: string]: Deployment[] };
  fetchedStatus: { [key: string]: LoadingStatus };
};

export const initialState: EnvironmentHistoryState = {
  map: {},
  fetchedStatus: {},
};

export default createReducer(initialState, {
  [REQUEST_ENVIRONMENT_HISTORY.REQUEST](
    state: EnvironmentHistoryState,
    action: Action & { meta: { environmentUuid: string } }
  ) {
    const { environmentUuid } = action.meta;
    return {
      map: {
        ...state.map,
        [environmentUuid]: state.map[environmentUuid] || [],
      },
      fetchedStatus: {
        ...state.fetchedStatus,
        [environmentUuid]: LoadingStatus.Fetching,
      },
    };
  },
  [REQUEST_ENVIRONMENT_HISTORY.SUCCESS](
    state: EnvironmentHistoryState,
    action: Action<{ values: Partial<Deployment>[] }> & {
      meta: { environmentUuid: string };
    }
  ) {
    const { environmentUuid } = action.meta;
    const existingHistory = state.map[environmentUuid] || [];
    const newHistory =
      action.payload?.values
        .filter(
          newDeployment =>
            !existingHistory.some(
              existingDeployment =>
                existingDeployment.uuid === newDeployment.uuid
            )
        )
        .map(d => new Deployment(d))
        .filter(
          newDeployment => newDeployment.state?.parsedStatus !== 'UNDEPLOYED'
        ) || [];

    return {
      map: {
        ...state.map,
        [environmentUuid]: existingHistory.concat(newHistory),
      },
      fetchedStatus: {
        ...state.fetchedStatus,
        [environmentUuid]: LoadingStatus.Success,
      },
    };
  },
  [REQUEST_ENVIRONMENT_HISTORY.ERROR](
    state: EnvironmentHistoryState,
    action: any
  ) {
    const { environmentUuid } = action.meta;
    return {
      ...state,
      fetchedStatus: {
        ...state.fetchedStatus,
        [environmentUuid]: LoadingStatus.Failed,
      },
    };
  },
});
