import React, { useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import { Account, Pipeline, Repository } from 'src/components/pipelines/models';

import {
  ALLOWED_PLAN_URL,
  MEMORY_LIMIT_URL,
  PARALLEL_STEP_SIZE_LIMIT_URL,
  STEP_SIZE_LIMIT_URL,
  TIME_LIMIT_EXCEEDED_STEP_URL,
  YML_FILE_NAME,
} from '../../constants';
import { WarningMessageLinks, WarningMessageWrapper } from '../styled';
import WarningCard from '../WarningCard';

import WarningLinks from './WarningLinks';
import messages from './WarningMessage.i18n';

export interface Props {
  account: Account;
  repository: Repository;
  error: {
    key: string;
    message?: string;
    arguments?: { element: string; parent_element: string };
  };
  pipeline: Pipeline;
}

/* eslint @typescript-eslint/ban-types: "warn" */
const WarningMessage: React.FC<Props> = ({
  account,
  repository,
  error,
  pipeline,
}) => {
  const formattedMessage = useMemo(
    () => [
      {
        key: '',
        text: ({ errorMessage }: any) => errorMessage,
      },
      {
        key: 'plan-service.parse.missing-section',
        text: ({ parentElement, element }: any) => (
          <>
            <FormattedMessage
              {...messages.missingSection}
              values={{
                ymlFileName: <strong>{YML_FILE_NAME}</strong>,
                element,
                parentElement,
              }}
            />
          </>
        ),
      },
      {
        key: 'plan-service.parse.missing-string-specific',
        text: ({ parentElement, element }: any) => (
          <>
            <FormattedMessage
              {...messages.missingString}
              values={{
                ymlFileName: <strong>{YML_FILE_NAME}</strong>,
                element,
                parentElement,
              }}
            />
          </>
        ),
      },
      {
        key: 'plan-service.parse.invalid-list',
        text: ({ element }: any) => (
          <>
            <FormattedMessage
              {...messages.invalidList}
              values={{
                ymlFileName: <strong>{YML_FILE_NAME}</strong>,
                element,
              }}
            />
            <br />
            <FormattedMessage
              {...messages.checkDocumentationText}
              values={{
                element,
              }}
            />
          </>
        ),
      },
      {
        key: 'plan-service.parse.invalid-map',
        text: ({ element }: any) => (
          <>
            <FormattedMessage
              {...messages.invalidMap}
              values={{
                ymlFileName: <strong>{YML_FILE_NAME}</strong>,
                element,
              }}
            />
            <br />
            <FormattedMessage
              {...messages.checkDocumentationText}
              values={{
                element,
              }}
            />
          </>
        ),
      },
      {
        key: 'plan-service.parse.invalid-image-name',
        text: ({ element }: any) => (
          <>
            <FormattedMessage
              {...messages.invalidImageName}
              values={{
                ymlFileName: <strong>{YML_FILE_NAME}</strong>,
                element,
              }}
            />
          </>
        ),
      },
      {
        key: 'plan-service.parse.parse-error',
        text: () => (
          <>
            <FormattedMessage
              {...messages.parseError}
              values={{
                ymlFileName: <strong>{YML_FILE_NAME}</strong>,
              }}
            />
          </>
        ),
      },
      {
        key: 'plan-service.parse.parse-indentation-error',
        text: () => (
          <>
            <FormattedMessage
              {...messages.parseIndentationError}
              values={{
                ymlFileName: <strong>{YML_FILE_NAME}</strong>,
              }}
            />
          </>
        ),
      },
      {
        key: 'agent.build.time-limit-exceeded',
        text: () => (
          <>
            <FormattedMessage {...messages.timeLimitExceeded} />
          </>
        ),
      },
      {
        key: 'result-service.pipeline.too-many-steps',
        text: () => (
          <>
            <FormattedMessage
              {...messages.tooManySteps}
              values={{
                stepSizeLimitLink: (
                  <a href={STEP_SIZE_LIMIT_URL} target="_blank">
                    <FormattedMessage {...messages.stepSizeLimit} />
                  </a>
                ),
              }}
            />
          </>
        ),
      },
      {
        key: 'agent-service.account-concurrency.reached',
        text: () => (
          <>
            <FormattedMessage
              {...messages.improvedErrorMappingEnabledText}
              values={{
                linkOne: (
                  <a href={ALLOWED_PLAN_URL} target="_blank">
                    <FormattedMessage {...messages.ImprovedErrorLinkOneText} />
                  </a>
                ),
                linkTwo: (
                  <a href={PARALLEL_STEP_SIZE_LIMIT_URL} target="_blank">
                    <FormattedMessage {...messages.ImprovedErrorLinkTwoText} />
                  </a>
                ),
              }}
            />
          </>
        ),
      },
      {
        key: 'pipeline-error',
        text: () => (
          <>
            <FormattedMessage {...messages.pipelineErrorText} />
          </>
        ),
      },
      {
        key: 'agent.step.time-limit-exceeded',
        text: ({ errorMessage }: any) => (
          <>
            {errorMessage}{' '}
            <a href={TIME_LIMIT_EXCEEDED_STEP_URL} target="_blank">
              <FormattedMessage {...messages.troubleShootingSteps} />
            </a>
          </>
        ),
      },
      {
        key: 'agent.step.memory-limit-exceeded',
        text: ({ errorMessage }: any) =>
          errorMessage.includes('memory limit.') ? (
            <>
              {errorMessage.replace('memory limit.', '')}
              <a href={MEMORY_LIMIT_URL} target="_blank">
                <FormattedMessage {...messages.memoryLimit} />
              </a>
              .
            </>
          ) : (
            errorMessage
          ),
      },
    ],
    []
  );

  const warningMessage = useMemo(() => {
    return (
      formattedMessage.filter(l => l.key === error.key)[0] ||
      formattedMessage[0]
    );
  }, [error, formattedMessage]);

  const { element, parent_element: parentElement } =
    error.arguments || ({} as any);

  return (
    <WarningCard title={pipeline.statusText} color={pipeline.statusColor}>
      <WarningMessageWrapper>
        {warningMessage.text({
          errorMessage: error?.message,
          element,
          parentElement,
        })}
      </WarningMessageWrapper>
      <WarningMessageLinks>
        <WarningLinks
          account={account}
          repository={repository}
          errorKey={error?.key}
          revision={pipeline.revision}
        />
      </WarningMessageLinks>
    </WarningCard>
  );
};

export default React.memo(WarningMessage);
