import React, { useCallback } from 'react';

import { usePipelineRunHistoryContext } from 'src/components/pipelines/contexts/pipeline-run-history-context';
import {
  Account,
  Checks,
  Deployment,
  Pipeline,
  Repository,
  Step,
} from 'src/components/pipelines/models';

import {
  MetricUsageStatusType,
  METRICS_USAGE_ERROR_LIMIT,
} from '../../constants';
import { PipelineWarningWrapper } from '../styled';

import HaltedMessage from './HaltedMessage';
import { MetricsUsageMessage } from './MetricsUsageMessage';
import { PreviousRunMessage } from './PreviousRunMessage';
import WarningMessage from './WarningMessage';

export interface Props {
  account: Account;
  repository: Repository;
  pipeline: Pipeline;
  step: Step;
  steps: Step[];
  checks: Checks;
  deployments: { [key: string]: Deployment };
  maxMemoryPercentage: any;
  isFetchingMetrics: boolean;
  metricWarningLimit: number;
  stepMetricsEnabled: boolean;
}

/* eslint @typescript-eslint/ban-types: "warn" */
export const PipelineWarning: React.FC<Props> = React.memo(
  ({
    account,
    repository,
    pipeline,
    step,
    steps,
    checks,
    deployments,
    maxMemoryPercentage,
    isFetchingMetrics,
    metricWarningLimit,
    stepMetricsEnabled,
  }) => {
    const { isHistoryRunSelected } = usePipelineRunHistoryContext();

    const getMemoryUsageWarning = useCallback(
      (maxMemory: any, isFetching: boolean) => {
        const warningContainers = [] as any;
        let usageErrorKey = MetricUsageStatusType.Unknown;

        if (!isFetching && Object.keys(maxMemory).length > 0) {
          Object.keys(maxMemory).forEach(element => {
            if (
              maxMemory[element] >= METRICS_USAGE_ERROR_LIMIT &&
              step.hasError
            ) {
              usageErrorKey = MetricUsageStatusType.Error;
              warningContainers.push(element);
            } else if (maxMemory[element] >= metricWarningLimit) {
              warningContainers.push(element);
              if (usageErrorKey === MetricUsageStatusType.Unknown) {
                usageErrorKey = MetricUsageStatusType.Warning;
              }
            }
          });
        }

        return { usageErrorKey, warningContainers };
      },
      [step, metricWarningLimit]
    );

    const error = pipeline.error || step.error;
    const errorKey = error && error.key;

    const { usageErrorKey, warningContainers } = getMemoryUsageWarning(
      maxMemoryPercentage,
      isFetchingMetrics
    );

    const isPipelineDefined = !!pipeline.uuid;
    const hasError = !!errorKey;
    const hasMetricsWarning =
      stepMetricsEnabled && usageErrorKey !== MetricUsageStatusType.Unknown;
    const hasWarning =
      isPipelineDefined && (hasError || pipeline.isHalted || hasMetricsWarning);

    const renderWarningMessage = () => {
      if (hasMetricsWarning) {
        return (
          <MetricsUsageMessage
            status={usageErrorKey}
            stepSize={step.stepSize}
            warningContainers={warningContainers}
          />
        );
      }

      if (pipeline.isHalted) {
        return (
          <HaltedMessage
            account={account}
            repository={repository}
            pipeline={pipeline}
            steps={steps}
            checks={checks}
            deployments={deployments}
          />
        );
      }

      return (
        <WarningMessage
          account={account}
          repository={repository}
          pipeline={pipeline}
          error={error}
        />
      );
    };

    if (!hasWarning && !isHistoryRunSelected) {
      return null;
    }

    return (
      <PipelineWarningWrapper data-testid="pipeline-warning-wrapper">
        {isHistoryRunSelected && <PreviousRunMessage />}
        {hasWarning && renderWarningMessage()}
      </PipelineWarningWrapper>
    );
  }
);
