export class OfficialTemplate {
  readonly name: string = '';
  readonly description: string = '';
  readonly logo: string = '';
  readonly yml: string = '';
  readonly id: number = 0;
  readonly weight: number = 1;
  readonly language: string = '';

  constructor(props: Partial<OfficialTemplate> = {}) {
    Object.assign(this, props);
    Object.freeze(this);
  }
}
