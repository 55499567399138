/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { gridSize, colors, typography } from '@atlaskit/theme';

const groupHeaderFontSize = gridSize() * 3;
const groupHeaderLineHeight = gridSize() * 3.5;

export const ReadViewContainer = styled.div`
  letter-spacing: normal;
  line-height: ${groupHeaderLineHeight}px;
  padding: ${gridSize() - 2}px ${gridSize() - 2}px;
  word-break: break-word;

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

export const EditViewContainer = styled.div<{
  scrollHeight: number;
}>`
  & textarea {
    font-weight: 500;
    font-size: ${groupHeaderFontSize}px;
    line-height: ${groupHeaderLineHeight}px;
    height: ${props =>
      props.scrollHeight ||
      groupHeaderLineHeight}px !important; /* Override the the inline height rule that AtlasKit injects */
    word-break: break-word;
  }
`;

export const ButtonGroupContainer = styled.div`
  margin-top: ${gridSize()}px;
`;

export const WorkspaceInfoSection = styled.div`
  box-shadow: 0 3px 5px ${colors.N50A}, 0 0 1px ${colors.N60A};
  border-radius: ${gridSize() / 2}px;
  padding: ${gridSize() * 2.5}px;
  margin-bottom: ${gridSize() * 5}px;
  display: flex;
  justify-content: space-between;
`;

export const EditGroupButtonContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const WorkspaceInfoHeader = styled.h3`
  ${typography.h400 as any}
`;

export const DropdownItemContent = styled.div`
  min-width: ${gridSize() * 20}px;
`;
