import { createResource } from 'react-resource-router';

import { GetCompassCloudIdsData, compassApi } from './compass-requests';

export const compassResource = createResource<
  GetCompassCloudIdsData | undefined
>({
  type: 'compassResource',
  getKey: ({ match }) => {
    return `compassResource.${match.params.workspaceSlug}`;
  },
  maxAge: 60000,
  getData: ({ match }) => {
    const { workspaceSlug } = match.params;
    if (!workspaceSlug) {
      throw new Error(
        'Could not fetch compass cloudIds, URL params were not available'
      );
    }
    const api = compassApi();

    return api.getCloudIdsForWorkspace(workspaceSlug);
  },
});
