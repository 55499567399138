export type LocalizationMessages = {
  [localizationKey: string]: string;
};

/**
 * Fetches default messages imported from a locale file
 * @param intl - the Intl object imported from a locale file
 * @returns An object containing the default messages
 */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getDefaultMessages = (intl: any): { [key: string]: any } => {
  return intl.default && typeof intl.default !== 'string' ? intl.default : intl;
};

/**
 * Fetches platform translations for the provided locale
 * @param locale - string representing the locale to fetch translations for
 * @returns An array of promises representing the translations for the provided locale
 */

export const getPlatformMessages = (
  locale: string
): Promise<LocalizationMessages>[] => {
  switch (locale) {
    case 'cs':
      return [
        import(
          /* webpackChunkName: "translations.cs" */ '@atlassian/generative-ai-modal/dist/es2019/i18n/cs.js'
        ),
      ];
    case 'de':
      return [
        import(
          /* webpackChunkName: "translations.de" */ '@atlassian/generative-ai-modal/dist/es2019/i18n/de.js'
        ),
      ];
    case 'es':
      return [
        import(
          /* webpackChunkName: "translations.es" */ '@atlassian/generative-ai-modal/dist/es2019/i18n/es.js'
        ),
      ];
    case 'fr':
      return [
        import(
          /* webpackChunkName: "translations.fr" */ '@atlassian/generative-ai-modal/dist/es2019/i18n/fr.js'
        ),
      ];
    case 'it':
      return [
        import(
          /* webpackChunkName: "translations.it" */ '@atlassian/generative-ai-modal/dist/es2019/i18n/it.js'
        ),
      ];
    case 'ja':
      return [
        import(
          /* webpackChunkName: "translations.ja" */ '@atlassian/generative-ai-modal/dist/es2019/i18n/ja.js'
        ),
      ];
    case 'ko':
      return [
        import(
          /* webpackChunkName: "translations.ko" */ '@atlassian/generative-ai-modal/dist/es2019/i18n/ko.js'
        ),
      ];
    case 'nl':
      return [
        import(
          /* webpackChunkName: "translations.nl" */ '@atlassian/generative-ai-modal/dist/es2019/i18n/nl.js'
        ),
      ];
    case 'pl':
      return [
        import(
          /* webpackChunkName: "translations.pl" */ '@atlassian/generative-ai-modal/dist/es2019/i18n/pl.js'
        ),
      ];
    case 'pt-BR':
      return [
        import(
          /* webpackChunkName: "translations.pt-BR" */ '@atlassian/generative-ai-modal/dist/es2019/i18n/pt_BR.js'
        ),
      ];
    case 'ru':
      return [
        import(
          /* webpackChunkName: "translations.ru" */ '@atlassian/generative-ai-modal/dist/es2019/i18n/ru.js'
        ),
      ];
    case 'sv':
      return [
        import(
          /* webpackChunkName: "translations.sv" */ '@atlassian/generative-ai-modal/dist/es2019/i18n/sv.js'
        ),
      ];
    case 'tr':
      return [
        import(
          /* webpackChunkName: "translations.tr" */ '@atlassian/generative-ai-modal/dist/es2019/i18n/tr.js'
        ),
      ];
    case 'uk':
      return [
        import(
          /* webpackChunkName: "translations.uk" */ '@atlassian/generative-ai-modal/dist/es2019/i18n/uk.js'
        ),
      ];
    case 'vi':
      return [
        import(
          /* webpackChunkName: "translations.vi" */ '@atlassian/generative-ai-modal/dist/es2019/i18n/vi.js'
        ),
      ];
    case 'zh-CN':
      return [
        import(
          /* webpackChunkName: "translations.zh-CN" */ '@atlassian/generative-ai-modal/dist/es2019/i18n/zh.js'
        ),
      ];
    case 'en':
    default:
      return [
        import(
          /* webpackChunkName: "translations.en" */ '@atlassian/generative-ai-modal/dist/es2019/i18n/en.js'
        ),
      ];
  }
};
