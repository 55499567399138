import {
  generateRefUrl,
  QueryOptions,
} from 'src/sections/repository/components/ref-selector/utils';
import { RefType } from 'src/sections/repository/types';

/**
 * Build urls that will be used by fetchRefs
 * If there is no search filter,
 * return one request url to fetch main branch + last modified branches
 * If there is a search filter,
 * return two request urls, one to fetch exact matches + one to fetch similar maches
 * @param {string} slug - Repo slug to search branches for
 * @param {string} type - Type of refs to search for ('branch', 'tag', or 'all')
 * @param {string} filter - Optional search filter
 * @param {string} customQuery - Optional BBQL query that prepends the base query param
 * @returns {string[]} - Array of urls to fetch branches for
 */
export const buildRefUrls = (
  slug: string,
  type: RefType | 'all',
  filter: string,
  customQuery = ''
): string[] => {
  const requestUrls: string[] = [];
  if (filter === '') {
    // If there is no search filter, fetch the main branch + last modified branches
    requestUrls.push(
      generateRefUrl(slug, '', type, undefined, undefined, '-target.date', {
        type: 'custom',
        customQuery,
      })
    );
  } else {
    const customQueryWithAnd =
      customQuery.length > 0 ? customQuery.concat(' AND ') : '';
    // When a search filter is present, fetch exact matches first, then similar matches
    const customQueries: QueryOptions[] = [
      {
        type: 'custom',
        customQuery: `${customQueryWithAnd}name = "${filter}"`,
      },
      // Only return similar matches that aren't exact
      {
        type: 'custom',
        customQuery: `${customQueryWithAnd}name != "${filter}" AND name ~ "${filter}"`,
      },
    ];

    customQueries.forEach(query => {
      requestUrls.push(
        generateRefUrl(
          slug,
          filter,
          type,
          undefined,
          undefined,
          '-target.date',
          query
        )
      );
    });
  }

  return requestUrls;
};
