import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { jsx } from '@emotion/react';
import { easeOut, SlideIn } from '@atlaskit/motion';
/**
 * @internal
 */
export var NestingMotion = function NestingMotion(props) {
  var children = props.children,
    enterFrom = props.enterFrom,
    exitTo = props.exitTo,
    testId = props.testId;
  return jsx(SlideIn, {
    exitTo: exitTo,
    enterFrom: enterFrom,
    animationTimingFunction: function animationTimingFunction(_) {
      return easeOut;
    }
  }, function (innerProps, direction) {
    return children(_objectSpread({
      'data-enter-from': enterFrom,
      'data-exit-to': exitTo,
      'data-testid': testId && "".concat(testId, "-").concat(direction)
    }, innerProps));
  });
};