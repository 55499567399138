import React, { useMemo, useRef } from 'react';

import { useResource } from 'react-resource-router';

import { projectNavigationResource } from 'src/sections/project/project-navigation-resource';
import { repositoryNavigationResource } from 'src/sections/repository/repository-navigation-resource';
import { workspaceNavigationResource } from 'src/sections/workspace/workspace-navigation-resource';

export type CurrentData = {
  workspace?: BB.Workspace;
  project?: BB.Project;
  repository?: BB.Repository;
};

export const CurrentDataContext = React.createContext<CurrentData>({
  workspace: undefined,
  project: undefined,
  repository: undefined,
});

type Props = React.PropsWithChildren<{
  initialContext: CurrentData;
}>;

export const CurrentDataProvider = (props: Props): JSX.Element => {
  // We get the initial context from the server via app data. As soon as we
  // get any content from a resource we should ignore the initial values so
  // we don't pass stale data.
  const hasUpdatedRef = useRef(false);
  const initialValue = props.initialContext;

  const { data: workspaceData } = useResource(workspaceNavigationResource);
  const { data: projectData } = useResource(projectNavigationResource);
  const { data: repoData } = useResource(repositoryNavigationResource);

  const workspace =
    workspaceData?.workspace ||
    projectData?.project.workspace ||
    repoData?.repository.workspace;
  const project = projectData?.project || repoData?.repository.project;
  const repository = repoData?.repository;

  const resourceValue = useMemo(() => {
    return {
      workspace,
      project,
      repository,
    };
  }, [workspace, project, repository]);

  if (workspaceData || projectData || repoData) {
    hasUpdatedRef.current = true;
  }

  const value = hasUpdatedRef.current ? resourceValue : initialValue;

  return (
    <CurrentDataContext.Provider value={value}>
      {props.children}
    </CurrentDataContext.Provider>
  );
};
