/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import * as Sentry from '@sentry/browser';
import { call, select, put } from 'redux-saga/effects';

import { CommentLikes } from 'src/components/conversation-provider/types';
import { commentLikes as commentLikesSchema } from 'src/redux/pull-request/schemas';
import urls from 'src/redux/pull-request/urls';
import {
  toggledCommentLike,
  publishCommentLikesUiEvent,
} from 'src/sections/repository/sections/pull-request/components/comment-likes/utils';
import { getCurrentRepositoryUuid } from 'src/selectors/repository-selectors';
import { getCurrentUserKey } from 'src/selectors/user-selectors';
import { Action } from 'src/types/state';
import authRequest from 'src/utils/fetch';

import { TOGGLE_COMMENT_LIKE } from '../actions';
import {
  getCurrentPullRequestUrlPieces,
  getCommentLikes,
  getCurrentPullRequestId,
} from '../selectors';

export function* toggleCommentLike(
  action: Action<{
    result: {
      commentId: number;
      setToLike: boolean;
      commentLike: {} | { commentId: number; users: string[] };
      autoreviewFeedbackAttributes?: {
        isAutoreview: boolean;
        autoreviewFeedbackSentiment: string;
        prId: number;
        prURL: string;
      } | null;
    };
  }>
) {
  if (!action.payload) {
    return;
  }
  const { owner, slug, id } = yield select(getCurrentPullRequestUrlPieces);
  const {
    result: { commentId, setToLike, autoreviewFeedbackAttributes },
  } = action.payload;

  const commentLikesUrl = urls.api.internal.likeComment(
    owner,
    slug,
    id,
    commentId
  );
  const authUrl = authRequest(commentLikesUrl, {
    method: setToLike ? 'POST' : 'DELETE',
  });

  const repoUUID: string = yield select(getCurrentRepositoryUuid);
  const prId: string = yield select(getCurrentPullRequestId);
  publishCommentLikesUiEvent(
    repoUUID,
    prId,
    commentId,
    setToLike,
    autoreviewFeedbackAttributes
  );

  const currentUserUuid: string | null | undefined = yield select(
    getCurrentUserKey
  );
  const commentLikes: CommentLikes[] = yield select(getCommentLikes, [
    { id: commentId },
  ]);

  try {
    const response: Response = yield call(fetch, authUrl);
    if (response.ok) {
      yield put({
        type: TOGGLE_COMMENT_LIKE.SUCCESS,
      });
    } else if (
      (setToLike && response.status === 400) ||
      (!setToLike && response.status === 404)
    ) {
      yield put({
        type: TOGGLE_COMMENT_LIKE.SUCCESS,
      });
    } else {
      // An unknown situation occured, reverse the pre-emptive comment likes change
      yield put({
        type: TOGGLE_COMMENT_LIKE.ERROR,
        payload: toggledCommentLike(commentId, commentLikes, currentUserUuid),
        meta: {
          schema: commentLikesSchema,
        },
      });
    }
  } catch (e) {
    yield put({
      type: TOGGLE_COMMENT_LIKE.ERROR,
      payload: toggledCommentLike(commentId, commentLikes, currentUserUuid),
      meta: {
        schema: commentLikesSchema,
      },
    });
    Sentry.captureException(e);
  }
}
