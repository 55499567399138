(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.connectHostHistory = f()}})(function(){var define,module,exports;return (function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(_dereq_,module,exports){
/**
* The History API allows your add-on to manipulate the current page URL for use in navigation. When using
* the history module only the page anchor is modified and not the entire window location.
*
* Note: This is only enabled for page modules (Admin page, General page, Configure page, User profile page).
* It cannot be used if the page module is launched as a dialog.
* ### Example ###
* ```
* // Register a function to run when state is changed.
* // You should use this to update your UI to show the state.
* AP.history.popState(function(e){
*     alert("The URL has changed from: " + e.oldURL + "to: " + e.newURL);
* });
*
* // Adds a new entry to the history and changes the url in the browser.
* AP.history.pushState("page2");
*
* // Changes the URL back and invokes any registered popState callbacks.
* AP.history.back();
* ```
* @module History
*/

'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});

var _constants = _dereq_('./constants');

var _models = _dereq_('./models');

var _utils = _dereq_('./utils');

function broadcastEvents(eventName, evt) {
  (0, _utils.callConnectHost)(function (ch) {
    var extensions = (0, _utils.values)(ch.getExtensions());
    extensions.forEach(function (ext) {
      var addonKey = ext.extension.addon_key;
      var current = new _models.Current({
        url: window.location.href,
        state: evt.state
      });
      var route = new _models.Route(current);
      ch.broadcastEvent(eventName, {
        addon_key: addonKey
      }, route.render(addonKey));
    });
  });
}

var registeredWindowListeners = false;

function registerWindowListeners() {
  if (registeredWindowListeners) return;

  window.addEventListener('popstate', function (e) {
    var evt = (0, _utils.createEvent)('ap_popstate');
    evt.state = (0, _utils.unwrapState)(e.state);
    window.dispatchEvent(evt);
  });

  window.addEventListener('ap_popstate', function (e) {
    var evt = (0, _utils.createEvent)('ap_changestate');
    evt.state = e.state;
    window.dispatchEvent(evt);
    broadcastEvents(_constants.HISTORY_POPSTATE, e);
  });

  window.addEventListener('ap_pushstate', function (e) {
    broadcastEvents(_constants.HISTORY_PUSHSTATE, e);
  });

  window.addEventListener('ap_replacestate', function (e) {
    broadcastEvents(_constants.HISTORY_REPLACESTATE, e);
  });

  window.addEventListener('ap_changestate', function (e) {
    broadcastEvents(_constants.HISTORY_CHANGESTATE, e);
  });

  registeredWindowListeners = true;
}

function _pushState(route) {
  var renderedState = route.state.render();
  var renderedURL = route.url.render();
  window.history.pushState((0, _utils.wrapState)(renderedState), renderedURL.title, renderedURL.url);
}

function _replaceState(route) {
  var renderedState = route.state.render();
  var renderedURL = route.url.render();
  window.history.replaceState((0, _utils.wrapState)(renderedState), renderedURL.title, renderedURL.url);
}

exports['default'] = {
  _registerWindowListeners: function _registerWindowListeners() {
    registerWindowListeners();
  },
  /**
  * Goes back one step in the joint session history. Will invoke the popState callback.
  * @noDemo
  * @example
  * AP.history.back(); // go back by 1 entry in the browser history.
  */
  back: function back(callback) {
    callback = arguments[arguments.length - 1];
    this.go(-1, callback);
  },
  /**
  * Goes back one step in the joint session history. Will invoke the popState callback.
  * @noDemo
  * @example
  * AP.history.forward(); // go forward by 1 entry in the browser history.
  */
  forward: function forward(callback) {
    callback = arguments[arguments.length - 1];
    this.go(1, callback);
  },
  /**
  * Moves the page history back or forward the specified number of steps. A zero delta will reload the current page.
  * If the delta is out of range, does nothing. This call invoke the popState callback.
  * @param {Integer} delta - Number of places to move in the history
  * @noDemo
  * @example
  * AP.history.go(-2); // go back by 2 entries in the browser history.
  */
  go: function go(delta) {
    var callback = arguments[arguments.length - 1];
    if (callback._context.extension.options.isFullPage) {
      window.history.go(delta);
    } else {
      (0, _utils.log)("History is only available to page modules");
    }
  },
  /**
  * Retrieves the current state of the history stack and returns the value. The returned value is the same as what was set with the pushState method.
  * @return {String} The current url anchor
  * @noDemo
  * @example
  * AP.history.pushState("page5");
  * AP.history.getState(); // returns "page5";
  */
  getState: function getState(type, callback) {
    callback = arguments[arguments.length - 1];
    var addonKey = callback._context.extension.addon_key;
    var current = new _models.Current({
      url: window.location.href,
      state: (0, _utils.unwrapState)(window.history.state)
    });
    var route = new _models.Route(current);
    var rendered = route.render(addonKey);
    type = type ? typeof type === 'string' ? type : 'hash' : 'hash';
    if (type === 'hash') {
      callback(rendered.hash);
    } else if (type === 'all') {
      callback(rendered);
    } else {
      throw new Error('invalid type for getState');
    }
  },

  /**
  * Updates the location's anchor with the specified value and pushes the given data onto the session history.
  * Does not invoke popState callback.
  * @param {Object} newState
  * @param {String} title
  * @param {String} url - URL to add to history
  */
  pushState: function pushState(newState, callback) {
    callback = arguments[arguments.length - 1];
    var addonKey = callback._context.extension.addon_key;
    var current = new _models.Current({
      url: window.location.href,
      state: (0, _utils.unwrapState)(window.history.state)
    });
    var currentRoute = new _models.Route(current);
    var change = new _models.Change(newState, addonKey);
    var changeRoute = new _models.Route(change);
    if (callback._context.extension.options.isFullPage) {
      if (changeRoute.url.isURLEqual(currentRoute.url)) {
        currentRoute.merge(changeRoute);
        _replaceState(currentRoute);
      } else {
        if (changeRoute.url.isPathnameEqual(currentRoute.url)) {
          currentRoute.merge(changeRoute);
          _pushState(currentRoute);
        } else {
          _pushState(changeRoute);
        }
      }
      var _event = (0, _utils.createEvent)('ap_pushstate');
      _event.state = (0, _utils.unwrapState)(window.history.state);
      window.dispatchEvent(_event);
    } else {
      (0, _utils.log)('History is only available to page modules');
    }
  },
  /**
  * Updates the current entry in the session history. Updates the location's anchor with the specified value but does not change the session history. Does not invoke popState callback.
  * @param {String} url - URL to update current history value with
  */
  replaceState: function replaceState(newState, callback) {
    callback = arguments[arguments.length - 1];
    var addonKey = callback._context.extension.addon_key;
    var current = new _models.Current({
      url: window.location.href,
      state: (0, _utils.unwrapState)(window.history.state)
    });
    var currentRoute = new _models.Route(current);
    var change = new _models.Change(newState, addonKey);
    var changeRoute = new _models.Route(change);
    if (callback._context.extension.options.isFullPage) {
      if (changeRoute.url.isURLEqual(currentRoute.url)) {
        currentRoute.merge(changeRoute);
        _replaceState(currentRoute);
      } else {
        if (changeRoute.url.isPathnameEqual(currentRoute.url)) {
          currentRoute.merge(changeRoute);
          _replaceState(currentRoute);
        } else {
          _replaceState(changeRoute);
        }
      }
      var _event2 = (0, _utils.createEvent)('ap_replacestate');
      _event2.state = (0, _utils.unwrapState)(window.history.state);
      window.dispatchEvent(_event2);
    } else {
      (0, _utils.log)('History is only available to page modules');
    }
  }
};
module.exports = exports['default'];
},{"./constants":7,"./models":8,"./utils":9}],2:[function(_dereq_,module,exports){
(function (global){
/*! http://mths.be/punycode v1.2.4 by @mathias */
;(function(root) {

	/** Detect free variables */
	var freeExports = typeof exports == 'object' && exports;
	var freeModule = typeof module == 'object' && module &&
		module.exports == freeExports && module;
	var freeGlobal = typeof global == 'object' && global;
	if (freeGlobal.global === freeGlobal || freeGlobal.window === freeGlobal) {
		root = freeGlobal;
	}

	/**
	 * The `punycode` object.
	 * @name punycode
	 * @type Object
	 */
	var punycode,

	/** Highest positive signed 32-bit float value */
	maxInt = 2147483647, // aka. 0x7FFFFFFF or 2^31-1

	/** Bootstring parameters */
	base = 36,
	tMin = 1,
	tMax = 26,
	skew = 38,
	damp = 700,
	initialBias = 72,
	initialN = 128, // 0x80
	delimiter = '-', // '\x2D'

	/** Regular expressions */
	regexPunycode = /^xn--/,
	regexNonASCII = /[^ -~]/, // unprintable ASCII chars + non-ASCII chars
	regexSeparators = /\x2E|\u3002|\uFF0E|\uFF61/g, // RFC 3490 separators

	/** Error messages */
	errors = {
		'overflow': 'Overflow: input needs wider integers to process',
		'not-basic': 'Illegal input >= 0x80 (not a basic code point)',
		'invalid-input': 'Invalid input'
	},

	/** Convenience shortcuts */
	baseMinusTMin = base - tMin,
	floor = Math.floor,
	stringFromCharCode = String.fromCharCode,

	/** Temporary variable */
	key;

	/*--------------------------------------------------------------------------*/

	/**
	 * A generic error utility function.
	 * @private
	 * @param {String} type The error type.
	 * @returns {Error} Throws a `RangeError` with the applicable error message.
	 */
	function error(type) {
		throw RangeError(errors[type]);
	}

	/**
	 * A generic `Array#map` utility function.
	 * @private
	 * @param {Array} array The array to iterate over.
	 * @param {Function} callback The function that gets called for every array
	 * item.
	 * @returns {Array} A new array of values returned by the callback function.
	 */
	function map(array, fn) {
		var length = array.length;
		while (length--) {
			array[length] = fn(array[length]);
		}
		return array;
	}

	/**
	 * A simple `Array#map`-like wrapper to work with domain name strings.
	 * @private
	 * @param {String} domain The domain name.
	 * @param {Function} callback The function that gets called for every
	 * character.
	 * @returns {Array} A new string of characters returned by the callback
	 * function.
	 */
	function mapDomain(string, fn) {
		return map(string.split(regexSeparators), fn).join('.');
	}

	/**
	 * Creates an array containing the numeric code points of each Unicode
	 * character in the string. While JavaScript uses UCS-2 internally,
	 * this function will convert a pair of surrogate halves (each of which
	 * UCS-2 exposes as separate characters) into a single code point,
	 * matching UTF-16.
	 * @see `punycode.ucs2.encode`
	 * @see <http://mathiasbynens.be/notes/javascript-encoding>
	 * @memberOf punycode.ucs2
	 * @name decode
	 * @param {String} string The Unicode input string (UCS-2).
	 * @returns {Array} The new array of code points.
	 */
	function ucs2decode(string) {
		var output = [],
		    counter = 0,
		    length = string.length,
		    value,
		    extra;
		while (counter < length) {
			value = string.charCodeAt(counter++);
			if (value >= 0xD800 && value <= 0xDBFF && counter < length) {
				// high surrogate, and there is a next character
				extra = string.charCodeAt(counter++);
				if ((extra & 0xFC00) == 0xDC00) { // low surrogate
					output.push(((value & 0x3FF) << 10) + (extra & 0x3FF) + 0x10000);
				} else {
					// unmatched surrogate; only append this code unit, in case the next
					// code unit is the high surrogate of a surrogate pair
					output.push(value);
					counter--;
				}
			} else {
				output.push(value);
			}
		}
		return output;
	}

	/**
	 * Creates a string based on an array of numeric code points.
	 * @see `punycode.ucs2.decode`
	 * @memberOf punycode.ucs2
	 * @name encode
	 * @param {Array} codePoints The array of numeric code points.
	 * @returns {String} The new Unicode string (UCS-2).
	 */
	function ucs2encode(array) {
		return map(array, function(value) {
			var output = '';
			if (value > 0xFFFF) {
				value -= 0x10000;
				output += stringFromCharCode(value >>> 10 & 0x3FF | 0xD800);
				value = 0xDC00 | value & 0x3FF;
			}
			output += stringFromCharCode(value);
			return output;
		}).join('');
	}

	/**
	 * Converts a basic code point into a digit/integer.
	 * @see `digitToBasic()`
	 * @private
	 * @param {Number} codePoint The basic numeric code point value.
	 * @returns {Number} The numeric value of a basic code point (for use in
	 * representing integers) in the range `0` to `base - 1`, or `base` if
	 * the code point does not represent a value.
	 */
	function basicToDigit(codePoint) {
		if (codePoint - 48 < 10) {
			return codePoint - 22;
		}
		if (codePoint - 65 < 26) {
			return codePoint - 65;
		}
		if (codePoint - 97 < 26) {
			return codePoint - 97;
		}
		return base;
	}

	/**
	 * Converts a digit/integer into a basic code point.
	 * @see `basicToDigit()`
	 * @private
	 * @param {Number} digit The numeric value of a basic code point.
	 * @returns {Number} The basic code point whose value (when used for
	 * representing integers) is `digit`, which needs to be in the range
	 * `0` to `base - 1`. If `flag` is non-zero, the uppercase form is
	 * used; else, the lowercase form is used. The behavior is undefined
	 * if `flag` is non-zero and `digit` has no uppercase form.
	 */
	function digitToBasic(digit, flag) {
		//  0..25 map to ASCII a..z or A..Z
		// 26..35 map to ASCII 0..9
		return digit + 22 + 75 * (digit < 26) - ((flag != 0) << 5);
	}

	/**
	 * Bias adaptation function as per section 3.4 of RFC 3492.
	 * http://tools.ietf.org/html/rfc3492#section-3.4
	 * @private
	 */
	function adapt(delta, numPoints, firstTime) {
		var k = 0;
		delta = firstTime ? floor(delta / damp) : delta >> 1;
		delta += floor(delta / numPoints);
		for (/* no initialization */; delta > baseMinusTMin * tMax >> 1; k += base) {
			delta = floor(delta / baseMinusTMin);
		}
		return floor(k + (baseMinusTMin + 1) * delta / (delta + skew));
	}

	/**
	 * Converts a Punycode string of ASCII-only symbols to a string of Unicode
	 * symbols.
	 * @memberOf punycode
	 * @param {String} input The Punycode string of ASCII-only symbols.
	 * @returns {String} The resulting string of Unicode symbols.
	 */
	function decode(input) {
		// Don't use UCS-2
		var output = [],
		    inputLength = input.length,
		    out,
		    i = 0,
		    n = initialN,
		    bias = initialBias,
		    basic,
		    j,
		    index,
		    oldi,
		    w,
		    k,
		    digit,
		    t,
		    /** Cached calculation results */
		    baseMinusT;

		// Handle the basic code points: let `basic` be the number of input code
		// points before the last delimiter, or `0` if there is none, then copy
		// the first basic code points to the output.

		basic = input.lastIndexOf(delimiter);
		if (basic < 0) {
			basic = 0;
		}

		for (j = 0; j < basic; ++j) {
			// if it's not a basic code point
			if (input.charCodeAt(j) >= 0x80) {
				error('not-basic');
			}
			output.push(input.charCodeAt(j));
		}

		// Main decoding loop: start just after the last delimiter if any basic code
		// points were copied; start at the beginning otherwise.

		for (index = basic > 0 ? basic + 1 : 0; index < inputLength; /* no final expression */) {

			// `index` is the index of the next character to be consumed.
			// Decode a generalized variable-length integer into `delta`,
			// which gets added to `i`. The overflow checking is easier
			// if we increase `i` as we go, then subtract off its starting
			// value at the end to obtain `delta`.
			for (oldi = i, w = 1, k = base; /* no condition */; k += base) {

				if (index >= inputLength) {
					error('invalid-input');
				}

				digit = basicToDigit(input.charCodeAt(index++));

				if (digit >= base || digit > floor((maxInt - i) / w)) {
					error('overflow');
				}

				i += digit * w;
				t = k <= bias ? tMin : (k >= bias + tMax ? tMax : k - bias);

				if (digit < t) {
					break;
				}

				baseMinusT = base - t;
				if (w > floor(maxInt / baseMinusT)) {
					error('overflow');
				}

				w *= baseMinusT;

			}

			out = output.length + 1;
			bias = adapt(i - oldi, out, oldi == 0);

			// `i` was supposed to wrap around from `out` to `0`,
			// incrementing `n` each time, so we'll fix that now:
			if (floor(i / out) > maxInt - n) {
				error('overflow');
			}

			n += floor(i / out);
			i %= out;

			// Insert `n` at position `i` of the output
			output.splice(i++, 0, n);

		}

		return ucs2encode(output);
	}

	/**
	 * Converts a string of Unicode symbols to a Punycode string of ASCII-only
	 * symbols.
	 * @memberOf punycode
	 * @param {String} input The string of Unicode symbols.
	 * @returns {String} The resulting Punycode string of ASCII-only symbols.
	 */
	function encode(input) {
		var n,
		    delta,
		    handledCPCount,
		    basicLength,
		    bias,
		    j,
		    m,
		    q,
		    k,
		    t,
		    currentValue,
		    output = [],
		    /** `inputLength` will hold the number of code points in `input`. */
		    inputLength,
		    /** Cached calculation results */
		    handledCPCountPlusOne,
		    baseMinusT,
		    qMinusT;

		// Convert the input in UCS-2 to Unicode
		input = ucs2decode(input);

		// Cache the length
		inputLength = input.length;

		// Initialize the state
		n = initialN;
		delta = 0;
		bias = initialBias;

		// Handle the basic code points
		for (j = 0; j < inputLength; ++j) {
			currentValue = input[j];
			if (currentValue < 0x80) {
				output.push(stringFromCharCode(currentValue));
			}
		}

		handledCPCount = basicLength = output.length;

		// `handledCPCount` is the number of code points that have been handled;
		// `basicLength` is the number of basic code points.

		// Finish the basic string - if it is not empty - with a delimiter
		if (basicLength) {
			output.push(delimiter);
		}

		// Main encoding loop:
		while (handledCPCount < inputLength) {

			// All non-basic code points < n have been handled already. Find the next
			// larger one:
			for (m = maxInt, j = 0; j < inputLength; ++j) {
				currentValue = input[j];
				if (currentValue >= n && currentValue < m) {
					m = currentValue;
				}
			}

			// Increase `delta` enough to advance the decoder's <n,i> state to <m,0>,
			// but guard against overflow
			handledCPCountPlusOne = handledCPCount + 1;
			if (m - n > floor((maxInt - delta) / handledCPCountPlusOne)) {
				error('overflow');
			}

			delta += (m - n) * handledCPCountPlusOne;
			n = m;

			for (j = 0; j < inputLength; ++j) {
				currentValue = input[j];

				if (currentValue < n && ++delta > maxInt) {
					error('overflow');
				}

				if (currentValue == n) {
					// Represent delta as a generalized variable-length integer
					for (q = delta, k = base; /* no condition */; k += base) {
						t = k <= bias ? tMin : (k >= bias + tMax ? tMax : k - bias);
						if (q < t) {
							break;
						}
						qMinusT = q - t;
						baseMinusT = base - t;
						output.push(
							stringFromCharCode(digitToBasic(t + qMinusT % baseMinusT, 0))
						);
						q = floor(qMinusT / baseMinusT);
					}

					output.push(stringFromCharCode(digitToBasic(q, 0)));
					bias = adapt(delta, handledCPCountPlusOne, handledCPCount == basicLength);
					delta = 0;
					++handledCPCount;
				}
			}

			++delta;
			++n;

		}
		return output.join('');
	}

	/**
	 * Converts a Punycode string representing a domain name to Unicode. Only the
	 * Punycoded parts of the domain name will be converted, i.e. it doesn't
	 * matter if you call it on a string that has already been converted to
	 * Unicode.
	 * @memberOf punycode
	 * @param {String} domain The Punycode domain name to convert to Unicode.
	 * @returns {String} The Unicode representation of the given Punycode
	 * string.
	 */
	function toUnicode(domain) {
		return mapDomain(domain, function(string) {
			return regexPunycode.test(string)
				? decode(string.slice(4).toLowerCase())
				: string;
		});
	}

	/**
	 * Converts a Unicode string representing a domain name to Punycode. Only the
	 * non-ASCII parts of the domain name will be converted, i.e. it doesn't
	 * matter if you call it with a domain that's already in ASCII.
	 * @memberOf punycode
	 * @param {String} domain The domain name to convert, as a Unicode string.
	 * @returns {String} The Punycode representation of the given domain name.
	 */
	function toASCII(domain) {
		return mapDomain(domain, function(string) {
			return regexNonASCII.test(string)
				? 'xn--' + encode(string)
				: string;
		});
	}

	/*--------------------------------------------------------------------------*/

	/** Define the public API */
	punycode = {
		/**
		 * A string representing the current Punycode.js version number.
		 * @memberOf punycode
		 * @type String
		 */
		'version': '1.2.4',
		/**
		 * An object of methods to convert from JavaScript's internal character
		 * representation (UCS-2) to Unicode code points, and back.
		 * @see <http://mathiasbynens.be/notes/javascript-encoding>
		 * @memberOf punycode
		 * @type Object
		 */
		'ucs2': {
			'decode': ucs2decode,
			'encode': ucs2encode
		},
		'decode': decode,
		'encode': encode,
		'toASCII': toASCII,
		'toUnicode': toUnicode
	};

	/** Expose `punycode` */
	// Some AMD build optimizers, like r.js, check for specific condition patterns
	// like the following:
	if (
		typeof define == 'function' &&
		typeof define.amd == 'object' &&
		define.amd
	) {
		define('punycode', function() {
			return punycode;
		});
	} else if (freeExports && !freeExports.nodeType) {
		if (freeModule) { // in Node.js or RingoJS v0.8.0+
			freeModule.exports = punycode;
		} else { // in Narwhal or RingoJS v0.7.0-
			for (key in punycode) {
				punycode.hasOwnProperty(key) && (freeExports[key] = punycode[key]);
			}
		}
	} else { // in Rhino or a web browser
		root.punycode = punycode;
	}

}(this));

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{}],3:[function(_dereq_,module,exports){
// Copyright Joyent, Inc. and other Node contributors.
//
// Permission is hereby granted, free of charge, to any person obtaining a
// copy of this software and associated documentation files (the
// "Software"), to deal in the Software without restriction, including
// without limitation the rights to use, copy, modify, merge, publish,
// distribute, sublicense, and/or sell copies of the Software, and to permit
// persons to whom the Software is furnished to do so, subject to the
// following conditions:
//
// The above copyright notice and this permission notice shall be included
// in all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
// OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
// MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
// NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
// DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
// OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
// USE OR OTHER DEALINGS IN THE SOFTWARE.

var punycode = _dereq_('punycode');

exports.parse = urlParse;
exports.resolve = urlResolve;
exports.resolveObject = urlResolveObject;
exports.format = urlFormat;

exports.Url = Url;

function Url() {
  this.protocol = null;
  this.slashes = null;
  this.auth = null;
  this.host = null;
  this.port = null;
  this.hostname = null;
  this.hash = null;
  this.search = null;
  this.query = null;
  this.pathname = null;
  this.path = null;
  this.href = null;
}

// Reference: RFC 3986, RFC 1808, RFC 2396

// define these here so at least they only have to be
// compiled once on the first module load.
var protocolPattern = /^([a-z0-9.+-]+:)/i,
    portPattern = /:[0-9]*$/,

    // RFC 2396: characters reserved for delimiting URLs.
    // We actually just auto-escape these.
    delims = ['<', '>', '"', '`', ' ', '\r', '\n', '\t'],

    // RFC 2396: characters not allowed for various reasons.
    unwise = ['{', '}', '|', '\\', '^', '`'].concat(delims),

    // Allowed by RFCs, but cause of XSS attacks.  Always escape these.
    autoEscape = ['\''].concat(unwise),
    // Characters that are never ever allowed in a hostname.
    // Note that any invalid chars are also handled, but these
    // are the ones that are *expected* to be seen, so we fast-path
    // them.
    nonHostChars = ['%', '/', '?', ';', '#'].concat(autoEscape),
    hostEndingChars = ['/', '?', '#'],
    hostnameMaxLen = 255,
    hostnamePartPattern = /^[a-z0-9A-Z_-]{0,63}$/,
    hostnamePartStart = /^([a-z0-9A-Z_-]{0,63})(.*)$/,
    // protocols that can allow "unsafe" and "unwise" chars.
    unsafeProtocol = {
      'javascript': true,
      'javascript:': true
    },
    // protocols that never have a hostname.
    hostlessProtocol = {
      'javascript': true,
      'javascript:': true
    },
    // protocols that always contain a // bit.
    slashedProtocol = {
      'http': true,
      'https': true,
      'ftp': true,
      'gopher': true,
      'file': true,
      'http:': true,
      'https:': true,
      'ftp:': true,
      'gopher:': true,
      'file:': true
    },
    querystring = _dereq_('querystring');

function urlParse(url, parseQueryString, slashesDenoteHost) {
  if (url && isObject(url) && url instanceof Url) return url;

  var u = new Url;
  u.parse(url, parseQueryString, slashesDenoteHost);
  return u;
}

Url.prototype.parse = function(url, parseQueryString, slashesDenoteHost) {
  if (!isString(url)) {
    throw new TypeError("Parameter 'url' must be a string, not " + typeof url);
  }

  var rest = url;

  // trim before proceeding.
  // This is to support parse stuff like "  http://foo.com  \n"
  rest = rest.trim();

  var proto = protocolPattern.exec(rest);
  if (proto) {
    proto = proto[0];
    var lowerProto = proto.toLowerCase();
    this.protocol = lowerProto;
    rest = rest.substr(proto.length);
  }

  // figure out if it's got a host
  // user@server is *always* interpreted as a hostname, and url
  // resolution will treat //foo/bar as host=foo,path=bar because that's
  // how the browser resolves relative URLs.
  if (slashesDenoteHost || proto || rest.match(/^\/\/[^@\/]+@[^@\/]+/)) {
    var slashes = rest.substr(0, 2) === '//';
    if (slashes && !(proto && hostlessProtocol[proto])) {
      rest = rest.substr(2);
      this.slashes = true;
    }
  }

  if (!hostlessProtocol[proto] &&
      (slashes || (proto && !slashedProtocol[proto]))) {

    // there's a hostname.
    // the first instance of /, ?, ;, or # ends the host.
    //
    // If there is an @ in the hostname, then non-host chars *are* allowed
    // to the left of the last @ sign, unless some host-ending character
    // comes *before* the @-sign.
    // URLs are obnoxious.
    //
    // ex:
    // http://a@b@c/ => user:a@b host:c
    // http://a@b?@c => user:a host:c path:/?@c

    // v0.12 TODO(isaacs): This is not quite how Chrome does things.
    // Review our test case against browsers more comprehensively.

    // find the first instance of any hostEndingChars
    var hostEnd = -1;
    for (var i = 0; i < hostEndingChars.length; i++) {
      var hec = rest.indexOf(hostEndingChars[i]);
      if (hec !== -1 && (hostEnd === -1 || hec < hostEnd))
        hostEnd = hec;
    }

    // at this point, either we have an explicit point where the
    // auth portion cannot go past, or the last @ char is the decider.
    var auth, atSign;
    if (hostEnd === -1) {
      // atSign can be anywhere.
      atSign = rest.lastIndexOf('@');
    } else {
      // atSign must be in auth portion.
      // http://a@b/c@d => host:b auth:a path:/c@d
      atSign = rest.lastIndexOf('@', hostEnd);
    }

    // Now we have a portion which is definitely the auth.
    // Pull that off.
    if (atSign !== -1) {
      auth = rest.slice(0, atSign);
      rest = rest.slice(atSign + 1);
      this.auth = decodeURIComponent(auth);
    }

    // the host is the remaining to the left of the first non-host char
    hostEnd = -1;
    for (var i = 0; i < nonHostChars.length; i++) {
      var hec = rest.indexOf(nonHostChars[i]);
      if (hec !== -1 && (hostEnd === -1 || hec < hostEnd))
        hostEnd = hec;
    }
    // if we still have not hit it, then the entire thing is a host.
    if (hostEnd === -1)
      hostEnd = rest.length;

    this.host = rest.slice(0, hostEnd);
    rest = rest.slice(hostEnd);

    // pull out port.
    this.parseHost();

    // we've indicated that there is a hostname,
    // so even if it's empty, it has to be present.
    this.hostname = this.hostname || '';

    // if hostname begins with [ and ends with ]
    // assume that it's an IPv6 address.
    var ipv6Hostname = this.hostname[0] === '[' &&
        this.hostname[this.hostname.length - 1] === ']';

    // validate a little.
    if (!ipv6Hostname) {
      var hostparts = this.hostname.split(/\./);
      for (var i = 0, l = hostparts.length; i < l; i++) {
        var part = hostparts[i];
        if (!part) continue;
        if (!part.match(hostnamePartPattern)) {
          var newpart = '';
          for (var j = 0, k = part.length; j < k; j++) {
            if (part.charCodeAt(j) > 127) {
              // we replace non-ASCII char with a temporary placeholder
              // we need this to make sure size of hostname is not
              // broken by replacing non-ASCII by nothing
              newpart += 'x';
            } else {
              newpart += part[j];
            }
          }
          // we test again with ASCII char only
          if (!newpart.match(hostnamePartPattern)) {
            var validParts = hostparts.slice(0, i);
            var notHost = hostparts.slice(i + 1);
            var bit = part.match(hostnamePartStart);
            if (bit) {
              validParts.push(bit[1]);
              notHost.unshift(bit[2]);
            }
            if (notHost.length) {
              rest = '/' + notHost.join('.') + rest;
            }
            this.hostname = validParts.join('.');
            break;
          }
        }
      }
    }

    if (this.hostname.length > hostnameMaxLen) {
      this.hostname = '';
    } else {
      // hostnames are always lower case.
      this.hostname = this.hostname.toLowerCase();
    }

    if (!ipv6Hostname) {
      // IDNA Support: Returns a puny coded representation of "domain".
      // It only converts the part of the domain name that
      // has non ASCII characters. I.e. it dosent matter if
      // you call it with a domain that already is in ASCII.
      var domainArray = this.hostname.split('.');
      var newOut = [];
      for (var i = 0; i < domainArray.length; ++i) {
        var s = domainArray[i];
        newOut.push(s.match(/[^A-Za-z0-9_-]/) ?
            'xn--' + punycode.encode(s) : s);
      }
      this.hostname = newOut.join('.');
    }

    var p = this.port ? ':' + this.port : '';
    var h = this.hostname || '';
    this.host = h + p;
    this.href += this.host;

    // strip [ and ] from the hostname
    // the host field still retains them, though
    if (ipv6Hostname) {
      this.hostname = this.hostname.substr(1, this.hostname.length - 2);
      if (rest[0] !== '/') {
        rest = '/' + rest;
      }
    }
  }

  // now rest is set to the post-host stuff.
  // chop off any delim chars.
  if (!unsafeProtocol[lowerProto]) {

    // First, make 100% sure that any "autoEscape" chars get
    // escaped, even if encodeURIComponent doesn't think they
    // need to be.
    for (var i = 0, l = autoEscape.length; i < l; i++) {
      var ae = autoEscape[i];
      var esc = encodeURIComponent(ae);
      if (esc === ae) {
        esc = escape(ae);
      }
      rest = rest.split(ae).join(esc);
    }
  }


  // chop off from the tail first.
  var hash = rest.indexOf('#');
  if (hash !== -1) {
    // got a fragment string.
    this.hash = rest.substr(hash);
    rest = rest.slice(0, hash);
  }
  var qm = rest.indexOf('?');
  if (qm !== -1) {
    this.search = rest.substr(qm);
    this.query = rest.substr(qm + 1);
    if (parseQueryString) {
      this.query = querystring.parse(this.query);
    }
    rest = rest.slice(0, qm);
  } else if (parseQueryString) {
    // no query string, but parseQueryString still requested
    this.search = '';
    this.query = {};
  }
  if (rest) this.pathname = rest;
  if (slashedProtocol[lowerProto] &&
      this.hostname && !this.pathname) {
    this.pathname = '/';
  }

  //to support http.request
  if (this.pathname || this.search) {
    var p = this.pathname || '';
    var s = this.search || '';
    this.path = p + s;
  }

  // finally, reconstruct the href based on what has been validated.
  this.href = this.format();
  return this;
};

// format a parsed object into a url string
function urlFormat(obj) {
  // ensure it's an object, and not a string url.
  // If it's an obj, this is a no-op.
  // this way, you can call url_format() on strings
  // to clean up potentially wonky urls.
  if (isString(obj)) obj = urlParse(obj);
  if (!(obj instanceof Url)) return Url.prototype.format.call(obj);
  return obj.format();
}

Url.prototype.format = function() {
  var auth = this.auth || '';
  if (auth) {
    auth = encodeURIComponent(auth);
    auth = auth.replace(/%3A/i, ':');
    auth += '@';
  }

  var protocol = this.protocol || '',
      pathname = this.pathname || '',
      hash = this.hash || '',
      host = false,
      query = '';

  if (this.host) {
    host = auth + this.host;
  } else if (this.hostname) {
    host = auth + (this.hostname.indexOf(':') === -1 ?
        this.hostname :
        '[' + this.hostname + ']');
    if (this.port) {
      host += ':' + this.port;
    }
  }

  if (this.query &&
      isObject(this.query) &&
      Object.keys(this.query).length) {
    query = querystring.stringify(this.query);
  }

  var search = this.search || (query && ('?' + query)) || '';

  if (protocol && protocol.substr(-1) !== ':') protocol += ':';

  // only the slashedProtocols get the //.  Not mailto:, xmpp:, etc.
  // unless they had them to begin with.
  if (this.slashes ||
      (!protocol || slashedProtocol[protocol]) && host !== false) {
    host = '//' + (host || '');
    if (pathname && pathname.charAt(0) !== '/') pathname = '/' + pathname;
  } else if (!host) {
    host = '';
  }

  if (hash && hash.charAt(0) !== '#') hash = '#' + hash;
  if (search && search.charAt(0) !== '?') search = '?' + search;

  pathname = pathname.replace(/[?#]/g, function(match) {
    return encodeURIComponent(match);
  });
  search = search.replace('#', '%23');

  return protocol + host + pathname + search + hash;
};

function urlResolve(source, relative) {
  return urlParse(source, false, true).resolve(relative);
}

Url.prototype.resolve = function(relative) {
  return this.resolveObject(urlParse(relative, false, true)).format();
};

function urlResolveObject(source, relative) {
  if (!source) return relative;
  return urlParse(source, false, true).resolveObject(relative);
}

Url.prototype.resolveObject = function(relative) {
  if (isString(relative)) {
    var rel = new Url();
    rel.parse(relative, false, true);
    relative = rel;
  }

  var result = new Url();
  Object.keys(this).forEach(function(k) {
    result[k] = this[k];
  }, this);

  // hash is always overridden, no matter what.
  // even href="" will remove it.
  result.hash = relative.hash;

  // if the relative url is empty, then there's nothing left to do here.
  if (relative.href === '') {
    result.href = result.format();
    return result;
  }

  // hrefs like //foo/bar always cut to the protocol.
  if (relative.slashes && !relative.protocol) {
    // take everything except the protocol from relative
    Object.keys(relative).forEach(function(k) {
      if (k !== 'protocol')
        result[k] = relative[k];
    });

    //urlParse appends trailing / to urls like http://www.example.com
    if (slashedProtocol[result.protocol] &&
        result.hostname && !result.pathname) {
      result.path = result.pathname = '/';
    }

    result.href = result.format();
    return result;
  }

  if (relative.protocol && relative.protocol !== result.protocol) {
    // if it's a known url protocol, then changing
    // the protocol does weird things
    // first, if it's not file:, then we MUST have a host,
    // and if there was a path
    // to begin with, then we MUST have a path.
    // if it is file:, then the host is dropped,
    // because that's known to be hostless.
    // anything else is assumed to be absolute.
    if (!slashedProtocol[relative.protocol]) {
      Object.keys(relative).forEach(function(k) {
        result[k] = relative[k];
      });
      result.href = result.format();
      return result;
    }

    result.protocol = relative.protocol;
    if (!relative.host && !hostlessProtocol[relative.protocol]) {
      var relPath = (relative.pathname || '').split('/');
      while (relPath.length && !(relative.host = relPath.shift()));
      if (!relative.host) relative.host = '';
      if (!relative.hostname) relative.hostname = '';
      if (relPath[0] !== '') relPath.unshift('');
      if (relPath.length < 2) relPath.unshift('');
      result.pathname = relPath.join('/');
    } else {
      result.pathname = relative.pathname;
    }
    result.search = relative.search;
    result.query = relative.query;
    result.host = relative.host || '';
    result.auth = relative.auth;
    result.hostname = relative.hostname || relative.host;
    result.port = relative.port;
    // to support http.request
    if (result.pathname || result.search) {
      var p = result.pathname || '';
      var s = result.search || '';
      result.path = p + s;
    }
    result.slashes = result.slashes || relative.slashes;
    result.href = result.format();
    return result;
  }

  var isSourceAbs = (result.pathname && result.pathname.charAt(0) === '/'),
      isRelAbs = (
          relative.host ||
          relative.pathname && relative.pathname.charAt(0) === '/'
      ),
      mustEndAbs = (isRelAbs || isSourceAbs ||
                    (result.host && relative.pathname)),
      removeAllDots = mustEndAbs,
      srcPath = result.pathname && result.pathname.split('/') || [],
      relPath = relative.pathname && relative.pathname.split('/') || [],
      psychotic = result.protocol && !slashedProtocol[result.protocol];

  // if the url is a non-slashed url, then relative
  // links like ../.. should be able
  // to crawl up to the hostname, as well.  This is strange.
  // result.protocol has already been set by now.
  // Later on, put the first path part into the host field.
  if (psychotic) {
    result.hostname = '';
    result.port = null;
    if (result.host) {
      if (srcPath[0] === '') srcPath[0] = result.host;
      else srcPath.unshift(result.host);
    }
    result.host = '';
    if (relative.protocol) {
      relative.hostname = null;
      relative.port = null;
      if (relative.host) {
        if (relPath[0] === '') relPath[0] = relative.host;
        else relPath.unshift(relative.host);
      }
      relative.host = null;
    }
    mustEndAbs = mustEndAbs && (relPath[0] === '' || srcPath[0] === '');
  }

  if (isRelAbs) {
    // it's absolute.
    result.host = (relative.host || relative.host === '') ?
                  relative.host : result.host;
    result.hostname = (relative.hostname || relative.hostname === '') ?
                      relative.hostname : result.hostname;
    result.search = relative.search;
    result.query = relative.query;
    srcPath = relPath;
    // fall through to the dot-handling below.
  } else if (relPath.length) {
    // it's relative
    // throw away the existing file, and take the new path instead.
    if (!srcPath) srcPath = [];
    srcPath.pop();
    srcPath = srcPath.concat(relPath);
    result.search = relative.search;
    result.query = relative.query;
  } else if (!isNullOrUndefined(relative.search)) {
    // just pull out the search.
    // like href='?foo'.
    // Put this after the other two cases because it simplifies the booleans
    if (psychotic) {
      result.hostname = result.host = srcPath.shift();
      //occationaly the auth can get stuck only in host
      //this especialy happens in cases like
      //url.resolveObject('mailto:local1@domain1', 'local2@domain2')
      var authInHost = result.host && result.host.indexOf('@') > 0 ?
                       result.host.split('@') : false;
      if (authInHost) {
        result.auth = authInHost.shift();
        result.host = result.hostname = authInHost.shift();
      }
    }
    result.search = relative.search;
    result.query = relative.query;
    //to support http.request
    if (!isNull(result.pathname) || !isNull(result.search)) {
      result.path = (result.pathname ? result.pathname : '') +
                    (result.search ? result.search : '');
    }
    result.href = result.format();
    return result;
  }

  if (!srcPath.length) {
    // no path at all.  easy.
    // we've already handled the other stuff above.
    result.pathname = null;
    //to support http.request
    if (result.search) {
      result.path = '/' + result.search;
    } else {
      result.path = null;
    }
    result.href = result.format();
    return result;
  }

  // if a url ENDs in . or .., then it must get a trailing slash.
  // however, if it ends in anything else non-slashy,
  // then it must NOT get a trailing slash.
  var last = srcPath.slice(-1)[0];
  var hasTrailingSlash = (
      (result.host || relative.host) && (last === '.' || last === '..') ||
      last === '');

  // strip single dots, resolve double dots to parent dir
  // if the path tries to go above the root, `up` ends up > 0
  var up = 0;
  for (var i = srcPath.length; i >= 0; i--) {
    last = srcPath[i];
    if (last == '.') {
      srcPath.splice(i, 1);
    } else if (last === '..') {
      srcPath.splice(i, 1);
      up++;
    } else if (up) {
      srcPath.splice(i, 1);
      up--;
    }
  }

  // if the path is allowed to go above the root, restore leading ..s
  if (!mustEndAbs && !removeAllDots) {
    for (; up--; up) {
      srcPath.unshift('..');
    }
  }

  if (mustEndAbs && srcPath[0] !== '' &&
      (!srcPath[0] || srcPath[0].charAt(0) !== '/')) {
    srcPath.unshift('');
  }

  if (hasTrailingSlash && (srcPath.join('/').substr(-1) !== '/')) {
    srcPath.push('');
  }

  var isAbsolute = srcPath[0] === '' ||
      (srcPath[0] && srcPath[0].charAt(0) === '/');

  // put the host back
  if (psychotic) {
    result.hostname = result.host = isAbsolute ? '' :
                                    srcPath.length ? srcPath.shift() : '';
    //occationaly the auth can get stuck only in host
    //this especialy happens in cases like
    //url.resolveObject('mailto:local1@domain1', 'local2@domain2')
    var authInHost = result.host && result.host.indexOf('@') > 0 ?
                     result.host.split('@') : false;
    if (authInHost) {
      result.auth = authInHost.shift();
      result.host = result.hostname = authInHost.shift();
    }
  }

  mustEndAbs = mustEndAbs || (result.host && srcPath.length);

  if (mustEndAbs && !isAbsolute) {
    srcPath.unshift('');
  }

  if (!srcPath.length) {
    result.pathname = null;
    result.path = null;
  } else {
    result.pathname = srcPath.join('/');
  }

  //to support request.http
  if (!isNull(result.pathname) || !isNull(result.search)) {
    result.path = (result.pathname ? result.pathname : '') +
                  (result.search ? result.search : '');
  }
  result.auth = relative.auth || result.auth;
  result.slashes = result.slashes || relative.slashes;
  result.href = result.format();
  return result;
};

Url.prototype.parseHost = function() {
  var host = this.host;
  var port = portPattern.exec(host);
  if (port) {
    port = port[0];
    if (port !== ':') {
      this.port = port.substr(1);
    }
    host = host.substr(0, host.length - port.length);
  }
  if (host) this.hostname = host;
};

function isString(arg) {
  return typeof arg === "string";
}

function isObject(arg) {
  return typeof arg === 'object' && arg !== null;
}

function isNull(arg) {
  return arg === null;
}
function isNullOrUndefined(arg) {
  return  arg == null;
}

},{"punycode":2,"querystring":6}],4:[function(_dereq_,module,exports){
// Copyright Joyent, Inc. and other Node contributors.
//
// Permission is hereby granted, free of charge, to any person obtaining a
// copy of this software and associated documentation files (the
// "Software"), to deal in the Software without restriction, including
// without limitation the rights to use, copy, modify, merge, publish,
// distribute, sublicense, and/or sell copies of the Software, and to permit
// persons to whom the Software is furnished to do so, subject to the
// following conditions:
//
// The above copyright notice and this permission notice shall be included
// in all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
// OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
// MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
// NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
// DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
// OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
// USE OR OTHER DEALINGS IN THE SOFTWARE.

'use strict';

// If obj.hasOwnProperty has been overridden, then calling
// obj.hasOwnProperty(prop) will break.
// See: https://github.com/joyent/node/issues/1707
function hasOwnProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

module.exports = function(qs, sep, eq, options) {
  sep = sep || '&';
  eq = eq || '=';
  var obj = {};

  if (typeof qs !== 'string' || qs.length === 0) {
    return obj;
  }

  var regexp = /\+/g;
  qs = qs.split(sep);

  var maxKeys = 1000;
  if (options && typeof options.maxKeys === 'number') {
    maxKeys = options.maxKeys;
  }

  var len = qs.length;
  // maxKeys <= 0 means that we should not limit keys count
  if (maxKeys > 0 && len > maxKeys) {
    len = maxKeys;
  }

  for (var i = 0; i < len; ++i) {
    var x = qs[i].replace(regexp, '%20'),
        idx = x.indexOf(eq),
        kstr, vstr, k, v;

    if (idx >= 0) {
      kstr = x.substr(0, idx);
      vstr = x.substr(idx + 1);
    } else {
      kstr = x;
      vstr = '';
    }

    k = decodeURIComponent(kstr);
    v = decodeURIComponent(vstr);

    if (!hasOwnProperty(obj, k)) {
      obj[k] = v;
    } else if (isArray(obj[k])) {
      obj[k].push(v);
    } else {
      obj[k] = [obj[k], v];
    }
  }

  return obj;
};

var isArray = Array.isArray || function (xs) {
  return Object.prototype.toString.call(xs) === '[object Array]';
};

},{}],5:[function(_dereq_,module,exports){
// Copyright Joyent, Inc. and other Node contributors.
//
// Permission is hereby granted, free of charge, to any person obtaining a
// copy of this software and associated documentation files (the
// "Software"), to deal in the Software without restriction, including
// without limitation the rights to use, copy, modify, merge, publish,
// distribute, sublicense, and/or sell copies of the Software, and to permit
// persons to whom the Software is furnished to do so, subject to the
// following conditions:
//
// The above copyright notice and this permission notice shall be included
// in all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
// OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
// MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN
// NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM,
// DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR
// OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE
// USE OR OTHER DEALINGS IN THE SOFTWARE.

'use strict';

var stringifyPrimitive = function(v) {
  switch (typeof v) {
    case 'string':
      return v;

    case 'boolean':
      return v ? 'true' : 'false';

    case 'number':
      return isFinite(v) ? v : '';

    default:
      return '';
  }
};

module.exports = function(obj, sep, eq, name) {
  sep = sep || '&';
  eq = eq || '=';
  if (obj === null) {
    obj = undefined;
  }

  if (typeof obj === 'object') {
    return map(objectKeys(obj), function(k) {
      var ks = encodeURIComponent(stringifyPrimitive(k)) + eq;
      if (isArray(obj[k])) {
        return map(obj[k], function(v) {
          return ks + encodeURIComponent(stringifyPrimitive(v));
        }).join(sep);
      } else {
        return ks + encodeURIComponent(stringifyPrimitive(obj[k]));
      }
    }).join(sep);

  }

  if (!name) return '';
  return encodeURIComponent(stringifyPrimitive(name)) + eq +
         encodeURIComponent(stringifyPrimitive(obj));
};

var isArray = Array.isArray || function (xs) {
  return Object.prototype.toString.call(xs) === '[object Array]';
};

function map (xs, f) {
  if (xs.map) return xs.map(f);
  var res = [];
  for (var i = 0; i < xs.length; i++) {
    res.push(f(xs[i], i));
  }
  return res;
}

var objectKeys = Object.keys || function (obj) {
  var res = [];
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) res.push(key);
  }
  return res;
};

},{}],6:[function(_dereq_,module,exports){
'use strict';

exports.decode = exports.parse = _dereq_('./decode');
exports.encode = exports.stringify = _dereq_('./encode');

},{"./decode":4,"./encode":5}],7:[function(_dereq_,module,exports){
'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});
var STATE_AP_KEY = '_AP';

exports.STATE_AP_KEY = STATE_AP_KEY;
var QUERY_KEY_PREFIX = 'ac';
exports.QUERY_KEY_PREFIX = QUERY_KEY_PREFIX;
var QUERY_KEY_DELIMITER = '.';
exports.QUERY_KEY_DELIMITER = QUERY_KEY_DELIMITER;
var ANCHOR_PREFIX = "!";

exports.ANCHOR_PREFIX = ANCHOR_PREFIX;
var HISTORY_POPSTATE = 'history_popstate';
exports.HISTORY_POPSTATE = HISTORY_POPSTATE;
var HISTORY_PUSHSTATE = 'history_pushstate';
exports.HISTORY_PUSHSTATE = HISTORY_PUSHSTATE;
var HISTORY_REPLACESTATE = 'history_replacestate';
exports.HISTORY_REPLACESTATE = HISTORY_REPLACESTATE;
var HISTORY_CHANGESTATE = 'history_changestate';
exports.HISTORY_CHANGESTATE = HISTORY_CHANGESTATE;
},{}],8:[function(_dereq_,module,exports){
'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});
exports.Addon = Addon;
exports.Addons = Addons;
exports.Current = Current;
exports.Change = Change;
exports.State = State;
exports.URL = URL;
exports.Route = Route;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var _url = _dereq_('url');

var _url2 = _interopRequireDefault(_url);

var _utils = _dereq_('./utils');

function Addon(opts) {
  opts = opts || {};
  if (!opts.key) {
    throw new Error('missing key for addon');
  }
  this.key = opts.key;
  this.state = opts.state;
  this.query = opts.query || {};
  return this;
}

function Addons(opts) {
  opts = opts || {};
  opts.models = opts.models || [];
  if (!(opts.models instanceof Array)) {
    opts.models = [opts.models];
  }
  opts.models = opts.models.map(function (model) {
    if (model instanceof Addon) {
      return model;
    } else {
      return new Addon(model);
    }
  });
  this.models = opts.models;
  return this;
}

Addons.prototype.add = function (addon) {
  this.models.push(addon);
};

Addons.prototype.remove = function (addon) {
  this.models.splice(this.models.indexOf(addon), 1);
};

Addons.prototype.find = function (key) {
  return (0, _utils.find)(this.models, function (addon) {
    return addon.key === key;
  });
};

Addons.prototype.merge = function (addon) {
  var found = this.find(addon.key);
  if (found) {
    this.remove(found);
  }
  this.add(addon);
};

function Current(opts) {
  opts = opts || {};
  if (!opts.url) {
    throw new Error('missing url');
  }
  var parsed = _url2['default'].parse(opts.url);
  var addons = new Addons();
  var queryArr = (0, _utils.parseQuery)(parsed.query).map(_utils.stripAddonQueryPrefix);
  var normalizedQueryAddons = (0, _utils.normalizeQueryByAddons)(queryArr);
  var queryAddons = normalizedQueryAddons.filter(function (item) {
    return item.key;
  });
  var queryGlobal = normalizedQueryAddons.filter(function (item) {
    return !item.key;
  });
  queryAddons.forEach(function (item) {
    var created = new Addon(item);
    addons.add(created);
  });
  this.url = new URL({
    url: opts.url,
    addons: addons,
    global: queryGlobal
  });
  this.state = new State(opts.state);
  return this;
}

function Change(opts, addonKey) {
  var type = typeof opts;
  var defaults = _url2['default'].parse(window.location.href);
  var urlObject = {};
  var options = {};
  if (type === 'string') {
    urlObject.protocol = defaults.protocol;
    urlObject.slashes = defaults.slashes;
    urlObject.hostname = defaults.hostname;
    urlObject.pathname = defaults.pathname;
    urlObject.port = defaults.port;
    urlObject.search = defaults.search;
    urlObject.query = defaults.query;
    urlObject.hash = (0, _utils.addHash)((0, _utils.addHashPrefix)(opts));
  } else if (type === 'object') {
    var parsed = opts.href ? _url2['default'].parse(opts.href) : defaults;
    urlObject.protocol = defaults.protocol;
    urlObject.slashes = defaults.slashes;
    urlObject.hostname = defaults.hostname;
    urlObject.port = defaults.port;
    urlObject.pathname = parsed.pathname || defaults.pathname;
    urlObject.search = null;
    urlObject.query = null;
    urlObject.hash = opts.hash ? (0, _utils.addHash)((0, _utils.addHashPrefix)(opts.hash)) : null;
    options.state = opts.state;
    options.query = opts.query;
  } else {
    throw new Error('invalid option type');
  }
  var addonsState = new Addons();
  var addonsURL = new Addons();
  if (options.state) {
    var addonState = new Addon({
      key: addonKey,
      state: options.state
    });
    addonsState.add(addonState);
  }
  if (options.query) {
    var addonURL = new Addon({
      key: addonKey,
      query: options.query
    });
    addonsURL.add(addonURL);
  }
  var changeState = new State({
    addons: addonsState
  });
  var changeURL = new URL({
    url: _url2['default'].format(urlObject),
    addons: addonsURL
  });
  this.state = changeState;
  this.url = changeURL;
  return this;
}

function State(opts) {
  opts = opts || {};
  opts.addons = opts.addons || [];
  if (!(opts.addons instanceof Addons)) {
    opts.addons = new Addons(opts.addons);
  }
  this.addons = opts.addons;
  return this;
}

State.prototype.merge = function (instance) {
  var self = this;
  instance.addons.models.forEach(function (addon) {
    self.addons.merge(addon);
  });
};

State.prototype.render = function () {
  return (0, _utils.deepCopy)(this);
};

function URL(opts) {
  opts = opts || {};
  opts.addons = opts.addons || [];
  opts.global = opts.global || [];
  if (!(opts.parsed instanceof _url2['default'].Url)) {
    opts.parsed = _url2['default'].parse(opts.url);
  }
  if (!(opts.addons instanceof Addons)) {
    opts.addons = new Addons(opts.addons);
  }
  this.parsed = opts.parsed;
  this.addons = opts.addons;
  this.global = opts.global; // global query parameters that are not associated with addons
  this.title = opts.title || window.document.title;
}

URL.prototype.isURLEqual = function (instance) {
  return this.render().url === instance.render().url;
};

URL.prototype.isPathnameEqual = function (instance) {
  var protocolEqual = this.parsed.protocol === instance.parsed.protocol;
  var slashesEqual = this.parsed.slashes === instance.parsed.slashes;
  var hostnameEqual = this.parsed.hostname === instance.parsed.hostname;
  var pathnameEqual = this.parsed.pathname === instance.parsed.pathname;
  return protocolEqual && slashesEqual && hostnameEqual && pathnameEqual;
};

URL.prototype.merge = function (instance) {
  var self = this;
  self.parsed.hash = instance.parsed.hash;
  instance.addons.models.forEach(function (addon) {
    self.addons.merge(addon);
  });
};

URL.prototype.render = function () {
  var deep = (0, _utils.deepCopy)(this);
  var urlObj = deep.parsed;
  var addonsDenormalizedQueryAddons = (0, _utils.denormalizeQueryByAddons)(this.addons.models);
  var globalDenormalizedQueryAddons = (0, _utils.denormalizeQueryByAddons)(this.global);
  var allQueryParams = addonsDenormalizedQueryAddons.concat(globalDenormalizedQueryAddons);
  var prefixedQueryArr = allQueryParams.map(_utils.addAddonQueryPrefix);
  var formattedQuery = (0, _utils.formatQuery)(prefixedQueryArr);
  urlObj.query = formattedQuery;
  urlObj.search = urlObj.query ? '?' + urlObj.query : null;
  deep.url = _url2['default'].format(urlObj);
  return deep;
};

function Route(opts) {
  opts = opts || {};
  if (!opts.url) {
    throw new Error('missing url option');
  }
  if (!(opts.url instanceof URL)) {
    opts.url = new URL(opts.url);
  }
  if (!opts.state) {
    throw new Error('missing state option');
  }
  if (!(opts.state instanceof State)) {
    opts.state = new State(opts.state);
  }
  this.url = opts.url;
  this.state = opts.state;
}

Route.prototype.merge = function (route) {
  this.url.merge(route.url);
  this.state.merge(route.state);
};

Route.prototype.render = function (addonKey) {
  return (0, _utils.deepCopy)({
    key: addonKey,
    hash: (0, _utils.stripHashPrefix)((0, _utils.stripHash)(this.url.parsed.hash)),
    query: this.url.addons.find(addonKey) ? this.url.addons.find(addonKey).query : null,
    title: this.url.title,
    href: this.url.parsed.href,
    state: this.state.addons.find(addonKey) ? this.state.addons.find(addonKey).state : null
  });
};
},{"./utils":9,"url":3}],9:[function(_dereq_,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.find = find;
exports.values = values;
exports.stripHash = stripHash;
exports.stripHashPrefix = stripHashPrefix;
exports.addHash = addHash;
exports.addHashPrefix = addHashPrefix;
exports.splitQueryParam = splitQueryParam;
exports.joinQueryParam = joinQueryParam;
exports.parseQuery = parseQuery;
exports.formatQuery = formatQuery;
exports.stripAddonQueryPrefix = stripAddonQueryPrefix;
exports.addAddonQueryPrefix = addAddonQueryPrefix;
exports.normalizeQueryByAddons = normalizeQueryByAddons;
exports.denormalizeQueryByAddons = denormalizeQueryByAddons;
exports.deepCopy = deepCopy;
exports.wrapState = wrapState;
exports.unwrapState = unwrapState;
exports.createEvent = createEvent;
exports.callConnectHost = callConnectHost;
exports.log = log;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var _constants = _dereq_('./constants');

function find(arr, iterator) {
  for (var i = 0, l = arr.length; i < l; i += 1) {
    if (iterator(arr[i])) {
      return arr[i];
    }
  }
}

function values(obj) {
  var values = [];
  if (!obj) return values;
  for (var key in obj) {
    values.push(obj[key]);
  }
  return values;
}

function stripHash(str) {
  return str ? str.slice(str.search("#") + 1) : null;
}

function stripHashPrefix(text) {
  if (text === undefined || text === null || text === "") {
    return "";
  }
  return text.toString().replace(new RegExp("^" + _constants.ANCHOR_PREFIX), "");
}

function addHash(str) {
  return '#' + str;
}

function addHashPrefix(text) {
  if (text === undefined || text === null) {
    throw "You must supply text to prefix";
  }
  return _constants.ANCHOR_PREFIX + stripHashPrefix(text);
}

function splitQueryParam(paramString) {
  var split = paramString ? paramString.split('=') : [];
  var key = split[0] || null;
  var value = split[1] || null;
  if (split.length === 2) {
    var _key = split[0];
    var _value = split[1];
    return {
      key: _key,
      value: _value
    };
  } else {
    return null;
  }
}

function joinQueryParam(paramObject) {
  if ('key' in paramObject && 'value' in paramObject) {
    return [paramObject.key, '=', paramObject.value].join('');
  } else {
    return null;
  }
}

function parseQuery(queryString) {
  // returns an array of objects
  var params = queryString ? queryString.split('&') : [];
  return params.map(function (paramString) {
    return splitQueryParam(paramString);
  }).filter(function (item) {
    return item;
  });
}

function formatQuery(queryArr) {
  // returns a query string
  var mapped = queryArr.map(function (paramObject) {
    return joinQueryParam(paramObject);
  }).filter(function (item) {
    return item;
  });
  return mapped.length ? mapped.join('&') : null;
}

function stripAddonQueryPrefix(queryObject) {
  var split = queryObject.key ? queryObject.key.split(_constants.QUERY_KEY_DELIMITER) : [];
  var prefix = _constants.QUERY_KEY_PREFIX;
  if (split.length >= 3) {
    if (prefix === split[0]) {
      // be greedy for addonKey instead of queryKey
      var addonKey = split.slice(1, split.length - 1).join(_constants.QUERY_KEY_DELIMITER);
      var queryKey = split.slice(split.length - 1).join(_constants.QUERY_KEY_DELIMITER);
      return {
        addonKey: addonKey,
        key: queryKey,
        value: queryObject.value
      };
    } else {
      return queryObject;
    }
  } else {
    return queryObject;
  }
}

function addAddonQueryPrefix(queryObject) {
  var prefix = _constants.QUERY_KEY_PREFIX;
  if ('addonKey' in queryObject && queryObject.addonKey) {
    var queryKey = [prefix, queryObject.addonKey, queryObject.key].join(_constants.QUERY_KEY_DELIMITER);
    return {
      key: queryKey,
      value: queryObject.value
    };
  } else {
    return queryObject;
  }
}

function normalizeQueryByAddons(queryArr) {
  var addons = [];
  queryArr.forEach(function (query) {
    var key = query.key;
    var value = query.value;
    var addonKey = query.addonKey;
    var filtered = addons.filter(function (addon) {
      return addon.key === addonKey;
    });
    if (filtered && filtered.length) {
      var found = filtered[0];
      found.query[key] = value;
    } else {
      addons.push({
        key: addonKey,
        query: _defineProperty({}, key, value)
      });
    }
  });
  return addons;
}

function denormalizeQueryByAddons(addonArr) {
  var query = [];
  addonArr.forEach(function (addon) {
    var addonKey = addon.key;
    Object.keys(addon.query).forEach(function (key) {
      var value = addon.query[key];
      query.push({
        addonKey: addonKey,
        key: key,
        value: value
      });
    });
  });
  return query;
}

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function wrapState(state) {
  var rootState = {};
  rootState[_constants.STATE_AP_KEY] = state;
  return rootState;
}

function unwrapState(state) {
  return state && state[_constants.STATE_AP_KEY] ? state[_constants.STATE_AP_KEY] : null;
}

function createEvent(eventName) {
  if (typeof Event === 'function') {
    // chrome, firefox, safari
    var evt = new Event(eventName);
    return evt;
  } else {
    var evt = document.createEvent('Event'); // ie11
    evt.initEvent(eventName, false, false);
    return evt;
  }
}

function callConnectHost(cb) {
  if (_dereq_ && _dereq_.amd) {
    _dereq_(['connect-host'], function (connectHost) {
      cb(connectHost);
    });
  } else {
    cb(window.connectHost);
  }
}

function log(toLog, type) {
  if (!type) {
    type = 'log';
  }
  console[type].call(null, 'Atlassian Connect JS History: ', toLog);
}
},{"./constants":7}]},{},[1])(1)
});