import { css } from '@emotion/core';

import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const overflowEllipsis = (maxWidth = 'inherit') => `
  overflow: hidden;
  max-width: ${maxWidth};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const link = css({
  '&, &:hover, &:focus': {
    color: token('color.text', colors.N800),
  },
});

export const subtleLink = css({
  '&, &:hover, &:focus': {
    color: token('color.text.subtlest', colors.N300),
  },
});

export const fixedFlexItem = css({
  display: 'flex',
  flexShrink: 0,
  flexGrow: 0,
});
