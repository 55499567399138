import { get } from 'lodash-es';
import { denormalize } from 'normalizr';
import createCachedSelector from 're-reselect';
import { createSelector, Selector } from 'reselect';

import { uncurlyUuid } from '@atlassian/bitkit-analytics';
import { createDiffPermalink } from '@atlassian/bitkit-diff/exports/diff-permalink';

import {
  CodeReviewConversation,
  FabricConversation,
  ApiComment,
  CommentLikes,
  PlaceholderApiComment,
  isApiComment,
  ApiCommentOrPlaceholder,
} from 'src/components/conversation-provider/types';
import { CommentTree } from 'src/components/conversation/src/components/comment-tree';
import { flattenFiles } from 'src/components/file-list/src/flatten-files';
import { FileEntry } from 'src/components/file-tree';
import { flattenDirectories } from 'src/components/file-tree/src/flatten-directories';
import { removeUrlHashPrefix } from 'src/components/file-tree/utils';
import {
  PullRequest,
  Repository,
  ParticipantStateTypes,
} from 'src/components/types';
import { DiffType } from 'src/components/types/src/pull-request';
import {
  DiffViewMode,
  FileViewMode,
  getGlobalDiffViewMode,
  getGlobalFileViewMode,
  getGlobalShouldIgnoreWhitespace,
} from 'src/redux/diff-settings';
import {
  commentLikes as commentLikesSchema,
  pullRequest as pullRequestSchema,
} from 'src/redux/pull-request/schemas';
import { toNavComment } from 'src/sections/repository/sections/pull-request/components/diff-comment-navigator/to-nav-comment';
import { getIsMobileHeaderActive } from 'src/selectors/global-selectors';
import {
  getCurrentRepositoryOwnerName,
  getCurrentRepositorySlug,
  getRepositoryAccessLevel,
  getRepositoryGrantedAccess,
  getCurrentRepositoryUuid,
  getCurrentRepositoryWorkspaceUUID,
} from 'src/selectors/repository-selectors';
import {
  getRepository,
  getEntities,
} from 'src/selectors/state-slicing-selectors';
import {
  getCurrentUserKey,
  getCurrentUser,
} from 'src/selectors/user-selectors';
import { MergeCheck, Conflict } from 'src/types';
import { DiffStat } from 'src/types/diffstat';
import { Diff } from 'src/types/pull-request';
import { BucketState } from 'src/types/state';
import urls from 'src/urls/source';
import { countAllAddedAndDeletedLines } from 'src/utils/count-diff-lines';
import { diffStatToFileTree } from 'src/utils/diffstat-transformer';
import { extractFilepath } from 'src/utils/extract-file-path';
import { isReviewer, isNotAuthor } from 'src/utils/pull-request-roles';
import { truncateDiffsByOverallLineCount } from 'src/utils/truncate-diffs';

import { DiffStateMap } from '../actions';
import { PullRequestState } from '../reducer';
import { formatAllComments } from '../utils/comments';
import { defaultDiff as defaultDiffProps } from '../utils/default-diff';
import {
  getDiffPath,
  getDiffStatPath,
  DiffStatPathType,
} from '../utils/get-diff-path';

import {
  areLocatorsEqual,
  toPullRequestLocator,
  RouteParams,
} from './locator-utils';

export type PRSelector<T> = Selector<BucketState, T>;

export const getPullRequestSlice: PRSelector<PullRequestState> = createSelector(
  getRepository,
  repository => repository.pullRequest
);

export const getAllRawComments: PRSelector<
  (ApiComment | PlaceholderApiComment)[]
> = createSelector(getPullRequestSlice, prSlice => prSlice.rawComments);

export const getThreadsLatestComment: PRSelector<{
  [key: number | string]: ApiCommentOrPlaceholder;
}> = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.threadsLatestComment
);

export const getDefaultMergeStrategy: PRSelector<string | null | undefined> =
  createSelector(getPullRequestSlice, prSlice => prSlice.defaultMergeStrategy);

export const pullRequestRenderingLimits = {
  singleFileModeThresholdFiles: 200,
  singleFileModeThresholdLines: 8000,
  singleFileModePerFileLimitLines: 3000,
  allFilesModePerFileLimitBytes: 102400,
  singleFileModePerFileLimitBytes: 153600,
  allFilesModePerFileLimitLines: 2000,
  singleFileModeTruncateLines: 20000,
  allFilesModeTruncateLines: 8000,
  // The "truncate files" (file tree) limit should generally be the same for SFM and AFM,
  // but separate limits are available in case different limits are needed in the future.
  singleFileModeTruncateFiles: 999,
  allFilesModeTruncateFiles: 999,
};

export const getDiffsExpansions: PRSelector<DiffStateMap> = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.diffsExpansionState
);

export const getSingleDiffExpansion = createSelector(
  getDiffsExpansions,
  (_: any, filepath: string) => filepath,
  (diffsExpansionState, filepath) => diffsExpansionState[filepath]
);

const getCurrentPullRequestKey: PRSelector<string | null | undefined> =
  createSelector(getPullRequestSlice, prSlice => prSlice.currentPullRequest);

export const getCurrentPullRequest: PRSelector<PullRequest | undefined> =
  createSelector(getCurrentPullRequestKey, getEntities, (key, entities) =>
    denormalize(key, pullRequestSchema, entities)
  );

export const getCurrentPullRequestId = createSelector(
  getCurrentPullRequest,
  currentPullRequest => (currentPullRequest ? currentPullRequest.id : null)
);

export type PullRequestIRAnalyticsAttributes = {
  // if the pr-review-iterative-review FF is enabled
  irEnabled: boolean;
  irSelectedDiffType: string;
  // identifier includes `<workspaceUuid>::<repoUuid>::<prId>`
  prIdentifier: string;
};

export const getIRAnalyticsTrackingAttributes = createSelector(
  getCurrentRepositoryWorkspaceUUID,
  getCurrentRepositoryUuid,
  getCurrentPullRequestId,
  (workspaceUuid, repoUuid, prId): PullRequestIRAnalyticsAttributes => {
    const prIdentifier = `${uncurlyUuid(workspaceUuid || '')}::${uncurlyUuid(
      repoUuid || ''
    )}::${prId || ''}`;

    return {
      irEnabled: true,
      // we expect irSelectedDiffType to be overwritten so this is a placeholder
      irSelectedDiffType: 'NONE',
      prIdentifier,
    };
  }
);

export const getCurrentPullRequestTitle = createSelector(
  getCurrentPullRequest,
  currentPullRequest => (currentPullRequest ? currentPullRequest.title : null)
);

export const getCurrentPullRequestShouldCloseSourceBranch = createSelector(
  getCurrentPullRequest,
  currentPullRequest =>
    currentPullRequest ? currentPullRequest.close_source_branch : false
);

export const getCurrentPullRequestAnnotations = createSelector(
  getPullRequestSlice,
  pullRequest => (pullRequest ? pullRequest.annotations : null)
);

export const getHiddenAnnotations = createSelector(
  getPullRequestSlice,
  pullRequest => (pullRequest ? pullRequest.hiddenAnnotations : [])
);

export const getHiddenDiffComments = createSelector(
  getPullRequestSlice,
  pullRequest => (pullRequest ? pullRequest.hiddenDiffComments : [])
);

export const getCurrentPullRequestAnnotationsForFile = createCachedSelector(
  getCurrentPullRequestAnnotations,
  getHiddenAnnotations,
  (_state: BucketState, filePath: string | undefined) => filePath,
  (annotations, hiddenAnnotations, filePath) =>
    annotations && filePath && !hiddenAnnotations.includes(filePath)
      ? annotations.filter(annotation => annotation.path === filePath)
      : undefined
)((_state, filePath) => filePath || '');

export const getCurrentCodeInsightsReports = createSelector(
  getPullRequestSlice,
  pullRequest =>
    pullRequest
      ? Array.isArray(pullRequest.codeInsightsReports)
        ? pullRequest.codeInsightsReports
        : []
      : []
);

export const getCurrentCodeInsightsReportsLoadingState = createSelector(
  getPullRequestSlice,
  pullRequest =>
    pullRequest ? pullRequest.isCodeInsightsReportsLoading : false
);

export const getCanCreatePendingMerge = createSelector(
  getPullRequestSlice,
  state => state.merge.canCreatePendingMerge
);

export const getHasCodeInsightsReportsError = createSelector(
  getPullRequestSlice,
  pullRequest =>
    pullRequest ? !!pullRequest.hasCodeInsightsReportsError : false
);

export const getIsIterativeReviewComplete = createSelector(
  getPullRequestSlice,
  pullRequest =>
    pullRequest ? pullRequest.iterativeReview.isIterativeReviewComplete : false
);

export const getCurrentCodeInsightsAnnotations = createSelector(
  getPullRequestSlice,
  pullRequest => (pullRequest ? pullRequest.codeInsightsAnnotations || {} : {})
);

export const getCurrentCodeInsightsAnnotationsLoadingState = createSelector(
  getPullRequestSlice,
  pullRequest =>
    pullRequest ? pullRequest.isCodeInsightsAnnotationsLoading : false
);

export const getIsCurrentPullRequestOpen: PRSelector<boolean> = createSelector(
  getCurrentPullRequest,
  currentPullRequest => currentPullRequest?.state === 'OPEN'
);

export const getCurrentPullRequestAuthor = createSelector(
  getCurrentPullRequest,
  currentPullRequest => (currentPullRequest ? currentPullRequest.author : null)
);

export const getCurrentPullRequestDiffType = createSelector(
  getCurrentPullRequest,
  currentPullRequest => currentPullRequest?.diff_type ?? null
);

export const getCurrentPullRequestInTopicDiffMode = createSelector(
  getCurrentPullRequestDiffType,
  diffType => diffType === DiffType.TOPIC_DIFF
);

export const getCurrentPullRequestInPreviewDiffMode = createSelector(
  getCurrentPullRequestDiffType,
  diffType => diffType === DiffType.PREVIEW_MERGE_DIFF
);

export const getIsCurrentPullRequestAuthor = createSelector(
  getCurrentPullRequestAuthor,
  getCurrentUserKey,
  (author, userUuid) => author?.uuid === userUuid
);

export const getCurrentPullRequestParticipants = createSelector(
  getCurrentPullRequest,
  pullRequest => (pullRequest ? pullRequest.participants || [] : [])
);

export const getCurrentPullRequestReviewers = createSelector(
  getCurrentPullRequestAuthor,
  getCurrentPullRequestParticipants,
  (author, participants) =>
    participants.filter(
      participant => isReviewer(participant) && isNotAuthor(participant, author)
    )
);

export const getCurrentPullRequestApprovers = createSelector(
  getCurrentPullRequestAuthor,
  getCurrentPullRequestParticipants,
  (author, participants) => {
    const reviewers = participants.filter(
      participant => isReviewer(participant) && isNotAuthor(participant, author)
    );
    return reviewers.filter(reviewer => reviewer.approved);
  }
);

export const getCurrentPullRequestRoleForCurrentUser = createSelector(
  getCurrentPullRequestAuthor,
  getCurrentPullRequestReviewers,
  getCurrentUser,
  (author, reviewers, currentUser) => {
    if (!currentUser?.account_id || !author?.account_id) {
      return 'WATCHER';
    } else if (currentUser.account_id === author?.account_id) {
      return 'AUTHOR';
    } else if (
      reviewers
        .map(reviewer => reviewer.user?.account_id)
        .includes(currentUser.account_id)
    ) {
      return 'REVIEWER';
    }
    return 'WATCHER';
  }
);

export const getIsCurrentPullRequestChangesRequested = createSelector(
  getCurrentPullRequestAuthor,
  getCurrentPullRequestParticipants,
  (author, participants) => {
    return participants.some(
      participant =>
        isReviewer(participant) &&
        isNotAuthor(participant, author) &&
        participant.state === ParticipantStateTypes.changesRequested
    );
  }
);

export const getCurrentPullRequestRequestChangesSlice = createSelector(
  getPullRequestSlice,
  pullRequest => {
    return {
      isRequestingChangesDisabled: pullRequest.isRequestingChangesDisabled,
      isRequestingChangesError: pullRequest.isRequestingChangesError,
      isRequestingChangesPending: pullRequest.isRequestingChangesPending,
    };
  }
);

export const getIsChangesRequested = createSelector(
  getCurrentPullRequestParticipants,
  getCurrentUserKey,
  (participants, userUuid) => {
    const foundParticipant = participants.find(
      participant => participant.user?.uuid === userUuid
    );
    return foundParticipant?.state === ParticipantStateTypes.changesRequested;
  }
);

export const getHasCurrentUserApprovedPr = createSelector(
  getCurrentPullRequestParticipants,
  getCurrentUserKey,
  (participants, userUuid) => {
    const foundParticipant = participants.find(
      participant => participant.user?.uuid === userUuid
    );
    return (
      foundParticipant?.approved ||
      foundParticipant?.state === ParticipantStateTypes.approved
    );
  }
);

export const getLastPullRequestRetrievalTime = (state: BucketState) =>
  state.repository.pullRequest.lastPullRequestRetrieved;

export const getCurrentPullRequestError = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.errorMessage
);

export const getCurrentPullRequestCreatedOnDate = createSelector(
  getCurrentPullRequest,
  currentPullRequest =>
    currentPullRequest ? currentPullRequest.created_on : null
);

export const getCurrentPullRequestUrlPieces = createSelector(
  getCurrentPullRequest,
  pullRequest => {
    const FALLBACK_REPO = { full_name: '/', owner: { uuid: undefined } };
    const destRepo: Repository = get(
      pullRequest,
      'destination.repository',
      // This repo object has so many nullable fields to traverse
      FALLBACK_REPO as unknown as Repository
    );
    const ownerUuid = destRepo.owner ? destRepo.owner.uuid : undefined;

    return {
      owner: destRepo.full_name.split('/')[0],
      ownerUuid,
      slug: destRepo.full_name.split('/')[1],
      repoUuid: destRepo.uuid,
      id: pullRequest ? pullRequest.id : undefined,
    };
  }
);

export const getPullRequestSourceRepo = createSelector(
  getCurrentPullRequest,
  pullRequest => pullRequest?.source?.repository || null
);

export const getPullRequestSourceRepoUuid = createSelector(
  getPullRequestSourceRepo,
  sourceRepo => sourceRepo?.uuid || undefined
);

export const getPullRequestDestinationRepo = createSelector(
  getCurrentPullRequest,
  pullRequest => pullRequest?.destination?.repository || null
);

export const getPullRequestDestinationRepoFullSlug = createSelector(
  getPullRequestDestinationRepo,
  repository => repository?.full_name
);

export const getPullRequestDestinationRepoUuid = createSelector(
  getPullRequestDestinationRepo,
  repository => repository?.uuid
);

export const getPullRequestSourceHash: PRSelector<string | null> =
  createSelector(getCurrentPullRequest, pullRequest =>
    get(pullRequest, 'source.commit.hash', null)
  );

export const getCodeInsightsDiscoveryUrl = createSelector(
  getCurrentPullRequest,
  pullRequest =>
    `${urls.ui.edit(
      get(pullRequest, 'source.repository.full_name', null),
      get(pullRequest, 'source.commit.hash', null),
      'bitbucket-pipelines.yml',
      { at: { name: get(pullRequest, 'source.branch.name', null) } }
    )}&showCodeInsightsPipes=true`
);

export const getCodeInsightsAnnotationUrl = createSelector(
  getCurrentPullRequest,
  pullRequest => (path: string) =>
    urls.ui.source(pullRequest?.source?.repository?.full_name || '', {
      refOrHash: pullRequest?.source?.commit?.hash,
      path,
      at: pullRequest?.source?.branch?.name,
    })
);

export const getPipelinesOnboardingUrl = createSelector(
  getCurrentPullRequest,
  pullRequest =>
    `/${get(pullRequest, 'source.repository.full_name', null)}` +
    `/addon/pipelines-installer/home#!/?from=prPage`
);

export const getPullRequestDestinationHash: PRSelector<string | null> =
  createSelector(getCurrentPullRequest, pullRequest =>
    get(pullRequest, 'destination.commit.hash', null)
  );

export const getCurrentPullRequestSpec = createSelector(
  getPullRequestSourceHash,
  getPullRequestDestinationHash,
  (sourceHash, destinationHash) => {
    if (!sourceHash || !destinationHash) {
      return null;
    }
    return `${sourceHash}..${destinationHash}`;
  }
);

export const getPullRequestBranchName = createSelector(
  getCurrentPullRequest,
  pullRequest => (pullRequest ? pullRequest.source.branch.name : '')
);

export const getDestinationBranchName = createSelector(
  getCurrentPullRequest,
  pullRequest => (pullRequest ? pullRequest.destination.branch.name : '')
);

export const getPullRequestCompareSpecDiffUrl = createSelector(
  getCurrentPullRequest,
  pullRequest => (pullRequest ? pullRequest.links.diff.href : undefined)
);

export const getPullRequestCompareSpecDiffStatUrl = createSelector(
  getCurrentPullRequest,
  pullRequest => (pullRequest ? pullRequest.links.diffstat.href : undefined)
);

export const getPullRequestDescription = createSelector(
  getCurrentPullRequest,
  pullRequest => (pullRequest ? pullRequest.rendered.description.html : '')
);

export const getIsDifferentRepo = createSelector(
  getPullRequestSourceRepo,
  getPullRequestDestinationRepo,
  (sourceRepo, destinationRepo) => {
    const source = get(sourceRepo, 'uuid');
    const destination = get(destinationRepo, 'uuid');

    return !source || !destination || source !== destination;
  }
);

export const getHighlighted = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.highlightedCommentId
);

export const getHighlightCommentThread = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.highlightCommentThread
);

export const getIsSingleFileModeActive = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isSingleFileMode
);

export const getIsSingleFileModeEligible = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isSingleFileModeEligible
);

export const getIsSingleFileModeSettingsHeaderVisible = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isSingleFileModeSettingsHeaderVisible
);

export const getIsStickyHeaderActive = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isStickyHeaderActive
);

export const getConflicts = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.conflicts
);

export const getConflictCount = createSelector(
  getConflicts,
  conflicts => conflicts.length
);

export const getConflictStatus = createSelector(
  getConflicts,
  conflicts => conflicts.length > 0
);

export const getIsAsyncMergeInProgress = createSelector(
  getPullRequestSlice,
  state => state.merge.isAsyncMergeInProgress || !!state.mergeInProgress
);

export const getInProgressMergeTask = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.mergeInProgress
);

export const getPullRequestMergeChecks = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.mergeChecks
);

export const getPullRequestMergeChecksIsMergeable = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isMergeable
);

export const getPullRequestCanCurrentUserMerge = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.canCurrentUserMerge
);

export const getPullRequestIsMissingCommits = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isMissingCommits
);

export const getPullRequestIsUpdatingCommits = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isUpdatingCommits
);

export const getPullRequestHasMissingCommitsError = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.hasMissingCommitsError
);

export const getCountFailedPullRequestMergeChecks = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.mergeChecks.filter((item: MergeCheck) => !item.pass).length
);

export const getPullRequestApprovalsMergeCheck = createSelector(
  getPullRequestSlice,
  prSlice =>
    prSlice.mergeChecks.filter(
      (item: MergeCheck) => item.key === 'minimum_approvals'
    )[0]
);

export const getPullRequestSuccessfulBuildsMergeCheck = createSelector(
  getPullRequestSlice,
  prSlice =>
    prSlice.mergeChecks.filter(
      (item: MergeCheck) => item.key === 'minimum_successful_builds'
    )[0]
);

export const getIsEnforcedMergeChecksEnabled = createSelector(
  getPullRequestMergeChecks,
  mergeChecks =>
    mergeChecks.length > 0 &&
    mergeChecks.every(mergeCheck => !mergeCheck.allow_merge)
);

export const getIsPullRequestMergeChecksPassed = createSelector(
  getPullRequestMergeChecks,
  getConflicts,
  (mergeChecks, conflicts) =>
    conflicts.length === 0 &&
    mergeChecks.length > 0 &&
    mergeChecks.every(mergeCheck => mergeCheck.pass)
);

export const getPullRequestIsMergeable = createSelector(
  getPullRequestMergeChecks,
  getConflicts,
  getIsAsyncMergeInProgress,
  getPullRequestIsMissingCommits,
  (mergeChecks, conflicts, isAsyncMergeInProgress, isMissingCommits) =>
    conflicts.length === 0 &&
    mergeChecks.every(mergeCheck => mergeCheck.pass) &&
    !isAsyncMergeInProgress &&
    !isMissingCommits
);

export const getIsPullRequestReadyToUsePendingMerge = createSelector(
  getCountFailedPullRequestMergeChecks,
  getPullRequestSuccessfulBuildsMergeCheck,
  (countFailedPullRequestMergeChecks, pullRequestSuccessfulBuildsMergeCheck) =>
    countFailedPullRequestMergeChecks === 1 &&
    pullRequestSuccessfulBuildsMergeCheck &&
    !pullRequestSuccessfulBuildsMergeCheck.pass
);

export const getPullRequestMergeChecksError = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.mergeChecksError
);

export const getIsPullRequestMergeChecksSourceFrozen = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.mergeChecksSource === 'frozen'
);

export const getIsMergeChecklistLoading = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isMergeChecksLoading && !prSlice.mergeChecks.length
);

export const getPullRequestDiff = createSelector(
  getPullRequestSlice,
  pullRequest => pullRequest.diff
);

export const getPullRequestDiffFileCount = createSelector(
  getPullRequestDiff,
  diff => diff.length
);

export const getPullRequestDiffLinesCount = createSelector(
  getPullRequestDiff,
  (diff: Diff[]) => countAllAddedAndDeletedLines(diff)
);

export const getDiffStat = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.diffStat
);

export const getFileDiffStatus = createSelector(
  getDiffStat,
  (_: any, path: string | null) => path,
  (statuses, path: string | null) =>
    statuses?.filter(status => status.new?.path === path)[0]?.status
);

export const getUntruncatedPullRequestDiffFileCount = createSelector(
  getDiffStat,
  diffStat => diffStat?.length || 0
);

const getFileTruncatedDiffStat: Selector<BucketState, DiffStat[] | null> =
  createSelector(
    getDiffStat,
    getIsSingleFileModeActive,
    (diffStats, isSingleFileModeActive) => {
      if (!diffStats) {
        return null;
      }
      return diffStats.slice(
        0,
        isSingleFileModeActive
          ? pullRequestRenderingLimits.singleFileModeTruncateFiles
          : pullRequestRenderingLimits.allFilesModeTruncateFiles
      );
    }
  );

export const getDiffStatErrorKey = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.diffStatErrorKey
);

export const getDiffErrorCode = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.diffErrorCode
);

export const getDiffStatErrorCode = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.diffStatErrorCode
);

export const getActivePermalink = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.activePermalink
);

const getFileAndLineTruncatedPullRequestDiff = createSelector(
  getFileTruncatedDiffStat,
  getPullRequestDiff,
  getIsSingleFileModeActive,
  (diffStats, diffs, isSingleFileModeActive) => {
    if (!diffStats || diffStats.length === 0) {
      return [];
    }

    /**
     * The diffstat is the base source of truth for files that will be rendered in the PR
     * because when "ignore whitespace" is enabled, files with whitespace-only changes will
     * not be present in the full diff.
     *
     * The rendered files might be further filtered based on the overall # of lines, which we
     * calculate below.
     */
    const filteredDiffStats = diffStats.map(stat =>
      getDiffStatPath(stat, DiffStatPathType.Escaped)
    );
    const filteredDiffs = diffs.filter(
      diff => filteredDiffStats.indexOf(getDiffPath(diff)) !== -1
    );

    return truncateDiffsByOverallLineCount(
      filteredDiffs,
      isSingleFileModeActive,
      pullRequestRenderingLimits
    );
  }
);

export const getRenderedDiffStat = createSelector(
  getPullRequestDiff,
  getFileAndLineTruncatedPullRequestDiff,
  getFileTruncatedDiffStat,
  getIsSingleFileModeActive,
  (allDiffs, diffs, diffStats, isSingleFileModeActive) => {
    if (!diffStats || diffStats.length === 0) {
      return [];
    }

    /**
     * If there are files in the truncated diffstat but not the truncated diff, there are 2 possibilities
     *
     * A) The remaining files present all have whitespace-only changes and "ignore whitespace" is enabled.
     *    In this case, we want to return the diffstat without further changes.
     *
     * B) The 1st file in the diff is rendered, but exceeds the limit on the # of lines for the entire diff.
     *    This should not be possible. The per-file limit should always be at or below the entire diff limit.
     */
    if (diffs.length === 0) {
      return diffStats;
    }

    // If we didn't truncate the diff due to the # of lines, just return the full diffstat to avoid
    // accidentally dropping files with whitespace-only changes if they're at the end of the diff
    if (diffs.length === allDiffs.length) {
      return diffStats;
    }

    // For single file mode, we don't want the file tree truncation point to depend on how much of
    // the diff is visible.
    if (isSingleFileModeActive) {
      return diffStats; // truncation is already done in getFileTruncatedDiffStat
    }

    /**
     * Find the path of the last file diff that we render, taking our client-side limits
     * into account, and only return entries in the diffstat up to that file.
     *
     * We can't just filter the diffstat by paths present in the the full diff because when
     * "ignore whitespace" is enabled, files with whitespace-only changes will not be present
     * in the full diff.
     */
    const limitBreakingDiffIndex = diffs.length;
    // This is the path of the 1st file that's not included in the truncated diff
    const limitBreakingDiffPath = getDiffPath(allDiffs[limitBreakingDiffIndex]);

    const cutoffIndex = diffStats
      .map(stat => getDiffStatPath(stat, DiffStatPathType.Escaped))
      .indexOf(limitBreakingDiffPath);

    // If a file in the diff isn't found in the diffstat, something is wrong.
    // Fall back to returning an unfiltered diffstat
    if (cutoffIndex === -1) {
      return diffStats;
    }

    // Return everything up to (but not including) the file that went over the
    // diff limit for # of lines
    return diffStats.slice(0, cutoffIndex);
  }
);

export const getHiddenFileDialogAnchor: PRSelector<string | null> =
  createSelector(
    getPullRequestSlice,
    prSlice => prSlice.hiddenFileDialogAnchor
  );

export const getFilterCommentsByUser: PRSelector<string | null> =
  createSelector(getPullRequestSlice, prSlice => prSlice.filterCommentsByUser);

export const getIsCommentsFilterActive: PRSelector<boolean> = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isCommentsFilterActive
);

// takes in a value and returns true if the value is the
// currently selected comment filter
export const getIsCurrentSelectedFilter = createSelector(
  getIsCommentsFilterActive,
  getFilterCommentsByUser,
  (_: any, passedArg: null | BB.User['uuid']) => passedArg,
  (isCommentFilterActive, filterUserUuid, valueArg) => {
    return isCommentFilterActive && filterUserUuid === valueArg;
  }
);

export const getInlineComments: PRSelector<
  (ApiComment | PlaceholderApiComment)[]
> = createSelector(getAllRawComments, rawComments => {
  return rawComments.filter(c => (!isApiComment(c) || !c.deleted) && c.inline);
});

export const getAllPendingComments: PRSelector<
  (ApiComment | PlaceholderApiComment)[]
> = createSelector(getAllRawComments, rawComments => {
  return rawComments.filter(c => (!isApiComment(c) || !c.deleted) && c.pending);
});

export const getNumOutdatedPendingComments: PRSelector<number> = createSelector(
  getAllPendingComments,
  pendingComments => {
    return pendingComments.filter(c => c.inline && c.inline.outdated).length;
  }
);

export const getIsCurrentPullRequestMerged: PRSelector<boolean> =
  createSelector(
    getCurrentPullRequest,
    currentPullRequest => currentPullRequest?.state === 'MERGED'
  );

const getDiffSort = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.diffSort
);

function hasFilteredComment(comments: ApiComment[], userUuid: string | null) {
  const matchesUserFilter = (c: ApiComment, userId: null | string) =>
    userId ? c.user.uuid === userId : true;

  // returns true if comment modified the diff file path
  const matchesDiffPath = (c: ApiComment, diff: DiffStat) =>
    c.inline?.path === getDiffStatPath(diff, DiffStatPathType.Unescaped);

  // return a function to pass to .filter()
  // Return true if the diff should be shown
  return (stat: DiffStat) => {
    return !!comments.find(
      c => matchesUserFilter(c, userUuid) && matchesDiffPath(c, stat)
    );
  };
}

const sortByNewestComments = (inlineComments: ApiComment[]) => {
  // sort in descending order; newest comments are first
  const commentsByDate = inlineComments.sort((a, b) =>
    b.created_on.localeCompare(a.created_on)
  );
  const lastCommentDate = (diffStat: DiffStat): string => {
    const newestComment = commentsByDate.find(
      c =>
        c.inline?.path === getDiffStatPath(diffStat, DiffStatPathType.Unescaped)
    );
    return newestComment ? newestComment.created_on : '';
  };

  return (a: DiffStat, b: DiffStat) =>
    lastCommentDate(b).localeCompare(lastCommentDate(a));
};

// If comment filter is applied this will filter out any
// diff stats that don't match the filter selected.
// This also sorts the diff stat by path alpha or by latest comment.
export const getFilteredDiffStat = createSelector(
  getRenderedDiffStat,
  getInlineComments,
  getIsCommentsFilterActive,
  getFilterCommentsByUser,
  getDiffSort,
  getGlobalFileViewMode,
  (
    diffStat: DiffStat[],
    inlineComments: ApiComment[],
    isCommentsFilterActive: boolean,
    userUuid: string | null,
    diffSort,
    fileViewMode
  ) => {
    if (!diffStat) {
      return diffStat;
    }

    const filteredDiffStat = isCommentsFilterActive
      ? diffStat.filter(hasFilteredComment(inlineComments, userUuid))
      : diffStat.slice();

    if (diffSort === 'latestComments') {
      // sorting by comment order
      filteredDiffStat.sort(sortByNewestComments(inlineComments));
      return filteredDiffStat;
    }

    if (fileViewMode === FileViewMode.List) {
      // sorting by file list order
      const flattenedFileTree =
        diffStatToFileTree(filteredDiffStat).map(flattenDirectories);
      const flattenedFiles = flattenFiles(flattenedFileTree).flatMap(
        dirEntry => dirEntry.contents
      );

      // sort diffStat by file list order
      const sortedFilteredDiffStat: DiffStat[] = [];
      flattenedFiles.forEach(fileEntry => {
        // exclude prefix "#chg-" when comparing path in file href as diffstat path doesn't have it
        const matchedDiffStat = filteredDiffStat.find(
          diffStatEntry =>
            getDiffStatPath(diffStatEntry, DiffStatPathType.Unescaped) ===
            removeUrlHashPrefix((fileEntry as FileEntry).href)
        );
        if (matchedDiffStat) {
          sortedFilteredDiffStat.push(matchedDiffStat);
        }
      });
      return sortedFilteredDiffStat;
    } else {
      const sortedFilteredDiffStat: DiffStat[] = filteredDiffStat
        .slice()
        .sort((a: DiffStat, b: DiffStat) => {
          const aPath = getDiffStatPath(a, DiffStatPathType.Unescaped);
          const bPath = getDiffStatPath(b, DiffStatPathType.Unescaped);

          // Put root files first
          if (aPath.includes('/') && !bPath.includes('/')) {
            return 1;
          }

          if (!aPath.includes('/') && bPath.includes('/')) {
            return -1;
          }

          // Otherwise, alpha sorting
          return aPath.localeCompare(bPath);
        });
      return sortedFilteredDiffStat;
    }
  }
);

export const getActiveDiff = createSelector(
  getPullRequestSlice,
  getFilteredDiffStat,
  getFileAndLineTruncatedPullRequestDiff,
  (
    pullRequest: PullRequestState,
    diffStat: DiffStat[] = [],
    diffs: Diff[] = []
  ) => {
    if (pullRequest.activeDiff) return pullRequest.activeDiff;
    if (!diffStat.length || !diffs.length) return '';

    const diffPaths = new Set(diffs.map(diff => getDiffPath(diff)));

    // Pick the first file from the diffStat that also exists in the diff (we
    // may only have a partial diff)
    for (const file of diffStat) {
      const filepath = getDiffStatPath(file, DiffStatPathType.Unescaped);
      if (diffPaths.has(filepath)) {
        return createDiffPermalink(extractFilepath(file));
      }
    }
    return '';
  }
);

// For the buildFiles() selector we only need the activeDiff value when the PR
// is being viewed in single file mode. When *not* in single file mode, the
// activeDiff can be updated by scrolling the page, which can lead to the
// entire diff re-rendering due to activeDiff invalidating the caching of
// the selectors passed to buildFiles().
export const getSingleFileModeActiveDiff = createSelector(
  getIsSingleFileModeActive,
  getActiveDiff,
  (isSingleFileModeActive: boolean, activeDiff: string) =>
    isSingleFileModeActive ? activeDiff : undefined
);

export const getRenderedPullRequestDiff = createSelector(
  getPullRequestDiff,
  getFileAndLineTruncatedPullRequestDiff,
  getDiffStat,
  getIsSingleFileModeActive,
  getSingleFileModeActiveDiff,
  (diffs, limitedDiffs, diffStats, isSingleFileModeActive, activeDiff) => {
    const relevantDiffs = !isSingleFileModeActive ? limitedDiffs : diffs;
    if (isSingleFileModeActive && activeDiff) {
      if (!diffStats) {
        return [];
      }
      const singleDiffStat = diffStats.find(
        stat =>
          createDiffPermalink(
            getDiffStatPath(stat, DiffStatPathType.Unescaped)
          ) === activeDiff
      );
      if (singleDiffStat) {
        const singleDiff = relevantDiffs.find(
          diff =>
            getDiffPath(diff) ===
            getDiffStatPath(singleDiffStat, DiffStatPathType.Escaped)
        );
        if (singleDiff) {
          return [singleDiff];
        }
      }
      return [];
    }
    return relevantDiffs;
  }
);

export const getPullRequestDiffRenderedFileCount = createSelector(
  getRenderedPullRequestDiff,
  diff => diff.length
);

export const getPullRequestDiffRenderedLinesCount = createSelector(
  getRenderedPullRequestDiff,
  (diff: Diff[]) => countAllAddedAndDeletedLines(diff)
);

export const getIsPullRequestTruncated = createSelector(
  getDiffStat,
  getRenderedDiffStat,
  (diffStats, limitedDiffStats) => {
    if (!diffStats || !limitedDiffStats) {
      return false;
    }
    return limitedDiffStats.length < diffStats.length;
  }
);

const buildDiffFiles = (
  diffs: Diff[],
  diffStats: DiffStat[],
  conflicts: Conflict[],
  shouldIgnoreWhitespace: boolean,
  isSingleFileModeActive: boolean,
  singleFileModeActiveDiff: string
): Diff[] => {
  if (!diffStats || diffStats.length === 0) {
    return [];
  }
  if (isSingleFileModeActive && !singleFileModeActiveDiff) {
    return [];
  }
  const singleFileModeDiffStat =
    isSingleFileModeActive &&
    diffStats.find(
      diffStat =>
        createDiffPermalink(
          getDiffStatPath(diffStat, DiffStatPathType.Unescaped)
        ) === singleFileModeActiveDiff
    );
  const relevantDiffStats = singleFileModeDiffStat
    ? [singleFileModeDiffStat]
    : diffStats;

  return relevantDiffStats.map((diffStat: DiffStat) => {
    const aConflict =
      conflicts &&
      conflicts.find(
        (conflict: Conflict) =>
          conflict.path ===
          getDiffStatPath(diffStat, DiffStatPathType.Unescaped)
      );

    let matchingDiff =
      diffs &&
      diffs.find((diff: Diff) => {
        return (
          // Diffs have escaped file paths so we need to match
          // diff w/ diffstat using the unescaped diffstat path
          getDiffStatPath(diffStat, DiffStatPathType.Escaped) ===
          getDiffPath(diff)
        );
      });

    if (matchingDiff) {
      matchingDiff = {
        ...matchingDiff,
        // We want to get the `from` and `to` values from the diffstat
        // because the diff can have escaping on certain file paths (e.g.
        // paths with tabs, emojis, etc. in them). The diffstat always gives
        // use the unescaped paths which we want to use from this point
        // forward for things such as posting comments, permalinking,
        // file tree, file headers, etc.
        from: diffStat.old?.path || '/dev/null',
        to: diffStat.new?.path || '/dev/null',
      };
    }

    const defaultDiff: Diff = {
      ...defaultDiffProps,
      fileDiffStatus: diffStat.status,
      from: diffStat.old?.path || '/dev/null',
      to: diffStat.new?.path || '/dev/null',
    };
    // ============================
    // WHITESPACE ONLY DIFF
    // If we are ignoring whitespace, the diff endpoint will return
    // nothing for files that only have whitespace changes.
    // In order to build out a dummy diff placeholder in the UI
    // we need to to find the whitespace-only files and stub out a 'diff'
    // ============================
    return {
      ...defaultDiff,
      // Including the lines added and removed from the diff stat helps us
      // identify whether the file _should_ have been found in the diff
      // (e.g. it was a partial diff)
      additions: diffStat.lines_added,
      deletions: diffStat.lines_removed,
      ...matchingDiff,
      ...(aConflict && { isConflicted: true }),
      ...(aConflict && { conflictMessage: aConflict.message }),
      isFileContentsUnchanged: !!shouldIgnoreWhitespace && !matchingDiff,
    };
  });
};

export const getAllDiffFiles: PRSelector<Diff[]> = createSelector(
  getPullRequestDiff,
  getDiffStat,
  getConflicts,
  getGlobalShouldIgnoreWhitespace,
  () => false, // when calculating all diff files, single file mode is not relevant
  getSingleFileModeActiveDiff,
  buildDiffFiles
);

// This only retrieves the diff files that are going to be rendered in the PR,
// taking client-side limits and single file mode into account
// TODO: getDiffFiles needs to include which commentFilter is applied
export const getDiffFiles: PRSelector<Diff[]> = createSelector(
  getRenderedPullRequestDiff,
  getFilteredDiffStat,
  getConflicts,
  getGlobalShouldIgnoreWhitespace,
  getIsSingleFileModeActive,
  getSingleFileModeActiveDiff,
  buildDiffFiles
);

export const getWatchActionLoading = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.watchActionLoading
);

export const getWatch = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isWatching
);

const getPullRequestPolling = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.polling
);

export const getLastPollTime = createSelector(
  getPullRequestPolling,
  prPolling => prPolling.lastPollTime
);

export const getUpdateNeeds = createSelector(
  getPullRequestPolling,
  prPolling => ({
    ...prPolling,
    lastPollTime: undefined,
  })
);

export const getOutdatedCommentsDialogFilepath = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.outdatedCommentsDialog
);

export const getBaseRevCommentsDialogFilepath = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.baseRevCommentsDialog
);

export const getDiffCommentsDialogFilepath = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.diffCommentsDialog
);

export const getNonRenderedDiffCommentsDialogFilepath = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.nonRenderedDiffCommentsDialogFilepath
);

// @ts-ignore TODO: fix noImplicitAny error here
export const findFileInDiffFiles = (diffFiles, filepath) => {
  const files = (diffFiles || []).filter(
    // @ts-ignore TODO: fix noImplicitAny error here
    file => extractFilepath(file) === filepath
  );

  return files[0];
};

export const getIsFileRemovedFromDiff = createCachedSelector(
  getDiffStat,
  (_state: BucketState, filePath: string | undefined) => filePath,
  (diffStat, filePath) => !!filePath && !findFileInDiffFiles(diffStat, filePath)
)((_state, filePath) => filePath || '');

export const getIsFileHiddenFromDiff = createCachedSelector(
  getRenderedDiffStat,
  getIsFileRemovedFromDiff,
  (_state: BucketState, filePath: string | undefined) => filePath,
  (renderedDiffStat, isFileRemovedFromDiff, filePath) =>
    !!filePath &&
    !isFileRemovedFromDiff &&
    !findFileInDiffFiles(renderedDiffStat, filePath)
)((_state, filePath) => filePath || '');

export const getOutdatedCommentsDialogFile = createSelector(
  getDiffFiles,
  getOutdatedCommentsDialogFilepath,
  (diffFiles, filepath) => {
    const outdatedDialogFile = findFileInDiffFiles(diffFiles, filepath);
    if (outdatedDialogFile) {
      return { file: outdatedDialogFile, isFileOutdated: false };
    }

    // If the requested file isn't in the diff then we will construct a basic
    // "outdated" file that has enough information to fetch the context lines.
    return {
      file: {
        headers: [],
        chunks: [],
        fileDiffStatus: null,
        from: filepath,
        to: filepath,
      },
      isFileOutdated: true,
    };
  }
);

export const getCslrCommentsDialogFile = createSelector(
  getDiffFiles,
  getBaseRevCommentsDialogFilepath,
  (diffFiles, filepath) => {
    const cslrDialogFile = findFileInDiffFiles(diffFiles, filepath);
    if (cslrDialogFile) {
      return { file: cslrDialogFile, isFileOutdated: false };
    }

    // If the requested file isn't in the diff then we will construct a basic
    // "outdated" file that has enough information to fetch the context lines.
    return {
      file: {
        headers: [],
        chunks: [],
        fileDiffStatus: null,
        from: filepath,
        to: filepath,
      },
      isFileOutdated: true,
    };
  }
);

export const getDiffCommentsDialogFile = createSelector(
  getDiffFiles,
  getDiffCommentsDialogFilepath,
  (diffFiles, filepath) => findFileInDiffFiles(diffFiles, filepath)
);

export const getNonRenderedDiffCommentsDialogFile = createSelector(
  getAllDiffFiles,
  getNonRenderedDiffCommentsDialogFilepath,
  (diffFiles, filepath) => findFileInDiffFiles(diffFiles, filepath)
);

export const getIsCorrectPullRequest = createCachedSelector(
  getCurrentPullRequestUrlPieces,
  (_state: BucketState, params: RouteParams) => params,
  (urlPieces, params) => {
    return areLocatorsEqual(urlPieces, toPullRequestLocator(params));
  }
)((_state, params: RouteParams) => {
  const { repositoryOwner, repositorySlug, pullRequestId } = params;
  return `${repositoryOwner}:${repositorySlug}:${pullRequestId}`;
});

export const getIsSettingsChangeboardingDialogOpen = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isSettingsChangeboardingDialogOpen
);

export const getSideBySideDiffState = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.sideBySideDiffState
);

export const getSideBySideDiffStateForFile = createCachedSelector(
  getPullRequestSlice,
  getGlobalDiffViewMode,
  (_state: BucketState, fileName: string) => fileName,
  getIsMobileHeaderActive,
  (
    prSlice: PullRequestState,
    globalDiffViewMode: DiffViewMode,
    fileName: string,
    isMobileHeaderActive: boolean
  ) => {
    if (isMobileHeaderActive) {
      return false;
    }
    const isSideBySideEnabledForFile = prSlice.sideBySideDiffState[fileName];
    return isSideBySideEnabledForFile === undefined
      ? globalDiffViewMode === DiffViewMode.SideBySide
      : isSideBySideEnabledForFile;
  }
)((_state, fileName) => fileName);

export const getIsOutdatedDialogOpen = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isOutdatedDialogOpen
);

export const getIsReviewDraftDialogOpen = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isReviewDraftDialogOpen
);

export const getIsDiffCommentsDialogOpen = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isDiffCommentsDialogOpen
);

export const getIsNonRenderedDiffCommentsDialogOpen = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isNonRenderedDiffCommentsDialogOpen
);

export const getEditorState = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isEditorOpen
);

export const getSourceRepositoryAccessLevel = createSelector(
  getPullRequestSlice,
  prSlice => {
    return prSlice.sourceRepository.accessLevel;
  }
);

export const getContainerId = createSelector(
  getCurrentRepositoryOwnerName,
  getCurrentRepositorySlug,
  getCurrentPullRequestId,
  (owner, repoSlug, pullRequestId) =>
    `ari:cloud:bitbucket:${owner}-${repoSlug}:pullrequest/${pullRequestId}`.toLowerCase()
);

export const getTopLevelInlineCommentsOrderByRecent = createSelector(
  getAllRawComments,
  rawComments => {
    return rawComments
      .filter(c => (!isApiComment(c) || !c.deleted) && !c.parent && c.inline)
      .reverse();
  }
);

export const getNavUnresolvedComments = createSelector(
  getTopLevelInlineCommentsOrderByRecent,
  comments => {
    // filter out any comments that are unresolved or pending.
    // we don't have to do this for resolved comments since
    // the user cannot resolve a pending comment
    const unresolvedComments = comments.filter(
      comment => !comment.resolution && !comment.pending
    );

    return unresolvedComments.map(unresolvedComment =>
      toNavComment(unresolvedComment)
    );
  }
);

export const getNavResolvedComments = createSelector(
  getTopLevelInlineCommentsOrderByRecent,
  comments => {
    const resolvedComments = comments.filter(comment => comment.resolution);
    return resolvedComments.map(resolvedComment =>
      toNavComment(resolvedComment)
    );
  }
);

export const getFormattedCommentsForConversations: PRSelector<{
  fabricConversations: FabricConversation[];
  conversations: CodeReviewConversation[];
  commentTree: CommentTree;
}> = createSelector(
  getAllRawComments,
  getContainerId,
  (rawComments, containerId) => formatAllComments(rawComments, containerId)
);

export const getFabricConversationsList: PRSelector<FabricConversation[]> =
  createSelector(
    getFormattedCommentsForConversations,
    formattedComments => formattedComments.fabricConversations
  );

export const getContextLines = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.contextLines || []
);

export const getCommentTree: PRSelector<CommentTree> = createSelector(
  getFormattedCommentsForConversations,
  formattedComments => formattedComments.commentTree
);

export const getConversationsList: PRSelector<CodeReviewConversation[]> =
  createSelector(
    getFormattedCommentsForConversations,
    getContextLines,
    (formattedComments, contextLines) => {
      const { conversations } = formattedComments;

      return conversations.map(convo => {
        const contextForConvo = contextLines[convo.conversationId];
        // explicit undefined check so that empty lines still count, friends don't let friends use truthiness
        if (contextForConvo === undefined) {
          return convo;
        }

        return {
          ...convo,
          meta: {
            ...convo.meta,
            context_lines: contextForConvo,
          },
        };
      });
    }
  );

export const getIsDiffsLoading = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isDiffsLoading
);

export const getIsLoadingOutdatedCommentContext = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isLoadingOutdatedCommentContext
);

export const getIsLoadingPendingCommentContext = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isLoadingPendingCommentContext
);

export const getisLoadingBaseRevCommentContext = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isLoadingBaseRevCommentContext
);

export const getIsLoadingAllChangesCommentContext = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isLoadingAllChangesCommentContext
);

export const getIsDiffStatLoading = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isDiffStatLoading
);

export const getIsRevertDialogOpen = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isRevertDialogOpen
);

export const getIsPullRequestTitleUpdatePending = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isPullRequestTitleUpdatePending
);

export const getIsPullRequestDescriptionUpdatePending = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isPullRequestDescriptionUpdatePending
);

export const getIsPullRequestRemoveReviewerPending = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.isPullRequestRemoveReviewerPending
);

export const getIsPullRequestAddReviewerPending = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.pullRequestAddReviewerPendingAaids.length > 0
);

export const getPullRequestAddReviewerPendingAaids = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.pullRequestAddReviewerPendingAaids
);

export const getRevertError = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.revertError
);

export const getHasDestinationBranch = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.hasDestinationBranch
);

export const getCurrentPullRequestSourceBranchDetails = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.sourceBranchDetails
);

export const getCurrentPullRequestSourceBranchPermissions = createSelector(
  getCurrentPullRequestSourceBranchDetails,
  sourceBranch => (sourceBranch ? sourceBranch.permissions : null)
);

export const getCanDeleteSourceBranch = createSelector(
  getPullRequestSourceRepo,
  getPullRequestDestinationRepo,
  getCurrentPullRequestSourceBranchPermissions,
  getCurrentPullRequestSourceBranchDetails,
  (sourceRepo, destRepo, permissions, sourceBranch) =>
    sourceRepo &&
    destRepo &&
    permissions &&
    !sourceBranch?.isMainBranch &&
    sourceRepo.full_name === destRepo.full_name &&
    permissions.delete === 'allow'
);

function commentsToKey(_state: BucketState, comments: { id: number }[]) {
  if (!comments) {
    return '';
  }
  return comments.map(comment => comment.id).join(',');
}

export const getCommentLikes = createCachedSelector(
  getEntities,
  (_state: BucketState, comments: { id: number }[] | undefined) => comments,
  (entities, comments) => {
    if (!comments) {
      return [];
    }

    return comments.reduce((result, comment) => {
      const { id: commentId } = comment;
      const commentLikes = denormalize(commentId, commentLikesSchema, entities);

      if (commentLikes && commentLikes.users.length !== 0) {
        result.push(commentLikes);
      }
      return result;
    }, [] as CommentLikes[]);
  }
)(commentsToKey);

// essentially getCanCreateTask selector
export const getCanLikeComments = createSelector(
  getRepositoryGrantedAccess,
  getRepositoryAccessLevel,
  grantedAccess => grantedAccess !== 'none'
);

const getDiffCommentingMap: PRSelector<DiffStateMap> = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.diffCommentingMap
);

export const isFileCommentOpen = createCachedSelector(
  getDiffCommentingMap,
  (_state: BucketState, filepath: string) => filepath,
  (diffCommentingMap: DiffStateMap, filepath: string) =>
    !!diffCommentingMap[filepath]
)((_state, filePath) => filePath || '');

export const getPullRequestRefetchBuildsDummy = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.refetchBuildsDummy
);

export const getPullRequestRefetchIRDiffDummy = createSelector(
  getPullRequestSlice,
  prSlice => prSlice.refetchIRDiffDummy
);

export const getPullRequestViewSyncStrategies = createSelector(
  getCurrentPullRequest,
  pullRequest => pullRequest?.source.branch?.sync_strategies
);
