import React from 'react';

import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { AutoDismissFlag } from '@atlaskit/flag';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import SimpleTag from '@atlaskit/tag/simple-tag';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import {
  DeleteBranchErrorType,
  DeleteBranchErrorTypes,
  getDeleteBranchDialogSlice,
} from 'src/redux/branches';
import { BucketState } from 'src/types/state';

import messages from './delete-branch-error-flag.i18n';

const getErrorMessage = (errorType: DeleteBranchErrorType) => {
  switch (errorType) {
    case DeleteBranchErrorTypes.BRANCH_NOT_FOUND:
      return messages.deleteBranchErrorDescriptionNotFound;
    case DeleteBranchErrorTypes.ACCESS_DENIED:
      return messages.deleteBranchErrorDescriptionAccessDenied;
    default:
    case DeleteBranchErrorTypes.GENERIC:
      return messages.deleteBranchErrorDescriptionGeneric;
  }
};

type Props = {
  id: string;
  branchName: string;
  errorType: DeleteBranchErrorType;
  intl: InjectedIntl;
};

const ErrorFlag = ({ branchName, errorType, intl, ...otherProps }: Props) => (
  <AutoDismissFlag
    {...otherProps}
    icon={
      <ErrorIcon
        label="error"
        primaryColor={token('color.icon.danger', colors.R300)}
      />
    }
    title={intl.formatMessage(messages.deleteBranchErrorTitle)}
    description={
      <FormattedMessage
        {...getErrorMessage(errorType)}
        values={{ branchLabel: <SimpleTag text={branchName} /> }}
      />
    }
  />
);

const mapStateToProps = (state: BucketState) => {
  const {
    errorFlag: { branchName, errorType },
  } = getDeleteBranchDialogSlice(state);

  return {
    branchName,
    errorType,
  };
};

export const DeleteBranchErrorFlag = compose<any, any, any>(
  injectIntl,
  connect(mapStateToProps)
)(ErrorFlag);
