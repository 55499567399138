export default function (): boolean {
  let isInIframe = true;
  try {
    if (window.top === window) {
      isInIframe = false;
    }
    // eslint-disable-next-line no-empty
  } catch (ignore) {}
  return isInIframe;
}
