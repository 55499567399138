import React from 'react';

import Icon from '@atlaskit/icon';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { BitkitIconTypes } from '../types';

// Inlining the SVG to manually change snake-case props to camelCase
// so React is happy (https://github.com/facebook/react/issues/2250).
// @ts-ignore TODO: fix noImplicitAny error here
const glyph = props => (
  <svg viewBox="0 0 16 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect
        fill={token('color.icon.success', colors.G400)}
        width="14"
        height="14"
        x="1"
        y="1"
        rx="3"
      />
      <rect
        width="15"
        height="15"
        x=".5"
        y=".5"
        stroke={token('elevation.surface.raised', colors.N0)}
        rx="3"
      />
      <path
        fill={token('elevation.surface.raised', colors.N0)}
        fillRule="nonzero"
        d="M4 7h8v2H4V7zm3-3h2v8H7V4z"
      />
    </g>
  </svg>
);

export const FileAddedIcon = (props: BitkitIconTypes) => (
  <Icon {...props} glyph={glyph} />
);
