/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import * as Sentry from '@sentry/browser';
import { call, put, select } from 'redux-saga/effects';

import { Repository } from 'src/components/types';
import { AsyncAction } from 'src/redux/actions';
import { getCurrentRepository } from 'src/selectors/repository-selectors';
import { settings } from 'src/settings';
import { Action } from 'src/types/state';
import fetchAccessToken, { AccessToken } from 'src/utils/fetch-access-token';
import { captureMessageForResponse } from 'src/utils/sentry';

import {
  INSTALL_ADDON_JIRA_TAB,
  INSTALL_ADDON_JIRA_MIGRATION,
} from '../actions';

export function* installAddon(action: AsyncAction, workspace: string) {
  const url = `${
    settings().API_CANON_URL
  }/internal/site/addons/account/${workspace}/install/`;
  try {
    const accessToken: AccessToken = yield call(
      fetchAccessToken,
      'site:bitbucket-jira-installer'
    );
    const response: Response = yield call(fetch, url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken.token}`,
      },
    });

    if (response.ok) {
      yield put({
        type: action.SUCCESS,
      });
    } else {
      yield captureMessageForResponse(response, 'Installing Jira addon failed');
      yield put({
        type: action.ERROR,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: action.ERROR,
    });
  }
}

export function* installAddonJiraMigrationSaga({
  payload: workspace,
}: Action<{ workspace: string }>) {
  yield call(installAddon, INSTALL_ADDON_JIRA_MIGRATION, workspace as any);
}

export function* installAddonJiraTabSaga() {
  const currentRepo: Repository = yield select(getCurrentRepository);
  const workspace = currentRepo?.owner?.uuid || '';
  yield call(installAddon, INSTALL_ADDON_JIRA_TAB, workspace);
}
