import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useRef, useState } from 'react';
import BrowserInteractionTime from 'browser-interaction-time';
export var useBrowserInteractionTime = function useBrowserInteractionTime(threshold) {
  var _useState = useState(true),
    _useState2 = _slicedToArray(_useState, 2),
    active = _useState2[0],
    setActive = _useState2[1];
  var _useState3 = useState(true),
    _useState4 = _slicedToArray(_useState3, 2),
    tabActive = _useState4[0],
    setTabActive = _useState4[1];
  var activeRef = useRef(active);
  activeRef.current = active;
  useEffect(function () {
    var timer;
    timer = new BrowserInteractionTime({
      idleTimeoutMs: threshold,
      stopTimerOnTabchange: false,
      browserTabActiveCallbacks: [function () {
        setTabActive(true);
      }],
      browserTabInactiveCallbacks: [function () {
        setTabActive(false);
      }],
      idleCallbacks: [function () {
        if (activeRef.current) {
          setActive(false);
        }
      }],
      activeCallbacks: [function () {
        if (!activeRef.current) {
          setActive(true);
        }
      }]
    });
    timer.startTimer();
    return function () {
      timer && timer.destroy();
      timer = null;
    };
  }, [threshold]);
  return {
    active: active,
    tabActive: tabActive
  };
};