import { alignment as alignmentFactory } from '../../next-schema/generated/markTypes';

/** TODO: Flip these positions for RTL */
export var alignmentPositionMap = {
  end: 'right',
  right: 'end',
  center: 'center'
};

/**
 * @name alignment_mark
 */

export var alignment = alignmentFactory({
  parseDOM: [{
    tag: 'div.fabric-editor-block-mark',
    getAttrs: function getAttrs(dom) {
      var align = dom.getAttribute('data-align');
      return align ? {
        align: align
      } : false;
    }
  }],
  toDOM: function toDOM(mark) {
    return ['div', {
      class: "fabric-editor-block-mark fabric-editor-alignment fabric-editor-align-".concat(mark.attrs.align),
      'data-align': mark.attrs.align
    }, 0];
  }
});