import { createResource, RouterContext } from 'react-resource-router';

import { getRef } from 'src/sections/create-branch/utils';
import urls from 'src/sections/repository/urls';
import authRequest from 'src/utils/fetch';

const fetchData = async (
  repoOwner: string,
  repoSlug: string,
  sourceBranch: string | undefined | null,
  destRepo: string | undefined | null,
  destBranch: string | undefined | null
) => {
  const url = urls.api.internal.createPullRequestData(
    repoOwner,
    repoSlug,
    sourceBranch,
    destRepo,
    destBranch
  );
  const resp = await fetch(authRequest(url));
  return resp.json();
};

export const loadBranchesResource = createResource({
  type: 'createPullRequestBranches',
  getKey: ({ match, query }: RouterContext) => {
    return `createPullRequestBranches:${match.params.repositoryOwner}:${match.params.repositorySlug}:${query.source}:${query.dest}.`;
  },
  maxAge: 10000, // cache for 10 seconds to help with quick moves around pages, but refs could change so don't cache for too long
  getData: async ({ match, query }) => {
    const { repositoryOwner, repositorySlug } = match.params;
    if (!repositoryOwner || !repositorySlug) {
      throw new Error(
        'Could not fetch create PR branches, URL params were not available'
      );
    }
    const { source, dest } = query;

    let sourceBranchName: string;
    let destBranchName: string;
    let destRepoName: string | undefined;

    if (source && source.includes('::')) {
      sourceBranchName = source.split('::')[1];
    } else {
      sourceBranchName = source;
    }
    if (dest && dest.includes('::')) {
      [destRepoName, destBranchName] = dest.split('::');
    } else {
      destBranchName = dest;
    }
    const {
      source_branch: sourceBranch,
      dest_branch: destBranch,
      dest_repo: destRepo,
      error_on_source: errorOnSourceLoad,
      error_on_dest: errorOnDestLoad,
      can_access_parent_repo: showParentRepo,
      pull_request_url: pullRequestUrl,
    } = await fetchData(
      repositoryOwner,
      repositorySlug,
      sourceBranchName,
      destRepoName,
      destBranchName
    );

    const sourceRef = getRef(sourceBranch);
    const destRef = getRef(destBranch);

    if (sourceRef === undefined) {
      throw Error('undefined source ref');
    }

    if (destRef === undefined) {
      throw Error('undefined dest ref');
    }

    return {
      sourceRef,
      errorOnSourceLoad,
      destRepo,
      destRef,
      errorOnDestLoad,
      showParentRepo,
      pullRequestUrl,
    };
  },
});
