import {AnalyticsAPI} from './AnalyticsAPI';

class DefaultAnalyticsAPI implements AnalyticsAPI {

  trigger(category: string, action: string, payload: any): void {
    // Do nothing.
  }

  markAsSafe(...allowedStrings:string[]):any {
    // Do no filtering
    return (value:string) => {
      return value;
    }
  }

  dangerouslyCreateSafeString(value:string){
    // Do nothing
    return value;
  }

}

export default new DefaultAnalyticsAPI() as AnalyticsAPI;
