import { ROUTE_NAME, SupportedPath } from '../types';

export const getSupportedPaths = (): SupportedPath[] => [
  {
    name: ROUTE_NAME.GLOBAL_SEARCH,
    path: '/search',
  },
  {
    name: ROUTE_NAME.GLOBAL_ACCOUNT_WORKSPACES,
    path: '/account/workspaces',
  },
  ...[
    '/account/user/:uuid/plans',
    '/:workspaceSlug/workspace/settings/plans',
    '/workspaces/plans',
  ].map(path => ({
    name: ROUTE_NAME.GLOBAL_ACCOUNT_PLAN_SELECTOR,
    path,
  })),
  ...['/account/user/:uuid/payment', '/account/user/:uuid/billing-details'].map(
    path => ({
      name: ROUTE_NAME.GLOBAL_ACCOUNT_PAYMENT,
      path,
    })
  ),
  {
    name: ROUTE_NAME.GLOBAL_BRANCH_CREATE,
    path: '/branch/create',
  },
  {
    name: ROUTE_NAME.GLOBAL_ATTRIBUTIONS_PAGE,
    path: '/site/attributions',
  },
];
