"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
/* eslint-disable no-underscore-dangle */
var ResponseError = /** @class */ (function (_super) {
    tslib_1.__extends(ResponseError, _super);
    function ResponseError(status, body) {
        var _this = 
        // Extending error requires this workaround, see https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        _super.call(this, "Response Error: " + status) || this;
        Object.setPrototypeOf(_this, ResponseError.prototype);
        _this._status = status;
        _this._body = body;
        return _this;
    }
    Object.defineProperty(ResponseError.prototype, "status", {
        get: function () {
            return this._status;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResponseError.prototype, "body", {
        get: function () {
            return this._body;
        },
        enumerable: true,
        configurable: true
    });
    return ResponseError;
}(Error));
exports.ResponseError = ResponseError;
