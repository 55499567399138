/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { call, put, select } from 'redux-saga/effects';

import { CREATE_PENDING_MERGE } from 'src/redux/pull-request/merge-reducer';
import { getJsonErrorMessageWithCode } from 'src/redux/pull-request/sagas/utils/get-error-message';
import { getCurrentPullRequestUrlPieces } from 'src/redux/pull-request/selectors';
import urls from 'src/redux/pull-request/urls';
import { MergeInfo } from 'src/types/pull-request';
import { Action } from 'src/types/state';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

export function* createPendingMergeSaga(action: Action) {
  // eslint-disable-next-line prefer-destructuring
  const payload: MergeInfo = action.payload;
  const { owner, slug, id } = yield select(getCurrentPullRequestUrlPieces);
  const url = urls.api.internal.pendingMerge(owner, slug, id);

  const authWrapped = authRequest(url, {
    method: 'POST',
    headers: jsonHeaders,
    body: JSON.stringify({
      close_source_branch: payload.closeSourceBranch,
      message: payload.message,
      merge_strategy: payload.mergeStrategy,
      newstatus: 'fulfilled',
    }),
  });

  try {
    const response: Response = yield call(fetch, authWrapped);

    if (!response.ok) {
      // @ts-ignore
      const message = yield call(getJsonErrorMessageWithCode, response.clone());

      throw message;
    }

    yield put({
      type: CREATE_PENDING_MERGE.SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({
      type: CREATE_PENDING_MERGE.ERROR,
      payload: e,
      error: true,
    });
  }
}
