import React from 'react';

import Button from '@atlaskit/button';
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu-12';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';

import { DropMenuContainer } from 'src/components/settings/privileges-dropdown/privileges-dropdown.styles';
import { useIntl } from 'src/hooks/intl';

import { AccessLevel } from './constants';
import messages from './shared.i18n';
import { AccessLevelFilter } from './types';

export type AccessLevelDropdownProps = {
  value: AccessLevelFilter;
  onChange: (accessLevel: AccessLevelFilter) => void;
  isDisabled?: boolean;
  allowEmptyChoice?: boolean;
  isProjectContext?: boolean;
};

/* eslint @typescript-eslint/ban-types: "warn" */
const AccessLevelDropdown: React.FC<AccessLevelDropdownProps> = ({
  value,
  onChange,
  isDisabled,
  allowEmptyChoice = false,
  isProjectContext = false,
}) => {
  const { formatMessage } = useIntl();
  let accessLevels = Object.values(AccessLevel);

  if (isProjectContext) {
    accessLevels = [AccessLevel.workspace, AccessLevel.project];
  }

  const getTriggerLabel = () => {
    if (value) {
      return formatMessage(messages[value]);
    }
    return formatMessage(messages.allAccessLevels);
  };

  return (
    <DropMenuContainer
      showValueDescription={false}
      allowEmptyChoice={allowEmptyChoice}
      fitContainer
      shiftLeft
    >
      <DropdownMenu
        testId="accessLevelDropdown"
        shouldRenderToParent
        shouldFitContainer
        spacing="compact"
        trigger={({ triggerRef, ...props }) => (
          <Button
            {...props}
            className="triggerButton"
            ref={triggerRef}
            iconAfter={<ChevronDownIcon label="" />}
            shouldFitContainer
            isDisabled={isDisabled}
          >
            {getTriggerLabel()}
          </Button>
        )}
      >
        <DropdownItemGroup>
          {allowEmptyChoice && (
            <DropdownItem onClick={() => onChange(null)} isDisabled={!value}>
              {formatMessage(messages.allAccessLevelsMenuItem)}
            </DropdownItem>
          )}
          {accessLevels.map(accessLevel => (
            <DropdownItem
              key={accessLevel}
              isDisabled={accessLevel === value}
              onClick={() => onChange(accessLevel)}
            >
              {formatMessage(messages[accessLevel])}
            </DropdownItem>
          ))}
        </DropdownItemGroup>
      </DropdownMenu>
    </DropMenuContainer>
  );
};

export default AccessLevelDropdown;
