import { DirectoryEntry, FileEntry, TreeEntry } from 'src/components/file-tree';

export function isFile(
  entry: TreeEntry | null | undefined
): entry is FileEntry {
  return !!entry && entry.type !== 'directory';
}

/**
 * Flatten all dirs that have at least one file to a single level.
 * @param entries list of dirs or files
 * @param parentPath current hierarchy's path (starts at '/' for root)
 */
export function flattenFiles(
  entries: TreeEntry[],
  parentPath = ''
): DirectoryEntry[] {
  if (!entries.length) {
    return [];
  }

  // hierarchy is flattened to a single level as an array of dirs
  return entries.reduce((dirEntries, entry) => {
    if (isFile(entry)) {
      // files at root level
      if (!parentPath) {
        // add file to root dir if it exists
        const rootDir = dirEntries.find(dir => dir.name === '/');
        if (rootDir) {
          rootDir.contents.push(entry);
          return dirEntries;
        }
        return dirEntries.concat([
          {
            name: '/',
            type: 'directory',
            contents: [entry],
          },
        ]);
      }
      // non root level files aren't added directly
      return dirEntries;
    }
    const currPath = `${parentPath}/${entry.name}`;
    const flattenedContents = flattenFiles(entry.contents, currPath);
    // dirs that have at least one file are included
    if (entry.contents.some(dirContent => isFile(dirContent))) {
      // parent dir ordered ahead of subdirs
      return dirEntries.concat(
        [
          {
            ...entry,
            name: currPath,
            // only include files of this dir in contents
            contents: entry.contents.filter(entryContent =>
              isFile(entryContent)
            ),
          },
        ],
        flattenedContents
      );
    }
    return dirEntries.concat(flattenedContents);
  }, [] as DirectoryEntry[]);
}
