import { Allowance } from 'src/components/pipelines/models';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import { REQUEST_ALLOWANCE } from '../actions/pipelines';

function toOneDecimalPlace(num: number): number {
  return Math.round(num * 10) / 10;
}

function calculatePercentageUsed(usage: number, quota: number): number {
  return usage / quota;
}

export default createReducer(new Allowance(), {
  [REQUEST_ALLOWANCE.SUCCESS](state: Allowance, action: Action<any>) {
    return new Allowance({
      ...state?.toJS?.(),
      startDate: action.payload.start_date,
      endDate: action.payload.end_date,
      isTrusted: action.payload.is_trusted,
      totalUsage: toOneDecimalPlace(action.payload.usage_seconds / 60),
      quota: toOneDecimalPlace(action.payload.quota_seconds / 60),
      percentageUsed: calculatePercentageUsed(
        action.payload.usage_seconds,
        action.payload.quota_seconds
      ),
      hasFetched: true,
      hasPermissionToView: true,
    });
  },
  [REQUEST_ALLOWANCE.ERROR](state: Allowance) {
    return new Allowance({
      ...state?.toJS?.(),
      hasFetched: true,
      totalUsage: 0,
      percentageUsed: 0,
      isErrored: true,
    });
  },
});
