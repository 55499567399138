import { stringify } from 'src/urls/utils';

type IssueFilterQueryParams = {
  pagelen?: number;
  page?: number;
  cloud_id: string;
  project_ids?: string[];
  statuses?: string[];
  assignees?: string[];
  text: string;
  sort?: string;
};

export default {
  ui: {
    root: (repositoryFullSlug: string) => `/${repositoryFullSlug}/jira/`,
    settingsJiraIntegration: (workspace: string) =>
      `/${workspace}/workspace/settings/jira-integration`,
    workspace: (workspace: string) => `/${workspace}/`,
  },
  api: {
    v20: {
      workspacePermission: (workspace: string) =>
        `/!api/2.0/user/permissions/workspaces?fields=values.permission&q=workspace.slug="${workspace}"`,
    },
    internal: {
      sites: (workspace: string) =>
        `/!api/internal/workspaces/${workspace}/jira/sites`,

      connectedSites: (workspace: string) =>
        `/!api/internal/workspaces/${workspace}/jira/sites?connected=true`,

      relevantSites: (repositoryFullSlug: string) =>
        `/!api/internal/repositories/${repositoryFullSlug}/jira/sites`,

      project: (workspace: string, cloudId: string, projectId: string) =>
        `/!api/internal/workspaces/${workspace}/jira/sites/${cloudId}/projects/${projectId}`,

      validateProject: (
        workspace: string,
        cloudId: string,
        projectKey: string,
        projectName: string
      ) =>
        `/!api/internal/workspaces/${workspace}/jira/sites/${cloudId}/validate-project?project_key=${projectKey}&project_name=${projectName}`,

      generateProjectKey: (workspace: string, cloudId: string) =>
        `/!api/internal/workspaces/${workspace}/jira/sites/${cloudId}/generate-project-key`,

      projects: (workspace: string, cloudId: string, projectFilter?: string) =>
        `/!api/internal/workspaces/${workspace}/jira/sites/${cloudId}/projects${stringify(
          { projectFilter }
        )}`,

      projectAssociation: (
        repositoryFullSlug: string,
        cloudId: string,
        projectId: string
      ) =>
        `/!api/internal/repositories/${repositoryFullSlug}/jira/sites/${cloudId}/projects/${projectId}`,

      devActivity: (workspace: string, cloudId: string) =>
        `/!api/internal/workspaces/${workspace}/jira/sites/${cloudId}/issues/dev-status`,

      relevantProjects: (repositoryFullSlug: string) =>
        `/!api/internal/repositories/${repositoryFullSlug}/jira/projects`,

      relevantIssues: (
        repositoryFullSlug: string,
        filterParams: IssueFilterQueryParams
      ) =>
        `/!api/internal/repositories/${repositoryFullSlug}/jira/projects/issues${stringify(
          filterParams,
          {
            arrayFormat: 'repeat',
            filter: (_: string, value: any) => {
              if (value && value.length === 0) {
                return undefined;
              }
              return value;
            },
          }
        )}`,

      assignees: (repositoryFullSlug: string, cloudId: string) =>
        `/!api/internal/repositories/${repositoryFullSlug}/jira/projects/assignees?cloud_id=${cloudId}&pagelen=90`,

      setAssignee: (cloudId: string, issueKeyOrId: string) =>
        `/!api/internal/sites/${cloudId}/jira/issues/${issueKeyOrId}/assign`,

      jiraUsers: (workspace: string, cloudId: string, userIds: [string]) =>
        `/!api/internal/workspaces/${workspace}/jira/sites/${cloudId}/users${stringify(
          { account_ids: userIds },
          { arrayFormat: 'repeat' }
        )}`,

      issueCreationMetadata: (
        workspace: string,
        cloudId: string,
        projectId: string
      ) =>
        `/!api/internal/workspaces/${workspace}/jira/sites/${cloudId}/issuecreationmetadata?project_id=${projectId}`,

      issues: (repositoryFullSlug: string, pullRequestId: number | string) =>
        `/!api/internal/repositories/${repositoryFullSlug}/pullrequests/${pullRequestId}/jira/issues`,

      jiraIssues: (repositoryFullSlug: string) =>
        `/!api/internal/repositories/${repositoryFullSlug}/jira/issues`,

      availableTransitions: (cloudId: string, issueKeyOrId: number | string) =>
        `/!api/internal/jira/sites/${cloudId}/issues/${issueKeyOrId}/transitions`,

      transitions: (repositoryFullSlug: string, pullRequestId: number) =>
        `/!api/internal/repositories/${repositoryFullSlug}/pullrequests/${pullRequestId}/jira/transitions`,

      myIssues: (cloudId: string, pagelen?: number) =>
        `/!api/internal/my/jira/sites/${cloudId}/issues${
          pagelen ? `?pagelen=${pagelen}` : ''
        }`,

      dashboardDevActivity: (cloudId: string) =>
        `/!api/internal/my/jira/sites/${cloudId}/issues/dev-status`,

      startIssueMigrationToJira: (
        repositoryFullSlug: string,
        cloudId: string,
        projectKey: string,
        projectName: string
      ) =>
        `/!api/internal/repositories/${repositoryFullSlug}/issues/jira-export/${stringify(
          {
            cloud_id: cloudId,
            project_key: projectKey,
            project_name: projectName,
          }
        )}`,

      issueMigrationToJiraStatus: (repositoryFullSlug: string) =>
        `/!api/internal/repositories/${repositoryFullSlug}/issues/jira-export/status`,

      issueMigrationToJiraLogs: (repositoryFullSlug: string) =>
        `/!api/internal/repositories/${repositoryFullSlug}/issues/jira-export/log`,
    },
  },
  external: {
    wacTryJiraBbcBundleUrl:
      'https://www.atlassian.com/software/jira/bitbucket-integration?utm_source=bitbucket&utm_medium=in_product_ad&utm_campaign=bitbucket_jswtab&utm_content=jswtab_welcome',
    docsConnectJira: 'https://confluence.atlassian.com/x/FQCDM',
    docsUseJiraProjects: 'https://confluence.atlassian.com/x/JJ9gOg',
    docsJiraAdminPermissions: 'https://confluence.atlassian.com/x/tRaEOQ',
    docsJiraIssues: 'https://confluence.atlassian.com/x/yAiiLQ',
    docsBitbucketJiraIntegration: 'https://confluence.atlassian.com/x/jIeHM',
  },
};
