import React from 'react';

import CreateBranch, { Props } from '../components/create-branch-dialog';
import { CREATE_FROM } from '../constants';

import connect from './connect';

export default connect((props: Props) => (
  <CreateBranch
    {...props}
    createFrom={CREATE_FROM.GLOBAL_DIALOG}
    shouldRenderCreateBranchButton={false}
  />
));
