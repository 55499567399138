import React, { PureComponent } from 'react';

/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';
import { injectIntl, InjectedIntl } from 'react-intl';

import AkAvatar, { AvatarPropTypes } from '@atlaskit/avatar';

import { getAvatarUrl, getName } from './utils';

const AvatarContainer = styled.div`
  /* Remove extra padded height from AK avatar in order to help center-align vertically */
  line-height: 1;
`;

type BaseUserAvatarProps = {
  intl: InjectedIntl;
  /** Customize the `name` prop passed to `@atlaskit/avatar`. Should incorporate the `privateName` argument into the return value. */
  renderName: (privateName: string) => string;
  user?: BB.UserType;
  tabIndex?: number;
};

class BaseUserAvatar extends PureComponent<
  BaseUserAvatarProps & AvatarPropTypes
> {
  render() {
    const { intl, renderName, user, tabIndex, ...avatarProps } = this.props;

    const name = getName(user, intl);
    const displayName = renderName(name);
    const avatarUrl = getAvatarUrl(user);

    return (
      <AvatarContainer>
        <AkAvatar
          {...avatarProps}
          name={displayName}
          src={avatarUrl || undefined}
          tabIndex={tabIndex || 0}
        />
      </AvatarContainer>
    );
  }
}

export default injectIntl(BaseUserAvatar);
