/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { N80, N900 } from '@atlaskit/theme/colors';
import { useGlobalTheme } from '@atlaskit/theme/components';
import { fontFamily } from './constants';
import theme from './theme';
var labelStyles = css({
  display: 'grid',
  gridAutoColumns: '1fr',
  gridAutoRows: 'min-content',
  color: "var(--ds-text, ".concat(N900, ")"),
  cursor: 'default',
  fontFamily: "var(--ds-font-family-sans, ".concat(fontFamily, ")")
});
var textLabelLayoutStyles = css({
  gap: "var(--ds-space-0, 0px)".concat(" ", "var(--ds-space-050, 4px)"),
  gridTemplateColumns: 'min-content auto'
});
var disabledStyles = css({
  color: "var(--ds-text-disabled, ".concat(N80, ")"),
  cursor: 'not-allowed'
});
var themeStyles = {
  light: css({
    /**
     * Background
     */
    '--local-background': theme.light.boxColor.rest,
    '--local-background-active': theme.light.boxColor.active,
    '--local-background-checked': theme.light.boxColor.checked,
    '--local-background-checked-hover': theme.light.boxColor.hoveredAndChecked,
    '--local-background-disabled': theme.light.boxColor.disabled,
    '--local-background-hover': theme.light.boxColor.hovered,
    /**
     * Border
     */
    '--local-border': theme.light.borderColor.rest,
    '--local-border-active': theme.light.borderColor.active,
    '--local-border-checked': theme.light.borderColor.checked,
    '--local-border-checked-hover': theme.light.borderColor.hoveredAndChecked,
    '--local-border-checked-invalid': theme.light.borderColor.invalidAndChecked,
    '--local-border-disabled': theme.light.borderColor.disabled,
    '--local-border-focus': theme.light.borderColor.focused,
    '--local-border-hover': theme.light.borderColor.hovered,
    '--local-border-invalid': theme.light.borderColor.invalid,
    /**
     * Tick
     */
    '--local-tick-active': theme.light.tickColor.activeAndChecked,
    '--local-tick-checked': theme.light.tickColor.checked,
    '--local-tick-disabled': theme.light.tickColor.disabledAndChecked,
    '--local-tick-rest': 'transparent'
  }),
  dark: css({
    /**
     * Background
     */
    '--local-background': theme.dark.boxColor.rest,
    '--local-background-active': theme.dark.boxColor.active,
    '--local-background-checked': theme.dark.boxColor.checked,
    '--local-background-checked-hover': theme.dark.boxColor.hoveredAndChecked,
    '--local-background-disabled': theme.dark.boxColor.disabled,
    '--local-background-hover': theme.dark.boxColor.hovered,
    /**
     * Border
     */
    '--local-border': theme.dark.borderColor.rest,
    '--local-border-active': theme.dark.borderColor.active,
    '--local-border-checked': theme.dark.borderColor.checked,
    '--local-border-checked-hover': theme.dark.borderColor.hoveredAndChecked,
    '--local-border-checked-invalid': theme.dark.borderColor.invalidAndChecked,
    '--local-border-disabled': theme.dark.borderColor.disabled,
    '--local-border-focus': theme.dark.borderColor.focused,
    '--local-border-hover': theme.dark.borderColor.hovered,
    '--local-border-invalid': theme.dark.borderColor.invalid,
    /**
     * Tick
     */
    '--local-tick-active': theme.dark.tickColor.activeAndChecked,
    '--local-tick-checked': theme.dark.tickColor.checked,
    '--local-tick-disabled': theme.dark.tickColor.disabledAndChecked,
    '--local-tick-rest': 'transparent'
  })
};
export default function Label(_ref) {
  var children = _ref.children,
    isDisabled = _ref.isDisabled,
    testId = _ref.testId,
    label = _ref.label,
    id = _ref.id;
  var _useGlobalTheme = useGlobalTheme(),
    mode = _useGlobalTheme.mode;
  return jsx("label", {
    css: [labelStyles, label && textLabelLayoutStyles, isDisabled && disabledStyles, mode === 'light' && themeStyles.light, mode === 'dark' && themeStyles.dark],
    "data-testid": testId,
    "data-disabled": isDisabled || undefined,
    id: id
  }, children);
}