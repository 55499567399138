import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * Inspired by analytics-web-react
 */

import last from 'lodash/last';
import merge from 'lodash/merge';
import { DEFAULT_SOURCE, UI_EVENT_TYPE, SCREEN_EVENT_TYPE, TRACK_EVENT_TYPE, OPERATIONAL_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { getSources, getActionSubject, getExtraAttributes, getPackageInfo, getPackageHierarchy, getComponents } from './extract-data-from-event';
var ATLASKIT_TAG = 'atlaskit';
var listenerVersion = "8.11.1";

/**
 * This util exists to convert the Atlaskit event format into the analytics platform format.
 *
 * Atlaskit event format:
 * event {
 *      payload: {
 *          ...attributesFromLowestPointInTheTree
 *      },
 *      context: [{
 *          ...attributesFromHighestPointInTheTree
 *      }, {
 *          ...attributesFromSecondHighestPointInTheTree
 *      }]
 * }
 *
 * Analytics platform event format:
 *  event {
 *      type: @atlaskit/analytics-gas-types.EventType
 *      payload {
 *          ...mandatoryAttributesBasedOnEventType
 *          attributes: {
 *              ...arbitraryAttributes
 *          }
 *      }
 *  }
 */

export default (function (event, logger) {
  var sources = getSources(event);
  var source = last(sources) || DEFAULT_SOURCE;
  var extraAttributes = getExtraAttributes(event);
  var components = getComponents(event);
  var _ref = last(getPackageInfo(event)) || {},
    packageName = _ref.packageName,
    packageVersion = _ref.packageVersion;
  var _event$payload = event.payload,
    _event$payload$eventT = _event$payload.eventType,
    eventType = _event$payload$eventT === void 0 ? UI_EVENT_TYPE : _event$payload$eventT,
    action = _event$payload.action,
    actionSubjectId = _event$payload.actionSubjectId,
    payloadAttributes = _event$payload.attributes,
    containerId = _event$payload.containerId;
  var attributes = _objectSpread(_objectSpread({
    listenerVersion: listenerVersion,
    sourceHierarchy: sources.join('.') || undefined,
    componentHierarchy: components.join('.') || undefined,
    packageHierarchy: getPackageHierarchy(event) || undefined
  }, {
    packageName: packageName,
    packageVersion: packageVersion
  }), merge(extraAttributes, payloadAttributes));
  // Ensure atlaskit tag is not duplicated by using Set
  var tags = new Set(event.payload.tags || []);
  tags.add(ATLASKIT_TAG);
  if (event.payload) {
    if (eventType === UI_EVENT_TYPE) {
      return {
        eventType: eventType,
        source: source,
        actionSubject: getActionSubject(event),
        action: action,
        actionSubjectId: actionSubjectId,
        attributes: attributes,
        containerId: containerId,
        tags: Array.from(tags)
      };
    }
    if (eventType === TRACK_EVENT_TYPE || eventType === OPERATIONAL_EVENT_TYPE || eventType === SCREEN_EVENT_TYPE) {
      logger.error('Track, screen and operational events are currently not supported for atlaskit events');
    } else {
      logger.error('Invalid event type', eventType);
    }
  }
  return null;
});