/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import * as Sentry from '@sentry/browser';
import { select, call, takeLatest, put, all } from 'redux-saga/effects';

import { Commit } from 'src/components/types';
import { getCurrentRepository } from 'src/selectors/repository-selectors';
import { BuildStatusCountsMap, VerificationStatus } from 'src/types';

import { getCurrentCommits } from './selectors';
import {
  createCommitStatusMapper,
  fetchBuildStatuses,
  fetchVerificationStatuses,
} from './utils';

import { LoadCommits, UPDATE_COMMITS } from './';

export function* loadCommitStatusesSaga() {
  const commits: Commit[] = yield select(getCurrentCommits);

  if (!commits.length) {
    return;
  }

  try {
    const { full_name: fullName } = yield select(getCurrentRepository);
    const statuses: BuildStatusCountsMap = yield call(
      fetchBuildStatuses,
      fullName,
      commits
    );

    const verificationStatuses: VerificationStatus[] = yield call(
      fetchVerificationStatuses,
      fullName,
      commits
    );
    const addStatus = createCommitStatusMapper(statuses, verificationStatuses);
    yield put({
      type: UPDATE_COMMITS,
      payload: commits.map(addStatus),
    });
  } catch (e) {
    Sentry.captureException(e);
  }
}

export default function* commitListSagas() {
  yield all([takeLatest(LoadCommits.SUCCESS, loadCommitStatusesSaga)]);
}
