import qs from 'qs';

import { settings } from 'src/settings';
import authRequest from 'src/utils/fetch';

import { parseTokenDetailsFromMetaTag } from './get-token-details';

export type AccessToken = {
  token: string;
  expiresInSeconds: number;
};

export default function (clientId: string): Promise<AccessToken> {
  if (clientId === 'site:JSAPITOKEN') {
    const rawToken = parseTokenDetailsFromMetaTag();
    if (rawToken.expiration && rawToken.token) {
      const expiresInSeconds = (rawToken.expiration - Date.now()) / 1000;
      if (expiresInSeconds > 15) {
        // Return token from page if it's not going to expire soon. Subsequent requests should
        // re-call fetch-access-token so expiry should be handled there.
        return Promise.resolve({
          expiresInSeconds,
          token: rawToken.token,
        });
      }
    }
  }

  const requestData = {
    client_id: clientId,
    redirect_uri: 'https://bitbucket.org/',
    grant_type: 'urn:bitbucket:oauth2:session',
  };

  return fetch(
    authRequest(`${settings().CANON_URL}/site/oauth2/access_token`, {
      method: 'POST',
      body: qs.stringify(requestData),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  )
    .then(response =>
      response.ok ? response.json() : Promise.reject(response)
    )
    .then(json => ({
      expiresInSeconds: json.expires_in,
      token: json.access_token,
    }));
}
