import { createResource } from 'react-resource-router';

import {
  setDeploymentDashboard,
  setBranchRestrictions,
} from 'src/components/pipelines/redux/actions/deployments';
import {
  getDeploymentDashboardUrl,
  getEnvironmentUuids,
  getBranchRestrictionsUrl,
} from 'src/components/pipelines/redux/sagas/deployments';
import { ResourceContext } from 'src/router/types';
import { jsonHeaders } from 'src/utils/fetch';

import { fetchData } from './utils';

const DEPLOYMENT_SETTINGS_URL = '/admin/pipelines/deployment-settings';
const OPENID_URL = '/admin/pipelines/openid-connect';

export type Params = {
  repositoryFullSlug?: string;
  repositoryOwner?: string;
  repositorySlug?: string;
};

const getRepositoryFullSlug = (params: Params) => {
  return (
    params.repositoryFullSlug ||
    `${params.repositoryOwner}/${params.repositorySlug}`
  );
};

export const getUrl = (params: Params, url: string): string => {
  const repoFullSlug = getRepositoryFullSlug(params);
  const includeHidden =
    url.includes(DEPLOYMENT_SETTINGS_URL) || url.includes(OPENID_URL);
  return getDeploymentDashboardUrl(repoFullSlug, {
    includeHidden,
  });
};

export const loadDeploymentDashboardResource = createResource({
  type: 'deployment-dashboard',
  getKey: ({ match: { params, url } }) => getUrl(params as Params, url),
  maxAge: 0,
  getData: async (
    { match: { params, url } },
    { reduxStore }: ResourceContext
  ) => {
    const { dispatch } = reduxStore;
    const data = await fetchData(
      getUrl(params as Params, url),
      'deployment_dashboard_resource_exception'
    );
    dispatch(setDeploymentDashboard(data));

    const branchRestrictions = await fetchData(
      getBranchRestrictionsUrl(getRepositoryFullSlug(params)),
      'branch_restrictions_resource_exception',
      {
        method: 'POST',
        headers: jsonHeaders,
        body: JSON.stringify({
          environmentUuids: getEnvironmentUuids(data.groupings),
        }),
      }
    );
    dispatch(setBranchRestrictions(branchRestrictions));

    return { status: 'success' };
  },
});
