import { defineMessages } from 'react-intl';

export const keyboardShortcutDialogMessages = defineMessages({
  closeButton: {
    id: 'frontbucket.components.keyboardShortcutMenu.closeButton',
    description: 'Button to close the keyboard shortcut help menu',
    defaultMessage: 'Close',
  },
  heading: {
    id: 'frontbucket.components.keyboardShortcutMenu.heading',
    description: 'Heading for the keyboard shortcut help menu',
    defaultMessage: 'Keyboard shortcuts',
  },
  shiftKey: {
    id: 'frontbucket.components.keyboardShortcutMenu.shiftKey',
    description: 'The "Shift" key label',
    defaultMessage: 'Shift',
  },
});
