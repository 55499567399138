export enum EditorEnum {
  DeploymentsOnboarding = 'deployments_onboarding',
  PipelinesOnboarding = 'pipelines_onboarding',
  PipelinesOnboardingEnable = 'pipelines_onboarding_enable',
  Source = 'source',
}

export class Validator {
  readonly isValid: boolean = false;
  readonly code: string = '';
  readonly template: string = '';
  readonly currentEditor: EditorEnum | null = null;

  constructor(props: Partial<Validator> = {}) {
    Object.assign(this, props);
    Object.freeze(this);
  }
}
