import { bulletList as bulletListFactory } from '../../next-schema/generated/nodeTypes';
export var bulletListSelector = '.ak-ul';
export var bulletList = bulletListFactory({
  parseDOM: [{
    tag: 'ul'
  }],
  toDOM: function toDOM() {
    var attrs = {
      class: bulletListSelector.substr(1)
    };
    return ['ul', attrs, 0];
  }
});