import { flatten } from '../utils/flatten';
import { pick } from '../utils/pick';

import { ModelBase } from './ModelBase';

class Execution extends ModelBase {
  readonly pipelineUuid: string = '';
  readonly type: string = '';
  readonly error: any = {};
  readonly 'error.type': string = '';
  readonly 'error.key': string = '';
  readonly 'error.message': string = '';

  constructor(props: Partial<Execution> = {}) {
    super(props);
    Object.assign(this, {
      ...pick(flatten(props), Object.keys(this)),
    });
    Object.freeze(this);
  }
}

export class Schedule extends ModelBase {
  readonly uuid: string = '';
  readonly enabled: boolean = false;
  readonly cron_pattern: string = '';
  readonly created_on: string = '';
  readonly updated_on: string = '';
  readonly executions: Execution[] = [];
  readonly target: any = {};
  readonly 'target.ref_name': string = '';
  readonly 'target.ref_type': string = 'branch';
  readonly 'target.selector.type': string = '';
  readonly 'target.selector.pattern': string = '';

  constructor(props: Partial<Schedule> = {}) {
    super(props);
    Object.assign(this, {
      ...pick(flatten(props), Object.keys(this)),
      executions: Array.isArray(props.executions)
        ? props.executions.map(execution => new Execution(execution))
        : [],
    });
    Object.freeze(this);
  }
}
