import { SearchLog } from 'src/components/pipelines/models';
import { LoadingStatus } from 'src/constants/loading-status';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import {
  DISABLE_LOG_SEARCH,
  DOWNLOAD_ALL_COMMANDS,
  ENABLE_LOG_SEARCH,
  SEARCH_LOG,
  SEARCH_LOG_NEXT_LINE,
  SEARCH_LOG_PREV_LINE,
} from '../actions/pipelines';

export type SearchLogState = {
  searchLog: SearchLog;
  fetchedStatus: LoadingStatus;
  searchedStatus: LoadingStatus;
};

export const initialState: SearchLogState = {
  searchLog: new SearchLog(),
  fetchedStatus: LoadingStatus.Before,
  searchedStatus: LoadingStatus.Before,
};

export default createReducer(initialState, {
  [SEARCH_LOG.REQUEST](state: SearchLogState) {
    return { ...state, searchedStatus: LoadingStatus.Fetching };
  },
  [SEARCH_LOG.SUCCESS](
    state: SearchLogState,
    action: Action<number[]> & { meta: string }
  ) {
    return {
      ...state,
      searchedStatus: LoadingStatus.Success,
      searchLog: new SearchLog({
        ...state.searchLog,
        currentLineIndex: 0,
        matchedLines: action.payload,
        search: action.meta,
      }),
    };
  },
  [SEARCH_LOG_NEXT_LINE](state: SearchLogState) {
    return {
      ...state,
      searchLog: new SearchLog({
        ...state.searchLog,
        currentLineIndex:
          state.searchLog.currentLineIndex >
          state.searchLog.matchedLines.length - 2
            ? 0
            : state.searchLog.currentLineIndex + 1,
      }),
    };
  },
  [SEARCH_LOG_PREV_LINE](state: SearchLogState) {
    return {
      ...state,
      searchLog: new SearchLog({
        ...state.searchLog,
        currentLineIndex:
          state.searchLog.currentLineIndex < 1
            ? state.searchLog.matchedLines.length - 1
            : state.searchLog.currentLineIndex - 1,
      }),
    };
  },
  [ENABLE_LOG_SEARCH](state: SearchLogState) {
    return { ...state, searchLog: new SearchLog({ isEnabled: true }) };
  },
  [DISABLE_LOG_SEARCH]() {
    return { ...initialState };
  },
  [DOWNLOAD_ALL_COMMANDS.REQUEST](state: SearchLogState) {
    return {
      ...state,
      fetchedStatus: LoadingStatus.Fetching,
    };
  },
  [DOWNLOAD_ALL_COMMANDS.SUCCESS](state: SearchLogState) {
    return {
      ...state,
      fetchedStatus: LoadingStatus.Success,
    };
  },
  [DOWNLOAD_ALL_COMMANDS.ERROR](state: SearchLogState) {
    return {
      ...state,
      searchLog: new SearchLog({ isErrored: true }),
      fetchedStatus: LoadingStatus.Failed,
    };
  },
});
