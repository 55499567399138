/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { Children } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { BORDER_WIDTH } from '@atlaskit/avatar';
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { gridSize } from '@atlaskit/theme/constants';
var gutter = BORDER_WIDTH * 2 + gridSize() / 2;
var listStyles = css({
  display: 'flex',
  margin: "var(--ds-space-0, 0px)",
  padding: "var(--ds-space-0, 0px)",
  lineHeight: 1,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
  listStyleType: 'none !important',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  marginInlineEnd: gutter
});
var listItemStyles = css({
  margin: "var(--ds-space-0, 0px)",
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  marginInlineEnd: -gutter
});
var Stack = function Stack(_ref) {
  var children = _ref.children,
    testId = _ref.testId,
    label = _ref['aria-label'];
  return jsx("ul", {
    "data-testid": testId,
    "aria-label": label,
    css: listStyles
  }, Children.map(children, function (child) {
    return child && jsx("li", {
      css: listItemStyles
    }, child);
  }));
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default Stack;