/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { all, takeLatest } from 'redux-saga/effects';

import { ENTERED_CODE_REVIEW } from 'src/redux/pull-request/actions';

import {
  CREATE_PR_JIRA_ISSUE,
  FETCH_CONNECTED_JIRA_SITES,
  FETCH_CREATE_JIRA_ISSUE_ONBOARDING_VIEWED,
  FETCH_CREATE_JIRA_ISSUE_PREFERENCES,
  FETCH_DASHBOARD_DEV_ACTIVITY,
  FETCH_DASHBOARD_JIRA_ISSUES,
  FETCH_DEV_ACTIVITY,
  FETCH_JIRA_ASSIGNEES,
  FETCH_JIRA_ISSUE_CREATION_METADATA,
  FETCH_JIRA_PROJECT,
  FETCH_JIRA_RELEVANT_ISSUES,
  FETCH_JIRA_RELEVANT_PROJECTS,
  FETCH_JIRA_SITES,
  FETCH_PULL_REQUEST_JIRA_ISSUES,
  FETCH_RELEVANT_JIRA_SITES,
  FETCH_WORKSPACE_PERMISSION,
  INIT_CREATE_JIRA_ISSUE,
  INIT_DASHBOARD_JIRA,
  INIT_JIRA_REPO_PAGE,
  INSTALL_ADDON_JIRA_TAB,
  ON_DASHBOARD_JIRA_SITE_CHANGE,
  ON_UNSUPPORTED_FIELDS_ERROR,
  ON_PR_UNSUPPORTED_FIELDS_ERROR,
  UPDATE_CREATE_JIRA_ISSUE_ONBOARDING_VIEWED,
  UPDATE_CREATE_JIRA_ISSUE_PREFERENCES,
  TRANSITION_ISSUES,
  FILTER_STATE,
  FETCH_JIRA_TAB_FILTERS_USER_PREFERENCE,
  FETCH_AVAILABLE_ISSUE_TRANSITIONS,
  FETCH_SITES_FOR_JIRA_MIGRATION,
  CREATE_JIRA_ISSUE,
  START_ISSUE_MIGRATION_TO_JIRA,
  FETCH_ISSUE_MIGRATION_TO_JIRA_LOGS,
  FETCH_JIRA_TAB_CREATE_ISSUE_USER_PREFERENCE,
  INSTALL_ADDON_JIRA_MIGRATION,
  LINK_JIRA_PROJECT,
  UNLINK_JIRA_PROJECT,
  SET_ISSUE_ASSIGNEE,
} from '../actions';

import {
  createPrJiraIssueSaga,
  fetchConnectedJiraSitesSaga,
  fetchCreateJiraIssueOnboardingViewedSaga,
  fetchCreateJiraIssuePreferencesSaga,
  fetchDebouncedJiraProjectsForSiteSaga,
  fetchJiraIssueCreationMetadataSaga,
  fetchJiraProjectSaga,
  handleUnsupportedFieldsErrorSaga,
  handlePrUnsupportedFieldsErrorSaga,
  initCreateJiraIssueSaga,
  updateCreateJiraIssueOnboardingViewedSaga,
  updateCreateJiraIssuePreferencesSaga,
  createJiraIssueSaga,
  fetchJiraTabCreateIssueUserPreferencesSaga,
} from './create-jira-issue-saga';
import {
  fetchDashboardDevActivitySaga,
  fetchDashboardJiraIssuesSaga,
  initDashboardJira,
  onSelectedSiteChangeSaga,
} from './dashboard-jira-issues-saga';
import {
  installAddonJiraTabSaga,
  installAddonJiraMigrationSaga,
} from './install-addon-saga';
import {
  fetchSitesForJiraMigrationSaga,
  startIssueMigrationToJiraSaga,
  issueMigrationToJiraStatusSaga,
  fetchIssueMigrationToJiraLogsSaga,
} from './jira-migration-sagas';
import {
  changeFilterStateSaga,
  debouncedFetchJiraProjectsSaga,
  fetchDevActivitySaga,
  fetchJiraAssigneesSaga,
  fetchJiraRelevantIssuesSaga,
  fetchJiraRelevantProjectsSaga,
  fetchRelevantJiraSitesSaga,
  fetchSitesSaga,
  fetchWorkspacePermissionSaga,
  handleFetchRelevantIssuesSuccessSaga,
  handleFetchRelevantProjectsSuccessSaga,
  handleFetchRelevantSitesSuccessSaga,
  initJiraRepoPageSaga,
  fetchJiraTabFiltersUserPreferencesSaga,
  updateEmptyStateForSelectedSite,
  linkJiraProjectSaga,
  unlinkJiraProjectSaga,
  setIssueAssigneeSaga,
} from './jira-repo-page-saga';
import {
  fetchPullRequestJiraIssuesSaga,
  transitionIssuesSaga,
  initialiseIssueTransitions,
  fetchAvailableIssueTransitionsSaga,
  fetchTransitionUserPreference,
} from './pull-request-jira-issues-saga';

export default function* jiraSagas() {
  yield all([
    takeLatest(ENTERED_CODE_REVIEW, fetchTransitionUserPreference),
    takeLatest(FETCH_JIRA_RELEVANT_ISSUES.REQUEST, fetchJiraRelevantIssuesSaga),
    takeLatest(FETCH_DEV_ACTIVITY.REQUEST, fetchDevActivitySaga),
    takeLatest(FETCH_JIRA_ASSIGNEES.REQUEST, fetchJiraAssigneesSaga),
    takeLatest(INSTALL_ADDON_JIRA_TAB.REQUEST, installAddonJiraTabSaga),
    takeLatest(INIT_JIRA_REPO_PAGE, initJiraRepoPageSaga),
    takeLatest(FETCH_RELEVANT_JIRA_SITES.REQUEST, fetchRelevantJiraSitesSaga),
    takeLatest(
      FETCH_JIRA_RELEVANT_ISSUES.SUCCESS,
      handleFetchRelevantIssuesSuccessSaga
    ),
    takeLatest(
      FETCH_RELEVANT_JIRA_SITES.SUCCESS,
      handleFetchRelevantSitesSuccessSaga
    ),
    takeLatest(FETCH_JIRA_SITES.REQUEST, fetchSitesSaga),
    takeLatest(
      FETCH_JIRA_RELEVANT_PROJECTS.REQUEST,
      fetchJiraRelevantProjectsSaga
    ),
    debouncedFetchJiraProjectsSaga(),
    takeLatest(
      FETCH_JIRA_RELEVANT_PROJECTS.SUCCESS,
      handleFetchRelevantProjectsSuccessSaga
    ),
    takeLatest(
      FETCH_JIRA_RELEVANT_PROJECTS.ERROR,
      updateEmptyStateForSelectedSite
    ),
    takeLatest(
      FETCH_WORKSPACE_PERMISSION.REQUEST,
      fetchWorkspacePermissionSaga
    ),
    takeLatest(FETCH_CONNECTED_JIRA_SITES.REQUEST, fetchConnectedJiraSitesSaga),
    takeLatest(
      FETCH_JIRA_ISSUE_CREATION_METADATA.REQUEST,
      fetchJiraIssueCreationMetadataSaga
    ),
    takeLatest(CREATE_JIRA_ISSUE.REQUEST, createJiraIssueSaga),
    takeLatest(
      FETCH_PULL_REQUEST_JIRA_ISSUES.REQUEST,
      fetchPullRequestJiraIssuesSaga
    ),
    takeLatest(CREATE_PR_JIRA_ISSUE.REQUEST, createPrJiraIssueSaga),
    takeLatest(
      FETCH_PULL_REQUEST_JIRA_ISSUES.REQUEST,
      fetchPullRequestJiraIssuesSaga
    ),
    takeLatest(SET_ISSUE_ASSIGNEE.REQUEST, setIssueAssigneeSaga),
    takeLatest(
      FETCH_AVAILABLE_ISSUE_TRANSITIONS.REQUEST,
      fetchAvailableIssueTransitionsSaga
    ),
    takeLatest(
      FETCH_PULL_REQUEST_JIRA_ISSUES.SUCCESS,
      initialiseIssueTransitions
    ),
    takeLatest(TRANSITION_ISSUES.REQUEST, transitionIssuesSaga),
    takeLatest(ON_UNSUPPORTED_FIELDS_ERROR, handleUnsupportedFieldsErrorSaga),
    takeLatest(
      ON_PR_UNSUPPORTED_FIELDS_ERROR,
      handlePrUnsupportedFieldsErrorSaga
    ),
    takeLatest(
      FETCH_CREATE_JIRA_ISSUE_ONBOARDING_VIEWED.REQUEST,
      fetchCreateJiraIssueOnboardingViewedSaga
    ),
    takeLatest(
      FETCH_JIRA_TAB_FILTERS_USER_PREFERENCE.REQUEST,
      fetchJiraTabFiltersUserPreferencesSaga
    ),
    takeLatest(
      FETCH_JIRA_TAB_CREATE_ISSUE_USER_PREFERENCE.REQUEST,
      fetchJiraTabCreateIssueUserPreferencesSaga
    ),
    takeLatest(
      UPDATE_CREATE_JIRA_ISSUE_ONBOARDING_VIEWED.REQUEST,
      updateCreateJiraIssueOnboardingViewedSaga
    ),
    takeLatest(
      FETCH_CREATE_JIRA_ISSUE_PREFERENCES.REQUEST,
      fetchCreateJiraIssuePreferencesSaga
    ),
    takeLatest(
      UPDATE_CREATE_JIRA_ISSUE_PREFERENCES.REQUEST,
      updateCreateJiraIssuePreferencesSaga
    ),
    takeLatest(INIT_CREATE_JIRA_ISSUE, initCreateJiraIssueSaga),
    takeLatest(INIT_DASHBOARD_JIRA.REQUEST, initDashboardJira),
    takeLatest(ON_DASHBOARD_JIRA_SITE_CHANGE, onSelectedSiteChangeSaga),
    takeLatest(
      FETCH_DASHBOARD_JIRA_ISSUES.REQUEST,
      fetchDashboardJiraIssuesSaga
    ),
    takeLatest(
      FETCH_DASHBOARD_DEV_ACTIVITY.REQUEST,
      fetchDashboardDevActivitySaga
    ),
    takeLatest(FETCH_JIRA_PROJECT.REQUEST, fetchJiraProjectSaga),
    takeLatest(FILTER_STATE.CHANGE, changeFilterStateSaga),
    takeLatest(LINK_JIRA_PROJECT.REQUEST, linkJiraProjectSaga),
    takeLatest(UNLINK_JIRA_PROJECT.REQUEST, unlinkJiraProjectSaga),
    fetchDebouncedJiraProjectsForSiteSaga(),

    // Jira migration sagas
    takeLatest(
      FETCH_SITES_FOR_JIRA_MIGRATION.REQUEST,
      fetchSitesForJiraMigrationSaga
    ),
    takeLatest(
      START_ISSUE_MIGRATION_TO_JIRA.REQUEST,
      startIssueMigrationToJiraSaga
    ),
    takeLatest(
      FETCH_ISSUE_MIGRATION_TO_JIRA_LOGS.REQUEST,
      fetchIssueMigrationToJiraLogsSaga
    ),
    takeLatest(
      INSTALL_ADDON_JIRA_MIGRATION.REQUEST,
      installAddonJiraMigrationSaga
    ),
    issueMigrationToJiraStatusSaga(),
  ]);
}
