import { createSelector, Selector } from 'reselect';

import { LoadGlobal } from 'src/redux/global/actions';
import { BucketState } from 'src/types/state';

export enum DiffViewMode {
  Unified = 'UNIFIED',
  SideBySide = 'SIDE_BY_SIDE',
}

export enum LoadFileMode {
  AllFilesMode = 'ALL_FILES_MODE',
  SingleFileMode = 'SINGLE_FILE_MODE',
}

export enum FileViewMode {
  Tree = 'TREE',
  List = 'LIST',
}

type UpdateSettingsPayload = {
  diffViewMode: DiffViewMode;
  fileViewMode: FileViewMode;
  tabSize: number;
  isWordDiffEnabled: boolean;
  isColorBlindModeEnabled: boolean;
  shouldIgnoreWhitespace: boolean;
  isAnnotationsEnabled: boolean;
  isSingleFileModeEnabled: boolean;
};

// Actions

const TOGGLE_SETTINGS_DIALOG = 'diffSettings/TOGGLE_SETTINGS_DIALOG';
export const UPDATE_GLOBAL_DIFF_VIEW_MODE_SUCCESS =
  'diffSettings/UPDATE_GLOBAL_DIFF_VIEW_MODE_SUCCESS';
export const UPDATE_GLOBAL_FILE_VIEW_MODE_SUCCESS =
  'diffSettings/UPDATE_GLOBAL_FILE_VIEW_MODE_SUCCESS';
export const UPDATE_GLOBAL_TAB_SIZE_SUCCESS =
  'diffSettings/UPDATE_GLOBAL_TAB_SIZE_SUCCESS';
export const UPDATE_GLOBAL_IS_WORD_DIFF_ENABLED_SUCCESS =
  'diffSettings/UPDATE_GLOBAL_IS_WORD_DIFF_ENABLED_SUCCESS';
export const UPDATE_GLOBAL_SHOULD_IGNORE_WHITESPACE_SUCCESS =
  'diffSettings/UPDATE_GLOBAL_SHOULD_IGNORE_WHITESPACE_SUCCESS';
export const UPDATE_GLOBAL_IS_COLOR_BLIND_MODE_ENABLED_SUCCESS =
  'diffSettings/UPDATE_GLOBAL_IS_COLOR_BLIND_MODE_ENABLED_SUCCESS';
export const UPDATE_GLOBAL_IS_ANNOTATIONS_ENABLED_SUCCESS =
  'diffSettings/UPDATE_GLOBAL_IS_ANNOTATIONS_ENABLED_SUCCESS';
export const UPDATE_GLOBAL_IS_SINGLE_FILE_MODE_ENABLED_SUCCESS =
  'diffSettings/UPDATE_GLOBAL_IS_SINGLE_FILE_MODE_ENABLED_SUCCESS';
export const UPDATE_SETTINGS_REQUEST = 'diffSettings/UPDATE_SETTINGS_REQUEST';
export const UPDATE_SETTINGS_SUCCESS = 'diffSettings/UPDATE_SETTINGS_SUCCESS';

type ToggleSettingsDialogAction = {
  readonly type: typeof TOGGLE_SETTINGS_DIALOG;
  payload: { isOpen: boolean };
};

export type UpdateGlobalDiffViewModeSuccessAction = {
  readonly type: typeof UPDATE_GLOBAL_DIFF_VIEW_MODE_SUCCESS;
  payload: { diffViewMode: DiffViewMode };
};

export type UpdateGlobalFileViewModeSuccessAction = {
  readonly type: typeof UPDATE_GLOBAL_FILE_VIEW_MODE_SUCCESS;
  payload: { fileViewMode: FileViewMode };
};

export type UpdateGlobalTabSizeSuccessAction = {
  readonly type: typeof UPDATE_GLOBAL_TAB_SIZE_SUCCESS;
  payload: { tabSize: number };
};

export type UpdateGlobalIsWordDiffEnabledSuccessAction = {
  readonly type: typeof UPDATE_GLOBAL_IS_WORD_DIFF_ENABLED_SUCCESS;
  payload: { isWordDiffEnabled: boolean };
};

export type UpdateGlobalIsColorBlindNodeEnabledSuccessAction = {
  readonly type: typeof UPDATE_GLOBAL_IS_COLOR_BLIND_MODE_ENABLED_SUCCESS;
  payload: { isColorBlindModeEnabled: boolean };
};

export type UpdateGlobalShouldIgnoreWhitespaceSuccessAction = {
  readonly type: typeof UPDATE_GLOBAL_SHOULD_IGNORE_WHITESPACE_SUCCESS;
  payload: { shouldIgnoreWhitespace: boolean };
};

export type UpdateGlobalIsAnnotationsEnabledSuccessAction = {
  readonly type: typeof UPDATE_GLOBAL_IS_ANNOTATIONS_ENABLED_SUCCESS;
  payload: { isAnnotationsEnabled: boolean };
};

export type UpdateGlobalIsSingleFileModeEnabledSuccessAction = {
  readonly type: typeof UPDATE_GLOBAL_IS_SINGLE_FILE_MODE_ENABLED_SUCCESS;
  payload: { isSingleFileModeEnabled: boolean };
};

export type UpdateSettingsRequestAction = {
  readonly type: typeof UPDATE_SETTINGS_REQUEST;
  payload: UpdateSettingsPayload;
};

export type UpdateSettingsSuccessAction = {
  readonly type: typeof UPDATE_SETTINGS_SUCCESS;
};

type DiffSettingsAction =
  | ToggleSettingsDialogAction
  | UpdateGlobalDiffViewModeSuccessAction
  | UpdateGlobalFileViewModeSuccessAction
  | UpdateGlobalTabSizeSuccessAction
  | UpdateGlobalIsWordDiffEnabledSuccessAction
  | UpdateGlobalShouldIgnoreWhitespaceSuccessAction
  | UpdateGlobalIsColorBlindNodeEnabledSuccessAction
  | UpdateGlobalIsAnnotationsEnabledSuccessAction
  | UpdateGlobalIsSingleFileModeEnabledSuccessAction
  | UpdateSettingsRequestAction
  | UpdateSettingsSuccessAction;

// Action Creators

export const toggleSettingsDialog = (isOpen: boolean) => ({
  type: TOGGLE_SETTINGS_DIALOG,
  payload: { isOpen },
});

export const updateSettings = (
  settings: UpdateSettingsPayload
): UpdateSettingsRequestAction => ({
  type: UPDATE_SETTINGS_REQUEST,
  payload: settings,
});

// Selectors + Reducer

const initialState = {
  globalDiffViewMode: DiffViewMode.Unified,
  globalFileViewMode: FileViewMode.Tree,
  globalTabSize: 6,
  globalIsWordDiffEnabled: true,
  globalShouldIgnoreWhitespace: false,
  globalIsColorBlindModeEnabled: false,
  globalIsAnnotationsEnabled: true,
  globalIsSingleFileModeEnabled: false,
  isDialogLoading: false,
  isDialogOpen: false,
};

export type DiffSettingsState = typeof initialState;

const getDiffSettingsSlice = (state: BucketState) => state.diffSettings;

export const getIsDiffSettingsDialogLoading: Selector<BucketState, boolean> =
  createSelector(
    getDiffSettingsSlice,
    (state: DiffSettingsState) => state.isDialogLoading
  );

export const getIsDiffSettingsDialogOpen: Selector<BucketState, boolean> =
  createSelector(
    getDiffSettingsSlice,
    (state: DiffSettingsState) => state.isDialogOpen
  );

export const getGlobalDiffViewMode: Selector<BucketState, DiffViewMode> =
  createSelector(
    getDiffSettingsSlice,
    (state: DiffSettingsState) => state.globalDiffViewMode
  );

export const getGlobalFileViewMode: Selector<BucketState, FileViewMode> =
  createSelector(
    getDiffSettingsSlice,
    (state: DiffSettingsState) => state.globalFileViewMode
  );

export const getGlobalTabSize: Selector<BucketState, number> = createSelector(
  getDiffSettingsSlice,
  (state: DiffSettingsState) => state.globalTabSize
);

export const getGlobalIsWordDiffEnabled: Selector<BucketState, boolean> =
  createSelector(
    getDiffSettingsSlice,
    (state: DiffSettingsState) => state.globalIsWordDiffEnabled
  );

export const getGlobalIsColorBlindModeEnabled: Selector<BucketState, boolean> =
  createSelector(
    getDiffSettingsSlice,
    (state: DiffSettingsState) => state.globalIsColorBlindModeEnabled
  );

export const getGlobalShouldIgnoreWhitespace: Selector<BucketState, boolean> =
  createSelector(
    getDiffSettingsSlice,
    (state: DiffSettingsState) => state.globalShouldIgnoreWhitespace
  );

export const getGlobalIsAnnotationsEnabled: Selector<BucketState, boolean> =
  createSelector(
    getDiffSettingsSlice,
    (state: DiffSettingsState) => state.globalIsAnnotationsEnabled
  );

export const getGlobalIsSingleFileModeEnabled: Selector<BucketState, boolean> =
  createSelector(
    getDiffSettingsSlice,
    (state: DiffSettingsState) => state.globalIsSingleFileModeEnabled
  );

export const reducer = (
  state: DiffSettingsState = initialState,
  action: DiffSettingsAction
): DiffSettingsState => {
  switch (action.type) {
    case LoadGlobal.SUCCESS: {
      // User preference keys are legacy naming, so settings apply to all diffs,
      // not just pull request diffs
      const {
        isPullRequestIgnoreWhitespaceEnabled,
        isPullRequestWordDiffEnabled,
        pullRequestDiffViewMode,
        pullRequestFileViewMode,
        pullRequestDiffTabSize,
        isPullRequestColorBlindModeEnabled,
        isPullRequestAnnotationsEnabled,
        isPullRequestSingleFileModeEnabled,
        // The reducer takes advantage of const assertions and discriminated unions, and AsyncActions
        // like `LoadGlobal` aren't yet compatible with those
        // @ts-ignore
      } = action.payload.result;

      return {
        ...state,
        // explicitly make sure a valid enum is used (the preference value from the server can technically be anything)
        globalDiffViewMode:
          pullRequestDiffViewMode === DiffViewMode.SideBySide
            ? DiffViewMode.SideBySide
            : state.globalDiffViewMode,
        // explicitly make sure a valid enum is used (the preference value from the server can technically be anything)
        globalFileViewMode:
          pullRequestFileViewMode === FileViewMode.Tree
            ? FileViewMode.Tree
            : pullRequestFileViewMode === FileViewMode.List
            ? FileViewMode.List
            : state.globalFileViewMode,
        globalTabSize:
          pullRequestDiffTabSize !== null
            ? pullRequestDiffTabSize
            : state.globalTabSize,
        globalIsWordDiffEnabled: isPullRequestWordDiffEnabled,
        globalShouldIgnoreWhitespace: isPullRequestIgnoreWhitespaceEnabled,
        globalIsColorBlindModeEnabled: isPullRequestColorBlindModeEnabled,
        globalIsAnnotationsEnabled: isPullRequestAnnotationsEnabled,
        globalIsSingleFileModeEnabled: isPullRequestSingleFileModeEnabled,
      };
    }

    case TOGGLE_SETTINGS_DIALOG: {
      return {
        ...state,
        isDialogOpen: action.payload.isOpen,
      };
    }

    case UPDATE_GLOBAL_DIFF_VIEW_MODE_SUCCESS: {
      return {
        ...state,
        globalDiffViewMode: action.payload.diffViewMode,
      };
    }

    case UPDATE_GLOBAL_FILE_VIEW_MODE_SUCCESS: {
      return {
        ...state,
        globalFileViewMode: action.payload.fileViewMode,
      };
    }

    case UPDATE_GLOBAL_TAB_SIZE_SUCCESS: {
      return {
        ...state,
        globalTabSize: action.payload.tabSize,
      };
    }

    case UPDATE_GLOBAL_IS_WORD_DIFF_ENABLED_SUCCESS: {
      return {
        ...state,
        globalIsWordDiffEnabled: action.payload.isWordDiffEnabled,
      };
    }

    case UPDATE_GLOBAL_IS_COLOR_BLIND_MODE_ENABLED_SUCCESS: {
      return {
        ...state,
        globalIsColorBlindModeEnabled: action.payload.isColorBlindModeEnabled,
      };
    }

    case UPDATE_GLOBAL_SHOULD_IGNORE_WHITESPACE_SUCCESS: {
      return {
        ...state,
        globalShouldIgnoreWhitespace: action.payload.shouldIgnoreWhitespace,
      };
    }

    case UPDATE_GLOBAL_IS_ANNOTATIONS_ENABLED_SUCCESS: {
      return {
        ...state,
        globalIsAnnotationsEnabled: action.payload.isAnnotationsEnabled,
      };
    }

    case UPDATE_GLOBAL_IS_SINGLE_FILE_MODE_ENABLED_SUCCESS: {
      return {
        ...state,
        globalIsSingleFileModeEnabled: action.payload.isSingleFileModeEnabled,
      };
    }

    case UPDATE_SETTINGS_REQUEST: {
      return {
        ...state,
        isDialogLoading: true,
      };
    }

    case UPDATE_SETTINGS_SUCCESS: {
      return {
        ...state,
        isDialogLoading: false,
        isDialogOpen: false,
      };
    }

    default:
      return state;
  }
};
