/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { gridSize, typography } from '@atlaskit/theme';

// Most of this is taken directly from Atlaskit PageHeader
// https://bitbucket.org/atlassian/atlassian-frontend/src/master/packages/design-system/page-header/src/PageHeader/styled.ts

interface StyledProps {
  truncate?: boolean;
}

const getTruncationStyles = ({ truncate }: StyledProps) =>
  truncate
    ? `
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `
    : null;

// Normally one should NEVER use outline: none but we are manually setting focus on a non-interactive element for screen readers.
// http://www.outlinenone.com/
export const StyledTitle = styled.h1`
  ${typography.h700() as any};
  line-height: ${gridSize() * 4}px;
  margin-top: 0;
  outline: none;
  ${getTruncationStyles}
`;
