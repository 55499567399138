import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import AnalyticsWebClient, { envType } from '@atlassiansox/analytics-web-client';

// eslint-disable-next-line @atlassian/tangerine/import/no-restricted-paths

import { getBscGlobalState } from '../bsc-global-state';
import { Logger } from '../logger';
import { packageName, packageVersion } from '../package-info';
import { withAnalyticsEventSampling } from './with-sampling';
var getEnvironment = function getEnvironment() {
  var hostname = window.location.hostname;
  if (hostname.includes('.dev.') || hostname.includes('.stg.')) {
    return envType.STAGING;
  }
  if (hostname.includes('localhost') || hostname.includes('ngrok') || hostname.includes('atlastunnel')) {
    return envType.DEV;
  }
  return envType.PROD;
};

// As many Atlassian products all have the same origin and we don't want the whole path for security reasons
// we'll extract the subpath for some additional context around our products if it exists
export var getProductSubPath = function getProductSubPath(path) {
  var subPath = '';
  if (!path) {
    return subPath;
  }
  var checkPath = function checkPath(match) {
    return new RegExp("^".concat(match, "(/|$)")).test(String(path));
  };
  if (checkPath('/wiki')) {
    subPath = '/wiki';
  }
  if (checkPath('/jira')) {
    subPath = '/jira';
  }
  if (checkPath('/compass')) {
    subPath = '/compass';
  }
  if (checkPath('/canvas')) {
    subPath = '/canvas';
  }
  if (checkPath('/servicedesk') || checkPath('/jira/servicedesk')) {
    subPath = '/servicedesk';
  }
  return subPath;
};
var getProductFromDomain = function getProductFromDomain(domain) {
  if (domain === 'https://trello.com') {
    return 'trello';
  }
  return null;
};
export var getContextualPageDetails = function getContextualPageDetails() {
  var _window$location = window.location,
    origin = _window$location.origin,
    pathname = _window$location.pathname;
  var productPath = getProductSubPath(pathname);
  var domain = "".concat(origin).concat(productPath);
  var product = getProductFromDomain(domain) || productPath.slice(1);
  return {
    domain: domain,
    product: product
  };
};
var decorateEventAttributes = function decorateEventAttributes(attributes) {
  var _getBscGlobalState$pr, _getBscGlobalState$pr2;
  var _getContextualPageDet = getContextualPageDetails(),
    domain = _getContextualPageDet.domain,
    product = _getContextualPageDet.product;
  return _objectSpread({
    packageName: packageName,
    packageVersion: packageVersion,
    domain: domain,
    product: (_getBscGlobalState$pr = (_getBscGlobalState$pr2 = getBscGlobalState().product) === null || _getBscGlobalState$pr2 === void 0 ? void 0 : _getBscGlobalState$pr2.toLowerCase()) !== null && _getBscGlobalState$pr !== void 0 ? _getBscGlobalState$pr : product
  }, attributes && attributes);
};
export var getAnalyticsClient = function () {
  var instance;
  return function () {
    try {
      if (!instance && getBscGlobalState().analyticsEnabled) {
        instance = new AnalyticsWebClient({
          env: getEnvironment(),
          product: 'atlassianCookies'
        }, {
          // useLegacyUrl tells the analytics client to make calls directly to Global Analytics Service.
          // When set to false, the client will use a stargate proxy for the current URL to send events
          // to GAS. Said another way:
          // true => events are sent to https://api-private.stg.atlassian.com/gasv3/api/v1/batch
          // false => events are sent to <the current domain>/gasv3/api/v1/batch
          //
          // By default, this should be set to false.  If you need to validate that analytics events
          // are being transmitted correctly in local dev, you can temporarily enable it since localhost
          // won't be able to proxy to GAS via stargate.
          useLegacyUrl: false,
          disableCookiePersistence: true
        });
      }
    } catch (e) {
      // Log  errors thrown in constructor initialization for the AnalyticsWebClient
      Logger.error("Failed to initialize AnalyticsWebClient. ".concat(e));
    }
    if (!getBscGlobalState().analyticsEnabled) {
      return undefined;
    }
    // Return the instance, even if it is undefined
    return instance;
  };
}();
export function sendPackageOperationalEvent(properties) {
  withAnalyticsEventSampling(properties.action, function () {
    var _getAnalyticsClient;
    return (_getAnalyticsClient = getAnalyticsClient()) === null || _getAnalyticsClient === void 0 ? void 0 : _getAnalyticsClient.sendOperationalEvent({
      source: 'package',
      actionSubject: 'package',
      action: properties.action,
      attributes: decorateEventAttributes(properties.attributes)
    });
  });
}
export function sendUIEvent(properties) {
  var _getAnalyticsClient2;
  (_getAnalyticsClient2 = getAnalyticsClient()) === null || _getAnalyticsClient2 === void 0 || _getAnalyticsClient2.sendUIEvent(_objectSpread(_objectSpread({}, properties), {}, {
    attributes: decorateEventAttributes(properties.attributes)
  }));
}
export function sendScreenEvent(properties) {
  var _getAnalyticsClient3;
  (_getAnalyticsClient3 = getAnalyticsClient()) === null || _getAnalyticsClient3 === void 0 || _getAnalyticsClient3.sendScreenEvent(_objectSpread(_objectSpread({}, properties), {}, {
    attributes: decorateEventAttributes(properties.attributes)
  }));
}
export function sendTrackEvent(properties) {
  var _getAnalyticsClient4;
  (_getAnalyticsClient4 = getAnalyticsClient()) === null || _getAnalyticsClient4 === void 0 || _getAnalyticsClient4.sendTrackEvent(_objectSpread(_objectSpread({}, properties), {}, {
    attributes: decorateEventAttributes(properties.attributes)
  }));
}