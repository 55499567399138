import store from 'src/utils/store';

import refreshToken from './refresh-token';

export type BitbucketApiClientOptions = {
  token: string | null | undefined;
  expiration: number | null | undefined;
};

const TOKEN_EXPIRY_THRESHOLD = 30000;

function anonymousToken() {
  const tokenDetails: BitbucketApiClientOptions = {
    token: null,
    expiration: null,
  };

  return tokenDetails;
}

export function parseTokenDetailsFromMetaTag(): BitbucketApiClientOptions {
  const tokenMetaTag = document.querySelector('meta[name=apitoken]');

  const rawTokenDetails =
    tokenMetaTag && tokenMetaTag.hasAttribute('content')
      ? tokenMetaTag.getAttribute('content')
      : null;

  if (rawTokenDetails) {
    try {
      const tokenDetails = JSON.parse(rawTokenDetails);

      // convert tokenDetails expiration to milliseconds
      if (tokenDetails.expiration) {
        tokenDetails.expiration *= 1000;
      }

      return tokenDetails;
    } catch (ignore) {
      return anonymousToken();
    }
  }

  return anonymousToken();
}

// This function compares the expiration of the token that we extracted from
// the meta tag with the token in local storage. It then updates local
// storage with the latest token and returns the latest token.
function getLatestToken(
  userUuid: string,
  tokenFromMetaTag: BitbucketApiClientOptions
) {
  const tokenStored = store.get(`api-token-data:${userUuid}`);
  let latestToken;

  if (tokenStored && tokenStored.expiration && tokenFromMetaTag.expiration) {
    if (tokenStored.expiration > tokenFromMetaTag.expiration) {
      // The stored token is newer than the token from meta tag
      latestToken = tokenStored;
    } else {
      // Update the store with the newer token from meta tag
      store.set(`api-token-data:${userUuid}`, tokenFromMetaTag);
      latestToken = tokenFromMetaTag;
    }
  } else if (!tokenStored && tokenFromMetaTag.expiration) {
    // No stored token and the token from meta tag is NOT the anonymous token
    store.set(`api-token-data:${userUuid}`, tokenFromMetaTag);
    latestToken = tokenFromMetaTag;
  } else if (tokenStored && !tokenFromMetaTag.expiration) {
    // Stored token and the token from meta tag is the anonymous token
    latestToken = tokenStored;
  } else {
    // No stored token and the token from meta tag is the anonymous token
    latestToken = tokenFromMetaTag;
  }

  return latestToken;
}

function isExpired(tokenDetails: BitbucketApiClientOptions) {
  // The token is expired if now is after (>) the expiration time.
  // We include a threshold so that we refresh the token before it
  // actually expires.
  return (
    !tokenDetails.expiration ||
    Date.now() + TOKEN_EXPIRY_THRESHOLD > tokenDetails.expiration
  );
}

export const getTokenDetails = async (userUuid?: string) => {
  // Anonymous users have no token
  if (!userUuid) {
    return anonymousToken();
  }

  const tokenFromMetaTag = parseTokenDetailsFromMetaTag();
  const latestToken = getLatestToken(userUuid, tokenFromMetaTag);

  if (isExpired(latestToken)) {
    const refreshedToken = await refreshToken(latestToken);
    store.set(`api-token-data:${userUuid}`, refreshedToken);

    return refreshedToken;
  }

  return latestToken;
};

// @ts-ignore TODO: fix noImplicitAny error here
export const getAuthHeader = options => {
  // Anonymous user have no token
  if (!options.token) {
    return {};
  }

  return {
    Authorization: `Bearer ${options.token ? options.token : ''}`,
  };
};
