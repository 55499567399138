import { createResource, RouterContext } from 'react-resource-router';

import authRequest from 'src/utils/fetch';

import { WorkspaceHookListApiModel } from '../types';

export const loadWorkspaceWebhooksResource = createResource({
  type: 'workspaceWebhooks',
  getKey: ({ match }: RouterContext) =>
    `workspaceWebhooks:${match.params.repositoryOwner}`,
  maxAge: 60000, // cache workspace hooks for 1 minute so navigating between pages is fast
  getData: async ({ match }) => {
    const { repositoryOwner } = match.params;
    if (!repositoryOwner) {
      throw new Error(
        'Could not fetch repository webhooks, repositoryOwner URL param was not available'
      );
    }
    const resp = await fetch(
      authRequest(`/!api/2.0/workspaces/${repositoryOwner}/hooks/`)
    );
    if (!resp.ok) {
      // The workspace hooks API can return a 403 if the current user is not the owner of the
      // current repo workspace. In that case, just return an error so the an error is shown
      // in the UI explaining this.
      throw new Error('Could not fetch workspace webhooks');
    }
    const body: WorkspaceHookListApiModel = await resp.json();
    return body.values;
  },
});
