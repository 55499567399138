import { combineReducers } from 'redux';

import { MenuItem, createNestedMenu } from '@atlassian/bitbucket-navigation';

import { createAsyncAction, hydrateAction } from 'src/redux/actions';
import { project } from 'src/sections/repository/schemas';
import createReducer from 'src/utils/create-reducer';

export type ProjectSectionState = {
  currentProject: string | null | undefined;
  menuItems: MenuItem[];
};

export type ProjectState = {
  section: ProjectSectionState;
};

// Action Types
export const LoadProjectPage = createAsyncAction('project/LOAD_PROJECT_PAGE');
export const UNLOAD_PROJECT = 'project/UNLOAD_PROJECT';

// Action Creators
export const stateKey = ['project.section', 'section.project'];
export const loadProjectPage = (workspaceSlug: string, projectKey: string) =>
  hydrateAction(LoadProjectPage, stateKey, {
    url: `/${workspaceSlug}/workspace/projects/${projectKey}`,
    schema: {
      currentProject: project,
    },
  });

// Reducer
const initialState: ProjectSectionState = {
  currentProject: null,
  menuItems: [],
};

const section = createReducer(initialState, {
  [LoadProjectPage.SUCCESS](state, action) {
    const { menuItems, ...result } = action.payload.result;
    return {
      ...state,
      ...result,
      ...(menuItems && { menuItems: createNestedMenu(menuItems) }),
    };
  },

  [UNLOAD_PROJECT](state) {
    return { ...state, currentProject: null };
  },
});

export const reducer = combineReducers<ProjectState>({
  section,
});
