import React from 'react';

import { CustomGlyphProps } from '@atlaskit/icon/types';

export const HaltedStatusIcon = (props: CustomGlyphProps): JSX.Element => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16">
      <path
        d="M15.367,4.898 C15.789,5.883 16,6.917 16,8 C16,9.083 15.786,10.12 15.359,11.109 C14.932,12.099 14.362,12.951 13.648,13.664 C12.934,14.377 12.085,14.945 11.101,15.367 C10.117,15.789 9.083,16 8,16 C6.917,16 5.879,15.786 4.89,15.359 C3.901,14.932 3.049,14.362 2.335,13.648 C1.622,12.935 1.054,12.086 0.632,11.102 C0.21,10.117 -1.77635684e-15,9.083 -1.77635684e-15,8 C-1.77635684e-15,6.917 0.213,5.88 0.64,4.891 C1.067,3.901 1.637,3.05 2.351,2.336 C3.065,1.623 3.914,1.055 4.898,0.633 C5.882,0.211 6.917,0 8,0 C9.083,0 10.12,0.214 11.109,0.641 C12.098,1.068 12.95,1.638 13.664,2.352 C14.377,3.065 14.945,3.914 15.367,4.898 Z M5,4.66666667 L5,11.3333333 L7,11.3333333 L7,4.66666667 L5,4.66666667 Z M9,4.66666667 L9,11.3333333 L11,11.3333333 L11,4.66666667 L9,4.66666667 Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};
