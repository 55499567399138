import authRequest, { jsonHeaders } from 'src/utils/fetch';
import HttpRequestError, {
  InternalServerError,
} from 'src/utils/http-request-error';

export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export type ThrowErrorType = 'none' | 'json' | 'text' | 'errorObject';

export interface Options<T> {
  bodyPreformatter?: (data: any) => any;
  throwErrorType?: ThrowErrorType;
  customResponseHandler?: (response: Response) => any;
  errorTransformer?: (error: any) => any;
  successCustomHandler?: (response: Response) => any;
  successTransformer?: (json: any) => T;
  headers?: any;
}

export interface CreateHttpRequest {
  <T>(method: 'GET', options?: Options<T>): (url: string) => Promise<T>;
  <T>(method: 'POST', options?: Options<T>): (
    url: string,
    body: Partial<T> | any
  ) => Promise<T>;
  <T>(method: 'PUT', options?: Options<T>): (
    url: string,
    body?: Partial<T> | any
  ) => Promise<T>;
  <T>(method: 'DELETE', options?: Options<T>): (
    url: string,
    body?: Partial<T> | any
  ) => Promise<undefined>;
}

export type RequestOptions<T> = Options<T> & {
  method?: HTTPMethod;
  body?: any;
};

export const request = async <T = any>(
  url: string,
  { method = 'GET', body, ...options }: RequestOptions<T> = {}
): Promise<T | undefined> => {
  const {
    bodyPreformatter,
    customResponseHandler,
    throwErrorType = 'json',
    errorTransformer,
    successTransformer,
    successCustomHandler,
    headers,
  } = options || {};

  let bodyToUse = body;

  if (bodyPreformatter) {
    bodyToUse = bodyPreformatter(body);
  } else if (typeof body === 'object') {
    bodyToUse = JSON.stringify(body);
  }

  if (['POST', 'PUT'].includes(method) && !body) {
    bodyToUse = JSON.stringify({});
  }

  const response = await fetch(
    authRequest(url, {
      method,
      headers: headers || jsonHeaders,
      body: bodyToUse,
    })
  );

  if (customResponseHandler) {
    return customResponseHandler(response);
  }

  if (!response.ok) {
    if (response.status === 500) {
      throw InternalServerError;
    }

    if (throwErrorType === 'none') {
      if (errorTransformer) {
        throw errorTransformer(response);
      }
    }

    if (throwErrorType === 'json') {
      const errorResult = (await response.json()) || {};
      if (errorTransformer) {
        throw errorTransformer(errorResult);
      } else {
        errorResult.status = response.status;
        throw errorResult;
      }
    }

    if (throwErrorType === 'text') {
      const errorText = await response.text();
      throw errorText;
    }

    if (throwErrorType === 'errorObject') {
      const errorText = await response.text();
      const errorResult = new (HttpRequestError as any)(
        response.status,
        errorText
      );
      throw errorResult;
    }
  }

  if (method !== 'DELETE') {
    if (successCustomHandler) {
      return successCustomHandler(response);
    }

    const result = await response.json();

    if (successTransformer) {
      return successTransformer(result);
    }

    return result;
  }

  return undefined;
};

/**
 * @param method describes HTTP method name
 * @param options response handling options
 *
 * @template T basically the endpoint's data model
 *
 * Usage example:
 * ```typescript
 * const responseGet = createHttpRequest<T>('GET')
 * const responseSingleGet = createHttpRequest<T>('GET')
 * const responsePost = createHttpRequest<T>('POST')
 * const responsePut = createHttpRequest<T>('PUT')
 * const responseDelete = createHttpRequest('DELETE')
 * ```
 *
 * @returns function to create HTTP request
 */
export const createHttpRequest: CreateHttpRequest =
  (method: HTTPMethod, options: Options<any>) =>
  /**
   * @param url endpoint url
   * @param body data to be send
   *
   * @template T data model
   *
   * Usage example:
   * ```typescript
   * TODO: add examples here
   * ```
   *
   * @returns Promise<T | T[] | undefined>
   */
  (url: string, body?: any) =>
    request(url, { ...options, method, body });

/**
 * TODO: add doc for httpRequest usage
 * @template T endpoint data model
 * @returns object of HTTP methods ready for usage
 */
export const httpRequest = <T>(options?: Options<T>) => ({
  get: createHttpRequest<T>('GET', options),
  post: createHttpRequest<T>('POST', options),
  put: createHttpRequest<T>('PUT', options),
  delete: createHttpRequest<T>('DELETE', options),
});

interface FullOptions<T> extends Options<T> {
  getAll?: Options<T[]>;
  get?: Options<T>;
  post?: Options<T>;
  put?: Options<T>;
  delete?: Options<T>;
}

/**
 * TODO: add doc for createAPI usage
 * @param endpointUrl endpoint url string
 * @template T endpoint data model
 * @returns object of API methods for the current url ready for usage
 */
export const createAPI = <
  T extends { id?: number | string; uuid?: number | string }
>(
  endpointUrl: string,
  options?: FullOptions<T>
) => {
  let commonOptions = {};
  if (options) {
    const {
      headers,
      bodyPreformatter,
      throwErrorType,
      customResponseHandler,
      errorTransformer,
      successTransformer,
      successCustomHandler,
    } = options;

    commonOptions = {
      headers,
      bodyPreformatter,
      throwErrorType,
      customResponseHandler,
      errorTransformer,
      successTransformer,
      successCustomHandler,
    };
  }

  return {
    getAll: () =>
      httpRequest<T[]>({ ...commonOptions, ...options?.getAll }).get(
        endpointUrl
      ),
    get: (id?: number | string) =>
      httpRequest<T>({ ...commonOptions, ...options?.get }).get(
        `${endpointUrl}${id || ''}`
      ),
    post: (data: Partial<T> | any) =>
      httpRequest<T>({ ...commonOptions, ...options?.post }).post(
        endpointUrl,
        data
      ),
    put: (data?: Partial<T> | any) =>
      httpRequest<T>({ ...commonOptions, ...options?.put }).put(
        `${endpointUrl}${data.id}`,
        data
      ),
    delete: (id: number | string, data?: Partial<T> | any) =>
      httpRequest<T>({ ...commonOptions, ...options?.delete }).delete(
        `${endpointUrl}${id}`,
        data
      ),
  };
};
