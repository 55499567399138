import { defineMessages } from 'react-intl';

export default defineMessages({
  missingSection: {
    id: 'frontbucket.pipelines.components.warningMessage.missingSection',
    description: 'Text for missing section',
    defaultMessage:
      'The {parentElement} section in your {ymlFileName} file is missing a {element}. Add the missing {element} to get this working.',
  },
  missingString: {
    id: 'frontbucket.pipelines.components.warningMessage.missingString',
    description: 'Text for missing string specific',
    defaultMessage:
      'The {element} in the {parentElement} section of your {ymlFileName} file is missing a string. Add the missing string to to get this working.',
  },
  pipelineErrorText: {
    id: 'frontbucket.pipelines.components.warningMessage.pipelineErrorText',
    description: 'Pipeline error text',
    defaultMessage: 'Unable to retrieve Pipeline.',
  },
  memoryLimit: {
    id: 'frontbucket.pipelines.components.warningMessage.memoryLimit',
    description: 'Text for memory limit',
    defaultMessage: 'memory limit',
  },

  improvedErrorMappingEnabledText: {
    id: 'frontbucket.pipelines.components.warningMessage.improvedErrorMappingEnabledText',
    description: 'Text for improved error mapping enabled',
    defaultMessage:
      "You've exceeded the maximum number of concurrent steps {linkOne}. Any steps in excess of this limit have failed. This may indicate you're running too many pipelines at once, or pipeline {linkTwo}.",
  },
  ImprovedErrorLinkOneText: {
    id: 'frontbucket.pipelines.components.warningMessage.ImprovedErrorLinkOneText',
    description: 'Text for improved error link one',
    defaultMessage: 'supported by your plan',
  },
  ImprovedErrorLinkTwoText: {
    id: 'frontbucket.pipelines.components.warningMessage.ImprovedErrorLinkTwoText',
    description: 'Text for improved error link two',
    defaultMessage: 'contains too many parallel steps',
  },
  improvedErrorMappingDisabledText: {
    id: 'frontbucket.pipelines.components.warningMessage.improvedErrorMappingDisabledText',
    description: 'Text for improved error mapping disabled',
    defaultMessage:
      "Seems like you're running a lot of pipelines at the same time. This is usually due to an accidental push. To conserve your minutes, we won't run them all.",
  },
  troubleShootingSteps: {
    id: 'frontbucket.pipelines.components.warningMessage.troubleShootingSteps',
    description: 'Text for trouble shooting steps',
    defaultMessage: 'Troubleshooting Steps',
  },
  timeLimitExceeded: {
    id: 'frontbucket.pipelines.components.warningMessage.timeLimitExceeded',
    description: 'Text for time limit exceeded',
    defaultMessage:
      'The build stopped because one or more steps reached the timeout limit of two hours.',
  },
  parseError: {
    id: 'frontbucket.pipelines.components.warningMessage.parseError',
    description: 'Text for parse error',
    defaultMessage:
      'Something is misconfigured in your {ymlFileName} file. View it in our editor to find out where.',
  },
  parseIndentationError: {
    id: 'frontbucket.pipelines.components.warningMessage.parseIndentationError',
    description: 'Text for parse indentation error',
    defaultMessage:
      'The indentation in the {ymlFileName} file is incorrect. Correct the spacing and remove any tabs.',
  },
  invalidImageName: {
    id: 'frontbucket.pipelines.components.warningMessage.invalidImageName',
    description: 'Text for invalid image name',
    defaultMessage:
      "We don't recognise the image name {element} in your {ymlFileName} file. Correct the name to get this working.",
  },
  tooManySteps: {
    id: 'frontbucket.pipelines.components.warningMessage.tooManySteps',
    description: 'Text for too many steps',
    defaultMessage:
      '{stepSizeLimitLink}. To run this pipeline, reduce the total number of steps',
  },
  stepSizeLimit: {
    id: 'frontbucket.pipelines.components.warningMessage.stepSizeLimit',
    description: 'Text for step size limit',
    defaultMessage: 'A pipeline cannot exceed 100 steps',
  },
  checkDocumentationText: {
    id: 'frontbucket.pipelines.components.warningMessage.checkDocumentationText',
    description: 'Text for check documentation',
    defaultMessage:
      'Check out our documentation on how to format the {element} section.',
  },
  invalidList: {
    id: 'frontbucket.pipelines.components.warningMessage.invalidList',
    description: 'Text for invalid list',
    defaultMessage:
      'The {element} section in your {ymlFileName} file must be a list.',
  },
  invalidMap: {
    id: 'frontbucket.pipelines.components.warningMessage.invalidMap',
    description: 'Text for invalid map',
    defaultMessage:
      'The {element} section in your {ymlFileName} file must be a map.',
  },
});
