import _extends from "@babel/runtime/helpers/extends";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
/**
 * @jsxRuntime classic
 */
/** @jsx jsx */
import { Fragment } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { UNSAFE_media } from '@atlaskit/primitives/responsive';
import { BANNER, BANNER_HEIGHT, CONTENT, DEFAULT_I18N_PROPS_SKIP_LINKS, LEFT_PANEL, LEFT_PANEL_WIDTH, PAGE_LAYOUT_CONTAINER_SELECTOR, RIGHT_PANEL, RIGHT_PANEL_WIDTH, TOP_NAVIGATION, TOP_NAVIGATION_HEIGHT } from '../../common/constants';
import { SidebarResizeController, SkipLinksController } from '../../controllers';
import { SkipLinkWrapper } from '../skip-links';
var pageLayoutSelector = _defineProperty({}, PAGE_LAYOUT_CONTAINER_SELECTOR, true);
var gridTemplateAreasMobile = "\n".concat("\n  \"", LEFT_PANEL, " ").concat(BANNER, "\"\n  ", "\n  \"").concat(LEFT_PANEL, " ").concat(TOP_NAVIGATION, "\"\n  ", "\n  \"").concat(LEFT_PANEL, " ").concat(CONTENT, "\"\n ");
var gridTemplateAreas = "\n".concat("\n  \"", LEFT_PANEL, " ").concat(BANNER, " ").concat(RIGHT_PANEL, "\"\n  ", "\n  \"").concat(LEFT_PANEL, " ").concat(TOP_NAVIGATION, " ").concat(RIGHT_PANEL, "\"\n", "\n  \"").concat(LEFT_PANEL, " ").concat(CONTENT, " ").concat(RIGHT_PANEL, "\"\n ");
var gridStyles = css({
  display: 'grid',
  height: '100%',
  gridTemplateAreas: gridTemplateAreas,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  gridTemplateColumns: "".concat(LEFT_PANEL_WIDTH, " minmax(0, 1fr) ").concat(RIGHT_PANEL_WIDTH),
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  gridTemplateRows: "".concat(BANNER_HEIGHT, " ").concat(TOP_NAVIGATION_HEIGHT, " auto"),
  outline: 'none'
});
var gridStylesMobileStyles = css(_defineProperty({}, UNSAFE_media.below.sm, {
  gridTemplateAreas: gridTemplateAreasMobile,
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  gridTemplateColumns: "".concat(LEFT_PANEL_WIDTH, " minmax(0, 1fr)")
}));

/**
 * __Page layout__
 *
 * A collection of components which let you compose an application's page layout.
 *
 * - [Examples](https://atlassian.design/components/page-layout/examples)
 * - [Code](https://atlassian.design/components/page-layout/code)
 */
var PageLayout = function PageLayout(_ref) {
  var _ref$skipLinksLabel = _ref.skipLinksLabel,
    skipLinksLabel = _ref$skipLinksLabel === void 0 ? DEFAULT_I18N_PROPS_SKIP_LINKS : _ref$skipLinksLabel,
    children = _ref.children,
    testId = _ref.testId,
    onLeftSidebarExpand = _ref.onLeftSidebarExpand,
    onLeftSidebarCollapse = _ref.onLeftSidebarCollapse;
  return jsx(Fragment, null, jsx(SkipLinksController, null, jsx(SkipLinkWrapper, {
    skipLinksLabel: skipLinksLabel
  }), jsx("div", _extends({}, pageLayoutSelector, {
    "data-testid": testId,
    css: [gridStyles, gridStylesMobileStyles],
    tabIndex: -1
  }), jsx(SidebarResizeController, {
    onLeftSidebarCollapse: onLeftSidebarCollapse,
    onLeftSidebarExpand: onLeftSidebarExpand
  }, children))));
};
export default PageLayout;