import React from 'react';

import Button from '@atlaskit/button';
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu-12';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';

import { PrivilegeOptions } from 'src/components/types';
import { useIntl } from 'src/hooks/intl';

import * as messages from './privileges-dropdown.i18n';
import {
  DropMenuContainer,
  PermissionDescription,
  PermissionsTriggerDescriptionContainer,
} from './privileges-dropdown.styles';
import {
  AllPrivileges,
  getDescription,
  getPermissionName,
  privilegeOptions,
} from './utils';

export type PrivilegesDropdownProps = {
  value: AllPrivileges;
  onChange: (privilege: AllPrivileges) => void;
  isDisabled?: boolean;
  allowEmptyChoice?: boolean;
  includeNoneOption?: boolean;
  showOptionDescription?: boolean;
  showValueDescription?: boolean;
  emptyValueLabel?: 'none' | 'allPermissions';
  customEmptyValueLabel?: string;
  fitContainer?: boolean;
  testId?: string;
  context?: 'repository' | 'project' | 'workspace';
  isFilter?: boolean;
  selectedPrivilege?: PrivilegeOptions | null;
};

/* eslint @typescript-eslint/ban-types: "warn" */
const PrivilegesDropdown: React.FC<PrivilegesDropdownProps> = ({
  value,
  onChange,
  isDisabled,
  allowEmptyChoice = false,
  includeNoneOption = false,
  showOptionDescription = true,
  showValueDescription = false,
  emptyValueLabel = 'allPermissions',
  customEmptyValueLabel,
  fitContainer = false,
  testId = 'privilegesDropdown',
  context = 'repository',
  isFilter = false,
  selectedPrivilege = null,
}) => {
  const { formatMessage } = useIntl();

  const privileges = [
    ...privilegeOptions[context][isFilter ? 'filter' : 'default'],
  ];

  if (includeNoneOption) {
    privileges.unshift(PrivilegeOptions.none);
  }

  const getName = (permission: AllPrivileges) => {
    if (permission) {
      return formatMessage(getPermissionName(permission));
    }
    if (customEmptyValueLabel) {
      return customEmptyValueLabel;
    }
    if (emptyValueLabel === 'allPermissions') {
      return formatMessage(messages.default.allPermissions);
    }
    return formatMessage(messages.default.none);
  };

  const getTrigger = () => {
    const label = getName(value);

    if (!isFilter && value && showValueDescription) {
      return (
        <PermissionsTriggerDescriptionContainer>
          <DropdownItem
            description={
              <PermissionDescription>
                {formatMessage(getDescription(value, context))}
              </PermissionDescription>
            }
          >
            {label}
          </DropdownItem>
        </PermissionsTriggerDescriptionContainer>
      );
    }

    return label;
  };

  const nonePrivilegeOption = PrivilegeOptions.none;

  return (
    <DropMenuContainer
      showValueDescription={showValueDescription}
      allowEmptyChoice={allowEmptyChoice}
      fitContainer={fitContainer}
    >
      <DropdownMenu
        testId={testId}
        shouldRenderToParent
        shouldFitContainer
        spacing="compact"
        trigger={({ triggerRef, ...props }) => (
          <Button
            {...props}
            className="triggerButton"
            ref={triggerRef}
            iconAfter={<ChevronDownIcon label="" />}
            isDisabled={isDisabled}
          >
            {getTrigger()}
          </Button>
        )}
      >
        {!selectedPrivilege && (
          <DropdownItemGroup>
            {allowEmptyChoice && (
              <DropdownItem
                onClick={() => onChange(null)}
                isDisabled={!value || value === PrivilegeOptions.none}
              >
                {formatMessage(messages.default.allPrivilegesMenuItem)}
              </DropdownItem>
            )}
            {privileges.map(privilege => (
              <DropdownItem
                key={privilege}
                isDisabled={privilege === value}
                onClick={() => onChange(privilege)}
                description={
                  !isFilter && showOptionDescription ? (
                    <PermissionDescription>
                      {formatMessage(getDescription(privilege, context))}
                    </PermissionDescription>
                  ) : undefined
                }
              >
                {getName(privilege)}
              </DropdownItem>
            ))}
          </DropdownItemGroup>
        )}
        {selectedPrivilege && (
          <DropdownItemGroup>
            <DropdownItem
              key={nonePrivilegeOption}
              isDisabled={value === nonePrivilegeOption}
              onClick={() => onChange(nonePrivilegeOption)}
              description={
                <PermissionDescription>
                  {formatMessage(messages.repository.none)}
                </PermissionDescription>
              }
            >
              {getName(nonePrivilegeOption)}
            </DropdownItem>
            <DropdownItem
              key={selectedPrivilege}
              isDisabled={value === selectedPrivilege}
              onClick={() => onChange(selectedPrivilege)}
              description={
                <PermissionDescription>
                  {formatMessage(getDescription(selectedPrivilege, context))}
                </PermissionDescription>
              }
            >
              {getName(selectedPrivilege)}
            </DropdownItem>
          </DropdownItemGroup>
        )}
      </DropdownMenu>
    </DropMenuContainer>
  );
};

export default PrivilegesDropdown;
