import React, { useMemo } from 'react';

import { useIntl } from 'react-intl-next';

import {
  PostOfficeEnvironmentConfig,
  PostOfficeProvider as PostOfficeBaseProvider,
} from '@atlassian/post-office-placement-remote';

import { useAppData } from 'src/app/data';
import { BbEnv, getBbEnv } from 'src/utils/bb-env';

export const transformDeployEnvToPostOfficeEnvironment = (
  env?: BbEnv
): PostOfficeEnvironmentConfig['env'] => {
  if (env === 'dev-west2') {
    return 'test';
  } else if (env === 'development') {
    return 'dev';
  } else if (env === 'staging') {
    return 'staging';
  }

  return 'production';
};

type PostOfficeContextProviderProps = { children: React.ReactNode };

/*
 * This component is used to provide the PostOfficeContext to the application. It provides the product, subproduct and locale at the application level.
 */
const PostOfficeContextProviderImpl = ({
  children,
}: PostOfficeContextProviderProps): JSX.Element => {
  const { locale } = useIntl();
  const { tenantId } = useAppData();
  const env = getBbEnv();

  const workspaceAri = tenantId
    ? `ari:cloud:bitbucket::workspace/${tenantId}`
    : 'no-workspace-ari-available'; // Bitbucket tenantId is the equivalent of Atlassian workspaceId

  const context = useMemo(
    () => ({
      current: {
        product: 'bitbucket',
        locale,
        workspaceAri,
      },
    }),
    [locale, workspaceAri]
  );

  const environment = useMemo(
    () => ({
      env: transformDeployEnvToPostOfficeEnvironment(env),
    }),
    [env]
  );

  return (
    <PostOfficeBaseProvider context={context} environment={environment}>
      {children}
    </PostOfficeBaseProvider>
  );
};

export const PostOfficeContextProvider = PostOfficeContextProviderImpl;
