import { token } from '@atlaskit/tokens';

import { convertStepStatus } from '../utils/convertStatus';

type StageStatusUnknown = 'UNKNOWN' | '';
type StageStatusPending = 'PENDING' | 'PAUSED' | 'HALTED';
type StageStatusInProgress = 'RUNNING' | 'READY';
type StageStatusCompleted =
  | 'SYSTEM_ERROR'
  | 'ERROR'
  | 'FAILED'
  | 'NOT_RUN'
  | 'STOPPED'
  | 'SKIPPED'
  | 'SUCCESSFUL';
export type StageStatusType =
  | StageStatusUnknown
  | StageStatusPending
  | StageStatusInProgress
  | StageStatusCompleted;
export const StageStatus: {
  [K in StageStatusType]: { text: string; color: string };
} = {
  '': {
    text: '',
    color: '',
  },
  UNKNOWN: {
    text: '',
    color: '',
  },
  PENDING: {
    text: '',
    color: token('color.icon.selected', '#0065FF'),
  },
  PAUSED: {
    text: 'Waiting',
    color: token('color.background.accent.gray.subtler', '#C1C7D0'),
  },
  HALTED: {
    text: 'Halted',
    color: token('color.icon.warning', '#FF8B00'),
  },
  READY: {
    text: 'Queued',
    color: token('color.icon.selected', '#0065FF'),
  },
  RUNNING: {
    text: 'Running',
    color: token('color.icon.information', '#0065FF'),
  },
  ERROR: {
    text: 'Error',
    color: token('color.icon.danger', '#FF5630'),
  },
  SYSTEM_ERROR: {
    text: 'Error',
    color: token('color.icon.danger', '#FF5630'),
  },
  FAILED: {
    text: 'Failed',
    color: token('color.icon.danger', '#FF5630'),
  },
  NOT_RUN: {
    text: 'Not run',
    color: token('color.background.accent.gray.subtler.hovered', '#A5ADBA'),
  },
  STOPPED: {
    text: 'Stopped',
    color: token('color.icon.subtle', '#7A869A'),
  },
  SKIPPED: {
    text: 'Skipped',
    color: token('color.icon.accent.yellow', '#FFAB00'),
  },
  SUCCESSFUL: {
    text: 'Successful',
    color: token('color.icon.success', '#36B27E'),
  },
};

export class Stage {
  readonly index: number = 0;
  readonly name: string = '';
  readonly build_seconds_used: number = 0;
  readonly completed_on: string = '';
  readonly duration_in_seconds: number = 0;
  readonly is_deployment_stage: boolean = false;
  readonly is_redeploy: boolean = false;
  readonly started_on: string = '';
  readonly state: any = {};
  readonly status?: StageStatusType = undefined;
  readonly expired: boolean = false;

  constructor(props: Partial<Stage> = {}) {
    Object.assign(this, {
      ...props,
      status: convertStepStatus(props.state),
      name: props.name || `Stage ${(props.index || 0) + 1}`,
    });
    Object.freeze(this);
  }

  get isInProgress(): boolean {
    return this.status === 'RUNNING' || this.status === 'READY';
  }

  get isSuccessful(): boolean {
    return this.status === 'SUCCESSFUL';
  }

  get isSkipped(): boolean {
    return this.status === 'SKIPPED';
  }

  get isFailed(): boolean {
    return this.status === 'FAILED';
  }

  get willNotRun(): boolean {
    return this.status === 'NOT_RUN';
  }

  get isStopped(): boolean {
    return this.status === 'STOPPED';
  }

  get isPaused(): boolean {
    return this.status === 'PAUSED';
  }

  get isHalted(): boolean {
    return this.status === 'HALTED';
  }

  get isPending(): boolean {
    return this.status === 'PENDING';
  }

  get hasError(): boolean {
    return (
      this.status === 'ERROR' ||
      this.status === 'FAILED' ||
      this.status === 'SYSTEM_ERROR'
    );
  }

  get isComplete(): boolean {
    return (
      this.isSuccessful ||
      this.willNotRun ||
      this.isStopped ||
      this.isSkipped ||
      this.hasError
    );
  }

  get isUnfinished(): boolean {
    return this.hasError || this.isStopped || this.isHalted;
  }

  get statusIcon(): string {
    if (this.is_redeploy && this.isComplete) {
      return 'REDEPLOY';
    }
    return this.status || '';
  }

  get statusText(): string {
    return this.status ? StageStatus[this.status].text : '';
  }

  get statusColor(): string {
    return this.status ? StageStatus[this.status].color : '';
  }
}
