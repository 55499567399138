import React from 'react';

import Icon from '@atlaskit/icon';

import { BitkitIconTypes } from '../types';

// Inlining the SVG to manually change snake-case props to camelCase
// so React is happy (https://github.com/facebook/react/issues/2250).
// @ts-ignore TODO: fix noImplicitAny error here
const glyph = props => (
  <svg {...props} viewBox="0 0 18 16" aria-hidden="true" focusable="false">
    <path
      fill="currentColor"
      d="M1.99766939,7.5133308 C1.99766939,4.47515334 5.13900449,2.00275379 9.0004994,2.00275379 C12.8609955,2.00275379 16.0023306,4.47515334 16.0023306,7.5133308 C16.0023306,10.5515083 12.8609955,13.0239079 9.0004994,13.0239079 C5.13900449,13.0239079 1.99766939,10.5515083 1.99766939,7.5133308 Z M16.8373564,15.2840155 L16.8373564,15.2830141 L16.8373564,15.2820128 C16.8373564,15.2820128 15.2741801,13.0219051 16.0712502,12.1657279 L16.0342933,12.1857554 C17.2608623,10.9019902 18,9.2787583 18,7.5133308 C18,3.37063462 13.9627102,0 9.0004994,0 C4.03728983,0 0,3.37063462 0,7.5133308 C0,11.656027 4.03728983,15.0266617 9.0004994,15.0266617 C10.4208424,15.0266617 11.7602797,14.7422706 12.9568836,14.2505946 C13.9597137,15.2730004 15.2442151,15.8227563 16.1961045,15.9699587 L16.199101,15.9669546 C16.2510405,15.983978 16.3049775,16 16.3629099,16 C16.6475778,16 16.8773098,15.7686819 16.8773098,15.4832895 C16.8773098,15.4121918 16.8633261,15.3450995 16.8373564,15.2840155 Z"
    />
  </svg>
);

export const CommentIcon = (props: BitkitIconTypes) => (
  <Icon {...props} glyph={glyph} />
);
