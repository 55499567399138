import React, { useCallback, useState } from 'react';

import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import SectionMessage from '@atlaskit/section-message';

import { MessageCloseButton, MessageWrapper } from './styled';

export interface Props {
  type: 'warning' | 'error' | 'information';
  onMessageClose?: (params?: any) => any;
  options?: any;
  children?: any;
}

/* eslint @typescript-eslint/ban-types: "warn" */
const Message: React.FC<Props> = ({
  type,
  options,
  onMessageClose,
  children,
}) => {
  const [isClosed, setIsClosed] = useState(false);

  const closeMessage = useCallback(() => {
    setIsClosed(true);
    onMessageClose?.();
  }, [setIsClosed, onMessageClose]);
  return !isClosed ? (
    <MessageWrapper>
      <SectionMessage appearance={type}>
        {children}
        {options?.closeable && (
          <MessageCloseButton>
            <Button
              onClick={closeMessage}
              appearance="subtle"
              iconBefore={<CrossIcon label="close" />}
            />
          </MessageCloseButton>
        )}
      </SectionMessage>
    </MessageWrapper>
  ) : null;
};

export default React.memo(Message);
