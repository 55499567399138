import React from 'react';

import Icon, { IconProps } from '@atlaskit/icon';

const defaultGlyph = (props: { [key: string]: string }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    version="1.1"
  >
    <path
      d="M10.762 19.632l-.001.09.419.896c.177.39.01.85-.377 1.034-.256.12-.478.146-.666.077-.188-.068-.283-.103-.402-.358L8.36 18.422c-.179-.384-.008-.854.411-1.128l2.948-1.374a.785.785 0 0 1 1.033.376c.178.389.011.85-.375 1.034l-1.006.503c3.01.204 5.98-1.59 6.5-4.537a5.773 5.773 0 0 0-.973-4.337l-.094-.128a.91.91 0 0 1 .255-1.309 1.068 1.068 0 0 1 1.418.266l.052.07a7.704 7.704 0 0 1 1.298 5.783c-.742 4.205-4.786 6.69-9.065 5.99zm1.932-15.546l-.445-.955a.785.785 0 0 1 .376-1.034.785.785 0 0 1 1.034.376l1.375 2.949c.145.478-.026.947-.41 1.126l-2.948 1.375c-.257.12-.35.086-.539.017-.188-.067-.342-.23-.46-.487a.785.785 0 0 1 .375-1.034l.832-.398c-2.814.038-5.288 2.032-5.788 4.865a5.81 5.81 0 0 0 .434 3.423c.16.354.357.694.59 1.016a.818.818 0 0 1-.222 1.222l-.24.14a.899.899 0 0 1-1.167-.23 7.938 7.938 0 0 1-.776-1.332 7.754 7.754 0 0 1-.588-4.587c.715-4.057 4.472-6.828 8.567-6.452z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

const PipelinesIcon = (props: IconProps): React.ReactElement => (
  <Icon glyph={defaultGlyph} {...props} />
);

export default PipelinesIcon;
