"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_PROMISE_TIMEOUT = 3 * 1000;
var Ready = /** @class */ (function () {
    function Ready() {
        this.initialisePromise();
        this.initialiseDefaultPromise();
    }
    Ready.prototype.initialiseDefaultPromise = function () {
        this.defaultReadyPromise = new Promise(function (resolve) {
            setTimeout(resolve, exports.DEFAULT_PROMISE_TIMEOUT);
        });
    };
    Ready.prototype.initialisePromise = function () {
        var _this = this;
        /*
          A promise can only be resolved by calling the `resolve` function in the constructor callback.
          Since we need resolve the promise by external input, we must save the reference to this resolve function.
          This is safe as the callback function is called synchronously as defined by the ES spec.
        */
        this.readyPromise = new Promise(function (resolve) {
            _this.resolveReadyPromise = resolve;
        });
    };
    Ready.prototype.getPromise = function () {
        return Promise.race([this.readyPromise, this.defaultReadyPromise]);
    };
    Ready.prototype.triggerReady = function () {
        if (this.resolveReadyPromise !== undefined) {
            this.resolveReadyPromise();
            this.resolveReadyPromise = undefined;
        }
    };
    Ready.prototype.reset = function () {
        if (this.resolveReadyPromise === undefined) {
            this.initialisePromise();
        }
        this.initialiseDefaultPromise();
    };
    return Ready;
}());
exports.default = Ready;
