import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';

export const AccountPaymentLoadable = Loadable({
  loading: LoadingPage,
  loader: () => import('./components/payment'),
});

export const PlanSelectorLoadable = Loadable({
  loading: LoadingPage,
  loader: () => import('./containers/plan-selector'),
});
