import React from 'react';

import { Text } from '@atlaskit/primitives';

interface TestMetricValueProps {
  hasWarning?: boolean;
  children: React.ReactNode;
}

/* eslint @typescript-eslint/ban-types: "warn" */
export const TestMetricValue: React.FC<TestMetricValueProps> = ({
  hasWarning,
  children,
}) => {
  return (
    <Text color={hasWarning ? 'color.text.danger' : 'color.text'}>
      {children}
    </Text>
  );
};
