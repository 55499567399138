import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { Appearance } from '@atlaskit/button/types';

import ModalDialog from 'src/components/modal-dialog-actions';
import PrivilegesDropdown from 'src/components/settings/privileges-dropdown';
import {
  FormContainer,
  SelectDropdownContainer,
} from 'src/components/settings/styles/select';
import { Privilege } from 'src/components/types';
import { useIntl } from 'src/hooks/intl';

import { AccessLevelType } from '../types';

import messages from './modals.i18n';

export type BulkChangePrivilegesModalProps = {
  onClose: () => void;
  onBulkChange: (privilege: Privilege) => void;
  removeOwnAccessWarning?: React.ReactNode;
  isLoading: boolean;
  itemNames: string[];
  context: AccessLevelType;
};

/* eslint @typescript-eslint/ban-types: "warn" */
const BulkChangePrivilegesModal: React.FC<BulkChangePrivilegesModalProps> = ({
  onClose,
  onBulkChange,
  isLoading,
  itemNames,
  removeOwnAccessWarning,
  context,
}) => {
  const [newPrivilege, setNewPrivilege] = useState<Privilege | null>(null);
  const { formatMessage } = useIntl();

  const actions = [
    {
      text: formatMessage(messages.cancel),
      onClick: onClose,
      appearance: 'subtle' as Appearance,
    },
    {
      text: formatMessage(messages.bulkEditPrivilegesButton),
      onClick: () => onBulkChange(newPrivilege as Privilege),
      appearance: 'warning' as Appearance,
      isDisabled: !newPrivilege,
      isLoading,
    },
  ];

  return (
    <ModalDialog
      actions={actions}
      heading={formatMessage(messages.bulkEditPrivilegesHeader, {
        count: itemNames.length,
      })}
      width="medium"
      onClose={onClose}
      appearance="warning"
    >
      <FormContainer>
        {removeOwnAccessWarning}
        <FormattedMessage
          tagName="p"
          {...messages.bulkEditPrivilegesBody}
          values={{ items: <b>{`"${itemNames.join('", "')}"`}</b> }}
        />
        <SelectDropdownContainer topMarginFactor={3}>
          <PrivilegesDropdown
            fitContainer
            showValueDescription
            showOptionDescription
            customEmptyValueLabel={formatMessage(messages.selectPermission)}
            onChange={(privilege: Privilege) => setNewPrivilege(privilege)}
            value={newPrivilege}
            context={context}
          />
        </SelectDropdownContainer>
      </FormContainer>
    </ModalDialog>
  );
};

export default BulkChangePrivilegesModal;
