import { BucketState } from 'src/types/state';

export const getDashboard = (state: BucketState) => state.dashboard;
export const getEntities = (state: BucketState) => state.entities;
export const getGlobal = (state: BucketState) => state.global ?? {};
export const getKeyboardShortcuts = (state: BucketState) =>
  state.keyboardShortcuts;
export const getRecentlyViewedRepositoriesKeys = (state: BucketState) =>
  state.recentlyViewedRepositories;
export const getRepository = (state: BucketState) => state.repository;
export const getPipelines = (state: BucketState) => state.pipelines;
export const getProject = (state: BucketState) => state.project;
export const getRecentlyViewedWorkspacesKeys = (state: BucketState) =>
  state.recentlyViewedWorkspaces;
export const getProfile = (state: BucketState) => state.profile;
export const getSearch = (state: BucketState) => state.search;
export const getJiraSlice = (state: BucketState) => state.jira;
export const getWorkspacePlanSlice = (state: BucketState) =>
  state.workspacePlan;
