export const LoadingState = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  MODAL_LOADING: 'MODAL_LOADING',
  PRIVILEGE_UPDATING: 'PRIVILEGE_UPDATING',
  ERROR: 'ERROR',
} as const;

export const AccessLevel = {
  workspace: 'workspace',
  project: 'project',
  repository: 'repository',
} as const;

export const INVITE_FAILED_ERROR = 'INVITE_FAILED_ERROR';
export const NON_ADMIN_RESTRICTION_ERROR = 'NON_ADMIN_RESTRICTION_ERROR';
export const DOMAIN_RESTRICTION_ERROR = 'DOMAIN_RESTRICTION_ERROR ';
export const BAD_REQUEST_ERROR = 'BAD_REQUEST_ERROR';
