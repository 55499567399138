import Cookies from 'js-cookie';
import { fg } from '@atlaskit/platform-feature-flags';
import { sendPackageOperationalEvent } from '../../../common/utils';
import { Logger } from '../../../common/utils/logger';

/**
 * Gets a cookie or all cookies. If no key is provided, returns all cookies.
 */

export function getCookie(key) {
  if (fg('platform_moonjelly_bsc_master_killswitch')) {
    // Get the cookie without package operational events
    // when master killswitch is enabled
    return key ? Cookies.get(key) : Cookies.get();
  }
  try {
    sendPackageOperationalEvent({
      action: 'usedGetCookie',
      attributes: {
        cookieKey: key
      }
    });
    return key ? Cookies.get(key) : Cookies.get();
  } catch (e) {
    Logger.errorWithOperationalEvent({
      action: 'usedGetCookieError',
      attributes: {
        cookieKey: key
      },
      message: "Failed to use get cookie. ".concat(e.message || '')
    });
  }
}