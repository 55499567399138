import { createResource } from 'react-resource-router';

import { KnownHost } from 'src/components/pipelines/models';
import authRequest from 'src/utils/fetch';

import { KNOWN_HOSTS_PAGELEN } from '../constants';
import { getKnownHostsRepoUrl, parseKnownHost } from '../utils/known-hosts';

export type KnownHostsResponse = {
  values: KnownHost[];
  pagelen: number;
  size: number;
  page: number;
  next: string;
  previous: string;
};

export const loadPipelinesRepositoryKnownHostsResource =
  createResource<KnownHostsResponse>({
    type: 'pipelinesRepositoryKnownHosts',
    getKey: ({ match, query }) =>
      getKnownHostsRepoUrl({
        page: 1,
        pagelen: KNOWN_HOSTS_PAGELEN,
        ...match.params,
        ...query,
      }),
    maxAge: 60000, // cache for 1 minute so navigating between pages is fast
    getData: async ({ match, query }) => {
      const data = (await (
        await fetch(
          authRequest(
            getKnownHostsRepoUrl({
              page: 1,
              pagelen: KNOWN_HOSTS_PAGELEN,
              ...match.params,
              ...query,
            })
          )
        )
      ).json()) as KnownHostsResponse;
      return {
        ...data,
        values: data.values.map(parseKnownHost),
      };
    },
  });
