import { denormalize } from 'normalizr';
import createCachedSelector from 're-reselect';
import { Selector, createSelector } from 'reselect';

import { MenuItem } from 'src/components/navigation';
import { findNestedMenuItem } from 'src/components/navigation/src/utils/create-nested-menu';
import { Link as BBLink, Project, Workspace } from 'src/components/types';
import { project as projectSchema } from 'src/sections/repository/schemas';
import { BucketState } from 'src/types/state';

import { getEntities, getProject } from './state-slicing-selectors';

export type ProjectSelector<T> = Selector<BucketState, T>;

export const getCurrentProjectID: ProjectSelector<string | null | undefined> =
  createSelector(getProject, project => project?.section?.currentProject);

export const getCurrentProject: ProjectSelector<Project | undefined> =
  createSelector(getCurrentProjectID, getEntities, (key, entities) =>
    denormalize(key, projectSchema, entities)
  );

export const getCurrentProjectKey: ProjectSelector<string | undefined> =
  createSelector(getCurrentProject, project => project?.key);

export const getCurrentProjectUuid: ProjectSelector<string | undefined> =
  createSelector(getCurrentProject, project => project?.uuid);

export const getCurrentProjectWorkspace: ProjectSelector<
  Workspace | undefined
> = createSelector(getCurrentProject, project => project?.workspace);

export const getCurrentProjectWorkspaceSlug: ProjectSelector<
  string | undefined
> = createSelector(getCurrentProject, project => project?.workspace?.slug);

export const getCurrentProjectWorkspaceUuid: ProjectSelector<
  string | undefined
> = createSelector(getCurrentProject, project => project?.workspace?.uuid);

export const getIsCurrentProjectPrivate: ProjectSelector<boolean | undefined> =
  createSelector(getCurrentProject, project => project?.is_private);

export const getCurrentProjectLinkData: ProjectSelector<{
  name?: string;
  links?: {
    avatar: BBLink;
    html: BBLink;
    repositories?: BBLink;
    self: BBLink;
  };
}> = createSelector(getCurrentProject, currentProject =>
  currentProject
    ? {
        name: currentProject.name,
        links: currentProject.links,
      }
    : {}
);

export const getMenuItems: ProjectSelector<MenuItem[]> = createSelector(
  getProject,
  project => project.section.menuItems
);

// Follows similar logic to getSelectedMenuItem in `workspace-selectors`
export const getSelectedMenuItem = createCachedSelector(
  getMenuItems,
  (_state: BucketState, pathname: string, matchUrl: string) => ({
    pathname,
    matchUrl,
  }),
  (menuItems, match) => {
    const { pathname, matchUrl } = match;

    return findNestedMenuItem(menuItems, item => {
      const projectSubpath = pathname.slice(matchUrl.length);
      const prefixes = item.matching_url_prefixes || [];
      const itemUrl = item.url.replace(/\/$/, '');
      return (
        pathname === itemUrl ||
        prefixes.some(
          prefix =>
            projectSubpath.startsWith(prefix) || // match if subpath starts with prefix
            (pathname.startsWith(itemUrl) &&
              projectSubpath &&
              prefix === '*') || // match if subpath has length and prefix is *
            pathname === prefix // accept override if entire path matches prefix (e.g /some-workspace-name)
        )
      );
    });
  }
)((_state, pathname, matchUrl) => `${matchUrl}:${pathname}`);
