import qs from 'qs';

export const encodeBranchName = (branchName: string) =>
  // Encode `#` - Fragment Identifiers are only client-side in HTTP request URLs
  branchName.replace(/#/g, '%23');

export const encodePath = (path: string | null | undefined) =>
  (path || '').split('/').map(encodeURIComponent).join('/');

export const stringify = (params: object, extraOptions: any = {}) =>
  qs.stringify(params, {
    addQueryPrefix: true,
    skipNulls: true,
    ...extraOptions,
  });

export const appendParamToPath = (
  queryParams: { [key: string]: string | boolean | number },
  destUrl?: string
): string => {
  const destinationUrl = destUrl || window.location.href;
  const newUrl = new URL(destinationUrl);

  Object.keys(queryParams).forEach(item =>
    newUrl.searchParams.set(item, String(queryParams[item]))
  );

  return newUrl.href;
};
