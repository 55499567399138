export class CacheVersion {
  readonly uuid: string = '';
  readonly file_size_bytes: number = 0;
  readonly created_on: string = '';
  readonly key_hash: string = '';

  constructor(props: Partial<CacheVersion> = {}) {
    Object.assign(this, props);
    Object.freeze(this);
  }
}

export class Cache {
  readonly name: string = '';
  readonly path: string = '';
  readonly pipeline_uuid: string = '';
  readonly versions: CacheVersion[] = [];

  constructor(props: Partial<Cache> = {}) {
    Object.assign(this, props);
    Object.freeze(this);
  }
}
