import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { DEFAULT_SOURCE } from '@atlaskit/analytics-gas-types';
import { MEDIA_CONTEXT } from '@atlaskit/analytics-namespaced-context';
import merge from 'lodash/merge';
import last from 'lodash/last';
import { getPackageHierarchy, getSources, getPackageInfo, getComponents, extractFromEventContext, getExtraAttributes } from '../atlaskit/extract-data-from-event';
var listenerVersion = "8.11.1";
function getMediaContexts(event) {
  return extractFromEventContext(MEDIA_CONTEXT, event);
}
function getMediaRegion() {
  try {
    return window && window.sessionStorage && window.sessionStorage.getItem('media-api-region');
  } catch (e) {
    return;
  }
}
export function processEvent(event) {
  var sources = getSources(event);
  var _ref = last(getPackageInfo(event)) || {},
    packageName = _ref.packageName,
    packageVersion = _ref.packageVersion;
  var extraAttributes = getExtraAttributes(event);
  var mediaContexts = getMediaContexts(event) || [];
  var mediaAttributes = merge.apply(void 0, [{}].concat(_toConsumableArray(mediaContexts)));
  var tags = new Set(event.payload.tags || []);
  tags.add('media');
  var components = getComponents(event);
  var mediaRegion = getMediaRegion();
  var payload = {
    source: last(sources) || DEFAULT_SOURCE,
    actionSubject: event.payload.actionSubject,
    action: event.payload.action,
    eventType: event.payload.eventType,
    actionSubjectId: event.payload.actionSubjectId,
    // for backward compatibilty
    name: event.payload.name,
    tags: Array.from(tags),
    attributes: _objectSpread(_objectSpread({
      packageName: packageName,
      packageVersion: packageVersion
    }, merge({}, extraAttributes, mediaAttributes, event.payload.attributes)), {}, {
      componentHierarchy: components.join('.') || undefined,
      packageHierarchy: getPackageHierarchy(event),
      sourceHierarchy: sources.join('.') || undefined,
      listenerVersion: listenerVersion
    }, mediaRegion ? {
      mediaRegion: mediaRegion
    } : undefined)
  };
  return payload;
}