import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { border as borderFactory } from '../../next-schema/generated/markTypes';
import { hexToEditorBorderPaletteColor } from '../../utils/editor-palette';
import { N300A, N600, N1000 } from '../../utils/colors';

/**
 * @name border_mark
 * @description This mark adds decoration to an element, and any element decorated with it will also have a border style.
 */

var borderColorArrayPalette = [[N300A, 'Subtle gray'], [N600, 'Gray'], [N1000, 'Bold gray']];
export var borderColorPalette = new Map();
borderColorArrayPalette.forEach(function (_ref) {
  var _ref2 = _slicedToArray(_ref, 2),
    color = _ref2[0],
    label = _ref2[1];
  return borderColorPalette.set(color.toLowerCase(), label);
});
export var border = borderFactory({
  parseDOM: [{
    tag: '[data-mark-type="border"]',
    getAttrs: function getAttrs(domNode) {
      var _dom$getAttribute, _dom$getAttribute2;
      var dom = domNode;
      var color = ((_dom$getAttribute = dom.getAttribute('data-color')) !== null && _dom$getAttribute !== void 0 ? _dom$getAttribute : '').toLowerCase();
      var size = +((_dom$getAttribute2 = dom.getAttribute('data-size')) !== null && _dom$getAttribute2 !== void 0 ? _dom$getAttribute2 : '0');
      return {
        size: size > 3 ? 3 : size < 1 ? false : size,
        color: borderColorPalette.has(color) ? color : false
      };
    }
  }],
  toDOM: function toDOM(mark, isInline) {
    var wrapperStyle = isInline ? 'span' : 'div';

    // Note -- while there is no way to create custom colors using default tooling
    // the editor does supported ad hoc color values -- and there may be content
    // which has been migrated or created via apis which use such values.
    var paletteColorValue = hexToEditorBorderPaletteColor(mark.attrs.color) || mark.attrs.color;
    return [wrapperStyle, {
      'data-mark-type': 'border',
      'data-color': mark.attrs.color,
      'data-size': mark.attrs.size,
      style: "--custom-palette-color: ".concat(paletteColorValue)
    }];
  }
});