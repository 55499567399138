import React, { useMemo } from 'react';

import Button from '@atlaskit/button';
import IconRemove from '@atlaskit/icon/glyph/editor/remove';
import Tooltip from '@atlaskit/tooltip';

import { Account, Repository } from 'src/components/pipelines/models';

import { formatDate, formatDistanceToNow } from '../utils';
import { getPipelinePageURL } from '../utils/links';

import BitbucketLink from './BitbucketLink';
import { CacheItem } from './Caches';
import {
  SmartCacheRemoveButton,
  SmartCachesFileSize,
  SmartCachesVersionName,
  SmartCachesVersionWrapper,
} from './styled';

type Props = {
  account: Account;
  repository: Repository;
  onClickRemove: (item: CacheItem) => void;
  item: CacheItem;
};

/* eslint @typescript-eslint/ban-types: "warn" */
const CacheVersionRow: React.FC<Props> = ({
  account,
  repository,
  onClickRemove,
  item,
}) => {
  const pipelinePageUrl = useMemo(
    () => getPipelinePageURL({ uuid: item.pipelineUuid }, repository.path),
    [item.pipelineUuid, repository.path]
  );
  return (
    <SmartCachesVersionWrapper role="row">
      <SmartCachesVersionName role="gridcell">
        {item.label}
      </SmartCachesVersionName>
      <SmartCachesFileSize role="gridcell">
        {item.fileSize!}
      </SmartCachesFileSize>
      <div role="gridcell">
        <Tooltip content={formatDate(item.createdOn!)}>
          <BitbucketLink
            account={account}
            repository={repository}
            to={pipelinePageUrl}
          >
            {formatDistanceToNow(item.createdOn!)}
          </BitbucketLink>
        </Tooltip>
      </div>
      {repository.canWrite && (
        <SmartCacheRemoveButton role="gridcell">
          <Tooltip content="Delete cache version" position="top">
            <Button
              appearance="subtle"
              iconAfter={<IconRemove label="Remove" />}
              onClick={() => onClickRemove(item)}
            />
          </Tooltip>
        </SmartCacheRemoveButton>
      )}
    </SmartCachesVersionWrapper>
  );
};

export default React.memo(CacheVersionRow);
