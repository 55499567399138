import { createResource } from 'react-resource-router';

import { Allowance } from 'src/components/pipelines/models';
import { ResourceContext } from 'src/router/types';

import { capturePipelinesExceptionWithTags } from '../utils/sentry';

import { fetchData } from './utils';

function toOneDecimalPlace(num: number): number {
  return Math.round(num * 10) / 10;
}

function calculatePercentageUsed(usage: number, quota: number): number {
  return usage / quota;
}

export const getAllowanceUrl = (accountUuid: string): string =>
  `/!api/internal/accounts/${accountUuid}/pipelines_config/allowance`;

export const getIsUserTrustedUrl = (accountUuid: string): string =>
  `/!api/internal/workspaces/${accountUuid}/pipelines-users/is-trusted`;

export const loadAllowanceResource = createResource({
  type: 'allowance',
  getKey: () => 'allowance',
  maxAge: 60000,
  getData: async (_params, { reduxStore }: ResourceContext) => {
    const { targetUser } = reduxStore.getState().global;
    if (!targetUser) {
      throw new Error('Target user not found');
    }

    const defaultAllowance = new Allowance();
    let allowanceData;
    let isTrusted = undefined;

    try {
      allowanceData = await fetchData(
        getAllowanceUrl(targetUser),
        'allowance_resource_exception'
      );

      try {
        const isTrustedData = await fetchData(
          getIsUserTrustedUrl(targetUser),
          'allowance_resource_exception'
        );
        isTrusted = isTrustedData?.trusted;
      } catch (e) {
        capturePipelinesExceptionWithTags(e, {
          segment: 'PIPELINES_FAILED_TO_VALIDATE_USER_IS_TRUSTED',
        });
      }
    } catch (e) {
      capturePipelinesExceptionWithTags(e, {
        segment: 'PIPELINES_FAILED_TO_FETCH_ALLOWANCE',
      });
      return new Allowance({
        ...defaultAllowance,
        hasFetched: true,
        isErrored: true,
      });
    }
    return new Allowance({
      ...defaultAllowance,
      startDate: allowanceData.start_date,
      endDate: allowanceData.end_date,
      isTrusted: isTrusted !== undefined ? isTrusted : false,
      totalUsage: toOneDecimalPlace(allowanceData.usage_seconds / 60),
      quota: toOneDecimalPlace(allowanceData.quota_seconds / 60),
      percentageUsed: calculatePercentageUsed(
        allowanceData.usage_seconds,
        allowanceData.quota_seconds
      ),
      hasFetched: true,
      hasPermissionToView: true,
    });
  },
});
