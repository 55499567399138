import { stringify } from 'qs';

import { fetchAction } from 'src/redux/actions';
import { FETCH_WORKSPACE_PLAN } from 'src/redux/workspaces/actions/constants';
import { repository as repoSchema } from 'src/sections/repository/schemas';

import urls from '../urls';

import { FETCH_IS_PIPELINES_ENABLED } from './constants';

import { FetchRepositoryDetails } from './';

export const query = stringify({
  fields: '+parent.mainbranch.*',
});
export default (repositoryFullSlug: string) => {
  return fetchAction(FetchRepositoryDetails, {
    url: `${urls.api.internal.details(repositoryFullSlug)}?${query}`,
    schema: {
      parent: repoSchema,
    },
  });
};

export const fetchIsPipelinesEnabled = () => ({
  type: FETCH_IS_PIPELINES_ENABLED.REQUEST,
});

export const fetchWorkspacePlan = (workspaceSlug: string) => ({
  type: FETCH_WORKSPACE_PLAN.REQUEST,
  payload: { workspaceSlug },
});
