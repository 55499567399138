/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { buttonWidthUnitless, cssVar, defaultTextPadding, maxTextWidth, maxTextWidthUnitless, textFontSize, textMarginLeft, textPaddingRight } from '../../../constants';
var baseStyles = css({
  maxWidth: maxTextWidth,
  paddingTop: "var(--ds-space-025, 2px)",
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  paddingRight: defaultTextPadding,
  paddingBottom: "var(--ds-space-025, 2px)",
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  paddingLeft: defaultTextPadding,
  fontSize: textFontSize,
  fontWeight: 'normal',
  lineHeight: 1,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  // Use "clip" overflow to allow ellipses on x-axis without clipping descenders
  '@supports not (overflow-x: clip)': {
    overflow: 'hidden'
  },
  '@supports (overflow-x: clip)': {
    overflowX: 'clip'
  }
});
var linkStyles = css({
  color: "var(".concat(cssVar.color.text.link, ")"),
  pointerEvents: 'auto',
  textDecoration: 'none',
  '&:hover': {
    color: "var(".concat(cssVar.color.text.hover, ")"),
    textDecoration: 'underline'
  },
  '&:active': {
    color: "var(".concat(cssVar.color.text.active, ")"),
    textDecoration: 'underline'
  },
  '&:focus': {
    outline: 'none'
  }
});
var hasAfterStyles = css({
  maxWidth: "".concat(maxTextWidthUnitless - buttonWidthUnitless, "px"),
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  paddingRight: textPaddingRight
});
var hasBeforeStyles = css({
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  marginLeft: textMarginLeft
});
var Content = function Content(_ref) {
  var _ref$elemBefore = _ref.elemBefore,
    elemBefore = _ref$elemBefore === void 0 ? null : _ref$elemBefore,
    _ref$isRemovable = _ref.isRemovable,
    isRemovable = _ref$isRemovable === void 0 ? true : _ref$isRemovable,
    _ref$text = _ref.text,
    text = _ref$text === void 0 ? '' : _ref$text,
    _ref$color = _ref.color,
    color = _ref$color === void 0 ? 'standard' : _ref$color,
    href = _ref.href,
    linkComponent = _ref.linkComponent;
  var Link = linkComponent !== null && linkComponent !== void 0 ? linkComponent : 'a';
  if (href) {
    return jsx(Link, {
      href: href,
      "data-color": color,
      css: [baseStyles, linkStyles, elemBefore && hasBeforeStyles, isRemovable && hasAfterStyles]
    }, text);
  } else {
    return jsx("span", {
      css: [baseStyles, elemBefore && hasBeforeStyles, isRemovable && hasAfterStyles]
    }, text);
  }
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default Content;