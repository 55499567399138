import { Reason } from './Reason';
import { User } from './User';

export class HaltedReason extends Reason {
  readonly actor: User = new User();
  readonly pipeline_uuid: string = '';
  readonly step_uuid: string = '';

  constructor(props: Partial<HaltedReason> = {}) {
    super(props);
    Object.assign(this, { ...props, actor: new User(props.actor) });
    Object.freeze(this);
  }

  get pipelineUuid(): string {
    return this.pipeline_uuid;
  }

  get stepUuid(): string {
    return this.step_uuid;
  }

  get warningText(): string | null {
    if (this.isDeploymentGateChecksRequiredReason) {
      return 'Change request pending';
    }
    return null;
  }
}
