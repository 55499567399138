import {
  createGetContainersPlugin,
  createGetUsersPlugin,
  ContainerTypes,
  UserIdTypes,
  ContainerList,
  UserData,
} from '@atlassiansox/cross-flow-plugins';

import { BitbucketWorkspaceAPIResponse } from './types';

export const getWorkspaces = async (): Promise<ContainerList> => {
  const getWorkspacesUrl =
    '/!api/2.0/user/permissions/workspaces?q=permission="owner"';
  const response = await fetch(getWorkspacesUrl, {
    credentials: 'same-origin',
  });

  if (response.ok) {
    const data: BitbucketWorkspaceAPIResponse = await response.json();
    const { values } = data;

    const containers = values.map(value => ({
      id: value.workspace.uuid,
      displayName: value.workspace.name,
      type: ContainerTypes.BITBUCKET_WORKSPACE,
    }));

    return {
      type: ContainerTypes.BITBUCKET_WORKSPACE,
      containers,
    };
  }

  throw new Error(
    `Unable to fetch ${getWorkspacesUrl} ${response.status} ${response.statusText}`
  );
};

export const getUsers = async (containerId: string): Promise<UserData[]> => {
  const getUsersUrl = `/!api/2.0/workspaces/${containerId}/permissions`;

  const response = await fetch(getUsersUrl, {
    credentials: 'same-origin',
  });

  if (response.ok) {
    const data: BitbucketWorkspaceAPIResponse = await response.json();
    const { values } = data;

    return values.map<UserData>(value => ({
      id: value.user.account_id,
      idType: UserIdTypes.ATLASSIAN_ACCOUNT_ID,
      displayName: value.user.display_name,
      avatarUrl: value.user.links.avatar
        ? value.user.links.avatar.href
        : undefined,
    }));
  }

  throw new Error(
    `Unable to fetch ${getUsersUrl} ${response.status} ${response.statusText}`
  );
};

export const getContainersPlugin = createGetContainersPlugin(getWorkspaces);
export const getUsersPlugin = createGetUsersPlugin(getUsers);
