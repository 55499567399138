import * as Sentry from '@sentry/browser';
import { Action } from 'redux';
/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import {
  all,
  call,
  CallEffect,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { User } from 'src/components/types';
import commonMessages from 'src/i18n/common';
import {
  getGlobalDiffViewMode,
  getGlobalTabSize,
  getGlobalIsColorBlindModeEnabled,
  getGlobalIsWordDiffEnabled,
  getGlobalShouldIgnoreWhitespace,
  getGlobalIsAnnotationsEnabled,
  getGlobalIsSingleFileModeEnabled,
  UPDATE_GLOBAL_DIFF_VIEW_MODE_SUCCESS,
  UPDATE_GLOBAL_TAB_SIZE_SUCCESS,
  UPDATE_GLOBAL_IS_WORD_DIFF_ENABLED_SUCCESS,
  UPDATE_GLOBAL_SHOULD_IGNORE_WHITESPACE_SUCCESS,
  UPDATE_GLOBAL_IS_COLOR_BLIND_MODE_ENABLED_SUCCESS,
  UPDATE_GLOBAL_IS_ANNOTATIONS_ENABLED_SUCCESS,
  UPDATE_GLOBAL_IS_SINGLE_FILE_MODE_ENABLED_SUCCESS,
  UPDATE_SETTINGS_REQUEST,
  UPDATE_SETTINGS_SUCCESS,
  UpdateGlobalDiffViewModeSuccessAction,
  UpdateGlobalTabSizeSuccessAction,
  UpdateGlobalIsWordDiffEnabledSuccessAction,
  UpdateGlobalShouldIgnoreWhitespaceSuccessAction,
  UpdateGlobalIsColorBlindNodeEnabledSuccessAction,
  UpdateGlobalIsAnnotationsEnabledSuccessAction,
  UpdateGlobalIsSingleFileModeEnabledSuccessAction,
  UpdateSettingsRequestAction,
  UpdateSettingsSuccessAction,
  getGlobalFileViewMode,
  UpdateGlobalFileViewModeSuccessAction,
  UPDATE_GLOBAL_FILE_VIEW_MODE_SUCCESS,
  DiffViewMode,
  FileViewMode,
} from 'src/redux/diff-settings';
import { showFlagComponent, showFlag } from 'src/redux/flags';
import { SimpleFlagProps } from 'src/redux/flags/types';
import messages from 'src/sections/repository/sections/pull-request/components/diff-settings/i18n';
import { getCurrentUser } from 'src/selectors/user-selectors';
import preferencesApi, { PreferenceKey } from 'src/utils/preferences';

export const GLOBAL_DIFF_VIEW_MODE_PREFERENCE_KEY =
  'pull-request-diff-view-mode';
export const GLOBAL_FILE_VIEW_MODE_PREFERENCE_KEY =
  'pull-request-file-view-mode';
export const GLOBAL_TAB_SIZE_PREFERENCE_KEY = 'pull-request-diff-tab-size';
export const GLOBAL_COLOR_BLIND_MODE_PREFERENCE_KEY =
  'pull-request-color-blind-mode-enabled';
export const GLOBAL_IGNORE_WHITESPACE_PREFERENCE_KEY =
  'pull-request-ignore-whitespace-enabled';
export const GLOBAL_WORD_DIFF_PREFERENCE_KEY = 'pull-request-word-diff-enabled';
export const GLOBAL_ANNOTATIONS_PREFERENCE_KEY =
  'pull-request-annotations-enabled';
export const GLOBAL_SINGLE_FILE_MODE_PREFERENCE_KEY =
  'pull-request-single-file-mode-enabled';

type UpdateUserPreferenceOptions = {
  key: PreferenceKey;
  successAction: Action;
  userUuid?: string;
  value: any;
};

// exported for use in tests
export const SUCCESS_FLAG = {
  id: 'diff-settings-update-success',
  description: { msg: messages.successFlagDescription },
  iconType: 'success',
  title: { msg: commonMessages.success },
} as SimpleFlagProps;

function* updateUserPreference(options: UpdateUserPreferenceOptions) {
  try {
    // If the current user isn't logged in, we don't have a user preference to update, so just fire the
    // success action to trigger any necessary re-renders and move on
    if (!options.userUuid) {
      yield put(options.successAction);
      // Return a truthy value so the saga doesn't produce an error flag
      return true;
    }
    // @ts-ignore
    const response = yield call(
      preferencesApi.set,
      options.userUuid,
      options.key,
      options.value
    );
    yield put(options.successAction);
    return response;
  } catch (e) {
    // Don't re-throw -- we want the `yield all` to wait for *all* of the updates to finish rather than
    // exit early if one fails
    Sentry.withScope(scope => {
      scope.setTag('preferenceKey', `${options.key}`);
      Sentry.captureMessage(
        `Error while updating a user preference via the Pull Request settings modal`,
        Sentry.Severity.Error
      );
    });
    return undefined;
  }
}

export function* updateDiffSettingsSaga(action: UpdateSettingsRequestAction) {
  const currentUser: User = yield select(getCurrentUser);

  const globalDiffViewMode: DiffViewMode = yield select(getGlobalDiffViewMode);
  const globalFileViewMode: FileViewMode = yield select(getGlobalFileViewMode);
  const globalTabSize: number = yield select(getGlobalTabSize);
  const globalIsWordDiffEnabled: boolean = yield select(
    getGlobalIsWordDiffEnabled
  );
  const globalIsColorBlindModeEnabled: boolean = yield select(
    getGlobalIsColorBlindModeEnabled
  );
  const globalShouldIgnoreWhitespace: boolean = yield select(
    getGlobalShouldIgnoreWhitespace
  );
  const globalIsAnnotationsEnabled: boolean = yield select(
    getGlobalIsAnnotationsEnabled
  );
  const globalIsSingleFileModeEnabled: boolean = yield select(
    getGlobalIsSingleFileModeEnabled
  );

  const newGlobalDiffViewMode = action.payload.diffViewMode;
  const newGlobalFileViewMode = action.payload.fileViewMode;
  const newGlobalTabSize = action.payload.tabSize;
  const newGlobalIsWordDiffEnabled = action.payload.isWordDiffEnabled;
  const newGlobalShouldIgnoreWhitespace = action.payload.shouldIgnoreWhitespace;
  const newGlobalIsColorBlindModeEnabled =
    action.payload.isColorBlindModeEnabled;
  const newGlobalIsAnnotationsEnabled = action.payload.isAnnotationsEnabled;
  const newGlobalIsSingleFileModeEnabled =
    action.payload.isSingleFileModeEnabled;

  const preferenceUpdateEffects: CallEffect[] = [];

  // This is just a passthrough for now, but the persistence logic to use a user pref (starting with COREX-1934)
  // will go here and this success action will then depend on the result of that API request
  if (newGlobalDiffViewMode !== globalDiffViewMode) {
    const successAction: UpdateGlobalDiffViewModeSuccessAction = {
      type: UPDATE_GLOBAL_DIFF_VIEW_MODE_SUCCESS,
      payload: {
        diffViewMode: newGlobalDiffViewMode,
      },
    };

    preferenceUpdateEffects.push(
      call(updateUserPreference, {
        key: GLOBAL_DIFF_VIEW_MODE_PREFERENCE_KEY,
        successAction,
        userUuid: currentUser?.uuid,
        value: newGlobalDiffViewMode,
      })
    );
  }

  if (newGlobalFileViewMode !== globalFileViewMode) {
    const successAction: UpdateGlobalFileViewModeSuccessAction = {
      type: UPDATE_GLOBAL_FILE_VIEW_MODE_SUCCESS,
      payload: {
        fileViewMode: newGlobalFileViewMode,
      },
    };

    preferenceUpdateEffects.push(
      call(updateUserPreference, {
        key: GLOBAL_FILE_VIEW_MODE_PREFERENCE_KEY,
        successAction,
        userUuid: currentUser?.uuid,
        value: newGlobalFileViewMode,
      })
    );
  }

  if (newGlobalTabSize !== globalTabSize) {
    const successAction: UpdateGlobalTabSizeSuccessAction = {
      type: UPDATE_GLOBAL_TAB_SIZE_SUCCESS,
      payload: {
        tabSize: newGlobalTabSize,
      },
    };
    preferenceUpdateEffects.push(
      call(updateUserPreference, {
        key: GLOBAL_TAB_SIZE_PREFERENCE_KEY,
        successAction,
        userUuid: currentUser?.uuid,
        value: newGlobalTabSize,
      })
    );
  }

  if (newGlobalIsWordDiffEnabled !== globalIsWordDiffEnabled) {
    const successAction: UpdateGlobalIsWordDiffEnabledSuccessAction = {
      type: UPDATE_GLOBAL_IS_WORD_DIFF_ENABLED_SUCCESS,
      payload: {
        isWordDiffEnabled: newGlobalIsWordDiffEnabled,
      },
    };

    preferenceUpdateEffects.push(
      call(updateUserPreference, {
        key: GLOBAL_WORD_DIFF_PREFERENCE_KEY,
        successAction,
        userUuid: currentUser?.uuid,
        value: newGlobalIsWordDiffEnabled,
      })
    );
  }

  if (newGlobalShouldIgnoreWhitespace !== globalShouldIgnoreWhitespace) {
    const successAction: UpdateGlobalShouldIgnoreWhitespaceSuccessAction = {
      type: UPDATE_GLOBAL_SHOULD_IGNORE_WHITESPACE_SUCCESS,
      payload: {
        shouldIgnoreWhitespace: newGlobalShouldIgnoreWhitespace,
      },
    };

    preferenceUpdateEffects.push(
      call(updateUserPreference, {
        key: GLOBAL_IGNORE_WHITESPACE_PREFERENCE_KEY,
        successAction,
        userUuid: currentUser?.uuid,
        value: newGlobalShouldIgnoreWhitespace,
      })
    );
  }

  if (newGlobalIsColorBlindModeEnabled !== globalIsColorBlindModeEnabled) {
    const successAction: UpdateGlobalIsColorBlindNodeEnabledSuccessAction = {
      type: UPDATE_GLOBAL_IS_COLOR_BLIND_MODE_ENABLED_SUCCESS,
      payload: {
        isColorBlindModeEnabled: newGlobalIsColorBlindModeEnabled,
      },
    };

    preferenceUpdateEffects.push(
      call(updateUserPreference, {
        key: GLOBAL_COLOR_BLIND_MODE_PREFERENCE_KEY,
        successAction,
        userUuid: currentUser?.uuid,
        value: newGlobalIsColorBlindModeEnabled,
      })
    );
  }

  if (newGlobalIsAnnotationsEnabled !== globalIsAnnotationsEnabled) {
    const successAction: UpdateGlobalIsAnnotationsEnabledSuccessAction = {
      type: UPDATE_GLOBAL_IS_ANNOTATIONS_ENABLED_SUCCESS,
      payload: {
        isAnnotationsEnabled: newGlobalIsAnnotationsEnabled,
      },
    };

    preferenceUpdateEffects.push(
      call(updateUserPreference, {
        key: GLOBAL_ANNOTATIONS_PREFERENCE_KEY,
        successAction,
        userUuid: currentUser?.uuid,
        value: newGlobalIsAnnotationsEnabled,
      })
    );
  }

  if (newGlobalIsSingleFileModeEnabled !== globalIsSingleFileModeEnabled) {
    const successAction: UpdateGlobalIsSingleFileModeEnabledSuccessAction = {
      type: UPDATE_GLOBAL_IS_SINGLE_FILE_MODE_ENABLED_SUCCESS,
      payload: {
        isSingleFileModeEnabled: newGlobalIsSingleFileModeEnabled,
      },
    };

    preferenceUpdateEffects.push(
      call(updateUserPreference, {
        key: GLOBAL_SINGLE_FILE_MODE_PREFERENCE_KEY,
        successAction,
        userUuid: currentUser?.uuid,
        value: newGlobalIsSingleFileModeEnabled,
      })
    );
  }

  const results: CallEffect[] = yield all(preferenceUpdateEffects);

  if (results.every(Boolean)) {
    const successAction: UpdateSettingsSuccessAction = {
      type: UPDATE_SETTINGS_SUCCESS,
    };
    yield put(successAction);
    yield put(showFlag(SUCCESS_FLAG));
  } else {
    yield put(showFlagComponent('diff-settings-error'));
  }
}

export default function* diffSettingsSagas() {
  yield all([takeLatest(UPDATE_SETTINGS_REQUEST, updateDiffSettingsSaga)]);
}
