import { WarningModal } from 'src/components/settings/modals';
import { Privilege } from 'src/components/types';
import RemoveAccessModal from 'src/sections/global/components/remove-access-modal';

import { Permission } from '../types';

import AddAccessModal from './add-access-modal';
import BulkChangePrivilegesModal from './bulk-change-privileges-modal';
import PartialInvitationMessageModal from './partial-invitation-message-modal';

const modals = {
  RemoveAccessModal,
  AddAccessModal,
  BulkChangePrivilegesModal,
  RemoveOwnAccessWarning: WarningModal,
  PartialInvitationMessageModal,
} as const;

export type ModalKeyType = keyof typeof modals;

export type ModalProps = {
  RemoveAccessModal?: Permission;
  AddAccessModal?: null;
  BulkChangePrivilegesModal?: null;
  RemoveOwnAccessWarning: [Permission, Privilege];
  PartialInvitationMessageModal: {
    successfullyAdded: string[];
    successfullyInvited: string[];
    nonAdminErrors: string[];
    domainErrors: string[];
  };
};

export type ModalPropsValue<T> = T extends ModalKeyType ? ModalProps[T] : never;

export type ModalType<T extends ModalKeyType> = {
  type: T;
  props: ModalPropsValue<T>;
};

export const isModalType = <K extends ModalKeyType>(
  type: K,
  modal?: ModalType<ModalKeyType>
): modal is ModalType<K> => modal?.type === type;

export default modals;
