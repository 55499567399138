import * as Sentry from '@sentry/browser';

import {
  RecentlyViewedRepositoriesWithWorkspace,
  RecentlyViewedRepositories,
} from 'src/redux/recently-viewed-repositories';
import store from 'src/utils/store';

type User = {
  uuid: string;
};

export const MAX_RECENTLY_VIEWED_REPOS = 15;

/* 
In this file, we store metadata into local storage as shown below to fetch recently viewed repo
uuids for workspace-scoped 'Your work'.

{
  "workspaceUuids": {
    "{workspace_uuid_1}": ["{repo_uuid_1}", "{repo_uuid_2}"],
    "{workspace_uuid_2}": ["{repo_uuid_3}"]
  }
}

The above data schema does not apply to the `recentlyViewedRepositories` reducer which
still expects an array of repo uuids due to several other dependencies.
*/

const storageName = (user: User) =>
  `recently-viewed-repo-uuids-v2:${user.uuid}`;

function insertRecentRepository(
  repositoryList: RecentlyViewedRepositories | undefined,
  repositoryUuid: string
): Array<string> {
  if (repositoryList === undefined) {
    return [repositoryUuid];
  } else {
    const idx = repositoryList.findIndex(r => repositoryUuid === r);
    if (idx === 0) {
      return repositoryList;
    } else if (idx !== -1) {
      return [
        repositoryUuid,
        ...repositoryList.slice(0, idx),
        ...repositoryList.slice(idx + 1),
      ];
    } else {
      return [
        repositoryUuid,
        ...repositoryList.slice(0, MAX_RECENTLY_VIEWED_REPOS - 1),
      ];
    }
  }
}

function add(
  user: User,
  repositoryUuid: string,
  workspaceUuid: string | undefined
): void {
  try {
    if (store.enabled) {
      if (!workspaceUuid) {
        // We should have workspace context but in case we don't
        throw new Error('Workspace context unavailable.');
      }
      const key = storageName(user);
      const repositoryStore: RecentlyViewedRepositoriesWithWorkspace =
        get(user);
      const { workspaceUuids } = repositoryStore;

      const repositoryUuids = insertRecentRepository(
        workspaceUuids[workspaceUuid],
        repositoryUuid
      );

      workspaceUuids[workspaceUuid] = repositoryUuids;

      store.set(key, { workspaceUuids });
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

function set(
  user: User,
  recentlyViewedRepositories: RecentlyViewedRepositoriesWithWorkspace
): void {
  if (store.enabled) {
    store.set(storageName(user), recentlyViewedRepositories);
  }
}

function get(user: User): RecentlyViewedRepositoriesWithWorkspace {
  const initialStore = {
    workspaceUuids: {},
  };
  if (!store.enabled) {
    return initialStore;
  }
  return store.get(storageName(user)) || initialStore;
}

export default { add, get, set };
