import {
  ApiCommentOrPlaceholder,
  isApiComment,
} from 'src/components/conversation-provider/types';
import {
  apiCommentId,
  conversationIdToTopCommentId,
} from 'src/components/conversation-provider/utils/to-conversation';

/** Class representing comments organized in the form of a tree-like structure where hierarchy is determined by nesting level */
export class CommentTree {
  private topLevelGlobalCommentList: ApiCommentOrPlaceholder[];
  private topLevelFileCommentMap = new Map<string, ApiCommentOrPlaceholder>();
  private childrenMap = new Map<number, ApiCommentOrPlaceholder[]>();
  private commentMap = new Map<string, ApiCommentOrPlaceholder>();

  constructor(comments?: ApiCommentOrPlaceholder[]) {
    this.topLevelGlobalCommentList = [];
    this.topLevelFileCommentMap = new Map();
    this.childrenMap = new Map();

    if (comments) {
      comments.forEach(comment => this.add(comment));
    }
  }

  /**
   * Gets a single comment by ID. This method is O(1) time complexity.
   * @param {number} commentId - The id of the comment to public get
   * @returns {ApiCommentOrPlaceholder | undefined} - The comment with the given id or undefined if not found
   */
  get(commentId: number | string): ApiCommentOrPlaceholder | undefined {
    return this.commentMap.get(commentId.toString());
  }

  /**
   * Returns a list of children of a comment
   * @param {number} commentId - The id of the comment to get children of
   * @returns {ApiCommentOrPlaceholder[]} - A list of children of the comment
   */
  childrenOf(commentId: number): ApiCommentOrPlaceholder[] {
    return this.childrenMap.get(commentId) ?? [];
  }

  /**
   * Returns a map of comment ids to their children
   * @returns {Map<number, ApiCommentOrPlaceholder[]>} - A map of comment ids to their children
   */
  commentChildrenMap(): Map<number, ApiCommentOrPlaceholder[]> {
    return this.childrenMap;
  }

  /**
   * Returns a list of top-level global comments
   * @returns {ApiCommentOrPlaceholder[]} - A list of top-level global comments
   */
  topLevelGlobalComments(): ApiCommentOrPlaceholder[] {
    return this.topLevelGlobalCommentList;
  }

  /**
   * Returns a list of top-level file comments
   * @returns {ApiCommentOrPlaceholder[]} - A list of top-level global comments
   */
  topLevelFileComments(): ApiCommentOrPlaceholder[] {
    return Array.from(this.topLevelFileCommentMap.values());
  }

  /**
   * Returns a list of all top-level comments
   * @returns {ApiCommentOrPlaceholder[]} - A list of all top-level comments
   */
  topLevelComments(): ApiCommentOrPlaceholder[] {
    return [...this.topLevelGlobalComments(), ...this.topLevelFileComments()];
  }

  /** Returns the top level comment with the given ID
   * @param {number} commentId - The id of the comment to get
   * @returns {ApiCommentOrPlaceholder | undefined} - The top-level comment with the given id or undefined if not found
   */
  topLevelCommentById(commentId: string): ApiCommentOrPlaceholder | undefined {
    const topLevelFileComment = this.topLevelFileCommentMap.get(commentId);
    if (topLevelFileComment) return topLevelFileComment;

    return this.topLevelGlobalComments().find(c =>
      isApiComment(c)
        ? c.id.toString() === commentId
        : c.placeholderId === commentId
    );
  }

  /**
   * Returns top-level global comments or a file comment from a conversationId
   * @param {string} conversationId - The id of the conversation of the top-level comment to get
   * @returns {ApiCommentOrPlaceholder[]} - The comment(s) with the given id or undefined if not found
   */
  topLevelCommentsByConversationId(
    conversationId: string
  ): ApiCommentOrPlaceholder[] {
    const commentId = conversationIdToTopCommentId(conversationId);
    if (commentId) {
      const comment = this.topLevelCommentById(commentId);
      if (comment) {
        if (comment.inline) {
          // This is a file comment; return it by itself
          return [comment];
        }
        // This is a global comment; we treat all global conversations as one, so return all top-level global comments
        return this.topLevelGlobalComments();
      }
    }

    return [];
  }

  /**
   * Adds a comment to the tree structure.
   * Order of addition matters as it does not determine hierarchy, but rather the order of comments in the tree.
   * Make sure to enforce a deterministic order of comment addition.
   * Adding a child before its parent will work as intended and add it as a child within `commentChildrenMap`,
   * but the parent comment's `id` will be present in the keys of `commentChildrenMap` even if the parent comment is not present in the tree.
   * @param comment - The comment to add to the tree
   */
  private add(comment: ApiCommentOrPlaceholder): void {
    const { parent } = comment;

    const commentId = apiCommentId(comment);
    if (commentId) {
      this.commentMap.set(commentId.toString(), comment);
    }

    if (parent) {
      // Comments with a parent are replies and should be added to the descendant map
      const previousThread = this.childrenMap.get(parent.id) ?? [];
      this.childrenMap.set(parent.id, [...previousThread, comment]);
    } else if (!comment.inline) {
      // Comments without a parent and no inline property are top-level global comments
      this.topLevelGlobalCommentList.push(comment);
    } else if (isApiComment(comment)) {
      // API comments without a parent are top-level file or inline comments
      this.topLevelFileCommentMap.set(comment.id.toString(), comment);
    } else {
      // All other comments at this point without a parent are top-level file or inline placeholder comments
      this.topLevelFileCommentMap.set(comment.placeholderId, comment);
    }
  }

  private filterThreads(
    newCommentTree: CommentTree,
    commentList: ApiCommentOrPlaceholder[],
    predicate: (comment: ApiCommentOrPlaceholder) => boolean
  ): void {
    for (const comment of commentList) {
      if (!isApiComment(comment)) {
        // Keep placeholder comments if they satisfy the predicate
        if (predicate(comment)) newCommentTree.add(comment);
        continue;
      }

      const children = this.childrenOf(comment.id);

      // Recursively clean nested comments of the current comment
      this.filterThreads(newCommentTree, children, predicate);
      const hasValidChildren = newCommentTree
        .commentChildrenMap()
        .has(comment.id);

      // Keep a comment if it has valid children or satisfies the predicate
      if (hasValidChildren || predicate(comment)) {
        newCommentTree.add(comment);
      }
    }
  }

  /**
   * Returns a new CommentTree with comments that either satisfy the predicate or have children that satisfy the predicate.
   * Comments that do not satisfy the predicate but have children that do satisfy the predicate will still be included in the new tree.
   * Comments within this tree can be either `ApiComment` or `PlaceholderApiComment` types, so make sure the predicate can handle both types.
   * @example
   * // Keep all placeholder comments
   * // Filter out deleted comments that do not have any non-deleted children or placeholder children
   * const newCommentTree = commentTree.filterMatchingSubtrees(c => !isApiComment(c) || !c.deleted);
   * @example
   * // Filter out comments that are not pending or do not have any pending children
   * // Placeholder comments are not treated any differently to API comments here
   * const newCommentTree = commentTree.filterMatchingSubtrees(c => c.pending);
   * @param predicate - Function that returns true if the comment should be included in the new tree
   * @returns {CommentTree} - A new CommentTree with comments that satisfy the predicate or have children that satisfy the predicate
   */
  filterMatchingSubtrees(
    predicate: (comment: ApiCommentOrPlaceholder) => boolean
  ): CommentTree {
    const newCommentTree = new CommentTree();

    this.filterThreads(newCommentTree, this.topLevelComments(), predicate);

    return newCommentTree;
  }

  /**
   * Returns all decendents of the provided comment ID, not including the provided comment.
   * @param commentId: The ID of the comment to find the descendents of
   * @returns {Generator<ApiCommentOrPlaceholder>} A generator of the descendents of the provided comment ID
   */
  *descendentsOf(commentId: number): Generator<ApiCommentOrPlaceholder> {
    for (const child of this.childrenOf(commentId)) {
      yield child;
      if ('id' in child) {
        yield* this.descendentsOf(child.id);
      }
    }
  }

  /**
   * Returns a thread for each global comment
   * @returns {CommentThread[]} An array of threads, each containing a global comment and its descendents
   */
  globalThreads(): CommentThread[] {
    return this.topLevelGlobalComments().map(comment =>
      this.getThreadForComment(comment)
    );
  }

  /**
   * Returns a thread for each file comment
   * @returns {CommentThread[]} An array of threads, each containing a file comment and its descendents
   */
  fileThreads(): CommentThread[] {
    return this.topLevelFileComments().map(comment =>
      this.getThreadForComment(comment)
    );
  }

  /**
   * Returns a thread for each global and each file comment
   * @returns {CommentThread[]} An array of threads, each containing a comment and its descendents
   */
  allThreads(): CommentThread[] {
    return this.topLevelComments().map(comment =>
      this.getThreadForComment(comment)
    );
  }

  /**
   * Returns a thread containing only the provided ID and its descendents
   * @param commentId: The ID of the comment to find the subtree of
   * @returns {CommentThread} A thread object containing only the provided comment and its descendents
   */
  getThreadForComment(topComment: ApiCommentOrPlaceholder): CommentThread {
    return {
      topComment,
      commentTree: new CommentTree(this.commentAndDescendents(topComment)),
    };
  }

  /**
   * Returns a list of the provided comment and its descendents.
   * @param comment: The comment to find the descendents of
   * @returns {ApiCommentOrPlaceholder[]} A list of the provided comment and its descendents
   */
  commentAndDescendents(
    comment: ApiCommentOrPlaceholder
  ): ApiCommentOrPlaceholder[] {
    return isApiComment(comment)
      ? [comment, ...this.descendentsOf(comment.id)]
      : [comment];
  }

  /**
   * Returns a thread containing only the provided ID and its descendents
   * @param commentId: The ID of the comment to find the subtree of
   * @returns {CommentThread} A thread object containing only the provided comment and its descendents
   */
  getThread(commentId: number | string): CommentThread | undefined {
    const topComment = this.topLevelCommentById(commentId.toString());
    if (topComment) {
      return this.getThreadForComment(topComment);
    }
    return undefined;
  }
}

export type CommentThread = {
  topComment: ApiCommentOrPlaceholder;
  commentTree: CommentTree;
};

export function commentsInThread(
  thread: CommentThread
): ApiCommentOrPlaceholder[] {
  return thread.commentTree.commentAndDescendents(thread.topComment);
}
