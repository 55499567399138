export var Journeys = {
  GET_STARTED: 'get-started',
  DECIDE: 'decide',
  DISCOVER: 'discover'
};
export var Reasons = {
  NO_API_SUPPORT: 'Could not provide requested API version',
  NO_PROVIDER: 'Provider not found'
};

// equivalent of `typeof Reason.NO_API_SUPPORT | typeof Reason.NO_PROVIDER | ...`