import { getExtensionAttrs } from '../../utils/extensions';
import { extension as extensionFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name extension_node
 */

/**
 * @name extension_with_marks_node
 */

export var extension = extensionFactory({
  parseDOM: [{
    tag: '[data-node-type="extension"]',
    getAttrs: function getAttrs(domNode) {
      return getExtensionAttrs(domNode);
    }
  }],
  toDOM: function toDOM(node) {
    var attrs = {
      'data-node-type': 'extension',
      'data-extension-type': node.attrs.extensionType,
      'data-extension-key': node.attrs.extensionKey,
      'data-text': node.attrs.text,
      'data-parameters': JSON.stringify(node.attrs.parameters),
      'data-layout': node.attrs.layout,
      'data-local-id:': node.attrs.localId
    };
    return ['div', attrs];
  }
});