import { cloneDeep } from 'lodash-es';

import { LoadingStatus } from 'src/constants/loading-status';
import { WorkspacePermission } from 'src/redux/workspaces/constants';
import createReducer from 'src/utils/create-reducer';

import {
  FETCH_JIRA_SITES,
  FETCH_JIRA_RELEVANT_PROJECTS,
  FETCH_WORKSPACE_PERMISSION,
  INSTALL_ADDON_JIRA_TAB,
  HYDRATE_JIRA_DATA,
  SET_EMPTY_STATE_KIND,
  RESET_STATE,
  FETCH_RELEVANT_JIRA_SITES,
  FILTER_STATE,
  FETCH_JIRA_RELEVANT_ISSUES,
  FETCH_DEV_ACTIVITY,
  FETCH_JIRA_ASSIGNEES,
  FETCH_JIRA_TAB_FILTERS_USER_PREFERENCE,
  JIRA_TAB_FILTERS_USER_PREFERENCE_NOT_REQUIRED,
  MANAGE_PROJECTS_DIALOG_OPEN_CHANGE,
  PROJECTS_MENU_OPEN_CHANGE,
  UNLINK_PROJECT_DIALOG_OPEN_CHANGE,
  FETCH_JIRA_PROJECTS,
  LINK_JIRA_PROJECT,
  UNLINK_JIRA_PROJECT,
  UPDATE_RELEVANT_JIRA_PROJECTS,
  CREATE_ISSUE_DIALOG_OPEN_CHANGE,
  SET_ISSUE_ASSIGNEE,
} from '../actions';
import {
  AppInstalledStatus,
  EmptyStateKind,
  IssueCategory,
} from '../constants';
import {
  Site,
  ProjectAssociation,
  JiraIssue,
  FilterState,
  Sort,
  DevActivityMap,
  Assignee,
  Project,
  SORT_ORDER,
} from '../types';

export type RepositoryJiraState = {
  appInstalledStatus: AppInstalledStatus;
  emptyStateKind: EmptyStateKind;
  projectAssociations: ProjectAssociation[];
  relevantProjectsFetchedStatus: LoadingStatus;
  relevantSites: Site[];
  relevantSitesFetchedStatus: LoadingStatus;
  sites: Site[];
  sitesFetchedStatus: LoadingStatus;
  jiraRelevantIssuesFetchedStatus: LoadingStatus;
  jiraRelevantIssues: JiraIssue[];
  jiraRelevantIssuesPages: number;
  devActivity: DevActivityMap;
  workspacePermission?: WorkspacePermission;
  workspacePermissionFetchedStatus: LoadingStatus;
  filterState: FilterState;
  assignees: Assignee[];
  assigneesFetchedStatus: LoadingStatus;
  filterPreferencesFetchedStatus: LoadingStatus | 'NOT_REQUIRED';
  isManageProjectsDialogOpen: boolean;
  isProjectsMenuOpen: boolean;
  projectToUnlink: Project | undefined;
  linkJiraProjectStatus: LoadingStatus;
  unlinkJiraProjectStatus: LoadingStatus;
  projects: {
    [cloudId: string]: {
      projects: Project[];
      fetchedStatus: LoadingStatus;
      size: number;
    };
  };
  isCreateIssueDialogOpen: boolean;
};

export const emptyFilterState = {
  siteCloudId: '',
  projectIds: [],
  issueCategories: [],
  textFilterQuery: '',
  assignees: [],
  sort: {
    field: 'updated',
    order: 'DESC',
  } as Sort,
  currentPage: 1,
};

export const defaultFilterState = {
  ...emptyFilterState,
  issueCategories: [IssueCategory.Todo, IssueCategory.InProgress],
};

export const defaultCreateJiraIssueState = {
  selectedProject: undefined,
};

export const initialState: RepositoryJiraState = {
  appInstalledStatus: AppInstalledStatus.Before,
  emptyStateKind: EmptyStateKind.Before,
  projectAssociations: [],
  relevantProjectsFetchedStatus: LoadingStatus.Before,
  relevantSites: [],
  relevantSitesFetchedStatus: LoadingStatus.Before,
  sites: [],
  sitesFetchedStatus: LoadingStatus.Before,
  jiraRelevantIssuesFetchedStatus: LoadingStatus.Before,
  jiraRelevantIssues: [],
  jiraRelevantIssuesPages: 0,
  devActivity: {},
  workspacePermission: undefined,
  workspacePermissionFetchedStatus: LoadingStatus.Before,
  filterState: defaultFilterState,
  assignees: [],
  assigneesFetchedStatus: LoadingStatus.Before,
  filterPreferencesFetchedStatus: LoadingStatus.Before,
  isManageProjectsDialogOpen: false,
  isProjectsMenuOpen: false,
  projectToUnlink: undefined,
  linkJiraProjectStatus: LoadingStatus.Before,
  unlinkJiraProjectStatus: LoadingStatus.Before,
  projects: {},
  isCreateIssueDialogOpen: false,
};

export default createReducer(initialState, {
  [HYDRATE_JIRA_DATA.REQUEST]: state => ({
    ...state,
    appInstalledStatus: AppInstalledStatus.Fetching,
  }),
  [HYDRATE_JIRA_DATA.SUCCESS]: (state, { payload }) => ({
    ...state,
    appInstalledStatus: payload.appInstalled
      ? AppInstalledStatus.Installed
      : AppInstalledStatus.NotInstalled,
  }),
  [HYDRATE_JIRA_DATA.ERROR]: state => ({
    ...state,
    appInstalledStatus: AppInstalledStatus.FailedToFetch,
  }),
  [INSTALL_ADDON_JIRA_TAB.REQUEST]: state => ({
    ...state,
    appInstalledStatus: AppInstalledStatus.Installing,
  }),
  [INSTALL_ADDON_JIRA_TAB.SUCCESS]: state => ({
    ...state,
    appInstalledStatus: AppInstalledStatus.Installed,
  }),
  [INSTALL_ADDON_JIRA_TAB.ERROR]: state => ({
    ...state,
    appInstalledStatus: AppInstalledStatus.FailedToInstall,
  }),

  // Jira sites
  [FETCH_JIRA_SITES.REQUEST]: state => ({
    ...state,
    sitesFetchedStatus: LoadingStatus.Fetching,
    sites: [],
  }),
  [FETCH_JIRA_SITES.SUCCESS]: (state, { payload }) => ({
    ...state,
    sitesFetchedStatus: LoadingStatus.Success,
    sites: payload,
  }),
  [FETCH_JIRA_SITES.ERROR]: state => ({
    ...state,
    sitesFetchedStatus: LoadingStatus.Failed,
    sites: [],
  }),

  // Jira projects
  [FETCH_JIRA_PROJECTS.REQUEST]: (state, { payload }) => ({
    ...state,
    projects: {
      ...state.projects,
      [payload.cloudId]: {
        fetchedStatus: LoadingStatus.Fetching,
        projects: [],
      },
    },
  }),
  [FETCH_JIRA_PROJECTS.SUCCESS]: (state, { payload }) => ({
    ...state,
    projects: {
      ...state.projects,
      ...payload,
    },
  }),
  [FETCH_JIRA_PROJECTS.ERROR]: (state, { payload }) => ({
    ...state,
    projects: {
      ...state.projects,
      [payload.cloudId]: {
        fetchedStatus: LoadingStatus.Failed,
        projects: [],
      },
    },
  }),

  // Jira relevant issues
  [FETCH_JIRA_RELEVANT_ISSUES.REQUEST]: state => ({
    ...state,
    jiraRelevantIssuesFetchedStatus: LoadingStatus.Fetching,
    jiraRelevantIssues: [],
    devActivity: {},
  }),
  [FETCH_JIRA_RELEVANT_ISSUES.SUCCESS]: (state, { payload }) => ({
    ...state,
    jiraRelevantIssuesFetchedStatus: LoadingStatus.Success,
    jiraRelevantIssues: payload.values,
    jiraRelevantIssuesPages: Math.ceil(payload.size / payload.pagelen),
  }),
  [FETCH_JIRA_RELEVANT_ISSUES.ERROR]: state => ({
    ...state,
    jiraRelevantIssuesFetchedStatus: LoadingStatus.Failed,
    jiraRelevantIssues: [],
    devActivity: {},
  }),

  // Dev activity (for icon with branch/pull request status for issues)
  [FETCH_DEV_ACTIVITY.REQUEST]: state => ({
    ...state,
    devActivity: {},
  }),
  [FETCH_DEV_ACTIVITY.SUCCESS]: (state, { payload }) => {
    // Update existing dev activity data for sites where we already had data,
    // instead of overwriting all the data for a site (which the normal shallow
    // spread would do). That means if you switch back and forth between pages,
    // the dev activity shows up immediately after issues are loaded.
    const devActivity = { ...state.devActivity };
    Object.keys(payload).forEach(cloudId => {
      devActivity[cloudId] = {
        ...devActivity[cloudId],
        ...payload[cloudId],
      };
    });
    return {
      ...state,
      devActivity,
    };
  },

  // Relevant Jira sites
  [FETCH_RELEVANT_JIRA_SITES.REQUEST]: state => ({
    ...state,
    relevantSitesFetchedStatus: LoadingStatus.Fetching,
    relevantSites: [],
  }),
  [FETCH_RELEVANT_JIRA_SITES.SUCCESS]: (
    state,
    { payload: { values, hasPermission } }
  ) => ({
    ...state,
    relevantSitesFetchedStatus: hasPermission
      ? LoadingStatus.Success
      : LoadingStatus.Forbidden,
    relevantSites: values,
  }),
  [FETCH_RELEVANT_JIRA_SITES.ERROR]: state => ({
    ...state,
    relevantSitesFetchedStatus: LoadingStatus.Failed,
    relevantSites: [],
  }),

  // Jira relevant projects
  [FETCH_JIRA_RELEVANT_PROJECTS.REQUEST]: state => ({
    ...state,
    relevantProjectsFetchedStatus: LoadingStatus.Fetching,
    projectAssociations: [],
  }),
  [FETCH_JIRA_RELEVANT_PROJECTS.SUCCESS]: (
    state,
    { payload: { projectAssociations, projectIds, hasPermission } }
  ) => {
    return {
      ...state,
      filterState: {
        ...state.filterState,
        projectIds,
      },
      relevantProjectsFetchedStatus: hasPermission
        ? LoadingStatus.Success
        : LoadingStatus.Forbidden,
      projectAssociations,
    };
  },
  [FETCH_JIRA_RELEVANT_PROJECTS.ERROR]: state => ({
    ...state,
    relevantProjectsFetchedStatus: LoadingStatus.Failed,
    projectAssociations: [],
  }),

  // Jira relevant assignees
  [FETCH_JIRA_ASSIGNEES.REQUEST]: state => ({
    ...state,
    assigneesFetchedStatus: LoadingStatus.Before,
    assignees: [],
  }),
  [FETCH_JIRA_ASSIGNEES.SUCCESS]: (state, { payload: { values } }) => ({
    ...state,
    assigneesFetchedStatus: LoadingStatus.Success,
    assignees: values,
  }),
  [FETCH_JIRA_ASSIGNEES.ERROR]: state => ({
    ...state,
    assigneesFetchedStatus: LoadingStatus.Failed,
    assignees: [],
  }),

  // Filters user preference
  [FETCH_JIRA_TAB_FILTERS_USER_PREFERENCE.REQUEST]: state => ({
    ...state,
    filterPreferencesFetchedStatus: LoadingStatus.Fetching,
  }),
  [FETCH_JIRA_TAB_FILTERS_USER_PREFERENCE.SUCCESS]: state => ({
    ...state,
    filterPreferencesFetchedStatus: LoadingStatus.Success,
  }),
  [FETCH_JIRA_TAB_FILTERS_USER_PREFERENCE.ERROR]: state => ({
    ...state,
    filterPreferencesFetchedStatus: LoadingStatus.Failed,
  }),
  [JIRA_TAB_FILTERS_USER_PREFERENCE_NOT_REQUIRED]: state => ({
    ...state,
    filterPreferencesFetchedStatus: 'NOT_REQUIRED' as const,
  }),

  // Workspace permission
  [FETCH_WORKSPACE_PERMISSION.REQUEST]: state => ({
    ...state,
    workspacePermission: undefined,
    workspacePermissionFetchedStatus: LoadingStatus.Fetching,
  }),
  [FETCH_WORKSPACE_PERMISSION.SUCCESS]: (state, { payload }) => ({
    ...state,
    workspacePermission: payload[0] && payload[0].permission,
    workspacePermissionFetchedStatus: LoadingStatus.Success,
  }),
  [FETCH_WORKSPACE_PERMISSION.ERROR]: state => ({
    ...state,
    workspacePermission: undefined,
    workspacePermissionFetchedStatus: LoadingStatus.Failed,
  }),

  [FILTER_STATE.SAVE]: (state, { payload }) => ({
    ...state,
    filterState: {
      ...state.filterState,
      ...payload,
    },
  }),

  [SET_EMPTY_STATE_KIND]: (state, { payload }) => ({
    ...state,
    emptyStateKind: payload,
  }),
  [RESET_STATE]: () => ({
    ...initialState,
  }),

  [MANAGE_PROJECTS_DIALOG_OPEN_CHANGE]: (state, { payload }) => ({
    ...state,
    isManageProjectsDialogOpen: payload,
  }),

  [PROJECTS_MENU_OPEN_CHANGE]: (state, { payload }) => ({
    ...state,
    isProjectsMenuOpen: payload,
  }),

  [UNLINK_PROJECT_DIALOG_OPEN_CHANGE]: (state, { payload }) => ({
    ...state,
    projectToUnlink: payload,
  }),

  // Link Jira project
  [LINK_JIRA_PROJECT.REQUEST]: state => ({
    ...state,
    linkJiraProjectStatus: LoadingStatus.Fetching,
  }),
  [LINK_JIRA_PROJECT.SUCCESS]: state => ({
    ...state,
    linkJiraProjectStatus: LoadingStatus.Success,
  }),
  [LINK_JIRA_PROJECT.ERROR]: state => ({
    ...state,
    linkJiraProjectStatus: LoadingStatus.Failed,
  }),

  // Unlink Jira project
  [UNLINK_JIRA_PROJECT.REQUEST]: state => ({
    ...state,
    unlinkJiraProjectStatus: LoadingStatus.Fetching,
  }),
  [UNLINK_JIRA_PROJECT.SUCCESS]: state => ({
    ...state,
    unlinkJiraProjectStatus: LoadingStatus.Success,
  }),
  [UNLINK_JIRA_PROJECT.ERROR]: state => ({
    ...state,
    unlinkJiraProjectStatus: LoadingStatus.Failed,
  }),

  [UPDATE_RELEVANT_JIRA_PROJECTS]: (state, { payload }) => ({
    ...state,
    projectAssociations: payload,
    relevantProjectsFetchedStatus: LoadingStatus.Success,
  }),

  [CREATE_ISSUE_DIALOG_OPEN_CHANGE]: (state, { payload }) => ({
    ...state,
    isCreateIssueDialogOpen: payload,
  }),

  [SET_ISSUE_ASSIGNEE.SUCCESS]: (state, { payload }) => {
    const currentIssues = cloneDeep(state.jiraRelevantIssues);
    const selectedIssue = currentIssues.find(
      issue => issue.key === payload.issueKey
    )!;
    const currentSort = state.filterState.sort;
    const sortOrderMultiplier = currentSort.order === SORT_ORDER.DESC ? -1 : 1;

    selectedIssue.assignee = payload.currentJiraUser;
    selectedIssue.updated = new Date().toISOString();

    if (currentSort.field === 'assignee') {
      currentIssues.sort((a, b) => {
        if (a.assignee?.accountId === b.assignee?.accountId) {
          return 0;
        } else if (!a.assignee) {
          return sortOrderMultiplier;
        } else if (!b.assignee) {
          return -sortOrderMultiplier;
        }
        return (
          sortOrderMultiplier *
          a.assignee.displayName.localeCompare(b.assignee.displayName)
        );
      });
    } else if (currentSort.field === 'updated') {
      currentIssues.sort((a, b) => {
        if (a.updated === b.updated) {
          return 0;
        } else if (!a.updated) {
          return sortOrderMultiplier;
        } else if (!b.updated) {
          return -sortOrderMultiplier;
        }
        return (
          sortOrderMultiplier *
          (new Date(a.updated) > new Date(b.updated) ? 1 : -1)
        );
      });
    }

    return {
      ...state,
      jiraRelevantIssues: currentIssues,
    };
  },
});
