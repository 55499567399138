import { useContext, useCallback, useMemo, useEffect } from 'react';

import memoize from 'memoize-one';
import { useSelector } from 'react-redux';

import { uncurlyUuid } from '@atlassian/bitkit-analytics';
import {
  ExperienceTracker,
  ExperienceEvent,
  ExperienceTrackerContext,
} from '@atlassian/experience-tracker';
import { CollectFn } from '@atlassian/experience-tracker/dist/types/ExperienceTracker.d';

import { generateUuid } from 'src/components/conversation/src/internal/uuid';
import { getCurrentPullRequestId } from 'src/redux/pull-request/selectors';
import {
  getCurrentRepositoryUuid,
  getCurrentRepositoryWorkspaceUUID,
} from 'src/selectors/repository-selectors';

import { publishOperationalEvent } from './publish';

export enum ExperienceName {
  PULL_REQUEST_APPROVE = 'pull-request/approve',
  PULL_REQUEST_DIFF = 'pull-request/diff',
  PULL_REQUEST_CREATE = 'pull-request/create',

  // sub-experiences for PR Create
  CREATE_PR_SOURCE = 'create-pr/source/load',
  CREATE_PR_DEST = 'create-pr/dest/load',
}

export enum ExperienceTimeout {
  DEFAULT = 40000, // ms
}

// Taken from https://bitbucket.org/atlassian/atlassian-frontend/src/develop/packages/confluence/embedded-confluence-common/src/analytics/createExperienceTrackerAnalyticsEventPayload.ts
export const createExperienceTrackerAnalyticsEventPayload = (
  event: ExperienceEvent
) => ({
  // Events should match the schema defined here:
  // https://hello.atlassian.net/wiki/spaces/BIZOPS/pages/387788554/Analytics+Event+Schema+for+SLA
  source: 'ui',
  actionSubject: 'ui',
  actionSubjectId: event.name,
  action: event.action,
  eventType: 'operational',
  attributes: createExperienceTrackerAnalyticsAttributes(event),
});

type ExperienceError = Error & {
  traceId?: string | null;
};

type Attributes = Omit<ExperienceEvent, 'action' | 'name' | 'id'> & {
  browserInfo: string;
  isActiveTab: boolean;
  error?: string | ExperienceError;
  errorMessage?: Error['message'];
  errorName?: Error['name'];
  task: ExperienceEvent['name'];
  taskId: ExperienceEvent['id'];
  traceId?: ExperienceError['traceId'];
};

type StartOptions = {
  name: ExperienceName;
  id?: string;
  timeout?: number;
  startTime?: number;
  attributes?: object;
  onSuccess?: () => void;
  onFailure?: () => void;
  onAbort?: () => void;
  collect?: CollectFn;
};

function createExperienceTrackerAnalyticsAttributes(event: ExperienceEvent) {
  const { action, name, id, attributes: experienceAttributes, ...rest } = event;

  const attributes: Partial<Attributes> = {
    ...experienceAttributes,
    ...rest,
    task: name,
    taskId: id,
  };

  if (attributes.error) {
    let error: ExperienceError;

    if (typeof attributes.error === 'string') {
      error = new Error(attributes.error);
    } else {
      error = attributes.error as ExperienceError;
    }

    delete attributes.error;
    attributes.errorName = error.name;

    if (!attributes.traceId && error.traceId) {
      attributes.traceId = error.traceId;
    }

    attributes.errorMessage = error.message;
  }

  attributes.browserInfo = window.navigator.userAgent;

  // Matches the analytics-web-client's apdex event
  attributes.isActiveTab = document.visibilityState === 'visible';

  return attributes as Attributes;
}

export const getExperienceTracker = memoize(() => {
  const tracker = new ExperienceTracker();

  tracker.subscribe(event => {
    if (
      event.action === 'taskSuccess' ||
      event.action === 'taskFail' ||
      event.action === 'taskAbort' ||
      event.action === 'taskStart'
    ) {
      publishOperationalEvent(
        createExperienceTrackerAnalyticsEventPayload(event)
      );
    }
  });

  return tracker;
});

function isFetchNetworkError(e: Error) {
  return (
    e instanceof TypeError &&
    [
      'NetworkError when attempting to fetch resource.', // Firefox
      'Failed to fetch', // Chrome
      'Load failed', // Safari
    ].includes(e.message)
  );
}

export const useExperienceTracker = (name: ExperienceName) => {
  const experienceTracker = useContext(ExperienceTrackerContext);
  const repositoryId = useSelector(getCurrentRepositoryUuid);
  const workspaceId = useSelector(getCurrentRepositoryWorkspaceUUID);
  const prId = useSelector(getCurrentPullRequestId);

  if (experienceTracker === null) {
    throw new Error(
      'useExperienceTracker requires a parent ExperienceTrackerContextProvider'
    );
  }

  const ids = useMemo(() => {
    return {
      repositoryId: uncurlyUuid(repositoryId || ''),
      workspaceId: uncurlyUuid(workspaceId || ''),
      prId,
    };
  }, [repositoryId, workspaceId, prId]);

  const startExperience = useCallback(
    (options?: Omit<StartOptions, 'name'>) => {
      experienceTracker.start({
        id: generateUuid(),
        // timeout: ExperienceTimeout.DEFAULT,
        attributes: ids,
        ...options,
        name,
      });
    },
    [name, ids, experienceTracker]
  );
  const succeedExperience = useCallback(
    // allow consumer to pass additional event attributes on success
    (additionalAttributes?: object) => {
      experienceTracker.succeed({
        name,
        attributes: additionalAttributes
          ? { ...additionalAttributes, ...ids }
          : ids,
      });
    },
    [name, ids, experienceTracker]
  );

  const abortExperience = useCallback(
    (reason: string) => {
      experienceTracker.abort({
        name,
        reason,
        attributes: ids,
        checkForTimeout: false, // disallow `taskAbort` to be converted into `taskFail`
      });
    },
    [name, ids, experienceTracker]
  );

  const failExperience = useCallback(
    (error: Error) => {
      // These errors are indicative of client-side failures to access Bitbucket.
      // We don't want to count these against our SLA calculations.
      if (isFetchNetworkError(error)) {
        abortExperience(error.message);
      } else {
        experienceTracker.fail({
          name,
          error,
          attributes: ids,
        });
      }
    },
    [name, ids, experienceTracker, abortExperience]
  );

  useEffect(() => {
    return () => {
      abortExperience('component unload');
    };
  }, [abortExperience]);

  return {
    startExperience,
    succeedExperience,
    failExperience,
    abortExperience,
  };
};

type ExperienceFailureProps = {
  name: ExperienceName;
  error: Error;
  /* eslint react/no-unused-prop-types: "warn" */
  attributes?: object;
};

type ExperienceAbortProps = {
  name: ExperienceName;
  reason: string;
  attributes?: object;
  checkForTimeout?: boolean;
};

type ExperienceSuccessProps = {
  name: ExperienceName;
  attributes?: object;
};

type ExperienceStartProps = {
  options: StartOptions;
};

export function ExperienceFailure({
  name,
  error,
}: ExperienceFailureProps): null {
  const { failExperience } = useExperienceTracker(name);

  useEffect(() => failExperience(error), [failExperience, error]);

  return null;
}

export function ExperienceSuccess({ name }: ExperienceSuccessProps): null {
  const { succeedExperience } = useExperienceTracker(name);

  useEffect(() => succeedExperience(), [succeedExperience]);

  return null;
}

export function ExperienceStart({ options }: ExperienceStartProps): null {
  const { startExperience } = useExperienceTracker(options.name);

  useEffect(() => {
    startExperience(options);
  }, [startExperience, options]);

  return null;
}

export function ExperienceAbort({ reason, name }: ExperienceAbortProps): null {
  const { abortExperience } = useExperienceTracker(name);

  useEffect(() => abortExperience(reason), [abortExperience, reason]);

  return null;
}
