import {
  FETCH_CODE_INSIGHTS_REPORTS,
  FETCH_CODE_INSIGHTS_ANNOTATIONS,
} from './constants';

export const fetchCodeInsightsAnnotations = (reportId: string) => ({
  type: FETCH_CODE_INSIGHTS_ANNOTATIONS.REQUEST,
  payload: reportId,
});

export default () => ({
  type: FETCH_CODE_INSIGHTS_REPORTS.REQUEST,
});
