import { inlineCard as inlineCardFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name inlineCard_node
 */

export var inlineCard = inlineCardFactory({
  parseDOM: [{
    tag: 'a[data-inline-card], span[data-inline-card]',
    // bump priority higher than hyperlink
    priority: 100,
    getAttrs: function getAttrs(dom) {
      var anchor = dom;
      var data = anchor.getAttribute('data-card-data');

      /* Support attrs from Editor and Renderer */
      return {
        url: anchor.getAttribute('href') || anchor.getAttribute('data-card-url') || null,
        data: data ? JSON.parse(data) : null
      };
    }
  },
  // for renderer
  {
    tag: 'div[data-inline-card]',
    getAttrs: function getAttrs(dom) {
      var anchor = dom;
      var data = anchor.getAttribute('data-card-data');
      return {
        url: anchor.getAttribute('data-card-url'),
        data: data ? JSON.parse(data) : null
      };
    }
  }],
  toDOM: function toDOM(node) {
    var attrs = {
      'data-inline-card': '',
      href: node.attrs.url || '',
      'data-card-data': node.attrs.data ? JSON.stringify(node.attrs.data) : ''
    };
    if (node.attrs.url) {
      return ['a', attrs, node.attrs.url];
    } else {
      return ['a', attrs];
    }
  }
});