import { text as textFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name text_node
 */

export var text = textFactory({
  toDebugString: process.env.NODE_ENV !== 'production' ? undefined : function () {
    return 'text_node';
  }
});