import { User as BBUser, Link } from 'src/components/types';

import { FabricUser } from '../bbc-atlaskit-editor';
import { Resolution } from '../conversation/src/components/types';

export type InlineField = {
  from: number | null;
  to: number | null;
  path?: string | null | undefined;
  outdated?: boolean;
  context_lines?: string;
  base_rev?: string;
  src_rev?: string;
  dest_rev?: string;
};

export type ADF = {
  version: number;
  type: 'doc';
  content: any[];
};

/**
 * Comment in the format needed by conversation components (such as AkComment)
 * for rendering. The state field is used to indicate the comment
 * is "in-flight" and causes the UI to render the text "Sending"
 * above the comment text.
 */
export type FabricComment = {
  commentId: number;
  localId: number;
  conversationId: string;
  parentId?: number;
  document: {
    adf?: ADF;
  };
  createdBy: FabricUser;
  createdAt: string;
  deleted?: boolean;
  state?: 'SAVING' | 'ERROR';
  resolution?: Resolution;
  pending?: boolean;
  nestedDepth?: number;
};

export type ApiCommentPR = {
  id: number;
  links: {
    html: Link;
    self: Link;
  };
  title: string;
  type: string;
};

/**
 * The raw representation of a comment in our store which is converted
 * into FabricComment for rendering in the UI. ApiComment maps to the
 * backend response we receive for any comment related operation such
 * as creating a comment, updating a comment and getting a comment.
 */
export type ApiComment = {
  content: {
    raw: string;
    markup: string;
    html: string;
    type: string;
    // Unused by backend: When comment is in "SAVING"/"in-flight" state
    // this field is used to render rich-text such as mentions, tables etc.
    // cleared upon successful response from backend when comment is no longer
    // "in-flight"
    doc?: ADF;
  };
  created_on: string;
  deleted: boolean;
  id: number;
  inline?: InlineField;
  links: { href: Link; self: Link };
  parent?: {
    id: number;
  };
  pullrequest: ApiCommentPR;
  type: string;
  updated_on: string;
  user: BBUser;
  resolution?: Resolution;
  pending?: boolean;
  state?: 'SAVING' | 'ERROR';
};

/**
 * Another "raw" representation of a  comment that is soon-to-be-saved but
 * is "in-flight" as the request to save them it been sent, but we haven't
 * yet received a successful response from the server. This is used to
 * render the just edited comment in the UI with "Sending" status. This
 * comment is similarly converted to FabricComment for rendering in the
 * Conversation and Comment component. This comment is stored in the
 * store alongside ApiComment and is replaced by an ApiComment when we
 * receive a response, when it's no longer an "in-flight" comment.
 */
export type PlaceholderApiComment = {
  placeholderId: string;
  created_on: string;
  updated_on: string;
  user: BBUser;
  content: { doc: ADF };
  inline?: InlineField;
  parent?: {
    id: number;
  };
  resolution?: Resolution;
  pending?: boolean;
  state?: 'SAVING' | 'ERROR';
};

export type ApiCommentOrPlaceholder = ApiComment | PlaceholderApiComment;

// narrow access of comment fields, particularly for rendering
export const isApiComment = (
  comment: ApiCommentOrPlaceholder
): comment is ApiComment => 'id' in comment && 'deleted' in comment;

export type ApiCommentLikes = {
  comment_id: number;
  users: BBUser[];
};

export type CommentLikes = {
  commentId: number | string;
  users: BBUser[];
};

// Helper type for narrowing access
type FilePathComment = ApiComment & {
  inline: {
    path: string;
  };
};
// Helper to narrow access to comment fields
export const isFileComment = (
  c: ApiCommentOrPlaceholder
): c is FilePathComment => {
  return !!c.inline && !!c.inline.path;
};

export type MinimalCodeReviewConversationComment = {
  id: number;
  permalink: string;
  authorUuid: string | null;
};

export type CodeReviewConversation = {
  conversationId: string;
  meta: InlineField;
  numOfComments: number;
  createdAt: string;
  createdBy: FabricUser;
  comments: MinimalCodeReviewConversationComment[];
};

export type FabricConversation = {
  conversationId: string;
  containerId: string;
  localId?: string;
  comments: FabricComment[];
  meta: InlineField;
  createdAt: string;
};

export type ProviderUrls = {
  commentSave: () => string;
  commentUpdate: (commentId: string | number) => string;
  commentDelete: (commentId: string | number) => string;
  commentResolve?: (commentId: string | number) => string;
};

export type ProviderConfig = {
  anchor?: string;
  destRev?: string;
  user: FabricUser | BBUser;
  urls: ProviderUrls;
  onAddComment: (comment: ApiComment) => void;
  onDeleteComment: (comment: { id: string }) => void;
};
