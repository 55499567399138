import { FetchAction, hydrateAction, MetaArguments } from 'src/redux/actions';
import urls from 'src/urls/profile';

import { LoadProfilePage } from './';

export const getApiUrl = (workspaceSlug: string) =>
  urls.ui.profile(workspaceSlug);
export const stateKey = ['profile.section', 'section.profile'];

export default (
  workspaceSlug: string,
  meta: Partial<MetaArguments> = {}
): FetchAction =>
  hydrateAction(LoadProfilePage, stateKey, {
    ...meta,
    url: getApiUrl(workspaceSlug),
  });
