/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { gridSize, fontSize, colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const SearchFieldContainer = styled.div<{
  widthGridMultiplier?: number;
}>`
  width: ${props =>
    props.widthGridMultiplier
      ? gridSize() * props.widthGridMultiplier
      : gridSize() * 30}px;

  & > div {
    height: ${gridSize() * 4}px;

    &:focus,
    &:hover {
      height: ${gridSize() * 4}px;
    }
  }
`;

export const SearchIconContainer = styled.span`
  padding-right: ${gridSize()}px;
  padding-top: ${gridSize() / 4}px;
`;

export const HeaderCheckbox = styled.span`
  vertical-align: bottom;
`;

export const BulkActionsHeader = styled.div`
  color: ${token('color.text', colors.N800)};
  font-size: ${fontSize()}px;

  & button:nth-of-type(2),
  & button:nth-of-type(3) {
    margin-left: ${gridSize() * 1.5}px;
  }
`;
