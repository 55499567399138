import React, { useCallback, useState } from 'react';

import { ErrorMessage } from '@atlaskit/form';
import {
  AsyncCreatableSelect,
  AsyncSelect,
  SelectProps,
  StylesConfig,
} from '@atlaskit/select';

import ModalDialog from 'src/components/modal-dialog-actions';
import { formatOptionLabel } from 'src/components/settings/permissions/modals/format-option-label';
import PrivilegesDropdown from 'src/components/settings/privileges-dropdown';
import { modalSelectStyles } from 'src/components/settings/styles/select';
import { Privilege } from 'src/components/types';
import { useIntl } from 'src/hooks/intl';
import { isValidEmail } from 'src/utils/is-valid-email';

import { AccessLevel } from '../constants';
import { useIsSumEnabled } from '../provider';
import {
  AccessLevelType,
  AccessMode,
  FlatPrincipal,
  SearchUsersAndGroups,
} from '../types';

import messages from './modals.i18n';
import { DescriptionContainer } from './modals.style';

export type AddAccessModalProps = {
  isLoading: boolean;
  onClose: () => void;
  onAdd: (list: FlatPrincipal[], privilege: Privilege) => void;
  searchUsersAndGroups: SearchUsersAndGroups;
  context: AccessLevelType;
};

const MAX_INVITATIONS = 10;
const MAX_PRINCIPALS = 10;

/* eslint @typescript-eslint/ban-types: "warn" */
const AddAccessModal: React.FC<AddAccessModalProps> = ({
  onClose,
  onAdd,
  searchUsersAndGroups,
  isLoading,
  context,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<
    Array<FlatPrincipal & { isNew?: boolean }>
  >([]);
  const [privilegeToAdd, setPrivilegeToAdd] = useState(
    context === 'workspace' ? Privilege['create-project'] : Privilege.read
  );
  const [inputValue, setInputValue] = useState('');
  const { formatMessage } = useIntl();
  const [isSumEnabled] = useIsSumEnabled();

  const isOverEmailLimit =
    selectedOptions.filter(opt => !!opt.isNew).length > MAX_INVITATIONS;
  const isOverPrincipalLimit =
    selectedOptions.filter(opt => !opt.isNew).length > MAX_PRINCIPALS;
  const noOptionsMessage = ({ inputValue: input }: { inputValue: string }) =>
    input
      ? formatMessage(
          isSumEnabled ? messages.noWorkspaceMembersFound : messages.noResults
        )
      : formatMessage(
          context === 'workspace'
            ? messages.beginTypingGroup
            : messages.beginTyping
        );

  const newValueToOption = useCallback(
    (input: string) =>
      ({
        id: input,
        name: input,
        mode: AccessMode.users,
        avatar: '',
        isNew: true,
        hasAccess: false,
      } as FlatPrincipal),
    []
  );

  const onInputChange = useCallback(
    (input: string, { action }) => {
      if (isSumEnabled) {
        setInputValue(input);
        return;
      }
      if (input.includes(',')) {
        const commaSeparatedInput = input.split(',').map(str => str.trim());
        const newEmails = commaSeparatedInput.filter(str => isValidEmail(str));
        const nonEmails = commaSeparatedInput
          .filter(str => !isValidEmail(str))
          .filter(str => !!str.length);
        if (newEmails.length) {
          setSelectedOptions(prevOptions => [
            ...prevOptions,
            ...newEmails.map(newValueToOption),
          ]);
          setInputValue(nonEmails.join(' '));
        } else {
          setInputValue(input);
        }
      } else {
        if (action !== 'input-blur' && action !== 'menu-close') {
          setInputValue(input);
        } else {
          const trimmedEmailInput = inputValue.trim();
          if (isValidEmail(trimmedEmailInput)) {
            setSelectedOptions(prevOptions => [
              ...prevOptions.filter(ent => ent.id !== trimmedEmailInput),
              // ^input-blur and menu-close are often fired one right after the other, before inputValue state is updated
              newValueToOption(trimmedEmailInput),
            ]);
            setInputValue(input);
          }
        }
      }
    },
    [inputValue, newValueToOption, isSumEnabled]
  );

  const changeOption = (options: FlatPrincipal[]) =>
    options?.length ? setSelectedOptions([...options]) : setSelectedOptions([]);

  const addPrivileges = () => onAdd(selectedOptions, privilegeToAdd);

  const getOptionValue = (option: FlatPrincipal) => option.id;

  const getOptionLabel = (option: FlatPrincipal) => option.name;

  const isOptionDisabled = (option: FlatPrincipal) => option.hasAccess ?? false;

  const getMessageForAccessLevel = (accessLevelType: AccessLevelType) => {
    switch (accessLevelType) {
      case AccessLevel.repository:
        return formatMessage(messages.hasRepoAccessLozenge);
      case AccessLevel.project:
        return formatMessage(messages.hasProjectAccessLozenge);
      case AccessLevel.workspace:
        return formatMessage(messages.hasWorkspaceAccessLozenge);
    }
    return '';
  };

  const hasAccessMessage = getMessageForAccessLevel(context);

  const actions = [
    {
      text: formatMessage(messages.cancel),
      onClick: onClose,
      appearance: 'subtle',
    },
    {
      text: formatMessage(messages.confirm),
      onClick: addPrivileges,
      appearance: 'primary',
      autoFocus: true,
      isDisabled:
        !selectedOptions.length || isOverEmailLimit || isOverPrincipalLimit,
      isLoading,
    },
  ];

  const selectSharedProps: SelectProps<FlatPrincipal, true> = {
    value: selectedOptions,
    isMulti: true,
    loadOptions: searchUsersAndGroups,
    onChange: changeOption,
    getOptionValue,
    getOptionLabel,
    formatOptionLabel: formatOptionLabel(hasAccessMessage),
    isOptionDisabled,
    inputValue,
    onInputChange,
    styles: {
      ...modalSelectStyles,
      container: base => ({
        ...base,
        maxWidth: '100%',
      }),
    } as StylesConfig<FlatPrincipal, true>,
    noOptionsMessage,
    components: {
      DropdownIndicator: null,
    },
    classNamePrefix: 'add-access-select',
  };

  return (
    <ModalDialog
      actions={actions as any}
      heading={formatMessage(
        context === 'workspace'
          ? messages.addWorkspaceAccessHeader
          : messages.addAccessHeader
      )}
      width="medium"
      onClose={onClose}
      scrollBehavior="outside"
    >
      {isSumEnabled || context === 'workspace' ? (
        <>
          {context === 'workspace' && (
            <DescriptionContainer>
              <p>{formatMessage(messages.createProjectDescription)}</p>
            </DescriptionContainer>
          )}
          <AsyncSelect
            {...selectSharedProps}
            placeholder={formatMessage(
              context === 'workspace'
                ? messages.addMembersWorkspacePlaceholder
                : messages.addMembersByNamePlaceholder
            )}
          />
        </>
      ) : (
        <AsyncCreatableSelect
          {...selectSharedProps}
          allowCreateWhileLoading
          isValidNewOption={isValidEmail}
          getNewOptionData={newValueToOption}
          placeholder={formatMessage(messages.addMembersPlaceholder)}
        />
      )}
      {isOverEmailLimit && (
        <ErrorMessage>
          {formatMessage(messages.emailInputMaxedOutError, {
            max: MAX_INVITATIONS,
          })}
        </ErrorMessage>
      )}
      {isOverPrincipalLimit && (
        <ErrorMessage>
          {formatMessage(messages.entitiesMaxedOutError, {
            max: MAX_PRINCIPALS,
          })}
        </ErrorMessage>
      )}
      <br />
      {context !== 'workspace' && (
        <PrivilegesDropdown
          fitContainer
          showValueDescription
          showOptionDescription
          value={privilegeToAdd}
          onChange={setPrivilegeToAdd as (privilege: Privilege) => void}
          context={context}
        />
      )}
    </ModalDialog>
  );
};

export default AddAccessModal;
