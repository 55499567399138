/**
 * @jsxRuntime classic
 * @jsx jsx
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { N30 } from '@atlaskit/theme/colors';
import { HORIZONTAL_GLOBAL_NAV_HEIGHT } from '../../common/constants';
import { defaultTheme, ThemeProvider } from '../../theme';
import { IconButtonSkeleton } from '../IconButton/skeleton';
import { PrimaryItemsContainerSkeleton } from '../PrimaryItemsContainer/skeleton';
import { ProductHomeSkeleton } from '../ProductHome/skeleton';
import { ProfileSkeleton } from '../Profile/skeleton';
import { SearchSkeleton } from '../Search/skeleton';
var containerStyles = css({
  display: 'flex',
  boxSizing: 'border-box',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  height: HORIZONTAL_GLOBAL_NAV_HEIGHT,
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderBlockEnd: "1px solid ".concat("var(--ds-border, ".concat(N30, ")")),
  paddingInlineEnd: "var(--ds-space-150, 12px)",
  paddingInlineStart: "var(--ds-space-150, 12px)"
});
var leftStyles = css({
  display: 'flex',
  minWidth: 0,
  height: 'inherit',
  alignItems: 'center',
  flexGrow: 1,
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '& > *': {
    flexShrink: 0
  }
});
var rightStyles = css({
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '& > *': {
    flexShrink: 0,
    marginInlineEnd: "var(--ds-space-050, 4px)"
  }
});

/**
 * __Navigation skeleton__
 *
 * Use loading skeletons to reduce the perceived loading time of heavier
 * full-page experiences. This should only be used when the whole navigation is
 * delayed; if there are only certain dynamically loaded navigation items that
 * slow down the page, you should look into using
 * [skeleton buttons](https://atlassian.design/components/atlassian-navigation/examples#skeleton-button)
 * instead.
 *
 * - [Examples](https://atlassian.design/components/atlassian-navigation/examples#skeleton-loader)
 * - [Code](https://atlassian.design/components/{packageName}/code)
 */
export var NavigationSkeleton = function NavigationSkeleton(_ref) {
  var _ref$primaryItemsCoun = _ref.primaryItemsCount,
    primaryItemsCount = _ref$primaryItemsCoun === void 0 ? 4 : _ref$primaryItemsCoun,
    _ref$secondaryItemsCo = _ref.secondaryItemsCount,
    secondaryItemsCount = _ref$secondaryItemsCo === void 0 ? 4 : _ref$secondaryItemsCo,
    _ref$theme = _ref.theme,
    theme = _ref$theme === void 0 ? defaultTheme : _ref$theme,
    _ref$showSiteName = _ref.showSiteName,
    showSiteName = _ref$showSiteName === void 0 ? false : _ref$showSiteName,
    _ref$shouldShowSearch = _ref.shouldShowSearch,
    shouldShowSearch = _ref$shouldShowSearch === void 0 ? true : _ref$shouldShowSearch,
    testId = _ref.testId;
  return jsx(ThemeProvider, {
    value: theme
  }, jsx("div", {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
    style: theme.mode.navigation,
    css: containerStyles,
    "data-testid": testId
  }, jsx("div", {
    css: leftStyles
  }, jsx(IconButtonSkeleton, {
    marginLeft: 0,
    marginRight: 5,
    size: 26
  }), jsx(ProductHomeSkeleton, {
    showSiteName: showSiteName
  }), jsx(PrimaryItemsContainerSkeleton, {
    count: primaryItemsCount
  })), jsx("div", {
    css: rightStyles
  }, shouldShowSearch && jsx(SearchSkeleton, null), Array.from({
    length: secondaryItemsCount
  }, function (_, index) {
    return jsx(IconButtonSkeleton, {
      key: index,
      marginLeft: 0,
      marginRight: 4,
      size: 26
    });
  }), jsx(ProfileSkeleton, null))));
};
export default NavigationSkeleton;