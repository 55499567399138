import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["isSingleLine"];
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
var overflowContainerStyles = css({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
});

/**
 * __Overflow container__
 */
var OverflowContainer = function OverflowContainer(_ref) {
  var isSingleLine = _ref.isSingleLine,
    props = _objectWithoutProperties(_ref, _excluded);
  return (
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
    jsx("span", _extends({
      css: isSingleLine && overflowContainerStyles
    }, props))
  );
};
export default OverflowContainer;