import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export var generateEnvironmentConfigWithOverrides = function generateEnvironmentConfigWithOverrides(defaultEnvironmentValues, environmentWithOverrides) {
  var _overrides$remoteModu, _overrides$remoteModu2, _overrides$remoteModu3, _overrides$remoteModu4, _overrides$remoteModu5, _overrides$remoteModu6, _overrides$api$origin, _overrides$api, _overrides$api$pathna, _overrides$api2, _overrides$api$search, _overrides$api3;
  var env = environmentWithOverrides.env,
    overrides = environmentWithOverrides.overrides;
  return {
    currentEnv: env,
    envConfigs: _objectSpread(_objectSpread({}, defaultEnvironmentValues.envConfigs), {}, _defineProperty({}, env, {
      remoteModule: {
        origin: (_overrides$remoteModu = overrides === null || overrides === void 0 || (_overrides$remoteModu2 = overrides.remoteModule) === null || _overrides$remoteModu2 === void 0 ? void 0 : _overrides$remoteModu2.origin) !== null && _overrides$remoteModu !== void 0 ? _overrides$remoteModu : defaultEnvironmentValues.envConfigs[env].remoteModule.origin,
        pathname: (_overrides$remoteModu3 = overrides === null || overrides === void 0 || (_overrides$remoteModu4 = overrides.remoteModule) === null || _overrides$remoteModu4 === void 0 ? void 0 : _overrides$remoteModu4.pathname) !== null && _overrides$remoteModu3 !== void 0 ? _overrides$remoteModu3 : defaultEnvironmentValues.envConfigs[env].remoteModule.pathname,
        searchParams: (_overrides$remoteModu5 = overrides === null || overrides === void 0 || (_overrides$remoteModu6 = overrides.remoteModule) === null || _overrides$remoteModu6 === void 0 ? void 0 : _overrides$remoteModu6.searchParams) !== null && _overrides$remoteModu5 !== void 0 ? _overrides$remoteModu5 : defaultEnvironmentValues.envConfigs[env].remoteModule.searchParams
      },
      api: {
        origin: (_overrides$api$origin = overrides === null || overrides === void 0 || (_overrides$api = overrides.api) === null || _overrides$api === void 0 ? void 0 : _overrides$api.origin) !== null && _overrides$api$origin !== void 0 ? _overrides$api$origin : defaultEnvironmentValues.envConfigs[env].api.origin,
        pathname: (_overrides$api$pathna = overrides === null || overrides === void 0 || (_overrides$api2 = overrides.api) === null || _overrides$api2 === void 0 ? void 0 : _overrides$api2.pathname) !== null && _overrides$api$pathna !== void 0 ? _overrides$api$pathna : defaultEnvironmentValues.envConfigs[env].api.pathname,
        searchParams: (_overrides$api$search = overrides === null || overrides === void 0 || (_overrides$api3 = overrides.api) === null || _overrides$api3 === void 0 ? void 0 : _overrides$api3.searchParams) !== null && _overrides$api$search !== void 0 ? _overrides$api$search : defaultEnvironmentValues.envConfigs[env].api.searchParams
      }
    }))
  };
};