import React, { ChangeEvent, useMemo } from 'react';

import { debounce } from 'lodash-es';
import { defineMessages } from 'react-intl';

import SearchIcon from '@atlaskit/icon/glyph/search';
import TextField from '@atlaskit/textfield';

import { DEBOUNCE_DELAY } from 'src/constants/settings';
import { useIntl } from 'src/hooks/intl';
import {
  SearchFieldContainer,
  SearchIconContainer,
} from 'src/styles/settings-table.style';

import { useGetPermissions } from './provider';
import { PermissionsAPIProps } from './types';

const messages = defineMessages({
  searchPlaceholder: {
    id: 'frontbucket.settings.permissions.searchPlaceholder',
    description:
      'Placeholder message for the filtering input above the list of permissions',
    defaultMessage: 'Search',
  },
});

const SearchField = ({
  api,
  defaultValue,
}: PermissionsAPIProps & { defaultValue?: string }) => {
  const { formatMessage } = useIntl();
  const getPermissions = useGetPermissions(api.getPermissions);
  const debouncedGetPermissions = useMemo(
    () =>
      debounce(getPermissions, DEBOUNCE_DELAY, {
        trailing: true,
      }),
    [getPermissions]
  );
  return (
    <SearchFieldContainer>
      <TextField
        testId="filterQuery"
        aria-label={formatMessage(messages.searchPlaceholder)}
        placeholder={formatMessage(messages.searchPlaceholder)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          debouncedGetPermissions(1, { searchTerm: e.target.value });
        }}
        defaultValue={defaultValue}
        elemAfterInput={
          <SearchIconContainer>
            <SearchIcon label="filterQuery" size="small" />
          </SearchIconContainer>
        }
      />
    </SearchFieldContainer>
  );
};

export default SearchField;
