import { useEffect } from 'react';
import useInterval from '@use-it/interval';
import { INTERVAL_TIME } from '../../constants';
import { useBrowserInteractionTime } from '../use-browser-interaction-time';
import { useHearbeatService } from '../use-heartbeat-service';
export var useHeartbeatOnInterval = function useHeartbeatOnInterval(endpoint, idleTime, onAuthenticationFailed, onBadRequest) {
  var _useHearbeatService = useHearbeatService(endpoint, onAuthenticationFailed, onBadRequest),
    nextCallTimestamp = _useHearbeatService.nextCallTimestamp,
    sessionExpiryTimestamp = _useHearbeatService.sessionExpiryTimestamp,
    callHeartbeatService = _useHearbeatService.callHeartbeatService,
    setCallHeartbeatService = _useHearbeatService.setCallHeartbeatService,
    deactivated = _useHearbeatService.deactivated;
  var _useBrowserInteractio = useBrowserInteractionTime(idleTime),
    active = _useBrowserInteractio.active,
    tabActive = _useBrowserInteractio.tabActive;

  /**
   * Initial heartbeat api request
   */
  useEffect(function () {
    setCallHeartbeatService(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Call heartbeat api when user is idle and the tab is active
   */
  useEffect(function () {
    if (!active && tabActive) {
      setCallHeartbeatService(true);
    }
  }, [active, tabActive, setCallHeartbeatService]);
  useInterval(function () {
    if (callHeartbeatService) {
      return;
    }

    /**
     * Call heartbeat api when session has expired to initiate redirect.
     */
    if (sessionExpiryTimestamp && sessionExpiryTimestamp.getTime() <= Date.now()) {
      setCallHeartbeatService(true);
      return;
    }

    /**
     * Call heartbeat api if user is active and nextCallTimestamp is reached.
     */
    if (active && tabActive && nextCallTimestamp && nextCallTimestamp.getTime() <= Date.now()) {
      setCallHeartbeatService(true);
    }
  }, INTERVAL_TIME);
  return {
    nextCallTimestamp: nextCallTimestamp,
    sessionExpiryTimestamp: sessionExpiryTimestamp,
    active: active,
    tabActive: tabActive,
    deactivated: deactivated
  };
};