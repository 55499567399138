import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { xcss } from '@atlaskit/primitives';
import { fontSize as getFontSize } from '@atlaskit/theme/constants';
import colors from './colors';
var fontSize = getFontSize();
export var heights = {
  default: "".concat(32 / fontSize, "em"),
  compact: "".concat(24 / fontSize, "em"),
  none: 'auto'
};
var lineHeights = {
  default: heights.default,
  compact: heights.compact,
  none: 'inherit'
};
var paddingInline = {
  default: {
    default: 'space.150',
    withIcon: 'space.100'
  },
  compact: {
    default: 'space.150',
    withIcon: 'space.100'
  },
  none: {
    default: 'space.0',
    withIcon: 'space.0'
  }
};
var gap = {
  compact: 'space.050',
  default: 'space.050',
  none: 'space.0'
};
var verticalAlign = {
  default: 'middle',
  compact: 'middle',
  none: 'baseline'
};
var splitBorderStyles = {
  ':focus-visible': {
    zIndex: 1
  }
};
function getColor(_ref) {
  var group = _ref.group,
    key = _ref.key;
  return group[key] || group.default;
}
function getColors(_ref2) {
  var appearance = _ref2.appearance,
    _ref2$interactionStat = _ref2.interactionState,
    interactionState = _ref2$interactionStat === void 0 ? 'default' : _ref2$interactionStat,
    isDisabled = _ref2.isDisabled,
    isSelected = _ref2.isSelected,
    isHighlighted = _ref2.isHighlighted,
    isActiveOverSelected = _ref2.isActiveOverSelected,
    hasOverlay = _ref2.hasOverlay;
  var key = interactionState;
  // Overlay does not change color on interaction, revert to 'default' or resting state
  key = hasOverlay ? 'default' : key;
  key = isSelected || isHighlighted ? isActiveOverSelected ? 'active' : 'selected' : key;
  // Disabled colors overrule everything else
  key = isDisabled ? 'disabled' : key;
  return {
    backgroundColor: getColor({
      group: colors.background[appearance],
      key: key
    }),
    color: "".concat(getColor({
      group: colors.color[appearance],
      key: key
    }))
  };
}
export function getXCSS(_ref3) {
  var appearance = _ref3.appearance,
    spacing = _ref3.spacing,
    isDisabled = _ref3.isDisabled,
    isSelected = _ref3.isSelected,
    isHighlighted = _ref3.isHighlighted,
    isActiveOverSelected = _ref3.isActiveOverSelected,
    isIconButton = _ref3.isIconButton,
    isCircle = _ref3.isCircle,
    shouldFitContainer = _ref3.shouldFitContainer,
    isLink = _ref3.isLink,
    isSplit = _ref3.isSplit,
    isNavigationSplit = _ref3.isNavigationSplit,
    hasOverlay = _ref3.hasOverlay,
    hasIconBefore = _ref3.hasIconBefore,
    hasIconAfter = _ref3.hasIconAfter;
  var baseColors = getColors({
    appearance: appearance,
    isSelected: isSelected,
    isHighlighted: isHighlighted,
    isActiveOverSelected: isActiveOverSelected,
    isDisabled: isDisabled
  });
  var combinedBaseColorStyles = isLink ? _objectSpread(_objectSpread({}, baseColors), {}, {
    textDecoration: 'none',
    // Disabling visited styles (by re-declaring the base colors)
    ':visited': baseColors
  }) : isNavigationSplit && !isSelected ? _objectSpread(_objectSpread({}, baseColors), {}, {
    backgroundColor: 'color.background.neutral.subtle'
  }) : baseColors;
  var height = heights[spacing];
  var width = shouldFitContainer ? '100%' : 'auto';
  width = isIconButton ? isNavigationSplit ? '24px' : height : width;
  var defaultPaddingInlineStart = paddingInline[spacing][hasIconBefore ? 'withIcon' : 'default'];
  var defaultPaddingInlineEnd = paddingInline[spacing][hasIconAfter ? 'withIcon' : 'default'];
  var splitButtonStyles = isSplit ? splitBorderStyles : {};
  var getNavigationSplitButtonPaddings = function getNavigationSplitButtonPaddings() {
    if (isNavigationSplit) {
      return {
        paddingInlineStart: 'space.075',
        paddingInlineEnd: 'space.075'
      };
    }
    return {
      paddingInlineStart: isIconButton ? 'space.0' : defaultPaddingInlineStart,
      paddingInlineEnd: isIconButton ? 'space.0' : defaultPaddingInlineEnd
    };
  };
  var _getNavigationSplitBu = getNavigationSplitButtonPaddings(),
    paddingInlineStart = _getNavigationSplitBu.paddingInlineStart,
    paddingInlineEnd = _getNavigationSplitBu.paddingInlineEnd;
  return xcss(_objectSpread(_objectSpread(_objectSpread({
    alignItems: 'baseline',
    borderWidth: 'border.width.0',
    borderRadius: isCircle && !isSplit ? 'border.radius.circle' : 'border.radius.100',
    boxSizing: 'border-box',
    display: 'inline-flex',
    fontSize: 'inherit',
    fontStyle: 'normal',
    fontFamily: 'inherit',
    fontWeight: 500,
    maxWidth: '100%',
    // Needed to position overlay
    position: 'relative',
    textAlign: 'center',
    transition: 'background 0.1s ease-out, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38)',
    whiteSpace: 'nowrap',
    height: height,
    lineHeight: lineHeights[spacing],
    paddingBlock: 'space.0',
    paddingInlineStart: paddingInlineStart,
    paddingInlineEnd: paddingInlineEnd,
    columnGap: gap[spacing],
    verticalAlign: verticalAlign[spacing],
    width: width,
    // justifyContent required for shouldFitContainer buttons with an icon inside
    justifyContent: 'center'
  }, combinedBaseColorStyles), isDisabled || hasOverlay ? {
    cursor: 'not-allowed'
  } : {}), {}, {
    ':hover': _objectSpread(_objectSpread({}, getColors({
      appearance: appearance,
      isSelected: isNavigationSplit && !isSelected ? false : isSelected,
      isActiveOverSelected: isActiveOverSelected,
      isDisabled: isDisabled,
      interactionState: 'hover',
      hasOverlay: hasOverlay
    })), {}, {
      textDecoration: !isSelected && (appearance === 'link' || appearance === 'subtle-link') ? 'underline' : 'none',
      // background, box-shadow
      transitionDuration: '0s, 0.15s'
    }),
    ':active': _objectSpread(_objectSpread({}, getColors({
      appearance: appearance,
      isSelected: isNavigationSplit && !isSelected ? false : isSelected,
      isActiveOverSelected: isActiveOverSelected,
      isDisabled: isDisabled,
      interactionState: 'active',
      hasOverlay: hasOverlay
    })), {}, {
      // background, box-shadow
      transitionDuration: '0s, 0s'
    })
  }, splitButtonStyles));
}