import {BaseFrameworkAdaptor} from './BaseFrameworkAdaptor';
import {SimpleXdmDefinition} from '../../definitions/SimpleXdmDefinition';
import {Provider} from '../../modules/base/Provider';

export interface IframeExtension {
  $el: HTMLElement;
  extension: {
    id: string;
    addon_key: string;
    key: string;
    options: object;
    url: string;
  };

}

/**
 * This class provides the Adaptor implementation for ACJS
 */
class ACJSFrameworkAdaptor extends BaseFrameworkAdaptor {

    public registerModuleWithHost(moduleName: string, simpleXdmDefinition: SimpleXdmDefinition): void {
        // Do nothing - ACJS is already registering all core modules.
    }

    public getProviderByModuleName(moduleName: string): Provider | undefined {
        return super.getProviderByModuleName(moduleName);
    }

}

/**
 * Use only this instance of ACJSFrameworkAdaptor.
 */
export const acjsFrameworkAdaptor = new ACJSFrameworkAdaptor() as BaseFrameworkAdaptor;
