import {AppContext, AppInstance, AppOptions, ContextualOperations} from '../AppContext';

export class TestContextualOperations implements ContextualOperations {

  inlineDialogOpen: boolean = true;

  resize(width: string, height: string): void {
    throw new Error('Not implemented yet');
  }

  sizeToParent(): void {
    throw new Error('Not implemented yet');
  }

  /**
   * This method hides the inline dialog that the add-on identified by this context is
   * being displayed in.
   */
  hideInlineDialog(): void {
    this.inlineDialogOpen = false;
  }

  isInlineDialogOpen(): boolean {
    return this.inlineDialogOpen;
  }

}

export class TestAppInstance implements AppInstance {

  addon_key: string;
  id: string;
  key: string;
  options: AppOptions;

  constructor(
      appKey: string,
      id: string,
      key: string,
      options: AppOptions) {
    this.addon_key = appKey;
    this.id = id;
    this.key = key;
    this.options = options;
  }

}

export class TestAppOptions implements AppOptions {

  autoresize: boolean;
  widthinpx: boolean;
  hostFrameOffset: number;
  _contextualOperations: ContextualOperations;

  constructor(
      autoresize: boolean,
      widthinpx: boolean,
      hostFrameOffset: number,
      _contextualOperations: ContextualOperations) {
    this.autoresize = autoresize;
    this.widthinpx = widthinpx;
    this.hostFrameOffset = hostFrameOffset;
    this._contextualOperations = _contextualOperations;
  }

}

export class TestAppContext implements AppContext {

  extension: AppInstance;

  constructor(extension: AppInstance) {
    this.extension = extension;
  }

  static buildNewInstance(): AppContext {
    const contextualOperations = new TestContextualOperations();

    const autoresize = true;
    const widthinpx = true;
    const hostFrameOffset = 1;
    const appOptions = new TestAppOptions(autoresize, widthinpx, hostFrameOffset, contextualOperations);

    const randomId = (new Date()).getMilliseconds();
    const appKey = 'foo';
    const key = 'bar';
    const id = appKey + '__' + key + '__' + randomId;
    const appInstance = new TestAppInstance(appKey, id, key, appOptions);
    const appContext = new TestAppContext(appInstance);
    return appContext;
  }

}
