import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _css;
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

import { Fragment } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
import { PRODUCT_HOME_BREAKPOINT } from '../../common/constants';
import { useTheme } from '../../theme';
import { stripEmptyProperties } from '../../utils';
var VAR_PRODUCT_HOME_COLOR_ACTIVE = '--product-home-color-active';
var VAR_PRODUCT_HOME_BACKGROUND_COLOR_ACTIVE = '--product-home-bg-color-active';
var VAR_PRODUCT_HOME_BOX_SHADOW_ACTIVE = '--product-home-box-shadow-active';
var VAR_PRODUCT_HOME_COLOR_FOCUS = '--product-home-color-focus';
var VAR_PRODUCT_HOME_BACKGROUND_COLOR_FOCUS = '--product-home-bg-color-focus';
var VAR_PRODUCT_HOME_BOX_SHADOW_FOCUS = '--product-home-box-shadow-focus';
var VAR_PRODUCT_HOME_COLOR_HOVER = '--product-home-color-hover';
var VAR_PRODUCT_HOME_BACKGROUND_COLOR_HOVER = '--product-home-bg-color-hover';
var VAR_PRODUCT_HOME_BOX_SHADOW_HOVER = '--product-home-box-shadow-hover';
var VAR_SITE_TITLE_BG_COLOR_AFTER = '--site-title-bg-color-after';
var productHomeButtonStyles = css((_css = {
  display: 'flex',
  padding: "var(--ds-space-050, 4px)",
  alignItems: 'center',
  background: 'none',
  border: 0,
  borderRadius: "var(--ds-border-radius, 3px)",
  color: 'inherit',
  cursor: 'pointer',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  '&::-moz-focus-inner': {
    border: 0
  },
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  '&:first-of-type': {
    marginInlineStart: 0
  },
  '&:active': {
    backgroundColor: "var(".concat(VAR_PRODUCT_HOME_BACKGROUND_COLOR_ACTIVE, ")"),
    boxShadow: "var(".concat(VAR_PRODUCT_HOME_BOX_SHADOW_ACTIVE, ")"),
    color: "var(".concat(VAR_PRODUCT_HOME_COLOR_ACTIVE, ")")
  },
  '&:focus': {
    backgroundColor: "var(".concat(VAR_PRODUCT_HOME_BACKGROUND_COLOR_FOCUS, ")"),
    boxShadow: "var(".concat(VAR_PRODUCT_HOME_BOX_SHADOW_FOCUS, ")"),
    color: "var(".concat(VAR_PRODUCT_HOME_COLOR_FOCUS, ")"),
    outline: 0
  },
  '&:hover': {
    backgroundColor: "var(".concat(VAR_PRODUCT_HOME_BACKGROUND_COLOR_HOVER, ")"),
    boxShadow: "var(".concat(VAR_PRODUCT_HOME_BOX_SHADOW_HOVER, ")"),
    color: "var(".concat(VAR_PRODUCT_HOME_COLOR_HOVER, ")")
  },
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  'div&': {
    pointerEvents: 'none'
  }
}, _defineProperty(_css, "@media (max-width: ".concat(PRODUCT_HOME_BREAKPOINT - 0.1, "px)"), {
  margin: "0 ".concat("var(--ds-space-100, 8px)")
}), _defineProperty(_css, "@media (min-width: ".concat(PRODUCT_HOME_BREAKPOINT, "px)"), {
  margin: "0 ".concat("var(--ds-space-200, 16px)")
}), _css));
var productLogoStyles = css(_defineProperty({
  display: 'flex',
  width: 120,
  maxWidth: 120,
  height: 24,
  maxHeight: 28,
  opacity: 0.15,
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '& > *': {
    maxHeight: 24
  }
}, "@media (max-width: ".concat(PRODUCT_HOME_BREAKPOINT - 0.1, "px)"), {
  display: 'none'
}));
var productIconStyles = css(_defineProperty({
  display: 'flex',
  width: 28,
  height: 28,
  borderRadius: "var(--ds-border-radius-circle, 50%)",
  opacity: 0.15,
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '& > *': {
    maxHeight: 24
  }
}, "@media (min-width: ".concat(PRODUCT_HOME_BREAKPOINT, "px)"), {
  display: 'none'
}));
var siteTitleStyles = css({
  display: 'flex',
  width: "var(--ds-space-500, 40px)",
  alignItems: 'center',
  backgroundColor: 'transparent',
  marginInlineEnd: "var(--ds-space-050, 4px)",
  marginInlineStart: "var(--ds-space-050, 4px)",
  paddingInlineEnd: "var(--ds-space-200, 16px)",
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
  '&:after': {
    width: '100%',
    height: 14,
    backgroundColor: "var(".concat(VAR_SITE_TITLE_BG_COLOR_AFTER, ")"),
    borderRadius: "var(--ds-border-radius, 4px)",
    content: '""'
  }
});

// Not exported to consumers, only used in NavigationSkeleton
// eslint-disable-next-line @repo/internal/react/require-jsdoc
export var ProductHomeSkeleton = function ProductHomeSkeleton(_ref) {
  var _stripEmptyProperties;
  var showSiteName = _ref.showSiteName;
  var theme = useTheme();
  var primaryButton = theme.mode.primaryButton;
  var productHomeButtonDynamicStyles = stripEmptyProperties((_stripEmptyProperties = {}, _defineProperty(_stripEmptyProperties, VAR_PRODUCT_HOME_COLOR_ACTIVE, primaryButton.active.color), _defineProperty(_stripEmptyProperties, VAR_PRODUCT_HOME_BACKGROUND_COLOR_ACTIVE, primaryButton.active.backgroundColor), _defineProperty(_stripEmptyProperties, VAR_PRODUCT_HOME_BOX_SHADOW_ACTIVE, primaryButton.active.boxShadow), _defineProperty(_stripEmptyProperties, VAR_PRODUCT_HOME_COLOR_FOCUS, primaryButton.focus.color), _defineProperty(_stripEmptyProperties, VAR_PRODUCT_HOME_BACKGROUND_COLOR_FOCUS, primaryButton.focus.backgroundColor), _defineProperty(_stripEmptyProperties, VAR_PRODUCT_HOME_BOX_SHADOW_FOCUS, primaryButton.focus.boxShadow), _defineProperty(_stripEmptyProperties, VAR_PRODUCT_HOME_COLOR_HOVER, primaryButton.hover.color), _defineProperty(_stripEmptyProperties, VAR_PRODUCT_HOME_BACKGROUND_COLOR_HOVER, primaryButton.hover.backgroundColor), _defineProperty(_stripEmptyProperties, VAR_PRODUCT_HOME_BOX_SHADOW_HOVER, primaryButton.hover.boxShadow), _defineProperty(_stripEmptyProperties, VAR_SITE_TITLE_BG_COLOR_AFTER, theme.mode.skeleton.backgroundColor), _stripEmptyProperties));
  var siteTitleDynamicStyles = _defineProperty({
    borderRight: theme.mode.productHome.borderRight,
    opacity: theme.mode.skeleton.opacity
  }, VAR_SITE_TITLE_BG_COLOR_AFTER, theme.mode.skeleton.backgroundColor);
  return jsx(Fragment, null, jsx("div", {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
    style: productHomeButtonDynamicStyles,
    css: productHomeButtonStyles
  }, jsx("div", {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
    style: theme.mode.skeleton,
    css: productLogoStyles
  }), jsx("div", {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
    style: theme.mode.skeleton,
    css: productIconStyles
  })), showSiteName && jsx("div", {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
    style: siteTitleDynamicStyles,
    css: siteTitleStyles
  }));
};