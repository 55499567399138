export enum BbEnv {
  Development = 'development',
  Production = 'production',
  Staging = 'staging',
  Integration = 'integration',
  Test = 'dev-west2', // Infra Dev "Red" test, not testing test
}

function getMetaContent(name: string): string | null {
  return (
    document.querySelector(`meta[name="${name}"]`)?.getAttribute('content') ||
    null
  );
}

// getMetaContent helper functions exported for testing
export const getBbEnvMeta = (): BbEnv => {
  return (getMetaContent('bb-env') as BbEnv) || BbEnv.Development;
};

export const getCommitHashMeta = (): string => {
  return getMetaContent('bitbucket-commit-hash') || 'unknown-version';
};

export const getLocaleMeta = (): string => {
  return getMetaContent('locale') || 'en';
};

export function getUserAaidMeta(): string | null {
  const el: HTMLElement | null = document.getElementById('bb-bootstrap');
  // bb-bootstrap is added to the base template for all frontbucket and core pages

  if (!el) {
    return null;
  }
  // `data-atlassian-id` is defined if the user is authenticated
  // (see templates/user-id.jinja in bitbucket/core repo)
  const userAaid: string | null = el.getAttribute('data-atlassian-id');

  if (!userAaid) {
    return null;
  }

  return userAaid;
}

export function getWorkspaceUuidMeta(): string | null {
  // bb-bootstrap has basic data about the current session that's
  // avaialble in both backbucket and frontbucket pages
  const el = document.getElementById('bb-bootstrap');

  if (!el) {
    return null;
  }

  const workspaceUuid = el.getAttribute('data-target-workspace-uuid');

  // workspaceUuid will not be set if there's no target (ex: support admin pages)
  if (!workspaceUuid) {
    return null;
  }

  return workspaceUuid;
}

type HtmlMetaValues = {
  bbEnv: BbEnv;
  version: string;
  locale: string;
  userAaid: string | null;
  workspaceUuid: string | null;
};

// `getHtmlMetaValues` is used to get data from the html meta tags
// that are used to initialize the AnalyticsWebClient provided by
// @atlassiansox/analytics-web-client package. Backbucket pages also
//  have these values available in the html head tag so we can access
// them the same way from SPA and non-SPA pages. See templates/user-id.jinja
// in core repo for more details.
export function getHtmlMetaValues(): HtmlMetaValues {
  return {
    bbEnv: getBbEnvMeta(),
    version: getCommitHashMeta(),
    locale: getLocaleMeta(),
    userAaid: getUserAaidMeta(),
    workspaceUuid: getWorkspaceUuidMeta(),
  };
}
