/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
var actionsStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBlockEnd: "var(--ds-space-100, 8px)",
  paddingInlineStart: "var(--ds-space-500, 40px)"
});

/**
 * __Actions container__
 *
 * A container for actions: primary action, secondary action, and tertiary action.
 *
 * @internal
 */
var ActionsContainer = function ActionsContainer(_ref) {
  var children = _ref.children;
  return jsx("div", {
    css: actionsStyles
  }, children);
};
export default ActionsContainer;