/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { put, call, race, take, delay } from 'redux-saga/effects';

import { FETCH_WORKSPACE_PLAN } from 'src/redux/workspaces/actions';
import urls from 'src/urls/workspaces';
import authRequest from 'src/utils/fetch';

export function* fetchWorkspacePlan({ payload }: any) {
  try {
    if (!payload.workspaceSlug) {
      throw new Error(`Workspace slug not available`);
    }
    const url = urls.api.internal.plan(payload.workspaceSlug);
    const request = authRequest(url);
    const response: Response = yield call(fetch, request);

    if (response.ok) {
      // @ts-ignore
      const plan = yield response.json();
      yield put({
        type: FETCH_WORKSPACE_PLAN.SUCCESS,
        payload: {
          isWorkspacePremium: plan.extra.has_premium,
          isWorkspacePaid: plan.extra.is_paid,
        },
      });
    } else {
      yield put({
        type: FETCH_WORKSPACE_PLAN.ERROR,
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_WORKSPACE_PLAN.ERROR,
      payload: e.message,
    });
  }
}

export default function* () {
  while (true) {
    const { payload: workspaceSlug } = yield take(FETCH_WORKSPACE_PLAN.REQUEST);

    const { timeout } = yield race({
      response: call(fetchWorkspacePlan, { payload: workspaceSlug }),
      timeout: delay(60000),
    });

    if (timeout) {
      yield put({ type: FETCH_WORKSPACE_PLAN.ERROR });
    }
  }
}
