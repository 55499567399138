import { defineMessages } from 'react-intl';

export default defineMessages({
  removeAccessHeader: {
    id: 'frontbucket.removeAccessModal.removeAccessHeader',
    description: 'Remove access modal header message',
    defaultMessage: 'Remove access',
  },
  cancel: {
    id: 'frontbucket.removeAccessModal.cancel',
    description:
      'Cancel button message for add/remove user/group access modal footer',
    defaultMessage: 'Cancel',
  },
  remove: {
    id: 'frontbucket.removeAccessModal.remove',
    description:
      'Remove button message for remove user/group access modal footer',
    defaultMessage: 'Remove',
  },
});
