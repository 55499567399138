import React, { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import Flag from '@atlaskit/flag';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import { ComponentFlagProps } from 'src/redux/flags/types';
import { AppearanceTypes } from 'src/sections/global/components/flags/simple/simple-flag';
import { getCurrentUser } from 'src/selectors/user-selectors';
import { getCurrentWorkspaceSlug } from 'src/selectors/workspace-selectors';

import { ModalDialogUrlHash } from '../types/groups.types';
import {
  setDismissed,
  setShownNow,
} from '../utils/default-group-intro-flag-store';

import messages from './default-group-into-flag.i18n';

export const DefaultGroupIntroFlag = React.memo((props: ComponentFlagProps) => {
  const intl = useIntl();
  const currentUser = useSelector(getCurrentUser);
  const currentWorkspaceSlug = useSelector(getCurrentWorkspaceSlug);

  const markDismissed = useCallback(() => {
    if (currentUser && currentWorkspaceSlug) {
      setDismissed(currentUser, currentWorkspaceSlug);
      // dismissal confirms that a user has seen the flag
      setShownNow(currentUser, currentWorkspaceSlug);
    }
  }, [currentUser, currentWorkspaceSlug]);

  const { id, handleDismissed } = props;
  const actions = useMemo(() => {
    return [
      {
        content: intl.formatMessage(messages.createDefaultGroupAction),
        onClick: () => {
          markDismissed();
          location.hash = `#${ModalDialogUrlHash.CREATE_GROUP_MODAL_KEY}`;
          handleDismissed(id);
        },
      },
      {
        content: intl.formatMessage(messages.chooseExistingAction),
        onClick: () => {
          markDismissed();
          location.hash = `#${ModalDialogUrlHash.CHOOSE_GROUP_MODAL_KEY}`;
          handleDismissed(id);
        },
      },
    ];
  }, [intl, handleDismissed, markDismissed, id]);

  return (
    <Flag
      onDismissed={markDismissed}
      actions={actions}
      appearance={AppearanceTypes.normal}
      description={intl.formatMessage(messages.description)}
      title={intl.formatMessage(messages.title)}
      icon={
        <InfoIcon
          label=""
          primaryColor={token('color.icon.discovery', colors.P300)}
        />
      }
      {...props}
    />
  );
});
