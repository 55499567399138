import {
  AfterScriptCommand,
  Command,
  ExecutionPhaseType,
  LogRange,
  LogRangeTaskData,
  RunnerDetailsCommand,
  ScriptCommand,
  SetupCommand,
  StepCommand,
  StepTaskData,
  TeardownCommand,
} from 'src/components/pipelines/models';

export class Task {
  commands: Command[] = [];
  log_range: LogRange = new LogRange();

  constructor(props: StepTaskData | LogRangeTaskData) {
    Object.assign(this, {
      ...props,
      commands: Array.isArray(props.commands)
        ? props.commands.map(
            command =>
              new Command({
                ...command,
                log_range: props.log_range,
              })
          )
        : [],
    });
    Object.freeze(this);
  }
}

export class ExecutionPhases {
  RUNNER_DETAILS: Task[] = [];
  SETUP: Task[] = [];
  MAIN: Task[] = [];
  AFTER_MAIN: Task[] = [];
  TEARDOWN: Task[] = [];

  constructor(props: {
    [key in ExecutionPhaseType]: StepTaskData[] | LogRangeTaskData[];
  }) {
    Object.assign(this, {
      ...(Array.isArray(props.RUNNER_DETAILS)
        ? {
            RUNNER_DETAILS: props.RUNNER_DETAILS.map(task => new Task(task)),
          }
        : {}),
      ...(Array.isArray(props.SETUP)
        ? { SETUP: props.SETUP.map(task => new Task(task)) }
        : {}),
      ...(Array.isArray(props.MAIN)
        ? { MAIN: props.MAIN.map(task => new Task(task)) }
        : {}),
      ...(Array.isArray(props.AFTER_MAIN)
        ? {
            AFTER_MAIN: props.AFTER_MAIN.map(task => new Task(task)),
          }
        : {}),
      ...(Array.isArray(props.TEARDOWN)
        ? { TEARDOWN: props.TEARDOWN.map(task => new Task(task)) }
        : {}),
    });
    Object.freeze(this);
  }
}

export class Tasks {
  execution_phases: ExecutionPhases;

  constructor(props: {
    execution_phases: {
      [key in ExecutionPhaseType]: StepTaskData[] | LogRangeTaskData[];
    };
  }) {
    Object.assign(this, {
      ...props,
      ...(props.execution_phases
        ? {
            execution_phases: new ExecutionPhases(props.execution_phases),
          }
        : {}),
    });
    Object.freeze(this);
  }

  get runnerDetailsCommand(): RunnerDetailsCommand | undefined {
    const tasks = this.execution_phases.RUNNER_DETAILS;
    if (!(tasks && tasks.length)) {
      return undefined;
    }
    if (Object.keys(tasks).length > 0) {
      return new RunnerDetailsCommand(
        this.legacyScriptCommands(tasks),
        tasks[0].log_range
      );
    }
    return undefined;
  }

  get setupCommand(): SetupCommand {
    const tasks = this.execution_phases.SETUP;
    // Setup should have one task.
    const logRange =
      tasks[0] === undefined ? new LogRange() : tasks[0].log_range;
    return new SetupCommand(this.legacyScriptCommands(tasks), logRange);
  }

  get mainCommands(): ScriptCommand[] {
    return this.legacyScriptCommands(this.execution_phases.MAIN);
  }

  get afterMainCommands(): AfterScriptCommand[] {
    return this.legacyAfterScriptCommands(this.execution_phases.AFTER_MAIN);
  }

  get teardownCommand(): TeardownCommand {
    const tasks = this.execution_phases.TEARDOWN;
    // Teardown should have one task.
    const logRange =
      tasks[0] === undefined ? new LogRange() : tasks[0].log_range;
    return new TeardownCommand(this.legacyScriptCommands(tasks), logRange);
  }

  convertToCommands(): StepCommand[] {
    return [
      this.runnerDetailsCommand,
      this.setupCommand,
      ...this.mainCommands,
      ...this.afterMainCommands,
      this.teardownCommand,
    ]
      .filter(command => !!command)
      .map((command, index) => {
        if (command instanceof RunnerDetailsCommand) {
          return new RunnerDetailsCommand([command], command.log_range, index);
        } else if (command instanceof SetupCommand) {
          return new SetupCommand([command], command.log_range, index);
        } else if (command instanceof TeardownCommand) {
          return new TeardownCommand([command], command.log_range, index);
        } else if (command instanceof AfterScriptCommand) {
          return new AfterScriptCommand({
            ...command,
            index,
          });
        } else {
          return new ScriptCommand({
            ...command,
            index,
          });
        }
      });
  }

  private legacyScriptCommands(tasks: Task[]): ScriptCommand[] {
    return tasks
      .flatMap(task => task?.commands)
      .map((command: any) => new ScriptCommand(command.toLegacyModel()));
  }

  private legacyAfterScriptCommands(tasks: Task[]): AfterScriptCommand[] {
    return tasks
      .flatMap(task => task?.commands)
      .map((command: any) => new AfterScriptCommand(command.toLegacyModel()));
  }
}
