import { defaultSchema } from '@atlaskit/adf-schema/schema-default';
import { BitbucketTransformer } from '@atlaskit/editor-bitbucket-transformer';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';

const jsonTransformer = new JSONTransformer();

const serializer = new BitbucketTransformer(defaultSchema, {
  disableBitbucketLinkStripping: true,
});

export const encode = (doc: any) =>
  serializer.encode(defaultSchema.nodeFromJSON(doc));

export const parse = (html: string) =>
  jsonTransformer.encode(serializer.parse(html));
