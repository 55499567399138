import type {
  TestCase as TestCaseType,
  TestExecution as TestExecutionType,
} from '../components/test-management';
import durationToSeconds from '../utils/durationToSeconds';
import { flatten } from '../utils/flatten';
import { pick } from '../utils/pick';

export class TestReportResult {
  readonly step_uuid: string = '';
  readonly number_of_error_test_cases: number = 0;
  readonly number_of_failed_test_cases: number = 0;
  readonly number_of_skipped_test_cases: number = 0;
  readonly number_of_successful_test_cases: number = 0;
  readonly number_of_test_cases: number = 0;

  constructor(props: Partial<TestReportResult> = {}) {
    Object.assign(this, props);
    Object.freeze(this);
  }
}

export class TestCaseReason {
  readonly uuid: string = '';
  readonly message: string = '';
  readonly stack_trace: string = '';

  constructor(props: Partial<TestCaseReason> = {}) {
    Object.assign(this, props);
    Object.freeze(this);
  }

  get logLines(): string[] {
    const logLines: any[] = [];
    if (this.message) {
      logLines.push(...this.message.split('\n'));
      // add extra empty line between message and stack trace
      logLines.push('');
    }

    if (this.stack_trace) {
      logLines.push(...this.stack_trace.split('\n'));
      // add extra empty line for proper rendering of truncated logs
      logLines.push('');
    }
    return logLines;
  }
}

export class TestCaseReasonError {
  readonly error: any = undefined;
  readonly 'error.key': string = '';
  readonly 'error.message': number = 0;

  constructor(props: Partial<TestCaseReasonError> = {}) {
    Object.assign(this, {
      ...pick(flatten(props), Object.keys(this)),
    });
    Object.freeze(this);
  }
}

export class TestCase {
  readonly uuid: string = '';
  readonly class_name: string = '';
  readonly name: string = '';
  readonly package_name: string = '';
  readonly suite_name: string = '';
  readonly status: string = '';
  readonly duration: string = '';
  readonly fully_qualified_name: string = '';
  readonly custom_properties: Array<{ key: string; value: string }> = [];
  readonly metrics: {
    p90_duration: number;
    p10_duration: number;
    average_duration: number;
    failure_rate: number;
  } = {
    p90_duration: 0,
    p10_duration: 0,
    average_duration: 0,
    failure_rate: 0,
  };

  constructor(props: Partial<TestCase> = {}) {
    Object.assign(this, {
      ...props,
      duration: props.duration ? durationToSeconds(props.duration) : null,
    });
    Object.freeze(this);
  }
}

export class TestCases {
  readonly values: TestCaseType[] = [];
  readonly page: number = 0;
  readonly pagelen: number = 0;
  readonly size: number = 0;

  constructor(props: Partial<TestCases> = {}) {
    Object.assign(this, props);
    Object.freeze(this);
  }
}

export class TestExecution {
  readonly uuid: string = '';
  readonly name: string = '';
  readonly status: string = '';
  readonly duration: string = '';
  readonly test_report_uuid: string = '';
  readonly test_summary_uuid: string = '';
  readonly fully_qualified_name: string = '';
  readonly class_name: string = '';
  readonly suite_name: string = '';
  readonly package_name: string = '';
  readonly created_on: string = '';
  readonly target: {
    reference_name: string;
    revision: string;
    type: string;
  } = {
    reference_name: '',
    revision: '',
    type: '',
  };

  constructor(props: Partial<TestExecution> = {}) {
    Object.assign(this, {
      ...props,
      duration: props.duration ? durationToSeconds(props.duration) : null,
    });
    Object.freeze(this);
  }
}

export class TestExecutions {
  readonly values: TestExecutionType[] = [];
  readonly page: number = 0;
  readonly pagelen: number = 0;
  readonly size: number = 0;

  constructor(props: Partial<TestExecutions> = {}) {
    Object.assign(this, props);
    Object.freeze(this);
  }
}

export type TestReportStatus = 'SUCCESSFUL' | 'FAILED' | 'ERROR' | 'SKIPPED';
export type TestCaseReasonMap = {
  [key: string]: TestCaseReason | TestCaseReasonError;
};
