/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import React from 'react';

import { CustomGlyphProps } from '@atlaskit/icon/types';

const PremiumIcon = (props: CustomGlyphProps): JSX.Element => {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="#0052CC"
        fillRule="evenodd"
        d="M9.276 4.382L7.357 9.247l-4.863 1.917a.78.78 0 0 0 0 1.45l4.863 1.918 1.919 4.863a.78.78 0 0 0 1.45 0h-.001l1.918-4.863 4.864-1.919a.781.781 0 0 0 0-1.45l-4.864-1.916-1.918-4.865a.776.776 0 0 0-.44-.438.778.778 0 0 0-1.01.438zm8.297-2.03l-.743 1.886-1.884.743a.56.56 0 0 0 0 1.038l1.884.743.743 1.886a.558.558 0 0 0 1.038 0l.745-1.886 1.883-.743a.557.557 0 0 0 0-1.038l-1.883-.743-.745-1.885a.552.552 0 0 0-.314-.314.562.562 0 0 0-.724.314zm-.704 13.003l-.744 1.883-1.883.744a.553.553 0 0 0-.316.314.56.56 0 0 0 .316.724l1.883.743.744 1.884c.057.144.17.258.314.315a.56.56 0 0 0 .724-.315l.744-1.884 1.883-.743a.557.557 0 0 0 0-1.038l-1.883-.744-.744-1.883a.551.551 0 0 0-.315-.316.56.56 0 0 0-.723.316z"
      />
    </svg>
  );
};

export default PremiumIcon;
