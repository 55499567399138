import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { v4 as createUUID } from 'uuid';
import coinflip from '@atlassian/ufo-coinflip';
import { getAwaitBM3TTIList, getCapabilityRate, getConfig } from '@atlassian/ufo-config';
import { clearActiveTrace } from '@atlassian/ufo-experience-trace-id-context';
import { allFeatureFlagsAccessed, currentFeatureFlagsAccessed } from '@atlassian/ufo-feature-flags-accessed';
import { getInteractionId } from '@atlassian/ufo-interaction-id-context';
import { getVCObserver } from '@atlassian/ufo-vc';
import interactions from './common/constants';
var PreviousInteractionLog = {
  name: undefined,
  isAborted: undefined
};
var interactionQueue = [];
var segmentCache = new Map();
var CLEANUP_TIMEOUT = 60 * 1000;
var CLEANUP_TIMEOUT_AFTER_APDEX = 15 * 1000;
var segmentObservers = [];
export function getActiveInteraction() {
  var interactionId = getInteractionId();
  if (!interactionId.current) {
    return;
  }
  return interactions.get(interactionId.current);
}
function labelStackToString(labelStack, name) {
  var _stack$map;
  var stack = _toConsumableArray(labelStack !== null && labelStack !== void 0 ? labelStack : []);
  if (name) {
    stack.push({
      name: name
    });
  }
  return (_stack$map = stack.map(function (l) {
    return l.name;
  })) === null || _stack$map === void 0 ? void 0 : _stack$map.join('/');
}
function labelStackToIdString(labelStack) {
  var _labelStack$map;
  return labelStack === null || labelStack === void 0 || (_labelStack$map = labelStack.map(function (l) {
    return 'segmentId' in l ? "".concat(l.name, ":").concat(l.segmentId) : "".concat(l.name);
  })) === null || _labelStack$map === void 0 ? void 0 : _labelStack$map.join('/');
}
function addSegmentObserver(observer) {
  segmentObservers.push(observer);
  var _iterator = _createForOfIteratorHelper(segmentCache.values()),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var segmentInfo = _step.value;
      observer.onAdd(segmentInfo);
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
}
function removeSegmentObserver(observer) {
  var index = segmentObservers.findIndex(function (obs) {
    return obs === observer;
  });
  if (index !== -1) {
    segmentObservers.splice(index, 1);
  }
}
export function remove(interactionId) {
  interactions.delete(interactionId);
}
export function updatePageLoadInteractionName(ufoName) {
  var routeName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ufoName;
  var interaction = getActiveInteraction();
  if (!interaction || interaction.type !== 'page_load' && interaction.type !== 'transition') {
    return;
  }
  interaction.ufoName = ufoName;
  interaction.routeName = routeName;
}
export function addMetadata(interactionId, data) {
  var interaction = interactions.get(interactionId);
  if (interaction != null) {
    Object.keys(data).forEach(function (key) {
      interaction.metaData[key] = data[key];
    });
  }
}
export function addCustomData(interactionId, labelStack, data) {
  var interaction = interactions.get(interactionId);
  if (interaction != null) {
    Object.keys(data).forEach(function (i) {
      interaction.customData.push({
        labelStack: labelStack,
        data: _defineProperty({}, i, data[i])
      });
    });
  }
}
export function addCustomTiming(interactionId, labelStack, data) {
  var interaction = interactions.get(interactionId);
  if (interaction != null) {
    interaction.customTimings.push({
      labelStack: labelStack,
      data: data
    });
    if (process.env.NODE_ENV !== 'production') {
      for (var _i = 0, _Object$entries = Object.entries(data); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
          key = _Object$entries$_i[0],
          timingData = _Object$entries$_i[1];
        var startTime = timingData.startTime,
          endTime = timingData.endTime;
        try {
          // for Firefox 102 and older
          performance.measure("\uD83D\uDEF8 ".concat(labelStackToString(labelStack, key), " [custom_timing]"), {
            start: startTime,
            end: endTime
          });
        } catch (e) {
          // do nothing
        }
      }
    }
  }
}
export function addMark(interactionId, type, name, labelStack) {
  var time = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : performance.now();
  var interaction = interactions.get(interactionId);
  if (interaction != null) {
    interaction.marks.push({
      type: type,
      name: name,
      labelStack: labelStack,
      time: time
    });
  }
  if (process.env.NODE_ENV !== 'production') {
    performance.mark("\uD83D\uDEF8 ".concat(labelStackToString(labelStack, name), " [").concat(type, "]"), {
      startTime: time
    });
  }
}
export function addMarkToAll(type, name, labelStack) {
  var time = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : performance.now();
  interactions.forEach(function (interaction) {
    interaction.marks.push({
      type: type,
      name: name,
      labelStack: labelStack,
      time: time
    });
  });
  if (process.env.NODE_ENV !== 'production') {
    performance.mark("\uD83D\uDEF8 ".concat(labelStackToString(labelStack, name), " [").concat(type, "]"), {
      startTime: time
    });
  }
}
export function addSpan(interactionId, type, name, labelStack, start) {
  var end = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : performance.now();
  var size = arguments.length > 6 ? arguments[6] : undefined;
  var interaction = interactions.get(interactionId);
  if (interaction != null) {
    interaction.spans.push({
      type: type,
      name: name,
      labelStack: labelStack,
      start: start,
      end: end,
      size: size
    });
    if (process.env.NODE_ENV !== 'production') {
      try {
        // for Firefox 102 and older
        performance.measure("\uD83D\uDEF8 ".concat(labelStackToString(labelStack, name), " [").concat(type, "]"), {
          start: start,
          end: end
        });
      } catch (e) {
        // do nothing
      }
    }
  }
}
export function addSpanToAll(type, name, labelStack, start) {
  var end = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : performance.now();
  var size = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 0;
  interactions.forEach(function (interaction) {
    interaction.spans.push({
      type: type,
      name: name,
      labelStack: labelStack,
      start: start,
      end: end,
      size: size
    });
  });
  if (process.env.NODE_ENV !== 'production') {
    try {
      // for Firefox 102 and older
      performance.measure("\uD83D\uDEF8 ".concat(labelStackToString(labelStack, name), " [").concat(type, "]"), {
        start: start,
        end: end
      });
    } catch (e) {
      // do nothing
    }
  }
}
export function addPreload(moduleId, timestamp) {
  addMarkToAll('bundle_preload', moduleId, null, timestamp);
}
export function addLoad(identifier, start, end) {
  addSpanToAll('bundle_load', identifier, null, start, end - start);
}
var moduleLoadingRequests = {};
export function extractModuleName(input) {
  var result = input !== null && input !== void 0 ? input : '';
  result = result.replace(/^\.\/src\/packages\//, '');
  result = result.replace(/^\.\/node_modules\//, '');
  result = result.replace(/(\/src)?\/(index|main)\.(tsx|ts|js|jsx)$/, '');
  return result;
}
export function addHold(interactionId, labelStack, name) {
  var interaction = interactions.get(interactionId);
  var id = createUUID();
  if (interaction != null) {
    var start = performance.now();
    interaction.holdActive.set(id, {
      labelStack: labelStack,
      name: name,
      start: start
    });
    return function () {
      var end = performance.now();
      if (process.env.NODE_ENV !== 'production') {
        try {
          // for Firefox 102 and older
          performance.measure("\uD83D\uDEF8 ".concat(labelStackToString(labelStack, name), " [hold]"), {
            start: start,
            end: end
          });
        } catch (e) {
          // do nothing
        }
      }
      var currentInteraction = interactions.get(interactionId);
      var currentHold = interaction.holdActive.get(id);
      if (currentInteraction != null && currentHold != null) {
        currentInteraction.holdInfo.push(_objectSpread(_objectSpread({}, currentHold), {}, {
          end: end
        }));
        interaction.holdActive.delete(id);
      }
    };
  }
  return function () {};
}
export function addHoldByID(interactionId, labelStack, name, id, ignoreOnSubmit) {
  var interaction = interactions.get(interactionId);
  if (interaction != null) {
    var start = performance.now();
    interaction.holdActive.set(id, {
      labelStack: labelStack,
      name: name,
      start: start,
      ignoreOnSubmit: ignoreOnSubmit
    });
  }
  return function () {};
}
export function removeHoldByID(interactionId, id) {
  var interaction = interactions.get(interactionId);
  if (interaction != null) {
    var end = performance.now();
    var currentInteraction = interactions.get(interactionId);
    var currentHold = interaction.holdActive.get(id);
    if (currentInteraction != null && currentHold != null) {
      currentInteraction.holdInfo.push(_objectSpread(_objectSpread({}, currentHold), {}, {
        end: end
      }));
      interaction.holdActive.delete(id);
    }
  }
}
export function getCurrentInteractionType(interactionId) {
  var interaction = interactions.get(interactionId);
  if (interaction) {
    return interaction.type;
  }
  return null;
}
export var ModuleLoadingProfiler = {
  onPreload: function onPreload(moduleId, _priority) {
    addPreload(extractModuleName(moduleId), performance.now());
  },
  onLoadStart: function onLoadStart(info) {
    var timeoutId = setTimeout(function () {
      delete moduleLoadingRequests[info.identifier];
    }, 30000);
    var request = {
      start: performance.now(),
      timeoutId: timeoutId
    };
    moduleLoadingRequests[info.identifier] = request;
  },
  onLoadComplete: function onLoadComplete(info) {
    var request = moduleLoadingRequests[info.identifier];
    if (request) {
      clearTimeout(request.timeoutId);
      delete moduleLoadingRequests[info.identifier];
      addLoad(extractModuleName(info.identifier), request.start, performance.now());
    }
  },
  placeholderFallBackMounted: function placeholderFallBackMounted(id, moduleId) {
    var interactionId = getInteractionId();
    var currentInteractionId = interactionId.current;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    addHoldByID(currentInteractionId, [], moduleId, id);
  },
  placeholderFallBackUnmounted: function placeholderFallBackUnmounted(id) {
    var interactionId = getInteractionId();
    var currentInteractionId = interactionId.current;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    removeHoldByID(currentInteractionId, id);
  }
};
export function addError(interactionId, name, labelStack, errorType, errorMessage, errorStack, forcedError) {
  var interaction = interactions.get(interactionId);
  if (interaction != null) {
    interaction.errors.push({
      name: name,
      labelStack: labelStack,
      errorType: errorType,
      errorMessage: errorMessage,
      errorStack: errorStack,
      forcedError: forcedError
    });
  }
}
export function addErrorToAll(name, labelStack, errorType, errorMessage, errorStack) {
  interactions.forEach(function (interaction) {
    interaction.errors.push({
      name: name,
      labelStack: labelStack,
      errorType: errorType,
      errorMessage: errorMessage,
      errorStack: errorStack
    });
  });
}
export var addProfilerTimings = function addProfilerTimings(interactionId, labelStack, type, actualDuration, baseDuration, startTime, commitTime) {
  var interaction = interactions.get(interactionId);
  if (interaction != null) {
    interaction.reactProfilerTimings.push({
      labelStack: labelStack,
      type: type,
      actualDuration: actualDuration,
      baseDuration: baseDuration,
      startTime: startTime,
      commitTime: commitTime
    });
  }
};
var pushToQueue = function pushToQueue(id, data) {
  interactionQueue.push({
    id: id,
    data: data
  });
};
var handleInteraction = pushToQueue;
function callCleanUpCallbacks(interaction) {
  interaction.cleanupCallbacks.reverse().forEach(function (cleanUpCallback) {
    cleanUpCallback();
  });
}
var finishInteraction = function finishInteraction(id, data) {
  var _getConfig;
  var endTime = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : performance.now();
  // eslint-disable-next-line no-param-reassign
  data.end = endTime;
  try {
    // for Firefox 102 and older
    performance.measure("\uD83D\uDEF8 ".concat(data.ufoName, " [ttai]"), {
      start: data.start,
      end: data.end
    });
  } catch (e) {
    // do nothing
  }
  if (data.featureFlags) {
    // eslint-disable-next-line no-param-reassign
    data.featureFlags.during = Object.fromEntries(currentFeatureFlagsAccessed);
  }
  clearActiveTrace();
  callCleanUpCallbacks(data);
  if ((_getConfig = getConfig()) !== null && _getConfig !== void 0 && (_getConfig = _getConfig.vc) !== null && _getConfig !== void 0 && _getConfig.stopVCAtInteractionFinish) {
    data.vc = getVCObserver().getVCRawData();
  }
  remove(id);
  PreviousInteractionLog.name = data.ufoName || 'unknown';
  PreviousInteractionLog.isAborted = data.abortReason != null;
  if (data.ufoName) {
    handleInteraction(id, data);
  }
  if (process.env.NODE_ENV !== 'production') {
    var profilerTimingMap = new Map();
    data.reactProfilerTimings.forEach(function (profilerTiming) {
      var labelStackId = labelStackToIdString(profilerTiming.labelStack);
      if (labelStackId) {
        var _profilerTimingMap$ge, _timing$start, _timing$end;
        var timing = (_profilerTimingMap$ge = profilerTimingMap.get(labelStackId)) !== null && _profilerTimingMap$ge !== void 0 ? _profilerTimingMap$ge : {
          labelStack: profilerTiming.labelStack
        };
        timing.start = profilerTiming.startTime < ((_timing$start = timing.start) !== null && _timing$start !== void 0 ? _timing$start : Number.MAX_SAFE_INTEGER) ? profilerTiming.startTime : timing.start;
        timing.end = profilerTiming.commitTime > ((_timing$end = timing.end) !== null && _timing$end !== void 0 ? _timing$end : Number.MIN_SAFE_INTEGER) ? profilerTiming.commitTime : timing.end;
        profilerTimingMap.set(labelStackId, timing);
      }
    });
    try {
      // for Firefox 102 and older
      var _iterator2 = _createForOfIteratorHelper(profilerTimingMap.entries()),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _step2$value = _slicedToArray(_step2.value, 2),
            _step2$value$ = _step2$value[1],
            labelStack = _step2$value$.labelStack,
            start = _step2$value$.start,
            end = _step2$value$.end;
          performance.measure("\uD83D\uDEF8 ".concat(labelStackToString(labelStack), " [segment_ttai]"), {
            start: start,
            end: end
          });
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    } catch (e) {
      // do nothing
    }
  }
};
export var sinkInteractionHandler = function sinkInteractionHandler(sinkFn) {
  if (handleInteraction === pushToQueue) {
    handleInteraction = sinkFn;
    interactionQueue.forEach(function (interaction) {
      sinkFn(interaction.id, interaction.data);
    });
    interactionQueue.length = 0;
  }
};
export function tryComplete(interactionId, endTime) {
  var interaction = interactions.get(interactionId);
  if (interaction != null) {
    var noMoreHolds = interaction.holdActive.size === 0;
    if (noMoreHolds) {
      finishInteraction(interactionId, interaction, endTime);
    }
  }
}
function callCancelCallbacks(interaction) {
  interaction.cancelCallbacks.reverse().forEach(function (cancelCallback) {
    cancelCallback();
  });
}
export function abort(interactionId, abortReason) {
  var interaction = interactions.get(interactionId);
  if (interaction != null) {
    callCancelCallbacks(interaction);
    interaction.abortReason = abortReason;
    finishInteraction(interactionId, interaction);
  }
}
export function abortByNewInteraction(interactionId, interactionName) {
  var interaction = interactions.get(interactionId);
  if (interaction != null) {
    callCancelCallbacks(interaction);
    interaction.abortReason = 'new_interaction';
    interaction.abortedByInteractionName = interactionName;
    finishInteraction(interactionId, interaction);
  }
}
export function abortAll(abortReason, abortedByInteractionName) {
  interactions.forEach(function (interaction, interactionId) {
    var noMoreHolds = interaction.holdActive.size === 0;
    if (!noMoreHolds) {
      callCancelCallbacks(interaction);
      // eslint-disable-next-line no-param-reassign
      interaction.abortReason = abortReason;
      if (abortedByInteractionName != null) {
        // eslint-disable-next-line no-param-reassign
        interaction.abortedByInteractionName = abortedByInteractionName;
      }
    }
    finishInteraction(interactionId, interaction);
  });
}
export function addOnCancelCallback(id, cancelCallback) {
  var interaction = interactions.get(id);
  interaction === null || interaction === void 0 || interaction.cancelCallbacks.push(cancelCallback);
}
export function addNewInteraction(interactionId, ufoName, type, startTime, rate, labelStack, routeName) {
  var trace = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : null;
  var previousTime = startTime;
  var timeoutTime = CLEANUP_TIMEOUT;
  var timerID = setTimeout(function () {
    abort(interactionId, 'timeout');
  }, CLEANUP_TIMEOUT);
  function changeTimeout(newTime) {
    // we compare if the time left is lower than the new time to no
    // extend the timeout beyond the initial waiting time
    var currentTime = performance.now();
    var timeLeft = timeoutTime - (currentTime - previousTime);
    if (timeLeft < newTime) {
      return;
    }
    clearTimeout(this.timerID);
    var newTimerID = setTimeout(function () {
      abort(interactionId, 'timeout');
    }, newTime);
    timeoutTime = newTime;
    previousTime = currentTime;
    this.timerID = newTimerID;
  }
  var addFeatureFlagsToInteraction = coinflip(getCapabilityRate('feature_flag_access'));
  var metrics = {
    id: interactionId,
    start: startTime,
    end: 0,
    ufoName: ufoName,
    type: type,
    previousInteractionName: PreviousInteractionLog.name,
    isPreviousInteractionAborted: PreviousInteractionLog.isAborted === true,
    marks: [],
    customData: [],
    customTimings: [],
    spans: [],
    requestInfo: [],
    reactProfilerTimings: [],
    holdInfo: [],
    holdActive: new Map(),
    // measure when we execute this code
    // from this we can measure the input delay -
    // how long the browser took to hand execution back to JS)
    measureStart: performance.now(),
    rate: rate,
    cancelCallbacks: [],
    metaData: {},
    errors: [],
    apdex: [],
    labelStack: labelStack,
    routeName: routeName !== null && routeName !== void 0 ? routeName : ufoName,
    featureFlags: addFeatureFlagsToInteraction ? {
      prior: Object.fromEntries(allFeatureFlagsAccessed),
      during: {}
    } : undefined,
    knownSegments: [],
    cleanupCallbacks: [],
    awaitReactProfilerCount: 0,
    redirects: [],
    timerID: timerID,
    changeTimeout: changeTimeout,
    trace: trace
  };
  if (addFeatureFlagsToInteraction) {
    currentFeatureFlagsAccessed.clear();
  }
  interactions.set(interactionId, metrics);
  var segmentObserver = {
    onAdd: function onAdd(segment) {
      metrics.knownSegments.push(segment);
    },
    onRemove: function onRemove() {}
  };
  addSegmentObserver(segmentObserver);
  metrics.cleanupCallbacks.push(function () {
    removeSegmentObserver(segmentObserver);
  });
  metrics.cleanupCallbacks.push(function () {
    clearTimeout(metrics.timerID);
  });
  var awaitBM3TTIList = getAwaitBM3TTIList();
  if (awaitBM3TTIList.includes(ufoName)) {
    addHoldByID(interactionId, [], ufoName, ufoName, true);
  }
  if (type === 'transition') {
    getVCObserver().start({
      startTime: startTime
    });
  }
}
export function addBrowserMetricEvent(event) {
  var interaction = getActiveInteraction();
  if (interaction) {
    var _event$config;
    interaction.legacyMetrics = interaction.legacyMetrics || [];
    interaction.legacyMetrics.push(event);
    if ((interaction.type === 'page_load' || interaction.type === 'transition') && ((_event$config = event.config) === null || _event$config === void 0 ? void 0 : _event$config.type) === 'PAGE_LOAD') {
      interaction.changeTimeout(CLEANUP_TIMEOUT_AFTER_APDEX);
      removeHoldByID(interaction.id, interaction.ufoName);
    }
  }
}
export function addApdexToAll(apdex) {
  interactions.forEach(function (interaction, key) {
    interaction.apdex.push(apdex);
    try {
      var _apdex$startTime;
      // for Firefox 102 and older
      performance.measure("\uD83D\uDEF8 ".concat(apdex.key, " [bm3_tti]"), {
        start: (_apdex$startTime = apdex.startTime) !== null && _apdex$startTime !== void 0 ? _apdex$startTime : interaction.start,
        end: apdex.stopTime
      });
    } catch (e) {
      // do nothing
    }
    if (interaction.type === 'page_load' || interaction.type === 'transition') {
      interaction.changeTimeout(CLEANUP_TIMEOUT_AFTER_APDEX);
      removeHoldByID(key, interaction.ufoName);
    }
  });
}
export function addApdex(interactionId, apdexInfo) {
  var interaction = interactions.get(interactionId);
  if (interaction != null) {
    interaction.apdex.push(apdexInfo);
    try {
      var _apdexInfo$startTime;
      // for Firefox 102 and older
      performance.measure("\uD83D\uDEF8 ".concat(apdexInfo.key, " [bm3_tti]"), {
        start: (_apdexInfo$startTime = apdexInfo.startTime) !== null && _apdexInfo$startTime !== void 0 ? _apdexInfo$startTime : interaction.start,
        end: apdexInfo.stopTime
      });
    } catch (e) {
      // do nothing
    }
    if (interaction.type === 'page_load' || interaction.type === 'transition') {
      interaction.changeTimeout(CLEANUP_TIMEOUT_AFTER_APDEX);
      removeHoldByID(interactionId, interaction.ufoName);
    }
  }
}
export function addRequestInfo(interactionId, labelStack, requestInfo) {
  var interaction = interactions.get(interactionId);
  if (interaction != null) {
    interaction.requestInfo.push(_objectSpread({
      labelStack: labelStack
    }, requestInfo));
  }
}
function isSegmentLabel(obj) {
  return obj && typeof obj.name === 'string' && typeof obj.segmentId === 'string';
}
function getSegmentCacheKey(labelStack) {
  return labelStack.map(function (l) {
    if (isSegmentLabel(l)) {
      return "".concat(l.name, "_").concat(l.segmentId);
    }
    return l.name;
  }).join('|');
}
export function addSegment(labelStack) {
  var key = getSegmentCacheKey(labelStack);
  var existingSegment = segmentCache.get(key);
  if (!existingSegment) {
    var segmentInfo = {
      labelStack: labelStack
    };
    segmentCache.set(key, segmentInfo);
    segmentObservers.forEach(function (observer) {
      observer.onAdd(segmentInfo);
    });
  }
}
export function removeSegment(labelStack) {
  var key = getSegmentCacheKey(labelStack);
  var segmentInfo = segmentCache.get(key);
  if (segmentInfo) {
    segmentCache.delete(JSON.stringify(labelStack));
    segmentObservers.forEach(function (observer) {
      observer.onRemove(segmentInfo);
    });
  }
}
export function addRedirect(interactionId, fromUfoName, nextUfoName, nextRouteName, time) {
  var interaction = interactions.get(interactionId);
  if (interaction != null) {
    interaction.ufoName = nextUfoName;
    interaction.routeName = nextRouteName;
    interaction.redirects.push({
      fromInteractionName: fromUfoName,
      time: time
    });
    if (process.env.NODE_ENV !== 'production') {
      var prevRedirect = interaction.redirects.at(-2);
      try {
        var _prevRedirect$time;
        // for Firefox 102 and older
        performance.measure("\uD83D\uDEF8 ".concat(nextUfoName, " [redirect]"), {
          start: (_prevRedirect$time = prevRedirect === null || prevRedirect === void 0 ? void 0 : prevRedirect.time) !== null && _prevRedirect$time !== void 0 ? _prevRedirect$time : interaction.start,
          end: time
        });
      } catch (e) {
        // do nothing
      }
    }
  }
}