import { PipelineState } from '../models/PipelineState';

export function convertPipelineStatus(
  state: PipelineState | undefined
): string {
  return convertStatus(state, false);
}

export function convertStepStatus(state: PipelineState | undefined): string {
  return convertStatus(state, true);
}

function convertStatus(
  state: PipelineState | undefined,
  usePendingStage: boolean
): string {
  if (!state || !state.name) {
    return '';
  }
  if (state.name === 'IN_PROGRESS' && state.stage) {
    return state.stage.name;
  }
  if (state.name === 'PENDING' && usePendingStage && state.stage) {
    return state.stage.name;
  }
  if (state.name === 'COMPLETED' && state.result) {
    if (state.result.name === 'ERROR') {
      return 'SYSTEM_ERROR';
    }
    return state.result.name;
  }
  return state.name;
}
