import { defineMessages } from 'react-intl';

export default defineMessages({
  userAiOnboardingFlagTitle: {
    id: 'frontbucket.pullRequest.userAiOnboardingFlagTitle',
    description: 'Text for the title of the user ai onboarding flag message',
    defaultMessage: 'Introducing Atlassian Intelligence (AI)',
  },
  userAiOnboardingFlagDescription: {
    id: 'frontbucket.pullRequest.userAiOnboardingFlagDescription',
    description: 'Text for the body of the user AI onboarding flag message',
    defaultMessage:
      'Write pull request descriptions, generate comments, or change the tone of your comments using AI. Access it via the { editorToolbar } or by typing { aiShortcut } in the description or comment fields.',
  },
  userAiOnboardingFlagEditorToolbar: {
    id: 'frontbucket.pullRequest.userAiOnboardingFlagEditorToolbar',
    description: 'Text for the editor toolbar',
    defaultMessage: 'editor toolbar',
  },
  userAiOnboardingFlagLearnMore: {
    id: 'frontbucket.pullRequest.userAiOnboardingFlagLearnMore',
    description:
      'Text for the "learn more" of the user AI onboarding flag message',
    defaultMessage: 'Learn more',
  },
});
