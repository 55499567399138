import { ChunkEntry, DiffEntry } from '@atlassian/bitkit-diff/exports/types';

function amendChunk(chunk: ChunkEntry, index: number) {
  const isFirstChunk = index === 0;
  const { changes } = chunk;
  const extra = {
    isLoadingBelow: false,
    isLoadingAbove: false,
    before: {
      hasMoreLines: true,
    },
    after: {
      hasMoreLines: true,
    },
  };

  if (isFirstChunk) {
    const firstChange = changes[0];
    const isLineOne = firstChange.oldLine === 1 || firstChange.newLine === 1;
    extra.before.hasMoreLines = !isLineOne;
    // check for added or deleted files
    extra.after.hasMoreLines = !(chunk.oldLines === 0 || chunk.newLines === 0);
  }

  return {
    ...chunk,
    id: chunk.content,
    extra,
  };
}

export function amendChunks<T extends DiffEntry>(diffs: T[]): T[] {
  return diffs.map(diff => ({
    ...diff,
    chunks: diff.chunks.map(amendChunk),
  }));
}

function clearContextExpansion<T extends ChunkEntry>(chunk: T) {
  return {
    ...chunk,
    extra: {
      ...chunk.extra,
      after: { hasMoreLines: false },
      before: { hasMoreLines: false },
    },
  };
}

export function clearContextExpansions<T extends DiffEntry>(diffs: T[]): T[] {
  return diffs.map(diff => ({
    ...diff,
    chunks: diff.chunks.map(clearContextExpansion),
  }));
}
