import {Flag} from '../Flag';
import {FlagProvider} from '../FlagProvider';
import {FlagType, FlagAction, CloseFlagOption} from '../FlagCreationOptions';
import ValidationUtil from '../../../definitions/validation/ValidationUtil';

/**
 * This class validates an instance of FlagProvider. This is intended to be used by consumers of this library in
 * their tests to validate their implementations. Knowing products have incorporated this into their test suite will
 * allow breaking changes to be introduced in the library.
 */
export class FlagProviderValidator {

  public static validate(flagProvider: FlagProvider): void {
    ValidationUtil.checkFunctionExists('flagProvider', flagProvider, 'create');
    ValidationUtil.checkFunctionExists('flagProvider', flagProvider, 'isFlagOpen');
    FlagProviderValidator.validateFlagCreationAndDeletions(flagProvider, 'manual' as CloseFlagOption);
    FlagProviderValidator.validateFlagCreationAndDeletions(flagProvider, 'auto' as CloseFlagOption);
    FlagProviderValidator.validateFlagCreationAndDeletions(flagProvider, 'never' as CloseFlagOption);
  }

  private static validateFlagCreationAndDeletions(
      flagProvider: FlagProvider,
      closeFlagOption: CloseFlagOption) {
    const flagActions :FlagAction[] = [];
    FlagProviderValidator.validateFlagCreationAndDeletion(flagProvider, closeFlagOption, flagActions, false);

    const flagAction = new FlagAction();
    flagAction.actionKey = 'some-action';
    flagAction.actionText = 'Some Action';
    flagAction.executeAction = () => {console.log('Action clicked')};
    flagActions.push(flagAction);
    FlagProviderValidator.validateFlagCreationAndDeletion(flagProvider, closeFlagOption, flagActions, false);

    const flagActionMissingExecuteAction = new FlagAction();
    flagActionMissingExecuteAction.actionKey = 'some-action';
    flagActionMissingExecuteAction.actionText = 'Some Action';
    flagActions[0] = flagActionMissingExecuteAction;
    FlagProviderValidator.validateFlagCreationAndDeletion(flagProvider, closeFlagOption, flagActions, true);
  }

  private static validateFlagCreationAndDeletion(
      flagProvider: FlagProvider,
      closeFlagOption: CloseFlagOption,
      flagActions: FlagAction[],
      expectValidationError: boolean) {
    const flagIdsToFlags = new Map<string, Flag>();
    const flagId = 'flag-' + (new Date()).getMilliseconds();
    const flagOptions = {
      id: flagId,
      title: 'My Flag',
      body: 'My flag content',
      type: 'success' as FlagType,
      close: closeFlagOption,
      onClose: (flagId: string) => {
        flagIdsToFlags.delete(flagId);
      },
      actions: flagActions
    };

    try {
      const flag = flagProvider.create(flagOptions);
      if (expectValidationError) {
        throw new Error('Expected a validation error, but did not get one.');
      }

      flagIdsToFlags.set(flag.id, flag);

      ValidationUtil.checkExists('flag', flag);
      ValidationUtil.checkFunctionExists('flag', flag, 'close');

      if (closeFlagOption !== 'auto') {
        const flagOpen = flagProvider.isFlagOpen(flag.id);
        if (!flagOpen) {
          throw new Error(`Expected flag to exist.`);
        }
      }

      flag.close();
      if (flagIdsToFlags.size) {
        throw new Error('It looks like the provider did not synchronously call back the onClose method.');
      }
    } catch (exception) {
      if (expectValidationError) {
        // OK
      } else {
        throw new Error('Did not expect a validation error, but got one.');
      }
    }
  }

}
