import React from 'react';

import { CustomGlyphProps } from '@atlaskit/icon/types';

export const InProgressStatusIcon = (props: CustomGlyphProps): JSX.Element => {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16">
      <path
        d="M0 8a7.77 7.77 0 01.637-3.105 8.039 8.039 0 011.707-2.55A8.047 8.047 0 014.894.637 7.785 7.785 0 018 0a7.77 7.77 0 013.105.637 8.039 8.039 0 012.55 1.707 8.047 8.047 0 011.708 2.55C15.788 5.883 16 6.918 16 8a7.77 7.77 0 01-.637 3.105 8.039 8.039 0 01-1.707 2.55 8.047 8.047 0 01-2.55 1.708A7.785 7.785 0 018 16a7.77 7.77 0 01-3.105-.637 8.039 8.039 0 01-2.55-1.707 8.047 8.047 0 01-1.708-2.55A7.785 7.785 0 010 8zm12.39 1.396a4.68 4.68 0 00-1.144-4.74 4.681 4.681 0 00-4.74-1.143l.61 1.904a2.676 2.676 0 013.369 3.37l1.905.61zm-3.702 1.187a2.677 2.677 0 01-2.715-.653 2.678 2.678 0 01-.653-2.715l-1.905-.61a4.68 4.68 0 001.144 4.74 4.677 4.677 0 004.738 1.143l-.61-1.905z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};
