export default function checkLanguage(repositoryFiles: string[]): string {
  let assumedLanguage = '';
  const languageSpecificFiles: any = {
    php: ['composer.json'],
    python: ['requirements.txt', 'manage.py'],
    java: ['pom.xml'],
    javascript: ['package.json', 'bower.json'],
    gradle: ['gradlew'],
    haskell: ['stack.yaml'],
    go: ['main.go'],
    rust: ['Cargo.toml', 'build.rs'],
    scala: ['build.sbt'],
    ruby: ['Rakefile', 'Gemfile'],
    clojure: ['project.clj'],
    'c\\+\\+': ['Makefile'],
    docker: ['Dockerfile'],
    'vb.net': ['build.proj'],
  };
  Object.keys(languageSpecificFiles).some(language => {
    const foundLanguageFile = languageSpecificFiles[language].some(
      (file: string) => repositoryFiles.indexOf(file) !== -1
    );
    if (foundLanguageFile) {
      assumedLanguage = language;
    }
    return foundLanguageFile;
  });
  return assumedLanguage;
}
