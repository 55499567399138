import React, { ReactElement } from 'react';

import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { AutoDismissFlag } from '@atlaskit/flag';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { handleJiraIssueLinkClick } from 'src/connect/jira-issue-dialog/jira-issue-dialog';
import { ComponentFlagProps } from 'src/redux/flags/types';
import { getCreatedJiraIssue } from 'src/redux/jira/selectors/jira-issue-selectors';

import messages from './create-jira-issue-success-flag.i18n';

export const CreateJiraIssueSuccessFlag = ({
  id,
  handleDismissed,
  ...otherProps
}: ComponentFlagProps): ReactElement | null => {
  const issue = useSelector(getCreatedJiraIssue);
  if (!issue) {
    return null;
  }

  const handleClick = (e: React.MouseEvent) => {
    const uiEvent = {
      action: 'clicked',
      actionSubject: 'link',
      actionSubjectId: 'createdJiraIssue',
      source: 'createJiraIssueSuccessFlag',
    };
    handleJiraIssueLinkClick(e, uiEvent, issue.site.cloudUrl, issue.key);

    // Dismiss the flag unless issue was opened in new tab
    if (!e.metaKey && !e.ctrlKey) {
      handleDismissed(id);
    }
  };

  const issueLink = (text: string | JSX.Element) => (
    <a
      href={`${issue.site.cloudUrl}/browse/${issue.key}`}
      target="_blank"
      onClick={handleClick}
    >
      {text}
    </a>
  );

  return (
    <AutoDismissFlag
      {...otherProps}
      id={id}
      onDismissed={handleDismissed}
      icon={
        <CheckCircleIcon
          label=""
          primaryColor={token('color.icon.success', colors.G300)}
        />
      }
      title={<FormattedMessage {...messages.successTitle} />}
      description={
        <FormattedMessage
          {...messages.successDescription}
          values={{
            issueKey: issueLink(issue.key),
            projectName: issue.project.name,
            projectKey: issue.project.key,
          }}
        />
      }
      actions={[
        {
          content: issueLink(<FormattedMessage {...messages.viewIssueLink} />),
        },
      ]}
      testId="jira-tab-issue-created-flag"
    />
  );
};
