import _typeof from "@babel/runtime/helpers/typeof";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { DEFAULT_SOURCE } from '@atlaskit/analytics-gas-types';
import { ELEMENTS_CONTEXT, EDITOR_CONTEXT } from '@atlaskit/analytics-namespaced-context';
import merge from 'lodash/merge';
import { ELEMENTS_TAG } from './FabricElementsListener';
import { EDITOR_TAG } from './FabricEditorListener';
var extractFieldsFromContext = function extractFieldsFromContext(fieldsToPick) {
  return function (contexts) {
    return contexts.map(function (ctx) {
      return fieldsToPick.reduce(function (result, key) {
        return ctx[key] ? merge(result, _defineProperty({}, key, ctx[key])) : result;
      }, {});
    }).reduce(function (result, item) {
      return merge(result, item);
    }, {});
  };
};
var fieldExtractor = function fieldExtractor(contextKey) {
  return extractFieldsFromContext(['source', 'objectType', 'objectId', 'containerType', 'containerId', contextKey]);
};
var getContextKey = function getContextKey(tag) {
  switch (tag) {
    case ELEMENTS_TAG:
      return ELEMENTS_CONTEXT;
    case EDITOR_TAG:
      return EDITOR_CONTEXT;
    default:
      return '';
  }
};
var updatePayloadWithContext = function updatePayloadWithContext(primaryTag, event) {
  if (event.context.length === 0) {
    return _objectSpread({
      source: DEFAULT_SOURCE
    }, event.payload);
  }
  var contextKey = getContextKey(primaryTag) || 'attributes';
  var _fieldExtractor = fieldExtractor(contextKey)(event.context),
    attributes = _fieldExtractor[contextKey],
    fields = _objectWithoutProperties(_fieldExtractor, [contextKey].map(_toPropertyKey));
  if (attributes) {
    event.payload.attributes = merge(attributes, event.payload.attributes || {});
  }
  return _objectSpread(_objectSpread({
    source: DEFAULT_SOURCE
  }, fields), event.payload);
};
var addTags = function addTags(tags) {
  var originalTags = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var mergedTags = new Set([].concat(_toConsumableArray(originalTags), _toConsumableArray(tags)));
  return Array.from(mergedTags);
};

/**
 * The primary tag is used for matching the analytics event payload
 * with its context.
 */
function getPrimaryTag(tags) {
  if (typeof tags === 'string') {
    return tags;
  }
  if (!tags.length) {
    throw new Error('Empty tags string array. Unable to match analytics event payload with context');
  }
  return tags[0];
}
export var processEventPayload = function processEventPayload(event, tags) {
  var primaryTag = getPrimaryTag(tags);
  var tagsArray = typeof tags === 'string' ? [tags] : tags;
  return _objectSpread(_objectSpread({}, updatePayloadWithContext(primaryTag, event)), {}, {
    tags: addTags(tagsArray, event.payload.tags)
  });
};