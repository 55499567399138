// NOTE: This assumes there are existing context lines.  If the existing
// chunk lines are only diff lines, this WILL BREAK.

import { ChunkEntry } from '@atlassian/bitkit-diff';

import { ContextLine } from '../sagas/utils/convert-to-chunks-format';

import { chunksWillConnect } from './chunks-will-connect';
import { mergeChunks } from './merge-chunks';
import { simplePrepend } from './simple-append';

export function prependToChunk(
  chunks: ChunkEntry[],
  chunkIndex: number,
  contextLines: ContextLine[],
  hasMoreLines: boolean
) {
  if (chunksWillConnect(chunks, chunkIndex, contextLines, true)) {
    return mergeChunks(chunks, chunkIndex, contextLines, true);
  }

  return simplePrepend(chunks, chunkIndex, contextLines, hasMoreLines);
}
