/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { put, call, spawn, select } from 'redux-saga/effects';

import { clearContextExpansions } from '@atlassian/bitkit-diff/exports/util/amend-chunks';

import { getResourceTimings } from 'src/components/performance-metrics/resource-timings';
import { AsyncAction } from 'src/redux/actions';
import {
  getGlobalIsWordDiffEnabled,
  getGlobalShouldIgnoreWhitespace,
  getGlobalIsSingleFileModeEnabled,
} from 'src/redux/diff-settings';
import {
  FETCH_DIFF,
  PUBLISH_BASE_PULL_REQUEST_FACT,
  PUBLISH_PULL_REQUEST_DIFF_FACT,
  TOGGLE_SINGLE_FILE_MODE,
  TOGGLE_SINGLE_FILE_MODE_ELIGIBILITY,
} from 'src/redux/pull-request/actions';
import {
  publishBasePullRequestFactSaga,
  publishPullRequestDiffFactSaga,
} from 'src/redux/pull-request/sagas/facts-sagas';
import {
  getPullRequestCompareSpecDiffUrl,
  getIsSingleFileModeActive,
  getCurrentPullRequestInTopicDiffMode,
  pullRequestRenderingLimits,
} from 'src/redux/pull-request/selectors';
import { getPullRequestApis } from 'src/sagas/helpers';
import { Diff } from 'src/types/pull-request';
import {
  getEffectivePerFileLimits,
  shouldTriggerSingleFileMode,
} from 'src/utils/diff-classifications';

import { addQueryParams, normalizeDiffParams } from '../urls/url-utils';
import {
  FetchEntireFile,
  ViewEntireFileAction,
} from '../view-entire-file-reducer';

function* publishDiffsFetchedFact() {
  let timings = getResourceTimings('/diff?');
  if (!timings.present) {
    timings = getResourceTimings('/diff/');
  }

  const diffsFetchedFactAction = {
    type: PUBLISH_BASE_PULL_REQUEST_FACT,
    payload: {
      name: 'bitbucket.pullrequests.diffs.fetched',
      extraFactData: {
        encoded_body_size: timings.encodedBodySize,
        request_waiting_time: timings.requestWaitingTime,
        response_duration: timings.responseDuration,
        total_fetch_duration: Math.floor(timings.duration) || null,
        transfer_size: timings.transferSize,
      },
    },
  };

  yield spawn(publishBasePullRequestFactSaga, diffsFetchedFactAction);
}

function* publishDiffsParsedFact() {
  const diffsParsedFactAction = {
    type: PUBLISH_PULL_REQUEST_DIFF_FACT,
    payload: {
      name: 'bitbucket.pullrequests.diffs.parsed',
    },
  };

  yield spawn(publishPullRequestDiffFactSaga, diffsParsedFactAction);
}

type FetchDiffOptions = {
  collectDiffFetchedAnalytics?: () => void;
  collectDiffParsedAnalytics?: () => void;
  postProcessDiff?: (diff: Diff[]) => Diff[];
};

function* diffWorkerLazyImport() {
  const importPromise = new Promise(resolve =>
    import(
      /* webpackChunkName: "word-diff-worker" */ 'src/workers/word-diff.worker'
    ).then(module => resolve(module.default))
  );
  const awaitPromise = () => Promise.resolve(importPromise);
  // @ts-ignore
  return yield call(awaitPromise);
}

export function* fetchDiff(
  url: string,
  action: AsyncAction,
  options: FetchDiffOptions = {}
) {
  try {
    const api = yield* getPullRequestApis();
    yield put({ type: action.REQUEST });

    const { diff } = yield call(api.getDiff, url);

    if (options.collectDiffFetchedAnalytics) {
      yield options.collectDiffFetchedAnalytics();
    }
    const isWordDiffEnabled: boolean = yield select(getGlobalIsWordDiffEnabled);
    // @ts-ignore
    const WordDiffWorker = yield call(diffWorkerLazyImport);
    const worker = WordDiffWorker();
    let processedDiffs: Diff[] = yield call(worker.processDiff, diff, {
      isWordDiffEnabled,
    });

    if (options.postProcessDiff) {
      processedDiffs = options.postProcessDiff(processedDiffs);
    }

    const globalIsSingleFileModeEnabled: boolean = yield select(
      getGlobalIsSingleFileModeEnabled
    );

    const isSingleFileModeThresholdMet = shouldTriggerSingleFileMode(
      processedDiffs,
      pullRequestRenderingLimits
    );
    if (isSingleFileModeThresholdMet) {
      yield put({
        type: TOGGLE_SINGLE_FILE_MODE_ELIGIBILITY,
        payload: true,
      });
    }
    const isSingleFileModeActive: boolean = yield select(
      getIsSingleFileModeActive
    );
    if (
      !isSingleFileModeActive &&
      (isSingleFileModeThresholdMet || globalIsSingleFileModeEnabled)
    ) {
      yield put({
        type: TOGGLE_SINGLE_FILE_MODE,
        payload: true,
      });
    }

    // Diff success should be posted after single file mode status is posted,
    // so that the initial diff is rendered in the correct mode.
    yield put({
      type: action.SUCCESS,
      payload: processedDiffs,
    });

    if (options.collectDiffParsedAnalytics) {
      yield options.collectDiffParsedAnalytics();
    }
  } catch (e) {
    yield put({ type: action.ERROR, payload: e.status });
  }
}

export function* fetchDiffSaga(url: string) {
  yield call(fetchDiff, url, FETCH_DIFF, {
    collectDiffFetchedAnalytics: publishDiffsFetchedFact,
    collectDiffParsedAnalytics: publishDiffsParsedFact,
  });
}

export function* viewEntireDiffFileSaga(action: ViewEntireFileAction) {
  const { path, url, topicDiff } = action.payload;

  const isSingleFileModeActive: boolean = yield select(
    getIsSingleFileModeActive
  );

  if (!url) {
    return;
  }

  const ignoreWhitespace: boolean = yield select(
    getGlobalShouldIgnoreWhitespace
  );

  // Only fetch up to our current per-file limit, because we won't be rendering the content
  // if the file is larger than this
  const { lineLimit: contextLineCount } = getEffectivePerFileLimits(
    isSingleFileModeActive,
    pullRequestRenderingLimits
  );

  const urlWithParams = addQueryParams(url, {
    context: contextLineCount,
    ignore_whitespace: ignoreWhitespace,
    topic: topicDiff ? true : null,
    path,
  });

  yield call(fetchDiff, urlWithParams, FetchEntireFile, {
    // We set MAX_CONTEXT_EXPANSION high enough so that it's almost impossible that the entire file
    // won't be rendered in a single chunk, so we shouldn't render the context expansion buttons at the
    // top or bottom. We're OK with this behavior being "wrong" for enormous files, because we don't
    // render files that would be large enough to run into that situation (we render a placeholder
    // message instead).
    postProcessDiff: clearContextExpansions,
  });
}

export function* fetchDiffByCompareSpecSaga() {
  const ignoreWhitespace: boolean = yield select(
    getGlobalShouldIgnoreWhitespace
  );
  const topicDiff: boolean = yield select(getCurrentPullRequestInTopicDiffMode);
  const serverGeneratedDiffUrl: string = yield select(
    getPullRequestCompareSpecDiffUrl
  );

  // Only run saga for PR page diffs
  if (serverGeneratedDiffUrl === undefined) {
    return;
  }

  const url = addQueryParams(
    serverGeneratedDiffUrl,
    normalizeDiffParams({ topicDiff, ignoreWhitespace })
  );

  yield call(fetchDiffSaga, url);
}
