import { createResource } from 'react-resource-router';

import {
  GetPermissionsOptions,
  PermissionsState,
  mapInheritedUserAndGroupPrivilege,
  InheritedPrivilegesResponse,
  buildBBQL,
} from 'src/components/settings/permissions';
import urls from 'src/urls/workspaces';
import { request } from 'src/utils/http-request';

export const fetchPermissions = (
  workspaceSlug: string,
  { page = 1, permission, principalName }: GetPermissionsOptions = {}
) => {
  return request<
    Pick<PermissionsState, 'permissions' | 'page' | 'limit' | 'total'>
  >(
    urls.api.internal.inheritedPermissions(workspaceSlug, {
      page,
      q: buildBBQL({ permission, principalName }),
    }),
    {
      successTransformer: ({
        values = [],
        page: pagenum,
        pagelen,
        size,
      }: InheritedPrivilegesResponse) => {
        return {
          permissions: new Map([
            [pagenum, values.map(mapInheritedUserAndGroupPrivilege)],
          ]),
          page: pagenum,
          limit: pagelen,
          total: size,
        };
      },
    }
  );
};

export const loadWorkspaceSettingsPermissionsResource = createResource({
  type: 'workspaceAdminPermissions',
  getKey: ({ match }) => {
    const { workspaceSlug } = match.params;
    return urls.ui.permissions(workspaceSlug!);
  },
  maxAge: 60000, // cache for 1 minute so navigating between pages is fast
  getData: async ({ match }) => {
    const { workspaceSlug } = match.params;
    return {
      ...(await fetchPermissions(workspaceSlug!)),
      adminLevel: 'workspace',
    } as Pick<
      PermissionsState,
      'permissions' | 'page' | 'limit' | 'total' | 'adminLevel'
    >;
  },
});
