/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import * as Sentry from '@sentry/browser';
import { call, debounce, put, select, take } from 'redux-saga/effects';

import { Team, User } from 'src/components/types';
import { LoadingStatus } from 'src/constants/loading-status';
import { showFlagComponent } from 'src/redux/flags';
import { getCreateJiraIssuePreferences } from 'src/redux/jira/selectors/jira-issue-selectors';
import { EnteredCodeReviewAction } from 'src/redux/pull-request/actions';
import { LoadRepositoryPage } from 'src/sections/repository/actions';
import { getRepositoryPageLoadingStatus } from 'src/selectors/global-selectors';
import {
  getCurrentRepositoryFullSlug,
  getCurrentRepositoryUuid,
} from 'src/selectors/repository-selectors';
import { getCurrentUser } from 'src/selectors/user-selectors';
import { Action } from 'src/types/state';
import urls from 'src/urls/jira';
import { publishTrackEvent } from 'src/utils/analytics/publish';
import authRequest from 'src/utils/fetch';
import prefs from 'src/utils/preferences';
import { captureMessageForResponse } from 'src/utils/sentry';

import {
  CREATE_PR_JIRA_ISSUE,
  FETCH_CONNECTED_JIRA_SITES,
  FETCH_JIRA_PROJECT,
  FETCH_JIRA_PROJECTS_FOR_SITE,
  FETCH_JIRA_ISSUE_CREATION_METADATA,
  FETCH_CREATE_JIRA_ISSUE_ONBOARDING_VIEWED,
  FETCH_CREATE_JIRA_ISSUE_PREFERENCES,
  UPDATE_CREATE_JIRA_ISSUE_ONBOARDING_VIEWED,
  UPDATE_CREATE_JIRA_ISSUE_PREFERENCES,
  fetchConnectedJiraSites,
  fetchCreateJiraIssuePreferences,
  fetchJiraProject,
  onCreateJiraIssueFormChangeVisibility,
  setFormErrorState,
  setPrFormErrorState,
  updateCreateJiraIssuePreferences,
  updateJiraIssuesListAfterCreation,
  CREATE_JIRA_ISSUE,
  fetchJiraRelevantIssues,
  changeIsCreateIssueDialogOpen,
  FETCH_JIRA_TAB_CREATE_ISSUE_USER_PREFERENCE,
} from '../actions';
import {
  ONBOARDING_VIEWED_PREF_KEY,
  IssueCreationFailureReason,
  OnboardingViewed,
} from '../constants';
import { CreateJiraIssueState } from '../reducers/create-jira-issue';
import {
  Site,
  CreatePrCommentIssuePayload,
  IssueType,
  CreateJiraIssueSagaPayload,
  JiraIssue,
} from '../types';
import {
  createJiraIssueUserPreferencesKey,
  createJiraTabCreateIssueUserPreferencesKey,
} from '../utils';

import { getWorkspace } from './get-repository-workspace';

export function* initCreateJiraIssueSaga(action: EnteredCodeReviewAction) {
  const { owner, slug } = action;
  const repositoryFullSlug = `${owner}/${slug}`;

  yield put(fetchCreateJiraIssuePreferences(repositoryFullSlug));
  yield put(fetchConnectedJiraSites());
}

export function* fetchConnectedJiraSitesSaga() {
  try {
    const workspace: User | Team = yield getWorkspace();
    const url = urls.api.internal.connectedSites(workspace.uuid);
    const request = authRequest(url);
    const response: Response = yield call(fetch, request);

    if (response.ok) {
      // @ts-ignore
      const data = yield response.json();
      yield put({
        type: FETCH_CONNECTED_JIRA_SITES.SUCCESS,
        payload: data.values,
      });
    } else {
      yield captureMessageForResponse(
        response,
        'Fetching Jira sites in PR failed'
      );
      yield put({
        type: FETCH_CONNECTED_JIRA_SITES.ERROR,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_CONNECTED_JIRA_SITES.ERROR,
    });
  }
}

export function* fetchJiraProjectSaga({
  payload,
}: Action<{ cloudId: string; projectId: string }>) {
  const { cloudId, projectId } = payload!;

  try {
    const workspace: User | Team = yield getWorkspace();
    const url = urls.api.internal.project(workspace.uuid, cloudId, projectId);
    const request = authRequest(url);
    const response: Response = yield call(fetch, request);

    if (response.ok) {
      // @ts-ignore
      const data = yield response.json();

      yield put({
        type: FETCH_JIRA_PROJECT.SUCCESS,
        payload: {
          [cloudId]: {
            project: data,
            fetchedStatus: LoadingStatus.Success,
          },
        },
      });
    } else {
      yield captureMessageForResponse(response, 'Fetching Jira project failed');
      yield put({
        type: FETCH_JIRA_PROJECT.ERROR,
        payload: {
          [cloudId]: {
            project: {},
            fetchedStatus: LoadingStatus.Failed,
          },
        },
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_JIRA_PROJECT.ERROR,
      payload: {
        [cloudId]: {
          project: {},
          fetchedStatus: LoadingStatus.Failed,
        },
      },
    });
  }
}

export function* fetchJiraProjectsForSiteSaga({
  payload,
}: Action<{ site: Site; projectFilter: string; commentId: number }>) {
  const { site, projectFilter, commentId } = payload!;
  const cloudId = site ? site.cloudId : '';

  try {
    const workspace: User | Team = yield getWorkspace();
    const url = urls.api.internal.projects(
      workspace.uuid,
      cloudId,
      projectFilter
    );
    const request = authRequest(url);
    const response: Response = yield call(fetch, request);

    if (response.status === 403) {
      yield put({
        type: FETCH_JIRA_PROJECTS_FOR_SITE.ERROR,
        payload: {
          [cloudId]: {
            list: [],
            fetchedStatus: LoadingStatus.Forbidden,
          },
        },
      });
    } else if (!response.ok) {
      yield captureMessageForResponse(
        response,
        'Fetching Jira projects for a site failed'
      );
      yield put({
        type: FETCH_JIRA_PROJECTS_FOR_SITE.ERROR,
        payload: {
          [cloudId]: {
            list: [],
            fetchedStatus: LoadingStatus.Failed,
          },
        },
      });
    } else {
      // @ts-ignore
      const data = yield response.json();
      yield put({
        type: FETCH_JIRA_PROJECTS_FOR_SITE.SUCCESS,
        payload: {
          [commentId]: {
            [cloudId]: {
              list: data.values,
              fetchedStatus: LoadingStatus.Success,
            },
          },
        },
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_JIRA_PROJECTS_FOR_SITE.ERROR,
      payload: {
        [cloudId]: {
          list: [],
          fetchedStatus: LoadingStatus.Failed,
        },
      },
    });
  }
}

export function* fetchDebouncedJiraProjectsForSiteSaga() {
  yield debounce(
    400,
    FETCH_JIRA_PROJECTS_FOR_SITE.REQUEST,
    fetchJiraProjectsForSiteSaga
  );
}

export function* fetchJiraIssueCreationMetadataSaga({
  payload,
}: Action<{ cloudId: string; projectId: string }>) {
  if (!payload) {
    return;
  }

  const { cloudId, projectId } = payload;

  try {
    const workspace: User | Team = yield getWorkspace();
    const url = urls.api.internal.issueCreationMetadata(
      workspace.uuid,
      cloudId,
      projectId
    );
    const request = authRequest(url);
    const response: Response = yield call(fetch, request);

    if (!response.ok) {
      const { status } = response;
      if (status === 403) {
        yield put({
          type: FETCH_JIRA_ISSUE_CREATION_METADATA.ERROR,
          payload: {
            cloudId,
            projectId,
            fetchedStatus: LoadingStatus.Forbidden,
          },
        });
      } else {
        yield captureMessageForResponse(
          response,
          'Fetching Jira issue creation metadata failed'
        );
        yield put({
          type: FETCH_JIRA_ISSUE_CREATION_METADATA.ERROR,
          payload: {
            cloudId,
            fetchedStatus: LoadingStatus.Failed,
            projectId,
          },
        });
      }
    } else {
      // @ts-ignore
      const data = yield response.json();
      // Filter "subtask" out
      const issueTypes = (data.issuetypes || []).filter(
        // @ts-ignore TODO: fix noImplicitAny error here
        ({ subtask }) => !subtask
      );
      yield put({
        type: FETCH_JIRA_ISSUE_CREATION_METADATA.SUCCESS,
        payload: {
          cloudId,
          projectId,
          issueTypes,
        },
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_JIRA_ISSUE_CREATION_METADATA.ERROR,
      payload: {
        cloudId,
        projectId,
        fetchedStatus: LoadingStatus.Failed,
      },
    });
  }
}

export function* updateJiraTabCreateIssueUserPreferencesSaga() {
  const currentUser: User = yield select(getCurrentUser);
  if (!currentUser) {
    return;
  }

  const repositoryUuid: string = yield select(getCurrentRepositoryUuid);
  const preferences: CreateJiraIssueState['preferences'] = yield select(
    getCreateJiraIssuePreferences
  );
  try {
    if (preferences.value) {
      yield call(
        prefs.set,
        currentUser.uuid,
        createJiraTabCreateIssueUserPreferencesKey(repositoryUuid),
        JSON.stringify(preferences.value)
      );
    }
  } catch (e) {
    Sentry.captureException(e);
  }
}

export function* fetchJiraTabCreateIssueUserPreferencesSaga() {
  const { status } = yield select(getRepositoryPageLoadingStatus);
  if (status !== LoadingStatus.Success) {
    yield take(LoadRepositoryPage.SUCCESS);
  }
  const currentUser: User = yield select(getCurrentUser);
  const repositoryUuid: string = yield select(getCurrentRepositoryUuid);

  try {
    if (currentUser) {
      // @ts-ignore
      const preferencesRaw = yield call(
        prefs.get,
        currentUser.uuid,
        createJiraTabCreateIssueUserPreferencesKey(repositoryUuid)
      );
      const preferences =
        preferencesRaw && preferencesRaw !== 'undefined'
          ? JSON.parse(preferencesRaw)
          : undefined;

      if (preferences) {
        yield put({
          type: FETCH_JIRA_TAB_CREATE_ISSUE_USER_PREFERENCE.SUCCESS,
          payload: preferences,
        });
      } else {
        yield put({
          type: FETCH_JIRA_TAB_CREATE_ISSUE_USER_PREFERENCE.ERROR,
        });
      }
    } else {
      // Put ERROR action for an anonymous user.
      yield put({
        type: FETCH_JIRA_TAB_CREATE_ISSUE_USER_PREFERENCE.ERROR,
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_JIRA_TAB_CREATE_ISSUE_USER_PREFERENCE.ERROR,
    });
    Sentry.captureException(e);
  }
}

export function* createJiraIssueSaga({
  payload,
}: Action<CreateJiraIssueSagaPayload>) {
  const repositoryFullSlug: string = yield select(getCurrentRepositoryFullSlug);

  if (!payload) {
    return;
  }

  const url = urls.api.internal.jiraIssues(repositoryFullSlug);

  try {
    const request = authRequest(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const response: Response = yield call(fetch, request, {
      method: 'POST',
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const createdIssue: JiraIssue = yield response.json();
      // Issue returned from the backend has only reference to the project,
      // whereas we need its key and name, so fill them in since we have them at hand
      const newIssue = {
        ...createdIssue,
        project: {
          ...createdIssue.project,
          key: payload.projectKey,
          name: payload.projectName,
        },
      };

      yield call(publishTrackEvent, {
        action: 'created',
        actionSubject: 'issue',
        actionSubjectId: newIssue.id,
        source: 'jiraTab',
      });

      // Order is important here: the flag requires the issue to be stored
      // in the Redux state, so we need to call the reducer first
      yield put({
        type: CREATE_JIRA_ISSUE.SUCCESS,
        payload: newIssue,
      });
      yield put(showFlagComponent('create-jira-issue-success'));

      yield put(changeIsCreateIssueDialogOpen(false));
      yield call(updateJiraTabCreateIssueUserPreferencesSaga);
      yield put(fetchJiraRelevantIssues());
    } else {
      yield captureMessageForResponse(response, 'Creating Jira issue failed');
      yield put({
        type: CREATE_JIRA_ISSUE.ERROR,
        payload: {
          failureReason: IssueCreationFailureReason.Unknown,
        },
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: CREATE_JIRA_ISSUE.ERROR,
      payload: {
        failureReason: IssueCreationFailureReason.Unknown,
      },
    });
  }
}

const supportedFields = ['issuetype', 'summary', 'project', 'reporter'];
const hasUnsupportedFields = (issueType: IssueType) => {
  if (issueType.fields) {
    const { fields } = issueType;

    return Object.keys(fields).some(fieldKey => {
      if (fields[fieldKey] && fields[fieldKey].required) {
        // Check if any of the required fields are not supported
        return !supportedFields.find(f => f === fieldKey);
      }
      return false;
    });
  }

  return false;
};

export function* handleUnsupportedFieldsErrorSaga({
  payload,
}: Action<{ issueType: IssueType }>) {
  const { issueType } = payload as {
    issueType: IssueType;
  };

  if (hasUnsupportedFields(issueType)) {
    yield put(
      setFormErrorState({
        failureReason: IssueCreationFailureReason.UnsupportedFields,
        status: LoadingStatus.Failed,
      })
    );
  } else {
    yield put(
      setFormErrorState({
        failureReason: IssueCreationFailureReason.None,
        status: LoadingStatus.Before,
      })
    );
  }
}

export function* handlePrUnsupportedFieldsErrorSaga({
  payload,
}: Action<{ issueType: IssueType; commentId: number }>) {
  const { issueType, commentId } = payload as {
    issueType: IssueType;
    commentId: number;
  };

  if (hasUnsupportedFields(issueType)) {
    yield put(
      setPrFormErrorState({
        commentId,
        failureReason: IssueCreationFailureReason.UnsupportedFields,
        status: LoadingStatus.Failed,
      })
    );
  } else {
    yield put(
      setPrFormErrorState({
        commentId,
        failureReason: IssueCreationFailureReason.None,
        status: LoadingStatus.Before,
      })
    );
  }
}

export function* createPrJiraIssueSaga({
  payload,
}: Action<CreatePrCommentIssuePayload & { pullRequestId: number }>) {
  const repositoryFullSlug: string = yield select(getCurrentRepositoryFullSlug);

  if (!payload) {
    return;
  }

  const { pullRequestId, ...requestPayload } = payload;

  try {
    const url = urls.api.internal.issues(repositoryFullSlug, pullRequestId);
    const request = authRequest(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestPayload),
    });
    const response: Response = yield call(fetch, request);

    if (response.ok) {
      // Hide the form after successful creation.
      yield put(
        onCreateJiraIssueFormChangeVisibility({
          commentId: payload.commentId,
          isVisible: false,
        })
      );

      // Update the Jira issues list to include the newly created issue
      // @ts-ignore
      const newIssue = yield response.json();
      yield put(updateJiraIssuesListAfterCreation(newIssue));

      yield put({
        type: CREATE_PR_JIRA_ISSUE.SUCCESS,
        payload: {
          commentId: payload.commentId,
        },
      });

      yield put(
        updateCreateJiraIssuePreferences({
          cloudId: requestPayload.cloudId,
          projectId: requestPayload.projectId,
          repositoryFullSlug,
        })
      );
    } else {
      yield captureMessageForResponse(response, 'Creating Jira issue failed');
      yield put({
        type: CREATE_PR_JIRA_ISSUE.ERROR,
        payload: {
          commentId: payload.commentId,
          failureReason: IssueCreationFailureReason.Unknown,
        },
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: CREATE_PR_JIRA_ISSUE.ERROR,
      payload: {
        commentId: payload.commentId,
        failureReason: IssueCreationFailureReason.Unknown,
      },
    });
  }
}

export function* fetchCreateJiraIssueOnboardingViewedSaga() {
  const user: User = yield select(getCurrentUser);
  if (!user || !user.uuid) {
    return;
  }

  try {
    // @ts-ignore
    const onboardingViewed = yield call(
      prefs.get,
      user.uuid,
      ONBOARDING_VIEWED_PREF_KEY
    );

    // If onboardingViewed = undefined, the preference hasn't set yet.
    // Which means the user has not seen the onboarding.
    yield put({
      type: FETCH_CREATE_JIRA_ISSUE_ONBOARDING_VIEWED.SUCCESS,
      payload: onboardingViewed || OnboardingViewed.Unseen,
    });
  } catch {
    Sentry.captureException(
      `Failed to get the ${ONBOARDING_VIEWED_PREF_KEY} preference`
    );
    // If fetching the preference fails for any reason, assume the user
    // has seen onboarding already. We don't want to show the onboarding more than once.
    yield put({
      type: FETCH_CREATE_JIRA_ISSUE_ONBOARDING_VIEWED.SUCCESS,
      payload: OnboardingViewed.Seen,
    });
  }
}

export function* updateCreateJiraIssueOnboardingViewedSaga({
  payload: onboardingViewed,
}: Action<OnboardingViewed>) {
  const user: User = yield select(getCurrentUser);
  if (!user || !user.uuid) {
    return;
  }

  try {
    yield call(
      prefs.set,
      user.uuid,
      ONBOARDING_VIEWED_PREF_KEY,
      onboardingViewed
    );
    yield put({
      type: UPDATE_CREATE_JIRA_ISSUE_ONBOARDING_VIEWED.SUCCESS,
      payload: onboardingViewed,
    });
  } catch (e) {
    Sentry.captureException(e);
    // If setting the preference fails for any reason, set viewed == SEEN.
    // We don't want to show the onboarding more than once.
    yield put({
      type: UPDATE_CREATE_JIRA_ISSUE_ONBOARDING_VIEWED.SUCCESS,
      payload: OnboardingViewed.Seen,
    });
  }
}

export function* fetchCreateJiraIssuePreferencesSaga({
  payload,
}: Action<{ repositoryFullSlug: string }>) {
  const user: User = yield select(getCurrentUser);
  if (!user || !user.uuid) {
    return;
  }

  const { repositoryFullSlug } = payload!;

  try {
    // @ts-ignore
    const preferencesRaw = yield call(
      prefs.get,
      user.uuid,
      createJiraIssueUserPreferencesKey(repositoryFullSlug)
    );
    const preferences = preferencesRaw ? JSON.parse(preferencesRaw) : undefined;

    yield put({
      type: FETCH_CREATE_JIRA_ISSUE_PREFERENCES.SUCCESS,
      payload: preferences,
    });

    if (preferences) {
      yield put(
        fetchJiraProject({
          cloudId: preferences.cloudId,
          projectId: preferences.projectId,
        })
      );
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_CREATE_JIRA_ISSUE_PREFERENCES.ERROR,
    });
  }
}

export function* updateCreateJiraIssuePreferencesSaga({
  payload,
}: Action<{
  cloudId: string;
  projectId: string;
  repositoryFullSlug: string;
}>) {
  const user: User = yield select(getCurrentUser);
  if (!user || !user.uuid) {
    return;
  }

  const { cloudId, projectId, repositoryFullSlug } = payload!;
  const preferences = {
    cloudId,
    projectId,
  };

  try {
    yield call(
      prefs.set,
      user.uuid,
      createJiraIssueUserPreferencesKey(repositoryFullSlug),
      JSON.stringify(preferences)
    );
    yield put({
      type: UPDATE_CREATE_JIRA_ISSUE_PREFERENCES.SUCCESS,
      payload: preferences,
    });
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: UPDATE_CREATE_JIRA_ISSUE_PREFERENCES.ERROR,
    });
  }
}
