import { fg } from '@atlaskit/platform-feature-flags';
import { sendPackageOperationalEvent } from '../../../common/utils';
import { Logger } from '../../../common/utils/logger';
/**
 * Gets a WebStorage item synchronously. This will NOT query user preferences before setting
 * the item, so it can be used to set strictly necessary
 */
export var getStorageItem = function getStorageItem(itemKey, storageType, browserStorage) {
  if (fg('platform_moonjelly_bsc_master_killswitch')) {
    // Get the item without package operational events
    // when master killswitch is enabled
    return browserStorage.getItem(itemKey);
  }
  var eventAttributes = {
    itemKey: itemKey,
    storageType: storageType
  };
  try {
    sendPackageOperationalEvent({
      action: 'usedAtlBrowserStorageGetItem',
      attributes: eventAttributes
    });
    return browserStorage.getItem(itemKey);
  } catch (e) {
    Logger.errorWithOperationalEvent({
      action: 'usedAtlBrowserStorageGetItemError',
      attributes: eventAttributes,
      message: "Failed to get ".concat(storageType, " item. ").concat(e.message || '')
    });
    return null;
  }
};