import { Deployment, Environment } from 'src/components/pipelines/models';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import {
  REMOVE_DEPLOYMENT_DATA,
  SET_DEPLOYMENT_PREVIEW,
} from '../actions/deployments';

export type PreviewState = {
  deployment: Deployment;
  environment: Environment;
};

export const initialState: PreviewState = {
  deployment: new Deployment(),
  environment: new Environment(),
};

export default createReducer(initialState, {
  [REMOVE_DEPLOYMENT_DATA]() {
    return initialState;
  },
  [SET_DEPLOYMENT_PREVIEW](state: PreviewState, action: Action<PreviewState>) {
    return { ...state, ...action.payload };
  },
});
