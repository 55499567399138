import {BaseFrameworkAdaptor} from '../../adaptors/framework/BaseFrameworkAdaptor';
import {BaseModule} from '../base/Module';
import {ModuleDefinitions} from '../base/Module';
import {FlagProvider} from './FlagProvider';
import {FlagSimpleXdmDefinitionBuilder} from './FlagSimpleXdmDefinitionBuilder';
import {Provider} from '../base/Provider';
import {SimpleXdmDefinitionBuilder} from '../../definitions/SimpleXdmDefinitionBuilder';

export module FlagModuleDefinitions {

    export const FLAG_MODULE_NAME = 'flag';

    export class Props implements ModuleDefinitions.Props {

        adaptor: BaseFrameworkAdaptor;
        flagProvider: FlagProvider;

        constructor(
            adaptor: BaseFrameworkAdaptor,
            flagProvider: FlagProvider) {
            this.adaptor = adaptor;
            this.flagProvider = flagProvider;
        }

    }

    export class State implements ModuleDefinitions.State {

        enabled: boolean;
        registered: boolean;
        flagProvider: FlagProvider;
        simpleXdmDefinitionBuilder: SimpleXdmDefinitionBuilder;

        constructor(flagProvider: FlagProvider) {
            this.enabled = true;
            this.registered = false;
            this.flagProvider = flagProvider;
            this.simpleXdmDefinitionBuilder = new FlagSimpleXdmDefinitionBuilder(flagProvider);
        }

    }

}

/**
 * This module provides flag related functionality.
 */
export class FlagModule extends BaseModule<FlagModuleDefinitions.Props, FlagModuleDefinitions.State> {

    constructor(props: FlagModuleDefinitions.Props) {
        super(props);
    }

    componentWillMount(): void {
        if (!this.props.flagProvider) {
            throw new Error('this.props.flagProvider is not defined');
        }
        this.setState(new FlagModuleDefinitions.State(this.props.flagProvider));
    }

    public getModuleRegistrationName(): string {
        return FlagModuleDefinitions.FLAG_MODULE_NAME;
    }

    public getSimpleXdmDefinitionBuilder() : SimpleXdmDefinitionBuilder {
      return this.state.simpleXdmDefinitionBuilder;
    }

    getProvider(): Provider {
        return this.props.flagProvider;
    }

    render(): JSX.Element | null {
        return null;
    }
}
