import React, { useMemo } from 'react';

import {
  Capabilities,
  Pipeline,
  Repository,
  Step,
} from 'src/components/pipelines/models';

import { ALLOWANCE_LIMIT } from '../../constants';
import { Action, DisabledState } from '../Action';
import MultiActionButton from '../MultiActionButton';
import SingleActionButton from '../SingleActionButton';
import { PipelineActionsWrapper } from '../styled';

export enum PipelineActionType {
  RerunSteps = 'RERUN_STEPS',
  RerunPipeline = 'RERUN_PIPELINE',
  Stop = 'STOP',
}

export interface Props {
  capabilities: Capabilities;
  repository: Repository;
  pipeline: Pipeline;
  steps: Step[];
  actionInProgress: boolean;
  onClick: (pipeline: Pipeline, actionKey: PipelineActionType) => void;
}

/* eslint @typescript-eslint/ban-types: "warn" */
const PipelineActions: React.FC<Props> = ({
  capabilities,
  repository,
  pipeline,
  steps,
  actionInProgress,
  onClick,
}) => {
  const hasUnfinishedRedeploy = useMemo(
    () =>
      steps.some(step =>
        step.stage
          ? step.stage.is_redeploy && step.stage.isUnfinished
          : step.isRedeployed && step.isRerunnable
      ),
    [steps]
  );

  const stepWithExpiredDeployment = useMemo(
    () =>
      steps.filter(
        step =>
          !!step.stage &&
          step.stage.is_deployment_stage &&
          step.stage.expired &&
          !step.stage.is_redeploy &&
          step.stage.isUnfinished
      )[0],
    [steps]
  );

  const disabled: { [key: string]: DisabledState } = useMemo(
    () => ({
      missingRepoWritePermissions: new DisabledState(
        !repository.canWrite,
        'You do not have write permissions.'
      ),
      fetchingCapabilities: new DisabledState(
        !capabilities.hasFetchedCapabilities,
        ''
      ),
      pipelinesDisabled: new DisabledState(
        !capabilities.pipelinesEnabled && capabilities.hasFetchedCapabilities,
        'Pipelines is disabled. Enable Pipelines to rerun the build.'
      ),
      noBuildMinutes: new DisabledState(
        capabilities.limited &&
          capabilities.allowancePercentageUsed >= ALLOWANCE_LIMIT,
        'Your account has run out of build minutes.'
      ),
      customPipelineWithVariables: new DisabledState(
        pipeline.has_variables,
        "This pipeline ran with temporary variable values so we can't rerun."
      ),
      hasUnfinishedRedeploy: new DisabledState(
        hasUnfinishedRedeploy,
        'To rerun failed steps all redeployments must be complete and successful.'
      ),
      hasExpiredStages: new DisabledState(
        !!stepWithExpiredDeployment,
        `Another pipeline has been deployed to the ${stepWithExpiredDeployment?.environmentName} environment.`
      ),
    }),
    [
      capabilities,
      repository.canWrite,
      pipeline.has_variables,
      hasUnfinishedRedeploy,
      stepWithExpiredDeployment,
    ]
  );
  const allActionsDisabled = useMemo(
    () =>
      disabled.missingRepoWritePermissions
        .or(disabled.fetchingCapabilities)
        .or(disabled.pipelinesDisabled)
        .or(disabled.noBuildMinutes),
    [disabled]
  );
  const canRerunFailedSteps = useMemo(
    () => pipeline.canRerunSteps && steps.length > 1,
    [pipeline.canRerunSteps, steps.length]
  );

  const actions: { [key in PipelineActionType]: Action } = useMemo(
    () => ({
      [PipelineActionType.RerunSteps]: {
        key: PipelineActionType.RerunSteps,
        name: pipeline.isStopped ? 'Rerun stopped steps' : 'Rerun failed steps',
        isVisible: canRerunFailedSteps,
        disabled: disabled.hasUnfinishedRedeploy.or(disabled.hasExpiredStages),
        onClick: () => onClick(pipeline, PipelineActionType.RerunSteps),
      },
      [PipelineActionType.RerunPipeline]: {
        key: PipelineActionType.RerunPipeline,
        name: !canRerunFailedSteps ? 'Rerun' : 'Rerun entire pipeline',
        isVisible: !pipeline.isRunning,
        disabled: disabled.customPipelineWithVariables,
        onClick: () => onClick(pipeline, PipelineActionType.RerunPipeline),
      },
      [PipelineActionType.Stop]: {
        key: PipelineActionType.Stop,
        name: 'Stop',
        isVisible: pipeline.isRunning,
        disabled: disabled.missingRepoWritePermissions
          .or(disabled.fetchingCapabilities)
          .or(disabled.pipelinesDisabled),
        onClick: () => onClick(pipeline, PipelineActionType.Stop),
      },
    }),
    [pipeline, disabled, canRerunFailedSteps, onClick]
  );

  if (steps.length === 0) {
    return <></>;
  }

  return (
    <PipelineActionsWrapper pipeline={pipeline}>
      {actions.RERUN_STEPS.isVisible || actions.RERUN_PIPELINE.isVisible ? (
        <MultiActionButton
          title="Rerun"
          isLoading={actionInProgress}
          disabled={allActionsDisabled}
          actions={[actions.RERUN_STEPS, actions.RERUN_PIPELINE]}
        />
      ) : actions.STOP.isVisible ? (
        <SingleActionButton
          isLoading={actionInProgress}
          action={actions.STOP}
        />
      ) : null}
    </PipelineActionsWrapper>
  );
};

export default React.memo(PipelineActions);
