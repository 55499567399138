import { createAsyncAction } from 'src/redux/actions';

export const ADD_RECENTLY_VIEWED_WORKSPACE =
  'workspace/ADD_RECENTLY_VIEWED_WORKSPACE';

export const GET_RECENTLY_VIEWED_WORKSPACES =
  'workspace/GET_RECENTLY_VIEWED_WORKSPACES';

export const FETCH_WORKSPACE_PLAN = createAsyncAction(
  'workspace/FETCH_WORKSPACE_PLAN'
);
