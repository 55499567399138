import { fg } from '@atlaskit/platform-feature-flags';
import { sendPackageOperationalEvent } from '../../../common/utils';
import { Logger } from '../../../common/utils/logger';
import { Status } from '../../../types';
import { BSCIndexedDB } from '../../indexed-db-service';
// SET STRICTLY NECESSARY COOKIE ANALOGUE

/**
 * Sets a WebStorage item synchronously. Since this doesn't wait to check against user consent preferences,
 * it should *only* be used for StrictlyNecessary categorized items, which are critical for application functionality.
 */
export var setStrictlyNecessaryStorageItem = function setStrictlyNecessaryStorageItem(itemKey, value, storageType, browserStorage) {
  if (fg('platform_moonjelly_bsc_master_killswitch')) {
    // Set the item without checking user preferences
    // when master killswitch is enabled
    browserStorage.setItem(itemKey, value);
    return Status.SUCCESS;
  }

  // Flag disabled, allow all items.
  var eventAttributes = {
    itemKey: itemKey,
    storageType: storageType
  };
  try {
    sendPackageOperationalEvent({
      action: 'usedAtlBrowserStorageSetStrictlyNecessaryItem',
      attributes: eventAttributes
    });
    // Store the item as a record in our IDB service to track that we've seen it before.
    // That way, when we can confirm/deny that this has been set by our service when we check again.
    // If it fails for some reason, it will be caught before actually setting the item
    // As setting Strictly Necessary requires synchronous operation, wait on the promise in an OG fashion
    BSCIndexedDB.setRecord(itemKey).then(function () {
      // NOTE: Should this just no-op/be removed? It already set and returned the item theoretically since this main func is sync
    }).catch(function (e) {
      // Rethrow to the above catch clause since this is async within a sync func
      throw new Error(e.message || '');
    });
    browserStorage.setItem(itemKey, value);
    return Status.SUCCESS;
  } catch (e) {
    Logger.errorWithOperationalEvent({
      action: 'usedAtlBrowserStorageSetStrictlyNecessaryItemError',
      attributes: eventAttributes,
      message: "Failed to set strictly necessary ".concat(storageType, " item. ").concat(e.message || '')
    });
    return Status.FAILED;
  }
};