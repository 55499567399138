import { ModelBase } from './ModelBase';

export const MAX_ENVIRONMENTS = 10;
export const MAX_ENVIRONMENTS_PREMIUM = 50;

export class Capabilities extends ModelBase {
  readonly hasFetchedCapabilities: boolean = false;
  readonly fetchCapabilitiesFailed: boolean = false;
  readonly fetchCapabilitiesError?: any = null;
  readonly pipelinesEnabled: boolean = false;
  readonly pipelinesEnabledError?: any = null;
  readonly allowancePercentageUsed: number = 0;
  readonly hasMinutesLeft: boolean = false;
  readonly limited: boolean = false;
  readonly isPremium: boolean = false;
  readonly isPaid: boolean = false;

  constructor(props: Partial<Capabilities> = {}) {
    super(props);
    Object.assign(this, props);
    Object.freeze(this);
  }

  get isPaidOrPremium(): boolean {
    return this.isPaid || this.isPremium;
  }

  get allowedEnvironments(): number {
    return this.isPaidOrPremium ? MAX_ENVIRONMENTS_PREMIUM : MAX_ENVIRONMENTS;
  }
}
