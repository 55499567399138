/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import * as Sentry from '@sentry/browser';
import { call, delay, put, take, race, select } from 'redux-saga/effects';

import { Action } from 'src/types/state';
import urls from 'src/urls/jira';
import authRequest from 'src/utils/fetch';
import { captureMessageForResponse } from 'src/utils/sentry';

import {
  FETCH_SITES_FOR_JIRA_MIGRATION,
  START_ISSUE_MIGRATION_TO_JIRA,
  POLL_ISSUE_MIGRATION_TO_JIRA,
  FETCH_ISSUE_MIGRATION_TO_JIRA_STATUS,
  FETCH_ISSUE_MIGRATION_TO_JIRA_LOGS,
  goToStep,
} from '../actions';
import { IssueMigrationCurrentStep } from '../constants';
import { getActiveStepIndex } from '../selectors/jira-migration-selectors';
import { AvailableSite, IssueMigrationToJiraProgress } from '../types';

import { fetchAvailableJiraSitesSaga } from './fetch-available-sites-saga';

const ISSUE_MIGRATION_STATUS_POLL_DELAY = 5000;

export function* fetchSitesForJiraMigrationSaga() {
  try {
    const availableSites: AvailableSite[] = yield call(
      fetchAvailableJiraSitesSaga
    );

    if (availableSites === undefined) {
      yield put({
        type: FETCH_SITES_FOR_JIRA_MIGRATION.ERROR,
      });
      return;
    } else {
      yield put({
        type: FETCH_SITES_FOR_JIRA_MIGRATION.SUCCESS,
        payload: availableSites,
      });
    }

    return;
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_SITES_FOR_JIRA_MIGRATION.ERROR,
    });
  }
}

export function* startIssueMigrationToJiraSaga({
  payload,
}: Action<{
  repositoryFullSlug: string;
  cloudId: string;
  projectKey: string;
  projectName: string;
}>) {
  if (!payload) {
    return;
  }

  const selectedStepIndex: number = yield select(getActiveStepIndex);
  const { repositoryFullSlug, cloudId, projectKey, projectName } = payload;

  try {
    const url = urls.api.internal.startIssueMigrationToJira(
      repositoryFullSlug,
      cloudId,
      projectKey,
      projectName
    );
    const request = authRequest(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const response: Response = yield call(fetch, request);

    if (response.ok) {
      yield put({
        type: START_ISSUE_MIGRATION_TO_JIRA.SUCCESS,
      });
      yield put(goToStep(selectedStepIndex + 1));
    } else {
      yield captureMessageForResponse(
        response,
        'Failed to start migrating issues to Jira'
      );

      yield put({
        type: START_ISSUE_MIGRATION_TO_JIRA.ERROR,
        payload: response.status,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: START_ISSUE_MIGRATION_TO_JIRA.ERROR,
    });
  }
}

export function* pollIssueMigrationToJiraProgress({
  payload,
}: Action<{ repositoryFullSlug: string }>) {
  if (!payload) {
    return;
  }

  yield put({
    type: FETCH_ISSUE_MIGRATION_TO_JIRA_STATUS.REQUEST,
  });

  const { repositoryFullSlug } = payload;

  try {
    while (true) {
      const url =
        urls.api.internal.issueMigrationToJiraStatus(repositoryFullSlug);
      const request = authRequest(url);
      const response: Response = yield call(fetch, request);

      if (response.ok) {
        const data: IssueMigrationToJiraProgress = yield response.json();
        yield put({
          type: FETCH_ISSUE_MIGRATION_TO_JIRA_STATUS.SUCCESS,
          payload: data,
        });

        // Stop polling when the migration is finished/failed.
        if (
          data.currentStep === IssueMigrationCurrentStep.Finished ||
          data.currentStep === IssueMigrationCurrentStep.Failed
        ) {
          yield put({
            type: POLL_ISSUE_MIGRATION_TO_JIRA.STOP,
          });
        }
      } else {
        yield captureMessageForResponse(
          response,
          'Failed to fetch issue migration Jira status'
        );
        yield put({
          type: FETCH_ISSUE_MIGRATION_TO_JIRA_STATUS.ERROR,
        });
      }

      yield delay(ISSUE_MIGRATION_STATUS_POLL_DELAY);
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_ISSUE_MIGRATION_TO_JIRA_STATUS.ERROR,
    });
  }
}

export function* issueMigrationToJiraStatusSaga() {
  while (true) {
    const action: Action<{
      repositoryFullSlug: string;
    }> = yield take(POLL_ISSUE_MIGRATION_TO_JIRA.START);

    yield race([
      call(pollIssueMigrationToJiraProgress, action),
      take(POLL_ISSUE_MIGRATION_TO_JIRA.STOP),
    ]);
  }
}

export function* fetchIssueMigrationToJiraLogsSaga({
  payload,
}: Action<{ repositoryFullSlug: string }>) {
  if (!payload) {
    return;
  }

  const { repositoryFullSlug } = payload;

  try {
    const url = urls.api.internal.issueMigrationToJiraLogs(repositoryFullSlug);
    const request = authRequest(url);
    const response: Response = yield call(fetch, request);

    if (response.ok) {
      const logs: string = yield response.text();
      yield put({
        type: FETCH_ISSUE_MIGRATION_TO_JIRA_LOGS.SUCCESS,
        payload: logs,
      });
    } else {
      yield captureMessageForResponse(
        response,
        'Failed to fetch logs for the migration'
      );

      yield put({
        type: FETCH_ISSUE_MIGRATION_TO_JIRA_LOGS.ERROR,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_ISSUE_MIGRATION_TO_JIRA_LOGS.ERROR,
    });
  }
}
