/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { gridSize } from '@atlaskit/theme';
// Required to override first group top margin
export const GroupHeadingNoTopMargin = styled.div`
  > *:first-child {
    margin-top: 0;
  }
`;

export const MenuItemWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: ${gridSize}px;
  }
`;

export const BetaLozenge = styled.div`
  display: flex;
  margin-left: 3px;
`;
