import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import {
  WEBSOCKET_SUBSCRIBE,
  WEBSOCKET_UNSUBSCRIBE,
} from '../actions/pipelines';

export type WebsocketType = { criteria?: {}; event: string };

export const initialState: WebsocketType[] = [];

export default createReducer(initialState, {
  [WEBSOCKET_SUBSCRIBE](state: WebsocketType[], action: Action<WebsocketType>) {
    return state.concat(action.payload || []);
  },
  [WEBSOCKET_UNSUBSCRIBE](
    state: WebsocketType[],
    action: Action<WebsocketType>
  ) {
    return state.filter(subscription => {
      // it there is no criteria remove all event subscriptions
      if (!action.payload?.criteria) {
        return subscription.event !== action.payload?.event;
      }
      return JSON.stringify(subscription) !== JSON.stringify(action.payload);
    });
  },
});
