import { createSelector } from 'reselect';

import { Action } from 'src/types/state';

import { prefixed } from './actions';
import { getPullRequestSlice } from './selectors';

// Action Types
export const COLLAPSE_CARD = prefixed('COLLAPSE_CARD');
export const EXPAND_CARD = prefixed('EXPAND_CARD');
export const COLLAPSE_ALL_CARDS = prefixed('COLLAPSE_ALL_CARDS');
export const EXPAND_CARDS = prefixed('EXPAND_CARDS');

// Action Creators
export const collapseCard = (key: string) => ({
  type: COLLAPSE_CARD,
  payload: key,
});
export const expandCard = (key: string) => ({
  type: EXPAND_CARD,
  payload: key,
});
export const collapseAllCards = () => ({ type: COLLAPSE_ALL_CARDS });
export const expandCards = (payload: string[]) => ({
  type: EXPAND_CARDS,
  payload,
});

// Initial State (also used as type)
export const sidebarCardCollapsedInitialState: { [key: string]: boolean } = {};

// Selectors
export const getSidebarCardsCollapsed = createSelector(
  getPullRequestSlice,
  state => state?.sidebarCardCollapsed
);

export const getIsCardCollapsed = createSelector(
  getSidebarCardsCollapsed,
  (_: any, key: string) => key,
  (state, key) => state[key]
);

// Reducer
export default (
  state: typeof sidebarCardCollapsedInitialState = sidebarCardCollapsedInitialState,
  action: Action
) => {
  const newStateCollapse: typeof sidebarCardCollapsedInitialState = {};
  const newStateExpand: typeof sidebarCardCollapsedInitialState = {};

  switch (action.type) {
    case COLLAPSE_CARD:
      return {
        ...state,
        [action.payload]: true,
      };
    case EXPAND_CARD:
      return {
        ...state,
        [action.payload]: false,
      };
    case COLLAPSE_ALL_CARDS:
      Object.keys(state).forEach(key => {
        newStateCollapse[key] = true;
      });
      return newStateCollapse;
    case EXPAND_CARDS:
      action.payload.forEach((key: string) => {
        newStateExpand[key] = false;
      });
      return {
        ...state,
        ...newStateExpand,
      };
    default:
      return state;
  }
};
