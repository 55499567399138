/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { all, fork, take, put, select, delay, call } from 'redux-saga/effects';

import { HORIZONTAL_GLOBAL_NAV_HEIGHT } from '@atlaskit/atlassian-navigation';
import {
  getFilepathFromPermalink,
  COMMENT_PERMALINK_MATCHER,
  SIDEBAR_EXPANDER_HASH_MATCHER,
  LINE_PERMALINK_MATCHER,
  SIDE_BY_SIDE_LINE_PERMALINK_MATCHER,
  FILEPATH_PERMALINK_MATCHER,
  getPermalink,
  createDiffPermalink,
  updateHashInURL,
} from '@atlassian/bitkit-diff/exports/diff-permalink';

import { PullRequest } from 'src/components/types';
import { LoadingStatus } from 'src/constants/loading-status';
import {
  COMMENT_PERMALINK_SCROLL_OFFSET,
  LINE_PERMALINK_SCROLL_OFFSET,
  SIDEBAR_EXPANDER_SCROLL_OFFSET,
  PERMALINK_DELAY,
} from 'src/constants/permalink-scroll';
import scrollTo, { ScrollAction } from 'src/redux/global/actions/scroll-to';
import updateMobileHeaderState from 'src/redux/global/actions/update-mobile-header-state';
import {
  ACTIVE_DIFF_PERMALINK,
  PERMALINK_HASH_CHANGE,
  INITIAL_DIFFS_RENDERED,
  FETCH_COMMENTS,
  FETCH_DIFF,
  OPEN_OUTDATED_COMMENTS_DIALOG,
  OPEN_NONRENDERED_DIFF_COMMENTS_DIALOG,
  TOGGLE_DIFF_EXPANSION,
  toggleSideBySideMode,
  LOAD_DIFFSTAT,
  DIFFS_HAVE_RENDERED,
  HIGHLIGHT_ACTIVE_TREE_ITEM,
  HIDDEN_FILE_DIALOG,
  scrollToAnchor,
  HIGHLIGHT_COMMENT,
  toggleDiffComments,
  DiffStateMap,
} from 'src/redux/pull-request/actions';
import {
  getDiffsExpansions,
  getActiveDiff,
  getAllDiffFiles,
  getIsOutdatedDialogOpen,
  getIsNonRenderedDiffCommentsDialogOpen,
  getDiffFiles,
  findFileInDiffFiles,
  getIsSingleFileModeActive,
  getSingleFileModeActiveDiff,
  getHiddenDiffComments,
  getCurrentPullRequest,
  getDiffStat,
  getPullRequestDiff,
} from 'src/redux/pull-request/selectors';
import history from 'src/router/history';
import {
  STICKY_FILE_HEIGHT,
  calculateStickyHeaderOffset,
} from 'src/sections/repository/sections/pull-request/components/utils/calculate-header-offset';
import {
  getCommentsMetaData,
  CommentsMetaData,
  getCommentsLoadingState,
} from 'src/selectors/conversation-selectors';
import { getFileTreeHrefs } from 'src/selectors/file-tree-selectors';
import { Diff } from 'src/types/pull-request';

// If trying to scroll to a line in a file that is different to the current
// single-file-mode file, switch to the correct file first and wait for its
// anchor to render. This is a no-op if single file mode is not active.
export function* switchSingleFileModeFileBeforeScrolling(filePath: string) {
  const singleFileModeActiveDiff: string = yield select(
    getSingleFileModeActiveDiff
  );
  if (singleFileModeActiveDiff && filePath !== singleFileModeActiveDiff) {
    yield put({ type: HIGHLIGHT_ACTIVE_TREE_ITEM, payload: filePath });
    yield take(DIFFS_HAVE_RENDERED);
    yield delay(PERMALINK_DELAY);
  }
}

export const scrollPastStickyHeaders = (
  permalink: string,
  isPullRequestPage: boolean,
  additionalOffset?: number
) => {
  const stickyHeaderOffset = calculateStickyHeaderOffset(isPullRequestPage);
  let offsetToApply =
    stickyHeaderOffset + STICKY_FILE_HEIGHT + HORIZONTAL_GLOBAL_NAV_HEIGHT;

  if (LINE_PERMALINK_MATCHER.test(permalink)) {
    offsetToApply += LINE_PERMALINK_SCROLL_OFFSET;
  } else if (COMMENT_PERMALINK_MATCHER.test(permalink)) {
    offsetToApply += COMMENT_PERMALINK_SCROLL_OFFSET;
  } else if (SIDEBAR_EXPANDER_HASH_MATCHER.test(permalink)) {
    offsetToApply = SIDEBAR_EXPANDER_SCROLL_OFFSET;
  }
  if (additionalOffset) {
    offsetToApply += additionalOffset;
  }
  /**
   * This is a scroll behavior function that takes the numbers provided
   * by smooth-scroll-into-view-if-needed library and performs a custom scroll.
   * @param {*} actions See https://www.npmjs.com/package/scroll-into-view-if-needed#function
   */
  const customScrollBehavior = (actions: ScrollAction) => {
    // @ts-ignore TODO: fix noImplicitAny error here
    actions.forEach(({ el, top }) => {
      if (top - offsetToApply >= 0) {
        el.scrollTop = top - offsetToApply;
        offsetToApply = 0;
      } else {
        offsetToApply = offsetToApply - top;
        el.scrollTop = 0;
      }
    });
  };

  return customScrollBehavior;
};

function* openPermalinksFile(filepath: string | undefined) {
  const expansions: DiffStateMap = yield select(getDiffsExpansions);

  if (filepath && expansions[filepath] !== true) {
    yield put({
      type: TOGGLE_DIFF_EXPANSION,
      payload: {
        filepath,
        isOpening: true,
      },
    });
  }
}

// @ts-ignore TODO: fix noImplicitAny error here
export function* scrollToFileThenToPermalink(
  targetId: string,
  permalink: string,
  additionalOffset = 0
) {
  if (SIDE_BY_SIDE_LINE_PERMALINK_MATCHER.test(permalink)) {
    yield put(
      toggleSideBySideMode({
        filePath: getFilepathFromPermalink(permalink),
        isSideBySide: true,
      })
    );
  }

  // If the permalink exists in the DOM then the diff has rendered
  // so no need to scroll to the file first.
  const permalinkElement = document.getElementById(permalink);

  // detect whether we are on the pull request page for additional sticky header offset
  const currentPullRequest: PullRequest | undefined = yield select(
    getCurrentPullRequest
  );
  const isPullRequestPage = currentPullRequest != null;

  if (permalinkElement) {
    yield put(
      scrollTo({
        targetId: permalink,
        customBehavior: scrollPastStickyHeaders(
          permalink,
          isPullRequestPage,
          additionalOffset
        ),
      })
    );
  } else {
    // delay for file anchors to render
    yield delay(PERMALINK_DELAY);
    yield put(scrollTo({ targetId }));
    // adjust for rendering delays
    yield delay(PERMALINK_DELAY);
    yield put(
      scrollTo({
        targetId: permalink,
        customBehavior: scrollPastStickyHeaders(
          permalink,
          isPullRequestPage,
          additionalOffset
        ),
      })
    );
  }
}

export function* scrollToLine() {
  const permalink = getPermalink() || '';

  if (!permalink || !LINE_PERMALINK_MATCHER.test(permalink)) {
    return;
  }
  const filepath = getFilepathFromPermalink(permalink) || '';
  if (filepath) {
    yield call(
      switchSingleFileModeFileBeforeScrolling,
      createDiffPermalink(filepath)
    );
    yield call(openPermalinksFile, filepath);
  }

  const targetId = permalink && createDiffPermalink(filepath);
  if (targetId) {
    yield call(scrollToFileThenToPermalink, targetId, permalink);
  }
}

// @ts-ignore TODO: fix noImplicitAny error here
export function* scrollToFile(targetId) {
  // delay for file anchors to render
  yield delay(PERMALINK_DELAY);
  yield put(scrollTo({ targetId }));
}

export function* scrollToFilepath() {
  const permalink = getPermalink() || '';

  if (!permalink || !FILEPATH_PERMALINK_MATCHER.test(permalink)) {
    return;
  }

  yield call(switchSingleFileModeFileBeforeScrolling, permalink);
  yield call(openPermalinksFile, permalink.replace(/chg-/, ''));
  yield call(scrollToFile, permalink);
}

export function* scrollToOutdatedComment(filepath: string) {
  yield put({ type: OPEN_OUTDATED_COMMENTS_DIALOG, payload: filepath });
}

export function* scrollToCommentOnNonRenderedFile(filepath: string) {
  yield put({ type: OPEN_NONRENDERED_DIFF_COMMENTS_DIALOG, payload: filepath });
}

const isDiffTabSelected = () => {
  const segments = location.pathname.split('/');
  // Second pop is needed in case there is a trailing slash
  return (segments.pop() || segments.pop()) === 'diff';
};

export function* scrollToComment(): Generator {
  const isThread = (getPermalink() || '').includes('thread');
  const permalink = (getPermalink() || '').replace('thread-', '');

  if (!permalink || !COMMENT_PERMALINK_MATCHER.test(permalink)) {
    return;
  }

  // scroll to comment in permalink
  yield put({
    type: HIGHLIGHT_COMMENT,
    payload: {
      commentId: Number(permalink.replace(COMMENT_PERMALINK_MATCHER, '')),
      highlightThread: isThread,
    },
  });

  const allDiffFiles = (yield select(getAllDiffFiles)) as Diff[];
  const diffFiles = (yield select(getDiffFiles)) as Diff[];
  const commentsMetaData = (yield select(
    getCommentsMetaData
  )) as CommentsMetaData[];
  const hiddenDiffComments = (yield select(getHiddenDiffComments)) as string[];

  const isOutdatedCommentsDialogOpen = yield select(getIsOutdatedDialogOpen);
  const isNonRenderedDiffCommentsDialogOpen = yield select(
    getIsNonRenderedDiffCommentsDialogOpen
  );

  const matchingCommentForPermalink =
    commentsMetaData &&
    commentsMetaData.filter(
      // @ts-ignore TODO: fix noImplicitAny error here
      commentMetaData => commentMetaData.permalink === permalink
    )[0];

  const offsetForResolutionHeader =
    matchingCommentForPermalink && matchingCommentForPermalink.isResolved
      ? 50
      : 0;

  const isFilePresentButNotRendered =
    matchingCommentForPermalink &&
    matchingCommentForPermalink.filepath &&
    findFileInDiffFiles(allDiffFiles, matchingCommentForPermalink.filepath) &&
    !findFileInDiffFiles(diffFiles, matchingCommentForPermalink.filepath);

  const isCommentHidden =
    matchingCommentForPermalink &&
    matchingCommentForPermalink.filepath &&
    hiddenDiffComments.includes(matchingCommentForPermalink.filepath) &&
    findFileInDiffFiles(diffFiles, matchingCommentForPermalink.filepath);

  const diffTabSelected = isDiffTabSelected();
  const overviewTabSelected = !diffTabSelected;

  const isCommentInOutdatedFile =
    matchingCommentForPermalink &&
    matchingCommentForPermalink.filepath &&
    !findFileInDiffFiles(allDiffFiles, matchingCommentForPermalink.filepath);

  const shouldShowOutdatedCommentsDialog =
    // since diff files aren't loaded in redux on overview tab, comments can be mistakenly identified as outdated
    !overviewTabSelected &&
    ((matchingCommentForPermalink && matchingCommentForPermalink.isOutdated) ||
      isCommentInOutdatedFile);

  // global comment
  let targetId = permalink;

  if (matchingCommentForPermalink && matchingCommentForPermalink.filepath) {
    // inline or file-level comment
    targetId = createDiffPermalink(matchingCommentForPermalink.filepath);
  }

  // If the file is in the full set of diff files but not in the rendered set,
  // open a modal to show the comment(s) since there's nothing to scroll to
  if (isFilePresentButNotRendered) {
    const singleFileModeActiveDiff = yield select(getSingleFileModeActiveDiff);
    if (singleFileModeActiveDiff && singleFileModeActiveDiff !== targetId) {
      yield call(switchSingleFileModeFileBeforeScrolling, targetId);
      yield fork(scrollToComment); // scrollToComment
    } else if (!isNonRenderedDiffCommentsDialogOpen) {
      yield fork(
        scrollToCommentOnNonRenderedFile,
        matchingCommentForPermalink.filepath
      );
    }
  } else if (isCommentHidden) {
    yield put(toggleDiffComments(matchingCommentForPermalink.filepath));
    yield fork(scrollToComment);
  } else if (shouldShowOutdatedCommentsDialog) {
    // If the comment is outdated, open that modal otherwise scroll to the file
    // & then the comment in it.
    if (!isOutdatedCommentsDialogOpen) {
      // Only scroll the page & open the modal dialog if it is not already open.
      // This helps prevent the UI from moving all over the place if a user clicks
      // on a comment permalink inside the outdated comments modal dialog.
      if (!isCommentInOutdatedFile) {
        // If the comment is in an outdated file, don't scroll to it since
        // it isn't in shown in the diff set
        yield call(scrollToFile, targetId);
      }
      yield fork(scrollToOutdatedComment, matchingCommentForPermalink.filepath);
    }
  } else {
    if (matchingCommentForPermalink && matchingCommentForPermalink.filepath) {
      yield call(openPermalinksFile, matchingCommentForPermalink.filepath);
    }
    yield fork(
      scrollToFileThenToPermalink,
      targetId,
      permalink,
      offsetForResolutionHeader
    );
  }
}

export function* scrollToGenericPermalink() {
  const permalink = getPermalink() || '';

  if (
    !permalink ||
    LINE_PERMALINK_MATCHER.test(permalink) ||
    COMMENT_PERMALINK_MATCHER.test(permalink) ||
    FILEPATH_PERMALINK_MATCHER.test(permalink)
  ) {
    return;
  }

  // delay for anchors to render
  yield delay(PERMALINK_DELAY);
  yield put(scrollTo({ targetId: permalink }));
}

export function* scrollBasedOnPermalinkType(permalink: string) {
  const isDiffPreviouslyLoaded =
    ((yield select(getDiffStat)) as boolean) &&
    ((yield select(getPullRequestDiff)) as boolean);

  const commentsMetaData = (yield select(
    getCommentsMetaData
  )) as CommentsMetaData[];
  const commentOnDiffTab = commentsMetaData.some(
    c => c.permalink === permalink && c.filepath
  );

  const diffTabSelected = isDiffTabSelected();

  // Wait for the diff to render before scrolling from a comment tab redirect
  if (commentOnDiffTab && !isDiffPreviouslyLoaded && diffTabSelected) {
    yield all([
      take(INITIAL_DIFFS_RENDERED),
      take(FETCH_DIFF.SUCCESS),
      take(LOAD_DIFFSTAT.SUCCESS),
    ]);
  }

  if (LINE_PERMALINK_MATCHER.test(permalink)) {
    yield call(scrollToLine);
  } else if (COMMENT_PERMALINK_MATCHER.test(permalink)) {
    yield call(scrollToComment);
  } else if (FILEPATH_PERMALINK_MATCHER.test(permalink)) {
    yield call(scrollToFilepath);
  } else {
    yield call(scrollToGenericPermalink);
  }
}

export function* pullRequestCommentPermalinkScrollerSaga() {
  const commentsLoadingStatus = (yield select(
    getCommentsLoadingState
  )) as LoadingStatus;

  const diffTabSelected = isDiffTabSelected();

  const permalink = (getPermalink() || '').replace('thread-', '');

  const isDiffPreviouslyLoaded =
    ((yield select(getDiffStat)) as boolean) &&
    ((yield select(getPullRequestDiff)) as boolean);

  const commentsMetaData = (yield select(
    getCommentsMetaData
  )) as CommentsMetaData[];
  const commentOnDiffTab = commentsMetaData.some(
    c => c.permalink === permalink && c.filepath
  );

  // Wait for the diff to render before scrolling to a permalink if the comment could be in a diff
  if (diffTabSelected || (commentOnDiffTab && !isDiffPreviouslyLoaded)) {
    yield all([
      take(INITIAL_DIFFS_RENDERED),
      take(FETCH_DIFF.SUCCESS),
      take(LOAD_DIFFSTAT.SUCCESS),
    ]);
  }

  // Wait for comments fetch to complete if it is in progress or hasn't started yet
  if (
    commentsLoadingStatus === LoadingStatus.Fetching ||
    commentsLoadingStatus === LoadingStatus.Before
  ) {
    yield take(FETCH_COMMENTS.SUCCESS);
  }

  yield fork(scrollToComment);
}

export function* commitCommentPermalinkScrollerSaga() {
  // pullRequestCommentPermalinkScrollerSaga will handle scrolling to comments on the PR page
  const isPullRequestPage = /^\/(.*)\/(.*)\/pull-requests\/(\d+)(\/.*)?/.test(
    location.pathname
  );

  if (isPullRequestPage) {
    return;
  }

  yield all([
    take(INITIAL_DIFFS_RENDERED),
    take(FETCH_COMMENTS.SUCCESS),
    take(FETCH_DIFF.SUCCESS),
    take(LOAD_DIFFSTAT.SUCCESS),
  ]);
  yield fork(scrollToComment);
}

export function* permalinkClickedScrollerSaga() {
  // detect whether we are on the pull request page for additional sticky header offset
  const currentPullRequest: PullRequest | undefined = yield select(
    getCurrentPullRequest
  );
  const isPullRequestPage = currentPullRequest != null;

  while (true) {
    const { payload: activePermalink } = yield take(ACTIVE_DIFF_PERMALINK);
    yield put(
      scrollTo({
        targetId: activePermalink,
        customBehavior: scrollPastStickyHeaders(
          activePermalink,
          isPullRequestPage
        ),
      })
    );
    updateHashInURL(activePermalink, history);
  }
}

export function* permalinkChangedScrollerSaga() {
  while (true) {
    const { payload: permalink } = yield take(PERMALINK_HASH_CHANGE);

    yield call(scrollBasedOnPermalinkType, permalink);
  }
}

// @ts-ignore TODO: fix noImplicitAny error here
export function* fileScrollerSaga(action) {
  const { payload: targetId, skipSagaScroll } = action;
  const isSingleFileModeActive: boolean = yield select(
    getIsSingleFileModeActive
  );
  const isPullRequestPage = /^\/(.*)\/(.*)\/pull-requests\/(\d+)(\/.*)?/.test(
    location.pathname
  );

  // Scroll to the root instead of the file anchor in IA SFM to avoid the compact
  // header transitioning sporadically between files
  const shouldScrollToRoot = isSingleFileModeActive && isPullRequestPage;

  if (isSingleFileModeActive && !shouldScrollToRoot) {
    // When in single file mode, clicking a new file in the file tree should cause
    // the page to scroll to the top of the new file. Since scrolling requres the
    // correct filepath-based anchor element to be rendered on the page, we need to
    // wait for the diff to render before starting scrolling. If scrolling to the top,
    // we don't need to wait for the diff to render.

    yield take(DIFFS_HAVE_RENDERED);
  }

  if (shouldScrollToRoot) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  } else if (!skipSagaScroll) {
    yield call(scrollToFile, targetId);
  }
  updateHashInURL(targetId, history);
}

// @ts-ignore TODO: fix noImplicitAny error here
export function* activeFileCollapsedScrollerSaga(action) {
  const activeDiff: string = yield select(getActiveDiff);
  const { payload } = action;
  const filePath = payload.filepath;
  const targetId = createDiffPermalink(filePath);
  if (activeDiff === targetId) {
    yield put(scrollTo({ targetId }));
  }
}

// scrolls to anchor and handles header state
export function* scrollWithDelay(anchorId: string) {
  yield call(switchSingleFileModeFileBeforeScrolling, anchorId);
  yield delay(PERMALINK_DELAY);
  yield put(updateMobileHeaderState('none'));
  yield put(scrollToAnchor(anchorId));
}

// This is for clicking on a file in the file tree that's currently hidden due
// to comment filtering. If the user confirms they want to close the filters,
// we want to scroll to the file, but we need to add a delay while we wait for
// the file to be rendered again.
export function* scrollToHiddenFile() {
  while (true) {
    const { payload: anchor } = yield take(HIDDEN_FILE_DIALOG.CONFIRM);
    yield call(scrollWithDelay, anchor);
  }
}

// If user is in single file mode, we want to scroll to the first
// file that matches the applied filter and sort order
// If _not_ in single file mode we don't need to scroll to the first
// file when filter or sort order changes
export function* scrollToFilteredSingleFile() {
  const isSingleFileModeActive: boolean = yield select(
    getIsSingleFileModeActive
  );
  // getFileTreeHrefs will return list of hrefs that's already filtered/sorted
  const fileHrefs: string[] = yield select(getFileTreeHrefs);
  const activeDiff: string = yield select(getActiveDiff);

  if (!isSingleFileModeActive || !fileHrefs.length) {
    return;
  }

  const newAnchor = fileHrefs[0].replace(/#/, '');

  // don't scroll to file if it's already being viewed
  if (activeDiff !== newAnchor) {
    yield call(scrollWithDelay, newAnchor);
  }
}
