import { unsupportedInline as unsupportedInlineFactory } from '../../next-schema/generated/nodeTypes';
export var unsupportedInline = unsupportedInlineFactory({
  parseDOM: [{
    tag: '[data-node-type="unsupportedInline"]',
    getAttrs: function getAttrs(dom) {
      return {
        originalValue: JSON.parse(dom.getAttribute('data-original-value') || '{}')
      };
    }
  }],
  toDOM: function toDOM(node) {
    var attrs = {
      'data-node-type': 'unsupportedInline',
      'data-original-value': JSON.stringify(node.attrs.originalValue)
    };
    return ['span', attrs, 'Unsupported content'];
  }
});