import isDate from 'date-fns/isDate';
import parseISO from 'date-fns/parseISO';

/**
 * A value type that can be converted into a `Date` object.
 */
export type DateLike = Date | string | number;

/**
 * Convert a number, ISO string, or Date into a `Date` object.
 */
export function toDate(dateLike: DateLike | null | undefined): Date {
  if (dateLike === null || dateLike === undefined || isDate(dateLike)) {
    // Return as is
    return dateLike as Date;
  } else if (typeof dateLike === 'string') {
    // Parse as ISO date string by default.
    // To support more formats, append them here.
    return parseISO(dateLike);
  } else {
    return new Date(dateLike);
  }
}
