/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { all, call } from 'redux-saga/effects';

import { allowanceSaga } from './allowance';
import artifactsSaga from './artifacts';
import cachesSaga from './caches';
import jsdChecksSaga from './jsd-checks';
import { logSaga } from './log';
import metricsSaga from './metrics';
import pipelinesListSaga from './pipelines';
import pipelinesInstallerSaga from './pipelines-installer';
import reportsSaga from './reports';
import repositorySaga from './repository';
import schedulesSaga from './schedules';
import { stepsSaga } from './steps';
import testReportsSaga from './test-reports';
import variablesSaga from './variables';
import webSocketSaga from './websocket';

export function* pipelinesSaga(): Generator {
  yield all([
    call(allowanceSaga),
    call(artifactsSaga),
    call(cachesSaga),
    call(jsdChecksSaga),
    call(logSaga),
    call(metricsSaga),
    call(pipelinesListSaga),
    call(pipelinesInstallerSaga),
    call(reportsSaga),
    call(repositorySaga),
    call(schedulesSaga),
    call(stepsSaga),
    call(testReportsSaga),
    call(variablesSaga),
    call(webSocketSaga),
  ]);
}
