import { B500, N0, N20, N30, N50, N500, N60, N800, N90 } from '@atlaskit/theme/colors';
export var bgColor = "var(--ds-surface-overlay, ".concat(N0, ")");
export var headerBgColor = "var(--ds-background-brand-bold, ".concat(B500, ")");
export var teamHeaderBgColor = "var(--ds-background-neutral, ".concat(N50, ")");
export var headerBgColorDisabledUser = "var(--ds-background-disabled, ".concat(N30, ")");
export var headerTextColor = "var(--ds-text-inverse, ".concat(N0, ")");
export var headerTextColorInactive = "var(--ds-text, ".concat(N800, ")");
export var appLabelBgColor = "var(--ds-background-neutral, ".concat(N20, ")");
export var appLabelTextColor = "var(--ds-text, ".concat(N500, ")");
export var labelTextColor = "var(--ds-text, ".concat(N800, ")");
export var labelIconColor = "var(--ds-text-subtlest, ".concat(N60, ")");
export var errorIconColor = "var(--ds-text-disabled, ".concat(N90, ")");
export var errorTitleColor = "var(--ds-text, ".concat(N800, ")");
export var errorTextColor = "var(--ds-text-subtlest, ".concat(N90, ")");