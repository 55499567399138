import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  ambiguousRefs: {
    id: 'frontbucket.repository.pullRequest.ambiguousRefs',
    description:
      'Description of a pull request diff section when there is a ref in the repo that conflicts in name with the PR source/destination hash',
    defaultMessage:
      "This repository contains a branch or tag {sourceHash} or {destHash} which shadows this pull request's source or destination commit. Delete it in order to view this pull request's diff.",
  },
  diffSetLabel: {
    id: 'frontbucket.repository.pullRequest.diffSetLabel',
    description: 'Label for the page area showing the diffs in a pull request',
    defaultMessage: 'Diff',
  },
  diffFileTitle: {
    id: 'frontbucket.repository.pullRequest.diffFileTitle',
    description: 'Title for the diff of a single file',
    defaultMessage: 'Diff of file {filepath}',
  },
  thisFile: {
    id: 'frontbucket.repository.pullRequest.diff.thisFile',
    description:
      'Label to indicate that the follow actions are for the current file only',
    defaultMessage: 'This file',
  },
  fileCount: {
    id: 'frontbucket.repository.pullRequest.diff.fileCount',
    description:
      'Header text for the diff files (lists the number of files in the diff)',
    defaultMessage: '{fileCount} {fileCount, plural, one {file} other {files}}',
  },
  fileRenamed: {
    id: 'frontbucket.repository.pullRequest.diff.fileRenamed',
    description: 'Text for empty renamed file diff',
    defaultMessage: 'File renamed but contents unchanged',
  },
  allFiles: {
    id: 'frontbucket.repository.pullRequest.diff.allFiles',
    description:
      'Label to indicate that the follow actions are for all files in this diff',
    defaultMessage: 'All files',
  },
  sideBySide: {
    id: 'frontbucket.repository.pullRequest.diff.sideBySide',
    description: 'Text for option to show side-by-side diffs',
    defaultMessage: 'View side-by-side',
  },
  unifiedDiff: {
    id: 'frontbucket.repository.pullRequest.diff.unifiedDiff',
    description: 'Text for option to show unified diffs',
    defaultMessage: 'View unified',
  },
  viewFile: {
    id: 'frontbucket.repository.pullRequest.diff.viewFile',
    description:
      'Text for option to view the diff of the file in the "Source" section of the repository',
    defaultMessage: 'Open in Source',
  },
  editFile: {
    id: 'frontbucket.repository.pullRequest.diff.editFile',
    description: 'Text for option to edit diffed files',
    defaultMessage: 'Edit file',
  },
  hideWordDiff: {
    id: 'frontbucket.repository.pullRequest.diff.hideWordDiff',
    description: 'Text for option to hide words in diffed files',
    defaultMessage: 'Hide word diff',
  },
  expandAll: {
    id: 'frontbucket.repository.pullRequest.diff.expandAll',
    description: 'Text for option to expand all diffed files',
    defaultMessage: 'Expand all files',
  },
  collapseAll: {
    id: 'frontbucket.repository.pullRequest.diff.collapseAll',
    description: 'Text for option to collapse all diffed files',
    defaultMessage: 'Collapse all files',
  },
  unmarkAllViewed: {
    id: 'frontbucket.repository.pullRequest.diff.clearViewed',
    description: 'Text for option to clear all file viewed statuses',
    defaultMessage: 'Unmark all files as viewed',
  },
  markAllViewed: {
    id: 'frontbucket.repository.pullRequest.diff.markAllViewed',
    description: 'Text for option to clear all file viewed statuses',
    defaultMessage: 'Mark all files as viewed',
  },
  hideAnnotations: {
    id: 'frontbucket.repository.pullRequest.diff.hideAnnotations',
    description: 'Text for option to hide annotations on diffed file',
    defaultMessage: 'Hide annotations',
  },
  showAnnotations: {
    id: 'frontbucket.repository.pullRequest.diff.showAnnotations',
    description: 'Text for option to show annotations on diffed file',
    defaultMessage: 'Show annotations',
  },
  showComments: {
    id: 'frontbucket.repository.pullRequest.diff.showComments',
    description: 'Text for option to show comments on a diffed file',
    defaultMessage: 'Show comments',
  },
  commentsHidden: {
    id: 'frontbucket.repository.pullRequest.diff.commentsHidden',
    description: 'Text for show below a diff header when comments are hidden',
    defaultMessage: 'Comments are hidden.',
  },
  hideComments: {
    id: 'frontbucket.repository.pullRequest.diff.hideComments',
    description: 'Text for option to hide comments on a diffed file',
    defaultMessage: 'Hide comments',
  },
  lozengeNewLabel: {
    id: 'frontbucket.repository.pullRequest.diff.lozengeNewLabel',
    description: 'Label for the "new" lozenge for hide/show comments option',
    defaultMessage: 'New',
  },
  diffActionsButtonLabel: {
    id: 'frontbucket.repository.pullRequest.diff.actions.menu.label',
    description: 'Accessibility text label for diff actions menu',
    defaultMessage: 'Diff actions',
  },
  viewEntireFile: {
    id: 'frontbucket.repository.pullRequest.diff.viewEntireFile',
    description:
      'Text for option to load the entire contents of the diffed file, not just the changes and surrounding context',
    defaultMessage: 'View entire file',
  },
  missingDiffsOnMergedPr: {
    id: 'frontbucket.repository.pullRequest.missingDiffsOnMergedPr',
    description:
      'Description of a pull request diff section that cannot be generated',
    defaultMessage:
      'The source or destination branch was deleted.{newLine}If the destination still exists, you can view the merged changes by inspecting the merge commit: {mergeCommitShortHash}',
  },
  missingDiffsErrorTitle: {
    id: 'frontbucket.repository.pullRequest.missingDiffsErrorTitle',
    description:
      'Title of a pull request diff section that cannot be generated',
    defaultMessage: 'Cannot generate the diff',
  },
  missingDiffs: {
    id: 'frontbucket.repository.pullRequest.missingDiffs',
    description:
      'Description of a pull request diff section that has nothing to show',
    defaultMessage:
      'The source or destination branch was deleted, or these changes already exist in the destination repository.',
  },
  missingDiffsTitle: {
    id: 'frontbucket.repository.pullRequest.missingDiffsTitle',
    description:
      'Title of a pull request diff section that has nothing to show',
    defaultMessage: 'Nothing to merge',
  },
  diffError: {
    id: 'frontbucket.repository.pullRequest.diffError',
    description:
      'Description of a pull request diff section that has had an error',
    defaultMessage:
      'Wait a few moments, then try again. If this keeps happening, let us know at {contactLink}.',
  },
  noFiles: {
    id: 'frontbucket.repository.pullRequest.noFiles',
    description: 'Description of a diff section that has no modified files',
    defaultMessage: 'This {context} has no changed files to display.',
  },
  noFilesTitle: {
    id: 'frontbucket.repository.pullRequest.noFilesTitle',
    description:
      'Title of a pull request diff section that has no modified files',
    defaultMessage: 'Nothing has changed',
  },
  noFilesMatchFilter: {
    id: 'frontbucket.repository.pullRequest.noFilesMatchFilter',
    description: 'Info message when no files match selected filters',
    defaultMessage: `{isCurrentUser, select,
    true {You haven't commented on any files.}
    other {There are no comments on these files.}
    }`,
  },
  unhandledMergeConflictsTitle: {
    id: 'frontbucket.repository.pullRequest.unhandledMergeConflictsTitle',
    description:
      'Description of a pull request diff section that has an error due to unhandled merge conflicts',
    defaultMessage: 'Unable to handle merge conflicts',
  },
  unhandledMergeConflictsError: {
    id: 'frontbucket.repository.pullRequest.unhandledMergeConflictsError',
    description:
      'Description of a pull request diff section that has an error due to unhandled merge conflicts',
    defaultMessage:
      'Resolve any conflicts locally and try again. {resolveMergeConflictsLink}.',
  },
  learnHowLink: {
    id: 'frontbucket.repository.pullRequest.learnHowLink',
    description: 'A link to more information about resolving merge conflicts',
    defaultMessage: 'Learn how',
  },

  /*
   * Copy for diff alert modals.
   */
  discardCommentsModalTitle: {
    id: 'frontbucket.repository.pullRequest.discardCommentsModal.title',
    description:
      'Title of a confirmation dialog explaining that unsaved comments will be lost upon clicking the "Discard" button.',
    defaultMessage: 'Discard unsaved comments?',
  },
  discardCommentsModalDiscardButton: {
    id: 'frontbucket.repository.pullRequest.discardCommentsModal.discard',
    description:
      'Text for a button to discard unsaved comments, and proceed with the attempted action.',
    defaultMessage: 'Discard',
  },
  discardCommentsModalCancelButton: {
    id: 'frontbucket.repository.pullRequest.discardCommentsModal.cancel',
    description:
      'Text for a button to cancel an action, and prevent discarding unsaved comments.',
    defaultMessage: 'Cancel',
  },

  discardCommentsModalBody__SideBySideToggle: {
    id: 'frontbucket.repository.pullRequest.discardCommentsModal.body.sideBySideToggle',
    description:
      'Body text of a confirmation dialog. Explains that unsaved comments will be lost upon changing to side-by-side view.',
    defaultMessage:
      'Changing to side-by-side view discards your unsaved comments.',
  },

  discardCommentsModalBody__ExpandContext: {
    id: 'frontbucket.repository.pullRequest.discardCommentsModal.body.expandContext',
    description:
      'Body text of a confirmation dialog. Explains that unsaved comments will be lost upon showing more lines in the current view.',
    defaultMessage: 'Showing more lines discards your unsaved comments.',
  },

  discardCommentsModalBody__SameFileComment: {
    id: 'frontbucket.repository.pullRequest.discardCommentsModal.body.sameFileComment',
    description:
      'Body text of a confirmation dialog. Explains that unsaved comments will be lost when user adds new comment in the same file.',
    defaultMessage:
      'Adding a new comment in the same file discards any unsaved comments.',
  },

  diffTimeout: {
    id: 'frontbucket.repository.pullRequest.diffTimeout',
    description:
      'Description of a pull request diff section that has had a timeout',
    defaultMessage:
      'The diff has timed out. For more information on diff limits or managing pull request size, refer to {docLink}. If this issue persists, contact {supportLink}.',
  },
  diffLimitsDocTitle: {
    id: 'frontbucket.repository.pullRequest.diffLimitsDocTitle',
    description: 'Title of our guidelines page for diff limits',
    defaultMessage: 'Limits for viewing content and diffs',
  },
  noCommonAncestor: {
    id: 'frontbucket.repository.pullRequest.noCommonAncestor',
    description: 'Description of an attempted diff between unrelated branches',
    defaultMessage: 'These branches have no common ancestor.',
  },
  noCommonAncestorTitle: {
    id: 'frontbucket.repository.pullRequest.noCommonAncestorTitle',
    description: 'Title of an attempted diff between unrelated branches',
    defaultMessage: 'Unrelated branches',
  },
});

export default messages;
