import React from 'react';

import { FormattedMessage } from 'react-intl';

import Button from '@atlaskit/button';
import DropdownMenu, {
  DropdownItemGroup,
  DropdownItem,
} from '@atlaskit/dropdown-menu-12';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';

import { useIntl } from 'src/hooks/intl';

import messages from './group.i18n';
import { DropdownItemContent } from './group.style';

export enum Privacy {
  public = 'public',
  private = 'private',
}

export type PrivacyDropdownProps = {
  onChange: (value: Privacy | null) => void;
  value: Privacy | null;
  allowEmptyChoice?: boolean;
};

/* eslint @typescript-eslint/ban-types: "warn" */
const PrivacyDropdown: React.FC<PrivacyDropdownProps> = ({
  value,
  onChange,
  allowEmptyChoice,
}) => {
  const { formatMessage } = useIntl();

  const getTriggerMessage = () => {
    if (value !== null) {
      return formatMessage(messages[value]);
    }

    return formatMessage(messages.privacy);
  };

  const getItem = (
    choice: Privacy | null,
    label: FormattedMessage.MessageDescriptor
  ) => (
    <DropdownItem
      onClick={() => onChange(choice)}
      isDisabled={value === choice}
    >
      <DropdownItemContent>{formatMessage(label)}</DropdownItemContent>
    </DropdownItem>
  );

  return (
    <DropdownMenu
      testId="privacyDropdown"
      shouldRenderToParent
      shouldFitContainer
      spacing="compact"
      trigger={({ triggerRef, ...props }) => (
        <Button
          {...props}
          shouldFitContainer
          className="triggerButton"
          iconAfter={<ChevronDownIcon label="" />}
          ref={triggerRef}
        >
          {getTriggerMessage()}
        </Button>
      )}
    >
      <DropdownItemGroup>
        {allowEmptyChoice && getItem(null, messages.all)}
        {getItem(Privacy.public, messages.public)}
        {getItem(Privacy.private, messages.private)}
      </DropdownItemGroup>
    </DropdownMenu>
  );
};

export default PrivacyDropdown;
