import { defineMessages } from 'react-intl';

export default defineMessages({
  alertSymbol: {
    id: 'frontbucket.globalErrorBoundary.alertSymbol',
    description: 'Descriptive text for an image showing an alert symbol',
    defaultMessage: 'An alert symbol, indicating a general error.',
  },

  contactSupport: {
    id: 'frontbucket.globalErrorBoundary.contactSupport',
    description:
      'A suggestion to contact the Support team with the displayed error code if the error continues to occur',
    defaultMessage:
      'If the problem persists, contact Support and include the code displayed above, if one is provided.',
  },

  notice: {
    id: 'frontbucket.globalErrorBoundary.notice',
    description: 'A notice that a general error has occurred.',
    defaultMessage: 'Something went wrong',
  },

  keepsHappening: {
    id: 'frontbucket.globalErrorBoundary.keepsHappening',
    description:
      'A statement indicating what to do if an error keeps occurring.',
    defaultMessage:
      'Wait a few moments, then try again. If this keeps happening, check the current operational status of Bitbucket Cloud at {statusPageLink}',
  },
});
