import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _extends from "@babel/runtime/helpers/extends";
var _excluded = ["isRoot"];
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { N30, N800 } from '@atlaskit/theme/colors';
export var iconColor = "var(--ds-text, ".concat(N800, ")");
export var indentBase = "var(--ds-space-300, 25px)";
var treeRowContainerStyles = css({
  display: 'flex',
  borderBottom: "1px solid ".concat("var(--ds-border, ".concat(N30, ")"))
});

/**
 * __Tree row container__
 */
export var TreeRowContainer = function TreeRowContainer(props) {
  return (
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
    jsx("div", _extends({
      css: treeRowContainerStyles
    }, props))
  );
};
var commonChevronContainerStyles = css({
  display: 'flex',
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage-spacing
  marginLeft: "calc(".concat(indentBase, " * -1)"),
  position: 'absolute',
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage-spacing
  top: 7,
  alignItems: 'center'
});
/**
 * __Chevron container__
 *
 * A wrapper container around the expand table tree button.
 */
export var ChevronContainer = function ChevronContainer(props
// eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
) {
  return jsx("span", _extends({}, props, {
    css: commonChevronContainerStyles
  }));
};
var chevronIconContainerStyles = css({
  position: 'relative',
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage-spacing
  top: 1
});

/**
 * __Chevron icon container__
 *
 * A chevron icon container.
 */
export var ChevronIconContainer = function ChevronIconContainer(props
// eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
) {
  return jsx("span", _extends({}, props, {
    css: chevronIconContainerStyles
  }));
};
var loadingItemContainerStyles = css({
  width: '100%',
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage-spacing
  paddingTop: 5
});
var paddingLeftStyles = css({
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage-spacing
  paddingLeft: '50%'
});
/**
 * __Loader item container__
 *
 * A loader item container.
 */
export var LoaderItemContainer = function LoaderItemContainer(_ref) {
  var isRoot = _ref.isRoot,
    props = _objectWithoutProperties(_ref, _excluded);
  return jsx("span", _extends({
    css: [commonChevronContainerStyles, loadingItemContainerStyles, isRoot && paddingLeftStyles]
    // eslint-disable-next-line @repo/internal/react/no-unsafe-spread-props
  }, props));
};