import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';
import { loadGlobalResource } from 'src/redux/global/resources/load-global';
import {
  AccountPaymentLoadable,
  PlanSelectorLoadable,
} from 'src/sections/account';
import { dashboardNavigationResource } from 'src/sections/dashboard/dashboard-navigation-resource';
import attributionsResource from 'src/sections/global/components/attributions/resource';

import { RouteConfig, ROUTE_NAME } from '../types';

const Search = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/search/containers/page'),
});

const AccountWorkspacesEmptyState = Loadable({
  loading: LoadingPage,
  loader: () =>
    import('src/sections/account/components/workspaces/empty-state'),
});

const GlobalCreateBranchPage = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/create-branch/containers/global-page'),
});

const DashboardLayout = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/dashboard/dashboard-route'),
});
const AttributionsPage = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/global/components/attributions/page'),
});

export const getGlobalRoutesConfig = (): RouteConfig => {
  return {
    [ROUTE_NAME.GLOBAL_SEARCH]: {
      // @ts-ignore: requires RouteContext type on component
      component: Search,
      resources: [loadGlobalResource],
    },
    [ROUTE_NAME.GLOBAL_ACCOUNT_PLAN_SELECTOR]: {
      // @ts-ignore: requires RouteContext type on component
      component: PlanSelectorLoadable,
      resources: [loadGlobalResource],
    },
    [ROUTE_NAME.GLOBAL_ACCOUNT_PAYMENT]: {
      // @ts-ignore: requires RouteContext type on component
      component: AccountPaymentLoadable,
      resources: [loadGlobalResource],
    },
    [ROUTE_NAME.GLOBAL_BRANCH_CREATE]: {
      component: GlobalCreateBranchPage,
      resources: [loadGlobalResource],
    },
    [ROUTE_NAME.GLOBAL_ACCOUNT_WORKSPACES]: {
      // @ts-ignore: requires RouteContext type on component
      component: AccountWorkspacesEmptyState,
      resources: [loadGlobalResource],
    },
    [ROUTE_NAME.GLOBAL_ATTRIBUTIONS_PAGE]: {
      // @ts-ignore: requires RouteContext type on component
      component: AttributionsPage,
      layout: DashboardLayout,
      resources: [
        loadGlobalResource,
        dashboardNavigationResource,
        attributionsResource,
      ],
    },
  };
};
