/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { all, call, select, take, put } from 'redux-saga/effects';
import { ParametricSelector } from 'reselect';

import { CodeReviewConversation } from 'src/components/conversation-provider/types';
import { AsyncAction } from 'src/redux/actions';
import {
  FETCH_OUTDATED_COMMENT_CONTEXT,
  FETCH_LARGE_FILE_COMMENT_CONTEXT,
  FETCH_PENDING_COMMENT_CONTEXT,
  FETCH_BASE_REV_COMMENT_CONTEXT,
} from 'src/redux/pull-request/actions';
import { CommentByIdApiType } from 'src/redux/pull-request/api';
import { parseId } from 'src/redux/pull-request/sagas/utils/conversations';
import { getCurrentPullRequestUrlPieces } from 'src/redux/pull-request/selectors';
import {
  getOutdatedConversationsForFile,
  getAllConversationsForFile,
  getPendingInlineConversations,
  getBaseRevConversationsForFile,
} from 'src/selectors/conversation-selectors';
import { BucketState } from 'src/types/state';
import { DiffPaths } from 'src/utils/extract-file-path';

type SelectorParameter = DiffPaths;

function createFetchCommentContextSaga(
  action: AsyncAction,
  conversationsSelector: ParametricSelector<
    BucketState,
    SelectorParameter,
    CodeReviewConversation[]
  >
) {
  return function* (getCommentById: CommentByIdApiType) {
    while (true) {
      try {
        const { payload } = yield take(action.REQUEST);
        const { owner, slug, id } = yield select(
          getCurrentPullRequestUrlPieces
        );

        // `payload` will be undefined when fetching pending comments since we're fetching
        // all conversations with pending comments, not just conversations for a particular
        // file like we do in the outdated comment dialog.
        const conversations: CodeReviewConversation[] = yield select(
          conversationsSelector,
          payload?.file
        );

        const conversationsWithoutContext = conversations.filter(c => {
          return c.meta && c.meta.context_lines === undefined;
        });

        // If none need updating then bail out
        if (conversationsWithoutContext.length === 0) {
          // Trigger success action to update loading state to false
          yield put({
            type: action.SUCCESS,
          });
          continue;
        }
        // @ts-ignore
        const contextsResults = yield all(
          // @ts-ignore
          conversationsWithoutContext.map(c =>
            call(getCommentById, owner, slug, id, parseId(c))
          )
        );

        // @ts-ignore TODO: fix noImplicitAny error here
        const contexts = contextsResults.map(cr => {
          if ('status' in cr) {
            return {
              inline: {
                context_lines: '',
              },
            };
          } else {
            return cr;
          }
        });

        const updatedConversations = conversationsWithoutContext.map(
          // @ts-ignore TODO: fix noImplicitAny error here
          (cv, i) => ({
            ...cv,
            meta: {
              ...cv.meta,
              context_lines: contexts[i].inline.context_lines,
            },
          })
        );

        yield put({
          type: action.SUCCESS,
          payload: updatedConversations,
        });
      } catch (e) {
        // do nothing
      }
    }
  };
}

export const fetchOutdatedCommentContext = createFetchCommentContextSaga(
  FETCH_OUTDATED_COMMENT_CONTEXT,
  getOutdatedConversationsForFile
);

export const fetchCommentContext = createFetchCommentContextSaga(
  FETCH_LARGE_FILE_COMMENT_CONTEXT,
  getAllConversationsForFile
);

export const fetchPendingCommentContext = createFetchCommentContextSaga(
  FETCH_PENDING_COMMENT_CONTEXT,
  getPendingInlineConversations
);

export const fetchBaseRevCommentContext = createFetchCommentContextSaga(
  FETCH_BASE_REV_COMMENT_CONTEXT,
  getBaseRevConversationsForFile
);
