import { subsup as subsupFactory } from '../../next-schema/generated/markTypes';

/**
 * @name subsup_mark
 */

function getAttrFromVerticalAlign(node) {
  if (node === 'sub') {
    return {
      type: 'sub'
    };
  }
  if (node === 'super') {
    return {
      type: 'sup'
    };
  }
  return false;
}
export var subsup = subsupFactory({
  parseDOM: [{
    tag: 'sub',
    attrs: {
      type: 'sub'
    }
  }, {
    tag: 'sup',
    attrs: {
      type: 'sup'
    }
  }, {
    // Special case for pasting from Google Docs
    // Google Docs uses vertical align to denote subscript and super script
    style: 'vertical-align=super',
    getAttrs: function getAttrs(node) {
      return getAttrFromVerticalAlign(node);
    }
  }, {
    style: 'vertical-align=sub',
    getAttrs: function getAttrs(node) {
      return getAttrFromVerticalAlign(node);
    }
  }],
  toDOM: function toDOM(mark) {
    return [mark.attrs.type];
  }
});