/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { call, put, take, select } from 'redux-saga/effects';

import { ApiComment } from 'src/components/conversation-provider/types';
import { pullRequest } from 'src/redux/pull-request/schemas';
import {
  getInlineComments,
  getCurrentPullRequestRoleForCurrentUser,
  getCurrentPullRequestUrlPieces,
} from 'src/redux/pull-request/selectors';
import urls from 'src/redux/pull-request/urls';
import { getCurrentRepositoryUuid } from 'src/selectors/repository-selectors';
import { getWorkspacePlan } from 'src/selectors/workspace-selectors';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

import { DECLINE } from '../decline-reducer';

import { getErrorMessage } from './utils/get-error-message';
import { publishPullRequestEvent } from './utils/pull-request-event-analytics';

export default function* () {
  while (true) {
    const { payload: message } = yield take(DECLINE.REQUEST);
    const { owner, slug, id } = yield select(getCurrentPullRequestUrlPieces);

    const url = urls.api.v20.decline(owner, slug, id);
    const request = authRequest(url, {
      method: 'POST',
      headers: jsonHeaders,
      body: JSON.stringify({ message }),
    });

    try {
      const response: Response = yield call(fetch, request);

      if (!response.ok) {
        const err: string = yield call(getErrorMessage, response.clone());
        throw new Error(err);
      }
      // @ts-ignore
      const updatedPullRequest = yield response.json();

      yield put({
        type: DECLINE.SUCCESS,
        payload: updatedPullRequest,
        meta: {
          schema: pullRequest,
        },
      });

      // publish analytics event
      const { isWorkspacePaid } = yield select(getWorkspacePlan);
      const repoUuid: string = yield select(getCurrentRepositoryUuid);
      const comments: ApiComment[] = yield select(getInlineComments);
      const role: string | null = yield select(
        getCurrentPullRequestRoleForCurrentUser
      );
      publishPullRequestEvent(
        'DECLINED',
        updatedPullRequest,
        comments,
        isWorkspacePaid,
        repoUuid,
        role
      );
    } catch (e) {
      yield put({
        type: DECLINE.ERROR,
        payload: e.message,
      });
    }
  }
}
