import { connect } from 'react-redux';

import ConnectRepoPage from 'src/connect/repo-page/connect-repo-page';
import { EntitiesState } from 'src/redux/entities/entities';
import isUuid from 'src/utils/is-uuid';

import updateMenuItemsAction from '../actions/update-menu-items';

// Connect apps are installed into an account, not a workspace. But the
// repository URL contains the workspace ID, not a username. We need to pass
// the username as the principal ID; in order to get that based on the URL
// alone we have to go digging in the entities table. That means the connect
// API call must wait until after the repository section API has returned.
export const getRepositoryOwnerUuid = (
  entities: EntitiesState,
  // @ts-ignore TODO: fix noImplicitAny error here
  repositoryFullSlug
): string | undefined => {
  const [owner] = repositoryFullSlug.split('/');
  if (isUuid(owner)) {
    return owner;
  }
  const repo = Object.values(entities.repositories).find(
    r => r.full_name === repositoryFullSlug
  );
  if (!repo) {
    return undefined;
  }
  // @ts-ignore Repo is normalized, so owner is *not* a User object
  return repo.owner;
};

// @ts-ignore TODO: fix noImplicitAny error here
const mapStateToProps = (state, ownProps) => {
  const { repositoryOwner, repositorySlug } = ownProps.match.params;
  const repositoryFullSlug = `${repositoryOwner}/${repositorySlug}`;
  const principalId = getRepositoryOwnerUuid(
    state.entities,
    repositoryFullSlug
  );
  return {
    principalId,
  };
};

const mapDispatchToProps = {
  updateMenuItems: updateMenuItemsAction,
};

// @ts-ignore TODO: fix noImplicitAny error here
function mergeProps(stateProps, dispatchProps, ownProps) {
  const { appKey, moduleKey, repositoryOwner, repositorySlug } =
    ownProps.match.params;

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    appKey,
    moduleKey,
    repositoryOwner,
    repositorySlug,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ConnectRepoPage);
