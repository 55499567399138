import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  pageTitle: {
    id: 'frontbucket.pipelines.components.testExecution.pageTitle',
    description: 'The title for the Test execution page',
    defaultMessage: 'Test execution',
  },
  build: {
    id: 'frontbucket.pipelines.components.testExecution.test',
    description: 'The test execution table head column name for Build',
    defaultMessage: 'Build',
  },
  outcome: {
    id: 'frontbucket.pipelines.components.testExecution.failureCorrelation',
    description: 'The test execution table head column name for Outcome',
    defaultMessage: 'Outcome',
  },
  executionTime: {
    id: 'frontbucket.pipelines.components.testExecution.averageDuration',
    description: 'The test execution table head column name for Execution time',
    defaultMessage: 'Execution time',
  },
  variance: {
    id: 'frontbucket.pipelines.components.testExecution.variance',
    description: 'The test execution table head column name for Variance',
    defaultMessage: 'Variance',
  },
  actions: {
    id: 'frontbucket.pipelines.components.testExecution.actions',
    description: 'The test execution table head column name for Actions',
    defaultMessage: 'Actions',
  },
  viewLogs: {
    id: 'frontbucket.pipelines.components.testExecution.viewLogs',
    description: 'The test execution table action to view logs',
    defaultMessage: 'View logs',
  },
  labelFilterPlaceholder: {
    id: 'frontbucket.pipelines.components.testManagement.labelFilterPlaceholder',
    description: 'The test execution table label filter placeholder',
    defaultMessage: 'Label',
  },
  statusFilterPlaceholder: {
    id: 'frontbucket.pipelines.components.testExecution.statusFilterPlaceholder',
    description: 'The test execution table status filter placeholder',
    defaultMessage: 'Status',
  },
  referenceNameLabel: {
    id: 'frontbucket.pipelines.components.testExecution.referenceNameLabel',
    description: 'The test execution table reference name label',
    defaultMessage: 'Reference name',
  },
  revisionLabel: {
    id: 'frontbucket.pipelines.components.testExecution.revisionLabel',
    description: 'The test execution table revision label',
    defaultMessage: 'Revision',
  },
});
