import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import last from 'lodash/last';
import merge from 'lodash/merge';
import { DEFAULT_SOURCE } from '@atlaskit/analytics-gas-types';
import { LINKING_PLATFORM_CONTEXT } from '@atlaskit/analytics-namespaced-context';
import { getSources, getExtraAttributes, getPackageInfo, getComponents } from '../helpers/extract-data-from-event';
import { FabricChannel } from '../types';
var listenerVersion = "8.11.1";
export default (function (event) {
  var sources = getSources(event, LINKING_PLATFORM_CONTEXT);
  var packages = getPackageInfo(event, LINKING_PLATFORM_CONTEXT);
  var components = getComponents(event, LINKING_PLATFORM_CONTEXT);
  var extraAttributes = getExtraAttributes(event, LINKING_PLATFORM_CONTEXT);
  var _ref = last(packages) || {},
    packageName = _ref.packageName,
    packageVersion = _ref.packageVersion;
  var packageHierarchy = packages.map(function (p) {
    return p.packageVersion ? "".concat(p.packageName, "@").concat(p.packageVersion) : p.packageName;
  });
  var tags = new Set(event.payload.tags || []);
  tags.add(FabricChannel.linkingPlatform);
  var payload = {
    name: event.payload.name,
    action: event.payload.action,
    actionSubject: event.payload.actionSubject,
    actionSubjectId: event.payload.actionSubjectId,
    eventType: event.payload.eventType,
    attributes: _objectSpread({
      packageName: packageName,
      packageVersion: packageVersion,
      listenerVersion: listenerVersion,
      sourceHierarchy: sources.join('.') || undefined,
      componentHierarchy: components.join('.') || undefined,
      packageHierarchy: packageHierarchy.join(',') || undefined
    }, merge(extraAttributes, event.payload.attributes)),
    tags: Array.from(tags),
    source: last(sources) || DEFAULT_SOURCE,
    nonPrivacySafeAttributes: event.payload.nonPrivacySafeAttributes
  };
  return payload;
});