import qs from 'qs';

const urls = {
  api: {
    v20: {
      defaultReviewers: (
        workspaceSlug: string,
        projectKey: string,
        options?: {
          page?: number;
          pagelen?: number;
          sort?: string;
        }
      ): string => {
        const url = `/!api/2.0/workspaces/${workspaceSlug}/projects/${projectKey}/default-reviewers/`;
        return `${url}${qs.stringify(options, {
          skipNulls: true,
          addQueryPrefix: true,
        })}`;
      },
      defaultReviewer: (
        workspaceSlug: string,
        projectKey: string,
        selectedUser: string
      ): string =>
        `/!api/2.0/workspaces/${workspaceSlug}/projects/${projectKey}/default-reviewers/${selectedUser}`,
      deployKeys: (
        workspaceSlug: string,
        projectKey: string,
        options?: { [key: string]: any }
      ): string => {
        const url = `/!api/2.0/workspaces/${workspaceSlug}/projects/${projectKey}/deploy-keys/`;
        if (options) {
          return `${url}${qs.stringify(options, {
            skipNulls: true,
            addQueryPrefix: true,
          })}`;
        }
        return url;
      },
      branchingModel: (workspaceSlug: string, projectKey: string): string =>
        `/!api/2.0/workspaces/${workspaceSlug}/projects/${projectKey}/branching-model`,
      branchingModelSettings: (
        workspaceSlug: string,
        projectKey: string
      ): string =>
        `/!api/2.0/workspaces/${workspaceSlug}/projects/${projectKey}/branching-model/settings`,
    },
    internal: {
      branchRestrictionsByBranch: (workspaceSlug: string, projectKey: string) =>
        `/!api/internal/workspaces/${workspaceSlug}/projects/${projectKey}/branch-restrictions/group-by-branch/`,
      branchRestrictionsByPattern: (
        workspaceSlug: string,
        projectKey: string,
        pattern: string
      ): string =>
        `/!api/internal/workspaces/${workspaceSlug}/projects/${projectKey}/branch-restrictions/by-pattern/${pattern}`,
      branchRestrictionsByBranchType: (
        workspaceSlug: string,
        projectKey: string,
        branchType: string
      ): string =>
        `/!api/internal/workspaces/${workspaceSlug}/projects/${projectKey}/branch-restrictions/by-branch-type/${branchType}`,
      mergeStrategy: (workspaceSlug: string, projectKey: string): string =>
        `/!api/internal/workspaces/${workspaceSlug}/projects/${projectKey}/merge-strategy`,
      repositories: (
        workspaceSlug: string,
        projectKey: string,
        options?: {
          page?: number;
          pagelen?: number;
          sort?: string;
        }
      ): string => {
        const url = `/!api/internal/workspaces/${workspaceSlug}/projects/${projectKey}/repositories`;
        return `${url}${qs.stringify(options, {
          skipNulls: true,
          addQueryPrefix: true,
        })}`;
      },
      addRepositories: (
        workspaceSlug: string,
        projectKey: string,
        options?: {
          page?: number;
          pagelen?: number;
          sort?: string;
          search?: string;
        }
      ): string => {
        const url = `/!api/internal/workspaces/${workspaceSlug}/projects/${projectKey}/add-repositories`;
        return `${url}${qs.stringify(
          {
            ...options,
            search: undefined,
            q: options?.search && `name~"${options.search}"`,
          },
          {
            skipNulls: true,
            addQueryPrefix: true,
          }
        )}`;
      },
      addDefaultReviewers: (
        workspaceSlug: string,
        projectKey: string,
        options?: { search?: string }
      ): string => {
        const url = `/!api/internal/workspaces/${workspaceSlug}/projects/${projectKey}/add-default-reviewers/`;
        return `${url}${qs.stringify(options, {
          skipNulls: true,
          addQueryPrefix: true,
        })}`;
      },
      users: (
        workspaceSlug: string,
        projectKey: string,
        options: { term: string; has_access?: boolean }
      ) => {
        const url = `/!api/internal/workspaces/${workspaceSlug}/projects/${projectKey}/users/`;
        if (options) {
          return `${url}${qs.stringify(options, {
            skipNulls: true,
            addQueryPrefix: true,
          })}`;
        }
        return url;
      },
      groupPrivilegesToAdd: (
        workspaceSlug: string,
        projectKey: string,
        search?: string
      ): string => {
        const url = `/!api/internal/workspaces/${workspaceSlug}/projects/${projectKey}/group-privileges-to-add`;
        if (search) {
          return `${url}${qs.stringify(
            { search },
            {
              skipNulls: true,
              addQueryPrefix: true,
            }
          )}`;
        }
        return url;
      },
      inheritedPermissions: (
        workspaceSlug: string,
        projectKey: string,
        options?: {
          page?: number;
          q?: string;
        }
      ) => {
        const url = `/!api/internal/inherited-project-privileges/${workspaceSlug}/${projectKey}/`;
        if (options) {
          return `${url}${qs.stringify(options, {
            skipNulls: true,
            addQueryPrefix: true,
          })}`;
        }
        return url;
      },
      accessCheck: (workspaceSlug: string, projectKey: string) =>
        `/!api/internal/workspaces/${workspaceSlug}/projects/${projectKey}/access-check`,
      projectWriters: (
        workspaceSlug: string,
        projectKey: string,
        options?: { pagelen?: number; query?: string }
      ): string => {
        const url = `/!api/internal/workspaces/${workspaceSlug}/projects/${projectKey}/project-writers`;
        return `${url}${qs.stringify(options, {
          skipNulls: true,
          addQueryPrefix: true,
        })}`;
      },
      projectWriterGroups: (
        workspaceSlug: string,
        projectKey: string,
        options?: { pagelen?: number; query?: string }
      ): string => {
        const url = `/!api/internal/workspaces/${workspaceSlug}/projects/${projectKey}/project-writer-groups`;
        return `${url}${qs.stringify(options, {
          skipNulls: true,
          addQueryPrefix: true,
        })}`;
      },
      accessTokens: (workspaceSlug: string, projectKey: string): string =>
        `/!api/internal/workspaces/${workspaceSlug}/projects/${projectKey}/access-tokens`,
      invitations: (workspaceSlug: string, projectKey: string) =>
        `/!api/internal/invitations/projects/${workspaceSlug}/${projectKey}`,
      navigation: (workspaceSlug: string, projectKey: string): string =>
        `/!api/internal/menu/project/${workspaceSlug}/${projectKey}`,
      customMergeChecks: (workspaceSlug: string, projectKey: string): string =>
        `/!api/internal/projects/${workspaceSlug}/${projectKey}/checks-config/v2`,
      // TODO: BCAT-4915 rename to customMergeChecks
      customMergeChecksV3: (
        workspaceSlug: string,
        projectKey: string
      ): string =>
        `/!api/internal/workspaces/${workspaceSlug}/projects/${projectKey}/checks`,
    },
  },
  ui: {
    landing: (workspaceSlug: string, projectKey: string): string =>
      `/${workspaceSlug}/workspace/projects/${projectKey}`,
    admin: (workspaceSlug: string, projectKey: string): string =>
      `${urls.ui.landing(workspaceSlug, projectKey)}/settings`,
    defaultReviewers: (workspaceSlug: string, projectKey: string): string =>
      `${urls.ui.landing(
        workspaceSlug,
        projectKey
      )}/settings/pullrequests/reviewers`,
    settings: (
      workspaceSlug: string,
      projectKey: string,
      pageKey: string
    ): string =>
      `${urls.ui.landing(workspaceSlug, projectKey)}/settings/${pageKey}`,
    permissions: (workspaceSlug: string, projectKey: string): string =>
      `${urls.ui.landing(workspaceSlug, projectKey)}/settings/permissions`,
    branchingModel: (workspaceSlug: string, projectKey: string): string =>
      `${urls.ui.landing(workspaceSlug, projectKey)}/settings/branching-model`,
    branchRestrictions: (workspaceSlug: string, projectKey: string): string =>
      `${urls.ui.landing(
        workspaceSlug,
        projectKey
      )}/settings/branch-restrictions`,
  },
};

export default urls;
