import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { uncurlyUuid } from '@atlassian/bitkit-analytics';

import { ConversationsMentionContext } from 'src/components/bbc-atlaskit-editor';
import { ConversationModel } from 'src/components/conversation';
import {
  CodeReviewConversation,
  FabricConversation,
  InlineField,
  isApiComment,
} from 'src/components/conversation-provider/types';
import { toFabricUser } from 'src/components/conversation-provider/utils';
import {
  getAllDiffFiles,
  getConversationsList,
  getFabricConversationsList,
  getDiffFiles,
  findFileInDiffFiles,
  getAllRawComments,
  PRSelector,
  getCurrentPullRequestId,
  getPullRequestSlice,
  getCommentTree,
} from 'src/redux/pull-request/selectors';
import { isFileLevelComment } from 'src/redux/pull-request/utils/comments';
import {
  getCurrentRepositoryProjectUuid,
  getCurrentRepositoryUuid,
  getCurrentRepositoryWorkspaceUUID,
  getIsCurrentRepositoryPrivate,
} from 'src/selectors/repository-selectors';
import {
  getCurrentUser,
  getCurrentUserAccountId,
  getCurrentUserUuid,
} from 'src/selectors/user-selectors';
import { BucketState } from 'src/types/state';
import { DiffPaths, isFileLevel, isOnFile } from 'src/utils/extract-file-path';

import { getPendingCommentTasks } from './task-selectors';

type ConversationType = 'global' | 'file' | 'inline';

function getConversationType(meta?: InlineField): ConversationType {
  if (!meta || !meta.path) {
    return 'global';
  }

  if (!meta.to && !meta.from) {
    return 'file';
  }

  return 'inline';
}

export const getConversation = (
  fabricConversations: FabricConversation[],
  conversationId: string
): FabricConversation | undefined =>
  fabricConversations.filter(
    c => c.conversationId === conversationId || c.localId === conversationId
  )[0];

const getNestedDepth = (
  conversation: ConversationModel,
  parentId?: number,
  level = 0
): number => {
  // top-level comment
  if (!conversation || !conversation.comments || !parentId) {
    return level;
  }

  const parent = conversation.comments.find(
    comment => comment.commentId === parentId
  );

  if (!parent) {
    return level;
  }

  if (typeof parent.nestedDepth === 'number') {
    return parent.nestedDepth + 1;
  }

  return getNestedDepth(conversation, parent.parentId, level + 1);
};

function getFileCacheKey(_state: never, file: DiffPaths) {
  const cacheKey = `${file.from}->${file.to}`;
  return cacheKey;
}

export const getFabricConversations = createSelector(
  getFabricConversationsList,
  fabricConversations =>
    (fabricConversations || []).sort((a, b) => {
      if (a.createdAt === b.createdAt) {
        return 0;
      }

      return a.createdAt < b.createdAt ? -1 : 1;
    })
);

export const getConversations = createSelector(
  getConversationsList,
  conversations =>
    (conversations || []).sort((a, b) => {
      if (a.createdAt === b.createdAt) {
        return 0;
      }

      return a.createdAt < b.createdAt ? -1 : 1;
    })
);

export const getLeveledFabricConversations = createSelector(
  getFabricConversations,
  fabricConversations =>
    fabricConversations.map((conversation: FabricConversation) => {
      if (!conversation.comments) {
        return conversation;
      }
      const retConvo = {
        ...conversation,
        comments: conversation.comments.map(comment => ({
          ...comment,
          // @ts-ignore
          nestedDepth: getNestedDepth(conversation, comment.parentId),
        })),
      };
      return retConvo;
    })
);

export const getLeveledFabricConversationById = createCachedSelector(
  getLeveledFabricConversations,
  (_state: never, conversationId: string) => conversationId,
  (fabricConversations, conversationId) =>
    fabricConversations.filter(
      c => c.conversationId === conversationId || c.localId === conversationId
    )[0]
)((_state: never, conversationId) => conversationId);

export const getLeveledFabricCommentsById = createCachedSelector(
  (state: BucketState, conversationId: string, _parentId?: number) =>
    getLeveledFabricConversationById(state, conversationId),
  (_state: never, _conversationId: string, parentId?: number) => parentId,
  (fabricConversation, parentId) => {
    if (fabricConversation) {
      if (parentId) {
        // all replies to a comment
        return (fabricConversation.comments || []).filter(
          c => c.parentId === parentId
        );
      }
      // all top-level comments of a conversation sorted by creation date
      return (fabricConversation.comments || [])
        .filter(
          c =>
            !c.parentId &&
            c.conversationId === fabricConversation.conversationId
        )
        .sort((a, b) =>
          a.createdAt === b.createdAt ? 0 : a.createdAt < b.createdAt ? -1 : 1
        );
    }
    return [];
  }
)((_state: never, conversationId: string, parentId?: number) =>
  parentId !== undefined ? `${conversationId}->${parentId}` : conversationId
);

export type CommentsMetaData = {
  permalink: string;
  filepath: string;
  isOutdated: boolean;
  isResolved: boolean;
};

export const getCommentsMetaData: PRSelector<CommentsMetaData[]> =
  createSelector(getAllRawComments, conversations => {
    const commentsMetaData: CommentsMetaData[] = [];

    conversations.forEach(conversation => {
      const { path: filepath, outdated: isOutdated } =
        conversation.inline || ({} as InlineField);
      commentsMetaData.push({
        permalink: `comment-${
          isApiComment(conversation)
            ? conversation.id
            : conversation.placeholderId
        }`,
        filepath: filepath || '',
        isOutdated: !!isOutdated,
        isResolved: !!conversation.resolution,
      });
    });
    return commentsMetaData;
  });

export const isCommentFileForPermalinkAvailable = createCachedSelector(
  getCommentsMetaData,
  getAllDiffFiles,
  (_state: never, permalink: string) => permalink,
  (commentsMetaData, diffFiles, permalink) => {
    const metadata = commentsMetaData.find(
      data => data.permalink === permalink
    );
    if (!metadata) {
      return false;
    }
    return !!findFileInDiffFiles(diffFiles, metadata.filepath);
  }
)((_state, permalink) => permalink);

export const isCommentFileForPermalinkHiddenByLimits = createCachedSelector(
  getCommentsMetaData,
  getAllDiffFiles,
  getDiffFiles,
  (_state: never, permalink: string) => permalink,
  (commentsMetaData, diffFiles, limitedDiffFiles, permalink) => {
    const metadata = commentsMetaData.find(
      data => data.permalink === permalink
    );
    if (!metadata) {
      return false;
    }
    return (
      !findFileInDiffFiles(limitedDiffFiles, metadata.filepath) &&
      !!findFileInDiffFiles(diffFiles, metadata.filepath)
    );
  }
)((_state, permalink) => permalink);

export const getAllConversationsForFile = createCachedSelector(
  getConversations,
  (_state: never, file: DiffPaths) => file,
  (conversations, file) => {
    return (conversations || []).filter(convo => isOnFile(convo.meta, file));
  }
)(getFileCacheKey);

export const getAllThreadsForFile = createCachedSelector(
  getCommentTree,
  (_state: never, file: DiffPaths) => file,
  (commentTree, file) => {
    return (commentTree.allThreads() || []).filter(thread =>
      isOnFile(thread.topComment.inline, file)
    );
  }
)(getFileCacheKey);

export const getFileLevelThreadsForFile = createCachedSelector(
  getAllThreadsForFile,
  threads =>
    (threads || []).filter(thread => isFileLevel(thread.topComment.inline))
)(getFileCacheKey);

export const getFileLevelConversationsForFile = createCachedSelector(
  getAllConversationsForFile,
  conversations =>
    (conversations || []).filter(convo => isFileLevel(convo.meta))
)(getFileCacheKey);

export const getValidConversationsForFile = createCachedSelector(
  getAllConversationsForFile,
  fileConversations => fileConversations.filter(convo => !convo.meta.outdated)
)(getFileCacheKey);

export const getValidThreadsForFile = createCachedSelector(
  getAllThreadsForFile,
  fileThreads =>
    fileThreads.filter(thread => !thread.topComment.inline?.outdated)
)(getFileCacheKey);

export const getInlineConversationsForFile = createCachedSelector(
  getValidConversationsForFile,
  validConversations =>
    validConversations.filter(
      convo => getConversationType(convo.meta) === 'inline'
    )
)(getFileCacheKey);

export const getInlineThreadsForFile = createCachedSelector(
  getValidThreadsForFile,
  validThreads =>
    validThreads.filter(
      thread => getConversationType(thread.topComment.inline) === 'inline'
    )
)(getFileCacheKey);

export const getOutdatedConversationsForFile = createCachedSelector(
  getAllConversationsForFile,
  fileConversations => {
    return fileConversations.filter(
      convo => convo.meta.outdated && !isFileLevelComment(convo)
    );
  }
)(getFileCacheKey);

export const getOutdatedCommentsCountForFile = createSelector(
  getOutdatedConversationsForFile,
  outdatedConversations =>
    outdatedConversations.reduce(
      (count, convo) => count + convo.numOfComments,
      0
    )
);

export const getBaseRevConversationsForFile = createCachedSelector(
  getAllConversationsForFile,
  fileConversations => {
    return fileConversations.filter(convo => convo.meta.base_rev);
  }
)(getFileCacheKey);

export const getBaseRevCommentsCountForFile = createSelector(
  getBaseRevConversationsForFile,
  baseRevConversations =>
    baseRevConversations.reduce(
      (count, convo) =>
        !isFileLevelComment(convo) ? count + convo.numOfComments : count,
      0
    )
);

export const getAllChangesConversationsForFile = createCachedSelector(
  getAllConversationsForFile,
  fileConversations => {
    return fileConversations.filter(
      convo =>
        !convo.meta.base_rev &&
        !convo.meta.outdated &&
        !isFileLevelComment(convo)
    );
  }
)(getFileCacheKey);

export const getAllChangesCommentsCountForFile = createSelector(
  getAllChangesConversationsForFile,
  conversations =>
    conversations.reduce((count, convo) => count + convo.numOfComments, 0)
);

export const getCommentsCountForFile = createSelector(
  getAllConversationsForFile,
  fileConversations =>
    fileConversations.reduce((count, convo) => count + convo.numOfComments, 0)
);

export const getConversationsGlobal = createSelector(
  getConversations,
  conversations => conversations.filter(c => Object.keys(c.meta).length === 0)
);

export const getCommentsLoadingState = createSelector(
  getPullRequestSlice,
  pullRequest => pullRequest.commentsLoadingStatus
);

export const getConversationAnalytics = createSelector(
  getCurrentPullRequestId,
  getCurrentRepositoryUuid,
  getCurrentRepositoryProjectUuid,
  getCurrentRepositoryWorkspaceUUID,
  getCurrentUserAccountId,
  getCurrentUserUuid,
  (
    pullRequestId,
    repositoryUuid,
    repositoryProjectUuid,
    repositoryWorkspaceUuid,
    currentUserAccountId,
    currentUserUuid
  ) => ({
    repositoryUUID: uncurlyUuid(repositoryUuid || '') || null,
    repositoryProjectUUID: uncurlyUuid(repositoryProjectUuid || '') || null,
    repositoryWorkspaceUUID: uncurlyUuid(repositoryWorkspaceUuid || '') || null,
    pullrequestId: pullRequestId,
    currentUserUUID: uncurlyUuid(currentUserUuid || '') || null,
    currentUserAccountId,
  })
);

export const getCurrentFabricUser = createSelector(
  getCurrentUser,
  currentUser => (currentUser ? toFabricUser(currentUser) : null)
);

export const getConversationsMentionsContext = createSelector(
  getCurrentRepositoryUuid,
  getCurrentPullRequestId,
  getCurrentRepositoryWorkspaceUUID,
  getIsCurrentRepositoryPrivate,
  (
    currentRepositoryUuid,
    currentPullRequestId,
    currentRepositoryWorkspaceUUID,
    isCurrentRepoPrivate
  ): ConversationsMentionContext => ({
    repositoryUUID: uncurlyUuid(currentRepositoryUuid || '') || null,
    repositoryWorkspaceUUID:
      uncurlyUuid(currentRepositoryWorkspaceUUID || '') || null,
    pullrequestId: currentPullRequestId,
    isPrivateRepo: isCurrentRepoPrivate || false,
  })
);

/**
 * @returns { Object } An object containing pendingConversationSet and pendingCommentSet
 *
 * pendingConversationSet: Set of conversationId (string) from conversations that either contain a pending comment or pending task
 *
 * pendingCommentSet: Set of id (number) of comments that are, or are an ancestor of, a pending comment or have a pending task attached
 */
export const getPendingSets = createSelector(
  getFabricConversations,
  getPendingCommentTasks,
  (conversations, commentTasks) => {
    const pendingCommentSet = new Set<number>();
    const pendingConversationSet = new Set<string>();

    // Iterate through each task first to add comments that should be rendered
    commentTasks.forEach(task => {
      const { comment } = task;
      if (comment) {
        pendingCommentSet.add(comment.id);
      }
    });

    // FabricConversation comments are sorted by timestamp from oldest to newest
    // If we start from the back of the array, we will always traverse the child component before its parent
    // Iterate through each conversation to determine which should be rendered
    conversations.forEach(conversation => {
      const { comments } = conversation;

      // Start from newest comment
      for (let i = comments.length - 1; i >= 0; i--) {
        const comment = comments[i];

        // A comment may not be pending but if their commentId is in the set, then they must be an ancestor
        // to a pending comment or they have a pending task attached so we must add their conversation to
        // pendingConversationSet
        if (pendingCommentSet.has(comment.commentId)) {
          pendingConversationSet.add(conversation.conversationId);
        }

        // Add pending comment to set
        if (comment.pending) {
          pendingCommentSet.add(comment.commentId);

          // If we want to render these comments, then we want to keep their conversation to render
          pendingConversationSet.add(conversation.conversationId);
        }

        // If comment is pending or has a pending child, add its parent to set
        if (pendingCommentSet.has(comment.commentId) && comment.parentId) {
          pendingCommentSet.add(comment.parentId);
        }
      }
    });

    return { pendingConversationSet, pendingCommentSet };
  }
);

export const getPendingConversations: PRSelector<CodeReviewConversation[]> =
  createSelector(
    getConversationsList,
    getPendingSets,
    (conversations, pendingSets) => {
      return conversations.filter(conversation =>
        pendingSets.pendingConversationSet.has(conversation.conversationId)
      );
    }
  );

export const getPendingGlobalConversation: PRSelector<
  CodeReviewConversation | undefined
> = createSelector(getPendingConversations, conversations =>
  conversations.find(conversation => !conversation.meta.path)
);

export const getPendingFileConversations: PRSelector<CodeReviewConversation[]> =
  createSelector(getPendingConversations, conversations =>
    conversations.filter(conversation => conversation.meta.path)
  );

export const getPendingInlineConversations: PRSelector<
  CodeReviewConversation[]
> = createSelector(getPendingConversations, pendingConversations =>
  pendingConversations.filter(
    conversation =>
      Object.keys(conversation.meta).length !== 0 &&
      (conversation.meta.from !== null || conversation.meta.to !== null)
  )
);
