import { Diff, PullRequestRenderingLimits } from 'src/types/pull-request';
import {
  countAddedAndDeletedLines,
  countChunkLines,
} from 'src/utils/count-diff-lines';
import { getExcessiveDiffSize } from 'src/utils/get-excessive-diff-size';
import { getExcludedPattern } from 'src/utils/get-excluded-pattern';
import { getFileDiffSize } from 'src/utils/get-file-diff-size';

export const isBinaryDiff = (diff: Diff) => {
  return diff.isBinary && diff.fileDiffStatus !== 'type changed';
};

export const isEmptyDiff = (diff: Diff) => diff.chunks.length === 0;

// A partial diff means the diff stat shows file changes, but we don't have any
// chunks yet
export const isPartialDiff = (diff: Diff): boolean =>
  isEmptyDiff(diff) && (diff.additions > 0 || diff.deletions > 0);

// If we fail to load a partial diff, we set a mock header
export const isSingleDiffTimeout = (diff: Diff): boolean => {
  if (diff.headers.length === 0) return false;
  return diff.headers[0] === 'diff_timeout';
};

export function getEffectivePerFileLimits(
  isSingleFileModeActive: boolean,
  renderingLimits: PullRequestRenderingLimits
): { byteLimit: number; lineLimit: number } {
  const byteLimit = isSingleFileModeActive
    ? renderingLimits.singleFileModePerFileLimitBytes
    : renderingLimits.allFilesModePerFileLimitBytes;
  const lineLimit = isSingleFileModeActive
    ? renderingLimits.singleFileModePerFileLimitLines
    : renderingLimits.allFilesModePerFileLimitLines;
  return { byteLimit, lineLimit };
}

export const isExcessiveSizeDiff = (
  diff: Diff,
  isSingleFileModeActive: boolean,
  renderingLimits: PullRequestRenderingLimits | null
) => {
  if (!renderingLimits) {
    return false;
  }

  const { lineLimit } = getEffectivePerFileLimits(
    isSingleFileModeActive,
    renderingLimits
  );

  return countAddedAndDeletedLines(diff) > lineLimit;
};

// When viewing the entire file, we care about the total # of lines, not just the
// added/removed/changed lines
export const isExcessiveSizeEntireFile = (
  diff: Diff,
  isSingleFileModeActive: boolean,
  renderingLimits: PullRequestRenderingLimits | null
) => {
  if (!renderingLimits) {
    return false;
  }

  const { byteLimit, lineLimit } = getEffectivePerFileLimits(
    isSingleFileModeActive,
    renderingLimits
  );

  return getFileDiffSize(diff) > byteLimit || countChunkLines(diff) > lineLimit;
};

// We don't want to handle 'type changed' statuses in image diffs, so we let the default diff component (<DiffFile>)
// handle rendering a custom message for that. Renamed image diffs are handled by <FileContentsUnchangedDiff />
export const isImageDiff = (diff: Diff) => {
  return diff.isImage && diff.fileDiffStatus !== 'type changed';
};

export const isFileRenamedDiff = (diff: Diff) => {
  return diff.chunks.length === 0 && diff.fileDiffStatus === 'renamed';
};

export const hasRenderableLines = (
  diff: Diff,
  isSingleFileModeActive: boolean,
  renderingLimits: PullRequestRenderingLimits | null
) =>
  !diff.isFileContentsUnchanged &&
  !isImageDiff(diff) &&
  !isBinaryDiff(diff) &&
  !getExcessiveDiffSize(diff) &&
  !getExcludedPattern(diff) &&
  !isFileRenamedDiff(diff) &&
  !isEmptyDiff(diff) &&
  (isSingleFileModeActive ||
    !isExcessiveSizeDiff(diff, isSingleFileModeActive, renderingLimits));

export const shouldTriggerSingleFileMode = (
  diffs: Diff[],
  renderingLimits: PullRequestRenderingLimits | null
) => {
  if (!renderingLimits) {
    return false;
  }

  const { singleFileModeThresholdFiles, singleFileModeThresholdLines } =
    renderingLimits;

  if (diffs.length >= singleFileModeThresholdFiles) {
    return true;
  }

  return (
    diffs.reduce((accumulator, diff) => {
      return hasRenderableLines(
        diff,
        // isSingleFileModeActive:false is used here, otherwise every diff will be considered "renderable" by hasRenderableLines
        false,
        renderingLimits
      )
        ? accumulator + countAddedAndDeletedLines(diff)
        : accumulator;
    }, 0) >= singleFileModeThresholdLines
  );
};
