import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';

const PipelinesRepoSshKeysPageLoadable = Loadable({
  loading: LoadingPage,
  loader: () => import('./ssh-keys'),
});

export default PipelinesRepoSshKeysPageLoadable;
