import { BucketDispatch, Thunk } from 'src/types/state';

import { FetchAction } from './fetch';
import { fetchData } from './fetch-data-thunk';

export const fetchDataFromResource = (action: FetchAction): Thunk => {
  return (dispatch: BucketDispatch) => {
    // Add this flag to `meta` to tell `fetch-action-saga` not to process the `fetch` call
    // because we can't `await` the results of a saga from outside of it or some other
    // saga/generator context
    action.meta.isRouterResource = true;
    // We still want to dispatch `MyFetchAction.REQUEST` so other reducers or sagas can process it
    dispatch(action);
    // Make the API request and dispatch `.SUCCESS` or `.ERROR` events and return the Promise
    // to the caller so it can `await` if necessary
    // @ts-ignore our types for redux need some work : /
    return dispatch(fetchData(action));
  };
};
