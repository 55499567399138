import { defineMessages } from 'react-intl';

export default defineMessages({
  retargetPullRequestSuccessTitle: {
    id: 'frontbucket.repository.pullRequest.retargetPullRequestSuccess.title',
    description:
      'Text to display as the title of the section message for a successful retargeting',
    defaultMessage: 'Pull request retargeted',
  },
  retargetPullRequestSuccessBody: {
    id: 'frontbucket.repository.pullRequest.retargetPullRequestSuccess.description',
    description:
      'Descriptive text to display when the pull request was retargeted successfully',
    defaultMessage: 'The pull request was successfully retargeted to {branch}.',
  },
  retargetPullRequestErrorBody: {
    id: 'frontbucket.repository.pullRequest.retargetPullRequestError.description',
    description:
      'Descriptive text to display when the pull request could not be retargeted',
    defaultMessage:
      'We ran into an issue while retargeting the pull request. Refresh the page to try again.',
  },
});
