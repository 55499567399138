import URL from 'url';

import React, { ComponentType, memo } from 'react';

import { useIntl } from 'react-intl-next';

import DashboardIcon from '@atlaskit/icon/glyph/dashboard';
import LockCircleIcon from '@atlaskit/icon/glyph/lock-circle';
import PremiumIcon from '@atlaskit/icon/glyph/premium';
import Lozenge from '@atlaskit/lozenge';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import {
  GoBackItem,
  LinkItem,
  NavigationHeader,
  Header,
  NestableNavigationContent,
  NestingItem,
  Section,
  SideNavigation,
  CustomItem,
  type CustomItemComponentProps,
} from '@atlaskit/side-navigation';
import { token } from '@atlaskit/tokens';

import { MenuItem, MenuItemGroup, UiEvent } from '../types';
import {
  isNestedMenu,
  findNestedMenuItemParent,
  MENU_ITEM_GROUP_TYPE,
  ROOT_MENU_ID,
} from '../utils/create-nested-menu';

import messages from './container-navigation-v3.i18n';
import { BetaLozenge, MenuItemWrapper } from './container-navigation-v3.style';
import { renderMenuItemIcon } from './icons-map';

type ContainerHeaderProps =
  | {
      isGlobalContext: true;
      isPrivate?: false;
      containerHref?: undefined;
      containerLogo?: undefined;
      containerName?: undefined;
      containerType?: undefined;
    }
  | {
      isGlobalContext: false;
      isPrivate: boolean;
      containerHref: string;
      containerLogo: string;
      containerName: string;
      containerType: string;
    };

export type historyPushType = {
  push: (pathname: string) => void;
};

const avatarStyles = xcss({
  height: token('space.500', '40px'),
  width: token('space.500', '40px'),
  borderRadius: token('space.050', '4px'), // currently doesn't apply to flex, but is needed eventually
  position: 'relative',
});

const privateIconStyles = xcss({
  position: 'absolute',
  top: 'space.negative.050',
  right: 'space.negative.050',
});

export type ContainerNavigationProps = {
  history?: historyPushType; // This property is not passed in bitbucket-core.
  linkComponent?: ComponentType<any>;
  menuItems: MenuItem[];
  selectedMenuItem?: MenuItem;
  publishUiEvent: (event: UiEvent) => void;
};

type ContainerNavHeaderProps = {
  containerName?: string;
  linkComponent?: ComponentType<any>;
  containerLogo?: string;
  isPrivate?: boolean;
  containerHref?: string;
};

type ContainerNavigationComponentProps = ContainerNavigationProps &
  ContainerHeaderProps;

type CustomComponentWithHrefProps = CustomItemComponentProps & {
  href: string;
  linkComponent?: ComponentType<any>;
};

export const ContainerMenuItem = ({
  item,
  publishUiEvent,
  selectedMenuItem,
  linkComponent: LinkComponent,
  history,
  menuItems,
  isNested,
}: {
  item: MenuItem | MenuItemGroup;
  history?: historyPushType;
  publishUiEvent: (event: UiEvent) => void;
  selectedMenuItem?: MenuItem;
  linkComponent?: ComponentType<any>;
  menuItems: MenuItem[];
  isNested?: boolean;
}): JSX.Element => {
  const intl = useIntl();

  if (item.type === MENU_ITEM_GROUP_TYPE) {
    const Heading = ({ children }: { children: React.ReactNode }) => {
      return (
        <Section key={item.key} title={item.title}>
          {children}
        </Section>
      );
    };
    return (
      <Heading>
        {item.children.map(child => (
          <ContainerMenuItem
            key={child.id}
            history={history}
            item={child}
            selectedMenuItem={selectedMenuItem}
            linkComponent={LinkComponent}
            publishUiEvent={publishUiEvent}
            menuItems={menuItems}
            isNested
          />
        ))}
      </Heading>
    );
  }

  if (Array.isArray(item.children) && item.children.length) {
    return (
      <NestingItem
        id={item.id}
        title={item.label}
        iconBefore={renderMenuItemIcon(item)}
      >
        {item.children.map((child, index) => (
          <ContainerMenuItem
            key={`${item.id}-${index}`}
            item={child}
            publishUiEvent={publishUiEvent}
            selectedMenuItem={selectedMenuItem}
            linkComponent={LinkComponent}
            history={history}
            menuItems={menuItems}
          />
        ))}
      </NestingItem>
    );
  }

  const icon = isNested ? null : renderMenuItemIcon(item);

  const renderMenuItem = () => {
    if (item.is_new) {
      return (
        <MenuItemWrapper>
          <span>{item.label}</span>
          <BetaLozenge>
            <Lozenge appearance="new">
              {intl.formatMessage(messages.new)}
            </Lozenge>
          </BetaLozenge>
        </MenuItemWrapper>
      );
    }
    if (item.is_beta) {
      return (
        <MenuItemWrapper>
          <span>{item.label}</span>
          <BetaLozenge>
            <Lozenge appearance="new">Beta</Lozenge>
          </BetaLozenge>
        </MenuItemWrapper>
      );
    }
    if (item.is_premium) {
      return (
        <MenuItemWrapper>
          <span>{item.label}</span>
          <PremiumIcon
            primaryColor={token('color.icon.brand', '#579DFF')}
            label="premium icon"
          />
        </MenuItemWrapper>
      );
    }
    return item.label;
  };

  const parentItem = findNestedMenuItemParent(menuItems, item);
  const selectedParentItem = selectedMenuItem
    ? findNestedMenuItemParent(menuItems, selectedMenuItem)
    : undefined;

  const CustomClientLinkComponent = ({
    children,
    href,
    ...props
  }: CustomComponentWithHrefProps) => {
    if (LinkComponent) {
      return (
        <LinkComponent href={href} {...props}>
          {children}
        </LinkComponent>
      );
    } else {
      return (
        <a href={href} {...props}>
          {children}
        </a>
      );
    }
  };

  // We meed to skip out on client links when we are jumping into a sub-menu because
  // nav v3 does not play nicely with that flow. See COREX-8964
  if (item.is_client_link && parentItem?.id === selectedParentItem?.id) {
    return (
      <CustomItem
        href={item.url}
        component={CustomClientLinkComponent}
        isSelected={selectedMenuItem?.id === item.id}
        iconBefore={icon}
      >
        {renderMenuItem()}
      </CustomItem>
    );
  } else {
    return (
      <LinkItem
        href={item.url}
        isSelected={selectedMenuItem?.id === item.id}
        iconBefore={icon}
      >
        {renderMenuItem()}
      </LinkItem>
    );
  }
};

export const ContainerMenuSection = ({
  menuItems,
  publishUiEvent,
  selectedMenuItem,
  linkComponent,
  history,
  containerHref,
}: {
  menuItems: MenuItem[];
  publishUiEvent: (event: UiEvent) => void;
  selectedMenuItem?: MenuItem;
  linkComponent?: ComponentType<any>;
  history?: historyPushType;
  containerHref: string;
}): JSX.Element | null => {
  if (!selectedMenuItem) {
    return null;
  }

  const parentItem = findNestedMenuItemParent(menuItems, selectedMenuItem);
  const items = parentItem ? parentItem.children : menuItems;

  if (parentItem?.id !== ROOT_MENU_ID) {
    // we are in a submenu, so we need to render a custom GoBackItem
    return (
      <>
        <Section key="GoBack">
          <GoBackItem
            onClick={() => {
              const containerHrefPathname =
                URL.parse(containerHref).pathname || '/';
              if (history) {
                history.push(containerHrefPathname);
              } else {
                window.location.assign(containerHrefPathname);
              }
            }}
          >
            Go Back
          </GoBackItem>
        </Section>
        {items.map((item: MenuItem) => (
          <ContainerMenuItem
            key={item.id}
            history={history}
            item={item}
            selectedMenuItem={selectedMenuItem}
            linkComponent={linkComponent}
            publishUiEvent={publishUiEvent}
            menuItems={menuItems}
          />
        ))}
      </>
    );
  } else {
    return (
      <>
        {items.map((item: MenuItem) => (
          <ContainerMenuItem
            key={item.id}
            history={history}
            item={item}
            selectedMenuItem={selectedMenuItem}
            linkComponent={linkComponent}
            publishUiEvent={publishUiEvent}
            menuItems={menuItems}
          />
        ))}
      </>
    );
  }
};

const ContainerNavHeader = memo(
  ({
    containerName,
    linkComponent: LinkComponent,
    containerLogo,
    containerHref,
    isPrivate,
  }: ContainerNavHeaderProps): JSX.Element => {
    return (
      <NavigationHeader>
        <Header
          component={({ children, ...props }) => {
            if (LinkComponent) {
              return (
                <LinkComponent href={containerHref} {...props}>
                  <Box
                    xcss={xcss({
                      marginBlock: 'space.100',
                      marginLeft: 'space.100',
                      width: '100%',
                    })}
                  >
                    {children}
                  </Box>
                </LinkComponent>
              );
            } else {
              return (
                <a href={containerHref} {...props}>
                  <Box
                    xcss={xcss({
                      marginBlock: 'space.100',
                      marginLeft: 'space.100',
                      width: '100%',
                    })}
                  >
                    {children}
                  </Box>
                </a>
              );
            }
          }}
          iconBefore={
            containerLogo ? (
              <Flex xcss={avatarStyles}>
                <img
                  src={containerLogo}
                  alt={containerName}
                  style={{
                    display: 'block',
                    borderRadius: token('space.050', '4px'),
                  }}
                />
                {isPrivate && (
                  <Flex xcss={privateIconStyles}>
                    <LockCircleIcon
                      label="private icon"
                      size="small"
                      primaryColor={token('color.icon.accent.gray', '#44546F')}
                    />
                  </Flex>
                )}
              </Flex>
            ) : (
              <Flex xcss={avatarStyles}>
                <DashboardIcon label="defaultIcon" size="large" />
              </Flex>
            )
          }
        >
          <Box
            xcss={xcss({
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            })}
          >
            {containerName}
          </Box>
        </Header>
      </NavigationHeader>
    );
  }
);

export const ContainerNavigation = ({
  containerName,
  linkComponent: LinkComponent,
  menuItems,
  selectedMenuItem,
  containerLogo,
  containerHref,
  publishUiEvent,
  history,
  isPrivate,
}: ContainerNavigationComponentProps): JSX.Element => {
  return (
    <SideNavigation label="bbc-side-nav" testId="side-navigation">
      <ContainerNavHeader
        containerName={containerName}
        linkComponent={LinkComponent}
        containerLogo={containerLogo}
        containerHref={containerHref}
        isPrivate={isPrivate}
      />
      <NestableNavigationContent>
        {isNestedMenu(menuItems) ? (
          <ContainerMenuSection
            history={history}
            menuItems={menuItems}
            selectedMenuItem={selectedMenuItem}
            linkComponent={LinkComponent}
            publishUiEvent={publishUiEvent}
            containerHref={containerHref || '/'}
          />
        ) : (
          menuItems.map(menuItem => {
            // TODO this section isn't tested well and needs to be understood better
            // It shouldn't throw an error, but it's not clear what the expected behavior is
            return (
              <LinkItem href={menuItem.url} key={menuItem.id}>
                {menuItem.label}
              </LinkItem>
            );
          })
        )}
      </NestableNavigationContent>
    </SideNavigation>
  );
};
