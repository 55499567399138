import React from 'react';

import { FormattedMessage } from 'react-intl';
import { GroupHeadingProps, OptionProps } from 'react-select';

import { components } from '@atlaskit/select';

import messages from './create-branch.i18n';
import * as styles from './create-branch.style';

export const CustomSelectOption = (props: OptionProps): JSX.Element => {
  const option = <components.Option {...props} />;
  return props.isDisabled ? (
    <styles.DisabledOption>{option}</styles.DisabledOption>
  ) : (
    option
  );
};

export const CustomGroupHeading = (props: GroupHeadingProps): JSX.Element => {
  const { children: messageKey, ...restProps } = props;
  return (
    <components.GroupHeading {...restProps}>
      <FormattedMessage {...(messages as any)[messageKey as string]} />
    </components.GroupHeading>
  );
};
