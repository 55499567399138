import { get } from 'lodash-es';

import { SiteMessage } from 'src/types/site-message';
import { StatuspageIncident } from 'src/types/statuspage';

const CRITICAL = 'critical';
const MAJOR = 'major';

const getStatuspageApiHost = (): string => {
  const host: Element | null | undefined = document.head
    ? document.head.querySelector('meta[name=statuspage-api-host]')
    : undefined;
  return host ? (host as HTMLMetaElement).content : '';
};

export const getGenericStatusPageIncident = (
  incidents: StatuspageIncident[]
): SiteMessage => {
  const incident = incidents.shift();
  return {
    id: parseInt(incident!.id, 10),
    title: incident!.name,
    appearance: 'banner',
    // This is hardcoded for now since incident messages can have a varying length.
    text: "Bitbucket is experiencing an incident, but we're on it. Check our status page for more details.",
    url: incident!.shortlink,
    isIncident: true,
  };
};

/**
 * Filters out minor incidents and maintenance. We only show the global
 * incident banner when there are major outages.
 *
 * See logic for how "critical/major" status is determined:
 * https://support.atlassian.com/statuspage/docs/top-level-status-and-incident-impact-calculations/#How-is-incident-impact-calculated
 *
 * @param {StatusPageIncident[]} incidents
 */
export const getMajorIncidents = (
  incidents: StatuspageIncident[]
): StatuspageIncident[] =>
  incidents.filter(i => i.impact === CRITICAL || i.impact === MAJOR);

export const getIncidents = async () => {
  // Mock incident data for local testing
  if (process.env.NODE_ENV === 'development') {
    return [];
  }

  const resp = await fetch(
    `${getStatuspageApiHost()}/api/v2/incidents/unresolved.json`
  );

  if (!resp.ok) {
    throw new Error('Failed to fetch Statuspage incidents');
  }
  const json = await resp.json();

  // Pluck out the list of incidents from the API response
  return get(json, 'incidents', []);
};
