import React from 'react';

import Button from '@atlaskit/button';

import { usePipelineRunHistoryContext } from 'src/components/pipelines/contexts/pipeline-run-history-context';
import { useIntl } from 'src/hooks/intl';

import { PreviousRunMessageCard, WarningMessageWrapper } from '../styled';

import { messages } from './PreviousRunMessage.i18n';

/* eslint @typescript-eslint/ban-types: "warn" */
export const PreviousRunMessage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { onSelectRun, pipelineRuns } = usePipelineRunHistoryContext();

  return (
    <PreviousRunMessageCard>
      <WarningMessageWrapper>
        {formatMessage(messages.previousRunMessageText)}
      </WarningMessageWrapper>
      <Button
        spacing="none"
        appearance="link"
        onClick={() => {
          onSelectRun(pipelineRuns[0]);
        }}
      >
        {formatMessage(messages.previousRunMessageLink)}
      </Button>
    </PreviousRunMessageCard>
  );
};
