/**
 * Based on Confluence's error boundary code:
 * https://stash.atlassian.com/projects/CONFCLOUD/repos/confluence-frontend/browse/packages/confluence-shared-components/src/ErrorBoundary/ErrorBoundaryComponent.js
 *
 * Wrap this component around pieces of functionality to limit the blast radius of an error.
 */
import React, { ErrorInfo, PureComponent, ReactNode } from 'react';

import { captureExceptionWithTags } from 'src/utils/sentry';

import { GlobalErrorPage } from './global-error';

type GlobalErrorBoundaryProps = {
  children: ReactNode;
};

type GlobalErrorBoundaryState = {
  hasError: boolean;
  sentryEventId: string | null;
};

export class GlobalErrorBoundary extends PureComponent<
  GlobalErrorBoundaryProps,
  GlobalErrorBoundaryState
> {
  // handles fallback rendering only and does not allow side-effects
  // According to `@types/react` this *is* explicitly an `any` type
  static getDerivedStateFromError(error: any) {
    // eslint-disable-next-line no-console
    console.error(error);
    return {
      hasError: true,
    };
  }

  state = {
    hasError: false,
    sentryEventId: null,
  };

  // recommended method for side effects
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error(errorInfo.componentStack);
    captureExceptionWithTags(
      error,
      { component: 'global-error-boundary' },
      { componentStack: errorInfo.componentStack }
    ).then((sentryEventId: string) => {
      this.setState({ sentryEventId });
    });
  }

  render() {
    const { hasError, sentryEventId } = this.state;
    const { children } = this.props;

    if (!hasError) {
      return children;
    }

    return <GlobalErrorPage sentryEventId={sentryEventId || undefined} />;
  }
}
