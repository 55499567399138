import { FlagProvider as ConnectFlagProvider } from '@atlassian/connect-module-core';
import { FlagCreationOptions } from '@atlassian/connect-module-core/src/modules/flag/FlagCreationOptions';

export class FlagProvider implements ConnectFlagProvider {
  flagView: ConnectFlagProvider | null = null;

  create = (options: FlagCreationOptions) => {
    if (!this.flagView) {
      throw new Error('FlagView not set');
    }
    return this.flagView.create(options);
  };

  isFlagOpen = (flagId: string): boolean => {
    if (!this.flagView) {
      throw new Error('FlagView not set');
    }
    return this.flagView.isFlagOpen(flagId);
  };

  setView = (flagView: ConnectFlagProvider | null) => {
    this.flagView = flagView;
  };
}

export default new FlagProvider();
