import React from 'react';

/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';
import { defineMessages } from 'react-intl';

import Avatar from '@atlaskit/avatar';
import GroupIcon from '@atlaskit/icon/glyph/people-group';
import Lozenge from '@atlaskit/lozenge';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import RelativeLink from 'src/components/relative-link';
import NameCell from 'src/components/settings/name-cell';
import { useIntl } from 'src/hooks/intl';
import { link } from 'src/styles/mixins';

import { useIsOrgAdmin, useIsSumEnabled } from './provider';
import { GroupAvatarContainer } from './styled';
import { FlatPrivilege } from './types';

export const messages = defineMessages({
  membersCount: {
    id: 'frontbucket.settings.permissions.membersCount',
    description: 'Members count message',
    defaultMessage: '{count} {count, plural, one {member} other {members}}',
  },
  deactivatedUserLozenge: {
    id: 'frontbucket.settings.permissions.deactivatedUserLozenge',
    description: 'Label for the deactivated user lozenge',
    defaultMessage: 'Deactivated',
  },
});

const GroupLink = styled(RelativeLink)`
  ${link};
`;

export type AvatarItemProps = FlatPrivilege & {
  isWorkspaceAdmin?: boolean;
  /* eslint react/no-unused-prop-types: warn */
  workspaceSlug?: string;
};

export const AvatarItem = ({
  name,
  avatar,
  count,
  nickname = '',
  is_active: isActive,
  isWorkspaceAdmin,
  group_link: groupLink,
}: AvatarItemProps) => {
  const { formatMessage } = useIntl();
  const [isSumEnabled] = useIsSumEnabled();
  const [isOrgAdmin] = useIsOrgAdmin();

  if (avatar && !count) {
    return (
      <NameCell
        avatar={<Avatar src={avatar} size="medium" />}
        name={
          <span>
            {name}
            {!isActive && (
              <>
                {' '}
                <Lozenge>
                  {formatMessage(messages.deactivatedUserLozenge)}
                </Lozenge>
              </>
            )}
          </span>
        }
        additionalInfo={nickname}
      />
    );
  }

  return (
    <NameCell
      avatar={
        <GroupAvatarContainer>
          <GroupIcon
            label="groupAvatar"
            primaryColor={token('color.icon.inverse', colors.N0)}
            size="large"
          />
        </GroupAvatarContainer>
      }
      name={
        /* if the ‘check for workspace admin’ request fails the link is rendered, 
        but if the user clicks it they’ll see a 403 page saying they don’t have 
        access to the page. In that case, my thinking is that it’s better to let 
        some users through to a 403 than to intermittently hide functionality from 
        an admin user with no way for the user to know why. Instead this guarantees 
        the admin user’s experience won’t be broken, but on request failure may 
        allow a non-admin user to see a 403 page. */
        groupLink &&
        ((isSumEnabled && isOrgAdmin) ||
          isWorkspaceAdmin ||
          isWorkspaceAdmin === undefined) ? (
          <GroupLink to={groupLink}>{name}</GroupLink>
        ) : (
          name
        )
      }
      additionalInfo={formatMessage(messages.membersCount, { count })}
    />
  );
};
