import { createAsyncAction, hydrateAction } from 'src/redux/actions';
import urls from 'src/urls/jira';

import { EmptyStateKind } from '../constants';
import { FilterStatePartial, Project, ProjectAssociation } from '../types';

import { jiraRepoPage as prefix } from './prefix';

export const INSTALL_ADDON_JIRA_TAB = createAsyncAction(
  prefix('INSTALL_ADDON_JIRA_TAB')
);
export const installAddonJiraTab = () => ({
  type: INSTALL_ADDON_JIRA_TAB.REQUEST,
});

export const HYDRATE_JIRA_DATA = createAsyncAction(prefix('HYDRATE_JIRA_DATA'));
export const hydrateJiraData = (repositoryFullSlug: string) =>
  hydrateAction(HYDRATE_JIRA_DATA, 'repository.jira', {
    url: urls.ui.root(repositoryFullSlug),
  });

export const INIT_JIRA_REPO_PAGE = 'INIT_JIRA_REPO_PAGE';
export const initJiraRepoPage = (repositoryFullSlug: string) => ({
  type: INIT_JIRA_REPO_PAGE,
  payload: repositoryFullSlug,
});

export const FETCH_JIRA_SITES = createAsyncAction(prefix('FETCH_JIRA_SITES'));
export const fetchJiraSites = () => ({
  type: FETCH_JIRA_SITES.REQUEST,
});

export const FETCH_RELEVANT_JIRA_SITES = createAsyncAction(
  prefix('FETCH_RELEVANT_JIRA_SITES')
);
export const fetchRelevantJiraSites = (repositoryFullSlug: string) => ({
  type: FETCH_RELEVANT_JIRA_SITES.REQUEST,
  payload: repositoryFullSlug,
});

export const FETCH_JIRA_RELEVANT_PROJECTS = createAsyncAction(
  prefix('FETCH_JIRA_RELEVANT_PROJECTS')
);
export const fetchJiraRelevantProjects = () => ({
  type: FETCH_JIRA_RELEVANT_PROJECTS.REQUEST,
});

export const FETCH_JIRA_ASSIGNEES = createAsyncAction(
  prefix('FETCH_JIRA_ASSIGNEES')
);
export const fetchJiraAssignees = () => ({
  type: FETCH_JIRA_ASSIGNEES.REQUEST,
});

export const SET_ISSUE_ASSIGNEE = createAsyncAction(
  prefix('SET_ISSUE_ASSIGNEE')
);
export const setIssueAssignee = (issueKey: string, assigneeId: string) => ({
  type: SET_ISSUE_ASSIGNEE.REQUEST,
  payload: {
    issueKey,
    assigneeId,
  },
});

export const FETCH_JIRA_RELEVANT_ISSUES = createAsyncAction(
  prefix('FETCH_JIRA_RELEVANT_ISSUES')
);
export const fetchJiraRelevantIssues = () => ({
  type: FETCH_JIRA_RELEVANT_ISSUES.REQUEST,
});

export const FETCH_DEV_ACTIVITY = createAsyncAction(
  prefix('FETCH_DEV_ACTIVITY')
);
export const fetchDevActivity = (payload: {
  cloudId: string;
  issueIds: string[];
}) => ({
  type: FETCH_DEV_ACTIVITY.REQUEST,
  payload,
});

export const FETCH_WORKSPACE_PERMISSION = createAsyncAction(
  prefix('FETCH_WORKSPACE_PERMISSION')
);
export const fetchWorkspacePermission = () => ({
  type: FETCH_WORKSPACE_PERMISSION.REQUEST,
});

export const FILTER_STATE = {
  CHANGE: prefix('CHANGE_FILTER_STATE'),
  SAVE: prefix('SAVE_FILTER_STATE'),
};
export const saveFilterState = (payload: FilterStatePartial) => ({
  type: FILTER_STATE.SAVE,
  payload,
});

export const FETCH_JIRA_TAB_FILTERS_USER_PREFERENCE = createAsyncAction(
  prefix('FETCH_JIRA_TAB_FILTERS_USER_PREFERENCE')
);

export const fetchJiraTabFiltersUserPreference = () => ({
  type: FETCH_JIRA_TAB_FILTERS_USER_PREFERENCE.REQUEST,
});

export const JIRA_TAB_FILTERS_USER_PREFERENCE_NOT_REQUIRED = prefix(
  'JIRA_TAB_FILTERS_USER_PREFERENCE_NOT_REQUIRED'
);

export const setJiraTabFiltersUserPreferenceNotRequired = () => ({
  type: JIRA_TAB_FILTERS_USER_PREFERENCE_NOT_REQUIRED,
});

export const changeFilterState = (payload: FilterStatePartial) => ({
  type: FILTER_STATE.CHANGE,
  payload,
});

export const SET_EMPTY_STATE_KIND = prefix('SET_EMPTY_STATE_KIND');
export const setEmptyStateKind = (payload: EmptyStateKind) => ({
  type: SET_EMPTY_STATE_KIND,
  payload,
});

export const RESET_STATE = prefix('RESET_STATE');
export const resetState = () => ({
  type: RESET_STATE,
});

export const MANAGE_PROJECTS_DIALOG_OPEN_CHANGE = prefix(
  'MANAGE_PROJECTS_DIALOG_VISIBLE'
);
export const openManageProjectsDialog = () => ({
  type: MANAGE_PROJECTS_DIALOG_OPEN_CHANGE,
  payload: true,
});
export const closeManageProjectsDialog = () => ({
  type: MANAGE_PROJECTS_DIALOG_OPEN_CHANGE,
  payload: false,
});

export const PROJECTS_MENU_OPEN_CHANGE = prefix('PROJECTS_MENU_VISIBLE');
export const openProjectsMenu = () => ({
  type: PROJECTS_MENU_OPEN_CHANGE,
  payload: true,
});
export const closeProjectsMenu = () => ({
  type: PROJECTS_MENU_OPEN_CHANGE,
  payload: false,
});

export const UNLINK_PROJECT_DIALOG_OPEN_CHANGE = prefix(
  'UNLINK_PROJECT_DIALOG_VISIBLE'
);
export const openUnlinkProjectDialog = (projectToUnlink: Project) => ({
  type: UNLINK_PROJECT_DIALOG_OPEN_CHANGE,
  payload: projectToUnlink,
});
export const closeUnlinkProjectDialog = () => ({
  type: UNLINK_PROJECT_DIALOG_OPEN_CHANGE,
  payload: undefined,
});

export const FETCH_JIRA_PROJECTS = createAsyncAction(
  prefix('FETCH_JIRA_PROJECTS')
);
export const fetchJiraProjects = (payload: {
  cloudId: string;
  projectQuery: string;
}) => ({
  type: FETCH_JIRA_PROJECTS.REQUEST,
  payload,
});

export const LINK_JIRA_PROJECT = createAsyncAction(prefix('LINK_JIRA_PROJECT'));
export const linkJiraProject = (
  projectAssociation: Partial<ProjectAssociation>
) => ({
  type: LINK_JIRA_PROJECT.REQUEST,
  payload: { projectAssociation },
});

export const UNLINK_JIRA_PROJECT = createAsyncAction(
  prefix('UNLINK_JIRA_PROJECT')
);
export const unlinkJiraProject = (
  projectAssociation: Partial<ProjectAssociation>
) => ({
  type: UNLINK_JIRA_PROJECT.REQUEST,
  payload: { projectAssociation },
});

export const UPDATE_RELEVANT_JIRA_PROJECTS = prefix(
  'UPDATE_RELEVANT_JIRA_PROJECTS'
);
export const updateRelevantJiraProjects = (
  projectAssociations: ProjectAssociation[]
) => ({
  type: UPDATE_RELEVANT_JIRA_PROJECTS,
  payload: projectAssociations,
});

export const CREATE_ISSUE_DIALOG_OPEN_CHANGE = prefix(
  'CREATE_ISSUE_DIALOG_OPEN_CHANGE'
);
export const changeIsCreateIssueDialogOpen = (payload: boolean) => ({
  type: CREATE_ISSUE_DIALOG_OPEN_CHANGE,
  payload,
});
