import { defineMessages } from 'react-intl';

export default defineMessages({
  errorCode: {
    id: 'frontbucket.sectionErrorBoundary.errorCode',
    description:
      'A reference to a unique code to provide to customer support to help them narrow down the error',
    defaultMessage: 'When contacting support, provide them with this code:',
  },
  title: {
    id: 'frontbucket.sectionErrorBoundary.title',
    description: 'A title that says a runtime error has occurred.',
    defaultMessage: 'We stumbled a bit here.',
  },
  keepsHappening: {
    id: 'frontbucket.sectionErrorBoundary.keepsHappening',
    description:
      'A statement indicating what to do if an error keeps occurring.',
    defaultMessage:
      '{tryAgainLink} or {supportResourcesLink} if this keeps happening.',
  },
  supportResourcesLink: {
    id: 'frontbucket.sectionErrorBoundary.supportResourcesLink',
    description: 'Text for a link to support resources',
    defaultMessage: 'contact support',
  },
  tryAgainLink: {
    id: 'frontbucket.sectionErrorBoundary.tryAgainLink',
    description: 'Text for a link to reload the page',
    defaultMessage: 'Reload the page',
  },
});
