import { ModelBase } from './ModelBase';

export class Variable extends ModelBase {
  readonly uuid: string = '';
  readonly key: string = '';
  readonly value: string = '';
  readonly secured: boolean = false;
  readonly isSyncing: boolean = false;
  readonly error: any = null;

  constructor(props: Partial<Variable> = {}) {
    super(props);
    Object.assign(this, props);
    Object.freeze(this);
  }
}
