import { Validator } from 'src/components/pipelines/models';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import { SET_VALIDATOR } from '../actions/pipelines';

export default createReducer(new Validator(), {
  [SET_VALIDATOR](state, action: Action<object>) {
    return new Validator({
      ...state,
      ...action.payload,
    });
  },
});
