import envBaseUrl from './env-base-url';

export type Params = {
  repositoryFullSlug?: string;
  repositoryOwner?: string; // legacyPath
  repositorySlug?: string; // legacyPath
};

export type ConfigFormData = {
  branchFilter: string;
  releaseBranchFilter: string;
  httpAccessToken: string | null;
  repositoryAccessToken: string | null;
  projectKey: string;
  repositoryKey: string;
  enabled: boolean;
};

export const getShadowRepositoryConfigurationUrl = ({
  repositoryFullSlug,
  repositoryOwner,
  repositorySlug,
}: Params): string => {
  const repoPath = repositoryFullSlug || `${repositoryOwner}/${repositorySlug}`;
  return `${envBaseUrl(
    '/!api/internal',
    'pipelines',
    true
  )}/repositories/${repoPath}/shadow-repository-configuration`;
};
