import { Repository, Workspace } from 'src/components/types';

export type ResizeEvent = {
  isOpen: boolean;
  width?: number;
};

export type MenuItemGroup = {
  key: string;
  title: string;
  type: 'menu_item_group';
  children: MenuItem[];
};

export type MenuItem = {
  analytics_label: string;
  icon_class: string;
  badge_label: string | null;
  weight: number;
  url: string;
  tab_name: string | null;
  can_display: boolean;
  is_premium: boolean | null;
  is_beta: boolean | null;
  is_new: boolean | null;
  label: string;
  anchor: boolean;
  analytics_payload: {
    addon_key?: string;
    label?: string;
    module_key?: string;
    oauth_consumer_id?: string;
  };
  target: string;
  type: 'menu_item' | 'connect_menu_item' | 'forge_menu_item';
  id: string;
  test_id?: string | null;
  parentId?: string; // used for nestedMenu
  icon: string;
  is_client_link?: boolean;
  is_external_link?: boolean;
  is_dropdown_item?: boolean;
  icon_url?: string;
  matching_url_prefixes: string[];
  count?: number;
  children: (MenuItemGroup | MenuItem)[];
};

export type DroplistItem<C> = {
  content: C;
  href?: string;
  elemBefore?: any;
};

export type CheckableDroplistItem<C> = DroplistItem<C> & { isChecked: boolean };

export type UiEvent = {
  action: string;
  actionSubject: string;
  source: string;
  actionSubjectId: string;
  attributes?: object;
};

export type PullRequestGlobalSearchResult = {
  id: number;
  localId: number;
  repo: string;
  repo_owner_uuid: string;
  repo_uuid: string;
  title: string;
  url: string;
};

export type RepositoryGlobalSearchResult = {
  fullSlug: string;
  logo: string;
  name: string;
  owner: string;
  owner_uuid: string;
  slug: string;
  url: string;
  uuid: string;
};

export type AnalyticsEvent = {
  action: string;
  actionSubject: string;
  actionSubjectId: string | number;
  source: string;
  attributes?: object;
  containerType?: string;
  containerId?: string | number;
  objectType?: string;
  objectId?: string | number;
  tags?: string[];
};

type BaseEvent = {
  attributes?: object;
  containerType?: string;
  containerId?: string;
  objectType?: string;
  objectId?: string;
  tags?: string[];
  containers?: {
    [key: string]: {
      id?: string;
      type?: string;
    };
  };
};

export type OperationalEvent = {
  action: string;
  actionSubject: string;
  actionSubjectId?: string;
  source: string;
} & BaseEvent;

export enum Product {
  Bitbucket = 'bitbucket.ondemand',
  Confluence = 'confluence.ondemand',
  JiraSoftware = 'jira-software.ondemand',
  JiraServiceDesk = 'jira-servicedesk.ondemand',
  Opsgenie = 'opsgenie',
}

export enum ApiResponseStatusCode {
  Success = 200,
  BadRequest = 400,
  Unauthorized = 401,
}

export type Site = {
  avatar?: string;
  cloudId: string;
  displayName: string;
  products: Array<{ productKey: Product; url?: string }>;
  url: string;
  relevance?: number;
  users?: SiteUser[];
};

export interface JoinableSite extends Omit<Site, 'products'> {
  availableProducts: Product[];
}

export const isChildrenMenuItems = (
  menuItems: (MenuItemGroup | MenuItem)[]
): menuItems is MenuItem[] => {
  return (menuItems as MenuItem[])[0]?.type === 'menu_item';
};

export type SiteUser = { avatarUrl: string; displayName: string };

export type PageContext = {
  repository?: Repository;
  workspace?: Workspace;
};

export const JIRA_SOFTWARE_PRODUCT_TYPE = 'JIRA_SOFTWARE';
