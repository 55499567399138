import { Variable } from 'src/components/pipelines/models';
import { createAsyncAction } from 'src/redux/actions';

import { prefixed } from './common';

export const CLEAR_VARIABLES = 'CLEAR_VARIABLES';

export const REQUEST_VARIABLES = createAsyncAction(
  prefixed('REQUEST_VARIABLES')
);

export const REQUEST_CREATE_VARIABLE = createAsyncAction(
  prefixed('REQUEST_CREATE_VARIABLE')
);

export const REQUEST_UPDATE_VARIABLE = createAsyncAction(
  prefixed('REQUEST_UPDATE_VARIABLE')
);

export const REQUEST_DELETE_VARIABLE = createAsyncAction(
  prefixed('REQUEST_DELETE_VARIABLE')
);

export const REQUEST_ENVIRONMENT_VARIABLES = createAsyncAction(
  prefixed('REQUEST_ENVIRONMENT_VARIABLES')
);

export const REQUEST_CREATE_ENVIRONMENT_VARIABLE = createAsyncAction(
  prefixed('REQUEST_CREATE_ENVIRONMENT_VARIABLE')
);

export const REQUEST_UPDATE_ENVIRONMENT_VARIABLE = createAsyncAction(
  prefixed('REQUEST_UPDATE_ENVIRONMENT_VARIABLE')
);

export const REQUEST_DELETE_ENVIRONMENT_VARIABLE = createAsyncAction(
  prefixed('REQUEST_DELETE_ENVIRONMENT_VARIABLE')
);

export const requestVariables = () => ({
  type: REQUEST_VARIABLES.REQUEST,
  meta: {},
});

export const requestCreateVariable = (variable: Variable) => ({
  type: REQUEST_CREATE_VARIABLE.REQUEST,
  meta: { variable },
});

export const requestUpdateVariable = (
  previousVariable: Variable,
  variable: Variable
) => ({
  type: REQUEST_UPDATE_VARIABLE.REQUEST,
  meta: { previousVariable, variable },
});

export const requestDeleteVariable = (
  variable: Variable,
  variableIndex: number
) => ({
  type: REQUEST_DELETE_VARIABLE.REQUEST,
  meta: { variable, variableIndex },
});

export const requestEnvironmentVariables = (environmentUuid: string) => {
  return {
    type: REQUEST_ENVIRONMENT_VARIABLES.REQUEST,
    meta: { environmentUuid },
  };
};

export const requestCreateEnvironmentVariable = (
  environmentUuid: string,
  variable: Variable
) => {
  return {
    type: REQUEST_CREATE_ENVIRONMENT_VARIABLE.REQUEST,
    meta: { environmentUuid, variable },
  };
};

export const requestUpdateEnvironmentVariable = (
  environmentUuid: string,
  previousVariable: Variable,
  variable: Partial<Variable>
) => ({
  type: REQUEST_UPDATE_ENVIRONMENT_VARIABLE.REQUEST,
  meta: { environmentUuid, previousVariable, variable },
});

export const requestDeleteEnvironmentVariable = (
  environmentUuid: string,
  variable: Variable,
  variableIndex: number
) => ({
  type: REQUEST_DELETE_ENVIRONMENT_VARIABLE.REQUEST,
  meta: { environmentUuid, variable, variableIndex },
});
