import AnalyticsWebClient, {
  envType,
  tenantType,
  userType,
  originType,
} from '@atlassiansox/analytics-web-client';

import { getHtmlMetaValues } from './utils/get-html-meta-values';

const analyticsEnvironment = (env: string) => {
  if (env === 'production') {
    return envType.PROD;
  } else if (env === 'integration') {
    return envType.STAGING;
  } else if (env === 'development') {
    return envType.DEV;
  }
  return envType.LOCAL;
};

export const createAnalyticsClient = (): AnalyticsWebClient => {
  const { bbEnv, version, locale, userAaid, workspaceUuid } =
    getHtmlMetaValues();

  const analyticsEnv: envType = analyticsEnvironment(bbEnv);

  const client = new AnalyticsWebClient(
    {
      env: analyticsEnv,
      product: 'bitbucket',
      subproduct: 'frontbucket',
      version,
      origin: originType.WEB,
      locale,
    },
    {
      disableCookiePersistence: true,
    }
  );

  // Set the user identifier. In the case of an anonymous user, calling
  // clearUserInfo will ensure the 'ajs_anonymous_id' cookie is set, which we
  // require on the back end
  if (userAaid && typeof userAaid === 'string') {
    client.setUserInfo(userType.ATLASSIAN_ACCOUNT, userAaid);
  } else {
    client.clearUserInfo();
  }

  // pass target workspace id if it's available
  if (workspaceUuid && typeof workspaceUuid === 'string') {
    client.setTenantInfo(tenantType.CLOUD_ID, workspaceUuid);
    client.setWorkspaceInfo(workspaceUuid);
  } else {
    client.setTenantInfo(tenantType.NONE);
    client.clearWorkspaceInfo();
  }

  return client;
};
