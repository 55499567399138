import {
  ConnectModuleFactPayload,
  ConnectLinkerFactPayload,
  SendEvent,
} from '../../types';

import { emitter } from './emitter';

export const sendEvent: SendEvent = (
  eventType: string,
  payload: ConnectModuleFactPayload | ConnectLinkerFactPayload
) => {
  emitter.emit(eventType, payload);
};
