import { defineMessages } from 'react-intl';

export default defineMessages({
  deleteRepositoryErrorTitle: {
    id: 'frontbucket.dashboard.deleteRepositoryErrorTitle',
    description: 'Text for the title of the delete repo error message',
    defaultMessage: 'Unable to delete repository',
  },
  deleteRepositoryErrorDescription: {
    id: 'frontbucket.dashboard.deleteRepositoryErrorDescription',
    description: 'Text for the description of the delete repo error message',
    defaultMessage:
      'The repository cannot be deleted due to being under SOX compliance',
  },
});
