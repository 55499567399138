import React from 'react';

import AkPage from '@atlaskit/page';
import Spinner from '@atlaskit/spinner';

import { Content } from 'src/app/page.styled';

import * as styles from './loading.style';

type Props = {
  interactionName?: string;
};

/* eslint @typescript-eslint/ban-types: "warn" */
export const LoadingPage: React.FC<Props> = React.memo(
  ({ interactionName = 'loading-page' }: Props) => {
    return (
      <AkPage>
        <Content isBannerOpen={false} consentBannerHeight={0}>
          <styles.Loading>
            <Spinner delay={0} size="large" interactionName={interactionName} />
          </styles.Loading>
        </Content>
      </AkPage>
    );
  }
);
