import { createResource, RouterContext } from 'react-resource-router';

import { ResourceContext } from 'src/router/types';
import { PullRequest, PullRequestFilter } from 'src/types/pull-requests-table';
import { stringify } from 'src/urls/utils';
import workspaceUrls from 'src/urls/workspaces';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

const FIELDS = [
  '+pullRequests.*.author',
  '+pullRequests.*.closed_on',
  '+pullRequests.*.comment_count',
  '+pullRequests.*.created_on',
  '+pullRequests.*.destination.branch.name',
  '+pullRequests.*.destination.commit.hash',
  // Need to manually grab some of *.destination.repository and let *.repository fields fill out the rest
  '+pullRequests.*.destination.repository.workspace',
  '+pullRequests.*.id',
  '+pullRequests.*.links.html',
  '+pullRequests.*.links.self',
  '+pullRequests.*.participants',
  // These fill out *.destination.repository and *.source.repository
  '+pullRequests.*.repository.links.avatar',
  '+pullRequests.*.repository.links.html',
  '+pullRequests.*.repository.full_name',
  '+pullRequests.*.repository.name',
  '+pullRequests.*.source.branch.name',
  '+pullRequests.*.source.commit.hash',
  // Need to manually grab some of *.source.repository and let *.repository fields fill out the rest
  '+pullRequests.*.source.repository.workspace',
  '+pullRequests.*.state',
  '+pullRequests.*.task_count',
  '+pullRequests.*.title',
  '+pullRequests.*.updated_on',
].join(',');

export type DashboardView = {
  pullRequestFilters: PullRequestFilter[];
  pullRequests: {
    authored: PullRequest[];
    reviewing: PullRequest[];
    closed: PullRequest[];
  };
};

export const dashboardOverviewViewResource = createResource({
  type: 'DASHBOARD_VIEW',
  getKey: ({ match }: RouterContext) =>
    `dashboard:${match.params.workspaceSlug}`,
  maxAge: 2 * 1000,
  getData: async (
    { match },
    { csrftoken }: ResourceContext
  ): Promise<DashboardView | null> => {
    const { workspaceSlug } = match.params;

    // If we can't match the URL to a workspace slug, this resource is being used on a route
    // it shouldn't be.
    if (!workspaceSlug) {
      return null;
    }

    const requestUrl =
      workspaceUrls.api.internal.overviewViewState(workspaceSlug);

    const params = stringify({
      fields: FIELDS,
    });

    const response = await fetch(
      authRequest(
        `${requestUrl}/${params}`,
        { headers: jsonHeaders },
        csrftoken
      )
    );
    if (!response.ok) {
      throw new Error(
        `Error fetching dashboard overview view data (${response.status}): ${response.statusText}`
      );
    }

    const viewData: DashboardView = await response.json();
    return viewData;
  },
  isBrowserOnly: true,
});
