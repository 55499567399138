import React, { useState, memo, useCallback, useMemo } from 'react';

import { connect, useDispatch } from 'react-redux';

import Flag from '@atlaskit/flag';
import Info from '@atlaskit/icon/glyph/info';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import { ComponentFlagProps } from 'src/redux/flags/types';
import toggleMarketingConsentNeeded from 'src/redux/global/actions/marketing-consent';
import { BucketState } from 'src/types/state';
import authRequest from 'src/utils/fetch';

import * as styles from './marketing-consent-flag.style';
import messages from './marketing-consent.i18n';

type MarketingConsentFlagProps = ComponentFlagProps & {
  geoipCountry: string;
  marketingConsentLocale: string;
};

/* eslint @typescript-eslint/ban-types: "warn" */
export const MarketingConsentFlag: React.FC<MarketingConsentFlagProps> = memo(
  ({
    geoipCountry,
    marketingConsentLocale,
    id,
    handleDismissed,
    ...flagProps
  }) => {
    const { formatMessage } = useIntl();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitFailed, setSubmitFailed] = useState(false);
    const dispatch = useDispatch();

    const showErrorMessage = useCallback(() => {
      setIsSubmitting(false);
      setSubmitFailed(true);
    }, []);

    const closeFlag = useCallback(() => {
      dispatch(toggleMarketingConsentNeeded(false));
      handleDismissed(id);
    }, [id, handleDismissed, dispatch]);

    const submitConsent = useCallback(
      async (consent: boolean) => {
        if (isSubmitting) {
          return;
        }

        setIsSubmitting(true);

        const locale =
          marketingConsentLocale !== 'UNKNOWN'
            ? marketingConsentLocale
            : geoipCountry;

        try {
          const resp = await fetch(
            authRequest('/!api/internal/user/marketing_consent', {
              method: 'POST',
              body: JSON.stringify({
                marketing_consent: consent,
                marketing_consent_locale: locale,
                marketing_consent_verbiage: formatMessage(
                  messages.marketingConsentDescription
                ),
              }),
            })
          );

          if (resp.ok) {
            closeFlag();
          } else {
            showErrorMessage();
          }
        } catch (error) {
          showErrorMessage();
        }
      },
      [
        closeFlag,
        formatMessage,
        geoipCountry,
        marketingConsentLocale,
        isSubmitting,
        showErrorMessage,
      ]
    );

    const actions = useMemo(
      () =>
        submitFailed
          ? [
              {
                content: formatMessage(
                  messages.marketingConsentSubmitFailedButton
                ),
                onClick: closeFlag,
              },
            ]
          : [
              {
                content: formatMessage(messages.marketingConsentButtonYes),
                onClick: () => submitConsent(true),
              },
              {
                content: formatMessage(messages.marketingConsentButtonNo),
                onClick: () => submitConsent(false),
              },
            ],
      [closeFlag, formatMessage, submitConsent, submitFailed]
    );

    return (
      <styles.FlagWrapper>
        <Flag
          id={id}
          appearance="normal"
          title={
            submitFailed
              ? formatMessage(messages.marketingConsentFailedTitle)
              : formatMessage(messages.marketingConsentTitle)
          }
          description={
            submitFailed
              ? formatMessage(messages.marketingConsentSubmitFailedDescription)
              : formatMessage(messages.marketingConsentDescription)
          }
          icon={
            isSubmitting ? (
              <Spinner testId="marketing-content-spinner" size="small" />
            ) : (
              <Info
                label=""
                primaryColor={token('color.icon.discovery', colors.P300)}
                size="medium"
              />
            )
          }
          actions={actions}
          // Pass on remaining props to AkFlag set by AkFlagGroup
          {...flagProps}
        />
      </styles.FlagWrapper>
    );
  }
);

const mapStateToProps = (state: BucketState) => ({
  geoipCountry: state.global.geoipCountry,
  marketingConsentLocale: state.global.marketingConsentLocale,
});

export default connect(mapStateToProps, {})(MarketingConsentFlag);
