/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { call, select } from 'redux-saga/effects';

import { getCurrentPullRequestId } from 'src/redux/pull-request/selectors';
import { getCurrentRepositoryFullSlug } from 'src/selectors/repository-selectors';
import { updatePullRequestLastSeen } from 'src/utils/pull-requests/pull-request-activity';

export function* updateLastSeenSaga() {
  const repositoryFullSlug: string = yield select(getCurrentRepositoryFullSlug);
  const pullRequestId: number = yield select(getCurrentPullRequestId);

  yield call(updatePullRequestLastSeen, repositoryFullSlug, pullRequestId);
}
