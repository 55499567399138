import { Capabilities } from 'src/components/pipelines/models';
import { FETCH_IS_PIPELINES_ENABLED } from 'src/sections/repository/actions';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import {
  REQUEST_DISABLE_PIPELINES,
  REQUEST_ENABLE_PIPELINES,
} from '../actions/pipelines';

export default createReducer(new Capabilities(), {
  [FETCH_IS_PIPELINES_ENABLED.SUCCESS](
    state: Capabilities,
    action: Action<any>
  ) {
    const { repository_config, allowance } = action.payload;
    if (allowance) {
      return new Capabilities({
        ...state?.toJS?.(),
        pipelinesEnabled: repository_config?.pipelines_enabled,
        limited: allowance.limit_usage,
        isPremium: allowance.premium,
        isPaid: allowance.paid,
        allowancePercentageUsed: allowance.percentage_used,
        hasMinutesLeft: allowance.has_minutes_left,
        hasFetchedCapabilities: true,
      });
    } else {
      // In Pipelines' staging and ddev environments there is no allowance information,
      // and thus we do not get an allowance object as a result of this action.
      return new Capabilities({
        ...state?.toJS?.(),
        pipelinesEnabled: repository_config?.pipelines_enabled,
        hasFetchedCapabilities: true,
      });
    }
  },
  [REQUEST_ENABLE_PIPELINES.REQUEST](state: Capabilities) {
    return new Capabilities({
      ...state?.toJS?.(),
      pipelinesEnabled: false,
    });
  },
  [REQUEST_ENABLE_PIPELINES.SUCCESS](state: Capabilities) {
    return new Capabilities({
      ...state?.toJS?.(),
      pipelinesEnabled: true,
    });
  },
  [REQUEST_ENABLE_PIPELINES.ERROR](state: Capabilities, action: Action<any>) {
    return new Capabilities({
      ...state?.toJS?.(),
      pipelinesEnabled: false,
      pipelinesEnabledError: action?.payload,
    });
  },
  [REQUEST_DISABLE_PIPELINES.REQUEST](state: Capabilities) {
    return new Capabilities({
      ...state?.toJS?.(),
      pipelinesEnabled: true,
    });
  },
  [REQUEST_DISABLE_PIPELINES.SUCCESS](state: Capabilities) {
    return new Capabilities({
      ...state?.toJS?.(),
      pipelinesEnabled: false,
    });
  },
  [REQUEST_DISABLE_PIPELINES.ERROR](state: Capabilities, action: Action<any>) {
    return new Capabilities({
      ...state?.toJS?.(),
      pipelinesEnabled: true,
      pipelinesEnabledError: action?.payload,
    });
  },
});
