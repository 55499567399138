import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';
import { IS_SIDEBAR_COLLAPSING } from '../constants';
var getIsCollapsing = function getIsCollapsing() {
  // SSR bail-out because document is undefined on the server
  if (typeof document === 'undefined') {
    return false;
  }
  return document.documentElement.getAttribute(IS_SIDEBAR_COLLAPSING) === 'true';
};
var useIsSidebarCollapsing = function useIsSidebarCollapsing() {
  var _useState = useState(getIsCollapsing),
    _useState2 = _slicedToArray(_useState, 2),
    isCollapsing = _useState2[0],
    setIsCollapsing = _useState2[1];
  useEffect(function () {
    var observer = new MutationObserver(function () {
      setIsCollapsing(getIsCollapsing);
    });
    observer.observe(document.documentElement, {
      attributeFilter: [IS_SIDEBAR_COLLAPSING]
    });
    return function () {
      observer.disconnect();
    };
  }, []);
  return isCollapsing;
};
export default useIsSidebarCollapsing;