import React from 'react';

import Avatar from '@atlaskit/avatar';
import GroupIcon from '@atlaskit/icon/glyph/people-group';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import {
  AccessMode,
  FlatPrincipal,
} from 'src/components/settings/permissions/types';
import {
  HasAccessInfo,
  OptionAvatar,
  OptionName,
  SearchGroupOption,
  SearchResultOption,
} from 'src/components/settings/styles/select';

const formatUserOption = (
  option: FlatPrincipal,
  context: 'menu' | 'value',
  message: string
) => {
  return (
    <SearchResultOption role="option">
      <OptionAvatar>
        <Avatar
          name="userAvatar"
          size={context === 'value' ? 'xsmall' : 'small'}
          src={option.avatar}
        />
      </OptionAvatar>
      <OptionName>{option.name}</OptionName>
      {option.hasAccess && <HasAccessInfo>{message}</HasAccessInfo>}
    </SearchResultOption>
  );
};

const formatGroupOption = (
  option: FlatPrincipal,
  context: 'menu' | 'value',
  message: string
) => (
  <SearchGroupOption role="option">
    <OptionAvatar>
      <GroupIcon
        label=""
        primaryColor={token('color.icon.inverse', colors.N0)}
        size={context === 'value' ? 'small' : 'medium'}
      />
    </OptionAvatar>
    <OptionName>{option.name}</OptionName>
    {option.hasAccess && <HasAccessInfo>{message}</HasAccessInfo>}
  </SearchGroupOption>
);

export const formatOptionLabel =
  (message: string) =>
  (
    option: FlatPrincipal & { isNew?: boolean },
    { context }: { context: 'menu' | 'value' }
  ): React.ReactNode => {
    if (option.isNew) {
      return <span>{option.name}</span>;
    }
    return option.mode === AccessMode.users
      ? formatUserOption(option, context, message)
      : formatGroupOption(option, context, message);
  };
