import React from 'react';

import Flag from '@atlaskit/flag';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { ComponentFlagId, HandleDismissedType } from 'src/redux/flags/types';
import { appendParamToPath } from 'src/urls/utils';

type Props = {
  id: ComponentFlagId;
  props: {
    bugReportLink?: string;
  };
} & HandleDismissedType;

export const InternalNewPageFlag = ({
  id,
  props,
  ...otherProps
}: Props): JSX.Element => {
  const { bugReportLink } = props;

  const newUrl = appendParamToPath({ spa: 0 });

  const actions = [
    {
      content: 'Take me to the old page',
      href: newUrl,
    },
  ];
  if (bugReportLink) {
    actions.push({
      content: 'Found a bug?',
      href: bugReportLink,
    });
  }

  return (
    <Flag
      {...otherProps}
      id={id}
      description="We are testing a new design of this page internally with fellow Atlassians. If you need to view the old page please click the link below."
      icon={
        <InfoIcon
          primaryColor={token('color.icon.information', colors.B300)}
          label="success"
        />
      }
      title="Like the new look?"
      actions={actions}
    />
  );
};
