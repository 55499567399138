import React, { Fragment, useCallback, useState, useMemo } from 'react';

import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Flag from '@atlaskit/flag';
import { ActionType } from '@atlaskit/flag/types';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import { HandleDismissedType } from 'src/redux/flags/types';
import scrollTo from 'src/redux/global/actions/scroll-to';
import { REFRESH_CODE_REVIEW_DATA_FROM_POLL_RESULTS } from 'src/redux/pull-request/actions';
import { PR_UPDATE_FLAG_ID } from 'src/redux/pull-request/sagas/poll-pullrequest-updates-saga';
import { getEditorState } from 'src/redux/pull-request/selectors';
import DiscardCommentsDialog from 'src/sections/repository/sections/pull-request/components/discard-comments-dialog';
import messages from 'src/sections/repository/sections/pull-request/pull-request.i18n';
import { PullRequestUpdatesResponse } from 'src/types/pull-request';

import { AppearanceTypes } from './simple/simple-flag';

type Props = {
  id: typeof PR_UPDATE_FLAG_ID;
  props?: {
    response: PullRequestUpdatesResponse;
  };
} & HandleDismissedType;

function getFlagText(response?: PullRequestUpdatesResponse): {
  title: FormattedMessage.MessageDescriptor;
  description?: FormattedMessage.MessageDescriptor;
} {
  if (response) {
    if (response.anchorMoved) {
      return {
        title: messages.anchorMovedTitle,
        description: messages.anchorMovedDescription,
      };
    }
  }
  return {
    title: messages.pullRequestUpdated,
  };
}

export const PullRequestUpdatedFlag = React.memo((props: Props) => {
  const { id, handleDismissed, ...flagProps } = props;
  const isEditorOpen = useSelector(getEditorState);
  const [showDiscardCommentsDialog, setShowDiscardCommentsDialog] =
    useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const handleUpdate = useCallback(() => {
    dispatch({ type: REFRESH_CODE_REVIEW_DATA_FROM_POLL_RESULTS });
    dispatch(
      scrollTo({
        targetId: 'root',
      })
    );
    handleDismissed(id);
  }, [dispatch, id, handleDismissed]);

  const refreshAction: ActionType = useMemo(
    () => ({
      content: intl.formatMessage(messages.reload),
      onClick: () => {
        if (isEditorOpen) {
          setShowDiscardCommentsDialog(true);
        } else {
          handleUpdate();
        }
      },
    }),
    [handleUpdate, intl, isEditorOpen]
  );

  const handleDiscardComments = useCallback(() => {
    setShowDiscardCommentsDialog(false);
    handleUpdate();
  }, [handleUpdate]);

  const handleDiscardCommentsCancel = useCallback(() => {
    setShowDiscardCommentsDialog(false);
  }, []);

  const { title, description } = getFlagText(flagProps.props?.response);
  const formattedTitle = title ? intl.formatMessage(title) : '';
  const formattedDescription = description
    ? intl.formatMessage(description)
    : '';

  return (
    <Fragment>
      <Flag
        id={id}
        title={formattedTitle}
        appearance={AppearanceTypes.normal}
        description={formattedDescription}
        icon={
          <InfoIcon
            label=""
            primaryColor={token('color.icon.discovery', colors.P300)}
          />
        }
        actions={[refreshAction]}
        {...flagProps}
      />
      {showDiscardCommentsDialog && (
        <DiscardCommentsDialog
          onCancel={handleDiscardCommentsCancel}
          onDiscard={handleDiscardComments}
        >
          <FormattedMessage
            {...messages.discardCommentsModalBody}
            tagName="p"
          />
        </DiscardCommentsDialog>
      )}
    </Fragment>
  );
});
