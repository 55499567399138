import { createResource } from 'react-resource-router';

import { RepositoryAssociationSummary } from 'src/components/pipelines/models';
import envBaseUrl from 'src/components/pipelines/utils/env-base-url';
import authRequest from 'src/utils/fetch';

export type Params = {
  repositoryFullSlug?: string;
  repositoryOwner?: string; // legacyPath
  repositorySlug?: string; // legacyPath
};

export function getRepositoryAssociationSummaryUrl(params: Params) {
  const {
    repositoryFullSlug = `${params.repositoryOwner}/${params.repositorySlug}`,
  } = params;
  return `${envBaseUrl(
    '/!api/internal'
  )}/repositories/${repositoryFullSlug}/pipelines_config/associations/summary`;
}

export const loadPipelinesRepositoryAssociationSummaryResource =
  createResource<RepositoryAssociationSummary>({
    type: 'pipelinesRepositoryAssociationSummary',
    getKey: ({ match }) => getRepositoryAssociationSummaryUrl(match.params),
    maxAge: 60000,
    getData: async ({ match }) => {
      const data = (await (
        await fetch(
          authRequest(getRepositoryAssociationSummaryUrl(match.params))
        )
      ).json()) as RepositoryAssociationSummary;
      return data;
    },
  });
