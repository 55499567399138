export enum CreateInvitationStatus {
  invited = 'invited',
  resent = 'resent',
  added = 'added',
  error = 'error',
}
export type CreateInvitationResponse = {
  [email: string]: {
    status: CreateInvitationStatus | keyof typeof CreateInvitationStatus;
    error?: string;
  };
};
