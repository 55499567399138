import { defineMessages } from 'react-intl';

export default defineMessages({
  colorAccessibilityLabel: {
    id: 'frontbucket.pullRequest.settings.colorAccessibilityFiled',
    description: 'Label for the "color accessibility" form field',
    defaultMessage: 'Enable color accessibility',
  },
  colorAccessibilityLabelCheckbox: {
    id: 'frontbucket.pullRequest.settings.colorAccessibilityFiledCheckbox',
    description: 'Label for the "color accessibility" form field',
    defaultMessage: 'Color accessibility',
  },
  filesRadioGroupSectionLabel: {
    id: 'frontbucket.pullRequest.filesRadioGroupSectionLabel',
    description:
      'Label for the "Diff" section header of "Diff view" radio group',
    defaultMessage: 'Diff',
  },
  diffViewModeLabel: {
    id: 'frontbucket.pullRequest.settings.diffViewModeLabel',
    description:
      'Label for the diff view mode form field ("unified" vs. "side by side")',
    defaultMessage: 'View',
  },
  showLabel: {
    id: 'frontbucket.pullRequest.settings.showLabel',
    description: '"Show" label that appears above the view mode checkboxes',
    defaultMessage: 'Show',
  },
  diffViewModeLabelSideBySide: {
    id: 'frontbucket.pullRequest.settings.diffViewModeLabelSideBySide',
    description: 'Label for the "side-by-side" diff view mode option',
    defaultMessage: 'Side-by-side',
  },
  diffViewModeLabelUnified: {
    id: 'frontbucket.pullRequest.settings.diffViewModeLabelUnified',
    description: 'Label for the "unified" diff view mode option',
    defaultMessage: 'Unified',
  },
  errorFlagDescription: {
    id: 'frontbucket.pullRequest.settings.errorFlagDescription',
    description:
      'The description text in the flag that appears after an attempt to update diff settings fails',
    defaultMessage:
      'Some of the updates to your diff settings may not have been saved.',
  },
  errorFlagRetryButton: {
    id: 'frontbucket.pullRequest.settings.errorFlagRetryButton',
    description:
      'A button in the flag that appears after an attempt to update diff settings fails. The button re-opens the settings modal.',
    defaultMessage: 'Try again',
  },
  errorFlagTitle: {
    id: 'frontbucket.pullRequest.settings.errorFlagTitle',
    description:
      'Title in the flag that appears after an attempt to update diff settings fails',
    defaultMessage: 'Something went wrong',
  },
  iconLabel: {
    id: 'frontbucket.pullRequest.settings.iconLabel',
    description: 'Label for the diff settings icon',
    defaultMessage: 'Open modal window: diff display settings',
  },
  diffSettingsButtonLabel: {
    id: 'frontbucket.pullRequest.settings.diffSettingsButtonLabel',
    description: 'Label for the diff settings button',
    defaultMessage: 'Global diff settings',
  },
  ignoreWhitespaceLabel: {
    id: 'frontbucket.pullRequest.settings.ignoreWhitespaceLabel',
    description: 'Label for the "ignore whitespace" form field',
    defaultMessage: 'Ignore whitespace',
  },
  ignoreWhitespaceLabelCheckbox: {
    id: 'frontbucket.pullRequest.settings.ignoreWhitespaceLabelCheckbox',
    description: 'Label for the "whitespace changes" form field',
    defaultMessage: 'Whitespace changes',
  },
  singleFileModeLabel: {
    id: 'frontbucket.pullRequest.settings.singleFileModeLabel',
    description: 'Label for the "load files individually" form field',
    defaultMessage: 'Load files individually',
  },
  singleFileModeRadioGroupLabel: {
    id: 'frontbucket.pullRequest.settings.singleFileModeRadioGroupLabel',
    description: 'Label for the "Load files" radio group',
    defaultMessage: 'Load files',
  },
  allFileModeRadioButtonLabel: {
    id: 'frontbucket.pullRequest.allFileModeRadioButtonLabel',
    description: 'Label for the "All at once" radio button',
    defaultMessage: 'All at once',
  },
  singleFileModeRadioButtonLabel: {
    id: 'frontbucket.pullRequest.singleFileModeRadioButtonLabel',
    description: 'Label for the "Individually" radio button',
    defaultMessage: 'Individually',
  },
  sidebarRadioGroupSectionLabel: {
    id: 'frontbucket.pullRequest.sidebarRadioGroupSectionLabel',
    description:
      'Label for the "Sidebar" section header of "File view" radio group',
    defaultMessage: 'Right Sidebar',
  },
  fileViewRadioGroupLabel: {
    id: 'frontbucket.pullRequest.fileViewRadioGroupLabel',
    description: 'Label for the "File view" radio group',
    defaultMessage: 'File view',
  },
  fileViewTreeRadioButtonLabel: {
    id: 'frontbucket.pullRequest.fileViewTreeRadioButtonLabel',
    description: 'Label for the "Tree" radio button',
    defaultMessage: 'Tree',
  },
  fileViewListRadioButtonLabel: {
    id: 'frontbucket.pullRequest.fileViewListRadioButtonLabel',
    description: 'Label for the "List" radio button',
    defaultMessage: 'List',
  },
  modalButton: {
    id: 'frontbucket.pullRequest.settings.modalButton',
    description: 'Button text for the global diff settings modal.',
    defaultMessage: 'Settings',
  },
  modalHeading: {
    id: 'frontbucket.pullRequest.settings.modalHeading',
    description: 'Heading text for the global diff settings modal.',
    defaultMessage: 'Global diff view settings',
  },
  successFlagDescription: {
    id: 'frontbucket.pullRequest.settings.successFlagDescription',
    description:
      'The description text in the flag that appears after succesfully updating diff settings',
    defaultMessage: 'Your diff settings have been updated.',
  },
  wordDiffLabel: {
    id: 'frontbucket.pullRequest.settings.wordDiffLabel',
    description: 'Label for the "enable word diff" form field',
    defaultMessage: 'Enable word diff',
  },
  wordDiffLabelCheckbox: {
    id: 'frontbucket.pullRequest.settings.wordDiffLabelCheckbox',
    description: 'Label for the "Word diff" form field',
    defaultMessage: 'Word diff',
  },
  annotationsLabel: {
    id: 'frontbucket.pullRequest.settings.annotationsLabel',
    description: 'Label for the "enable annotations" form field',
    defaultMessage: 'Enable annotations',
  },
  annotationsLabelCheckbox: {
    id: 'frontbucket.pullRequest.settings.annotationsLabelCheckbox',
    description: 'Label for the "Annotations" form field',
    defaultMessage: 'Annotations',
  },
  tabSizePrefLabel: {
    id: 'frontbucket.pullRequest.settings.tabSizeLabel',
    description: 'Label for the "tab size" form field',
    defaultMessage: 'Tab size',
  },
  tabSizeOptionLabel: {
    id: 'frontbucket.pullRequest.settings.tabSizeOptionLabel',
    description: 'Label for the "tab size" option',
    defaultMessage:
      '{tabWidth, plural, one {{tabWidth} space} other {{tabWidth} spaces}}',
  },
  diffViewLabel: {
    id: 'frontbucket.pullRequest.settings.diffViewLabel',
    description:
      'Label for the diff view mode form field ("unified" vs. "side by side")',
    defaultMessage: 'Diff view',
  },
});
