import React, { useContext } from 'react';

import UFOCustomData from '@atlassian/react-ufo/custom-data';

import { CurrentDataContext } from 'src/app/current-data';
import { AppDataContext } from 'src/app/data';
import { uncurlyUuid } from 'src/components/analytics';

/* eslint @typescript-eslint/ban-types: "warn" */
export const BitbucketCustomData: React.FC = () => {
  const { user } = useContext(AppDataContext);
  const { repository } = useContext(CurrentDataContext);

  const data: {
    repositoryId?: string;
    isAtlassian: boolean;
    isBitbucket: boolean;
  } = {
    isAtlassian: user?.isAtlassian ?? false,
    isBitbucket: user?.isBitbucket ?? false,
  };
  if (repository?.uuid) {
    data.repositoryId = uncurlyUuid(repository.uuid);
  }

  return <UFOCustomData data={data} />;
};
