import { EXPAND_CONTEXT } from './constants';

export type ExpandContextParams = {
  filepath: string;
  prevFilepath?: string;
  fileIndex: number;
  expanderIndex: number;
  shouldExpandDown: boolean;
  selectedDiffSourceHash?: string;
  selectedDiffDestHash?: string;
};

export type ExpandContextAction = {
  type: string;
  payload: ExpandContextParams;
};

const expandContext = (expandContextParams: ExpandContextParams) => ({
  type: EXPAND_CONTEXT.REQUEST,
  payload: expandContextParams,
});

export default expandContext;
