import { defineMessages } from 'react-intl';

export default defineMessages({
  success: {
    id: 'frontbucket.hooks.flag.success',
    description: 'Message for success flag title',
    defaultMessage: 'Success',
  },
  error: {
    id: 'frontbucket.hooks.flag.error',
    description: 'Message for error flag title',
    defaultMessage: 'Error',
  },
  info: {
    id: 'frontbucket.hooks.flag.info',
    description: 'Message for info flag title',
    defaultMessage: 'Important information',
  },
  warning: {
    id: 'frontbucket.hooks.flag.warning',
    description: 'Message for info flag title',
    defaultMessage: 'Warning',
  },
  normal: {
    id: 'frontbucket.hooks.flag.normal',
    description: 'Message for normal flag title',
    defaultMessage: 'Attention',
  },
});
