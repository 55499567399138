import React from 'react';

import {
  Link as ResourceRouterLink,
  LinkProps as ResourceRouterLinkProps,
} from 'react-resource-router';

export type RouterLinkProps = ResourceRouterLinkProps &
  Omit<
    React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    'ref'
  > & {
    innerRef?: React.Ref<HTMLAnchorElement>;
  };

const Link = (props: RouterLinkProps) => {
  const { innerRef, ...resourceRouterProps } = props;
  return (
    <ResourceRouterLink
      ref={innerRef}
      {...(resourceRouterProps as ResourceRouterLinkProps)}
      // Override type="button"; explicitly force anchor links with react-resource-router
      // https://github.com/atlassian-labs/react-resource-router/blob/master/src/ui/link/utils/get-valid-link-type.tsx#L6
      type="a"
    />
  );
};
export default Link;
