import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';

export const RepoSettingsMergeStrategiesLoadable = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      /* webpackChunkName: "repo-settings-merge-strategies" */
      './merge-strategies'
    ),
});
