import { UP_TO_SMALL_BREAKPOINT_ACTIVE_WIDTH } from 'src/sections/global/constants';

// Note: do not use window.screen.width here because Chromedriver
// does not set that value reliably.
export const isUpToSmallBreakpoint = () => {
  const clientWidth = document?.documentElement?.clientWidth || 0;
  const innerWidth = window.innerWidth || 0;
  const windowWidth =
    clientWidth && innerWidth
      ? Math.min(clientWidth, innerWidth)
      : Math.max(clientWidth, innerWidth);
  return windowWidth < UP_TO_SMALL_BREAKPOINT_ACTIVE_WIDTH;
};
