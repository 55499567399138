import {LoggerAPI} from './LoggerAPI';

class DefaultLoggerAPI implements LoggerAPI {

  debug(message?: string, ...optionalParams: any[]): void {
    console.debug.apply(console, arguments);
  }

  info(message?: string, ...optionalParams: any[]): void {
    console.info.apply(console, arguments);
  }

  warn(message?: string, ...optionalParams: any[]): void {
    console.warn.apply(console, arguments);
  }

  error(message?: string, ...optionalParams: any[]): void {
    console.error.apply(console, arguments);
  }

}

export default new DefaultLoggerAPI() as LoggerAPI;
