import React, { useEffect } from 'react';

import Flag, { AutoDismissFlag } from '@atlaskit/flag';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import Error from '@atlaskit/icon/glyph/error';
import Info from '@atlaskit/icon/glyph/info';
import Warning from '@atlaskit/icon/glyph/warning';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import { FlagText, SimpleFlagProps } from 'src/redux/flags/types';
import { publishFact, publishScreenEvent } from 'src/utils/analytics/publish';

import { FlagViewed } from '../../../facts';

import * as styles from './simple-flag.style';

export enum AppearanceTypes {
  error = 'error',
  info = 'info',
  normal = 'normal',
  success = 'success',
  warning = 'warning',
}

const icons: { [K in AppearanceTypes]: React.ReactElement } = {
  error: (
    <Error
      label="Error"
      primaryColor={token('color.icon.danger', colors.R300)}
    />
  ),
  info: (
    <Info
      label="Info"
      primaryColor={token('color.icon.discovery', colors.P300)}
    />
  ),
  normal: (
    <Info
      label="Info"
      primaryColor={token('color.icon.discovery', colors.P300)}
    />
  ),
  success: (
    <CheckCircleIcon
      label="Success"
      primaryColor={token('color.icon.success', colors.G300)}
    />
  ),
  warning: (
    <Warning
      label="Warning"
      primaryColor={token('color.icon.warning', colors.Y300)}
    />
  ),
};

const SimpleFlag = React.memo((props: SimpleFlagProps) => {
  const {
    actions,
    nonIntlActions, // ability to pass in non-Intl actions for Forge
    autoDismiss,
    coloredAppearance,
    description,
    iconType,
    id,
    title,
    props: additionalProps = {},
    // FlagGroup passes other props through to its Children that need to be preserved
    ...akProps
  } = props;

  const intl = useIntl();

  useEffect(() => {
    publishFact(new FlagViewed({ flag_id: id }));
    publishScreenEvent('siteMessageFlag', { flag_id: id });
  }, [id]);

  const extractIntlMessage = (text: FlagText) =>
    typeof text === 'string' ? text : intl.formatMessage(text.msg, text.values);

  const intlActions = actions
    ? actions.map(action => ({
        content: extractIntlMessage(action.content),
        href: action.href,
        target: action.target,
        onClick: action.onClick,
      }))
    : undefined;

  const intlTitle = extractIntlMessage(title);
  const intlDescription = description ? (
    <styles.SimpleFlagDescription>
      {extractIntlMessage(description)}
    </styles.SimpleFlagDescription>
  ) : undefined;

  const flagProps: React.ComponentProps<typeof Flag> = {
    ...akProps,
    actions: nonIntlActions || intlActions,
    appearance: coloredAppearance ? iconType : 'normal',
    description: intlDescription,
    icon: iconType ? icons[iconType] : icons.normal,
    id,
    title: intlTitle,
    // any Flag props explicitly passed to the SimpleFlag component
    // will take precedent over other props computed here
    ...additionalProps,
  };

  return autoDismiss ? (
    <AutoDismissFlag key={id} {...flagProps} />
  ) : (
    <Flag key={id} {...flagProps} />
  );
});

export default SimpleFlag;
