import {DialogProvider} from '../DialogProvider';
import {DialogSize} from '../DialogCreationOptions';
import ValidationUtil from '../../../definitions/validation/ValidationUtil';

/**
 * This class validates an instance of DialogProvider. This is intended to be used by consumers of this library in
 * their tests to validate their implementations. Knowing products have incorporated this into their test suite will
 * allow breaking changes to be introduced in the library.
 */
export class DialogProviderValidator {

  public static validate(dialogProvider: DialogProvider): void {
    ValidationUtil.checkFunctionExists('dialogProvider', dialogProvider, 'create');
    ValidationUtil.checkFunctionExists('dialogProvider', dialogProvider, 'close');
    ValidationUtil.checkFunctionExists('dialogProvider', dialogProvider, 'setButtonDisabled');
    ValidationUtil.checkFunctionExists('dialogProvider', dialogProvider, 'isButtonDisabled');
    ValidationUtil.checkFunctionExists('dialogProvider', dialogProvider, 'toggleButton');
    ValidationUtil.checkFunctionExists('dialogProvider', dialogProvider, 'setButtonHidden');
    ValidationUtil.checkFunctionExists('dialogProvider', dialogProvider, 'isButtonHidden');
    ValidationUtil.checkFunctionExists('dialogProvider', dialogProvider, 'isActiveDialog');

    let actionClicked = false;
    const myAction = {
      identifier: 'my-action',
      text: 'Some Action',
      onClick: () => {
        actionClicked = true;
      }
    };
    const dialogCreationOptions = {
      key: 'my-dialog',
      size: 'medium' as DialogSize,
      width: '100%',
      height: '50px',
      chrome: false,
      header: 'My header',
      actions: [myAction],
      closeOnEscape: true,
      hint: 'this is a test',
    };
    const bogus_addon_key = 'bogus_app';
    const addon_key = 'foo-app';
    const dialogExtension = {
      addon_key: addon_key,
      key: 'foo-key',
      options: {}
    };
    const dialog = dialogProvider.create(dialogCreationOptions, dialogExtension);

    // Allow contenet resolver promises to complete
    setTimeout(() => {
      // TODO: Fix under ACJS-735
      // ValidationUtil.checkExists('dialog', dialog);
      // ValidationUtil.checkFunctionExists('dialog', dialog, 'on');

      // Check the dialog is active...
      const isActiveAfterCreation = dialogProvider.isActiveDialog(addon_key);
      if (!isActiveAfterCreation) {
        throw new Error('The dialog should be active');
      }

      // Check the active status returns false for another add-on...
      const isAnotherAppsDialogActive = dialogProvider.isActiveDialog(bogus_addon_key);
      if (isAnotherAppsDialogActive) {
        throw new Error('The dialog should not be active');
      }

      // Check the enabled state of a button...
      if (dialogProvider.isButtonDisabled('my-action')) {
        throw new Error('Button should be enabled by default');
      }

      // Disable a button...
      dialogProvider.setButtonDisabled('my-action', true);
      if (!dialogProvider.isButtonDisabled('my-action')) {
        throw new Error('Button should have been disabled.');
      }

      // Re-enabled a button...
      dialogProvider.toggleButton('my-action');
      if (dialogProvider.isButtonDisabled('my-action')) {
        throw new Error('Button should have been re-enabled.');
      }

      // Check the visibility of a button...
      if (dialogProvider.isButtonHidden('my-action')) {
        throw new Error('Button should be visible by default');
      }

      // Hide a button...
      dialogProvider.setButtonHidden('my-action', true);
      if (!dialogProvider.isButtonHidden('my-action')) {
        throw new Error('Button should have been hidden.');
      }

      dialogProvider.close();

    },0);
  }

}
