import React from 'react';

import { AutoDismissFlag } from '@atlaskit/flag';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import { ComponentFlagProps } from 'src/redux/flags/types';

import messages from './revert-pull-request-error-flag.i18n';

export const RevertPullRequestErrorFlag = React.memo(
  ({ id, ...otherProps }: ComponentFlagProps) => {
    const intl = useIntl();
    return (
      <AutoDismissFlag
        {...otherProps}
        id={id}
        icon={
          <ErrorIcon
            label="error"
            primaryColor={token('color.icon.danger', colors.R300)}
          />
        }
        title={intl.formatMessage(messages.title)}
        description={intl.formatMessage(messages.description)}
        actions={[
          {
            content: intl.formatMessage(messages.learnMore),
            href: 'https://www.atlassian.com/git/tutorials/undoing-changes/git-revert',
            target: '_blank',
          },
        ]}
      />
    );
  }
);
