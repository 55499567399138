/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { call, select } from 'redux-saga/effects';

import urls from 'src/components/navigation/src/urls';
import { getCurrentUserIsAnonymous } from 'src/selectors/user-selectors';
import authRequest from 'src/utils/fetch';
import { captureMessageForResponse } from 'src/utils/sentry';

export function* fetchAvailableJiraSitesSaga() {
  const isAnonymousUser: boolean = yield select(getCurrentUserIsAnonymous);
  if (isAnonymousUser) {
    return [];
  }

  const url = urls.api.gateway.aisAvailableProducts();
  const request = authRequest(url);
  const response: Response = yield call(fetch, request);

  if (response.ok) {
    // @ts-ignore
    const data = yield response.json();
    // @ts-ignore TODO: fix noImplicitAny error here
    const sites = data.sites.filter(s =>
      // @ts-ignore TODO: fix noImplicitAny error here
      s.products.find(p => p === 'jira-software.ondemand')
    );
    // @ts-ignore TODO: fix noImplicitAny error here
    sites.sort((a, b) => a.displayName.localeCompare(b.displayName));
    return sites;
  } else if (response.status === 401) {
    return undefined;
  } else {
    yield captureMessageForResponse(
      response,
      'Fetching dashboard Jira sites failed'
    );
    return undefined;
  }
}
