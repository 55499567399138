import {InlineDialogProvider} from '../InlineDialogProvider';
import ValidationUtil from '../../../definitions/validation/ValidationUtil';
import {TestAppContext} from '../../../definitions/_tests/TestAppContext';

/**
 * This class validates an instance of InlineDialogProvider. This is intended to be used by consumers of this library in
 * their tests to validate their implementations. Knowing products have incorporated this into their test suite will
 * allow breaking changes to be introduced in the library.
 */
export class InlineDialogProviderValidator {

  public static validate(inlineDialogProvider: InlineDialogProvider): void {
    ValidationUtil.checkFunctionExists('inlineDialogProvider', inlineDialogProvider, 'hide');

    const appContext = TestAppContext.buildNewInstance();
    inlineDialogProvider.hide(appContext);
  }

}
