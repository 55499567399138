const getEntryByName = (name: string) => {
  if (window.performance && window.performance.getEntriesByName) {
    const entries = window.performance.getEntriesByName(name);
    return entries && entries[0];
  }

  return null;
};
export const getMark = (name: string): number | undefined => {
  const mark = getEntryByName(name);
  if (mark) {
    return mark.startTime;
  }
  // ./src/ssr/services/metrics/utils.ts uses a namespace for the performance
  // mark when rendering SSR. If we didn't find an entry already, try with the
  // default namespace
  const namespacedMark = getEntryByName(`SSR_SPA_${name}`);
  if (namespacedMark) {
    return namespacedMark.startTime;
  }
  return undefined;
};
