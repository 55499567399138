import { ApiComment } from 'src/components/conversation-provider/types';
import { PullRequest } from 'src/components/types';
import { publishTrackEvent } from 'src/utils/analytics/publish';

type PullRequestAction = 'MERGED' | 'DECLINED';

export const publishPullRequestEvent = (
  prAction: PullRequestAction,
  updatedPullRequest: PullRequest,
  comments: ApiComment[],
  paid: boolean,
  repoUuid: string,
  role: string | null
) => {
  // pull request closed_on field definitely exists since it is set when a pull request is merged or declined
  const closedOn = new Date(updatedPullRequest.closed_on!);
  const createdOn = new Date(updatedPullRequest.created_on);
  const hoursOpen = (closedOn.valueOf() - createdOn.valueOf()) / (3600 * 1000);

  const numComments = updatedPullRequest.comment_count;
  const pullRequestLocalId = updatedPullRequest.id;

  // We only want to count comments that don't have parents, as those are the top-level comments that can be resolved
  const numResolvedConvos = comments.filter(
    comment => !comment.parent && comment.resolution
  ).length;
  const numUnresolvedConvos = comments.filter(
    comment => !comment.parent && !comment.resolution
  ).length;

  let action = '';
  let actionSubjectId = '';

  if (prAction === 'DECLINED') {
    action = 'declined';
    actionSubjectId = 'pullRequestDeclined';
  } else if (prAction === 'MERGED') {
    action = 'merged';
    actionSubjectId = 'pullRequestMerged';
  }

  // COREX-5987: include number of unresolved/resolved comments once functionality is added (TODO)
  publishTrackEvent({
    action,
    actionSubject: 'pullRequest',
    actionSubjectId,
    source: 'pullRequestScreen',
    attributes: {
      role,
      paid,
      numUnresolvedConvos,
      numResolvedConvos,
      numComments,
      hoursOpen,
      pullRequestLocalId,
      repoUuid,
    },
  });
};
