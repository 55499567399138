/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { takeLatest, call, select, all } from 'redux-saga/effects';

import { ToggleSearchDrawerFact } from 'src/sections/global/facts';
import { getIsSearchDrawerOpen } from 'src/selectors/global-selectors';
import {
  publishFact,
  publishScreenEvent,
  publishUiEvent,
} from 'src/utils/analytics/publish';

import { TOGGLE_CREATE_DRAWER, TOGGLE_SEARCH_DRAWER } from '../actions';

export function* toggleSearchDrawer() {
  const isSearchDrawerOpen: boolean = yield select(getIsSearchDrawerOpen);

  yield call(
    publishFact,
    new ToggleSearchDrawerFact({
      action: isSearchDrawerOpen ? 'opened' : 'closed',
    })
  );

  yield call(publishUiEvent, {
    action: 'clicked',
    actionSubject: 'button',
    actionSubjectId: `searchDrawer${isSearchDrawerOpen ? 'Opened' : 'Closed'}`,
    source: 'navigation',
  });

  if (isSearchDrawerOpen) {
    yield call(publishScreenEvent, 'globalSearchDrawer');
  }
}

// @ts-ignore TODO: fix noImplicitAny error here
export function* toggleCreateDrawer(action) {
  const { payload } = action;
  if (payload) {
    yield call(publishScreenEvent, 'globalCreateDrawer');
  }
}

function* drawerSagas() {
  yield all([
    takeLatest(TOGGLE_SEARCH_DRAWER, toggleSearchDrawer),
    takeLatest(TOGGLE_CREATE_DRAWER, toggleCreateDrawer),
  ]);
}

export default drawerSagas;
