import { createResource } from 'react-resource-router';

import {
  setPipelines,
  setPipelinesLoading,
} from 'src/components/pipelines/redux/actions/pipelines';
import { getPipelinesUrl } from 'src/components/pipelines/redux/sagas/pipelines';
import { buildPipelineFilterQuery } from 'src/components/pipelines/utils/pipeline-filter-query';
import { ResourceContext } from 'src/router/types';

import { fetchData } from './utils';

export type Params = {
  repositoryFullSlug: string;
  branch: string;
  page: string;
};

export const getUrl = (params: Params, url: string): string => {
  return getPipelinesUrl(
    params.repositoryFullSlug,
    buildPipelineFilterQuery(params, url)
  );
};

export const loadPipelinesResource = createResource({
  type: 'pipelines',
  getKey: ({ match: { params, url } }) => getUrl(params as Params, url),
  maxAge: 0,
  getData: async (
    { match: { params, url } },
    { reduxStore }: ResourceContext
  ) => {
    const { dispatch, getState } = reduxStore;
    dispatch(setPipelinesLoading());
    const { fetchCount } = getState().pipelines.pipelines;
    const data = await fetchData(
      getUrl(params as Params, url),
      'pipelines_resource_exception'
    );
    dispatch(setPipelines(data, fetchCount));
    return { status: 'success' };
  },
});
