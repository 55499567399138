// Babel doesn't work well with extending built-in classes like `Error`.
// Instead, we create a custom error using prototypal JS, though TypeScript
// doesn't like this as much

export type HttpError = Error & {
  status: number;
};

export default function HttpRequestError(
  status: number,
  message: string
): HttpError {
  const instance: HttpError = Reflect.construct(Error, [message]) as HttpError;
  instance.status = status;
  Reflect.setPrototypeOf(instance, Reflect.getPrototypeOf(this));
  return instance;
}

HttpRequestError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: Error,
    enumerable: false,
    writable: true,
    configurable: true,
  },
});

export const InternalServerError = new (HttpRequestError as any)(
  500,
  'Internal Server Error'
);

export const BadRequest = new (HttpRequestError as any)(400, 'Bad Request');
