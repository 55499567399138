import React from 'react';

import { defineMessages } from 'react-intl';

import { Checkbox } from '@atlaskit/checkbox';

import { useAnalytics } from 'src/components/settings/analytics';
import { useIntl } from 'src/hooks/intl';

import { BulkActions } from './bulk-actions';
import { useActions } from './provider';
import { HeaderCheckbox } from './styled';
import { PermissionsState } from './types';

export const messages = defineMessages({
  nameHeader: {
    id: 'frontbucket.settings.permissions.table.nameHeader',
    description: 'Table header for Name column',
    defaultMessage: 'Name',
  },
  permissionHeader: {
    id: 'frontbucket.settings.permissions.table.permissionHeader',
    description: 'Table header for Permission column',
    defaultMessage: 'Permission',
  },
  accessLevelHeader: {
    id: 'frontbucket.settings.permissions.table.accessLevelHeader',
    description: 'Table header for Access level column',
    defaultMessage: 'Access level',
  },
  actionsHeader: {
    id: 'frontbucket.settings.permissions.table.actionsHeader',
    description: 'Table header for Actions column',
    defaultMessage: 'Actions',
  },
});

type Props = Pick<PermissionsState, 'limit'> & {
  checkedCount: number;
  checkedCountCurrentPage: number;
  isDisabled?: boolean;
  showAccessLevel: boolean;
};

export const useTableHeader = ({
  checkedCount,
  checkedCountCurrentPage,
  limit,
  isDisabled,
  showAccessLevel,
}: Props) => {
  const { formatMessage } = useIntl();
  const { publishClickEvent } = useAnalytics();
  const { selectAll } = useActions();
  const isChecked = checkedCountCurrentPage > 0;
  const checkbox = (
    <HeaderCheckbox>
      <Checkbox
        isChecked={isChecked}
        isIndeterminate={checkedCountCurrentPage < limit}
        onChange={() => {
          selectAll(!isChecked);
          publishClickEvent({
            name: 'SelectAll',
            actionSubject: 'checkbox',
          });
        }}
        isDisabled={isDisabled}
        testId="bulk-checkbox"
      />
    </HeaderCheckbox>
  );
  return {
    actions: checkedCount > 0 && (
      <BulkActions checkedCount={checkedCount}>{checkbox}</BulkActions>
    ),
    header: {
      cells: [
        {
          key: 'checkbox',
          content: checkedCount <= 0 && checkbox,
          width: 3,
        },
        {
          key: 'name',
          content: checkedCount <= 0 && formatMessage(messages.nameHeader),
          width: showAccessLevel ? 32 : 52,
        },
        {
          key: 'permission',
          content:
            checkedCount <= 0 && formatMessage(messages.permissionHeader),
          width: 20,
        },
        ...(showAccessLevel
          ? [
              {
                key: 'level',
                content:
                  checkedCount <= 0 &&
                  formatMessage(messages.accessLevelHeader),
                width: 20,
              },
            ]
          : []),
        {
          key: 'actions',
          content: checkedCount <= 0 && formatMessage(messages.actionsHeader),
          width: 15,
        },
      ],
    },
  };
};
