import { createResource } from 'react-resource-router';

import { FeatureKeys } from '@atlassian/bitbucket-features';

import {
  customMergeChecksConfigApi,
  customMergeChecksConfigApiV3,
} from 'src/components/settings/merge-checks/requests';
import { ResourceContext } from 'src/router/types';
import { fetchWorkspacePlan } from 'src/sections/repository/actions/fetch-repository-details';
import repoUrls from 'src/sections/repository/urls';
import { getFeatures } from 'src/selectors/feature-selectors';
import { getIsCustomMergeChecksEnabled } from 'src/selectors/global-selectors';
import {
  CustomMergeCheckConfigRouteResource,
  CustomMergeCheckConfigurationState,
  CustomMergeCheckKey,
  CustomMergeChecksResourceType,
} from 'src/types/custom-merge-checks';
import projectUrls from 'src/urls/projects';
import workspaceUrls from 'src/urls/workspaces';
import {
  customMergeCheckKey,
  determineResourceType,
} from 'src/utils/custom-merge-checks';

export const customMergeChecksConfigRouteResource =
  createResource<CustomMergeCheckConfigRouteResource>({
    type: 'custom-merge-checks-configuration',
    getKey: ({ match }) => {
      const { repositoryOwner, repositorySlug, workspaceSlug, projectKey } =
        match.params;
      if (repositorySlug) {
        // repository settings page
        return `${repositoryOwner}/${repositorySlug}/custom-merge-checks-repository-config`;
      } else if (projectKey) {
        // project settings page
        return `${workspaceSlug}/${projectKey}/custom-merge-checks-project-config`;
      } else {
        // workspace settings page
        return `${workspaceSlug}/custom-merge-checks-workspace-config`;
      }
    },
    maxAge: 60000, // cache for 1 minute so navigating between pages is fast
    getData: async ({ match }, { reduxStore }: ResourceContext) => {
      const { repositoryOwner, repositorySlug, workspaceSlug, projectKey } =
        match.params;

      let resourceType: CustomMergeChecksResourceType | undefined = undefined;
      if (repositorySlug) {
        resourceType = CustomMergeChecksResourceType.Repository;
      } else if (projectKey) {
        resourceType = CustomMergeChecksResourceType.Project;
      } else if (workspaceSlug) {
        resourceType = CustomMergeChecksResourceType.Workspace;
      }

      if (resourceType === undefined) {
        throw new Error('Invalid custom merge check settings route');
      }

      const state = reduxStore.getState();

      /**
       * We fetch the workspace plan (which includes the workspace's premium
       * status) as a repository navigation effect. This will not trigger if
       * we're on the project/workspace settings pages. So we need to
       * explicitly dispatch the `fetchWorkspacePlan` action to trigger the
       * fetch in those cases.
       *
       * `workspaceSlug` is only set when we're on the project/workspace
       * settings routes.
       *
       * `state.workspacePlan.isFetchWorkspacePlanLoading` is true initially,
       * and is set to false once the fetch completes either successfully or
       * with an error.
       */
      const shouldFetchWorkspacePlan =
        workspaceSlug &&
        (state.workspacePlan.isFetchWorkspacePlanLoading ||
          state.workspacePlan.isFetchWorkspacePlanError);
      if (shouldFetchWorkspacePlan) {
        reduxStore.dispatch(fetchWorkspacePlan(workspaceSlug));
      }

      const stateMap = new Map<
        CustomMergeCheckKey,
        CustomMergeCheckConfigurationState
      >();
      const emptyState = {
        branchConfigs: [],
        stateMap,
        enabledChecks: [],
      };

      if (!getIsCustomMergeChecksEnabled(state)) {
        return emptyState;
      }

      const projectWorkspaceCustomMergeChecksEnabled =
        getFeatures(state)[FeatureKeys.projectWorkspaceCustomMergeChecks];

      if (
        !projectWorkspaceCustomMergeChecksEnabled &&
        (resourceType === CustomMergeChecksResourceType.Project ||
          resourceType === CustomMergeChecksResourceType.Workspace)
      ) {
        return emptyState;
      }

      let apiBaseUrl: string;
      switch (resourceType) {
        case CustomMergeChecksResourceType.Repository:
          apiBaseUrl = projectWorkspaceCustomMergeChecksEnabled
            ? repoUrls.api.internal.customMergeChecksV3(
                repositoryOwner!,
                repositorySlug!
              )
            : repoUrls.api.internal.customMergeChecks(
                repositoryOwner!,
                repositorySlug!
              );
          break;
        // No need to check the FF for project/workspace resource types
        // since we always return an empty state above if the FF is off
        case CustomMergeChecksResourceType.Project:
          apiBaseUrl = projectUrls.api.internal.customMergeChecksV3(
            workspaceSlug!,
            projectKey!
          );
          break;
        case CustomMergeChecksResourceType.Workspace:
          apiBaseUrl = workspaceUrls.api.internal.customMergeChecksV3(
            workspaceSlug!
          );
          break;
      }

      const { getBranchConfigs, getCustomMergeChecks } =
        projectWorkspaceCustomMergeChecksEnabled
          ? customMergeChecksConfigApiV3(apiBaseUrl)
          : customMergeChecksConfigApi(apiBaseUrl);

      const [branchConfigs, enabledChecks] = await Promise.all([
        getBranchConfigs(),
        getCustomMergeChecks(),
      ]);

      // stateMap is only used in the old UI
      if (!projectWorkspaceCustomMergeChecksEnabled) {
        for (const customMergeCheck of enabledChecks) {
          const key = customMergeCheckKey(
            determineResourceType(customMergeCheck.resource),
            customMergeCheck.branchConfig,
            customMergeCheck.extensionId
          );
          stateMap.set(key, customMergeCheck.state);
        }
      }

      return {
        branchConfigs,
        stateMap,
        // enabledChecks is only used in the new UI
        enabledChecks: projectWorkspaceCustomMergeChecksEnabled
          ? enabledChecks
          : [],
      };
    },
  });
