/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { select, call, put } from 'redux-saga/effects';
import { Selector } from 'reselect';

import { Repository } from 'src/components/types';
import { AsyncAction } from 'src/redux/actions';
import { addStatuses, RepositoryBuildStatus } from 'src/redux/global/utils';
import { repository as repositorySchema } from 'src/sections/repository/schemas';
import urls from 'src/urls/dashboard';
import authRequest from 'src/utils/fetch';

export function createGetRepositoryBuildStatuses(
  action: AsyncAction,
  repositorySelector: Selector<any, Repository[]>
) {
  return function* getRepositoryBuildStatusesSaga() {
    // @ts-ignore
    const repositories = yield select(repositorySelector);

    if (repositories.length === 0) {
      return;
    }

    const url = urls.api.internal.repositoryBuildStatuses();

    try {
      const request = authRequest(url, {
        method: 'POST',
        // @ts-ignore TODO: fix noImplicitAny error here
        body: repositories.map(r => `uuid=${r.uuid}`).join('&'),
        headers: {
          'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      });

      const response: Response = yield call(fetch, request);

      if (response.ok) {
        const statuses: RepositoryBuildStatus[] = yield response.json() || [];

        const updatedRepositories = addStatuses(repositories, statuses);

        // normalizr will handle this action
        yield put({
          type: action.SUCCESS,
          payload: updatedRepositories,
          meta: {
            schema: [repositorySchema],
          },
        });
      }
    } catch (e) {
      yield put({
        type: action.ERROR,
        payload: e,
      });
    }
  };
}
