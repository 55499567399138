import { version as reactVersion } from 'react';

import { useRouter } from 'react-resource-router';

import { browserMetrics } from '@atlassian/browser-metrics';

import { getMark } from 'src/components/react-ufo/utils/getMark';
import { PERFORMANCE_MARK_SSR_HTML } from 'src/ssr/services/metrics/constants';
import { analyticsClient } from 'src/utils/analytics/client';
import { appWasServerSideRendered } from 'src/utils/ssr';

import { SessionData } from './types';
import {
  mapResources,
  sanitiseEndpoints,
  bundleEvalTimings,
} from './utils/plugins';

export const initializeBrowserMetrics = (sessionData: SessionData): void => {
  const { isAtlassian, featureFlagClient, frontbucket_version } = sessionData;

  browserMetrics.init({
    info: {
      app: {
        framework: {
          name: 'react',
          version: reactVersion,
        },
        version: {
          web: frontbucket_version,
        },
      },
      hostname: window.location.hostname,
      product: 'bitbucket',
      region: 'unknown',
    },
    endpoints: {
      eventPipelineClient: Promise.resolve(analyticsClient()),
    },
    events: {
      all: {
        custom: {
          ssr: appWasServerSideRendered(),
          isAtlassian,
        },
      },
    },

    plugins: {
      featureFlags: {
        client: Promise.resolve({
          getValue: (featureFlagKey: string) =>
            featureFlagClient.getFlagValue(featureFlagKey, false),
        }),
      },
      resourceTimings: {
        mapResources,
        sanitiseEndpoints,
      },
      bundleEvalTimings,
    },
    ssr: {
      getDoneMark: () => getMark(PERFORMANCE_MARK_SSR_HTML) ?? null,
      getTimings: () => null,
    },
    enableReactUFOTracker: true,
  });

  // We should start the page load metric immediately, outside of the React
  // render cycle. Only this first page load is marked as `isInitial`;
  browserMetrics.startPageLoad({ isInitial: true });
};

// This hook watches for route changes to start page load metrics for
// BrowserMetrics. It should be called from the app root.
export const useBrowserMetricsWatcher = (): void => {
  const [routerState] = useRouter();

  // From my testing, the only actions that should start metrics are PUSH
  // events, when a user clicks a link and we append a value to the browser
  // history. Page loads have some combination of POP and REPLACE actions (and
  // those are already tracked separately above). Some client links also have
  // REPLACE actions, but that is after the initial PUSH action (and we want to
  // start tracking from the very first event).
  if (routerState.action === 'PUSH') {
    browserMetrics.startPageLoad();
  }
};
