import { denormalize } from 'normalizr';
import createCachedSelector from 're-reselect';
import { createSelector, Selector } from 'reselect';

import { User } from 'src/components/types';
import { EntitiesState } from 'src/redux/entities/entities';
import { GlobalState } from 'src/redux/global/reducer';
import { user as userSchema } from 'src/sections/profile/schemas';
import { BucketState } from 'src/types/state';

import { getEntities, getGlobal } from './state-slicing-selectors';

export const getCurrentUserKey: Selector<
  BucketState,
  string | null | undefined
> = createSelector(getGlobal, (state: GlobalState) => state.currentUser);

export const getTargetUserKey: Selector<
  BucketState,
  string | null | undefined
> = createSelector(getGlobal, (state: GlobalState) => state.targetUser);

export const getCurrentUser: Selector<BucketState, User | null | undefined> =
  createSelector(getCurrentUserKey, getEntities, (key, entities) =>
    denormalize(key, userSchema, entities)
  );

export const getTargetUser: Selector<BucketState, User | null | undefined> =
  createSelector(getTargetUserKey, getEntities, (key, entities) =>
    denormalize(key, userSchema, entities)
  );

export const getCurrentUserDefaultCloneProtocol: Selector<BucketState, string> =
  createSelector(getCurrentUser, (user: User | null | undefined) =>
    user && user.extra && user.extra.has_ssh_key ? 'ssh' : 'https'
  );

export const getCurrentUserIsAnonymous: Selector<BucketState, boolean> =
  createSelector(getGlobal, (state: GlobalState) => !state.currentUser);

export const getCurrentUserAccountId = createSelector(
  getCurrentUser,
  user => user?.account_id
);

export const getCurrentUserUuid = createSelector(
  getCurrentUser,
  user => user?.uuid
);

export const getUserUuidToAaidMap = createCachedSelector(
  (state: BucketState) => getEntities(state),
  (_state: never, uuids: string[]) => uuids,
  (entities: EntitiesState, uuids: string[]) => {
    const idMap = new Map();
    uuids.forEach(uuid => {
      if (uuid) {
        const denormalizedUser = denormalize(uuid, userSchema, entities);
        if (denormalizedUser) {
          idMap.set(uuid, denormalizedUser.account_id);
        }
      }
    });
    return idMap;
  }
)((_state, uuids: string[]) => uuids.join('-'));

export const getAccountIdFromUuid = createCachedSelector(
  (state: BucketState) => getEntities(state),
  (_state: never, uuid: string) => uuid,
  (entities: EntitiesState, uuid) => {
    let userAaid = null;
    if (uuid) {
      const denormalizedUser = denormalize(uuid, userSchema, entities);
      if (denormalizedUser) {
        userAaid = denormalizedUser.account_id;
      }
    }
    return userAaid;
  }
)((_state, userAaid) => userAaid);
