import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["attributes"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// Copied from xflow-ui. We post raw analytics events (context and payload only) from xflow-ui to consumer (CFE), transform on consumer and post to gasv3.

import { UI_EVENT_TYPE, TRACK_EVENT_TYPE, SCREEN_EVENT_TYPE, OPERATIONAL_EVENT_TYPE, DEFAULT_SOURCE } from '@atlaskit/analytics-gas-types';
var GROWTH_TAG = 'growth';
var extractFromEventContext = function extractFromEventContext(propertyNames, context) {
  return context.reduce(function (acc, contextItem) {
    propertyNames.forEach(function (propertyName) {
      var navContextProp = contextItem ? contextItem[propertyName] : null;
      acc.push(navContextProp || contextItem[propertyName]);
    });
    return acc;
  }, []).filter(Boolean);
};
var getSources = function getSources(context) {
  return extractFromEventContext(['source'], context);
};
var getExtraAttributes = function getExtraAttributes(context) {
  return context.reduce(function (ans, _ref) {
    var attributes = _ref.attributes,
      contextProps = _objectWithoutProperties(_ref, _excluded);
    var newAttributes = _objectSpread(_objectSpread({}, ans), contextProps);
    return attributes ? _objectSpread(_objectSpread({}, newAttributes), attributes) : newAttributes;
  }, {});
};
var getPackageInfo = function getPackageInfo(context) {
  return context.map(function (contextItem) {
    return {
      packageName: contextItem.packageName,
      packageVersion: contextItem.packageVersion
    };
  }).filter(function (p) {
    return p.packageName;
  });
};
var last = function last(values) {
  return values[values.length - 1];
};
export var transformEvent = function transformEvent(event) {
  var context = event.context,
    payload = event.payload;
  if (!payload) {
    return null;
  }
  var sources = getSources(context);
  var source = last(sources) || DEFAULT_SOURCE;
  var extraAttributes = getExtraAttributes(context);
  var _ref2 = last(getPackageInfo(context)) || {},
    _ref2$packageName = _ref2.packageName,
    packageName = _ref2$packageName === void 0 ? undefined : _ref2$packageName,
    _ref2$packageVersion = _ref2.packageVersion,
    packageVersion = _ref2$packageVersion === void 0 ? undefined : _ref2$packageVersion;
  var _payload$eventType = payload.eventType,
    eventType = _payload$eventType === void 0 ? UI_EVENT_TYPE : _payload$eventType,
    action = payload.action,
    actionSubject = payload.actionSubject,
    actionSubjectId = payload.actionSubjectId,
    payloadAttributes = payload.attributes,
    name = payload.name;
  var attributes = _objectSpread(_objectSpread({
    packageName: packageName,
    packageVersion: packageVersion
  }, extraAttributes), payloadAttributes);
  var tags = payload.tags || [];
  tags.push(GROWTH_TAG);
  switch (eventType) {
    case UI_EVENT_TYPE:
    case OPERATIONAL_EVENT_TYPE:
    case TRACK_EVENT_TYPE:
      return {
        eventType: eventType,
        source: source,
        actionSubject: actionSubject,
        action: action,
        actionSubjectId: actionSubjectId,
        attributes: attributes,
        tags: tags.slice(0)
      };
    case SCREEN_EVENT_TYPE:
      return {
        eventType: eventType,
        name: name,
        attributes: attributes,
        source: source,
        tags: tags.slice(0)
      };
    default:
      break;
  }
  return null;
};