import { combineReducers } from 'redux';

import {
  Account,
  Allowance,
  BuildConfiguration,
  Capabilities,
  Repository,
  Validator,
} from 'src/components/pipelines/models';

import account from './account';
import allowance from './allowance';
import artifacts, { ArtifactsState } from './artifacts';
import buildConfiguration from './build-configuration';
import caches, { CachesState } from './caches';
import capabilities from './capabilities';
import commitPullRequests, {
  CommitPullRequestsState,
} from './commit-pull-requests';
import currentPipeline, { CurrentPipelineState } from './current-pipeline';
import environmentVariables, {
  EnvironmentVariablesState,
} from './environment-variables';
import jsdChecks, { JsdChecksState } from './jsd-checks';
import { log, LogState } from './log';
import { metricsReducer as metrics, MetricsState } from './metrics';
import officialTemplates, {
  OfficialTemplatesState,
} from './official-templates';
import pipelines, { PipelinesState as PipelinesListState } from './pipelines';
import reports, { ReportsState } from './reports';
import repository from './repository';
import schedules, { SchedulesState } from './schedules';
import searchLog, { SearchLogState } from './search-log';
import stepPreview, { StepPreviewState } from './step-preview';
import stepRedeployment, { StepRedeploymentState } from './step-redeployment';
import { steps, StepsState } from './steps';
import testReport, { TestReportsState } from './test-reports';
import validator from './validator';
import variables, { VariablesState } from './variables';

export type PipelinesState = {
  account: Account;
  allowance: Allowance;
  artifacts: ArtifactsState;
  buildConfiguration: BuildConfiguration;
  caches: CachesState;
  capabilities: Capabilities;
  commitPullRequests: CommitPullRequestsState;
  currentPipeline: CurrentPipelineState;
  jsdChecks: JsdChecksState;
  log: LogState;
  environmentVariables: EnvironmentVariablesState;
  metrics: MetricsState;
  officialTemplates: OfficialTemplatesState;
  pipelines: PipelinesListState;
  stepPreview: StepPreviewState;
  stepRedeployment: StepRedeploymentState;
  reports: ReportsState;
  repository: Repository;
  schedules: SchedulesState;
  searchLog: SearchLogState;
  steps: StepsState;
  testReport: TestReportsState;
  validator: Validator;
  variables: VariablesState;
};

export const pipelinesReducer = combineReducers<PipelinesState>({
  account,
  allowance,
  artifacts,
  buildConfiguration,
  caches,
  capabilities,
  commitPullRequests,
  currentPipeline,
  jsdChecks,
  environmentVariables,
  log,
  metrics,
  officialTemplates,
  pipelines,
  reports,
  repository,
  schedules,
  searchLog,
  stepPreview,
  stepRedeployment,
  steps,
  testReport,
  validator,
  variables,
});
