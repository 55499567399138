const urls = {
  api: {
    v10: {
      // v1 invitations URL can be removed once we remove UserAndGroupAccess
      // settings page and replace it with RepositoryPermissions settings page
      invitations: (workspaceSlug: string, repoSlug: string) =>
        `/!api/1.0/invitations/${workspaceSlug}/${repoSlug}`,
    },
    internal: {
      navigation: (repositoryFullSlug: string): string =>
        `/!api/internal/menu/repository/${repositoryFullSlug}`,
      invitations: (workspaceSlug: string, repoSlug: string) =>
        `/!api/internal/invitations/repositories/${workspaceSlug}/${repoSlug}`,
    },
  },
};

export default urls;
