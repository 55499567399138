import _defineProperty from "@babel/runtime/helpers/defineProperty";
/**
 * @jsxRuntime classic
 * @jsx jsx
 */

// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, jsx } from '@emotion/react';
var CSS_VAR_MAX_WIDTH = '--max-width';
var CSS_VAR_MAX_HEIGHT = '--max-height';
var imageStyles = css({
  display: 'block',
  maxWidth: "var(".concat(CSS_VAR_MAX_WIDTH, ")"),
  maxHeight: "var(".concat(CSS_VAR_MAX_HEIGHT, ")"),
  // eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
  margin: "0 auto ".concat("var(--ds-space-300, 24px)")
});

/**
 * __Image__
 *
 * Image in Empty State.
 *
 * @internal
 */
var Image = function Image(_ref) {
  var _ref2;
  var maxHeight = _ref.maxHeight,
    maxWidth = _ref.maxWidth,
    _ref$height = _ref.height,
    height = _ref$height === void 0 ? 'auto' : _ref$height,
    _ref$width = _ref.width,
    width = _ref$width === void 0 ? 'auto' : _ref$width,
    src = _ref.src;
  return jsx("img", {
    // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
    style: (_ref2 = {}, _defineProperty(_ref2, CSS_VAR_MAX_WIDTH, "".concat(maxWidth, "px")), _defineProperty(_ref2, CSS_VAR_MAX_HEIGHT, "".concat(maxHeight, "px")), _ref2),
    width: width,
    height: height,
    alt: "",
    role: "presentation",
    css: imageStyles,
    src: src
  });
};
export default Image;