import { defineMessages } from 'react-intl';

export default defineMessages({
  pullRequestUpdated: {
    id: 'frontbucket.pullRequest.updatedFlagTitle',
    description:
      'Title for the popup flag which tells a user the pull request has been updated.',
    defaultMessage: 'This pull request was updated',
  },
  anchorMovedTitle: {
    id: 'frontbucket.pullRequest.anchorMovedTitle',
    description:
      'Title for the popup flag which tells a user the source branch for a pull request has been updated.',
    defaultMessage: 'Source branch updated',
  },
  anchorMovedDescription: {
    id: 'frontbucket.pullRequest.anchorMovedDescription',
    description:
      'Body text for the popup flag which tells a user the source branch for a pull request has been updated.',
    defaultMessage: 'The source branch for this pull request has been updated.',
  },
  reload: {
    id: 'frontbucket.pullRequest.reloadAction',
    description: 'Link to reload the page.',
    defaultMessage: 'Reload page',
  },
  discardCommentsModalBody: {
    id: 'frontbucket.pullRequest.updatedFlagTitle.discardCommentsModalBody',
    description:
      'Body text of a confirmation dialog. Explains that unsaved comments will be lost upon reloading pull request.',
    defaultMessage:
      'Reloading the pull request discards your unsaved comments.',
  },
  topicDiffConflictTitle: {
    id: 'frontbucket.pullRquest.topicDiffRolloutConflictsFlag.title',
    description:
      'Title for flag informing the user of the topic diff rollout and why conflicts look different now',
    defaultMessage: 'Merge conflicts look a bit different now',
  },
  topicDiffConflictBody: {
    id: 'frontbucket.pullRquest.topicDiffRolloutConflictsFlag.body',
    description:
      'Body for flag informing the user of the topic diff rollout and why conflicts look different now',
    defaultMessage:
      'To help improve performance, we’ve updated the way our diffs are displayed. Now, files with merge conflicts are still marked as conflicted, but no longer display the conflicts in highlighted text on the diffs.',
  },
  syntaxHighlightingPopupTitle: {
    id: 'frontbucket.pullRequest.syntaxHighlightingPopup.title',
    description:
      'Title for flag informing users about enabling syntax highlighting in pull requests',
    defaultMessage: 'Syntax highlighting is available',
  },
  syntaxHighlightingPopupBody: {
    id: 'frontbucket.pullRequest.syntaxHighlightingPopup.body',
    description:
      'Body for flag informing users about enabling syntax highlighting in pull requests',
    defaultMessage:
      'Try out syntax highlighting by enabling the feature in Labs. You can provide feedback via the Labs feedback collector.',
  },
  syntaxHighlightingGoToLabsAction: {
    id: 'frontbucket.pullRequest.syntaxHighlightingPopup.goToLabsAction',
    description: 'Link to Labs in syntax highlighting informational popup',
    defaultMessage: 'Go to Labs',
  },
});
