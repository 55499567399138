import store from 'src/utils/store';

const MAX_RECENTS = 5;

type User = {
  uuid: string;
};

const storageName = (user: User) =>
  `recently-viewed-workspace-uuids:${user.uuid}`;

function addToRecents(recentUuids: string[], uuid: string): string[] {
  const idx = recentUuids.indexOf(uuid);
  if (idx === 0) {
    return recentUuids;
  } else if (idx > 0) {
    recentUuids.splice(idx, 1);
  }
  return [uuid, ...recentUuids];
}

function add(user: User, workspaceUuid: string): void {
  if (store.enabled) {
    const key = storageName(user);
    const currentUuids = store.get(key) || [];

    const uuids = addToRecents(currentUuids, workspaceUuid);

    store.set(key, uuids.slice(0, MAX_RECENTS));
  }
}

function set(user: User, workspaceUuids: string[]): void {
  if (store.enabled) {
    store.set(storageName(user), workspaceUuids);
  }
}

function get(user: User): string[] {
  if (!store.enabled) {
    return [];
  }
  return store.get(storageName(user)) || [];
}

export default { get, set, add };
