import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';

export const ProjectSettingsDetailsLoadable = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      /* webpackChunkName: "project-settings-details" */
      './project-details-page'
    ),
});
