import { createBoolAction } from 'src/redux/actions';
import { prefixed } from 'src/redux/pull-request/actions';
import { Action } from 'src/types/state';

export const ITERATIVE_REVIEW_COMPLETED = createBoolAction(
  prefixed('ITERATIVE_REVIEW_COMPLETED')
);

export type IterativeReviewState = {
  isIterativeReviewComplete: boolean;
};

export const initialState: IterativeReviewState = {
  isIterativeReviewComplete: false,
};

export const iterativeReviewReducer = (
  state: IterativeReviewState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ITERATIVE_REVIEW_COMPLETED.TRUE:
      return {
        ...state,
        isIterativeReviewComplete: true,
      };
    case ITERATIVE_REVIEW_COMPLETED.FALSE:
      return {
        ...state,
        isIterativeReviewComplete: false,
      };
    default:
      return state;
  }
};
