export class ModelBase {
  private readonly props: {} = {};

  constructor(props = {}) {
    Object.assign(this, {
      props,
    });
  }

  toJS(): {} {
    return this.props;
  }
}
