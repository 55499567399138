/**
 * Convert incoming context lines to match the format
 * of the existing diff chunks. Also flags if there are
 * more lines of content available.
 */
import { Line } from '@atlassian/bitkit-diff';

import { ContextLineRanges } from 'src/utils/validate-context-lines';

import { buildLineNumbers } from './get-context-data';

export type ContextLine = Line & {
  type: 'normal';
  normal: true;
};

// @ts-ignore TODO: fix noImplicitAny error here
function formatLine(line): ContextLine {
  return {
    normal: true, // Context lines are always 'normal'
    type: 'normal',
    oldLine: line.from_line,
    newLine: line.to_line,
    content: line.content,
  };
}

export function convertToChunksFormat(
  originalContextLines: string[],
  lineNums: ContextLineRanges
) {
  const converted = {
    hasMoreLines: true,
    contextLines: [] as ContextLine[],
  };

  if (!Array.isArray(originalContextLines) || !originalContextLines.length) {
    converted.hasMoreLines = false;
    return converted;
  }

  const { startingFrom, startingTo, endingFrom, endingTo } = lineNums;
  const fromLineNumbers = buildLineNumbers(
    originalContextLines.length,
    startingFrom
  );
  const toLineNumbers = buildLineNumbers(
    originalContextLines.length,
    startingTo
  );

  // there is no indication of whether there is more content so we check here
  let expectedNumLines;

  if (endingTo !== undefined) {
    expectedNumLines = endingTo - startingTo + 1;
  } else if (endingFrom !== undefined) {
    expectedNumLines = endingFrom - startingFrom + 1;
  }

  if (originalContextLines.length !== expectedNumLines || startingFrom === 0) {
    converted.hasMoreLines = false;
  }

  const contextLines = originalContextLines.map((value, index) => {
    return {
      content: ` ${value}`, // diff content starts with +, - or ' '
      from_line: fromLineNumbers[index],
      to_line: toLineNumbers[index],
    };
  });

  converted.contextLines = contextLines.map(formatLine);
  return converted;
}
