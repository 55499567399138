import { FabricUser } from 'src/components/bbc-atlaskit-editor';
import {
  isApiComment,
  ApiCommentOrPlaceholder,
} from 'src/components/conversation-provider/types';
import { toFabricUser } from 'src/components/conversation-provider/utils';

export type NavComment = {
  id: number;
  createdBy: FabricUser;
  createdAt: string;
  content: string | null;
  file: string | null | undefined;
  outdated: boolean | undefined;
  srcHash?: string;
  destHash?: string;
  baseRevHash?: string;
};

const extractContent = (comment: ApiCommentOrPlaceholder) => {
  const { content } = comment;

  const html = 'html' in content ? content.html : '';
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const text = doc.body.textContent || '';

  /* Since trim() doesn't remove zero space characters, we replace them after trimming
    to determine if the string is truly empty.
    u200B: Zero Width Space
    u200C: Zero Width Non-Joiner
    u200D: Zero Width Joiner
    uFEFF: Zero Width No-Break Space
  */
  if (!text || text.trim().replace(/[\u200B-\u200D\uFEFF]/g, '') === '') {
    return null;
  }

  return text;
};

export function toNavComment(comment: ApiCommentOrPlaceholder): NavComment {
  const { created_on: createdAt, user, inline } = comment;
  const id = isApiComment(comment) ? comment.id : 0;

  const content = extractContent(comment);

  return {
    id,
    createdBy: toFabricUser(user),
    createdAt,
    content,
    file: inline?.path,
    outdated: inline?.outdated,
    srcHash: inline?.src_rev,
    destHash: inline?.dest_rev,
    baseRevHash: inline?.base_rev,
  };
}
