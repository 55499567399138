import React from 'react';

import { Link, type LinkProps } from 'react-resource-router';

import * as colors from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

/* eslint @typescript-eslint/ban-types: "warn" */
export const TestLink: React.FC<LinkProps> = ({ children, ...props }) => {
  return (
    <Link style={{ color: token('color.text', colors.N800) }} {...props}>
      {children}
    </Link>
  );
};
