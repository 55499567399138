import React, { PureComponent, ComponentType } from 'react';

import { parse } from 'qs';

import Button from '@atlaskit/button/standard-button';
import AkSpinner from '@atlaskit/spinner';

export type IframeTimeoutIndicatorProps = {
  containerStyle?: {
    [key: string]: any;
  };
  failedCallback?: (e: React.MouseEvent) => void;
  context?: {
    [key: string]: any;
  };
  publishUiEvent?: (event: {}, callback?: () => void) => void;
  captureMessageWithTags?: (message: string, tags?: {}, extras?: {}) => void;
};

export type LoadingTimeoutComponent =
  ComponentType<IframeTimeoutIndicatorProps>;

export class IframeTimeoutIndicator extends PureComponent<IframeTimeoutIndicatorProps> {
  static defaultProps = {
    containerStyle: {
      position: 'relative',
      height: 'auto',
      width: 'auto',
      minHeight: 140,
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  componentDidMount() {
    if (this.props.context && this.props.captureMessageWithTags) {
      this.props.captureMessageWithTags(
        'CONNECT_IFRAME_TIMEOUT',
        {},
        this.parsedAttributes
      );
    }
  }

  get parsedAttributes() {
    if (!this.props.context) {
      return {};
    }
    const { origin, pathname, search } = new URL(this.props.context.url);
    const urlAttributes = { ...parse(search), url: `${origin}${pathname}` };
    delete urlAttributes.jwt;
    return { ...this.props.context, ...urlAttributes };
  }

  onRetry = () => {
    if (this.props.context && this.props.publishUiEvent) {
      this.props.publishUiEvent(
        {
          action: 'clicked',
          actionSubject: 'button',
          actionSubjectId: 'connectTimeoutRetryButton',
          source: 'addonRepositoryPage',
          attributes: this.parsedAttributes,
        },
        () => window.location.reload()
      );
    } else {
      window.location.reload();
    }
  };

  render() {
    return (
      <div style={this.props.containerStyle}>
        <AkSpinner size="large" />
        <div style={{ margin: '16px' }}>
          Wait,{' '}
          <Button appearance="link" spacing="none" onClick={this.onRetry}>
            retry
          </Button>{' '}
          or{' '}
          <Button
            appearance="link"
            spacing="none"
            onClick={this.props.failedCallback}
          >
            cancel
          </Button>
          ?
        </div>
      </div>
    );
  }
}

export default IframeTimeoutIndicator;
