import { createResource, RouterContext } from 'react-resource-router';

import { getHydrateAction } from 'src/middleware/hydrate';
import { FetchAction, fetchDataFromResource } from 'src/redux/actions';
import { LoadProfilePage } from 'src/redux/profile/section/actions';
import loadProfile, {
  getApiUrl,
  stateKey,
} from 'src/redux/profile/section/actions/load-profile';
import { ResourceContext } from 'src/router/types';
import { Action, Thunk } from 'src/types/state';
import { urlWithState } from 'src/utils/state-key';

export const workspaceSectionResource = createResource({
  type: 'WORKSPACE_SECTION',
  getKey: ({ match }: RouterContext) =>
    `workspace:${match.params.workspaceSlug}`,
  maxAge: 0,
  getData: async ({ match }, { reduxStore, csrftoken }: ResourceContext) => {
    const { workspaceSlug } = match.params;

    // If we can't match the URL to a workspace slug, this resource is being used on a route
    // it shouldn't be.
    if (!workspaceSlug) {
      return null;
    }

    const { dispatch } = reduxStore as {
      dispatch: (x: Action | Thunk) => Action<FetchAction> | null;
    };

    const action = loadProfile(workspaceSlug, {
      csrftoken,
      isRouterResource: true,
    });

    // check the initial state and skip to MyAction.SUCCESS if we already have the data
    const hydrateAction = getHydrateAction(action, stateKey);
    if (hydrateAction) {
      dispatch(hydrateAction);
      return { status: 'success' };
    }

    // Update the URL to fetch the data based on the state key
    action.meta.url = urlWithState(getApiUrl(workspaceSlug), stateKey);

    const result = await dispatch(fetchDataFromResource(action));

    if (result?.type === LoadProfilePage.ERROR) {
      return null;
    }

    return { status: 'success' };
  },
});
