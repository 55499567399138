import React, { useEffect, useState } from 'react';

export type IntervalRendererProps = {
  children: () => React.ReactNode;
  interval?: number;
};

/**
 * A component that re-renders itself automatically.
 * Useful for "elapsed time" etc.
 * The `children` shall be a render function that
 * will be called repetitively every `interval` (in milliseconds).
 */
/* eslint @typescript-eslint/ban-types: "warn" */
const IntervalRenderer: React.FC<IntervalRendererProps> = ({
  children,
  interval = 1000,
}) => {
  const [, rerender] = useState({});

  useEffect(() => {
    let isMounted = true;
    const timer = setInterval(() => {
      if (isMounted) {
        rerender({});
      }
    }, interval);

    // Clear interval on unmount
    return () => {
      isMounted = false;
      clearInterval(timer);
    };
  }, [interval]);

  // The children() is wrapped with a fragment for type checking
  return <>{children?.() ?? null}</>;
};

export default IntervalRenderer;
