import * as Sentry from '@sentry/browser';
/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { uniqBy } from 'lodash-es';
import qs from 'qs';
import { all, call, put, race, select, take } from 'redux-saga/effects';

import { User } from 'src/components/types';
import { BranchingModelState } from 'src/redux/branches/reducers/branch-list-reducer';
import { showFlag, showFlagComponent } from 'src/redux/flags';
import {
  CREATE_BRANCH_ERROR_TYPE,
  CREATE_FROM,
  EXTENDED_BRANCH_KINDS,
} from 'src/sections/create-branch/constants';
import {
  getJiraIssueTypeForAnalytics,
  getRepositoryForIssueFromLocalStorage,
} from 'src/sections/create-branch/jira';
import {
  CreateBranchError,
  CreateFromPayload,
  JiraIssue,
  Ref,
  CreateBranchParams,
  WorkflowBranches,
  BranchType,
  RefSelector,
  RepositoryDetailsMainBranch,
} from 'src/sections/create-branch/types';
import {
  generateBranchName,
  generateRefSelectorOptions,
  getRef,
  getRefs,
  getRepoFullName,
  getRepoOwnerAndSlug,
  moveMainBranchToTop,
  suggestFromBranch,
} from 'src/sections/create-branch/utils';
import messages from 'src/sections/global/components/flags/create-branch/create-branch-error-flag.i18n';
import { buildRefUrls } from 'src/sections/repository/sections/branches/hooks/utils';
import branchUrls from 'src/sections/repository/sections/branches/urls';
import repoUrls from 'src/sections/repository/urls';
import {
  getCurrentRepositoryOwnerName,
  getCurrentRepositorySlug,
  getRepositoryBranchingModel,
} from 'src/selectors/repository-selectors';
import { getCurrentUser } from 'src/selectors/user-selectors';
import { Action } from 'src/types/state';
import authRequest, { jsonHeaders } from 'src/utils/fetch';
import prefs from 'src/utils/preferences';

import {
  CHANGE_BRANCH_TYPE,
  changeFromBranch,
  ChangeRepositoryAction,
  closeCreateBranchGlobalDialog,
  closeCreateBranchRepoDialog,
  CREATE_BRANCH,
  FETCH_BRANCHING_MODEL,
  FETCH_COMMIT_STATUSES,
  FETCH_REF_OPTIONS,
  fetchBranchingModel,
  fetchRefOptions,
  FetchRefOptionsAction,
  FetchRefOptionsSuccessAction,
  LOAD_REPOSITORY,
  loadRepository,
  onChangeRepository,
  setSuggestedFromBranch,
} from '../actions';
import {
  getBranchTypesVisible,
  getCreateBranchParams,
  getJiraIssue,
  getRefSelectorState,
  getRepositorySelectorState,
  getSelectedBranchType,
  getSelectedRepoFullSlug,
  getSelectedRepoOwnerAndSlug,
  getSelectedRepositoryMainBranch,
  getWorkflowBranches,
} from '../selectors';

const getErrorType = (
  status: number,
  errorKey: string | null | undefined,
  message: string | null | undefined
): CREATE_BRANCH_ERROR_TYPE => {
  if (status >= 500 || !errorKey || !message) {
    return CREATE_BRANCH_ERROR_TYPE.GENERIC;
  }

  // @ts-ignore TODO: fix noImplicitAny error here
  return CREATE_BRANCH_ERROR_TYPE[errorKey] || CREATE_BRANCH_ERROR_TYPE.OTHER;
};

const getRepositoryForJiraIssueUserPrefKey = (issue: JiraIssue) =>
  `create-branch:repo-for-jira-project-${issue.project}`;

export function redirectToBranchPage(href: string) {
  window.location.assign(href);
}

export function* handleCreateBranchSuccessSaga(
  createFrom: CREATE_FROM,
  branch: any,
  targetBranchName: string
) {
  // Once the branch page is a SPA, we will also
  // want to show the "create branch success" flag
  // here after redirecting the user.

  if (createFrom === CREATE_FROM.REPO_DIALOG) {
    yield put(closeCreateBranchRepoDialog());
  } else if (createFrom === CREATE_FROM.GLOBAL_DIALOG) {
    yield put(closeCreateBranchGlobalDialog());
  }

  // Store the preferred repository for the issue project in user preferences
  if (
    [CREATE_FROM.GLOBAL_DIALOG, CREATE_FROM.GLOBAL_PAGE].includes(createFrom)
  ) {
    const issue: JiraIssue = yield select(getJiraIssue);
    if (issue) {
      const prefsKey = getRepositoryForJiraIssueUserPrefKey(issue);
      if (prefsKey) {
        const currentUser: User = yield select(getCurrentUser);
        const { selected } = yield select(getRepositorySelectorState);
        try {
          yield call(prefs.set, currentUser.uuid, prefsKey, selected.value);
        } catch {
          Sentry.captureException(
            'Failed to save the preferred repo for issue'
          );
        }
      }
    }
  }
  const queryParams = qs.stringify({ dest: targetBranchName });
  yield redirectToBranchPage(`${branch.links.html.href}?${queryParams}`);
}

export function* handleLoadingBranchingModelErrorSaga() {
  yield put({ type: FETCH_BRANCHING_MODEL.ERROR });
  /**
   *  this error flag is stateless and
   *  auto-dismiss disable
   * */
  yield put(
    showFlag({
      id: FETCH_BRANCHING_MODEL.ERROR,
      iconType: 'warning',
      title: { msg: messages.branchingModelsLoadingErrorTitle },
      description: { msg: messages.branchingModelsLoadingErrorDescription },
    })
  );
}
export function* handleCreateBranchErrorSaga(error: CreateBranchError) {
  yield put({
    type: CREATE_BRANCH.ERROR,
    payload: error,
  });
  yield put(showFlagComponent('create-branch-error'));
}

// @ts-ignore fix implicit any return type
export function* createBranchSaga(action: Action) {
  // eslint-disable-next-line prefer-destructuring
  const payload: CreateFromPayload = action.payload;
  const params: CreateBranchParams = yield select(getCreateBranchParams);
  const [owner, slug] = yield select(getSelectedRepoOwnerAndSlug);
  const branchType: BranchType | null = yield select(getSelectedBranchType);
  const branchTypeVisible: boolean = yield select(getBranchTypesVisible);
  const jiraIssue: JiraIssue = yield select(getJiraIssue);
  const url = repoUrls.api.v20.branches(owner, slug);
  const targetBranch = params.target;

  if (!targetBranch) {
    Sentry.captureMessage(
      `Tried to create branch but could not find target branch data`,
      Sentry.Severity.Error
    );
    yield call(handleCreateBranchErrorSaga, {
      type: CREATE_BRANCH_ERROR_TYPE.BRANCH_PARAMS_MISSING,
    });
    return;
  }

  const body = JSON.stringify({
    name: generateBranchName(params.name, branchType),
    target: {
      hash: targetBranch.hash,
    },
  });

  const headers: HeadersInit = {
    ...jsonHeaders,
    'X-Bitbucket-ScreenName': payload.createFrom,
    // urlencode branch name because it can contain non-latin1 characters which are problematic in headers
    'X-Bitbucket-SourceBranch': encodeURIComponent(targetBranch.name),
  };

  if (branchTypeVisible) {
    headers['X-Bitbucket-BranchType'] = branchType
      ? branchType.kind
      : EXTENDED_BRANCH_KINDS.OTHER;
  }
  if (jiraIssue) {
    headers['X-Bitbucket-JiraIssueType'] =
      getJiraIssueTypeForAnalytics(jiraIssue);
  }

  const request = authRequest(url, {
    method: 'POST',
    body,
    headers,
  });

  try {
    const response: Response = yield call(fetch, request);
    if (response.ok) {
      // @ts-ignore
      const branch = yield response.json();
      yield call(
        handleCreateBranchSuccessSaga,
        action.payload.createFrom,
        branch,
        targetBranch.name
      );
    } else {
      const { error } = yield response.json();
      const errorType = getErrorType(
        response.status,
        error.data && error.data.key,
        error.message
      );
      yield call(handleCreateBranchErrorSaga, {
        type: errorType,
        message: error.message,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield call(handleCreateBranchErrorSaga, {
      type: CREATE_BRANCH_ERROR_TYPE.GENERIC,
    });
  }
}

export function* fetchRefOptionsSaga({
  // @ts-ignore TODO: fix noImplicitAny error here
  payload: { search, mainBranch, repositoryFullSlug },
}) {
  // If a search query is not provided, we will fetch branches in one request
  // If a search query is provided, we will fetch branches in two requests
  // - First request fetches the exact match from the filter
  // - Second request fetches similar matches from the filter
  const urls: string[] = buildRefUrls(repositoryFullSlug, 'all', search || '');
  try {
    // Since branches can be fetched in 1 or 2 requests, we can use this object
    // to join the results for each request
    let res = { success: true, values: [], next: '' };
    for (const url of urls) {
      const request = authRequest(url);
      const response: Response = yield call(fetch, request);

      if (response.ok) {
        // @ts-ignore
        const result = yield response.json();

        // Combine each request's list of branches
        res.values = res.values.concat(result.values);

        // Store the last next url to populate hasMoreRefs
        res.next = result.next;
      } else {
        // If either request fails, we want to dispatch a FETCH_REF_OPTIONS.ERROR
        res = { success: false, values: [], next: '' };
        break;
      }
    }

    if (res.success) {
      let refs = getRefs(res.values);
      const branchingModel: BranchingModelState = yield select(
        getRepositoryBranchingModel
      );
      // @ts-ignore TODO: fix noImplicitAny error here
      const searchCondition = branch => {
        if (!search || !branch.name) {
          return true;
        }
        if (branch.name.includes(search)) {
          return true;
        }
        return false;
      };

      if (branchingModel) {
        const { development, production } = branchingModel;
        const priorityRefs: Ref[] = [development, production]
          .filter(b => b && b.use_mainbranch && searchCondition(b))
          .map(
            b =>
              ({
                hash: b && b.target ? b.target.hash : undefined,
                name: b && b.name ? b.name : '',
                type: b && b.type.toUpperCase(),
              } as Ref)
          );
        const uniqPriorityRefs = uniqBy(priorityRefs, 'name');
        const priorityRefNames = uniqPriorityRefs.map(b => b.name);
        const filteredRefs = refs.filter(
          b => !priorityRefNames.includes(b.name)
        );

        refs = [...uniqPriorityRefs, ...filteredRefs];
      }

      const reorderedRefs = moveMainBranchToTop(refs, mainBranch, !!search);
      const result: FetchRefOptionsSuccessAction = {
        type: FETCH_REF_OPTIONS.SUCCESS,
        payload: {
          mainRef: mainBranch ? getRef(mainBranch) : null,
          refs: generateRefSelectorOptions(reorderedRefs),
          hasMoreRefs: !!res.next,
        },
      };
      yield put(result);
    } else {
      yield put({
        type: FETCH_REF_OPTIONS.ERROR,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_REF_OPTIONS.ERROR,
    });
  }
}

export function* selectDataAndFetchRefOptionsSaga({
  payload: { search },
}: FetchRefOptionsAction) {
  const repositoryFullSlug: string = yield select(getSelectedRepoFullSlug);
  const mainBranch: RepositoryDetailsMainBranch = yield select(
    getSelectedRepositoryMainBranch
  );
  yield call(fetchRefOptionsSaga, {
    payload: {
      repositoryFullSlug,
      mainBranch,
      search,
    },
  });
}

// @ts-ignore fix implicit any return type
export function* fetchCommitStatusesSaga() {
  const [owner, slug] = yield select(getSelectedRepoOwnerAndSlug);
  const {
    target: { hash },
  } = yield select(getCreateBranchParams);
  const url = branchUrls.api.v20.commitStatuses(owner, slug, hash);
  const request = authRequest(url);
  try {
    const response: Response = yield call(fetch, request);
    if (response.ok) {
      const { values: statuses } = yield response.json();
      yield put({
        type: FETCH_COMMIT_STATUSES.SUCCESS,
        payload: statuses,
      });
    } else {
      yield put({
        type: FETCH_COMMIT_STATUSES.ERROR,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_COMMIT_STATUSES.ERROR,
    });
  }
}

// @ts-ignore fix implicit any return type
export function* fetchBranchingModelSaga() {
  const [owner, slug] = yield select(getSelectedRepoOwnerAndSlug);
  try {
    const url = repoUrls.api.v20.branchingModel(owner, slug);
    const request = authRequest(url);
    const response: Response = yield call(fetch, request);
    if (response.ok) {
      const {
        development,
        production,
        branch_types: branchTypes,
      } = yield response.json();

      const devBranch = development && development.branch;
      const prodBranch = production && production.branch;

      yield put({
        type: FETCH_BRANCHING_MODEL.SUCCESS,
        payload: {
          development: devBranch ? getRef(devBranch) : null,
          production: prodBranch ? getRef(prodBranch) : null,
          branchTypes,
        },
      });
    } else {
      yield call(handleLoadingBranchingModelErrorSaga);
    }
  } catch (e) {
    Sentry.captureException(e);
    yield call(handleLoadingBranchingModelErrorSaga);
  }
}

export function* handleChangeRepositorySaga(action: ChangeRepositoryAction) {
  const {
    payload: { selected },
  } = action;
  const [owner, slug] = getRepoOwnerAndSlug(selected.value);
  yield put(loadRepository(owner, slug));
  yield take([LOAD_REPOSITORY.SUCCESS, LOAD_REPOSITORY.ERROR]);

  yield put(fetchRefOptions());
  yield put(fetchBranchingModel());
}

export function* setCurrentRepositorySaga() {
  const owner: string = yield select(getCurrentRepositoryOwnerName);
  const slug: string = yield select(getCurrentRepositorySlug);
  const fullName = getRepoFullName({ owner, slug });

  yield put(
    onChangeRepository({ value: fullName, label: fullName, data: undefined })
  );
}

export function* handleLoadRepositoriesSuccessSaga({ payload }: Action) {
  const { repositories } = payload;
  if (repositories && repositories.length !== 0) {
    const issue: JiraIssue = yield select(getJiraIssue);
    let repoForIssue = null;
    if (issue) {
      let repoNameForIssue: string | null = null;
      const prefsKey = getRepositoryForJiraIssueUserPrefKey(issue);
      if (prefsKey) {
        const currentUser: User = yield select(getCurrentUser);
        try {
          repoNameForIssue = yield call(prefs.get, currentUser.uuid, prefsKey);
        } catch {
          Sentry.captureException('Failed to get the preferred repo for issue');
        }
      }
      // Fall back to reading from local storage to allow some transition period
      if (!repoNameForIssue) {
        repoNameForIssue = getRepositoryForIssueFromLocalStorage(issue);
      }
      repoForIssue =
        repoNameForIssue &&
        // @ts-ignore TODO: fix noImplicitAny error here
        repositories.find(r => r.full_name === repoNameForIssue);
    }

    const defaultRepo = repoForIssue || repositories[0];

    yield put(
      onChangeRepository({
        value: defaultRepo.full_name,
        label: defaultRepo.full_name,
        data: undefined,
      })
    );
  }
}

export function* handleLoadRepositoriesErrorSaga() {
  yield put(showFlagComponent('create-branch-load-repositories-error'));
}

export function* suggestFromBranchSaga() {
  while (true) {
    // We need to trigger pre-selection in the following cases:
    //   - repository refs were successfully fetched AND
    //     branching model was fetched successfully or with an error
    //   - branch type was changed
    yield race({
      changeBranchType: take(CHANGE_BRANCH_TYPE),
      fetchBranchAndRefs: all([
        race({
          branchModelSuccess: take(FETCH_BRANCHING_MODEL.SUCCESS),
          branchModelFailure: take(FETCH_BRANCHING_MODEL.ERROR),
        }),
        take(FETCH_REF_OPTIONS.SUCCESS),
      ]),
    });

    const workflowBranches: WorkflowBranches = yield select(
      getWorkflowBranches
    );
    const branchType: BranchType = yield select(getSelectedBranchType);

    const fromBranch = suggestFromBranch(workflowBranches, branchType);
    if (fromBranch) {
      yield put(setSuggestedFromBranch(fromBranch));

      const refSelector: RefSelector = yield select(getRefSelectorState);
      // Change from branch only if user hasn't changed it already
      if (!refSelector.selectedByUser) {
        yield put(changeFromBranch(fromBranch, false));
      }
    }
  }
}
