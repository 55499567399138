/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { call } from 'redux-saga/effects';

const contentTypeMatchers = {
  html: /html/,
  json: /json/,
};

export function* getErrorMessage(response: Response) {
  const contentType = response.headers.get('Content-Type') || '';

  if (contentTypeMatchers.json.test(contentType)) {
    // @ts-ignore
    const json = yield response.json();
    const errorMessage = Object.values(json.error?.fields || {}).join('\n');

    return errorMessage || json.error?.message || 'Unknown error';
  }

  const text: string = yield response.text();

  if (contentTypeMatchers.html.test(contentType)) {
    const domParser = new DOMParser();
    const doc: Document = yield call(
      { context: domParser, fn: domParser.parseFromString },
      text,
      'text/html'
    );
    const content = doc.getElementById('content');
    const innerText = content ? content.innerText : doc.body.innerText;
    const [result] = innerText.trim().split('\n');

    return result;
  }

  return text;
}

/** Async version of `getErrorMessage` to provide the equivalent functionality outside of sagas */
export async function getErrorMessageAsync(
  response: Response
): Promise<string> {
  const contentType = response.headers.get('Content-Type') || '';

  if (contentTypeMatchers.json.test(contentType)) {
    const json = await response.json();
    const errorMessage = Object.values(json.error?.fields || {}).join('\n');

    return errorMessage || json.error?.message || 'Unknown error';
  }

  const text: string = await response.text();

  if (contentTypeMatchers.html.test(contentType)) {
    const domParser = new DOMParser();
    const doc: Document = domParser.parseFromString(text, 'text/html');
    const content = doc.getElementById('content');
    // This implementation is slightly different than the saga version.
    // `innerText` isn't supported in tests, so we use textContent instead and
    // add an extra last-resort fallback.
    const innerText = content?.textContent ?? doc.body.textContent ?? '';
    const [result] = innerText.trim().split('\n');

    return result;
  }

  return text;
}

export function* getJsonErrorMessageWithCode(response: Response) {
  const contentType = response.headers.get('Content-Type') || '';

  if (contentTypeMatchers.json.test(contentType)) {
    // @ts-ignore
    const json = yield response.json();
    return json.error;
  }

  return {};
}
