/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import qs from 'qs';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { getCurrentRepository } from 'src/selectors/repository-selectors';
import authRequest from 'src/utils/fetch';

import { EMPTY_STEP } from '../../constants';
import envBaseUrl from '../../utils/env-base-url';
import {
  capturePipelinesExceptionWithTags,
  createErrorMessage,
} from '../../utils/sentry';
import { METRIC_UPDATED, REQUEST_METRICS } from '../actions/pipelines';

export const getMetricsUrl = (
  repositoryFullSlug: string,
  pipelineUuid: string,
  stepUuid: string,
  queryParams: {} = {}
) => {
  const allQueryParams = {
    pagelen: 100,
    ...queryParams,
  };
  return `${envBaseUrl(
    '/!api/internal'
  )}/repositories/${repositoryFullSlug}/pipelines/${pipelineUuid}/steps/${stepUuid}/metrics?${qs.stringify(
    allQueryParams,
    { allowDots: true }
  )}`;
};

export function* requestMetricsSaga(action: {
  meta: { pipelineUuid: string; stepUuid: string };
}): any {
  const meta = action.meta || action; // websocket is not grouping params in action
  if (!meta.pipelineUuid || !meta.stepUuid || meta.stepUuid === EMPTY_STEP) {
    return;
  }
  const { pipelineUuid, stepUuid } = meta;
  const { full_name } = yield select(getCurrentRepository);
  try {
    const res = yield all([
      call(
        fetch,
        authRequest(
          getMetricsUrl(full_name, pipelineUuid, stepUuid, {
            metric_type: 'CPU',
          })
        )
      ),
      call(
        fetch,
        authRequest(
          getMetricsUrl(full_name, pipelineUuid, stepUuid, {
            metric_type: 'MEMORY',
          })
        )
      ),
      call(
        fetch,
        authRequest(
          getMetricsUrl(full_name, pipelineUuid, stepUuid, {
            metric_type: 'MEMORY_PERCENTAGE',
          })
        )
      ),
    ]);

    for (const response of res) {
      if (!response.ok) {
        throw new Error(yield createErrorMessage(response));
      }
    }

    const data = yield all(res.map((r: any) => call([r, 'json'])));

    yield put({
      type: REQUEST_METRICS.SUCCESS,
      meta: action.meta,
      payload: data
        .reduce((reducer: any, r: { values: any[] }) => {
          // @ts-ignore use es2019 lib
          reducer.push(r.values.flat());
          return reducer;
        }, [])
        .flat(),
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_METRICS.ERROR,
    });
    yield put({
      type: REQUEST_METRICS.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export default function* () {
  yield all([
    takeLatest(REQUEST_METRICS.REQUEST, requestMetricsSaga),
    takeLatest(METRIC_UPDATED, requestMetricsSaga),
  ]);
}
