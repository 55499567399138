import { FabricConversation, ApiCommentOrPlaceholder } from '../types';

import { toConversation } from './to-conversation';

const isTopLevelInlineComment = (comment: ApiCommentOrPlaceholder) =>
  !comment.parent && comment.inline;

/**
 * This is a recursive function that uses the map of (comment id -> child comments) to build a thread of comments
 * where the passed comment is the first element and all comments nested below occur after this comment in the thread.
 * @param {Map<number, ApiCommentOrPlaceholder[]>} commentMap - A map of comment id to its list of children
 * @param {ApiCommentOrPlaceholder} comment - The comment to build a thread for
 * @returns {ApiCommentOrPlaceholder[]} - Thread of comments
 */
function buildCommentThread(
  commentMap: Map<number, ApiCommentOrPlaceholder[]>,
  comment: ApiCommentOrPlaceholder
): ApiCommentOrPlaceholder[] {
  // Add passed comment as first element of the list
  const list: ApiCommentOrPlaceholder[] = [comment];

  // Recursive case to add nested comments below this to the resuling list
  if ('id' in comment && commentMap.has(comment.id)) {
    const children = commentMap.get(comment.id) ?? [];

    return [
      comment,
      ...children.flatMap(child => buildCommentThread(commentMap, child)),
    ];
  }

  return list;
}

/**
 * This function takes all raw comments, filters into inline comments, and builts a list of FabricConversation
 * @param {ApiCommentOrPlaceholder[]} allComments - all raw comments
 * @param {string} containerId - id of conversation container
 * @returns {FabricConversation[]} - list of conversations
 */
export function buildInlineConversations(
  allComments: ApiCommentOrPlaceholder[],
  commentDescendantMap: Map<number, ApiCommentOrPlaceholder[]>,
  containerId: string
): FabricConversation[] {
  const topLevelInlineComments = allComments.filter(isTopLevelInlineComment);

  const conversations = topLevelInlineComments.map(comment =>
    buildCommentThread(commentDescendantMap, comment)
  );

  function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  const inlineConversations = conversations
    .map(thread => toConversation(thread, containerId))
    .filter(notEmpty);

  return inlineConversations;
}
