import { useContext, useEffect, useRef } from 'react';
import { FocusManagerContext } from '../components/focus-manager';

// The refs stored in the context are used to programatically
// control focus on a user navigates using the keyboard.
function useRegisterItemWithFocusManager() {
  var _useContext = useContext(FocusManagerContext),
    registerRef = _useContext.registerRef;
  var itemRef = useRef(null);
  useEffect(function () {
    if (itemRef.current !== null) {
      registerRef(itemRef.current);
    }
  }, [registerRef]);
  return itemRef;
}
export default useRegisterItemWithFocusManager;