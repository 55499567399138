import {
  BranchConfigsResponse,
  CustomMergeCheck,
  CustomMergeChecksConfigApi,
  CustomMergeChecksConfigurationResponse,
  CustomMergeChecksConfigureApi,
  CustomMergeChecksConfigureApiV3,
} from 'src/types/custom-merge-checks';
import { createHttpRequest } from 'src/utils/http-request';

export const customMergeChecksConfigApi = (
  url: string
): CustomMergeChecksConfigApi => {
  return {
    getBranchConfigs: () =>
      createHttpRequest<BranchConfigsResponse>('GET')(`${url}/branch-configs`),
    getCustomMergeChecks: () =>
      createHttpRequest<CustomMergeChecksConfigurationResponse>('GET')(url),
  };
};

// TODO: BCAT-4915: rename to customMergeChecksConfigApi
export const customMergeChecksConfigApiV3 = (
  url: string
): CustomMergeChecksConfigApi => {
  return {
    getBranchConfigs: () =>
      createHttpRequest<BranchConfigsResponse>('GET')(`${url}/branch-config`),
    getCustomMergeChecks: () =>
      createHttpRequest<CustomMergeChecksConfigurationResponse>('GET')(
        `${url}/enabled-checks`
      ),
  };
};

export const createCustomMergeChecksConfigureApi = (
  url: string
): CustomMergeChecksConfigureApi => {
  return {
    configureCustomMergeCheck: (extensionId, branch, state) =>
      createHttpRequest<CustomMergeCheck>('PUT')(`${url}/configure`, {
        extensionId,
        branch,
        state,
      }),
  };
};

// TODO: BCAT-4915: rename to createCustomMergeChecksConfigureApi
export const createCustomMergeChecksConfigureApiV3 = (
  url: string
): CustomMergeChecksConfigureApiV3 => {
  return {
    addCheck: (extensionId, branch, state) =>
      createHttpRequest<CustomMergeCheck>('POST')(`${url}/enabled-checks`, {
        extensionId,
        branch,
        state,
      }),
    editCheck: (uuid, state) =>
      createHttpRequest<CustomMergeCheck>('PUT')(
        `${url}/enabled-checks/${uuid}`,
        {
          state,
        }
      ),
    deleteCheck: uuid =>
      createHttpRequest<{}>('DELETE')(`${url}/enabled-checks/${uuid}`, {}),
  };
};
