import { createSelector } from 'reselect';

import {
  createAsyncAction,
  FetchAction,
  hydrateAction,
} from 'src/redux/actions';
import { getRepository } from 'src/selectors/state-slicing-selectors';
import createReducer from 'src/utils/create-reducer';

// Types - should match the serialised keys from backend RepositorySecurityView
export type Descriptor = {
  scopes: string[];
  vendor: {
    url: string;
    name: string;
  };
  modules?: {
    repoPages?: Array<{ key: string }>;
  };
  name: string;
  key: string;
  baseUrl: string;
};

export type RepositorySecurityState = {
  snykDescriptor: Descriptor | undefined;
  snykDescriptorUrl: string | undefined;
  snykPrivacyPolicyUrl: string | undefined;
  snykEulaUrl: string | undefined;
};

// Redux actions to start fetching the page state from the backend
export const HYDRATE_SECURITY_DATA = createAsyncAction(
  'repoSecurity/HYDRATE_REPO_SECURITY_DATA'
);
export const hydrateRepoSecurityData = (
  repositoryFullSlug: string
): FetchAction =>
  hydrateAction(HYDRATE_SECURITY_DATA, 'repository.security', {
    url: `/${repositoryFullSlug}/security/`,
  });

// Redux reducers to store in Redux the page state provided by the backend
export const initialState: RepositorySecurityState = {
  snykDescriptor: undefined,
  snykDescriptorUrl: undefined,
  snykPrivacyPolicyUrl: undefined,
  snykEulaUrl: undefined,
};

export const repoSecurityReducer = createReducer(initialState, {
  [HYDRATE_SECURITY_DATA.SUCCESS]: (
    state,
    { payload: { snykDescriptor, snykDescriptorUrl } }
  ) => ({
    ...state,
    snykDescriptor,
    snykDescriptorUrl,
  }),
});

// Redux selectors to access the page state stored in redux
export const getSnykDescriptor = createSelector(
  getRepository,
  state => state.security.snykDescriptor
);

export const getSnykDescriptorUrl = createSelector(
  getRepository,
  state => state.security.snykDescriptorUrl
);

export const getSnykPrivacyPolicyUrl = createSelector(
  getRepository,
  state => state.security.snykPrivacyPolicyUrl
);

export const getSnykEulaUrl = createSelector(
  getRepository,
  state => state.security.snykEulaUrl
);
