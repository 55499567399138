/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import qs from 'qs';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { getCurrentRepository } from 'src/selectors/repository-selectors';
import authRequest from 'src/utils/fetch';

import { TEST_REPORTS_PAGE_SIZE } from '../../constants';
import envBaseUrl from '../../utils/env-base-url';
import {
  capturePipelinesExceptionWithTags,
  createErrorMessage,
} from '../../utils/sentry';
import {
  REQUEST_FAILED_TEST_CASES,
  REQUEST_TEST_CASE_REASON,
} from '../actions/pipelines';

export const getTestCasesUrl = (
  repositoryFullSlug: string,
  pipelineUuid: string,
  stepUuid: string,
  queryParams: any = {}
) => {
  const allQueryParams = {
    page: 1,
    pagelen: TEST_REPORTS_PAGE_SIZE,
    successfulTestCases: 'false',
    fields: '-values.reason',
    ...queryParams,
  };
  return `${envBaseUrl(
    '/!api/2.0'
  )}/repositories/${repositoryFullSlug}/pipelines/${pipelineUuid}/steps/${stepUuid}/test_reports/test_cases?${qs.stringify(
    allQueryParams
  )}`;
};
export const getTestCaseReasonUrl = (
  repositoryFullSlug: string,
  pipelineUuid: string,
  stepUuid: string,
  testCaseUuid: string
) => {
  return `${envBaseUrl(
    '/!api/2.0'
  )}/repositories/${repositoryFullSlug}/pipelines/${pipelineUuid}/steps/${stepUuid}/test_reports/test_cases/${testCaseUuid}/test_case_reasons`;
};

export function* requestFailedTestCaseSaga(action: {
  meta: { pipelineUuid: string; stepUuid: string };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  const { pipelineUuid, stepUuid } = action.meta;
  try {
    const url = getTestCasesUrl(full_name, pipelineUuid, stepUuid);
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);
    yield put({
      type: REQUEST_FAILED_TEST_CASES.SUCCESS,
      meta: action.meta,
      payload: data,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_FAILED_TEST_CASES.ERROR,
    });
    yield put({
      type: REQUEST_FAILED_TEST_CASES.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export function* requestTestCaseReasonSaga(action: {
  meta: { pipelineUuid: string; stepUuid: string; testCaseUuid: string };
}): any {
  const { full_name } = yield select(getCurrentRepository);
  const { pipelineUuid, stepUuid, testCaseUuid } = action.meta;
  try {
    const url = getTestCaseReasonUrl(
      full_name,
      pipelineUuid,
      stepUuid,
      testCaseUuid
    );
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(yield createErrorMessage(res));
    }
    const data = yield call([res, 'json']);
    yield put({
      type: REQUEST_TEST_CASE_REASON.SUCCESS,
      meta: action.meta,
      payload: data,
    });
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: REQUEST_TEST_CASE_REASON.ERROR,
    });
    yield put({
      type: REQUEST_TEST_CASE_REASON.ERROR,
      meta: action.meta,
      payload: e,
    });
  }
}

export default function* () {
  yield all([
    takeEvery(REQUEST_FAILED_TEST_CASES.REQUEST, requestFailedTestCaseSaga),
    takeEvery(REQUEST_TEST_CASE_REASON.REQUEST, requestTestCaseReasonSaga),
  ]);
}
