import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * @jsxRuntime classic
 */
/** @jsx jsx */
import { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css, Global, jsx } from '@emotion/react';
import { bindAll } from 'bind-event-listener';
import rafSchd from 'raf-schd';
import { UNSAFE_useMediaQuery as useMediaQuery } from '@atlaskit/primitives/responsive';
import { COLLAPSED_LEFT_SIDEBAR_WIDTH, DEFAULT_LEFT_SIDEBAR_WIDTH, IS_SIDEBAR_DRAGGING, MIN_LEFT_SIDEBAR_DRAG_THRESHOLD, RESIZE_CONTROL_SELECTOR, VAR_LEFT_SIDEBAR_WIDTH } from '../../common/constants';
import { getLeftPanelWidth, getLeftSidebarPercentage } from '../../common/utils';
import { SidebarResizeContext } from '../../controllers/sidebar-resize-context';
/* import useUpdateCssVar from '../../controllers/use-update-css-vars'; */

import GrabArea from './grab-area';
import ResizeButton from './resize-button';
import Shadow from './shadow';
var cssSelector = _defineProperty({}, RESIZE_CONTROL_SELECTOR, true);
var resizeControlStyles = css({
  position: 'absolute',
  insetBlockEnd: 0,
  insetBlockStart: 0,
  insetInlineStart: '100%',
  outline: 'none'
});
var showResizeButtonStyles = css({
  '--ds--resize-button--opacity': 1
});

// @ts-expect-error adding `!important` to style rules is currently a type error
var globalResizingStyles = css({
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  '*': {
    // Setting the cursor to be `ew-resize` on all elements so that even if the user
    // pointer slips off the resize handle, the cursor will still be the resize cursor
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    cursor: 'ew-resize !important',
    // Blocking selection while resizing
    // Notes:
    // - This prevents a user selection being caused by resizing
    // - Safari + Firefox → all good
    // - Chrome → This will undo the current selection while resizing (not ideal)
    //   - The current selection will resume after resizing
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    userSelect: 'none !important'
  },
  // eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
  iframe: {
    // Disabling pointer events on iframes when resizing
    // as iframes will swallower user events when the user is over them
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
    pointerEvents: 'none !important'
  }
  // Note: We _could_ also disable `pointer-events` on all elements during resizing.
  // However, to minimize risk we are just disabling `pointer-events` on iframes
  // as that change is actually needed to fix resizing with iframes
});
var ResizeControl = function ResizeControl(_ref) {
  var testId = _ref.testId,
    overrides = _ref.overrides,
    _ref$resizeButtonLabe = _ref.resizeButtonLabel,
    resizeButtonLabel = _ref$resizeButtonLabe === void 0 ? 'Current project sidebar' : _ref$resizeButtonLabe,
    _ref$valueTextLabel = _ref.valueTextLabel,
    valueTextLabel = _ref$valueTextLabel === void 0 ? 'Width' : _ref$valueTextLabel,
    _ref$resizeGrabAreaLa = _ref.resizeGrabAreaLabel,
    resizeGrabAreaLabel = _ref$resizeGrabAreaLa === void 0 ? 'Resize Current Project Sidebar' : _ref$resizeGrabAreaLa,
    onResizeStart = _ref.onResizeStart,
    onResizeEnd = _ref.onResizeEnd;
  var _useContext = useContext(SidebarResizeContext),
    toggleLeftSidebar = _useContext.toggleLeftSidebar,
    collapseLeftSidebar = _useContext.collapseLeftSidebar,
    leftSidebarState = _useContext.leftSidebarState,
    setLeftSidebarState = _useContext.setLeftSidebarState;
  var isLeftSidebarCollapsed = leftSidebarState.isLeftSidebarCollapsed;
  var sidebarWidth = useRef(leftSidebarState[VAR_LEFT_SIDEBAR_WIDTH]);
  // Distance of mouse from left sidebar onMouseDown
  var offset = useRef(0);
  var keyboardEventTimeout = useRef();
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    isGrabAreaFocused = _useState2[0],
    setIsGrabAreaFocused = _useState2[1];
  var unbindEvents = useRef(null);
  var mobileMediaQuery = useMediaQuery('below.sm');

  // Used in some cases to ensure function references don't have to change
  // TODO: more functions could use `stableSidebarState` rather than `leftSidebarState`
  var stableSidebarState = useRef(leftSidebarState);
  useEffect(function () {
    stableSidebarState.current = leftSidebarState;
  }, [leftSidebarState]);
  var toggleSideBar = useCallback(function (event) {
    // don't cascade down to the LeftSidebarOuter
    event === null || event === void 0 || event.stopPropagation();
    toggleLeftSidebar();
  }, [toggleLeftSidebar]);
  var onMouseDown = function onMouseDown(event) {
    if (isLeftSidebarCollapsed) {
      return;
    }

    // Only allow left (primary) clicks to trigger resize as we've received
    // bug reports about right click unexpectedly beginning a resize.
    if (event.button !== 0) {
      return;
    }

    // It is possible for a mousedown to fire during a resize
    // Example: the user presses another pointer button while dragging
    if (leftSidebarState.isResizing) {
      // the resize will be cancelled by our global event listeners
      return;
    }
    offset.current = event.clientX - leftSidebarState[VAR_LEFT_SIDEBAR_WIDTH] - getLeftPanelWidth();
    unbindEvents.current = bindAll(window, [{
      type: 'mousemove',
      listener: function listener(event) {
        onUpdateResize({
          clientX: event.clientX
        });
      }
    }, {
      type: 'mouseup',
      listener: onFinishResizing
    }, {
      type: 'mousedown',
      // this mousedown event listener is being added in the bubble phase
      // on a higher event target than the resize handle.
      // This means that the original mousedown event that triggers a resize
      // can hit this mousedown handler. To get around that, we only call
      // `onFinishResizing` after an animation frame so we don't pick up the original event
      // Alternatives:
      // 1. Add the window 'mousedown' event listener in the capture phase
      // 👎 A 'mousedown' during a resize would trigger a new resize to start
      // 2. Do 1. and call `event.preventDefault()`, then check for `event.defaultPrevented` inside
      // the grab handle `onMouseDown`
      // 👎 Not ideal to cancel events if we don't have to
      listener: function () {
        var hasFramePassed = false;
        requestAnimationFrame(function () {
          hasFramePassed = true;
        });
        return function listener() {
          if (hasFramePassed) {
            onFinishResizing();
          }
        };
      }()
    }, {
      type: 'visibilitychange',
      listener: onFinishResizing
    },
    // A 'click' event should never be hit as the 'mouseup' will come first and cause
    // these event listeners to be unbound. I just added 'click' for extreme safety (paranoia)
    {
      type: 'click',
      listener: onFinishResizing
    }, {
      type: 'keydown',
      listener: function listener(event) {
        // Can cancel resizing by pressing "Escape"
        // Will return sidebar to the same size it was before the resizing started
        if (event.key === 'Escape') {
          sidebarWidth.current = Math.max(leftSidebarState.lastLeftSidebarWidth, COLLAPSED_LEFT_SIDEBAR_WIDTH);
          document.documentElement.style.setProperty("--".concat(VAR_LEFT_SIDEBAR_WIDTH), "".concat(sidebarWidth.current, "px"));
          onFinishResizing();
        }
      }
    }]);
    document.documentElement.setAttribute(IS_SIDEBAR_DRAGGING, 'true');
    var newLeftbarState = _objectSpread(_objectSpread({}, leftSidebarState), {}, {
      isResizing: true
    });
    setLeftSidebarState(newLeftbarState);
    onResizeStart && onResizeStart(newLeftbarState);
  };
  var onResizeOffLeftOfScreen = function onResizeOffLeftOfScreen() {
    var _unbindEvents$current;
    onUpdateResize.cancel();
    (_unbindEvents$current = unbindEvents.current) === null || _unbindEvents$current === void 0 || _unbindEvents$current.call(unbindEvents);
    unbindEvents.current = null;
    document.documentElement.removeAttribute(IS_SIDEBAR_DRAGGING);
    offset.current = 0;
    collapseLeftSidebar(undefined, true);
  };

  // It is important that `onUpdateResize` is a stable function reference, so that:
  // 1. we ensure we are correctly throttling with `requestAnimationFrame`
  // 2. that a `onUpdateResize` will cancel the one and only pending frame
  // To help ensure `onUpdateResize` is stable, we are putting the last state into a ref
  var _useState3 = useState(function () {
      return rafSchd(function (_ref2) {
        var clientX = _ref2.clientX;
        // Allow the sidebar to be 50% of the available page width
        var maxWidth = Math.round(window.innerWidth / 2);
        var leftPanelWidth = getLeftPanelWidth();
        var leftSidebarWidth = stableSidebarState.current.leftSidebarWidth;
        var hasResizedOffLeftOfScreen = clientX < 0;
        if (hasResizedOffLeftOfScreen) {
          onResizeOffLeftOfScreen();
          return;
        }
        var delta = Math.max(Math.min(clientX - leftSidebarWidth - leftPanelWidth, maxWidth - leftSidebarWidth - leftPanelWidth), COLLAPSED_LEFT_SIDEBAR_WIDTH - leftSidebarWidth - leftPanelWidth);
        sidebarWidth.current = Math.max(leftSidebarWidth + delta - offset.current, COLLAPSED_LEFT_SIDEBAR_WIDTH);
        document.documentElement.style.setProperty("--".concat(VAR_LEFT_SIDEBAR_WIDTH), "".concat(sidebarWidth.current, "px"));
      });
    }),
    _useState4 = _slicedToArray(_useState3, 1),
    onUpdateResize = _useState4[0];
  var onFinishResizing = function onFinishResizing() {
    var _unbindEvents$current2;
    if (isLeftSidebarCollapsed) {
      return;
    }
    document.documentElement.removeAttribute(IS_SIDEBAR_DRAGGING);

    // TODO: the control flow is pretty strange as the first codepath which calls `collapseLeftSidebar()`
    // does not return an updated state snapshot.
    var updatedLeftSidebarState = null;

    // If it is dragged to below the threshold,
    // collapse the navigation
    if (sidebarWidth.current < MIN_LEFT_SIDEBAR_DRAG_THRESHOLD) {
      // TODO: for this codepath, `onCollapse` occurs before `onResizeEnd` which seems wrong
      document.documentElement.style.setProperty("--".concat(VAR_LEFT_SIDEBAR_WIDTH), "".concat(COLLAPSED_LEFT_SIDEBAR_WIDTH, "px"));
      collapseLeftSidebar(undefined, true);
    }
    // If it is dragged to position in between the
    // min threshold and default width
    // expand the nav to the default width
    else if (sidebarWidth.current > MIN_LEFT_SIDEBAR_DRAG_THRESHOLD && sidebarWidth.current < DEFAULT_LEFT_SIDEBAR_WIDTH) {
      var _objectSpread2;
      document.documentElement.style.setProperty("--".concat(VAR_LEFT_SIDEBAR_WIDTH), "".concat(DEFAULT_LEFT_SIDEBAR_WIDTH, "px"));
      updatedLeftSidebarState = _objectSpread(_objectSpread({}, leftSidebarState), {}, (_objectSpread2 = {
        isResizing: false
      }, _defineProperty(_objectSpread2, VAR_LEFT_SIDEBAR_WIDTH, DEFAULT_LEFT_SIDEBAR_WIDTH), _defineProperty(_objectSpread2, "lastLeftSidebarWidth", DEFAULT_LEFT_SIDEBAR_WIDTH), _objectSpread2));
      setLeftSidebarState(updatedLeftSidebarState);
    } else {
      var _objectSpread3;
      // otherwise resize it to the desired width
      updatedLeftSidebarState = _objectSpread(_objectSpread({}, leftSidebarState), {}, (_objectSpread3 = {
        isResizing: false
      }, _defineProperty(_objectSpread3, VAR_LEFT_SIDEBAR_WIDTH, sidebarWidth.current), _defineProperty(_objectSpread3, "lastLeftSidebarWidth", sidebarWidth.current), _objectSpread3));
      setLeftSidebarState(updatedLeftSidebarState);
    }
    (_unbindEvents$current2 = unbindEvents.current) === null || _unbindEvents$current2 === void 0 || _unbindEvents$current2.call(unbindEvents);
    unbindEvents.current = null;
    onUpdateResize.cancel();
    sidebarWidth.current = 0;
    offset.current = 0;

    // TODO: no idea why this is in an animation frame
    requestAnimationFrame(function () {
      var _updatedLeftSidebarSt;
      setIsGrabAreaFocused(false);
      // Note: the `collapseSidebar` codepath does not return state, so we need to pull it from the ref
      onResizeEnd === null || onResizeEnd === void 0 || onResizeEnd((_updatedLeftSidebarSt = updatedLeftSidebarState) !== null && _updatedLeftSidebarSt !== void 0 ? _updatedLeftSidebarSt : stableSidebarState.current);
    });
  };
  var onKeyDown = function onKeyDown(event) {
    if (isLeftSidebarCollapsed || !isGrabAreaFocused) {
      return false;
    }
    var key = event.key;
    var isLeftOrTopArrow = key === 'ArrowLeft' || key === 'ArrowUp' || key === 'Left' || key === 'Up';
    var isRightOrBottomArrow = key === 'ArrowRight' || key === 'ArrowDown' || key === 'Right' || key === 'Down';
    var isSpaceOrEnter = key === 'Enter' || key === 'Spacebar' || key === ' ';
    if (isSpaceOrEnter) {
      toggleSideBar(event);
      event.preventDefault();
    }
    if (isLeftOrTopArrow || isRightOrBottomArrow) {
      event.preventDefault(); // prevent content scroll
      onResizeStart && onResizeStart(leftSidebarState);
      var step = 10;
      var stepValue = isLeftOrTopArrow ? -step : step;
      var leftSidebarWidth = leftSidebarState.leftSidebarWidth;
      var maxWidth = Math.round(window.innerWidth / 2) - getLeftPanelWidth();
      var hasModifierKey = event.metaKey || event.altKey || event.ctrlKey || event.shiftKey;
      var width = leftSidebarWidth + stepValue;
      if (width <= DEFAULT_LEFT_SIDEBAR_WIDTH) {
        width = DEFAULT_LEFT_SIDEBAR_WIDTH;
        document.documentElement.style.setProperty("--".concat(VAR_LEFT_SIDEBAR_WIDTH), "".concat(DEFAULT_LEFT_SIDEBAR_WIDTH - 20, "px"));
      } else if (width > maxWidth) {
        width = maxWidth;
        document.documentElement.style.setProperty("--".concat(VAR_LEFT_SIDEBAR_WIDTH), "".concat(maxWidth + 20, "px"));
      } else if (hasModifierKey) {
        width = isRightOrBottomArrow ? maxWidth : DEFAULT_LEFT_SIDEBAR_WIDTH;
      }

      // Nesting the setTimeout within requestAnimationFrame helps
      // the browser schedule the setTimeout call in an efficient manner
      requestAnimationFrame(function () {
        keyboardEventTimeout.current = window.setTimeout(function () {
          var _objectSpread4;
          keyboardEventTimeout.current && clearTimeout(keyboardEventTimeout.current);
          document.documentElement.style.setProperty("--".concat(VAR_LEFT_SIDEBAR_WIDTH), "".concat(width, "px"));
          var updatedLeftSidebarState = _objectSpread(_objectSpread({}, leftSidebarState), {}, (_objectSpread4 = {}, _defineProperty(_objectSpread4, VAR_LEFT_SIDEBAR_WIDTH, width), _defineProperty(_objectSpread4, "lastLeftSidebarWidth", width), _objectSpread4));
          setLeftSidebarState(updatedLeftSidebarState);
          onResizeEnd && onResizeEnd(updatedLeftSidebarState);
        }, 50);
      });
    }
  };
  var onFocus = useCallback(function () {
    setIsGrabAreaFocused(true);
  }, []);
  var onBlur = useCallback(function () {
    setIsGrabAreaFocused(false);
  }, []);
  var resizeButton = _objectSpread({
    render: function render(Component, props) {
      return jsx(Component, props);
    }
  }, overrides && overrides.ResizeButton);

  // This width is calculated once only on mount.
  // This means resizing the window will cause this value to be incorrect for screen reader users,
  // however this comes with a substantial performance gain and so is considered acceptable.
  var maxAriaWidth = useMemo(function () {
    var innerWidth = typeof window === 'undefined' ? 0 : window.innerWidth;
    return Math.round(innerWidth / 2) - getLeftPanelWidth();
  }, []);
  var leftSidebarPercentageExpanded = getLeftSidebarPercentage(leftSidebarState.leftSidebarWidth, maxAriaWidth);
  return jsx(Fragment, null, jsx("div", _extends({}, cssSelector, {
    css: [resizeControlStyles, (isGrabAreaFocused || isLeftSidebarCollapsed) && showResizeButtonStyles]
  }), jsx(Shadow, {
    testId: testId && "".concat(testId, "-shadow")
  }),
  // Only show the GrabArea if we're not on the mobile viewport
  !(mobileMediaQuery !== null && mobileMediaQuery !== void 0 && mobileMediaQuery.matches) && jsx(GrabArea, {
    isDisabled: isLeftSidebarCollapsed,
    isLeftSidebarCollapsed: isLeftSidebarCollapsed,
    label: resizeGrabAreaLabel,
    valueTextLabel: valueTextLabel,
    leftSidebarPercentageExpanded: leftSidebarPercentageExpanded,
    onBlur: onBlur,
    onFocus: onFocus,
    onKeyDown: onKeyDown,
    onMouseDown: onMouseDown,
    testId: testId && "".concat(testId, "-grab-area")
  }), resizeButton.render(ResizeButton, {
    isLeftSidebarCollapsed: mobileMediaQuery !== null && mobileMediaQuery !== void 0 && mobileMediaQuery.matches ? !leftSidebarState.isFlyoutOpen : isLeftSidebarCollapsed,
    label: resizeButtonLabel,
    onClick: toggleSideBar,
    testId: testId && "".concat(testId, "-resize-button")
  })), leftSidebarState.isResizing ? jsx(Global, {
    styles: globalResizingStyles
  }) : null);
};

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default ResizeControl;