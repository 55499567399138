import { InjectedIntl } from 'react-intl';

import messages from './i18n';

type UserType = Models.UserType<{
  account_status?: BB.AccountStatus;
  display_name: string;
  kind?:
    | 'repository_access_token'
    | 'project_access_token'
    | 'workspace_access_token'
    | 'forge_app';
  links: {
    html?: { href: string };
    avatar: { href: string };
  };
  type?: 'user' | 'team' | 'app_user';
  uuid?: string;
}>;

export const getAvatarUrl = (user: UserType | null | undefined) => {
  if (!user) {
    return undefined;
  }

  // Temporarily enable avatars for teams until we launch workspaces (PHAROS-465)
  if (user.type === 'team') {
    return user.links.avatar.href;
  }

  return user.links.avatar.href;
};

export const getName = (
  user: UserType | null | undefined,
  intl?: InjectedIntl
): string => {
  // Fall back to the original English string if `intl` is not available in the
  // consuming environment
  const formerUser = intl
    ? intl.formatMessage(messages.displayNameDeletedPrivateNickname)
    : messages.displayNameDeletedPrivateNickname.defaultMessage;

  if (!user) {
    return formerUser;
  }

  if (user.type === 'team') {
    return user.display_name;
  }

  return user.display_name || formerUser;
};

export const getProfileUrl = (user: UserType | null | undefined) => {
  if (
    !user ||
    user.type === 'app_user' ||
    (user.type !== 'team' && user.account_status === 'closed')
  ) {
    return undefined;
  }

  if (user.links.html) {
    return user.links.html.href;
  }

  return `/${user.uuid}/`;
};
