import { defineMessages } from 'react-intl';

export default defineMessages({
  manageProjectsDialogHeading: {
    id: 'frontbucket.jiraTab.manageProjectsDialog.heading',
    description: 'Manage projects dialog heading',
    defaultMessage: 'Manage linked Jira projects',
  },
  manageProjectsDialogDetails: {
    id: 'frontbucket.jiraTab.manageProjectsDialog.details',
    description: 'Manage projects dialog details',
    defaultMessage:
      'Link Jira projects to this repository by including an issue key in any branch name or commit. Alternatively you can manually select a project below.',
  },
  errorLoadingProjectsMessage: {
    id: 'frontbucket.jiraTab.manageProjectsDialog.errorLoadingProjectsMessage',
    description: 'Message displayed when failed to load linked projects.',
    defaultMessage: `We can't load the project list.`,
  },
  unlinkProjectLabel: {
    id: 'frontbucket.jiraTab.manageProjectsDialog.unlinkProjectLabel',
    description: 'Unlink project label.',
    defaultMessage: 'Unlink project',
  },
  linkedProjectsTitle: {
    id: 'frontbucket.jiraTab.manageProjectsDialog.linkedProjectsTitle',
    description: 'Linked projects list title.',
    defaultMessage: 'Linked projects',
  },
  automaticallyLinkedProjectLabel: {
    id: 'frontbucket.jiraTab.manageProjectsDialog.automaticallyLinkedProjectLabel',
    description: 'Label for a project that was linked automatically.',
    defaultMessage: 'Linked via commit/pull request',
  },
  manuallyLinkedProjectLabel: {
    id: 'frontbucket.jiraTab.manageProjectsDialog.manuallyLinkedProjectLabel',
    description: 'Label for a project that was linked manually.',
    defaultMessage: 'Manually linked by {personName}',
  },
  closeManageProjectsDialogLabel: {
    id: 'frontbucket.jiraTab.manageProjectsDialog.closeManageProjectsDialogLabel',
    description: 'Close manage projects dialog label.',
    defaultMessage: 'Close manage projects dialog',
  },
  manuallyLinkProjectTitle: {
    id: 'frontbucket.jiraTab.manageProjectsDialog.manuallyLinkProjectTitle',
    description: 'Manually link project title.',
    defaultMessage: 'Manually link project',
  },
  unlinkProjectTitle: {
    id: 'frontbucket.jiraTab.manageProjectsDialog.unlinkProjectTitle',
    description: 'Unlink project title.',
    defaultMessage: 'Unlink project',
  },
  unlinkProjectDetails: {
    id: 'frontbucket.jiraTab.manageProjectsDialog.unlinkProjectDetails',
    description: 'Unlink project details.',
    defaultMessage:
      '<p>Are you sure that you want to unlink the <b>{projectName} ({projectKey})</b> project from this repository?</p>' +
      '<p>You can link this project again in the future by manually linking it from ‘Manage linked projects’.</p>',
  },
  unlinkProjectCancel: {
    id: 'frontbucket.jiraTab.manageProjectsDialog.unlinkProjectCancel',
    description: 'Unlink project dialog cancel button.',
    defaultMessage: 'Cancel',
  },
  unlinkProjectConfirm: {
    id: 'frontbucket.jiraTab.manageProjectsDialog.unlinkProjectConfirm',
    description: 'Unlink project dialog confirm button.',
    defaultMessage: 'Confirm',
  },
  linkProjectFailedMessage: {
    id: 'frontbucket.jiraTab.manageProjectsDialog.linkProjectFailedMessage',
    description: 'Failed to link project message.',
    defaultMessage: 'Failed to link project',
  },
  linkProjectSuccessMessage: {
    id: 'frontbucket.jiraTab.manageProjectsDialog.linkProjectSuccessMessage',
    description: 'Project linked successfully message.',
    defaultMessage: 'Project linked successfully',
  },
  unlinkProjectFailedMessage: {
    id: 'frontbucket.jiraTab.manageProjectsDialog.unlinkProjectFailedMessage',
    description: 'Failed to link project message.',
    defaultMessage: 'Failed to unlink project',
  },
  unlinkProjectSuccessMessage: {
    id: 'frontbucket.jiraTab.manageProjectsDialog.unlinkProjectSuccessMessage',
    description: 'Project linked successfully message.',
    defaultMessage: 'Project unlinked successfully',
  },
});
