import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from 'react';

// eslint-disable-next-line @repo/internal/react/require-jsdoc
export default function useOverflowable(truncationWidth, buttonRefCurrent, iconWidthAllowance) {
  // Default to true to match hasOverflow = true default in Step component.
  // This should ensure the icon never appears and then quickly disappears
  var _useState = useState(true),
    _useState2 = _slicedToArray(_useState, 2),
    hasOverflow = _useState2[0],
    setOverflow = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    showTooltip = _useState4[0],
    setShowTooltip = _useState4[1];

  // Need to recalculate on every render cycle as text/icons/width changing will change the outcome
  useEffect(function () {
    if (truncationWidth && buttonRefCurrent) {
      // Calculate if the button width will be larger than the truncation width after allowing for icon widths.
      // The button having a width greater than the truncationWidth, with icons, indicates the icons should be hidden to avoid going over the width limit
      var shouldOverflow = buttonRefCurrent.clientWidth + iconWidthAllowance > truncationWidth;

      // The button width can already be equal to the truncationWidth which is an indicator that truncation is occurring and a tooltip should be displayed
      var shouldShowTooltip = buttonRefCurrent.clientWidth + iconWidthAllowance >= truncationWidth;
      setOverflow(shouldOverflow);
      setShowTooltip(shouldShowTooltip);
    } else {
      setOverflow(false);
      setShowTooltip(false);
    }
  }, [truncationWidth, buttonRefCurrent, iconWidthAllowance]);
  return [hasOverflow, showTooltip];
}