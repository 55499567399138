import { defineMessages } from 'react-intl';

export default defineMessages({
  fileModeOnboardingFlagTitle: {
    id: 'frontbucket.pullRequest.fileModeOnboardingFlagTitle',
    description: 'Title for the file mode onboarding flag.',
    defaultMessage: 'You can now load files individually',
  },
  fileModeOnboardingFlagDescription: {
    id: 'frontbucket.pullRequest.fileModeOnboardingFlagDescription',
    description: 'Description for the file mode onboarding flag.',
    defaultMessage:
      'Improve performance and stay focused by configuring your diff settings.',
  },
  gotItAction: {
    id: 'frontbucket.pullRequest.gotItAction',
    description: 'Text for "Got it" action.',
    defaultMessage: 'Got it',
  },
  viewPrefsAction: {
    id: 'frontbucket.pullRequest.viewPrefsAction',
    description: 'Text for "Take me to diff settings" action.',
    defaultMessage: 'Take me to diff settings',
  },
});
