import { OfficialTemplate } from 'src/components/pipelines/models';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import { REQUEST_OFFICIAL_TEMPLATES } from '../actions/pipelines';

export type OfficialTemplatesState = OfficialTemplate[];

export const initialState: OfficialTemplatesState = [];

export default createReducer(initialState, {
  [REQUEST_OFFICIAL_TEMPLATES.SUCCESS](
    state: OfficialTemplatesState,
    action: Action<OfficialTemplatesState[]>
  ) {
    if (!action.payload) {
      return state;
    }
    return action.payload?.map(
      (officialTemplate, id) =>
        new OfficialTemplate({ ...officialTemplate, id })
    );
  },
});
