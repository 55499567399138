/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import * as Sentry from '@sentry/browser';
import qs from 'qs';
import { call, put, select } from 'redux-saga/effects';

import { uncurlyUuid } from '@atlassian/bitkit-analytics';

import { User } from 'src/components/types';
import { SEARCH_RECENT_LOCALSTORAGE_KEY } from 'src/constants/search';
import { FetchSearchResults } from 'src/redux/search/actions';
import { SearchResultsViewedFact } from 'src/sections/search/facts';
import { SearchQueryParams } from 'src/types/search';
import urls from 'src/urls/search';
import {
  publishFact,
  publishTrackEvent,
  TrackEvent,
} from 'src/utils/analytics/publish';
import authRequest from 'src/utils/fetch';
import { statsdApiClient } from 'src/utils/metrics';
import store from 'src/utils/store';

import { getSearchAccount } from '../selectors';

type SearchParamsAction = {
  type: 'search/FETCH_SEARCH_RESULTS';
  payload: SearchQueryParams;
};

export default function* fetchSearchResultSaga({
  payload: { q, account, page, extra },
}: SearchParamsAction) {
  const targetAccount: User = yield select(getSearchAccount, { account });
  const params = {
    search_query: q,
    page,
    fields: '+values.file.commit.repository.mainbranch.name',
  };
  const url = `${urls.api.v20.code(targetAccount)}?${qs.stringify(params)}`;
  store.set(SEARCH_RECENT_LOCALSTORAGE_KEY, targetAccount.uuid);

  const start = Date.now();
  try {
    const searchTrackEvent: TrackEvent = {
      action: 'searched',
      actionSubject: 'code',
      actionSubjectId: uncurlyUuid(targetAccount.uuid),
      source: extra?.source || '',
      attributes: {},
    };

    const response: Response = yield call(fetch, authRequest(url));
    // @ts-ignore
    const json = yield response.json();
    const tags = [
      `response:${response.status === 200 ? 'ok' : 'error'}`,
      `status:${response.status}`,
    ];

    statsdApiClient.histogram(
      {
        'search_results.search.timing': Math.round(Date.now() - start),
      },
      { tags }
    );

    if (response.ok) {
      if (!page) {
        // only fire on initial query
        yield call(publishTrackEvent, {
          ...searchTrackEvent,
          attributes: { status: 'success' },
        });
        yield call(
          publishFact,
          new SearchResultsViewedFact({
            total_result_count: json.size,
            is_substituted: json.query_substituted,
          })
        );
      }

      yield put({
        type: FetchSearchResults.SUCCESS,
        payload: json,
      });
    } else {
      if (!page && json.error && json.error.data && json.error.data.key) {
        const errorKey = json.error.data.key;
        yield call(publishTrackEvent, {
          ...searchTrackEvent,
          attributes: {
            status: 'error',
            error: errorKey,
          },
        });
        yield call(
          publishFact,
          new SearchResultsViewedFact({
            error: errorKey,
          })
        );
      }
      yield put({
        type: FetchSearchResults.ERROR,
        error: true,
        meta: json,
      });
    }
  } catch (err) {
    statsdApiClient.histogram(
      {
        'search_results.search.timing': Math.round(Date.now() - start),
      },
      { tags: ['response:error'] }
    );
    yield put({
      type: FetchSearchResults.ERROR,
      error: true,
      // An empty object (`{}`) will trigger the display of a generic error msg
      meta: {},
    });

    if (err instanceof Error) {
      Sentry.captureException(err);
    }
  }
}
