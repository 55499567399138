import { defineMessages } from 'react-intl';

export default defineMessages({
  loadContextErrorTitle: {
    id: 'frontbucket.dashboard.loadContextErrorTitle',
    description: 'Text for the title of the load context error message',
    defaultMessage: 'Something went wrong',
  },
  loadContextErrorDescription: {
    id: 'frontbucket.dashboard.loadContextErrorDescription',
    description: 'Text for the description of the load context error message',
    defaultMessage:
      'We are unable to load additional context. Please try again.',
  },
  loadContextForbiddenNotFoundTitle: {
    id: 'frontbucket.dashboard.loadContextForbiddenNoFoundTitle',
    description: 'Text for the title of the load context error message',
    defaultMessage: 'Unable to load context',
  },
  loadContextForbiddenNotFoundDescription: {
    id: 'frontbucket.dashboard.loadContextForbiddenNotFoundDescription',
    description: 'Text for the description of the load context error message',
    defaultMessage:
      'The source repository either does not exist or you do not have access.',
  },
});
