/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';

export const AILogoWrapper = styled.div`
  > svg {
    width: ${token('space.300', '24px')};
    height: ${token('space.300', '24px')};
  }
`;
