import createReducer from 'src/utils/create-reducer';

import { LoadDashboardOverview } from '../actions';

export type DashboardOverviewState = {
  isLoading: boolean;
  isError: boolean;
};

const initialState: DashboardOverviewState = {
  isLoading: false,
  isError: false,
};

export default createReducer(initialState, {
  [LoadDashboardOverview.REQUEST](state) {
    return {
      ...state,
      isLoading: true,
    };
  },

  [LoadDashboardOverview.SUCCESS](state) {
    return {
      ...state,
      isLoading: false,
      isError: false,
    };
  },

  [LoadDashboardOverview.ERROR](state) {
    return {
      ...state,
      isLoading: false,
      isError: true,
    };
  },
});
