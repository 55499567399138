import { getBooleanFF } from '@atlaskit/platform-feature-flags';
import { B200, B300, B400, B50, B75, DN10, DN200, DN30, DN80, DN90, N10, N100, N20, N30, N40, N70, R300 } from '@atlaskit/theme/colors';
var theme = {
  light: {
    borderColor: {
      rest: getBooleanFF('platform.design-system-team.update-border-radio-checkbox_7askv') ? "var(--ds-border-bold, ".concat(N100, ")") : "var(--ds-border-input, ".concat(N40, ")"),
      hovered: getBooleanFF('platform.design-system-team.update-border-radio-checkbox_7askv') ? "var(--ds-border-bold, ".concat(N100, ")") : "var(--ds-border-input, ".concat(N40, ")"),
      disabled: "var(--ds-background-disabled, ".concat(N20, ")"),
      checked: "var(--ds-background-selected-bold, ".concat(B400, ")"),
      active: "var(--ds-border, ".concat(B50, ")"),
      invalid: "var(--ds-border-danger, ".concat(R300, ")"),
      invalidAndChecked: "var(--ds-border-danger, ".concat(R300, ")"),
      focused: "var(--ds-border-focused, ".concat(B200, ")"),
      hoveredAndChecked: "var(--ds-background-selected-bold-hovered, ".concat(B300, ")")
    },
    boxColor: {
      rest: "var(--ds-background-input, ".concat(N10, ")"),
      hovered: "var(--ds-background-input-hovered, ".concat(N30, ")"),
      disabled: "var(--ds-background-disabled, ".concat(N20, ")"),
      active: "var(--ds-background-input-pressed, ".concat(B50, ")"),
      hoveredAndChecked: "var(--ds-background-selected-bold-hovered, ".concat(B300, ")"),
      checked: "var(--ds-background-selected-bold, ".concat(B400, ")")
    },
    tickColor: {
      disabledAndChecked: "var(--ds-icon-disabled, ".concat(N70, ")"),
      activeAndChecked: "var(--ds-icon-inverse, ".concat(B400, ")"),
      checked: "var(--ds-icon-inverse, ".concat(N10, ")")
    }
  },
  dark: {
    borderColor: {
      rest: getBooleanFF('platform.design-system-team.update-border-radio-checkbox_7askv') ? "var(--ds-border-bold, ".concat(DN200, ")") : "var(--ds-border-input, ".concat(DN80, ")"),
      hovered: "var(--ds-border-input, ".concat(DN200, ")"),
      disabled: "var(--ds-background-disabled, ".concat(DN10, ")"),
      checked: "var(--ds-background-selected-bold, ".concat(B400, ")"),
      active: "var(--ds-border, ".concat(B200, ")"),
      invalid: "var(--ds-border-danger, ".concat(R300, ")"),
      invalidAndChecked: "var(--ds-border-danger, ".concat(R300, ")"),
      focused: "var(--ds-border-focused, ".concat(B75, ")"),
      hoveredAndChecked: "var(--ds-background-selected-bold-hovered, ".concat(B75, ")")
    },
    boxColor: {
      rest: "var(--ds-background-input, ".concat(DN10, ")"),
      hovered: "var(--ds-background-input-hovered, ".concat(DN30, ")"),
      disabled: "var(--ds-background-disabled, ".concat(DN10, ")"),
      active: "var(--ds-background-input-pressed, ".concat(B200, ")"),
      hoveredAndChecked: "var(--ds-background-selected-bold-hovered, ".concat(B75, ")"),
      checked: "var(--ds-background-selected-bold, ".concat(B400, ")")
    },
    tickColor: {
      disabledAndChecked: "var(--ds-icon-disabled, ".concat(DN90, ")"),
      activeAndChecked: "var(--ds-icon-inverse, ".concat(DN10, ")"),
      checked: "var(--ds-icon-inverse, ".concat(DN10, ")")
    }
  }
};
export default theme;