"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Identifiers;
(function (Identifiers) {
    Identifiers["ANALYTICS_ANONYMOUS_ID"] = "analyticsAnonymousId";
    Identifiers["ATLASSIAN_ACCOUNT_ID"] = "atlassianAccountId";
    Identifiers["TENANT_ID"] = "tenantId";
    Identifiers["TRELLO_ANONYMOUS_ID"] = "trelloAnonymousUserId";
    Identifiers["TRELLO_USER_ID"] = "trelloUserId";
    Identifiers["FF_CLIENT_ANONYMOUS_ID"] = "ffClientAnonymousId";
})(Identifiers = exports.Identifiers || (exports.Identifiers = {}));
/**
 * Reasons from a FeatureFlag evaluation to explain the value provided.
 *
 * By default it uses 'OFF' = 0, which consider 0 in EvaluationReason and '0' in EvaluationReason
 * are valid EvaluationReason value
 * We set the enum value to string thus it can perform proper 'reason in EvaluationReason' check
 *
 */
var EvaluationReason;
(function (EvaluationReason) {
    EvaluationReason["OFF"] = "OFF";
    EvaluationReason["FALLTHROUGH"] = "FALLTHROUGH";
    EvaluationReason["RULE_MATCH"] = "RULE_MATCH";
    EvaluationReason["TARGET_MATCH"] = "TARGET_MATCH";
    EvaluationReason["PREREQUISITE_FAILED"] = "PREREQUISITE_FAILED";
    EvaluationReason["ERROR"] = "ERROR";
    EvaluationReason["INELIGIBLE"] = "INELIGIBLE";
    EvaluationReason["OVERRIDE"] = "OVERRIDE";
})(EvaluationReason = exports.EvaluationReason || (exports.EvaluationReason = {}));
/**
 * The error kind when there is a problem evaluating a FeatureFlag
 */
var EvaluationErrorKind;
(function (EvaluationErrorKind) {
    EvaluationErrorKind["CLIENT_NOT_READY"] = "CLIENT_NOT_READY";
    EvaluationErrorKind["FLAG_NOT_FOUND"] = "FLAG_NOT_FOUND";
    EvaluationErrorKind["WRONG_TYPE"] = "WRONG_TYPE";
    EvaluationErrorKind["VALIDATION_ERROR"] = "VALIDATION_ERROR";
    EvaluationErrorKind["SERVER_ERROR"] = "SERVER_ERROR";
    EvaluationErrorKind["OTHER"] = "OTHER";
})(EvaluationErrorKind = exports.EvaluationErrorKind || (exports.EvaluationErrorKind = {}));
var EnvironmentType;
(function (EnvironmentType) {
    EnvironmentType["LOCAL"] = "local";
    EnvironmentType["DEV"] = "dev";
    EnvironmentType["STAGING"] = "staging";
    EnvironmentType["PROD"] = "prod";
})(EnvironmentType = exports.EnvironmentType || (exports.EnvironmentType = {}));
