import React, { useMemo } from 'react';

import { LoadingButton } from '@atlaskit/button';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu-12';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import Tooltip from '@atlaskit/tooltip';

import { Action, DisabledState } from './Action';
import SingleActionButton from './SingleActionButton';
import { ButtonOverlay, MultiActionButtonWrapper } from './styled';

export interface Props {
  title: string;
  isLoading?: boolean;
  disabled?: DisabledState;
  actions: Action[];
}

/* eslint @typescript-eslint/ban-types: "warn" */
const MultiActionButton: React.FC<Props> = ({
  title,
  isLoading = false,
  disabled = DisabledState.empty,
  actions,
}) => {
  const availableActions = useMemo(
    () => actions.filter(a => a.isVisible),
    [actions]
  );
  const singleAction = useMemo(() => {
    if (availableActions.length === 1) {
      return {
        ...availableActions[0],
        disabled: disabled.or(
          availableActions[0].disabled || DisabledState.empty
        ),
      };
    } else {
      return null;
    }
  }, [disabled, availableActions]);

  if (!availableActions.length) {
    return null;
  }

  if (singleAction) {
    return <SingleActionButton action={singleAction} isLoading={isLoading} />;
  }

  return (
    <MultiActionButtonWrapper>
      <Tooltip content={!isLoading && disabled?.reason} position="left">
        {!isLoading && disabled?.value && <ButtonOverlay />}
        <DropdownMenu
          trigger={({ triggerRef, ...props }) => (
            <LoadingButton
              {...props}
              isDisabled={disabled?.value}
              spacing="compact"
              iconAfter={
                <ChevronDownIcon testId="chevron-down-icon" label="" />
              }
              ref={triggerRef}
              isLoading={isLoading}
              testId="dropdown-menu"
            >
              {title}
            </LoadingButton>
          )}
          placement="bottom-end"
        >
          <DropdownItemGroup>
            {actions.map(
              action =>
                action.isVisible && (
                  <Tooltip
                    content={action.disabled?.reason}
                    position="left"
                    key={action.key}
                  >
                    <DropdownItem
                      onClick={action.onClick}
                      isDisabled={action.disabled?.value}
                      testId={`dropdown-item-${action.key}`}
                    >
                      {action.name}
                    </DropdownItem>
                  </Tooltip>
                )
            )}
          </DropdownItemGroup>
        </DropdownMenu>
      </Tooltip>
    </MultiActionButtonWrapper>
  );
};

export default React.memo(MultiActionButton);
