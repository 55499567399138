import React, { Fragment, PureComponent } from 'react';

import { InjectedIntl } from 'react-intl';

import { Inline, Text } from '@atlaskit/primitives';

import { KeyboardShortcuts } from 'src/redux/keyboard-shortcuts';

import { keyboardShortcutDialogMessages } from './keyboard-shortcut-dialog.i18n';
import * as styles from './keyboard-shortcut-menu.style';

type Props = {
  shortcuts: KeyboardShortcuts;
  intl: InjectedIntl;
};

export class KeyboardShortcutMenu extends PureComponent<Props> {
  static defaultProps = {
    /* eslint react/default-props-match-prop-types: "warn" */
    shortcuts: {},
  };

  render() {
    const { shortcuts, intl } = this.props;
    const sortedGroupIds = Object.keys(shortcuts).sort(
      (idA: string, idB: string) =>
        (shortcuts[idA].groupWeight || 0) - (shortcuts[idB].groupWeight || 0)
    );
    return (
      <Fragment>
        {sortedGroupIds.map(
          id =>
            !!shortcuts[id].groupTitle && (
              <styles.ShortcutGroup key={id}>
                <styles.GroupTitle>
                  {shortcuts[id].groupTitle}
                </styles.GroupTitle>
                <styles.Shortcuts>
                  {shortcuts[id].shortcuts.map(
                    shortcut =>
                      !!shortcut.description && (
                        <styles.Shortcut key={shortcut.id}>
                          <dt>{shortcut.description}</dt>
                          <dd>
                            <Inline>
                              {shortcut.keys[0].match(/[A-Z]/) && (
                                <>
                                  <styles.ShortcutKey>
                                    {intl.formatMessage(
                                      keyboardShortcutDialogMessages.shiftKey
                                    )}
                                  </styles.ShortcutKey>
                                  <Text>+</Text>
                                </>
                              )}
                              <styles.ShortcutKey>
                                {shortcut.keys[0].toLowerCase()}
                              </styles.ShortcutKey>
                            </Inline>
                          </dd>
                        </styles.Shortcut>
                      )
                  )}
                </styles.Shortcuts>
              </styles.ShortcutGroup>
            )
        )}
      </Fragment>
    );
  }
}
