import React from 'react';

import ConfirmationModal from './confirmation-modal';
import { SharedProps } from './confirmation-modal/confirmation-modal.types';

// Some predefined variants of this modal rergarding the
// current UI on the project
export const WarningModal = (props: SharedProps) => (
  <ConfirmationModal {...props} appearance="warning" />
);

export const DangerModal = (props: SharedProps) => (
  <ConfirmationModal {...props} appearance="danger" />
);

export const RemoveModal = (props: SharedProps) => (
  <ConfirmationModal
    {...props}
    appearance="warning"
    buttonText={{
      confirm: 'remove',
    }}
  />
);

export const DeleteModal = (props: SharedProps) => (
  <ConfirmationModal
    {...props}
    appearance="danger"
    buttonText={{
      confirm: 'delete',
    }}
  />
);

export {
  default as ConfirmationModal,
  ConfirmationModalProps,
} from './confirmation-modal';
