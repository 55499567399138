import {
  createResource,
  RouteResource,
  RouterContext,
} from 'react-resource-router';

import authRequest from 'src/utils/fetch';

import { WebhookTriggerKey } from '../triggers';
import {
  ConnectHook,
  ConnectHookApiModel,
  ConnectHookListApiModel,
} from '../types';

// convert a list of url,event pairs into a map of url->[event]
function eventsByUrl(
  list: ConnectHookApiModel[]
): Record<string, WebhookTriggerKey[]> {
  return list.reduce(function (acc: Record<string, WebhookTriggerKey[]>, hook) {
    const key = hook.url;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(hook.event);
    return acc;
  }, {});
}

function connectHooksDjangoToJs(json: ConnectHookListApiModel): ConnectHook[] {
  const hookEventsByUrl = eventsByUrl(json.hooks);
  const result = Object.entries(hookEventsByUrl).map(item => {
    return {
      uuid: json.connection_uuid,
      installationId: json.context_ari,
      description: json.connection_name,
      url: item[0],
      events: item[1],
    };
  });
  return result;
}

export const loadConnectWebhooksResource: RouteResource<ConnectHook[]> =
  createResource({
    type: 'connectWebhooks',
    getKey: ({ match }: RouterContext) =>
      `connectWebhooks:${match.params.repositoryOwner}`,
    maxAge: 60000, // cache connect hooks for 1 minute so navigating between pages is fast
    getData: async ({ match }) => {
      const { repositoryOwner, repositorySlug } = match.params;
      if (!repositoryOwner || !repositorySlug) {
        throw new Error(
          'Could not fetch Connect webhooks, URL params were not available'
        );
      }
      const resp = await fetch(
        authRequest(
          `/!api/internal/repositories/${repositoryOwner}/${repositorySlug}/connect/hooks`
        )
      );
      if (!resp.ok) {
        throw new Error('Could not fetch Connect webhooks');
      }
      const body: ConnectHookListApiModel[] = await resp.json();
      return body.map(connectHooksDjangoToJs).flat();
    },
  });
