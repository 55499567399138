import {
  getCurrentPipeline,
  getCurrentStepUuid,
} from 'src/components/pipelines/redux/selectors/pipelines';
import {
  capturePipelinesExceptionWithTags,
  createErrorMessage,
} from 'src/components/pipelines/utils/sentry';
import { BucketState } from 'src/types/state';
import authRequest from 'src/utils/fetch';

export async function fetchData(
  url: string,
  exceptionSegment: string,
  options: RequestInit = {}
): Promise<any> {
  try {
    const req = authRequest(url, options);
    const resp = await fetch(req);

    if (!resp.ok) {
      throw new Error(await createErrorMessage(resp));
    }

    const data = await resp.json();
    return data;
  } catch (e) {
    capturePipelinesExceptionWithTags(e, {
      segment: exceptionSegment,
    });
    throw e;
  }
}

export const hasFetchedPipeline = (state: BucketState): boolean => {
  return !!getCurrentPipeline(state).uuid;
};

export const hasFetchedSteps = (state: BucketState): boolean => {
  return !!getCurrentStepUuid(state);
};
