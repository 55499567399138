import React, { memo } from 'react';

import Helmet from 'react-helmet';

import { AddonManager, ConnectModule } from '@atlassian/bitbucket-connect-js';
import {
  ConnectModules,
  ConnectModuleIframe,
} from '@atlassian/bitbucket-connect-react';

import { GlobalError } from 'src/connect/shared/global-error';
import { RepositoryTarget, WorkspaceTarget } from 'src/connect/targets';

type Props = {
  principalId?: string;
  appKey: string;
  moduleKey: string;
  target: RepositoryTarget | WorkspaceTarget;
  modules?: string; // comma seperated string of moduleType and/or moduleType:location
};

export const SettingsConnectIframe = memo(
  ({
    principalId,
    appKey,
    moduleKey,
    target,
    modules = 'adminPages:org.bitbucket.repository.admin,generalPages',
  }: Props) => {
    if (!principalId) {
      return null;
    }

    // As we are requesting multiple modules for this connect iframe, we need
    // to use this cache resolver to tell <ConnectModules /> to fetch data
    // if module not found in cache. Otherwise it could find an adminPage or
    // generalPage and return our cache without the module we want
    const cacheResolver = (): ConnectModule[] => {
      return AddonManager.filterModules({
        appKey,
        moduleKey,
        targetHref: target.links.self.href,
      });
    };

    return (
      <ConnectModules
        principalId={principalId}
        query={[
          {
            target,
            modules: modules.split(',').map(mod => {
              const [moduleType, location] = mod.split(':');
              return Object.assign({ moduleType }, location && { location });
            }),
          },
        ]}
        cacheResolver={cacheResolver}
      >
        {({ modules: mods, loading, error }) => {
          const page = mods.find(
            m => m.app_key === appKey && m.descriptor.key === moduleKey
          );
          if (page) {
            return (
              <>
                <Helmet
                  defer={false}
                  title={`/ ${page.descriptor?.name?.value || page.app_name}`}
                />
                <ConnectModuleIframe
                  key={page.id}
                  module={page}
                  iframeContainer={({ children }) => <>{children}</>}
                  options={{
                    autoresize: true,
                    isFullPage: true,
                    __featuresInternal: {
                      'nav-next-settings': true,
                    },
                  }}
                />
              </>
            );
          }
          if (!loading && error) {
            return <GlobalError />;
          }
          return null;
        }}
      </ConnectModules>
    );
  },
  (prev, next) => {
    return (
      prev.principalId === next.principalId &&
      prev.appKey === next.appKey &&
      prev.moduleKey === next.moduleKey &&
      prev.target.links.self.href === next.target.links.self.href &&
      prev.modules === next.modules
    );
  }
);
