/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { R500 } from '@atlaskit/theme/colors';
var requiredIndicatorStyles = css({
  paddingLeft: "var(--ds-space-025, 2px)",
  color: "var(--ds-text-danger, ".concat(R500, ")")
});
export default function RequiredIndicator() {
  return jsx("span", {
    css: requiredIndicatorStyles,
    "aria-hidden": true
  }, "*");
}