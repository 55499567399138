'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

/**
 * Parse unified diff input
 * see: http://www.gnu.org/software/diffutils/manual/diffutils.html#Unified-Format
 */


exports.default = function (input) {
  var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (!input) return [];
  if (input.match(/^\s+$/)) return [];

  var lines = input.split('\n');
  if (lines.length == 0) return [];

  var files = [];
  var file = null;
  var oldLine = 0;
  var newLine = 0;
  var position = 0;
  var current = null;
  var isInChunk = false;

  function start(line) {
    isInChunk = false;

    var _parseFile = parseFile(line),
        _parseFile2 = _slicedToArray(_parseFile, 2),
        from = _parseFile2[0],
        to = _parseFile2[1];

    file = {
      from: from,
      to: to,
      headers: [],
      chunks: [],
      deletions: 0,
      additions: 0,
      isBinary: false,
      isExcluded: false,
      isImage: false
    };
    files.push(file);
    position = 0;
  }

  function restart() {
    if (!file || file.chunks.length) start();
  }

  function newFile() {
    restart();
    file.new = true;
    file.from = '/dev/null';
  }

  function deletedFile() {
    restart();
    file.deleted = true;
    file.to = '/dev/null';
  }

  function index(line) {
    restart();
    file.index = line.split(' ').slice(1);
  }

  function fromFile(line) {
    if (isInChunk) {
      del(line);
      return;
    }

    restart();
    file.from = parseFileFallback(line);
  }

  function toFile(line) {
    restart();
    file.to = parseFileFallback(line);
  }

  function chunk(line, match) {
    isInChunk = true;
    var oldStart = +match[1];
    var oldLines = match[2] === '' ? 1 : +match[2];
    var newStart = +match[3];
    var newLines = match[4] === '' ? 1 : +match[4];
    oldLine = oldStart;
    newLine = newStart;
    current = {
      content: line,
      changes: [],
      oldStart: oldStart,
      oldLines: oldLines,
      newStart: newStart,
      newLines: newLines
    };
    file.chunks.push(current);
    if (!position) position = 1;
  }

  function del(line) {
    current.changes.push({
      type: 'del',
      del: true,
      oldLine: oldLine++,
      position: position++,
      content: line
    });
    file.deletions++;
  }

  function add(line) {
    current.changes.push({
      type: 'add',
      add: true,
      newLine: newLine++,
      position: position++,
      content: line
    });
    file.additions++;
  }

  var noeol = '\\ No newline at end of file';

  function normal(line) {
    if (!file) return;
    current.changes.push({
      type: 'normal',
      normal: true,
      oldLine: line !== noeol ? oldLine++ : undefined,
      newLine: line !== noeol ? newLine++ : undefined,
      position: position++,
      content: line
    });
  }

  function binaryFile() {
    file.isBinary = true;
  }

  function excludedFile() {
    file.isExcluded = true;
  }

  function resetChunk() {
    /*
    Knowing if we're inside a chunk is vital to not mis-parse sql comments.
    However we need to reset this value when we start
    new svn files to not mis-parse svn filenames.
    */
    file = null;
    isInChunk = false;
  }

  var schema = [[/^\s+/, normal], [/^diff\s/, start], [/^GIT binary patch$/, binaryFile], [/^File excluded by pattern\s/, excludedFile], [/^Binary files? .* (?:differ|has changed)$/, binaryFile], [/^new file mode \d+$/, newFile], [/^deleted file mode \d+$/, deletedFile], [/^index\s[\da-zA-Z]+\.\.[\da-zA-Z]+(\s(\d+))?$/, index], [/^Index:/, resetChunk], [/^(---|rename from)\s/, fromFile], [/^(\+\+\+|rename to)\s/, toFile], [/^@@\s+\-(\d+),?(\d*)\s+\+(\d+),?(\d*)\s@@/, chunk], [/^-/, del], [/^\+/, add]];

  function parse(line) {
    var result = schema.some(function (p) {
      var _p = _slicedToArray(p, 2),
          pattern = _p[0],
          handler = _p[1];

      if (typeof handler !== 'function') {
        throw new Error(pattern + ' has no handler');
      }
      var m = line.match(pattern);
      if (m) {
        handler(line, m);
        return true;
      }
      return false;
    });

    if (file && !isInChunk) {
      file.headers.push(line);
    }

    return result;
  }

  lines.forEach(parse);
  if (opts.findRenames) {
    consolidateRenames(files);
  }
  identifyImageFiles(files);
  return files;
};

var _mimeTypes = require('mime-types');

var _mimeTypes2 = _interopRequireDefault(_mimeTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function getContent(file) {
  return file.chunks.map(function (chunk) {
    return chunk.changes.map(function (c) {
      return c.content.slice(1);
    });
  }).join('\n');
}

function consolidateRenames(files) {
  var newFiles = files.filter(function (f) {
    return f.new;
  });
  newFiles.forEach(function (newFile) {
    var newContent = getContent(newFile);
    var i = files.findIndex(function (f) {
      return f.deleted && getContent(f) == newContent;
    });
    if (~i) {
      var oldFile = files[i];
      files.splice(i, 1);
      delete newFile.new;
      delete newFile.chunks;
      delete newFile.deletions;
      delete newFile.additions;
      delete newFile.index;
      newFile.renamed = true;
      newFile.from = oldFile.from;
    }
  });
}

/**
 * Process a list of files and set isImage to true on any file with an image mimetype.
 *
 * @param files - list of files
 */
function identifyImageFiles(files) {
  files.forEach(function (file) {
    var fromIsImage = isImageMimetype(file.from);
    var toIsImage = isImageMimetype(file.to);
    if ((file.from === '/dev/null' || fromIsImage) && (file.to === '/dev/null' || toIsImage)) {
      // Technically this means we'll set isImage to true if both the to and from
      // paths are /dev/null, but that should never happen so we don't really care
      file.isImage = true;
    }
  });
}

function isImageMimetype(filename) {
  // Approved by security to render all image file extensions.
  // mime.lookup returns false if cannot find mimetype.
  // Otherwise, returns a String mimetype that we check.
  var mimetype = _mimeTypes2.default.lookup(filename);

  // https://softwareteams.atlassian.net/browse/COREX-8074
  // .fbs is also flatbuffer schema
  if (mimetype === 'image/vnd.fastbidsheet') {
    return false;
  }

  return mimetype && mimetype.startsWith('image');
}

function parseFile(s) {
  if (!s) return [];

  var m = void 0;

  if (m = s.match(/^diff (?:.+ )?("?)a\/(.+)\1 \1b\/\2\1$/)) {
    // git diff without rename
    return [m[2], m[2]];
  } else if (m = s.match(/^diff (?:.+ )?("?)a\/(.+)\1 \1b\/(.+)\1$/)) {
    // git diff with rename
    // This might not work for some ambiguous paths with spaces, but it will be
    // corrected in the fallback.
    return [m[2], m[3]];
  } else if (m = s.match(/^diff -r [0-9a-f]+ -r [0-9a-f]+ (.+)$/)) {
    // hg diff
    // This won't work with renames, but it will be corrected in the fallback.
    return [m[1], m[1]];
  } else {
    var fileNames = s.split(' ').slice(-2);
    return fileNames.map(function (f) {
      return f.replace(/^("?)(a|b)\/(.+)\1$/, '$3');
    });
  }
}

function parseFileFallback(s) {
  if (s.match(/^rename (from|to) /)) {
    return s.replace(/^rename (from|to) /, '');
  } else {
    // ignore normal prefix
    s = s.replace(/^(---|\+\+\+) /, '');

    // ignore possible GNU-style timestamp
    s = s.replace(/\t\d{4}-\d\d-\d\d \d\d:\d\d:\d\d(.\d+)? (\+|-)\d\d\d\d$/, '');
    // ignore possible Hg-style timestamp
    s = s.replace(/\t[A-Z][a-z]{2} [A-Z][a-z]{2} \d\d \d\d:\d\d:\d\d \d{4} (\+|-)\d{4}$/, '');
    // ignore possible SVN info
    s = s.replace(/\t\([^\)]+\)$/, '');

    if (s.includes(' ') && s.slice(-1) == '\t') {
      // If a path contains a space and ends with a tab, then remove the tab.
      // This is a compatibility hack that both Git and Hg do so that GNU patch
      // can correctly parse the diffs they generate.
      s = s.slice(0, -1);
    }

    // ignore git prefixes a/ or b/
    return s.replace(/^("?)(a|b)\/(.+)\1$/, '$3');
  }
}
module.exports = exports['default'];