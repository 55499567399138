import { Privilege, PrivilegeOptions } from 'src/components/types';
import { CreateInvitationResponse } from 'src/components/types/src/api/internal/invitation';

import { AccessLevel, LoadingState } from './constants';
import { ModalKeyType, ModalType } from './modals';

export enum AccessMode {
  users = 'users',
  groups = 'groups',
}

export type AccessLevelType = keyof typeof AccessLevel;
export type PrivilegeOptionsType = keyof typeof PrivilegeOptions;

export interface User {
  account_id: string;
  avatar: string;
  display_name: string;
  is_staff: true;
  is_team: false;
  mention_id: string;
  nickname: string;
  uuid: string;
}

export type Workspace = Omit<User, 'is_staff' | 'is_team'> & {
  is_staff?: false;
  is_team: true;
};

export type Repository = {
  name: string;
  owner: User | Workspace;
  slug: string;
};

export type Group = {
  members: User[];
  name: string;
  owner: User | Workspace;
  slug: string;
};

type CommonPrivilege = {
  privilege: Privilege;
  repo: string;
  repository: Repository;
};

export type UserPrivilege = CommonPrivilege & {
  user: User;
};

export type GroupPrivilege = CommonPrivilege & {
  group: Group;
};

export type InheritedPrivilege = {
  access_level: AccessLevelType;
  members_count?: number;
  permission: Privilege;
  principal:
    | (BB.User & { is_active?: boolean })
    | {
        type: 'group';
        owner: BB.User | BB.Team;
        workspace: BB.Workspace;
        slug: string;
        full_slug: string;
        name: string;
        default_permission: string;
        email_forwarding_disabled: boolean | null;
        links: {
          self: {
            href: string;
          };
          html: {
            href: string;
          };
        };
        account_privilege: string;
      };
  principal_name: string;
  resource: BB.Project | BB.Repository;
};

export type InheritedPrivilegesResponse = {
  values: InheritedPrivilege[];
  page: number;
  pagelen: number;
  size: number;
  next?: string; // url provided for next results if any
};

export type FlatPrivilege = {
  id: string;
  name: string;
  privilege: Privilege;
  mode: AccessMode;
  level: AccessLevelType;
  avatar: string;
  nickname: string;
  count: number;
  slug: string;
  is_active?: boolean;
  group_link?: string;
};

export enum ModalDialogAction {
  add = 'add',
  remove = 'remove',
  bulkDelete = 'bulkDelete',
  bulkEdit = 'bulkEdit',
  removeOwnAccessWarning = 'removeOwnAccessWarning',
}

export type CommonUser = {
  avatar_url: string;
  display_name: string;
  is_team: false;
  is_teammate: boolean;
  keywords: string[];
  mention_id: string;
  nickname: string;
  has_access?: boolean;
};

export type CommonGroup = {
  auto_add: boolean;
  email_forwarding_disabled: boolean;
  members: User[];
  name: string;
  owner: User | Workspace;
  permission: Privilege;
  slug: string;
  has_access?: boolean;
};

export type WorkspaceGroup = CommonGroup & {
  account_privilege: null | BB.AccountPrivilege;
};

export type FlatEntity = {
  id: string;
  name: string;
  avatar: string;
  mode: AccessMode;
};

export type FlatPrincipal = FlatEntity & { hasAccess?: boolean };

export type PermissionsProps = {};

export type LoadingStateType = keyof typeof LoadingState;

export type PrivilegeFilter =
  | Privilege
  | PrivilegeOptions
  | keyof typeof PrivilegeOptions
  | null;

export type AccessLevelFilter = AccessLevelType | null;

export type Permission = FlatPrivilege;
export type PermissionOrPermissions = Permission | Permissions;

export type PermissionsState = {
  loadingState: LoadingStateType;
  permissions: Map<number, Permission[]>;
  checkedRows: Permission[];
  limit: number;
  page: number;
  total: number;
  privilegeFilter: PrivilegeFilter;
  accessLevelFilter: AccessLevelFilter;
  activeModal?: ModalType<ModalKeyType>;
  activePermission?: Permission;
  searchTerm?: string;
  adminLevel?: AccessLevelType;
  context: AccessLevelType;
  sumEnabled?: boolean;
  orgAdmin?: boolean;
  workspaceOrgId?: string;
};

// Permission API funcs

export type PermissionsFilters = {
  accessLevel?: AccessLevelType;
  permission?: Privilege | keyof typeof Privilege;
  principalName?: string;
};

export type GetPermissionsOptions = PermissionsFilters & {
  page?: number;
};

export type GetPermissions = (
  options?: GetPermissionsOptions
) => Promise<
  Pick<PermissionsState, 'permissions' | 'page' | 'limit' | 'total'> | undefined
>;

export type AddAccess = (body: {
  principals: Partial<{ account_id: string; slug: string }>[];
  privilege: Privilege | 'none';
}) => Promise<void>;

export type UpdateAccess = (body: {
  principals: Partial<{ account_id: string; slug: string }>[];
  privilege: Privilege | 'none';
}) => Promise<void>;

export type SearchUsersAndGroups = (
  searchTerm: string
) => Promise<Array<FlatPrincipal | undefined>>;

export type CreateInvitation = (body: {
  permission: BB.Privilege;
  emails: string[];
}) => Promise<CreateInvitationResponse | undefined>;

export type AccessCheck = () => Promise<boolean | undefined>;

export type PermissionsAPI = {
  getPermissions: GetPermissions;
  addAccess: UpdateAccess;
  updateAccess: UpdateAccess;
  searchUsersAndGroups: SearchUsersAndGroups;
  createInvitation: CreateInvitation;
  accessCheck: AccessCheck;
};

export type PermissionsAPIProps = {
  api: PermissionsAPI;
};

export type NotUpdatedItems = Array<{
  id: string;
  errorMessage?: string;
  name: string;
}>;

export type isEditableProps = {
  level: AccessLevelType;
  privilege: PrivilegeOptionsType;
};
