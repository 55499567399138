export enum TestOutcomeEnum {
  passed = 'PASSED',
  failed = 'FAILED',
  skipped = 'SKIPPED',
  error = 'ERROR',
}

export type TestCase = {
  uuid: string;
  fullyQualifiedName: string;
  name: string;
  className: string;
  suiteName: string;
  customProperties: Array<{ key: string; value: string }>;
  metricsUpdatedTime?: string;
  metrics: {
    p90Duration: number;
    p10Duration: number;
    averageDuration: number;
    failureCorrelation?: number;
    failureRate: number;
    numberOfTests?: number;
  };
};

export type TestCases = {
  values: Array<TestCase>;
  page: number;
  pagelen: number;
  size: number;
};

export type TestExecution = {
  uuid: string;
  testReportUuid: string;
  testSummaryUuid: string;
  fullyQualifiedName: string;
  name: string;
  className: string;
  suiteName: string;
  packageName?: string;
  target: {
    referenceName: string;
    revision: string;
    type: string;
  };
  customTestProperties: Array<{ key: string; value: string }>;
  customSummaryProperties: Array<{ key: string; value: string }>;
  createdOn: string;
  duration: string;
  status: TestOutcomeEnum;
  executionTime?: number;
  averageExecutionTime?: number;
  reason?: {
    testCaseUuid: string;
    uuid: string;
    message: string;
    stackTrace: string;
  };
};

export type TestExecutions = {
  values: Array<TestExecution>;
  page: number;
  pagelen: number;
  size: number;
};

export type SortData = {
  key: string;
  sortOrder?: 'ASC' | 'DESC';
};
