import { Deployment, Environment } from 'src/components/pipelines/models';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import {
  REMOVE_DEPLOYMENT_DATA,
  REQUEST_DEPLOYMENT_SUMMARY,
} from '../actions/deployments';

export type SummaryState = {
  deployment: Deployment;
  lastSuccessfulDeployment?: Deployment;
  environment: Environment;
};

export const initialState: SummaryState = {
  deployment: new Deployment(),
  lastSuccessfulDeployment: undefined,
  environment: new Environment(),
};

const updateDeployment = (deploymentData: any) => {
  return {
    deployment: new Deployment(deploymentData),
    lastSuccessfulDeployment: deploymentData?.state?.last_successful_deployment
      ?.uuid
      ? new Deployment(deploymentData?.state?.last_successful_deployment)
      : undefined,
    environment: new Environment(deploymentData?.environment),
  };
};

export default createReducer(initialState, {
  [REMOVE_DEPLOYMENT_DATA]() {
    return initialState;
  },
  [REQUEST_DEPLOYMENT_SUMMARY.REQUEST]() {
    return initialState;
  },
  [REQUEST_DEPLOYMENT_SUMMARY.SUCCESS](
    state: SummaryState,
    action: Action<any>
  ) {
    return { ...state, ...updateDeployment(action?.payload) };
  },
});
