// This will get expanded by babel-preset-env's useBuiltins so we only get the polyfills needed
// for our targeted browsers.
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import 'intersection-observer';

// polyfill .remove() for any element in DOM (specifically for IE11)
// https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
// https://caniuse.com/#search=.remove
if (!Element.prototype.remove) {
  Element.prototype.remove = function remove() {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
}

// Polyfill for Promise.allSettled
// Used by @atlassiansox/analytics-web-client
// https://caniuse.com/mdn-javascript_builtins_promise_allsettled
Promise.allSettled =
  Promise.allSettled ||
  ((promises: Promise<any>[]) => {
    return Promise.all(
      promises.map((promise: Promise<any>) => {
        return promise
          .then((value: any) => {
            return { status: 'fulfilled', value };
          })
          .catch((reason: any) => {
            return { status: 'rejected', reason };
          });
      })
    );
  });
