import { createResource } from 'react-resource-router';

import { NavigationData } from 'src/types/navigation';
import urls from 'src/urls/account';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

export const accountNavigationResource = createResource({
  type: 'ACCOUNT_NAVIGATION',
  getKey: () => 'account',
  maxAge: 20 * 1000,
  getData: async (_routerStoreContext): Promise<NavigationData | null> => {
    const requestUrl = urls.api.internal.navigation();

    const response = await fetch(
      authRequest(requestUrl, { headers: jsonHeaders })
    );

    if (!response.ok) {
      throw new Error(
        `Error fetching account navigation data (${response.status}): ${response.statusText}`
      );
    }

    const navigationData = await response.json();

    return navigationData;
  },
});
