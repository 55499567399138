import {
  FabricConversation,
  CodeReviewConversation,
  MinimalCodeReviewConversationComment,
  isApiComment,
  ApiCommentOrPlaceholder,
} from 'src/components/conversation-provider/types';
import {
  buildInlineConversations,
  getNumOfComments,
  toConversation,
} from 'src/components/conversation-provider/utils';
import { getCreatedBy } from 'src/components/conversation-provider/utils/get-created-by';
import { CommentTree } from 'src/components/conversation/src/components/comment-tree';

function isGlobalComment(comment: ApiCommentOrPlaceholder) {
  return !comment.inline;
}

export function commentPermalink(comment: ApiCommentOrPlaceholder): string {
  const id = isApiComment(comment) ? comment.id : -1;
  return `comment-${id}`;
}

export function toMinimalComment(
  comment: ApiCommentOrPlaceholder
): MinimalCodeReviewConversationComment {
  const id = isApiComment(comment) ? comment.id : -1;
  return {
    id,
    permalink: commentPermalink(comment),
    authorUuid: comment.user?.uuid,
  };
}

// This function is exported so it can be used in tests that need to mock CodeReviewConversations
export function getMinimalConversationData(
  conversation: FabricConversation
): CodeReviewConversation {
  const { conversationId, meta, createdAt, comments } = conversation;
  const commentMetaData = comments.map(comment => {
    const { commentId } = comment;
    return {
      id: commentId,
      permalink: `comment-${commentId}`,
      authorUuid: comment.createdBy.id,
    };
  });

  return {
    conversationId,
    comments: commentMetaData,
    meta,
    numOfComments: getNumOfComments(conversation),
    createdAt,
    createdBy: getCreatedBy(conversation),
  };
}

/**
 * Sorting function that sorts by the created_on timestamp and then by id if the timestamps are equal.
 * PlaceholderApiComment comments do not have an id so they are always sorted to the end of the list.
 */
function compareComments(
  a: ApiCommentOrPlaceholder,
  b: ApiCommentOrPlaceholder
) {
  const comparedLocale = a.created_on.localeCompare(b.created_on);
  if (comparedLocale) return comparedLocale;

  if ('id' in b && 'id' in a) return a.id - b.id;

  return 0;
}

export function formatAllComments(
  commentValues: ApiCommentOrPlaceholder[] = [],
  containerId: string
): {
  fabricConversations: FabricConversation[];
  conversations: CodeReviewConversation[];
  commentTree: CommentTree;
} {
  // Sort comments by timestamp and then by id if the timestamps are equal
  // Comments submitted through a multi-comment review all share the same timestamp upon submission
  // Enforce a deterministic order of comments in each conversation
  const comments = [...commentValues].sort(compareComments);

  const rawCommentTree = new CommentTree(comments);

  // First part of condition automatically keeps all placeholder comments
  // Second part of condition Filter out deleted comments with invalid children
  const commentTree = rawCommentTree.filterMatchingSubtrees(
    c => !isApiComment(c) || !c.deleted
  );

  const globalComments = comments.filter(isGlobalComment);
  const globalConversation = toConversation(globalComments, containerId);
  const inlineConversations = buildInlineConversations(
    comments,
    commentTree.commentChildrenMap(),
    containerId
  );

  // Global Convo must go first!
  const fabricConversations = globalConversation
    ? [globalConversation, ...inlineConversations]
    : inlineConversations;

  return {
    fabricConversations,
    conversations: fabricConversations.map(getMinimalConversationData) || [],
    commentTree,
  };
}

export function isFileLevelComment(conversation: CodeReviewConversation) {
  return (
    conversation.meta.from === null &&
    conversation.meta.to === null &&
    conversation.meta.path
  );
}
