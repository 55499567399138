import { useEffect } from 'react';

import { useRecentData } from 'src/hooks/recent-data';

export const LoadRecentWorkspaces = (): null => {
  const { loadRecentWorkspaces } = useRecentData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadRecentWorkspaces, []);
  return null;
};
