/* eslint-disable @atlassian/tangerine/import/no-relative-package-imports, @atlassian/tangerine/import/no-restricted-paths, @atlassian/tangerine/import/entry-points, @atlassian/tangerine/import/no-parent-imports */

import SuccessIcon from '@atlaskit/icon/core/migration/check-circle';
import CommentIcon from '@atlaskit/icon/core/migration/comment';
import DownloadIcon from '@atlaskit/icon/core/migration/download';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import FeedbackIcon from '@atlaskit/icon/core/migration/feedback';
import EditorInfoIcon from '@atlaskit/icon/core/migration/information--editor-info';
import InfoIcon from '@atlaskit/icon/core/migration/information--info';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { colors } from '@atlaskit/theme';
var flagMapping = {
  success: {
    component: SuccessIcon,
    label: 'success',
    color: "var(--ds-icon-success, ".concat(colors.G300, ")")
  },
  error: {
    component: ErrorIcon,
    label: 'error',
    color: "var(--ds-icon-danger, ".concat(colors.R300, ")")
  },
  info: {
    component: InfoIcon,
    label: 'info',
    color: "var(--ds-icon-information, ".concat(colors.T300, ")")
  },
  warning: {
    component: WarningIcon,
    label: 'warning',
    color: "var(--ds-icon-warning, ".concat(colors.Y300, ")")
  },
  smallInfo: {
    component: EditorInfoIcon,
    label: 'info',
    color: "var(--ds-icon-discovery, ".concat(colors.P300, ")")
  },
  comment: {
    component: CommentIcon,
    label: 'comment',
    color: "var(--ds-icon, ".concat(colors.N300, ")")
  },
  download: {
    component: DownloadIcon,
    label: 'download',
    color: "var(--ds-icon, ".concat(colors.N300, ")")
  },
  feedback: {
    component: FeedbackIcon,
    label: 'comment',
    color: "var(--ds-icon, ".concat(colors.N300, ")")
  }
};
export default flagMapping;