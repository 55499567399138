/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { all, takeLatest } from 'redux-saga/effects';

import {
  ENVIRONMENT_DASHBOARD_UPDATED,
  ENVIRONMENT_LOCK_UPDATED,
  ENVIRONMENT_UPDATED,
  REQUEST_CREATE_BRANCH_RESTRICTION,
  REQUEST_CREATE_ENVIRONMENT,
  REQUEST_DELETE_BRANCH_RESTRICTION,
  REQUEST_DELETE_ENVIRONMENT,
  REQUEST_DEPLOYMENT_CHANGES,
  REQUEST_DEPLOYMENT_COMMITS,
  REQUEST_DEPLOYMENT_DASHBOARD,
  REQUEST_DEPLOYMENT_SUMMARY,
  REQUEST_ENVIRONMENT_HISTORY,
  REQUEST_REDEPLOYMENT,
  REQUEST_REORDER_ENVIRONMENTS,
  REQUEST_UPDATE_ENVIRONMENT,
} from '../actions/deployments';

import {
  requestBranchRestrictionsSaga,
  requestCreateBranchRestrictionSaga,
  requestCreateEnvironmentSaga,
  requestDeleteBranchRestrictionSaga,
  requestDeleteEnvironmentSaga,
  requestDeploymentChangesWithRaceSaga,
  requestDeploymentCommitsWithRaceSaga,
  requestDeploymentDashboardSaga,
  requestDeploymentPullRequestsWithRaceSaga,
  requestDeploymentSummaryWithRaceSaga,
  requestEnvironmentHistorySaga,
  requestRedeploymentWithRaceSaga,
  requestReorderEnvironmentsSaga,
  requestUpdateEnvironmentSaga,
} from './deployments';

export default function* () {
  yield all([
    takeLatest(
      REQUEST_DEPLOYMENT_CHANGES.REQUEST,
      requestDeploymentChangesWithRaceSaga
    ),
    takeLatest(
      REQUEST_DEPLOYMENT_CHANGES.SUCCESS,
      requestDeploymentPullRequestsWithRaceSaga
    ),
    takeLatest(
      REQUEST_DEPLOYMENT_COMMITS.REQUEST,
      requestDeploymentCommitsWithRaceSaga
    ),
    takeLatest(
      REQUEST_DEPLOYMENT_DASHBOARD.REQUEST,
      requestDeploymentDashboardSaga
    ),
    takeLatest(
      REQUEST_DEPLOYMENT_DASHBOARD.SUCCESS,
      requestBranchRestrictionsSaga
    ),
    takeLatest(
      REQUEST_DEPLOYMENT_SUMMARY.REQUEST,
      requestDeploymentSummaryWithRaceSaga
    ),
    takeLatest(REQUEST_REDEPLOYMENT.REQUEST, requestRedeploymentWithRaceSaga),
    takeLatest(
      REQUEST_ENVIRONMENT_HISTORY.REQUEST,
      requestEnvironmentHistorySaga
    ),
    takeLatest(
      REQUEST_CREATE_ENVIRONMENT.REQUEST,
      requestCreateEnvironmentSaga
    ),
    takeLatest(
      REQUEST_CREATE_ENVIRONMENT.ERROR,
      requestDeploymentDashboardSaga
    ),
    takeLatest(
      REQUEST_UPDATE_ENVIRONMENT.REQUEST,
      requestUpdateEnvironmentSaga
    ),
    takeLatest(
      REQUEST_UPDATE_ENVIRONMENT.ERROR,
      requestDeploymentDashboardSaga
    ),
    takeLatest(
      REQUEST_DELETE_ENVIRONMENT.REQUEST,
      requestDeleteEnvironmentSaga
    ),
    takeLatest(
      REQUEST_DELETE_ENVIRONMENT.ERROR,
      requestDeploymentDashboardSaga
    ),
    takeLatest(
      REQUEST_REORDER_ENVIRONMENTS.REQUEST,
      requestReorderEnvironmentsSaga
    ),
    takeLatest(
      REQUEST_REORDER_ENVIRONMENTS.ERROR,
      requestDeploymentDashboardSaga
    ),
    takeLatest(ENVIRONMENT_UPDATED, requestDeploymentDashboardSaga),
    takeLatest(ENVIRONMENT_LOCK_UPDATED, requestDeploymentDashboardSaga),
    takeLatest(ENVIRONMENT_DASHBOARD_UPDATED, requestDeploymentDashboardSaga),
    takeLatest(
      REQUEST_CREATE_BRANCH_RESTRICTION.REQUEST,
      requestCreateBranchRestrictionSaga
    ),
    takeLatest(
      REQUEST_DELETE_BRANCH_RESTRICTION.REQUEST,
      requestDeleteBranchRestrictionSaga
    ),
  ]);
}
