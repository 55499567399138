import { unescape } from 'lodash-es';
/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { select, call, put } from 'redux-saga/effects';

import { DiffType } from 'src/components/types/src/pull-request';
import { showFlagComponent } from 'src/redux/flags';
import { EXPAND_CONTEXT } from 'src/redux/pull-request/actions';
import { ExpandContextAction } from 'src/redux/pull-request/actions/expand-context';
import { getDiffPath } from 'src/redux/pull-request/utils/get-diff-path';
import { Diff } from 'src/types/pull-request';
import authRequest from 'src/utils/fetch';
import {
  ContextLineRanges,
  validateContextLineRanges,
} from 'src/utils/validate-context-lines';

import {
  getCurrentPullRequestUrlPieces,
  getAllDiffFiles,
  getPullRequestDestinationHash,
  getPullRequestSourceHash,
  getCurrentPullRequestDiffType,
} from '../selectors';
import urls from '../urls';

import {
  convertToChunksFormat,
  ContextLine,
} from './utils/convert-to-chunks-format';
import {
  getContextData,
  getContextDataTopicDiff,
  parseSrcApiLines,
} from './utils/get-context-data';

export type ContextDatum = {
  beforeOrAfter: string;
  chunkId: string;
  lineNums: ContextLineRanges;
};

// See https://softwareteams.atlassian.net/browse/NPR-539
// The v1.0 context API returns escaped strings. We are unescaping the content
// before it is passed to the Diff -> CodeLine components which automatically HTML escaped
// during rendering.
//
// FIXME: This will have to be removed once we migrate to the v2.0 context API
// see: https://softwareteams.atlassian.net/browse/NPR-557
export function decodeContextLines(contextLines: ContextLine[]): ContextLine[] {
  return contextLines.map(contextLine => ({
    ...contextLine,
    content: unescape(contextLine.content),
  }));
}

// @ts-ignore TODO: fix noImplicitAny error here
function getJson(response) {
  return response.status === 200
    ? response.json()
    : Promise.resolve({ lines: [] });
}

export function* expandContextSaga({ payload }: ExpandContextAction) {
  const {
    filepath,
    prevFilepath,
    expanderIndex,
    shouldExpandDown,
    selectedDiffSourceHash,
    selectedDiffDestHash,
  } = payload;
  const diffs: Diff[] = (yield select(getAllDiffFiles)) as Diff[];
  const indexFromFullDiff = diffs.findIndex(
    diff => getDiffPath(diff) === filepath
  );
  const file = diffs[indexFromFullDiff];
  const { chunks } = file;
  const { owner, slug: repoSlug } = yield select(
    getCurrentPullRequestUrlPieces
  );

  const destinationHash: string = yield select(getPullRequestDestinationHash);
  const sourceHash: string = yield select(getPullRequestSourceHash);

  const topicDiffType: DiffType = yield select(getCurrentPullRequestDiffType);
  const topicDiff = topicDiffType === DiffType.TOPIC_DIFF;

  const contextData = topicDiff
    ? getContextDataTopicDiff(chunks, expanderIndex, shouldExpandDown)
    : getContextData(chunks, expanderIndex, shouldExpandDown);

  const buildContextUrl = (contextDatum: ContextDatum) =>
    topicDiff
      ? urls.api.internal.contextFromSrcTopicDiff(
          {
            // lookup the source hash in the destination repo
            // to avoid issues with repo permissions
            owner,
            slug: repoSlug,
            sourceHash: selectedDiffSourceHash || sourceHash,
            filepath,
          },
          contextDatum.lineNums as ContextLineRanges
        )
      : urls.api.internal.contextFromSrcPreviewMerge(
          {
            owner,
            slug: repoSlug,
            destinationHash: selectedDiffDestHash || destinationHash,
            filepath: prevFilepath || filepath,
          },
          contextDatum.lineNums as ContextLineRanges
        );

  // @ts-ignore TODO: fix noImplicitAny error here
  function putSuccess(json) {
    const lines = (json.data && parseSrcApiLines(json.data)) || [];

    const { chunkId, beforeOrAfter } = contextData;
    const lineNums: ContextLineRanges =
      contextData.lineNums as ContextLineRanges;
    const convertedJson = convertToChunksFormat(lines, lineNums);

    convertedJson.contextLines = decodeContextLines(
      convertedJson.contextLines
    ) as ContextLine[];

    return put({
      type: EXPAND_CONTEXT.SUCCESS,
      payload: {
        ...convertedJson,
        fileIndex: indexFromFullDiff,
        chunkId,
        beforeOrAfter,
      },
    });
  }

  try {
    try {
      validateContextLineRanges(contextData.lineNums);
    } catch (e) {
      // Only logging these in Sentry for now
    }

    const url = buildContextUrl(contextData);
    const response: Response = yield call(fetch, authRequest(url));

    if (response.ok) {
      // @ts-ignore
      const jsonObjects = yield getJson(response);

      yield putSuccess(jsonObjects);
    } else if (response.status === 403 || response.status === 404) {
      yield put(showFlagComponent('load-context-forbidden-not-found'));
      yield put({
        type: EXPAND_CONTEXT.ERROR,
        payload: {
          fileIndex: indexFromFullDiff,
        },
        error: true,
      });
    } else {
      yield put(showFlagComponent('load-context-error'));
      yield put({
        type: EXPAND_CONTEXT.ERROR,
        payload: {
          fileIndex: indexFromFullDiff,
        },
        error: true,
      });
    }
  } catch (error) {
    yield put(showFlagComponent('load-context-error'));
    yield put({
      type: EXPAND_CONTEXT.ERROR,
      payload: {
        message: error.message,
        fileIndex: indexFromFullDiff,
      },
      error: true,
    });
  }
}
