import { UserProfileClient, ProfileClient } from '@atlaskit/profilecard';

class DeletedUserClient extends UserProfileClient {
  // @ts-ignore (TS thinks that @atlaskit/profilecard defines this as an instance member property vs. an instance member function)
  makeRequest(): Promise<ProfileCardClientData> {
    return Promise.resolve({
      isBot: false,
      isCurrentUser: false,
      isNotMentionable: true,
      status: 'closed',
    });
  }
}

const deletedUserProfileConfig = {
  cacheMaxAge: Number.MAX_SAFE_INTEGER,
  url: '',
};

export const deletedUserProfileClient = new ProfileClient(
  deletedUserProfileConfig,
  {
    userClient: new DeletedUserClient(deletedUserProfileConfig),
  }
);
