import { Repository } from 'src/components/types';
import { BuildStatusesMap } from 'src/types';

export type RepositoryBuildStatus = {
  links: {
    builds: {
      href: string;
    };
  };
  repository: Repository;
  status_counts?: BuildStatusesMap;
  type: 'computed_build';
};

export const addStatuses = (
  repositories: Repository[],
  statuses: RepositoryBuildStatus[]
): Repository[] => {
  return repositories.map(repository => {
    const buildStatus = statuses.find(
      s => s.repository.uuid === repository.uuid
    );

    return {
      ...repository,
      status_counts: buildStatus && buildStatus.status_counts,
      statusUrl: buildStatus && buildStatus.links.builds.href,
    };
  });
};
