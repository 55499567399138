import { ComponentType, ReactNode } from 'react';

import { ConnectIframe } from '@atlassian/connect-module-core';
import { ConnectIframeDefinitions } from '@atlassian/connect-module-core/dist/es5/modules/app/ConnectIframe';

export type IframeContainerProps = {
  children: ReactNode;
  width: string | number | undefined;
  height: string | number | undefined;
};
export type IframeContainer = ComponentType<IframeContainerProps>;

export const iframeEmptyContainer = (
  ConnectIframe.defaultProps as ConnectIframeDefinitions.Props
).iframeContainer;

export function applyUrlFragment(
  url: string,
  moduleType: string,
  passLocation?: boolean
) {
  if (!passLocation || moduleType !== 'repoPages' || !window.location.hash) {
    return url;
  }
  return `${url}${window.location.hash}`;
}

/* TODO: Remove once the ACJS upgrade is complete */

export const SSR_STATE_KEY = '__ssr_state__';
export const getSsrState = () => window[SSR_STATE_KEY] || {};
const APP_DID_SSR = Object.keys(getSsrState()).length > 0;
export const appWasServerSideRendered = () => APP_DID_SSR;

export const getInitialOrBucketState = (): any =>
  appWasServerSideRendered()
    ? getSsrState().reduxStoreState
    : // eslint-disable-next-line no-restricted-properties
      window.__initial_state__;
