import {DropdownProvider} from './DropdownProvider';
import {SimpleXdmDefinition} from '../../definitions/SimpleXdmDefinition';
import {SimpleXdmDefinitionBuilder} from '../../definitions/SimpleXdmDefinitionBuilder';
import {Dropdown} from './Dropdown';

export class DropdownSimpleXdmDefinitionBuilder implements SimpleXdmDefinitionBuilder {

  dropdownProvider: DropdownProvider;

  constructor(dropdownProvider: DropdownProvider) {
      if (!dropdownProvider) {
          throw new Error('dropdownProvider is not defined');
      }
      this.dropdownProvider = dropdownProvider;
  }

  buildSimpleXdmDefinition(): SimpleXdmDefinition {

    return {
      // This is not implemented yet. The implication of this is that dropdown lists must use the
      // module definition defined in the atlassian-connect-js repo which switches between the AUI
      // implementation and module provider implementation.
    };
  }

}
