export enum StepGroupType {
  SINGLE,
  PARALLEL,
  STAGE,
}

export class StepGroup {
  readonly key: string = '';
  readonly steps: string[] = [];
  readonly type: StepGroupType = StepGroupType.SINGLE;

  constructor(props: Partial<StepGroup> = {}) {
    Object.assign(this, props);
    Object.freeze(this);
  }
}
