import { Reason } from './Reason';

export class StoppedReason extends Reason {
  constructor(props: Partial<StoppedReason> = {}) {
    super(props);
    Object.assign(this, { ...props });
    Object.freeze(this);
  }

  get stoppedReasonText(): string | null {
    if (this.isFailFastStoppedReason) {
      return 'Stopped when a parallel step failed (fail-fast is enabled)';
    }
    return null;
  }
}
