// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { borderRadius, fontSize, gridSize } from '@atlaskit/theme/constants';
var gridSizeValue = gridSize();
var borderRadiusValue = borderRadius();
var fontSizeValue = fontSize();
export var tagHeightUnitless = 2.5 * gridSizeValue;
export var tagHeight = "var(--ds-space-250, ".concat("".concat(tagHeightUnitless, "px"), ")");
export var buttonWidthUnitless = tagHeightUnitless; // button should be square
export var buttonWidth = tagHeight; // button should be square
export var maxWidthUnitless = 25 * gridSizeValue;
export var maxWidth = "".concat(maxWidthUnitless, "px");
export var maxTextWidthUnitless = maxWidthUnitless - tagHeightUnitless;
export var maxTextWidth = "".concat(maxTextWidthUnitless, "px");
export var defaultBorderRadius = "".concat(borderRadiusValue, "px");
export var defaultRoundedBorderRadius = "".concat(buttonWidthUnitless / 2, "px");
export var defaultMargin = "var(--ds-space-050, ".concat("".concat(gridSizeValue / 2, "px"), ")");
export var defaultTextPadding = "var(--ds-space-050, ".concat("".concat(gridSizeValue / 2, "px"), ")");
export var textPaddingRight = "var(--ds-space-200, ".concat("".concat(2 * gridSizeValue, "px"), ")");
export var textMarginLeft = tagHeight;
export var textFontSize = "".concat(fontSizeValue, "px");
export var cssVar = {
  color: {
    background: {
      default: '--ds-cb',
      hover: '--ds-cbh',
      active: '--ds-cba'
    },
    focusRing: '--ds-cfr',
    text: {
      default: '--ds-ct',
      hover: '--ds-cth',
      active: '--ds-ctp',
      link: '--ds-ctl'
    },
    removeButton: {
      default: '--ds-rb',
      hover: '--ds-rbh'
    }
  },
  borderRadius: '--ds-br'
};