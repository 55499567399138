import {
  MenuItem,
  createNestedMenu,
  addMenuItemsById,
  ROOT_MENU_ID,
} from '@atlassian/bitbucket-navigation';

import { OverrideSettings } from 'src/types/override-settings';
import createReducer from 'src/utils/create-reducer';

import {
  FETCH_FORGE_REPO_MENU_ITEMS,
  LoadRepositoryPage,
  TOGGLE_CLONE_DIALOG,
  TOGGLE_SYNC_DIALOG,
  UNLOAD_REPOSITORY,
  UPDATE_MENU_ITEMS,
  UPDATE_REPOSITORY_SECTION_MENU_ITEMS,
  UPDATE_REPOSITORY_SECTION_OVERRIDE_SETTINGS,
} from '../actions';
import { Mirror } from '../types';

export type RepositorySizeLimits = {
  hard: number;
  soft: number;
  readOnly: number;
};

export type RepositorySectionState = {
  currentRepository: string | null | undefined;
  isCloneDialogOpen: boolean;
  isSyncDialogOpen: boolean;
  menuItems: MenuItem[];
  bitbucketActions: MenuItem[];
  connectActions: MenuItem[];
  mirrors: Mirror[] | null;
  sizeLimits: RepositorySizeLimits;
  overrideSettings: OverrideSettings;
};

const initialState: RepositorySectionState = {
  currentRepository: null,
  isCloneDialogOpen: false,
  isSyncDialogOpen: false,
  menuItems: [],
  bitbucketActions: [],
  connectActions: [],
  mirrors: null,
  sizeLimits: {
    soft: Infinity,
    hard: Infinity,
    readOnly: Infinity,
  },
  overrideSettings: {},
};

export default createReducer(initialState, {
  [LoadRepositoryPage.SUCCESS](state, action) {
    const {
      menuItems,
      sizeLimits,
      overrideSettings,
      override_settings,
      ...result
    } = action.payload.result;
    return {
      ...state,
      ...result,
      ...(menuItems && {
        menuItems: createNestedMenu(menuItems),
      }),
      sizeLimits: {
        soft: sizeLimits?.soft || Infinity,
        hard: sizeLimits?.hard || Infinity,
        readOnly: sizeLimits?.readOnly || Infinity,
      },
      // This should work if backend changes snake-casing to
      // camel-casing to follow current convention
      overrideSettings: overrideSettings || override_settings,
    };
  },

  [UNLOAD_REPOSITORY](state) {
    return { ...state, currentRepository: null, mirrors: null, menuItems: [] };
  },

  [UPDATE_MENU_ITEMS](state, action) {
    return { ...state, menuItems: createNestedMenu(action.payload) };
  },

  [UPDATE_REPOSITORY_SECTION_OVERRIDE_SETTINGS](state, action) {
    return {
      ...state,
      overrideSettings: { ...state.overrideSettings, ...action.payload },
    };
  },

  [UPDATE_REPOSITORY_SECTION_MENU_ITEMS](state, action) {
    return { ...state, menuItems: createNestedMenu(action.payload) };
  },

  [TOGGLE_CLONE_DIALOG](state, action) {
    return {
      ...state,
      isCloneDialogOpen: action.payload,
    };
  },

  [TOGGLE_SYNC_DIALOG](state, action) {
    if (action.payload === state.isSyncDialogOpen) {
      return state;
    }

    return {
      ...state,
      isSyncDialogOpen: action.payload,
    };
  },

  [FETCH_FORGE_REPO_MENU_ITEMS.SUCCESS](state, action) {
    let { menuItems } = state;
    const {
      menu_items: forgeMenuItems,
      admin_menu_items: forgeAdminMenuItems,
    } = action.payload;

    if (forgeMenuItems && forgeMenuItems.length > 0) {
      menuItems = addMenuItemsById(menuItems, ROOT_MENU_ID, forgeMenuItems);
    }
    if (forgeAdminMenuItems && forgeAdminMenuItems.length > 0) {
      menuItems = addMenuItemsById(
        menuItems,
        'repo-settings-link',
        forgeAdminMenuItems
      );
    }
    return {
      ...state,
      menuItems: createNestedMenu(menuItems),
    };
  },

  [FETCH_FORGE_REPO_MENU_ITEMS.ERROR](state) {
    // Swallow error since showing an error to customers will be too noisy
    return state;
  },
});
