import { Diff } from 'src/types/pull-request';

export function getExcessiveDiffSize(file: Diff) {
  for (let i = 0; i < file.headers.length; ++i) {
    const excludedHeader = file.headers[i].match(
      /^The diff for this file is too large to render \((\d+) bytes\)$/
    );
    if (excludedHeader) {
      return Number(excludedHeader[1]);
    }
  }

  return null;
}
