export enum BBPrivilegeOptions {
  none = 'none',
  read = 'read',
  write = 'write',
  'create-repo' = 'create-repo',
  'create-project' = 'create-project',
  admin = 'admin',
}

export enum BBPrivilege {
  read = 'read',
  write = 'write',
  'create-repo' = 'create-repo',
  'create-project' = 'create-project',
  admin = 'admin',
}

export enum BBAccountPrivilege {
  admin = 'admin',
  'create-project' = 'create-project',
  collaborator = 'collaborator',
  member = 'member',
}

declare global {
  namespace BB {
    type Privilege = BBPrivilege;
    type PrivilegeOptions = BBPrivilegeOptions;
    type AccountPrivilege = BBAccountPrivilege;
  }
}

export { BBPrivilege as Privilege };
export { BBPrivilegeOptions as PrivilegeOptions };
export { BBAccountPrivilege as AccountPrivilege };
