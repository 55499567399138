import createReducer from 'src/utils/create-reducer';

import { FETCH_WORKSPACE_PLAN } from '../actions';

export type WorkspacePlanState = {
  isWorkspacePremium: boolean;
  isWorkspacePaid: boolean;
  isFetchWorkspacePlanLoading: boolean;
  isFetchWorkspacePlanError: boolean;
};

export const initialState: WorkspacePlanState = {
  isWorkspacePremium: false,
  isWorkspacePaid: false,
  isFetchWorkspacePlanLoading: true,
  isFetchWorkspacePlanError: false,
};

export default createReducer(initialState, {
  [FETCH_WORKSPACE_PLAN.REQUEST](state) {
    return {
      ...state,
      isWorkspacePremium: false,
      isWorkspacePaid: false,
      isFetchWorkspacePlanLoading: true,
      isFetchWorkspacePlanError: false,
    };
  },
  [FETCH_WORKSPACE_PLAN.SUCCESS](state, action) {
    return {
      ...state,
      ...action.payload,
      isWorkspacePremium: action.payload.isWorkspacePremium,
      isWorkspacePaid: action.payload.isWorkspacePaid,
      isFetchWorkspacePlanLoading: false,
      isFetchWorkspacePlanError: false,
    };
  },
  [FETCH_WORKSPACE_PLAN.ERROR](state) {
    return {
      ...state,
      isWorkspacePremium: false,
      isWorkspacePaid: false,
      isFetchWorkspacePlanLoading: false,
      isFetchWorkspacePlanError: true,
    };
  },
});
