/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import {
  call,
  put,
  select,
  take,
  delay,
  race,
  spawn,
} from 'redux-saga/effects';

import { ApiComment } from 'src/components/conversation-provider/types';
import { Commit, PullRequest } from 'src/components/types';
import { loadBranchingModel, getBranchingModel } from 'src/redux/branches';
import {
  EXITED_CODE_REVIEW,
  CLEAR_IN_PROGRESS_MERGE_TASK,
} from 'src/redux/pull-request/actions';
import {
  FETCH_PULL_REQUEST_MERGE_STATUS,
  MERGE,
  setAsyncMergeInProgress,
  fetchPullRequestMergeStatus,
} from 'src/redux/pull-request/merge-reducer';
import { fetchCommitsStatusesSaga } from 'src/redux/pull-request/sagas/fetch-commit-statuses';
import {
  getCurrentPullRequest,
  getCurrentPullRequestRoleForCurrentUser,
  getCurrentPullRequestUrlPieces,
  getDestinationBranchName,
  getInlineComments,
  getInProgressMergeTask,
} from 'src/redux/pull-request/selectors';
import urls from 'src/redux/pull-request/urls';
import { prefetchCommits } from 'src/redux/repo-commits/sagas';
import { getCurrentRepoCommits } from 'src/redux/repo-commits/selectors';
import { BranchingModelBranch } from 'src/sections/repository/sections/branches/types';
import { getCurrentRepositoryUuid } from 'src/selectors/repository-selectors';
import { getWorkspacePlan } from 'src/selectors/workspace-selectors';
import authRequest, { jsonHeaders } from 'src/utils/fetch';

import { updatePullRequest } from '../actions/update-pull-request';

import { getErrorMessage } from './utils/get-error-message';
import { publishPullRequestEvent } from './utils/pull-request-event-analytics';

export const PULL_REQUEST_MERGE_STATUS_DELAY = 3000;

export enum MERGE_TASK_STATUSES {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}

export function* pollMergedCommitStatus() {
  const { owner, slug, id } = yield select(getCurrentPullRequestUrlPieces);

  const currentPullRequest: PullRequest = yield select(getCurrentPullRequest);
  const { hash: merge_commit_hash } = currentPullRequest.merge_commit;

  // After merging, this should fetch the *merge commit* itself.
  yield call(prefetchCommits, { owner, slug, id });
  const commits: Commit[] = yield select(getCurrentRepoCommits);
  // The merge commit should then be the first commit in the "current pull request commits".
  if (
    commits &&
    commits.length !== 0 &&
    commits[0].hash === merge_commit_hash
  ) {
    // Fetch the statuses multiple times (waiting some time between fetches).
    // We don't really know if we can stop, because there might be more than 1 build.
    // Build statuses can also be posted from external places that poll the repository, so we
    // can't rely on it being fast.
    // Why do we do this? Because we want to show the build status in the merge success message
    // so that the user can click through to the build straight after merging (instead of having
    // to go via commit or look at all the builds and find theirs).
    for (const delaySeconds of [2, 4, 8, 16]) {
      // Delay before first fetch because `prefetchCommits` above causes a status fetch already.
      yield delay(delaySeconds * 1000);
      yield call(fetchCommitsStatusesSaga);
    }
  }
}
function* updatePullRequestStatus(json: PullRequest) {
  const { owner, slug: repoSlug } = yield select(
    getCurrentPullRequestUrlPieces
  );

  const currentPullRequest: PullRequest = yield select(getCurrentPullRequest);
  const updatedPullRequest = {
    ...currentPullRequest,
    state: json.state,
    updated_on: json.updated_on,
    closed_by: json.closed_by,
    closed_on: json.closed_on,
    merge_commit: json.merge_commit,
  } as PullRequest;

  yield put(
    updatePullRequest({
      type: MERGE.SUCCESS,
      pullRequest: updatedPullRequest,
    })
  );

  // publish analytics event
  const { isWorkspacePaid } = yield select(getWorkspacePlan);
  const repoUuid: string = yield select(getCurrentRepositoryUuid);
  const comments: ApiComment[] = yield select(getInlineComments);
  const role: string | null = yield select(
    getCurrentPullRequestRoleForCurrentUser
  );
  publishPullRequestEvent(
    'MERGED',
    updatedPullRequest,
    comments,
    isWorkspacePaid,
    repoUuid,
    role
  );

  yield spawn(pollMergedCommitStatus);

  const destinationBranchName: string = yield select(getDestinationBranchName);
  const { development, production } = yield select(getBranchingModel);

  const checkBranchName = (branch: BranchingModelBranch | null) => {
    return !!branch && branch.name === destinationBranchName;
  };

  // If the destination branch is the "development" or "production" branch
  // we need to refetch the branching model in order to update the hashes to
  // the latest commit.
  if (checkBranchName(development) || checkBranchName(production)) {
    yield put(
      loadBranchingModel({
        repositoryOwner: owner,
        repositorySlug: repoSlug,
      })
    );
  }
}

export function* pollPullRequestMergeStatus(pollingLink: string) {
  while (true) {
    yield delay(PULL_REQUEST_MERGE_STATUS_DELAY);
    yield put(fetchPullRequestMergeStatus(pollingLink));
    const { success, error } = yield race({
      success: take(FETCH_PULL_REQUEST_MERGE_STATUS.SUCCESS),
      error: take(FETCH_PULL_REQUEST_MERGE_STATUS.ERROR),
    });

    if (error) {
      throw Error(error.payload);
    }
    const { payload: json } = success;
    if (json.task_status === MERGE_TASK_STATUSES.SUCCESS) {
      const { merge_result: pullRequest } = json;
      return pullRequest;
    }
  }
}

export function* startMergePoll(pollingLink: string) {
  yield put(setAsyncMergeInProgress(true));

  try {
    // @ts-ignore
    return yield race({
      json: call(pollPullRequestMergeStatus, pollingLink),
      exitedCodeReview: take(EXITED_CODE_REVIEW),
    });
  } finally {
    // clear banner on initial merge request
    yield put(setAsyncMergeInProgress(false));

    // clear banner on in-progress merge
    yield put({
      type: CLEAR_IN_PROGRESS_MERGE_TASK,
    });
  }
}

// poll merge task status on page refresh for in-progress merges
export function* pollMergeTaskStatusSaga() {
  // @ts-ignore
  const mergeInProgress = yield select(getInProgressMergeTask);

  if (!mergeInProgress) {
    return;
  }

  try {
    const { json } = yield call(startMergePoll, mergeInProgress);

    if (json) {
      yield call(updatePullRequestStatus, json);
    }
  } catch (e) {
    yield put({
      type: MERGE.ERROR,
      payload: e.message,
      error: true,
    });
  }
}

// poll merge task status for new merge requests
export function* mergePullRequestSaga() {
  while (true) {
    const { payload: mergeInfo } = yield take(MERGE.REQUEST);

    const {
      owner,
      slug: repoSlug,
      id: pullRequestId,
    } = yield select(getCurrentPullRequestUrlPieces);
    const endpoint_parameters = { owner, repoSlug, pullRequestId };

    const endpoint = urls.api.v20.merge_async(endpoint_parameters);

    try {
      const authWrapped = authRequest(endpoint, {
        method: 'POST',
        headers: jsonHeaders,
        body: JSON.stringify({
          close_source_branch: mergeInfo.closeSourceBranch,
          message: mergeInfo.message,
          merge_strategy: mergeInfo.mergeStrategy,
        }),
      });

      const response: Response = yield call(fetch, authWrapped);

      if (!response.ok) {
        const message: string = yield call(getErrorMessage, response.clone());

        throw Error(message);
      }

      const pullRequestMergeStatusPollingLink =
        response.headers.get('location');
      if (!pullRequestMergeStatusPollingLink) {
        return { json: null };
      }

      const { json } = yield call(
        startMergePoll,
        pullRequestMergeStatusPollingLink
      );

      // If json is falsey it means that the user has exited the code review page
      // or there is an error occured while the async merge polling was happening
      if (json) {
        yield call(updatePullRequestStatus, json);
      }
    } catch (e) {
      yield put({
        type: MERGE.ERROR,
        payload: e.message,
        error: true,
      });
    }
  }
}
