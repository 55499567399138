/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import * as Sentry from '@sentry/browser';
import { call, put, select, all } from 'redux-saga/effects';

import { User } from 'src/components/types';
import { AvailableSite } from 'src/redux/jira/types';
import { getCurrentUser } from 'src/selectors/user-selectors';
import { Action } from 'src/types/state';
import urls from 'src/urls/jira';
import authRequest from 'src/utils/fetch';
import prefs from 'src/utils/preferences';
import { captureMessageForResponse } from 'src/utils/sentry';

import {
  INIT_DASHBOARD_JIRA,
  FETCH_DASHBOARD_JIRA_ISSUES,
  fetchDashboardJiraIssues,
  setDashboardJiraDefaultCloudId,
  fetchDashboardDevActivity,
  FETCH_DASHBOARD_DEV_ACTIVITY,
} from '../actions';

import { fetchAvailableJiraSitesSaga } from './fetch-available-sites-saga';

const SITE_PREFERENCE_KEY = 'dashboard:jira-issues:site';

export function* fetchDefaultSiteSaga() {
  const user: User = yield select(getCurrentUser);
  if (user) {
    try {
      // @ts-ignore
      const selectedSiteCloudId = yield call(
        prefs.get,
        user.uuid,
        SITE_PREFERENCE_KEY
      );

      yield put(setDashboardJiraDefaultCloudId(selectedSiteCloudId));

      return selectedSiteCloudId;
    } catch (e) {
      Sentry.captureException(e);
      return undefined;
    }
  } else {
    return undefined;
  }
}

export function* saveDefaultSiteSaga(cloudId: string) {
  const user: User = yield select(getCurrentUser);
  if (user) {
    try {
      if (cloudId === '') {
        yield call(prefs.delete, user.uuid, SITE_PREFERENCE_KEY);
      } else {
        yield call(prefs.set, user.uuid, SITE_PREFERENCE_KEY, cloudId);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }
}

export function* initDashboardJira() {
  try {
    const [availableSites, defaultSiteCloudId]: [AvailableSite[], string] =
      yield all([
        call(fetchAvailableJiraSitesSaga),
        call(fetchDefaultSiteSaga),
      ]);

    if (availableSites === undefined) {
      yield put({
        type: INIT_DASHBOARD_JIRA.ERROR,
      });
      return;
    }

    if (availableSites.length === 0) {
      if (defaultSiteCloudId) {
        // Clear the preference
        yield call(saveDefaultSiteSaga, '');
      }

      yield put({
        type: INIT_DASHBOARD_JIRA.SUCCESS,
        payload: {
          selectedSite: undefined,
          availableSites: [],
        },
      });
      return;
    }

    const selectedSite =
      availableSites.find(site => site.cloudId === defaultSiteCloudId) ||
      availableSites[0];

    yield put({
      type: INIT_DASHBOARD_JIRA.SUCCESS,
      payload: {
        selectedSite,
        availableSites,
      },
    });

    yield put(fetchDashboardJiraIssues(selectedSite));

    if (!defaultSiteCloudId) {
      // If we haven't saved the selected site as a preference yet, save it now so that in case the sites change, we
      // don't suddenly pick a different one for the user. By saving it, it will just stay the same as the initial
      // selection.
      yield call(saveDefaultSiteSaga, selectedSite.cloudId);
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: INIT_DASHBOARD_JIRA.ERROR,
    });
  }
}

export function* onSelectedSiteChangeSaga({ payload }: Action<AvailableSite>) {
  if (payload) {
    yield all([
      call(saveDefaultSiteSaga, payload.cloudId),
      put(fetchDashboardJiraIssues(payload)),
    ]);
  }
}

export function* fetchDashboardJiraIssuesSaga({
  payload,
}: Action<AvailableSite>) {
  if (!payload) {
    return;
  }

  try {
    const url = urls.api.internal.myIssues(payload.cloudId, 15);
    const request = authRequest(url);
    const response: Response = yield call(fetch, request);

    if (response.ok) {
      // @ts-ignore
      const data = yield response.json();
      yield put({
        type: FETCH_DASHBOARD_JIRA_ISSUES.SUCCESS,
        payload: {
          jiraIssues: data.values,
          jiraIssuesCount: data.size,
          jiraIssuesJql: data.links.jira.jql,
        },
      });
      yield put(
        fetchDashboardDevActivity({
          cloudId: payload.cloudId,
          // @ts-ignore TODO: fix noImplicitAny error here
          issueIds: data.values.map(val => val.id),
        })
      );
    } else {
      yield captureMessageForResponse(
        response,
        'Fetching dashboard Jira issues failed'
      );
      yield put({
        type: FETCH_DASHBOARD_JIRA_ISSUES.ERROR,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_DASHBOARD_JIRA_ISSUES.ERROR,
    });
  }
}

export function* fetchDashboardDevActivitySaga({
  payload,
}: Action<{ issueIds: string[]; cloudId: string }>) {
  if (!payload) {
    return;
  }

  const { cloudId, issueIds } = payload;

  try {
    const url = urls.api.internal.dashboardDevActivity(cloudId);
    const request = authRequest(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ issueIds }),
    });
    const response: Response = yield call(fetch, request);

    if (response.ok) {
      // @ts-ignore
      const data = yield response.json();
      yield put({
        type: FETCH_DASHBOARD_DEV_ACTIVITY.SUCCESS,
        payload: data ? { [cloudId]: data.activity } : {},
      });
    } else {
      yield captureMessageForResponse(
        response,
        'Fetching dashboard Jira issues dev activity information failed'
      );
      yield put({
        type: FETCH_DASHBOARD_DEV_ACTIVITY.ERROR,
      });
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: FETCH_DASHBOARD_DEV_ACTIVITY.ERROR,
    });
  }
}
