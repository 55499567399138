import fetchAccessToken from 'src/utils/fetch-access-token';
import { BitbucketApiClientOptions } from 'src/utils/get-token-details';

export default async function refreshToken(options: BitbucketApiClientOptions) {
  let newOptions = { ...options };
  try {
    const accessToken = await fetchAccessToken('site:JSAPITOKEN');
    newOptions.token = accessToken.token;
    newOptions.expiration = Date.now() + accessToken.expiresInSeconds * 1000;
  } catch {
    newOptions = {
      token: null,
      expiration: null,
    };
  }
  return newOptions;
}
