import { MenuItem, mapNestedMenuItems } from '@atlassian/bitbucket-navigation';

import { Dispatch, BucketState } from 'src/types/state';

import { UPDATE_MENU_ITEMS } from './';

export default (oldRoute: string, newRoute: string) =>
  (dispatch: Dispatch, getState: () => BucketState) => {
    const state = getState();
    if (state.repository && state.repository.section) {
      let { menuItems } = state.repository.section;
      menuItems = mapNestedMenuItems(menuItems, item => {
        if (item.url === oldRoute) {
          return { ...item, url: newRoute };
        }
        return item;
      }) as MenuItem[];
      dispatch({
        type: UPDATE_MENU_ITEMS,
        payload: menuItems,
      });
    }
  };
