import _defineProperty from "@babel/runtime/helpers/defineProperty";
var _COMMERCIAL, _FEDRAMP_MODERATE, _domainMap, _COMMERCIAL9, _FEDRAMP_MODERATE2, _start, _COMMERCIAL14, _FEDRAMP_MODERATE3, _id;
import { COMMERCIAL, DEV, FEDRAMP_FEDEX, FEDRAMP_MODERATE, PRODUCTION, STAGING } from './index';
/**
 * Creates a mapping from perimeter to environment to domain, allowing simply substitutions
 * to minimize the amount of data we have to ship to the browser.
 */
export var domainMap = (_domainMap = {}, _defineProperty(_domainMap, COMMERCIAL, (_COMMERCIAL = {}, _defineProperty(_COMMERCIAL, STAGING, 'stg.atlassian.com'), _defineProperty(_COMMERCIAL, PRODUCTION, 'atlassian.com'), _COMMERCIAL)), _defineProperty(_domainMap, FEDRAMP_FEDEX, _defineProperty({}, STAGING, 'atlassian-fex.com')), _defineProperty(_domainMap, FEDRAMP_MODERATE, (_FEDRAMP_MODERATE = {}, _defineProperty(_FEDRAMP_MODERATE, STAGING, 'stg.atlassian-us-gov-mod.com'), _defineProperty(_FEDRAMP_MODERATE, PRODUCTION, 'atlassian-us-gov-mod.com'), _FEDRAMP_MODERATE)), _domainMap);

// Contains overrides for subdomains where the DomainKey is different from the subdomain
export var subdomainOverride = {};

/**
 * Contains overrides for domains where the pattern of subdomain only doesn't work.
 * Useful in situations where this is a global domain that won't vary per environment.
 * Priority is exact match > fallback to commercial production
 *
 * IF YOU ADD SOMETHING HERE YOU MUST EXPLICITLY ADD EVERY PERIMETER AND EVERY ENVIRONMENT
 * ANYTHING NOT SET EXPLICITLY WILL DEFEAULT TO COMMERCIAL/PRODUCTION
 */
export var fullDomainOverride = {
  analytics: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'analytics.atlassian.com')),
  // Marketing site
  confluence: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'confluence.atlassian.com')),
  // Atlassian design system documentation
  design: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'design.atlassian.com')),
  // Old staff intranet (now hello.atlassian.net)
  extranet: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'extranet.atlassian.com')),
  // Go links
  go: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'go.atlassian.com')),
  my: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'my.atlassian.com')),
  schema: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'schema.atlassian.com')),
  start: (_start = {}, _defineProperty(_start, COMMERCIAL, (_COMMERCIAL9 = {}, _defineProperty(_COMMERCIAL9, PRODUCTION, 'start.atlassian.com'), _defineProperty(_COMMERCIAL9, STAGING, 'start.stg.atlassian.com'), _COMMERCIAL9)), _defineProperty(_start, FEDRAMP_MODERATE, (_FEDRAMP_MODERATE2 = {}, _defineProperty(_FEDRAMP_MODERATE2, PRODUCTION, 'start.atlassian-us-gov-mod.com'), _defineProperty(_FEDRAMP_MODERATE2, STAGING, 'start.stg.atlassian-us-gov-mod.com'), _FEDRAMP_MODERATE2)), _start),
  surveys: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'surveys.atlassian.com')),
  'wac-cdn': _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'wac-cdn.atlassian.com')),
  integrations: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'integrations.atlassian.com')),
  permalink: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'permalink.atlassian.com')),
  id: (_id = {}, _defineProperty(_id, COMMERCIAL, (_COMMERCIAL14 = {}, _defineProperty(_COMMERCIAL14, PRODUCTION, 'id.atlassian.com'), _defineProperty(_COMMERCIAL14, STAGING, 'id.stg.internal.atlassian.com'), _defineProperty(_COMMERCIAL14, DEV, 'id.dev.internal.atlassian.com'), _COMMERCIAL14)), _defineProperty(_id, FEDRAMP_MODERATE, (_FEDRAMP_MODERATE3 = {}, _defineProperty(_FEDRAMP_MODERATE3, PRODUCTION, 'id.atlassian-us-gov-mod.com'), _defineProperty(_FEDRAMP_MODERATE3, STAGING, 'id.stg.atlassian-us-gov-mod.com'), _FEDRAMP_MODERATE3)), _id),
  support: _defineProperty({}, COMMERCIAL, _defineProperty({}, PRODUCTION, 'support.atlassian.com'))
};