import { Deployment, Environment } from 'src/components/pipelines/models';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import {
  REMOVE_DEPLOYMENT_DATA,
  REQUEST_REDEPLOYMENT,
} from '../actions/deployments';

export type RedeploymentState = {
  deployment: Deployment;
  lastSuccessfulDeployment: Deployment;
  environment: Environment;
};

export const initialState: RedeploymentState = {
  deployment: new Deployment(),
  lastSuccessfulDeployment: new Deployment(),
  environment: new Environment(),
};

export default createReducer(initialState, {
  [REMOVE_DEPLOYMENT_DATA]() {
    return initialState;
  },
  [REQUEST_REDEPLOYMENT.REQUEST]() {
    return initialState;
  },
  [REQUEST_REDEPLOYMENT.SUCCESS](
    state: RedeploymentState,
    action: Action<RedeploymentState>
  ) {
    return { ...state, ...action.payload };
  },
});
