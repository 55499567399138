import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

const testNameStyles = xcss({
  color: 'color.text.accent.gray',
  fontSize: '12px',
  lineHeight: '22px',
  paddingInlineEnd: 'space.500',
  marginBlockEnd: 'space.100',
  display: 'flex',
  alignItems: 'center',
  gap: 'space.075',
});

const childWrapperStyles = xcss({
  maxWidth: '200px',
  display: 'flex',
  alignItems: 'center',
  gap: 'space.025',
});

const textWrapperStyles = xcss({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
});

/* eslint @typescript-eslint/ban-types: "warn" */
export const TestName: React.FC = ({ children }) => (
  <Box xcss={testNameStyles}>{children}</Box>
);

/* eslint @typescript-eslint/ban-types: "warn" */
export const TestChildWrapper: React.FC = ({ children }) => (
  <Box xcss={childWrapperStyles}>{children}</Box>
);

/* eslint @typescript-eslint/ban-types: "warn" */
export const TestTextWrapper: React.FC = ({ children }) => (
  <Box xcss={textWrapperStyles}>{children}</Box>
);
