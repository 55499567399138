import React, { ReactNode, useMemo } from 'react';

import Spinner from '@atlaskit/spinner';

import {
  Account,
  Capabilities,
  Repository,
} from 'src/components/pipelines/models';

import { getSettingsPageURL } from '../utils/links';

import Message from './Message';
import { SettingsPageWrapperLoading } from './styled';

export interface Props {
  title: string;
  account: Account;
  capabilities: Capabilities;
  repository: Repository;
  children?: ReactNode;
}

/* eslint @typescript-eslint/ban-types: "warn" */
const SettingsPageWrapper: React.FC<Props> = ({
  title,
  capabilities,
  repository,
  account,
  children,
}) => {
  const shouldShowChildren = useMemo(() => {
    const isAccountLevel = !repository.uuid;
    const ready = capabilities.hasFetchedCapabilities;
    const isPipelinesEnabled = capabilities.pipelinesEnabled;
    return isAccountLevel || (ready && isPipelinesEnabled);
  }, [
    repository.uuid,
    capabilities.hasFetchedCapabilities,
    capabilities.pipelinesEnabled,
  ]);

  if (shouldShowChildren) {
    return <>{children}</>;
  } else if (!capabilities.hasFetchedCapabilities) {
    return (
      <SettingsPageWrapperLoading>
        <Spinner size="large" />
      </SettingsPageWrapperLoading>
    );
  } else if (!capabilities.pipelinesEnabled) {
    return (
      <Message type="warning">
        <div>
          Pipelines must be enabled on this repository before you can add{' '}
          {title}.
        </div>
        <a
          target="_top"
          href={getSettingsPageURL(repository.path, account.addonKey)}
        >
          Go to settings
        </a>
      </Message>
    );
  }
  return <div />;
};

export default React.memo(SettingsPageWrapper);
