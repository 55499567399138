/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
import * as colors from '@atlaskit/theme/colors';
import { defaultBorderRadius, defaultRoundedBorderRadius } from './constants';

// Text colors
export var textColors = {
  standard: {
    light: "var(--ds-text, ".concat(colors.N700, ")"),
    dark: "var(--ds-text, ".concat(colors.DN600, ")")
  },
  standardLink: {
    light: "var(--ds-link, ".concat(colors.B400, ")"),
    dark: "var(--ds-link, ".concat(colors.B400, ")")
  },
  blue: {
    light: "var(--ds-text-accent-blue-bolder, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-accent-blue-bolder, ".concat(colors.N800, ")")
  },
  red: {
    light: "var(--ds-text-accent-red-bolder, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-accent-red-bolder, ".concat(colors.N800, ")")
  },
  yellow: {
    light: "var(--ds-text-accent-yellow-bolder, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-accent-yellow-bolder, ".concat(colors.N800, ")")
  },
  green: {
    light: "var(--ds-text-accent-green-bolder, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-accent-green-bolder, ".concat(colors.N800, ")")
  },
  teal: {
    light: "var(--ds-text-accent-teal-bolder, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-accent-teal-bolder, ".concat(colors.N800, ")")
  },
  purple: {
    light: "var(--ds-text-accent-purple-bolder, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-accent-purple-bolder, ".concat(colors.N800, ")")
  },
  lime: {
    light: "var(--ds-text-accent-lime-bolder, #37471F)",
    dark: "var(--ds-text-accent-lime-bolder, #D3F1A7)"
  },
  magenta: {
    light: "var(--ds-text-accent-magenta-bolder, #50253F)",
    dark: "var(--ds-text-accent-magenta-bolder, #FDD0EC)"
  },
  orange: {
    light: "var(--ds-text-accent-orange-bolder, #5F3811)",
    dark: "var(--ds-text-accent-orange-bolder, #FFE2BD)"
  },
  grey: {
    light: "var(--ds-text-accent-gray-bolder, ".concat(colors.N0, ")"),
    dark: "var(--ds-text-accent-gray-bolder, ".concat(colors.N0, ")")
  },
  blueLight: {
    light: "var(--ds-text-accent-blue, ".concat(colors.B500, ")"),
    dark: "var(--ds-text-accent-blue, ".concat(colors.B500, ")")
  },
  redLight: {
    light: "var(--ds-text-accent-red, ".concat(colors.N500, ")"),
    dark: "var(--ds-text-accent-red, ".concat(colors.N500, ")")
  },
  yellowLight: {
    light: "var(--ds-text-accent-yellow, ".concat(colors.N500, ")"),
    dark: "var(--ds-text-accent-yellow, ".concat(colors.N500, ")")
  },
  greenLight: {
    light: "var(--ds-text-accent-green, ".concat(colors.G500, ")"),
    dark: "var(--ds-text-accent-green, ".concat(colors.G500, ")")
  },
  tealLight: {
    light: "var(--ds-text-accent-teal, ".concat(colors.N500, ")"),
    dark: "var(--ds-text-accent-teal, ".concat(colors.N500, ")")
  },
  purpleLight: {
    light: "var(--ds-text-accent-purple, ".concat(colors.P500, ")"),
    dark: "var(--ds-text-accent-purple, ".concat(colors.P500, ")")
  },
  limeLight: {
    light: "var(--ds-text-accent-lime, #4C6B1F)",
    dark: "var(--ds-text-accent-lime, #B3DF72)"
  },
  magentaLight: {
    light: "var(--ds-text-accent-magenta, #943D73)",
    dark: "var(--ds-text-accent-magenta, #F797D2)"
  },
  orangeLight: {
    light: "var(--ds-text-accent-orange, #974F0C)",
    dark: "var(--ds-text-accent-orange, #FEC57B)"
  },
  greyLight: {
    light: "var(--ds-text-accent-gray, ".concat(colors.N500, ")"),
    dark: "var(--ds-text-accent-gray, ".concat(colors.N500, ")")
  }
};

/**
 * With design tokens, hover colors do not change
 * compared to resting state. These are only here
 * for backwards compatibiltiy with legacy theming.
 * This can be removed when legacy theming is removed
 */
export var textHoverColors = {
  standard: {
    light: "var(--ds-link, ".concat(colors.B300, ")"),
    dark: "var(--ds-link, ".concat(colors.B200, ")")
  },
  blue: {
    light: "var(--ds-text-accent-blue-bolder, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-accent-blue-bolder, ".concat(colors.N800, ")")
  },
  red: {
    light: "var(--ds-text-accent-red-bolder, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-accent-red-bolder, ".concat(colors.N800, ")")
  },
  yellow: {
    light: "var(--ds-text-accent-yellow-bolder, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-accent-yellow-bolder, ".concat(colors.N800, ")")
  },
  green: {
    light: "var(--ds-text-accent-green-bolder, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-accent-green-bolder, ".concat(colors.N800, ")")
  },
  teal: {
    light: "var(--ds-text-accent-teal-bolder, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-accent-teal-bolder, ".concat(colors.N800, ")")
  },
  purple: {
    light: "var(--ds-text-accent-purple-bolder, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-accent-purple-bolder, ".concat(colors.N800, ")")
  },
  lime: {
    light: "var(--ds-text-accent-lime-bolder, #37471F)",
    dark: "var(--ds-text-accent-lime-bolder, #D3F1A7)"
  },
  magenta: {
    light: "var(--ds-text-accent-magenta-bolder, #50253F)",
    dark: "var(--ds-text-accent-magenta-bolder, #FDD0EC)"
  },
  orange: {
    light: "var(--ds-text-accent-orange-bolder, #5F3811)",
    dark: "var(--ds-text-accent-orange-bolder, #FFE2BD)"
  },
  grey: {
    light: "var(--ds-text-accent-gray-bolder, ".concat(colors.N0, ")"),
    dark: "var(--ds-text-accent-gray-bolder, ".concat(colors.N0, ")")
  },
  blueLight: {
    light: "var(--ds-text-accent-blue, ".concat(colors.B500, ")"),
    dark: "var(--ds-text-accent-blue, ".concat(colors.B500, ")")
  },
  redLight: {
    light: "var(--ds-text-accent-red, ".concat(colors.N500, ")"),
    dark: "var(--ds-text-accent-red, ".concat(colors.N500, ")")
  },
  yellowLight: {
    light: "var(--ds-text-accent-yellow, ".concat(colors.N500, ")"),
    dark: "var(--ds-text-accent-yellow, ".concat(colors.N500, ")")
  },
  greenLight: {
    light: "var(--ds-text-accent-green, ".concat(colors.G500, ")"),
    dark: "var(--ds-text-accent-green, ".concat(colors.G500, ")")
  },
  tealLight: {
    light: "var(--ds-text-accent-teal, ".concat(colors.N500, ")"),
    dark: "var(--ds-text-accent-teal, ".concat(colors.N500, ")")
  },
  purpleLight: {
    light: "var(--ds-text-accent-purple, ".concat(colors.P500, ")"),
    dark: "var(--ds-text-accent-purple, ".concat(colors.P500, ")")
  },
  limeLight: {
    light: "var(--ds-text-accent-lime, #4C6B1F)",
    dark: "var(--ds-text-accent-lime, #B3DF72)"
  },
  magentaLight: {
    light: "var(--ds-text-accent-magenta, #943D73)",
    dark: "var(--ds-text-accent-magenta, #F797D2)"
  },
  orangeLight: {
    light: "var(--ds-text-accent-orange, #974F0C)",
    dark: "var(--ds-text-accent-orange, #FEC57B)"
  },
  greyLight: {
    light: "var(--ds-text-accent-gray, ".concat(colors.N500, ")"),
    dark: "var(--ds-text-accent-gray, ".concat(colors.N500, ")")
  }
};
export var textActiveColors = {
  standard: {
    light: "var(--ds-link-pressed, ".concat(colors.B300, ")"),
    dark: "var(--ds-link-pressed, ".concat(colors.B300, ")")
  },
  blue: {
    light: "var(--ds-text-accent-blue, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-accent-blue, ".concat(colors.N800, ")")
  },
  red: {
    light: "var(--ds-text-accent-red, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-accent-red, ".concat(colors.N800, ")")
  },
  yellow: {
    light: "var(--ds-text-accent-yellow, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-accent-yellow, ".concat(colors.N800, ")")
  },
  green: {
    light: "var(--ds-text-accent-green, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-accent-green, ".concat(colors.N800, ")")
  },
  teal: {
    light: "var(--ds-text-accent-teal, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-accent-teal, ".concat(colors.N800, ")")
  },
  purple: {
    light: "var(--ds-text-accent-purple, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-accent-purple, ".concat(colors.N800, ")")
  },
  lime: {
    light: "var(--ds-text-accent-lime, #4C6B1F)",
    dark: "var(--ds-text-accent-lime, #B3DF72)"
  },
  magenta: {
    light: "var(--ds-text-accent-magenta, #943D73)",
    dark: "var(--ds-text-accent-magenta, #F797D2)"
  },
  orange: {
    light: "var(--ds-text-accent-orange, #974F0C)",
    dark: "var(--ds-text-accent-orange, #FEC57B)"
  },
  grey: {
    light: "var(--ds-text-accent-gray, ".concat(colors.N0, ")"),
    dark: "var(--ds-text-accent-gray, ".concat(colors.N0, ")")
  },
  blueLight: {
    light: "var(--ds-text-accent-blue-bolder, ".concat(colors.B500, ")"),
    dark: "var(--ds-text-accent-blue-bolder, ".concat(colors.B500, ")")
  },
  redLight: {
    light: "var(--ds-text-accent-red-bolder, ".concat(colors.N500, ")"),
    dark: "var(--ds-text-accent-red-bolder, ".concat(colors.N500, ")")
  },
  yellowLight: {
    light: "var(--ds-text-accent-yellow-bolder, ".concat(colors.N500, ")"),
    dark: "var(--ds-text-accent-yellow-bolder, ".concat(colors.N500, ")")
  },
  greenLight: {
    light: "var(--ds-text-accent-green-bolder, ".concat(colors.G500, ")"),
    dark: "var(--ds-text-accent-green-bolder, ".concat(colors.G500, ")")
  },
  tealLight: {
    light: "var(--ds-text-accent-teal-bolder, ".concat(colors.N500, ")"),
    dark: "var(--ds-text-accent-teal-bolder, ".concat(colors.N500, ")")
  },
  purpleLight: {
    light: "var(--ds-text-accent-purple-bolder, ".concat(colors.P500, ")"),
    dark: "var(--ds-text-accent-purple-bolder, ".concat(colors.P500, ")")
  },
  limeLight: {
    light: "var(--ds-text-accent-lime-bolder, #37471F)",
    dark: "var(--ds-text-accent-lime-bolder, #D3F1A7)"
  },
  magentaLight: {
    light: "var(--ds-text-accent-magenta-bolder, #50253F)",
    dark: "var(--ds-text-accent-magenta-bolder, #FDD0EC)"
  },
  orangeLight: {
    light: "var(--ds-text-accent-orange-bolder, #5F3811)",
    dark: "var(--ds-text-accent-orange-bolder, #FFE2BD)"
  },
  greyLight: {
    light: "var(--ds-text-accent-gray-bolder, ".concat(colors.N500, ")"),
    dark: "var(--ds-text-accent-gray-bolder, ".concat(colors.N500, ")")
  }
};

// Background colors
export var backgroundColors = {
  standard: {
    light: "var(--ds-background-neutral, ".concat(colors.N20, ")"),
    dark: "var(--ds-background-neutral, ".concat(colors.DN100A, ")")
  },
  blue: {
    light: "var(--ds-background-accent-blue-subtle, ".concat(colors.B100, ")"),
    dark: "var(--ds-background-accent-blue-subtle, ".concat(colors.B100, ")")
  },
  red: {
    light: "var(--ds-background-accent-red-subtle, ".concat(colors.R100, ")"),
    dark: "var(--ds-background-accent-red-subtle, ".concat(colors.R100, ")")
  },
  yellow: {
    light: "var(--ds-background-accent-yellow-subtle, ".concat(colors.Y200, ")"),
    dark: "var(--ds-background-accent-yellow-subtle, ".concat(colors.Y200, ")")
  },
  green: {
    light: "var(--ds-background-accent-green-subtle, ".concat(colors.G200, ")"),
    dark: "var(--ds-background-accent-green-subtle, ".concat(colors.G200, ")")
  },
  teal: {
    light: "var(--ds-background-accent-teal-subtle, ".concat(colors.T200, ")"),
    dark: "var(--ds-background-accent-teal-subtle, ".concat(colors.T200, ")")
  },
  purple: {
    light: "var(--ds-background-accent-purple-subtle, ".concat(colors.P100, ")"),
    dark: "var(--ds-background-accent-purple-subtle, ".concat(colors.P100, ")")
  },
  lime: {
    light: "var(--ds-background-accent-lime-subtle, #94C748)",
    dark: "var(--ds-background-accent-lime-subtle, #4C6B1F)"
  },
  magenta: {
    light: "var(--ds-background-accent-magenta-subtle, #E774BB)",
    dark: "var(--ds-background-accent-magenta-subtle, #943D73)"
  },
  orange: {
    light: "var(--ds-background-accent-orange-subtle, #FAA53D)",
    dark: "var(--ds-background-accent-orange-subtle, #974F0C)"
  },
  grey: {
    light: "var(--ds-background-accent-gray-subtle, ".concat(colors.N500, ")"),
    dark: "var(--ds-background-accent-gray-subtle, ".concat(colors.N500, ")")
  },
  blueLight: {
    light: "var(--ds-background-accent-blue-subtler, ".concat(colors.B75, ")"),
    dark: "var(--ds-background-accent-blue-subtler, ".concat(colors.B75, ")")
  },
  redLight: {
    light: "var(--ds-background-accent-red-subtler, ".concat(colors.R75, ")"),
    dark: "var(--ds-background-accent-red-subtler, ".concat(colors.R75, ")")
  },
  yellowLight: {
    light: "var(--ds-background-accent-orange-subtler, ".concat(colors.Y100, ")"),
    dark: "var(--ds-background-accent-orange-subtler, ".concat(colors.Y100, ")")
  },
  greenLight: {
    light: "var(--ds-background-accent-green-subtler, ".concat(colors.G100, ")"),
    dark: "var(--ds-background-accent-green-subtler, ".concat(colors.G100, ")")
  },
  tealLight: {
    light: "var(--ds-background-accent-teal-subtler, ".concat(colors.T100, ")"),
    dark: "var(--ds-background-accent-teal-subtler, ".concat(colors.T100, ")")
  },
  purpleLight: {
    light: "var(--ds-background-accent-purple-subtler, ".concat(colors.P75, ")"),
    dark: "var(--ds-background-accent-purple-subtler, ".concat(colors.P75, ")")
  },
  limeLight: {
    light: "var(--ds-background-accent-lime-subtler, #D3F1A7)",
    dark: "var(--ds-background-accent-lime-subtler, #37471F)"
  },
  magentaLight: {
    light: "var(--ds-background-accent-magenta-subtler, #FDD0EC)",
    dark: "var(--ds-background-accent-magenta-subtler, #50253F)"
  },
  orangeLight: {
    light: "var(--ds-background-accent-orange-subtler, #FFE2BD)",
    dark: "var(--ds-background-accent-orange-subtler, #5F3811)"
  },
  greyLight: {
    light: "var(--ds-background-accent-gray-subtler, ".concat(colors.N30, ")"),
    dark: "var(--ds-background-accent-gray-subtler, ".concat(colors.N30, ")")
  }
};
export var linkHoverBackgroundColors = {
  standard: {
    light: "var(--ds-background-neutral-hovered, ".concat(colors.N30, ")"),
    dark: "var(--ds-background-neutral-hovered, ".concat(colors.DN60, ")")
  },
  blue: {
    light: "var(--ds-background-accent-blue-subtle-hovered, ".concat(colors.B75, ")"),
    dark: "var(--ds-background-accent-blue-subtle-hovered, ".concat(colors.DN60, ")")
  },
  red: {
    light: "var(--ds-background-accent-red-subtle-hovered, ".concat(colors.R75, ")"),
    dark: "var(--ds-background-accent-red-subtle-hovered, ".concat(colors.DN60, ")")
  },
  yellow: {
    light: "var(--ds-background-accent-yellow-subtle-hovered, ".concat(colors.Y100, ")"),
    dark: "var(--ds-background-accent-yellow-subtle-hovered, ".concat(colors.DN60, ")")
  },
  green: {
    light: "var(--ds-background-accent-green-subtle-hovered, ".concat(colors.G100, ")"),
    dark: "var(--ds-background-accent-green-subtle-hovered, ".concat(colors.DN60, ")")
  },
  teal: {
    light: "var(--ds-background-accent-teal-subtle-hovered, ".concat(colors.T100, ")"),
    dark: "var(--ds-background-accent-teal-subtle-hovered, ".concat(colors.DN60, ")")
  },
  purple: {
    light: "var(--ds-background-accent-purple-subtle-hovered, ".concat(colors.P75, ")"),
    dark: "var(--ds-background-accent-purple-subtle-hovered, ".concat(colors.DN60, ")")
  },
  lime: {
    light: "var(--ds-background-accent-lime-subtle-hovered, #B3DF72)",
    dark: "var(--ds-background-accent-lime-subtle-hovered, #37471F)"
  },
  magenta: {
    light: "var(--ds-background-accent-magenta-subtle-hovered, #F797D2)",
    dark: "var(--ds-background-accent-magenta-subtle-hovered, #50253F)"
  },
  orange: {
    light: "var(--ds-background-accent-orange-subtle-hovered, #FEC57B)",
    dark: "var(--ds-background-accent-orange-subtle-hovered, #37471F)"
  },
  grey: {
    light: "var(--ds-background-accent-gray-subtle-hovered, ".concat(colors.N400, ")"),
    dark: "var(--ds-background-accent-gray-subtle-hovered, ".concat(colors.N500, ")")
  },
  blueLight: {
    light: "var(--ds-background-accent-blue-subtler-hovered, ".concat(colors.B50, ")"),
    dark: "var(--ds-background-accent-blue-subtler-hovered, ".concat(colors.DN60, ")")
  },
  redLight: {
    light: "var(--ds-background-accent-red-subtler-hovered, ".concat(colors.R50, ")"),
    dark: "var(--ds-background-accent-red-subtler-hovered, ".concat(colors.DN60, ")")
  },
  yellowLight: {
    light: "var(--ds-background-accent-yellow-subtler-hovered, ".concat(colors.Y75, ")"),
    dark: "var(--ds-background-accent-yellow-subtler-hovered, ".concat(colors.DN60, ")")
  },
  greenLight: {
    light: "var(--ds-background-accent-green-subtler-hovered, ".concat(colors.G75, ")"),
    dark: "var(--ds-background-accent-green-subtler-hovered, ".concat(colors.DN60, ")")
  },
  tealLight: {
    light: "var(--ds-background-accent-teal-subtler-hovered, ".concat(colors.T75, ")"),
    dark: "var(--ds-background-accent-teal-subtler-hovered, ".concat(colors.DN60, ")")
  },
  purpleLight: {
    light: "var(--ds-background-accent-purple-subtler-hovered, ".concat(colors.P50, ")"),
    dark: "var(--ds-background-accent-purple-subtler-hovered, ".concat(colors.DN60, ")")
  },
  limeLight: {
    light: "var(--ds-background-accent-lime-subtler-hovered, #B3DF72)",
    dark: "var(--ds-background-accent-lime-subtler-hovered, #4C6B1F)"
  },
  magentaLight: {
    light: "var(--ds-background-accent-magenta-subtler-hovered, #F797D2)",
    dark: "var(--ds-background-accent-magenta-subtler-hovered, #943D73)"
  },
  orangeLight: {
    light: "var(--ds-background-accent-orange-subtler-hovered, #FEC57B)",
    dark: "var(--ds-background-accent-orange-subtler-hovered, #974F0C)"
  },
  greyLight: {
    light: "var(--ds-background-accent-gray-subtler-hovered, ".concat(colors.N40, ")"),
    dark: "var(--ds-background-accent-gray-subtler-hovered, ".concat(colors.DN60, ")")
  }
};
export var linkActiveBackgroundColors = {
  standard: {
    light: "var(--ds-background-neutral-pressed, ".concat(colors.N30, ")"),
    dark: "var(--ds-background-neutral-pressed, ".concat(colors.DN600, ")")
  },
  blue: {
    light: "var(--ds-background-accent-blue-subtle-pressed, ".concat(colors.B50, ")"),
    dark: "var(--ds-background-accent-blue-subtle-pressed, ".concat(colors.DN600, ")")
  },
  red: {
    light: "var(--ds-background-accent-red-subtle-pressed, ".concat(colors.R50, ")"),
    dark: "var(--ds-background-accent-red-subtle-pressed, ".concat(colors.DN600, ")")
  },
  yellow: {
    light: "var(--ds-background-accent-yellow-subtle-pressed, ".concat(colors.Y75, ")"),
    dark: "var(--ds-background-accent-yellow-subtle-pressed, ".concat(colors.DN600, ")")
  },
  green: {
    light: "var(--ds-background-accent-green-subtle-pressed, ".concat(colors.G75, ")"),
    dark: "var(--ds-background-accent-green-subtle-pressed, ".concat(colors.DN600, ")")
  },
  teal: {
    light: "var(--ds-background-accent-teal-subtle-pressed, ".concat(colors.T75, ")"),
    dark: "var(--ds-background-accent-teal-subtle-pressed, ".concat(colors.DN600, ")")
  },
  purple: {
    light: "var(--ds-background-accent-purple-subtle-pressed, ".concat(colors.P50, ")"),
    dark: "var(--ds-background-accent-purple-subtle-pressed, ".concat(colors.DN600, ")")
  },
  grey: {
    light: "var(--ds-background-accent-gray-subtle-pressed, ".concat(colors.N300, ")"),
    dark: "var(--ds-background-accent-gray-subtle-pressed, ".concat(colors.DN600, ")")
  },
  lime: {
    light: "var(--ds-background-accent-lime-subtle-pressed, #D3F1A7)",
    dark: "var(--ds-background-accent-lime-subtle-pressed, #2A3818)"
  },
  magenta: {
    light: "var(--ds-background-accent-magenta-subtle-pressed, #FDD0EC)",
    dark: "var(--ds-background-accent-magenta-subtle-pressed, #421F34)"
  },
  orange: {
    light: "var(--ds-background-accent-orange-subtle-pressed, #FFE2BD)",
    dark: "var(--ds-background-accent-orange-subtle-pressed, #4A2B0F)"
  },
  blueLight: {
    light: "var(--ds-background-accent-blue-subtler-pressed, ".concat(colors.B100, ")"),
    dark: "var(--ds-background-accent-blue-subtler-pressed, ".concat(colors.DN600, ")")
  },
  redLight: {
    light: "var(--ds-background-accent-red-subtler-pressed, ".concat(colors.R100, ")"),
    dark: "var(--ds-background-accent-red-subtler-pressed, ".concat(colors.DN600, ")")
  },
  yellowLight: {
    light: "var(--ds-background-accent-yellow-subtler-pressed, ".concat(colors.Y200, ")"),
    dark: "var(--ds-background-accent-yellow-subtler-pressed, ".concat(colors.DN600, ")")
  },
  greenLight: {
    light: "var(--ds-background-accent-green-subtler-pressed, ".concat(colors.G200, ")"),
    dark: "var(--ds-background-accent-green-subtler-pressed, ".concat(colors.DN600, ")")
  },
  tealLight: {
    light: "var(--ds-background-accent-teal-subtler-pressed, ".concat(colors.T200, ")"),
    dark: "var(--ds-background-accent-teal-subtler-pressed, ".concat(colors.DN600, ")")
  },
  purpleLight: {
    light: "var(--ds-background-accent-purple-subtler-pressed, ".concat(colors.P100, ")"),
    dark: "var(--ds-background-accent-purple-subtler-pressed, ".concat(colors.DN600, ")")
  },
  limeLight: {
    light: "var(--ds-background-accent-lime-subtler-pressed, #94C748)",
    dark: "var(--ds-background-accent-lime-subtler-pressed, #5B7F24)"
  },
  magentaLight: {
    light: "var(--ds-background-accent-magenta-subtler-pressed, #E774BB)",
    dark: "var(--ds-background-accent-magenta-subtler-pressed, #AE4787)"
  },
  orangeLight: {
    light: "var(--ds-background-accent-orange-subtler-pressed, #FAA53D)",
    dark: "var(--ds-background-accent-orange-subtler-pressed, #B65C02)"
  },
  greyLight: {
    light: "var(--ds-background-accent-gray-subtler-pressed, ".concat(colors.N50, ")"),
    dark: "var(--ds-background-accent-gray-subtler-pressed, ".concat(colors.DN600, ")")
  }
};
export var focusRingColors = {
  light: "var(--ds-border-focused, ".concat(colors.B100, ")"),
  dark: "var(--ds-border-focused, ".concat(colors.B75, ")")
};
export var borderRadius = {
  default: defaultBorderRadius,
  rounded: defaultRoundedBorderRadius
};
export var removalHoverBackgroundColors = {
  light: "var(--ds-background-danger, ".concat(colors.R50, ")"),
  dark: "var(--ds-background-danger, ".concat(colors.R100, ")")
};
export var removalActiveBackgroundColors = {
  light: "var(--ds-background-danger-hovered, ".concat(colors.R50, ")"),
  dark: "var(--ds-background-danger-hovered, ".concat(colors.DN600, ")")
};
export var removalTextColors = {
  light: "var(--ds-text-danger, ".concat(colors.R500, ")"),
  dark: "var(--ds-text-danger, ".concat(colors.DN30, ")")
};

/**
 * Remove button colors
 *
 * Once legacy theming support is dropped,
 * these can be removed and the remove button can inherit
 * from the tag text color
 */
export var removeButtonColors = {
  standard: "var(--ds-text, ".concat(colors.N500, ")"),
  blue: "var(--ds-text-accent-blue-bolder, ".concat(colors.N500, ")"),
  red: "var(--ds-text-accent-red-bolder, ".concat(colors.N500, ")"),
  yellow: "var(--ds-text-accent-yellow-bolder, ".concat(colors.N500, ")"),
  green: "var(--ds-text-accent-green-bolder, ".concat(colors.N500, ")"),
  teal: "var(--ds-text-accent-teal-bolder, ".concat(colors.N500, ")"),
  purple: "var(--ds-text-accent-purple-bolder, ".concat(colors.N500, ")"),
  lime: "var(--ds-text-accent-lime-bolder, ".concat(colors.N500, ")"),
  magenta: "var(--ds-text-accent-magenta-bolder, ".concat(colors.N500, ")"),
  orange: "var(--ds-text-accent-orange-bolder, ".concat(colors.N500, ")"),
  grey: "var(--ds-text-accent-gray-bolder, ".concat(colors.N500, ")"),
  blueLight: "var(--ds-text-accent-blue, ".concat(colors.N500, ")"),
  redLight: "var(--ds-text-accent-red, ".concat(colors.N500, ")"),
  yellowLight: "var(--ds-text-accent-yellow, ".concat(colors.N500, ")"),
  greenLight: "var(--ds-text-accent-green, ".concat(colors.N500, ")"),
  tealLight: "var(--ds-text-accent-teal, ".concat(colors.N500, ")"),
  purpleLight: "var(--ds-text-accent-purple, ".concat(colors.N500, ")"),
  limeLight: "var(--ds-text-accent-lime, ".concat(colors.N500, ")"),
  magentaLight: "var(--ds-text-accent-magenta, ".concat(colors.N500, ")"),
  orangeLight: "var(--ds-text-accent-orange, ".concat(colors.N500, ")"),
  greyLight: "var(--ds-text-accent-gray, ".concat(colors.N500, ")")
};
export var removeButtonHoverColors = {
  standard: {
    light: "var(--ds-text-danger, ".concat(colors.N700, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.DN600, ")")
  },
  standardLink: {
    light: "var(--ds-text-danger, ".concat(colors.B400, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.B400, ")")
  },
  blue: {
    light: "var(--ds-text-danger, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.N800, ")")
  },
  red: {
    light: "var(--ds-text-danger, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.N800, ")")
  },
  yellow: {
    light: "var(--ds-text-danger, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.N800, ")")
  },
  green: {
    light: "var(--ds-text-danger, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.N800, ")")
  },
  teal: {
    light: "var(--ds-text-danger, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.N800, ")")
  },
  purple: {
    light: "var(--ds-text-danger, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.N800, ")")
  },
  lime: {
    light: "var(--ds-text-danger, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.N800, ")")
  },
  magenta: {
    light: "var(--ds-text-danger, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.N800, ")")
  },
  orange: {
    light: "var(--ds-text-danger, ".concat(colors.N800, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.N800, ")")
  },
  grey: {
    light: "var(--ds-text-danger, ".concat(colors.N0, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.N0, ")")
  },
  blueLight: {
    light: "var(--ds-text-danger, ".concat(colors.B500, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.B500, ")")
  },
  redLight: {
    light: "var(--ds-text-danger, ".concat(colors.N500, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.N500, ")")
  },
  yellowLight: {
    light: "var(--ds-text-danger, ".concat(colors.N500, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.N500, ")")
  },
  greenLight: {
    light: "var(--ds-text-danger, ".concat(colors.G500, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.G500, ")")
  },
  tealLight: {
    light: "var(--ds-text-danger, ".concat(colors.N500, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.N500, ")")
  },
  purpleLight: {
    light: "var(--ds-text-danger, ".concat(colors.P500, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.P500, ")")
  },
  limeLight: {
    light: "var(--ds-text-danger, ".concat(colors.P500, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.P500, ")")
  },
  magentaLight: {
    light: "var(--ds-text-danger, ".concat(colors.P500, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.P500, ")")
  },
  orangeLight: {
    light: "var(--ds-text-danger, ".concat(colors.P500, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.P500, ")")
  },
  greyLight: {
    light: "var(--ds-text-danger, ".concat(colors.N500, ")"),
    dark: "var(--ds-text-danger, ".concat(colors.N500, ")")
  }
};