import { createResource } from 'react-resource-router';

import { loadRepositoryResource } from 'src/redux/repository/resources/load-repository-resource';

import { getRepoFullSlug } from './utils/get-full-repo-slug';

export const loadRepositoryResourcePipelines = createResource({
  type: 'repository',
  getKey: ({ match }) => `repository:${getRepoFullSlug(match.params)}`,
  maxAge: 0,
  getData: (routerStoreContext, resourceContext) => {
    if (location.hash.startsWith('#!/')) return { status: 'success' };
    return loadRepositoryResource.getData(routerStoreContext, resourceContext);
  },
});
