import Loadable from 'react-loadable';

import { LoadingPage } from 'src/components/react-loadable';
import { loadGlobalResource } from 'src/redux/global/resources/load-global';
import { accountNavigationResource } from 'src/sections/account/account-navigation-resource';
import { GPGKeysResourceLoadable } from 'src/sections/account/sections/settings/components/gpg-keys';

import { ROUTE_NAME, RouteConfig } from '../types';

const AccountSettingsLayout = Loadable({
  loading: LoadingPage,
  loader: () => import('src/sections/account/settings-layout'),
});

const AccountSettingsIframe = Loadable({
  loading: LoadingPage,
  loader: () =>
    import(
      'src/sections/workspace/sections/settings/containers/settings-iframe'
    ),
});

export const getAccountRoutesConfig = (): RouteConfig => {
  return {
    [ROUTE_NAME.ACCOUNT_SETTINGS_IFRAME]: {
      // @ts-ignore: requires RouteContext type on component
      component: AccountSettingsIframe,
      layout: AccountSettingsLayout,
      resources: [loadGlobalResource, accountNavigationResource],
    },
    [ROUTE_NAME.ACCOUNT_SETTINGS_GPG_KEYS]: {
      // @ts-ignore: requires RouteContext type on component
      component: GPGKeysResourceLoadable,
      layout: AccountSettingsLayout,
      resources: [loadGlobalResource, accountNavigationResource],
    },
  };
};
