export var getLinkProps = function getLinkProps(href, target) {
  return {
    href: href,
    rel: target === '_blank' ? 'noopener noreferrer' : null,
    target: target
  };
};
export var getButtonProps = function getButtonProps(onClick) {
  return {
    type: 'button',
    onClick: onClick
  };
};
export var getCustomElement = function getCustomElement(isDisabled, href, onClick) {
  if (href && !isDisabled) {
    return 'a';
  }
  if (onClick || isDisabled) {
    return 'button';
  }
  return 'span';
};