import React from 'react';

import Loadable from 'react-loadable';

import { useAppData } from 'src/app/data';
import { HiddenLoader } from 'src/components/react-loadable';

const DevAssetLoader = Loadable({
  loading: HiddenLoader,
  loader: () =>
    import(/* webpackChunkName: "dev-asset-loader" */ './version-dialog'),
});

export default () => {
  const { user } = useAppData();
  if (!user || !user.isAtlassian) {
    return null;
  }

  return <DevAssetLoader />;
};
