import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
  useCallback,
} from 'react';

import FeatureFlagWebClient, {
  SupportedFlagTypes,
  GetValueOptions,
  FeatureFlagUser,
  Identifiers,
} from '@atlassiansox/feature-flag-web-client';

import { getGlobalFeatureFlagClient, getFlagValue } from './client';
import {
  FeatureKeyType,
  FeatureKeys,
  StatsigFeatureKeyType,
  StatsigFeatureKeys,
} from './features';
import { FeatureConfig, FeatureFlagUserSettings } from './types';

const IS_SERVER = process.env.__SERVER__;

const useClient = ({
  apiKey,
  analyticsWebClient,
  featureFlagUser,
  options,
}: FeatureConfig) => {
  const getFeatureFlagClient = useCallback(() => {
    let newClient;
    // This feature flag client can't be used on the server until the launchdarkly node SDK is added
    if (!IS_SERVER && apiKey && analyticsWebClient && featureFlagUser) {
      newClient = getGlobalFeatureFlagClient({
        apiKey,
        analyticsWebClient,
        featureFlagUser,
        options,
      });
    }

    return newClient;
  }, [apiKey, analyticsWebClient, featureFlagUser, options]);

  // We wrap the client to give us an immutable object to pass to the
  // context provider. This allows us to create a new object when the client
  // is updated so consumers know that they need to re-render.
  const [currentClient, setCurrentClient] = useState<{
    client: FeatureFlagWebClient | null;
  }>(() => {
    return { client: getFeatureFlagClient() || null };
  });

  useEffect(() => {
    if (!currentClient.client) {
      // if we don't have a client for any reason, try to
      // create one when the client arguments change
      const newClient: FeatureFlagWebClient | null =
        getFeatureFlagClient() || null;

      if (newClient !== currentClient.client) {
        setCurrentClient({ client: newClient });
      }
    }
  }, [
    analyticsWebClient,
    apiKey,
    currentClient,
    getFeatureFlagClient,
    options,
    setCurrentClient,
    featureFlagUser,
  ]);

  useEffect(() => {
    // handle featureFlagUser updates
    if (currentClient && currentClient.client && featureFlagUser) {
      currentClient.client.updateFeatureFlagUser(featureFlagUser);
    }
  }, [featureFlagUser, currentClient]);

  return currentClient;
};

export const FeatureContext = createContext<{
  client: FeatureFlagWebClient | null;
}>({ client: null });

export const FeatureProvider: React.FC<FeatureConfig> = ({
  apiKey,
  analyticsWebClient,
  featureFlagUser,
  options,
  children,
}) => {
  const client = useClient({
    apiKey,
    analyticsWebClient,
    featureFlagUser,
    options,
  });

  return (
    <FeatureContext.Provider value={client}>{children}</FeatureContext.Provider>
  );
};

export const useFeature = (
  key: FeatureKeyType,
  defaultValue: SupportedFlagTypes = false,
  options?: GetValueOptions<SupportedFlagTypes>
) => {
  const clientWrapper = useContext(FeatureContext);
  const { client } = clientWrapper;
  const [flagValue, setFlagValue] = useState(() => {
    // if we have a client, get the value before falling back to the defaultValue
    return client
      ? client.getFlagValue(key, defaultValue, options)
      : defaultValue;
  });

  useEffect(() => {
    if (client) {
      // subscribe to updates of the flag
      return client.on(key, defaultValue, setFlagValue, options);
    }
    return () => {};
  }, [client, clientWrapper, defaultValue, key, options]);

  return flagValue;
};

const getFeatureName = (featureKey: FeatureKeyType): keyof typeof FeatureKeys =>
  (Object.keys(FeatureKeys) as (keyof typeof FeatureKeys)[]).filter(
    key => FeatureKeys[key] === featureKey
  )[0];

export type FeatureProp = {
  [name in keyof typeof FeatureKeys]: SupportedFlagTypes;
};

function isPropWithFeatures<T>(
  props: object
): props is T & { features: FeatureProp } {
  return !!(props as any).features;
}

const getFeatureGroups = (userSettings: FeatureFlagUserSettings): string[] => {
  const groups: string[] = [];

  if (userSettings.isAtlassian) {
    groups.push('atlassian-staff');
  }
  if (userSettings.isBitbucket) {
    groups.push('bitbucket-team');
  }

  return groups;
};

export const getUserIdentifier = (
  userSettings?: FeatureFlagUserSettings
): FeatureFlagUser => {
  if (!userSettings?.aaid) {
    return { isAnonymous: true };
  }
  return {
    identifier: {
      type: Identifiers.ATLASSIAN_ACCOUNT_ID,
      value: userSettings.aaid,
    },
    isAnonymous: false,
    custom: {
      feature_groups: getFeatureGroups(userSettings),
      atlassian_staff: !!userSettings.isAtlassian,
      bitbucket_team: !!userSettings.isBitbucket,
      workspace_uuid: userSettings.workspaceUuid,
      project_uuid: userSettings.projectUuid,
      repository_uuid: userSettings.repositoryUuid,
    },
  };
};

export const withFeature =
  (
    key: FeatureKeyType,
    defaultValue: SupportedFlagTypes,
    options?: GetValueOptions<SupportedFlagTypes>
  ) =>
  <T extends {}>(
    WrappedComponent: React.ComponentType<T>
  ): React.FC<Omit<T, 'features'>> => {
    return (props: T) => {
      const name: keyof typeof FeatureKeys = getFeatureName(key);
      const flagValue = useFeature(key, defaultValue, options);
      const featureProp = useMemo(() => {
        return isPropWithFeatures(props)
          ? { ...props.features, [name]: flagValue }
          : { [name]: flagValue };
      }, [name, flagValue, props]);

      return <WrappedComponent {...props} features={featureProp} />;
    };
  };

export {
  EnvironmentType,
  FeatureFlagUserWithIdentifier,
  AnonymousFlagUser,
  FeatureFlagUser,
  Identifiers,
} from '@atlassiansox/feature-flag-web-client';
export {
  StatsigFeatureKeys,
  StatsigFeatureKeyType,
  FeatureKeys,
  FeatureKeyType,
  getGlobalFeatureFlagClient,
  getFlagValue,
};
