import {BaseFrameworkAdaptor} from '../../adaptors/framework/BaseFrameworkAdaptor';
import {BaseModule} from '../base/Module';
import {ModuleDefinitions} from '../base/Module';
import {MessageProvider} from './MessageProvider';
import {MessageSimpleXdmDefinitionBuilder} from './MessageSimpleXdmDefinitionBuilder';
import {Provider} from '../base/Provider';
import {SimpleXdmDefinitionBuilder} from '../../definitions/SimpleXdmDefinitionBuilder';

export module MessageModuleDefinitions {

    export const MESSAGE_MODULE_NAME = 'messages';

    export class Props implements ModuleDefinitions.Props {

        adaptor: BaseFrameworkAdaptor;
        messageProvider: MessageProvider;

        constructor(
            adaptor: BaseFrameworkAdaptor,
            messageProvider: MessageProvider) {
            this.adaptor = adaptor;
            this.messageProvider = messageProvider;
        }

    }

    export class State implements ModuleDefinitions.State {

        enabled: boolean;
        registered: boolean;
        messageProvider: MessageProvider;
        simpleXdmDefinitionBuilder: SimpleXdmDefinitionBuilder;

        constructor(messageProvider: MessageProvider) {
            this.enabled = true;
            this.registered = false;
            this.messageProvider = messageProvider;
            this.simpleXdmDefinitionBuilder = new MessageSimpleXdmDefinitionBuilder(messageProvider);
        }

    }

}

/**
 * This module provides message related functionality.
 */
export class MessageModule extends BaseModule<MessageModuleDefinitions.Props, MessageModuleDefinitions.State> {

    constructor(props: MessageModuleDefinitions.Props) {
        super(props);
    }

    componentWillMount(): void {
        if (!this.props.messageProvider) {
            throw new Error('this.props.messageProvider is not defined');
        }
        this.setState(new MessageModuleDefinitions.State(this.props.messageProvider));
    }

    public getModuleRegistrationName(): string {
        return MessageModuleDefinitions.MESSAGE_MODULE_NAME;
    }

    public getSimpleXdmDefinitionBuilder() : SimpleXdmDefinitionBuilder {
      return this.state.simpleXdmDefinitionBuilder;
    }

    getProvider(): Provider {
        return this.props.messageProvider;
    }

    render(): JSX.Element | null {
        return null;
    }
}
