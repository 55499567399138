/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { parse, stringify } from 'qs';
import { call, all } from 'redux-saga/effects';

import authRequest from 'src/utils/fetch';
import { AccessToken } from 'src/utils/fetch-access-token';

export const getUrlForPage = (url: string, page: number, pagelen: number) => {
  const [baseUrl, search] = url.split('?');
  return `${baseUrl}?${stringify({
    ...parse(search || '', { ignoreQueryPrefix: true }),
    page,
    pagelen,
  })}`;
};

export function* fetchAllPages(url: string, pagelen = 10) {
  const fetchPage = function* (currentPage: number) {
    const pageUrl = getUrlForPage(url, currentPage, pagelen);
    const response: Response = yield call(fetch, authRequest(pageUrl));
    if (!response.ok) {
      throw Error(
        `Response failed with text - ${response.statusText}, status: ${response.status}`
      );
    }
    return response;
  };

  let values: any[] = [];
  const firstPageResponse: Response = yield fetchPage(1);
  // @ts-ignore
  const firstPagePayload: any = yield firstPageResponse.json();
  values = values.concat(firstPagePayload.values);

  // returns an array of page numbers left to fetch
  const pagesLeft = [
    ...Array(Math.ceil(firstPagePayload.size / pagelen) + 1).keys(),
  ].slice(2);
  const remainingPagesResponses: Response[] = yield all(
    pagesLeft.map(fetchPage)
  );
  if (remainingPagesResponses.length) {
    // @ts-ignore
    const remainingPagesPayloads = yield all(
      remainingPagesResponses.map(r => r.json())
    );
    values = values.concat(
      remainingPagesPayloads.reduce(
        (reducer: any[], p: { values: any[] }) => reducer.concat(p.values),
        []
      )
    );
  }

  return values;
}

export default function* fetchWithAccessToken(
  url: string,
  accessToken: AccessToken,
  returnValuesField = true,
  method: 'GET' | 'POST' = 'GET'
) {
  const response: Response = yield call(fetch, url, {
    method,
    headers: { Authorization: `Bearer ${accessToken.token}` },
  });
  if (!response.ok) {
    throw Error(
      `Response failed with text - ${response.statusText}, status: ${response.status}`
    );
  }
  try {
    // @ts-ignore
    const json = yield response.json();
    return returnValuesField ? json.values : json;
  } catch (e) {
    return response;
  }
}
