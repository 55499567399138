import React from 'react';

import { defineMessages, FormattedMessage } from 'react-intl';

import Flag from '@atlaskit/flag';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import { ComponentFlagId, HandleDismissedType } from 'src/redux/flags/types';
import urls from 'src/urls';

export const USER_NEEDS_ACCESS_FLAG_ID = 'user-needs-access' as const;

export const messages = defineMessages({
  userNeedsAccessFlagTitleWarning: {
    id: 'frontbucket.flags.userNeedsAccess.titleWarning',
    description: 'Text for title of flag indicating a user needs access',
    defaultMessage: '{displayName} needs access',
  },
  userNeedsAccessFlagDescriptionWarning: {
    id: 'frontbucket.flags.userNeedsAccess.descriptionWarning',
    description: 'Text for description of flag indicating a user needs access',
    defaultMessage: `You mentioned {displayName}, but they don’t have the necessary permissions to view or access the content. {link} about granting access to users and groups.`,
  },
  userNeedsAccessFlagLinkText: {
    id: 'frontbucket.flags.userNeedsAccess.linkText',
    description: 'Text for learn more link',
    defaultMessage: 'Learn more',
  },
  defaultUser: {
    id: 'frontbucket.flags.userNeedsAccess.defaultUser',
    description: 'Default user',
    defaultMessage: 'User',
  },
});

type Props = {
  id: ComponentFlagId;
  props?: {
    displayName: string;
  };
} & HandleDismissedType;

const UserNeedsAccessWarningFlag = ({
  id,
  props,
  ...otherProps
}: Props): JSX.Element => {
  const intl = useIntl();
  const displayName =
    props?.displayName || intl.formatMessage(messages.defaultUser);
  return (
    <Flag
      {...otherProps}
      id={id}
      title={intl.formatMessage(messages.userNeedsAccessFlagTitleWarning, {
        displayName,
      })}
      description={
        <FormattedMessage
          {...messages.userNeedsAccessFlagDescriptionWarning}
          values={{
            displayName,
            link: (
              <a
                href={urls.external.grantRepositoryAccessToUsersAndGroups}
                target="_blank"
              >
                {intl.formatMessage(messages.userNeedsAccessFlagLinkText)}
              </a>
            ),
          }}
        />
      }
      icon={
        <WarningIcon
          label="Warning"
          primaryColor={token('color.icon.warning', colors.Y300)}
        />
      }
    />
  );
};

export default UserNeedsAccessWarningFlag;
