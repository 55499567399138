import { defineMessages } from 'react-intl';

export default defineMessages({
  dismissButton: {
    id: 'frontbucket.flags.siteMessage.dismiss',
    description: 'Label to be shown on the button to dismiss the message',
    defaultMessage: 'Dismiss',
  },
  learnMoreButton: {
    id: 'frontbucket.flags.siteMessage.learnMore',
    description: 'Label to be shown on the button linking to more information',
    defaultMessage: 'Learn more',
  },
  refreshButton: {
    id: 'frontbucket.flags.siteMessage.refresh',
    description:
      'Label to be shown on the button that will refresh the current page',
    defaultMessage: 'Refresh page',
  },
});
