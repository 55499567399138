export function flatten(ob: any, prefix: any = false, res: any = null) {
  const result = res || {};

  if (
    prefix &&
    typeof ob === 'object' &&
    ob !== null &&
    Object.keys(ob).length === 0
  ) {
    result[prefix] = Array.isArray(ob) ? [] : {};
    return result;
  }

  const prefixValue = prefix ? `${prefix}.` : '';

  for (const i in ob) {
    if (Object.prototype.hasOwnProperty.call(ob, i)) {
      if (typeof ob[i] === 'object' && ob[i] !== null) {
        flatten(ob[i], prefixValue + i, result);
      } else {
        result[prefixValue + i] = ob[i];
      }
    }
  }
  return result;
}

export function unflatten(ob: any) {
  const result = {};
  for (const i in ob) {
    if (Object.prototype.hasOwnProperty.call(ob, i)) {
      const keys = i.match(/^\.+[^.]*|[^.]*\.+$|(?:\.{2,}|[^.])+(?:\.+$)?/g);
      if (keys) {
        keys.reduce((r: any, e, j) => {
          const value = r[e];
          if (value) {
            return value;
          } else {
            const newValue = isNaN(Number(keys[j + 1]))
              ? keys.length - 1 === j
                ? ob[i]
                : {}
              : [];
            r[e] = newValue;
            return newValue;
          }
        }, result);
      }
    }
  }
  return result;
}
