/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { getRepositories } from 'src/redux/dashboard/selectors/repositories';
import { UPDATE_REPOSITORY_BUILD_STATUSES } from 'src/redux/global/actions/repository-build-status';
import { createGetRepositoryBuildStatuses } from 'src/redux/global/sagas/create-get-repository-build-statuses';

export const getDashboardRepositoryListBuildStatuses =
  createGetRepositoryBuildStatuses(
    UPDATE_REPOSITORY_BUILD_STATUSES,
    getRepositories
  );
