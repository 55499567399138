import {FlagProvider} from './FlagProvider';
import {SimpleXdmDefinition} from '../../definitions/SimpleXdmDefinition';
import {SimpleXdmDefinitionBuilder} from '../../definitions/SimpleXdmDefinitionBuilder';
import {Flag} from './Flag';

export class FlagSimpleXdmDefinitionBuilder implements SimpleXdmDefinitionBuilder {

  flagProvider: FlagProvider;

  constructor(flagProvider: FlagProvider) {
      if (!flagProvider) {
          throw new Error('flagProvider is not defined');
      }
      this.flagProvider = flagProvider;
  }

  buildSimpleXdmDefinition(): SimpleXdmDefinition {

    var flagProvider = this.flagProvider;

    /*
     * This class is a proxy to the flag object created by the flag provider. It is
     * equivalent to the Flag class defined in atlassian-connect-js/src/modules/flag.js
     * except that it does not pull in any AUI flag implementation.
     */
    class FlagProxy {

      flag: Flag;

      constructor(options : any, callback : any) {
        options.id = callback._id;
        const flagId = callback._id;
        let actions: any[] = [];
        if (typeof options.actions === 'object') {
          actions = Object.getOwnPropertyNames(options.actions).map(key => {
            return {
              actionKey: key,
              actionText: options.actions[key],
              executeAction: () => {
                console.log('This needs to be implemented by broadcasting a "flag-action-invoked" event.');
              }
            };
          });
        }
        const flagOptions = {
          id: flagId,
          title: options.title,
          body: options.body,
          actions: actions,
          onClose: () => {
            console.log('This needs to be implemented by broadcasting a "flag.close" event.');
          },
          close: options.close,
          type: options.type.toLowerCase()
        };
        this.flag = flagProvider.create(flagOptions);
      }

      close() : void {
        this.flag.close();
      }

    }
    return {
      create: {
        constructor: FlagProxy,
        close: FlagProxy.prototype.close
      }
    };
  }

}
