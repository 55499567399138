export default {
  PARSING: 'Pending',
  PENDING: 'Pending',
  READY: 'Starting agent',
  UPLOADING_ARTIFACTS: 'Uploading artifacts',
  UPLOADING_CACHES: 'Uploading caches',
  DOWNLOADING_ARTIFACTS: 'Downloading artifacts',
  DOWNLOADING_CACHES: 'Downloading caches',
  COMPLETING_LOGS: 'Saving logs',
  PARSING_TEST_RESULTS: 'Parsing test results',
  IN_PROGRESS: 'In progress',
  PULLING_IMAGES: 'Pulling images',
  CLONING: 'Cloning repository',
  RUNNING: 'Running',
  PAUSED: 'Paused',
  HALTED: 'Halted',
  ERROR: 'Failed',
  SYSTEM_ERROR: 'System error',
  USER_ERROR: 'Error',
  FAILED: 'Failed',
  REDEPLOY: 'Redeployed',
  RERUN: 'Rerun',
  SUCCESSFUL: 'Successful',
  STOPPED: 'Stopped',
  SKIPPED: 'Skipped',
  NOT_RUN: 'Not run',
  UNDEPLOYED: 'Undeployed',
  fail: '',
  complete: '',
};
