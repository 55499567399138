import React, { useMemo } from 'react';

import {
  Account,
  Capabilities,
  Repository,
} from 'src/components/pipelines/models';

import { ALLOWANCE_LIMIT, ALLOWANCE_THRESHOLD } from '../constants';
import { getPlansPageURL, getSettingsPageURL } from '../utils/links';

import Message from './Message';
import SettingsButton from './SettingsButton';
import {
  PipelineMessagesBuildMinutesLink,
  PipelineMessagesMessage,
  PipelineMessagesWrapper,
} from './styled';

export interface Props {
  account: Account;
  repository: Repository;
  capabilities: Capabilities;
  allowanceProps?: any;
  isAllowanceMessageDismissed: boolean;
  onDismissMessage: () => void;
}

/* eslint @typescript-eslint/ban-types: "warn" */
const PipelineMessages: React.FC<Props> = ({
  account,
  repository,
  capabilities,
  allowanceProps,
  isAllowanceMessageDismissed,
  onDismissMessage,
}) => {
  const allowances = useMemo(
    () =>
      allowanceProps || {
        information: {
          message: 'Your account is close to running out of build minutes.',
          options: { closeable: 'true' },
          affectedByCookie: true,
        },
        warning: {
          message: 'Your account has run out of build minutes.',
          options: {},
          affectedByCookie: false,
        },
      },
    [allowanceProps]
  );

  const showAllowanceMessage = useMemo(() => {
    const allowanceMessageType =
      capabilities.allowancePercentageUsed >= ALLOWANCE_LIMIT
        ? 'warning'
        : 'information';
    const allowance = allowances[allowanceMessageType];

    return (
      repository.hasFetchedUserIsAdmin &&
      !(allowance.affectedByCookie && isAllowanceMessageDismissed) &&
      capabilities.limited &&
      capabilities.allowancePercentageUsed >= ALLOWANCE_THRESHOLD
    );
  }, [
    allowances,
    repository.hasFetchedUserIsAdmin,
    capabilities.allowancePercentageUsed,
    capabilities.limited,
    isAllowanceMessageDismissed,
  ]);

  const pipelinesDisabledMessage = useMemo(() => {
    return (
      <PipelineMessagesWrapper>
        <PipelineMessagesMessage>
          <Message type="warning">
            Pipelines is currently <strong>disabled</strong> for this
            repository. Enable it in the settings to start building again.{' '}
            <br />
            <SettingsButton
              configurationPageURL={getSettingsPageURL(
                repository.path,
                account.addonKey
              )}
              branch={repository.mainbranch}
              isBranchSet={repository.hasFetched}
              tooltipActive
            >
              Go to settings
            </SettingsButton>
          </Message>
        </PipelineMessagesMessage>
      </PipelineMessagesWrapper>
    );
  }, [
    repository.path,
    repository.hasFetched,
    repository.mainbranch,
    account.addonKey,
  ]);

  const pipelinesCapabilitiesErrorMessage = useMemo(() => {
    return (
      <PipelineMessagesWrapper>
        <PipelineMessagesMessage>
          <Message type="warning">
            Something went wrong. While we check things on our end, refresh the
            page and check the{' '}
            <a href="https://bitbucket.status.atlassian.com/" target="_blank">
              Bitbucket Cloud Status page
            </a>{' '}
            to ensure there are no outages or performance issues.
          </Message>
        </PipelineMessagesMessage>
      </PipelineMessagesWrapper>
    );
  }, []);

  const allowanceMessage = useMemo(() => {
    const allowanceMessageType =
      capabilities.allowancePercentageUsed >= ALLOWANCE_LIMIT
        ? 'warning'
        : 'information';
    const allowance = allowances[allowanceMessageType];

    return (
      <PipelineMessagesWrapper>
        <PipelineMessagesMessage>
          {repository.userIsAdmin ? (
            <Message
              type={allowanceMessageType}
              onMessageClose={onDismissMessage}
              options={allowance.options}
            >
              {allowance.message}
              <br />
              <PipelineMessagesBuildMinutesLink
                target="_blank"
                href={getPlansPageURL(repository.path)}
              >
                View plan details
              </PipelineMessagesBuildMinutesLink>
            </Message>
          ) : (
            <Message
              type={allowanceMessageType}
              onMessageClose={onDismissMessage}
              options={allowance.options}
            >
              {allowance.message} An account administrator can purchase more
              minutes.
            </Message>
          )}
        </PipelineMessagesMessage>
      </PipelineMessagesWrapper>
    );
  }, [
    allowances,
    capabilities.allowancePercentageUsed,
    onDismissMessage,
    repository.path,
    repository.userIsAdmin,
  ]);

  const calculatedMessage = useMemo(() => {
    if (capabilities.pipelinesEnabled) {
      return showAllowanceMessage ? allowanceMessage : null;
    }

    return capabilities.fetchCapabilitiesFailed
      ? pipelinesCapabilitiesErrorMessage
      : pipelinesDisabledMessage;
  }, [
    showAllowanceMessage,
    capabilities.pipelinesEnabled,
    capabilities.fetchCapabilitiesFailed,
    allowanceMessage,
    pipelinesDisabledMessage,
    pipelinesCapabilitiesErrorMessage,
  ]);

  return <>{calculatedMessage}</>;
};

export default React.memo(PipelineMessages);
