import React from 'react';

import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';

import Button from '@atlaskit/button/standard-button';
import SectionMessage from '@atlaskit/section-message';

import messages from './section-error.i18n';
import * as styles from './section-error.style';

type Props = {
  intl: InjectedIntl;
  sectionHeading?: JSX.Element;
  sentryEventId?: string;
};

export const SectionError = injectIntl(
  ({ intl, sectionHeading, sentryEventId }: Props) => (
    <styles.SectionErrorContainer>
      {sectionHeading}
      <SectionMessage
        appearance="error"
        title={intl.formatMessage(messages.title)}
      >
        <FormattedMessage
          {...messages.keepsHappening}
          values={{
            supportResourcesLink: (
              <a href="https://support.atlassian.com/bitbucket-cloud/">
                {intl.formatMessage(messages.supportResourcesLink)}
              </a>
            ),
            tryAgainLink: (
              <Button
                onClick={() => window.location.reload()}
                appearance="link"
                spacing="none"
              >
                {intl.formatMessage(messages.tryAgainLink)}
              </Button>
            ),
          }}
          tagName="p"
        />
        {sentryEventId && (
          <>
            <FormattedMessage {...messages.errorCode} tagName="p" />
            <p>
              <styles.SentryErrorCode>{sentryEventId}</styles.SentryErrorCode>
            </p>
          </>
        )}
      </SectionMessage>
    </styles.SectionErrorContainer>
  )
);
