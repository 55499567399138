import './polyfills';

// This is imported first to enable us to catch as many exceptions as possible
import './sentry';

// This assignment to __webpack_nonce__ needs to happen before other imports
// to ensure that dynamically loaded modules use the CSP nonce
import './csp';

// This assignment to __webpack_public_path__ needs to happen before all other
// imports in order to have correct asset URL resolution
// see: https://webpack.js.org/guides/public-path/#on-the-fly
import './public-path';

import React from 'react';

import ReactDOM from 'react-dom';
import { addLocaleData } from 'react-intl';
import Loadable from 'react-loadable';

import {
  getGlobalFeatureFlagClient,
  getUserIdentifier,
} from '@atlassian/bitbucket-features';
import FeatureFlagClient from '@atlassiansox/feature-flag-web-client';

import { appWasServerSideRendered } from 'src/utils/ssr';

import App from './app/index';
import { initializeBrowserMetrics } from './components/browser-metrics';
import { initUFO } from './components/react-ufo/initUFO';
import { FeatureKeys } from './config/feature-flag-keys';
import { envMap } from './contexts/feature-provider';
import locale from './locale';
import { analyticsClient } from './utils/analytics/client';
import { getBbEnv, BbEnv } from './utils/bb-env';

if (process.env.NODE_ENV === 'development') {
  const qs = require('qs');
  const { wdyu, wdyr, a11y } = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  // We previously used the "why-did-you-update" library, but that was
  // deprecated by its author in favour of "why-did-you-render". Keeping
  // the old "wdyu" support around in case people are used to using that.
  const wdyrTarget = wdyr || wdyu;

  if (wdyrTarget !== undefined) {
    // eslint-disable-next-line import/no-extraneous-dependencies
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    const ReactRedux = require('react-redux');
    whyDidYouRender(React, {
      include: [new RegExp(wdyrTarget)],
      trackExtraHooks: [[ReactRedux, 'useSelector']],
    });
  }

  if (a11y !== undefined) {
    // Show accessibility errors in development
    // https://github.com/dequelabs/axe-core-npm/tree/develop/packages/react
    // * Uses advanced console logging features, works best in Chrome
    //   (limited functionality in Safari and Firefox)
    //
    // eslint-disable-next-line import/no-extraneous-dependencies
    const axe = require('@axe-core/react');
    axe(React, ReactDOM, 1000);
  }
}

if (locale.localeData) {
  addLocaleData(locale.localeData);
  if (locale.isDummyLocale) {
    // @ts-ignore Is this argument even used?
    addLocaleData({ locale: locale.locale, parentLocale: 'en' });
  }
}

// This is the one place we should reference app data from the window
// eslint-disable-next-line no-restricted-properties
const appData = window.__app_data__;

function createFeatureFlagClient() {
  const env = envMap[getBbEnv() || BbEnv.Development];
  const apiKey = FeatureKeys[env];
  const featureFlagUser = getUserIdentifier(appData.user);

  return getGlobalFeatureFlagClient({
    apiKey,
    analyticsWebClient: analyticsClient(),
    featureFlagUser,
    options: {
      productKey: 'bitbucket',
      environment: env,
    },
  });
}

// Initialise the feature flag client before we start rendering.
// Kicking this off before the react render prevents components
// from rerendering once flag values are populated from the remote.
const featureFlagClient: FeatureFlagClient = createFeatureFlagClient();

// Start up Browser Metrics
initializeBrowserMetrics({
  isAtlassian: appData.user?.isAtlassian || false,
  featureFlagClient,
  frontbucket_version: appData.frontbucket_version || 'local',
  tenantId: appData.tenantId,
});

initUFO(appData);

const rootEl: HTMLElement | null | undefined = document.getElementById('root');
if (rootEl) {
  if (appWasServerSideRendered()) {
    Loadable.preloadReady().then(() => {
      ReactDOM.render(<App appData={appData} />, rootEl);
    });
  } else {
    ReactDOM.render(<App appData={appData} />, rootEl);
  }
}
