/* eslint-disable @repo/internal/react/require-jsdoc */
import { createAndFireEvent, withAnalyticsContext, withAnalyticsEvents } from '@atlaskit/analytics-next';
import AsyncSelect from '@atlaskit/react-select/async';
import createSelect from './createSelect';
var packageName = "@atlaskit/select";
var packageVersion = "18.5.0";
export var SelectWithoutAnalytics = createSelect(AsyncSelect);
var createAndFireEventOnAtlaskit = createAndFireEvent('atlaskit');
var Select = withAnalyticsContext({
  componentName: 'select',
  packageName: packageName,
  packageVersion: packageVersion
})(withAnalyticsEvents({
  onChange: createAndFireEventOnAtlaskit({
    action: 'changed',
    actionSubject: 'option',
    attributes: {
      componentName: 'select',
      packageName: packageName,
      packageVersion: packageVersion
    }
  })
})(SelectWithoutAnalytics));
export default Select;