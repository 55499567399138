import { fetchAction } from 'src/redux/actions';
import urls from 'src/redux/pull-request/urls';

import { FETCH_TASKS } from './constants';

type FetchTasksOptions = {
  owner: string;
  repoSlug: string;
  pullRequestId: string | number;
};

export default ({ owner, repoSlug, pullRequestId }: FetchTasksOptions) => {
  return fetchAction(FETCH_TASKS, {
    url: urls.api.v20.tasksFetch(owner, repoSlug, pullRequestId),
  });
};
