const urls = {
  api: {
    internal: {
      userPreference: (uuid: string, key: string) =>
        `/!api/internal/user/preferences/${uuid}/${key}/`,
      repositoriesLanguages: (workspaceSlug: string) =>
        `/!api/internal/repositories/${workspaceSlug}/languages`,
    },
    v10: {},
    v20: {
      user: (uuid: string) => `/!api/2.0/users/${uuid}`,
    },
  },
  external: {},
  ui: {
    // Trailing slash is necessary here to avoid a redirect that could wipe out query params
    profile: (username: string) => `/${username}/workspace/overview/`,
  },
};

export default urls;
