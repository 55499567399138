import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { WidthType } from './types/rich-media-common';
import { isDOMElement } from '../../utils/parseDOM';
import { mediaSingle as mediaSingleFactory, mediaSingleCaption as mediaSingleCaptionFactory, mediaSingleFull as mediaSingleFullFactory, mediaSingleWidthType as mediaSingleWidthTypeFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name mediaSingle_node
 * @additionalProperties true
 */

/**
 * @additionalProperties true
 */

/**
 * @name mediaSingle_caption_node
 */

/**
 * @additionalProperties true
 */

/**
 * @name mediaSingle_full_node
 */

export var defaultAttrs = {
  width: {
    default: null
  },
  // null makes small images to have original size by default
  layout: {
    default: 'center'
  }
};
export var mediaSingleSpec = function mediaSingleSpec(_ref) {
  var _ref$withCaption = _ref.withCaption,
    withCaption = _ref$withCaption === void 0 ? false : _ref$withCaption,
    _ref$withExtendedWidt = _ref.withExtendedWidthTypes,
    withExtendedWidthTypes = _ref$withExtendedWidt === void 0 ? false : _ref$withExtendedWidt;
  var getAttrs = function getAttrs(dom) {
    if (!isDOMElement(dom)) {
      // this should never happen
      return {
        layout: 'center'
      };
    }
    var layout = dom.getAttribute('data-layout') || 'center';
    var width = Number(dom.getAttribute('data-width')) || null;
    var widthType = dom.getAttribute('data-width-type');
    if (withExtendedWidthTypes) {
      return {
        layout: layout,
        width: width,
        widthType: widthType
      };
    } else if (widthType === WidthType.PIXEL) {
      // if editor does not support widthType attribute.
      // We ignore width and widthType together.
      return {
        layout: layout
      };
    } else {
      return {
        layout: layout,
        width: width
      };
    }
  };
  var getAttrsFromNode = function getAttrsFromNode(node) {
    var _node$attrs = node.attrs,
      layout = _node$attrs.layout,
      width = _node$attrs.width;
    var attrs = {
      'data-node-type': 'mediaSingle',
      'data-layout': layout,
      'data-width': ''
    };
    if (width) {
      attrs['data-width'] = isFinite(width) && Math.floor(width) === width ? width : width.toFixed(2);
    }
    if (withExtendedWidthTypes && node.attrs.widthType) {
      var widthType = node.attrs.widthType;
      return _objectSpread(_objectSpread({}, attrs), {}, {
        'data-width-type': widthType || WidthType.PERCENTAGE
      });
    }
    return attrs;
  };
  if (withExtendedWidthTypes && withCaption) {
    return mediaSingleFullFactory({
      parseDOM: [{
        tag: 'div[data-node-type="mediaSingle"]',
        getAttrs: getAttrs
      }],
      toDOM: function toDOM(node) {
        return ['div', getAttrsFromNode(node), 0];
      }
    });
  }
  if (withExtendedWidthTypes && !withCaption) {
    return mediaSingleWidthTypeFactory({
      parseDOM: [{
        tag: 'div[data-node-type="mediaSingle"]',
        getAttrs: getAttrs
      }],
      toDOM: function toDOM(node) {
        return ['div', getAttrsFromNode(node), 0];
      }
    });
  }
  if (!withExtendedWidthTypes && withCaption) {
    return mediaSingleCaptionFactory({
      parseDOM: [{
        tag: 'div[data-node-type="mediaSingle"]',
        getAttrs: getAttrs
      }],
      toDOM: function toDOM(node) {
        return ['div', getAttrsFromNode(node), 0];
      }
    });
  }
  return mediaSingleFactory({
    parseDOM: [{
      tag: 'div[data-node-type="mediaSingle"]',
      getAttrs: getAttrs
    }],
    toDOM: function toDOM(node) {
      return ['div', getAttrsFromNode(node), 0];
    }
  });
};
export var mediaSingle = mediaSingleSpec({
  withCaption: false,
  withExtendedWidthTypes: false
});
export var mediaSingleWithCaption = mediaSingleSpec({
  withCaption: true,
  withExtendedWidthTypes: false
});
export var mediaSingleWithWidthType = mediaSingleSpec({
  withCaption: false,
  withExtendedWidthTypes: true
});
export var mediaSingleFull = mediaSingleSpec({
  withCaption: true,
  withExtendedWidthTypes: true
});
export var toJSON = function toJSON(node) {
  return {
    attrs: Object.keys(node.attrs).reduce(function (obj, key) {
      if (node.attrs[key] !== null) {
        obj[key] = node.attrs[key];
      }
      return obj;
    }, {})
  };
};