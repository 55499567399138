import { gridSize } from '@atlaskit/theme';

export const STICKY_FILE_HEIGHT = 42;
export const STICKY_DIFF_HEADER_HEIGHT_OFFSET = 54;
export const STICKY_FILE_HEADER_HEIGHT_OFFSET = 56;
export const TABBED_STICKY_HEADER_HEIGHT = 70;
// In order to keep these level, the diff needs one less pixel of padding
// than the file tree
export const TABBED_STICKY_HEADER_DIFF_PADDING = 15;
export const TABBED_STICKY_HEADER_FILE_TREE_PADDING = 16;
export const TABBED_MOBILE_STICKY_HEADER_HEIGHT = 40;
export const MOBILE_NAV_HEIGHT = 54;

export const calculateStickyHeaderOffset = (
  hasCommentNav?: boolean,
  fileHeaderHeight?: number
) => {
  const offset = fileHeaderHeight || STICKY_FILE_HEADER_HEIGHT_OFFSET;
  return hasCommentNav
    ? STICKY_DIFF_HEADER_HEIGHT_OFFSET + offset
    : STICKY_DIFF_HEADER_HEIGHT_OFFSET;
};

// Ak Navigation widths are copied from @atlaskit/navigation-next because they can't be imported
const closedProductNavWidth = gridSize() * 2.5;

export const calculateOffsetForNavNext = (
  isNavigationOpen: boolean,
  isMobileHeaderActive: boolean,
  sidebarWidth: number,
  productNavWidth: number
): number => {
  let offset;

  if (isNavigationOpen && isMobileHeaderActive) {
    return 0;
  }

  if (isNavigationOpen) {
    offset = productNavWidth;
  } else {
    offset = closedProductNavWidth;
  }

  offset += sidebarWidth;

  return offset;
};
