import {MessageProvider} from './MessageProvider';
import {SimpleXdmDefinition} from '../../definitions/SimpleXdmDefinition';
import {SimpleXdmDefinitionBuilder} from '../../definitions/SimpleXdmDefinitionBuilder';

export class MessageSimpleXdmDefinitionBuilder implements SimpleXdmDefinitionBuilder {

  messageProvider: MessageProvider;

  constructor(messageProvider: MessageProvider) {
      if (!messageProvider) {
          throw new Error('messageProvider is not defined');
      }
      this.messageProvider = messageProvider;
  }

  buildSimpleXdmDefinition(): SimpleXdmDefinition {
    return {
      // This is not implemented yet. The implication of this is that messages must use the
      // module definition defined in the atlassian-connect-js repo which switches between the AUI
      // implementation and module provider implementation.
    };
  }

}
