import {
  ParticipantStateTypes,
  PullRequestParticipant,
  User,
} from 'src/components/types';

const REVIEWER_ROLE = 'REVIEWER';

export const isReviewer = (participant: PullRequestParticipant) =>
  participant.role === REVIEWER_ROLE ||
  participant.approved ||
  participant.state === ParticipantStateTypes.changesRequested ||
  participant.state === ParticipantStateTypes.approved;

export const isNotAuthor = (
  participant: PullRequestParticipant,
  author: User | null | undefined
) => !author || !participant.user || participant.user.uuid !== author.uuid;
