import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
export var encodeParamsToUrl = function encodeParamsToUrl(baseUrl, params) {
  var url = new URL(baseUrl);
  var searchParams = new URLSearchParams();
  for (var _i = 0, _Object$entries = Object.entries(params); _i < _Object$entries.length; _i++) {
    var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
      key = _Object$entries$_i[0],
      value = _Object$entries$_i[1];
    searchParams.append(key, String(value));
  }
  url.search = searchParams.toString();
  return url.toString();
};