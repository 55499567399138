import { DEFAULT_HEARTBEAT_ENDPOINT, DEFAULT_IDLE_TIME } from '../../constants';
import { useHeartbeatOnInterval } from '../../controllers/use-heartbeat-on-interval';
var Heartbeat = function Heartbeat(_ref) {
  var _ref$endpoint = _ref.endpoint,
    endpoint = _ref$endpoint === void 0 ? DEFAULT_HEARTBEAT_ENDPOINT : _ref$endpoint,
    _ref$idleTime = _ref.idleTime,
    idleTime = _ref$idleTime === void 0 ? DEFAULT_IDLE_TIME : _ref$idleTime,
    onAuthenticationFailed = _ref.onAuthenticationFailed,
    onBadRequest = _ref.onBadRequest;
  useHeartbeatOnInterval(endpoint, idleTime, onAuthenticationFailed, onBadRequest);
  return null;
};
export default Heartbeat;