import React, { useState } from 'react';

/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import Addon from '@atlaskit/icon/glyph/addon';
import { token } from '@atlaskit/tokens';

const IconImg = styled.img({
  height: token('space.300', '24px'),
  width: token('space.300', '24px'),
});

type ForgeModuleIconProps = {
  iconUrl?: string;
};

export function ForgeModuleIcon({
  iconUrl,
}: ForgeModuleIconProps): JSX.Element {
  const [customIcon, setCustomIcon] = useState(iconUrl);

  if (customIcon) {
    return (
      <IconImg
        src={customIcon}
        onError={() => setCustomIcon(undefined)}
        alt=""
        role="presentation"
      />
    );
  }

  return <Addon label="" size="medium" />;
}
