import React, { useMemo } from 'react';

import { useDispatch } from 'react-redux';

import Flag from '@atlaskit/flag';
import Warning from '@atlaskit/icon/glyph/warning';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import { toggleSettingsDialog } from 'src/redux/diff-settings';
import { ComponentFlagProps } from 'src/redux/flags/types';
import messages from 'src/sections/repository/sections/pull-request/components/diff-settings/i18n';

import { AppearanceTypes } from './simple/simple-flag';

export const DiffSettingsErrorFlag = React.memo((props: ComponentFlagProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { id, handleDismissed } = props;

  const actions = useMemo(
    () => [
      {
        content: intl.formatMessage(messages.errorFlagRetryButton),
        onClick: () => {
          dispatch(toggleSettingsDialog(true));
          handleDismissed(id);
        },
      },
    ],
    [dispatch, id, intl, handleDismissed]
  );

  return (
    <Flag
      actions={actions}
      appearance={AppearanceTypes.normal}
      description={intl.formatMessage(messages.errorFlagDescription)}
      icon={
        <Warning
          label=""
          primaryColor={token('color.icon.warning', colors.Y300)}
        />
      }
      title={intl.formatMessage(messages.errorFlagTitle)}
      {...props}
    />
  );
});
