import { createResource } from 'react-resource-router';

type Package = {
  name: string;
  version: string;
  url: string;
  license?: string;
  licenseFile?: string;
};

const fetchPackages = async (source: string) => {
  try {
    const response = await fetch(
      `https://bbc-object-storage--frontbucket.us-east-1.prod.public.atl-paas.net/licenses/${source}.json`
    );
    return response.json();
  } catch {
    // eslint-disable-next-line no-console
    console.error(`${source} licenses not found`);
    return [];
  }
};

export default createResource({
  type: 'global',
  getKey: () => 'attributions',
  maxAge: 30 * 1000,
  getData: async () => {
    const corePackages: Package[] = await fetchPackages('core');
    const frontbucketPackages: Package[] = await fetchPackages('frontbucket');
    // We want to show the latest version for each package. There may be a more
    // efficient way to do this but since we're only working with a few hundred
    // items we'll aim to make this as clear as possible

    const packageToVersions: Record<string, Package[]> = {};
    const addPackage = (pkg: Package) => {
      const packages = packageToVersions[pkg.name];
      if (!packages) {
        packageToVersions[pkg.name] = [pkg];
        return;
      }
      // if the license is different from any others, add it
      const packageWithMatchingLicense = packages.find(
        p => pkg.licenseFile === p.licenseFile
      );
      if (!packageWithMatchingLicense) {
        packages.push(pkg);
        // if any of the duplicates don't have a license, remove them
        if (packages.length > 1) {
          packageToVersions[pkg.name] = packages.filter(p => p.licenseFile);
        }
        return;
      }
      // if this version is older than the one we've already recorded, skip it
      if (packageWithMatchingLicense.version > pkg.version) {
        return;
      }
      // replace the older version with the newest version
      const index = packages.indexOf(packageWithMatchingLicense);
      packages.splice(index, 1, pkg);
    };

    corePackages.forEach(addPackage);
    frontbucketPackages.forEach(addPackage);

    const packageList = Object.values(packageToVersions).flatMap(p => p);
    packageList.sort((a, b) => a.name.localeCompare(b.name));
    return packageList;
  },
});
