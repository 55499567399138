const isNumeric = (value: string) =>
  !isNaN(parseFloat(value)) && isFinite(Number(value));

export default function formatUuid(
  uuidToEnCode = '' as string | number
): string {
  let uuid = uuidToEnCode;
  uuid = uuid.toString();
  if (isNumeric(uuid)) {
    return uuid;
  }
  uuid = decodeURI(uuid);
  if (!uuid.startsWith('{')) {
    uuid = `{${uuid}`;
  }
  if (!uuid.endsWith('}')) {
    uuid = `${uuid}}`;
  }
  return encodeURI(uuid);
}
