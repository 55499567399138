import React, { useContext, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { useResource } from 'react-resource-router';

import FeatureGates, {
  CustomAttributes,
  FeatureGateEnvironment,
  Identifiers,
} from '@atlaskit/feature-gate-js-client';
import {
  StatsigFeatureKeyType,
  FeatureProvider as BaseProvider,
  EnvironmentType,
  getUserIdentifier,
  FeatureFlagUser,
} from '@atlassian/bitbucket-features';
import { uncurlyUuid } from '@atlassian/bitkit-analytics';
import {
  FeatureGatesInitialization,
  FeatureGatesInitializationProps,
} from '@atlassian/feature-gates-react';

import { useAppData } from 'src/app/data';
import { FeatureKeys, StatsigKeys } from 'src/config/feature-flag-keys';
import { workspaceNavigationResource } from 'src/sections/workspace/workspace-navigation-resource';
import { getCurrentProjectUuid } from 'src/selectors/project-selectors';
import {
  getCurrentRepositoryProjectUuid,
  getCurrentRepositoryUuid,
  getCurrentRepositoryWorkspaceUUID,
} from 'src/selectors/repository-selectors';
import { getCurrentWorkspaceUUID } from 'src/selectors/workspace-selectors';
import { analyticsClient } from 'src/utils/analytics/client';
import { BbEnv, getBbEnv } from 'src/utils/bb-env';

export const envMap: { [bbEnv in BbEnv]: EnvironmentType } = {
  [BbEnv.Development]: EnvironmentType.DEV,
  [BbEnv.Test]: EnvironmentType.PROD, // Test is prod-like for application changes
  [BbEnv.Staging]: EnvironmentType.STAGING,
  [BbEnv.Integration]: EnvironmentType.STAGING,
  [BbEnv.Production]: EnvironmentType.PROD,
};

const uncurl = (uuid?: string | null) =>
  uncurlyUuid(uuid || '') || uuid || undefined;

/* eslint @typescript-eslint/ban-types: "warn" */
export const FeatureProvider: React.FC<{}> = ({ children }) => {
  // For now we need to avoid unmounting the child components due to a
  // bug with the AK router. This can be replaced with `useSelector`
  // once the router has been updated
  const { user } = useAppData();
  const [featureFlagUser, setFeatureFlagUser] = useState<FeatureFlagUser>(
    getUserIdentifier(user)
  );

  const workspaceResource = useResource(workspaceNavigationResource);

  const workspaceUUID = useSelector(getCurrentWorkspaceUUID);
  const projectUUID = useSelector(getCurrentProjectUuid);
  const repositoryUUID = useSelector(getCurrentRepositoryUuid);
  const repositoryProjectUUID = useSelector(getCurrentRepositoryProjectUuid);
  const repositoryWorkspaceUUID = useSelector(
    getCurrentRepositoryWorkspaceUUID
  );

  const workspaceUuid = uncurl(
    workspaceResource.data?.workspace.uuid ||
      repositoryWorkspaceUUID ||
      workspaceUUID
  );
  const projectUuid = uncurl(repositoryProjectUUID || projectUUID);
  const repositoryUuid = uncurl(repositoryUUID);

  const env = envMap[getBbEnv() || BbEnv.Development];
  const apiKey = FeatureKeys[env];

  useEffect(() => {
    setFeatureFlagUser(
      getUserIdentifier(
        user ? { ...user, workspaceUuid, projectUuid, repositoryUuid } : user
      )
    );
  }, [user, workspaceUuid, projectUuid, repositoryUuid]);

  return (
    <BaseProvider
      analyticsWebClient={analyticsClient()}
      apiKey={apiKey}
      featureFlagUser={featureFlagUser}
      options={{
        productKey: 'bitbucket',
        environment: env,
      }}
    >
      {children}
    </BaseProvider>
  );
};

export const statsigEnvMap: { [bbEnv in BbEnv]: FeatureGateEnvironment } = {
  [BbEnv.Development]: FeatureGateEnvironment.Development,
  [BbEnv.Test]: FeatureGateEnvironment.Production, // Test is prod-like for application changes
  [BbEnv.Staging]: FeatureGateEnvironment.Staging,
  [BbEnv.Integration]: FeatureGateEnvironment.Staging,
  [BbEnv.Production]: FeatureGateEnvironment.Production,
};

const onReadySubscribers = new Set<() => void>();

export const StatsigEvents = {
  onReady: (fn: () => void) => {
    onReadySubscribers.add(fn);
  },
  clearOnReady: (fn: () => void) => {
    onReadySubscribers.delete(fn);
  },
  broadcastOnReady: () => {
    onReadySubscribers.forEach(fn => fn());
  },
};

const statsigOptions = (function () {
  const env = statsigEnvMap[getBbEnv() || BbEnv.Development];
  const apiKey = StatsigKeys[env];

  return { apiKey, environment: env, targetApp: 'bitbucket-cloud_web' };
})();

export type StatsigPropsThatChange = Pick<
  FeatureGatesInitializationProps,
  'identifiers' | 'customAttributes'
>;

export const useFeatureIdsAndAttributes = () => {
  const { user } = useAppData();

  const workspaceUUID = useSelector(getCurrentWorkspaceUUID);
  const repositoryUUID = useSelector(getCurrentRepositoryUuid);
  const repositoryWorkspaceUUID = useSelector(
    getCurrentRepositoryWorkspaceUUID
  );

  const workspaceUuid = uncurl(repositoryWorkspaceUUID || workspaceUUID);

  const repositoryUuid = uncurl(repositoryUUID);

  const featureIdsAndAttributes: StatsigPropsThatChange = useMemo(() => {
    const customAttributes: CustomAttributes = {
      atlassian_staff: user?.isAtlassian ?? false,
      bitbucket_team: user?.isBitbucket ?? false,
    };
    const identifiers: Identifiers = {
      atlassianAccountId: user?.aaid ?? undefined,
      bitbucketWorkspaceId: workspaceUuid,
      bitbucketRepositoryId: repositoryUuid ?? undefined,
    };
    return { identifiers, customAttributes };
  }, [
    user?.aaid,
    user?.isBitbucket,
    user?.isAtlassian,
    workspaceUuid,
    repositoryUuid,
  ]);

  return featureIdsAndAttributes;
};

// This Statsig wrapper uses Atlassian's React features API just for handling
// the client lifecycle. It monitors the pieces of the redux store that we care
// about and causes the feature client to update when those pieces change. When
// the update occurs, an event is fired that updates any useStatsigGate hooks
// that are listening for that feature flag.
/* eslint @typescript-eslint/ban-types: "warn" */
export const StatsigFeatures: React.FC<{}> = () => {
  const featureIdsAndAttributes = useFeatureIdsAndAttributes();

  return (
    <FeatureGatesInitialization
      options={statsigOptions}
      onReady={StatsigEvents.broadcastOnReady}
      {...featureIdsAndAttributes}
    />
  );
};

export const StatsigGateContext = React.createContext<{
  featureGates: typeof FeatureGates;
  statsigEvents: typeof StatsigEvents;
}>({
  featureGates: FeatureGates,
  statsigEvents: StatsigEvents,
});

export function useStatsigGate(key: StatsigFeatureKeyType): boolean {
  const { featureGates, statsigEvents } = useContext(StatsigGateContext);
  const [flagValue, setFlagValue] = useState(featureGates.checkGate(key));

  useEffect(() => {
    const onReady = () => {
      setFlagValue(featureGates.checkGate(key));
    };

    statsigEvents.onReady(onReady);

    return () => {
      statsigEvents.clearOnReady(onReady);
    };
  }, [featureGates, key, statsigEvents]);

  return flagValue;
}
