import React from 'react';

import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { AutoDismissFlag } from '@atlaskit/flag';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import SimpleTag from '@atlaskit/tag/simple-tag';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { getDeleteBranchDialogSlice } from 'src/redux/branches';
import { BucketState } from 'src/types/state';

import messages from './delete-branch-success-flag.i18n';

type Props = {
  id: string;
  branchName: string;
  intl: InjectedIntl;
};

const SuccessFlag = ({ branchName, intl, ...otherProps }: Props) => (
  <AutoDismissFlag
    {...otherProps}
    icon={
      <SuccessIcon
        label="success"
        primaryColor={token('color.icon.success', colors.G300)}
      />
    }
    title={intl.formatMessage(messages.deleteBranchSuccessTitle)}
    description={
      <FormattedMessage
        {...messages.deleteBranchSuccessDescription}
        values={{ branchLabel: <SimpleTag text={branchName} /> }}
      />
    }
  />
);

const mapStateToProps = (state: BucketState) => {
  const {
    successFlag: { branchName },
  } = getDeleteBranchDialogSlice(state);

  return {
    branchName,
  };
};

export const DeleteBranchSuccessFlag = compose<any, any, any>(
  injectIntl,
  connect(mapStateToProps)
)(SuccessFlag);
