import { getExtensionAttrs } from '../../utils/extensions';
import { inlineExtensionWithMarks as inlineExtensionWithMarksFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name inlineExtension_node
 */

/**
 * @name inlineExtension_with_marks_node
 */

export var inlineExtension = inlineExtensionWithMarksFactory({
  parseDOM: [{
    tag: 'span[data-extension-type]',
    getAttrs: function getAttrs(domNode) {
      return getExtensionAttrs(domNode, true);
    }
  }],
  toDOM: function toDOM(node) {
    var attrs = {
      'data-extension-type': node.attrs.extensionType,
      'data-extension-key': node.attrs.extensionKey,
      'data-text': node.attrs.text,
      'data-parameters': JSON.stringify(node.attrs.parameters),
      'data-local-id:': node.attrs.localId,
      contenteditable: 'false'
    };
    return ['span', attrs];
  }
});