/* stylelint-disable a11y/media-prefers-reduced-motion */
/* stylelint-disable a11y/selector-pseudo-class-focus */
/* stylelint-disable block-no-empty */
/* stylelint-disable no-descending-specificity */

/* eslint frontbucket-patterns/no-new-emotion-styled: "warn" */
import styled from '@emotion/styled';

import * as colors from '@atlaskit/theme/colors';
import {} from '@atlaskit/theme/constants';
import { h400 } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';

import StatusIcon from '../components/status-icon';
import { MetricUsageStatusType } from '../constants';
import { Pipeline } from '../models/Pipeline';

import { Props as PipelineStatusProps } from './PipelineStatus/PipelineStatus';

const gridSize = token('space.100', '8px');

export const BreadcrumbsWrapper = styled.div({
  margin: `14px 0 ${token('space.negative.025', '-2px')} 0`,
});

export const PageTitleAlphaWrapper = styled.span({
  top: '-3px',
  left: '5px',
  position: 'relative',
  '& > div, & > div > div > div > span': {
    marginRight: '60px',
  },
});

export const PageTitleDialog = styled.div({
  display: 'block',
  width: '270px',
  h3: {
    fontWeight: 500,
    color: token('color.text', colors.N800),
    fontSize: '16px',
  },
  p: {
    fontWeight: 'normal',
    color: token('color.text', colors.N800),
    fontSize: '14px',
    lineHeight: '24px',
    span: {
      color: token('color.text.information', colors.B400),
    },
  },
});

export const PageTitleLozenge = styled.span({
  display: 'inline-flex',
  cursor: 'pointer',
  span: {
    pointerEvents: 'none',
  },
});

export const PageTitleDot = styled.span({
  fontSize: '10px',
  margin: '0 5px',
  position: 'relative',
  top: '-1px',
});

export const MessageWrapper = styled.div({
  position: 'relative',
});

export const MessageCloseButton = styled.div({
  position: 'absolute',
  top: token('space.100', '8px'),
  right: token('space.100', '8px'),
});

export const SettingsPageWrapperLoading = styled.div({
  width: '100%',
  animation: 'fadein 0.25s ease-out',
  textAlign: 'center',
  padding: '100px 0 100px !important',
});

export const WarningCardWrapper = styled.div({
  borderRadius: '3px',
  background: token('elevation.surface.raised', '#fff'),
  boxShadow: `0 0 1px ${token('elevation.shadow.raised', colors.N60A)}`,
  padding: `10px ${token('space.250', '20px')} 10px`,
  boxSizing: 'border-box',
  position: 'relative',
  color: token('color.text', colors.N800),
});

export const WarningCardHeader = styled.header({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '-3px',
  h5: {
    margin: 0,
    fontWeight: 600,
    textIndent: '4px',
  },
});

export const PipelineWarningWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  rowGap: token('space.100', '8px'),
  background: token('elevation.surface.sunken', colors.N20),
  boxSizing: 'border-box',
  padding: `${token('space.100', '8px')} ${token('space.100', '8px')} 0`,
});

export const WarningMessageWrapper = styled.p({
  paddingTop: '5px',
  margin: `0 0 ${token('space.100', '8px')}`,
  fontSize: '12px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const WarningMessageLinks = styled.div({
  textAlign: 'right',
});

export const MetricsUsageCard = styled.div<{
  usageStatus: MetricUsageStatusType;
}>(
  {
    display: 'flex',
    borderRadius: '3px',
    boxShadow: token('elevation.shadow.raised', `0 0 1px ${colors.N60A}`),
    minHeight: '71px',
    padding: '14px 0 14px',
    boxSizing: 'border-box',
    position: 'relative',
    pre: {
      display: 'inline',
    },
  },
  props =>
    props.usageStatus === MetricUsageStatusType.Error
      ? `background-color: ${token('color.background.danger', '#FFEBE6')}`
      : `background-color: ${token('color.background.warning', colors.Y50)}`
);

export const MetricsUsageSideIcon = styled.div({
  minWidth: '16px',
  top: '1px',
  position: 'relative',
  marginRight: token('space.050', '4px'),
  marginLeft: token('space.150', '12px'),
  userSelect: 'none',
});

export const PreviousRunMessageCard = styled.div({
  borderRadius: '3px',
  backgroundColor: token('color.background.information', colors.B50),
  boxShadow: token('elevation.shadow.raised', `0 0 1px ${colors.N60A}`),
  padding: '14px',
  boxSizing: 'border-box',
  position: 'relative',
});

export const PreviousRunMessageIcon = styled.div({
  minWidth: '16px',
  top: '1px',
  position: 'relative',
  marginRight: token('space.050', '4px'),
  marginLeft: token('space.150', '12px'),
  userSelect: 'none',
});

export const HaltedMessageWrapper = styled.div({
  position: 'relative',
});

export const HaltedMessageText = styled.p({
  paddingTop: '5px',
  margin: `0 0 ${token('space.100', '8px')}`,
  fontSize: '12px',
});

export const HaltedMessageLinks = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
});

export const ButtonOverlay = styled.div({
  zIndex: 1,
  width: '100%',
  height: '100%',
  position: 'absolute',
  cursor: 'not-allowed',
});

export const SingleActionButtonWrapper = styled.div({
  position: 'relative',
});

export const MultiActionButtonWrapper = styled.div({
  position: 'relative',
  button: {
    paddingRight: 0,
  },
  'button > span': {
    margin: 0,
  },
});

export const RerunButtonWrapper = styled.div<{
  pipeline: Pipeline;
}>({
  position: 'relative',
  "[data-role='droplistContent']": {
    backgroundColor: token('elevation.surface', colors.N0),
  },
  button: {
    paddingLeft: `${token('space.100', '8px')} !important`,
    '&, &:hover, &:active': {
      alignItems: 'center',
    },
  },
  'button:not(:active):hover': {
    backgroundColor: token('color.background.neutral.hovered', colors.N50A),
  },
  "button:not(:hover):not([aria-expanded='true'])": {
    backgroundColor: token('color.background.disabled', colors.N30A),
  },
  "button:not([aria-expanded='true'])": {
    color: `${token('color.text.inverse', colors.DN900)} !important`,
  },
  "button:not([aria-expanded='false']) span": {
    color: `${token('color.text.inverse', colors.DN900)} !important`,
  },
  "button:not([aria-expanded='false'])": {
    backgroundColor: token(
      'color.background.accent.gray.bolder.pressed',
      colors.N30A
    ),
  },
  'button[disabled]': {
    opacity: 0.4,
  },
  circle: {
    stroke: 'currentColor !important',
  },
});

export const RerunButtonOverlay = styled(ButtonOverlay)({});

export const PreviousRunsButton = styled.div({
  button: {
    paddingLeft: `${token('space.100', '8px')}`,
    '&, &:hover, &:active': {
      alignItems: 'center',
    },
  },
});

export const PipelineActionsWrapper = styled(RerunButtonWrapper)({});

export const PipelineStatusWrapper = styled.div<{
  pipeline?: PipelineStatusProps['pipeline'];
}>(
  {
    display: 'flex',
    alignItems: 'center',
    height: '50px',
    borderRadius: '3px 3px 0 0',
    color: token('color.text.inverse', '#fff'),
    boxSizing: 'border-box',
    padding: `0 ${token('space.150', '12px')} 0 ${token('space.200', '16px')}`,
    marginTop: '11px',
  },
  props =>
    props.pipeline
      ? `background: ${props.pipeline.statusColor}`
      : `background: ${token('color.background.input', colors.N20)}`
);

export const PipelineStatusBuildNumber = styled.div({
  fontSize: '18px',
  flex: '1 0 auto',
});

export const PipelineStatusIcon = styled(StatusIcon as any)({
  height: '24px',
  width: '24px',
  marginRight: token('space.200', '16px'),
  color: 'inherit !important',
  position: 'relative',
  display: 'block',
  svg: {
    width: '24px',
    height: '24px',
    margin: 0,
  },
}) as any;

export const PipelineMessagesWrapper = styled.div({
  width: '100%',
});

export const PipelineMessagesMessage = styled.div({
  width: '100%',
  marginTop: '10px',
  'br + button': {
    margin: '5px 0 -5px',
    display: 'block',
  },
});

export const PipelineMessagesBuildMinutesLink = styled.a({
  marginRight: token('space.250', '20px'),
});

export const UsageDialogWrapper = styled.div({
  width: '300px',
  whiteSpace: 'pre-wrap',
});

export const UsageHr = styled.hr({
  margin: `${token('space.200', '16px')} 0 0`,
  width: '99.5%',
  left: 0,
  position: 'absolute',
  border: `solid 1px ${token('color.border', colors.N50)}`,
});

export const UsageContainer = styled.div({
  padding: `${token('space.400', '32px')} 0 0 ${token('space.400', '32px')}`,
  textAlign: 'left',
});

export const UsageHeader = styled.h4(h400() as any, {
  svg: {
    '&:first-of-type': {
      position: 'absolute',
      marginLeft: `calc(${gridSize} * -4px)`,
      marginTop: `calc(${gridSize} * -0.5px)`,
    },
  },
});

export const UsageDescription = styled.div({
  padding: `${token('space.200', '16px')} 0`,
});

export const UsageWrapper = styled.div({
  textAlign: 'left',
  span: {
    color: token('color.text.subtlest', colors.N100),
    fontSize: '12px',
  },
});

export const UsageTitle = styled.h3({
  marginBottom: token('space.300', '24px'),
  color: 'inherit',
});

export const UsageLimitedInfo = styled.div({
  marginTop: '10px',
});

export const CachesWrapper = styled.div({
  textAlign: 'left',
  header: {
    display: 'flex',
    marginBottom: token('space.150', '12px'),
  },
});

export const CachesTitle = styled.h3({
  lineHeight: '32px',
  color: 'inherit',
  flex: '1 0 auto',
});

export const CachesDialogWrapper = styled.div({
  width: '553px',
  padding: `${token('space.200', '16px')} ${token('space.300', '24px')}`,
});

export const CachesList = styled.div({
  maxHeight: '448px',
  overflow: 'auto',
  width: '577px',
  "div[role='treegrid']": {
    paddingRight: '21px',
  },
  "div[role='row']": {
    border: 'none',
    textAlign: 'left',
    height: '41px',
    "div[role='gridcell']": {
      flexShrink: 0,
      span: {
        flexShrink: 0,
      },
    },
    '&:hover': {
      background: token('elevation.surface.hovered', colors.N20),
      button: {
        display: 'inline-flex',
      },
    },
  },
});

export const CachesName = styled.span({
  whiteSpace: 'nowrap',
  width: '200px',
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginLeft: '-13px',
});

export const CachesVersionsLabel = styled.div({
  whiteSpace: 'nowrap',
  marginLeft: '-35px',
});

export const CachesEmpty = styled.div({
  textAlign: 'center',
  padding: `${token('space.050', '4px')} 0`,
  svg: {
    position: 'relative',
  },
  h5: {
    color: token('color.text', colors.N800),
  },
});

export const CachesIconCell = styled.div({
  marginLeft: token('space.075', '6px'),
  '& > span': {
    lineHeight: '24px',
    color: token('color.icon', colors.N200),
  },
});

export const SmartCachesVersionWrapper = styled.div({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  paddingLeft: '34px',
  marginLeft: token('space.400', '32px'),
  whiteSpace: 'nowrap',
});

export const SmartCachesVersionName = styled.div({
  width: '216px',
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const SmartCachesFileSize = styled.div({
  width: '110px',
});

export const SmartCacheRemoveButton = styled.div({
  marginRight: '7px',
  marginLeft: 'auto',
  button: {
    display: 'none',
  },
});

export const CacheRemoveGroupButton = styled.div({
  marginLeft: '18px',
  button: {
    display: 'none',
  },
});
