import { createSelector } from 'reselect';

import { LoadGlobal } from 'src/redux/global/actions';
import {
  PIPELINE_SIDEBAR_EXPANDED_WIDTH,
  SIDEBAR_COLLAPSED_WIDTH,
  SIDEBAR_EXPANDED_WIDTH,
} from 'src/sections/global/constants';
import { Action, BucketState } from 'src/types/state';

import { TOGGLE_SIDEBAR } from './actions';
import { SidebarType } from './types';

export type SidebarState = {
  [K in SidebarType]: {
    isOpen: boolean;
    width: number;
    tabIndex?: number;
  };
};

// Selectors

export const getCodeReviewSidebarWidth = createSelector(
  (state: BucketState) => state.sidebar['code-review'],
  sidebarState => sidebarState.width
);

export const getBranchSidebarWidth = createSelector(
  (state: BucketState) => state.sidebar.branch,
  sidebarState => sidebarState.width
);

export const getCommitSidebarWidth = createSelector(
  (state: BucketState) => state.sidebar.commit,
  sidebarState => sidebarState.width
);

export const getCreatePRSidebarWidth = createSelector(
  (state: BucketState) => state.sidebar['create-pr'],
  sidebarState => sidebarState.width
);

export const getSourceSidebarWidth = createSelector(
  (state: BucketState) => state.sidebar.source,
  sidebarState => sidebarState.width
);

export const getPipelinesListSidebarWidth = createSelector(
  (state: BucketState) => state.sidebar['pipeline-list'],
  sidebarState => sidebarState.width
);

export const isCodeReviewSidebarOpen = createSelector(
  (state: BucketState) => state.sidebar['code-review'],
  sidebarState => sidebarState.isOpen
);

export const isBranchSidebarOpen = createSelector(
  (state: BucketState) => state.sidebar.branch,
  sidebarState => sidebarState.isOpen
);

export const isCommitSidebarOpen = createSelector(
  (state: BucketState) => state.sidebar.commit,
  sidebarState => sidebarState.isOpen
);

export const isCreatePRSidebarOpen = createSelector(
  (state: BucketState) => state.sidebar['create-pr'],
  sidebarState => sidebarState.isOpen
);

export const isSourceSidebarOpen = createSelector(
  (state: BucketState) => state.sidebar.source,
  sidebarState => sidebarState.isOpen
);

export const isPipelineListSidebarOpen = createSelector(
  (state: BucketState) => state.sidebar['pipeline-list'],
  sidebarState => sidebarState.isOpen
);

export const isSidebarOpen = createSelector(
  (state: BucketState, sidebarType: SidebarType) => state.sidebar[sidebarType],
  sidebarState => sidebarState.isOpen
);

export const getSidebarWidth = createSelector(
  (state: BucketState, sidebarType: SidebarType) => state.sidebar[sidebarType],
  sidebarState => sidebarState.width
);

export const getCodeReviewSidebarTabIndex = createSelector(
  (state: BucketState) => state.sidebar['code-review'],
  sidebarState => sidebarState?.tabIndex || 0
);

export const getBranchSidebarTabIndex = createSelector(
  (state: BucketState) => state.sidebar.branch,
  sidebarState => sidebarState?.tabIndex || 0
);

export const getCommitSidebarTabIndex = createSelector(
  (state: BucketState) => state.sidebar.commit,
  sidebarState => sidebarState?.tabIndex || 0
);

export const getCreatePRSidebarTabIndex = createSelector(
  (state: BucketState) => state.sidebar['create-pr'],
  sidebarState => sidebarState?.tabIndex || 0
);
// Reducer

const initialState: SidebarState = {
  'code-review': {
    isOpen: true,
    width: SIDEBAR_EXPANDED_WIDTH,
    tabIndex: 0,
  },
  branch: {
    isOpen: false,
    width: SIDEBAR_COLLAPSED_WIDTH,
    tabIndex: 0,
  },
  commit: {
    isOpen: false,
    width: SIDEBAR_COLLAPSED_WIDTH,
    tabIndex: 0,
  },
  'create-pr': {
    isOpen: true,
    width: SIDEBAR_EXPANDED_WIDTH,
    tabIndex: 0,
  },
  source: {
    isOpen: false,
    width: SIDEBAR_COLLAPSED_WIDTH,
  },
  'pipeline-list': {
    isOpen: true,
    width: PIPELINE_SIDEBAR_EXPANDED_WIDTH,
  },
};

const parseSidebarWidth = (width: string) => {
  const intWidth = parseInt(width, 10);
  if (isNaN(intWidth)) {
    return SIDEBAR_EXPANDED_WIDTH;
  }
  return intWidth;
};

export default (
  state: SidebarState = initialState,
  action: Action
): SidebarState => {
  switch (action.type) {
    case TOGGLE_SIDEBAR: {
      const {
        isOpen,
        sidebarType,
        width: newWidth,
        tabIndex: newTabIndex,
      } = action.payload;
      // @ts-ignore TODO: fix noImplicitAny error here
      const sidebarState = state[sidebarType];
      if (!sidebarState) {
        return state;
      }

      const newIsOpen = isOpen !== undefined ? isOpen : !sidebarState.isOpen;
      if (
        sidebarState.isOpen === newIsOpen &&
        sidebarState.width === newWidth
      ) {
        return state;
      }

      const newSidebarState = { ...sidebarState, isOpen: newIsOpen };

      if (newIsOpen && newWidth) {
        newSidebarState.width = newWidth;
      }

      if (newIsOpen && newTabIndex !== undefined) {
        newSidebarState.tabIndex = newTabIndex;
      }

      return {
        ...state,
        [sidebarType]: newSidebarState,
      };
    }

    case LoadGlobal.SUCCESS: {
      const { result } = action.payload;
      return {
        ...state,
        'code-review': {
          ...state['code-review'],
          isOpen: result.isCodeReviewSidebarOpen,
          width: parseSidebarWidth(result.codeReviewSidebarWidth),
          tabIndex: result.getCodeReviewSidebarTabIndex,
        },
        branch: {
          ...state.branch,
          isOpen: result.isBranchSidebarOpen,
          width: parseSidebarWidth(result.branchSidebarWidth),
        },
        commit: {
          ...state.commit,
          isOpen: result.isCommitSidebarOpen,
          width: parseSidebarWidth(result.commitSidebarWidth),
        },
        'create-pr': {
          ...state['create-pr'],
          isOpen: result.isCreatePRSidebarOpen,
          width: parseSidebarWidth(result.createPRSidebarWidth),
          tabIndex: result.getCreatePRSidebarTabIndex,
        },
        source: {
          ...state.source,
          isOpen: result.isSourceBrowserSidebarOpen,
          width: parseSidebarWidth(result.sourceBrowserSidebarWidth),
        },
        'pipeline-list': {
          ...state['pipeline-list'],
          isOpen: result.isPipelineListSidebarOpen,
          width: parseSidebarWidth(result.pipelineListSidebarWidth),
        },
      };
    }

    default:
      return state;
  }
};
