var defaultAllowOverrideBehaviour = {
  canOverrideUnsupportedMark: function canOverrideUnsupportedMark() {
    return true;
  }
};
var defaultDoNotAllowOverrideBehaviour = {
  canOverrideUnsupportedMark: function canOverrideUnsupportedMark() {
    return false;
  }
};
export var markOverrideRuleFor = function markOverrideRuleFor(type) {
  switch (type) {
    case 'link':
    case 'em':
    case 'strong':
    case 'strike':
    case 'subsup':
    case 'underline':
    case 'code':
    case 'textColor':
    case 'backgroundColor':
    case 'confluenceInlineComment':
    case 'breakout':
    case 'alignment':
    case 'indentation':
    case 'border':
      return defaultAllowOverrideBehaviour;
    default:
      return defaultDoNotAllowOverrideBehaviour;
  }
};