import React from 'react';

import { defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';

import Button from '@atlaskit/button/standard-button';
import { Checkbox } from '@atlaskit/checkbox';
import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel';
import Tooltip from '@atlaskit/tooltip';

import { useAnalytics } from 'src/components/settings/analytics';
import { Privilege } from 'src/components/types';
import { useIntl } from 'src/hooks/intl';
import { getCurrentUser } from 'src/selectors/user-selectors';

import { useIsSumEnabled } from '../permissions';
import PrivilegesDropdown from '../privileges-dropdown';
import { getPermissionName } from '../privileges-dropdown/utils';

import { AvatarItem } from './avatar-item';
import { LoadingState } from './constants';
import {
  useActions,
  useActivePermission,
  useAdminLevel,
  useLoadingState,
  usePermissionsContext,
  useUpdateAccess,
} from './provider';
import sharedMessages from './shared.i18n';
import { PrivilegesDropdownText, PrivilegeWithIcon } from './styled';
import { Permission, PermissionsAPIProps, PermissionsState } from './types';
import {
  getPermissionId,
  includesPermission,
  showRemoveOwnAccessWarning,
} from './utils';

const messages = defineMessages({
  nameHeader: {
    id: 'frontbucket.settings.permissions.table.nameHeader',
    description: 'Table header for Name column',
    defaultMessage: 'Name',
  },
  permissionHeader: {
    id: 'frontbucket.settings.permissions.table.permissionHeader',
    description: 'Table header for Permission column',
    defaultMessage: 'Permission',
  },
  actionsHeader: {
    id: 'frontbucket.settings.permissions.table.actionsHeader',
    description: 'Table header for Actions column',
    defaultMessage: 'Actions',
  },
  remove: {
    id: 'frontbucket.settings.permissions.remove',
    description: 'Remove permission from the list button label',
    defaultMessage: 'Remove',
  },
  noAccessProjectPermission: {
    id: 'frontbucket.settings.permissions.noAccessProjectPermission',
    description:
      'Tooltip message for when user is unable to edit a project permission',
    defaultMessage: 'This permission is inherited from the project.',
  },
  noAccessWorkspacePermission: {
    id: 'frontbucket.settings.permissions.noAccessWorkspacePermission',
    description:
      'Tooltip message for when user is unable to edit a workspace permission',
    defaultMessage: 'This permission is inherited from the workspace.',
  },
  noAccessWorkspacePermissionOrgLevel: {
    id: 'frontbucket.settings.permissions.noAccessWorkspacePermissionOrgLevel',
    description:
      'Tooltip message for when user is unable to edit a workspace permission',
    defaultMessage: 'This permission is managed in Atlassian Administration.',
  },
  createPermissionInfoLabel: {
    id: 'frontbucket.settings.permissions.createPermissionInfoLabel',
    description: 'Create permission info label',
    defaultMessage: 'create permission details',
  },
  createRepoPermissionDescription: {
    id: 'frontbucket.settings.permissions.createRepoPermissionInfo',
    description: 'Create repo permission description',
    defaultMessage: `Can create repositories and are assigned Admin access to those repositories. All activities permitted by the "Write" permission are granted as well.`,
  },
  createProjectPermissionDescription: {
    id: 'frontbucket.settings.permissions.createProjectPermissionInfo',
    description: 'Create project permission description',
    defaultMessage: `Can create projects and are assigned Admin access to those created projects. All activities permitted by the "Write" permission are allowed as well.`,
  },
});

type Props = PermissionsAPIProps & {
  permissions: Permission[];
  workspaceSlug?: string;
  showAccessLevel: boolean;
} & Pick<PermissionsState, 'checkedRows'>;

export const useTableRows = ({
  api,
  permissions,
  checkedRows,
  workspaceSlug,
  showAccessLevel,
}: Props) => {
  const [isSumEnabled] = useIsSumEnabled();
  const { formatMessage } = useIntl();
  const { publishButtonClickEvent } = useAnalytics();
  const [{ loadingState }] = useLoadingState();
  const [{ activePermission }] = useActivePermission();
  const [{ isWorkspaceAdmin }] = useAdminLevel();
  const [{ context }] = usePermissionsContext();
  const { setIsChecked, setActiveModal, isEditable } = useActions();
  const updateAccess = useUpdateAccess(api);
  const currentUser = useSelector(getCurrentUser);
  function getNoAccessWorkspacePermissionMessage() {
    return isSumEnabled
      ? messages.noAccessWorkspacePermissionOrgLevel
      : messages.noAccessWorkspacePermission;
  }
  return permissions.map(row => {
    const { id, name, level } = row;
    const canEdit = isEditable({ level, privilege: row.privilege });
    const key = getPermissionId(row);
    return {
      key,
      cells: [
        {
          key: `${key}-checkbox`,
          content: (
            <Checkbox
              onChange={() => setIsChecked(row)}
              isChecked={includesPermission(checkedRows, row)}
              isDisabled={!canEdit}
              testId={`${key}-checkbox`}
            />
          ),
        },
        {
          key: `${key}-name-${name.toLocaleLowerCase()}`,
          content: (
            <AvatarItem
              {...row}
              isWorkspaceAdmin={isWorkspaceAdmin}
              workspaceSlug={workspaceSlug}
            />
          ),
        },
        {
          key: `${key}-permission`,
          content:
            canEdit && context !== 'workspace' ? (
              <PrivilegesDropdown
                isDisabled={
                  activePermission === row &&
                  loadingState === LoadingState.PRIVILEGE_UPDATING
                }
                value={row.privilege}
                onChange={(privilege: Privilege) => {
                  if (
                    showRemoveOwnAccessWarning(
                      permissions,
                      row.mode,
                      id,
                      currentUser
                    )
                  ) {
                    setActiveModal('RemoveOwnAccessWarning', [row, privilege]);
                  } else {
                    updateAccess(privilege, row);
                  }
                  publishButtonClickEvent('PrivilegeDropdown', {
                    privilege,
                  });
                }}
                context={context}
              />
            ) : (
              (() => {
                let privilege;
                if (
                  row.level === 'workspace' &&
                  row.privilege === 'create-project'
                ) {
                  // no tooltip while hovering permission name for create-project
                  privilege = (
                    <PrivilegesDropdownText>
                      {formatMessage(getPermissionName(row.privilege))}
                    </PrivilegesDropdownText>
                  );
                } else {
                  // including check to see if workspace is sum-enabled or not
                  privilege = (
                    <Tooltip
                      testId="checksum-tooltip"
                      content={formatMessage(
                        row.level === 'workspace'
                          ? getNoAccessWorkspacePermissionMessage()
                          : messages.noAccessProjectPermission
                      )}
                      position="right"
                    >
                      <PrivilegesDropdownText>
                        {formatMessage(getPermissionName(row.privilege))}
                      </PrivilegesDropdownText>
                    </Tooltip>
                  );
                }
                if (
                  row.privilege === Privilege['create-repo'] ||
                  row.privilege === Privilege['create-project']
                ) {
                  // info icon tooltip for create-repo and create-project permissions
                  return (
                    <PrivilegeWithIcon>
                      {privilege}
                      <Tooltip
                        content={formatMessage(
                          row.privilege === Privilege['create-repo']
                            ? messages.createRepoPermissionDescription
                            : messages.createProjectPermissionDescription
                        )}
                        position="right"
                      >
                        <EditorPanelIcon
                          label={formatMessage(
                            messages.createPermissionInfoLabel
                          )}
                        />
                      </Tooltip>
                    </PrivilegeWithIcon>
                  );
                }
                return privilege;
              })()
            ),
        },
        ...(showAccessLevel
          ? [
              {
                key: `${key}-access-level`,
                content: formatMessage(sharedMessages[level]),
              },
            ]
          : []),
        {
          key: `${key}-actions`,
          content: canEdit && (
            <Button
              appearance="link"
              onClick={() => {
                setActiveModal('RemoveAccessModal', row);
                publishButtonClickEvent('RemoveAction');
              }}
              testId={`${key}-remove-btn`}
            >
              {formatMessage(messages.remove)}
            </Button>
          ),
        },
      ],
    };
  });
};
