import { ROUTE_NAME, SupportedPath } from '../types';

export const getSupportedPaths = (): SupportedPath[] => {
  const paths: SupportedPath[] = [
    {
      name: ROUTE_NAME.PROJECT_SETTINGS_ACCESS_KEYS,
      path: '/:workspaceSlug/workspace/projects/:projectKey/settings/access-keys',
      exact: true,
    },
    {
      name: ROUTE_NAME.PROJECT_REPOSITORIES,
      path: '/:workspaceSlug/workspace/projects/:projectKey',
      exact: true,
    },
    {
      name: ROUTE_NAME.PROJECT_SETTINGS_DETAILS,
      path: '/:workspaceSlug/workspace/projects/:projectKey/settings/',
      exact: true,
    },
    {
      name: ROUTE_NAME.PROJECT_SETTINGS_DEFAULT_REVIEWERS,
      path: '/:workspaceSlug/workspace/projects/:projectKey/settings/pullrequests/reviewers',
      exact: false,
    },
    {
      name: ROUTE_NAME.PROJECT_SETTINGS_BRANCHING_MODEL,
      path: '/:workspaceSlug/workspace/projects/:projectKey/settings/branching-model',
      exact: false,
    },
    {
      name: ROUTE_NAME.PROJECT_SETTINGS_MERGE_STRATEGIES,
      path: '/:workspaceSlug/workspace/projects/:projectKey/settings/merge-strategies',
      exact: false,
    },
    {
      name: ROUTE_NAME.PROJECT_SETTINGS_ACCESS_TOKENS,
      path: '/:workspaceSlug/workspace/projects/:projectKey/settings/access-tokens',
      exact: true,
    },
    {
      name: ROUTE_NAME.PROJECT_SETTINGS_CUSTOM_MERGE_CHECKS,
      path: '/:workspaceSlug/workspace/projects/:projectKey/settings/merge-checks',
      exact: true,
    },
  ];
  paths.push({
    name: ROUTE_NAME.PROJECT_SETTINGS_PERMISSIONS,
    path: '/:workspaceSlug/workspace/projects/:projectKey/settings/permissions',
    exact: true,
  });
  paths.push({
    name: ROUTE_NAME.PROJECT_SETTINGS_BRANCH_RESTRICTIONS,
    path: '/:workspaceSlug/workspace/projects/:projectKey/settings/branch-restrictions',
    exact: false,
  });

  return paths;
};
