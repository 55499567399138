import { acNameToEmoji, acShortcutToEmoji } from '../../utils/confluence/emoji';
import { emoji as emojiFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name emoji_node
 */

export var emoji = emojiFactory({
  parseDOM: [
  // Handle copy/paste beautiful panel from renderer />
  {
    tag: 'div.ak-editor-panel__icon span',
    ignore: true
  }, {
    tag: 'span[data-emoji-short-name]',
    getAttrs: function getAttrs(domNode) {
      var dom = domNode;
      return {
        shortName: dom.getAttribute('data-emoji-short-name') || emoji.attrs.shortName.default,
        id: dom.getAttribute('data-emoji-id') || emoji.attrs.id.default,
        text: dom.getAttribute('data-emoji-text') || emoji.attrs.text.default
      };
    }
  },
  // Handle copy/paste from old <ac:emoticon />
  {
    tag: 'img[data-emoticon-name]',
    getAttrs: function getAttrs(dom) {
      return acNameToEmoji(dom.getAttribute('data-emoticon-name'));
    }
  },
  // Handle copy/paste from old <ac:hipchat-emoticons />
  {
    tag: 'img[data-hipchat-emoticon]',
    getAttrs: function getAttrs(dom) {
      return acShortcutToEmoji(dom.getAttribute('data-hipchat-emoticon'));
    }
  },
  // Handle copy/paste from bitbucket's <img class="emoji" />
  {
    tag: 'img.emoji[data-emoji-short-name]',
    getAttrs: function getAttrs(domNode) {
      var dom = domNode;
      return {
        shortName: dom.getAttribute('data-emoji-short-name') || emoji.attrs.shortName.default,
        id: dom.getAttribute('data-emoji-id') || emoji.attrs.id.default,
        text: dom.getAttribute('data-emoji-text') || emoji.attrs.text.default
      };
    }
  }],
  toDOM: function toDOM(node) {
    var _node$attrs = node.attrs,
      shortName = _node$attrs.shortName,
      id = _node$attrs.id,
      text = _node$attrs.text;
    var attrs = {
      'data-emoji-short-name': shortName,
      'data-emoji-id': id,
      'data-emoji-text': text,
      contenteditable: 'false'
    };
    return ['span', attrs, text];
  }
});