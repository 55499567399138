import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

const headerStyles = xcss({
  font: 'font.heading.xsmall',
  fontWeight: 'font.weight.bold',
  color: 'color.text',
});

type TableHeaderProps = {
  children: React.ReactNode;
};

/* eslint @typescript-eslint/ban-types: "warn" */
export const TestTableHeader: React.FC<TableHeaderProps> = ({ children }) => (
  <Box xcss={headerStyles}>{children}</Box>
);
