import { makeTraceHttpRequestHeaders } from './utils/make-trace-http-request-headers';
var state = {
  context: null
};
export var setActiveTrace = function setActiveTrace(traceId, spanId, type) {
  state.context = {
    traceId: traceId,
    spanId: spanId,
    type: type
  };
};
export var getActiveTrace = function getActiveTrace() {
  return state.context || undefined;
};
export var clearActiveTrace = function clearActiveTrace() {
  state.context = null;
};
export var getActiveTraceHttpRequestHeaders = function getActiveTraceHttpRequestHeaders(_url) {
  if (state.context === null) {
    return null;
  }
  var _state$context = state.context,
    traceId = _state$context.traceId,
    spanId = _state$context.spanId;
  return makeTraceHttpRequestHeaders(traceId, spanId);
};