import { createResource } from 'react-resource-router';

import {
  buildBBQL,
  GetPermissionsOptions,
  InheritedPrivilegesResponse,
  mapInheritedUserAndGroupPrivilege,
  PermissionsState,
} from 'src/components/settings/permissions';
import urls from 'src/sections/repository/urls';
import { request } from 'src/utils/http-request';

export const fetchPermissions = (
  workspaceSlug: string,
  repositorySlug: string,
  {
    page = 1,
    accessLevel,
    permission,
    principalName,
  }: GetPermissionsOptions = {}
) =>
  request<Pick<PermissionsState, 'permissions' | 'page' | 'limit' | 'total'>>(
    urls.api.internal.inheritedPermissions(workspaceSlug, repositorySlug, {
      page,
      q: buildBBQL({ accessLevel, permission, principalName }),
    }),
    {
      successTransformer: ({
        values = [],
        page: pagenum,
        pagelen,
        size,
      }: InheritedPrivilegesResponse) => {
        return {
          permissions: new Map([
            [pagenum, values.map(mapInheritedUserAndGroupPrivilege)],
          ]),
          page: pagenum,
          limit: pagelen,
          total: size,
        };
      },
    }
  );

export const loadRepositorySettingsPermissionsResource = createResource({
  type: 'repoAdminPermissions',
  getKey: ({ match }) => {
    const { repositoryOwner, repositorySlug } = match.params;
    return urls.ui.adminPage(repositoryOwner!, repositorySlug!, 'permissions');
  },
  maxAge: 60000, // cache for 1 minute so navigating between pages is fast
  getData: async ({ match }) => {
    const { repositoryOwner, repositorySlug } = match.params;
    const permissions = await fetchPermissions(
      repositoryOwner!,
      repositorySlug!
    );
    // If fetch is successful, set initial access level to repository
    return { ...permissions, adminLevel: 'repository' } as Pick<
      PermissionsState,
      'permissions' | 'page' | 'limit' | 'total' | 'adminLevel'
    >;
  },
});
