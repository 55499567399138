/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { call, all, put, select, takeLatest } from 'redux-saga/effects';

import urls from 'src/sections/repository/urls';
import {
  getCurrentRepositoryOwnerName,
  getCurrentRepositorySlug,
} from 'src/selectors/repository-selectors';
import authRequest from 'src/utils/fetch';

import {
  UPDATE_SOURCE_BRANCH_HASH,
  getBranchViewSourceBranchName,
} from '../branch-redux';

export default function* () {
  yield all([
    takeLatest(UPDATE_SOURCE_BRANCH_HASH.REQUEST, fetchSourceBranchInfoSaga),
  ]);
}

export function* fetchSourceBranchInfoSaga() {
  // make the call to the branches endpoint and return the target value
  try {
    const owner: string = yield select(getCurrentRepositoryOwnerName);
    const slug: string = yield select(getCurrentRepositorySlug);
    const branchName: string = yield select(getBranchViewSourceBranchName);
    const url = urls.api.v20.branch(owner, slug, branchName);

    const request = authRequest(url);
    const response: Response = yield call(fetch, request);

    if (response.ok) {
      // @ts-ignore
      const data = yield response.json();
      yield put({
        type: UPDATE_SOURCE_BRANCH_HASH.SUCCESS,
        payload: {
          sourceBranchHash: data.target.hash,
          targetLinksSelf: data.target.links.self,
          targetLinksHtml: data.target.links.html,
        },
      });
    } else {
      // silently failing if API call does not succeed
    }
  } catch (e) {
    // COREX-4845: Add error handling logic (TO-DO)
  }
}
