import { createResource } from 'react-resource-router';

import { AccessKeysResponse } from 'src/components/settings/access-keys';
import { DEFAULT_ROWS_PER_PAGE } from 'src/constants/settings';
import urls from 'src/urls/projects';
import { httpRequest } from 'src/utils/http-request';

type FetchSshKeysOptions = { page?: number; pagelen?: number; sort?: string };

export const fetchSshKeys =
  (workspaceSlug: string, projectKey: string) =>
  (options?: FetchSshKeysOptions) =>
    httpRequest<AccessKeysResponse>().get(
      urls.api.v20.deployKeys(workspaceSlug, projectKey, {
        ...options,
      })
    );

export const loadProjectSettingsAccessKeysResource = createResource({
  type: 'projectAdminAccessKeys',
  getKey: ({ match }) => {
    const { workspaceSlug, projectKey } = match.params;
    return urls.ui.settings(workspaceSlug!, projectKey!, 'access-keys');
  },
  maxAge: 60000, // cache for 1 minute so navigating between pages is fast
  getData: ({ match }) => {
    const { workspaceSlug, projectKey } = match.params;
    if (!workspaceSlug || !projectKey) {
      throw new Error(
        'Could not fetch, workspaceSlug/projectKey URL param was not available'
      );
    }
    const { pagelen = DEFAULT_ROWS_PER_PAGE, page = 1, sort } = match.query;
    return fetchSshKeys(
      workspaceSlug,
      projectKey
    )({
      pagelen: ~~pagelen,
      page: ~~page,
      sort,
    });
  },
});
