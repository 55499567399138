/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { select, put } from 'redux-saga/effects';

import { getGlobalIsSingleFileModeEnabled } from 'src/redux/diff-settings/index';
import { TOGGLE_SINGLE_FILE_MODE } from 'src/redux/pull-request/actions';

import { getIsSingleFileModeEligible } from '../selectors';

export function* globalSingleFileModeEnabledSaga() {
  const isGlobalSingleFileModeEnabled: boolean = yield select(
    getGlobalIsSingleFileModeEnabled
  );
  const isSingleFiledModeEligible: boolean = yield select(
    getIsSingleFileModeEligible
  );
  // only change mode for small PRs
  if (!isSingleFiledModeEligible) {
    yield put({
      type: TOGGLE_SINGLE_FILE_MODE,
      payload: isGlobalSingleFileModeEnabled,
    });
  }
}
