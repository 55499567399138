/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { call, select, take } from 'redux-saga/effects';

import { User } from 'src/components/types';
import { ADD_RECENTLY_VIEWED_WORKSPACE } from 'src/redux/workspaces/actions/constants';
import { getCurrentUser } from 'src/selectors/user-selectors';
import store from 'src/utils/recently-viewed-workspaces-store';

function* cacheRecentlyViewedWorkspace() {
  while (true) {
    // @ts-ignore
    const action = yield take(ADD_RECENTLY_VIEWED_WORKSPACE);
    const workspaceUuid = action.payload.result;

    const currentUser: User = yield select(getCurrentUser);

    if (!currentUser) {
      continue;
    }
    if (workspaceUuid) {
      yield call(store.add, currentUser, workspaceUuid);
    }
  }
}

export default cacheRecentlyViewedWorkspace;
