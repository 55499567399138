import { createResource } from 'react-resource-router';

import {
  hydrateAction as hydrateActionCreator,
  FetchAction,
} from 'src/redux/actions';
import { user } from 'src/sections/profile/schemas';
import { Action, Thunk } from 'src/types/state';

import { getHydrateAction } from '../../../middleware/hydrate';
import { ResourceContext } from '../../../router/types';
import { LoadGlobal } from '../actions';
import { stateKey, url } from '../actions/load-global';

/**
 * Resource for the load-global action.
 *
 * @see src/redux/global/actions/load-global.ts
 */
export const loadGlobalResource = createResource({
  type: 'global',
  getKey: () => 'global',
  maxAge: Infinity,
  getData: async (
    _routerStoreContext,
    { reduxStore, csrftoken }: ResourceContext
  ) => {
    const { dispatch } = reduxStore as {
      dispatch: (x: Action | Thunk) => Action<FetchAction> | null;
    };
    const action = hydrateActionCreator(LoadGlobal, stateKey, {
      url,
      isRouterResource: true,
      csrftoken,
      schema: {
        currentUser: user,
        targetUser: user,
      },
    });

    // check the initialstate before requesting anything
    const hydrateAction = getHydrateAction(action, stateKey);

    const result = await dispatch(hydrateAction);

    if (result?.type === LoadGlobal.ERROR) {
      return null;
    }

    return { status: 'success' };
  },
});
