import { createResource } from 'react-resource-router';

import {
  fetchAction as fetchActionCreator,
  FetchAction,
  fetchData,
} from 'src/redux/actions';
import { mergeCheckPayloadTransformer } from 'src/redux/pull-request/sagas/utils/merge-check-payload-transformer';
import { hasTransitioned } from 'src/router/history';
import { ResourceContext } from 'src/router/types';
import { FetchSourceRepositoryDetails } from 'src/sections/repository/actions';
import repositoryUrls from 'src/sections/repository/urls';
import { BucketState, Dispatch, Action, Thunk } from 'src/types/state';

import { LOAD_PULL_REQUEST, FETCH_MERGE_CHECKS } from '../actions';
import { fetchSourceRepositoryDetailsQuery } from '../actions/fetch-source-repository-details';
import { makeBackbucketSafeNow } from '../sagas/fetch-pull-request-saga';
import {
  commit as commitSchema,
  pullRequest as pullRequestSchema,
} from '../schemas';
import {
  getPullRequestDestinationRepo,
  getPullRequestSourceRepo,
} from '../selectors';
import urls from '../urls';

export const pullRequestActionStateKey = 'repository.pullRequest';
// Either isDiffsLoading or isDiffStatLoading is true, the spinner would be displayed.
// But the page is going to fetch both of these api, so just set both of them true.
export const enableDiffLoadingStatus = {
  isDiffsLoading: true,
  isDiffStatLoading: true,
};
export const loadPullRequestPayloadTransformer = (payload: any) => {
  if (!hasTransitioned()) {
    return {
      ...payload,
      lastPoll: makeBackbucketSafeNow(),
    };
  }
  return {
    ...payload,
    lastPoll: makeBackbucketSafeNow(),
  };
};

const loadPullRequest = async (
  owner: string,
  slug: string,
  id: string,
  { reduxStore, csrftoken }: Pick<ResourceContext, 'reduxStore' | 'csrftoken'>
) => {
  const { dispatch } = reduxStore as {
    dispatch: (x: Action | Thunk) => Action<FetchAction> | null;
  };

  const requestUrl = urls.api.v20.pullRequestLoad(owner, slug, Number(id));

  const resourceMetadata = {
    isRouterResource: true,
    url: requestUrl,
    csrftoken,
    schema: {
      currentPullRequest: pullRequestSchema,
      commits: [commitSchema],
    },
  };
  const action = fetchActionCreator(LOAD_PULL_REQUEST, resourceMetadata);
  // Fetch only currentPullRequest
  dispatch(action);
  const result = await dispatch(
    fetchData(action, payload => {
      return loadPullRequestPayloadTransformer({
        currentPullRequest: payload,
        defaultMergeStrategy: payload.destination.branch.default_merge_strategy,
        mergeInProgress: payload.merge_in_progress,
        ...enableDiffLoadingStatus,
        lastPoll: makeBackbucketSafeNow(),
      });
    })
  );
  if (result?.type === LOAD_PULL_REQUEST.ERROR) {
    return null;
  }
  return result;
};

const fetchMergeChecks = async (
  owner: string,
  slug: string,
  id: string,
  { reduxStore, csrftoken }: Pick<ResourceContext, 'reduxStore' | 'csrftoken'>
) => {
  const { dispatch } = reduxStore as {
    dispatch: (x: Action | Thunk) => Action<FetchAction> | null;
  };
  const mergeCheckUrl = urls.api.internal.mergeChecks(owner, slug, id);

  const action = fetchActionCreator(FETCH_MERGE_CHECKS, {
    url: mergeCheckUrl,
    isRouterResource: true,
    csrftoken,
  });

  dispatch(action);
  const result = await dispatch(
    fetchData(action, mergeCheckPayloadTransformer)
  );

  if (result?.type === FETCH_MERGE_CHECKS.ERROR) {
    return null;
  }

  return result;
};

const fetchSourceRepositoryDetails = async ({
  reduxStore,
  csrftoken,
}: Pick<ResourceContext, 'reduxStore' | 'csrftoken'>) => {
  const { dispatch, getState } = reduxStore as {
    dispatch: (x: Action | Thunk) => Action<FetchAction> | null;
    getState: () => BucketState;
  };
  const state = getState();
  const sourceRepository = getPullRequestSourceRepo(state);
  const destinationRepository = getPullRequestDestinationRepo(state);

  if (
    !!sourceRepository &&
    !!destinationRepository &&
    !!sourceRepository.full_name &&
    sourceRepository.full_name !== destinationRepository.full_name
  ) {
    const baseUrl = repositoryUrls.api.internal.details(
      sourceRepository.full_name
    );
    const action = fetchActionCreator(FetchSourceRepositoryDetails, {
      url: `${baseUrl}?${fetchSourceRepositoryDetailsQuery}`,
      isRouterResource: true,
      csrftoken,
    });

    dispatch(action);
    const result = await dispatch(fetchData(action));

    if (result?.type === FetchSourceRepositoryDetails.SUCCESS) {
      return result;
    }
  }

  return null;
};

export const loadPullRequestResource = createResource({
  type: 'pullrequest',
  getKey: routerStoreContext => {
    const {
      pullRequestId: id,
      repositoryOwner: owner,
      repositorySlug: slug,
    } = routerStoreContext.match.params;
    return `${owner}/${slug}/${id}`;
  },
  // We can't cache this data yet since it's set in redux. When we leave the
  // page we call UNLOAD_PULL_REQUEST which removes some of the data. If we
  // come back to the same PR (where getKey() would return the same value),
  // then getData() won't be called and there won't be any data in redux. We
  // get an infinite spinner.
  maxAge: 0,
  getData: async (
    routerStoreContext,
    { reduxStore, csrftoken }: ResourceContext
  ) => {
    const { dispatch } = reduxStore as { dispatch: Dispatch };
    const {
      pullRequestId: id,
      repositoryOwner: owner,
      repositorySlug: slug,
    } = routerStoreContext.match.params;

    if (!id || !owner || !slug) {
      dispatch({
        type: LOAD_PULL_REQUEST.ERROR,
        payload: 'Loading Pull Request failed due to missing parameters',
      });
      throw new Error('Loading Pull Request failed due to missing parameters');
    }

    const results = await Promise.all([
      loadPullRequest(owner, slug, id, { reduxStore, csrftoken }),
      fetchMergeChecks(owner, slug, id, { reduxStore, csrftoken }),
    ]);

    // FetchSourceRepositoryDetails need pull request data.
    await fetchSourceRepositoryDetails({
      reduxStore,
      csrftoken,
    });

    // check that we got a result for every request, otherwise fall back to client-side render
    if (!results.every(r => r)) {
      return null;
    }

    return { status: 'success' };
  },
});
