import { getExtensionAttrs } from '../../utils/extensions';
import { multiBodiedExtensionStage0 as multiBodiedExtensionStage0Factory } from '../../next-schema/generated/nodeTypes';
import { extensionFrameStage0 as extensionFrameStage0Factory } from '../../next-schema/generated/nodeTypes';

/**
 * @stage 0
 * @name extensionFrame_node
 * @description Wraps the block content
 */

/**
 * @returns NodeSpec for ExtensionFrameDefinition
 */
export var extensionFrame = extensionFrameStage0Factory({
  parseDOM: [{
    context: 'extensionFrame//',
    tag: 'div[data-extension-frame]',
    skip: true
  }, {
    tag: 'div[data-extension-frame]'
  }],
  toDOM: function toDOM() {
    var attrs = {
      'data-extension-frame': 'true'
    };
    return ['div', attrs, 0];
  }
});

/**
 * @stage 0
 * @name multiBodiedExtension_node
 * @description Wraps multiple extensionFrame objects.
 */

export var multiBodiedExtension = multiBodiedExtensionStage0Factory({
  parseDOM: [{
    context: 'multiBodiedExtension//',
    tag: '[data-node-type="multi-bodied-extension"]',
    skip: true
  }, {
    tag: '[data-node-type="multi-bodied-extension"]',
    getAttrs: function getAttrs(domNode) {
      return getExtensionAttrs(domNode);
    }
  }],
  toDOM: function toDOM(node) {
    var attrs = {
      'data-node-type': 'multi-bodied-extension',
      'data-extension-type': node.attrs.extensionType,
      'data-extension-key': node.attrs.extensionKey,
      'data-text': node.attrs.text,
      'data-parameters': JSON.stringify(node.attrs.parameters),
      'data-layout': node.attrs.layout,
      'data-local-id:': node.attrs.localId
    };
    return ['div', attrs, 0];
  }
});