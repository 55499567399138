import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/**
 * Factory method to attach custom parseDOM and/or toDOM for nodeSpec
 *
 * @example
 * createPMNodeSpecFactory<SomeNode>(node)({parseDOM: {}, toDOM: (node) => {} });
 *
 * @param nodeSpec - NodeSpec without toDom and parseDom
 * @returns A function for a node which allows the consumer to define toDom and parseDom
 */
export var createPMNodeSpecFactory = function createPMNodeSpecFactory(nodeSpec) {
  return function (_ref) {
    var parseDOM = _ref.parseDOM,
      toDOM = _ref.toDOM,
      toDebugString = _ref.toDebugString;
    return _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, nodeSpec), parseDOM && {
      parseDOM: parseDOM
    }), toDOM && {
      toDOM: toDOM
    }), toDebugString && {
      toDebugString: toDebugString
    });
  };
};

/**
 * Factory method to attach custom parseDOM and/or toDOM for markSpec
 *
 * @example
 * createPMMarkSpecFactory<SomeMark>(mark)({parseDOM: {}, toDOM: (mark, inline) => {} });
 *
 * @param markSpec - Markspec without toDom and parseDom
 * @returns A function for a mark which allows the consumer to define toDom and parseDom
 */
export var createPMMarkSpecFactory = function createPMMarkSpecFactory(markSpec) {
  return function (_ref2) {
    var parseDOM = _ref2.parseDOM,
      toDOM = _ref2.toDOM,
      toDebugString = _ref2.toDebugString;
    return _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, markSpec), parseDOM && {
      parseDOM: parseDOM
    }), toDOM && {
      toDOM: toDOM
    }), toDebugString && {
      toDebugString: toDebugString
    });
  };
};