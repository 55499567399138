import { PipelineState } from 'src/components/pipelines/models/PipelineState';

export function convertTerminatedBy(state: PipelineState | undefined): string {
  if (!state || !state.name) {
    return '';
  }
  if (state.name === 'COMPLETED') {
    if (state.result?.name === 'STOPPED') {
      return state.result.terminator?.display_name || '';
    }
  }
  return '';
}
