import React from 'react';

import { AutoDismissFlag } from '@atlaskit/flag';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { useIntl } from 'src/hooks/intl';
import { ComponentFlagId, HandleDismissedType } from 'src/redux/flags/types';

import messages from './pull-request-retarget.i18n';

type Props = {
  id: ComponentFlagId;
  props: {
    branchName: string;
  };
} & HandleDismissedType;

export const PullRequestRetargetSuccessFlag = ({
  id,
  props,
  ...otherProps
}: Props): JSX.Element => {
  const intl = useIntl();

  return (
    <AutoDismissFlag
      {...otherProps}
      id={id}
      description={intl.formatMessage(messages.retargetPullRequestSuccessBody, {
        branch: props?.branchName,
      })}
      icon={
        <SuccessIcon
          primaryColor={token('color.icon.success', colors.G300)}
          label="success"
        />
      }
      title={intl.formatMessage(messages.retargetPullRequestSuccessTitle)}
    />
  );
};
