import { Pipeline } from 'src/components/pipelines/models';

import { ORIGIN, YML_FILE_NAME } from '../constants';

import extractUsername from './extractUsername';
import formatUuid from './formatUuid';

export function getEditConfigurationURL(
  repoPath: string,
  branch: string
): string {
  return `${ORIGIN}/${repoPath}/src/${branch}/${YML_FILE_NAME}`;
}

export function getPipelinesPageURL(repoPath: string, route = ''): string {
  return `/${repoPath}/pipelines${route}`;
}

export function getPipelinePageURL(
  pipeline: Pipeline | { uuid?: string; buildNumber?: string },
  repoPath: string
): string {
  const route = `/results/${pipeline.buildNumber || formatUuid(pipeline.uuid)}`;

  return `/${repoPath}/pipelines${route}`;
}

export function getStepPageURL(
  pipeline: Pipeline | { uuid: string; buildNumber?: number },
  stepUuid: string,
  repoPath?: string
): string {
  const pipelineId = pipeline.buildNumber || formatUuid(pipeline.uuid);
  const route = `/results/${pipelineId}/steps/${formatUuid(stepUuid)}`;

  return `/${repoPath}/pipelines${route}`;
}

export function getTestReportPageURL(
  pipeline: Pipeline | { uuid: string; buildNumber?: number },
  stepUuid: string,
  repoPath?: string
): string {
  const stepPage = getStepPageURL(pipeline, stepUuid, repoPath);
  return `${stepPage}/test-report`;
}

export function getAccountPageURL(accountUuid: string): string {
  return `${ORIGIN}/${accountUuid}`;
}

export function getProjectPageURL(
  repoPath: string,
  projectKey: string
): string {
  return `${ORIGIN}/account/user/${extractUsername(
    repoPath
  )}/projects/${projectKey}`;
}

export function getSettingsPageURL(repoPath: string, addonKey: string): string {
  return `${ORIGIN}/${repoPath}/admin/${addonKey}/settings`;
}

export function getDeploymentSettingsPageURL(
  repoPath: string,
  addonKey: string
): string {
  return `${ORIGIN}/${repoPath}/admin/addon/admin/${addonKey}/deployment-settings`;
}

export function getAddConfigurationURL(
  repoPath: string,
  branch: string,
  ymlToCopy = ''
): string {
  let yml = ymlToCopy;
  if (yml) {
    yml = `&source=${encodeURIComponent(yml)}&disableFilename=true`;
  }
  return `${ORIGIN}/${repoPath}/create-file/?filename=${YML_FILE_NAME}&at=${branch}${yml}&fromOnboarding=true`;
}

export function getDeploymentsPageURL(
  repoPath: string,
  addonKey: string
): string {
  return `${ORIGIN}/${repoPath}/addon/${addonKey}/deployments`;
}

export function getPlansPageURL(repoPath = ''): string {
  return `${ORIGIN}/account/user/${extractUsername(
    repoPath
  )}/plans-and-billing/`;
}

export function getAdminPlans(workspace?: string): string {
  if (!workspace) {
    return `${ORIGIN}/account/admin/plans/`;
  }
  return `${ORIGIN}/${workspace}/workspace/settings/plans-and-billing/`;
}

export function getTestExecutionUrl(
  testUuid: string,
  fullyQualifiedName: string,
  averageDuration: number
): string {
  return `test-cases/${testUuid}?fullyQualifiedName=${encodeURIComponent(
    fullyQualifiedName
  )}&averageDuration=${averageDuration}`;
}
