import { createResource } from 'react-resource-router';

import { getHydrateAction } from 'src/middleware/hydrate';
import {
  fetchData,
  hydrateAction as hydrateActionCreator,
} from 'src/redux/actions';
import { ResourceContext } from 'src/router/types';
import { LoadRepositoryPage } from 'src/sections/repository/actions';
import { stateKey } from 'src/sections/repository/actions/load-repository-page';
import { repository } from 'src/sections/repository/schemas';
import { Dispatch } from 'src/types/state';
import { urlWithState } from 'src/utils/state-key';

import { getRepoFullSlug } from './utils/get-full-repo-slug';
import { isSameRepository } from './utils/is-same-repository';

export const loadRepositoryResource = createResource({
  type: 'repository',
  getKey: ({ match }) => `repository:${getRepoFullSlug(match.params)}`,
  maxAge: 0,
  getData: async (
    routerStoreContext,
    { reduxStore, csrftoken }: ResourceContext
  ) => {
    const { dispatch } = reduxStore as { dispatch: Dispatch };
    const repositoryFullSlug = getRepoFullSlug(routerStoreContext.match.params);
    const url = `/${repositoryFullSlug}`;

    // don't fetch data if repository hasn't changed
    if (isSameRepository(repositoryFullSlug, reduxStore.getState())) {
      return { status: 'success' };
    }

    const action = hydrateActionCreator(LoadRepositoryPage, stateKey, {
      url,
      isRouterResource: true,
      csrftoken,
      schema: {
        currentRepository: repository,
      },
    });

    const hydrateAction = getHydrateAction(action, stateKey);
    if (hydrateAction) {
      dispatch(hydrateAction);
      return { status: 'success' };
    }

    action.meta.url = urlWithState(url, stateKey);
    dispatch(action);
    await dispatch(fetchData(action));
    return { status: 'success' };
  },
});
