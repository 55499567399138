import { formatInTimeZone } from 'date-fns-tz';
export var localTime = function localTime(timezone, format) {
  if (!timezone) {
    return null;
  }
  try {
    return formatInTimeZone(new Date(), timezone, format);
  } catch (error) {
    return null;
  }
};