import {Module} from '../../modules/base/Module';
import {Provider} from '../../modules/base/Provider';

/**
 * This class provides common behaviour relating to the adaption of functionality to a
 * particular Connect client framework. This is necessary for an interim period during which
 * we have multiple Connect client frameworks that we need to support: ACJS and CaaS Client.
 */
export abstract class BaseFrameworkAdaptor {

    moduleNamesToModules: Map<string, Module<any, any> | undefined> = new Map<string, Module<any, any> | undefined>();

    abstract registerModuleWithHost(moduleName: string, simpleXdmDefinition: any): void;

    /**
     * This method registers a module with the Connect client framework relating to this adaptor instance.
     * @param moduleDefinition the definition of the module.
     */
    public registerModule(module: Module<any, any>, props: any): void {
        const moduleRegistrationName : string = module.getModuleRegistrationName();
        this.moduleNamesToModules.set(moduleRegistrationName, module);

        const simpleXdmDefinitionBuilder = module.getSimpleXdmDefinitionBuilder();
        const simpleXdmDefinition = simpleXdmDefinitionBuilder.buildSimpleXdmDefinition();
        this.registerModuleWithHost(moduleRegistrationName, simpleXdmDefinition);
    }

    public getModuleByName(moduleName: string): Module<any, any> | undefined {
        return this.moduleNamesToModules.get(moduleName);
    }

    /**
     * This routine gets a module provider based on the module's name. The provider will only be returned if
     * the module is enabled.
     * @param moduleName the name of the module.
     * @returns {any} the module provider if found and enabled.
     */
    public getProviderByModuleName(moduleName: string): Provider | undefined {
        const module: Module<any, any> | undefined = this.moduleNamesToModules.get(moduleName);
        if (module && module.isEnabled()) {
            return module.getProvider();
        } else {
            return undefined;
        }
    }

}
