import { defineMessages } from 'react-intl-next';
var getLabelMessage = function getLabelMessage(ariaLabelProp, fullName, formatMessage) {
  if (ariaLabelProp) {
    return ariaLabelProp;
  }
  return fullName ? formatMessage(messages.label, {
    fullName: fullName
  }) : formatMessage(messages.fallbackLabel);
};
var messages = defineMessages({
  fallbackLabel: {
    id: 'profilecard.user.trigger.fallback-aria-label',
    defaultMessage: 'More information about this user',
    description: 'Label for profile card trigger when we do not have user name'
  },
  label: {
    id: 'profilecard.user.trigger.aria-label',
    defaultMessage: 'More information about {fullName}',
    description: 'Label for profile card trigger'
  }
});
export default getLabelMessage;