import React, { ReactNode } from 'react';

import {
  Router as ResourceRouter,
  StaticRouter,
  BrowserHistory,
} from 'react-resource-router';
import { RouterProps as ResourceRouterProps } from 'react-resource-router/build/cjs/controllers/router/types';

import { Routes } from 'src/router/routes/types';
import { getResourceStoreSsrState } from 'src/utils/ssr-hydration';

type RouterProps = Omit<ResourceRouterProps, 'history'> & {
  history: BrowserHistory;
  routes: Routes;
  children: ReactNode;
  isSsr: boolean;
  location: string;
};

const Router = ({
  history,
  routes,
  children,
  isSsr,
  location,
  ...rest
}: RouterProps) => {
  if (isSsr) {
    return (
      <StaticRouter routes={routes} location={location}>
        {children}
      </StaticRouter>
    );
  }

  return (
    <ResourceRouter
      history={history}
      routes={routes}
      resourceData={getResourceStoreSsrState()}
      {...rest}
    >
      {children}
    </ResourceRouter>
  );
};

Router.defaultProps = {
  /* eslint react/default-props-match-prop-types: "warn" */
  isStatic: false,
  // eslint-disable-next-line
  transitionBlocker: async () => true,
};

export default Router;
