/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { Action } from 'redux';
import {
  call,
  delay,
  select,
  put,
  take,
  fork,
  cancel,
  cancelled,
  race,
  spawn,
} from 'redux-saga/effects';

import FeatureGates from '@atlaskit/feature-gate-js-client';
import { StatsigFeatureKeys } from '@atlassian/bitbucket-features';

import { PullRequest } from 'src/components/types';
import { dismissFlag, showFlagComponent } from 'src/redux/flags';
import {
  PAGE_HIDDEN,
  PAGE_VISIBLE,
} from 'src/redux/global/actions/page-visibility';
import { prCommitActions } from 'src/redux/repo-commits/actions';
import { prefetchCommits } from 'src/redux/repo-commits/sagas';
import { getPullRequestApis } from 'src/sagas/helpers';
import { PullRequestUpdatesResponse } from 'src/types/pull-request';

import {
  POLLED_PULLREQUEST,
  EXITED_CODE_REVIEW,
  LOAD_DIFFSTAT,
  LOAD_PULL_REQUEST,
  FETCH_DIFF,
  EnteredCodeReviewAction,
  RefreshCodeReviewDataOptions,
  UPDATE_PULL_REQUEST_LINKS,
  fetchTasks,
  TOGGLE_REFETCH_BUILDS_DUMMY,
  REFETCH_IR_DIFF,
  MOCK_IR_DIFF_FETCH,
} from '../actions';
import { updatePullRequest } from '../actions/update-pull-request';
import { FETCH_ACTIVITY } from '../activity-reducer';
import { ITERATIVE_REVIEW_COMPLETED } from '../iterative-review-reducer';
import {
  getCurrentPullRequestUrlPieces,
  getLastPollTime,
  getUpdateNeeds,
  getCurrentPullRequest,
} from '../selectors';
import { backOffPoller } from '../utils/polling-delay-timer';

import fetchBranchSyncInfoSaga from './branch-sync-info-saga';
import fetchConflictsSaga from './fetch-conflicts-saga';
import {
  retryFetchMergeChecks,
  retryFetchAllMergeChecksIfNeeded,
} from './fetch-merge-checks-saga';
import { fetchPullRequestComments } from './fetch-pull-request-comments-saga';
import { fetchPullRequest } from './fetch-pull-request-saga';

export const INITIAL_INTERVAL = 20 * 1000;
export const PR_UPDATE_FLAG_ID = 'pull-request-updated';

const shouldShowFlag = (
  response: PullRequestUpdatesResponse,
  updatedOn: string
) => {
  // Response from server is a UTC date string without a defined timezone
  // The UTC timezone must be defined otherwise older browsers may
  // interpret the date as a local timezone, instead of UTC
  const lastModified = response.lastModified.endsWith('Z')
    ? response.lastModified
    : `${response.lastModified}Z`;

  // Inline edits update the current pull request data in the Redux store
  // to match the lastModified data returned in the updates/ response
  const recentInlineEdit =
    new Date(lastModified).getTime() === new Date(updatedOn).getTime();

  return (
    response.anchorMoved ||
    // response.destRevMoved || // Ignored for now because of noisy amount of updates
    /* If only the PR Details are modified, we will not show the flag if
       the change was a result of a recent inline edit */
    (response.prDetailsModified && !recentInlineEdit) ||
    response.reviewersModified ||
    response.approvalsAdded.length > 0 ||
    response.approvalsRemoved.length > 0 ||
    response.requestChangesAdded.length > 0 ||
    response.requestChangesRemoved.length > 0 ||
    response.commentsAdded.length > 0 ||
    response.commentsDeleted.length > 0 ||
    response.commentsReplied.length > 0 ||
    response.commentsEdited.length > 0 ||
    response.commentsResolved.length > 0 ||
    response.commentsReopened.length > 0 ||
    response.needsTasks ||
    response.statusChanged
  );
};

export function* getPullRequestUpdates(lastPollTime: string) {
  const api = yield* getPullRequestApis();
  const { owner, slug, id } = yield select(getCurrentPullRequestUrlPieces);
  const response: UnwrapPromise<typeof api.getUpdates> = yield call(
    api.getUpdates,
    owner,
    slug,
    id,
    lastPollTime
  );

  if ('error' in response) {
    throw response.error;
  }

  const { updated_on: updatedOn } = yield select(getCurrentPullRequest);

  if (shouldShowFlag(response, updatedOn)) {
    yield put({
      type: POLLED_PULLREQUEST,
      payload: {
        lastModified: response.lastModified,
        needsPullRequest:
          response.prDetailsModified ||
          response.reviewersModified ||
          response.approvalsAdded.length > 0 ||
          response.approvalsRemoved.length > 0 ||
          response.requestChangesAdded.length > 0 ||
          response.requestChangesRemoved.length > 0 ||
          response.anchorMoved ||
          response.statusChanged,
        needsDiff: response.anchorMoved,
        needsComments:
          response.commentsAdded.length > 0 ||
          response.commentsDeleted.length > 0 ||
          response.commentsReplied.length > 0 ||
          response.commentsEdited.length > 0 ||
          response.commentsResolved.length > 0 ||
          response.commentsReopened.length > 0,
        needsTasks: response.needsTasks,
        links: response.links,
      },
    });

    const descriptivePrUpdateFlag = (yield call(
      FeatureGates.checkGate,
      StatsigFeatureKeys.descriptivePrUpdateFlag
    )) as boolean;

    if (descriptivePrUpdateFlag) {
      // Including the response in the props allows the flag to choose its text based on the values in the response
      yield put(
        showFlagComponent(PR_UPDATE_FLAG_ID, {
          props: { response },
        })
      );
    } else {
      // The original implementation; not including the response causes the flag to default to the original generic text
      yield put(showFlagComponent(PR_UPDATE_FLAG_ID));
    }
  }
}

export function* pollPullRequestUpdates() {
  const { calculateDelay, cleanup: cleanupPoller } =
    backOffPoller(INITIAL_INTERVAL);

  while (true) {
    // @ts-ignore
    const lastPollTime: ReturnType<typeof getLastPollTime> = yield select(
      getLastPollTime
    );

    if (!lastPollTime) {
      yield delay(calculateDelay());
      continue;
    }

    try {
      yield call(getPullRequestUpdates, lastPollTime);
    } catch (e) {
      // Unhandled currently, just delay and try again
    } finally {
      // @ts-ignore
      if (yield cancelled()) {
        cleanupPoller();
      }
    }

    yield delay(calculateDelay());
  }
}

// @ts-ignore TODO: fix noImplicitAny error here
export function* startingPollingForUpdates(_action: EnteredCodeReviewAction) {
  // @ts-ignore
  const pollingLoop = yield fork(pollPullRequestUpdates);
  const { pageWasHidden } = yield race({
    pageWasHidden: take(PAGE_HIDDEN),
    exitedPage: take(EXITED_CODE_REVIEW),
  });

  yield cancel(pollingLoop);

  if (pageWasHidden) {
    yield take(PAGE_VISIBLE);
    yield* startingPollingForUpdates({} as EnteredCodeReviewAction);
  }
}

export function* dismissUpdateFlag() {
  yield put(dismissFlag(PR_UPDATE_FLAG_ID));
}

export function* minimalUpdateSaga(
  action: Action & {
    payload: RefreshCodeReviewDataOptions;
  }
) {
  const { owner, slug, id } = yield select(getCurrentPullRequestUrlPieces);

  const {
    needsDiff,
    needsComments,
    needsTasks,
    needsPullRequest,
    links,
    forceNeedsMergeChecks,
  } = action.payload;

  // This clears the existing activity feed and refetches from the beginning
  yield put({ type: FETCH_ACTIVITY.REQUEST, owner, slug, id });

  if (needsDiff) {
    yield call(prefetchCommits, { owner, slug, id });
    yield take(prCommitActions.UPDATE_COMMITS);
  }

  if (needsPullRequest) {
    yield spawn(fetchPullRequest);
    if (forceNeedsMergeChecks) {
      yield spawn(retryFetchMergeChecks);
    } else {
      yield spawn(retryFetchAllMergeChecksIfNeeded);
    }
    yield take([LOAD_PULL_REQUEST.SUCCESS]);
  }

  if (needsComments) {
    yield spawn(fetchPullRequestComments);
  }

  if (needsTasks) {
    yield put(fetchTasks({ owner, repoSlug: slug, pullRequestId: id }));
  }

  if (needsDiff) {
    if (links) {
      const pullRequest: PullRequest = yield select(getCurrentPullRequest);
      const updatedPullRequest = {
        ...pullRequest,
        links: {
          ...pullRequest.links,
          ...links,
        },
      };
      yield put(
        updatePullRequest({
          type: UPDATE_PULL_REQUEST_LINKS,
          pullRequest: updatedPullRequest,
        })
      );
    }

    yield spawn(fetchBranchSyncInfoSaga, owner, slug, id);

    yield put({ type: REFETCH_IR_DIFF.TRUE });

    yield take([FETCH_DIFF.SUCCESS, LOAD_DIFFSTAT.SUCCESS, MOCK_IR_DIFF_FETCH]);
    yield spawn(fetchConflictsSaga, owner, slug, id);
    // reset the iterative review completed state
    yield put({ type: ITERATIVE_REVIEW_COMPLETED.FALSE });
  }

  // refreshes builds card (currently on every reload)
  yield put({ type: TOGGLE_REFETCH_BUILDS_DUMMY });
}

export function* minimalUpdateFromPollResults() {
  // @ts-ignore
  const updateNeeds = yield select(getUpdateNeeds);
  yield spawn(minimalUpdateSaga, { type: 'IRRELEVANT', payload: updateNeeds });
}
