/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { put, call, select } from 'redux-saga/effects';

import urls from 'src/sections/repository/urls';
import { getCurrentRepositoryFullSlug } from 'src/selectors/repository-selectors';
import authRequest from 'src/utils/fetch';

import {
  FETCH_DEFAULT_COMMIT_MESSAGE,
  FetchDefaultCommitMessageAction,
} from '../actions';

export default function* fetchDefaultCommitMessageSaga(
  action: FetchDefaultCommitMessageAction
) {
  const repositoryFullName: string = yield select(getCurrentRepositoryFullSlug);

  const { sourceBranchName, destinationBranchName, mergeStrategy } =
    action.payload;

  try {
    const url = urls.api.internal.defaultCommitMsg(
      repositoryFullName,
      mergeStrategy,
      sourceBranchName,
      destinationBranchName
    );
    const res: Response = yield call(fetch, authRequest(url));
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    // @ts-ignore
    const data = yield call([res, 'json']);
    yield put({
      type: FETCH_DEFAULT_COMMIT_MESSAGE.SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({
      type: FETCH_DEFAULT_COMMIT_MESSAGE.ERROR,
      payload: e,
    });
  }
}
