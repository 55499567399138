import { layoutColumn as layoutColumnFactory } from '../../next-schema/generated/nodeTypes';

/**
 * @name layoutColumn_node
 */

export var layoutColumn = layoutColumnFactory({
  parseDOM: [{
    context: 'layoutColumn//',
    tag: 'div[data-layout-column]',
    skip: true
  }, {
    tag: 'div[data-layout-column]',
    getAttrs: function getAttrs(domNode) {
      var dom = domNode;
      return {
        width: Number(dom.getAttribute('data-column-width')) || undefined
      };
    }
  }],
  toDOM: function toDOM(node) {
    var attrs = {
      'data-layout-column': 'true'
    };
    var width = node.attrs.width;
    if (width) {
      attrs['style'] = "flex-basis: ".concat(width, "%");
      attrs['data-column-width'] = "".concat(width);
    }

    // We need to apply a attribute to the inner most child to help
    // ProseMirror identify its boundaries better.
    var contentAttrs = {
      'data-layout-content': 'true'
    };
    return ['div', attrs, ['div', contentAttrs, 0]];
  }
});