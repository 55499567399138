/* eslint frontbucket-patterns/no-new-sagas: "warn" */
import { put, call, all, takeLatest, select } from 'redux-saga/effects';

import { getCurrentPullRequestUrlPieces } from 'src/redux/pull-request/selectors';
import urls from 'src/redux/pull-request/urls';
import { getPullRequestApis } from 'src/sagas/helpers';
import { UrlPieces } from 'src/types/pull-request';

import { commit } from '../pull-request/schemas';

import { prCommitActions } from './actions';
import { getCommitsNextUrl } from './selectors';

type UrlString = string;
const PAGE_LIMIT = '25';

// Workhorse function to simply use the api layer and the url to put commits into state
export function* fetchCommits(url: UrlString) {
  const api = yield* getPullRequestApis();
  const response: UnwrapPromise<typeof api.getCommits> = yield call(
    api.getCommits,
    url
  );

  if ('error' in response) {
    yield put({
      type: prCommitActions.COMMIT_FETCH_ERROR,
      payload: response.status,
    });
    return;
  }

  const { commits, next, page } = response;
  yield put({
    type: prCommitActions.UPDATE_COMMITS,
    meta: {
      schema: { commits: [commit] },
    },
    payload: { commits, next, page },
  });
}

/** Useful before we have anything in state */
export function* prefetchCommits({ owner, slug, id }: UrlPieces) {
  yield fetchCommits(urls.api.v20.commits(owner, slug, id, PAGE_LIMIT));
}

/** owner, slug, and id are expected to be in state */
export function* retryCommits() {
  const { owner, slug, id } = yield select(getCurrentPullRequestUrlPieces);
  if (!owner || !slug || !id) {
    return;
  }
  yield fetchCommits(urls.api.v20.commits(owner, slug, id, PAGE_LIMIT));
}

/** Uses next url from state */
export function* fetchNextCommits() {
  let nextUrl: string = yield select(getCommitsNextUrl);
  const nextCommitsUrl = new URL(nextUrl);
  nextCommitsUrl.searchParams.set('pagelen', PAGE_LIMIT);
  nextUrl = nextCommitsUrl.href;
  yield fetchCommits(nextUrl);
}

export function* rootPrCommitsSaga() {
  yield all([
    takeLatest(prCommitActions.RETRY_COMMITS, retryCommits),
    takeLatest(prCommitActions.FETCH_MORE_COMMITS, fetchNextCommits),
  ]);
}
