import React, { PureComponent } from 'react';

import AKFlag, { FlagGroup as AKFlagGroup } from '@atlaskit/flag';
import { Flag as FlagModule } from '@atlassian/connect-module-core/src/modules/flag/Flag';
import { FlagCreationOptions } from '@atlassian/connect-module-core/src/modules/flag/FlagCreationOptions';

import { FlagProvider } from './provider';
import { getIcon, wrapAction } from './utils';

type FlagProps = {
  flagProvider: FlagProvider;
};

type FlagStateOptions = FlagCreationOptions & { timeoutId?: number };

type FlagState = {
  flags: FlagStateOptions[];
};

export default class Flag extends PureComponent<FlagProps, FlagState> {
  state: FlagState = { flags: [] };

  componentDidMount() {
    this.props.flagProvider.setView({
      create: this.create,
      isFlagOpen: this.isFlagOpen,
    });
  }

  componentWillUnmount() {
    this.props.flagProvider.setView(null);
    this.state.flags.forEach(flag => clearTimeout(flag.timeoutId));
  }

  create = (flag: FlagCreationOptions): FlagModule => {
    const newFlag: FlagStateOptions = flag;
    if (newFlag.close === 'auto') {
      newFlag.timeoutId = window.setTimeout(() => {
        this.close(newFlag.id);
      }, 8000);
    }
    this.setState({ flags: [newFlag, ...this.state.flags] });
    return {
      id: newFlag.id,
      close: () => this.close(newFlag.id),
    };
  };

  close = (flagId: string) => {
    this.setState({
      flags: this.state.flags.filter(flag => flag.id !== flagId),
    });
  };

  isFlagOpen = (flagId: string): boolean =>
    this.state.flags.some(flag => flag.id === flagId);

  render() {
    return (
      <AKFlagGroup onDismissed={this.close}>
        {this.state.flags.map(flag => (
          <AKFlag
            key={flag.id}
            id={flag.id}
            title={flag.title}
            description={flag.body}
            actions={flag.actions.map(wrapAction)}
            icon={getIcon(flag.type)}
          />
        ))}
      </AKFlagGroup>
    );
  }
}
