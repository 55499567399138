import escapeBbqlString from 'src/utils/escape-bbql-string';

import { PermissionsFilters } from './types';

export const buildBBQL = (filters: PermissionsFilters = {}): string => {
  const queries: string[] = [];

  if (filters.accessLevel) {
    queries.push(`access_level="${escapeBbqlString(filters.accessLevel)}"`);
  }

  if (filters.permission) {
    queries.push(`permission="${escapeBbqlString(filters.permission)}"`);
  }

  if (filters.principalName) {
    queries.push(`principal_name~"${escapeBbqlString(filters.principalName)}"`);
  }

  return queries.join(' AND ');
};
