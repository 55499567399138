import React from 'react';

import { defineMessages } from 'react-intl';

import Button from '@atlaskit/button/standard-button';

import { useAnalytics } from 'src/components/settings/analytics';
import { useIntl } from 'src/hooks/intl';

import { useActions } from './provider';

const messages = defineMessages({
  addUsersOrGroupsBtn: {
    id: 'frontbucket.settings.permissions.add-users-or-groups-btn',
    description: 'Add users or groups button text',
    defaultMessage: 'Add users or groups',
  },
});

export const PermissionsActions = () => {
  const { formatMessage } = useIntl();
  const { publishButtonClickEvent } = useAnalytics();
  const { setActiveModal } = useActions();
  return (
    <Button
      testId="addPrivilegeButton"
      onClick={() => {
        setActiveModal('AddAccessModal');
        publishButtonClickEvent('AddUsersOrGroups');
      }}
    >
      {formatMessage(messages.addUsersOrGroupsBtn)}
    </Button>
  );
};
