import { Metric } from 'src/components/pipelines/models';
import { LoadingStatus } from 'src/constants/loading-status';
import { Action } from 'src/types/state';
import createReducer from 'src/utils/create-reducer';

import { REQUEST_METRICS } from '../actions/pipelines';

export type MetricsState = {
  metrics: {
    [stepUuid: string]: {
      MEMORY: { [key: string]: Metric };
      CPU: { [key: string]: Metric };
      MEMORY_PERCENTAGE: { [key: string]: Metric };
    };
  };
  fetchedStatus: LoadingStatus;
};

export const initialState: MetricsState = {
  metrics: {},
  fetchedStatus: LoadingStatus.Before,
};

export const metricsReducer = createReducer(initialState, {
  [REQUEST_METRICS.REQUEST](state) {
    return {
      ...state,
      fetchedStatus: LoadingStatus.Fetching,
    };
  },
  [REQUEST_METRICS.ERROR](state) {
    return {
      ...state,
      fetchedStatus: LoadingStatus.Failed,
    };
  },
  [REQUEST_METRICS.SUCCESS](
    state: MetricsState,
    action: Action<
      {
        metric_type: 'MEMORY' | 'CPU' | 'MEMORY_PERCENTAGE';
        sample_time: string;
      }[]
    > & { meta: { stepUuid: string } }
  ) {
    if (!action.payload || !action.meta) {
      return state;
    }

    const stepMetrics = state.metrics[action.meta.stepUuid] || {
      MEMORY: {},
      CPU: {},
      MEMORY_PERCENTAGE: {},
    };

    action.payload.forEach(metric => {
      if (metric.metric_type && metric.sample_time) {
        stepMetrics[metric.metric_type][metric.sample_time] = new Metric(
          metric
        );
      }
    });

    return {
      metrics: {
        ...state.metrics,
        [action.meta.stepUuid]: stepMetrics,
      },
      fetchedStatus: LoadingStatus.Success,
    };
  },
});
