import {BaseFrameworkAdaptor} from '../../adaptors/framework/BaseFrameworkAdaptor';
import {BaseModule} from '../base/Module';
import {ModuleDefinitions} from '../base/Module';
import {DialogProvider} from './DialogProvider';
import {Provider} from '../base/Provider';
import {SimpleXdmDefinitionBuilder} from '../../definitions/SimpleXdmDefinitionBuilder';
import {DialogSimpleXdmDefinitionBuilder} from './DialogSimpleXdmDefinitionBuilder';

export module DialogModuleDefinitions {

    export const DIALOG_MODULE_NAME = 'dialog';

    export class Props implements ModuleDefinitions.Props {

        adaptor: BaseFrameworkAdaptor;
        dialogProvider: DialogProvider;

        constructor(
            adaptor: BaseFrameworkAdaptor,
            dialogProvider: DialogProvider) {
            this.adaptor = adaptor;
            this.dialogProvider = dialogProvider;
        }

    }

    export class State implements ModuleDefinitions.State {

        enabled: boolean;
        registered: boolean;
        dialogProvider: DialogProvider;
        simpleXdmDefinitionBuilder: SimpleXdmDefinitionBuilder;

        constructor(dialogProvider: DialogProvider) {
            this.enabled = true;
            this.registered = false;
            this.dialogProvider = dialogProvider;
            this.simpleXdmDefinitionBuilder = new DialogSimpleXdmDefinitionBuilder(dialogProvider);
        }

    }

}

/**
 * This module provides dialog related functionality.
 */
export class DialogModule extends BaseModule<DialogModuleDefinitions.Props, DialogModuleDefinitions.State> {

    constructor(props: DialogModuleDefinitions.Props) {
        super(props);
    }

    componentWillMount(): void {
        if (!this.props.dialogProvider) {
            throw new Error('this.props.dialogProvider is not defined');
        }
        this.setState(new DialogModuleDefinitions.State(this.props.dialogProvider));
    }

    public getModuleRegistrationName(): string {
        return DialogModuleDefinitions.DIALOG_MODULE_NAME;
    }

    public getSimpleXdmDefinitionBuilder() : SimpleXdmDefinitionBuilder {
      return this.state.simpleXdmDefinitionBuilder;
    }

    getProvider(): Provider {
        return this.props.dialogProvider;
    }

    render(): JSX.Element | null {
        return null;
    }
}
